import {useState, useEffect, useCallback} from "react";
import {useApiCallback} from "../utils/Api";
import TextField from "@mui/material/TextField";
import {Button} from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import GenericSelect from "./GenericSelect";
import {canUser} from "../App";
import {useLoginStore} from "../Login";
import {DataGridPro} from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";


export default function Acl() {
    const [loading, setLoading] = useState(false)
    const [acls, setAcls] = useState([])
    const {permissions} = useLoginStore()

    const [group, setGroup] = useState('')
    const [authority, setAuthority] = useState('')
    const [method, setMethod] = useState('')
    const [path, setPath] = useState('')

    const [disableDel, setDisableDel] = useState(true)
    const [disableAdd, setDisableAdd] = useState(true)

    const [groupEmails, setGroupEmails] = useState({})

    const gotAcls = dd => {
        setAcls(dd.map(d => {
            return {id: d.group + d.authority + d.method + d.path, ...d}
        }))
    }

    const fetchAcl = useApiCallback('GET', `/api/acl`, {setLoading, onDone: gotAcls})
    const fetchGroupEmails = useApiCallback('GET', `/api/groups`, {setLoading, onDone: setGroupEmails})
    const addAcl = useApiCallback('POST', `/api/acl`, {
        data: {group, authority, method, path},
        setLoading,
        onDone: fetchAcl
    })
    const removeAcl = useApiCallback('DELETE', `/api/acl`, {
        data: {group, authority, method, path},
        setLoading,
        onDone: fetchAcl
    })

    useEffect(fetchAcl, [])
    useEffect(fetchGroupEmails, [])

    const setSelected = (k) => {
        const id = k[0]
        acls.map(acl => {
            if (acl.id === id) {
                setGroup(acl.group)
                setAuthority(acl.authority)
                setMethod(acl.method)
                setPath(acl.path)
            }
        })
    }

    const [canSetAcl, setCanSetAcl] = useState(false)
    useEffect(() => {
        setCanSetAcl(canUser('POST', '/api/acl', permissions))
    }, [permissions])

    useEffect(() => {
        const id = group + authority + method + path
        const ids = acls.map(acl => acl.id)
        console.log('ID', id)
        console.log('IDS', ids)
        setDisableDel(ids.indexOf(id) === -1)
        setDisableAdd(ids.indexOf(id) > -1)
    }, [acls, method, path, group, authority])

    const styleFlex = useCallback(row => {
        console.log(row)
        const sx = {fontFamily: 'monospace'}
        const deselected = '#666'
        if (row.field === 'group' && group !== row.value) {
            sx.color = deselected
        }
        else if (row.field === 'authority' && authority !== row.value) {
            sx.color = deselected
        }
        else if (row.field === 'method' && method !== row.value) {
            sx.color = deselected
        }
        else if (row.field === 'path' && path !== row.value) {
            sx.color = deselected
        }
        return <Typography sx={sx}>{row.value}</Typography>
    })

    const getColumnDefinition = useCallback(() => {
        return [
            {field: 'group', headerName: 'Group', flex: 1, renderCell: styleFlex},
            {field: 'authority', headerName: 'Authority', flex: 1, renderCell: styleFlex},
            {field: 'method', headerName: 'Method', flex: 0.5, renderCell: styleFlex},
            {field: 'path', headerName: 'Path', flex: 1, renderCell: styleFlex},
        ]
    })

    return <Stack justifyContent="center" alignItems="start">
        {canSetAcl && <>
        <Grid container alignItems="center">
            <Grid item sx={3}>
                <GenericSelect
                    setValue={setGroup}
                    value={group}
                    label="Group Email"
                    options={Object.keys(groupEmails)}
                    disabled={loading}
                />
            </Grid>
            <Grid item sx={3}>
                <GenericSelect
                    setValue={setAuthority}
                    value={authority}
                    label="Authority"
                    options={['member', 'manager', 'owner']}
                    disabled={loading}
                />
            </Grid>
            <Grid item sx={3}>
                <GenericSelect
                    setValue={setMethod}
                    value={method}
                    label="Method"
                    options={['GET','POST','DELETE','PUT','*']}
                    disabled={loading}
                />
            </Grid>
            <Grid item sx={3}>
                <TextField
                    label="Path"
                    InputLabelProps={{shrink: true}}
                    value={path}
                    onChange={event => setPath(event.target.value.toLowerCase())}
                />
            </Grid>
            <Grid item sx={12} alignItems="center" justifyItems="center">
                <Button disabled={loading || disableAdd} onClick={() => addAcl()}>Add</Button>
                <Button disabled={loading || disableDel} onClick={() => removeAcl()}>Delete</Button>
            </Grid>
        </Grid>
        </>}
        <div style={{height: 'calc(100vh - 180px)', width: '100%'}}>
            <DataGridPro
                hideFooter={true}
                loading={loading}
                rows={acls}
                disableMultipleRowSelection={true}
                onRowSelectionModelChange={setSelected}
                columns={getColumnDefinition()}
                initialState={{
                    sorting: {
                        sortModel: [
                            {field:'group', sort:'asc'},
                            {field:'authority', sort:'asc'},
                            {field:'group', sort:'path'}
                        ],
                    }
                }}
            />
        </div>
    </Stack>
}
