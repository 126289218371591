import {useState, useEffect, useCallback} from "react"
import {useApiCallback} from "../utils/Api"
import Grid from '@mui/material/Grid';
import 'mapbox-gl/dist/mapbox-gl.css'
import {timeSince} from "../utils/time";
import {DataGridPro} from '@mui/x-data-grid-pro'
import useStatsStore from "../utils/useStatsStore"
import Typography from "@mui/material/Typography";
import GenericSelect from "./GenericSelect";
import {Button, LinearProgress, Modal} from "@mui/material";
import UploadModal from "./UploadModal";

export default function Uploads() {
    const [loading, setLoading] = useState(false)
    const {servertime} = useStatsStore()
    const [uploads, setUploads] = useState([])
    const [aircrafts, setAircrafts] = useState([])
    const [aircraft, setAircraft] = useState('')
    const [detail, setDetail] = useState(null)
    const [flights, setFlights] = useState([])

    const gotUploads = useCallback(dd => {
        const aircrafts = {}
        const uploads = dd.map(d => {
            aircrafts[d['aircraft']] = 1
            return {id: d['client']+'|'+d['contract']+'|'+d['filename'], ...d}
        })
        // sort uploads in ascending order of created_at time
        uploads.sort((x, y) => (x.created_at === y.created_at) ? 0 : (x.created_at < y.created_at ? -1 : 1))

        const flights = []
        const _1HOUR = 3600
        uploads.forEach(d => {
            let found = false
            flights.forEach(f => {
                if (found)
                    return
                if (d.client !== f.client || d.contract !== f.contract || d.aircraft !== f.aircraft)
                    return
                if (d.created_at >= f.start - _1HOUR && d.created_at <= f.end + _1HOUR) {
                    found = true
                    f.unverified += d.verified_at ? 0 : d.size
                    f.verified += d.verified_at ? d.size : 0
                    f.start = Math.min(f.start, d.created_at)
                    f.end = Math.max(f.end, d.created_at)
                    f.duration = f.end - f.start
                    d.flight = f
                    f.id = `${f.aircraft}|${f.client}|${f.contract}|${f.start}`
                    f.errors += d.errors ? 1 : 0
                }
            })
            if (found) return
            const f = {
                id: `${d.aircraft}|${d.client}|${d.contract}|${d.start}`,
                client: d.client,
                aircraft: d.aircraft,
                contract: d.contract,
                start: d.created_at,
                end: d.created_at,
                unverified: d.verified_at ? 0 : d.size,
                verified: d.verified_at ? d.size : 0,
                duration: 0,
                errors: d.errors ? 1 : 0
            }
            d.flight = f
            flights.push(f)
        })
        uploads.forEach(d => {d.flight = d.flight.id})
        setAircrafts(Object.keys(aircrafts))
        setUploads(uploads)
        setFlights(flights)
    }, [uploads, setUploads, setAircrafts])

    const fetchUploads = useApiCallback('GET', `/api/uploads/96h`, {setLoading, onDone: gotUploads})
    useEffect(() => {
        window.location.hostname === 'localhost.dea.aero' ? gotUploads(sampleData()) : fetchUploads()
    }, [Math.round(servertime / 30)])

    const renderAt = (params) => timeSince(servertime, params.value)
    const filesize = (params) => {
        let size = params.value
        if (size < 1024)
            return `${size} B`
        size = size >> 10
        if (size < 1024)
            return `${size} KB`
        size = size >> 10
        return `${size} MB`
    }
    const verifiedAt = (params) => {
        if (params.value) {
            return <Typography sx={{color:'#0c0', fontWeight:'bold'}}>{timeSince(servertime, params.value)}</Typography>
        }
        return <Typography sx={{color:'#f44'}}>unverified</Typography>
    }

    const inspect = useCallback(params => {
        return <Button sx={{margin:0, padding:0}} onClick={() => setDetail(params.row)}>🔎</Button>
    })

    const duration = (params) => timeSince(params.row.end, params.row.start)

    const verifiedPercent = (params) => {
        const percent = Math.round(100 * (params.row.verified / (params.row.verified+params.row.unverified)))
        const color = (percent >= 98) ? '#0c0' : (percent > 50 ? '#fa0' : '#f44')
        return <Typography sx={{color:color}}>{percent}%</Typography>
    }

    const errorsRender = (params) => {
        if (!params.value) return ''
        return <Typography sx={{color:'#f44', fontWeight:'bold'}}>{params.value} errors</Typography>
    }

    const getFlightsColumnDefinition = useCallback(() => {
        return [
            {field: 'client', headerName: 'Client', flex: 1, },
            {field: 'aircraft', headerName: 'Aircraft', flex: 1, },
            {field: 'contract', headerName: 'Contract', flex: 1, },
            {field: 'start', headerName: 'Start', flex: 1.6, renderCell: (params) => (new Date(params.value * 1000)).toISOString()},
            {field: 'end', headerName: 'End', flex: 1, renderCell: (params) => timeSince(servertime, params.value)},
            {field: 'duration', headerName: 'Duration', flex: 1, renderCell: duration},
            {field: 'errors', headerName: 'Errors', flex: 1, renderCell: errorsRender},
            {field: 'verified', headerName: 'Verified', flex: 1,  renderCell: verifiedPercent},
        ]
    })

    const getColumnDefinition = useCallback(() => {
        return [
            {field: 'flight', headerName: 'Flight', flex: 0},
            {field: 'client', headerName: 'Client', flex: 0.7},
            {field: 'aircraft', headerName: 'Aircraft', flex: 0.7},
            {field: 'contract', headerName: 'Contract', flex: 0.7},
            {field: 'open', headerName: 'Inspect', flex: 0.5, renderCell: inspect},
            {field: 'filename', headerName: 'Filename', flex: 1.4},
            {field: 'size', headerName: 'Size', flex: 0.5, renderCell: filesize},
            {field: 'created_at', headerName: 'Created', flex: 1, renderCell: (params) => (new Date(params.value * 1000)).toISOString()},
            {field: 'verified_at', headerName: 'Verified', flex: 1, renderCell: verifiedAt},
            {field: 'errors', headerName: 'Errors', flex: 1},
        ]
    })

    const [selected, setSelected] = useState([])
    const [filterModel, setFilterModel] = useState({items:[]})
    useEffect(() => {
        console.log('SELECTED', selected)
        setFilterModel({
            items: selected ? [{field: 'flight', operator: 'equals', value: selected[0]}] : []
        })
    }, [selected, setFilterModel])

    return <div style={{height: 'calc(100vh - 200px)', width: '100%'}}>
        {detail && <UploadModal detail={detail} onClose={() => setDetail(null)}/>}
        <Typography variant="h5">Flights (within last 96h)</Typography>
        <DataGridPro
            sx={{height:'50%'}}
            hideFooter={true}
            loading={loading}
            onRowSelectionModelChange={setSelected}
            rows={flights}
            disableMultipleRowSelection={true}
            columns={getFlightsColumnDefinition()}
            initialState={{
                columns: {
                  columnVisibilityModel: {
                    end: false,
                  },
                },
                sorting: {
                    sortModel: [{field: 'start', sort: 'desc'}],
                },
            }}
        />
        {selected.length ? <Typography variant="h5">Files collected on the selected flight</Typography> : ''}
        {selected.length ? <DataGridPro
            sx={{height:'50%'}}
            hideFooter={true}
            loading={loading}
            rows={uploads}
            disableMultipleRowSelection={true}
            columns={getColumnDefinition()}
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            initialState={{
                columns: {
                  columnVisibilityModel: {
                    flight: false,
                      client: false,
                      aircraft: false,
                      contract: false,
                  },
                },
                sorting: {
                    sortModel: [{field: 'filename', sort: 'desc'}],
                },
            }}
        /> : ''}
    </div>
}


function sampleData() {
    return [
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698752179,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "WGGNJYHmJmWXDEfV/KLEWafiNg2ZugUlteS2wLi2Rs0n4KYrtMi2qMo4fmptQP+XDceeyzzFF+GHOvWB9uG25A0BIFuYvWIFne9tuwBWuvE0KtdkiE3m8V6JXmnQdA3JYbWLeFROTk8XFdrepz5m8//Lneiid2z/zwticudDl0ztDb1+mXYKCaeAvOxxi40f3SLcjG3lU1dggfauvZlFS1RheZugWg1hbArm/QyvCBLPuMHI8u4xubnZH94/dp4ZR8VXLP9SkjF/zuUYg7jutrAnOZ9BXLOUH0cjfjuHz2fD1N6ABAnUmgGH4hMIwrhAEpgdtYPs42KjHK5yp4eCfFU6MkOm0igDzbVCvZhjHUTeK99AiNjD/1njG/dYk32+4LMM1iTiD++Qe6VbZTaJ2cFWqNDQlwGTctWc6pmDklrl37Hqp2KMu5z5CR16yZDnxtDT2s/gStHpeiYW5MT4oim+rbSamb9LMxbRXbvXhwTZIc6JMS7Eb/QgjmnZQu1AmKggaxPpNVJ/5WxIKtpBLuh27V8Qpdjg1fWJMy0r22s/xJu+UB73rNbtc8WHOMvFPenHKmQ2/5+99jHVFzCYbZqP8KfjHaxPC6GgiGmLpPMAfwPW5+uAz3DdUP0Tjv0rN9FMHipckDcU/b21EjF/oeNFZm5pUyBRjTgbCyzSoDg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-36-16Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698752050.0497909,
                        "modified": 1698752050.0497909,
                        "name": "/dev/shm/incoming/2023-10-31T11:24:09.ais",
                        "sha256": "0ea21d816d84045275d3db68914ff29f363c0948d5b7cee6e8bf426e63c946d3",
                        "size": 407384
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "a53c8e15b857bc9e747d8378fd282d293939049113ec5a18f973a0eaf93d417f",
                "size": 110020
            },
            "errors": "",
            "filename": "2023-10-31T11-36-16Z",
            "size": 110020,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698574307,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "i2JbjUluYLZi++djboTq1v+0zU89zzKHvc3bSHPiLkVaaV6/LK3uIOelUaVKnOz7sO/m5yN/NKC1SOLQ/PC3HsTfxEiAsrUCdNaJcffiPdrRFWeOg8xovlLPZ0ZlLwF/QP9yKdPaxXCcHs2dFBmNSq3XGhiShDqv27mnX07M1Jo6SKnfY5haDzJK1cVpMQaiqynYpW6LwAxqBUV695RoHMdMSsgghZNP0iyPClNdwqFkrC0Gu4D1WhYbCVoW7ivrmdzqoHhYUtqVdSBbXxn1+85CpbjihnAp1SqnWTAuh5vDy7gLj0CPNjVj0Q1Ldck7/j3AkHsJeMNQrkzOlY+oBnrAxFBbJklYjYvRO/W31kzK9qgeFzaQmcOo2IQRqI1GQZ/k4FvAR+RoYpIbtXoN0V1QF18BNdo+jOId1OEoyVreeUQ4a1wmcSVWiMVg//yv3wXkWfS4MrgQnfl25W6HFBIDQpmDmw1BQoO0EsIWjgl8PweTiL0SQkQ49McL7X+eBXFHD1OALu7aoUul1puXgz0JO45flVurG05/zvqEnyDxXwVSCXHJcYJx5B/QM6KwbTpE86zywMu33SVXWm2XBgrgG+5+s1o8K/9zrhXmsQkvoOxeCZd3yqNSrLx+T1MD3i/5nNExirVy/QfB6nxNLoUZ3OAtVacaFjAI6TNRUHU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T10-11-00Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698574196.383234,
                        "modified": 1698574196.383234,
                        "name": "/dev/shm/incoming/2023-10-29T09:59:51.ais",
                        "sha256": "3b6d1bb891f1959294550fb881406363ae0e7a30a0c5cc7270963bcc664569f9",
                        "size": 217522
                    },
                    {
                        "created": 1698574251.553234,
                        "modified": 1698574251.553234,
                        "name": "/dev/shm/incoming/2023-10-29T09:40:51.ts",
                        "sha256": "a0c1aff05c6fa6a48ef08f1f268ff93acb7ab39ae7497dcca172ac890d94b97f",
                        "size": 302562500
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "c9173c92430bd790f3f5c497b239fd0c3d3efe74f9d10b877e735ae0d81eb015",
                "size": 258692932
            },
            "errors": "",
            "filename": "2023-10-29T10-11-00Z",
            "size": 258692932,
            "verified_at": 1698596236
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698579203,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "LLH3uvDj+FYKu2A9LZ3eqsaDIuSxNcvwsqKy4ZgAoCfEOIl7bRk/zZWYfrWgiIqn7wC7HI/g7hdicW1h00H9weikuwXLnjNIkSrWHGPxosDiaQ1f2Xa78n93g0V112cBNzrmLAKx51lHCX8iwMRvsQC+VFclsrGIrrMhU3wZHwN5DomRWyvSkHfrXv3IVrRTVs4M+EvZ3A9xrx655evZKNMFKRmiauA35I8h0Pe8v9SBGn7RpwPirvRT2bCGMOlXVIQ0OUaoo/SwakSiuizLKiSnoUxdYLEJiat4ro0L2gcBxK2bQUzoBzkM2Z29ik1zpGWIybnjdNGPRq23wbynj2ENMlNx+6XugWlKAup535OYA5huWXJQdxU+Oa9R9SloWdjB1urtEo26NUKGsDf2KCYOFNFIAz/IJWQfpk7OAAvOe2CBuiIvvx1wXeaooS+8Orp/Lcp3NtJpSsA3JPx0lR2ZzkCflSsYviIJirJyLQi3Ilgjtgxe7rlDdIvttDIQuHGj/EDhRGaOgvIBbt2eNy1bCl0bnOjKDr+/5HU6QGWP/VNXuUDitbIuRCc5LywFFJj7/5jZTxU6wvdPBN6dEBDriu91HZjBEYwPpyAdD96Yuemo/1pE26AKfpOd5dk08j5QhE1DhXXSEKh9OTVw36Z9YXwBeHXGPVKZz/Gu/UA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T11-32-34Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698579026.2732341,
                        "modified": 1698579026.2732341,
                        "name": "/dev/shm/incoming/2023-10-29T11:20:24.ais",
                        "sha256": "388570ad3bf003e8a76bca7dcb0eb3752d67bbd227da1c6c8da14455f3926787",
                        "size": 206477
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "8cc4bf55893a882cc663240304b00b2622c526ec37692443937305cc585de64e",
                "size": 60646
            },
            "errors": "",
            "filename": "2023-10-29T11-32-34Z",
            "size": 60646,
            "verified_at": 1698596321
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698752777,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "J6uu0mVKluM5jYTrB3rvU/bmoHKvi64vUz0ix9yfebv/3BIeMSmju/C/00T8dO6Bq+n6VTAm5GsGHXzojj8r/EkR1R1sbTP3hxqByRF1H1og1nEr8+DChOpzxmhMXFcjRT85A1HAnh0dx1xTGS39ITgEx2hnHLwa0p2s+q/XVPnr56JrXTy+zIHYxl6BurqNod+hM6PASABAueJaOWBnTXd5HJtZOkVunpB22DOfzK7J+zwjk8giSDUfxfFmSSMPHgSLrMxuyqrGN+UGSPFpDQlJukqFaj7yluyXfbncn6dtha0kd+zfu51uVGdJt9x478ldfP472nKkHai6PkF2y0tPLZWd6/dGJEnpIYzdnyM9nGFYQwzvFCTpW6eoTeNMDJqdDmfZtaqh0GtUhkS9CMkfckuQE5q/cp6FP/jo5XOu4q+Tp/AAV7wLuT85LQ8tk8704vA1m4supnJe0BnXNDK/DemdGHN1Cm8JA95Cdg+6rJ3xA6aYHOULgl44HfPqDLka5M2HyA3dBaSRRIIwW8pAIqPe91QUxqaCoptRIHSWlhUGgTqhBVRdhmDwK40FWY3/T/YSaI8zrurWD45sLxTqiCjZQNCKbDLsAs+BtVWkxb213rjnWoRb57kzp1h/hvIqMEKi4hyBjxhzROxV+VuKi3/31VIIQgFbbGvRzQo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-46-14Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698752651.0497909,
                        "modified": 1698752651.0497909,
                        "name": "/dev/shm/incoming/2023-10-31T11:34:10.ais",
                        "sha256": "c9f49e98754ad2ec3cefda206ef6de9a7c8e9636d2eea6db67af2781afb167b2",
                        "size": 331577
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "b95661d2dd1d2e3f651c58f89e55f3c1e3e9bec37e17695492f28598afb3b9d4",
                "size": 91862
            },
            "errors": "",
            "filename": "2023-10-31T11-46-14Z",
            "size": 91862,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698753381,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "WZ7ml8jjVbJ8rT+g6qEDHDtduSc/Owaw7xofMYtgzJ2RgJ6qE4/qCS8B73nfR5NlbFvSz4OaRdCU5MLkiVKDg4rdmN12qWFHXca0D1QkC4r5yebNDILZmZbgaOvlr1gSzR6dzx94u1m0s7RfKfbEqt1pdbGDEBbBIO97cPuaRzTGnvu22ZkTTgo3pcOkc1padurTSEQ/QwIZAnJ1oFbnvZqY5Enyh4W/uHYyjZ0Hyr3sji0mE/YB304WtHLY7zCAcBa0kOI+EjcUQMTLDQRRxqVrVQEFggbYoM3TdjltOgAGmwe9MzXlGDcBMHwUqnLZcSdFDCsfPrNakfD/iGwmxYPzWyEaBsNnF7Z3+ZlUZtAHqxh3uvUobm2cDE81ZrN9bH3tqVnWKG5FZRLrNSYAXa2Z12MDaqKu061oWVjoQXcSoppMfmkcFg8pdcLKIjx1GLrD6a7B2HOVpfiHy+u+OnUU8yp2FOykh8QBr0T4Ci1WMdN4RSpn1Vfx0ovSUa+TwposOKwe3kEUrbcuFjW4oSIy0v+n78YrhnsVJDLp8HkuY2JAG2K3jsl07pyAhbL32cudMGsPcPyIj1Iy1cNC4gvi/1+4PstwGWfP+75rqaD+HfoVePL7OSLeP2EQ5kssV8PHw5WOYGd3lAoXWhJmbxJk3nFVdeeiiCBfqrDh9Ug=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-56-18Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698753252.0597908,
                        "modified": 1698753252.0597908,
                        "name": "/dev/shm/incoming/2023-10-31T11:44:11.ais",
                        "sha256": "1a7ab175b244c10d8b1f96351b4599f7d2b9f6aca76950cc315f9e759ef63714",
                        "size": 308522
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "9ef805a7b879ef82ce7f6f76edeb8597fbb5ab661512fdcb57470b6c245160d8",
                "size": 89096
            },
            "errors": "",
            "filename": "2023-10-31T11-56-18Z",
            "size": 89096,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698754584,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "h/MVOsdt5vTd/83Mgprup8KK91CcwhmYVdOOaXjfa7/9k3L+7eQHpyXv76REXyO2RUv4YNNOe3/wJYCSN0YcHPR9hVxV7pxNZc5tkIZiSec10kFU8+XDU+ee4TzfA6zj9FQfMBg5UFwSp4dcUENi797Nl7wb6FGoSPOtvc9TvYnWP1/rNFZifcE8cAoGExEcxHgZTKb6fB7WRy6eU/FT1gnMQ6TfTGna4935n1lktB55S7WkPEX3Nhzh7SwNbd/SVEyeBVfXPjf8W/PE00JZmVry+VCyRxdgG0rUQA8IgK1GJY6lv8kzheelG6KMVagkESn/1Tb1UZXF1SxkN2tn5eFm8KeVxlUF+UbGEz+CDxJjrPet5py1l/Sl7dxiO0TW0VD/PcPVYi6OVn8PWmjxk1Ahe0B2UyU6IL/AsQfSw3LKWcbpGECQO8iJwtE7sq7qAVPXB5pSmi5whjlNGtK560S9aQ0fQ6ef2rh6mRzaAgBnsy76eTwf4Bz4m+yJzwI6jk2lEsuhSsp+5G+5M4RXYHO7T4cL+k4S6isM8lzErbAxilXmlcSjLvMBZIttrHq8Px2RYLBxar2rVuVw2gOlYYRW0WvFzhvPNYyQlRDpEm+R7EzJ7Wt0EC5oGEyQ5QYiFrhjh34qvpxBpAf2BwHa5EaLxM7TfJq06Hjv5sTRa10=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-16-20Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698754454.0697908,
                        "modified": 1698754454.0697908,
                        "name": "/dev/shm/incoming/2023-10-31T12:04:13.ais",
                        "sha256": "25c56a29930bc1c73b4882e44b6fbb134d20c5adbc76100b30de7c954657766e",
                        "size": 370319
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "744d56c269a3f98636202508cbe17b7b6246e63954d018c571b0d0632b954c6c",
                "size": 111910
            },
            "errors": "",
            "filename": "2023-10-31T12-16-20Z",
            "size": 111910,
            "verified_at": null
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698677570,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "cXRDZs1fCqDcAm9pCSdHk/dT3tqE6bya0/K0894Ll8yXMgg52VdbxsIEgtDnMwnTV7xJvAZjs+CUU3fQBM29QMnOKuU9ccU3aJ2haQ/KIjMDd/TgcT+ZnYkToLZtosSJE/23e1qMrF1mWYnWTrDEcpqhrbjde+3Ff+S2L3QSZla+j+v8//5Kd/tl8RRerBgxqgaL4nj20nSWqcxD4lSzDX8RmJxxeSuKvzJzHZlDRWROCrX5f4q4MEas1h35f9pyiC3YHM5qAUgkYwSVqwk4BPvoU3iEZOasgw5j51OjTAqcapREjjJ6CEYjj1e6vH+GzDmKQIcDQhA9Wxt/Jf4ko18l2mHxPvv3gNhK8rp3+2bwzl7cRYoCrLBuI+fscbTDOy7j9mSHgZNYui/AWcgImk3RRZybFStVHB+DnZ0LRNx6a2gMKxy8OutHfgUjigpsLVzcX5VApJ8uHF3IGgTCsqM5qwGvAYscQ66OyIw6GifGJCq1aS2Jh2z0uSISGEtE8rn7gGAitzwZjWw7v7uSzoktkCUmZwqegVo9HhZ04+cdxqimaiTvPvHeWxWicX7Tw6Ey7P7DW+G1SgebmEZ9EkmIfnRUDa6rWvPXRvLkpAAp3Tb6VSGhJw11RFhxBhtq/FXgB19itFNVmLn7RcdZwM6V+ca4O9L7tdB07oMyZsg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T14-51-43Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698677496.3386981,
                        "modified": 1698677496.3386981,
                        "name": "/dev/shm/incoming/2023-10-30T14:21:35.ts",
                        "sha256": "587d4628d02ace21ff113777f9e1a1bbfd4957c0395e0d4cdb0b2783d07427c4",
                        "size": 410791280
                    }
                ],
                "mission": "OSP2",
                "sha256": "e749194f9943df86a754fd3c87878fc29d226402251b29b0077b738c590b8bad",
                "size": 396610392
            },
            "errors": "",
            "filename": "2023-10-30T14-51-43Z",
            "size": 396610392,
            "verified_at": 1698753454
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698753980,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "RpnQUyyplOtscJhASeunUHPdEfoAZMqVyegfNch3cAYmshaMkAFNJe3tumF655Kn2SfPI88k90h5qR4I49E4gNfiU7UjMqwlGhVUoaAnTTh/thByOHXkgcYsaQjZQ8zdSHcIae62SaF7M9jswnUA/X6foUAqKwtnYTGDv6WQK8tAef80BBjYQWp8J1xI9LwrvJqh7OBUolIZsnRSz1xfvyAsawBi1rv8eg/WrNaqv2si8L5fUYFC/qX5aMc01svnyU4L3GH0L85gjHWLroNuWzXZm4d1+C4bP/IElcYcn5ptReAKeguGgZj6B9C8loHCLpRiMHD+ZSyLskLiookYDJ9FFv7zS/YVPCdeIENLogS2TlvwQkh7CEDyZx6Yk7z3Ib1e0VKi/Mte/glLrgEl1WLLZfI03ALu0hH4v5sMD4sK4NyuYp0R2CAloN6vLo0ZUz2stGkz/BqTaOxzNFDOlYhiLwW9IWTZuq9u+OFNeYgnUUSUdWn+Vl/jbNkO2/hR0aGiLNFf20Wrl1FHka/ksjygV+4Fn8xDw3iaLOgAilWZFNFKAHAs4T57J0cU/zkoQrHxVyUlLchIEGmHZmRM7SwXwvGijPRtCqU1Qob5L6Gwnl+R4QxesGcdDDqSm64VJ2jGhGImUsBMcg1m9zWV1iIuYYuicw7ZjlsYzha7/b8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-06-16Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698753853.149791,
                        "modified": 1698753853.149791,
                        "name": "/dev/shm/incoming/2023-10-31T11:54:12.ais",
                        "sha256": "ea6b550686fcf1e5fe355d355f9e60e0fba40c98357cb414806cbf8f42405cb2",
                        "size": 380487
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "82cbd5363541257d858c7e4049a87863ff823bc5df02b5639e59c3b5684eb9fa",
                "size": 115293
            },
            "errors": "",
            "filename": "2023-10-31T12-06-16Z",
            "size": 115293,
            "verified_at": null
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698683420,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "q+4T7LrrANToBjRE5MCkfRwmXNVkSYtJir42w14O8d67nm733AuzsCLoY+/I1NXh4TdD/zuksA8omX5L53W5EogNBoFAMV56SpwHxCtq57ucre/gxUzgrrRPANJDK+H2/vUnevCa8FdHs9SOpVS7fmYMZMCiewY1hQWXcwcosCjz/p38WQxL5vA7C2ziASsXb65wwKP9jkypTSLODT6c0/V7+8cUpFc90StldmqsVkROePtP2MXEuBQRH0e70rL57kuap+B9FkSpacXEv82Sl0F8jl7yRFP1eUIFEBIEZQIE2AjTlDuSoOHYyXpcOHcWEcrcrOKaP/rl84DkLW1Qqq6TCzj+P3n2ErRuJZxLpGRROR0Xp2nDhqtO2Gy1qRBKtJ2oqciBrk3SQc6Yvb9crPjTQ1SfuYXGgBfAMODF4lXfHKDhuWHgS3JMImdWZOtktxMz3h3jKdyWmWuWsUFPTuG5b2pIXOgWyZstm+wt12AnmHw3aDd69qCM9sLKapKAHcGO1ZQ/YGhj1D2gsRBGreRMdfogwFfDQsFZ/m0gXC984vFnJfpMiVFB9a6uymtehYnJ8oijCs3NGdPYMDj1n28HEe6FNml1XA4mjbL8u/g1rJ5kqxTTYLbRw+0t+Z0znd4C1SssJEJEdgaw61JXepRT999WQtWxNq5tFk2cREw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T16-30-02Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698683274.888698,
                        "modified": 1698683274.888698,
                        "name": "/dev/shm/incoming/2023-10-30T16:17:51.ais",
                        "sha256": "c7e85c50a1fdf8224804f7e969ac054af7cde44cb2d08c5a8bf14d391b726544",
                        "size": 34982
                    }
                ],
                "mission": "OSP2",
                "sha256": "f9d02942bacb78d628ee21495c2cb853edc984f91fb5f35c460b6c84d5ad631f",
                "size": 9372
            },
            "errors": "",
            "filename": "2023-10-30T16-30-02Z",
            "size": 9372,
            "verified_at": 1698753537
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698691731,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "B6bvPDCBe1PukHsVmxEq/WBi1yMM7mu8pWCxQCC35i5+IkDmk9M03ygMImh+IaojLWGT/Xiuu6PGMXymwhzNxa/Yu4R8aQ76q5hI9SttMofOhu7/RlAp3zghQA2XLlhBJOZtsHxJ8jHOK229E4tDzgvykJ/oXl2iIMuZPGIXcRKxB6P9VJam7fBEQwLTq4/kSDkO2lqopKVbWo5I9/pG+VkzghrsNLrelxDUbASMEBnX0vGnqc6GkDxf2/4honpwJeliJ+Ua2EhqTC+BtyeP6kNXhYvzwMB/4ixoG7rh6f821Sa/J2uvpiyqo6+OQ5dtf1NpxZkeHRqAYMODMjczfCdH6mLay5RpV7FzKbUXoJJFt1hyav6LMG5HCKSkR1gRLenMwAKjWKnvdUJqBeWQFc/t7Ddm98+LhZypiXoUuLCaXKeOj4ruCA92fJqp3jxFBwCG29rP5DIaQGdVjmL3zRvaCAh4HmRdxFSW6PsMZT38Jky3tWVyIrapW0MeFoWOdMlChTK+OkscCu4tsLvYJcoIiieDcBbm40AbNPXIBOZRXRNOAvmS8+qCUDXp4h6W/2DCUcAJpBGqu2GFB6vObSXTjfZMf+Wu/yRJqX72Hyt1Wsp7AwQFIXZXvLbCKvIDrcokX45nKt76wMy2znhvOiyN9hVH9ed0riu112TDxtk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T18-48-02Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698691675.378698,
                        "modified": 1698691675.378698,
                        "name": "/dev/shm/incoming/2023-10-30T18:22:28.ts",
                        "sha256": "9bf676ffae251f5a8f53b4beda697ec28c11d39cfa7fc78afaf73916cce97ffd",
                        "size": 348299516
                    }
                ],
                "mission": "OSP2",
                "sha256": "9bcfe91f869fa33e560dccccf47897434672304a23f48db18b73f063a97ef4bc",
                "size": 336452298
            },
            "errors": "",
            "filename": "2023-10-30T18-48-02Z",
            "size": 336452298,
            "verified_at": 1698754624
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698675564,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "uS2GSbs2LXAiuya0ARtg4KDQzMszGzdWV5GWIRxB6aRe6nqNjDlEokQPoNGM1gm5Di93n8/x4UviU9KrGFCNyy1hIZ38uMwz+nqMeQR8ykHJ6PfQqcKCrNiXU66b106HJegGE4x48N66/Vm6hTsg/VQ5IGHeQp0bXUkk5Cccda66I+c0G/O5SZvSrGlnkaY1GueEcOp+ski8hIVyGvzeNvHPgJh3YTLhTFPUwbJN8Mka1/hLHk69rCHso2v7dq7p2DvufgTBGAJLWg1gvKUBU862B9ONWUMTJf5kN2saWZA+RuO4K6LiQBOm96FqoTQzYjS4bf5oaBjru7kGxvTIm54Es2cEbR2eeyBUR4sbFk7vbrOcZ3eJww19PchHuKAlYAK42GWfN00aTqWMd1T2zQGrmPC8tFaYq+/43S+Es/84nXdCtz6tdwqoD4Gms7Nyl/+MLiISaXzfvjDSILK3bUTF0IZWixJPy08BwDXRA2+vGj6xeNVxIOR2e51oNsg4ls6+N7dBb0Adhmdkwa2C5t77FPBp7G6xpQyKQ2x9AGKzvad64DJlz2hvKbquoNNkwDDj+sRE2wzwBEGpLKuPdX8TCgnRAR/FbiXM9/TFxofJFgHmNCcRUlarXf6rAkRi3q8WEsbmNY6AeV2w8UhL5yp2MsyC+IyzumZq5oC3xJQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T14-19-11Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698675427.5886981,
                        "modified": 1698675427.5886981,
                        "name": "/dev/shm/incoming/2023-10-30T14:07:06.ais",
                        "sha256": "683d213b65d121eb6ecc77e2d800c4cb9724764af29cf36f22e1f638e85bf7d1",
                        "size": 88228
                    }
                ],
                "mission": "OSP2",
                "sha256": "1232ea831922e44c95bf8853ba6fd4141ebc6f4377d8d70a1e7a0a6a7e8a7dd9",
                "size": 25372
            },
            "errors": "",
            "filename": "2023-10-30T14-19-11Z",
            "size": 25372,
            "verified_at": 1698753097
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698684019,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "b30ZogAt/UGvkD9ynXcgYWi1JZxLUCoZukaher2pFpap73MUl35O6o4Jf8Vx2QgX875iYB7IVXSsZjXX61qCibBFr9Qi36vwvs5nPzFBhY8bZUZ6XUzTjqv1G/K7a5eI8u0XvC6j0ibAnkqc2ijKNrq0ivp14G/ha0zBM2GuWpceZ8L4KFP1sArA7jp7z31WpTB7DnAxYhXHGnch6v4sJOIBi8RzwbJj7b4/hEDCE1RYLpTM+5lkVjiOvxgON3ZdzUo/9v6ahk93hw9dKf7V91UiXadtolH6iOCVVuicPQmlWL1V5KwskmYHQr/o1/GWiybQacA6c1xruaj1sZa2IblEjfu+rfiO1GKuAixbGewzD+I/kPrjqmq+DKRX8t4LlqYc2sVbSylsFYzCB/pU53yrgVg4fY8oS8aWpYKPzpttiLK9MREoaXsXGnahaTa8s/FyALveGxlMpoYX6hykLnfzI10gW8EARSx14kJUvnok39NCN9O+LJ1T/0kd0Xcu/oEhf8GmF9eCS2lc8YV7Fk9stkd9d6SR2lw3HSkKURil96cllKfupdAmngSJ0AMvHHBMSrE/rMTXQQbhVRjyev2sq7fbe+jN1UDniK8njH8/1m3a9WexQAOkuGFPPQ2RqOiCa6LSWenhOVItMM9N1eE1I61XIB6ExT8oN5/qZXw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T16-40-00Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698683877.5086982,
                        "modified": 1698683877.5086982,
                        "name": "/dev/shm/incoming/2023-10-30T16:27:54.ais",
                        "sha256": "ec937312ceb2162f4b29fcc5a5663048506d7c21b2ee95827cea5e090a1848f1",
                        "size": 40871
                    }
                ],
                "mission": "OSP2",
                "sha256": "bbca120f6d5c7e62d56bfb91ced14ac4314365d0002b9d87bb1ba8c9547a413a",
                "size": 10501
            },
            "errors": "",
            "filename": "2023-10-30T16-40-00Z",
            "size": 10501,
            "verified_at": 1698753590
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698677988,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ZtiN6yx4NULGr9SYN/Zo+2PldjEQ7zumB5jp5F6TenI01RKxtCdvTx3In5E26mrsbLJZh93E9Gpj5swAV43V4iPg0N3LJbcGkP7lShQufHshFteXqViruLSqikOlRfXJSqWWQRYpBNEqZjapTdGMaeI/vtHQw455tXzAq5XaJW/wu46Zetv69TqcVwkDA51FwuxXgcLKB5rQPt4C/o3q37WlA1cNkATAlc/GBBSgMH2eDNSfsQeUQ0nXSHPKxwoXyRMvfeE4MJgqaB++Kax2UKsb/aQfQM7y3PNupWWZ+yKG/pN6lsLc2RxPysfXs5iLEn5QcmD26MFw39yc5Q7qgM7m9GEQpq1iRJkRYn5boHbAsBg3z9+p/tyC3upkYB+l8iISlFnI/beN5ilDvPJmImT5HuIsOsxD4ClyiVT2LP4jUxQn2NNLPeNTj6rHlWrs5aF7zVYGBF9lKmkhxKKeA65AwYmsNSoG5BDRGOpd0I+kVDU+cLAy9pPMAflGOPv5Usqiwi1R5o9JN/NIith1M9VosOfUbhHxTmvDGWUEHk0Oy5K7ORI7UwH04w4ZHjeo+M6tVblujn1x8vSxTGKnT7VQLf7TnQ/nrMfMCUJVO+oFYlPboHjZEsuTxM+bHqZWuDUwY0flhbf7VaCJGKFeCkx06SZIs5qjDPukXMPx8sw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T14-59-30Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698677841.2586982,
                        "modified": 1698677841.2586982,
                        "name": "/dev/shm/incoming/2023-10-30T14:47:17.ais",
                        "sha256": "ba5f63dc37d360df7c6fa9ec08fe61eb4d3dc385514b776809decbbded45eac6",
                        "size": 91823
                    }
                ],
                "mission": "OSP2",
                "sha256": "6dd6c199f0bc3b3aef31afc035ab0dc2f3f60b00122ca5cddf48790c412b6021",
                "size": 24160
            },
            "errors": "",
            "filename": "2023-10-30T14-59-30Z",
            "size": 24160,
            "verified_at": 1698753107
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698505591,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "qQRkDfaAoc7Mx3OybEW4EbIy+CzhpO77MT695h0BFnyMVjltImN44SjcJj9rmWc9NGpT2TYbzWNOtZohPhp9TACEHhue1WnvdKiwg19mrS48Kpeykz6JUZ2TWMdGGNQogcbFvrKLQvthGZ8+umcKXJH+0QuGxcGkvpcfEt1ZuXriEJBe1wZ+zPewDj+sJHqFsirhdf2opn1mfWdkh5kf516BabX+gSpk9ZVADPR8QOjqagorTt5Z7qpI0S9ME55KP24CzF7RqRu0UarB2a74YuN7H8H/5GzX1JDkQ7vuUTizuEaPKFDC87LTPSdTx07d+jlYSL78en/0Rs7e1BzhMEyFot6ViEiHUaa89Mj1AfLeUH545amyW+6AxH8w6KEugIhW6+Lq53qX6gqwHCnbxinNumfIGlXuM6A2vpIU66Wf8XNhiJoa1rVH/N3+mA/eOi8bhv4SyIZ7Sw0w/0gU2QiuUqbQ+Roi8ZBWumwDjB2sNCNKhlww6tMOyGSfdqkBMn5KijUUYPVDy+UzBqLgq/02qFjnIg/9mkmNXS3oHx6ndGHwbTFtrVW8PtgM8QtYA8GaeBMJTJw233H4SPSiFhYzPrlwJcRWg59hALWWeg3jMdXswyIHGbEkHuEtKIfx+s0FqYil59G9pt9UYnthr8GscobUwOPo8NWLUWh7PrE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T15-05-08Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698505504.8528728,
                        "modified": 1698505504.8528728,
                        "name": "/dev/shm/incoming/2023_10_28_150439_00_00_V_056.txt",
                        "sha256": "252d492cd880b17e343e0a58b6af0a89741fd3039f0f0dbd77f3322ae4d8cccd",
                        "size": 2033
                    },
                    {
                        "created": 1698505505.1828728,
                        "modified": 1698505505.1828728,
                        "name": "/dev/shm/incoming/2023_10_28_150439_00_00_V_056.kml",
                        "sha256": "a5cc856c626c9279cd3818530d0062bedbf84a6bc47c81cf7ce644cdeb5e8c51",
                        "size": 976
                    },
                    {
                        "created": 1698505505.032873,
                        "modified": 1698505505.032873,
                        "name": "/dev/shm/incoming/2023_10_28_150439_00_00_V_056.jpg",
                        "sha256": "9c5ecc450860615d8521424ca030196dc85fd5d6c1f12a5ebdb8c1a255eda6bd",
                        "size": 771787
                    },
                    {
                        "created": 1698505444.9928727,
                        "modified": 1698505444.9928727,
                        "name": "/dev/shm/incoming/2023_10_28_150330_00_00_V_055.txt",
                        "sha256": "58d577be81b83ff13ae0f8c0c114cead18ebf4a1e27f1e7388426d5d5c24b252",
                        "size": 2032
                    },
                    {
                        "created": 1698505439.4428728,
                        "modified": 1698505439.4428728,
                        "name": "/dev/shm/incoming/2023_10_28_150330_00_00_V_055.kml",
                        "sha256": "40e585d19b8a9856af4c7d7b0736a2b39f4be9f3ccc1bc3daecdef761511f508",
                        "size": 976
                    },
                    {
                        "created": 1698505435.2428727,
                        "modified": 1698505435.2428727,
                        "name": "/dev/shm/incoming/2023_10_28_150330_00_00_V_055.jpg",
                        "sha256": "6ea57ef7078fca8c2fe380db7ccf1d702c8ef5950ef9bbc4bb518a1aacf48e06",
                        "size": 979241
                    },
                    {
                        "created": 1698505446.2328727,
                        "modified": 1698505446.2328727,
                        "name": "/dev/shm/incoming/2023_10_28_150321_00_00_V_054.txt",
                        "sha256": "a1e96aa13b9d3c81e568bd4895994b84e508e6aca77329b94979b5cd847ce87e",
                        "size": 2033
                    },
                    {
                        "created": 1698505433.042873,
                        "modified": 1698505433.042873,
                        "name": "/dev/shm/incoming/2023_10_28_150321_00_00_V_054.kml",
                        "sha256": "6b85f1fef0fe08671c13d3ad00a95633c77c5a33c47756964f54991fb6fa73de",
                        "size": 980
                    },
                    {
                        "created": 1698505429.532873,
                        "modified": 1698505429.532873,
                        "name": "/dev/shm/incoming/2023_10_28_150321_00_00_V_054.jpg",
                        "sha256": "1da386337ea9e3471517d2f5fdf396c9372f9b5d5d9e864ed3e4b910c3040c86",
                        "size": 778375
                    },
                    {
                        "created": 1698505383.782873,
                        "modified": 1698505383.782873,
                        "name": "/dev/shm/incoming/2023_10_28_150238_00_00_V_053.txt",
                        "sha256": "d24cc8574b7729897ad79190ca806de2d5dbfd007054c0d72dbb4f83eeb2bee4",
                        "size": 2032
                    },
                    {
                        "created": 1698505383.6128728,
                        "modified": 1698505383.6128728,
                        "name": "/dev/shm/incoming/2023_10_28_150238_00_00_V_053.kml",
                        "sha256": "6b707c44f1eeae211d1d95824d23d2b0c396bfc86ba75e8636a7da7789bb53bf",
                        "size": 982
                    },
                    {
                        "created": 1698505382.3228729,
                        "modified": 1698505382.3228729,
                        "name": "/dev/shm/incoming/2023_10_28_150238_00_00_V_053.jpg",
                        "sha256": "3be649771bcc9c2fa5f89d976ade01f992bbf5e7d96b27eda4652ee031b94b0c",
                        "size": 808301
                    },
                    {
                        "created": 1698505376.782873,
                        "modified": 1698505376.782873,
                        "name": "/dev/shm/incoming/2023_10_28_150235_00_00_V_052.txt",
                        "sha256": "bd4a81f1696070710170948569b06206e3a36d878e52b46812cd8ee4fa240231",
                        "size": 2022
                    },
                    {
                        "created": 1698505377.3228729,
                        "modified": 1698505377.3228729,
                        "name": "/dev/shm/incoming/2023_10_28_150235_00_00_V_052.kml",
                        "sha256": "e244b13d6cb4d52929e8bc6f97da4258f0b17ae91d6e3ca8d17b8e3e062317a9",
                        "size": 977
                    },
                    {
                        "created": 1698505376.0228727,
                        "modified": 1698505376.0228727,
                        "name": "/dev/shm/incoming/2023_10_28_150235_00_00_V_052.jpg",
                        "sha256": "a579e713986bed8cf293c7b04c9ad14deb3a90da300f4084ae4dfc8194e37e2f",
                        "size": 976726
                    },
                    {
                        "created": 1698505375.1928728,
                        "modified": 1698505375.1928728,
                        "name": "/dev/shm/incoming/2023_10_28_150232_00_00_V_051.txt",
                        "sha256": "f33c8558e46c944aa86db1bf689a1efdd0cc9b9b2542c6589c457896dc03964c",
                        "size": 2020
                    },
                    {
                        "created": 1698505375.7328727,
                        "modified": 1698505375.7328727,
                        "name": "/dev/shm/incoming/2023_10_28_150232_00_00_V_051.kml",
                        "sha256": "6e1550609affa0dd4cf41de32e279bf87cbdfa7e66e7fdd47c6fc098d3a94126",
                        "size": 979
                    },
                    {
                        "created": 1698505374.3928728,
                        "modified": 1698505374.3928728,
                        "name": "/dev/shm/incoming/2023_10_28_150232_00_00_V_051.jpg",
                        "sha256": "604122c3746ecc88acb4000e5ad02c25b2109277a73eca973f27b8ff1527757d",
                        "size": 811763
                    },
                    {
                        "created": 1698505373.282873,
                        "modified": 1698505373.282873,
                        "name": "/dev/shm/incoming/2023_10_28_150227_00_00_V_050.txt",
                        "sha256": "21fca853ad82aae947dbc85aaa8611acce7521065dc5983a81d9bbe8ae8d3913",
                        "size": 2028
                    },
                    {
                        "created": 1698505373.062873,
                        "modified": 1698505373.062873,
                        "name": "/dev/shm/incoming/2023_10_28_150227_00_00_V_050.kml",
                        "sha256": "ca05dd274d639d1b06746e0f0963e98aa8768ce473979c31ad8bea5188c383a2",
                        "size": 978
                    },
                    {
                        "created": 1698505372.782873,
                        "modified": 1698505372.782873,
                        "name": "/dev/shm/incoming/2023_10_28_150227_00_00_V_050.jpg",
                        "sha256": "7a19b8c0b01a7ea8acce268e0ae9822586d4e5daba287a97172667e146ed5671",
                        "size": 657279
                    },
                    {
                        "created": 1698505364.5728729,
                        "modified": 1698505364.5728729,
                        "name": "/dev/shm/incoming/2023_10_28_150216_00_00_V_049.txt",
                        "sha256": "599506513c24e5095a8d52081c87d70c387362cbf6a5c29636bdb13c78402b4a",
                        "size": 2032
                    },
                    {
                        "created": 1698505363.0928729,
                        "modified": 1698505363.0928729,
                        "name": "/dev/shm/incoming/2023_10_28_150216_00_00_V_049.kml",
                        "sha256": "c53f96e178bdca591e580ebab0b4cc95bf5adc65da8b05f6985956a32fd5c990",
                        "size": 973
                    },
                    {
                        "created": 1698505360.302873,
                        "modified": 1698505360.302873,
                        "name": "/dev/shm/incoming/2023_10_28_150216_00_00_V_049.jpg",
                        "sha256": "08ae053dd5d4b06851a6d0450825967fe37c4f1cc3be00c1a3f5c33ac9cbe8d0",
                        "size": 653054
                    },
                    {
                        "created": 1698505357.3928728,
                        "modified": 1698505357.3928728,
                        "name": "/dev/shm/incoming/2023_10_28_150203_00_00_V_048.txt",
                        "sha256": "70ae85e5eb1b405285a1fab0a79d1cea70a2272b9ff792cd1eb6788e56ea0cef",
                        "size": 2032
                    },
                    {
                        "created": 1698505358.7428727,
                        "modified": 1698505358.7428727,
                        "name": "/dev/shm/incoming/2023_10_28_150203_00_00_V_048.kml",
                        "sha256": "92255de493ce36aca769ff08beb068c3699d8c865ec796fc2403736416ccf560",
                        "size": 976
                    },
                    {
                        "created": 1698505347.7028728,
                        "modified": 1698505347.7028728,
                        "name": "/dev/shm/incoming/2023_10_28_150203_00_00_V_048.jpg",
                        "sha256": "03140749f71a2040f218d23de2c6a72b9488fbd24f2d744dbeed71f25a71a5de",
                        "size": 984111
                    },
                    {
                        "created": 1698505346.8428729,
                        "modified": 1698505346.8428729,
                        "name": "/dev/shm/incoming/2023_10_28_150157_00_00_V_047.txt",
                        "sha256": "fdf9b458eed3f0d5f07da87b7ba1399436864af04b118f48f91b6a6d6994ebaf",
                        "size": 2032
                    },
                    {
                        "created": 1698505346.6328728,
                        "modified": 1698505346.6328728,
                        "name": "/dev/shm/incoming/2023_10_28_150157_00_00_V_047.kml",
                        "sha256": "52aa2dfbe8ae46275993eab2832850283e3da3eb9dfb77c57d3ca983e909d1b9",
                        "size": 977
                    },
                    {
                        "created": 1698505345.2528727,
                        "modified": 1698505345.2528727,
                        "name": "/dev/shm/incoming/2023_10_28_150157_00_00_V_047.jpg",
                        "sha256": "e23ad38423da240246fbc59b3d1843ef13da22f4455e78bb5c5c5b0e5bcee743",
                        "size": 817519
                    },
                    {
                        "created": 1698505335.0028727,
                        "modified": 1698505335.0028727,
                        "name": "/dev/shm/incoming/2023_10_28_150153_00_00_V_046.txt",
                        "sha256": "a79c2106e7c06f5305a9d82d8f76763e87e4a7bfea4079f04e27c86735b07c5c",
                        "size": 2032
                    },
                    {
                        "created": 1698505334.6128728,
                        "modified": 1698505334.6128728,
                        "name": "/dev/shm/incoming/2023_10_28_150153_00_00_V_046.kml",
                        "sha256": "a8692bfb3fe4e6b3fd3f310ef7ee8ebaab6b30f40ed8b9d63e587c6878a2ffd8",
                        "size": 976
                    },
                    {
                        "created": 1698505331.812873,
                        "modified": 1698505331.812873,
                        "name": "/dev/shm/incoming/2023_10_28_150153_00_00_V_046.jpg",
                        "sha256": "3143215aaca961592ffd90a8c6108037e6664001e6550c2b15c70b03a8905071",
                        "size": 731730
                    },
                    {
                        "created": 1698505322.292873,
                        "modified": 1698505322.292873,
                        "name": "/dev/shm/incoming/2023_10_28_150138_00_00_V_045.txt",
                        "sha256": "98a3552b9f14993b61103661cbfc381dc2ba69e0c4b5b166c571b5f3a825b18e",
                        "size": 2032
                    },
                    {
                        "created": 1698505323.2028728,
                        "modified": 1698505323.2028728,
                        "name": "/dev/shm/incoming/2023_10_28_150138_00_00_V_045.kml",
                        "sha256": "40b5bf4327703f2b93c98d61abbaabf02963067588b4d64ffb442c0bae6a1b4b",
                        "size": 980
                    },
                    {
                        "created": 1698505322.8428729,
                        "modified": 1698505322.8428729,
                        "name": "/dev/shm/incoming/2023_10_28_150138_00_00_V_045.jpg",
                        "sha256": "960f1ec9602c55734736d2003966d8eafc4f06af0a12fada9b204679962207ef",
                        "size": 810056
                    },
                    {
                        "created": 1698505318.9128728,
                        "modified": 1698505318.9128728,
                        "name": "/dev/shm/incoming/2023_10_28_150135_00_00_V_044.txt",
                        "sha256": "7f68d118ceef329bbc5ac7b30c2f96b8f37500cc1ea4800437f839d4ddfa9705",
                        "size": 2032
                    },
                    {
                        "created": 1698505318.4728727,
                        "modified": 1698505318.4728727,
                        "name": "/dev/shm/incoming/2023_10_28_150135_00_00_V_044.kml",
                        "sha256": "c7f2003676bcdd82637e1d6624efc9c1d72ea9f9df3e0181b1cfb8218ab5e22c",
                        "size": 979
                    },
                    {
                        "created": 1698505318.1328728,
                        "modified": 1698505318.1328728,
                        "name": "/dev/shm/incoming/2023_10_28_150135_00_00_V_044.jpg",
                        "sha256": "0ba56415fa7d141604692eacbdbd3f2aec0d0cd04b83f72c522f2de095dd85c3",
                        "size": 862923
                    },
                    {
                        "created": 1698505300.4928727,
                        "modified": 1698505300.4928727,
                        "name": "/dev/shm/incoming/2023_10_28_150119_00_00_V_043.txt",
                        "sha256": "662cd033e5acb28ba76a839167cb700b2c034dd98ac779a58ce3a6e1fcde5a1c",
                        "size": 2032
                    },
                    {
                        "created": 1698505305.6928728,
                        "modified": 1698505305.6928728,
                        "name": "/dev/shm/incoming/2023_10_28_150119_00_00_V_043.kml",
                        "sha256": "47ccf75202eb76b0b8487814fd6aa68e9484d544d1cf5a102937f64d969e10ba",
                        "size": 977
                    },
                    {
                        "created": 1698505297.8428729,
                        "modified": 1698505297.8428729,
                        "name": "/dev/shm/incoming/2023_10_28_150119_00_00_V_043.jpg",
                        "sha256": "7e8cba0557504e4a807127bb3a6dd592376180629c43b0d1889c7c379974d6a8",
                        "size": 989965
                    },
                    {
                        "created": 1698505499.4328728,
                        "modified": 1698505499.4328728,
                        "name": "/dev/shm/incoming/2023-10-28T14:34:58.ts",
                        "sha256": "9d91d537b48ac7506d0e0b8557c2c302eb046abdc41f85d2843497fd0f5c6630",
                        "size": 418216904
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "32d1ab70b2b1546c49871b0511100396d1160dd6f2317fc899f603be9b42f80b",
                "size": 408000409
            },
            "errors": "",
            "filename": "2023-10-28T15-05-08Z",
            "size": 408000409,
            "verified_at": 1698523846
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698684622,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Ist7+mUPi8sPjfgv6n4qH+k8xukW04wtjrP9XKYMIEiYqqIRBadErAZl0r1F1JMq/2pT/xbVUuW6KTV5juwgMd9HV9WcVsOFwXJTL/ILxIsKMsO3I2ixITFmiK8paHdnNzPX7t15DK5DGq1XwgOp6K552yIR6xaVZK1W8rIt7o5zUX0xzkwsSe1vjMOPrjOR1tLnZRjHbwYcnvsjAAgFVqw/Uv4f/DWe9vtMl1UW92zNuEpSTjUryEOwLq3gjd60ry4Is2mdheDpq64Fq9PTAPsV/aafL59xJY/rfA52v2mTRmzKCzKt+Uryuw6Ta+hLSc3zUsczRq+aLLVaB9pu4DzeLlkwTe4B04a/1NIyUdr3LNpfDuQ0NVOUs1DL7tGNNZcVBfGlFCTxTfDA+h2gVmpQvMalV2vbp86cCVvfoMt4bGaQ9bEOHPf3KqthEs4zBHfWnGWpLlTG7aBWYpDAcUHmGdp9LvTc73S83kgM8jF+kZYW4y7BBoRdeXz0jJAcVZyHk4oHahlvY5nYTcyax6BNPQhcjouCh02caesWC96Tv+7fUyYAfkTWWn04so3XPQP9r/IyHSLJyiJx1qkPrGUhALG5kjUQATnys/VgcSy2uAT65wNZqMcQo62BuqCNNaaBU/56Joz8zhDq5TSrARaRcR65+BioHdb8Qk/UMeM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T16-50-04Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698684478.148698,
                        "modified": 1698684478.148698,
                        "name": "/dev/shm/incoming/2023-10-30T16:37:57.ais",
                        "sha256": "0dd45cbcb86112e0e0c4059a3fcd86faaf4291df8ea706c6b1d4d2b232834e3c",
                        "size": 41421
                    }
                ],
                "mission": "OSP2",
                "sha256": "103b0594ac9edc9a34caac39785e2e3b74fd637990d42e546456a75ec77c049c",
                "size": 10995
            },
            "errors": "",
            "filename": "2023-10-30T16-50-04Z",
            "size": 10995,
            "verified_at": 1698753597
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698754895,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "ch5BEYTM8pkTfZhtTronkdNSygy5P45gG6SfvCiDQBol7cK50+E6BpCAi+fBmyOuEgKZ/Y66Zzkls67t8pmQ1hY0INQCIEk0ZRtcO75fg2uICRedVt+KhgV76QTH/T0HvxCNw/jh0tXcWT2y7PowqIDhO7VaCU3ZZ76Zh4iDD6y6GuT42qwGZK5hhlY9DS+moN6jDpZl7ZzfrTFpyScpopQqM5eikgWtmWjijwh2e426ih5VzIrLfDWIkJByR9ThzP95IZKkJzdBo0qmodJ7mR2Gvc6tm6rw8I62ClnXM2Go5xYzo1NgEi9/RX0CM4Rd+xdRv7fshkRWAptiVlOAB1Xbnbmzo0GIJ5OqY4wBJuhoulCQ4BuQVJpwu3I+h5lmpwWd4NjAkxhwBdooPK+RpSkCCox/WrhxOpg+1Yhq6rqHf46MHjtN8Wi0WPQU5WJV0TNSYIOx9x2O+r+/npK+C8aDRehqSibhz6WARlHMjE2x5HAOF1CNG0iECpX7gSh705CZCpCc+JWaIfxhRSMUDidCHGh1/D6ZhLFAgrxEgGyvxQhwivVM0/CF/AmP8KdrMmICZxKxI04JXG9LUwOnbAOb0rhbFFliFG3tt3mPb9rmcVzVCel/Z01kGD65IwoVIaCKtmMoMgv2tnRlSsxYo0gtWnvJGnTqwPBAwzagWuU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-21-19Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698754763.114362,
                        "modified": 1698754763.114362,
                        "name": "/dev/shm/incoming/2023_10_31_121859_00_00_V_125.txt",
                        "sha256": "46b301aa89f0d7355ca39bcfc29b490565b9e74db518a8264ae572b7d83fb20c",
                        "size": 2052
                    },
                    {
                        "created": 1698754753.8943622,
                        "modified": 1698754753.8943622,
                        "name": "/dev/shm/incoming/2023_10_31_121859_00_00_V_125.kml",
                        "sha256": "cf0ba477d5137a81ddccde2caf4fcd8a16787dc6f5a189c51286938dbeca172d",
                        "size": 983
                    },
                    {
                        "created": 1698754758.044362,
                        "modified": 1698754758.044362,
                        "name": "/dev/shm/incoming/2023_10_31_121859_00_00_V_125.jpg",
                        "sha256": "d5e0a5858c610b00714a0e80bebd70baa73a5615515a610904d0b92f48b2f1ae",
                        "size": 961248
                    },
                    {
                        "created": 1698754735.6543622,
                        "modified": 1698754735.6543622,
                        "name": "/dev/shm/incoming/2023_10_31_121826_00_00_V_124.txt",
                        "sha256": "f83e5a19dbc32ebc00defcba5a378318d1fb0d074b56416e97d64f22c1383007",
                        "size": 2052
                    },
                    {
                        "created": 1698754753.294362,
                        "modified": 1698754753.294362,
                        "name": "/dev/shm/incoming/2023_10_31_121826_00_00_V_124.kml",
                        "sha256": "f0e6d343c04098c784db75e891578dee07db5743d727f172328570b8496a0728",
                        "size": 984
                    },
                    {
                        "created": 1698754744.764362,
                        "modified": 1698754744.764362,
                        "name": "/dev/shm/incoming/2023_10_31_121826_00_00_V_124.jpg",
                        "sha256": "e2ee618d3e6daf3d25b9e4660f8f9f02c64fe8e3750fb84c43d4da439f5f4d03",
                        "size": 958019
                    },
                    {
                        "created": 1698754719.574362,
                        "modified": 1698754719.574362,
                        "name": "/dev/shm/incoming/2023_10_31_121817_00_00_V_123.txt",
                        "sha256": "01329a478d2d2338aca746e8f16cfbb0c537a47838ee3dd48809e18717c83898",
                        "size": 2052
                    },
                    {
                        "created": 1698754717.754362,
                        "modified": 1698754717.754362,
                        "name": "/dev/shm/incoming/2023_10_31_121817_00_00_V_123.kml",
                        "sha256": "882d4572c5aa8c7652857ccd19f2e6d63d068ecad256969c0cb7be102577fef0",
                        "size": 984
                    },
                    {
                        "created": 1698754717.014362,
                        "modified": 1698754717.014362,
                        "name": "/dev/shm/incoming/2023_10_31_121817_00_00_V_123.jpg",
                        "sha256": "81754fed55a862e17a0224ed449e76aa658dfa24034fae0c29cdb823d2c2d4b8",
                        "size": 988721
                    },
                    {
                        "created": 1698754697.994362,
                        "modified": 1698754697.994362,
                        "name": "/dev/shm/incoming/2023_10_31_121737_00_00_V_122.txt",
                        "sha256": "0ec947b71232de04fb311974a6488b88166717788290372c3205d2905a63d42c",
                        "size": 2053
                    },
                    {
                        "created": 1698754688.1643622,
                        "modified": 1698754688.1643622,
                        "name": "/dev/shm/incoming/2023_10_31_121737_00_00_V_122.kml",
                        "sha256": "3da6878886aac1bc142bd5b32952c5c7d73f6550976d6b32522ddc87b0d1a2a7",
                        "size": 983
                    },
                    {
                        "created": 1698754684.604362,
                        "modified": 1698754684.604362,
                        "name": "/dev/shm/incoming/2023_10_31_121737_00_00_V_122.jpg",
                        "sha256": "966d99ddbc2cfe4658467b2911d6c8919355b2ae6ebacda567cb4d4b9d31ef28",
                        "size": 959840
                    },
                    {
                        "created": 1698754681.274362,
                        "modified": 1698754681.274362,
                        "name": "/dev/shm/incoming/2023_10_31_121731_00_00_V_121.txt",
                        "sha256": "c95489c98571a700ec20963959d31e946f26a276397e43cf571c3a38d2614101",
                        "size": 2054
                    },
                    {
                        "created": 1698754680.6543622,
                        "modified": 1698754680.6543622,
                        "name": "/dev/shm/incoming/2023_10_31_121731_00_00_V_121.kml",
                        "sha256": "72a59d012c3963fb15df77ca676a5c13981bd493dab3ef7cbc4720e377491399",
                        "size": 983
                    },
                    {
                        "created": 1698754679.544362,
                        "modified": 1698754679.544362,
                        "name": "/dev/shm/incoming/2023_10_31_121731_00_00_V_121.jpg",
                        "sha256": "f164a8900ca3454828f741d71356277b7c6246e67ea11bf41aa18a31d3f800db",
                        "size": 1052852
                    },
                    {
                        "created": 1698754669.9343622,
                        "modified": 1698754669.9343622,
                        "name": "/dev/shm/incoming/2023_10_31_121729_00_00_V_120.txt",
                        "sha256": "3c4864c2cd23261ca45ed07dca43a2550946d047b7cf18b523ce111d1ff6fbef",
                        "size": 2052
                    },
                    {
                        "created": 1698754669.624362,
                        "modified": 1698754669.624362,
                        "name": "/dev/shm/incoming/2023_10_31_121729_00_00_V_120.kml",
                        "sha256": "ebd3b37d193593e6319a926d7518c838337e4ba7bcf9a0cff612beeea7c873e8",
                        "size": 982
                    },
                    {
                        "created": 1698754670.4243622,
                        "modified": 1698754670.4243622,
                        "name": "/dev/shm/incoming/2023_10_31_121729_00_00_V_120.jpg",
                        "sha256": "1016bcfc62c7a3c96136bd785896a2eed9c2925da17bee2e70a553a43313d44e",
                        "size": 1038027
                    },
                    {
                        "created": 1698754629.774362,
                        "modified": 1698754629.774362,
                        "name": "/dev/shm/incoming/2023_10_31_121622_00_00_V_119.txt",
                        "sha256": "c06b0c1ae97158da409af01df26b12ae5157a40a18d04b0db3042df1fd9ac244",
                        "size": 2057
                    },
                    {
                        "created": 1698754629.624362,
                        "modified": 1698754629.624362,
                        "name": "/dev/shm/incoming/2023_10_31_121622_00_00_V_119.kml",
                        "sha256": "14fd9b760dcc2857eb2325afd1f61ca01c1530d34843db706f0cdd84e30140d4",
                        "size": 982
                    },
                    {
                        "created": 1698754624.9343622,
                        "modified": 1698754624.9343622,
                        "name": "/dev/shm/incoming/2023_10_31_121622_00_00_V_119.jpg",
                        "sha256": "5ce1780f7dcac9974ff918b625ef6324764475988f1fbf1e9cfa875924efae51",
                        "size": 966351
                    },
                    {
                        "created": 1698754623.874362,
                        "modified": 1698754623.874362,
                        "name": "/dev/shm/incoming/2023_10_31_121622_00_00_V_118.txt",
                        "sha256": "c7da8d9ff97751083ee91974b182f4a0a4db3012c915103a7d0afe09b1f0a260",
                        "size": 2052
                    },
                    {
                        "created": 1698754623.494362,
                        "modified": 1698754623.494362,
                        "name": "/dev/shm/incoming/2023_10_31_121622_00_00_V_118.kml",
                        "sha256": "85137d913e9cbd3ee8e3b67517a391f075d72377ddf549e6b6ca14fc96bac7e8",
                        "size": 983
                    },
                    {
                        "created": 1698754623.344362,
                        "modified": 1698754623.344362,
                        "name": "/dev/shm/incoming/2023_10_31_121622_00_00_V_118.jpg",
                        "sha256": "f204cf6df656d5371784cae2550d881e082115d8e95492176bc2c4942fc37e9e",
                        "size": 1044435
                    },
                    {
                        "created": 1698754622.4343622,
                        "modified": 1698754622.4343622,
                        "name": "/dev/shm/incoming/2023_10_31_121614_00_00_V_117.txt",
                        "sha256": "d224537dab6a27984664ce0879fb303c26ad4eb13c52cadc35be46e85f7916d1",
                        "size": 2057
                    },
                    {
                        "created": 1698754622.254362,
                        "modified": 1698754622.254362,
                        "name": "/dev/shm/incoming/2023_10_31_121614_00_00_V_117.kml",
                        "sha256": "261900976949cc2c03222e7365185900088a46d171b335a67c4b54a9fae28d24",
                        "size": 983
                    },
                    {
                        "created": 1698754621.3943622,
                        "modified": 1698754621.3943622,
                        "name": "/dev/shm/incoming/2023_10_31_121614_00_00_V_117.jpg",
                        "sha256": "333ece967a308cf5039fa9433e635fa22a3701cb01d85a2bb3b234e56eb15243",
                        "size": 900306
                    },
                    {
                        "created": 1698754620.324362,
                        "modified": 1698754620.324362,
                        "name": "/dev/shm/incoming/2023_10_31_121614_00_00_V_116.txt",
                        "sha256": "8a8f254e24ff9c1e8ef171a12f708d44ff1c6d987c41307e89129d499480a34a",
                        "size": 2057
                    },
                    {
                        "created": 1698754613.354362,
                        "modified": 1698754613.354362,
                        "name": "/dev/shm/incoming/2023_10_31_121614_00_00_V_116.kml",
                        "sha256": "2a79bdce83f9da60495fb415708d02ff0e727283f55b998c226f8d0163e92669",
                        "size": 982
                    },
                    {
                        "created": 1698754608.4243622,
                        "modified": 1698754608.4243622,
                        "name": "/dev/shm/incoming/2023_10_31_121614_00_00_V_116.jpg",
                        "sha256": "77e05154c1c176b9693dd720858f5defffd1562ba9df7157964f268fab87e1ee",
                        "size": 871230
                    },
                    {
                        "created": 1698754607.364362,
                        "modified": 1698754607.364362,
                        "name": "/dev/shm/incoming/2023_10_31_121614_00_00_V_115.txt",
                        "sha256": "863c921eb26261336316637114d4ec0a48d8321e7798b87eb77a38c9ef5fd028",
                        "size": 2057
                    },
                    {
                        "created": 1698754607.9743621,
                        "modified": 1698754607.9743621,
                        "name": "/dev/shm/incoming/2023_10_31_121614_00_00_V_115.kml",
                        "sha256": "a9404042e4ce66d29dbd85d1dfff0f5f402428411b18457f7a784abac4648f13",
                        "size": 982
                    },
                    {
                        "created": 1698754606.504362,
                        "modified": 1698754606.504362,
                        "name": "/dev/shm/incoming/2023_10_31_121614_00_00_V_115.jpg",
                        "sha256": "07909b1fa72dd1dae063309994b989d61afe5a407ed84a9865cdbe8c12680c2f",
                        "size": 802708
                    },
                    {
                        "created": 1698754605.4443622,
                        "modified": 1698754605.4443622,
                        "name": "/dev/shm/incoming/2023_10_31_121614_00_00_V_114.txt",
                        "sha256": "da1be7d7e18724dbdace699792656eb09fcaf2c5e2144b02ece1ae37575f18fb",
                        "size": 2057
                    },
                    {
                        "created": 1698754605.824362,
                        "modified": 1698754605.824362,
                        "name": "/dev/shm/incoming/2023_10_31_121614_00_00_V_114.kml",
                        "sha256": "f99f742c93b13bbab4b81551031e897ca230499a3983e6816e0d4241fbffc2cb",
                        "size": 981
                    },
                    {
                        "created": 1698754604.4043622,
                        "modified": 1698754604.4043622,
                        "name": "/dev/shm/incoming/2023_10_31_121614_00_00_V_114.jpg",
                        "sha256": "946f53cc754a343419feb5d86eb0150a116cef5deffc975847e0c39009cc03c2",
                        "size": 825801
                    },
                    {
                        "created": 1698754602.6843622,
                        "modified": 1698754602.6843622,
                        "name": "/dev/shm/incoming/2023_10_31_121614_00_00_V_113.txt",
                        "sha256": "19fdb94f195e8df1366a9d4302790ad757f21b1c1da1403d2da6d00eed0d0b8e",
                        "size": 2052
                    },
                    {
                        "created": 1698754602.574362,
                        "modified": 1698754602.574362,
                        "name": "/dev/shm/incoming/2023_10_31_121614_00_00_V_113.kml",
                        "sha256": "50e3d1021948abc5ac4030309bdae59bec98d85f6781fb314c5f64ee9dcea09f",
                        "size": 981
                    },
                    {
                        "created": 1698754602.294362,
                        "modified": 1698754602.294362,
                        "name": "/dev/shm/incoming/2023_10_31_121614_00_00_V_113.jpg",
                        "sha256": "56f5bd8d089cd49b0ef07121b5bf9ff37c3440c2f726b77256ed03b17b951201",
                        "size": 897075
                    },
                    {
                        "created": 1698754601.2043622,
                        "modified": 1698754601.2043622,
                        "name": "/dev/shm/incoming/2023_10_31_121612_00_00_V_112.txt",
                        "sha256": "9b6ffdd9283ee3fb4c2350dd5406852f9fe42293afbf9fcedaa6d9290bee681a",
                        "size": 2052
                    },
                    {
                        "created": 1698754600.504362,
                        "modified": 1698754600.504362,
                        "name": "/dev/shm/incoming/2023_10_31_121612_00_00_V_112.kml",
                        "sha256": "ae9029d373f0fccacbfa0f4c6f1d74c59d7d690e3475196ab4ba039dc258a876",
                        "size": 982
                    },
                    {
                        "created": 1698754600.364362,
                        "modified": 1698754600.364362,
                        "name": "/dev/shm/incoming/2023_10_31_121612_00_00_V_112.jpg",
                        "sha256": "52bbd3e602de6e72889c96a24d55285be218e5d363d739cd9d698c7a17f960e4",
                        "size": 795118
                    },
                    {
                        "created": 1698754599.4643621,
                        "modified": 1698754599.4643621,
                        "name": "/dev/shm/incoming/2023_10_31_121608_00_00_V_111.txt",
                        "sha256": "6f808e59f275d5686d69e4cdad7d7505be682ac3e10c216569d5d4d438fd6b90",
                        "size": 2057
                    },
                    {
                        "created": 1698754599.304362,
                        "modified": 1698754599.304362,
                        "name": "/dev/shm/incoming/2023_10_31_121608_00_00_V_111.kml",
                        "sha256": "5cb05df9cb8789ef1684683eb04b096a98c46fc7eb4c176819a2751e6eebae2d",
                        "size": 982
                    },
                    {
                        "created": 1698754600.274362,
                        "modified": 1698754600.274362,
                        "name": "/dev/shm/incoming/2023_10_31_121608_00_00_V_111.jpg",
                        "sha256": "472dc700ed7f64fa10a326357d854b1216a60a315fc8df15f3c3428549eea709",
                        "size": 1517716
                    },
                    {
                        "created": 1698754598.254362,
                        "modified": 1698754598.254362,
                        "name": "/dev/shm/incoming/2023_10_31_121608_00_00_V_110.txt",
                        "sha256": "cd1615745a003ace34f27d33e79be05e7b9597cf614f5a956dee223c635f571a",
                        "size": 2052
                    },
                    {
                        "created": 1698754598.074362,
                        "modified": 1698754598.074362,
                        "name": "/dev/shm/incoming/2023_10_31_121608_00_00_V_110.kml",
                        "sha256": "8cfe0311a4483d189887ef16378255065dbdc098d80b54e261e315544bdd9449",
                        "size": 983
                    },
                    {
                        "created": 1698754597.7343621,
                        "modified": 1698754597.7343621,
                        "name": "/dev/shm/incoming/2023_10_31_121608_00_00_V_110.jpg",
                        "sha256": "a3f8f7aa1a5f22cd43101c579e83d8cde82be72d0f3ebdeeaf864db9e7d746b5",
                        "size": 1623582
                    },
                    {
                        "created": 1698754597.2143621,
                        "modified": 1698754597.2143621,
                        "name": "/dev/shm/incoming/2023_10_31_121604_00_00_V_109.txt",
                        "sha256": "0a9bb4a719260c784fdade859c683462d7e5dadc523dc81c6ed58a06d78c0450",
                        "size": 2052
                    },
                    {
                        "created": 1698754597.034362,
                        "modified": 1698754597.034362,
                        "name": "/dev/shm/incoming/2023_10_31_121604_00_00_V_109.kml",
                        "sha256": "662f59b816557907665762faca404b3855311636b479638291000ffb5432cac4",
                        "size": 981
                    },
                    {
                        "created": 1698754595.6643622,
                        "modified": 1698754595.6643622,
                        "name": "/dev/shm/incoming/2023_10_31_121604_00_00_V_109.jpg",
                        "sha256": "7c04cced88944e880c265af75bb408cf27ebcfdfefe6db09880dbe05cf772d1b",
                        "size": 1708771
                    },
                    {
                        "created": 1698754593.4843621,
                        "modified": 1698754593.4843621,
                        "name": "/dev/shm/incoming/2023_10_31_121556_00_00_V_108.txt",
                        "sha256": "b8e1828861735320c2bd218fc2d4ce012a3876cc986cad222467858926a9729f",
                        "size": 2051
                    },
                    {
                        "created": 1698754593.044362,
                        "modified": 1698754593.044362,
                        "name": "/dev/shm/incoming/2023_10_31_121556_00_00_V_108.kml",
                        "sha256": "894c0d45219b6e5bf5f8db53392ac912486e5f86bcf0b72c171fffd765a071aa",
                        "size": 982
                    },
                    {
                        "created": 1698754584.014362,
                        "modified": 1698754584.014362,
                        "name": "/dev/shm/incoming/2023_10_31_121556_00_00_V_108.jpg",
                        "sha256": "36d7b7682889f570e3b62ea8142d34101626afe681e6d653cb97f0ebad3edf63",
                        "size": 2052743
                    },
                    {
                        "created": 1698754581.784362,
                        "modified": 1698754581.784362,
                        "name": "/dev/shm/incoming/2023_10_31_121548_00_00_V_107.txt",
                        "sha256": "fa441a855d4059708ec3201f804eaafd1f36bb6daf4c3a7109a0ba19f7c1be60",
                        "size": 2050
                    },
                    {
                        "created": 1698754581.364362,
                        "modified": 1698754581.364362,
                        "name": "/dev/shm/incoming/2023_10_31_121548_00_00_V_107.kml",
                        "sha256": "0c0a053849c7edc57bf4efa0eb0c628e60bfb1d343a798efc19aeab5fc63adb7",
                        "size": 983
                    },
                    {
                        "created": 1698754581.2243621,
                        "modified": 1698754581.2243621,
                        "name": "/dev/shm/incoming/2023_10_31_121548_00_00_V_107.jpg",
                        "sha256": "d90907279369419302f23a6c7933647471a163af0ed643bae01b9b6d74e6430b",
                        "size": 1735926
                    },
                    {
                        "created": 1698754571.344362,
                        "modified": 1698754571.344362,
                        "name": "/dev/shm/incoming/2023_10_31_121539_00_00_V_106.txt",
                        "sha256": "a8987c37687157078d1bba89a137dfc4eecd3a3e8594b9b1b314141fd47ef6b5",
                        "size": 2050
                    },
                    {
                        "created": 1698754570.9443622,
                        "modified": 1698754570.9443622,
                        "name": "/dev/shm/incoming/2023_10_31_121539_00_00_V_106.kml",
                        "sha256": "dac5630b8af035d38aef3c5480c9aabe541ba4fec77cbbf789f39115e746b092",
                        "size": 983
                    },
                    {
                        "created": 1698754568.094362,
                        "modified": 1698754568.094362,
                        "name": "/dev/shm/incoming/2023_10_31_121539_00_00_V_106.jpg",
                        "sha256": "a052fa87bc629bd439e6ec0aa4ecf9ad8cf89c2c32a6202b233cf8985011d5bf",
                        "size": 1388550
                    },
                    {
                        "created": 1698754566.994362,
                        "modified": 1698754566.994362,
                        "name": "/dev/shm/incoming/2023_10_31_121534_00_00_V_105.txt",
                        "sha256": "58b4fce9b5cf348987e26fa391fe759e9618b14b01506d2b219b4eb9b44a9869",
                        "size": 2049
                    },
                    {
                        "created": 1698754566.354362,
                        "modified": 1698754566.354362,
                        "name": "/dev/shm/incoming/2023_10_31_121534_00_00_V_105.kml",
                        "sha256": "ae940c7c42ba1ee06ac6d1fffc83560f5770591e6b0917919a6894430b55917b",
                        "size": 983
                    },
                    {
                        "created": 1698754565.9443622,
                        "modified": 1698754565.9443622,
                        "name": "/dev/shm/incoming/2023_10_31_121534_00_00_V_105.jpg",
                        "sha256": "b4d87ccd132e67ec76470bac74b4e2d2c47c6b6c7fde9fca6a0c307b9b4c3ca6",
                        "size": 1252678
                    },
                    {
                        "created": 1698754564.864362,
                        "modified": 1698754564.864362,
                        "name": "/dev/shm/incoming/2023_10_31_121532_00_00_V_104.txt",
                        "sha256": "03e3d8d08ed35fc3028f4c327c4636bf6e0be07b26bad424553630ade821b4f1",
                        "size": 2050
                    },
                    {
                        "created": 1698754565.6843622,
                        "modified": 1698754565.6843622,
                        "name": "/dev/shm/incoming/2023_10_31_121532_00_00_V_104.kml",
                        "sha256": "e18360d8787883ca587df43bbce8994cd82de1d1f77e1de71efd39fb4ff811f3",
                        "size": 982
                    },
                    {
                        "created": 1698754563.9843621,
                        "modified": 1698754563.9843621,
                        "name": "/dev/shm/incoming/2023_10_31_121532_00_00_V_104.jpg",
                        "sha256": "6093eb5a8ef36ebabfda8aab504da733769e5e5250ee73e0fd31c8287acfe096",
                        "size": 1049949
                    },
                    {
                        "created": 1698754561.784362,
                        "modified": 1698754561.784362,
                        "name": "/dev/shm/incoming/2023_10_31_121530_00_00_V_103.txt",
                        "sha256": "fd48813f296a205d87dbe88cd2263c4841d770c7d33071f04be3758af44939e7",
                        "size": 2050
                    },
                    {
                        "created": 1698754561.284362,
                        "modified": 1698754561.284362,
                        "name": "/dev/shm/incoming/2023_10_31_121530_00_00_V_103.kml",
                        "sha256": "e67b59bfc3385873a286390b6eea7e69e7cbc5f82fc9d56038d3c9336c9b6a89",
                        "size": 983
                    },
                    {
                        "created": 1698754555.804362,
                        "modified": 1698754555.804362,
                        "name": "/dev/shm/incoming/2023_10_31_121530_00_00_V_103.jpg",
                        "sha256": "33aa3a243113988214f116228e1e98cbb4cc9b392bec85459bc3de3cd709025d",
                        "size": 959653
                    },
                    {
                        "created": 1698754550.374362,
                        "modified": 1698754550.374362,
                        "name": "/dev/shm/incoming/2023_10_31_121528_00_00_V_102.txt",
                        "sha256": "8b29919f38ef7d49e5cd228978c6e469f6a821b31867af914245941391a42227",
                        "size": 2050
                    },
                    {
                        "created": 1698754551.004362,
                        "modified": 1698754551.004362,
                        "name": "/dev/shm/incoming/2023_10_31_121528_00_00_V_102.kml",
                        "sha256": "1208740e88b81f716192e6f4ea04e23b7fc1946c92d14df1fdc8e567b727e282",
                        "size": 981
                    },
                    {
                        "created": 1698754549.624362,
                        "modified": 1698754549.624362,
                        "name": "/dev/shm/incoming/2023_10_31_121528_00_00_V_102.jpg",
                        "sha256": "57048db0d006833e0e59f2d38d3f24290be1eec5046c6a60e06ea38fd47c274e",
                        "size": 993747
                    },
                    {
                        "created": 1698754548.884362,
                        "modified": 1698754548.884362,
                        "name": "/dev/shm/incoming/2023_10_31_121526_00_00_V_101.txt",
                        "sha256": "2478f970035d016217dac2f2914d6b4d51fb2c1adf7438704c7b81c7c6e5d4bf",
                        "size": 2049
                    },
                    {
                        "created": 1698754548.564362,
                        "modified": 1698754548.564362,
                        "name": "/dev/shm/incoming/2023_10_31_121526_00_00_V_101.kml",
                        "sha256": "bbd0d07166d6bd3f6d996e899e1ad10b226114bb8c564f275ba75323ddbfef03",
                        "size": 983
                    },
                    {
                        "created": 1698754550.504362,
                        "modified": 1698754550.504362,
                        "name": "/dev/shm/incoming/2023_10_31_121526_00_00_V_101.jpg",
                        "sha256": "a68cf0f4851bbde6872cfe98f1e013c786a759eb6c5f915f9aa4a075458d2d07",
                        "size": 1031758
                    },
                    {
                        "created": 1698754537.1843622,
                        "modified": 1698754537.1843622,
                        "name": "/dev/shm/incoming/2023_10_31_121516_00_00_V_100.txt",
                        "sha256": "574d7dabe590579946d9b9fba09f0de7c6658eed295c0d72dc795e14cf66f8ec",
                        "size": 2049
                    },
                    {
                        "created": 1698754546.4543622,
                        "modified": 1698754546.4543622,
                        "name": "/dev/shm/incoming/2023_10_31_121516_00_00_V_100.kml",
                        "sha256": "81c4479df29642fb96e39a1f52033e34308bebedbb64c1fd3c5681f3c364d900",
                        "size": 983
                    },
                    {
                        "created": 1698754543.2043622,
                        "modified": 1698754543.2043622,
                        "name": "/dev/shm/incoming/2023_10_31_121516_00_00_V_100.jpg",
                        "sha256": "62a18747cb3f0817478f8a1317a16ff75988a5ad1091f83c77816a4cf8700a01",
                        "size": 1024433
                    },
                    {
                        "created": 1698754534.554362,
                        "modified": 1698754534.554362,
                        "name": "/dev/shm/incoming/2023_10_31_121514_00_00_V_099.txt",
                        "sha256": "1067aa8af024012f7a2bf029c92a8a6deb640de039e9ae751ca3b9c8168de558",
                        "size": 2049
                    },
                    {
                        "created": 1698754534.344362,
                        "modified": 1698754534.344362,
                        "name": "/dev/shm/incoming/2023_10_31_121514_00_00_V_099.kml",
                        "sha256": "8afcddcc297c788770ec2a58481c09b12d8b3e12e1c1c251db623353ca25c5d1",
                        "size": 982
                    },
                    {
                        "created": 1698754533.7243621,
                        "modified": 1698754533.7243621,
                        "name": "/dev/shm/incoming/2023_10_31_121514_00_00_V_099.jpg",
                        "sha256": "66bf7810b04ef57321b63ff226255b44ceeaa0d8dab7a6d651538dfe9a9d4e1d",
                        "size": 1012608
                    },
                    {
                        "created": 1698754527.834362,
                        "modified": 1698754527.834362,
                        "name": "/dev/shm/incoming/2023_10_31_121501_00_00_V_098.txt",
                        "sha256": "fb76d67a4536d09cabae68529a01afb2c58b967d4ce3e7847041e2882a2f953e",
                        "size": 2052
                    },
                    {
                        "created": 1698754531.764362,
                        "modified": 1698754531.764362,
                        "name": "/dev/shm/incoming/2023_10_31_121501_00_00_V_098.kml",
                        "sha256": "c3606fd305ad54a365e4538928ddf623b759c9b4f3cc9e8babee4ab3f6447bec",
                        "size": 982
                    },
                    {
                        "created": 1698754530.624362,
                        "modified": 1698754530.624362,
                        "name": "/dev/shm/incoming/2023_10_31_121501_00_00_V_098.jpg",
                        "sha256": "17208d9f4629a964dad028a7016cd3958cf9bd7e6cab69c9c6cab1e208305daa",
                        "size": 1033645
                    },
                    {
                        "created": 1698754518.804362,
                        "modified": 1698754518.804362,
                        "name": "/dev/shm/incoming/2023_10_31_121433_00_00_V_097.txt",
                        "sha256": "6dd8fc3217d2c676314f40342390e87e475bf690f2112bc4d14349024e18b61e",
                        "size": 2052
                    },
                    {
                        "created": 1698754507.4443622,
                        "modified": 1698754507.4443622,
                        "name": "/dev/shm/incoming/2023_10_31_121433_00_00_V_097.kml",
                        "sha256": "252193e3b881ccdc295a6e47280c7cc3a45453cee27db37560661d2927a19ceb",
                        "size": 983
                    },
                    {
                        "created": 1698754497.254362,
                        "modified": 1698754497.254362,
                        "name": "/dev/shm/incoming/2023_10_31_121433_00_00_V_097.jpg",
                        "sha256": "13e5b0214248d140cd21a82c1a21ceec5b3e925afa298183921a0f7df8ad5ca3",
                        "size": 966635
                    },
                    {
                        "created": 1698754485.554362,
                        "modified": 1698754485.554362,
                        "name": "/dev/shm/incoming/2023_10_31_121423_00_00_V_096.txt",
                        "sha256": "79e197ea0db8a665538d6c952bfbfb91a50c547c51b181f7b57401487e5de815",
                        "size": 2052
                    },
                    {
                        "created": 1698754484.9843621,
                        "modified": 1698754484.9843621,
                        "name": "/dev/shm/incoming/2023_10_31_121423_00_00_V_096.kml",
                        "sha256": "3d35ebdcc945252e68e434d4b417aa42d0d56aef41410c4f772804e0ba0d849a",
                        "size": 983
                    },
                    {
                        "created": 1698754484.804362,
                        "modified": 1698754484.804362,
                        "name": "/dev/shm/incoming/2023_10_31_121423_00_00_V_096.jpg",
                        "sha256": "4fa486697edf93fc8fc93baba99a2f4a2fa1b5e24b0d5b8eb868612f0e838847",
                        "size": 921092
                    },
                    {
                        "created": 1698754440.324362,
                        "modified": 1698754440.324362,
                        "name": "/dev/shm/incoming/2023_10_31_121332_00_00_V_095.txt",
                        "sha256": "9f954b80499f6cd4b55e82a5cbca33c0672122bddf9329cae97765386cb384a9",
                        "size": 2053
                    },
                    {
                        "created": 1698754434.884362,
                        "modified": 1698754434.884362,
                        "name": "/dev/shm/incoming/2023_10_31_121332_00_00_V_095.kml",
                        "sha256": "8e4f8e2a3a6f12308079015ffa0e160ca630b9d3a284dfe1b8a73dae4b40384e",
                        "size": 983
                    },
                    {
                        "created": 1698754444.064362,
                        "modified": 1698754444.064362,
                        "name": "/dev/shm/incoming/2023_10_31_121332_00_00_V_095.jpg",
                        "sha256": "ebd09fe6244916fa76b9ba6f7fae6f91b9f8afc6a032e0c83fdf23a0350daea8",
                        "size": 1071536
                    },
                    {
                        "created": 1698754440.6643622,
                        "modified": 1698754440.6643622,
                        "name": "/dev/shm/incoming/2023_10_31_121331_00_00_V_094.txt",
                        "sha256": "8f4a757c4749368dec7750de80d0cfaae01079080f63c0efc5f6271a0362e2ed",
                        "size": 2053
                    },
                    {
                        "created": 1698754428.7343621,
                        "modified": 1698754428.7343621,
                        "name": "/dev/shm/incoming/2023_10_31_121331_00_00_V_094.kml",
                        "sha256": "2e2f2a269cb63c6b7b6da045e99ccb13cccb4b8990fa5f2b68ca89c049655070",
                        "size": 981
                    },
                    {
                        "created": 1698754429.544362,
                        "modified": 1698754429.544362,
                        "name": "/dev/shm/incoming/2023_10_31_121331_00_00_V_094.jpg",
                        "sha256": "a4e32bd080e5167e999c33f0518f11c38587f3248afd1f3c2f6b077d6b4dd6d7",
                        "size": 1102028
                    },
                    {
                        "created": 1698754427.794362,
                        "modified": 1698754427.794362,
                        "name": "/dev/shm/incoming/2023_10_31_121329_00_00_V_093.txt",
                        "sha256": "c830d26c59a94b2f357cd8f8428a145c2daced1dc98ee2fb6a51293caaad48ae",
                        "size": 2053
                    },
                    {
                        "created": 1698754427.364362,
                        "modified": 1698754427.364362,
                        "name": "/dev/shm/incoming/2023_10_31_121329_00_00_V_093.kml",
                        "sha256": "e45e62d5429e9097d007115a289ba46c9b0d19749da622536c1eebbc15059568",
                        "size": 982
                    },
                    {
                        "created": 1698754427.304362,
                        "modified": 1698754427.304362,
                        "name": "/dev/shm/incoming/2023-10-31T12:03:44.ais",
                        "sha256": "27231296fe113196ae402fadc1e36de391706c57109bc007a20881c2aa241fd5",
                        "size": 39097
                    },
                    {
                        "created": 1698754426.7343621,
                        "modified": 1698754426.7343621,
                        "name": "/dev/shm/incoming/2023_10_31_121329_00_00_V_093.jpg",
                        "sha256": "f9046a5468c4c2b480c02810983f7deab43091235a59d2785c92beb478268149",
                        "size": 1140539
                    },
                    {
                        "created": 1698754395.104362,
                        "modified": 1698754395.104362,
                        "name": "/dev/shm/incoming/2023_10_31_121248_00_00_V_092.txt",
                        "sha256": "4eec3c701d9f493949a334ac12403ce3e0c4c1b8ebe2c0a82fcef654b9f28e31",
                        "size": 2051
                    },
                    {
                        "created": 1698754394.554362,
                        "modified": 1698754394.554362,
                        "name": "/dev/shm/incoming/2023_10_31_121248_00_00_V_092.kml",
                        "sha256": "702055ef45991cc7fc93cfca442bb9c6792f85e3a9c53df9ab8daab7a7354fae",
                        "size": 982
                    },
                    {
                        "created": 1698754393.4043622,
                        "modified": 1698754393.4043622,
                        "name": "/dev/shm/incoming/2023_10_31_121248_00_00_V_092.jpg",
                        "sha256": "889a9dbc3dcaeb75d4947a58324567c68686ae0c090b09e4fbcbd43b07451451",
                        "size": 1078110
                    },
                    {
                        "created": 1698754387.354362,
                        "modified": 1698754387.354362,
                        "name": "/dev/shm/incoming/2023_10_31_121242_00_00_V_091.txt",
                        "sha256": "1c8fe53207a6224cffdb52929eb839413190edc60b00ad414c3c72a6897c05fd",
                        "size": 2051
                    },
                    {
                        "created": 1698754387.074362,
                        "modified": 1698754387.074362,
                        "name": "/dev/shm/incoming/2023_10_31_121242_00_00_V_091.kml",
                        "sha256": "89feb6cde33c942399168f86447bc92583174d78bb089eeba0853a9d984b9ce0",
                        "size": 982
                    },
                    {
                        "created": 1698754389.1743622,
                        "modified": 1698754389.1743622,
                        "name": "/dev/shm/incoming/2023_10_31_121242_00_00_V_091.jpg",
                        "sha256": "a374a1256886f0f1d5758500b20bfb01a55794952fb066ba195ec275a9b8f6d6",
                        "size": 940699
                    },
                    {
                        "created": 1698754378.4843621,
                        "modified": 1698754378.4843621,
                        "name": "/dev/shm/incoming/2023_10_31_121238_00_00_V_090.txt",
                        "sha256": "f165fbf7b771226fbc72b3d7a8d81f563dd528994d7350ee4eec2bcdd8b174fa",
                        "size": 2051
                    },
                    {
                        "created": 1698754378.264362,
                        "modified": 1698754378.264362,
                        "name": "/dev/shm/incoming/2023_10_31_121238_00_00_V_090.kml",
                        "sha256": "66bb17d86da12d8d9d30ba585496ed275022a275504691b71f265111318da9c5",
                        "size": 982
                    },
                    {
                        "created": 1698754378.124362,
                        "modified": 1698754378.124362,
                        "name": "/dev/shm/incoming/2023_10_31_121238_00_00_V_090.jpg",
                        "sha256": "387ed90d7bd32c19a1c5dd966142bc847a5e5279c627ada8ea4e9f86b4095604",
                        "size": 1047416
                    },
                    {
                        "created": 1698754348.294362,
                        "modified": 1698754348.294362,
                        "name": "/dev/shm/incoming/2023_10_31_121149_00_00_V_089.txt",
                        "sha256": "46cc4d8a525feda4965808c5bf7ffa2ac260a761fdc6537e0489443ce4e0c624",
                        "size": 2050
                    },
                    {
                        "created": 1698754336.264362,
                        "modified": 1698754336.264362,
                        "name": "/dev/shm/incoming/2023_10_31_121149_00_00_V_089.kml",
                        "sha256": "379dc7db811cf79338a2243fa9e0ce73f32b8a3e996e09242a67a068d8c6ae74",
                        "size": 985
                    },
                    {
                        "created": 1698754332.624362,
                        "modified": 1698754332.624362,
                        "name": "/dev/shm/incoming/2023_10_31_121149_00_00_V_089.jpg",
                        "sha256": "70bef28c580899c08db5c2b8f214226f1f02337453fa62c706734ce1ee88e9db",
                        "size": 1045335
                    },
                    {
                        "created": 1698754330.4443622,
                        "modified": 1698754330.4443622,
                        "name": "/dev/shm/incoming/2023_10_31_121131_00_00_V_088.txt",
                        "sha256": "8620f4926d10862eba4dfca558f912b1029135c1a9f0c87954bc09cc20a61db0",
                        "size": 2051
                    },
                    {
                        "created": 1698754321.554362,
                        "modified": 1698754321.554362,
                        "name": "/dev/shm/incoming/2023_10_31_121131_00_00_V_088.kml",
                        "sha256": "9b922d844ba59e2cde1d939a483f4204de8971153fb17d9e92f9e1651b2d1e04",
                        "size": 980
                    },
                    {
                        "created": 1698754329.8943622,
                        "modified": 1698754329.8943622,
                        "name": "/dev/shm/incoming/2023_10_31_121131_00_00_V_088.jpg",
                        "sha256": "0d4c5d3eee5b3e26da05294350762bfafb37e96da43ef737f4f5bd89afcdd935",
                        "size": 1162041
                    },
                    {
                        "created": 1698754318.9343622,
                        "modified": 1698754318.9343622,
                        "name": "/dev/shm/incoming/2023_10_31_121121_00_00_V_087.txt",
                        "sha256": "47847f42c466b74603a85da709a5cc22298c5f88abfc89c280e4fafc1156e583",
                        "size": 2051
                    },
                    {
                        "created": 1698754307.804362,
                        "modified": 1698754307.804362,
                        "name": "/dev/shm/incoming/2023_10_31_121121_00_00_V_087.kml",
                        "sha256": "dc6328ee6bf894982f06b9411bc07a7bd75ef1fc61558c4f04faabbe5b0cbfb6",
                        "size": 983
                    },
                    {
                        "created": 1698754307.104362,
                        "modified": 1698754307.104362,
                        "name": "/dev/shm/incoming/2023_10_31_121121_00_00_V_087.jpg",
                        "sha256": "8c2edfd6e9fa7d7f18fb87d1c1b9a108fc34c648bc5c124780721ef7aa97a547",
                        "size": 1991138
                    },
                    {
                        "created": 1698754307.1643622,
                        "modified": 1698754307.1643622,
                        "name": "/dev/shm/incoming/2023_10_31_121112_00_00_V_086.txt",
                        "sha256": "b8c705f96a089d1b564eeae377f8d5026ea537fe703f3330cee808b4170c3bd4",
                        "size": 2050
                    },
                    {
                        "created": 1698754306.7343621,
                        "modified": 1698754306.7343621,
                        "name": "/dev/shm/incoming/2023_10_31_121112_00_00_V_086.kml",
                        "sha256": "1980da381f429636322773b07e9da773aa70498d832fa0f14fb665fd17e7f733",
                        "size": 982
                    },
                    {
                        "created": 1698754305.1943622,
                        "modified": 1698754305.1943622,
                        "name": "/dev/shm/incoming/2023_10_31_121112_00_00_V_086.jpg",
                        "sha256": "cd0877b42731bffecef7a80504ff67861aed26d7dc29b78a652a34e05a48cbd6",
                        "size": 1115473
                    },
                    {
                        "created": 1698754304.604362,
                        "modified": 1698754304.604362,
                        "name": "/dev/shm/incoming/2023_10_31_121110_00_00_V_085.txt",
                        "sha256": "c13b1ef1d5b55c1c66a4b488c02384bbc5295381fc0e3ced8ecfa3fb60a60ef3",
                        "size": 2049
                    },
                    {
                        "created": 1698754303.7043622,
                        "modified": 1698754303.7043622,
                        "name": "/dev/shm/incoming/2023_10_31_121110_00_00_V_085.kml",
                        "sha256": "2140330bcd55e44ceb04749c50e3ecd930dc4dcd687b85569634ee3a7560d71f",
                        "size": 980
                    },
                    {
                        "created": 1698754303.554362,
                        "modified": 1698754303.554362,
                        "name": "/dev/shm/incoming/2023_10_31_121110_00_00_V_085.jpg",
                        "sha256": "f6535c2df0db60c23fafaf260fab46aa99b71d80ff96ed65d3fcf164ca5f09f5",
                        "size": 564521
                    },
                    {
                        "created": 1698754301.534362,
                        "modified": 1698754301.534362,
                        "name": "/dev/shm/incoming/2023_10_31_121107_00_00_V_084.txt",
                        "sha256": "516cc4145db36b1d35604dcbf8e8b2e02e1a9fc0a5fd44b9cb2f831133fe6db6",
                        "size": 2057
                    },
                    {
                        "created": 1698754293.4143622,
                        "modified": 1698754293.4143622,
                        "name": "/dev/shm/incoming/2023_10_31_121107_00_00_V_084.kml",
                        "sha256": "a019d5f29e86c313b375f53c3b7478faa83dec8a368bef46d9cbb064ea310158",
                        "size": 981
                    },
                    {
                        "created": 1698754290.794362,
                        "modified": 1698754290.794362,
                        "name": "/dev/shm/incoming/2023_10_31_121107_00_00_V_084.jpg",
                        "sha256": "e2aa1f603a8dd14e1b99796051fd6b932f5f26edba5b8b8354c955948669f76e",
                        "size": 963327
                    },
                    {
                        "created": 1698754298.4043622,
                        "modified": 1698754298.4043622,
                        "name": "/dev/shm/incoming/2023_10_31_121107_00_00_V_083.txt",
                        "sha256": "3503696ff5774639d1a304eadc71c66dbb0b02fa4c5ba33f09300587bbb9d23d",
                        "size": 2051
                    },
                    {
                        "created": 1698754289.7143621,
                        "modified": 1698754289.7143621,
                        "name": "/dev/shm/incoming/2023_10_31_121107_00_00_V_083.kml",
                        "sha256": "5f118de7c15d6c80133035e360f9bb8fcf779b66ed4b35c2fd30cd0994c96592",
                        "size": 983
                    },
                    {
                        "created": 1698754290.2043622,
                        "modified": 1698754290.2043622,
                        "name": "/dev/shm/incoming/2023_10_31_121107_00_00_V_083.jpg",
                        "sha256": "e7fcacf4f4b335e6dafffc0ffaadacb77fd1337eb186fd1779e84ae63ec46928",
                        "size": 959311
                    },
                    {
                        "created": 1698754288.034362,
                        "modified": 1698754288.034362,
                        "name": "/dev/shm/incoming/2023_10_31_121102_00_00_V_082.txt",
                        "sha256": "fa185a19602ab1c5c93c2cba677ed3a606e0c88af0ca9dd0dc9164a6b0c5e9e2",
                        "size": 2053
                    },
                    {
                        "created": 1698754287.624362,
                        "modified": 1698754287.624362,
                        "name": "/dev/shm/incoming/2023_10_31_121102_00_00_V_082.kml",
                        "sha256": "63ec6d29e1d65015aa3e933c9fa00bf9e254f76976564cc3358565ad9ee7bf8d",
                        "size": 983
                    },
                    {
                        "created": 1698754286.994362,
                        "modified": 1698754286.994362,
                        "name": "/dev/shm/incoming/2023_10_31_121102_00_00_V_082.jpg",
                        "sha256": "919c7ca52d05b67efb9eb47380077c8d95d6bfb0aa06dd231b4fad132fb869ca",
                        "size": 1006829
                    },
                    {
                        "created": 1698754284.524362,
                        "modified": 1698754284.524362,
                        "name": "/dev/shm/incoming/2023_10_31_121058_00_00_V_081.txt",
                        "sha256": "da6b58e940f7bd2a204545c8c1b9094d2f55a45bda31973d494a2959f96e9c9e",
                        "size": 2052
                    },
                    {
                        "created": 1698754274.754362,
                        "modified": 1698754274.754362,
                        "name": "/dev/shm/incoming/2023_10_31_121058_00_00_V_081.kml",
                        "sha256": "d47d222ea3c0e2ecb825cfe20e110a8c714aa4f49a797ce8266af5a9c8d6431b",
                        "size": 981
                    },
                    {
                        "created": 1698754278.4443622,
                        "modified": 1698754278.4443622,
                        "name": "/dev/shm/incoming/2023_10_31_121058_00_00_V_081.jpg",
                        "sha256": "e74b57e3aaabac24a291531255905e6451bbcfcc3ed242b347b10a9116d257e2",
                        "size": 1016035
                    },
                    {
                        "created": 1698754278.2343621,
                        "modified": 1698754278.2343621,
                        "name": "/dev/shm/incoming/2023_10_31_121054_00_00_V_080.txt",
                        "sha256": "66e482f07a80793e07fd5179871ded8b2cec0b5c96bd63e271d6a92358cf9bda",
                        "size": 2053
                    },
                    {
                        "created": 1698754272.4543622,
                        "modified": 1698754272.4543622,
                        "name": "/dev/shm/incoming/2023_10_31_121054_00_00_V_080.kml",
                        "sha256": "604fbff4118d4ed46567730b4c32ccca1a91db5298d58ab12acfa3f3c5878216",
                        "size": 982
                    },
                    {
                        "created": 1698754271.794362,
                        "modified": 1698754271.794362,
                        "name": "/dev/shm/incoming/2023_10_31_121054_00_00_V_080.jpg",
                        "sha256": "0eccbafcf4f6d0368d05fdc6c3590676fc2f23b1c6ce0d9321ca14fa99924f52",
                        "size": 1043115
                    },
                    {
                        "created": 1698754249.064362,
                        "modified": 1698754249.064362,
                        "name": "/dev/shm/incoming/2023_10_31_121030_00_00_V_079.txt",
                        "sha256": "00a40079dc725621be3d11dbb26c0a3fbf8be2ed2c5e7db55dfff51ada6ed4d7",
                        "size": 2051
                    },
                    {
                        "created": 1698754248.4143622,
                        "modified": 1698754248.4143622,
                        "name": "/dev/shm/incoming/2023_10_31_121030_00_00_V_079.kml",
                        "sha256": "422ae035f1a7f9d58dece7ef0858f4831d092a7220ac046d6e488d1418c3375d",
                        "size": 983
                    },
                    {
                        "created": 1698754248.024362,
                        "modified": 1698754248.024362,
                        "name": "/dev/shm/incoming/2023_10_31_121030_00_00_V_079.jpg",
                        "sha256": "f08b3b5db825f05b0e69943273be58f83276edc19900de5b0822cb597ecb6201",
                        "size": 1033723
                    },
                    {
                        "created": 1698754239.554362,
                        "modified": 1698754239.554362,
                        "name": "/dev/shm/incoming/2023_10_31_121018_00_00_V_078.txt",
                        "sha256": "161687589c5afa21614e104f273094e815d2392f93848832522b0291b87ef6e2",
                        "size": 2050
                    },
                    {
                        "created": 1698754241.304362,
                        "modified": 1698754241.304362,
                        "name": "/dev/shm/incoming/2023_10_31_121018_00_00_V_078.kml",
                        "sha256": "5b898fc85358f689da7f1ced65ee1363f6b12af00f18587bf831123171afaffd",
                        "size": 979
                    },
                    {
                        "created": 1698754237.7343621,
                        "modified": 1698754237.7343621,
                        "name": "/dev/shm/incoming/2023_10_31_121018_00_00_V_078.jpg",
                        "sha256": "f05498a9e5b65c6932fd70c621d342419ea50b5ac4525298935744a01c024f24",
                        "size": 1046628
                    },
                    {
                        "created": 1698754200.324362,
                        "modified": 1698754200.324362,
                        "name": "/dev/shm/incoming/2023_10_31_120932_00_00_V_077.txt",
                        "sha256": "73f0b72225c794aa36a269821e01209ced1d136e30a82d59a07cd5fc2536d56c",
                        "size": 2051
                    },
                    {
                        "created": 1698754191.084362,
                        "modified": 1698754191.084362,
                        "name": "/dev/shm/incoming/2023_10_31_120932_00_00_V_077.kml",
                        "sha256": "11b9ba5ac2edd1b6ccf55fef0c07542f8e301a1c7764222b4ab1549c555b4ae2",
                        "size": 983
                    },
                    {
                        "created": 1698754190.614362,
                        "modified": 1698754190.614362,
                        "name": "/dev/shm/incoming/2023_10_31_120932_00_00_V_077.jpg",
                        "sha256": "b50cf6b03c41008541327e82e478ba8d523b6144f63a4d40893eb03c53b2708c",
                        "size": 1328862
                    },
                    {
                        "created": 1698754180.504362,
                        "modified": 1698754180.504362,
                        "name": "/dev/shm/incoming/2023_10_31_120917_00_00_V_076.txt",
                        "sha256": "2f60592925930819863f2b753ba41179e830fc1670510d56e3a3fa887fcfb7d6",
                        "size": 2050
                    },
                    {
                        "created": 1698754188.7143621,
                        "modified": 1698754188.7143621,
                        "name": "/dev/shm/incoming/2023_10_31_120917_00_00_V_076.kml",
                        "sha256": "4f4488472bc7fdcbb99eee78568597406e7fcf75f5159be723b7368f18cb2054",
                        "size": 982
                    },
                    {
                        "created": 1698754187.594362,
                        "modified": 1698754187.594362,
                        "name": "/dev/shm/incoming/2023_10_31_120917_00_00_V_076.jpg",
                        "sha256": "e2d069b9197caa35bc8b161f742c2e33e44bffaed5109b336fc2b2a5439658b2",
                        "size": 858579
                    }
                ],
                "mission": "EFCA1",
                "sha256": "2652c3d301904f1a9c8fcb11600ad7b102849081b76ecdc5bec769301818c6a2",
                "size": 54849350
            },
            "errors": "",
            "filename": "2023-10-31T12-21-19Z",
            "size": 54849350,
            "verified_at": 1698781833
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698580375,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "iBQlcUUWH2B/lM5QeNtFDYUU4HYvHXbS4UIssDWjBQ3F6tikpZXVWu9Gsa3EdcE86NKE6FBUTvZ7n3vgXfQ/5W2U8c885joKJrMr8ACPhuxBXwwhhdz/fI7iJUhe1RewyT5u6/WJ2EPTtx17cYoveB1cnJNQTUy0VABfpiH0Yqks3yglx4O2n9Z9qSumKa41Rv+JFTpw+NTOyPHCchg63G+1ADS2ggeW5cu/3XHAltl54E4znbEPV73w2J+qOwLRjO8cYSJHUVa+r4P0jRGMwWkPgIz7/o5co9IdZfoanPzlplKyfxgN1xOlm7eJQkfwpZQ00LYUmdQpVGQSCQ/YxYHJumh7ZX1jK5YJDhVTHVA0R28oBpO+Ba6hGtb+T9s2n+MTyJDtq8a7ZX7mD6ZdsNR0DBHIGq8raGFCyOzaicYf6UGc+xTwfd6nmCY8mfxsdC6lAfoDynOiicasA0XGd1+xZzAE0nhQRaXzz+Db/53c5TxqPjd0xw/Z9ACUdsoa8kythXNPx/Z0ulKsF/1jm6yOk+jlgSNNj/MGLBJx1+qU01ZDaoO/nUbtZMq+DF4qu3gT4794dk347o2wDJe8oNWn9+qt/xBWdc8wGK0q9DP/SdVLBd3Hs4HL4/y9TK3psqWn5c1OcUOw8XfAibIkJiynUluI8ct9qkiJDfCbcwY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T11-52-37Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698580228.853234,
                        "modified": 1698580228.853234,
                        "name": "/dev/shm/incoming/2023-10-29T11:40:27.ais",
                        "sha256": "953e5d60041246a3bb47d915d490f77a98fa36adef0aa61ff72c910dec9fecb9",
                        "size": 286996
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "8bf86496361b48267bac52ec206effa7b964632be0f2487554830f3f5db9738c",
                "size": 86215
            },
            "errors": "",
            "filename": "2023-10-29T11-52-37Z",
            "size": 86215,
            "verified_at": 1698596557
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698679796,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "N8OUSQjXqRAnXNgkOyQugTFAc2M5Qd10+jZ3pDEhe8ua2+r5JgqDhIXXeEPjM5exFUEU4LHbxI/HCpp2XsZ7pFdi6sv0UAXGL3wrSQN6fuLSj5pqNl/FdOyllGGiCt8SfsqESF8vYed0SbU9bU2EIi0je7kKNWoU3xoVFalQYNciHrYJjEoI3p0wo5+JJeq9SLePWbia6nNV45JpRtJuBo2QN4Tag0kHJDE7LAEJuX7WAiJh5MiQzTByKjhbMWyPRERaV967/FW+SqCosj1LeGnKT3MuwZCau0+UZFOqRIYKMS6i/bFPtYVaooUT4qs+rx0EjaL6lNnfLXK1871k//I+x1VvCVYswLcfYVtWH1ep8iDKHMEvF8kBgRJC14hugBAqMoeDIMLy7mqfnmiba3jw6Kw89Qfjp1RX2/MOAXlfIQyOuC/usozkMAOd/rGXU5cN05ahzESTjxt/Y2jHAY7d2/Yegqhq02Hu9yiJbJuitwsmslABQmmcxefJT82AgPbSBdb4tO5qDOiDktK51PLtY21T1XeCvdBO9AQFWf9hl8mZsoB0DOxrE5Ui0l08A+eMlZqeJB59FZ5B0yIeC3HrWS6CxwmqYHV+cg+xMqxyJfAVjskBtom2GEdFPh31pomdCDr2r6IIEEp7f71BQJxdmpMW2Y3GqQM8ocW4K38=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T15-29-37Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698679652.3086982,
                        "modified": 1698679652.3086982,
                        "name": "/dev/shm/incoming/2023-10-30T15:17:29.ais",
                        "sha256": "422658b781ecdb26f562efad940d6acf21485eca84bead7aa037f3cbb5a81d32",
                        "size": 39470
                    }
                ],
                "mission": "OSP2",
                "sha256": "68b96cb07b71d1002bb52ea6e6b47d83a21b4e64c34fda9aa75b891a8bb191dc",
                "size": 11124
            },
            "errors": "",
            "filename": "2023-10-30T15-29-37Z",
            "size": 11124,
            "verified_at": 1698753173
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698679534,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "oexDV9m9wkATKyPhBzFCxQ8iBSRC2zezAhyKtlYbehFNncfOIGwgQoOcnEetzOrE6oMU9mjlqiXbhaecy5wOhwyHhVSkKnjlcRSt7mHLvGX9PFoTTk30J1pIbWijTuIfFIdTDXujuVNzkM9W5JsJkEpOxMIJO7N75Gwc8lFCKbt6sIaSxvUq63DUFKWroY613jUic8zsOAAajCG8rSdsMBEcnrODo+qnDTSKrE64y78JH2RTiXZVLvk6xt28Zk8Q/v5DQMUM01Qd5kpMM4UjOyrABpHSOBnnEpNdDlp965/gub3zPHmd8zPki5fWd0ioHYRjb1AeHmbQeKQHhyUrZlAA/5wAtTPRt1eAig+su4t4sYgWN7qxTx7z251oV1+Cqu2ZekkGTm4OXkGlq+WjP2pD3peZh0wuDcuxnQpicvSFjJ+re+DKjJFN7uEL6Bv44SFzYwVMEma3U8cQvhkwVfVg6fN9kTVLLP76fp0S2/zQJ1GWTzm/aYtGdUXMDxi2a/021QQIXh17k8CXS0pYPrcjscCt3YvZup29UW09LhYVlIL6/f7bsPK/XjAhn+NOuBldp8LaGmXDMQ4nSUMEAe9IYH4hmEtbJKOB948W8Ru5j3EqKsdrY3ZhRZwrPk5r98su8BRVeb+iuRwcagJwzF+tA7FXHeDPH+8Q07e/6tg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T15-25-16Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698679392.878698,
                        "modified": 1698679392.878698,
                        "name": "/dev/shm/incoming/2023_10_30_152250_00_03_V_013.txt",
                        "sha256": "309109bd6a6a4215d5727d9d865000cb923bab5f0449a66b56bb51b49a8d688a",
                        "size": 2028
                    },
                    {
                        "created": 1698679392.6986983,
                        "modified": 1698679392.6986983,
                        "name": "/dev/shm/incoming/2023_10_30_152250_00_03_V_013.kml",
                        "sha256": "a177a74b28e430b3fec43770f1e7fae46bcb41ee8dac53c58aadd7b8ed855a8c",
                        "size": 979
                    },
                    {
                        "created": 1698679392.1186981,
                        "modified": 1698679392.1186981,
                        "name": "/dev/shm/incoming/2023_10_30_152250_00_03_V_013.jpg",
                        "sha256": "b1698fb62b84bdbcc4ea5c20896e908afda73b91cd67e9e126e341cb0f5a4077",
                        "size": 1007785
                    }
                ],
                "mission": "OSP2",
                "sha256": "dd35bb88da7fc92918016fe401578d3e6300b3da013b6d87e1ec46c9ab62ffd7",
                "size": 1009564
            },
            "errors": "",
            "filename": "2023-10-30T15-25-16Z",
            "size": 1009564,
            "verified_at": 1698753176
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698685224,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "TrMrowbylmSpoO5AKq5V4/JwjlhDfcwQLxIXoEvKfBYJeWrQbD1A0FAuBV+hBikoXGPYoN4EY++3gYM+aPo12JWm3pS/TNGJnPrtLHwvU931mU3QCfWOBeXcblE3xDEa6Dr5I4QalRcbBCBfj2jiX6CmgkFtLu4aOq/UZivBRM9qP5Ksr7xNU/D3f/CfdNSjnlOajhO/nHXmDt/+6iCDfoiQEd/H2PO7HCncc7fAZIjttYU/cOo/l7m/NLKqIiz+p8UxikIzZCLHP2/edp81iiQ4V2vx1+dc4GwnHIn+jE0iEtJ6i3l7AnMLosvfoEKeDJ8Ezx1Y2Fh98Xi9n60a0+UIDh3t/xVQuiNAPtgwQ0ZTEeP1Ahg7PqCTZaFvo2De9SzrHk5hwVB4lLlrz363CL8Tf0pxBijy9yy8wN4sekh7z/KpS7HVpXbxKUiV90z+Q8P521thKVZf6X/ERSB/w4FX4sS1L2alhSu7o6+0ZTnZTB/90Sq20uRX0ZOHxTKqJNOPLb6YUiwT/D+Y9ugSmV57AY4W04FOFLGMXVef6gLhmXtsb41NxQt8Z14DiEfJmMywxA6Iil5lMYDQWGL/vwqjZd3IS9ihCk57gwEUv7HDG76UCMVW6iGrQzlwnJQRBOt1Gebv8+ylXfh215y/FQjCE5ta61zVfbiFY3i8nsI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T17-00-07Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698685079.0686982,
                        "modified": 1698685079.0686982,
                        "name": "/dev/shm/incoming/2023-10-30T16:47:58.ais",
                        "sha256": "564c49d53f67cd8af8fbe1116fe85a5e9138bcfba416d55b9b10c59bdd50ae76",
                        "size": 40965
                    }
                ],
                "mission": "OSP2",
                "sha256": "243cd510508e9fe38c9ab22bca41077e7b0dd017ee1c4c323a9ac9f2cba3983f",
                "size": 11136
            },
            "errors": "",
            "filename": "2023-10-30T17-00-07Z",
            "size": 11136,
            "verified_at": 1698753675
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698646282,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "UmItQKIReuQMCTKQCt3es9OlE1Rsus4fqwqrpZYa4SM9aApo/63dx2eoHQYy9JO2iDKktWizYYr8OgrsvfYQenP1q3UFyFRrkWmhvBC1rzHrAolae5YwsL2aWYTaZjtUvB2n+bYV8EV8cUzW1I7GYHyOIIgBVqPmHeCmT7s0Sf1pV32BZUpPykz+7s7qC+4ai0eF9SOVJwk3v3DhBq3wZavsl4gSpzvQDsNH9w3tOil9aAHkZsA3Hxd/Z2T9IfjO91yKF0KUcuxKrlFC5F5EU5495wlf5iBNi8pAFhVzzdm2WyD9GS27ks63OA1207OvI68e/KJ+TekGzTrgzwmxnqel8AWeHnTdR9qSracOpHB+khA7pNBz16gF+0PXnmF4By+mPPJE9iRoNrDvfhylRTgTrFrthTM11RvqeyW0HxhL3U5OpqAUlxmvTPfVs7UQx5L8x4r/UuHLMxsbmDb1O5cFsNcVqtxYhLwwXTPy5QyN48sI3oitDvueCZ3QzvDsxZsWkMAqT/3RnrIHP/AYfcWEP5fv7pQcBjRnLFW5xOL1kADlGqrjYlDjwcgsnLAAOR6mI7dcugm35S6uhjOxw2PVbuSPYKDLE+pv1dQLlfCLzJ18nt8OTahfe2Lf5c7oARjwdb83oYHJzSK1xxixSaMCzdJ9VW/fNAT8AnhRWHY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-10-52Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698646042.996036,
                        "modified": 1698646042.996036,
                        "name": "/dev/shm/incoming/2023-10-30T05:57:18.ais",
                        "sha256": "c82c3cb445d885fe3d22fef023e562cc35fb7aa3d65708f340ea44155ee96b1d",
                        "size": 274005
                    },
                    {
                        "created": 1698646123.636036,
                        "modified": 1698646123.636036,
                        "name": "/dev/shm/incoming/2023-10-30T06:04:46.ts",
                        "sha256": "148ee3cd6c656fa3745340292759f3c2042aee211d4318e8dc1b4d98bae64131",
                        "size": 52710124
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "3433b9d4d1c0c79b84e562b2e9a280373d27219caf06dca61d15e348ab6084ec",
                "size": 50949266
            },
            "errors": "",
            "filename": "2023-10-30T06-10-52Z",
            "size": 50949266,
            "verified_at": 1698665532
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698684794,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "pA7wbXxdjyWLoePFMisUfVGwuQjq6q7OgI5Ctl28E9ZdBPxpc025uMsEeqGjDA97GHrdPN87Z1GSoXc0m6RXX4OHzxdbJf9+l+IJD2/ooHcQ9smXiYRZMrz4gY2BAcuiGOs5e0i0r5ZmwAE4uQujhljavNKJ8SSRBqZ4T29RcOyCNrDxgPWDPYpCZUfc+2aj3NAbUDU5kJr8k3qFm7kpc+cWxrZxMeu+vLkPlGDmYE2yMrd6WASQOE+9BcLV7OqDdEQht3uNnOrPdKGZIPxwNhL4EgVI1MimwZ2BMv9C3p9AMgJzYmJ0uQ80UdOa87/od38albNQIOhMWQ4HIcXiVQTsu59FVm7XHvdgG1cIw3/ect+tnFBd9o/nVaFVDzEA5kgBEN8O6fzb4Xx/50HtvkAOGCBUCeJg/o5Bb9wfGEC1nv4dQSb6Jvm8SymJ2yogMGt9g7PGzypZeBl/3n0bap7ml90fZDXbB8q2fZDBPHBkhweHhusN1ZQvIFaqVRLabu6hBJ80PU9wXcTojLV+a4fmv8YGRHilEhYdiJiVZN3Z6/a2WVatWONSRenV4f1wb/6XEXsdeFHF66egBHHWHTAVTWPpClguIiBFjhR3++J5VT1E+Vqt8aT2T4o7r6BHeY9HE4IGKmx5HsNFVQOHRDkU2x0f7G9hjPvJyuE19TA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T16-52-14Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698684722.7586982,
                        "modified": 1698684722.7586982,
                        "name": "/dev/shm/incoming/2023-10-30T16:22:02.ts",
                        "sha256": "8e865bebdfac583dffe95121caf0336332c5cbacb99548abfcc71940a9a37863",
                        "size": 410682428
                    }
                ],
                "mission": "OSP2",
                "sha256": "cb0cd800c497fb4c3e8777b24e6eb438065c421418a498f817b05582a2720690",
                "size": 396704852
            },
            "errors": "",
            "filename": "2023-10-30T16-52-14Z",
            "size": 396704852,
            "verified_at": 1698754096
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698508603,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "bU9DI8UK+ySeEzRexVAbJI0XYN3SUroum03lJWhIZnBOeoqqgsfhm8IZfqRNf/6Om5SLXKsrT2+T6STz/9sL6rY1j7HXK/cn/qktAVqmQC+UQi+xHUNlftcztxRTdb4bIoIPScrgd2H6YBkhYFHQNW537S1WDg1wfNcDbvBYx1XBVXVaXIoUc6qxDGUeHOPgh4WkoOUb1r7L2N+r2ruzr+OhnlqmkB7nXSZaCryRLz/ZqiAC4VoN0EWyR+kkjisnnylvN8HwMcnFAXpBrf1gJLdUlSblcv6qzm9Z+sQpIWRaRXBaJ6nLKz+Fzw+v9bS4VSxH6tvUKxsXeozu6C7MslivdV4BUhlKm48foVPb6NBXPFJ0qdV5Fpakrxmc6DD5hXDfQTkwnpH/tTD5SNmpwv89QnXe8fig3LmaelIxCrlnA39L74dVcaFLHn0hUVT2dQfxavt3UeewNcQgGOSJH0ybKPGRGEHyyW7HAqByRgsQ+1KLFd+qRXM5u4yv/japEDBL/VmR6lehmvUwfENk3gubdOOCKQgRGYGjK6MCM91GYN8/Nx5s8sML5Vp6FK+YXsxxAy3D3argqBH6+b/0n6HBagQ0QveYbvYfnl8bxAp93GmXvn7JjaslD81xSV2aQIkAitRMdxHJnFDEDijY75zZd0StPd5chEJ3Qn+ZVGU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T15-56-23Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698508464.7728727,
                        "modified": 1698508464.7728727,
                        "name": "/dev/shm/incoming/2023_10_28_155355_00_00_V_069.txt",
                        "sha256": "a4910f1c422c2d079dab8fb8b9aaa5f8e8eba9c477627a1c482cd790dd339ae8",
                        "size": 2035
                    },
                    {
                        "created": 1698508468.6628728,
                        "modified": 1698508468.6628728,
                        "name": "/dev/shm/incoming/2023_10_28_155355_00_00_V_069.kml",
                        "sha256": "133ad1f9f04bd7927fffce829c7aaf36a792fc67ad67840c4c2c8b96925ce949",
                        "size": 977
                    },
                    {
                        "created": 1698508468.5228727,
                        "modified": 1698508468.5228727,
                        "name": "/dev/shm/incoming/2023_10_28_155355_00_00_V_069.jpg",
                        "sha256": "6ead3decaa92d76d88fcecf8194b25d1a0e7dfa9c83f83d3767b5584ef4c3f60",
                        "size": 945180
                    },
                    {
                        "created": 1698508427.9328728,
                        "modified": 1698508427.9328728,
                        "name": "/dev/shm/incoming/2023_10_28_155330_00_00_V_068.txt",
                        "sha256": "d7a26977d79a28c544c04d8dd80460b7e82f525b9c4102b760f60cc447d8e638",
                        "size": 2035
                    },
                    {
                        "created": 1698508443.8928728,
                        "modified": 1698508443.8928728,
                        "name": "/dev/shm/incoming/2023_10_28_155330_00_00_V_068.kml",
                        "sha256": "d2404b2a041207f230f504d0b9b97c936a3fc4a75d76ff0a3e25b48f55b2079c",
                        "size": 973
                    },
                    {
                        "created": 1698508435.0228727,
                        "modified": 1698508435.0228727,
                        "name": "/dev/shm/incoming/2023_10_28_155330_00_00_V_068.jpg",
                        "sha256": "7619fa526d5decdeed1fd2ee06576f0786cda7e60b1dad0aa138d053e9541753",
                        "size": 943287
                    },
                    {
                        "created": 1698508418.7228727,
                        "modified": 1698508418.7228727,
                        "name": "/dev/shm/incoming/2023_10_28_155306_00_00_V_067.txt",
                        "sha256": "b5629f9abcfcc82ae794ff72129ed059f612a2cf8d9a07fd1568407565d60d97",
                        "size": 2030
                    },
                    {
                        "created": 1698508408.5928729,
                        "modified": 1698508408.5928729,
                        "name": "/dev/shm/incoming/2023_10_28_155306_00_00_V_067.kml",
                        "sha256": "a6f9835be01e3f9cc59d24bea4a7a8680e76421b93ba75cd1f94581f0eee9bf6",
                        "size": 978
                    },
                    {
                        "created": 1698508409.7128727,
                        "modified": 1698508409.7128727,
                        "name": "/dev/shm/incoming/2023_10_28_155306_00_00_V_067.jpg",
                        "sha256": "4b2c9dede1eeb8e2bb1a6a50a71c1992b6a785c2f39cbec0e51d495f4d0c42d9",
                        "size": 1067181
                    },
                    {
                        "created": 1698508402.0928729,
                        "modified": 1698508402.0928729,
                        "name": "/dev/shm/incoming/2023_10_28_154747_00_00_V_066.txt",
                        "sha256": "5ca341edfe4eb99e5a3b7c48f65d92dc5e9c56e409d8632433a1f2955c2d8b86",
                        "size": 2036
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "fea0a0720e7dc844fe23c0a0b4df06d43edc42add93781097685bc5b610e78bf",
                "size": 2957388
            },
            "errors": "",
            "filename": "2023-10-28T15-56-23Z",
            "size": 2957388,
            "verified_at": 1698523391
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698508313,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "FIkbgj0HuxEqd5o0PDSQCESB/f8iQbqaA3FcwmW/30BJ8g1mOaLm4NQIvgRLIsyL4l6EHyfumdivMeqHicxHqnCghhRRW/SCtncXe1GHS/KD5HqS5/gmwCn8wmitkCOQUUi5IDJSbX+JeG/IYUBoyFhvvWOWTQmeSLSq0WZPGpXl0XmHQNo4UrN9n7N9hPOEtQSFRqVcjpk4UAXj1EMZfCm/WTG870kzi2f6ovMUCbP9L2IKiyDCZ6j6MxPfJP5CUpTDMlXmixVYE81Yg1OPqZqvMBPNftNua6yut1jlnz8lhdcihTSLP7h0S+0PCEdIhC7hd38NU5P5Cizb4NLfHev5p1aYXFu1J8yidl91hvDVxJ/5/eL12KouZ3gSn3PWaRJxks59IAmzs5rOD2+chn/kG9nt5IzU5x65nEy6jukJG9Hyn56IOXYXwEzd8kUPyyEs5V8JQ1ASlWR8pCcguoIxQrjZeMThbPJ62oY106lDxKD0cx07Jll0ROEHJp9Voh0zsPJWYzpaGK+O7gWFSPdM28NJGzbZdhjC1vXkg6jHNHXQjges9C0vd3iqiyU2MCMR4drAyXmSKDCA2E7fT8jM2dRKZmch+y4rhHBK+RCgKg1xGcD16es5xK6YW5S4Mp1xbpU5FMdg/25OL9T6GddgxgNJIjsoRgbwybjdtS8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T15-51-30Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698508166.5928729,
                        "modified": 1698508166.5928729,
                        "name": "/dev/shm/incoming/2023-10-28T15:39:25.ais",
                        "sha256": "1782f0c0e39ea99efb77e11f0e555c9561bf2bceb5c8dc22d89ef15d7fb6b8ca",
                        "size": 575859
                    },
                    {
                        "created": 1698508089.0128727,
                        "modified": 1698508089.0128727,
                        "name": "/dev/shm/incoming/2023_10_28_154747_00_00_V_066.txt",
                        "sha256": "5ca341edfe4eb99e5a3b7c48f65d92dc5e9c56e409d8632433a1f2955c2d8b86",
                        "size": 2036
                    },
                    {
                        "created": 1698508088.3828728,
                        "modified": 1698508088.3828728,
                        "name": "/dev/shm/incoming/2023_10_28_154747_00_00_V_066.kml",
                        "sha256": "c208845c0e2983b0931b1a693e03419385236451bd128c1642e487434c0b1338",
                        "size": 977
                    },
                    {
                        "created": 1698508087.9628727,
                        "modified": 1698508087.9628727,
                        "name": "/dev/shm/incoming/2023_10_28_154747_00_00_V_066.jpg",
                        "sha256": "7d1f08d5bc15bdd8ed3eb491caf0568876ff6e75582ee795fd5e43863846aef0",
                        "size": 1056871
                    },
                    {
                        "created": 1698508028.4028728,
                        "modified": 1698508028.4028728,
                        "name": "/dev/shm/incoming/2023_10_28_154643_00_00_V_065.txt",
                        "sha256": "ef04a844fd6e32f280b93e61ad8c1c73164b2b20a868150b4f2b7e9d8cdd62bc",
                        "size": 2028
                    },
                    {
                        "created": 1698508031.552873,
                        "modified": 1698508031.552873,
                        "name": "/dev/shm/incoming/2023_10_28_154643_00_00_V_065.kml",
                        "sha256": "88caa5eaccc14dc3e9a44293912a16c78a0fc44998740a383f70542be43cefa4",
                        "size": 976
                    },
                    {
                        "created": 1698508021.4828727,
                        "modified": 1698508021.4828727,
                        "name": "/dev/shm/incoming/2023_10_28_154643_00_00_V_065.jpg",
                        "sha256": "131bd0cdfef81256b4fb404857636d238cff45e14ecf59cfd61d6f5c43d3defa",
                        "size": 1129297
                    },
                    {
                        "created": 1698508027.4328728,
                        "modified": 1698508027.4328728,
                        "name": "/dev/shm/incoming/2023_10_28_154407_00_00_V_064.txt",
                        "sha256": "8b32f524035f177d2d91ab5e136b7b837364594b77d79e115ee42822e320759a",
                        "size": 2035
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "a16b8c23160beeec1cbc518ac9e6ab813154b8f00c845ba6822a17b2933421e9",
                "size": 2354192
            },
            "errors": "",
            "filename": "2023-10-28T15-51-30Z",
            "size": 2354192,
            "verified_at": 1698523554
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698676173,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "sZWpGPtpipdagXFyDaACZFDr+SBTBnxQOuwfwG/DvNeUn9CL3hmtKEtxzvo5Ykgmy4nlYTYW1nOCRNB1viBwNqm/++CWY01rucfd2wv2mHwP9r8Dvq4MNo7tESpIMfXK/P6UElPV40+RerS7+vO/+CWZ1McXMlLyzlSpH0jI/jIHvZLxOe5EQoCJjQbbDDCewGbuck3VV6qciiBU3dddmug8SdP9koc4fvm83cgt8NEV9XkmktSaMUEuUGQXaQBinaCbNdBj7CGtYX777wRPk8VWwZkA5k3VbL61qdaN8hubldJZKq2PROukWnWX6QQZtG88FUpGH+UUzMUz8a0fSepuiAvOQP6TeKxXFjdTwn504I0koblvJlv+GmMHDgCvJfMPPnYHyU52UuyMJnka0VakzHaDFbMMsjwpj2vmGaUGYgv7NNWKIgOZ2qvXiljT3NI3xMXNcc1/+vjMAgELA2+DzvUBc+d0XVYWQlSduuIo8CdnfTrZhIVj85WDsrQbLxjjfno2aSqdqgpz3U9uU1XdFCLAwiyEdAmhx3y4802vqFuII7pGASU3H3VK489KDYb3uTWTIaT/nCmG5PJtnCg6gBVEBSwhF28uBo698xNx8Czs7DBYu7iEvtqAEiVZjic802EM8ekOWu8xQpECFWHWHxe1VzKELzM9qVIoRqE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T14-29-15Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698676029.4886982,
                        "modified": 1698676029.4886982,
                        "name": "/dev/shm/incoming/2023-10-30T14:17:07.ais",
                        "sha256": "0c481e888ba0a1980838b1091dd6f2639219a1a312cac292f4a5aa6b2572056d",
                        "size": 98840
                    }
                ],
                "mission": "OSP2",
                "sha256": "5399f89f490fdaad20f86d1e7819a0f3ba27da90f1f170aa5202aa53ed7b6fb8",
                "size": 27503
            },
            "errors": "",
            "filename": "2023-10-30T14-29-15Z",
            "size": 27503,
            "verified_at": 1698753214
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698754105,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "twcPFqw7u1Vu6Ul2yZjvBSOAUaJC+EHiSo7AS1dVDFyFjk60f53Vsc70FsubdEdrgbEKteaqtC4PUlPcaieTobKyMoLbV+DyNCvm9O2EMJICIYBGpbwjZP907Cp8nsvrVycwlbmmM2QVDxVnVmzg+7mvctWpJlOQJAMVirt38ZjDHv3O/iQ3EtaXBBH+4Y7MEyylRUoZniFmUM1kfElGZB7UWycU0GbZsPGS/Kk07616QJUnrWxXijfApj3sarXzK69rxoGurhTs9tjqweSl0jvJfxFPmGZgITHsVG4kO7Og7CLLA/UOm7i0R0SRluML8pUK+cdiijlRxxpvdFMJozG4rcVjx/JNUZFUxcch6Zt3gljV3V/4np+m0g2jQVfXiToCYPX+zYijhoOkJYk+tVfMxBzf88jFbWN/Pi98jIAQFIJJ6zdCNjcYtjJ2YtwfZP0z5CGY6x+OzYUjat5ilBJuKCFG7Qjzkt4V2ZK1e6S+3WtBXvplLuH4B1A7IXNH0IYZjsxvSqFCBuL0OvyvCgDRnBDmeBuhAMnm0L4y5rEXSRqiKTYUHP7RE5kgqxJWiUhvOmwqZ+OGCLyMJpdcow2+ikxDOggx4PLj3GeQLg9Im8lWwhQJSW6g78qvAOmLYRisABlCx/+r5vlbDSXZ470jEW3n+PiQ+UDUuv2nVOw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-08-12Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698753965.7914186,
                        "modified": 1698753965.7914186,
                        "name": "/dev/shm/incoming/2023_10_31_120544_00_01_V_072.txt",
                        "sha256": "9b654cfb7db402c5295d21e1a64400164991419e225cacebdafc631c3e517776",
                        "size": 2054
                    },
                    {
                        "created": 1698753965.6314185,
                        "modified": 1698753965.6314185,
                        "name": "/dev/shm/incoming/2023_10_31_120544_00_01_V_072.kml",
                        "sha256": "02be3dc55d44e36f7bac31cb83c37318638e99470a14f86f4ecc73583db8ce63",
                        "size": 950
                    },
                    {
                        "created": 1698753965.5014186,
                        "modified": 1698753965.5014186,
                        "name": "/dev/shm/incoming/2023_10_31_120544_00_01_V_072.jpg",
                        "sha256": "528cede078dadd47c0cd775ca811adedf00d7b1b811fcdeb06c30f1c548c4bad",
                        "size": 1741712
                    },
                    {
                        "created": 1698753964.4114184,
                        "modified": 1698753964.4114184,
                        "name": "/dev/shm/incoming/2023_10_31_120543_00_01_V_071.txt",
                        "sha256": "b5bb5c2d31189ba45f187cb7b11c90b58159cb491cfdb81a6295634b59227a6f",
                        "size": 2058
                    },
                    {
                        "created": 1698753963.7414186,
                        "modified": 1698753963.7414186,
                        "name": "/dev/shm/incoming/2023_10_31_120543_00_01_V_071.kml",
                        "sha256": "7124b05c9b60f505fe40b65785950850a73650a594cd8a6e62455c4ef8866299",
                        "size": 950
                    },
                    {
                        "created": 1698753963.5314186,
                        "modified": 1698753963.5314186,
                        "name": "/dev/shm/incoming/2023_10_31_120543_00_01_V_071.jpg",
                        "sha256": "ba5d168622a89ba08b297f2667437d1c8cc6567dcb02c14ccd709311f9fc8087",
                        "size": 1727909
                    },
                    {
                        "created": 1698753952.1514184,
                        "modified": 1698753952.1514184,
                        "name": "/dev/shm/incoming/2023_10_31_120527_00_01_V_070.txt",
                        "sha256": "88829d528cc270732ac75af6840a142bcf287007f0f974587afd47662493c4ba",
                        "size": 2053
                    },
                    {
                        "created": 1698753951.4614184,
                        "modified": 1698753951.4614184,
                        "name": "/dev/shm/incoming/2023_10_31_120527_00_01_V_070.kml",
                        "sha256": "b006b72392152e37e3840b31467397101870c68966fb1ce71831c2a8d11edb67",
                        "size": 953
                    },
                    {
                        "created": 1698753951.3714185,
                        "modified": 1698753951.3714185,
                        "name": "/dev/shm/incoming/2023_10_31_120527_00_01_V_070.jpg",
                        "sha256": "059a5bbf314597a8b3a1b6c7dc5621b2f9771ee0b362c9db745b00bf5aac24e0",
                        "size": 1718632
                    },
                    {
                        "created": 1698753939.8014185,
                        "modified": 1698753939.8014185,
                        "name": "/dev/shm/incoming/2023_10_31_120526_00_01_V_069.txt",
                        "sha256": "05aaff8afc508609a126009dda17e33499c2c988d0b0b9d411bd4e2ba153dbce",
                        "size": 2054
                    },
                    {
                        "created": 1698753939.3114185,
                        "modified": 1698753939.3114185,
                        "name": "/dev/shm/incoming/2023_10_31_120526_00_01_V_069.kml",
                        "sha256": "6d54c013e8f1dd3edf3d50a8dced26d82bfda8d2feabdb1d4dbf480baf46011c",
                        "size": 951
                    },
                    {
                        "created": 1698753939.1914184,
                        "modified": 1698753939.1914184,
                        "name": "/dev/shm/incoming/2023_10_31_120526_00_01_V_069.jpg",
                        "sha256": "33a466195c1cb7df69f84a0a45bf4c667764e1f0af96ee1fe8116e089029e9f8",
                        "size": 1606739
                    },
                    {
                        "created": 1698753917.0214186,
                        "modified": 1698753917.0214186,
                        "name": "/dev/shm/incoming/2023_10_31_120453_00_01_V_068.txt",
                        "sha256": "0857e2d619f0d38756bdbef6a9869937a95500f4310bc720932059d42b70a4d7",
                        "size": 2054
                    },
                    {
                        "created": 1698753916.7614186,
                        "modified": 1698753916.7614186,
                        "name": "/dev/shm/incoming/2023_10_31_120453_00_01_V_068.kml",
                        "sha256": "f8c408b1cd228713cc1eb4b7b233b7777f37f31e5b1c83577e076e68bbb7790b",
                        "size": 951
                    },
                    {
                        "created": 1698753916.3814185,
                        "modified": 1698753916.3814185,
                        "name": "/dev/shm/incoming/2023_10_31_120453_00_01_V_068.jpg",
                        "sha256": "888c32b014367d0936547cbeb9f1367e8509e493c4158630270221119e8652d8",
                        "size": 1322539
                    },
                    {
                        "created": 1698753915.7514186,
                        "modified": 1698753915.7514186,
                        "name": "/dev/shm/incoming/2023_10_31_120448_00_01_V_067.txt",
                        "sha256": "cd99980b45c523a9702794f56b81e413f56a4e5304adea8938358475a75a4fc2",
                        "size": 2058
                    },
                    {
                        "created": 1698753904.7014184,
                        "modified": 1698753904.7014184,
                        "name": "/dev/shm/incoming/2023_10_31_120448_00_01_V_067.kml",
                        "sha256": "6b9eb8090a98ead7419f869b08dbdfbfff2ef5166b1f7827decbd93fc71046f8",
                        "size": 951
                    },
                    {
                        "created": 1698753904.6014185,
                        "modified": 1698753904.6014185,
                        "name": "/dev/shm/incoming/2023_10_31_120448_00_01_V_067.jpg",
                        "sha256": "b2bd109e0a70987376997145eb01d764605aebadaf7a9848577e44a42f264ff5",
                        "size": 1280540
                    }
                ],
                "mission": "OSP1",
                "sha256": "ecb0c865f9f41da678a316a10ae698ec5c8019b82c53be22eea0a3f116952dbe",
                "size": 9408773
            },
            "errors": "",
            "filename": "2023-10-31T12-08-12Z",
            "size": 9408773,
            "verified_at": 1698766361
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698756153,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "sOd9dKcm3F1pvFyzLxwDUIGCJbIhGOWRgftpErvg5p6F1TIu/CiMhoi5Tvd2Vj7uKEq/jeQBShsmWSL5smcafwXbK413jzg2udoZJdUDB/WW0MHaSDMp2lyCtCH/AXIVFsR8vVw4VtxnDrkbtyJQWtxpx1CpW9Zm2kJWBxox2+CDg45dh5J9c0RKapSaSU5yGfMmZRu8YdcMr84qScXE/35OgFy1SUZWHsDoHItAAHEeNYTIMfLl5pDBrge4/Fnj7lCOObEIWbujFh6PQkficWecQb7VZn7CmPrmgMBaMLGtlRt0uVHQe+WbIWE+4ucIjV/O2BJLsqBqCtQmnFT88x2zSv2axIokagqdGHyiCueK04VoGL8VEGBCXdsQsWIIMqkW1P2RaiN4cDin5kaj+T9XNXFsZfDoB+G7zWsRzmhnPK5lzqD3vflcCKOwui+t4UFv5ULuj6wymC8zRZ8EeqCIbLMj3Wbg+BIcPn3Hjuw95siMvJ3ppGXo5VJ0fpMxrCfitgDy+2rppzRkZbce+6kCrANVddixzsCuGJ8H3WIAf52m5wFUK+O6OIVDA4viWWMJXorHP/UeoOky7Df0ubDyy8atxPcS6BOP2kz44tXgODpUzOO3qSvlcgYuKs0Qfnz5aAS3dXsRdG7pkUWNQA3fRxvQK915Q8wXyr2+xE4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-42-24Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698756016.9043622,
                        "modified": 1698756016.9043622,
                        "name": "/dev/shm/incoming/2023_10_31_123953_00_00_V_158.txt",
                        "sha256": "e91a7bd0996fbef9d31eaf9fe8bc8382491f86a00c23071746a94bff26ec9550",
                        "size": 2049
                    },
                    {
                        "created": 1698756032.304362,
                        "modified": 1698756032.304362,
                        "name": "/dev/shm/incoming/2023_10_31_123953_00_00_V_158.kml",
                        "sha256": "4a842adb1df5d3737fb27d27086a26f2bb8a768fe19d4e46efa79ab49f7c4656",
                        "size": 980
                    },
                    {
                        "created": 1698756020.4343622,
                        "modified": 1698756020.4343622,
                        "name": "/dev/shm/incoming/2023_10_31_123953_00_00_V_158.jpg",
                        "sha256": "854907481ef0bcc7d5f118bebdc92b30993e41c48635c2f07694b4c08cc328e0",
                        "size": 966756
                    },
                    {
                        "created": 1698756003.9743621,
                        "modified": 1698756003.9743621,
                        "name": "/dev/shm/incoming/2023_10_31_123944_00_00_V_157.txt",
                        "sha256": "845aacb556166c62a2c46212ac6a4e243a4a47a132f3c7c40d47bc1322029082",
                        "size": 2050
                    },
                    {
                        "created": 1698756002.1843622,
                        "modified": 1698756002.1843622,
                        "name": "/dev/shm/incoming/2023_10_31_123944_00_00_V_157.kml",
                        "sha256": "ea989edbbcee8c872c6ad559e77633ca957cabbc16eb6020244af81bfaed17d2",
                        "size": 983
                    },
                    {
                        "created": 1698756001.854362,
                        "modified": 1698756001.854362,
                        "name": "/dev/shm/incoming/2023_10_31_123944_00_00_V_157.jpg",
                        "sha256": "749e00395ee0b103f8a28413cd1357bf44e14ffbd7a9e4c4a2b1f70490efa558",
                        "size": 1302169
                    },
                    {
                        "created": 1698756002.274362,
                        "modified": 1698756002.274362,
                        "name": "/dev/shm/incoming/2023_10_31_123941_00_00_V_156.txt",
                        "sha256": "b82474a0ad027bf7554377422de3b80855a981076cef35b0816b068a25425f02",
                        "size": 2051
                    },
                    {
                        "created": 1698756000.784362,
                        "modified": 1698756000.784362,
                        "name": "/dev/shm/incoming/2023_10_31_123941_00_00_V_156.kml",
                        "sha256": "87ac5b4351aa73ccf973fec796bbe597f16c40784ebbaa90ed1e86fb9e63f46a",
                        "size": 982
                    },
                    {
                        "created": 1698755999.374362,
                        "modified": 1698755999.374362,
                        "name": "/dev/shm/incoming/2023_10_31_123941_00_00_V_156.jpg",
                        "sha256": "39f849281805cb3255f040405c7334c6b349b1aa0ad69d956101ccfdb20ea3f6",
                        "size": 1218177
                    },
                    {
                        "created": 1698755941.754362,
                        "modified": 1698755941.754362,
                        "name": "/dev/shm/incoming/2023_10_31_123826_00_00_V_155.txt",
                        "sha256": "23dbcb5cc3084a67730dd1d72dc2b162acfb611f2a596d6289b3f3b427c47f45",
                        "size": 2051
                    },
                    {
                        "created": 1698755933.854362,
                        "modified": 1698755933.854362,
                        "name": "/dev/shm/incoming/2023_10_31_123826_00_00_V_155.kml",
                        "sha256": "8fda75a9a4e5dfe642b9eda3d8d4c31019d3e5f79be9e3de93d3bb6d225520a9",
                        "size": 981
                    },
                    {
                        "created": 1698755940.9443622,
                        "modified": 1698755940.9443622,
                        "name": "/dev/shm/incoming/2023_10_31_123826_00_00_V_155.jpg",
                        "sha256": "3a8e1861d0eeb3fbcc21c1f4fb9c5bf6095ee8035a8d9540a99beed4ae6d54df",
                        "size": 974993
                    },
                    {
                        "created": 1698755920.6743622,
                        "modified": 1698755920.6743622,
                        "name": "/dev/shm/incoming/2023_10_31_123814_00_00_V_154.txt",
                        "sha256": "900432a00e259f2d6f43fc04ba7d6bf20c6651c449731ffe6bdfa95acd642715",
                        "size": 2052
                    },
                    {
                        "created": 1698755923.634362,
                        "modified": 1698755923.634362,
                        "name": "/dev/shm/incoming/2023_10_31_123814_00_00_V_154.kml",
                        "sha256": "d798166e650a8177eb68f44d59491554c79d15c19b2c689cad606a382b470fd6",
                        "size": 982
                    },
                    {
                        "created": 1698755924.624362,
                        "modified": 1698755924.624362,
                        "name": "/dev/shm/incoming/2023_10_31_123814_00_00_V_154.jpg",
                        "sha256": "8e18d20214178164470e984e269bf56dba75f7669abb7134ebbb839fdf5a3bbe",
                        "size": 999545
                    },
                    {
                        "created": 1698755915.994362,
                        "modified": 1698755915.994362,
                        "name": "/dev/shm/incoming/2023_10_31_123804_00_00_V_153.txt",
                        "sha256": "8b91daa8c14306d2e8717ef17547f6f6bee0b6c87d4a272e9627861df71f0c2b",
                        "size": 2053
                    },
                    {
                        "created": 1698755916.1443622,
                        "modified": 1698755916.1443622,
                        "name": "/dev/shm/incoming/2023_10_31_123804_00_00_V_153.kml",
                        "sha256": "bd543e9b1b1bc101a29368dbfd724e518ed0d31aa8bd0a4b47c533e57eb4769b",
                        "size": 983
                    },
                    {
                        "created": 1698755905.1843622,
                        "modified": 1698755905.1843622,
                        "name": "/dev/shm/incoming/2023_10_31_123804_00_00_V_153.jpg",
                        "sha256": "ffa916fbdf50383532940b4c3b34a95dfe1a38b1b0bd7ee494332ac4063f8a2f",
                        "size": 1074914
                    },
                    {
                        "created": 1698755904.1743622,
                        "modified": 1698755904.1743622,
                        "name": "/dev/shm/incoming/2023_10_31_123803_00_00_V_152.txt",
                        "sha256": "90f6b6c0d291d7bae2eaccb9ef27abd6c928dd56de0d283adf7106f278f3b6f3",
                        "size": 2052
                    },
                    {
                        "created": 1698755902.9743621,
                        "modified": 1698755902.9743621,
                        "name": "/dev/shm/incoming/2023_10_31_123803_00_00_V_152.kml",
                        "sha256": "da64dfafd05ece529ff5c003413a639d67ce70806b2fa084dc66f438a9c8e867",
                        "size": 979
                    },
                    {
                        "created": 1698755904.314362,
                        "modified": 1698755904.314362,
                        "name": "/dev/shm/incoming/2023_10_31_123803_00_00_V_152.jpg",
                        "sha256": "6724b6931e54e9d6f9d44d472160af40990afeb209acfbe8c53348176dabdbf8",
                        "size": 1127407
                    },
                    {
                        "created": 1698755901.044362,
                        "modified": 1698755901.044362,
                        "name": "/dev/shm/incoming/2023_10_31_123754_00_00_V_151.txt",
                        "sha256": "86f96400903ab909ab4f0d9c7113467f301c6a14e3b8d0c05f651e538419bc02",
                        "size": 2057
                    },
                    {
                        "created": 1698755895.544362,
                        "modified": 1698755895.544362,
                        "name": "/dev/shm/incoming/2023_10_31_123754_00_00_V_151.kml",
                        "sha256": "0e6a3c6fdd0aaf599b90a378dc47cba1450e6b8793cbbc32d3ddcf3c8af7ea4b",
                        "size": 983
                    },
                    {
                        "created": 1698755891.114362,
                        "modified": 1698755891.114362,
                        "name": "/dev/shm/incoming/2023_10_31_123754_00_00_V_151.jpg",
                        "sha256": "f7139980a05f2a0107204ec02f720aae8b59693512e1a6a6b97744dce95f6970",
                        "size": 967946
                    }
                ],
                "mission": "EFCA1",
                "sha256": "c5aea3b0f15d9510a687108c79aeb060678a85be6054a4551335c858a8e536f2",
                "size": 8623668
            },
            "errors": "",
            "filename": "2023-10-31T12-42-24Z",
            "size": 8623668,
            "verified_at": 1698781633
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698690041,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "BgxoOyAOxVJAi8Ig+nQzFY+56a2sdDs6N5ZVW9HREVcwkx7f4x8rE+FY6mAEU8oa4UEd1rqnjXs/fd/OOeUkqDbPRz+Z6b0kanLIt0NyJo9ni7G1LLJ+4gxVE7NfNta04GWJqtiV31yjwYRItAxfINvmwOGIusmJPx43PrETrAnp4Qli8DdvAvKXUD0H7bhQEJYMBFFMw/Q7Hz4cEQrvc3CYyotxeJYJWerYPtSRQveKjj+w2xrJYy9D9P9wfiDquQVUXJqrzZ+KOcu7x8nt/kTPKyq5yadjjUjLA3kiPZ7N26l2p07O1yAhMKkHn8sUZgg/6DsXe33trUFfgzyivW9gH56Vbn86ABIXLzn/8kCXKC6IhiMuK9YlhmoZx7Qaofv5zB4GG2MCMiZ3FmWY8BoNg/8mlYq/rHlIxPWWGKBhjrQELVJkzfuDWF4QWPMjpoEeAKgO0RlT7xgTYZdiWEJpiuUpqo71EwANEOeDmhsDUvvIp6E+XhLZucg8s7VjseIVy4/4FuU8drf3HTnsWWIwM7YyJrGL3IXD07Wn5dzVaoHMp1uS/W6DwADJDQ5JII4hPwBnVc6CUXp4C0vGyBdkmf10TPQRdQWr/lz8SJbMuIYpG9uQgaRTQb6ipUPDA1plJrTFLSHyoXpEv5vMoa++ccvRJcZd+uz0M097dJM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T18-20-24Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698689900.9386983,
                        "modified": 1698689900.9386983,
                        "name": "/dev/shm/incoming/2023-10-30T18:08:18.ais",
                        "sha256": "c020d8abab92ff1db0e4392fdd969bd5f6c5d0fc9502747976455e45ff01a266",
                        "size": 36635
                    }
                ],
                "mission": "OSP2",
                "sha256": "416afc1768a000c0b682eac0125d221ef3dfbad769eb3e24443a10f341047c2b",
                "size": 9998
            },
            "errors": "",
            "filename": "2023-10-30T18-20-24Z",
            "size": 9998,
            "verified_at": 1698754129
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698753733,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "SWLmowsywkIpBBp4m0GEVZwIVONF9tq1sS9dkKTuoIjqV2WvgpMjZ8E8ciJbY1g4TgnQxtLff73HSLTRBqiyv271XWKZdcUG1Olkkusd7oyYRFn71t8m7Yb6Xtu/HgrW1YtTR7q1dqpVjlUs+FxcJwrT8Iw4l8oXeUYX7XsM8Yq8qTn3ROs+Hp9mBEzyFjh+AL0a8cuxW2uLxCnoAugNcmA0vrQgoeHx3L5jw9xuaq0Z4fbCN6Q4EZop9fUXqR4d8ppOHmq80vYxU/r5hUNKSLEKcxS2hrnAhGWqqruFSa5ZDSOpQLfB6Ix/O36BA40VrCoLAjNlDRmf4vgNHaIx5Ly8vhinuaSrQqGISwKPQJ24fg61s0nDK6mRF4XZk5RH4Wg1gL2Ersy1wdyRwr5zyxlSfHRawsbkgJuhuPepjZxdd0EXCdzZAmKox8MPZJxm78fVgUcpOtzkF8sTHhUaWvwkEl97fWhwkUvpY56uVMBq2HmtNsfms3DbJY5g2hhiVCKD2qqQfliTDMKWIb0QB4uN2vojuqmlnHevlPFgy8J3EsCq5YZIcvc6Z+Yd54qlvxO+uNDPRKjEk8aWbgq5vccut7k8JPz1bMucaT5lURy46NnZEvhjnIp4Sb5T1/Jp49uX8CFmRm/GbXTR5rZn/6FoHJPEYLtt5ZZ9H8dg1n4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-01-09Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698753668.564362,
                        "modified": 1698753668.564362,
                        "name": "/dev/shm/incoming/2023_10_31_120030_00_00_V_073.txt",
                        "sha256": "c9fb043935d1f1d83d0692bf886b8704208a939cca906991780e484066a277f5",
                        "size": 2054
                    },
                    {
                        "created": 1698753654.314362,
                        "modified": 1698753654.314362,
                        "name": "/dev/shm/incoming/2023_10_31_120030_00_00_V_073.kml",
                        "sha256": "c9b77b3c4e2c91fc369576d60e039c24612fe7b3695713e156f7cd8b0280b380",
                        "size": 983
                    },
                    {
                        "created": 1698753660.564362,
                        "modified": 1698753660.564362,
                        "name": "/dev/shm/incoming/2023_10_31_120030_00_00_V_073.jpg",
                        "sha256": "e9b450bb31937984b8adabc0e2a9a0d12e2f904ffff82fb6135c903497f817c0",
                        "size": 790294
                    },
                    {
                        "created": 1698753648.554362,
                        "modified": 1698753648.554362,
                        "name": "/dev/shm/incoming/2023_10_31_120011_00_00_V_072.txt",
                        "sha256": "eccbd3792f526904c9bc2339dbe81410d7dbd384e96e7b8a00b69275ff0d21b9",
                        "size": 2053
                    },
                    {
                        "created": 1698753645.1843622,
                        "modified": 1698753645.1843622,
                        "name": "/dev/shm/incoming/2023_10_31_120011_00_00_V_072.kml",
                        "sha256": "ebbf8622b767910eb5aa603e1a38da7041311a9e0c99a256f9e457f66ea1863c",
                        "size": 982
                    },
                    {
                        "created": 1698753661.004362,
                        "modified": 1698753661.004362,
                        "name": "/dev/shm/incoming/2023_10_31_120011_00_00_V_072.jpg",
                        "sha256": "728ab4ffe12ae4e91a5b1f8fb6d645092fba293f2636a5a892043d0e5a7c89a7",
                        "size": 814108
                    },
                    {
                        "created": 1698753636.9743621,
                        "modified": 1698753636.9743621,
                        "name": "/dev/shm/incoming/2023_10_31_120006_00_00_V_071.txt",
                        "sha256": "ef5524b07922bea1c257ef1b72753c20f1363bb10b0f96a85fd6f205c5db0804",
                        "size": 2053
                    },
                    {
                        "created": 1698753636.604362,
                        "modified": 1698753636.604362,
                        "name": "/dev/shm/incoming/2023_10_31_120006_00_00_V_071.kml",
                        "sha256": "0b9e969db163ebda636afce06ae0a2c2a25d2831ad4c09c72691462f448f273d",
                        "size": 982
                    },
                    {
                        "created": 1698753635.9343622,
                        "modified": 1698753635.9343622,
                        "name": "/dev/shm/incoming/2023_10_31_120006_00_00_V_071.jpg",
                        "sha256": "22567fdcd844fb087a043e29e791cf2c00ace24c65d75f9a6c5d46b53365a76c",
                        "size": 857966
                    },
                    {
                        "created": 1698753626.344362,
                        "modified": 1698753626.344362,
                        "name": "/dev/shm/incoming/2023_10_31_115952_00_00_V_070.txt",
                        "sha256": "bc40dae94954468eac855d8cc5498e3b144c3dd9ea636cdfaa2c1edaeafb38ca",
                        "size": 2054
                    },
                    {
                        "created": 1698753635.564362,
                        "modified": 1698753635.564362,
                        "name": "/dev/shm/incoming/2023_10_31_115952_00_00_V_070.kml",
                        "sha256": "298dabd7d3ffaac7b8b13f08ab84bee220a63950667eebf3c0d31acc20ec3bc5",
                        "size": 983
                    },
                    {
                        "created": 1698753633.354362,
                        "modified": 1698753633.354362,
                        "name": "/dev/shm/incoming/2023_10_31_115952_00_00_V_070.jpg",
                        "sha256": "78cb2b59f46a17de2c6d9a09d37f6029569e941c96e2bdea3b7e972c364fab69",
                        "size": 946597
                    },
                    {
                        "created": 1698753622.374362,
                        "modified": 1698753622.374362,
                        "name": "/dev/shm/incoming/2023_10_31_115949_00_00_V_069.txt",
                        "sha256": "1676f3882902c22ec12ce518a83590f72d5f4b8d4ec5ecdcc743774aae5d726f",
                        "size": 2050
                    },
                    {
                        "created": 1698753622.114362,
                        "modified": 1698753622.114362,
                        "name": "/dev/shm/incoming/2023_10_31_115949_00_00_V_069.kml",
                        "sha256": "9bb27587bea9ee339940a82a2f9ec5cc39f049f02fd1005ec89dd521dd7ccfad",
                        "size": 983
                    },
                    {
                        "created": 1698753621.9843621,
                        "modified": 1698753621.9843621,
                        "name": "/dev/shm/incoming/2023_10_31_115949_00_00_V_069.jpg",
                        "sha256": "87c87a1118c9432afa075af377ce1ad6b129c66b9e23f8ca1a5a123faac85363",
                        "size": 945385
                    },
                    {
                        "created": 1698753621.064362,
                        "modified": 1698753621.064362,
                        "name": "/dev/shm/incoming/2023_10_31_115947_00_00_V_068.txt",
                        "sha256": "29bdf09fca07b67a6cebc9bec69f816607ccbbeb00eb7ad5b6597413283ac79e",
                        "size": 2052
                    },
                    {
                        "created": 1698753620.9143622,
                        "modified": 1698753620.9143622,
                        "name": "/dev/shm/incoming/2023_10_31_115947_00_00_V_068.kml",
                        "sha256": "a3348f012c2de74b4b20f023ef9d8f205621cd9f3b6c74db74adb91e2dfef946",
                        "size": 982
                    },
                    {
                        "created": 1698753620.254362,
                        "modified": 1698753620.254362,
                        "name": "/dev/shm/incoming/2023_10_31_115947_00_00_V_068.jpg",
                        "sha256": "36cda88bd27ea46f658b3056995fe62237b89ecc771cc3f7dffd9886e35f91ee",
                        "size": 883344
                    },
                    {
                        "created": 1698753614.264362,
                        "modified": 1698753614.264362,
                        "name": "/dev/shm/incoming/2023_10_31_115941_00_00_V_067.txt",
                        "sha256": "1a7158c04b66d1842f958ddaa7b2fa29760d36ceb0a71be92caffe530c3c16cd",
                        "size": 2059
                    },
                    {
                        "created": 1698753613.774362,
                        "modified": 1698753613.774362,
                        "name": "/dev/shm/incoming/2023_10_31_115941_00_00_V_067.kml",
                        "sha256": "5d5dd9d36b00b3b44d303b8e1362f1346a88d9048652b608a7335f3333f7a424",
                        "size": 983
                    },
                    {
                        "created": 1698753613.634362,
                        "modified": 1698753613.634362,
                        "name": "/dev/shm/incoming/2023_10_31_115941_00_00_V_067.jpg",
                        "sha256": "e93cc5176c3fc6fdfc7b6d005b466e0460c957ba12c8048f0daaca1631e5b0d2",
                        "size": 893269
                    },
                    {
                        "created": 1698753607.354362,
                        "modified": 1698753607.354362,
                        "name": "/dev/shm/incoming/2023_10_31_115941_00_00_V_066.txt",
                        "sha256": "0c1a22c468850fa6ae44f3345523ed413d69407920dda13b24600d04dce54dfd",
                        "size": 2059
                    },
                    {
                        "created": 1698753607.774362,
                        "modified": 1698753607.774362,
                        "name": "/dev/shm/incoming/2023_10_31_115941_00_00_V_066.kml",
                        "sha256": "e74fbdad81c031a8e9fde3eb263f517aa203593009e250d0e89913abaab6f78a",
                        "size": 984
                    },
                    {
                        "created": 1698753606.314362,
                        "modified": 1698753606.314362,
                        "name": "/dev/shm/incoming/2023_10_31_115941_00_00_V_066.jpg",
                        "sha256": "ad094ea516821be46336ee73aee2c3f59da8e138d56146aa75662299fafd5094",
                        "size": 901789
                    },
                    {
                        "created": 1698753605.2343621,
                        "modified": 1698753605.2343621,
                        "name": "/dev/shm/incoming/2023_10_31_115941_00_00_V_065.txt",
                        "sha256": "2634111522a8b08de12bc6a805ad8dcc3a1a78e4afeeb122461fd852c4730b22",
                        "size": 2059
                    },
                    {
                        "created": 1698753604.9843621,
                        "modified": 1698753604.9843621,
                        "name": "/dev/shm/incoming/2023_10_31_115941_00_00_V_065.kml",
                        "sha256": "b64434a14dc757931d1a7bccccbef9c1eca05c798301601b81d56fb02ec9af3a",
                        "size": 980
                    },
                    {
                        "created": 1698753604.1843622,
                        "modified": 1698753604.1843622,
                        "name": "/dev/shm/incoming/2023_10_31_115941_00_00_V_065.jpg",
                        "sha256": "96b54feac71c015771db6847519cf1eb0623df1aa57bcd810f59ad511cc6939b",
                        "size": 928922
                    },
                    {
                        "created": 1698753603.344362,
                        "modified": 1698753603.344362,
                        "name": "/dev/shm/incoming/2023_10_31_115941_00_00_V_064.txt",
                        "sha256": "3fbd76dcf1923bf8ba24d1eb6e338e765a63783b71ecbdfc8859d59047a0cddc",
                        "size": 2053
                    },
                    {
                        "created": 1698753603.114362,
                        "modified": 1698753603.114362,
                        "name": "/dev/shm/incoming/2023_10_31_115941_00_00_V_064.kml",
                        "sha256": "49660314f4265ac6ab9ee108519178386bb25e996f4ec96d3e809641c1095e33",
                        "size": 983
                    },
                    {
                        "created": 1698753602.634362,
                        "modified": 1698753602.634362,
                        "name": "/dev/shm/incoming/2023_10_31_115941_00_00_V_064.jpg",
                        "sha256": "bbb5dee2f02a9bbe9dcfb23a97c00d394618b26d440a076c00fe5e0d193567cf",
                        "size": 942936
                    },
                    {
                        "created": 1698753601.574362,
                        "modified": 1698753601.574362,
                        "name": "/dev/shm/incoming/2023_10_31_115937_00_00_V_063.txt",
                        "sha256": "be5e83012f3dde31417d9a90c31c13918913f2d4c992a32549597d3b5a92e46c",
                        "size": 2054
                    },
                    {
                        "created": 1698753602.314362,
                        "modified": 1698753602.314362,
                        "name": "/dev/shm/incoming/2023_10_31_115937_00_00_V_063.kml",
                        "sha256": "3f4d183bc5f7aaae30cc3640c183a194fbe45536204b4ec1ceb7683e2474cd38",
                        "size": 980
                    },
                    {
                        "created": 1698753600.9043622,
                        "modified": 1698753600.9043622,
                        "name": "/dev/shm/incoming/2023_10_31_115937_00_00_V_063.jpg",
                        "sha256": "8ad628d43bc91f43eaee8d7a7d5cad9a80cb837ad628dc4a55ac1a13a16e3254",
                        "size": 805911
                    },
                    {
                        "created": 1698753598.264362,
                        "modified": 1698753598.264362,
                        "name": "/dev/shm/incoming/2023_10_31_115932_00_00_V_062.txt",
                        "sha256": "8ebd8a882d00d7a8b4b7d41d6d76fa7ad92571f8ea3079237a3c6c407670d886",
                        "size": 2052
                    },
                    {
                        "created": 1698753602.074362,
                        "modified": 1698753602.074362,
                        "name": "/dev/shm/incoming/2023_10_31_115932_00_00_V_062.kml",
                        "sha256": "31f1fffb840a9b00a11d1ae1f37c7e199e6c2b1bb97b931115f7127ab3beef91",
                        "size": 982
                    },
                    {
                        "created": 1698753588.884362,
                        "modified": 1698753588.884362,
                        "name": "/dev/shm/incoming/2023_10_31_115932_00_00_V_062.jpg",
                        "sha256": "dd4d190bdd9da1472c0c4643270a3432180e13485c4fe0fcaa67b4a55fe3b337",
                        "size": 816599
                    },
                    {
                        "created": 1698753575.9043622,
                        "modified": 1698753575.9043622,
                        "name": "/dev/shm/incoming/2023_10_31_115920_00_00_V_061.txt",
                        "sha256": "ea763f6ae884f1a795c3d7dbae7c36fffaa30303ba5560a4b700ccf5cf17dbcf",
                        "size": 2054
                    },
                    {
                        "created": 1698753575.2043622,
                        "modified": 1698753575.2043622,
                        "name": "/dev/shm/incoming/2023_10_31_115920_00_00_V_061.kml",
                        "sha256": "31eb08c24e23c15a4b2ae9452959f4ab623e687e9708708ddfbb2304d33dece9",
                        "size": 982
                    },
                    {
                        "created": 1698753574.6943622,
                        "modified": 1698753574.6943622,
                        "name": "/dev/shm/incoming/2023_10_31_115920_00_00_V_061.jpg",
                        "sha256": "cd7e4844afa0b687b5e3a26e51fc4ee16c6ac5d497ac56b9b733377a593e47b4",
                        "size": 936266
                    },
                    {
                        "created": 1698753501.9543622,
                        "modified": 1698753501.9543622,
                        "name": "/dev/shm/incoming/2023_10_31_115753_00_00_V_060.txt",
                        "sha256": "86c36edd6ba342456c0bae2cb810f0436a1e9ac6f89aa2fe4c33396273639086",
                        "size": 2054
                    },
                    {
                        "created": 1698753501.784362,
                        "modified": 1698753501.784362,
                        "name": "/dev/shm/incoming/2023_10_31_115753_00_00_V_060.kml",
                        "sha256": "4ae41f0c1a138b5ca8668c85d0a6c96af2edfdfe53d5cd2fd10bbf5a13b1e910",
                        "size": 982
                    },
                    {
                        "created": 1698753498.2343621,
                        "modified": 1698753498.2343621,
                        "name": "/dev/shm/incoming/2023_10_31_115753_00_00_V_060.jpg",
                        "sha256": "c88e90e8ae479979be2dc0b2389a5c45749c34d21975f95edec1f081ce5fea84",
                        "size": 1021329
                    },
                    {
                        "created": 1698753475.6743622,
                        "modified": 1698753475.6743622,
                        "name": "/dev/shm/incoming/2023_10_31_115737_00_00_V_059.txt",
                        "sha256": "80524448f76be8c406ee7d294f349f89b20a7b08653d2e8f7b0a75a8777484a3",
                        "size": 2053
                    },
                    {
                        "created": 1698753482.494362,
                        "modified": 1698753482.494362,
                        "name": "/dev/shm/incoming/2023_10_31_115737_00_00_V_059.kml",
                        "sha256": "c9d40485bfbc7cce684040ed63f08ca20f74a99ef3486b0232d429456fcdddab",
                        "size": 982
                    },
                    {
                        "created": 1698753484.744362,
                        "modified": 1698753484.744362,
                        "name": "/dev/shm/incoming/2023_10_31_115737_00_00_V_059.jpg",
                        "sha256": "46d7b0bbad3a4df8d67b38a44e8466ff84480be57ea1930fc503761d01adaeaf",
                        "size": 956041
                    },
                    {
                        "created": 1698753471.6843622,
                        "modified": 1698753471.6843622,
                        "name": "/dev/shm/incoming/2023_10_31_115720_00_00_V_058.txt",
                        "sha256": "906070f39b953bb34b5d15e6faeb30db98a47e8edaa9409cca1dd4fde602ff4a",
                        "size": 2052
                    },
                    {
                        "created": 1698753472.104362,
                        "modified": 1698753472.104362,
                        "name": "/dev/shm/incoming/2023_10_31_115720_00_00_V_058.kml",
                        "sha256": "3e7b17e10ad431585e72ca33603a0559dc3632278e79c9b8aa1450fd98e02246",
                        "size": 982
                    },
                    {
                        "created": 1698753460.9843621,
                        "modified": 1698753460.9843621,
                        "name": "/dev/shm/incoming/2023_10_31_115720_00_00_V_058.jpg",
                        "sha256": "6af022c3f8040d991f8407a004387760e4f74dbebeefbf1daf478c8aa9f9b50d",
                        "size": 946008
                    },
                    {
                        "created": 1698753438.024362,
                        "modified": 1698753438.024362,
                        "name": "/dev/shm/incoming/2023_10_31_115659_00_00_V_057.txt",
                        "sha256": "aa5e40d31b16fb27c41c72c9c17ef76e66f31afc60121647b8a262c9640ca1ea",
                        "size": 2052
                    },
                    {
                        "created": 1698753447.2043622,
                        "modified": 1698753447.2043622,
                        "name": "/dev/shm/incoming/2023_10_31_115659_00_00_V_057.kml",
                        "sha256": "a85bbc2dc3a05f9b269087b137d1c9eb8b13793106c4ddf386dec856add46522",
                        "size": 981
                    },
                    {
                        "created": 1698753447.244362,
                        "modified": 1698753447.244362,
                        "name": "/dev/shm/incoming/2023_10_31_115659_00_00_V_057.jpg",
                        "sha256": "c1c2b646507b290bc74e2ae8ff0e43c43cc5ce906841d0b6529926d8afdb741c",
                        "size": 1174167
                    },
                    {
                        "created": 1698753436.374362,
                        "modified": 1698753436.374362,
                        "name": "/dev/shm/incoming/2023_10_31_115652_00_00_V_056.txt",
                        "sha256": "1c0e22c111572cdb7d73820daa7674398f2be1ae6384e7ce706f29defa6b2d33",
                        "size": 2052
                    },
                    {
                        "created": 1698753436.9843621,
                        "modified": 1698753436.9843621,
                        "name": "/dev/shm/incoming/2023_10_31_115652_00_00_V_056.kml",
                        "sha256": "d7fa4eb04e660735c99b43afcd8cfe3a0f67e75f4201b37cbb0ecb75db111e16",
                        "size": 981
                    },
                    {
                        "created": 1698753435.794362,
                        "modified": 1698753435.794362,
                        "name": "/dev/shm/incoming/2023_10_31_115652_00_00_V_056.jpg",
                        "sha256": "4dfa2286d36d5ef3ddc7fbc4ddd9d565098eaa64874fcaf638d04c810ff2e631",
                        "size": 1080562
                    },
                    {
                        "created": 1698753425.624362,
                        "modified": 1698753425.624362,
                        "name": "/dev/shm/incoming/2023_10_31_115637_00_00_V_055.txt",
                        "sha256": "9a2aa20823b6067d5cc8a2b3b25f8ca399f3354eb1b89e7f8f9c175347f2d066",
                        "size": 2051
                    },
                    {
                        "created": 1698753425.354362,
                        "modified": 1698753425.354362,
                        "name": "/dev/shm/incoming/2023_10_31_115637_00_00_V_055.kml",
                        "sha256": "4e70a2d4bd9b0f5e005f9db6b7373047b8e4791ff996507fb20c60636f9681b5",
                        "size": 983
                    },
                    {
                        "created": 1698753426.264362,
                        "modified": 1698753426.264362,
                        "name": "/dev/shm/incoming/2023_10_31_115637_00_00_V_055.jpg",
                        "sha256": "ee4418dfed06b29e7070c3b0622eba5534504eb424999590f532eb6f3a6bcc11",
                        "size": 1209517
                    },
                    {
                        "created": 1698753422.1443622,
                        "modified": 1698753422.1443622,
                        "name": "/dev/shm/incoming/2023_10_31_115628_00_00_V_054.txt",
                        "sha256": "8b47152e7b93e84b8d889991e185dc443c4e9a0a8e6a1a17165017e734fa6299",
                        "size": 2052
                    },
                    {
                        "created": 1698753421.9843621,
                        "modified": 1698753421.9843621,
                        "name": "/dev/shm/incoming/2023_10_31_115628_00_00_V_054.kml",
                        "sha256": "033ad20a6072d15d5031bd7aa58594ac747c08f247722aba90cf7f8f660b2a9d",
                        "size": 980
                    },
                    {
                        "created": 1698753419.3943622,
                        "modified": 1698753419.3943622,
                        "name": "/dev/shm/incoming/2023_10_31_115628_00_00_V_054.jpg",
                        "sha256": "9bf0d4b1b4db0d91862591e4b75dee98f4dcd5cef083c3ed64abc3de06e21d31",
                        "size": 1058603
                    },
                    {
                        "created": 1698753409.614362,
                        "modified": 1698753409.614362,
                        "name": "/dev/shm/incoming/2023_10_31_115621_00_00_V_053.txt",
                        "sha256": "6ab899e30f1313d0880565036ac187be028e9dbf1a871cc26583f1291a5014db",
                        "size": 2052
                    },
                    {
                        "created": 1698753409.1743622,
                        "modified": 1698753409.1743622,
                        "name": "/dev/shm/incoming/2023_10_31_115621_00_00_V_053.kml",
                        "sha256": "c28091d1ded2f3226c79b5b5cf1ffacfb53359cc8d3a3cf428db9c365b27cd39",
                        "size": 982
                    },
                    {
                        "created": 1698753411.364362,
                        "modified": 1698753411.364362,
                        "name": "/dev/shm/incoming/2023_10_31_115621_00_00_V_053.jpg",
                        "sha256": "373569e1a5a80cea5bac4477b0931d948e91aa85523ffdb6174224c3204994de",
                        "size": 1056230
                    },
                    {
                        "created": 1698753349.264362,
                        "modified": 1698753349.264362,
                        "name": "/dev/shm/incoming/2023_10_31_115520_00_00_V_052.txt",
                        "sha256": "6035a24496d6e670efda60a2571ecfc2ae71ce37b7696f0d0632017265292d3f",
                        "size": 2053
                    },
                    {
                        "created": 1698753354.4243622,
                        "modified": 1698753354.4243622,
                        "name": "/dev/shm/incoming/2023_10_31_115520_00_00_V_052.kml",
                        "sha256": "f9c3c22b4bad2e09ab91b8ee0855854ac9e132e677795e1c1a4578c7e17e6ffe",
                        "size": 984
                    },
                    {
                        "created": 1698753343.9043622,
                        "modified": 1698753343.9043622,
                        "name": "/dev/shm/incoming/2023_10_31_115520_00_00_V_052.jpg",
                        "sha256": "dcb809910f9006de6a584198a32a603dda069e9d5d1535f3864cb09a60c576be",
                        "size": 981689
                    },
                    {
                        "created": 1698753330.634362,
                        "modified": 1698753330.634362,
                        "name": "/dev/shm/incoming/2023_10_31_115459_00_00_V_051.txt",
                        "sha256": "3a29afb30dbcc26ad7388fd739a94d02e38e4746723301db40a4b84668643cee",
                        "size": 2054
                    },
                    {
                        "created": 1698753324.9043622,
                        "modified": 1698753324.9043622,
                        "name": "/dev/shm/incoming/2023_10_31_115459_00_00_V_051.kml",
                        "sha256": "da80bb1da7e966045d85bf42dcb8c2edfdfe66ace5242e5df8c31060a3914963",
                        "size": 981
                    },
                    {
                        "created": 1698753331.094362,
                        "modified": 1698753331.094362,
                        "name": "/dev/shm/incoming/2023_10_31_115459_00_00_V_051.jpg",
                        "sha256": "191a0b1a05c1a59298512f91e7b2517b64d9680f9d70178b2b222757d5447a33",
                        "size": 648217
                    },
                    {
                        "created": 1698753320.2143621,
                        "modified": 1698753320.2143621,
                        "name": "/dev/shm/incoming/2023_10_31_115456_00_00_V_050.txt",
                        "sha256": "83064268c6491580c03e37de2be93b719db797d295f66325c6ca9779b94e3b3c",
                        "size": 2054
                    },
                    {
                        "created": 1698753319.814362,
                        "modified": 1698753319.814362,
                        "name": "/dev/shm/incoming/2023_10_31_115456_00_00_V_050.kml",
                        "sha256": "2d5a2dd4c54163605d934da5c2d903c8347f107ab0155d754c2604d81535e7d7",
                        "size": 984
                    },
                    {
                        "created": 1698753319.6743622,
                        "modified": 1698753319.6743622,
                        "name": "/dev/shm/incoming/2023_10_31_115456_00_00_V_050.jpg",
                        "sha256": "5dc9c48926a01f7678512d9252bdac20fe8d998d466843fd8552fba803ba17e4",
                        "size": 651595
                    },
                    {
                        "created": 1698753308.2143621,
                        "modified": 1698753308.2143621,
                        "name": "/dev/shm/incoming/2023_10_31_115450_00_00_V_049.txt",
                        "sha256": "155e18a744909deed2c4e7abf65fbe7f4e870e596be19194e4e0a12adfa82328",
                        "size": 2054
                    },
                    {
                        "created": 1698753308.104362,
                        "modified": 1698753308.104362,
                        "name": "/dev/shm/incoming/2023_10_31_115450_00_00_V_049.kml",
                        "sha256": "77a8e213e5e3d4c64df268e6e795dc363f461b7696ed4fa786d91a97d7aab01a",
                        "size": 982
                    },
                    {
                        "created": 1698753308.604362,
                        "modified": 1698753308.604362,
                        "name": "/dev/shm/incoming/2023_10_31_115450_00_00_V_049.jpg",
                        "sha256": "4e5436b37e6d7b9e05345d28b37618818233a819dbaa2b9687832cb1c0f612d9",
                        "size": 781276
                    },
                    {
                        "created": 1698753306.4543622,
                        "modified": 1698753306.4543622,
                        "name": "/dev/shm/incoming/2023_10_31_115443_00_00_V_048.txt",
                        "sha256": "243ce665cea6a4df4b78e93e378d8971b096211f8536f7ebab61f0e4d0f609d3",
                        "size": 2053
                    },
                    {
                        "created": 1698753306.004362,
                        "modified": 1698753306.004362,
                        "name": "/dev/shm/incoming/2023_10_31_115443_00_00_V_048.kml",
                        "sha256": "841774a98fe8ced360b99c57e814a90c96b961355dff2ff1e69ee1585a4f80b9",
                        "size": 981
                    },
                    {
                        "created": 1698753305.824362,
                        "modified": 1698753305.824362,
                        "name": "/dev/shm/incoming/2023_10_31_115443_00_00_V_048.jpg",
                        "sha256": "01e1c6a0005d886bf2db1fa29f3d743d71074f59a2ae160129dd6bb0592473df",
                        "size": 841527
                    },
                    {
                        "created": 1698753303.874362,
                        "modified": 1698753303.874362,
                        "name": "/dev/shm/incoming/2023_10_31_115421_00_00_V_047.txt",
                        "sha256": "9eca45cbeff14cd5207ca6f3da0eb4df44654cd024a1a90024d66bbd6ece96eb",
                        "size": 2054
                    },
                    {
                        "created": 1698753303.754362,
                        "modified": 1698753303.754362,
                        "name": "/dev/shm/incoming/2023_10_31_115421_00_00_V_047.kml",
                        "sha256": "ad2188c961680b3084249f0c22b69aacaa2eeeee117f92be9e0424e249f5c6eb",
                        "size": 983
                    },
                    {
                        "created": 1698753300.324362,
                        "modified": 1698753300.324362,
                        "name": "/dev/shm/incoming/2023_10_31_115421_00_00_V_047.jpg",
                        "sha256": "9222331dfeaa53f1d2c6aab1b8e575735eb86ad6da9a70f2cc00efd372faf914",
                        "size": 974848
                    },
                    {
                        "created": 1698753290.784362,
                        "modified": 1698753290.784362,
                        "name": "/dev/shm/incoming/2023_10_31_115408_00_00_V_046.txt",
                        "sha256": "76310a7153bb82a6f068f935626124a46178ce703e212951370ca4b4f83c45db",
                        "size": 2052
                    },
                    {
                        "created": 1698753293.104362,
                        "modified": 1698753293.104362,
                        "name": "/dev/shm/incoming/2023_10_31_115408_00_00_V_046.kml",
                        "sha256": "10177de6d554c89d6ce341c0ec01422a5a184a2dce59af1b3695b70f5f98ff55",
                        "size": 980
                    },
                    {
                        "created": 1698753279.9743621,
                        "modified": 1698753279.9743621,
                        "name": "/dev/shm/incoming/2023_10_31_115408_00_00_V_046.jpg",
                        "sha256": "cbc5feb0d836e0ccda47d57fb7a1cc1536686cddd27d2253482c7d478f1cd68b",
                        "size": 991653
                    },
                    {
                        "created": 1698753268.754362,
                        "modified": 1698753268.754362,
                        "name": "/dev/shm/incoming/2023_10_31_115400_00_00_V_045.txt",
                        "sha256": "173013937461a3779ee56e52b5875b26a3142ed17f904252a8dc0bf4158f5d75",
                        "size": 2052
                    },
                    {
                        "created": 1698753276.854362,
                        "modified": 1698753276.854362,
                        "name": "/dev/shm/incoming/2023_10_31_115400_00_00_V_045.kml",
                        "sha256": "3ae8c0da45cf32bb1534a1936e1f5183874cd945afa85f23fc678f0a2c7a528c",
                        "size": 980
                    },
                    {
                        "created": 1698753278.004362,
                        "modified": 1698753278.004362,
                        "name": "/dev/shm/incoming/2023_10_31_115400_00_00_V_045.jpg",
                        "sha256": "00c3957f1eaddc29d1f97696db6efc704c39ffbeb0723520557487ec5c5b21a4",
                        "size": 630487
                    },
                    {
                        "created": 1698753265.9743621,
                        "modified": 1698753265.9743621,
                        "name": "/dev/shm/incoming/2023_10_31_115357_00_00_V_044.txt",
                        "sha256": "1d83c5b6139b550f6fe3ecd454de9c18664253b72aa68e06c3598f1e9841c527",
                        "size": 2052
                    },
                    {
                        "created": 1698753265.134362,
                        "modified": 1698753265.134362,
                        "name": "/dev/shm/incoming/2023_10_31_115357_00_00_V_044.kml",
                        "sha256": "bda1ac9455e4390e39796f880529e21ce19ca02e3be7485a03f2676f9a251836",
                        "size": 981
                    },
                    {
                        "created": 1698753264.9343622,
                        "modified": 1698753264.9343622,
                        "name": "/dev/shm/incoming/2023_10_31_115357_00_00_V_044.jpg",
                        "sha256": "0e48627d6ea2960833172e11f859e27548c6ea213d7c5868b2b0b3147f58cd24",
                        "size": 629881
                    },
                    {
                        "created": 1698753263.854362,
                        "modified": 1698753263.854362,
                        "name": "/dev/shm/incoming/2023_10_31_115352_00_00_V_043.txt",
                        "sha256": "04ffb664c8cebea10f87b7ff590b363fa0af795ec93c93f77c3ab1d14b1f24c1",
                        "size": 2052
                    },
                    {
                        "created": 1698753264.094362,
                        "modified": 1698753264.094362,
                        "name": "/dev/shm/incoming/2023_10_31_115352_00_00_V_043.kml",
                        "sha256": "a3e4c263f3468690b55082ac0347f1f137e69a1fa60d35a1f2cdb7543d2c1862",
                        "size": 983
                    },
                    {
                        "created": 1698753262.804362,
                        "modified": 1698753262.804362,
                        "name": "/dev/shm/incoming/2023_10_31_115352_00_00_V_043.jpg",
                        "sha256": "1eb3d7b759a46e3b7b7ca47451954e849bf4f19ab3f77d5a2301ee28cbd7be00",
                        "size": 993900
                    },
                    {
                        "created": 1698753250.044362,
                        "modified": 1698753250.044362,
                        "name": "/dev/shm/incoming/2023_10_31_115339_00_00_V_042.txt",
                        "sha256": "5ef3ca96e3e3576fbfd48bcfbe89e2c7b5d4ca1daa71d6f14567431b57ca37ed",
                        "size": 2052
                    },
                    {
                        "created": 1698753248.9843621,
                        "modified": 1698753248.9843621,
                        "name": "/dev/shm/incoming/2023_10_31_115339_00_00_V_042.kml",
                        "sha256": "5f2a8b6284268a24becc53b58c3d29eece8cc9bc65735d6cb6d894ba6ff4eb40",
                        "size": 980
                    },
                    {
                        "created": 1698753248.7343621,
                        "modified": 1698753248.7343621,
                        "name": "/dev/shm/incoming/2023_10_31_115339_00_00_V_042.jpg",
                        "sha256": "25f20037d964ae45661824a41c8a4bf61f4afe00ce8f728420eaabfa4667cf8b",
                        "size": 976142
                    },
                    {
                        "created": 1698753246.9843621,
                        "modified": 1698753246.9843621,
                        "name": "/dev/shm/incoming/2023_10_31_115335_00_00_V_041.txt",
                        "sha256": "0c187d982fac6aed82ea7791b05a258402677339e54d6c45a4114b5eec552ee2",
                        "size": 2052
                    },
                    {
                        "created": 1698753246.884362,
                        "modified": 1698753246.884362,
                        "name": "/dev/shm/incoming/2023_10_31_115335_00_00_V_041.kml",
                        "sha256": "9c5c957ebb9c477f715a310ec54c07c8285d679c7ec5d5e7f9e50a1aa1a4593c",
                        "size": 983
                    },
                    {
                        "created": 1698753246.6743622,
                        "modified": 1698753246.6743622,
                        "name": "/dev/shm/incoming/2023_10_31_115335_00_00_V_041.jpg",
                        "sha256": "4a2f538b93db141fce9d52c6d00c53ac0679c129ccf18c061ee095746a567f9f",
                        "size": 1069205
                    },
                    {
                        "created": 1698753239.7343621,
                        "modified": 1698753239.7343621,
                        "name": "/dev/shm/incoming/2023_10_31_115331_00_00_V_040.txt",
                        "sha256": "1eb550fada9d706857ead5349b888f4c6ec057fcdb3f2617a080460fe77ea1bc",
                        "size": 2054
                    },
                    {
                        "created": 1698753245.834362,
                        "modified": 1698753245.834362,
                        "name": "/dev/shm/incoming/2023_10_31_115331_00_00_V_040.kml",
                        "sha256": "765b4c7a28145508e94b38f43f8186c09fcdc713ec89be83ed2b171da1889ad9",
                        "size": 981
                    },
                    {
                        "created": 1698753243.6843622,
                        "modified": 1698753243.6843622,
                        "name": "/dev/shm/incoming/2023_10_31_115331_00_00_V_040.jpg",
                        "sha256": "15aa8412ad9d7868b190cdf1dfa192ed465e05f3e0fd4e90f9eea5e7f1625dab",
                        "size": 1016246
                    },
                    {
                        "created": 1698753232.9143622,
                        "modified": 1698753232.9143622,
                        "name": "/dev/shm/incoming/2023_10_31_115324_00_00_V_039.txt",
                        "sha256": "e6743eb4def0a8fe7f88dd9cbf3814eb99ced244b9f2a685f0fc5818e57ea788",
                        "size": 2054
                    },
                    {
                        "created": 1698753232.6743622,
                        "modified": 1698753232.6743622,
                        "name": "/dev/shm/incoming/2023_10_31_115324_00_00_V_039.kml",
                        "sha256": "43af64d473cdc80a56c6f09d7698e95cd7f7bf262e5d52026d00038bddd9821e",
                        "size": 982
                    },
                    {
                        "created": 1698753232.1843622,
                        "modified": 1698753232.1843622,
                        "name": "/dev/shm/incoming/2023_10_31_115324_00_00_V_039.jpg",
                        "sha256": "3786faf865bcc7c66f65848b1daeb8d3dc420466688030b651f0f67bb6f2d902",
                        "size": 1076007
                    },
                    {
                        "created": 1698753230.804362,
                        "modified": 1698753230.804362,
                        "name": "/dev/shm/incoming/2023_10_31_115322_00_00_V_038.txt",
                        "sha256": "7d3db30e3b685e6c4320712080d3ac2f7f39dba1eb11e4652b27c361980449f7",
                        "size": 2054
                    },
                    {
                        "created": 1698753230.584362,
                        "modified": 1698753230.584362,
                        "name": "/dev/shm/incoming/2023_10_31_115322_00_00_V_038.kml",
                        "sha256": "3dbdf58aa456b981f55638f0674227fe3a24829fb20bdf0c14768bc5e1a40cf8",
                        "size": 983
                    },
                    {
                        "created": 1698753230.354362,
                        "modified": 1698753230.354362,
                        "name": "/dev/shm/incoming/2023_10_31_115322_00_00_V_038.jpg",
                        "sha256": "8f987c3818dbfe947a5f2495e01ed5fe103d08af32c87f22bb7294452833f53c",
                        "size": 1024595
                    },
                    {
                        "created": 1698753222.4343622,
                        "modified": 1698753222.4343622,
                        "name": "/dev/shm/incoming/2023-10-31T11:43:41.ais",
                        "sha256": "ba4d32e9207bcdb458262dd27e961adfb83a8051ddecaa8f26f90f1df983f676",
                        "size": 39012
                    },
                    {
                        "created": 1698753220.244362,
                        "modified": 1698753220.244362,
                        "name": "/dev/shm/incoming/2023_10_31_115308_00_00_V_037.txt",
                        "sha256": "dc47de2df964df60c31ca95899ce43b02cd158011d503dcf5574e6b2e520c4a3",
                        "size": 2052
                    },
                    {
                        "created": 1698753218.9143622,
                        "modified": 1698753218.9143622,
                        "name": "/dev/shm/incoming/2023_10_31_115308_00_00_V_037.kml",
                        "sha256": "0631d407d0de1845959290f0ffb010966dc8d4f1ed25a8d0830448876afc7065",
                        "size": 983
                    },
                    {
                        "created": 1698753229.764362,
                        "modified": 1698753229.764362,
                        "name": "/dev/shm/incoming/2023_10_31_115308_00_00_V_037.jpg",
                        "sha256": "fe1146250381d9b90163c9aa454a61c04859a81fe6f047834bcde8f8a9335290",
                        "size": 1043738
                    },
                    {
                        "created": 1698753217.874362,
                        "modified": 1698753217.874362,
                        "name": "/dev/shm/incoming/2023_10_31_115306_00_00_V_036.txt",
                        "sha256": "3c263708e31e4c3d730d02b42c0710b869e0c808267b6c82432bfc6ec6262cd0",
                        "size": 2051
                    },
                    {
                        "created": 1698753217.6943622,
                        "modified": 1698753217.6943622,
                        "name": "/dev/shm/incoming/2023_10_31_115306_00_00_V_036.kml",
                        "sha256": "24255e686789e1310979eb485e67e55c439ea2b088c7b6a03464e7d576d07bf4",
                        "size": 981
                    },
                    {
                        "created": 1698753217.254362,
                        "modified": 1698753217.254362,
                        "name": "/dev/shm/incoming/2023_10_31_115306_00_00_V_036.jpg",
                        "sha256": "06b867e2ca70323597b74f1fd6133e23d8a7638ee65f2eb1b99abc84e61bd5d5",
                        "size": 1011247
                    },
                    {
                        "created": 1698753209.554362,
                        "modified": 1698753209.554362,
                        "name": "/dev/shm/incoming/2023_10_31_115302_00_00_V_035.txt",
                        "sha256": "e6c84436435a165dda2dec4c5aad894faaa8442976f4a304931e70e68ea88d99",
                        "size": 2052
                    },
                    {
                        "created": 1698753211.284362,
                        "modified": 1698753211.284362,
                        "name": "/dev/shm/incoming/2023_10_31_115302_00_00_V_035.kml",
                        "sha256": "da9dac4609dee6eb6967f723327ecd06a9ab2fd02d70834d44534a47155c6f73",
                        "size": 981
                    },
                    {
                        "created": 1698753216.834362,
                        "modified": 1698753216.834362,
                        "name": "/dev/shm/incoming/2023_10_31_115302_00_00_V_035.jpg",
                        "sha256": "d7aaf8d3d34bda85a1edb548605ffdbec4119806a44158b3c4cedc69664db734",
                        "size": 991030
                    },
                    {
                        "created": 1698753203.774362,
                        "modified": 1698753203.774362,
                        "name": "/dev/shm/incoming/2023_10_31_115257_00_00_V_034.txt",
                        "sha256": "ca40019177fc5c8d6a27573d41c5f28b1d8627ea6b687b8e5f76f1d78df17f06",
                        "size": 2052
                    },
                    {
                        "created": 1698753203.584362,
                        "modified": 1698753203.584362,
                        "name": "/dev/shm/incoming/2023_10_31_115257_00_00_V_034.kml",
                        "sha256": "8affcf12596a801d525c9ce2bb58fb654c8bab648a6c3f16ac6f9c5b4fd07011",
                        "size": 982
                    },
                    {
                        "created": 1698753202.9143622,
                        "modified": 1698753202.9143622,
                        "name": "/dev/shm/incoming/2023_10_31_115257_00_00_V_034.jpg",
                        "sha256": "d4f6e771bce0ef20142090f2108810457235d7e941aeeab42b7f55bed7bf008c",
                        "size": 995712
                    },
                    {
                        "created": 1698753202.054362,
                        "modified": 1698753202.054362,
                        "name": "/dev/shm/incoming/2023_10_31_115253_00_00_V_033.txt",
                        "sha256": "297710f33e76901961192d561522cf10c4d6f64d0814adce34d755b1a8419742",
                        "size": 2053
                    },
                    {
                        "created": 1698753202.544362,
                        "modified": 1698753202.544362,
                        "name": "/dev/shm/incoming/2023_10_31_115253_00_00_V_033.kml",
                        "sha256": "c9f2e65d71e46bcfcf542bd9c407a4ded7d350da7d03eb18520439e18a520936",
                        "size": 983
                    },
                    {
                        "created": 1698753201.334362,
                        "modified": 1698753201.334362,
                        "name": "/dev/shm/incoming/2023_10_31_115253_00_00_V_033.jpg",
                        "sha256": "35d3709b1f1f6132256050b13c2b55ca398fa15342920f1bd632c6d6aff6c21c",
                        "size": 970096
                    },
                    {
                        "created": 1698753200.524362,
                        "modified": 1698753200.524362,
                        "name": "/dev/shm/incoming/2023_10_31_115252_00_00_V_032.txt",
                        "sha256": "e38865bbb8321c14cbd41c88eb772f668293557b69a5f1e079dc2074a445c135",
                        "size": 2053
                    },
                    {
                        "created": 1698753200.4143622,
                        "modified": 1698753200.4143622,
                        "name": "/dev/shm/incoming/2023_10_31_115252_00_00_V_032.kml",
                        "sha256": "511f8b727ed6021c62a79a8c45f2030ab775dfe29a545e59f5feccdbda1fd54d",
                        "size": 983
                    },
                    {
                        "created": 1698753200.274362,
                        "modified": 1698753200.274362,
                        "name": "/dev/shm/incoming/2023_10_31_115252_00_00_V_032.jpg",
                        "sha256": "0bda7fa5f13cf76c1af9b4ac0fb4a73199a9f5a5bf8ed773a9244ffbb8adafee",
                        "size": 1006775
                    },
                    {
                        "created": 1698753189.6843622,
                        "modified": 1698753189.6843622,
                        "name": "/dev/shm/incoming/2023_10_31_115248_00_00_V_031.txt",
                        "sha256": "faa247a87e93fc7b432d7164330599b17a2a4a2f779de84be1f27f5743018d6e",
                        "size": 2052
                    },
                    {
                        "created": 1698753190.014362,
                        "modified": 1698753190.014362,
                        "name": "/dev/shm/incoming/2023_10_31_115248_00_00_V_031.kml",
                        "sha256": "ae0d8b248d8871edf46d8736a846c17bb900d73b314c61557212a7b773cf978e",
                        "size": 981
                    },
                    {
                        "created": 1698753187.564362,
                        "modified": 1698753187.564362,
                        "name": "/dev/shm/incoming/2023_10_31_115248_00_00_V_031.jpg",
                        "sha256": "dcd2a8520622a6cb0be334b1f567b3111eb1f36aecafa0c72d194c8a989e97a3",
                        "size": 1131165
                    },
                    {
                        "created": 1698753185.794362,
                        "modified": 1698753185.794362,
                        "name": "/dev/shm/incoming/2023_10_31_115241_00_00_V_030.txt",
                        "sha256": "bfbf2812019a98f20b0d70630bce367d996202560888b18ebe951ae057bd4a47",
                        "size": 2052
                    },
                    {
                        "created": 1698753185.4543622,
                        "modified": 1698753185.4543622,
                        "name": "/dev/shm/incoming/2023_10_31_115241_00_00_V_030.kml",
                        "sha256": "26d3350048eeb3e7a6ec1af8e32cd724cf0b484c78b253344136e60cc552c0cc",
                        "size": 983
                    },
                    {
                        "created": 1698753184.9543622,
                        "modified": 1698753184.9543622,
                        "name": "/dev/shm/incoming/2023_10_31_115241_00_00_V_030.jpg",
                        "sha256": "69b2845bb594ad9ed2d49cfb2e7ef25bf9401f542fa47925a79386628c7a7b63",
                        "size": 1082025
                    },
                    {
                        "created": 1698753182.814362,
                        "modified": 1698753182.814362,
                        "name": "/dev/shm/incoming/2023_10_31_115228_00_00_V_029.txt",
                        "sha256": "d13ac18b91975a8ac22d44d178514276557cb301ccb04ad9da96d079ada65876",
                        "size": 2052
                    },
                    {
                        "created": 1698753173.854362,
                        "modified": 1698753173.854362,
                        "name": "/dev/shm/incoming/2023_10_31_115228_00_00_V_029.kml",
                        "sha256": "76f39e176a245202b3a77957d200c105f2bbddbe2e73797ab4dc99ef324f3b07",
                        "size": 981
                    },
                    {
                        "created": 1698753182.364362,
                        "modified": 1698753182.364362,
                        "name": "/dev/shm/incoming/2023_10_31_115228_00_00_V_029.jpg",
                        "sha256": "a416798634f4928fcb1370c6afe6d3bb99dd955b49306e02d526505a7d1ac1e7",
                        "size": 941370
                    },
                    {
                        "created": 1698753171.374362,
                        "modified": 1698753171.374362,
                        "name": "/dev/shm/incoming/2023_10_31_115222_00_00_V_028.txt",
                        "sha256": "343fe7932d5412f9b2f6808e05157c390dc293ba5d568bbbb06bc9d11b26b677",
                        "size": 2052
                    },
                    {
                        "created": 1698753170.8943622,
                        "modified": 1698753170.8943622,
                        "name": "/dev/shm/incoming/2023_10_31_115222_00_00_V_028.kml",
                        "sha256": "35d240e7f396c07f560c5876f9aac3cd1a84213796344eb7656333ee3c887508",
                        "size": 983
                    },
                    {
                        "created": 1698753170.7243621,
                        "modified": 1698753170.7243621,
                        "name": "/dev/shm/incoming/2023_10_31_115222_00_00_V_028.jpg",
                        "sha256": "7800b28c9aad7ce1a8c072411280a2c6355617862e492c52c69b32250068f6aa",
                        "size": 973854
                    },
                    {
                        "created": 1698753161.624362,
                        "modified": 1698753161.624362,
                        "name": "/dev/shm/incoming/2023_10_31_115215_00_00_V_027.txt",
                        "sha256": "3070838deec257767354d291d3b6734ef99523aa440c97d4b988ade6a797029e",
                        "size": 2052
                    },
                    {
                        "created": 1698753168.784362,
                        "modified": 1698753168.784362,
                        "name": "/dev/shm/incoming/2023_10_31_115215_00_00_V_027.kml",
                        "sha256": "e2b5a6c624e1c706449b45328a9ca05c9ffa5ba93d4e40e42b920061c6be907d",
                        "size": 979
                    },
                    {
                        "created": 1698753168.6543622,
                        "modified": 1698753168.6543622,
                        "name": "/dev/shm/incoming/2023_10_31_115215_00_00_V_027.jpg",
                        "sha256": "f47b4489dcc52cd9fc324a4fb50a120ad5087b4c25808c5e218e2c829e052a49",
                        "size": 964534
                    },
                    {
                        "created": 1698753158.054362,
                        "modified": 1698753158.054362,
                        "name": "/dev/shm/incoming/2023_10_31_115212_00_00_V_026.txt",
                        "sha256": "0a57097d0c3a194aceecb3760c3de776fafb7a7ee8920503ad004a5278c43ae0",
                        "size": 2053
                    },
                    {
                        "created": 1698753157.764362,
                        "modified": 1698753157.764362,
                        "name": "/dev/shm/incoming/2023_10_31_115212_00_00_V_026.kml",
                        "sha256": "f01de0cf756afc67b2e941c60c1397e1b51d4fb121c502177304c42359c790ab",
                        "size": 983
                    },
                    {
                        "created": 1698753157.244362,
                        "modified": 1698753157.244362,
                        "name": "/dev/shm/incoming/2023_10_31_115212_00_00_V_026.jpg",
                        "sha256": "970a0536d9ac72046e5578710944584593cf91f0b341d88703b2e36bc10212a6",
                        "size": 928856
                    },
                    {
                        "created": 1698753155.134362,
                        "modified": 1698753155.134362,
                        "name": "/dev/shm/incoming/2023_10_31_115203_00_00_V_025.txt",
                        "sha256": "59d9d74b96d03860e5b4b5149d35af278f4c1e03baabb43cabcc425c96de4bbb",
                        "size": 2052
                    },
                    {
                        "created": 1698753154.6443622,
                        "modified": 1698753154.6443622,
                        "name": "/dev/shm/incoming/2023_10_31_115203_00_00_V_025.kml",
                        "sha256": "56ff70b0562ce80678e2ea6fbd44f91624cc99f622677e0c3cbcb88066a4688a",
                        "size": 979
                    },
                    {
                        "created": 1698753145.874362,
                        "modified": 1698753145.874362,
                        "name": "/dev/shm/incoming/2023_10_31_115203_00_00_V_025.jpg",
                        "sha256": "14c001597165166ebbc5f7335b55abf1bfccbb29e1618b65bdb742216f4d3eaa",
                        "size": 936689
                    },
                    {
                        "created": 1698753143.8943622,
                        "modified": 1698753143.8943622,
                        "name": "/dev/shm/incoming/2023_10_31_115202_00_00_V_024.txt",
                        "sha256": "8a38d4bd381147f4d3765b52d9956db223fa50c29e584b575d8535c489176fa3",
                        "size": 2051
                    },
                    {
                        "created": 1698753143.744362,
                        "modified": 1698753143.744362,
                        "name": "/dev/shm/incoming/2023_10_31_115202_00_00_V_024.kml",
                        "sha256": "bacac31d70dd451addd51ce0e839c8b37a9a403b451b2e0b74e4d7409b468037",
                        "size": 982
                    },
                    {
                        "created": 1698753143.074362,
                        "modified": 1698753143.074362,
                        "name": "/dev/shm/incoming/2023_10_31_115202_00_00_V_024.jpg",
                        "sha256": "049a7a4387c5061c5704bd95b24814361007998ed02936e573d73284101b197d",
                        "size": 900384
                    },
                    {
                        "created": 1698753042.834362,
                        "modified": 1698753042.834362,
                        "name": "/dev/shm/incoming/2023_10_31_115006_00_00_V_023.txt",
                        "sha256": "852f408b4a76d8b42fc71eedb27d22194ba7dc32503253bb59836fd0eb1bfd02",
                        "size": 2053
                    },
                    {
                        "created": 1698753042.7243621,
                        "modified": 1698753042.7243621,
                        "name": "/dev/shm/incoming/2023_10_31_115006_00_00_V_023.kml",
                        "sha256": "3b58dabffed49e232c3dce2c782de06d6dbbc4f4abbacae92183ab34faed8e99",
                        "size": 983
                    },
                    {
                        "created": 1698753032.9443622,
                        "modified": 1698753032.9443622,
                        "name": "/dev/shm/incoming/2023_10_31_115006_00_00_V_023.jpg",
                        "sha256": "bc2e829d18ca0e16e24c9f5daa78fef6813d67059e3d211c5cd9c45a9e7a0e3b",
                        "size": 936791
                    },
                    {
                        "created": 1698753029.824362,
                        "modified": 1698753029.824362,
                        "name": "/dev/shm/incoming/2023_10_31_115003_00_00_V_022.txt",
                        "sha256": "05baf1e52962c715c80427e22b9dadbd33855236b501c7a16f64d8a07c76ae3d",
                        "size": 2053
                    },
                    {
                        "created": 1698753021.494362,
                        "modified": 1698753021.494362,
                        "name": "/dev/shm/incoming/2023_10_31_115003_00_00_V_022.kml",
                        "sha256": "29c3dac8688c40eeaddaea039aac0cf136cddec52f0f174e5d995db76eda2be4",
                        "size": 978
                    },
                    {
                        "created": 1698753018.9043622,
                        "modified": 1698753018.9043622,
                        "name": "/dev/shm/incoming/2023_10_31_115003_00_00_V_022.jpg",
                        "sha256": "3c317739fd3197f3a6e68695aa0f0d135de1a2b56dd499b1998c02a6eb6f9336",
                        "size": 942744
                    },
                    {
                        "created": 1698753013.024362,
                        "modified": 1698753013.024362,
                        "name": "/dev/shm/incoming/2023_10_31_114941_00_00_V_021.txt",
                        "sha256": "2c2e59fd98aedaa625613258ad53cecd2f3779df760d87efe334ddb68e07088c",
                        "size": 2054
                    },
                    {
                        "created": 1698753013.794362,
                        "modified": 1698753013.794362,
                        "name": "/dev/shm/incoming/2023_10_31_114941_00_00_V_021.kml",
                        "sha256": "ed0fb423c4191af15c0801cf9e7a35799fee9d118c95d79e631ed7dc7344988c",
                        "size": 981
                    },
                    {
                        "created": 1698753013.604362,
                        "modified": 1698753013.604362,
                        "name": "/dev/shm/incoming/2023_10_31_114941_00_00_V_021.jpg",
                        "sha256": "70f97795c52b8b3e37d45bb5c15bce762bfd917d01f044f71b8b84cbca66935b",
                        "size": 900586
                    },
                    {
                        "created": 1698752997.294362,
                        "modified": 1698752997.294362,
                        "name": "/dev/shm/incoming/2023_10_31_114933_00_00_V_020.txt",
                        "sha256": "dc60f38a24112c116e46136540aad9bde17a6f96d14a58bc1a3d1400a368012e",
                        "size": 2054
                    },
                    {
                        "created": 1698753003.104362,
                        "modified": 1698753003.104362,
                        "name": "/dev/shm/incoming/2023_10_31_114933_00_00_V_020.kml",
                        "sha256": "846f331544578878cc43f03bac806053ad46d96659d507c5c1d7ee460633da65",
                        "size": 980
                    },
                    {
                        "created": 1698752993.004362,
                        "modified": 1698752993.004362,
                        "name": "/dev/shm/incoming/2023_10_31_114933_00_00_V_020.jpg",
                        "sha256": "b5ccf1d9a446cf91c6f2fe832787d1dfd099d966db490e3bafe5c37229933899",
                        "size": 743983
                    },
                    {
                        "created": 1698753002.774362,
                        "modified": 1698753002.774362,
                        "name": "/dev/shm/incoming/2023_10_31_114930_00_00_V_019.txt",
                        "sha256": "b755ca180716986fdb5e57e2244c658cff7e6fa57563013421aed927e29fab3b",
                        "size": 2054
                    },
                    {
                        "created": 1698752991.9143622,
                        "modified": 1698752991.9143622,
                        "name": "/dev/shm/incoming/2023_10_31_114930_00_00_V_019.kml",
                        "sha256": "6e3bc3860ab243043936e62236fdfe8514db5fd190bf9f3ae4d67e42ad528eb3",
                        "size": 979
                    },
                    {
                        "created": 1698752991.054362,
                        "modified": 1698752991.054362,
                        "name": "/dev/shm/incoming/2023_10_31_114930_00_00_V_019.jpg",
                        "sha256": "def91a9002c626165c9b394dea84075f6d8330c2b70a3957e4dd01132f599392",
                        "size": 712113
                    },
                    {
                        "created": 1698752985.1943622,
                        "modified": 1698752985.1943622,
                        "name": "/dev/shm/incoming/2023_10_31_114914_00_00_V_018.txt",
                        "sha256": "81a956e4dfede2ad0e68df4667a2d5ba154d9954239a4a6948be490a4d20c54f",
                        "size": 2054
                    },
                    {
                        "created": 1698752983.7043622,
                        "modified": 1698752983.7043622,
                        "name": "/dev/shm/incoming/2023_10_31_114914_00_00_V_018.kml",
                        "sha256": "a37304d18d6c0fbf790f5fa0960d75f9328553406e5836eb71314fdcb328d454",
                        "size": 981
                    },
                    {
                        "created": 1698752985.764362,
                        "modified": 1698752985.764362,
                        "name": "/dev/shm/incoming/2023_10_31_114914_00_00_V_018.jpg",
                        "sha256": "7e629ec8e71775da859faab9322d69d8538e4f2a2e3924d5cad85d1d4f26044a",
                        "size": 907347
                    },
                    {
                        "created": 1698752965.774362,
                        "modified": 1698752965.774362,
                        "name": "/dev/shm/incoming/2023_10_31_114904_00_00_V_017.txt",
                        "sha256": "37bea88875d2a0ece9582a8e11f79a096fc4828e2ad7d090c1f9aff176a6240a",
                        "size": 2053
                    },
                    {
                        "created": 1698752984.604362,
                        "modified": 1698752984.604362,
                        "name": "/dev/shm/incoming/2023_10_31_114904_00_00_V_017.kml",
                        "sha256": "b20fd788db424e2274366ffe8e22ea96b3900daf211f19109d955b331e5d3fa3",
                        "size": 982
                    },
                    {
                        "created": 1698752964.744362,
                        "modified": 1698752964.744362,
                        "name": "/dev/shm/incoming/2023_10_31_114904_00_00_V_017.jpg",
                        "sha256": "6803a0e55cd633e42b69345838d924b2c8994290564b51a5f73aa95191f282ea",
                        "size": 1264045
                    },
                    {
                        "created": 1698752962.6943622,
                        "modified": 1698752962.6943622,
                        "name": "/dev/shm/incoming/2023_10_31_114850_00_00_V_016.txt",
                        "sha256": "27baabf93dcb3a09750bc858f226fc2821236e0076c9d7b02705aa0369b0485b",
                        "size": 2054
                    },
                    {
                        "created": 1698752956.024362,
                        "modified": 1698752956.024362,
                        "name": "/dev/shm/incoming/2023_10_31_114850_00_00_V_016.kml",
                        "sha256": "fea5e1b655d94133c59a6750c9a2268aad14c8963dca82821d9306169f29c75e",
                        "size": 980
                    },
                    {
                        "created": 1698752954.494362,
                        "modified": 1698752954.494362,
                        "name": "/dev/shm/incoming/2023_10_31_114850_00_00_V_016.jpg",
                        "sha256": "a9bb2fc8d0e653167e11e226fa350bbb8474cdae25ce41c75a0ff3954ab4d505",
                        "size": 1195941
                    },
                    {
                        "created": 1698752936.274362,
                        "modified": 1698752936.274362,
                        "name": "/dev/shm/incoming/2023_10_31_114827_00_00_V_015.txt",
                        "sha256": "2f497450bddc7796eb9465edb503f26758f56f721a218dc6d474b0a9dca74eff",
                        "size": 2055
                    },
                    {
                        "created": 1698752933.7143621,
                        "modified": 1698752933.7143621,
                        "name": "/dev/shm/incoming/2023_10_31_114827_00_00_V_015.kml",
                        "sha256": "8ffd30c45395008ac01f2b467c225d22072d4d112e5be4ef3c2a460c0076b406",
                        "size": 982
                    },
                    {
                        "created": 1698752929.2343621,
                        "modified": 1698752929.2343621,
                        "name": "/dev/shm/incoming/2023_10_31_114827_00_00_V_015.jpg",
                        "sha256": "48fe9deccd9ef8d7889a8a12546e6977a350f7dda58e78d2e6603600a3623838",
                        "size": 1029355
                    },
                    {
                        "created": 1698752918.2343621,
                        "modified": 1698752918.2343621,
                        "name": "/dev/shm/incoming/2023_10_31_114821_00_00_V_014.txt",
                        "sha256": "8dddd634fc6ce509b2dfe88871fcdab4483c16d13c75f2b017554e662696c397",
                        "size": 2054
                    },
                    {
                        "created": 1698752926.6743622,
                        "modified": 1698752926.6743622,
                        "name": "/dev/shm/incoming/2023_10_31_114821_00_00_V_014.kml",
                        "sha256": "5c1366e116e0ae1b99743b7c3fecabf1e1f438c43b82484d2751cd86c11a5b8e",
                        "size": 984
                    },
                    {
                        "created": 1698752925.4043622,
                        "modified": 1698752925.4043622,
                        "name": "/dev/shm/incoming/2023_10_31_114821_00_00_V_014.jpg",
                        "sha256": "1f0204e2045f4b31293e0c041624e258ef8e834dfb8ab7a13fa3b8b94e38fcf5",
                        "size": 1028977
                    },
                    {
                        "created": 1698752897.814362,
                        "modified": 1698752897.814362,
                        "name": "/dev/shm/incoming/2023_10_31_114750_00_00_V_013.txt",
                        "sha256": "d30010ef265bac73a165cc57bc3baf88eab933618b4b6aa1c3f3a7120b43ded2",
                        "size": 2051
                    },
                    {
                        "created": 1698752899.534362,
                        "modified": 1698752899.534362,
                        "name": "/dev/shm/incoming/2023_10_31_114750_00_00_V_013.kml",
                        "sha256": "e9f2db23c7a7bbde12d3fcf1a817510c074a4e29efff30e2896e5d71d0a0fa15",
                        "size": 980
                    },
                    {
                        "created": 1698752895.9143622,
                        "modified": 1698752895.9143622,
                        "name": "/dev/shm/incoming/2023_10_31_114750_00_00_V_013.jpg",
                        "sha256": "ea30a60505e98cd8e7b47632a02c086f37f3a4134cc3521cf25ac1fdc2c39170",
                        "size": 948515
                    },
                    {
                        "created": 1698752889.9243622,
                        "modified": 1698752889.9243622,
                        "name": "/dev/shm/incoming/2023_10_31_114735_00_00_V_012.txt",
                        "sha256": "96689eae9d70dc7b5e02a81a5d65b653223ba4c1cd39c072ed5e8e2ef206b256",
                        "size": 2052
                    },
                    {
                        "created": 1698752893.534362,
                        "modified": 1698752893.534362,
                        "name": "/dev/shm/incoming/2023_10_31_114735_00_00_V_012.kml",
                        "sha256": "58ca88986530632f44083bbea2e62c01452f2b3873887f94363b7e616b4ffac1",
                        "size": 982
                    },
                    {
                        "created": 1698752880.784362,
                        "modified": 1698752880.784362,
                        "name": "/dev/shm/incoming/2023_10_31_114735_00_00_V_012.jpg",
                        "sha256": "9046b90185acd78c898438c08d5c1b97418b3b975fd33e5e850870fc8a513a60",
                        "size": 999980
                    },
                    {
                        "created": 1698752861.4643621,
                        "modified": 1698752861.4643621,
                        "name": "/dev/shm/incoming/2023_10_31_114719_00_00_V_011.txt",
                        "sha256": "12426a84bd81abdb845bdff45f95efc8cc8e6563726a46c9501759c50d48a168",
                        "size": 2052
                    },
                    {
                        "created": 1698752867.774362,
                        "modified": 1698752867.774362,
                        "name": "/dev/shm/incoming/2023_10_31_114719_00_00_V_011.kml",
                        "sha256": "fba0c151585eaa7f1de610e1e6edd012b6fc033ad36babf7ee61d12303fdf67c",
                        "size": 983
                    },
                    {
                        "created": 1698752866.634362,
                        "modified": 1698752866.634362,
                        "name": "/dev/shm/incoming/2023_10_31_114719_00_00_V_011.jpg",
                        "sha256": "118f413f2c3da527d0f60eae0e56886281be96ce702aa2b9886495498c290bb6",
                        "size": 938641
                    },
                    {
                        "created": 1698752857.1743622,
                        "modified": 1698752857.1743622,
                        "name": "/dev/shm/incoming/2023_10_31_114715_00_00_V_010.txt",
                        "sha256": "96dba1cf5296dd38089dbe198febeead2a3437584881a3ec555ca0757a117e6f",
                        "size": 2051
                    },
                    {
                        "created": 1698752856.864362,
                        "modified": 1698752856.864362,
                        "name": "/dev/shm/incoming/2023_10_31_114715_00_00_V_010.kml",
                        "sha256": "1df3062f0427e21f26b53a53d1d87592f595b7047993c5bddbb71d95ca2a1fa7",
                        "size": 982
                    },
                    {
                        "created": 1698752856.264362,
                        "modified": 1698752856.264362,
                        "name": "/dev/shm/incoming/2023_10_31_114715_00_00_V_010.jpg",
                        "sha256": "6db84ba0e35b5d922f69d623fad1852df1d8bfef27b2907edb7973f868a5db0f",
                        "size": 966527
                    },
                    {
                        "created": 1698752855.804362,
                        "modified": 1698752855.804362,
                        "name": "/dev/shm/incoming/2023_10_31_114713_00_00_V_009.txt",
                        "sha256": "2e7e971496d3474c25e29953e3730a8df76aa39edd9d94faa6f8f662169eaadc",
                        "size": 2052
                    },
                    {
                        "created": 1698752855.1943622,
                        "modified": 1698752855.1943622,
                        "name": "/dev/shm/incoming/2023_10_31_114713_00_00_V_009.kml",
                        "sha256": "a93d52afbdf749d5c33fec80fdf9522e4970647e6df2d6f028fcdadb12765a7f",
                        "size": 982
                    },
                    {
                        "created": 1698752854.8943622,
                        "modified": 1698752854.8943622,
                        "name": "/dev/shm/incoming/2023_10_31_114713_00_00_V_009.jpg",
                        "sha256": "820205ce94378e90727a2b38a63059ad896ede1758e1e70a2fdaac7207af591c",
                        "size": 918867
                    },
                    {
                        "created": 1698752854.1443622,
                        "modified": 1698752854.1443622,
                        "name": "/dev/shm/incoming/2023_10_31_114713_00_00_V_008.txt",
                        "sha256": "47c1ea6c770f8931a862598fb49ba28e9eec478fbd83d040c0a291bbed2fbeb6",
                        "size": 2053
                    },
                    {
                        "created": 1698752853.834362,
                        "modified": 1698752853.834362,
                        "name": "/dev/shm/incoming/2023_10_31_114713_00_00_V_008.kml",
                        "sha256": "8ed0e8fcdb5453e0278bc2e2945aa9418111bee4b5e14c784151a761cfab11a9",
                        "size": 982
                    },
                    {
                        "created": 1698752857.004362,
                        "modified": 1698752857.004362,
                        "name": "/dev/shm/incoming/2023_10_31_114713_00_00_V_008.jpg",
                        "sha256": "7bae3815524d82a904b26584c3c9367f41c06e3bd99aeaf347576df15a459c98",
                        "size": 908884
                    },
                    {
                        "created": 1698752845.024362,
                        "modified": 1698752845.024362,
                        "name": "/dev/shm/incoming/2023_10_31_114707_00_00_V_007.txt",
                        "sha256": "e530f2758babf251b49545d97700180ea616a4ab9c8f99e72d13b2b6ce6ba7c7",
                        "size": 2048
                    },
                    {
                        "created": 1698752845.754362,
                        "modified": 1698752845.754362,
                        "name": "/dev/shm/incoming/2023_10_31_114707_00_00_V_007.kml",
                        "sha256": "d416ee8c9c2b1091c5e28d095b5ca7adc0af5d70d2d2a63b29fe31e37de49738",
                        "size": 984
                    },
                    {
                        "created": 1698752840.7143621,
                        "modified": 1698752840.7143621,
                        "name": "/dev/shm/incoming/2023_10_31_114707_00_00_V_007.jpg",
                        "sha256": "3e0012cfdd1dd2fcd772e8ba800c67757efdf30679f556b530e44644b219d0c7",
                        "size": 915307
                    },
                    {
                        "created": 1698752795.8943622,
                        "modified": 1698752795.8943622,
                        "name": "/dev/shm/incoming/2023_10_31_114606_00_00_V_006.txt",
                        "sha256": "0936a7c1476efc4ef73458e2181d745c7dd0549d943c68c598122d584b22185b",
                        "size": 2051
                    },
                    {
                        "created": 1698752796.1743622,
                        "modified": 1698752796.1743622,
                        "name": "/dev/shm/incoming/2023_10_31_114606_00_00_V_006.kml",
                        "sha256": "267e2245c250d1a219b2b1164b57da88a826f89449e74d260c8605fd5e8ba2fe",
                        "size": 983
                    },
                    {
                        "created": 1698752785.274362,
                        "modified": 1698752785.274362,
                        "name": "/dev/shm/incoming/2023_10_31_114606_00_00_V_006.jpg",
                        "sha256": "26337faa4e1d9c71d1d16ac3f4958fb6e651f5e0de7ab686ca7da61c3bccaff5",
                        "size": 963584
                    },
                    {
                        "created": 1698752784.334362,
                        "modified": 1698752784.334362,
                        "name": "/dev/shm/incoming/2023_10_31_114604_00_00_V_005.txt",
                        "sha256": "eb766f3b0e91a114b64004c156f652c312a01f43109c9490019d01f2e6cb3884",
                        "size": 2053
                    },
                    {
                        "created": 1698752784.1843622,
                        "modified": 1698752784.1843622,
                        "name": "/dev/shm/incoming/2023_10_31_114604_00_00_V_005.kml",
                        "sha256": "b01fdf339c7f5893d8335bf4ab42de45b3cae750e64c41ff04c5d42383cf82f8",
                        "size": 981
                    },
                    {
                        "created": 1698752783.754362,
                        "modified": 1698752783.754362,
                        "name": "/dev/shm/incoming/2023_10_31_114604_00_00_V_005.jpg",
                        "sha256": "127fb3dc9900023056355b5441dbcf88fc96307f801be18b5e48b75ee729a404",
                        "size": 945319
                    },
                    {
                        "created": 1698752784.9143622,
                        "modified": 1698752784.9143622,
                        "name": "/dev/shm/incoming/2023_10_31_114557_00_00_V_004.txt",
                        "sha256": "c9d41ed118dbf32fedd79eae6e3117e701ab86c0376d4a108e3d6695a4db701b",
                        "size": 2056
                    },
                    {
                        "created": 1698752780.044362,
                        "modified": 1698752780.044362,
                        "name": "/dev/shm/incoming/2023_10_31_114557_00_00_V_004.kml",
                        "sha256": "34696bce99627878ac213e8b21c16b0c7f505a8cd7b6abad84b6a9032113af6e",
                        "size": 983
                    },
                    {
                        "created": 1698752778.9443622,
                        "modified": 1698752778.9443622,
                        "name": "/dev/shm/incoming/2023_10_31_114557_00_00_V_004.jpg",
                        "sha256": "029e8e91f2ad4ed675389ef08aa0dcf4898971d035ec1427445f907d3ca4ee89",
                        "size": 904233
                    },
                    {
                        "created": 1698753660.274362,
                        "modified": 1698753660.274362,
                        "name": "/dev/shm/incoming/2023-10-31T11:30:59.ts",
                        "sha256": "5b6851c66ebc90109e43a0b65f16c237853470ec2e8682d6294cdd8eff1085a6",
                        "size": 410628848
                    }
                ],
                "mission": "EFCA1",
                "sha256": "f3185debfeaf93c82d3c25dc46f1b175df11c88d70c308082813175e87e96ba9",
                "size": 462022127
            },
            "errors": "",
            "filename": "2023-10-31T12-01-09Z",
            "size": 462022127,
            "verified_at": 1698782137
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698686426,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "I5wt6YXvVH9ON0ulPbalU31tUQx7vzGQyCvzF6b87SrL5APKbJPNDq/t+Oo4HOzDAE1ixpJp9Uuf3MXaQ7W3Kgq1/jyWk2WqIZOspVqBWLbl5RKKOK7C9osdZmgBSoNlFbFj64YNryJyB+VAacrhCb4pWmE95FLpAacqdw0hR3nVsQyJC4xUdkOrRusTsNWmufvvBAEdoZYU8CF0rI/I7WY3zzXsGo+JGSNXXmffWePUvjUp5eihVgfaunDMF7Z4lzsa4wKs9oUj3iIJBvaQLiZRJqrloGedavR5othTfppib9Sg4R+U+yQU9qSBwuel21xBWzFc3R8ymJCpgHzph6+2kLvRiPYeWbQlX2dj+adKYQqDzkVdIUVDi0/dGdY+0jsxe+oJl2/gB7mBgJ2WHhIj0rje/uLt+Ht0hJxLAhpWYOr5aHQ+XHZ2wOJDqzcn6vcFK64TMs86JPbRwMz1EKqDNEggi4L8AyR0ePoBOYinPWURwcPK6WJuHWrwqqlfFpXzSue/Ze2IZMRW8d1PFITE0ZxvuX7SodGXcvf0i4kz99MlHIehxO4QWJXbSP1Lr5FGyuVITweyXCTpr0SQSV+66AoVIZT9v6YRT9D5VSy9CE8C46QlhDHmlZUEjo8BW2LE9fa1CSkK6I1co7NPSRcZFbwrPrGkxzQf7xRD4kw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T17-20-09Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698686284.908698,
                        "modified": 1698686284.908698,
                        "name": "/dev/shm/incoming/2023-10-30T17:08:01.ais",
                        "sha256": "33d49c7722aefa0c0a5fa663fdb7000591bd29fb90a59f3c408608ed984d4455",
                        "size": 37309
                    }
                ],
                "mission": "OSP2",
                "sha256": "a599bfd2779fe369c922a1fc20fcd7092787a7e561152ad9d6c637b0c2f50431",
                "size": 10439
            },
            "errors": "",
            "filename": "2023-10-30T17-20-09Z",
            "size": 10439,
            "verified_at": 1698753751
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698505781,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Mehm30LSpAK7nnoUgOFdVQ3+ltyHWAZpqYQiJUokyi89ZaM76DztV9bQAZ4BFjVKJ10pQL+LkqtpfPd7kKnT8J8Spi1uE7pCVpLGVDz/CdrNRpz6Smv5bvgIUfNaG/dJbxItfGbZGp9RTvpqKlD6s5f25V5BlZGHAW48fbegdkJjp5L/M8xsFMd7Nu2NtDvqAdBpXS7QcQjo18ynb1jHaJqAFIigLN3LsCnjhn80n8QajCoZyZegKr0AxVlHUMusDnruGFPtgtkhm8b/ttMz4NqoyfSEI+s7AVaq+f4zS/ANnv0qjynxGwPt4aPA7zNGXmfgjAVqQFYFnwhjgjXjwx6eyuPi//W5VtrLXB/orA5SZDHpaQrSOmY+8m1aWsCOmAUTglY6meJMGzK3GsgXrwyaOjO8iJdOWCJISBeIa9Ne3RsT9HmYJm+SWSjrWafugcvV10PM2KCC8oVKnP98PyyZ0Rf+lW48OCxXsTctpIJSXJ6QJXvBg/zd7+5maUDHUoz0j2JlVYmOlcibGFpAPD3SZ52CkwjTcHh7Gk5XS1hu8uSfIIJKG3xJ9ANuIUf8gFhbdDz8AknkjlLgQHpRVpmb+s9S4pNXIltOYR+tX+cs4qClqmSfysb1b9ycfC35kAXO+nDuz0bOw5NPJ4AHTWx5XmibL12BCPY63IQ4WeY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T15-09-19Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698505646.2128727,
                        "modified": 1698505646.2128727,
                        "name": "/dev/shm/incoming/2023_10_28_150657_00_00_V_062.txt",
                        "sha256": "1afb39ce45f35e717b65facfe82202a34f2c02583faeb4957f8ce47e905ca051",
                        "size": 2032
                    },
                    {
                        "created": 1698505644.5928729,
                        "modified": 1698505644.5928729,
                        "name": "/dev/shm/incoming/2023_10_28_150657_00_00_V_062.kml",
                        "sha256": "d919e7c41b11ef5a77052f76afbb7c06e0690617d227be165e62830e4b9f28c6",
                        "size": 978
                    },
                    {
                        "created": 1698505651.3628728,
                        "modified": 1698505651.3628728,
                        "name": "/dev/shm/incoming/2023_10_28_150657_00_00_V_062.jpg",
                        "sha256": "7f79d35bd8693033633145233e4bdb8b9d056ca4537bdc3ccad42f6bd78c35db",
                        "size": 820428
                    },
                    {
                        "created": 1698505619.7428727,
                        "modified": 1698505619.7428727,
                        "name": "/dev/shm/incoming/2023_10_28_150625_00_00_V_061.txt",
                        "sha256": "1b5a247e0426c7af505bceeb5faeefebbb4d5f9fa50df26214f307e01e2620e1",
                        "size": 2032
                    },
                    {
                        "created": 1698505619.5928729,
                        "modified": 1698505619.5928729,
                        "name": "/dev/shm/incoming/2023_10_28_150625_00_00_V_061.kml",
                        "sha256": "b2ba2f1d58291e223174d13a75d2cc055147388d6f1c812d15d421fc90a71880",
                        "size": 977
                    },
                    {
                        "created": 1698505608.8728728,
                        "modified": 1698505608.8728728,
                        "name": "/dev/shm/incoming/2023_10_28_150625_00_00_V_061.jpg",
                        "sha256": "1e1d7325180018d645403f7b6893c110496f345374b201f31ebdd4d1495fa200",
                        "size": 828589
                    },
                    {
                        "created": 1698505585.6228728,
                        "modified": 1698505585.6228728,
                        "name": "/dev/shm/incoming/2023_10_28_150604_00_00_V_060.txt",
                        "sha256": "b776c3e6b01f5861635e06b3c9573055a5fcd4dbf725b62d86d7be8a91758723",
                        "size": 2030
                    },
                    {
                        "created": 1698505592.8328729,
                        "modified": 1698505592.8328729,
                        "name": "/dev/shm/incoming/2023_10_28_150604_00_00_V_060.kml",
                        "sha256": "7d8fb532a2fae6cf4420e2f024fca57cd98c872efc12190ddc636e0e99171e72",
                        "size": 978
                    },
                    {
                        "created": 1698505583.4628727,
                        "modified": 1698505583.4628727,
                        "name": "/dev/shm/incoming/2023_10_28_150604_00_00_V_060.jpg",
                        "sha256": "c7e20b481a0e987227c6a969a22fbca7baad2f6bba2575a833eda84d13d70acb",
                        "size": 812046
                    },
                    {
                        "created": 1698505582.4028728,
                        "modified": 1698505582.4028728,
                        "name": "/dev/shm/incoming/2023_10_28_150559_00_00_V_059.txt",
                        "sha256": "5690ae7e30313b5e0f4a490280d1c26c057a822054d60b94bc70c2d9bf0ac1b7",
                        "size": 2031
                    },
                    {
                        "created": 1698505582.9428728,
                        "modified": 1698505582.9428728,
                        "name": "/dev/shm/incoming/2023_10_28_150559_00_00_V_059.kml",
                        "sha256": "3b79da1eb2b3890f76b86bd47adcca274dccaa885210a65478fd0f17b1253677",
                        "size": 977
                    },
                    {
                        "created": 1698505581.6528728,
                        "modified": 1698505581.6528728,
                        "name": "/dev/shm/incoming/2023_10_28_150559_00_00_V_059.jpg",
                        "sha256": "1c79999b0f0fcf02590202dfbe991b1f8d4f46dc72a5ee803ab78b66d5cb5f18",
                        "size": 859580
                    },
                    {
                        "created": 1698505570.3828728,
                        "modified": 1698505570.3828728,
                        "name": "/dev/shm/incoming/2023_10_28_150546_00_00_V_058.txt",
                        "sha256": "82700d14a44e764883f96b32d39719ad4dca17152a2cb99edcf276b914f9fb6e",
                        "size": 2032
                    },
                    {
                        "created": 1698505570.282873,
                        "modified": 1698505570.282873,
                        "name": "/dev/shm/incoming/2023_10_28_150546_00_00_V_058.kml",
                        "sha256": "1b0902c057f06c6dc096d6dd9f3d7fd2dfd3026b01990bd1a66b2138eb29c07c",
                        "size": 977
                    },
                    {
                        "created": 1698505571.1028728,
                        "modified": 1698505571.1028728,
                        "name": "/dev/shm/incoming/2023_10_28_150546_00_00_V_058.jpg",
                        "sha256": "a051a4b65ff4d79edb11c89df9850fad0c87056fd60d9397d0061b9b6351b04c",
                        "size": 979892
                    },
                    {
                        "created": 1698505564.2628727,
                        "modified": 1698505564.2628727,
                        "name": "/dev/shm/incoming/2023_10_28_150539_00_00_V_057.txt",
                        "sha256": "67dc966f1c1b70b67cd2b678c14d4ed730b478e5aa10e5e04c33d72ed11ad31b",
                        "size": 2032
                    },
                    {
                        "created": 1698505563.8328729,
                        "modified": 1698505563.8328729,
                        "name": "/dev/shm/incoming/2023_10_28_150539_00_00_V_057.kml",
                        "sha256": "8c387b591e1ce8b18566b6c44b7bcf977096eb686357b708e5971478e3d66dda",
                        "size": 977
                    },
                    {
                        "created": 1698505557.7528727,
                        "modified": 1698505557.7528727,
                        "name": "/dev/shm/incoming/2023_10_28_150539_00_00_V_057.jpg",
                        "sha256": "e7ec5ba8f435eb0d00aa7f1094a337d246de6fe07ab8dcda044c019a68589ca9",
                        "size": 742674
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "9695bebf8123f49e175c839723573ff35721d3d997f535bc4fa24ab9e2a9921f",
                "size": 5038534
            },
            "errors": "",
            "filename": "2023-10-28T15-09-19Z",
            "size": 5038534,
            "verified_at": 1698523030
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698506734,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ThK4JqwuAjlHSwmuFUg1dXSDL0ctPt71GPnOtFvp72bAgbiWbTbGHpOsBuHTW5XITiHn6DgFqDmdwXjnbewJRVg4dr2A/l+h3bugSYSsQGgy7BYoM6yMthkF/SPjg4wy2CfpKBAwxeswu1s06/epPM6V0YOZtj2JjUAZORGjjlR4+ZEC6CJTethcQWdmiGvS8W6JIxCbnd05Qe8rul06XGR4xU9g4EvaBTAAjPqi5Txu4njeUSZXyKZV6tPHK9dfGMkKZxt0vT/wsWx6UYUSqby841rQ3j8S+olDB0YC7QPGp3lVjIzYIKWgOEU/L9XCeIPig9+axZ1ViXlwUqCczeJKnQERtVA2eZQJm0SEFE78vUVe47KuhOrMFNpfuql9Yb2NXJiRdef0c+iQwbhiYzipFshU1PG+HtxorCVgn4mwPGrUpHLiTDXc7UZEt4RFtUeMm3Tz3JTwoHfJ/QjXgL+Pwh9e6YPKkTpiH5ef6TTNPyuJPzOUftBrsEsKRfrpYS0Gx01m7JtAkHjc5iYYhyEkYpLBHf/P9mOPv33Seclm+X7lA0TFt7tYqndCdqbBf6qOLGRi3wANZg3/ckumLSTZvHXAQ9yDnqxVZyclMPKdkExAm87JJ7jYIc8xzih4ARjakHPV4TYT9BxY1Bet9hnWJMyCUM+KO/XDhwYqFLk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T15-25-12Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698506592.1528728,
                        "modified": 1698506592.1528728,
                        "name": "/dev/shm/incoming/2023_10_28_152249_00_00_V_063.txt",
                        "sha256": "cc8092d92b32e6965fc4a3a8866724f2e07f4d9f79f7f6bbd7f2b52f32d69be8",
                        "size": 2025
                    },
                    {
                        "created": 1698506590.9428728,
                        "modified": 1698506590.9428728,
                        "name": "/dev/shm/incoming/2023_10_28_152249_00_00_V_063.kml",
                        "sha256": "26facd85b3521f1c6d7d0b0ea2445dd9c385ca15d70aaafe878b4bd2664dd77c",
                        "size": 976
                    },
                    {
                        "created": 1698506585.6828728,
                        "modified": 1698506585.6828728,
                        "name": "/dev/shm/incoming/2023_10_28_152249_00_00_V_063.jpg",
                        "sha256": "25b4e098b17918d82145e0de1901acd939b9483bbfdaa81bb0cb5d4ab7937bce",
                        "size": 895676
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "f2fd8316e839ff08d50a8f28bf0b9b7f5cffd3a33bc51b5820c34e8ebadb8cd6",
                "size": 896089
            },
            "errors": "",
            "filename": "2023-10-28T15-25-12Z",
            "size": 896089,
            "verified_at": 1698523050
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698508915,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "hMIEannk4mZRTWdZeJV/FuuE1r8K0J7UASqD4YTeyIC69w1lYt1lF9wXXuLgLblnTAID4YgXGhYnA9NSLKgmb41Zsn0ousYI8Ket0tW7QyxEiFaifrKutDeAchx1+LblAyEdklN9ddqdBD+wAoVThyiRYib1EdGjCCR2iB09BygK537c2f4JiW82onE4BqZmtErTFUWNLl7zsb28sgBJ2Ibmms4MbkpyHrBejfQRVXa9nX4SXWuAYdirh9myOfZ0rG/o6OUDAZu33mvg2f+lqP60B2HEeGpWoBZVWtP7aET/umd/ho9KWBit/ydvn70bC/VlBgnqYQQphXRYNoIJm7wGUtaK3s4IwO53bnVuFYqQSDyzY8FK9O5A6FBBpK/uf4KLwaMI/y842WAr+jakvNeWf3ClR9UQP6kx8WG0ihMVfsZ5+YdzFqnj+EmPrga4y8/IYI3cHYSg72dmJ38TdPU1Cr1ADyfVCDS/dS6Pm2eKJcQDYL9CXBa1GMee31rZSWkXikl4azN4BKCvQ3QpYxSYbMUr3pcMBRohYVEeuK5nykNXOvSHkL1uCCpNiIqq7Xgtanp+Xkb4wTnVM+lELfLIukpmNZ2hcHEH2Kgrhn8Yjygz5jR5LcshIl7c6AoGrx3owk9wJoLFmoUABUsFSiYJTCsTgqG70qjy0UG8JY8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T16-01-33Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698508768.292873,
                        "modified": 1698508768.292873,
                        "name": "/dev/shm/incoming/2023-10-28T15:49:26.ais",
                        "sha256": "2c12d528ac47475c28bc9fe707cb7a835e6dc8ee031c667ba2086d4cb32efb12",
                        "size": 535217
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "d07f6dac74b7fecb99f6495811f5c131a938f4049148ade43ad83b1bf4e5dcb9",
                "size": 148708
            },
            "errors": "",
            "filename": "2023-10-28T16-01-33Z",
            "size": 148708,
            "verified_at": 1698523059
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698509729,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "QgahzEfI90Noof1imX/WaqEnY6g5OeqMf8Y4spTGQCApHDzrpN4TE/snmOYb/nvzGvzNkM5EmwPn4dMRqEb7A2sjt1zqK2uUmWibyFMmHUEiVdsiillhBo6dEqnhHuyG3pZSko/zSF1mx1hLZ8W1cI8gAwSHTgbH8aqzPRjHUm3IJAxGXNRfJwQj7xzbuLnzB0P9XRQJIK7f97cXniSyFBhsY9He07+chmr/Jc85o1B10PqpXMuLfsglDoU+rN4MRxSAI50iCGXj41AqIlaiXWdzb2vDQIzI2WaHI7CgVaLGOCmiWHOujysN3cqKHfm4B5kec8sivNdfeI+djfE3dOgLdm9T7scWIJQuEHiOCp37DAt11DWU874k4pefmr35V9ncvPw7xx/DkZks0QkVdevu4WvXjZGgE+hLNOO6Ux9WYKOkONYeg42sGYqGqEFRr42+6mEGidp5G9BSdnnhAH9ympPoHKg3AV+Q3zZ8GN7yAt9lP5u0qg+m0/NkLJ6mFJEWY9c+JpAf1w8ygz+xzDRdwulwmUSWGRng0goUqog3zV1pyfgm3aWwUzVRmE0m0s5zU0UQ7NRn62R8b8ycy9/6YnhR4OQs0LuEHUANgF68B7tVhp3QDfAKJdAudLca27MHtOT7tn4OkYJQOU5pt06KSPGGZspYsav6DBp3ddc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T16-14-54Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698509684.4728727,
                        "modified": 1698509684.4728727,
                        "name": "/dev/shm/incoming/2023-10-28T16:05:17.ts",
                        "sha256": "a7f3de3badc1205c726c90ae3f9f8978fdc472d72485585e806019be59c2e6fe",
                        "size": 134425452
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "c86b3db3ac33037b7df2d9d1a9afbe6aa998fe2b344a48077a4c993dc2808240",
                "size": 126198193
            },
            "errors": "",
            "filename": "2023-10-28T16-14-54Z",
            "size": 126198193,
            "verified_at": 1698523065
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698508015,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "u5S5+GPAzRf4qpgb+lOM5Ugoh+gOI04Tu15Yx4eZxKLrAN/c9uyN54qf538bmun5HtF1IEDP3wDyEz4iGTN/9G49ft3tADM8W6QvXTSC9Vx5TJbGk4qNIsAurVq14PK/1u0SQpYHbc2w8fez3OkN3eEf1vufB2syuWSVI1bh9XLOcBRyReJa2XIfR3PzbO4UM844x8N8kFx9ADAILveTZTKClRV7lE3qIi3RcNkIwd56dePDpIgDG4HZH0n3vWMHoLFRUfI5lczI04ozm9cKrCSQXVITSTlBm8P+5ulzTaUAArCGSkIxFUUkDDH5FdnG87Da+WhD0R1e5/O6tt5YemErRWkjZu1qJ8Sel7uvQ8yigPO1FpkJ2aVX/4uIG2lKDiU4KWcLBT8eVD/Epd29k36cCeR1lGOr1IKFWIxgdyKGYWX13yINQv/puQ4F1FBShhpZNHZedb4eDrg1GWlWZP7EtiTQonZqhVb092ZCMkLcY0nHs+eo6Vj/I9HUn7ZsXKpyx8ygCV5AGR4QVa2FyW1247nMnvMxawplupXjJBqU8qhCFwvPqjL9gCY9+sv8ocQG9NG47CfrGvZc2/e7NjGVMWjju8gXvvWwosOWyMrz2pV13axAdptThHbY+i6YVshgHF+Rvl5Hv/UjRNV+xkcz6h1rqFP95vPX3NoSFkI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T15-46-33Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698507872.4328728,
                        "modified": 1698507872.4328728,
                        "name": "/dev/shm/incoming/2023_10_28_154407_00_00_V_064.txt",
                        "sha256": "8b32f524035f177d2d91ab5e136b7b837364594b77d79e115ee42822e320759a",
                        "size": 2035
                    },
                    {
                        "created": 1698507881.5728729,
                        "modified": 1698507881.5728729,
                        "name": "/dev/shm/incoming/2023_10_28_154407_00_00_V_064.kml",
                        "sha256": "1386b199caaf5144ee6788076db19f5bc561783db0b69e76e8366b453bab5a38",
                        "size": 975
                    },
                    {
                        "created": 1698507877.2028728,
                        "modified": 1698507877.2028728,
                        "name": "/dev/shm/incoming/2023_10_28_154407_00_00_V_064.jpg",
                        "sha256": "73d2ff778448a29a1c252c351315682205c6e1a2e34e4be1d33b12285c1c5b56",
                        "size": 1082213
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "ce76b6ae39eb390ee69f953d7367aa9798cdb8fa63d71cb890db1e0db0f4466b",
                "size": 1084017
            },
            "errors": "",
            "filename": "2023-10-28T15-46-33Z",
            "size": 1084017,
            "verified_at": 1698523157
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698510714,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "W/w2vFJe0FgQEC3LwTMxsvr3zNNEytvbWHLGYeTC1/sri3Vzp0m+nNz6SBOOtUtFlnzQOB++84AGruoS70cxjulHqyokcLq4Cq5a9DDtUioqPTowy/pz9at1S/62iofsZOHUKuKwz5xGLrc97142kerLukqWt1U31skFr/1/zmDYAOVKlD1NKHoXItiE9nj9EYjEhvNXPJZvCs6TgrVGXWu8nUwKcOm+0662FfoeaPXgVhgyHKLGuO1P+4f/mhb4FaLu0IE5REhbuDUfXIhLJdzJ00UbGJ7rfM5lV9rOnW7glrIq2PEpu1g8ysB+mcdHXPNhnNVc87/4YcpK9s4o2x+n++G8pJ0UY3yDc0avA6uRKU3P+4WfRRun08GD3SACUnDIqTzo7+PAzOTXE7/BHpRdJOkj6IPg6Gsm1AcDfbd9W7ozFPPnlQACMpX+BjUBTeLWNE5NbV+Gbf8k4XmYcDNCk+H9iLJ2h/jxhxD4N62aRHvetHnJOCoitETCuqXrpPyi+qsh3ADl8rJqzJIHyQd7UvzXatipQvrUGdYji7YDNC8Zrv/MUQh7GxzEUVgVl6wgCg62gwja56u0nxMqG0o2DVQeYuOaKpG2q8d2dLH3TDs+KtofuxOMxnObV54ikcwI7e4hMbwkLvxOvCcAGVGFS6JG6/wqbni8nQ3iUwY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T16-31-37Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698510573.5228727,
                        "modified": 1698510573.5228727,
                        "name": "/dev/shm/incoming/2023-10-28T16:19:31.ais",
                        "sha256": "7035c6eb80c2d36b90e860f4d2652ae955d94706153951676cb13e371328ea0c",
                        "size": 521008
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "e4754d5f58bce3fdd22ec0a4835635fbd879b2d7b62fab89cc28f15c5d63bccf",
                "size": 139377
            },
            "errors": "",
            "filename": "2023-10-28T16-31-37Z",
            "size": 139377,
            "verified_at": 1698523172
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698505308,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "OHqVvpZdBZ31Ngvl4OkHAcLfGwfa+wi3297MsIJnVj5geWym8gr1dnADD6R6J68bSRg+Lq7JHxFS+Z6375tXZZ4tt45x8mRmNlNNoZ9obA335lMN8oN7gNoNcn34QRwklWLGM5HerYMea9N+9Qf9I5VvzO12TwFN3h4SxXAd2nFaiJ4qbjKAK9FW8C5HxqES6nH8aB0iEtN55O+AAL+bg55nGpLRxdsE5LfJriND3rOVo3WaKPjePy01g+XZ7gp5+SzExj3B+wJJQcEzSGfp25okdoppiD3pXtP/VQh4cpUGShLka2ruLwvclQGr0f+x+lvjfPRbFeCo8IBbJEMkSLTiELs6+TJawnztjlHQN/n+mL6k3tQiLHtFV30fh+SpT6j/QsStGrh2yR12hFXVZzLTBD4ekWnEiDyt9qWEx0Lvzql529UQm5dVlLm4lwAQW0DQTaXc3S1cEE7Mlwr6px3gvYkzms/CYfG3CUAqYh5fjXx7FTXOjbQcf/bU27TtWT6D/AWqmz4FYcu0b9w2OUQ4BwKAN/99lMXFqfG/z7+Y3H9xmAKZTg2M6RZDcTXGM31tcC69iLxqOcerwZhUEiwkAkkhGmKIfR9XB3nQjRLpickVcgiMtzL3Vqnd0/24M8eZhbpY1ThcfOAOW3GA+kF5+D9wstDp19/nwMeeeL8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T15-01-28Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698505159.802873,
                        "modified": 1698505159.802873,
                        "name": "/dev/shm/incoming/2023-10-28T14:49:18.ais",
                        "sha256": "a2d8bc07ed8d067dc59906af2678e35b8e032defb74f5cf3ecb11c2d06b1b142",
                        "size": 648817
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "546c3e6c8b2d966f85abba4b3a91ad17005176985460e73683125b8df641489a",
                "size": 183924
            },
            "errors": "",
            "filename": "2023-10-28T15-01-28Z",
            "size": 183924,
            "verified_at": 1698523179
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698511318,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "MQiLMg8Z7HXtUACG4GP7GYk6L1FtnTmR3cIc8QLoS3HDXmQybR5zcjsHfDgNu/2aLYeCncHdTLN2uupV4huw7E0P/0rqvzoIfaYSAc+tFMefjrrZTyzHQ5u+Ybdj901Dn3Bukh9ifqtVrpFfHf05i9rgKEpfrW8a6xSRM44vxK8JWYk+AbQAqYHheqkn4Yd6XVZcuaEtCj908beD00H+oPJvuDjBzuyiHQYPeKo/6tjEILa62wQ7dbklUgZ39oHu1X0WYiJPvdcu+/uYNxvcgrKLPfNDBXYvOJCXyhDP3EyJ3eQnJ4570FTgcmOqkJ+1eVSX5Q7/p6eBmdeYuqmomd0xGpeEqLBbpBDlziHVHKUA6trDPmqEoUtaeACyal1WlCLqUDS2B4xG6dwXldNif4cSDY+RHfOEycMue3Z01hwe4vzsHoNCaXUYiF8eBXNMRGYo2xA0pbF1QNeZSTmOrLizuvTuBA3QeeDR5ZX+swZKDZwIrp9zqb2xxPM4gJWgl6lQk0T0Gxac8ZGRWc1wknONBw3JVrF0l07asS5oHSfhgTJxDPOc9ewESopWNflUwzLcxza5pexK+dV/eYDrV8+PvONZrR7ibBK9Xvi8h6TW+bIlu8Ht+lcnrzGsyTCYQMfPeLs2YaAXFrtqeoFPMtX9FGQyUXam6MTDlWn1X5U=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T16-41-41Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698511175.2528727,
                        "modified": 1698511175.2528727,
                        "name": "/dev/shm/incoming/2023-10-28T16:29:33.ais",
                        "sha256": "3d3361409ff0b529ac5bd81b9cc0180a43f7f8265f0034557c26270eab73e3a5",
                        "size": 416844
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "7d5eb290924691baf2ed6c777db23dba5c889d2a114986c08b75755c9b64f906",
                "size": 109039
            },
            "errors": "",
            "filename": "2023-10-28T16-41-41Z",
            "size": 109039,
            "verified_at": 1698523194
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698510116,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "jZvSpAwaSHQ5AyzSs7Azed3ije+NZGW67DKhUU8octZ5vxs/Kv5NYpWlkAL5YbIw+SyvXHPcXCxzXKiR3L503DW6iwMO+00YL39XmVPbzpOloJrQ3Pzp7ZN4DTbdyAX1X1tbXITxlI1/jNz9KVI8YKLZ4rI8tFq2QhGLN7gNkg8mqCw32C5Q5WAT1mV61A7nFKegqTKMiDa0RWHykTo+LqkgKB2g1NyUdzrUdV5bx5lN9C+S7QsqAi4LISEL8FDzLCAIzDnFIzf4H1lOGjyPcwnLzQVb0oPe22mgbqfAzVzDURV/okrZp1pndDxP9iDoHQ+h3EIjyj8oQNHdvUc9gmSMYkpZ/eMONsGvOYp35fCkXd8xLsCU2i+j5zN4ylNmchdshcSER7eU/iJb6bIFLbFI18xw7H7dDANdmbWzrVcbyZWiNxHS4Mujoo13vQh7z8sVPYdFje4J8upyZYGlYbaZN8uB4CNE8JFMlDu4WLco6HQDpKZ4ut1cd5avt9Xhd3I5O2n8+UGVra/GHkk/TvWQLf5VFQXA7cBoPr/ic0kEt7rD6fvZurg3yhywUcBcTraWEAJhzIv2V0YtnCFvB+q2BVFuGg2k6sHeonWkRbPaAsnQhKO2jlFuLYWfM4uXRPySRfkDlHYW70I0RnF8p9OvOgfegFj2NAzwNz5o6ac=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T16-21-39Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698509971.7728727,
                        "modified": 1698509971.7728727,
                        "name": "/dev/shm/incoming/2023-10-28T16:09:30.ais",
                        "sha256": "b2ac752334c535d95b458323a99ab3aaa723ac4b67f33df36654449b85e21472",
                        "size": 528421
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "472e48fdad76563842fbac08e8e2aed34308258360b435c7db5a549f0151787b",
                "size": 142368
            },
            "errors": "",
            "filename": "2023-10-28T16-21-39Z",
            "size": 142368,
            "verified_at": 1698523214
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698507711,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "R+0DtQTgVpPNcFbOJn7pWC+Bv2exPn6Yr3MrS9/PORNXfR0zdGHEflcBqStonMUhVZ/Rl6nKH954EHNXkc+HuaA8qa4V8ARb++vvAaKWByQ1R4C1Dygvbrco87byxbQNpl3jLr10BfjlAQVpIKxqcQRTaVwcIO+c2BcF26OXkwml9pZ/WcvwMArNJO93Fd0XM9xX0pmeSh+imON8d62pQuwyS96zrWo3JC38S1OHVg5sVDOY0J+jyfdt0VKgR0bNeOMN3iDC6+3jd6pY1I5GxPiNiu7PazQjhxfewJ8oiguKKM3Ma8S5hNfF9VRHPSuT/TyGtWF6Gj+CLSXW+kl5WBVZjdQ3jkMj/CSc6ggNothCw3pEAd1wnjFOOJFvSeN9wRigW+6jhu9Lq09vxFVc1oaIXSjT296BdRhDMkvm1eAfO1nbye9LlmzjcQBSxflp6br3nLJO7KgV7UEhUDkQKMN8HV2JHp5SJTR9hlFEUHbtvOr5gR6aweSks3gkOPUHK3J6g18i4OwW5mzrdzvHOoWFA/FcKXYFicWK0koJjbZp8MM4ve5OcazjzqkZ8aQgmkcwki3qY+ZY3Ht3MKEpxEXj+/ae0WVfkmW8z+JllXwrkTgFylmMU92ckb/Pa+bTjeCrZztNuQyN85ZIE0BsI1AoSTKtH9B895nBMUuHxtY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T15-41-29Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698507565.2428727,
                        "modified": 1698507565.2428727,
                        "name": "/dev/shm/incoming/2023-10-28T15:29:23.ais",
                        "sha256": "d1a8adcc60cd283c51b45de329b8b7576029ff419e8fa0a25fb8cb90da0107f1",
                        "size": 588421
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "c267ad0f5594f7ed98a0306e07c921e4e2f0220a221c1a9490455425924ab060",
                "size": 168458
            },
            "errors": "",
            "filename": "2023-10-28T15-41-29Z",
            "size": 168458,
            "verified_at": 1698523223
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698505903,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "SaYASPW5WqE2qxt6iKkezZWlKpuPSoKS3UYTcQ/ETxFh2psvu5DchMWDhjvniH6dcx9bpFdRqEf3ZTzzfWkXdcr5a8iDmkg5XpORXxv2/l8PtnOXWKw0gGxZ4YUf09lTUY47U1CyVeRDNKjXuP4tDO5k18D2mrvZtIYgUNwKO4ExhTCGc49IeYaPCnEQSAdGpFHa+3i612RPoDeqOcoUPvTjUU4cWvFlUcyzZCj02Scn9y2VIUYaiPmIpspdC12L/CQPd5zO01+pAictiIfdoZztr8pd8ieruaXyI3sJisU+AdZ4c+0YOR1BgcTAxoqPe3+94daHV9bm0NcaKgh51Y1HByPhz+Hp/0pcshaahEWWYxx/ou/kfu1qVxBcMsD2Y4Zcdzd4syZUBMLmg1jZkMwdoJA0oK47wi2Acca3665VgBIBBbFyYyu66CdXp80bpH04JfCzE23O5joC6+zeepiRELfN+3ZcDWKWFH1gHGqKeym5RTaAljtS7PW236Wiy32+OLx1W0XVDmvnlz7MPmjttGMCsXm9M+Npogdfh3hSTABpRlkKozhY8EIl3iOm2F1dYDd3ZuxKVmfhc56p6gbjhI19bvXHwHKG/LGtblzIvKo5pgZvbLdj0v70WbVtqzfsEbdFLugnbpoAq1sErFb66oHfzm+fx7Fo1fYqJoA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T15-11-23Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698505760.1128728,
                        "modified": 1698505760.1128728,
                        "name": "/dev/shm/incoming/2023-10-28T14:59:19.ais",
                        "sha256": "0d24673fcaa34a5123b076981f3b87fd6d797bbc635d8d243c3ac43c64677ec5",
                        "size": 604618
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "96d26626b382637eaa23a137a733807afe58474cba4a50eb50c0e9ca53d04bb5",
                "size": 171599
            },
            "errors": "",
            "filename": "2023-10-28T15-11-23Z",
            "size": 171599,
            "verified_at": 1698523227
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698504709,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Vx3yjqhlaNVO+rwCBQd9xQMySEJVOsJzYwNdxJQ61Pc3CT/2gkMfombu3V42Ll59x2pGwz8H4JAsGqMMs2PBg3w9Mrdl3OYjudlj/8vV1gen63JMwhiUky12a3x1e6+Vqicwjw6/Si/wYysW3fJ3psHTLh8DsGdD67mVMLdlb4RhBJQDO8tE0J6EDj2cnuIdKRAXTwlc2XPfK84AVAi3jHtXkzGL/keUGkQU3NA0IcPww9VRMvzJnC1DNS8NYFzB+cujiUPgx622tg/8YCsmKFXbnTa+nTf/3b+T4niQkqbzfzngZWySqbH/1JDjY05Nrc+7LkaZKOApBSjWLIRGkIu7X3TQ7fapE50pUkIaUmloAdhVlEA7Lfx+T90xdwuCZrxdTytvoq+NNPZitClDDzCmfjdQWAUQQdkA3RAC/GIMnUt3hg5NK7pb/5QbKMO6b3T89Fx4Y5FHqNQo8TZ84skDKGmxKkL13YhGxSRQhfnosmblz70MJkGElz0wIRGES8/QVzNZXc5lD6VN1K7nS0Pk/VX8ZqtJE1WnnKe4f388YAUT0SuXRBMLICOaFna940fCvn9Q04GiCYPGiyOBlE0TL9gheVUQlfgnhXGySAu6340miG9dYFh+SG272vTTJSI6lI1mRFc24fCg9x8Ghv9luf9dxsSkMwICR8XUva8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T14-51-27Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698504558.8228729,
                        "modified": 1698504558.8228729,
                        "name": "/dev/shm/incoming/2023-10-28T14:39:17.ais",
                        "sha256": "c85590014ed1d27cf51220465c4b489ab2864db721ccd11d844fb7d66eaa05dd",
                        "size": 615677
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "d93023592fcdde62c30b340d2cdc0c4cc2b54a85315ace59897ea5b2388c88b8",
                "size": 176175
            },
            "errors": "",
            "filename": "2023-10-28T14-51-27Z",
            "size": 176175,
            "verified_at": 1698523268
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698507110,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "mBPKv318cOJuFS/1NR+kT9eCivHtczGpgmeM9jlrb9zHvseX8vM/DWJn32VIPsd163AhdnYsBs5bdaP9IPjULYgBA58GgSOzmPU00GFbgxJYitDWdzC2B8iVXCND/f0lx//KI4fCi1fK+NUklANOgEo8GbqLbvTCAp9TxCnG18T9v3fE0P09w33MO/DNwTWUerH3jorQYY1HcV6lYx+2WKl5QHzN4LUrHo4g5BX6m1udAhzVS3pG/gtgDNtdttYiWMG9yELFFufQ1PoJqRYr4pYht+oUD9HKvj5qO8fkqm5+mh5Ec70/W9aPJn7CwjIZQQkH+ZTn+S11HARtV9iyaAPht0ECkdZQeZoaAShSRLoXOk0h4XX6ZqW7T2NCvSVjzs7gXK1JhjilQsMvgwgTAyW1PUxvDMJ+igJx+JRjmQbfUOEsAD6k7U5krItaizX4UW6YdQcEYFsj6/GrIiZXB9k837up7ai75cc0oPbWLrNUqafRiV9Hy5Q8qe22JcyuhoJ4M3TadzpeXaXr2iQ8SyY+5Aw26URymKTZOjc5eN7477M5uG92V4ZhSY6xD6oNMmpngFjQDoNDr6v9JwE/RWcMWIRBQ0TyeXmFUxd/XSwxeHSux5vj9dvUrVDYgpWD+TO1WVLAbPmKkQGztv+r/SI7gZTSN/6QVIuFBJxRZK4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T15-31-28Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698506963.3828728,
                        "modified": 1698506963.3828728,
                        "name": "/dev/shm/incoming/2023-10-28T15:19:21.ais",
                        "sha256": "49b16df5e48e1f02bfb50c7600ff4de3a3a68aa738943f34df093609974db513",
                        "size": 588802
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "9c69e0417760c19bc13775820eb28bb3265fa252c093ac0b16352b523a4b3b63",
                "size": 169082
            },
            "errors": "",
            "filename": "2023-10-28T15-31-28Z",
            "size": 169082,
            "verified_at": 1698523270
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698504105,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Ja2BSbQ0KzKtT9VkpnxWj3ZRHIJRdqTuEnIXzcacuZsoxYb6EA7pnbU0W3Tzvd+9GVcp5dVqIsV0fFx4gbf2dDJ86zK0e8KtrjZdiu8rMeOEQHTAczjxRGKYjPTSmw+uwOn6lHuH1KI9Rn2Jha1yqiitiXySnosM85IrKHxy9Us3UeiEcxnSkamddpOjgDFO3IelnDY1Anjbd09glrW9/Mo2AGH/fs3MbLa5YvSlQukin7vyQvO4I8m1ZjJIlqih8AN04Crw0SaEiq6SgyX9Hwi0pnzSPfcuki8EF9K0NJHk/RL9nOX8mqGTmWe2514avNdJtfVbUUUViMk/F097oESONu3HFcxgFuNldRLO4gQkzhTrkwzLrUKIhpr5rCkdAQ5SYLo10D3354gSVU4eQSveeWTys53W6KgwMHIQgJNJjKw1Aib7nH2GcUu9foWVm0gpuhumHUKPIskvswZT1k0nMgUawUJisfT3ruCM7LEzXSCY4flsQehv0lv041UVbozasPt2+8bq//YdcIhXNVohzWwiZkVz5U8G7TEM/eIsUkuvns+1riaNK7VIWnNUL/i0GVI7M9qdtLymOOcKD7+sqiRqx0UKs8JrZ6M6VfAA/HYVXGBWphizaNrTBm5KJeaQt/M+kSrbHiC6S8wXF44JgXo9IMO82OitnyOD+I8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T14-41-23Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698503957.6628728,
                        "modified": 1698503957.6628728,
                        "name": "/dev/shm/incoming/2023-10-28T14:29:16.ais",
                        "sha256": "3b0191e1971d3fb73bd1f1616f81952faf9c15ea7e25d8c97b7f6231d38aaf28",
                        "size": 635950
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "98ec2911a6f06b9bccdb047f42fc7e4a75f6744e3767339c8bb1413777445b7a",
                "size": 184020
            },
            "errors": "",
            "filename": "2023-10-28T14-41-23Z",
            "size": 184020,
            "verified_at": 1698523277
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698506509,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Xolel5hnlH4E1s06gLMMANQ1XZDIFQzGwqItxLuSjEqnvp0SLm2bE1DcMM9rZAiKHGs59FGqfFv8YsLaMYwYRtPM5rGFVsP5KLlw5U5PExskEyROM/qIA/wiJIZVYd8Uxucj+SdfVwvxM80xJqXrGU70bIXqexBwE+TjVKA2/spEQfJM+dvbaDSXkJa7Mtu2aXI8c2CVTQud7tV169mOEXSthtkRFQhIr7B0z7s7/KFVXTcnssiHD4TXZB0S0QctGFaV0YAaNzPpW8bMWYiuOQ1fOdTYEEsEZPso/9+g7wwBgT8006JQD68KulapA+Vuz9PunW12T0d92yx4W545jHchFyKo19X1cTWD/OntiC+qzY/ohgwA0eHWaBLHS1c1pRsUtQyVigCmJZZ4zYh6Wj022llzLUDqYq7cR61s2fmXoJBsJ+uah1vONvu7hvWC3oxau5HGiNgo8xVNNN8THm2YhtTIbnlJMSZNYsoz9SXVZsYxARWtSLxoLgaGsPyDXBbaJF4jsI6EVVOTGDIgRQjf9osL6CHCS+AttaKznuVYkauHNXN7wSFGjNA/b2mKSjohi2k4gmkhPizx5moc45RAmzwAwyJvQsIPFSSj/6sg3xRQWoAuFV1ZNJdAuGOIpr6eR11KcOgY744t+HEANbPPMASNxsu80QLmk+KEuU8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T15-21-27Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698506361.1828728,
                        "modified": 1698506361.1828728,
                        "name": "/dev/shm/incoming/2023-10-28T15:09:20.ais",
                        "sha256": "fd811d2857ea1fb51c89df4b14e3f206325c276850923d9f9b334fab799b866d",
                        "size": 610878
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "e3fd08be1cdddfccd2a5361815c4f6fd073d55326f08ecdda0162ca449e3720f",
                "size": 171755
            },
            "errors": "",
            "filename": "2023-10-28T15-21-27Z",
            "size": 171755,
            "verified_at": 1698523286
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698509196,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "siqWKFDJOG6gSaC5/p90190ig5bK7C5oRY1dzdtZ6tFUsaka4H122YsvZqChCFan1CphyrEPH/zDCmEkgQMTNzLVkpESaiKHAfdx0xdqS0n967IvXoW3EEHI7/dVYURQo2njE/FgZwKSEIXsp5ukMr6ZVH6R/nVy4YqcAOMa9u6bTvbr8YBkWhDrDL2vuwAxzGUS/hTzJy5Jovwi7DUiP9oNXcn4sglVB5ov4vEYoF8SPOdFqx5ieLJt+gcxhyML8PeC0ku5jv7nN19Ko5AI0y1q12UBQ1auKT+4XkK90YfxSuRAOKsvSw618xdlBHphhtWetxyEjBgCLz/bpDuIAF8qbvt4eyZCgP1GkrXkV6nlN+hBbfUFs/P9zdb0UK8O/bnKzfOzQcrOmvPOl3X+6/UGMLoIjGe3ZRbowPThonRdm7cnnFtVUc9wYyo5alcSJ/Gx99Cj6S4L+FsvX5cdSuH+vp0q/Ztnc4arut09wWwI/rooEiF4njY8HcQL+dNIPQjVLb3N8we25fICYJmpnmIle+x5CX29btG8/mOCGcfQ+6HvpX4I2okZywwpFF3mBKXbjxHJB+gH4CUT8Ck8KGhOWGOq7hvPaWbYUjuNHOXkmlO/z/91nRDzVMGRYOIhQ9AbXOxQ3Ktsz84tCxX7ki/5LoXSeiaIvPU93IfciHs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T16-05-18Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698509111.2428727,
                        "modified": 1698509111.2428727,
                        "name": "/dev/shm/incoming/2023-10-28T15:35:10.ts",
                        "sha256": "2133a1b353ff15838c6bbadcdb4bdc7c5842bbbec961ea2d269e5dee0b05eb03",
                        "size": 426697208
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "1b8402e3a8262421fa5282a0e4bd9f69883ad32ddd99777610af473bb80c50b6",
                "size": 400280942
            },
            "errors": "",
            "filename": "2023-10-28T16-05-18Z",
            "size": 400280942,
            "verified_at": 1698523316
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698687028,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "kSyHrKSuMoznRIYJSE3tNFjuXsXN/ERmnYcIGfa5CcKGm+cOgLe5jf2HOlp1avn3wfE8ZJc/J79bwHnP5QUYNK3WMatyyD/XpxcH04p4mLvmcxrHB0ka93iqUPh7vLIMOV+GbuQmXfYw4quxSNlIR8xXNEp55FT+ECz4eW/12J+XgB0EWzZ2/SnPh47x0C+N04xX06zN/0mLiRLalYOijIYjZ9eLgIEyJsFu61XDY7CE3ecaAu+5z83XU+neFYya6LswJ88B4YmxuwPBAHyccObxl/VxpHqeZbzUZpzzYNkeoQ7Ahy+nnq6SNgOd/NiGCbENMVsyc7raqRWkx4Yz4r3ahPwFWcUNju8yju8h24PYfZbzM4gwcZT3ocJdOhHYpVOHWyM1mDM63YqU5v2ER9XZWM5m0HFDebrb2toPmXY+Q1pCOnuPsogrhnbwYlBUQsYVOFU2ZhTBSnovDKWq2XS/TTktYdtwf1L9mHn/xt1Ju357dUr/AZIDRw87IiJgTwWmCGJMGF/PDmWxogevG6Y68xTeuH/1x9xQc7JGMsdImnH1iWGr4F5cR9hcMcWIhLKM5SrT5bhATy49Z1GhLhpMM+mSWvo/IFokJVj1mdwFqW4WA0TQkArCxITpo4YA7bpc3GoQ3V1C+oRds8vgRboB4ubL+QY1LA8gFKNmeJ0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T17-30-12Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698686887.6786983,
                        "modified": 1698686887.6786983,
                        "name": "/dev/shm/incoming/2023-10-30T17:18:04.ais",
                        "sha256": "7d55af8e167297859e0b95fb44c90eb883e02f2d2a46d8d6271bb85f63a6d984",
                        "size": 41438
                    }
                ],
                "mission": "OSP2",
                "sha256": "b0dc34fec71a7bc2203461a387d33228650a4ce5b5c59c367f7d3622eb3918ef",
                "size": 12057
            },
            "errors": "",
            "filename": "2023-10-30T17-30-12Z",
            "size": 12057,
            "verified_at": 1698753888
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698505169,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "dUYVhJBhE4zUKBSS8/W0IseCqlvtDLARXTXtKbCyW7g3Ja+EAg169+XVdPnqYsut6YhM7WEokF1GKEoslC36GDo+3aSnzQ92dODygBb3yZYZCZNBX28IyGxr3w6mEqmrsD2lMr1siJQhPXewmib/duM3edJbx9dzePeCaQkQHQWLVjcxmQmOco8mevKIO66fFlze3sQ7iEJINM0nO9TI6AGE9BonT9bOyM+0jwigcPM6+qQkHJKRb4WQvEHyPBZBIhcezoiVqbIWUIclTd/aSA/FrUyDtSQza2X2cEBCr22BVkA+yV1/OiD2TWZSI5O53D3Ezn5f+YwUm1AlPrwqFhCBY2NeXJOAHuVg5ZjJv+hK3lSTxDVAlw64QPY1Js2DF1jXBoNDB9zRns9W097AICgGGblTputxFoxitayM2aixjtiItOg39/uNpsuIl0jgTYbMCI75Jo+0VGGwmLcl1TgkxxI/OkDbEz21HEJ7LXgN0e1GX+agpNKqoqwzoFlBvR++pXfEYTsIJXgVUG0dpQl+j6WjpcWifkTPiKX9f1Y4c60PMp249J7GNhu/sF8uFogmMYEj3CXhICT9kHPdhbFM9JT13qVheoLjKPdoj/6m+AP9D50dzj8/aLTl4bSmxoi7/iO5butkNN5zuF3s5jqt1uax3d7iq9qJfZwP0TU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T14-59-07Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698505018.3528728,
                        "modified": 1698505018.3528728,
                        "name": "/dev/shm/incoming/2023_10_28_145635_00_00_V_042.txt",
                        "sha256": "c5b0790116920d01dd9c5c0143180efaca30f482d264d30b71ceee02a5758cee",
                        "size": 2028
                    },
                    {
                        "created": 1698505017.9328728,
                        "modified": 1698505017.9328728,
                        "name": "/dev/shm/incoming/2023_10_28_145635_00_00_V_042.kml",
                        "sha256": "1d6536c2d4fccd1202f69fd7641ff9fe3d0262d844739a1e633321d45a1741b8",
                        "size": 977
                    },
                    {
                        "created": 1698505027.7428727,
                        "modified": 1698505027.7428727,
                        "name": "/dev/shm/incoming/2023_10_28_145635_00_00_V_042.jpg",
                        "sha256": "b2929e5118b1def4b243e084a144219d07f636184146d2774096512fc074c701",
                        "size": 967033
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "0f79d5dcb66f1d8c1dcde0b57b9479b61088541be5e9a1e5ba0878cccfa55250",
                "size": 967573
            },
            "errors": "",
            "filename": "2023-10-28T14-59-07Z",
            "size": 967573,
            "verified_at": 1698523451
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698691855,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "BVSv8eqJaYN0IRNLEzxuIiuw0pbF1x1vJ0iwm4zCOdvJBl26Dlrh1+PgBMPCso1ccr+dx3a7hmNK9S0aeffIfXBvPdyc13cYbv+AT4rCh3yYRpcz0YJ0xiD4GQ9nQAoJISqyI6AGfL4p8niV5gqxfwFs0dtKoUJTxgEX91n0EhGXFeEnotlD9Um0iR403TR8qNLBI+lOb7/3T/S+TLNCewcZNFPJExbVbPW/I1sxa1Z1tuVI+lel1LBY0KewVshv60vYAsJERHAS7MeAGHiei3cJX1lwdT1SA5Pm9mCj/d8zfk0lwp6DPHPZBAkuTwBouCIFcZQACa4KmF2AzMY+oPWO/y08uCcECh8Ft752B5nyY4wYOdB70lnSISr+I8QmvplwItlPt/EqIGlDv6V9IXfUnSisoT0Ln2reTLTlDxFNkqGEh6EvRTrriRAk8WujUoDA1VVWqlSpasYFc6aChgI6/LX2epHuNClUCftsWT2F43xmIeJ0WmFxYTLcNun0j4UmBDn4QmWhLKm4ub/bja39l+ddt+BrMzanYw+yWuwAa9f6bxW93VTsAKmXqRVb85r2NjtxS4xlcrSy0pEctYgT3oTr3ZFLRdtaal/ImkLYny4rqH75pwvrjUfpYPsZVSC3hwS2huFIvX8DR5be7dKbYhGniaV5oyudayIkTmE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T18-50-41Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698691708.6786983,
                        "modified": 1698691708.6786983,
                        "name": "/dev/shm/incoming/2023-10-30T18:38:24.ais",
                        "sha256": "7ccc20fe300af3a61a90f9b72016154fae1a558a088286039ba7f6f2a59ced24",
                        "size": 67654
                    }
                ],
                "mission": "OSP2",
                "sha256": "07a7e68e3f6b2ec038054b710774df857a4093ec7f176dea315fd952b2582803",
                "size": 16355
            },
            "errors": "",
            "filename": "2023-10-30T18-50-41Z",
            "size": 16355,
            "verified_at": 1698754306
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698507393,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "h+dQHw8n2L0tyujJ/oQJ9R7iC21AQZYzhlDl6l7yQcTXj3E35wkTyMBbZiiALdXZ54+SfRq/e9nsWJ0SBTO8w1uA5bWkklLYVpbSR0oTJGVrdVJ9APViOp44ldy/rJcRTt1XykVgwVaZn3qTh6/RhWv4RgdPuTDIixuJkn9syFerTMllgNpwp5R1lHONhQSi1bmWpAdI2/LVHEumUZRETvP1F4IgFiwoQbWJCAFOsZSgmYEL8/Je/B+tb3kOLJ2kSD+NpumHeTV3DiMizguD7/eiD/1ONCyn5aydHhNz1s2wrymnX30Imq6H0zTBVpGHOCefRV07IcUXnlMsGTVA7ClXXY9mKWmolKc7pzNVzEeeWTcjJt85J4MbDIe3+qNOY2eYbLDg5ybhsU3Sa8NIl7cPxM/uDUE4OWQvfrBoESDaIPmDn8t2edLH6hFVVGeLDmrHJ9DBVyE2fQ1MkFKoohenPf4V1PGgQnbiLOjjF7AO7rF9ZRzPJvI+mvZZotsc9BLOu5h5FwzqHYCI4dulQoAIESCAaoeKnTPiIw1wFcOjEWXntPTxRHw612d+jbWnCDa/UjN5tuaN81gAvNAIe+Wt2E5o6P1fZ5aVc/XQLjJDxGRD/33akIoSNvA8LjgC0rndOhDyMWNDCOO3DW7+SfodOLJzNWY6aCNMg9u33Ak=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T15-35-14Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698507305.2128727,
                        "modified": 1698507305.2128727,
                        "name": "/dev/shm/incoming/2023-10-28T15:05:04.ts",
                        "sha256": "70807a434d3222d3775a703e2e4a7e8e38d734efa42b53abcd0ceef7b66477b3",
                        "size": 426587980
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "972997f4a65afc6231a5ad7400407398af2f33cc5e5e9db23ef8ec5d539bdce4",
                "size": 399436979
            },
            "errors": "",
            "filename": "2023-10-28T15-35-14Z",
            "size": 399436979,
            "verified_at": 1698523821
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698509520,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "VBY45gNOEN6vqS2kWCMJHvPgBI02iIA9qgPnUNnR/q8swt0m7fhWk3evS3vM7kyhN9SLjPbT5/vSLqK+lKEBd9CC9QNkhmLrluAE60IbLl/IvMnGRo5vn15aRzHhLA7i2lqb+xZjGuTmxZ+zQC10VviRlYdxbebz7UfA6RTrS/Cjec+56AcWSdPNkQ9CVNyf3HXE9Lb+Y7BwNTJ7J85xyYL/0nZlOQrPUJWRxOZQLzV3W9EVTzt3fyqxY4PkFYiEHZ7CDjwTMCWpPbuYSTOkA7kpbMnH/opLBS3QoWDjqzL6GsQ7ScUzUv1dKfQ/OvjN3lF3fCvvju6tktE6YY1/SVIluvOMhiS8kRoHITEaDPSShXWl00WOGY+ETc2fdSOmbRXG0FzXJ5FrTEaCPHMhbmWbujh6KYrkhPokyCp2/RTebjGmQ7EFFP0K+gieb4QQ7JL9Jm89CEun+LKaGbOhpYOKZi5SlM7O7bUEa287/G2Wqos8iRWsS7hNAgXzNN6hgA8DdSMWCNXQERjy8y/kN5GBuISk7L57MltRj7wia6lFqtIi0yh08OPxFIBRSA3aujSfmMJ5L8l0uJ5QAOrN9pC+NDJUZJha0D1vdVWY66LgRX//WeZEYFyubN17/vCjULQd9WcHN/XSYbAsD6yQwbytttUO0McTEpoKYkmbhD4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-28T16-11-38Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698509370.802873,
                        "modified": 1698509370.802873,
                        "name": "/dev/shm/incoming/2023-10-28T15:59:28.ais",
                        "sha256": "8cf5f77a8ba795bab81eaafb683344716db3b2ab1c06ecf41777d12cd4eed00b",
                        "size": 463518
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "49910989d6f22d08d00e9b831262b3cc9f25706ed0ac845014d2146377d34f5e",
                "size": 124764
            },
            "errors": "",
            "filename": "2023-10-28T16-11-38Z",
            "size": 124764,
            "verified_at": 1698524010
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698755182,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "bOLuHuWFXwabNtqkwJjx87fDGl/ka6LUJSzhaNtspecwj+aN17juMP2C4dVd6OQRFFK/EVgqvufDXdmZMJet6hQr/YmFz+VF+DLg2U1+ZObfAnSZGjIJDQapzUyt6edY+8pi5gzHMIciqXQ2YlFndaxJwYfLoQ74UX4BOOuxgaA1eLCFPy5KZQH3nEyWRe1dtoIJdWArla+KtamAHJb00l8ddX6ltPMnGHI/TNGhFntM0QnczlkAfaVtLVCV+p9j9odwyOso7ZYaLLzMcgutPB4IZbQUgLILymQDWeie78jjPV+VdZc1t1qV+qCV28F8twnxPPUhCHoPoD+hWH2CNsNUdHgKL9x3SQqxT9iV8U9KCnxOAY6BUnWvtTSGa4g269ICWPZJtO/zJaC9q7ljhdpXqneNNfcjKPtabiQregQHq7ZyxtjRuKk3nAtMoChNdENWL+c58JVPkhVWGOwnlMCf+/sMFbaBngNIoLaS/VrMg2p/1HGj3AdmJ9ZT/qafxNr66h161jqxKjVKKN0KUInJxvhXsVU09j5JYA56XUoC5G3wFsiSyyEVt0qMcFmsj6LLkp3+avXo4oac3QogzBKZ2cJsNJ/SsdS0bUNTVoS/775eNNvskQnqNl1NVPU5z7p8TK2zDM6E85AJFUh2sWMwkT1hQ8ludHtIEwlNM6A=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-26-18Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698755055.139791,
                        "modified": 1698755055.139791,
                        "name": "/dev/shm/incoming/2023-10-31T12:14:14.ais",
                        "sha256": "509841288683f2860938b2c06392b027fabe122a45a08bf06339f0b8b8057892",
                        "size": 384554
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "7678b34e8b4c9197cd53762c542eb64f6101251dc250e44a2d4e9a976731d817",
                "size": 116922
            },
            "errors": "",
            "filename": "2023-10-31T12-26-18Z",
            "size": 116922,
            "verified_at": null
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698549000,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "mOH6Sz38e/rihjLwHA4bFodsBvGFEjmlHDvc1ZRHlsr5McfvIPmmZ6lxlGjifvVXfhFSUa9g0AmIMeux5IBeizrIBfyizq76zSh0FJxbx4Ub5UM9caAo0Loatc8EOOjmFTJGpJiSfQ5otnFHgJ9WoxUxtpF5sKIxEfGFwmKyabd41T+JP7zhTP+pQM5IIg2L7nCNxJIQKSmoATi5thQvW9iWGCXHxmX79p6Tu7Xbx+tr215zPEfQ+Ot6AL26vBiiMvdY2U//Pyg1QzYO/sQ/dPfb7N2H++0uUcIFkVSBNAjyV+r0foWJ0Xw2ajK6Ao5ZQkiZKYuwXCexETb5yxG21Gmi6ySB21Es1gBx6eXtFDlfuTgzaiYeQ1lWXb75MVdkodq2KMm306oA+LvYct9tJGnG+cBddeM+v8QAMECaieD+/jWwzMw0kvAU6+lP2YMABPF8VHbqxBzXhIaYiAJWnvArek5E6ppaAGudlVUEem4HBRQrQixHg1sr+izbuVFXrMg7E1G3FzdZsfUkE+PcrwUc2TA1Aacvtpr1cgDiFfn+kzuk3X88Dq7FT/yXCNhLLv8tsbJPkVCH9GAhtiC1oDVuIMp7/4Y1dt6fbHQLivjk7axgfQufU0aJlVMxOYJfJpuiO8OzMhKeiDTRyVcLtawdshtPPzNzBvUSDe5MQAk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T03-09-56Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698548869.247969,
                        "modified": 1698548869.247969,
                        "name": "/dev/shm/incoming/2023-10-29T02:57:27.ais",
                        "sha256": "c7ab7488c019ccc280a8d31950d94daf8618402520078fe69c1073d1191edd3d",
                        "size": 17290
                    }
                ],
                "mission": "OSP3",
                "sha256": "cabf8ba7f5bf80de9e5833bb6f06c5fd0232fefd16a0275f3cf6cf69902d87c8",
                "size": 5064
            },
            "errors": "",
            "filename": "2023-10-29T03-09-56Z",
            "size": 5064,
            "verified_at": 1698580398
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698755786,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "RA7mVyvdbRJ/wmrhACXE1daeZX4Rqrj3EVz/1jIkEtxlQGOw7uJRCkPr0kx5EF7fiO6yS4erb8zv2I/nqPRkxZEyVPlGB+UnhRRESy5N0SD7MVkCvAlHK1sy4di4BwWbGBk5tNcACb6jsLeFCAtILDWCaSbu9es6bevv1GoFMjWz6tAuQnzBdM1ms092vTDmV0yq1Cw26wAMMo4EgN7PDa0U+VXeeoPmlhp91eY4eVZAZjNhQTHHtze0uWJjs3wJ8ROgSe6CGp2ul4Sik+1Xpeks9FB6zJ1Mz1cxoLJFicI/0DUQ6cf88Xp2Uy3nDY5Nxzpi+lw2pKroEyCffI0vGZUecVpse/WW7OARxP8ioEBQen/oAiirygpsRd3ioAPMf5d4NZFkDJ5qDtDN1d+OEjybNM2LHljoGyHtBEnXmrZVS8mv0Ix1ryYsQdQVyKZRpHcwikzUcwpbIpWiZlg5edsQPgKfCqJmpzGU7I/pORhDvaOVKCAyTpByffDC9unDK/yRRTRmiIpycnhJiVRic1rLCqKt4rigghQJIGP2F24N20zeCnFz9mrGnnWglxATL8qdDp9++NcpnUEEKaPEl9+2pBh9EKg4uwqII6/1p1O8vuoRCDGlVueoMx1QxeO1WYc1l9+9Eevrdts2bvvqMcGEHq9J5JUkWUrdM4iom9I=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-36-22Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698755655.999791,
                        "modified": 1698755655.999791,
                        "name": "/dev/shm/incoming/2023-10-31T12:24:15.ais",
                        "sha256": "d47770cac5e4e82e4d569c25e894ce0606d6de93a9e3ab4cbafafb181ef7a8c6",
                        "size": 409401
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "f416bb1e271ac2052a8ecba8f46c05c6d046fabc5e5b8632e91a7d6892cad367",
                "size": 123372
            },
            "errors": "",
            "filename": "2023-10-31T12-36-22Z",
            "size": 123372,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698755275,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "semIufc3cmDSjCq5+FcVgJgLiKGcf3dxqrLXhtMcQ9MZQi6c/OnSFGlBay6mVq1miTq6eYQulrXfh0Zsh7i/Yk0WQFz+uX895Fu2WLnPgk9tkKlQysRyWsToOCD0WV/OLNejVbVhu4uV4Ok6EjbPBJM0JhxCA8ZNr+d2r1mdJGXFzJtrbS/9F3VmPXtN/pJT4LGXrs7LBR/Jx0NoLJ1x2P7iqORMPzxVFSRiDAWAVYHp5rT+b4F1WPCFUSRvyc1Gs1KAA5qPDkdAEHHTcpMWonPQfa1Y9MAdWw/MOZd+cwb+ov/sKCN4S9tkVXd9MkNzeJgof8IP3sGdyg0M0LbdjfmD1Y0mFhGL76ZAB7mYQPGXZ8r7Sn465kehrqcejeAnpCjVo/ea5ZGxqhGCFVOeBmlbZ1m1FpKRMK05kJfoJ6V7sa9+xPhDdNrxz+4LD5bW5018VgVJHG5o6ADG63zxTcFPmPRQ95n2fIoqb8ucHziAgRmTqbIa2Pmlipgj6hOf2wQyez5tvH04taFyf59rxVKSLsWFxnnBEh/pKvspUsHdFP3dqn6U/25xrZpJCYPdkYJN1d35iGrr9h+oS83RcEpLXeB1kBgVyv8spbZyJcl4YTWrEQETFlO1VGtXioYAgoH5DMESxumj479/vbDA7OLqxu3dYl3SwCPbOsxa7Cg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-27-41Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698755138.2614186,
                        "modified": 1698755138.2614186,
                        "name": "/dev/shm/incoming/2023_10_31_122522_00_01_V_076.txt",
                        "sha256": "e38c47187c2d9d07f2f90182bc2d5b271beca71da6756f67bc54d22ad45a4ded",
                        "size": 2042
                    },
                    {
                        "created": 1698755138.0414186,
                        "modified": 1698755138.0414186,
                        "name": "/dev/shm/incoming/2023_10_31_122522_00_01_V_076.kml",
                        "sha256": "917dcc7f1dcad89094515f291d4c8dec6efe82ea7c3331d82250926c1e81b347",
                        "size": 948
                    },
                    {
                        "created": 1698755137.9114184,
                        "modified": 1698755137.9114184,
                        "name": "/dev/shm/incoming/2023_10_31_122522_00_01_V_076.jpg",
                        "sha256": "5e0cedfb6788c40cc5c8f784c7654297f98643939641eea36187bfce8ded3699",
                        "size": 1558925
                    },
                    {
                        "created": 1698755126.1714184,
                        "modified": 1698755126.1714184,
                        "name": "/dev/shm/incoming/2023_10_31_122510_00_01_V_075.txt",
                        "sha256": "a1467e1044212177a028b7914ec5f33928af110267ca70111a359cbe439fe0c9",
                        "size": 2041
                    },
                    {
                        "created": 1698755125.9914186,
                        "modified": 1698755125.9914186,
                        "name": "/dev/shm/incoming/2023_10_31_122510_00_01_V_075.kml",
                        "sha256": "ac194c366af8cf4e5ea9118ab0118706dd9da6ba45f1f5ed87ead19fbe1fc6a6",
                        "size": 953
                    },
                    {
                        "created": 1698755125.7914186,
                        "modified": 1698755125.7914186,
                        "name": "/dev/shm/incoming/2023_10_31_122510_00_01_V_075.jpg",
                        "sha256": "bb4553b9451371fcb47ac6c9e2350a14b2ce4d37101cafcad41cd5c1a81504af",
                        "size": 1632356
                    },
                    {
                        "created": 1698755114.7914186,
                        "modified": 1698755114.7914186,
                        "name": "/dev/shm/incoming/2023_10_31_122459_00_01_V_074.txt",
                        "sha256": "2633ca8218e91a0c244532670cbb5bc2ef9b03b002d28a95809da21313f77692",
                        "size": 2041
                    },
                    {
                        "created": 1698755114.5014186,
                        "modified": 1698755114.5014186,
                        "name": "/dev/shm/incoming/2023_10_31_122459_00_01_V_074.kml",
                        "sha256": "97fb0c60d101062aa7351758e9246d98f142fb2ea3b59dbe8c8e861e9fc994d1",
                        "size": 949
                    },
                    {
                        "created": 1698755114.2514186,
                        "modified": 1698755114.2514186,
                        "name": "/dev/shm/incoming/2023_10_31_122459_00_01_V_074.jpg",
                        "sha256": "ca04261bd49d314eafc91db6c298f85a19da066381407afdc67b602e919a5516",
                        "size": 1502805
                    },
                    {
                        "created": 1698755103.1014185,
                        "modified": 1698755103.1014185,
                        "name": "/dev/shm/incoming/2023_10_31_122445_00_01_V_073.txt",
                        "sha256": "832faf26177169606d571eeb062dd5b96ab4db1a305b9dc8724be3d3ddcc7968",
                        "size": 2041
                    },
                    {
                        "created": 1698755102.5914185,
                        "modified": 1698755102.5914185,
                        "name": "/dev/shm/incoming/2023_10_31_122445_00_01_V_073.kml",
                        "sha256": "888768ee87cc977c183836f843c658d466ee4cde78fb34a41fb669ce3b2f2a16",
                        "size": 953
                    },
                    {
                        "created": 1698755102.3014185,
                        "modified": 1698755102.3014185,
                        "name": "/dev/shm/incoming/2023_10_31_122445_00_01_V_073.jpg",
                        "sha256": "a59bae8c8b51945d41cb28b542088a3c995b452e3b94c4f76eab5533883ef152",
                        "size": 1442329
                    }
                ],
                "mission": "OSP1",
                "sha256": "6e8cc1676c9dcf75f2ee18259536317fe29f736aedb9ce6068528d7b03f83372",
                "size": 6143484
            },
            "errors": "",
            "filename": "2023-10-31T12-27-41Z",
            "size": 6143484,
            "verified_at": 1698766285
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698756969,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "Jkn0PEY6AZ2y1NK3y+l6w39n9uSywWnCh9xey39v5bQR/M1ZYreHbxy4Tsv9L+nTDXRWyqhaqv25mSi9Bd1KF1Ch8PYy/CNaNyM7JgLzUMCGpXsLAmXcR8FwvG/zT7InVea4wtbyfIZ6w6zbU+c/yYY4MvpHoIBefVFtsExWHhyIHfFpdQJVAVEPD78lzpkvpflt8qEtoVPMGJrDqutqQZOZj323h2ec6owoY6cUFu5Tq1R982Es2fOgxBNKxTCQITA8m54fQFbn19fvxs0oF4hhyOhueFUo/qRFYvjZgAqoXYc423wXybpk/f5D1oTyyh8I+BjvgBtFBRnTgvrGgJrF1+Ab2th0jakJbPuF0asLJKk6RR1XFjvWtZ4ekZ57epFFSqS5nK2fFzIc1yUsfaEcREIMZm7EtUS3UAmevsle4iBXwHo6QfyK3fPRNnqeRP4fiadjAOZNdV0rW3VNv8JH23CnFjDmrlUJIZ7UAeSYIm/RLTp7yXpNh6AC7fg0bC5kBt6Q5hBJoAY/IDHUrUi1NQxMUE83PsxyA4D5/kztM9t3Z+V/g+pIE11ucJfeQzqSLYUDb7PMZ1CPW4go+M1Ydk9G/Zqfe90GPWFC0Qwvr2tV+OE+myEHbWjzT5dEfCVZi7DZaCR4NGmOPDbkEXxOsngICME6oeBJmDUFCY4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-56-03Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698756836.044362,
                        "modified": 1698756836.044362,
                        "name": "/dev/shm/incoming/2023-10-31T12:43:54.ais",
                        "sha256": "9cf224f50456a12fa3939bce337568949af17b2120d7a77869bcf3380f18a754",
                        "size": 75126
                    }
                ],
                "mission": "EFCA1",
                "sha256": "eb55a2df31d5679fa808899f9949b819521be1df16595cf65f5d91b77ee6d145",
                "size": 18476
            },
            "errors": "",
            "filename": "2023-10-31T12-56-03Z",
            "size": 18476,
            "verified_at": 1698781597
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698756988,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "k9y6PST73wXiaAq6YRMh+h60Fj2E7eLo/G1lf9dcmpTmlHmxv6IPMb2t3KXtmS7t/Pqchvw0z8fAtg+zgO7kFLQLe+pEYgLMaN6MIZh0pPES+JWL4o8lMNFbemM/cVg24lVQweeNyVuS82ldsCiRzWRi7AHGN2hiKJ/F6EeZUXhrF3DeqAglZ+3zuv7klVagzKfmbj1Zp4bZhweFrT/oxPt1BA3Dhbx0NFalFSTbgIGQpQcKKfdGfUvSH34Xw/6faGnHcvV2HljeK3C+8Z01nR/dSJA/Ws2nI6GfiZQbuveyFp48/RVjIWU/FtODjLdpiFCGf+JOh5v8CBGCGSTzeXDh+MKZVviT969oZmND3fG0GIcvpNGU0kZf5PKE5Tlhku0wFY6d8Y+f4dg66MZU3zlphMVilJsvN0hKYpVf+Qw/pg8T8jnIeJoK2puK6nZ5t+KTRm4iueLAAyw5dqmCAdqVCQLrNNNQmfk4f9Y0Q1NIbDTNXKW9c/fb1YlI1xhtJ+p90jLKgtvyT1wkvYh5hVi5fZ7SrTI1+OrisZuiOq0Zg2PKlXhcaE7rKbj/pKxwu6cYq7nuuai/RE226U7s7+MFEcxiMlHPIA7ITgSXNDyOxjrjI/derlo8RoeQU6vIMwhc2GgVnod6+VWF4RkC7MhxxajdNfmCXuM5Pw/Cm4U=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-56-25Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698756858.0697908,
                        "modified": 1698756858.0697908,
                        "name": "/dev/shm/incoming/2023-10-31T12:44:17.ais",
                        "sha256": "350c78bbe341509e2e57c33eedb2ad4bfeaf22ea91a77530ea9edb3322458f2e",
                        "size": 372864
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "5037c7116e5bccc36c7a99a60090c1afc888cd9b7e1464613100bc2b613e59f5",
                "size": 111109
            },
            "errors": "",
            "filename": "2023-10-31T12-56-25Z",
            "size": 111109,
            "verified_at": null
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698551411,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "VhQ/X8vVlyrVEerTxM2twf7YexBO/hZ/67R0ec7YzgC6aO+LFy9vwbjCXE7M3qPPsnTaIMFR+qv98E+DfA1nn56dALfJraJ7T0W4r48LoRZREcmq37i4X8rZDI8O3iHBdXg3Ccjz+s5xfGaiWoqi1ZEDUGe3oj1thFRa3dMPOkCnDg3nZ7oVFMR0X76O0cownd7H9dsu984KLcpAP4djGKgnm3Swx2DQccPKA+9rn+kS15eju7W4JgBWICogPwmZZo61lWpQXymT/7b23kJGDiiKgU3zILd3wPx3yO9MXyUm0A7M0Oxz/2/RMO9caKR68MGvtHwULlI1+RO0rtolpOmCWjlMYCh1Vel5vAh2nCkIO3/DtyAtCJmqV50DKjnFNSK6STI+8uJeZ9cvPyMnt1sG3iER4lA+TnBT/3jC3JDNboMuYdKpcF2ZTJ9V5QlKE0H+b0nybipabuhliwJTLCE+IJ+t3VZOq3+7lyBDhgvIy7R/i59VXdtBl72jkOoMrpu8sOB5wwVqhcSt1AakZnvoybkjnf23P7QTPICPvQCOu/g8W5htZFefbZpLmiD2EBva6Eh2qs0gdw+5VCjeQdGrFEd1nZ9wjXbDnEcUNUJEf1NCF333abjVCGIH+B4TXPoVroI+syQM4Ac9zUt+MGstWylonH9iK29vZZH3LZg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T03-50-00Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698551273.0579689,
                        "modified": 1698551273.0579689,
                        "name": "/dev/shm/incoming/2023-10-29T03:37:52.ais",
                        "sha256": "f866dfcbc95bd54bfaa69d41643072b2a37f00c55a7f604ad23fd59eabc8f90a",
                        "size": 183311
                    }
                ],
                "mission": "OSP3",
                "sha256": "c87d5955172628d0d466dc4eaaf25db7b8d52ccafedfbbe8fd035fb32bc7bf37",
                "size": 53198
            },
            "errors": "",
            "filename": "2023-10-29T03-50-00Z",
            "size": 53198,
            "verified_at": 1698580023
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698550205,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Ov/4f0rk3EK+dyDcTDbJf9ZY/tNIH4CTWQl1AxKBv2TXC+dbhF18L/wCfV51dGJALNBDDeRJ/SZROHts8zykcRSMcQgi8ohPZkOlUxmz7JFXVXSHvMvxtcF1gKLy578e63AxB+OkJ8lLVRv0wQ9At62VTKL/EdKtpLQlmCYfjKk3MFx6OMPLyyIaiWcFTNWzttPE7kjoGQ0fp2RKKODWFVGDs0v7dUdf0YyKmRgrQg45cOWEoacK/KTyUkdUGGvzxwpF9qOQ7cZcZ4jHMLW5+uFgMCPGtIASlUTlLTO9JBPc96McT7HSwyaZgUI6pfWBBAqlwjdNTsZEQJsH3zaFQt2qM77MueRaoyN9O5mYw2BWsvLQsCao30e2WoeXQ0AZhSpvWEqgw5YlIcKBkDGRnomPtHKE3yI8i/KUugBhwUwYNsprp8XW2rpkQpZts9LPV1dHT+L77f/sWfU2y1jxIx1QOiApsOVpYkhUqx3WaVGbs5N9SRBil7I5h1GYcy36JAcSQqrgxZKngoZardmgOISSQBtHVjHwclo6YZ2tiwkAJg5HtTaiH8CTxM/uoY9TxX/ecD2MFhFC7L8wDUakdFtV00Ex9GFdS3pVRQRbqpHDORasCBz7mqOVpFPkGthbQsyAqp+Foo1mrDLDTDqWXGO865MqTtVIhe+/h8bfrbo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T03-29-59Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698550071.0579689,
                        "modified": 1698550071.0579689,
                        "name": "/dev/shm/incoming/2023-10-29T03:17:50.ais",
                        "sha256": "01a6fbb47334ca3fb2554d7ed8f88f84e69396be596d0ef1130b4aa47a0a8a9d",
                        "size": 404615
                    }
                ],
                "mission": "OSP3",
                "sha256": "ead27432bba13b743e85011148be7362fdcdb30a9b1091f8e63bd08e0e4a9aeb",
                "size": 119795
            },
            "errors": "",
            "filename": "2023-10-29T03-29-59Z",
            "size": 119795,
            "verified_at": 1698580234
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698552010,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "tQdo6yfLdLC/HR/1KfW9VMf7PX6OmqpFFr/HyWSswabI1PxxIRWSeQYxKp7pEbIMFqr0xy0FeuZ89IMM+8ufuAbV+/pFogos/es1XCZLjMt0tjf5ytaHKQ0SU5PcM954OZW2HspudF3XkTXTRkBhO8ifKZKdlTOtOy1kgg7By+BQPbRibcyvnwBSJ65YSIqWL3KdZT/xReaAO25W6DXq4fq9DZURVRCK3gVhrRujmR/Ps463+QLsChLbFXNKBD1BDkcqIucNtpLLPjOWzmg5isNPMHpSrZTwxeMA15y5nQKuvH+x/JKZ2Paj3Ocf8SAHUcFAbuLx8Uk10n92YfmsOvYbLb9g5g0uHt2Fbfa8RGLTNiEdQaapRqDIgQ6U6oH2AaDnGpDjiFaO1lAM2FO2LeP/FBge6X3W/dHtuUEYb1FtTKr5VQ2APKuQPW3lCi4DFBf3nfBKVC0xhQikoYjMVvMdjqPLUDNYR45CBAi/QgjTpbRlL/v/cDkMPPn8nCZPtniaL1Ri10o4q/s8UUSFnWth5VJb2+mamsfKk5wQzNsXJe5y7Kp3+QpxWUjEmQ5xFeVwKSxIyRK5eUKxxKFdLm2a/46cvPOSkh+Fae15+o4Q0mmqNp3RlAWArTrONNG0wzDnmEE1g95BvrClIHN0BV7//MWbBOiWWfVQNPuhT08=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T03-59-58Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698551874.397969,
                        "modified": 1698551874.397969,
                        "name": "/dev/shm/incoming/2023-10-29T03:47:53.ais",
                        "sha256": "4ba6eb47ad5cf80d90a26ca1ec7ed69701f1249bff49abb5145d2a750217fb95",
                        "size": 112402
                    }
                ],
                "mission": "OSP3",
                "sha256": "813e4dece1c565cf06721cce2de85c2cf7d540b68605402873df6a476ed7a752",
                "size": 31244
            },
            "errors": "",
            "filename": "2023-10-29T03-59-58Z",
            "size": 31244,
            "verified_at": 1698580371
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698759139,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "ap7C258oW+KAxz/TrmxYGz6HicCBO5zebbb3tc++vD7LRbs583+FSciOlqaW+bQ1O2g+8qAgt/7mHEX6Rkr2bB/7nbNlEZhnTHXrbLUlvhtKnYlVqwpYyG4s0JC7JzINN1JdSQRNUo1K6u9tD7UcjqisrmzvukWb1bnbUDs+SBsZ6WbkHBRRI3ut6p2A8cXezrbrefuN79M9yoQEt18FfbDA7EwmxWGIAbKmmsriytcdn6BpSlTP8g0xKcI/YBflR7j170sR7N+lbqQpV9T4wi9Ma6SKOlPVvjzxWQsNd40QHd/nHP4dl0aeU2I8X7rZs9poxfTp6v8gCjLPmSnlcVKBR2lEjNXrVH+no//xLpa5gsZW/T8VwzR9QXVsnLyCIJZLAYXiEkZDIIWU8Pu6oir9UEKJnQ6TUdFrgykXvc8GwAcc0rO//U+nC3KuMyHsQw569NIBgmf/0IPpUVnjoUJAd4egJeuPK0se9oqBd5167DhRVZqGjFeoCBoFvnH6jdzu+iHn2rlUhCgk3yup2CZrDQ55mO+Y4sW2rBOxeHEv/6iO86ed/5dVZtki9sYzDAzFGZe3pl1Itdjw3/M9va6EdUd9WY732y0ahnzZ3c0BdyeojcyCtq6V7DcCr8mqhQ92eTIhTOfow4G3KE53kmDbV3PHIafuLZFK3SUw5Nc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T13-31-27Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698759077.504362,
                        "modified": 1698759077.504362,
                        "name": "/dev/shm/incoming/2023-10-31T13:01:16.ts",
                        "sha256": "95025f665c4d24da4fad58dc7471aad5ec5f3ef5fc9d96e8af2557b18c69c76b",
                        "size": 410660432
                    }
                ],
                "mission": "EFCA1",
                "sha256": "1980f52e97c606108a54f6c49774109dd029d6c8f704817119259f342b3a9ca7",
                "size": 395439726
            },
            "errors": "",
            "filename": "2023-10-31T13-31-27Z",
            "size": 395439726,
            "verified_at": 1698781744
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698553470,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "bE90donoNltfDwnXAb0AJd5aXsVsC2G2dIf5XlfLTzCalNfTuX9XAJQ76OGkpyR8hW5Tb385wstQWK6LO6BOBU3PqX3aZjmkxFd5+a20sFwbC1FQXpXPQ/AKZh1aoNT5znO9A1WDyCOyDuQomgr5D70q49lYFI48RUd3xHEwF1KMfxKVcRyddNC6vHInglaGB7s7mkNw3uJTPeXZT3u4iy4VE5lcel91FAXZGpipGWiSo+RhCbXnDWBfZq3AIf9BWk6RLY6qm/psr7egZ3tQ7N4bhWwp0AB2GdTdh/tRSnu+JroF+3vvxD6cxUn0EOfYWWuLB+B4u4zHxWcVfNNWu2R2XtlWCGp90dmwJqpSLFL84j0JgHFiljAqaHsW9PxX8iJArZgiD79DBGKeov68NDKOpZBeNtOn+raLQXcg5smFbsgWO+b8aMawnGY6FUv0Ye4amxAnbjy+zw+TMyO/Bso72C5lpsz58scpFm0FhdRXUNTP4EViK8eIm98S1Fppfq+9B4qMyb267nh3AjsvJLloBskw7ieMDPZWuXWaVbJ4pnHBR//c3+p/Jw5v7yGEOLy4GI9WGxQKKOVNxT4J5FR/1GfpUhM2mz8jFmctzeETKLHbBxpSHyHdNvOs6IJsSq2OM8ahgnYgYomN+fen6AlDD9f0eN9plReScG10Ok4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T04-24-24Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698553384.977969,
                        "modified": 1698553384.977969,
                        "name": "/dev/shm/incoming/2023_10_29_042153_00_03_V_001.txt",
                        "sha256": "c9dd2f6588d05bc030476e2ca8b5ebb6303ce62c81be31dfbe32e15a1ff25940",
                        "size": 2039
                    },
                    {
                        "created": 1698553342.8379688,
                        "modified": 1698553342.8379688,
                        "name": "/dev/shm/incoming/2023_10_29_042153_00_03_V_001.kml",
                        "sha256": "7d2f91714e358ea6e3b3043c0ba7f913616b8fb7f0e134083c9271826fcacc89",
                        "size": 978
                    },
                    {
                        "created": 1698553342.657969,
                        "modified": 1698553342.657969,
                        "name": "/dev/shm/incoming/2023_10_29_042153_00_03_V_001.jpg",
                        "sha256": "2d93fb7f6b361a58f03014353008f4fced87cd070ac998431012f070964bb4d4",
                        "size": 866991
                    }
                ],
                "mission": "OSP3",
                "sha256": "7b20908014ff86cbd995a1619e804b10fb12485c9586e2c42417ee3f796732ef",
                "size": 864474
            },
            "errors": "",
            "filename": "2023-10-29T04-24-24Z",
            "size": 864474,
            "verified_at": 1698578196
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698553210,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "MUt4nkMWnnXIAN5FppYN1GG1L+QcG+eccMVQ/IdBiCVQIJ5nFRSDZ+zw4tUGEH9I1o4oonxL1J6AfeM9kTQCNkUEzxu9jl8kNyMcDg9vknzEuoMQsNEnxsgzbmY5cbpKvL7t/nJqp1QG4tj5FuaRZFuOnuFCBa5iOH4wpVczVz0evvf5C8t8FR7KV0esA7kMYZxfAwRE7+Faoa+ddRtCnN1ZT6MfN1JnMVM0G6VcFlAn/M2t4PZQ+AX9kMRmCzFtEP3rSMdcfI5ueQTczjAOZ5ilg+fmFsa678n1vSIahG907JY5t/EMnHaRZeba+LI59goSFXBIrIfnZfnekGQl0uCsszdBG1ADaSOLmRxKWk5X+utewBRzfdsdfr0Kuhdkc1H0KQ++8oQjg8ocE7tk9WE30P40aJsLGetakEsSrLcdmRJ4PQ10J91F+NIrFfL7jO/U1YxdrYKRYBkpzA+q7J10aRWFgi/CVyP3rG8UZLgoYX8v3SybgRWXCFXjaDrwVyHOvcJmwMHpfGZ9tzfQklRHKWq3yJCF1r2HL9OMUTb4li4T6xLZPCau7zImovVL3l5iNB/J1pFCtt3C3hiyb9GFbhJ13RGPsERH/0Lfwy8T0vvTIssq3PMKWQ8pxoaFahAN5buGzoPoHM/nsolr9rStqBdSGODzLOMqZvbhNqQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T04-20-02Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698553076.027969,
                        "modified": 1698553076.027969,
                        "name": "/dev/shm/incoming/2023-10-29T04:07:55.ais",
                        "sha256": "4a7ef6354fef132b104b964afbc269e3403385054a83567dbc6af1412dfc6c9a",
                        "size": 69833
                    }
                ],
                "mission": "OSP3",
                "sha256": "ff40a04e72a80981fb72d26b34167dd7828ae2e0a3656f149aca3dbb8269e948",
                "size": 17110
            },
            "errors": "",
            "filename": "2023-10-29T04-20-02Z",
            "size": 17110,
            "verified_at": 1698578217
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698552611,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "su9NjD/FUznQIGG12yD5G3aIjrG+ox7ONfGqjg02owcde/MVxd7qdLVPO+55a8Dvjix3xXfFbV4gqoiVxdRgXT2TNaDQ+yRygwvPjmElxvDUr1XeaZTH89J58014+39SZc1JpZVsDOQ3mVJsgOyJf8QF8wUN4wmD4k/hDa1kDvskKiiaGSmDHhfRGlkG+M+MbMviR5ifVnIspmyajcadojyNedN5UMyIyziGoPBYOlQrIAro5SpoCYrumsIshNEIhoC8oxoRlNz+yQ/R1Ne6TyMVr/g8BW+0SJrcNtXL8PyMn3VZkbXH5GIFwbP+tcDZiz7HMcB5MQrEHjslErN5zh3ijf8PMh+DL5MOfuNxIkPWki1hXf1CK0tVuwULw5bolMvrDhaCnDYN/e10Fty3YfQvv53iY7erRr4mTXvO2WpRV5TmIibN358B1MyF7J6mTqiWrpMCNcH1X1upTxZI/sy9oaRSMm2zc44Dleis2PS4MkUJk8GvytEP09yC0hBhcUpSUb5tHZ7l4hP2vFa00/E2TPGEqzyGpZCRHKWonVS/ZR0Uc/DHRJ7yKwx8o+z2/bw4ivNKCuKuV2BNQU9sCkUFBnNaZqEMacySOmfLBjFD2P8fKmzahjxZAm5+WxlInXVRW3p9Hcx+HMyMXjENGxrIqJx4JG+ym7PTfUo4/YA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T04-10-03Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698552475.257969,
                        "modified": 1698552475.257969,
                        "name": "/dev/shm/incoming/2023-10-29T03:57:54.ais",
                        "sha256": "42fd13ae8c4d20e381d435b9cd73dee770427e1bbaa0ef149bd5bc69249c24a1",
                        "size": 92360
                    }
                ],
                "mission": "OSP3",
                "sha256": "78fb624780f367a7392637a21fa3232af185ea8874145e4549af57471434dfa3",
                "size": 21809
            },
            "errors": "",
            "filename": "2023-10-29T04-10-03Z",
            "size": 21809,
            "verified_at": 1698578246
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698552227,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "UusNT8vuDPRQ04Har91cbC4ZtgOsWXRzQyt48A5R64O7PFkidUb14pVqvTalPFjt0FQQR2mQjP+XfKuyjv0nZuRgLSU33RNH49mgTrEhGE1ULwiUOLaCMuHUlP4EJ1Bgw6VM9lGuWGl0UXZkfQLzpjVPBNZYw3lJBGzZNMm8Lc89XDrdYo032Zfc5UOQpd8eYZC+a4bmT+vxToUaiMO6bTblIb1IJCf6c7k7U0OUV7jCn4j2MaEt5z3C0DmuOBvyW/WHR8rZZcVv2SB3EG6/kCPwLPf52to+qbYLQ+iJoiKKO0KGj0+znZvO4iN5pgFAnbx0zk3cKUxyNVT0D9AtDda61ClJ5vRfc5xfbMH2xkXjTvUjuLy+RX8Sb09D+ttkJrPmexjBK3dgp6gevB02zOZKIm614hEbATe9aalD4hdmSV69ExVSchmPNpU/sClJQeJt6ZNY6cSTt2ng3P57HXbLtMWhgHc/RFyekIlZu9BlhJ1yPkbWjCaXdbKs6aE4gv3OR73pH71yd+oYH8R8AfRqxQ5zH07SGtxu9/syQOsBVgXPCSuiCglylbQx0Ee2znu6w+00BHNr1GBV4GWKQh0mfBWSBiNZreu6i9QxGmMGc1tDLgb38MKqMnfIeObX1wxEDUz2yvcAwVQg22eo2fZT48ypocOzSDYHb0hcj4E=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T04-01-26Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698552078.207969,
                        "modified": 1698552078.207969,
                        "name": "/dev/shm/incoming/2023-10-29T03:31:17.ts",
                        "sha256": "db048394dea471550b1a322f8d661679861590e204a3e60a45463b17044ea91f",
                        "size": 316605160
                    }
                ],
                "mission": "OSP3",
                "sha256": "2bc9e7f2488ea0edaf66a586ed3661342c34f78f4289437692012e11e6a97eb5",
                "size": 308124065
            },
            "errors": "",
            "filename": "2023-10-29T04-01-26Z",
            "size": 308124065,
            "verified_at": 1698580225
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698553811,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "FRM3vT6hMdm9G340GSsoiBgTbtllHHbHOetJy9t6tTxz4mCYXYOGWxgMR+e47o6FuBqWJdH9YE6SEyKVLujilhb7izDpsy9R1QRf4OPBLPeuoezgieME5C08FxToQKWjx8SWuiIKUqRgiyzBIHAuFgqJHuSUpFCjwzG33ViHPipE4iCE7JdiVigwh4MrXImsDuYWy19kvqalbGOLtu+S8NcYdU84avMbcXVo4y0kZPv74cprwVdoEHA/4E+5xYcgdhRvbGFFffaOwln217bJrwui0j5dBZJGkd47l083hFtq5vk8uALN/U00ZHwbYjqOUY8GM3u9syGIKXqkq7OW34tOfDT1kPGrPV5orTESWa5PReDzRdTBysotINuZXZHib2lnrfe26EJ4NqRzj5K6Ts3PvFSjpFn+OK2VDimvbKrjiIfU0FEWCiRKqPEW81z2IK2rO82Q1JiKZIbYJf/hP0Ww76qgRezCOZA58csfDUHjxL6XxNrI1Y1bEckNUxzSogS+1WpEpd0rc2oXe7DRX0XhoNALZxDGegEDF2PxqJ9kCW4HtYkD8gvd34wvMtV1UgLu3BNYN0cm5srk25lp00YgeWqsqaYC1mJWBodUadOEdnuAr1KnqSJ6MyOuHDYTFN4LQ5yHEL9HwQ5nhZ8P2JBSoufAyWX6Rw2qEwmel5o=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T04-30-02Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698553677.947969,
                        "modified": 1698553677.947969,
                        "name": "/dev/shm/incoming/2023-10-29T04:17:56.ais",
                        "sha256": "865ab863c3d1a95a1ca6ec3d9dbeb4e73962dea106b0f5be51a6d89e5274b5f6",
                        "size": 98979
                    }
                ],
                "mission": "OSP3",
                "sha256": "e58f89e509ff05255879d18d55b22394ceb0475d4da9012fd94df3b5bfae5bf0",
                "size": 26228
            },
            "errors": "",
            "filename": "2023-10-29T04-30-02Z",
            "size": 26228,
            "verified_at": 1698578153
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698554284,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "oG2RtHnRiFGQt+pUB7EfzhfUL09kpaJ/gZyGv0jJQpSTxnq08qo3kFH6Yc7pmDbQm9oH4njEALOkCzbXXZnqCnDZqf7yIGCfGVsmMU4XzpRKK2K9u2LUY6J321HgG+f/l9UfK5WX5q/JOYom86xt0O3ZxQ6QojxN05+OZRYQBQiH3p5fs2mHVD1/nKlQv36o2nJh+bG5xSOZBZm5WO0ECB0H78EXm1E+TMNPS18OOstldjWRiYC2Zb7ttKPPvgDRprdcnMzLfjfz57elJVDoantRUZZQJMDpxfkBVdA3RrxQgHMZFEC3v/CgQ+h1qGcYSz5AsjVfBejvIFoYYVqUlZgPyh4w0ULA4Tq3Gzj4HW3wg0YZUf2ZVoZLjBQo0qtP5B3sC05hlJEdN5LMQHsHgglwjzAOmb09fxDV6LY+F/iOuQwmX4Vpy2Mp4Rzc1F3MLCRl9+Y9/C2blzG1GW6TUVmbxkuio7VmcSbhrsiaob9gGCYtYJm2g37Okd6I6iFyj3/+Uy+etPCCbOIucv9Z53hrPmQcr854o0Zybl0rBOAXAqUFp3iYxeC81isaEn8ADri06awaI/vhhmYUSxlPKbGuCxA40phRTY6E7IKH1Ct7/bE31atf5DWkfRaLVboc8r8H7YbK2NEN6CmsAupKtY4B97IlXmackh0/n4SOWqg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T04-37-36Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698554126.407969,
                        "modified": 1698554126.407969,
                        "name": "/dev/shm/incoming/2023-10-29T04:31:29.ts",
                        "sha256": "71c1596b3e958ca9aa058682637da62a4e7862de17f8d3160f8c0ea28187004a",
                        "size": 40463992
                    }
                ],
                "mission": "OSP3",
                "sha256": "4fe79d6eb73a775f2a11155a9c912319166079425eb5b7dfe9a8e73513675869",
                "size": 38780062
            },
            "errors": "",
            "filename": "2023-10-29T04-37-36Z",
            "size": 38780062,
            "verified_at": 1698578243
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698554036,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "lKFGODDw+sjakxOsx/oWmTgEuHOOyDvXA8S/+4Tv+8xdUfIWKhGtB2Hp2Uj4UoRl7IhhZ/0a5zMeC1AQkj+ArBjaRl3J4iyTH1vDsqeZoTWr/LhmSR8AkxhgW/YjcXtxE6AKmgGHLKMARdEVpp7WIPb8f2O7uOZUZDG+nY9RbaMA7QXv68nhPJpx2TqE5/O4Lf+NeWhvkyznSZYNYjqVuEpaFypkgOjlGBF6XAtWNrDb6MDcP3VKZ32+F9i37F4gWvtEQCKYrqqRU3n/uvOuzWJ6aSVDTPuXrZLYlndpJU+8CpZCWthLbIDTbq1Z5rozGj994/l3Ap5naQbLd398PVVfNWX7wv7oxmbPqBVv80mpztbsYn4M4qdT4VlyGbQ1nSATVRfDWDdk5LyhUibgEe6QuDyOBObUSdnIrh9slzIraddaym++ypTukmauZKw6UxgiWoC8E6gkQKFFLAZy26eWgAlUmtpj5NYT94TlOpyheUKNVKXXlYM8T0KhYHj7B26QfrBZiq5RdBjZtx7J/rUEEd61ixLAvGeqY5cNWSRhwHCqLdCpZwSe8gjjv0NHhg9dxhl66767Pjliwd+vZKH/j3Gz1Vr3ZEn9Bsb8Av55D9uQZ/nswKTSljkv77ozXOoG2zJ+0eCmL1hU3LOFLxXJQs9uY0TTGj2mWS6nClE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T04-31-30Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698553884.407969,
                        "modified": 1698553884.407969,
                        "name": "/dev/shm/incoming/2023-10-29T04:01:23.ts",
                        "sha256": "f1639c98eebebabafce2140807bbd16925850763d9a18f305fb86c773517c19d",
                        "size": 329794864
                    }
                ],
                "mission": "OSP3",
                "sha256": "2349217da31c16658d9677160c31aaa5b56f15d335e39ea26c6011a0acc9af8b",
                "size": 319457872
            },
            "errors": "",
            "filename": "2023-10-29T04-31-30Z",
            "size": 319457872,
            "verified_at": 1698579526
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698555313,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "QYsLvkgxZ8Bnwpn4y8Zw7Efaj/Pv9Rn/tJOSXciE9I/8Kkza6CA1uRoikcwtHHrUert/Bd4Ru1kcXc/8tLTavaEpl9vurmrpCaKEI1zOb5mEm8sqEmdExFe/FEf8KbROWEmQFtMdZDEHmWHFSKuTOcleIOXf9AeWLXF2IRRyS8ICpSCGWezYIlIPa6ez4ZENgOWqg+oBoW5LzflC2QME//xWhJ3wsPdvha7kfv0jMyGbik6JxqrH/2nK4pYOoPlk6HrHtYxoX02atbiEIBYJRamnJ0VALff5Tn+0+Nb9SWuBGrJfoinAm0TZMo6PHRtoQ0IDUy3eYVET4ZxepBOf6VgQeOxde4XGIjt7O407hITnYQwyifqBprZpXDdb8EDcn2++/Cf9HcHgDBNQjdHRf9L3/AJHVRaT382RdBsM7BSJVyiwVl6XD/lQwNuQplBOuYjntq6LknsZNqhbt8dObrSM5QgGZ+ofm558knSZSVdxfEAVA5fn3/A4fi2Ixpdaxe2Nz6FMqa81MkUwCT6cFZS3gFopPLLpy8HNfQokFl21uPh87SUGkLE8vEWLR497d3JwuPjpqOcmX/1uH0/OOsRbzwOWuD59/Wuz3Iay7VWeodi4ylNGmJcXwX3e9t/fCV3bmS6AF4UgwutY8F78WPWAh0Bas5UcAucOqzrbqDo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T04-54-46Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698555165.667969,
                        "modified": 1698555165.667969,
                        "name": "/dev/shm/incoming/2023_10_29_045223_00_03_V_007.txt",
                        "sha256": "3a44dc4910d2af37d8a5034e47fbb56bcb257626b0ec1f204412041bfcf10552",
                        "size": 2038
                    },
                    {
                        "created": 1698555164.0779688,
                        "modified": 1698555164.0779688,
                        "name": "/dev/shm/incoming/2023_10_29_045223_00_03_V_007.kml",
                        "sha256": "d1aebe55b07af50568252246c251affecf2cefc939c40e2c83cb0bbb661f7d54",
                        "size": 977
                    },
                    {
                        "created": 1698555166.1079688,
                        "modified": 1698555166.1079688,
                        "name": "/dev/shm/incoming/2023_10_29_045223_00_03_V_007.jpg",
                        "sha256": "36ab30fd396bc15d0e66d27502dd025d26ad6644363ddb5f20d08296b9f35689",
                        "size": 819973
                    },
                    {
                        "created": 1698555119.727969,
                        "modified": 1698555119.727969,
                        "name": "/dev/shm/incoming/2023_10_29_045131_00_03_V_006.txt",
                        "sha256": "bce855da1a6497785122179599e458380df9f6f9a639ad3b0825e97009587ebb",
                        "size": 2031
                    },
                    {
                        "created": 1698555126.467969,
                        "modified": 1698555126.467969,
                        "name": "/dev/shm/incoming/2023_10_29_045131_00_03_V_006.kml",
                        "sha256": "0438eace2f3c2218d3ad794ade463c50e3c2ec8f7dbe42b517ca7c33cbffa33f",
                        "size": 974
                    },
                    {
                        "created": 1698555116.0479689,
                        "modified": 1698555116.0479689,
                        "name": "/dev/shm/incoming/2023_10_29_045131_00_03_V_006.jpg",
                        "sha256": "195656ee1c819d7e42da21e32fb9a08f51d4f471d90df51b561c476a07dc0f40",
                        "size": 949747
                    },
                    {
                        "created": 1698555129.167969,
                        "modified": 1698555129.167969,
                        "name": "/dev/shm/incoming/2023_10_29_044953_00_03_V_005.txt",
                        "sha256": "1dd9f866587cf23e41f45a6114e0ffedcaca34976a0381de50b51e27dc0517e7",
                        "size": 2035
                    },
                    {
                        "created": 1698555018.397969,
                        "modified": 1698555018.397969,
                        "name": "/dev/shm/incoming/2023_10_29_044953_00_03_V_005.kml",
                        "sha256": "970302f9f28bf4afca123825be0251f35230f736da0c8dad87950f29c3f8010d",
                        "size": 976
                    },
                    {
                        "created": 1698555012.897969,
                        "modified": 1698555012.897969,
                        "name": "/dev/shm/incoming/2023_10_29_044953_00_03_V_005.jpg",
                        "sha256": "78bc48c88b0454f01a67743f143ab6bfd46f88dac4be7688ce65790304d028be",
                        "size": 933545
                    },
                    {
                        "created": 1698555028.397969,
                        "modified": 1698555028.397969,
                        "name": "/dev/shm/incoming/2023-10-29T04:46:09.ts",
                        "sha256": "565185bfacd3ada19ca4dd7c2cd4c87a5084b2d6d225e91ad531cca49095fcc7",
                        "size": 39698456
                    }
                ],
                "mission": "OSP3",
                "sha256": "9535652c449cd700debaeb0ae4204441b4a20f0ab88aefe78e7447ba385f7332",
                "size": 40582118
            },
            "errors": "",
            "filename": "2023-10-29T04-54-46Z",
            "size": 40582118,
            "verified_at": 1698579788
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698555052,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Zhhcr4DCamJBqonBCsVMLJofkoU6lEicm+PyEjxljhthKWpDC48KkHD7IgYsIUk45XYwnCcxoYKEfGpNkEzg/iTb+CGYtB9ULe1W6e1Qnkp7D2F8zDUWTK7ZaZt9ZiXmmTYeo1n9lGfW7qck2auSYkQF//JevytayPRBR/W/80EF/b1+7RQ+xBduOZi0q9YDAziPpMZ/DvntwCA2s1HiG4CFaYByPGip/JC/qhhbQE+1LaBCcjEszOYgtaTKZhT8ldsQq6lGlYp0jkG1A/zaSpb5Aj4rlel/dTl13P8NrYkMOMv5xa4X8yCEYOiVUn+wAnkHVUlJsi4QibhJ7x4y4JmsdIz5drDnpGfKgN8KAw5LXrQdsFq8xzw2R8MYQpMciW4vzplE2gTSPsrpDi7Zu1hbVb1Y6oc+bXksHu0XMvIU1qiTzKnU7ydMj0BmSvjmWUiZW00TApkfwZN1ifKWzICJljXDSRTQXVUALnxh5tlnbj5PIdbAH+Q1JuWIndqJ48XlTzqOGB/WU1aA6Qk6rv2Th31Nk9obqNhsKcMmeYdy1LgN/uYelRFtZlGtqQkZ5AWroc9WS0UVlUwVrTQWT4hTKAm9eNZaBnYSQKFg9sZ5hkNyDQ9tVDdtiSWmZ3Y8Ugajss9z4chIHmYjKgdrBkELe/zzkPVUVbtTL8LReNI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T04-50-08Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698554879.777969,
                        "modified": 1698554879.777969,
                        "name": "/dev/shm/incoming/2023-10-29T04:37:58.ais",
                        "sha256": "1130b9f8625b657f78e183404733163a3051a8bf094d2012e0c3729d0d863c3e",
                        "size": 59404
                    },
                    {
                        "created": 1698554721.0479689,
                        "modified": 1698554721.0479689,
                        "name": "/dev/shm/incoming/2023_10_29_044459_00_03_V_004.txt",
                        "sha256": "ae7c827c3f13d1b2d7477fbf60d1c4f25d2c5f14bfabfec3f763bb8768432e64",
                        "size": 2032
                    },
                    {
                        "created": 1698554715.417969,
                        "modified": 1698554715.417969,
                        "name": "/dev/shm/incoming/2023_10_29_044459_00_03_V_004.kml",
                        "sha256": "ceb583da011f6d0924873e186b67a9a20a36a01dd6d40adef54962904d3dc17a",
                        "size": 977
                    },
                    {
                        "created": 1698554714.937969,
                        "modified": 1698554714.937969,
                        "name": "/dev/shm/incoming/2023_10_29_044459_00_03_V_004.jpg",
                        "sha256": "b8ba8510b20799033ec9fdb4608457c030731a5a7265756376f4d5d71c1babde",
                        "size": 729039
                    },
                    {
                        "created": 1698554714.277969,
                        "modified": 1698554714.277969,
                        "name": "/dev/shm/incoming/2023_10_29_042153_00_03_V_001.txt",
                        "sha256": "c9dd2f6588d05bc030476e2ca8b5ebb6303ce62c81be31dfbe32e15a1ff25940",
                        "size": 2039
                    },
                    {
                        "created": 1698554763.257969,
                        "modified": 1698554763.257969,
                        "name": "/dev/shm/incoming/2023-10-29T04:43:31.ts",
                        "sha256": "9f7aac3904e3d90b6fcb4a5c0b8a2386cd14bd6b668d122840353edf4ffdaa7c",
                        "size": 27350052
                    },
                    {
                        "created": 1698554605.8779688,
                        "modified": 1698554605.8779688,
                        "name": "/dev/shm/incoming/2023-10-29T04:43:14.ts",
                        "sha256": "f6019ce21010ca7d4747b90fb34f06a73f39a771b3e49b4b9a32463e0cc12ff0",
                        "size": 1938844
                    },
                    {
                        "created": 1698554588.907969,
                        "modified": 1698554588.907969,
                        "name": "/dev/shm/incoming/2023-10-29T04:42:42.ts",
                        "sha256": "903b62ee380eea6c13f7b3e6077e90a4944801a7be13cdfa511312ce84a7f0e2",
                        "size": 4148972
                    },
                    {
                        "created": 1698554480.887969,
                        "modified": 1698554480.887969,
                        "name": "/dev/shm/incoming/2023_10_29_044034_00_03_V_003.txt",
                        "sha256": "03715b7d33e1bc2c647bc65dd97b97083314075f8b95a7e4ba9b653fe5ad133b",
                        "size": 2028
                    },
                    {
                        "created": 1698554467.3279688,
                        "modified": 1698554467.3279688,
                        "name": "/dev/shm/incoming/2023_10_29_044034_00_03_V_003.kml",
                        "sha256": "4dad0f2da90e2ce2c9a2607725c7c25028e7998bff7a0599f65c4d659f02a983",
                        "size": 976
                    },
                    {
                        "created": 1698554457.887969,
                        "modified": 1698554457.887969,
                        "name": "/dev/shm/incoming/2023_10_29_044034_00_03_V_003.jpg",
                        "sha256": "ab8895d24501e050ab1080ec450367369491087c4f443b9803319b543804eede",
                        "size": 741449
                    },
                    {
                        "created": 1698554456.267969,
                        "modified": 1698554456.267969,
                        "name": "/dev/shm/incoming/2023_10_29_042528_00_03_V_002.txt",
                        "sha256": "e9fbdbec879b52cdcef29fcb43f0ab14c2746900318641387fcfd5344f7dc6c3",
                        "size": 2031
                    },
                    {
                        "created": 1698554555.997969,
                        "modified": 1698554555.997969,
                        "name": "/dev/shm/incoming/2023-10-29T04:38:40.ts",
                        "sha256": "33bb427cbf7cab6a239878233cab29d7d4af0d7d7dbcfd619a8186f5eb33ae9b",
                        "size": 43734252
                    }
                ],
                "mission": "OSP3",
                "sha256": "b38b1a0627236ce8afe332c6e7eeeb15d15bb04279010b523afc01a052bebe61",
                "size": 75537394
            },
            "errors": "",
            "filename": "2023-10-29T04-50-08Z",
            "size": 75537394,
            "verified_at": 1698579808
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698674362,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "B45Cz7OhwH+x2yhwqrClCwPYa7CFbOdxIdHawMwk5/vM5Qs+7aHJh68WDg3yWxnaytUPO68NNVahdZu80gIG3txUshC2zzjnW/5ABXW93YAZCfGmw2QBHHz4+lr8IZulvPQOnxxtBHzJJlGDltyenK496OAg9bINV1X3iQ83DfQzE5N488oipShwt6ZIX7p6ziAQ8CEci4SwiIeIXPGBiJ59q7G1iRJbFObEFD/IRpHhkWLRh9YMlKIGQFAIUUu+DzKRppUL+HApq36ALtRXlj0vLwIQBY0PCs7yyXZUsPwa6eQCeC8OvuqDgRITAZS65RB9lyQ+4NA3ICkpQHIje2fTUH0Mk5EAebYjS0qxdrMxAqx+o3wRY42jMK4gaboqOCcoXp7xqNVE0h6dOSj2YzQtZ5cwazXQbAD4rELblG9C39TTumyFtoEGlnWir9kv2k3m0MtRmHu6bZJZsQTTX9XfwsXG011qBykOBQPkIau0GMR7SvG+LSj3kp2vuqRhxmbazpCAgp75dF1vSU9zez2gzMsdAxbjIz3/yPR+6wZEBoKNuaIKZ74oXwA8PuAxOyDAKKgq029CSbwNw8f/CFOcN+78+fAbgO4Oj2h42/5UYRdYhqOhK4xaEyBhvVlnzUmukkiMp+mWDucqqh5zY76OvzuGX0r6dQ7F5cgvCPw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T13-59-09Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698674224.8086982,
                        "modified": 1698674224.8086982,
                        "name": "/dev/shm/incoming/2023-10-30T13:47:03.ais",
                        "sha256": "e44ba8128014b323a51710e05e67287168e8cd174a7fb036cdb46627399b943e",
                        "size": 1198
                    }
                ],
                "mission": "OSP2",
                "sha256": "db341d27e0f8016f1c5fd1b091ecc2c704cfe89cd4e4d07b242fed4e87c9425b",
                "size": 598
            },
            "errors": "",
            "filename": "2023-10-30T13-59-09Z",
            "size": 598,
            "verified_at": 1698754534
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698555419,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Uz4NftamBIDskHf0kIUfb4t3py71vdWWRvEzcjIz+u3mHEGlujGr4ov19QT3+ZSR5AIDVTCGjv6fR1TpSFAetS9I+4voqVe6ChdNw5yylbElrPeoKSCsuNFDRL4vuOCyleVNZ0lAonALYxFe2On4bZrzTfywy3GPSUKPjfaTW1UhrIkFWhyYucB2CVMqAzm4grJkIWmP4YrxBtg6Gneb6qMyYaPmj0xUjk+J928G1TBa9P5/+uYECJEy2vzpsGGRC8ZZi900S2JGh/TzMJT5FvyrmjfFD0pLIJSqUz0KJP446hGTaawdrHJdscIHcHc9YfsO2lCPO2DLLJfytJLvy2k3AegaLGNOZyrWB7+GclBvZUlk15+DgfQvvEiejsWODHZRgR9IM09WJMxxaYDZlU3bOgFeb25sk2x3a2WErkjnl+7CG42b34y9ulHEfGIS9QX3SOJYfHtX0QZPle0JQvVUWwYmXZwOIIW/WXT1Rj7LRhMq3sByP+1PKX+X/v3Tc82gIymasxXWa9Ob4ChXmwk16ZTQZbBLgB033+3kYcM+vtKnAUCovYxs7VKN4SS3fgQIdVK7nMFeN6BHzcZHO8iH8GiWbpiuPrX9+B/J73q2xaxYBbWX7w/CbuwTH63ekBF33x+8mlLgOGUM9wNBPaWBLFEf0a3kbqK2dVlxQ94=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T04-53-23Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698555076.8209403,
                        "modified": 1698555076.8209403,
                        "name": "/dev/shm/incoming/2023-10-29T04:41:08.ais",
                        "sha256": "eb0c036651a1e3f1f62ca4e02238b52e73f5910cf258e00c555dc5eeecfb8dd8",
                        "size": 6700
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "f2e49d631902401cb2d79a3267b16a23ae4c3be92cd84a1ef4e85cab9792a5ce",
                "size": 1530
            },
            "errors": "",
            "filename": "2023-10-29T04-53-23Z",
            "size": 1530,
            "verified_at": 1698576812
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698556276,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Z0hsRhQRQ6MukBtXcSlx2JKrgGlafjb5XqhhmAuo/9T4POM7ZvCNGjr+WXGZllv3Ni46sKgtcTGNLqjl4kM2pQOGybWN4CaobaT21cNnzSA6ZMSWid22Y+GOYu+O7WNol9pcGeZRVo4/OewW3nDnWvSysiDpqY2BKjDyrTNsgGTRF/jDaYNcvgzRZn6tMz9BpI/7kVtnKI1bCP2/xoPyj/KMTBgrYI3lR9MoJf8qs9QB1+Ph2nTIFD8Do9xphm9gv4fnWpH899S2kVspEOW9p+yW7O+NVKF35DOHncB+Tg3MTge4WFAjIh6mg6Ft+nPIsgZmCQgikVJw00ZyuXMLsH9m/TjYmDJhVYpiwUm51tS3cVI9RxcfCMbNokdNJCQW6XqxURJJhsC8bZDTxyBtfYH9TPVK3znBb72vRhWD5H/gJCJXUwS5oI5+Urj3lnN7HZqHpeEixKs5cb/Lfb5bqJ3GneUOX0xFG/+WIgp4WTI6WeqVSmQHYy3w8Ku2s1OQDRiqnoyk+gd9fuEF32rjix1D9byNwfefoixRlEFgWD5R6tbWj/SLEDuA87gY5CBn/p6Qp9E7sHfGw0jptxAbE6w9L9jD4lW5joEf6kM3IgNzunklpeV0RPnHBY8+9vMPFlG/KddJyDiBNifOTQvDI3YUmb5soPAoJHlPXnmMz3Q=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-09-31Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698556161.6509402,
                        "modified": 1698556161.6509402,
                        "name": "/dev/shm/incoming/2023-10-29T04:46:58.ts",
                        "sha256": "9da9180447232089266eefb3f30e967191917b5833d2e3a1c71bfe2efa5fb0ce",
                        "size": 850240340
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "539400eb673973f4bc1bd38c5c82ef8fcadf1f35ee2a380a67839c507de9dcad",
                "size": 838023826
            },
            "errors": "",
            "filename": "2023-10-29T05-09-31Z",
            "size": 838023826,
            "verified_at": 1698577322
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698556216,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "EInKNDfgJTaBOzTCaGfmLW9if10sTA6IV7S2eHc7q36D6mnNqYPPqLBV+ruQJyXVmC7gbj2Guu54xmIk4jkgvJfg2NkAKeHJDkXeHnpPsj4Bw46YDOoT7uZTb8cIWkJ5CXjuQC4t00KWpiUT/OHmKXd0OVswM9HgsAfpm7IeCcoKjOlVnTaPYznTn2u//9K54ISdlPh0/OCrB/NZC5csuePbxle08oSNvEukAiE17KH5jOA6mNcv3RnzlRIxlAfLtyIJvanLxz4L+zJH3bl3wFvieM23I9cSwB72N1gQXJwtOZBSK7f8SdSqp+s40UaHtEyxUl2O3+OOnN0FhqV5FNwBYfVNS86UaZ7Wt51Xc2M0N2JaqbAksR7nk7gU+7Lnb0D9WqW4Xkw8d6CXwrmXV+B+/dzeAsGFjF08bcScMECcgPk7DhMOPtz1Z5UOXFoOtFPWyeAxjBz1qo68/mHvQdAmvE0LjbeJ7gjCLaPl14oLUSvANfQHnFl9eifNl7qFC2qlUC7OXilMG35rM8+sxogX6ialIK7g9h32iD0+CA5x6Hyh92JDB3+FcCtxw5+puEjQOh9Vam7CB5heCPZv6GSEDTF0CG83j40q0C6DF89gxZ02hN6/8vFrtDI+hoz/jnRpkaHPIC5GluMfasrkrfiumQEwp/w9XUUDQmj8J9M=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-10-07Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698556081.527969,
                        "modified": 1698556081.527969,
                        "name": "/dev/shm/incoming/2023-10-29T04:58:00.ais",
                        "sha256": "d2a73ff12da1e6be6da6e9d342d1b5471a91163a5c425bc4e45bc2d767e64b6b",
                        "size": 73395
                    },
                    {
                        "created": 1698556018.217969,
                        "modified": 1698556018.217969,
                        "name": "/dev/shm/incoming/2023_10_29_050625_00_03_V_008.txt",
                        "sha256": "799e3c368898d3e9e01523da4a8a92a76b88ea3156395b81ecd3920eba97729d",
                        "size": 2036
                    },
                    {
                        "created": 1698556013.427969,
                        "modified": 1698556013.427969,
                        "name": "/dev/shm/incoming/2023_10_29_050625_00_03_V_008.kml",
                        "sha256": "3a16eeca034735d278e9af18ecea59fd06a9d2168332ebd933eb0ddc88e38178",
                        "size": 977
                    },
                    {
                        "created": 1698556006.6179688,
                        "modified": 1698556006.6179688,
                        "name": "/dev/shm/incoming/2023_10_29_050625_00_03_V_008.jpg",
                        "sha256": "2f277855f62252fa1d5490d1f61801f18222d5836f7f3bc26b7f239f158c8d71",
                        "size": 1010473
                    }
                ],
                "mission": "OSP3",
                "sha256": "279345067ca8864319f338abcaa016c47de13462ccc37c696c2a26cf23c9903c",
                "size": 1026860
            },
            "errors": "",
            "filename": "2023-10-29T05-10-07Z",
            "size": 1026860,
            "verified_at": 1698579692
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698555617,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "n8IuiDTn9rrQpPeo5hvHt7SAJlXYH3y9z/YwFwdIJxyjyrrm/pmsxUuwVN00eOlPwqd++4h+bSNsOO7BdBxrgcRqXXkJXZ0tEyGSTT93Dk/y44XjvvBAnmoXnNF5qtozbR8lX0SQSexd0p+v32PzZGWSpRuZTTn28ELICvo7+sQSGtkG6PHD9aN8mnpGwUTWZIvfj/pXY0JdQ0h46MnBVqubHP4Uh1kB8YlvMCZKl2wUAkBVdPaGE3rUScwl2MY6tvhQgqY6qkNUp92LG8Gj2/7aCaT0z/cSYaylhj2SQBC60JitlkWXw1e4zEt9xW9MEskXsR70AHaNIe3qejQVDoNP52Gy7GLjzJOJArp6IRAbAiyeDxwssVeDs4ClQ0n5E7pDNcyW+8ibqfwXlXFzBwefVirL7m2Wgmksu7m6Yc59hl4t5psrjWcgvHBDScb6SxQZqWcqopiIwPt/HxnlYAv1TN7A8EpczkVFYyOvltYkwNyo8EtrSgcjcn1QGDKZDHoWN/B4gGs9MA0dIYvL/1yJkuP+Ifo5wKzlRrR7RnpjRr2Cb/Z/mErMcE99chVvbd7SgnMpek0VlNTm9TqujOb2JFhk7+EMlgzJIFVkJInduLNirPIAAc3CFaoVu0z+/YOrNfHEnXrnxxGjudzqJetWKD7ncK0advLIMDL6NmY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-00-08Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698555480.0879688,
                        "modified": 1698555480.0879688,
                        "name": "/dev/shm/incoming/2023-10-29T04:47:59.ais",
                        "sha256": "ef5d19fee87cee0e6e48507a3f103a9a05c72a988bcb59f97f16e5ff8090f806",
                        "size": 59502
                    }
                ],
                "mission": "OSP3",
                "sha256": "7d6ec62165fe020e5e027fd66cbedf3c370122a1948e0a6c3502fb1250d35491",
                "size": 12066
            },
            "errors": "",
            "filename": "2023-10-29T05-00-08Z",
            "size": 12066,
            "verified_at": 1698579992
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698756379,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "l1YjCxyitKCvzO6C9Aq4lLU9CcDi6OhBl+Z5YCpTAKulLk68wdanyHn4ZUR5IyGv7BQ5lYJNI44+YUu5eDUPsmQbRUTdRQKdKzxeRYngtivzXxV4BooSW0cOOnxHcWZd5Wt+DE7UVPRZQdDQygzKajbOF/6kfC0BUQfSEj7R9C399F4RmS9vrfQSb2YiXymvOHH9UjdWRVNSENQ9j6zWPotnRWoeYGzasYJUcwfdRC1CQ0zLdB8bXr3bpz8UiAfOGuaUiffq9+1LRjfAUQFKOzYz0NuH6tcAmyfOt2lgsL/4VQMu/qJZhUfXqDjbj/jTuHEwTWrTUYlc465vQZ4pAsvL/PuA5GKBpTOFjzWg3vG6CyfjoYyjqjdVlL6Eg2Afj0aZUGDA7mVJ2XQ5UhCyXCT/d/d2pI49EE76+io9HgXKCV470oITtqYyIiz8vOSYigsx+C5R8lf/bAUJqBHCwI9elHgoXSh1l/XBzNt9cJuNKCb3Sj1eK9lPDqX3fw+qCLiTpsWNvvokYioa98i2NdmxkWXyf2wKJFrECuwjcVAh2w2fQL0bGFxvwEIKwSBrgI0Suz8bORoBIjBOcEQvxDGNc2fMr8GkE9QnPAEDS/GGVa9S9Y5eoSTOrVAIu3oNsd0fUyI1DkZ2mv49VhXA+cbcjS3ra4AdfPVOfEdM1XQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-46-10Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698756247.6643622,
                        "modified": 1698756247.6643622,
                        "name": "/dev/shm/incoming/2023_10_31_124344_00_00_V_164.txt",
                        "sha256": "27d831320c7b628186f3066c4c2e856a809314cd2204b4eeecc6727a6207bf49",
                        "size": 2052
                    },
                    {
                        "created": 1698756246.364362,
                        "modified": 1698756246.364362,
                        "name": "/dev/shm/incoming/2023_10_31_124344_00_00_V_164.kml",
                        "sha256": "3281226a4d18adb8c3a9b9eb56037c18de5e944b05ccbf3b0060b9d735e25048",
                        "size": 979
                    },
                    {
                        "created": 1698756243.384362,
                        "modified": 1698756243.384362,
                        "name": "/dev/shm/incoming/2023_10_31_124344_00_00_V_164.jpg",
                        "sha256": "fd9abaea81e60ec205d095c0fe0d41c626c59ae0e0d7c7374513a8d5ab999834",
                        "size": 1622214
                    },
                    {
                        "created": 1698756242.304362,
                        "modified": 1698756242.304362,
                        "name": "/dev/shm/incoming/2023_10_31_124343_00_00_V_163.txt",
                        "sha256": "50e31ba2fbba9f82ae11211dc3ef9a919aab0d0f4c1e0be60d03e2a0aa025be4",
                        "size": 2053
                    },
                    {
                        "created": 1698756241.874362,
                        "modified": 1698756241.874362,
                        "name": "/dev/shm/incoming/2023_10_31_124343_00_00_V_163.kml",
                        "sha256": "594cdb35c27544eec561aba1c62874ff61f06f9c80fc8273f9295e591bcba530",
                        "size": 980
                    },
                    {
                        "created": 1698756241.244362,
                        "modified": 1698756241.244362,
                        "name": "/dev/shm/incoming/2023_10_31_124343_00_00_V_163.jpg",
                        "sha256": "ecfd41b07ebe1a3e49458549e5e1eeba3992010e09013c7c449dec3214e7759f",
                        "size": 1568900
                    },
                    {
                        "created": 1698756244.584362,
                        "modified": 1698756244.584362,
                        "name": "/dev/shm/incoming/2023_10_31_124337_00_00_V_162.txt",
                        "sha256": "80551739069fe2b43e85e24dfd0c64f9983ce02b5a83e3f71a06f0c0437df53d",
                        "size": 2054
                    },
                    {
                        "created": 1698756240.1743622,
                        "modified": 1698756240.1743622,
                        "name": "/dev/shm/incoming/2023_10_31_124337_00_00_V_162.kml",
                        "sha256": "ac5c2d076d717eb83ef45de4cbdcfc8e81dcd5eec59f56e470383bf322da14da",
                        "size": 982
                    },
                    {
                        "created": 1698756239.494362,
                        "modified": 1698756239.494362,
                        "name": "/dev/shm/incoming/2023_10_31_124337_00_00_V_162.jpg",
                        "sha256": "088c2c5d49074fbe87039d3f76890ff6ab9e11f4397948411ebaacf30625e9c4",
                        "size": 1390580
                    },
                    {
                        "created": 1698756234.524362,
                        "modified": 1698756234.524362,
                        "name": "/dev/shm/incoming/2023-10-31T12:33:51.ais",
                        "sha256": "02ddf8ac0918f0c6d170bda0d22252c4a12f4518911bc17e8a578c7ff63e115b",
                        "size": 47808
                    },
                    {
                        "created": 1698756218.634362,
                        "modified": 1698756218.634362,
                        "name": "/dev/shm/incoming/2023_10_31_124318_00_00_V_161.txt",
                        "sha256": "9aafb347cb32e3ce97e2dfe1f6a7a8764bb1a1cd272a10f00e29b7b3f1b1743a",
                        "size": 2048
                    },
                    {
                        "created": 1698756226.844362,
                        "modified": 1698756226.844362,
                        "name": "/dev/shm/incoming/2023_10_31_124318_00_00_V_161.kml",
                        "sha256": "3e68042b291b241d3ac96515c9b0935bfe190a991975235066c3d707cc32ba0d",
                        "size": 980
                    },
                    {
                        "created": 1698756217.024362,
                        "modified": 1698756217.024362,
                        "name": "/dev/shm/incoming/2023_10_31_124318_00_00_V_161.jpg",
                        "sha256": "8e7afa392c96fd0e701a9fc4c20fe84d5a6ea73e6e9695b02aaf268af0a69e5d",
                        "size": 1231387
                    },
                    {
                        "created": 1698756216.084362,
                        "modified": 1698756216.084362,
                        "name": "/dev/shm/incoming/2023_10_31_124315_00_00_V_160.txt",
                        "sha256": "57bdf151fe09d34a2250175307fe1bc6aaf8bfcf4e8f81ec1c9c4cd6006a38e3",
                        "size": 2049
                    },
                    {
                        "created": 1698756214.4743621,
                        "modified": 1698756214.4743621,
                        "name": "/dev/shm/incoming/2023_10_31_124315_00_00_V_160.kml",
                        "sha256": "9d948fc09d2446302b26f74e0b723c331b1a314d633a247e981e595c7c9413b1",
                        "size": 982
                    },
                    {
                        "created": 1698756213.9043622,
                        "modified": 1698756213.9043622,
                        "name": "/dev/shm/incoming/2023_10_31_124315_00_00_V_160.jpg",
                        "sha256": "68ef3a2034a9948d051a4f4d4a501413d603756e99e593523a7baa478eb8cabf",
                        "size": 1238703
                    },
                    {
                        "created": 1698756213.9343622,
                        "modified": 1698756213.9343622,
                        "name": "/dev/shm/incoming/2023_10_31_124303_00_00_V_159.txt",
                        "sha256": "b564a2db0c2c9a2b16c6ea7011d20d888be95ef2e799f86db631bc1ba8578167",
                        "size": 2048
                    },
                    {
                        "created": 1698756212.2143621,
                        "modified": 1698756212.2143621,
                        "name": "/dev/shm/incoming/2023_10_31_124303_00_00_V_159.kml",
                        "sha256": "1eeefa62c326f7b7a56c92b51c72210d6dfa94e41ec5eaf1cfdbab6ac0196da5",
                        "size": 982
                    },
                    {
                        "created": 1698756201.304362,
                        "modified": 1698756201.304362,
                        "name": "/dev/shm/incoming/2023_10_31_124303_00_00_V_159.jpg",
                        "sha256": "86c1d8d5c996e6a7085ef6fa1fbac1fc2a934a427f2a7388e5f409174d2f533e",
                        "size": 1111943
                    }
                ],
                "mission": "EFCA1",
                "sha256": "644180c43488a95c62f6f444d95cd0ae22069c571cc382938e37597e61aeb370",
                "size": 8184203
            },
            "errors": "",
            "filename": "2023-10-31T12-46-10Z",
            "size": 8184203,
            "verified_at": 1698781602
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698756384,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "DYo6M2JYwJxDjIOhwqCpHy6v8/ppJNYKGmGCM/rnzNM20IT5Vfk+uB1nbqF+GQFBO5sI5pUPAXMRAHhFAs8k4kScu3M0DJHFmtysrrOm9ZFD7kUEtVu2805ymPqnFf18aAvFmP+p+LZMXIf/saPGJRj15IrcHnNeJEEAVehZvLJxlJvF9G2aFRfQBh8H8ZKAF86UBv0a52pYhr0nWmDZwlvuDV7BEnRc6mEgb48bZKOoBQjpOe2+rohh5FCdGG3UXMS55nHUhjqOguWq53JYefbxLhveEAAWFb68eHkhMgaHcaVP26aAKX+hEgTtEVEwUnoVXXUGstnGeAySklElNeSJEfLRJnK5eMUmTbbsz4PApkVVEMlpr79gfc4RQDdvIa7GHh3TWjDicK/n8uXa7MofA8TvnZyH95OvCxuDI+KCGMD6jExteNBLh1XgdSaJ5BrYworUhIzACkPxtwMJeNrB7ggnkz/86/Rjyt9dBm2XN1Sm83v5zrkGWc5dp6CNxQS9mCFs4WBaAccLR4gmy/M4nJjh+9iiMcLk9lmd7azM8o9a2YmhV8qgjVeXMvVY9sm4VMy1GVoSrF9YbTPZ+deWeBH4QlyHhcUxatMPrsnI5Z+zVhxBir9klwWarwGM2QNoZfnlVL8mgnCPszSlc81te2PnaZ+d7odOECZNVTU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-46-21Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698756257.009791,
                        "modified": 1698756257.009791,
                        "name": "/dev/shm/incoming/2023-10-31T12:34:16.ais",
                        "sha256": "3998ba57296ada8bc127e4bf756e7fb38e4a99a985ea469ead42836b3bfff4c6",
                        "size": 545070
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "75a562f4eb8fd018935de7d7b1d1ed9098fc3d403ac822b9c966429d83473e76",
                "size": 165211
            },
            "errors": "",
            "filename": "2023-10-31T12-46-21Z",
            "size": 165211,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698663669,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "JNkyzKVip7iJxptcsu9I9xypmslu7HeltiJG8aU8vhobVggWKHDh/BGKsUrkwqtRyusc8ZMoPcHMvGqOmZUGVBS/2Sg8wmQgNS+QxR/8f9nTiATXhbb+Hh/I253RYmX5BwvVbWDIFUU5mhiMOxw3i4CVbKOPJKHlBSjBZGbSI6T9asNFB+DzJ+qVtYFqlIxH2F5TeTNVFs9KaxNOH4nL4pIa39HZ8oQXkYsnBpi+7d1FxgchqKRIoYllknqQq8dwdiMvQiSWoeYZOMQ52wqCONUnUgwEaxvO0wNOk5OJHYzgmf6+czkIQ6+x8ZOeZ1AG3CGrUou30lR/HUzipn1TGU9LZS/4xWc3j3j/oODwddz5GKO5+NzNQkCXeNCVBUzYROaC0YaeERPd2zSdrwCXrvCZsJid1af5qdPbtTwXzSINYlxSSSNBQQzM+rjhz5/SVN+gETNHKyAmptd8gcBzvg2l2syJQmuapshJstJu8YQ3Cjburb93H9Qd78D00ZrsRak1Ya4MzVwh3Sex9kYwM6mx2Lc1iUTk0H48uD5XIKIZDu30kAZZytmS1ezuQNeMEA5BPKw5vaoUX0ia2rxyaYR4WpFLJyy7sH6D8zqlWBjo1bv0naBpCOeYeqwj3G7cRIEGkpX8FaEvbnGGulmdYxawmq8FhGeOclQ4YeMtvfM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T11-00-46Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698663521.2278287,
                        "modified": 1698663521.2278287,
                        "name": "/dev/shm/incoming/2023_10_30_105821_00_00_V_037.txt",
                        "sha256": "4a5c766126143f0af3f169f85549c7d9766c13611b1baaf7a7405a5e8e3c3ca0",
                        "size": 2027
                    },
                    {
                        "created": 1698663520.4978287,
                        "modified": 1698663520.4978287,
                        "name": "/dev/shm/incoming/2023_10_30_105821_00_00_V_037.kml",
                        "sha256": "a9db2e0991d338b7deccdff951c9947a2ec2ed36fd2c812e56febae840b5886c",
                        "size": 971
                    },
                    {
                        "created": 1698663520.2078288,
                        "modified": 1698663520.2078288,
                        "name": "/dev/shm/incoming/2023_10_30_105821_00_00_V_037.jpg",
                        "sha256": "e0cb8f259c54f97a870d52c2821cb5d487856d4c4ba8d975d1b2418489753749",
                        "size": 856602
                    },
                    {
                        "created": 1698663504.0878289,
                        "modified": 1698663504.0878289,
                        "name": "/dev/shm/incoming/2023-10-30T10:48:23.ais",
                        "sha256": "7a6cba7c5c30c30cfb221a8b91cb7510892c6e81d7c80793f2f24ca9fc0054bd",
                        "size": 611309
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "eb84b199a732771cc5021aa3f36e05df12641066d3f50135de4fbe330a9816bf",
                "size": 1028900
            },
            "errors": "",
            "filename": "2023-10-30T11-00-46Z",
            "size": 1028900,
            "verified_at": 1698693486
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698759784,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "EEtwZu/SzfQtr7H0rbB3BCSqmMfiGkgtOkpiPtfdUzY5HfC+tgdhF2ySV+jTvzjI2mnQPKBeF30U3vEY2RoiI+s0uSHtZ69IWZ5TmQis4BmwNz2lt12H6DDRlddRkP7ZrKfwXwNBB0/qwvWTIWyxr+iMQHWeZLvGiz/MTVR3HDP/0EQ4QiJKzcRlY5HJAWccgGZHPNl+ni+bsataxJgTriizeR0FwV4vn9VhP3iadGD+EuuzUl6YlsmQknhQJbGHOjeyjwRknuGJ/Jov3OpcDYYGqwcwmUwQOAoM5M7TEYYZNP2d8Isfi5bIyvczlNDZHCbM7eXhFbhuEwkWeQefB7Im6iJabCYrT5/rT39bvT1BNjL1L2m7hKmQrrnW8UL8kcCQGgIlh7WFn4jW5vc3Ytw/ToGeIH4hmB37AZ7BPbdu9k+rP9JdhHS1kRxFBdtYpM5ufKAVc/J4Unhlu9VqRmEEfBdiB1b/v6v4WXG4EHhYzy7o2JI2y6YS+D9YZJVZ5+ZYkS1dMEjrnOzKdceFHGVu/gcmQH/+lFUG5MSs0p2jeoWMhFn/PgGApsr3X3hETN1WWXFSUcfPp/g+DEC5LbeWgWOi+ZLsuQPqWhVY634b92JIMhaJ9OLCztFQoUE0s4X3pJAtfSH8p0yiADuxFfZeh0eOJ4VKj2Urky8xF+A=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T13-42-42Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698759753.2143621,
                        "modified": 1698759753.2143621,
                        "name": "/dev/shm/incoming/2023-10-31T13:31:23.ts",
                        "sha256": "9adfb8bbbae611536583578f9cba456a7b705bd0bc0cf88f173279f7ad0bd932",
                        "size": 152563880
                    }
                ],
                "mission": "EFCA1",
                "sha256": "f229493b9b43c14e5ec50e21f6cf34c42dda01fd5cbd508fce0bf23e22d202d7",
                "size": 146997574
            },
            "errors": "",
            "filename": "2023-10-31T13-42-42Z",
            "size": 146997574,
            "verified_at": 1698781669
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698556912,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "WipiRLtor7cDLj7FGnnsmVrq4Tqx2B2lV6uDxhJZmeIZwqb5NUL5fXpGFiCKYb0nwJD7BcUKGmhlmy+IygVwHbncJKkdwIwo8kPHu5CHAahpYQ5EvP+S9W9ACSfE1RboB1OGH9ELKI7Qo1uWOYGxXR6Sij17FPQmzT69vbt2CvB5D/uNRTrI7T0/TEgDRsq2NeKYci2NwnZm9kqNzFNJojh1nO2T4HQLb9kvw7jZg9bf2UJniSwTcL+eYgL2pzWgucCcaMFLlERf9gEz5amXL9jXpPe5nGOwjBeNv0wFyTzffO+0h/CBwWafVdKHgv2cI17ydH0XNVLUqEBF9eOepQZ+bzHyrL9c9clrcsOaAdfsJDLWNEnmgUNHkmGlhBD56SQpWph9q5GWZZSQ6MHiHmZFv9N3cqFh6cgwKtP75vTys8XWKOUhAxNyWTt5uxLzAMpwTnjcsLQfHQbVbvfLeg0Wf6IdFig1Jvuygs5ooL7hN2E+kwTfFI2SjzyTaNBF/cDhmDa+P6nFRE3irLOPe4BavrS6hIz0+6/h9MWeeh2T3sqoqyUyuVnqQieDXbFbuS6Wiv1LVO/3ND7StqUEM/DicZcU1oZEOO7PnkKt3uwg0bQ0wrhymcB+cwW9lVq89do4WDi4ES5m5v0Tn45xw+Q+OaxCxRNeAFjHXX0Rk9U=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-21-29Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698556758.4509401,
                        "modified": 1698556758.4509401,
                        "name": "/dev/shm/incoming/2023-10-29T05:18:28.ts",
                        "sha256": "236aa99711118ce2d79ce87011ceac03a96d1c22c06d1682cb2ed6b99770d00e",
                        "size": 12745836
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "ed1282814cb613497b94f0fefb48e727757dd41a4fcc2a78d9f3fae0edbb72a2",
                "size": 12320332
            },
            "errors": "",
            "filename": "2023-10-29T05-21-29Z",
            "size": 12320332,
            "verified_at": 1698577453
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698556815,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "cCNa23tw4d+2mT2isitF+TwLqRNXKYYB7aEUlOGgVwBKuL6A9eCXZc8GwqoFYiAlxvWHyPE1ddPnHIYMCV1WZnbZZV4LK4qGUajXMivgoMHewu7tRUHTqJ6qOG7J4JTVPmvn1Cw2xD77WdaBsjOs0xOqxcyOdw9JyH9cWc6pXcx39FAMOMp/BUXITiEB3pvnWHhugIL2u3klVCoE9LujvS8qFYcvdNdftPeGd5SWg20M4+3FRwz3VCjZd29Qtx4pOr3KHodWBEi0oLokGpWL9dg3R0aEY0EshjlQHZMpVn1Ocp1CKP6uPUU3cI1iw7+cvQ8mZg1ktlZyE2OJbrxL4xi89mtJroj6ezIKYJ7jlwOBHhm7h3DDx5sQzIWerm4JFns1bJgxSKa+38iAacrLesDD5R7+Ap3uNN2aXPTc8klPGU2NtmHRggb5duS80mrZ6GmGpSYg3yXx6vS5SC+LgL8ulLDBfJHzd/PvzGtqeEm5GeGY86squQQ6oGjXLve5exItqRdrOVl+m+rbI0mvWTePGdtveypsogBeCCZkXPk4QQ091C0OlGR7IEHCRpSqCbacLtJd8/XFN+z7qFYZtxfwBduaR3U1vAPwSg1NgZ8h5svUl++GgIQCMaFsdP1uHIeS800GCeuRr0Ynupk26MK1V3Lx2eY6QvjjqgE44SU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-20-06Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698556682.0579689,
                        "modified": 1698556682.0579689,
                        "name": "/dev/shm/incoming/2023-10-29T05:08:01.ais",
                        "sha256": "a4a37368a65501a4d76d32abef92c99766797b4e2992a02e50aee9a764b5b720",
                        "size": 82136
                    }
                ],
                "mission": "OSP3",
                "sha256": "eaf6f4f57d1050ac9fef1b05c926649a9014c4d7bab8f956f0ef74b7212e973d",
                "size": 18806
            },
            "errors": "",
            "filename": "2023-10-29T05-20-06Z",
            "size": 18806,
            "verified_at": 1698578277
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698556964,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "PM7RBuR1yqh1ZUuJ0nS/bvoGMcLXtvGA8eRLs44YRoVGfqs6B2sftDy2fZ2wif55aGIFAI9JXUI4QQOgHXD/LSQH9JzT/ZDtu6CW3IVZIMYQFbiju1CQ0YC4QJKBXgQw0Ix4iW2PGoF+F1kiNVuVIPVsTBiCeJbwucBYwKq89YjUDG125r1Tc1jXzDQV0eokump+AVx3a42KLVnJpvB7ETuTXwuk6YSX6CUWcIVuS2ovyEnHKSdNhuSVsgknGTcF+8yM8o0512WQob7zCfAZkA0EJrJKDDTDn+TIA018niq1RNeJJJ3DUw4MEb6HzcQ76Q+C7dOzqTeQ9po5R3oV0XR/zCUqC5P7fyeQjcCnb7cU6QpjyGj4qQUYCEqiZGcR4PwHKfuf27bTpGhc6qVD9Pfz41N3zJfFT/ssxsp74uZboxfiFqXSAJ3erePoP0rSIyYnGyKsOyAn+qWCid1uwpRyLptdaeBKsZYFzNFyfAlfo5NdrjgIekIRzfv9nBPkGAF2yBvBGoLNyPHFJ+MF6FrGVm2TCetGXOob7BuW+A9udNiIeIVqTXbS2vN/c60rw7e57Ag6Og63wAT/8RuLSy1lO1dpblD2coy/TcZgEji3xMsSEguEByhWLsdziy27sseO96fdHd7BQUYZFSSLPVTLs/hH4sQJPjl9TVQcbzg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-20-45Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698556834.917969,
                        "modified": 1698556834.917969,
                        "name": "/dev/shm/incoming/2023-10-29T04:50:34.ts",
                        "sha256": "7105839f907f5ce2399a960fcf9e0b7b81965e1aa1713aa53e6967b3ce9cc828",
                        "size": 263227636
                    }
                ],
                "mission": "OSP3",
                "sha256": "27d5d640d10dc253bc6e4b185276d19f80d1cd17e90fb8eacad5a3d135f0e78c",
                "size": 256134760
            },
            "errors": "",
            "filename": "2023-10-29T05-20-45Z",
            "size": 256134760,
            "verified_at": 1698578345
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698557648,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "OKkS2KmtAFim1lRcrsyLu7hUyw3S0kfiY+pWLWzhP9hSyBXOFFfNIS0mDQDwaIBtQ3lECEkwya7Ljbclo2Q06xkgZE5e7zXWEZWaO/SfUZxPgx1oPtwqTAKKsUe1+iVSvHrMHHCmGaWV0I6CzEkg+aUzwhOfkEDVel7jVCVZFpSmhoQUmbQqGwASzqQvM8650mQV8Ei2jFOSRNrv/lHVX8NfSYxZlGv7vExW31ko2DUG5i7gZpONALJCReYdY2ky7llxsebZOqWpk6NVqDBTfUmLmwel9chlyH5K0Stn8rGT1AYt/EbTvSIFpQ6SdDdPPBGB2qcmOXtXBk4JzAxpWrId8kphD2NhFqseALJCpweiycrR29KZz5TpPDmiKF7qbCTmb24jYQyB0IKKjkTtZIOeTfwvv9StwICna75IarmCsbbMvfuFd2Jax3ZZSSDznSRfk7nu+7At2KPZy6Vz8a9x37Kb7ACo9kTCWML+UMAySx132jgsLKHvpiB2fGlYFMMAyjofM0aEMYFi9R6PYkofKM8ZV5lIsnYnFYgOS5odEN8nGxGOw8kFRRxVQzvhhtWxvMVM5VFg9t3iKI2dv884cDziEwajVQUk4HS+rpUA3WTSRh5iLQmFy20/EL40bZ2zoiKmb8B2qeRktlyOiO2zv0FutI/DCZFfNDuSGB4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-33-42Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698557497.6009402,
                        "modified": 1698557497.6009402,
                        "name": "/dev/shm/incoming/2023-10-29T05:21:35.ais",
                        "sha256": "c146c326192b3f355abf1067fcf8ebc79bee535290aebfe5bef9b10025c394c6",
                        "size": 415715
                    },
                    {
                        "created": 1698557475.3809402,
                        "modified": 1698557475.3809402,
                        "name": "/dev/shm/incoming/2023_10_29_053054_00_00_V_001.txt",
                        "sha256": "b42a1b037fde9fd60a290f81f9f3d91174ba1beb96f52c8f2287ae56a5765b31",
                        "size": 2034
                    },
                    {
                        "created": 1698557494.9509401,
                        "modified": 1698557494.9509401,
                        "name": "/dev/shm/incoming/2023_10_29_053054_00_00_V_001.kml",
                        "sha256": "4be62b10c9b9f463ba407b4165265203f68428281d559e3edcfd8ebd38e93bae",
                        "size": 978
                    },
                    {
                        "created": 1698557474.3209403,
                        "modified": 1698557474.3209403,
                        "name": "/dev/shm/incoming/2023_10_29_053054_00_00_V_001.jpg",
                        "sha256": "4416c5dc67103934aa17ca033e827999930f0d722eaafb6fdfc58378b26cb7dc",
                        "size": 1421658
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "23345d041dc7fc612f4fb45ad9d6f072bf5ebfca5a46989a697724290a04712b",
                "size": 1546184
            },
            "errors": "",
            "filename": "2023-10-29T05-33-42Z",
            "size": 1546184,
            "verified_at": 1698577582
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698557841,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ouhOJfU/1vmr5jDflCbcM5Vbj9RzfcfAOOWa+StYRrpRx3l3Jx6JXClUAX8mHBcQYRs0ytwU0GfyxIsMNELeZP2oNxjaOMyHP5rAd1K9zKEzQWIPm4rd8bv5Z3/gbHjSpr1f5gj2zUbnX4iJus/YWvxP2tA3Ji7f6iauUpCruAOJ5+QcyD68CpfaYmw2bmX3eW96qN9gPrzx2a9sQp+U6NE13IjRa+2F2MjCxfIVsKUpdV47jGIIxkD+QULUqo4wkB9eRhNGMog2lZ1zeIUhZ/S/lptgpRopKAnVbt4lCirXgtOPFwkOXphUSq6SeeJa4t1pAT9BTF4O4vfEoIqoj+JZurvZPBwrcjnytFj6Vpwu+Zqa1QBGO8XVvlAdUbs2FG6WcMbNl4u/sCSvmG/WOuEu14WdrocWR5UU1FbK6sUxOgoMJh34ML1XyQRHOMlf7bQul7TmSuzKHV0qjwLN1wPXXYcPRH6RgDfDxGtLgLD3X8Lo1QHUbXiLIHKN/42mEyNSBDCflBT8uR9QHzGSjzie3jLVhdw5vrPnrUQKF4GWZLPjS4EC1wbeendJNn1FDuaCpgCcFedI3XwJXtTPKbK4f6daWcC74rH0Tu+nFrNRD0LZHtdvRHCj5U5fgZauzNaXFM/BdHvvHFYctR3cvBegIWDAE24lCn/bjzCH6Dk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-36-40Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698557724.9009402,
                        "modified": 1698557724.9009402,
                        "name": "/dev/shm/incoming/2023_10_29_053501_00_00_V_004.txt",
                        "sha256": "068e54e3fb1da221a2ead2e8055828e9f18f10e8892e42e6b755db095a2749e3",
                        "size": 2029
                    },
                    {
                        "created": 1698557724.3909402,
                        "modified": 1698557724.3909402,
                        "name": "/dev/shm/incoming/2023_10_29_053501_00_00_V_004.kml",
                        "sha256": "3e18b8b681d9f4fff3a78be5e2857c4d1c91dc159445c398f1e25b5ccf6a0c82",
                        "size": 976
                    },
                    {
                        "created": 1698557720.8009403,
                        "modified": 1698557720.8009403,
                        "name": "/dev/shm/incoming/2023_10_29_053501_00_00_V_004.jpg",
                        "sha256": "1df78c1ab66a1fe669e4f596ed55e713cdd2864ec4368af6a3bcc5a0ac5983c4",
                        "size": 1199732
                    },
                    {
                        "created": 1698557687.1109402,
                        "modified": 1698557687.1109402,
                        "name": "/dev/shm/incoming/2023_10_29_053419_00_00_V_003.txt",
                        "sha256": "8835dbc55a4a57b31e28ead6a54a869ae8b63ea9aabe8f9cb1c2c535b041ea1c",
                        "size": 2025
                    },
                    {
                        "created": 1698557677.48094,
                        "modified": 1698557677.48094,
                        "name": "/dev/shm/incoming/2023_10_29_053419_00_00_V_003.kml",
                        "sha256": "d402cb790579dcef1ccf7262bf07d78b09b1bfe8b66add1dab7c8df04e188dc9",
                        "size": 978
                    },
                    {
                        "created": 1698557677.1409402,
                        "modified": 1698557677.1409402,
                        "name": "/dev/shm/incoming/2023_10_29_053419_00_00_V_003.jpg",
                        "sha256": "c271780fa4ecaf74771fd2ad0e6c6e00fcd6b69fe070cc62275b3e14fbdfd56f",
                        "size": 1021214
                    },
                    {
                        "created": 1698557676.4509401,
                        "modified": 1698557676.4509401,
                        "name": "/dev/shm/incoming/2023_10_29_053358_00_00_V_002.txt",
                        "sha256": "6ce25e10a6cd69c9ee8888744b43217a033fe2d9b7ebc94e1e8e8ec4fde70d62",
                        "size": 2029
                    },
                    {
                        "created": 1698557656.2109401,
                        "modified": 1698557656.2109401,
                        "name": "/dev/shm/incoming/2023_10_29_053358_00_00_V_002.kml",
                        "sha256": "90efc18e06ccd2771391b05245aed492c38cbdcec5f0ac34aad0cec67d857914",
                        "size": 975
                    },
                    {
                        "created": 1698557653.75094,
                        "modified": 1698557653.75094,
                        "name": "/dev/shm/incoming/2023_10_29_053358_00_00_V_002.jpg",
                        "sha256": "866423fa232a8d9a79f89e77b6be6645fdc1078e138e3af2fa387b7c8c4366fd",
                        "size": 1124340
                    },
                    {
                        "created": 1698557790.1709402,
                        "modified": 1698557790.1709402,
                        "name": "/dev/shm/incoming/2023-10-29T05:23:27.ts",
                        "sha256": "78966b3df38a9668ec38b75cc49b9d5a0677c7b98e6ac445bfbf56031e55793e",
                        "size": 171800416
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "ca04e857677c346a8ab295dba5a933d9e0473592bbc173fdb440206e5f8995ae",
                "size": 169006736
            },
            "errors": "",
            "filename": "2023-10-29T05-36-40Z",
            "size": 169006736,
            "verified_at": 1698577691
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698558251,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "DsnVcngeyV+MJ5n8yWYDKZNliz94g4uhYfY6NZAf2QEPe9Qm9wG3TDhuZ1L/vGw08sHfWkA7LayEO33A98OX/22jR/LOvmu7kHvJERLJOTmEZ4EYaLAgDBRHRyswPo/3X3Zh72ApUaudN8Fu0Br/F9x1w9vfEOI8V4YTfvt8ZPYRZNlnoUQ8qbBJIH9Qmi2KsRNr2YSRlbd/43KoSmaChvGs6sBugfy7mX9ar0/W6EFNEd3M6OdTQyJ4xoKGK74SgzLEhk4NZSs7/l15/wKHig7PBcwxbfHMn7PVVBgzgnQvdxYjMEqluB+VeKuvuwiT5ppudZeNuiuPrOAYZKAZCPLdrFLoZMh4vnRL9jBzMG/YydPMIhVvzrfS717XQdzcvxViwo8mG4JP1Q1T2LAvUssA4hhvUGLMSAK6rZi1kIYvm+DLgn547GdhBCXaDORCacDkK9x/RJtQgMBLj77ubZ0Vwoz9yr/9W/1XHBdDWdk08aJdp4kdIO5RNSPxT45AzTI5rQmyuYMy/GaTztlO2dF0KyZf3oAQf7zxIEpaoQprVB3DCJfVkCeTOmioi7sQZXnCAW+GLPFoAXVYC3oYoAlXq1n1bCCS9t45plg+lNzRsDmnm0wwhPi3QBqwZVjqw4VZBUjExNb0gnu8P40ep4n6WpXBSJZVFkdqoG8+uSo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-43-48Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698558099.50094,
                        "modified": 1698558099.50094,
                        "name": "/dev/shm/incoming/2023-10-29T05:31:37.ais",
                        "sha256": "1889412772d24e5cb425efa2fb06187f97589df9d6ffc8593a4e4781dafe147a",
                        "size": 410835
                    },
                    {
                        "created": 1698558037.25094,
                        "modified": 1698558037.25094,
                        "name": "/dev/shm/incoming/2023_10_29_054009_00_00_V_012.txt",
                        "sha256": "44c63c18d17d350f37caf4effee7fdb041bb127f63a2129df985617fc6a21a87",
                        "size": 2033
                    },
                    {
                        "created": 1698558043.4409401,
                        "modified": 1698558043.4409401,
                        "name": "/dev/shm/incoming/2023_10_29_054009_00_00_V_012.kml",
                        "sha256": "844c0970dbe7051fb29ae537c0f9a338d9b21e21678799ab44266cc50461d1ac",
                        "size": 978
                    },
                    {
                        "created": 1698558038.1209402,
                        "modified": 1698558038.1209402,
                        "name": "/dev/shm/incoming/2023_10_29_054009_00_00_V_012.jpg",
                        "sha256": "fc95239905b33451b800066a660741e79592dc27d97c2d24075192310a287071",
                        "size": 1074328
                    },
                    {
                        "created": 1698558011.3409402,
                        "modified": 1698558011.3409402,
                        "name": "/dev/shm/incoming/2023_10_29_053953_00_00_V_011.txt",
                        "sha256": "d06f10d05d38ead89ffd7f42e15335f12d3a0b853a0a35f1b6dce0824b9052f1",
                        "size": 2033
                    },
                    {
                        "created": 1698558009.0909402,
                        "modified": 1698558009.0909402,
                        "name": "/dev/shm/incoming/2023_10_29_053953_00_00_V_011.kml",
                        "sha256": "c6e8a15ed32244cb9028f025558406089b1cde74a5f0d5d0868669f9e24dbb61",
                        "size": 974
                    },
                    {
                        "created": 1698558009.7609403,
                        "modified": 1698558009.7609403,
                        "name": "/dev/shm/incoming/2023_10_29_053953_00_00_V_011.jpg",
                        "sha256": "a7370dc986e848dbc39f3c599e8220dfc32dae40c853695b46ca63c03a6a01b5",
                        "size": 987415
                    },
                    {
                        "created": 1698558005.47094,
                        "modified": 1698558005.47094,
                        "name": "/dev/shm/incoming/2023_10_29_053926_00_00_V_010.txt",
                        "sha256": "baf5d4c0a5da47dafc59321e87874bacf149c6ce97a57501d7c2c00c17ec0fc0",
                        "size": 2033
                    },
                    {
                        "created": 1698557985.1109402,
                        "modified": 1698557985.1109402,
                        "name": "/dev/shm/incoming/2023_10_29_053926_00_00_V_010.kml",
                        "sha256": "db8bd2b8d7a11e49971c285518eca60d43ecbb78d81c1928dafe026aef30a7fe",
                        "size": 974
                    },
                    {
                        "created": 1698557988.6109402,
                        "modified": 1698557988.6109402,
                        "name": "/dev/shm/incoming/2023_10_29_053926_00_00_V_010.jpg",
                        "sha256": "13d5019e4d35713e141d9b298e8c88d6e29e31e63d19c6c90451fa86836bdc4a",
                        "size": 1228988
                    },
                    {
                        "created": 1698557982.8709402,
                        "modified": 1698557982.8709402,
                        "name": "/dev/shm/incoming/2023_10_29_053915_00_00_V_009.txt",
                        "sha256": "ec161dc836bb7638d809cdb93882e528c585ecb65a648b5c411d243fdd07dfa7",
                        "size": 2031
                    },
                    {
                        "created": 1698557982.1709402,
                        "modified": 1698557982.1709402,
                        "name": "/dev/shm/incoming/2023_10_29_053915_00_00_V_009.kml",
                        "sha256": "4b5b5d7e3ad565144aa563e87a79fd967131589e26888fd3b1b58907d54661ef",
                        "size": 977
                    },
                    {
                        "created": 1698557982.0409403,
                        "modified": 1698557982.0409403,
                        "name": "/dev/shm/incoming/2023_10_29_053915_00_00_V_009.jpg",
                        "sha256": "5ba5e48e33dacb24488e1662f63bc4c9308a9a703af8d4c6ce8d107205dc42ee",
                        "size": 908090
                    },
                    {
                        "created": 1698557979.0609403,
                        "modified": 1698557979.0609403,
                        "name": "/dev/shm/incoming/2023_10_29_053909_00_00_V_008.txt",
                        "sha256": "c149356f6170b6c6bf551d5822302dd3ecfa4f4d79dc37a00929a55a32867b28",
                        "size": 2032
                    },
                    {
                        "created": 1698557981.8209403,
                        "modified": 1698557981.8209403,
                        "name": "/dev/shm/incoming/2023_10_29_053909_00_00_V_008.kml",
                        "sha256": "ff6d4fa9d84da5e8320d52dc2c4ffdcfdee77eb31aa9da7b7364832bb87e7141",
                        "size": 977
                    },
                    {
                        "created": 1698557979.49094,
                        "modified": 1698557979.49094,
                        "name": "/dev/shm/incoming/2023_10_29_053909_00_00_V_008.jpg",
                        "sha256": "405b8139beaac36eba0b3d7e667427ae5cf08119e1e45ac335448ac2156c7d52",
                        "size": 1120684
                    },
                    {
                        "created": 1698557966.9609401,
                        "modified": 1698557966.9609401,
                        "name": "/dev/shm/incoming/2023_10_29_053859_00_00_V_007.txt",
                        "sha256": "90aaab78fdbadd9c20e5c1367f135137afa10a82706710cb06b00c0ed01ba652",
                        "size": 2029
                    },
                    {
                        "created": 1698557959.4009402,
                        "modified": 1698557959.4009402,
                        "name": "/dev/shm/incoming/2023_10_29_053859_00_00_V_007.kml",
                        "sha256": "d81352b1600dc09f1e442c0aa82197abf2356f19255a5c7c5cf7c45d0a4a8108",
                        "size": 975
                    },
                    {
                        "created": 1698557959.0309403,
                        "modified": 1698557959.0309403,
                        "name": "/dev/shm/incoming/2023_10_29_053859_00_00_V_007.jpg",
                        "sha256": "29ae6318a1a15128bbbe7b886d421b663c8c69913430b36670fef27857af51e9",
                        "size": 1049989
                    },
                    {
                        "created": 1698557956.3509402,
                        "modified": 1698557956.3509402,
                        "name": "/dev/shm/incoming/2023_10_29_053853_00_00_V_006.txt",
                        "sha256": "6d973086ba80a62be73e0d2ea6d23a9ac4c5f55761e43364e261677d8dfa68cb",
                        "size": 2031
                    },
                    {
                        "created": 1698557955.8309402,
                        "modified": 1698557955.8309402,
                        "name": "/dev/shm/incoming/2023_10_29_053853_00_00_V_006.kml",
                        "sha256": "ca9ce0a60a832149b041b756f7ebf4233e68dcbbd26656e54027ac15214c3a5b",
                        "size": 978
                    },
                    {
                        "created": 1698557955.4609401,
                        "modified": 1698557955.4609401,
                        "name": "/dev/shm/incoming/2023_10_29_053853_00_00_V_006.jpg",
                        "sha256": "fc6dfb0663b0f863787bd3653420db247d1f3fdd0f8a1e97377ea1a4cd536c06",
                        "size": 946336
                    },
                    {
                        "created": 1698557896.6009402,
                        "modified": 1698557896.6009402,
                        "name": "/dev/shm/incoming/2023_10_29_053749_00_00_V_005.txt",
                        "sha256": "b685562d7ebd2b378ab46af4bfc71286c60161a33014570ba64d78d060e6079e",
                        "size": 2031
                    },
                    {
                        "created": 1698557892.24094,
                        "modified": 1698557892.24094,
                        "name": "/dev/shm/incoming/2023_10_29_053749_00_00_V_005.kml",
                        "sha256": "e5f850f6d823523deb79b8671b1557bf2891e2c6685e2131be7e259ce7d4bc41",
                        "size": 978
                    },
                    {
                        "created": 1698557911.8809402,
                        "modified": 1698557911.8809402,
                        "name": "/dev/shm/incoming/2023_10_29_053749_00_00_V_005.jpg",
                        "sha256": "bd8be03b941ce5b69ef951dfe56abe5eb9d4cfa009daec63b432431241b97d82",
                        "size": 1033689
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "3fc614f8e8a3fda7facffda3a492635ed0b9e5d30fad3dc6e42acff57ef07df7",
                "size": 8478908
            },
            "errors": "",
            "filename": "2023-10-29T05-43-48Z",
            "size": 8478908,
            "verified_at": 1698577747
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698558019,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "qlSRXmikcGYVwnYfhuiXz346Es3OZv5K//ccUFHX3MeNOGSZTeXbrypKFT1ekq74a4AQZDkwoPenDhEms0JnEbszJcz+47z9omwacOCAeJuTe4prZsvv2IgwCft2hPfBnOEsnvkxDGouGIrBqifizzlB5hC4s8yZjYSbk21YRc3J1DXnE/BZ3Tzn8+qNueEwchMJJnDtgqxUPs8z8m9F5eFe9c1Eke61WikPOVGsfNLni6CKwQCswA2+940i4J7C6mYf/SI88WzOhaRKcIvABbpBVOTv9ZUUWO/cBk7dvJNkpPbq+Jtt2UhifZTQlbVQye2RrD1VWgBjyU0ssGR07MBW8EOmPzjxaAGlIEjz7jqDMYcMJmzJA97zo00gYQO00pnzvObmbJqQNkK1LywMyVyUvyDibi0997ZtQ3pzLYontMAj+v3v7VVA16r8nvFe7TDG0Ngdfy5ntlsu2NIx5NJ+DdgkKLJuhU8Yy2y/xAUQ5Enczu2ZyTLfu2GwCx/TUQT5FTwiPsfk+m3yGT06mCuvuk1KfmtGVVQCwHbo7meN75cEXie06HeCKstZiMAYkoXywxiNFA297hmcN/uBtEqOPgHqH87kY112KmlWW9YiUZwkatF+Rcz0jv0A/5ZuYzd7NqB5ADUDrVW2DG6NQWhpRKy2a33Qvg/uPGOMCR4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-40-11Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698557884.0979688,
                        "modified": 1698557884.0979688,
                        "name": "/dev/shm/incoming/2023-10-29T05:28:03.ais",
                        "sha256": "62671cb42a28ef0393a6a30e375b091c9f0b4948691d3cfd37ec02132d521b28",
                        "size": 61684
                    }
                ],
                "mission": "OSP3",
                "sha256": "ab4d6e85688ed40aafd1cf563b5bb4eea70df443ce3c0c720144e5ee4cdb363a",
                "size": 16853
            },
            "errors": "",
            "filename": "2023-10-29T05-40-11Z",
            "size": 16853,
            "verified_at": 1698578291
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698557412,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "SXtCllFvGyHl8iDY+8glkQjCv0MuA5B4RkTrzmK/cF/Zr9RxVwGgtyiXUyUa3Md7SQdP7XtCahxklbHVfmUomzvJQYrD8nkS9JO7OplOX4gkStz/Kq9OpCYJ8uirLkMMUUu8UOmuRTxlv+AmLYtMtm4DI3ZKdEDjgt7kN1NQqF9LM4agnSW4qsnDcQeGXDIhMi4Tobbwm/ITYB7kjgK2oLLS3uBD/JRChKQsuxxujKUiDUtN5xgiOT4keZ2DAoa21xelGOJVvIYOe/Xj/tD/e2jsDOzU2LL0GqTKmWLwQWDK9d/324LNPADK3Y9YIuWEv/yfqC2Umov163nUk/u1Fd3FDO3vS3UlFOnjPkdNwGKkPhD18f1ELVwz3Q5TgiNxznJJIhswza5gBSFBkagOcl9sAUuxO8vglOgAIybmooCO/YWsYOm/9SB4yMKCtLDQHIy/usCdaq9CUHKhMA4dpmt7gfFeHDVrVAOxNlXCdrsXqRFBbKXJON7SvLt8bxkNucRM/FHLeZLXCBPsYGOc70o5Nikxox6iq5KFwy6o/ycy4zttkKujKod20Ahlb9adHIWPF6zDQlr5gOQouT6BdxdntReYBwEE5RipfWTyjO1K4vCYA+hxGRTyasWSJ2HkEXP6MH1e1wKNVwIBIqYSy+ZDuxk8fQ3SnJXp9QY6Y9Q=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-30-06Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698557283.0879688,
                        "modified": 1698557283.0879688,
                        "name": "/dev/shm/incoming/2023-10-29T05:18:02.ais",
                        "sha256": "42ce0886a58149bdc319dfd641d4c9aa7f683493767a823b491ec180d12fc70b",
                        "size": 64853
                    }
                ],
                "mission": "OSP3",
                "sha256": "194136ca44a7bc138058a381a3c8145de1f9703ffde5dd0e0968d6935791a87f",
                "size": 17149
            },
            "errors": "",
            "filename": "2023-10-29T05-30-06Z",
            "size": 17149,
            "verified_at": 1698580016
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698761176,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "lCCh+pG/VkaLQ4mjoKlY6Sg94f9uysPfI0YEbbivG1ZLH5Hkv1aRpP5/0z4EX7Cuv5xF+glrka80G4p3atm7XDADs/XMM7zLX3l+1e5cS1YD5IhhvsddEEVhVH/1nrfheVWHSDtED8lZEalFIhd3Au0zGAXTfM1xa4sBuGDi8UKz6w6KIrD5v5rOaAZ14bguNE5+gATYjMeCnRUNT5/ApNi45s80nmrG2CML2OoHi3/cJnOgjFkSaVk7ueZ0yurl1Q91eUWVsdQ4pUhT4UaL4qp9tQh1pEkp9xuEsjHMFgLRfqji65ndr6rfwuzR4tiEc36wRht7rTPGIL1EQ5qFiqSt9uxzh4+HnxEl/oCao/zl11/UNvywlHuQ8D6ArzlmWLogSCX6wAAvWVQq6tpVvTuALSP1Mex7Y4dSLoKyb4A08XBR7S3jWHF4aerp20bmZvYRlxhYf/kbw6SxsJN57ZD5ueXagOBe/U9SnkVm0b8FduXANLel3tFazbBXMZqAn2IfeOscICVaPjY1nlClTT17KtzA1KqMTAOgaOOW7WIw8Ae0EFGf7ZWSTWxbPsozWaAi5atA8hewxb+ExkSHYn52iCQsexL2KpATDcWSDaBN8eySxX2fuiWVxm9kMWj/zLgzYYtFzsnvWi7u6lAELiRA6XKNH6kG0l7sEvvocIA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T14-06-12Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698761048.064362,
                        "modified": 1698761048.064362,
                        "name": "/dev/shm/incoming/2023-10-31T13:54:06.ais",
                        "sha256": "36214cf5a14d5e3ad3d698b7e167d2b11fa8f2e193a16985a09cae9b1a67f1f1",
                        "size": 68877
                    }
                ],
                "mission": "EFCA1",
                "sha256": "ed3752e8fe8f7f0055480f18112f7e061913e30d41368a52842ba3f286f1d486",
                "size": 15173
            },
            "errors": "",
            "filename": "2023-10-31T14-06-12Z",
            "size": 15173,
            "verified_at": 1698781646
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698765967,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "OD3znQn9tuYpPUcFuQbXXzIt9J/zcOOVhzkJ1KXCr+XQ1/LJ5px3LBthqFNC8KusGK7jk2osSzzIafd1vao1rcR09KFqCKj2rpqWgHrW4sTumJoQ6NqIpDyjCDz822IMCL8/I7+Luf2wBdREQySvOAhy+jAbwTJdmWk5hXWrdutgZ7pOTg4bh8b8zw1yQ6mR5U+SyVxeBbbPZLLDbDE5UoKD3USa5/Z3zZeT2DJZj6fmRFByA0GowLJ36pZGUxVaFiKwDlNSny+wHaeOu86WoVT5xFOs6QxFQNEI12Nj42023helpzmT7av3jEjtZwcay5888e6Ln6lng/6WQAYPWFxJ8+B6ggbvW3WC7YrDVh+7h5kwL3wktlWaeRD7i6y4R74dwZcqwyR20rkOkCTGJH4uFhPGFb/nmyDK/7v9GMLdBeh7gGZa/O5yJmrkiPAAmA+FofTaPAFMGMFLusjTHK/Z0QvmeslBeD9JnTtgQ7vKBgF519lCvt6OIX/njdVr1mQVuwlnZluh7KEDrqs3kuwWY8YBRpVUWlld9Nrwt1b3Mq1rRWqc3KIeKxMFlb4EUILNvyCF9SnvoNavPgFDPqgDL1Ej1BRlcM0Xtm+/X4xzyblBMBCU4tnr09LvXPWyQ4lqb6v/Qk39eJq/9TelIqZHapSZP3OfjVK+Hx19keU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T15-25-26Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698765917.933036,
                        "modified": 1698765917.933036,
                        "name": "/dev/shm/incoming/2023-10-31T15:10:36.ts",
                        "sha256": "c1c15bac709df3e31df96ab1d3eb4385cb6cff10fb00ce530b7b34e3edcef389",
                        "size": 200877436
                    }
                ],
                "mission": "OSP2",
                "sha256": "e0399340f7db69803a0b1475d9b45a0b3be5739d011adb0e6ae79d4ceadf5046",
                "size": 193772144
            },
            "errors": "",
            "filename": "2023-10-31T15-25-26Z",
            "size": 193772144,
            "verified_at": 1698783559
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698758712,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "dATMMcBpzwHlau/DcbLOprulvQfgKdrKEn5eAzfDfBcW8aabiIcGf4Dqq7xr+MSU3dTwvIvXZ99EXbojOcyBw42nIwD28Hxg2wKi+HUM+fMNxNnhxE3UNttw3usB8JDn0PHuPIe+oUzYr66ZokzdUzHJeEtVBnebugYkMfIJE7Ac6hNkpPtFRDC4rHXncQ9xK4Hj2PTxCUXcLb7WYWrbVZgF5NWtntbQtX3a6O5sqNwgDm6/Tu70Ek1U1GLI0EnWwYsGyqckiX7rYfp9PMBSP97/h9vIxNZJjvxJKZXuZRdweWWFZQtZIg4kR4VPQnmRPGpT6DKtiWzYAImV71usPxLW8n47nKN/rzbX0OuKM2GTQ0/VdJTrXAmIc3m1dsrMq84o3w5QNtMitlR3LdihmhnE4m4hw8DAT6R5M828yIdXnw+l1x3CARc7DRWZekRC6eznQiAWd7KNtDcHD3dJ/s1H6PX1quqIo4PenskYlRjjQ3jExWkYZ8lyaHRu7J4GcoKHvNtYQHIkErdeCW74TqdZcdG+n7n6wMxAuh7SVyM5dx5vAJ9YAJqQdMPRfdDWiSJiRQXLCO2YBYgsLb4/kSJ58aCDDZMChK6grsdMUFM6M2cMTfIP6yH38TuU/jZxE3G38p+1XN6gSTUbuNT9xJ5HJoEvqa7L6s+NTfU2CCc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T13-24-42Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698758671.3614185,
                        "modified": 1698758671.3614185,
                        "name": "/dev/shm/incoming/2023-10-31T13:10:02.ts",
                        "sha256": "1b344478151e97a753e6ad6ff6730aff50236a9d7932acda50abe77b3e1c20b7",
                        "size": 198384932
                    }
                ],
                "mission": "OSP1",
                "sha256": "7386192edf5a996907ddcf6c556b30bb4c2f5f1366f71d1e06f971ebd304ffba",
                "size": 191385648
            },
            "errors": "",
            "filename": "2023-10-31T13-24-42Z",
            "size": 191385648,
            "verified_at": 1698766075
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698559219,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "a9Hkq/5V5j0+vFJic4rlSqG5loiXKyx2m/IOJIvS8fLJ5h1+cMf0VaxSzoILUG/Qbxsvs/vsiqimKcMPDFRdwOxIfzgB11TnXE5zM8Q7UCafKJxV/nc+OBLYtp2ks5xB4+PVlSuwba6EStJMfLDPSCKyFCbvjF57V9+lzF1+SS45IcolQvmoxd1R4fgPw6xw6Y8Vl5V425ZOgaBFVzKQHc48YZ6X2SW9XLRjTqMoI6fXzmc2styloFKuyA/gfT5T1w8HUUy+/xg62EY+TJFk1SAkq1GmdHomz5PqlIDmdpqC7jH3A5wpGWdovzK2Hcnzfsz/EE2JI90VRlk44dbUE7KIgw95sgZf1jw/NKdR76zralyiIfH9WjSqJpi4LxThz96QwKC3YzC7q1/4/d3KMh3PR5eLSi/uY58RonEiPGG3boOzi+uTpuQysdG3Qb9pwkBPqY2kQbQfbutYRjp5Yn5ke/18iQHieQ4UuowO8kaziq3oDUNGeVz1OUZ8ZmHAr7lGtShT/r0Szl4XTYjUJ4d67/+BGQlfpwG3ESPLiuxWxPWGFATJNFlOnRqVPa9nmVZZl2q5kXXN0RA5hLwyArgfQ7HR+WJ/DUgC95D+fLwOE422Q2tgQbPXSs1g2pwB6PbGn9i0BzeGhSi4focW5JTkyONPHSPs1YV1/h2YjdM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-00-11Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698559087.737969,
                        "modified": 1698559087.737969,
                        "name": "/dev/shm/incoming/2023-10-29T05:48:06.ais",
                        "sha256": "cfa0c15b5ebd4accf8846022ec6acf9e82394f161f03200a00d4f2c3fdca7a4d",
                        "size": 72662
                    }
                ],
                "mission": "OSP3",
                "sha256": "5a68f4fe0d73e0a8e9ff4556790a94e2e5cb60ab2f55ba40a32a5de270e84d88",
                "size": 16677
            },
            "errors": "",
            "filename": "2023-10-29T06-00-11Z",
            "size": 16677,
            "verified_at": 1698578367
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698559096,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "GWDF8g4qELLZV8C1lriHF3YJVhOBfPM+3FJEn4JzJEjTxZqrVv2LcqSzEP1g0gU8xENLsY7m30JWyRS3RRWY2JJaqXq87MsWdPSWc3pNFhOIN9RZZOTGA5IQNdew60R2mnEQC7W6Mn33wE+RI6tWfwugeZahmIh6Lux7pjvER4AFh0RjQpLV/YP4MU6DaKJsQosS0MgFDPtbbaCnO0Bzxb474kHtUk00TUuO78ITZAQJRd8QRAfO2e7NRD0JL9MMPXiLHiPCkYFH1lztxvPwnQ1cnQdAxRiW7422GDnPDbwOR0Djp2S/FQx8l8dDTPSSRzBvyLmkRnJ1ZWu78xBTBnvtsSqIaygPT6dS8MrHBPa36Hz9yT/Y136Zok5eXbQegxudcStsuztpRw8+Dd1KrNwfX/M6jhZ+sBmW+NHCYTq6MY0tQNLKdlV1J2iwevJ9pgYfLMoc0G8GOZHIlJbC0ZIecuyEwwvt2SjwHl3JBZ44nwiM70kSc2Kem+ZznwD4eW2hnG1KFIu832CstyDxyCBZ2CL0o1bZAk6KjyrSUUgBCoOHxH2EcYaM5lXKiWv1CA314lR4N6q2GooHhDI7jhp8oUrYpmUjH8gyMjgyrbmY0sl2RaTisqvhk9GK9DyDdnRYqKwJbPo2kM0OpBw0Y5gw2/mJY5XEseCyfWwPTqQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-57-47Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698558875.1079688,
                        "modified": 1698558875.1079688,
                        "name": "/dev/shm/incoming/2023_10_29_055410_00_03_V_009.txt",
                        "sha256": "a7140cb3888b6bed86afa4c5b7be1c9579532387575f2a77a9501991cdc69849",
                        "size": 2033
                    },
                    {
                        "created": 1698558868.497969,
                        "modified": 1698558868.497969,
                        "name": "/dev/shm/incoming/2023_10_29_055410_00_03_V_009.kml",
                        "sha256": "19df9e96c7f4a7de8242ff2c5bab5eacdaf2ab224202cc022a38321fd748746f",
                        "size": 976
                    },
                    {
                        "created": 1698558874.527969,
                        "modified": 1698558874.527969,
                        "name": "/dev/shm/incoming/2023_10_29_055410_00_03_V_009.jpg",
                        "sha256": "88083b106ca555c6c4e3bbff0c07a42e08ba921d0bc69d4674f003ddec1b14f1",
                        "size": 1190239
                    },
                    {
                        "created": 1698558866.0979688,
                        "modified": 1698558866.0979688,
                        "name": "/dev/shm/incoming/2023_10_29_050625_00_03_V_008.txt",
                        "sha256": "799e3c368898d3e9e01523da4a8a92a76b88ea3156395b81ecd3920eba97729d",
                        "size": 2036
                    },
                    {
                        "created": 1698558936.137969,
                        "modified": 1698558936.137969,
                        "name": "/dev/shm/incoming/2023-10-29T05:51:13.ts",
                        "sha256": "4fbd73b2f685c5398ef388a99e619c672f3f52acff4f7ad77cf29cbcdafe7a8e",
                        "size": 43981472
                    },
                    {
                        "created": 1698558667.767969,
                        "modified": 1698558667.767969,
                        "name": "/dev/shm/incoming/2023-10-29T05:50:47.ts",
                        "sha256": "d15c8bc4ded4c5930c0be13087dfc5d1a231dcad5ff865ed709507d53c60caca",
                        "size": 3641560
                    }
                ],
                "mission": "OSP3",
                "sha256": "a2833879e58c7c5a17ee7caf7015181e567d3e2acca75eeca9f5deeadceb382f",
                "size": 46745038
            },
            "errors": "",
            "filename": "2023-10-29T05-57-47Z",
            "size": 46745038,
            "verified_at": 1698578433
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698558777,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "caCCEFnVvHHy67JzzFwbVpvqL36+h23rOuYWoTb0OnLwIQvhsh7QAeW0yrdnsPrfag9P6O1Pp4jsLk4jyMnfdQ5hWkbxNjk2V9PmvuFIB1PdJ3H75zRRQBuejpvTA4+54YKh4KsqGq0QaALOSH+j4sLcrwd46XkJ7G0sksd5i6xwfQOvE1sD4G8nctsrwx8qc2C0Z2M+F1BzLZ1InRP9hq2mxAVK/a+pM5h6cunoJcJAU144mx7iEjl/WsxOiQX0SSCfo0QYSMiJ+lp7+rx1Yyf7G4wlPxnbl9QF1oVg7o4Su91lRxsVdN4KENuP+3B4NdL6ztQgyNxB/UX+dJJVwHNMVwhEq5swz1QXH7OzHsa40X06AIBTX46qBw2EVzuesp3TNInmAD9O43TUIS5p3z7cMdIZWFHffKFDl5ZQ8k/KpDbu4qkrlCFVqFw/YsgL9DXG7NUiNRFzGBgS7Zmn2Ttr4xhlJ4wbS/50DCyumJPfZlusOvwQDwvZjrZESeIUH0wpslgim8RB3lWYs7HijihXEcGviLytqfrHTHpw6VyFEZ/euROUiUZrKa2HUHG68kJ1I1aYQf+SLWvc7KyUahvZa4eN5shPfkL1zXkpYUKmqrsqsj61KUSi/PgDIK35LlxtNi5suMfE21RmNlHb7lzEuB4FN0udNXPbw4/phTs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-50-49Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698558641.3479688,
                        "modified": 1698558641.3479688,
                        "name": "/dev/shm/incoming/2023-10-29T05:20:40.ts",
                        "sha256": "aa96574c1077219254bcb30d4112dec4170ce0d9720ff583c6911f1e167fd445",
                        "size": 290835624
                    }
                ],
                "mission": "OSP3",
                "sha256": "83b12118262cd593dcc691edff787d897d862243bfaa06189211099fe57ed636",
                "size": 282407534
            },
            "errors": "",
            "filename": "2023-10-29T05-50-49Z",
            "size": 282407534,
            "verified_at": 1698580437
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698559666,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Y7LGDaeS2ESfpCAGYaW+z4etNlEte5r9IkNPmuZIchqTZIwNZcre8vaXec6afQJ8jAbYsH9BbZ7JxEmXywPQkO9dPviqD/pETTJ0i+GBlI02DAKi6BfUcscH4BrHYFJtQ8tosmTR46NrcSqydiXzvlXOMg6UpwHvg61Z9pnbXlgpKh5Xhd3OpzvvCpGartmr61J4myvb5mn150eO3ER7R5/QsTSqcT4GzOgzDyIxWW7Ph0VfHCb1zHnaK76gwQtd5Ge2fpH8+Oto42TCcP1HYzXca56DY13PcsP5wfTlnQlXzKbI+p+w0CYZigLgosHp4/HHLU/isxc7V/TzAELQCeDqgh6oAHo8e5BXhCE1b68/XtZhcOozFE/mhF23cG8//zkTQZNoaMVaiRP7kv2Iws0F8UPEk5JtsCVvtKWiB1BcJMxRIRTMoUO/gslOim+1Zno7/zu1pqAHZfupl5IUV/zF63uZEruxL5sskF3jP5r6BePYVZsBDZgcAa9uyjF3aCsepCBbdR5/kHc2tylsEWjjYfQkz62l+v05ewM/Ue534vgl5TmDsI0n8cgFwWHzLIJZ9Kc8rpXppXmUPJXgw4WM6kfeo9kGYl45wf7XmB2ZF5VdSS3BpFbuAOCYFOTCSXtBfwq5DjyBGaj9l+fdv/UGTiZOnZFzGNyYX9lQIlQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-06-45Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698559598.0909402,
                        "modified": 1698559598.0909402,
                        "name": "/dev/shm/incoming/2023-10-29T05:36:36.ts",
                        "sha256": "1d4870fc4e204ef65502e4162fdf40431d6a5295186bae88af9f4447d5c8be12",
                        "size": 378395684
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "41d4c2312cd67665482db49a3f6ad8c1d4a4e02bb497d288c683ff679c6f7be5",
                "size": 369034813
            },
            "errors": "",
            "filename": "2023-10-29T06-06-45Z",
            "size": 369034813,
            "verified_at": 1698577972
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698560428,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ejqLD13FXSWoWlCNCz0oV82wM8lnA6sH7JDnbBQzCMFF7PX/vA9UJzeu873TCtxLnTaPfIv/ZMh1/qmKrCbm5AvBQabwjK0Wu9ozz7zcKQ4KkDn5VvlpNLqPNfjeTUI0O0sIlfOEkXzEft8N7T1VSzuGfoLafocKWiwl9d2XwmR3csUULWdDtwYjL9fNZfXHjqiOcy2h920bbOaEb0eUPuUewGYuOoecSEaeJu4sa+FIr+DXEMcot6Gyqgnjg4dZS4hM+SaxT1i4BM92ZvU5CJ1JdbnnKlJBJp/pkTgUOnGeuUxvZuLQQPtEI67YkmGwVjeBK1jFuVfjqwl0jWrAyyRR8hf0/R8tH9dVrrNqLqPtYlDemDQ+OnEDRct2iVXHI2nY2K7ak6iwzJxJ6KMWrL7DdrR3hS2uhbFhFt1hxZsEy6/RvgUZ2awzumGTGmZivwVul5iTNRDlkSv7/ArUS9/uTCfwzP8ILFHQ6R0eSTmca9qSuYEiPGPPe8zqFtHNdjWBiWXpr/4keNrHw0cXuIRFVq40l88+9iGU5XXL4WdZgv1tyWJIZE/Ujh3MZay0VyL3hH5wqykNnrrlrdVLJmHKJE54NqiNksWz6PkKvTw7VxkMdYvzXBnzlNdNylDrBJYwQ4FG9XYxNQqA18E2WeZ4fdSAi9WbMTkIFzKOhkE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-20-19Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698560291.937969,
                        "modified": 1698560291.937969,
                        "name": "/dev/shm/incoming/2023-10-29T06:08:10.ais",
                        "sha256": "225b5b98675ccdfa3eb9fb7b90c3782dc18a332649980412046ce8a07e5b89e5",
                        "size": 71444
                    }
                ],
                "mission": "OSP3",
                "sha256": "ef66e2324bd7d782123f2e02c3bcab6f0167688ee11e4b2fc78f6654ecb37a56",
                "size": 17940
            },
            "errors": "",
            "filename": "2023-10-29T06-20-19Z",
            "size": 17940,
            "verified_at": 1698578399
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698559820,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "P/z6L4eC3Ty0Wv1MwmBHSvYLvfut8VF7YYjKmg1iuJPzQwg9+DjRXzWrO47uB/7wn5ZCt7ZPpBVDqmI1h7aMjwPr2BOly5b7Bjt0d8uyNzwtfCGYpFuWw87wCfA7eRk+ErKdd+Wce5dgGtUdOiMoz8cIkU/vp4lDoW0ri5xy6cNwxHnkIMj3MVz8xPaz+m6XZscyQIF1nMZZ0KUtJaPH2obbq+xvTfPfm52BoGSzVBoqOTlvg6BTdNEY/059Wm3IQN/z0oauKEYkWVl5SqfWIsAo9xqyrAe2XK+qy9D8e6VMnR1zz96USd5xU8eT/dprct8w4DrmKytY1pF6MxcbUbowzD/EPMpzgKa180pUCQ5Pk7/jzKGBJLTdWM7RcFS7jB50u4ywK9wj/KUqN2IB9bkTIVC36Li1Me3wJHfmVcC3CWY9pjIesD5UO8d7DkHGSNcWQxCxQ0zgAvO07/2XyP29Hm/fBteD6/n17SKWiJ5Tl0dL9ouyZLmuCetf7jOaiJE3YUt/RvMMKQ4Xkblv1Msh8NEw2DCVDXmT4JqLlefukAzd8vtjdZKGTu2HxuKP4JXNDeUVjl4eelYnQTPUQ2WjGz9Rsir3tR92ZLgIxovB+fAscx/NLn/MuXU74EBlrJ2bmHzbmd6p1PL44hOqVtz60KmKJkuMJyJO98zUqPM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-10-14Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698559690.5879688,
                        "modified": 1698559690.5879688,
                        "name": "/dev/shm/incoming/2023-10-29T05:58:07.ais",
                        "sha256": "369e6aebdd219a9ae9cb1f45eab1d72dc1f2de2e1fc6331d671af6e2e940ab0a",
                        "size": 77402
                    }
                ],
                "mission": "OSP3",
                "sha256": "4205f190bfad278586cd9f30835ff74bbfdfadb1034d35c8be505d7fb91707a3",
                "size": 18397
            },
            "errors": "",
            "filename": "2023-10-29T06-10-14Z",
            "size": 18397,
            "verified_at": 1698579744
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698560227,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Vy/ASNIFhTOGs/zhZpkK/28LXR79ZCbzSrrFu0nE4fkOdLGn4DxBYlMqfqrDFYIH99SltKiPpaM5Q0AiLU+8Q97XvtCEBRsiF9wORYiqSjeJNaf+JI2m/98nbAYfxvwsXSLUqOL72j0iYvDABeCa1Yf5zU0ML3GQWhBkxNzLNuAb1rzuTURZhbIVnM3E9WDuA5Aeyej5wsVOW3mKHT9qB/XdoHdpyp9QA5tJJWNS5uR6wDxNWqQ4+QSJcTZrlQe/iolAGZSjApTQW3+AvHFq2t8eoxI88LwiMwSljVhq6UFAm7ScphmBnlAVTjYDHOs5ME5h5BcoLsKYGF6zjBJEHHTIWAdckHmYNRvBuKkh9BaozfOAI0dWRAIyG9qr/22+CPMgSttlOd9ClnKmTnNvuRsnfXJumLnOchfr+Js6dHpV1PN76ooruSOpZaB/qSWj3k5/zmVLr68Lb/Sx6IH5FIL0ZtXtEz47ALUWbPmckRG5kOGYOKCPXEPcG7WrZtNQ7LeZNpPDEMXaQkb8i2Lx8tTzD+FftaCM89DgvfuEQFkEt4JqkpXXEod528t/9lHYYjudV1G+pd7+fn1yrHy7lK7O6BIXKL0W7F2nPCDkSS9TnjxR2RHF/rTAphdFpy9b8buzHJeMMAf+AgeSDvEada6Ks8ttaXf+7kCIE66Pwzg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-16-34Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698560067.1409402,
                        "modified": 1698560067.1409402,
                        "name": "/dev/shm/incoming/2023-10-29T06:12:47.ts",
                        "sha256": "8a231b1ea8986fbf40bb24d7a1d6e9866c48055ec9916419be6891da32cb0c87",
                        "size": 21888840
                    },
                    {
                        "created": 1698559905.97094,
                        "modified": 1698559905.97094,
                        "name": "/dev/shm/incoming/2023-10-29T06:01:42.ais",
                        "sha256": "69255d9920685f84d6875afb1d4d57fca68773a6eb8340e63e95ac5310e5baf2",
                        "size": 333143
                    },
                    {
                        "created": 1698559962.1409402,
                        "modified": 1698559962.1409402,
                        "name": "/dev/shm/incoming/2023-10-29T06:10:51.ts",
                        "sha256": "f0347e16b1befd7bb8fe078eedcfcba33f88f917b0ec03995a16bb0ef6fa35fd",
                        "size": 24546408
                    },
                    {
                        "created": 1698559845.0309403,
                        "modified": 1698559845.0309403,
                        "name": "/dev/shm/incoming/2023-10-29T06:06:43.ts",
                        "sha256": "8d9db63dbf0b005e925d492716efe5779285ce69312e64cf4d7b008e27444b27",
                        "size": 52499752
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "2fb4280c8cd803a3c28b19eb576b8d4562bc534d3eee895c429e07035c79b156",
                "size": 95476112
            },
            "errors": "",
            "filename": "2023-10-29T06-16-34Z",
            "size": 95476112,
            "verified_at": 1698579835
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698559603,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "lv9kucB/PSsZTTbkLcRIcRq4lPHxo5CHjHPR0HOJ8syRTpfotv/Y5AgbCp/lK4VxwyiuTLA+fz965qpCp1jjsvvc7QKTXvCVruQTQQdgVMIaAwysH06lIgo5BusYT3FOMGqq+rqaBdeZaSqEkzuYfxvvaX6Kn2nDzcy/M7IebyebxJ+p/pS5mPf2PCR712BvxnwLqpqiggLTscuDfE+/g41n9CdenpUi4oSukwW/hs2gaS0L9rEAy1hc8OKdpmRT49oOlFeU4OrJqC2eRZ2XMGqZaU/8TjEYBI9zQzbFxMIaixdBATNEIlenIBMQ5qNA6fb/YEJU0/yqGBDsIDNKMoXYzqYjCT0X8bLPBvZmQNmx9hXtVS1dyfMgz5IucHv5aPTmzmtLNztEbVysuoSA77aEom6l2KQEj9YFpmhm1CpfCzh6PlEX3xRK77V0fuywhVrzd08iguu4mE8Z0YgEI9/pfd3FshsjQBrdIxihnYImkoKxO/18iFT/sWscnV9gx/lrGSXaUj54sQMUEbgLNjp5RVU+mCSLilUJ5FFtTygbvfTUz3iu+iVakvqWJbdPvTUvSSXZyQBM4ayL5Bqa3zMy6ElT/0vfktEYvMIoA9DZNcZaQdKjKwLRyCdmGcOawOajLquc2A4lmmPK/0N2Q8gHEaHrjhHMSaYhGafF2II=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-06-33Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698559477.0479689,
                        "modified": 1698559477.0479689,
                        "name": "/dev/shm/incoming/2023_10_29_060359_00_03_V_012.txt",
                        "sha256": "b8331cd4b46092be77f5af8791f5b81242d0017491def7b74fa81fb75a944353",
                        "size": 2028
                    },
                    {
                        "created": 1698559471.457969,
                        "modified": 1698559471.457969,
                        "name": "/dev/shm/incoming/2023_10_29_060359_00_03_V_012.kml",
                        "sha256": "2a9f6893cfaab4572503b92dbcf66dcb83d8de353bdcc85780f0283ee6fc8fc1",
                        "size": 977
                    },
                    {
                        "created": 1698559465.247969,
                        "modified": 1698559465.247969,
                        "name": "/dev/shm/incoming/2023_10_29_060359_00_03_V_012.jpg",
                        "sha256": "2a108b4a0e0ac6f9663973e46bde52276cfc782f697c2a69b6105dba25ed705f",
                        "size": 899623
                    },
                    {
                        "created": 1698559460.7879689,
                        "modified": 1698559460.7879689,
                        "name": "/dev/shm/incoming/2023_10_29_060350_00_03_V_011.txt",
                        "sha256": "44b78dfd7454f588ee6faaba73c86abb0acf215d2c630c5cc1fc71c1ae4774b6",
                        "size": 2025
                    },
                    {
                        "created": 1698559455.0679688,
                        "modified": 1698559455.0679688,
                        "name": "/dev/shm/incoming/2023_10_29_060350_00_03_V_011.kml",
                        "sha256": "88e74a0e68e2e7dedece55b457bfc23b4fe25f0577944f84562e38c3fcafd5e3",
                        "size": 975
                    },
                    {
                        "created": 1698559448.8779688,
                        "modified": 1698559448.8779688,
                        "name": "/dev/shm/incoming/2023_10_29_060350_00_03_V_011.jpg",
                        "sha256": "ef9ccf0fa2ee1d3cac60cb52e1e50bbc9e2ba22aa0c16d71ee55cade85c26852",
                        "size": 921341
                    },
                    {
                        "created": 1698559449.147969,
                        "modified": 1698559449.147969,
                        "name": "/dev/shm/incoming/2023_10_29_060234_00_03_V_010.txt",
                        "sha256": "1cc87bf09e0e402e07da168481ecd78521784c2ae128a4bccce4e60cc0feb7b1",
                        "size": 2031
                    },
                    {
                        "created": 1698559379.2979689,
                        "modified": 1698559379.2979689,
                        "name": "/dev/shm/incoming/2023_10_29_060234_00_03_V_010.kml",
                        "sha256": "5d80a492f0c101f8e309f30a4d0721481beeb0570bae77eddfbde41bc56e437b",
                        "size": 975
                    },
                    {
                        "created": 1698559381.3579688,
                        "modified": 1698559381.3579688,
                        "name": "/dev/shm/incoming/2023_10_29_060234_00_03_V_010.jpg",
                        "sha256": "4d48e4a34368cb45acf012af7e2765a83b2a2ab044a5b0f9aa3380999ad7a26d",
                        "size": 804807
                    }
                ],
                "mission": "OSP3",
                "sha256": "0704acea7cb9608c92e13b7eb04213339d3a9a47b3240e9037d7e25c41cde116",
                "size": 2621603
            },
            "errors": "",
            "filename": "2023-10-29T06-06-33Z",
            "size": 2621603,
            "verified_at": 1698579963
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698761544,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "f4MSW/xrKHCiqadoUZTJ5GElqikzuAg/JOv+gf7IKyBS4aoknvelmYDdI5IsQX5xCZbUszmkdvOqFZ7dTPyLz+imYosEaCYWWTdeDEbxTOaXpef36/VBERKVRGjdgw7FCcM/ikOh76vFpEvGYwcnerykGpmgEYokxvhHceQqlEfixoJNJ2Ax3fg392+p8oo5XNXoKJu4VUM7btpR4DeU3h2+INtntLThLdPutiKzZSFwgBHUwzz9whaEWuS77mHIZ2yo3C/UYSSGVQawfwoeFXuiF4EAyp7FZuCsG2uSxySKW6zcwayvAF0prH5ef/ANFAd9D1pa7lEB2Z6K/Jix8s98MeS0wACwPfAyW7OC1OLWxT0liGz6vKUuXQ5j5MCmelYeP0EHQIrgYYLjXOlNmSBTV0u2KwqzXqxLPngYk/zK111Tm1myTJb2nP50wUMl20Xhdgf0SdD6qRd58gIPrfQ4tdumlFkEBVY9Uedbx89ariF22SHohlJ9FGq8b8uiIOe8ui0P1sZfoAxk+UFu86LzigFQ2bqICjqn0FgUqyIJYC4oRhwSu6NN1YdxpLVSAkczP6YxrgJ6zhXf32VV627Gfl2g0w0QxiVAtxJgKvMPW62eRtrPGuWLTK6VlrJfCXyaiYcXLj5N4HSg+Ro00coEoTm1A3IgpGelw/vrhWU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T14-12-09Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698761404.713036,
                        "modified": 1698761404.713036,
                        "name": "/dev/shm/incoming/2023-10-31T14:00:03.ais",
                        "sha256": "c7f19bc8e0b144ead94aa239c2c286d0474a767e76e333c366c174d13b9f6892",
                        "size": 3291
                    }
                ],
                "mission": "OSP2",
                "sha256": "aad55db8c4296a19d8c59b47e5afe30555c1a1c1d0c5e743f5f290f7f16f5264",
                "size": 1286
            },
            "errors": "",
            "filename": "2023-10-31T14-12-09Z",
            "size": 1286,
            "verified_at": 1698784489
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698643187,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "e5aBWtD60BoZ527IQYjJBiAoKjmXDmWHqGDzwDExn9rvt6pTYCHcj8mKgUSxqtmxgAHZt88e1Ht+g84KGdfXfBzzP5BmXiMba/TAXOQcf3h8yuizYxXwzJZJ9AfE1kqpPiXd7QUC6CntriJrwAYUAvWZpCGpGop8aRXNJG/nKJA2zbPhXGR7s8SET+o3q2TBgKQUuUSmUVlDmXm0iyUu0sIhePX+JJvHcsuW+tMLdP/DGe5P0iQbFN4fpNhYYAhFLK0hpCF1v9sgpZnGk9pjkHEl7WvzvZBOEaUqL0HMfJ9AQWp17pqGqLKOwhmWSGrA71uCXd+d9ru1mP+/nLBE2mPndNKTwsmdkdSPxR7xIfuC/ufMt8l/avIx8viU13aJWPdIyxlKhu/6fSgqwuKAYSbzyvfpVLJNRW2GHiF2Cr9xl6NipwEfVZYw8rHRISb3UyD/ci3SwvyTyLyuGgUpA9Uns7Wq76txgpwG512/L1INkKOwkpHVPWmWT1MajPkEqtDJ52qXBxqCGxdbDrbJxuuMtDhJ2JWAW/Wb/INleMu40YY2dHYk8B+8lI2JhRaP1ZBVmPBXySFvKErGdbAXWhJ61jjCk2LIwrQ08Sr+E9QVbFpl6vmyfObDH5XO4o49HWJwPX1FKQurYi3cNi0f2NJpaI7ilegAGkTa9Zohdmg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T05-19-20Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698643034.246036,
                        "modified": 1698643034.246036,
                        "name": "/dev/shm/incoming/2023-10-30T05:07:13.ais",
                        "sha256": "8ce8e7d198813c9000a572f463a7b2e4d62e800a1b6fc37dba86bee034bd081d",
                        "size": 541076
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "c2b6496bbdfde30461a8fc5437bd922f96377c2afbe0ed4fd454f56d90e0ed41",
                "size": 155239
            },
            "errors": "",
            "filename": "2023-10-30T05-19-20Z",
            "size": 155239,
            "verified_at": 1698664467
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698560610,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ogkzxZXrTQZX4Q6/yYy3MczBr0GOLvcs9ZlkUCqTopl6yvkgfHcslNPiiEG+wrNLKklgEkxUvMh9fC0/CWoAVLfy6w6my12XgTwlUeCAEGAwjV43OXhBo/HpsNoFfWMU1WPGIQeu3RER9BKDFAPbq0J+9uQFCPG3KXTQIOGzTX2yNKQc1S/Z0Ckp/y+ntsna5kj5a3pjyATyb1vyp9JbClz+sZeaR2HmYn23xPRDfDl+5N05nRtLC4B34bs2PGmS/65/7qD4jku0UeP1TFa/q6wzveth0+OyEYGBIG2KVsoMFj1CMoN3iwQQErgr2d33uj7ITmgNLd7JcJA1natw+A9fImWXCt7mV8y9KX6tQjx1hQtwMiilL4l1Qh+qh6vtz2OyXX2QtALAOmv/OohuLpULbXO/nz/uEqZcf5m/ul/wmjx2hCryaK1vmz/X5V85inbgLjDLZXGFnt07zhzlSLm4audyCyREB6sfQ2/EL9QgiTr7i0tEz5uhqhkNcZEGNXAySRwuZrm9rTy3SUcsbvvJZagLTpeixXAfniOshb4DCQhqk+8A1yIecy0nVE/vYvRyOlgJknP3Z0xHA/XIAA8mYo8LF/hxPHD6qumkBBZw5bFNCr7f33B/VpTaNdIjU7h2NPWOyyOTuZJ/ZCprLgW7PGFSg0jjVv2ulC0MYI8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-22-53Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698560513.7709403,
                        "modified": 1698560513.7709403,
                        "name": "/dev/shm/incoming/2023-10-29T06:11:45.ais",
                        "sha256": "ddb0664c8d926ac5e750fd93566b22db68567d963ee28c432f7ada173f7c175d",
                        "size": 139615
                    },
                    {
                        "created": 1698560565.1409402,
                        "modified": 1698560565.1409402,
                        "name": "/dev/shm/incoming/2023-10-29T06:14:32.ts",
                        "sha256": "9c036718d7e1c65b91fd4f29d71640804bd0344865674655cc6ddae08de97f81",
                        "size": 109643480
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "2fcbbb985fc493fb9936dda33dc893cc7b9c7b1864fb284a4396702f0b089fcf",
                "size": 105835549
            },
            "errors": "",
            "filename": "2023-10-29T06-22-53Z",
            "size": 105835549,
            "verified_at": 1698579670
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698560763,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "N7BN0tjxqLY/Ker+DHzmaJ+2DHGE9kRBy6NBaCWQHPwQAlC5ym8Syb+mzUIh9G1IETckHgYfNrIDmJM8Enyw54w1c2TM+oSG4hP7uPYb3At+/bBe4Xd9UQ3wIRKYMknIZtLV63+QjVldvtTGlNvWxKpAa1dF5EF8Nz+Bkfp5JY8j9cWt+sVRz1qFMfuNH0JGoWpaWwhY5Ze9uKxOprm9Xxn13YBs3EUf8o2gWR629oHwLKXlSQPAbRr15JPKlttvQX53co0YyH7Z7l5/UbPH+FEXae/PvchT2X66pXhNveOZN7oGjdwJIYluBS06dFgz6RW0aLHUWPvykxH5e4gPhZuqH4zWOImSZjV1uLKWIJg8mWn0oOAOw9bv8jPdpMBfsithK0tMTbE4jO0dFy3NrA5n373wNH6OFDzRfJoK1WoQ7EA7likmw0BP+jodUIKjQ1ZiwcqcmGGb6ecXPfK8LQvPS4DxJMRVmRaYHPRVWNE9N2swH/j3sPq4l6o7fOoK4ssXkta98yH8NfTwAxiQ71e5iKqRUrxn8/pMDXO3wR7nSXCR50Yp/UJIzsls9ytTgfGO21HLvsvF4kCxEtyuHLg9lMrch5L87Ia4SCfGIDemUu29ngixhlGNfCg+P5GUaH+v+LAgrSlbQT6GwAESWZ1y1pGYbRGcrt4Q8IbjLT0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-25-37Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698560608.1209402,
                        "modified": 1698560608.1209402,
                        "name": "/dev/shm/incoming/2023-10-29T06:22:50.ts",
                        "sha256": "1173f55bc7192857c2362f1a364e84598e7683d8ba6f8c38fc2ad4ef61e97378",
                        "size": 8563400
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "5155c309db1f45d6b00a22a0a35677662c8dee3961b11003e7279d69242e90e2",
                "size": 8274873
            },
            "errors": "",
            "filename": "2023-10-29T06-25-37Z",
            "size": 8274873,
            "verified_at": 1698579740
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698561494,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "o5Q5U7UBnYcpTQi+zOccx/WRAJ74w+U1qFmXEGpUaOmE9l+9tqbSx7AjErgZCHNIGG3aFYPfXRt3y7mZl5QfRgEUxgmifTkEo/F+5Fmie05zAfJxo3S8KDxdJP48ob6wNQT7ZBcIcp7ZY190dnK2BRJW1oLJ5W0mxxvVe9/gZPCMMEV2Zrd3ZWqjm30A+Idu0npRfUr/rljcyXsCA9l2N3l7E8F0/1k99bEHxjTnA3M0gM/leKMhMFf7lLXVILZCjLim7v0PCs+DEMAK61CNuIPwWpzkAPrdzokgXN9iNRnY6+YqjPDZ9gRySvLwZ37KRvzfE8ITLXMgMY+SfQBUldZIDVYkVKquR3nIZt51NcUNTXXSniiuKwrHuy4Ops8uLGlxKvz0JXUiLJ/5zkpVKq8eG5SlJr3Q9iAT+Ad5urZ4t15/au5y/YpjYSghDErcZiQ5AbYnxslFA6jMogH8PFr9ZLxN/87PObOCgNyi10Q3goTT8hRbZl9/br9oEL3i2Hul5Dnzua4hW79e76TLbT+TtC00XekQxxq5yUvUjuIMij/vLe5QAtPhJc2kbuVsi08yN98pOeUseoGzsfltAt+44K6QF4BcO5INhVk+8Ws4TTE50YDQ6JzHKvN6PWrSgvdTNrwGh/pK/SlL0xIdBtcXbXJ7G+19rMOH9P+nVlE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-37-33Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698561447.3309402,
                        "modified": 1698561447.3309402,
                        "name": "/dev/shm/incoming/2023-10-29T06:23:33.ts",
                        "sha256": "368abe96ffc9f9d7602a7232e812ad4946094bf7fbae7ebdd95785cd2598e8ab",
                        "size": 187292180
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "037d961c655d32b0c73326a04c6911927449a72719b74b607ea3cb410d916d46",
                "size": 180566533
            },
            "errors": "",
            "filename": "2023-10-29T06-37-33Z",
            "size": 180566533,
            "verified_at": 1698579314
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698561629,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "KtBvcTz9emDwajBIeHrgYsNaFRNyZU8kZkbjZn6hFn0bAB0BXb+WHJ1sQXzmvAFV9NRSxs6CVi7SVPnNR3U7e3cl/3ifEP1Lz9G9lHp3gCJTf31LrkgRCIQUbVFxTr7CNIJm4AxZhZv32f+UO5B52y1d7eDaByN4wDfo+NLfenMu1ByKURuIxt0DcmBy7W904AZSLSHwMEpnvM4zaF7Cg0E7gJg409WnEDre7gUkvA4ucuDtJ1bjQ2qUygDHNpUDqe9lbW5iTrpsWSu/29pHxuxltXtvvRZawWANpDriNRnOFeFUu5pe88zdM2wRh9kzpnYS245OLy2cIuQT4oB7NLxqiJE1WSiAIsIRsFyClanxHr4OAjpptaTBwDKzu4KIPza4q3qO6y43PNLn+OV+PEVJqkz5GNUBUMSTm5hPOlDTXxRL43HV62oqPtVHTuHSisvOP0rH0iG6G6buVDarfnLezc3F0XKOmnmnRG+gXmjBETdm/tdNRc4Pxn1JtnIv9Br2mVle9Yb10vW40TcnUMrsaY8grpsKH7Oy1fgpv7ZqCIFuSKxVr3FyTAZZqHaf9kGR2sRlsceKAo+PZwHrRmwuFYF5/F8y0eoNbwDmADvQdLTXZgvMqUJcM2h7N+GL7MBw/Htf0btInlkYPkz+6LnGUZ1GLGbjdJJpurEfIqA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-40-21Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698561493.1279688,
                        "modified": 1698561493.1279688,
                        "name": "/dev/shm/incoming/2023-10-29T06:28:12.ais",
                        "sha256": "0bf2026ba380b0dece1aac788104e264a52ed629f27aa001a30c151b6637cf6e",
                        "size": 59581
                    }
                ],
                "mission": "OSP3",
                "sha256": "c15b79dbb6121d14a79ee420902d0c56a7b2c77df8acd07f72f7824f61a328d6",
                "size": 16898
            },
            "errors": "",
            "filename": "2023-10-29T06-40-21Z",
            "size": 16898,
            "verified_at": 1698579768
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698757331,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "DlG3M7U957zqcy5z4C/ing4GKu9ub0HH7IdShAurZ0gi8WcpcmmNBildjJXCu0TrmS8DrifDbhvVB8Q2YwSwumwRrk9QJXopKN5vHMOxjTvxgL9DTw7IYD1KJn2XoIm+3vXFlhKIrZGOHUSjFzW4K9rYh3izuy3WiHHfYpH5vsKQsY/Xm2z2sJRBKASTz15VR+fIv4uWUbE70TCt/0wtNFIasD+RUHBTyIE8amXRkkzAi62khMwV2u0WZXwwpfToA4AatJidfGbitW6TAL0qh/LHPo1T1UP/TKU574+bkQ6vZ6PoWhoHVt6/MtmKpoUwE5KcKGRHSJp2LEUljBdlpuf/eXT7i7Mr8on1dhkxcVVmgqb/0iRRhxedYgYUn5CgKaEtLh7DKnT+pwlCF34trKPTlrA0tp6usOmmk+ZUPV9RGcRwPUitqcQhqYJCHo1gLKVprkRXk3NcvBEszNpImJnxfzGlNs0mJaT2SAjeijEzVXiSkUq2EyBONYsDykag7zDBcHPpm00xnL6kiOq2Bb0Brh9jeza+Qs0sGk/zr8ESeMIQqtnhPbHxJ2YP7FGLvUf10OCJgZCn7/OlsxTsI8Roh13bXJLd4qRFCLM9ARCUm3WKy0Kxlz+ff/IzGJxL61QnqU9T1UWc5NejOE3RnP387Drc8gsWRBNKK/P/ydY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T13-01-57Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698757190.8514185,
                        "modified": 1698757190.8514185,
                        "name": "/dev/shm/incoming/2023_10_31_125935_00_01_V_079.txt",
                        "sha256": "8ef0eb2406ca3cb06296db86030d3341405fb301b80ecc104554b4d2f5ad356c",
                        "size": 2041
                    },
                    {
                        "created": 1698757190.5714185,
                        "modified": 1698757190.5714185,
                        "name": "/dev/shm/incoming/2023_10_31_125935_00_01_V_079.kml",
                        "sha256": "9b2aa7e8eca2263bbef810abf65f2c1299340f69c8c96cbf1c044c3c96b4e0fe",
                        "size": 952
                    },
                    {
                        "created": 1698757190.4114184,
                        "modified": 1698757190.4114184,
                        "name": "/dev/shm/incoming/2023_10_31_125935_00_01_V_079.jpg",
                        "sha256": "9270e8313976782e4bf34efbc75031f9f0e86ef3bd65d62374dc4c9347b7b01e",
                        "size": 1909377
                    },
                    {
                        "created": 1698757167.3014185,
                        "modified": 1698757167.3014185,
                        "name": "/dev/shm/incoming/2023-10-31T12:49:26.ais",
                        "sha256": "f220f0ede507e56c01d22f8f32fd1d9b30465ecd76442cd112e0ba5f2c934ad4",
                        "size": 241961
                    }
                ],
                "mission": "OSP1",
                "sha256": "5f1a754488ab8423c006ceb607835c63cd297ff76cb70c627c5b74779ab1f122",
                "size": 1968472
            },
            "errors": "",
            "filename": "2023-10-31T13-01-57Z",
            "size": 1968472,
            "verified_at": 1698766239
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698762153,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "kIslW2wDQUlbzLzDNrc9BRKY9CUGLBD9VOVzebVsEDW7gKDwSPdOSxRkqgiE5HplsZkk5dFtumzOEvELVPdehE0g6vl2NZbLMU1QEvZgIJQ/Z7qlisYh49qxPWWkdRCI9IXlDULt7Y1XwiSBAxnC3pJJweUvxQfYnBnokzc37Eo2TM9wcDTPRbuF2X9P9UY+9saN5pWU4hdcYAzBHxO5Bze3t2F61XbJTBxnvfOQkNqVnrfiKAMY+ip9bnZuPbES/8piNwh4OamyII/ClljBwJN9IkGDdC2F4ZxurtNFZiHE546KDs5u0t9+1dCsK63o9s1F/k3tSxZzFVFd5aU5rctNbAitahvpwDZ+LE1sp5+k+V/6+tNjuaVbj+fT0OD0Za88r6HOGEEKPa8H8o/Iqos9W9ag3gvx/o4398ZMorhaIeR0ZMybg5LGes72LLl2rnksuvQti/5znn28W9ggMyuNI3NjA4SkEId1hTniqu4xgSItoSg1PpKWUVOrEOfjo2+qiAOfEN6aKZe8j3fm1hnNsSvqbbUg4YUjXd160Q4+Pm0kamfmguGb5RfkT1cRyJSh7rjffQF2ye8D4J9Jtwh0cmp2/HpKRIpmxbskQiSGQ1R/TaT77ZepGStLOX90Cwv6jPRJ4OgKtE2qpVeRkgOP/UsnZXIHdj55bTLTRZc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T14-22-19Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698762012.1030362,
                        "modified": 1698762012.1030362,
                        "name": "/dev/shm/incoming/2023-10-31T14:10:04.ais",
                        "sha256": "35c267d06caf7d359bc3a423eb27f46635c6c38c58ed95fc55c0b0784a350e3d",
                        "size": 10184
                    }
                ],
                "mission": "OSP2",
                "sha256": "99a1e2af762fc29f00b1210093230bfd438bf9287f1a928f9e90aa106afda8bf",
                "size": 3191
            },
            "errors": "",
            "filename": "2023-10-31T14-22-19Z",
            "size": 3191,
            "verified_at": 1698783371
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698643421,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "aUg4ysrpJ0AaNTQKzNiiOuzDo4CUkp1lRYiLVUQp1d4XvT1znNZIgsrhBRF0DuiI3IEs467AetF4r86umOoCVIYQ0XCrfo0Rklj/kttAkb9tqS20kRQxAJuWmSEsIKYhaw6ZOcWonDmt2x2e1e5wcyMU8k7LDqJCuNESGEMRSWHFRnwrayuP4A577oqBFUYc+3uBz1MR5oJA1MRZiM9HDXw6FSeB8G8hnYLYcgabqdFfHWmwFNmbytO25fgCsSMiyXc+QG+d9O+iCZaGUAOJqEGqrbqIlvILeAdMCR7AtJ74hsHtLmJykE5KxT10AAo+6277iu/OCktBeSgTsWtXDMFeTtclwl/6Bq+7eiP6V0x9yxkWfq0pqyswJ8SA1UVVdsAnDCpWGt0uV6LEu18KnnDF/kFnruZqaa0WIOeZOWfWx+WqQKSFGRoFY28i4/bUaWMQlIZ97SLpF9Nn+n1eXz9szMQ8I20F7g6KTILQwG9RYsMVo62GUDfceqysrlqgScidu5bMBclauyCLbYlnlLd6ESdHdnhXLZLeyuXhKooYqj1dQxTBm2OumaBsCoxSt/ESwyAxLO1emmk7eL2OXT5+robuCefVGmpFjKtZwZKINhwjvBP6R1aPgh6N9dfqRCCjjb0Bn0Fp/UNOiqJ62Msra3HDiPFNKl6ZYW0PTPQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T05-23-12Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698643266.446036,
                        "modified": 1698643266.446036,
                        "name": "/dev/shm/incoming/2023-10-30T05:16:57.ts",
                        "sha256": "f19c75564c801732b1af9074f1e4949d9886421d88e48eccf3b97fb3fd608600",
                        "size": 53049088
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "16f96646170e1773b6398a3967e7243e47b2645c3985b3c23f16557bb687b9ae",
                "size": 51159494
            },
            "errors": "",
            "filename": "2023-10-30T05-23-12Z",
            "size": 51159494,
            "verified_at": 1698664635
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698561873,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "UcQe52K/lZOY29CCECcFm+3Z5CMw8YpFdX7VHTHN/iFfmzhKuY5JNiXoQ7u47k/K3gwSLwH0v2POL23XkwJgDnBSWEUREzPETDJgobtfqW1yJhdlqTl9XAxVno8YFtoqYRdvSrDFPehxaVlHITDYU/NKwOeBSoKFmNMFg3NCfv7Z9U1UyQKGRXNIJDpYG+myDXniR03ERT8HxXBJpVYzGgvDrF+lnM4FOSCvAjTbsZeEQsZwbIWKXhjh4ZlzBpBKHiZlEl4QOr31pj+970ET+5FiG8+vXIKsncM4FP39JddhSznRfZg4oL/rLjhYok9sBH6p8tVEoRBJmNpk/Tfo8IL3WGuJO3MmINfu7RzNqDa33qfgXwutj7MgEHPsSI+LuWS4m+8inrsbgCU7DSAKQtMibK3DYpW8491Y2n40MsGrwj+4Cx3pRAI2OcuGXId/pUlqrFOD53l/vIYzOKwCk+tKHPxKjxmTFZQOJd2y7jJEYievat6MwVUIb4qMG8u47sWROrwzTPS2bEhAzpiFLE6JvjyNomvRdumJejHL4o1DX9TVVuVimmAdw/EGoXjkKUSFmDxZjybG8qRd24SETIa2GBKXzFu7/NjK3OSMTwoBCKPMnpKpt4E6i1UrWdFJ2PpGTv2PaQG9IfVLLrzKYVBl6KYnWrZeY2hiYKw9PdA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-44-10Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698561726.72094,
                        "modified": 1698561726.72094,
                        "name": "/dev/shm/incoming/2023-10-29T06:31:58.ais",
                        "sha256": "2db83be963afc7e560a959646cb613006ae614c0f6a158ed30ffbd93f6d9d5da",
                        "size": 143566
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "68d9fdc748a4decc16cb2a8759c2d42dca22ad7932a4d121d42cb9d8d602138a",
                "size": 40512
            },
            "errors": "",
            "filename": "2023-10-29T06-44-10Z",
            "size": 40512,
            "verified_at": 1698578587
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698561728,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "W7e/+D0nZ9IuEAg9rcsfV3MGyKkYrN3hc9qvllbpQGuC6Iwic81D1U+puElLhD5O8xcA9u6sNRWI4bSq0GUt+/AhKjVL5KOx5bcrLj/EEiEq3+dmM0Tz+acISyOOTZXJqNQg99teFRfWHOE1EG6ByYB7juKMSpqHAlDMkSUm+c882Xx1HMTrcEhqpFPH4SyEDRbPVHk9IjoGTHNYxqFD6cFWVK+N+ocOgp6l/O0zQtgb5BaITLeHKSeqTa20fRlRp9wzstiLEn+rJKnV/k3RgZ11bX29jE8HMyGAz506LCu3LxfkxSkFtj0RKnNY95yvJHl5J6vYpby3Gh7kI2dYRNFZGmho22ALA7yQ2XhwExqBAnyF00I4bUzCNJQu7zIY6NrtGHsbcUIZ4eflUNcffHxVUXE09sAIUvqHvdo8+h/lvsWNDJ+wgEDtwhMBLbaKOLZvVRhD5Sz8WeJFzaP7/pZ53sRsBtTACyRvhD7xg7NPrN4slc+IUwiIiTJ667CjnSn7R74D07nOTertAttW8rLGuFOcBMf9KjRSCOe9I0XJuRoxgXZfsoOZZSaG6Vj/hTDjkkwu59LHuy4fYtgHjVKTwOQ4p/RjMh4OYq1X0nB1kEiXHaGPJ8JmtRz7HIOfcmsrbQU5yQN6BOF8TNUdAkJdbWOaCLtJY4SAkwGmoYg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-40-54Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698561645.0479689,
                        "modified": 1698561645.0479689,
                        "name": "/dev/shm/incoming/2023-10-29T06:27:44.ts",
                        "sha256": "41e2357f064d820aa96975151a4dd579d114862cbc8b1278afe6441cfe637c1f",
                        "size": 158612404
                    }
                ],
                "mission": "OSP3",
                "sha256": "94a7968e6cc0da23fc3b8b7a7cdb957785a2efdbc0959a30fbbdb5c2ad8755d8",
                "size": 152475383
            },
            "errors": "",
            "filename": "2023-10-29T06-40-54Z",
            "size": 152475383,
            "verified_at": 1698579619
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698582828,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "O1nOPcl97oqVsRZ7Wpm1AmdMtDCR0Z70hJPoJy6b7LUZZp7BAoaVNdPwl9sgyzALCAOWUXtXHU6N909UilY6+WAk2mB7bg1sLLn8IKJpZwX9t5CbeSkDIiC7ptR2iutYZlEJPPmVkHw/thSy4hb41BQk+EVztmAJtxzwFMvpseAPGaHX2yTi0Yj+J2klLk8PK8Ke2FFW1YxVk+9S4tv8QaWfS6T2t81c+B2r2ZNHJ/xmAHTAXTH//5h00VViZVjhUAwHYTVfPvsosGP6+FS97huBsA+G9S7lFE0oewuaU6ezZJ5gv2wOO4yX2zjhb4F/tI5qJa2vEKynCzva93rIt47MQXZx1jbenhx4O5Z0+b76QcYBeYUcbMwrfdbOnj6xEE7oQMz2KN9UfcmqIVd1Y0f7pD338MECarjWXhyZjTFW0zytkcuNxJVOFijWj4nRoQu2EVjiOK8jt9AgwSW6gmEdLy5w5TgvwSz7SBvFBkMdfFqXa4Kwiag4jCxMBOt8OU0zQ7VXCuJ/vA8Pb1l/TlLF3JYtGDssa1SnG+jZ/EAI6O6h+Bv17RmS1NkqrcdO2ug0D7nEL0RuPw/vJhx3MiaxjIMKnh/dDIdPrE2gd4vbb/eXfIyURyVupwlevo8i2kJUWFu6nbMYiQmChVM5UqLEd09zh+nBqFjCXojYsjw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T12-32-42Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698582634.353234,
                        "modified": 1698582634.353234,
                        "name": "/dev/shm/incoming/2023-10-29T12:20:33.ais",
                        "sha256": "81e74315b31b05f497547ca070d0f1557e5c013b1492a7139ba2e37a7c31b91d",
                        "size": 426140
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "028197369a0d66797fe1e2b3b34ab89cb73aefdbde4db90df6b16307c10f3fea",
                "size": 127713
            },
            "errors": "",
            "filename": "2023-10-29T12-32-42Z",
            "size": 127713,
            "verified_at": 1698596780
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698561955,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "DRKRRhtd1ojqLpIZB+IUXoZJbL1iFV/Wkv61NS1ozt5NAZN9Huwk8AD9msIr5ZzmL/hCRzIsyC/3T4/T68rlXLXZvHm6VlNbVctsfkhU8Los4Uj69aZn9xbENVFaygd50+LaGMuP+kGoiK9pZEwXv1chRzcu4+6WLvF+lcN4MPX44pbhN13CkmBHiJcuPHUhbwGeAQZk1qOrriblkWa6Oep7zxHWTwMGK09Q7+4dTUyReLBdxm2bjaTffVBi1IoHtnkhKjgTWP2trCXPW7TN2L2M0wbQxTsT2RODSoGNCX3OGNCadwYOXLXLHt42/h1RC55BYM6GoOR/JG/G6pIA6puwBWXKHRggQ0r8K85Bptol+0BmlkQolBbpzYw1c6GJ2nlAM9GFIKwNHJU00upVUfb5LMLW3oIvAiEvbwCISekkdTq3umZMe+BW3cjWrwqx3+bjSSPxsvbfWIxcK+WpP2kYAJkKUOeCKX2RnYGyEaQ3tW4QcNoW7fLj6If3r/kHLTL1tcQE8PBrHpARdBNUxJPfnKTm+EqS1cDWAFrqLAS2N+taHuMbFoHyRVdj+H2Z2X5+igpQQJuYZ1U+sygYpJL3Q+6qNAdcPh95yFwHw+NnkuwIeH7dlsSZHPBpiOmWd2a+EtT+qby5G+NQvh7/U9bE1LUM/nQdxaELl2URe1E=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-45-33Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698561803.417969,
                        "modified": 1698561803.417969,
                        "name": "/dev/shm/incoming/2023-10-29T06:41:14.ts",
                        "sha256": "889281fd4890aa13d2cd29d467a2b71fc26770b4aa0d4c3764147bd948cf5a92",
                        "size": 25785140
                    },
                    {
                        "created": 1698561668.417969,
                        "modified": 1698561668.417969,
                        "name": "/dev/shm/incoming/2023-10-29T06:40:51.ts",
                        "sha256": "f22abc5b053ec111fbd04240c94f91aabb5c30383ed1ebc503716611f1c264f1",
                        "size": 3824860
                    }
                ],
                "mission": "OSP3",
                "sha256": "b99910a04c11a78f1edd6fe26d7a4adb955edff7e63b6bbb7bead9612998b5fa",
                "size": 28411139
            },
            "errors": "",
            "filename": "2023-10-29T06-45-33Z",
            "size": 28411139,
            "verified_at": 1698579460
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698762788,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "DNnC7+4lgz6JQFZk0OZ+gB7+kKMust8qM98Ikoy09JS0g8dYb3dkqyxN/SLX9FOoPzPhfan+wJIYE8UwI8Ml4FPQ7OMlV+YZxUXkDsabo/RQMjC4bhKD+SVCIOhh1gDShmXyldtrKWwFdXQXGue5l78oEr5WTPKeWvU5/KmNGEF4kOgqr0Wokwd3G6XOwUmrrxQUZDKYAFu/TMxV0CLPCy6jLL9FQ1SttCMQc+EnU/TjjbhOItm7NN4EzauX/B02NEVHtKK9pF9A1UkLlznW4Rpcb/XQa/ykg+xxKBbGHWqzmNOPtc8lkGEO93pmg0fBKr2tPuMQ4ujm5j5RFWcC2t/YsXXOBcuSgzNXMO4dR5V3B2ezKXTGy55+wmkdJnEyjdRiBxYAQbC7wX7fW9fyl1fjfr/X0691kand3jYwU59GZCMptkBMkAThuA2gg2EV1jrXhCom2jDosHVtQTUq/rSVnYdLA6oNMEl2Mbo+TFSqeQMkWz5P9b2h3UEf5Pc/6WbFUgHeBJOf3waZK3RNIBhy34zMg5Cewx4QOfwfvi2eQ7pn2amGEJFM4mQQ6PG447WiWULISosLsmuGCmdrdVAXeJur2OzZs8CWE2potx+/iX2/YYUUE5IEsYXhrsVg+5GhprBhXxdTq+QmC8Uc+T887z4LjKYegaTpFLChAE0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T14-32-53Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698762645.923036,
                        "modified": 1698762645.923036,
                        "name": "/dev/shm/incoming/2023-10-31T14:20:12.ais",
                        "sha256": "0554c1d27320cd4623186641bb7e15b0f787ee0899166b61d1489db2b99608ef",
                        "size": 9892
                    }
                ],
                "mission": "OSP2",
                "sha256": "99b9f0798bdcca2dca5d054d456ce37f89938e520b35453d8e679fd8a6723e8f",
                "size": 2747
            },
            "errors": "",
            "filename": "2023-10-31T14-32-53Z",
            "size": 2747,
            "verified_at": 1698783365
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698757574,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "tIjgKx9YjWxy9LJ+vR11iSuA9fmGD5fSDA2J5bhH88x5pVT+VIxLIvADpgDfOnS5FLB8FvS/7EswDsZGaFnDcOdnUOPSvoxPnyfuNF9T5akTWbaXJ9j0wugtvxgAvDkPMtEVgE7TSUn68oZYWjmjuGfhWHX7rJZVfsZIYXYN1s6HVCLICeKG22sUcdxZPvv4njEP+eQ1MzOhADHTZHQsu47H2/W1VfZKJSIIiRXm9pctuFezm2gLzeP9SnKTjkrPBGlsRHw79DGJ7tIRJPcKmN4cOs+ZfB2WyZu9eDX9oaAwiPatyPlGWpcEEXRyNAhETAy9CYTHLxTWXA6F2biVySmHBqT1aAH92jL4/sV/+DBB5XfgTZKpboCHB45FfxtzxBs6DEsdinANnJxcJYXSzskLiRw2DypcS1LNJMG/ZFG18YUWhjqz2uyQHghl0UZA1NI+eHoxldI6SxpU+Ufb339muTCOSa2KJbkpZArc3SRmj86SGADJd+spaBQbfb1uPCf+IUX96MolK9Frqauu3Ek/Ec/dPyuZjdqYjRfD5Nw+o4nhcjUoyJezzTzSAkHrF6kUHHfY65aSB8mtwP4qSS/rmjjSplucZjuy42N8unqJPb+gRXRAMJ1siQUbkJ/Npej0363TaqNkp/kJmBfqOnVmannzFRzGrC98y2QHuXU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T13-06-03Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698757437.874362,
                        "modified": 1698757437.874362,
                        "name": "/dev/shm/incoming/2023-10-31T12:53:56.ais",
                        "sha256": "288d8d260feee879facb6287bec5bfffa6a9a5b58d6c0ebd8040b42828722988",
                        "size": 74038
                    }
                ],
                "mission": "EFCA1",
                "sha256": "3f42a7b117f89eabdbf4b91108cea504e9a068dd85e4bf932d88da9e1b3b08f9",
                "size": 18170
            },
            "errors": "",
            "filename": "2023-10-31T13-06-03Z",
            "size": 18170,
            "verified_at": 1698781595
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698562447,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ZIb3sndCcEZ0Fe49mvsMB5fn1uA1mKsT/G5gS7TBPc4Vlp97R10IgyDc1yc+iI+J3zSJKQnTv11kAW3yWjKat9yyuOs8TfnbzyibECXMAmXXSrseC/wdpXxuvt2gzZwkXCpS5m0NJ+VJJId2xD+A8zdLOVKBSkOaHhCN6Ii/rktpxUjE6aQPPD3fOxZOy5Z7CaQmAFDTm/rAMoVk7AIQFjSB+m9s2NaB9mgC2HMdkrPWId6+cdWuD7w4MMSyUfOVTgiNlcbNB0irtQ1Re3XttBkL901vLoIso/nwY0tgA4sWYJXl7F96PtvM3dtr/2Zz/+5QgxY50UuxwRTaWZU5sNEJn95pf579oGWwmVZIiS7Do5YMgzEqyfh7ADxs9HoxOiMquGUOneuDiXgE/J+wZrHY1Uhnzmepnk3le10Itk7alV7SHY0QcMZVHNCyuTnR+pnzJuH5SW0+DPFf5gxgLJDNXFdpAFsgFfebjYL60dGl+5W6PYEt06Koe1lE/oVeyVkWQbawK3rn+4s3yBpquevVdP3D/kfbQizGU6+2nnvmbUB05q3Wf5FRYsLa++bUUL+58Zbaz3ZjBe+F+2Z8hwMij4nVgMEVkg2fQHErDYO7gMtbQ3OBsycm/mn8uQkiqiKFf8fqCgMA7OVrIir5G8bsXI8AM5lRTDA2nT59DfA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-53-43Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698562293.637969,
                        "modified": 1698562293.637969,
                        "name": "/dev/shm/incoming/2023-10-29T06:47:40.ts",
                        "sha256": "d9a332e8fa8d06f8770a963b3703664cbbf53fbba94665565e10da8e32d8f634",
                        "size": 45295216
                    }
                ],
                "mission": "OSP3",
                "sha256": "0e0943896be966817d2d2c5257dcf79bb6a72410a09e1aafa2936c82e7e3adcb",
                "size": 43506020
            },
            "errors": "",
            "filename": "2023-10-29T06-53-43Z",
            "size": 43506020,
            "verified_at": 1698578537
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698562430,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "AehKS/NdVO+PJ1lJVN6cAQWM7oORNRdDFZXw/HQf0deGcbG8YsGAojbGyP9UCXoDsHkZ0x78g12xD7Q1kRpuJs3/dUXoOjw8qQcM5wkug4dxqMD59FnKWCvykxxAdFYbdZTauB2Y2C0KWdKK6ih8TBKYFd89bclwiVL+vt5SBoShNHRn+oj+jvn0LhWiIMku0HfFOiry1pqwkPsp//VHjFDLClrXbdB05OOvqaukKl1UHFrxkn1NeIlHLD3I/QaGLr2hr/bCwfHnHwbzBJmhjYfN0lta8lkulHLyOZonITRsCS6cYYtBSVL69ID+PqPAENMD00q0jmeTnB0mMn+Kn2+OSEjw81JpDDjFGaV5ndjV2J605xr02KARgn6e8cEMFiPTFIYzPjUODBdgKVWEcFd+RNM+2BepBxzQsppcSorYXYcC8bmSGA0Jj6pg5tylKPl8cqysXjdi3AhTnkO37v9/8U0R1YIjkfl2WO23KUYtnkTHQOfCIdehbBRifLUcwh3jpD5PszZGabMY3iIvwDTWDnCFOwoB27R1hjuzIFRQWY27e1gK5CYkoUSVEqrA39PD+15DUBgzFe8/7x6Xmlfssa17mcHeNikzAgoCOmGG3Rv0PFBGI0FbVlI7Uqb8mIo9r47+2tqH9sXIA5boSOCnYrq7kPPscNQhxxSb6Oc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-51-07Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698562144.0132341,
                        "modified": 1698562144.0132341,
                        "name": "/dev/shm/incoming/2023-10-29T06:38:59.ais",
                        "sha256": "3690dab4e0289e5bc70e77b722d1dafbeb4d044c7e522b4fb2dc111674ce2313",
                        "size": 10386
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "72fef85068fa6dbe4908f23413a5d8ea31f9002ca615c9801716de7f01750e39",
                "size": 2055
            },
            "errors": "",
            "filename": "2023-10-29T06-51-07Z",
            "size": 2055,
            "verified_at": 1698594828
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698757592,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "EquHCxBMtvkh3sQl+hRx58C0ipXHNwlhZ+wbbFQUOuSRph2LOJ8w4skdDprQhCnD+v2nYuTX61FPMpxzRXIZUcU0KZ02ZLJpVXVtxkXFKfWLx0pkEMFHDAOg3gtoigGFCphqNaZpEnKcOFdufE+k6YmvtQJ/sNBDCk226UhNoJ8ygKWcx6CWwMDkysV50d8Ah3HcwK+sRYtZG1mJvQK2cs6Jz7jt15HH+wqdf9drU87KObWcAN9q0oNL2WxzN78auSGmUdUasNX7CIfySUGJgsRrMz5QumkriKdgiQhuXx90IWzuLq24BjNAxTJrqlVg4qLPg3NuMqJQYqDVjz1eL9WI8InhA7vU1OJBuvAWD/KW6AkM6X6vmwB0mv5dsTI2Wrn5ARoGDLy2vtz5te9BWB75hSnqlfYt21LvM50bvOM+QgBEyVfUF5ex+4/EkBpXKKa/stUFrVVRsDzCLOanwkNM8mjA5qb4hvAQWvLc4B+QN6ykiVZaZ5VgRUQmAxoXwGx1AeleXGn7nNRgGl82lO2t/gQI9frPPIXa90cT8K+NAZIR6ZyjLfIZ42RagM4GdGAdh7m8kV2uPUOh2IBZBVXCagX40usuJ0hlsESC5ixq1buJYGD+Q/VmMSrTSxgCZWly6oc+vbSWyvhJgCcwFPS8XCRqHV8G7dWCNoQ+ugQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T13-06-29Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698757460.7997909,
                        "modified": 1698757460.7997909,
                        "name": "/dev/shm/incoming/2023-10-31T12:54:18.ais",
                        "sha256": "384f35730f76c45c771d7530e086bd940033dfde0bf48e31903dd97cb19ee024",
                        "size": 121060
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "338a829ae1ebca5632045317c903061dae659b0836847cc935a3c8a9fe469481",
                "size": 30726
            },
            "errors": "",
            "filename": "2023-10-31T13-06-29Z",
            "size": 30726,
            "verified_at": null
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698562830,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "b7qtZrM295Co9nWxIQttQz7CHCfQidjJ34xNDQ2O2qvpWvPLLa4T9Y0+XsWz+r0YfEikli7+usqLyzadGsqpKoHpzIVnGw4C4w3uQhUPAvRjbdIjGEJO+L7/dXJAkkrSHBtygwtgTc1294Ewr2PCzWCRaBAhIzgzJRSf1KSoLqZuwFwbn1rcn1uGP0k4Z5yzoI3MjVqU3V8ixl8rrpI6TIgS+qlegXLzwnQ7Twzbfi/Nzzby0pa9y95VBqEKtwGnURKZwbLqLypbEF48BcTDxME4Wx3/Jgjp9jdRvYynB5qQ0/vvJqbY41qcujj44KyLOD/D50+gAwGDalBPkUnT4fMvdWEBSjW1iAOLi9naq242Ebfiqi/kvhoPLjPAVES27npYiZNwpNAG3XaHyj9DfsRz83jrid4080ruM/H7bxfaP/K+sD4gPVIVkSB0/nvvKFORHEDhwYFl/M7Khc3jrgb+gEm3iUMW4/UtEz72i3huHAzo1Qtm4kLJkbsL6t/xCJZVQZ/k8CMvNvtcixrWkryBkFGdTWNa83a2XlWA7wUiE+8xe6Gdtrdb2zabgeD3hEMEGhD1w0d1N8bwCqxOJQOj3+lXe7ICSr9RA+x8lUJmpMhbir/R/bei+MbEqKqlEFirVh98Rh9BhQ76M7BlTAteWbv+uEmzPtZ5rqz7vXc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-00-21Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698562695.147969,
                        "modified": 1698562695.147969,
                        "name": "/dev/shm/incoming/2023-10-29T06:48:14.ais",
                        "sha256": "1fda749b9a309eebf2bbb4ee11828b43c076bcf3fd0a4c9a2616e29ad2000986",
                        "size": 73151
                    }
                ],
                "mission": "OSP3",
                "sha256": "3d4fbdbfaca11cd6288915b05356bce27fe5b768e9da508cea86ecea3b28d617",
                "size": 18300
            },
            "errors": "",
            "filename": "2023-10-29T07-00-21Z",
            "size": 18300,
            "verified_at": 1698579546
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698562891,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "nfrhY3V/hGaI0wRVqngOqTd3r4c15Xxd4/OIhCaC4WxUsIWU9DF98wA5/0MRe4EAtiiTLlEMc9QcSB8XzhFJL6IKWh72MqlBMWhP8zYhHrXNib6kYzP3euFhLg/DaI1BRvNDhadNyQ9JJvRJKj7TIPm7SYJLYPPWXZ9RXxHQzC8BGmYeBnUDuDR25zOmxmjsAu32gwuZMlNThaknr1HuVnK7qrUlXoSHlNH+xVhmbx0HgFkid7FHLZ6cUlKCD5JBuRfZNrrSUXnFlrCk2TSiG5OTmagaqJ3ayn3zYVHkTHIl6E0K5MomFJ22jsTygrq1HsB298dDV72Y1OoPiXWVF6/p43+6/5aZstMYoTcm43CULjjXn9WMQkJz/013HRz0fj53YwLS6h3xLEuBwB/nBemFGM6dDwmjNlh7LFI+ATgxqq3a4Jqg+0fk2K612KxYYsA2wGzU/x/bSwqjkJq3ZmZ+czmcfT4sFkjL8konlg1buzZFDuEUt1xVe8wg6a/RZFOOvltpmJjjoA0xE8ZcugKMzhivjjNvdllyD7CZlBg03CvPW19kNcRkfteEKCTqjeV6oeiTYpdCPzwzqY5TY0BuhWJIZ5E0oH3rYx52szsK1kY0aNmi0ecIbIqbsHaiWxYr1II/dfzvXPslCFMSY3udt8w6kzI6/SvMljL9bIY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-01-11Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698562745.4632342,
                        "modified": 1698562745.4632342,
                        "name": "/dev/shm/incoming/2023-10-29T06:49:04.ais",
                        "sha256": "1c2d519320536b9d173876815210777ea5ff55502307625808ee68886070b456",
                        "size": 70732
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "f1ed387586f3184624fd7417396c6779e12fada44556336dadfeb85fc1ac073f",
                "size": 19755
            },
            "errors": "",
            "filename": "2023-10-29T07-01-11Z",
            "size": 19755,
            "verified_at": 1698594822
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698562944,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "hIphArtAG1wcedkGvy/AfyLCPQzOlVoRDvMoT4c/Cf2hOYkQXO/Zfb0nUCZyKuhXBXCN3Fc18cFN4ARL86KNZy7t2ORibiNc0a+Vxq/vRqJHkK5/lMziIMgc6UcK+RH5Ph6OTSh5uIrzKSc9Fanz+AiLYmSvm44vk5G53wWPTlvLgS3SU/obLdTTbpaAhH+Xn2ZWSQCveVFqIyQpvB11+5cU1WLXHmRXzuY9E+eRcUx7tjkNfGRFKJZkbEpxJ6622QoKlV2ZJjmOStF4l8Pb3TXTUbw3aK+AaqfvWPIgaYAzWNZelDCUljT/doL725AWAZhRgfksq3eptWPwgrzDTs6dkl4MQpxMDrPDuSOn/MZZh6R8TiZ65o4yEaxzGihiE81H/ecpNpysIs72evTw5b4GbFd3tuYT5CEwHfk5WaQ0neeHOqGn5nndU5IaHdQnlzZjzAFWQROkZkltqN3LDpKxGMD+GGu5aMcxzrb1avOwiWv+AzG+FIyoJ7PSu3j37Ro0hCNG1/Zc+DpTGkCxbmmNjAra+izVktDvFT/0mYQUU0Q7zVgG8VyC8FRjGk+xwADDA6o5zjHZOQwXPXApgJcngEzqiD8EmDvFZYPw5WDquCXuhs4g8K4i+riC4njiJJctQCbRHpD+3hoy/GNcyNq9eWchPX4oai6o/9jA6WU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-01-57Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698562785.8509402,
                        "modified": 1698562785.8509402,
                        "name": "/dev/shm/incoming/2023-10-29T06:58:59.ts",
                        "sha256": "b789d2a362052416da635698607cadc7848504f795025829d06cd52e4c46434e",
                        "size": 10009308
                    },
                    {
                        "created": 1698562733.9309402,
                        "modified": 1698562733.9309402,
                        "name": "/dev/shm/incoming/2023-10-29T06:58:07.ts",
                        "sha256": "d139c73bc1ca51c610283d5ce6c18ff4b0ad35280add180599b1983268d29dbf",
                        "size": 10300144
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "7c5045cf86b4835a890973d1c1f3ed68220e884c5e74c74206392a23edf697b8",
                "size": 19578892
            },
            "errors": "",
            "filename": "2023-10-29T07-01-57Z",
            "size": 19578892,
            "verified_at": 1698578881
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698659928,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "wH2Cr/073h1FEdA9d/zYOv9TEWK8UDFFDlAfprxbgPjKY4j69UcJAew3hH4g9UMtpYyh3/kify14kAgRl9cs/tbuOgo01W6oF1kZ2sqrEBPF+visgdHYvFnTESWe07oi+yZ5DyTSN598Sw9FeWJ3c0l4VJL5+zNdMU/qz74zZ+1g/wiiKzn8PcOGWJlxREvY4ZXgKavNKPAqzwvi1VuxfBvSQRjQ5BHjRs+4BLTa9JgbwVy4B93oGQF/eDsK7bLcEfWUcI0byF/GqJciaDQEKlL/cXoI7VjxLjegZZO7dfNx+EpzUASYy6/PgQTgT24wQXcuHPh/bnE6WIzIdclg6t3ovogTn2nhgfeVC8Ej2i1yaYG/epvGQuj02xzZnPtv32dMX+qMLoMrftf9egBAqTKJ6+gLq1qrhpkW41g8J3e/3ZdsNBATFxLgUbAz4WhxirGoq4i1sNuytOV5DEsSpRxlvwfoytFwXEoRvrCxuq93CAQKfAS+VmbnuI9ppJRx3X5/uCHwXWufr9X5FVo3KGgnSckQOvNP5pcq6FcXVwQzMOpT2wjHkP1sMk8XCD9SiU027cQ+HXSC4DynTsZvsSrjz3bBfJ41K/AFfiPhZPQ7HImtf5f3s8trayV35JpnAbmiYCXZCxlAp3Ua8QyWYmLpS3vPwuyh3a7amPyXDOk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-57-44Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698659856.316036,
                        "modified": 1698659856.316036,
                        "name": "/dev/shm/incoming/2023-10-30T09:27:34.ts",
                        "sha256": "977df1c931322b46a89f601bbb2f413a83d3c87b6a1469e97b2917904f4df896",
                        "size": 381774796
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "4726e3991e8e592a0fceaab71fcbab83782da6b96e9f7e81272531c468e3bb8c",
                "size": 371548148
            },
            "errors": "",
            "filename": "2023-10-30T09-57-44Z",
            "size": 371548148,
            "verified_at": 1698663727
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698564033,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "dE8Aqm5zFIAxD3ylMWzshFy1BGrVu7h8OcMO480JJxvHACUex6jVyI+JND/Rkspm2J/wAbpQ9r1e/8plkmhav/RPBukHRw2QDjAd8N17C+KwJjPa+5zJ2g5/YA89C0994EAPB2MZj6k7o/TNRYUaLn0vHKILFCM2eAntr7mxnxXAU1SKd8xH2VeTOD31wNA5bVFI6PiZ36So4roS92e36c9T0Hb9Tgdcer7WUOD4rkhyOEfje0wNkBdf9pfs0kzfvzUZssbCYy11iju4QpEtpH0lVW1OWlaImNbWux0xBC95HR8OUcHDIgjZES86ksaygqRNwbYEEUEj7nmTaJa2+Fx/UnrnWzsE7Pt5Ql6fzkp8iu6jLZXARJGCYYIRwQQLdaLiWrcBaOw/lfgejDSxoZWpOoVQc2EAARpymWrglGY9Jxo/zk1o5eqXdyqMc1SAgrYbmbyd3659o5FhguNKA+O/OUoAMx3/0njn7cyyX5Rsfdq5R0ippKC7w8Y+32+xnNl/bPiR2P4huqCDRB3KqEDRg+wDmBrrgHLTN6Gw2WRtOjXJmoYsb8LeeicSFowT9wKsOSYtfYTmQHa0o/7aNfh0T4LxQD2gN6L/FltMXSzKieEnykZ6sKtG3kn6P66reNBLWEdfqDkbb9xD6Dyv6UbaraOWHk7XHZyJg3njHFE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-20-24Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698563898.8079689,
                        "modified": 1698563898.8079689,
                        "name": "/dev/shm/incoming/2023-10-29T07:08:16.ais",
                        "sha256": "71ec8fb5e558168b48473a1ec330aafd6727f1c81bd7da33f1d9bd43ee147f5b",
                        "size": 70129
                    }
                ],
                "mission": "OSP3",
                "sha256": "34447bae0d3255ef8a00e8c5e9008c5514e00bdcde36daca80cf80267ce793ad",
                "size": 17969
            },
            "errors": "",
            "filename": "2023-10-29T07-20-24Z",
            "size": 17969,
            "verified_at": 1698579238
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698563428,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Cvz2dUOSMF44jfihfYagRl9UdekMxFvSbPOq2s59t9lno2bGv4uvnzYTE09Tm95KpnBWFtxyphFasCz30l4plMBiM09qZtvcaTc+92UuJbUyfRM1YrJeBKpe5IFfxyeJ5TBx6ymvUyCkg06C1DAYRUfD27BqunL7eZFFxejVneWxd8GBTiYYQclkYk49Fc1rQJR/sKjZNYaLvq+4eEKFOcp1/r0jZKHA/kBvxachEuz9a/e0xRwQwxwIQcJncs8ZNeECPkRqrrHlvHfxvF9UPvTfZCxlYWsTdZOgP5NJYx3AL/gXm6s4isPDcmzIL7OLG3AHaGcnuzAccfX6hO35fG1BUor6ejt4OH+kucmboCZ+eKEKCFPUiChDMXuhlkjb/dU8WNDPZfpcVL39cMpacSKp1qpPNW4DCTBii0TT3tSt1hSAJpNApP25ngdJtpvH6FfsnNBeORTCuJLfzJkjzKCocrEZ+MRKdhpy3Vx2NgLVC9aQOm73l8WBix4PdX+G4XkLP18Ji4Ii1dD5hgg5DctcpMCfcXnpHgABSA8g9Pkiq2T6/3UqFgeSaveDNoyy44qyFcdDLyn7d63Go5ICmizCoM9TGSwmJnC/2f02BKNfyW5vEscJj8RABdAE8fVMvWJadltjBA35zFFOD2QT9rPcWwWaocqHSrpUb4dxc8Q=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-10-19Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698563296.1279688,
                        "modified": 1698563296.1279688,
                        "name": "/dev/shm/incoming/2023-10-29T06:58:15.ais",
                        "sha256": "dfd543af4cd4739629ecb9f35ea189474ffab14b42b2b07e88afc67014ba165e",
                        "size": 83958
                    }
                ],
                "mission": "OSP3",
                "sha256": "7a612cfdd73f85ca1a2764771ee71b7441c16992171dd662c85cc04d55ce8447",
                "size": 20884
            },
            "errors": "",
            "filename": "2023-10-29T07-10-19Z",
            "size": 20884,
            "verified_at": 1698579539
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698563493,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "f8BS57vK8anBqttQqkyeopWHTZyNJ9lXzkpsHOLfd80xREQVVkTz+0FWWvQQEZDJM+J79zbNMw7JvLFJ03hycuq/disqNrMeBFBarm/cDPT03//HJBXHjHtu1zpl3y2GlTDnphkwv4WzC9j5xXUzd2RDp1dUX1e6lqgZa2RvW4ttkPlvJANsnNSs4FPE4blLAOmlOSG/bNtNqWS48NeHllQRNg9xHKMlxLU+sIa200BpfhUZ+roZZye+1OebTFByg4aSiZpzEBUrfP083GsyG1UOxe/sqB5MsAdjwkClfSrL98TL6cba8mdxDdlHvAJSCRCB/0bD/FgssIsDxHSaI/vEZ9pWIZjeem6RBJQkJzKnTvqTpQNyGuyx2FoKAvp2Y9xsFHuS4OqJgsM2LkvaWNtTRG1bh0w4aQzrjN+MJiW7pwFTnkB35ik22KH4h1QG1wVabqY2mXCsTjADuV8qug1naickzJIkr8bChjM2OzPui68vOTnlKj1jOJcZAq/d4q+4iqHMPaK/sjbyy2Yka4tkgLqiS7V0Gog6i04NTchn0BjS0alsS4uBrnty/X28ufCfc5bAQ6x3WM7zeOuCSqI1QbKNmMbChxmJFb/2IRH+huLGT9lkEH0bilDGyoTlaQLBfEpYSxMShh83hnBkXcguaHVw+/iVSmepV13yFcw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-10-21Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698563346.663234,
                        "modified": 1698563346.663234,
                        "name": "/dev/shm/incoming/2023-10-29T06:59:05.ais",
                        "sha256": "6504d73fa0e477a6e656a8253bfe9014d52bf8086bcebb6bdae91d386273d0ef",
                        "size": 820630
                    },
                    {
                        "created": 1698563412.5232341,
                        "modified": 1698563412.5232341,
                        "name": "/dev/shm/incoming/2023-10-29T06:40:12.ts",
                        "sha256": "86bd2dbee44f71e03ef9469106f47d0641e7776078ba09a313d65f5562b6144f",
                        "size": 442775344
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "6a90c335cf18a1574468d91074154d77329f33d8a7e73c9a6b801e4963b84004",
                "size": 398456350
            },
            "errors": "",
            "filename": "2023-10-29T07-10-21Z",
            "size": 398456350,
            "verified_at": 1698595437
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698765107,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "dJlhUKSPyFiVqW37UHr4+XVFysPySC69oPUluTDp5rxcNylsqhFZy6QqNNWA6A7JYZxdpG3GQD9252ChIje+78H7vLa1OPXpfKq3dsy4T0k3wtqB/lk6N3dm/D7Dnz0Kc1K6isphTZk2UW+bwAAcAxCIG2LJmQ04ameuGy8XQXlGdoAgghGewMrgpGZxTYbYJqA+mIAIfRpSotMuKsibConQyDUGRS61matj0f3VmQvb6K+JV2ukChobAgkN98a8i0ZeSkjwCims8rOKI7ZlYfIed/GtWFSJnDn4jaK/8G/kqfR0JootheS/XHgHyTTHYlKUajW1LBYASM5uuoWAfhjhjtLPjQ9kraBW3iYDtoa/Yfo0iI83GPOkevF7cdmB58eqQ4cyzln4fAnYDh3TUDns7BEbTSG/UfeQauohO0yHSBIhcmwkdy8ToycJi5gTxZf2q9L8UGqYCxhxXjyfAgMH2KndW1KOcKJrUiejLOoyxK9SjGJ1c2ppbe5ywtxuKQXtAGymWe1+GXnJXNOtBWogrgHm/3oAzFkkXHcMXsP91R5MIiOlkIesrgUBH19vdj2XF0rcgKAx4E8BvLZKQB9cEFr3uQjF7uy3oV4yCk2V4JyQePH/khOIpF3oey+jO1JSKyvSBis0a32lI4ff9QBa4xX67R7PhGzPG8hMesI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T15-10-41Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698765031.663036,
                        "modified": 1698765031.663036,
                        "name": "/dev/shm/incoming/2023-10-31T14:40:31.ts",
                        "sha256": "a81981705b45db2f3fb0256ae7aa58d5c78559f370c02ad34633e8b14fd8acc9",
                        "size": 407073392
                    }
                ],
                "mission": "OSP2",
                "sha256": "3f6a9b2a78e5582193e4a6bff9c707e024605d782407361c046de5a3dd8e943c",
                "size": 392798740
            },
            "errors": "",
            "filename": "2023-10-31T15-10-41Z",
            "size": 392798740,
            "verified_at": 1698783740
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698564286,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "lRHBXuxvRn/4rzeuvBYCi8KwUbEOslYTO88SIVvzXK9Zf/WAaeccQrM+66nsHwuzRaPYD7RolHYJjSNGxNDZ62zxHBExoFCOrMZ8sjW8tbYmPSVdTfFaF6eY4tQc67uH6nnRhsmVT194s3CLGLDZkodLxor3UmfbRv620tkf6/C5D2JAISe15TpAin7X9Fo+9Qo59l8HxvA0/CJcy2wJIAb7BTFPlSpIFOgIkdZD9rIUIBUDf+O2HyDF4aYTme8aBAV9Hxh0bUplhv5fNRxzxeEbUwOhzBXsc6RNHoB0e4PRl8Ek+8tGmT+tnJgo4qL7cKIf5unSaQbcMNmkIbqURnWu039Sy0YPwExPjjt8fhEi3D/oWFLbWDeCQyomQDFgOfcvc4Bne5Q/VJFNuG4KGNRrjeIdJFnl+TJSrctNl47KUKEkaFJXr/aMCzLv7oiObK29F7rSQapXKv9c4yKFtBKVABVWR0LW4ONdmsmNw86R8EWApbSUXRfCgzygbK5liIw3uLXx6nxjoeUy+yj9j3oOuVxQVkhu74YIR+Wzh4BWizAabEu6w4n6Fo74sBuIu20STYxRgZWIv9fxSh59VhRIp4KlHkgpLdMPrtRU9DpoKkUwGbE6cSjrNZS66HHp3m+F20XYhmlHP72qV8Gx2TEqSQhJ4kusGrXdpZ216qc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-24-22Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698564138.1009402,
                        "modified": 1698564138.1009402,
                        "name": "/dev/shm/incoming/2023-10-29T07:12:17.ais",
                        "sha256": "0d55016c01bd8d441a3de16e6b9be2ffd2900055690672cb486bb917605d6ab0",
                        "size": 130504
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "d1e2dca7386d23f29c8235fbeda85e4c4637d98b07f46b78647d90b0de6d1355",
                "size": 36964
            },
            "errors": "",
            "filename": "2023-10-29T07-24-22Z",
            "size": 36964,
            "verified_at": 1698578811
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698564634,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "iIyzIZM2Mr1vGDg8ZZR9GMH+b2ddA+0IuMC5ICB9Lrgemt9XzjcppUZ5W4P4/Cqq1wCuyAgk2eYm4Zl8Bx88c7nl6yRAmagsI9C62k0V0s6ZLW9ISBzt2jECb+OCJPSYyJYT9j5IoXy7bLK4euUnxcISh2eRG/Ke7uY53i37ymDlMpO5G24xIzc4GDOGZ1tC4Yeu69cjJtH4G6z7EO2eHqxFLqjvpnnJfU3VY7MAwB1k/oSwKrLsNIPVTn8UMFmp5zNZlrrouZFlhVrgbTciveHb2NXqoi6h8r9st1XbpssrNEaujxFUxe+hTNBUdxf4pSK5F1Fw4CphUnR825IS+5U21BlU/1Q7vfvIKSxZhV6KxMzmAHFIKrzBFUDEGI0XhPW6Vf2QelASDDKlV2+U+ZTlEgZRL3/MpMjCgPljvQxiXf+t3TTUPZEH5+GtYuNokxrmWR/qMgvPNdwtoHB9pmyTcYLMU8oO9mup/UO/0y0g6d+7duNGGcX7xGkSLfnXy9yX7cggDV0J4kFPOPEGkwKPsxolMKDlY79T+FpBwEc9NnSHdnodO5IsPqyUHH2x0R0iD/za23dCj8L8GX1dEepCxWxUb9SHOC3CMWG6qSxRwnJxwGqevZq9MKYGxCxCWV7eHiQHHF4CYt60fKxN3T4q57AhdpZBk85somyzv3Y=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-30-25Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698564499.3379688,
                        "modified": 1698564499.3379688,
                        "name": "/dev/shm/incoming/2023-10-29T07:18:18.ais",
                        "sha256": "ac091cb37d86adc19ab1cc0754dcadd852e1423353636eccdf01b305378583cb",
                        "size": 63727
                    }
                ],
                "mission": "OSP3",
                "sha256": "1003cb45652b55e8d1ae12190b1517f40b593fae729246d3548e1009a6c14e72",
                "size": 18317
            },
            "errors": "",
            "filename": "2023-10-29T07-30-25Z",
            "size": 18317,
            "verified_at": 1698579228
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698564089,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "jCaWu3xvuJ9n93+9V+yVFJo55Ko8GijMkY17ujULchIMzBFtsV7cbTUOK9mXYsPGK6te38ryEKJ9RbGgms7nEJS+rBy3yVcfzafTZxhukwqQC7LlDuw8TDJNJ3e6VjxVTXC84RJvj34++xAlX2pDl+cTW0F4g/ic0be9ytTKxAw8Zs0+gxG6MiI1ZJbQd2e1F0d9tMZwRwNnocDpw9mV3hjK2ZAQZs5BWLsH505ObHssnvsCQc7XewbNhLoW+20NrmsdJDzFWSMHSP+DN+IA7WO+0vIxjqpTVbBIw/MUMIGV0ceabUJgIlUfa74Z8hFjdPkZOTVGFKYeIL354nkXHK1pBNMb+rmur6qT745zCX/8f/WvslYuMd1g6j7oLc3A4H/8Oo2hfdTkVRNJl6znpdaN5KkmaW68HzXoBcw/GIfAyr6RORvvzDw0qDMfU7KWapL48Lp2Rv6irpvnpkFyzmshWmZWxep0ZPR6Cya9b8VlOHBKDYwOp+nznrQbH/JMuc8hGcINsQmfeiA9kMMj3+3htpXhPRgsLFqOZFlYJIZTPaOtJPpcNfwygP4Td4QXgY8iBLSDNXxIHprwhvupQP3UPPPmb+S8aSpfwGIuTvxjZWSn8VDtRC2RiIMyEARdcyCZHoo6y6fIDiuwXzjidm+BjDXrK/FaZU+Lkw3kj+o=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-21-10Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698563947.043234,
                        "modified": 1698563947.043234,
                        "name": "/dev/shm/incoming/2023-10-29T07:09:06.ais",
                        "sha256": "2117b66e355f3ed0368cfa45b6c66f66d3dc0737b2f9faf57903ebfb22e84bb2",
                        "size": 889225
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "040f87a842995ad1e34a6e59de0a9a51be9d7c72342c787cb98a28d75ba5d6d6",
                "size": 275339
            },
            "errors": "",
            "filename": "2023-10-29T07-21-10Z",
            "size": 275339,
            "verified_at": 1698594824
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698647463,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ZE1xRCaMtd+FVREpiOwDp7Pg/LDV0NBtAcAaCp28hIQA7wsycHW2oG1deH2LNZHCbJztFe+abb2PyjJWE/w/6rx5D45uTgIqVLhp6UZSXDmPOwB4Se3T1Sgio9iTGlvZXQ7Q6gNp0roq1lET3GtWElBU4ipry5WcSYLK6xDNx6j1FKz8QnYp+Nkfp/OJgtCGvK4ChIWBtyYUpY8YzkRRMzrwCL6mnIqbtB4U/6hggHO3ZTI0s+m4+b1yxpk2pEN04pgt3n10zplHrIm0N1Mebbyc9OM+X0N627/4OHC+oFfNaE8RtnvzqWdLp7Nw/gbDL4tns1UzduNWXmrOQNY8jR4yXdBGgiDiotLWz3lDpsVFT9jDHw7Fhk1ah64CuGqaRY0wjYtO85CuMX7chphIvntlftjndwwhyAJypFCcobor3EzCRBHY7+QHP/vY7KmJ0lRA3gVstPnN04r7YOUMqONx0mzgvNmABPYnpGqXWTg7FlSBamjGVGe+bDOzVA3Rd9vCkHpFkjD3CEx7c/NXK/bCyybzgj3C3oKN5FPjRaOScrzxjhTBhgh0GrlpAFGBduGCDI0WH+AnsfcjdYP7FyvfuZiabCDhbt0uDP1TLkeI9JEqSFyYiAEidxjfc9gHrFGh26kx2xmHTcIsVYh/AuqKs2DpRm5Lgcimogus8EE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-30-35Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698647314.976036,
                        "modified": 1698647314.976036,
                        "name": "/dev/shm/incoming/2023_10_30_062816_00_00_V_015.txt",
                        "sha256": "301b16f972a50001e405fae232885acbbe701a512ef93bb3fce98e14ff73002a",
                        "size": 2026
                    },
                    {
                        "created": 1698647316.946036,
                        "modified": 1698647316.946036,
                        "name": "/dev/shm/incoming/2023_10_30_062816_00_00_V_015.kml",
                        "sha256": "4ed83d70b33a9fb5e5cda1fdd0582f32792ef51ff50e2b0d6bd3fad52661f3fb",
                        "size": 978
                    },
                    {
                        "created": 1698647325.636036,
                        "modified": 1698647325.636036,
                        "name": "/dev/shm/incoming/2023_10_30_062816_00_00_V_015.jpg",
                        "sha256": "3baabfb49038cbcd202548de3ffe068ebabd0b59708d594d2b4847fcefac4fb5",
                        "size": 712577
                    },
                    {
                        "created": 1698647310.716036,
                        "modified": 1698647310.716036,
                        "name": "/dev/shm/incoming/2023_10_30_062757_00_00_V_014.txt",
                        "sha256": "d7617acbca227cea881a3a3a1d08b91dbc311ad570a48665c3cc74a7d9aafebd",
                        "size": 2037
                    },
                    {
                        "created": 1698647309.446036,
                        "modified": 1698647309.446036,
                        "name": "/dev/shm/incoming/2023_10_30_062757_00_00_V_014.kml",
                        "sha256": "a41caabac91a4796bfb87724c154f08d38dd381681549abf748d4387a5b651be",
                        "size": 976
                    },
                    {
                        "created": 1698647307.046036,
                        "modified": 1698647307.046036,
                        "name": "/dev/shm/incoming/2023_10_30_062757_00_00_V_014.jpg",
                        "sha256": "7254b258007025b407bc8c158c91703297495f7f4f5f46b9dee20bd8bc2f4c2f",
                        "size": 901855
                    },
                    {
                        "created": 1698647250.776036,
                        "modified": 1698647250.776036,
                        "name": "/dev/shm/incoming/2023-10-30T06:17:24.ais",
                        "sha256": "2f0a24a615d7ab235773d9d29ac30a75041873dd7a10246b66c5f0d3a1d57603",
                        "size": 277694
                    },
                    {
                        "created": 1698647227.936036,
                        "modified": 1698647227.936036,
                        "name": "/dev/shm/incoming/2023_10_30_062634_00_00_V_013.txt",
                        "sha256": "15693934653a9e376ce0c12f71c2a9eeb356966b1b05c5b8dbd4b357c4f44aad",
                        "size": 2022
                    },
                    {
                        "created": 1698647218.066036,
                        "modified": 1698647218.066036,
                        "name": "/dev/shm/incoming/2023_10_30_062634_00_00_V_013.kml",
                        "sha256": "05a85762ac04d31c964039d6b2ef6c5999fb9bf182550cb7b137da58a47d1c60",
                        "size": 977
                    },
                    {
                        "created": 1698647214.726036,
                        "modified": 1698647214.726036,
                        "name": "/dev/shm/incoming/2023_10_30_062634_00_00_V_013.jpg",
                        "sha256": "33e2a83499421a8d599d4d949f7a7763ddcebc2fcac50beb71202e7ddd3992c7",
                        "size": 895608
                    },
                    {
                        "created": 1698647210.606036,
                        "modified": 1698647210.606036,
                        "name": "/dev/shm/incoming/2023_10_30_062627_00_00_V_012.txt",
                        "sha256": "e9afe82e632d3b1f648bbd1a0fd3c8c36d2e7cad7b33173e61686e72818c3ae7",
                        "size": 2028
                    },
                    {
                        "created": 1698647205.936036,
                        "modified": 1698647205.936036,
                        "name": "/dev/shm/incoming/2023_10_30_062627_00_00_V_012.kml",
                        "sha256": "001f2af60443708dcc00712976fd5a3cb915360b7e5b2bf071ce597a424e52bf",
                        "size": 979
                    },
                    {
                        "created": 1698647203.536036,
                        "modified": 1698647203.536036,
                        "name": "/dev/shm/incoming/2023_10_30_062627_00_00_V_012.jpg",
                        "sha256": "51ddacead65ab6e78ed5969d5a81ab26a271867290cdd20a6fda364f5af34933",
                        "size": 995084
                    },
                    {
                        "created": 1698647188.516036,
                        "modified": 1698647188.516036,
                        "name": "/dev/shm/incoming/2023_10_30_062605_00_00_V_011.txt",
                        "sha256": "029ca1d97e86a87f49cf44f5740ea5df1d545dc6fe42ac54622c17738d98869a",
                        "size": 2030
                    },
                    {
                        "created": 1698647190.316036,
                        "modified": 1698647190.316036,
                        "name": "/dev/shm/incoming/2023_10_30_062605_00_00_V_011.kml",
                        "sha256": "1788b95706fbf14d42af23f25c37223c4a54a37e0fe7f4f923980886a87da03f",
                        "size": 976
                    },
                    {
                        "created": 1698647186.726036,
                        "modified": 1698647186.726036,
                        "name": "/dev/shm/incoming/2023_10_30_062605_00_00_V_011.jpg",
                        "sha256": "3b91b1b2e7481756be5d7f71ecb8ed27759c4cd014c64fc917c567a315636108",
                        "size": 1070115
                    },
                    {
                        "created": 1698647179.706036,
                        "modified": 1698647179.706036,
                        "name": "/dev/shm/incoming/2023_10_30_062550_00_00_V_010.txt",
                        "sha256": "600aa5e68369eaf85db03f7576caf79d30d9d6aedff79cbef18540d5cf76152c",
                        "size": 2033
                    },
                    {
                        "created": 1698647178.216036,
                        "modified": 1698647178.216036,
                        "name": "/dev/shm/incoming/2023_10_30_062550_00_00_V_010.kml",
                        "sha256": "37e18d00969f567916197d4f8aa8cac526d54ca2d484900038c4541e6b122869",
                        "size": 976
                    },
                    {
                        "created": 1698647170.376036,
                        "modified": 1698647170.376036,
                        "name": "/dev/shm/incoming/2023_10_30_062550_00_00_V_010.jpg",
                        "sha256": "b7e7711197b5ff210c5b28ca8cc9f38a9cbb52ce1b14a7801897403c9fed11a8",
                        "size": 1053849
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "6469ccff1553de98642e2e2e601332f9aa460b6f6b2d2d3f877cdb76e1a9afb3",
                "size": 5705320
            },
            "errors": "",
            "filename": "2023-10-30T06-30-35Z",
            "size": 5705320,
            "verified_at": 1698665661
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698646882,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "qM7Kj1XODzG0MDmCfACfnRke9JsddXeQ5MIYhmDBuSqbHSyGoyU5sCLxZgrvqdv3uVpB/Av4VpS67bAGbK+hAhI0BLE+EPyp60TIz/DezTI9kfW8POF4fWOkBhDElraACc6lwMgvSwg+Ssud8DjhpNsHDhlxuwp1l+9EV+NbpYWX1nU6uKLiCOMR43PImwluThgrmnATUBdVT38Q1JqSKbrLWs107TqB2kRDmrlD1lPrUy/TTjSJMvJ2Hd8O49+S1txSMGT6E+SD1cJ26DJd/O/M9DOfDMo3ppXlk7dk+bkxnyhfCZw9idRCMcKaePH836/A4HoN8fjECXBaGYFtudQQIbQHee0Ng61usEVPybO9A1kX6L4YM7BzjAgDH5Qfnvm1cL9XsI26JwMl9G8TJ+HGvKgpi7isOa3JzlbgQPafmFEoTuunL4nasfUM/X557wju2Hj55pGmacVIfIuHzmDgpN0IENDMgRwr94NQOZA5Zz/KaFueRe8hAfnUg7QKA2c3DEfVez8i+oiAQ44XLjZA5+CSm3kE0p4cZU4L5eGX+09bM14Hh8/0ztEMkL3xCrrWC66lO3o0/oqD3Lg/vJNqehh+HOdihzpTsnt58iU0KjiIvUSwyEx99IZFdvVPRTOuj+wJ4VVBZ+TpPgh7L99+rTC31Qs6ISHSbphAKeE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-20-55Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698646736.486036,
                        "modified": 1698646736.486036,
                        "name": "/dev/shm/incoming/2023_10_30_061827_00_00_V_009.txt",
                        "sha256": "8b2130d06ce6d492efa3012e930470ff1fd0f72e71a7e354d01bf542bb2b0821",
                        "size": 2039
                    },
                    {
                        "created": 1698646734.986036,
                        "modified": 1698646734.986036,
                        "name": "/dev/shm/incoming/2023_10_30_061827_00_00_V_009.kml",
                        "sha256": "65f158951072099699ad07101a894650a4a3a729e3fc88c22831a8db19f7415e",
                        "size": 978
                    },
                    {
                        "created": 1698646728.506036,
                        "modified": 1698646728.506036,
                        "name": "/dev/shm/incoming/2023_10_30_061827_00_00_V_009.jpg",
                        "sha256": "ee456477a72c71bbc6a4194f7cff7ee717ccc3a7b743dd68b028961f75cebe99",
                        "size": 1041227
                    },
                    {
                        "created": 1698646676.456036,
                        "modified": 1698646676.456036,
                        "name": "/dev/shm/incoming/2023_10_30_061732_00_00_V_008.txt",
                        "sha256": "ed399e392b595927da0f7ca1d8f217befea2b4c47f54aaa26aece8ef1d01f2bc",
                        "size": 2041
                    },
                    {
                        "created": 1698646673.526036,
                        "modified": 1698646673.526036,
                        "name": "/dev/shm/incoming/2023_10_30_061732_00_00_V_008.kml",
                        "sha256": "a3c228171596e7e771b626efc72fc3b78396f2b38874c75bf28e97913474c53d",
                        "size": 976
                    },
                    {
                        "created": 1698646672.216036,
                        "modified": 1698646672.216036,
                        "name": "/dev/shm/incoming/2023_10_30_061732_00_00_V_008.jpg",
                        "sha256": "7fc1c4ec440e4594cca1b94de9eebe41a8f4ea4f27584cc743ee959b2c088702",
                        "size": 1176975
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "3e53a7efb75724541fbfa22a4f5f1490a18be8464cb56595c970bc8e1639f7f8",
                "size": 2220828
            },
            "errors": "",
            "filename": "2023-10-30T06-20-55Z",
            "size": 2220828,
            "verified_at": 1698665686
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698564724,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "pF7ZpCZAGxMeiLJMTFQSrXlIXlO6JcODG/TxlIGhtdwRHDgsXrcpVlm1HynbxoXACNBkHQ1CBmzpCMyFi87chHvR438g2PqwowVM+x7TwxPa2LYce23ovHif5F3LkPDimTITFarzCII4mBAGIeqVZNKFGRWpYWYyMsjS9INBSH2Du478OxloVHH4MaVFRX2OUsRpfTJi0IJfjH7QfNUb5QNFdQqN8yhNw4qMwdvlfHdxExVhvCum9lLngzg3u3lkJp6yxWgoXnHQDUJoXeMObgukspHnNUSWlh32CjBH8jsEdsOXyYCM112RbYeaoBH5Jo2S5nb19n7PuXPejzbl+CsWj+V2AkMk1mA3Zci1li1BYxMa8c4Z8f6KENSK8y8z1w4KSrRvWeadZika2WDvtNYS8o2ZSpCFcuOGgNXjLwwF3NpqoRX5nV9mPKAuxvRLX1cIlJlpR936+0wWia420UOe7HM/JyG/AFzo3bgqHYOye+YuCJ+M7VH7pJXWNHXIan8VCc/AubGSHYB38/nSrT+NL4XQ5Pm3D0/qGhnKi4pIvOfioqjYpvywZ+eTZOcN5FpbuCIz7nU+108d8Cm33kwTzpFlBEBMvfjemriqvxY8OOWDE2izJi46iBUaWyo0YCO3zgTF0fbJY4W85CqJJFcrMp6hTiagH51Yo+geiyI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-31-14Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698564548.2132342,
                        "modified": 1698564548.2132342,
                        "name": "/dev/shm/incoming/2023-10-29T07:19:07.ais",
                        "sha256": "02b5d9e051a9532cae7edb93c49c32c995f22d77975a43623bcf0babd2b33e6c",
                        "size": 1058118
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "a76575247d0c2b4b0380ca0c7bc8d0a468a2467faa7ea8929c698a6493dcc05d",
                "size": 336815
            },
            "errors": "",
            "filename": "2023-10-29T07-31-14Z",
            "size": 336815,
            "verified_at": 1698594826
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698565238,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "n/C1ih8rCXg80Tz9Qx6vONz4lzFfyno0Jjk2J1JIRovV6F34BESR2k3kEcfspNqP5St1/mWJ3z8n7Ep1oonZpiUmC9+sexbpQ4mJI0NlGOdL6v0ZeTEKFvNaHgFcAIu4XBCeRDPMnJ4Q2zS3RHcb1Divj+EvgReOyfnir1HBot1DCBeheETYzgWfOmp9TGGTQraSASXW7rE1/Gi4cuPIlGcjQTXAG8W5ljS2y23lo3Kod3ZuHd1SH65lawrt/MUPAL5j4F1uqqiyMt6Dd35QOI86JQmPDQETDOfVCFePDUyrOHGkaqQ7+WEMJglYe0ebpiWBuQp76iKV1xWAqMGtAAxTWSQOXfXQr+1GWGSi5ItkOtH3+WhxMUUmZBvM1foU3LmjsQED7Su2yRg9LLdQyDSFzZiD6sG2AI27tsIy20zDPsaASwt0IfdRFghYxeXN9S8SgVpVIt6DzcW0ZPU6cokk7lpHJ2kJkR1TyJ3MHMfRiBYL/5r1u7G66Dh5Cp4p98EGkYZXjo3tEVsJLNTMhBLqD12gJgTWb777+0HAdYkHTAb+IEta3N2xlG6ZOihFK+01VbCncu3zKiUKBj6qaq7Bn8GDQ9UNarKrLa2m0+y1tWe4BkAd/Mk99CjNN/PzVNR6pxOechpvgDx999qMg6uJeobYFWfyaoJlGLp3nkY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-40-30Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698565102.5379689,
                        "modified": 1698565102.5379689,
                        "name": "/dev/shm/incoming/2023-10-29T07:28:19.ais",
                        "sha256": "361e69565bb60d5257f49323e0ca20edf87b83358b28b661841bc8e6844bbafb",
                        "size": 66186
                    }
                ],
                "mission": "OSP3",
                "sha256": "ae599586e8378fc5aad0d14a34f1e29240dae75a39c9e1855ad90bc98fd9c3b7",
                "size": 18590
            },
            "errors": "",
            "filename": "2023-10-29T07-40-30Z",
            "size": 18590,
            "verified_at": 1698579392
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698565293,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "no2BEzXTFPyE4URers2yR/lNpLUAiTey2EMdtBQSUCiAWH2c0l9UjC6jIiB/CgcEzl1zecfJ9xCOjdrkP0YXnEV+PDT7YkM5i6E4IK651EWP4L7sBHjnT7EvxOA6NQ3XOk8aqUElKIHkTG61ctJmM9uyAIJgv38i1dWM6/NiuQI46aqBc8mijWslukEdYAVQRkb8twUt3Pc+xJbS+wi+6+YmAechz+AkQeavjW/ftsjQU22qZlE0yHIidNharZMWP2BlSRYlCG4N7ujak58HeTZtjpmM5A+AgSFMhEN0ccZBeqqbAejdPAQ3EQpZWKfhBVLM8p6JN0AgEKc8ofnVSwnewTNBbTVeK3c6qIMuoWprkNdXi11r/cJXCCJJ5bWg0e5a125jxso8dgduJzKtaI4snURWdtc6JoWC2r04Okh3nW5NGB9Y09OysOxsAe/JMZ2rl80rutYXDsfI3TWK11WWSL3GmDPmV7DPdSTy+HyH0bidtImBBjz+PeLyJA+fJnt6glRKhKFuUR1j+Vr5Ta/bIh2LdJ9cZ/sxjCzlTj+pTJdeGldgbF9lEFmngHo+UyZ+N5LXVTsVXSQ33QOtWi93hBmzm+c1AaIojfjT9YvDpCySC0CJvNWnU6m5m6lM/v9ZyyGLCgUVi9cogBHRXHW18ROVr6VwHbME4XgXivE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-40-30Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698565149.6832342,
                        "modified": 1698565149.6832342,
                        "name": "/dev/shm/incoming/2023-10-29T07:29:08.ais",
                        "sha256": "f21068b11c59f9e1ec55cab726f7ee5dd7729a9075be67f145b9d98094544ee2",
                        "size": 1034694
                    },
                    {
                        "created": 1698565218.9632342,
                        "modified": 1698565218.9632342,
                        "name": "/dev/shm/incoming/2023-10-29T07:10:18.ts",
                        "sha256": "60808b04817b4a942ce86e994cc17820e4aecd2e086a6239fdf5d7e93275b59c",
                        "size": 441420052
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "a7384e0a22fe45d4491eda25b31e6d317b000bd2525120fcaa3807271ff5a751",
                "size": 397972446
            },
            "errors": "",
            "filename": "2023-10-29T07-40-30Z",
            "size": 397972446,
            "verified_at": 1698595421
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698566100,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Z/KVx7GqYdJ7cZG7YiH9RjFXP9DQJOqD5JORmU/vLTS5tq7GTXrzt0b6CfOQldeVBmCh6hmPZvC1KpvH+7PyvtebP+2HaVbvGXI0297y77hbnQ67lWb85Pqbc9vjs8AWFPEFeJCVUDcxNXVx0dqcLFtucVaB+344ujbc8rlNTc+Epg37HmBhm/HLb6Be4KRWWn5LL1x4jqJJxnlO6gpIYkFzfPluLw+snxdtIU8FIC88ftW4KvytQHizz0w4H5RH54aKlIZIx+0oOR4KLRwy3bSniEjXyr4fn18aHgCKPd9jA35rhc3rHicm6AHYRk0MO4hthzchnjvfldqtqCYVm5JvnNqEzaDTAhxyGL7CBq9T/1bOLNcrdM6cvB3MhT7vFdxgA4042THVTSr0m6GVWOW5rQqlPGihwUUpIiaf++a5fpNu90hgDhhsSK8yEaKVli8HZynxXc1RwFAqwxnYXKo4LSncyhvVR6okj7a+7i/rOauk5wL+ISlh3Bz0jSWWc/Sx92SqgAispCYseWIH7HxxH6Unn1DQ7057GqhNByi0g+0N+NXndxMmGo3d5ChpdBYRDiHU54p8eTMhan2eVO1JoRQZfrkUvHdbg/1igBRohSDCoUET/aKpo+zEZxUZGKWojxJMK8pZ4CmuEp5ZRyFX3l1pICeeZJIybSK+1yU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-54-31Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698565947.3009403,
                        "modified": 1698565947.3009403,
                        "name": "/dev/shm/incoming/2023-10-29T07:42:23.ais",
                        "sha256": "73ecd10cf463854505fd8df194dac1b77eb4b36b0f3a2cd2db704973b052d190",
                        "size": 88060
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "fca448811741fcf10aff307d4b29756dad762ac5bf0647fc35621eab8e7aa3e1",
                "size": 24503
            },
            "errors": "",
            "filename": "2023-10-29T07-54-31Z",
            "size": 24503,
            "verified_at": 1698579049
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698565837,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "d0PP2Ib3LQeZe72ITTsCWMo2Z+dyc5BiLkeNyQ9yF7pxrxhhMOZafRKn32MyX4C95xnyiYqvi7oDktWEMIdxNFGGE3IIBQyXTFM1EGx3H/T5qc9bV7Zmig8ZCWC/UZZySfT2f5RAcDUJpNOy+rLJo3l56iaDXLpwgRPgFrjXQ7TAEVKiq69jh77Paw58EoOZQfzvPUA3jLu6qbCCss0Vy4YeZO1Inn/5St/qfQpglr4/40lsSM6/YYt214APAqctYuvz/WnIYJXcfFH2uyEwbY+lNs4daQi9Ek7WYrWgZLOXkcSUNZjYo5QO5GgO/r740W99ia/ea9vWAuRprLJbeqtVgjs9CzjNDba9LOTb44wTYibMFFWKJCSlGII4ocpEJPsThEAKqXjl7n679RmvfX4Hnm2zlYcpg36sXSw0JpzgkvNvZpzRU236/qEhGp0ZGH51v14Wf56n44PynF1sj5t+Zg8ezNmLe1B24g2pgU4/dCkJv4n+jDzkWFCRewJOMYjQmjNkPDZ87mno1MR3w8oU/DA4a3uQw2SlxXp0vwnx/rrfAXDPmIzxe4wkwEPFJIUApu6jiAop2idEll1kNCLorqNRiEku3XKzpwREoaBSyNq+Cf66WrUv1NJOcuImuqMZS6pJKFkGAzKZm35BnIwzUBkwMrZJrhwIESJPQSY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-50-29Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698565703.5979688,
                        "modified": 1698565703.5979688,
                        "name": "/dev/shm/incoming/2023-10-29T07:38:22.ais",
                        "sha256": "aff399babe06317dad25fcfb6e390d989f409ef3afc3d5c12579b4d8073eed82",
                        "size": 62696
                    }
                ],
                "mission": "OSP3",
                "sha256": "e4936f456af4cb60e93ac098a25218f20b5a56235b07da29cf8e2a0fc9c9256c",
                "size": 17245
            },
            "errors": "",
            "filename": "2023-10-29T07-50-29Z",
            "size": 17245,
            "verified_at": 1698579429
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698565882,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "HuUaiFCSIrfP2GiD19CxvJ4c/jwyD/wpq6m9bTjIOgza4L6NxITjMUw9dBVNjdBGQXRJg0yCeEc4yes5T+qGAHcKFI1S6uwlATYv5880YmsS8+kLE9lgO/6vZv2orJh338iYV6xWax6V1DNpAvVgjgMf4EtVearlibfIpccaoqYHKMmhcQXY39k0OXZmE39NnXQaqsV5NyTFVXc8iZ+tYQY3EIG0EC1KLXFM5+o3vcXjXJjQcx6N8BYSbvSvWeZCt16tRDQae2OhfX4gRmRbNoo+DL9rJfyeysRDmMlLTuNckicOweR6plmYZm3O46N/kQGSyRbKP5FQY4vAqFIdkUuQ73abVpXI1LEgTyPoACa5fbzJ9qJGrG6jTpBlFMBqPg3jcboJgbLBD7ijmZIOGARmdXAqEZ251EQO2oQF2zy3Td69kaoyB578L1Scf5i2wpEgDhLkhHOKrOe4GS1paKc4JGZm6APFENbaxluJ1bAXGKAQdvIpMAOLLVAarYHNin6gDqq+kSDhBe8NiepipglcyEsQmNJcsWX61QPhq3qqLshHQdh0pXfCVpe2DwLovigzjnLIzgMAfGdU/3Zmw+lU6wKxNrnGXzH6E4hppDR7fGwf4T8Wc/wvgNwIh0Pg4m+LzQ5T9mt0qgPaNw17dnkzWwVFE8nmpMOceIODo+k=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-50-40Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698565708.780866,
                        "modified": 1698565708.780866,
                        "name": "/dev/shm/incoming/2023-10-29T07:47:38.ts",
                        "sha256": "01430aec09f0f6b34ffd92fc098e2575a35925c2273e3093ad8401bc19ddf1e5",
                        "size": 11953604
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "14cd2a9b8add72b793b8e50f4f5a87515908a00738d786522e8304b829ff9383",
                "size": 11089274
            },
            "errors": "",
            "filename": "2023-10-29T07-50-40Z",
            "size": 11089274,
            "verified_at": 1698594410
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698565927,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "NYuVebF+KckLxwBc+3iJoTwC01T42YsNsfY1DXVNnbgeSrCuh3YpCciVRmJJ2lHq1disE666uIosFMYyIAeP+/fsBPJEJ+h+RvCpxRa1Y/lsplAyfyZPbBsGZFWwRgvbeSYEzw//yFdc+49ZEBH7xtpLKbQSNAdHK1lXSxIhkNykctQ7x/AJteHnoWspViXi+h0wbgXSga9dnd0iK57bMLEpKb433eiS0UoCNh0KrEvVUCb34mrJKNly/m7lBSctv5eZtzz1gzddCM3Cxw5Va0WHFfFoPFp8YI1On2FNp2z0WjUAqDJ0pU7iMicExuoUupV1h49sIUEUXnHiy4+mycT6OdcYp+cCY/34P+k9xo5HDGL7JbIyIrb+qMYjWkp0sFCMmPjzxozg0iwVb5EE3OkwbZZEPYg/IleLw2UabUZFG4Y3r7YGzs2aKcpTi72tBOodOf2gJONPU4QilgvpOcODSXCXrq1k2kYr5aj/11dl5Rr2ejeF623XwQcoHQjBzKG00Q6U122R/1Vy3pVb8uAVJsFphHdaDTqIPrTn1/f5fHBzFCQTO/2LinW7b/esfBe0Di36RzYtfwZa3aph3udj3u3uQfK65FgnfPXPlpCd3RyDEX2jm0F4BT6c9EVhyaDJr++Dd5x+gIvfnYvMCY+TjoXwCnNjs8I6sKX6MqA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-51-17Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698565750.063234,
                        "modified": 1698565750.063234,
                        "name": "/dev/shm/incoming/2023-10-29T07:39:09.ais",
                        "sha256": "d980d9d5030410cb721e68759b08258fd62021b3346301a1589e82bacf006f72",
                        "size": 1011234
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "28af8ede093015a988202382187d9bd28debf1085ca62bdaf077ef1b94091987",
                "size": 320461
            },
            "errors": "",
            "filename": "2023-10-29T07-51-17Z",
            "size": 320461,
            "verified_at": 1698594847
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698764248,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "nkOvKIzAm3gFfaKtCCQJSFXj0d208ySX2EpXeLoxeDmWOUWETDvEAbRhxUT+ZWfwxdBvRDMbZ5jrSpJs13FlZ2P3herkiL6uVPEAMPOrQJm5+JjiQ7ie41gTMzaH95uOaaAR/G0NdQZ2l5yXeqezop1qDF0Itlimoy6kiqXckFKj7M3ii+2W6we806FTGcTMaqMHpnRfxyhLYc8grLRq/Gg5+7Wf6FZr5Z5cSlp1U/POl/hlQ3C5f8G67oWGo3IT9qT9HjJUqV5dKbN7+5Cs66JD3OIPpl/2L3HABfq2EYidNKjGQUSBOKTm/PrBPrwhbDGm2HtnK/uEPhXZBm86NYU/mu140qw2rK1x4WcfNsYSZZi+ITH7wHxYOvliIDdxHPr4gMwdT0ZjuOrdzUXq9KggALzWI1DVt60dHd1MNqAkeY79hYmBm1lL/g+WXmgX4NW/29h5uxgbLcX2CyftND2TCLnrHbChfBjb2pdgFIZfSFFyEheI57EdH79xa2i6tr19uLflZJNzrMpEyl+DdobXN7LT/Zc9gLf13lDqD8N7jT70JQcA4S1YS82xNZ6KhRHMCz2VcSNQG22jV0RXFOJtZv9pdiY640SdEQjPqRG1aUs4eSEmv28HxxCywM7mBiXJG+rUmjMGeCTsQHlmORy+nq7CSBQMPdiPDTwN5E4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T14-57-08Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698764105.043036,
                        "modified": 1698764105.043036,
                        "name": "/dev/shm/incoming/2023_10_31_145444_00_03_V_004.txt",
                        "sha256": "346f30b6ea1217a61a707982c1935f95db9f5daa611c4771c161604f6243186b",
                        "size": 2025
                    },
                    {
                        "created": 1698764111.8930361,
                        "modified": 1698764111.8930361,
                        "name": "/dev/shm/incoming/2023_10_31_145444_00_03_V_004.kml",
                        "sha256": "cb70a6e215cfa54e8a1c7a5fe6021b96b275aec8d9858dab2fbb8c5a2a760eac",
                        "size": 975
                    },
                    {
                        "created": 1698764114.153036,
                        "modified": 1698764114.153036,
                        "name": "/dev/shm/incoming/2023_10_31_145444_00_03_V_004.jpg",
                        "sha256": "d96f5b030abbdc373fede67ddb2d3b1d220948d8d99ec49e1ea8993108f947a7",
                        "size": 1071660
                    },
                    {
                        "created": 1698764025.703036,
                        "modified": 1698764025.703036,
                        "name": "/dev/shm/incoming/2023_10_31_145319_00_03_V_003.txt",
                        "sha256": "f6b23d332a1bcbd8423b39af835677c3d743b35dca69877eeec789da44606e9b",
                        "size": 2030
                    },
                    {
                        "created": 1698764025.3730361,
                        "modified": 1698764025.3730361,
                        "name": "/dev/shm/incoming/2023_10_31_145319_00_03_V_003.kml",
                        "sha256": "2e3cb4c78af678949d76ccd433606ff7145bc28d22a8f7133a9de59c07d123e5",
                        "size": 976
                    },
                    {
                        "created": 1698764021.983036,
                        "modified": 1698764021.983036,
                        "name": "/dev/shm/incoming/2023_10_31_145319_00_03_V_003.jpg",
                        "sha256": "e66d91dce03d4f93462846a5c1b7566e2b6cc21fe64c1c48aa4c3e21d1400594",
                        "size": 936877
                    },
                    {
                        "created": 1698763918.033036,
                        "modified": 1698763918.033036,
                        "name": "/dev/shm/incoming/2023_10_31_145130_00_03_V_002.txt",
                        "sha256": "e5a8ee1e5baf79eb0f2f3d1e281b3c5ad40898afa4b88e4a1e679e35c507469b",
                        "size": 2026
                    },
                    {
                        "created": 1698763909.233036,
                        "modified": 1698763909.233036,
                        "name": "/dev/shm/incoming/2023_10_31_145130_00_03_V_002.kml",
                        "sha256": "ea6ca3b4573ed54ef463e85d13169b73d9b3472467d6040d52c50eef1d4babac",
                        "size": 974
                    },
                    {
                        "created": 1698763907.443036,
                        "modified": 1698763907.443036,
                        "name": "/dev/shm/incoming/2023_10_31_145130_00_03_V_002.jpg",
                        "sha256": "da39ee6b3eab4c59ecfffd314c14536950a6fea5eb1e085dd99fe4e463984e29",
                        "size": 909839
                    },
                    {
                        "created": 1698763906.3930361,
                        "modified": 1698763906.3930361,
                        "name": "/dev/shm/incoming/2023_10_31_145118_00_03_V_001.txt",
                        "sha256": "11437f6e321fc39aa4d5f150a33ba6e4a21ef837b357fc40b5e567f8525043b2",
                        "size": 2038
                    },
                    {
                        "created": 1698763905.3530362,
                        "modified": 1698763905.3530362,
                        "name": "/dev/shm/incoming/2023_10_31_145118_00_03_V_001.kml",
                        "sha256": "e7a2bd614137296330bfe3c94a1adab73010bbec7335d01ecc7160a878877057",
                        "size": 976
                    },
                    {
                        "created": 1698763899.6330361,
                        "modified": 1698763899.6330361,
                        "name": "/dev/shm/incoming/2023_10_31_145118_00_03_V_001.jpg",
                        "sha256": "777b702be5a180da93b8a5e25ab2d0c64451a2e7305f25a55c37bbbf11bfe98c",
                        "size": 840159
                    },
                    {
                        "created": 1698763850.223036,
                        "modified": 1698763850.223036,
                        "name": "/dev/shm/incoming/2023-10-31T14:40:49.ais",
                        "sha256": "3561b2ad3f1786260c8343b490608d44b13cca7d6ec1f7fa56a8e0c1a761779a",
                        "size": 79138
                    }
                ],
                "mission": "OSP2",
                "sha256": "9ab6abb01342d6c412d71e9240fc5d0b81aab940d0e1bfa67ba99de61b5f5670",
                "size": 3781428
            },
            "errors": "",
            "filename": "2023-10-31T14-57-08Z",
            "size": 3781428,
            "verified_at": 1698783375
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698566474,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "PkdLI5tLXAvLUozdGt/XtrmyS2tqjRAF5XL3rcQrzksVyaSUQy5uWwa8p5NkA5e7B/l5ASQOxgDoY+NMa+gT1+EA72ioJ27oGSN6rua4g5+GWeLYD8+ShMIJ1OratppTbjg1IG4w+g8ZqeSbJ5Djj2GH8JEdfSFQle6FiaBNNd47dEjEDFL0PyQnpekFY4tDPXXF6oGpXZNIzaSya8LhDc1fV67WaxV6YxPs+Qbp0k4z1AnzziYILJLhiosFTryK0Yt1oG6pZHrEgjixu+4hiF6gkqudssGVdDCcbk3P2JpYL8qvge7JhtWbt5qrcGsbw3tl4GjZ9QAZkPMHl+dpwBhD6ZMVl9FZmfX7Wuk0K+21QZ3a/7zCxjwbeTNzinSxsBkO2qWawb6ESh2F5FYdqa5bd0p9zXkLFa0gfrN6Kzf19Sn4BvND9jQPIixrgJeaWFv00+rr+Cu9KmxFuzKMR/Z/2Eq37YbVIuneVvwJ3KtR/eTnhV4J4eyDhxdFNUuq53bZP6BENVB+soskx0aATDHiVpwGzOd9dTMS66cCcjBxmWIBgChG0jq+6cTeWPUHkFsqNyV8N1vOPJ2mV4GlqjxFwn/kEVOaM7QW1j+oXdcPubstT+K3gmke2xbi9OFWi3Bh4wy9WDkFXAEKpdYSU7ytG3k1GMWINU7RW2flOBc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-00-11Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698566402.9509401,
                        "modified": 1698566402.9509401,
                        "name": "/dev/shm/incoming/2023-10-29T07:29:59.ts",
                        "sha256": "f317d939d8ec8a27e4b47407f58a472e39cea09b01e06fbbbfe5c09e83bf9161",
                        "size": 390615496
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "41ccf394f2998d6a3526f57731633dc974b4626beaa7d1059adc28cdb9eb5f04",
                "size": 381012983
            },
            "errors": "",
            "filename": "2023-10-29T08-00-11Z",
            "size": 381012983,
            "verified_at": 1698579261
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698566209,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "j0rkbttrcHS0XIwpG72W6mEGv/0JNGK7R9Wg76cZRaXB/40pr2zBj5UwkXHNHR66k5x5J578IgVSGyUWmyT4EUCyZHmM6ZJuJsRwA+PK/xh2sMeI6g6WOCa6tM+5Ak3Abts0w9pVZpj42gXYLjrohm6q7hJxWCK7La+ElenzF1Rlb5kEaXXGEWkoTpYoCEELYQo0N+Jlk5VPHDOEiSej6lXahnjjd4zWIxESBmVhpAuDWdN269P4iAldStOwXNqDhZmnJS1sN9fQRy1xuiAK1Z7tOpOiHB4j0gpFB/YdEn7fxsqffRtNlUGGySBKgnLethXC0aqmQHHA3J67F51ER0e6GDZ5phVWAI2fIXrHMRXv3LK9Bth4qy8nqbmKa4vxS7vwJP00J9VSvTSc/18ex7YjFxK8tL7ySz4k2Rtkrzbz8ALFzF1McC0WPWgM02PlcGJ5goLv192yCW68zHVZ8HR5B4XhcEMcrz9klCoSG9xWdDYi/NPTEVbt+yajSBh7KKD4HAz4/kTx/oqtwxHi3swRBHfu/GGD6INavPxe+0uPNyHGkuS3dBYMkqolwAWZ5upD4rU/n+4un/E0kq8bS1cvkowUxc8Ennig/1UZkJimuS1rLnl1GdcWqLAwcqREJdVvD7kuUpPTxd/LjZRrj5FSziLdx+jz83UzLXCGefo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-54-38Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698566069.3379688,
                        "modified": 1698566069.3379688,
                        "name": "/dev/shm/incoming/2023-10-29T07:24:28.ts",
                        "sha256": "410c832e20192b8a1a9a01801a151612cd40e5f513aa3f5fcd6d25c180cfb3d4",
                        "size": 293200852
                    }
                ],
                "mission": "OSP3",
                "sha256": "f92b5dc7ab925bfb65bc14d8b555c62d308ec4f42824dcf7cd1da92d32d5f799",
                "size": 285211613
            },
            "errors": "",
            "filename": "2023-10-29T07-54-38Z",
            "size": 285211613,
            "verified_at": 1698580415
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698566158,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "LPgz6lLmXH1GZYoLNpcBwoPUWcUll9H/fI9WNGSZy6a1QNAohpZxtpDgs2JAd+kxT5HrMipAiI/IPLolpZieBhAb4Zi6/VXuoTlS0wjsG/DkQ4FQZX//eI5tAMnB6K1GIFQV8B9DLN7GkV+Aoq+nbTsksDz8U7wXavBIbsDLK6XDWjbrVl5axO/YE9/TE6vZCY40LqudA2e/iDrQfeziGsPR3QblSXAaqEgDRxDFRP1EyusTSEc8JsgYekgKQsmtqJ+4msntfatbKGUh3UVR6aQmWMocrLeAFQx9l2h8SzVyf5mF/NmItxYCl4hiO53eM4VeieBaopVnr9hwTlu3/FAEug362Vi1qx8jmPonYlYLFJ5NKEEOy3Mq5JEuqzkGVLDno4dtrUsRWX183+GsKrBMC27ze0daO6ctrJMKjHiXYg+ae3T7C274DgRnlU0AhpBr6+msXIQHnDy3Zu9V/dxTUqu7MXyGngcgyX6BrYw22BJFUvy19siKfNhpQ4GQBbcYMrIntw+Cl7aifY26n7MY1NbfsJQ4IOFU6Wp9yJjpDg4UnffpmIwXsA5JAKmb6Mgr7rCU0T0yYzSiuWtJlm0XGUVpavQPmhcrQMAGGhVSq+Uhe+7w5D0r5XSEHPBBifKCGZvAuNYQSmt/qfhycOpWYltIdvfAlljf9E1gdgw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-55-38Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698566009.8308659,
                        "modified": 1698566009.8308659,
                        "name": "/dev/shm/incoming/2023-10-29T07:43:28.ais",
                        "sha256": "0bf6567c8f8db24ae4237db769976926204bb705f058d3c752ee1f12b1bbd670",
                        "size": 6135
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "834c3672d1a307bd05211d0f9575de44662f6724a04adc007b4ef19a97032253",
                "size": 1534
            },
            "errors": "",
            "filename": "2023-10-29T07-55-38Z",
            "size": 1534,
            "verified_at": 1698594251
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698566510,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "YXP7zIDsDoPLbAoBqZrI619aZ3sHRpN6rpJr4ebYS7u4SeuiWpEZi4CB8BljbtcbNWwHHBtBTjGpMwjc71MXSmKbRadRZFhADKxmKG1gxlKXvQ1yQLBH8sX1VCk7GAMqIUjaTP5tWKVbvIEKieDCdDjfiSuzUkjKO2ab5bcl3zNk0wgbaQ2RdEfTuUkLHX0dfHrFvPttBDiLakufR5PHdAMmKGBJAisy0Jya7g1eiI4c42XxO/Ndz1ZZdsRusSoZTNRsTbw9wNWO3bSyvpeqe6kPrT6niur4atyGxABD0HXJjssf4bX78BcZW4YGZXtqrWjfv87466mM8mw5dQeqkXI1wOja/9L5LQIUhvd88WpV0A7MzOmcrO/S4Yn1DEbciJoKoznDLojJL/Ew7e9NJ0nw/rfZ4gCNuIObw3pjcwWUvrGynLBY2KBSeI2g5w/EtL4/zBuMg2I4sodkJU8ArBr2gEMyL0+DHZl8mnuRaVtZJxJGla1cZu2MrW6qcFG5qvpm6jndiuo4ynMDL3MeRQOB8oGhvqj40LtTpZ1H+1Uc9YfbDcMkvr2ah7ZU22nJN1RCVFq5CFgqweMkVMbVCLCvKT5ZFpFhype0Z2HPiMYdWR3ZGQEqwFfgrqIb2eIVPXTlGqi0nC0di9DLvAA6fFl5noUGRHXfKNRkM6kLInM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-01-15Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698566351.403234,
                        "modified": 1698566351.403234,
                        "name": "/dev/shm/incoming/2023-10-29T07:49:10.ais",
                        "sha256": "e9e02066b667aca59cbcc53b096898cc1fba5084a25281b817011f9402ea4ccf",
                        "size": 920003
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "462fac6081e3901f37e0ddd3d89a4e390ebef18959965293e5b6a0f517197ec5",
                "size": 286785
            },
            "errors": "",
            "filename": "2023-10-29T08-01-15Z",
            "size": 286785,
            "verified_at": 1698594881
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698566824,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "FOEXYJQYTEq8McfEI988ApfFACrwJtDf1qKLwLeBqNfg/zMM6VyQZXnZkdHCJxWrZWhOug9oTx7F8WhmOo+CatvRdIKBsyyVB2d+naPcp98JhS7LtEkezZqvU6iEu1lx0tGoo0L9T5bFDCqW4wrARXypEwbBqo4JE9tMQtgC9p1kt0Of+9x1xn9Mt/AvNFaOZdYUPzBlJvZm4cz8JAbFK4fSJ2NOLRUgcjjBpFXUIH/NkJWZohgPKvBYsDnp89pZl7IToxlQXpAFKP1csL5yBRnCHCnjCVzb5mdUTmxE2PzIffj5nb/rjcr9k43P6R8zUpJypH9cmqkp56OUAhg/xKhviK+9dA/HFU+92EybEiLF4H0Gc/pSbDTgIKWg7Ke4tFS7lyMH5vw+wuoSVd5KeNVpdQQmPXGBD7Y755TGxyiVN74O23XZe7EvChVAgwwrAf3aG0ac3vEH5uej3L4wvhAhj+IKIIv03ZrSH8oewV1cnsboNHl1YQaU8d+TBrErIxC87fvrlNATApNWQDLDrAdvXVMClsCKWWjKGAgKtqpyi8aQpN+mw0vx0Xe7JhgggxMIWvPmdIRNysLZuzHVrHLe1P4pW99xRCI2xviy94+f2yjDaxyMvfa78fUyV6f8Lehnr1np0XqieKaKbsBi47e38w9D7boxI//JD9eP5Oc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-06-07Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698566756.3579688,
                        "modified": 1698566756.3579688,
                        "name": "/dev/shm/incoming/2023-10-29T07:54:34.ts",
                        "sha256": "3bb9efced669fdf72e0492eabd662af5da64bcbc9e3cd2ac9ca930a616e1bc37",
                        "size": 119259304
                    }
                ],
                "mission": "OSP3",
                "sha256": "7b53b82e3b46e59604f2cf494e18eaeac782f29909edc23a5370fa4bf4670aee",
                "size": 114082750
            },
            "errors": "",
            "filename": "2023-10-29T08-06-07Z",
            "size": 114082750,
            "verified_at": 1698579129
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698566811,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "I29wmd9Q0yFZ073p8z9YV4s6j3gphwi9q049gWU7H0fAjAzvaCnsPLqjH/+RgOTe6Oa4qkhl4vpY/vkN9rkIzogpc6IVhS6GxRAhNB14YwBbGJIOH+/NYB914TaUbnYknMMnO4/AM00MQoFmb0TgLCxHzj4u2T45uh9KyVBZu4AeO/tMz0RxrEkeceylabSimSSo+rIuiVWjfDAXVJbiw6xl9sFY5X2abO8kRy/9ikdEpNZ2JZmrDSLQ9j/c4C2/cJr+NwCcva+zq/Acay6Ywoc4LoHkVib2umcOWjVMYbfaZ87+RcGb6UdMfYqYc8JXzaIIUhpoJ4yp5OT5DhvTGxsTvz1lkSipMsLB5Zni1zXX0DsLaBME8hMAc6oqsaI1L8xjBhBl0cx+4uEuxMS9whjf+pHdmRj5WC7Zj0Mgz2bBOXDXFW+7pegY41dirlvk1Ykre/TE3vztXAyRXM8gyCKrt5W5AQtmXlHwN5anpgWvnwkP1M2tgdHkd5GS4Tsb8foc52GmqO3VmI1sp1utyhEbkVDXLmEw6tlkam4prj0ib2uf2tfL75eQ581ytpdwi4zmT1PHkkKoh59CyAYtNAivfBZ4iCIeZKpf0v4kPi9au/HtSJlPwi1uMpuYFRhoCAjXW7vI6epEhDsLaq1iYagFYKu5lYLcS3ix5RObhaA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-06-23Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698566549.2909403,
                        "modified": 1698566549.2909403,
                        "name": "/dev/shm/incoming/2023-10-29T07:52:27.ais",
                        "sha256": "55c9ab134b7fa3939ee776a0683f169311925d405e055a4079ebcebfafdf8bf2",
                        "size": 115296
                    },
                    {
                        "created": 1698566651.9609401,
                        "modified": 1698566651.9609401,
                        "name": "/dev/shm/incoming/2023-10-29T08:00:08.ts",
                        "sha256": "db236af216d7b917e8e51eaab58cf2f4306737cfe13528b567b7abe523da33ec",
                        "size": 53512320
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "43c533f05ca3571c6712fe0a513834c1536680d21effb0e32e3e0eadd3c98f0c",
                "size": 51651992
            },
            "errors": "",
            "filename": "2023-10-29T08-06-23Z",
            "size": 51651992,
            "verified_at": 1698579451
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698567100,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "ZjpNCvi+GpOxt+vH5mJPCju98vBBlC6MGI14r42+4c4ZjoMJtjfKdC/0hpXFWxEWAfyQT+Ohcwhc4ie8KPkeZCOvWQJknDfGPay80Qw/zFh/Q78oPFqt1XhURDt45y/OfRcWWcYjZ/PegAhaC91B4E/EtijvSvmbc/sbCw/IKl4W9Jp9FXLyQnBsojG4xmgycQeQAjbI6Fo7v9nX0xUkic3h3Yv8NbK7WLMTfEVXGgPSxy11D5msScoLy+NA9kHtimoESCG5GafOIJThs1cG+rcWdfmG5hN7xqgjHdtpk9uBXIwB1F1O1GfHX6e5HK8HjwoOQ20swZpASPZ3RmbgEgFZlpCHuCcrKx9G1PPFeLQb70orzTrelTgCtpyDYkobG9ncS4G1jdv6BHtC+5HpVYfDTRU8fFXjVkYaeomHlt7ZhvR2TrouoBXvpZgsbhfdEXHil8divx0EsLd6EpLNxuYgtxdF2maVt3XHMvO3/YAK2eWiVk+0E2wXfwfOGW8SFUudsjll1Pk3ZBlfv9JGUxdfqt5giyUXmgazAAMLQ+9hK9MkYJVHFVQCYL7zTgsHnFtzhHeWKP3OdmrWbJ+IVP7PAo0qUrciqw4CO1P45Ut5ia78n9SnN+n3M0n8Y0ZUMROaj1dBPkjB/9dlpFMGIOu19sPrJHnTkC1jOmOd6nA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-10-31Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698566952.4332342,
                        "modified": 1698566952.4332342,
                        "name": "/dev/shm/incoming/2023-10-29T07:59:11.ais",
                        "sha256": "fc3b7a27b7ab630d0354135a3b22728ae02c50b82a98667f0b754549e2cbaa76",
                        "size": 819227
                    },
                    {
                        "created": 1698567025.4332342,
                        "modified": 1698567025.4332342,
                        "name": "/dev/shm/incoming/2023-10-29T07:40:25.ts",
                        "sha256": "bb25cd37bb9977e1c48cc52793be91cf5dded69d05ba257fc8632a86b4d15098",
                        "size": 442287108
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "6509563eda9638b323bd6616ce3a850190ed189ca62323ee8d68d9d12cc7877c",
                "size": 396734279
            },
            "errors": "",
            "filename": "2023-10-29T08-10-31Z",
            "size": 396734279,
            "verified_at": 1698595578
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698663739,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "czMBPJZl/Cn18+csl55m+kTt4Vc1JyuU26hf01Dm8EbCvLraz9q4pC4FX8wbSXYzHVa4CCbWsvna8CXa8gP1JZvAkSurjz8/RjBkkRSsY3Ot76wZF+aaEPWFC26sLpiv6FzNxlMwgnz0UmOns8EAQVgvZo32ol51+y9NoGkVvKKtKS6GzMejuRDBTOuSOItuSGLOw5BmV+T5k+7q1KLPXyKxc5ls0wtYJbvShtTvy1mxohvWtcP86VqPAYVNeKF1qGENnHu+Qf2plIhAlRFz16fPJbaJX+Y+u14XA7oFBEc3T/FFlcooIfl6Oljv7mCOqL3hJgFVr3fK5ML3AlGxWrCmN5bkUu2t9pZIKi2Lv+WpdWGAs6uVLjBXw1d71ZcOR76RE3es6rwfR5fXncPPERJgSe+JETzxtrib3UvSrYMjXgWZsecz5vzYT1bmzf+aRhdGv1qX7A8ko1kStraHzWPcTqmVHV0/vt8bUlte9Lpr+ohTIENOZkmvWQLw8k3hUL/bq623WjR01D1MUjji4aYwn1lVDuQz8Q9A91AdKpuIgMkqPUVACt85BUcPjhUW/63zuGnzzX6w7qxp+fEHwEHeAdPLtOgUQbinJB9xiCYk2cXuaCamA8wHEFTIefuVUqB9tuTRUlFGxwewHIC7B8s9RS/o8CcVUZUpC0BrP2U=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T11-01-56Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698663600.0479405,
                        "modified": 1698663600.0479405,
                        "name": "/dev/shm/incoming/2023_10_30_105925_00_00_K_124.txt",
                        "sha256": "adab12d0996f0ac19c9ee90837a644472d23184a3f5f9fdf7288a7aa4eed1f62",
                        "size": 2045
                    },
                    {
                        "created": 1698663597.6479406,
                        "modified": 1698663597.6479406,
                        "name": "/dev/shm/incoming/2023_10_30_105925_00_00_K_124.kml",
                        "sha256": "4db4c20107a4b2cba88698b7934ce7cbd48bb8de8f5339ce0d813fd2c6b5e35c",
                        "size": 980
                    },
                    {
                        "created": 1698663589.3979406,
                        "modified": 1698663589.3979406,
                        "name": "/dev/shm/incoming/2023_10_30_105925_00_00_K_124.jpg",
                        "sha256": "1864fea91aed52be7521fff22488ccc00e92a24d56244f89227c1227e40ff76c",
                        "size": 1180245
                    },
                    {
                        "created": 1698663588.5179405,
                        "modified": 1698663588.5179405,
                        "name": "/dev/shm/incoming/2023_10_30_105922_00_00_K_123.txt",
                        "sha256": "6c3bf2772044e15f40217dd267a40be342881af34b6361ca296bf79cb65bd51b",
                        "size": 2045
                    },
                    {
                        "created": 1698663588.3279407,
                        "modified": 1698663588.3279407,
                        "name": "/dev/shm/incoming/2023_10_30_105922_00_00_K_123.kml",
                        "sha256": "27220f5bb6c53f145836bf0fd9eb872eedff1d771d2243cc4d7801832eb3952b",
                        "size": 979
                    },
                    {
                        "created": 1698663587.8679407,
                        "modified": 1698663587.8679407,
                        "name": "/dev/shm/incoming/2023_10_30_105922_00_00_K_123.jpg",
                        "sha256": "5f04f2a5cc9d78283a942ef49b4b7a394fefe60c064d4d4f10ef756b836338cf",
                        "size": 1105968
                    },
                    {
                        "created": 1698663579.3079405,
                        "modified": 1698663579.3079405,
                        "name": "/dev/shm/incoming/2023_10_30_105918_00_00_K_122.txt",
                        "sha256": "8929f282714ffbfb0c32b8d2823da257864bc7035aeadb72ff34486e3d3e4b97",
                        "size": 2046
                    },
                    {
                        "created": 1698663586.1479406,
                        "modified": 1698663586.1479406,
                        "name": "/dev/shm/incoming/2023_10_30_105918_00_00_K_122.kml",
                        "sha256": "674485e94c312c4d9e04b56fe1ea2bdce2cea97ec9d4d109dfbef320732999ad",
                        "size": 976
                    },
                    {
                        "created": 1698663587.2879405,
                        "modified": 1698663587.2879405,
                        "name": "/dev/shm/incoming/2023_10_30_105918_00_00_K_122.jpg",
                        "sha256": "a73318357a77fb6e4aad0fbf46142982e6589bee3229254879884de54490c769",
                        "size": 1024487
                    },
                    {
                        "created": 1698663569.6979406,
                        "modified": 1698663569.6979406,
                        "name": "/dev/shm/incoming/2023_10_30_105908_00_00_K_121.txt",
                        "sha256": "95ded2aa0deb373859492b3b099f24b80013664d32fb716c6b375c8ffad54bd7",
                        "size": 2045
                    },
                    {
                        "created": 1698663570.3279407,
                        "modified": 1698663570.3279407,
                        "name": "/dev/shm/incoming/2023_10_30_105908_00_00_K_121.kml",
                        "sha256": "74d2f02da68d97baa2408994320a16a436de805061980166eb138fe51c382272",
                        "size": 974
                    },
                    {
                        "created": 1698663568.9879405,
                        "modified": 1698663568.9879405,
                        "name": "/dev/shm/incoming/2023_10_30_105908_00_00_K_121.jpg",
                        "sha256": "ce6de93e76b1a5b983f9779238d947012f6af3bd22f244ba61723ea6e64f7108",
                        "size": 1126570
                    },
                    {
                        "created": 1698663538.0979407,
                        "modified": 1698663538.0979407,
                        "name": "/dev/shm/incoming/2023_10_30_105827_00_00_K_120.txt",
                        "sha256": "36a49eb5baa4ab7fe46234ba088bbfacf5bae280586d7445420f57a4f136620d",
                        "size": 2044
                    },
                    {
                        "created": 1698663541.9479406,
                        "modified": 1698663541.9479406,
                        "name": "/dev/shm/incoming/2023_10_30_105827_00_00_K_120.kml",
                        "sha256": "916bfdfb1919c5ee37fa48d2ef48bf4099b25e8aa40f6260c543cad4b2548ae5",
                        "size": 978
                    },
                    {
                        "created": 1698663537.6479406,
                        "modified": 1698663537.6479406,
                        "name": "/dev/shm/incoming/2023_10_30_105827_00_00_K_120.jpg",
                        "sha256": "6515de3f5f84895a82f9fa85e9c755d780ed8f8c31013ab4a3d1ed03f5afbf72",
                        "size": 688959
                    },
                    {
                        "created": 1698663527.0479405,
                        "modified": 1698663527.0479405,
                        "name": "/dev/shm/incoming/2023_10_30_105821_00_00_K_119.txt",
                        "sha256": "88f72ccb629197d2e653c65113357bc06f5b010b5c26051e1aa2b4de64ee5630",
                        "size": 2046
                    },
                    {
                        "created": 1698663526.9279406,
                        "modified": 1698663526.9279406,
                        "name": "/dev/shm/incoming/2023_10_30_105821_00_00_K_119.kml",
                        "sha256": "40a1e380eeda66ebabefc1607ace4ddc0f7330b1af30868381151432dca954a4",
                        "size": 975
                    },
                    {
                        "created": 1698663531.9279406,
                        "modified": 1698663531.9279406,
                        "name": "/dev/shm/incoming/2023_10_30_105821_00_00_K_119.jpg",
                        "sha256": "6826cec3cd30f92a86dc17de856f73ea6473e2063dc62ea75576d40ffee17fae",
                        "size": 690481
                    },
                    {
                        "created": 1698663513.3679407,
                        "modified": 1698663513.3679407,
                        "name": "/dev/shm/incoming/2023_10_30_105801_00_00_K_118.txt",
                        "sha256": "d428d99d0b43df20a531f8af6cf5d2d7ec074860fe2ab59153765ba0a3316101",
                        "size": 2044
                    },
                    {
                        "created": 1698663504.3279407,
                        "modified": 1698663504.3279407,
                        "name": "/dev/shm/incoming/2023_10_30_105801_00_00_K_118.kml",
                        "sha256": "217b61605070354f44e17da0615461851837355437135bc1846b7945d01cf280",
                        "size": 974
                    },
                    {
                        "created": 1698663510.6279407,
                        "modified": 1698663510.6279407,
                        "name": "/dev/shm/incoming/2023_10_30_105801_00_00_K_118.jpg",
                        "sha256": "074ee1b79f6a97d919ecd11b90e0997a4aefd71f849317b08047899417d1ebdc",
                        "size": 1130282
                    },
                    {
                        "created": 1698663502.1979406,
                        "modified": 1698663502.1979406,
                        "name": "/dev/shm/incoming/2023_10_30_105757_00_00_K_117.txt",
                        "sha256": "b2f5294e70b9e9f8eeea6d1193b9cac497b7682395985df8d95c3122d1d71548",
                        "size": 2045
                    },
                    {
                        "created": 1698663501.8979406,
                        "modified": 1698663501.8979406,
                        "name": "/dev/shm/incoming/2023_10_30_105757_00_00_K_117.kml",
                        "sha256": "0384f29cd4ea11a169fdf575d942ae0a5f62e77b1e70995fab5b6bc1568a6a58",
                        "size": 978
                    },
                    {
                        "created": 1698663502.7379405,
                        "modified": 1698663502.7379405,
                        "name": "/dev/shm/incoming/2023_10_30_105757_00_00_K_117.jpg",
                        "sha256": "b742d5560c722f3f8f151f0d9428cbcc08b8974939254f94fd78238c9f84733e",
                        "size": 1149947
                    },
                    {
                        "created": 1698663501.1679406,
                        "modified": 1698663501.1679406,
                        "name": "/dev/shm/incoming/2023_10_30_105753_00_00_K_116.txt",
                        "sha256": "7912024dfc6f8e03b4de7282202eecd326c90942093ff5f06aad832fd57787aa",
                        "size": 2045
                    },
                    {
                        "created": 1698663500.5879407,
                        "modified": 1698663500.5879407,
                        "name": "/dev/shm/incoming/2023_10_30_105753_00_00_K_116.kml",
                        "sha256": "216fbfcc311e9e0b3a1f2644fc628a5d53759b2813e57d618bffde9c325ede06",
                        "size": 977
                    },
                    {
                        "created": 1698663500.1279407,
                        "modified": 1698663500.1279407,
                        "name": "/dev/shm/incoming/2023_10_30_105753_00_00_K_116.jpg",
                        "sha256": "495c27a3afc6842775f51d97f93be0b205f7cd4052662903005ccfcfc4fe14c6",
                        "size": 1125763
                    },
                    {
                        "created": 1698663488.3279407,
                        "modified": 1698663488.3279407,
                        "name": "/dev/shm/incoming/2023_10_30_105745_00_00_K_115.txt",
                        "sha256": "9fe54948e3388c416ed87ef075a6baee38fd51da059ee55d6b2028ba5fdd6ccc",
                        "size": 2046
                    },
                    {
                        "created": 1698663497.4979405,
                        "modified": 1698663497.4979405,
                        "name": "/dev/shm/incoming/2023_10_30_105745_00_00_K_115.kml",
                        "sha256": "7ba99530041aff4587471a352cb2269c27459c8b19f8ce6e36ddd3de73972327",
                        "size": 978
                    },
                    {
                        "created": 1698663488.8779407,
                        "modified": 1698663488.8779407,
                        "name": "/dev/shm/incoming/2023_10_30_105745_00_00_K_115.jpg",
                        "sha256": "60eb1520831b70a7c8157b56ebc6ef2cc4855fa03fc0afe0edd0d60ea692364d",
                        "size": 1112646
                    },
                    {
                        "created": 1698663481.1679406,
                        "modified": 1698663481.1679406,
                        "name": "/dev/shm/incoming/2023_10_30_105731_00_00_K_114.txt",
                        "sha256": "7aa47e84a4dbf553969e7ba520501ff77f1aa26207cfce1a291b1b706b9b9569",
                        "size": 2045
                    },
                    {
                        "created": 1698663476.3279407,
                        "modified": 1698663476.3279407,
                        "name": "/dev/shm/incoming/2023_10_30_105731_00_00_K_114.kml",
                        "sha256": "92e7e14691cf137f0c0a464f36788fd066261de0ce9f3f2a437f23a58380d982",
                        "size": 977
                    },
                    {
                        "created": 1698663474.7079406,
                        "modified": 1698663474.7079406,
                        "name": "/dev/shm/incoming/2023_10_30_105731_00_00_K_114.jpg",
                        "sha256": "1c5589a86395173a9217f5c0d6aae2b43d6eb925155a9e5481a07a85ecc5147f",
                        "size": 1333644
                    },
                    {
                        "created": 1698663451.7379405,
                        "modified": 1698663451.7379405,
                        "name": "/dev/shm/incoming/2023_10_30_105714_00_00_K_113.txt",
                        "sha256": "0e04fc37634787e81699364d50404e2e7a547fcc5c8e5b2c98498ec647bf5530",
                        "size": 2046
                    },
                    {
                        "created": 1698663467.1179407,
                        "modified": 1698663467.1179407,
                        "name": "/dev/shm/incoming/2023_10_30_105714_00_00_K_113.kml",
                        "sha256": "c1284f23e62694fed76b4df4cdffe1f00d2ea8a7ddfcd519c08abb9da626f3a4",
                        "size": 975
                    },
                    {
                        "created": 1698663453.2079406,
                        "modified": 1698663453.2079406,
                        "name": "/dev/shm/incoming/2023_10_30_105714_00_00_K_113.jpg",
                        "sha256": "dde8da51007e85759b29805617505cacbc30c8e5cd8a2eefc92562f6b8f97385",
                        "size": 960742
                    },
                    {
                        "created": 1698663438.9779406,
                        "modified": 1698663438.9779406,
                        "name": "/dev/shm/incoming/2023_10_30_105704_00_00_K_112.txt",
                        "sha256": "3bb617db9106c35c50e10539cce9bf466f679ac8448da16035092e525ee47153",
                        "size": 2041
                    },
                    {
                        "created": 1698663449.9879405,
                        "modified": 1698663449.9879405,
                        "name": "/dev/shm/incoming/2023_10_30_105704_00_00_K_112.kml",
                        "sha256": "9dbf9cca640896212118d0ec6c8dfc678ea428c7f3a62ec0904b46b0fdad3a3f",
                        "size": 976
                    },
                    {
                        "created": 1698663437.9479406,
                        "modified": 1698663437.9479406,
                        "name": "/dev/shm/incoming/2023_10_30_105704_00_00_K_112.jpg",
                        "sha256": "9dceb043966ec73bb267b3da9dd39be39bd129f8ee83ac4dfda467d01173a2de",
                        "size": 1195799
                    },
                    {
                        "created": 1698663347.1179407,
                        "modified": 1698663347.1179407,
                        "name": "/dev/shm/incoming/2023-10-30T10:45:45.ais",
                        "sha256": "3a518b9da17e29f31dceccbfcbb89bafb28c99f69d5e25cd86e9bc0aac99f931",
                        "size": 404125
                    },
                    {
                        "created": 1698663270.6179407,
                        "modified": 1698663270.6179407,
                        "name": "/dev/shm/incoming/2023_10_30_105402_00_00_K_111.txt",
                        "sha256": "1a265fe20236a7ce2f7f64e80b548aa8acb7d41c52941cd4fa75eb4185ae0858",
                        "size": 2045
                    },
                    {
                        "created": 1698663273.2579405,
                        "modified": 1698663273.2579405,
                        "name": "/dev/shm/incoming/2023_10_30_105402_00_00_K_111.kml",
                        "sha256": "5f9ee1c724f5e4bee9d8255655100aaa7e6d60ab6b6f52b27ddb774b08b81ae2",
                        "size": 979
                    },
                    {
                        "created": 1698663283.8779407,
                        "modified": 1698663283.8779407,
                        "name": "/dev/shm/incoming/2023_10_30_105402_00_00_K_111.jpg",
                        "sha256": "510ddd471126aa538f64cd658b9a799500e87653e5fa0148fce1659bed8207d5",
                        "size": 937311
                    },
                    {
                        "created": 1698663187.6279407,
                        "modified": 1698663187.6279407,
                        "name": "/dev/shm/incoming/2023_10_30_105246_00_00_K_110.txt",
                        "sha256": "9ca7369a44b0d45b4dad18ad23e325ee287b52eb1499820f4f411e8b1ec1ecb4",
                        "size": 2045
                    },
                    {
                        "created": 1698663189.4579406,
                        "modified": 1698663189.4579406,
                        "name": "/dev/shm/incoming/2023_10_30_105246_00_00_K_110.kml",
                        "sha256": "42f1c10781935a707487af44d6ae60ab49e9b4fcc372a2f3b2813e797ee86017",
                        "size": 978
                    },
                    {
                        "created": 1698663186.5779407,
                        "modified": 1698663186.5779407,
                        "name": "/dev/shm/incoming/2023_10_30_105246_00_00_K_110.jpg",
                        "sha256": "38e61b71e296522f68dc6b2c105604bb438e784f90ab871d9e14dda46c5bd94d",
                        "size": 752472
                    },
                    {
                        "created": 1698663185.8979406,
                        "modified": 1698663185.8979406,
                        "name": "/dev/shm/incoming/2023_10_30_105242_00_00_K_109.txt",
                        "sha256": "87ed33a0405fd5c88bbba9efbe1c3e995e2f702bd4a2b5d8a154dbce39cb774a",
                        "size": 2044
                    },
                    {
                        "created": 1698663185.5379405,
                        "modified": 1698663185.5379405,
                        "name": "/dev/shm/incoming/2023_10_30_105242_00_00_K_109.kml",
                        "sha256": "abec78ebd1d8188e3562b04039e2393801d78e12f779a97d304d80c50eb31234",
                        "size": 972
                    },
                    {
                        "created": 1698663188.4479406,
                        "modified": 1698663188.4479406,
                        "name": "/dev/shm/incoming/2023_10_30_105242_00_00_K_109.jpg",
                        "sha256": "735cbf6b551fe58ac4e9d13a5c91230f9c4ef5602c1dad87c06bb8a3b8d2af9b",
                        "size": 724079
                    },
                    {
                        "created": 1698663183.1479406,
                        "modified": 1698663183.1479406,
                        "name": "/dev/shm/incoming/2023_10_30_105230_00_00_K_108.txt",
                        "sha256": "bcabc0e73ba7c0501675525363efe3a77054ff4f760ff42e9ba9d9ff4147e1af",
                        "size": 2046
                    },
                    {
                        "created": 1698663176.3879406,
                        "modified": 1698663176.3879406,
                        "name": "/dev/shm/incoming/2023_10_30_105230_00_00_K_108.kml",
                        "sha256": "678aa32924c7d69d7f0888f53ee7a240932e8242c86812c3f6ac18cafa8990b2",
                        "size": 977
                    },
                    {
                        "created": 1698663172.5379405,
                        "modified": 1698663172.5379405,
                        "name": "/dev/shm/incoming/2023_10_30_105230_00_00_K_108.jpg",
                        "sha256": "8c474f3e0e90138978a0fba9a5bfa5bbad45593b44aff9cf221a0346fd3b5553",
                        "size": 1115102
                    },
                    {
                        "created": 1698663183.2679405,
                        "modified": 1698663183.2679405,
                        "name": "/dev/shm/incoming/2023_10_30_105224_00_00_K_107.txt",
                        "sha256": "b2e72d9109aa5d3aefcbd1056de1e537cd9964c1dc8f42b97535ecc72d7cefb4",
                        "size": 2044
                    },
                    {
                        "created": 1698663166.9879405,
                        "modified": 1698663166.9879405,
                        "name": "/dev/shm/incoming/2023_10_30_105224_00_00_K_107.kml",
                        "sha256": "d247b34709fd9e1f7a56f723e8417af26b282cd13426111638ebe37250d77903",
                        "size": 979
                    },
                    {
                        "created": 1698663160.2379405,
                        "modified": 1698663160.2379405,
                        "name": "/dev/shm/incoming/2023_10_30_105224_00_00_K_107.jpg",
                        "sha256": "c20020bbf5bfb3f15bd680a65ef18f15cd456ee006561ceaf112030a0739be2b",
                        "size": 1121609
                    },
                    {
                        "created": 1698663159.5279405,
                        "modified": 1698663159.5279405,
                        "name": "/dev/shm/incoming/2023_10_30_105220_00_00_K_106.txt",
                        "sha256": "17cfeac2a2fb7c38283fa5c9a1977142e717718ac4d75f6e4303379330297ac7",
                        "size": 2046
                    },
                    {
                        "created": 1698663159.1879406,
                        "modified": 1698663159.1879406,
                        "name": "/dev/shm/incoming/2023_10_30_105220_00_00_K_106.kml",
                        "sha256": "b5d82aa6cc78efd60becedfaca43a55d2acdaf2e1891b46678899efbb92ea5f2",
                        "size": 973
                    },
                    {
                        "created": 1698663158.3279407,
                        "modified": 1698663158.3279407,
                        "name": "/dev/shm/incoming/2023_10_30_105220_00_00_K_106.jpg",
                        "sha256": "4e4c2265205d3d708fb3ed48e5f5a50d01d3173043260bfcfdf48547dfae6800",
                        "size": 1201243
                    },
                    {
                        "created": 1698663148.2279406,
                        "modified": 1698663148.2279406,
                        "name": "/dev/shm/incoming/2023_10_30_105204_00_00_K_105.txt",
                        "sha256": "25484fa6252467bbe13c0b7b8b7755434a96b91075804828fa81954bfd8da458",
                        "size": 2046
                    },
                    {
                        "created": 1698663157.4679406,
                        "modified": 1698663157.4679406,
                        "name": "/dev/shm/incoming/2023_10_30_105204_00_00_K_105.kml",
                        "sha256": "b547a52085f69b98763fc105b8644123fb63f2594c19e82c292e044bde56209d",
                        "size": 979
                    },
                    {
                        "created": 1698663156.3179407,
                        "modified": 1698663156.3179407,
                        "name": "/dev/shm/incoming/2023_10_30_105204_00_00_K_105.jpg",
                        "sha256": "8ed1c64ecf29dd6c042ac1966ea17414d8d0d72bcf8ab92940374c302d303d3a",
                        "size": 991644
                    },
                    {
                        "created": 1698663061.4279406,
                        "modified": 1698663061.4279406,
                        "name": "/dev/shm/incoming/2023_10_30_105042_00_00_K_104.txt",
                        "sha256": "44ea85e434293fbcf6ddccdbee7c552b5b2900fa0056a50c4feee3c797e4db5c",
                        "size": 2043
                    },
                    {
                        "created": 1698663069.8279407,
                        "modified": 1698663069.8279407,
                        "name": "/dev/shm/incoming/2023_10_30_105042_00_00_K_104.kml",
                        "sha256": "68cda9ccc8e669f86b4b84911da566a5a4362c00bf63d2323f74431a59de9877",
                        "size": 978
                    },
                    {
                        "created": 1698663061.0179405,
                        "modified": 1698663061.0179405,
                        "name": "/dev/shm/incoming/2023_10_30_105042_00_00_K_104.jpg",
                        "sha256": "868ca670db3d159ffdb17e8eaf916792401225102429350c50b219f3222b247f",
                        "size": 960872
                    },
                    {
                        "created": 1698663054.4679406,
                        "modified": 1698663054.4679406,
                        "name": "/dev/shm/incoming/2023_10_30_105028_00_00_K_103.txt",
                        "sha256": "93f1389dd48aaca2528fd53c9c53c23b1732f2b696f051dd988952111af2a9b5",
                        "size": 2045
                    },
                    {
                        "created": 1698663056.0579405,
                        "modified": 1698663056.0579405,
                        "name": "/dev/shm/incoming/2023_10_30_105028_00_00_K_103.kml",
                        "sha256": "18cc4f539ed3a0863b4d90b32027a3dffdaa9584c93a174cac8c6db4a5b69d3e",
                        "size": 977
                    },
                    {
                        "created": 1698663056.9279406,
                        "modified": 1698663056.9279406,
                        "name": "/dev/shm/incoming/2023_10_30_105028_00_00_K_103.jpg",
                        "sha256": "cc39e076b0316acc09db4ba9c90e1854cfab50cb51b9e16adfb3d03ca017927f",
                        "size": 1098203
                    },
                    {
                        "created": 1698663035.8479407,
                        "modified": 1698663035.8479407,
                        "name": "/dev/shm/incoming/2023_10_30_105017_00_00_K_102.txt",
                        "sha256": "b81073d6f2d6ea64a03d0eacebbe70c40f016880e4b0241697f9821ff6b56ee1",
                        "size": 2045
                    },
                    {
                        "created": 1698663040.6779406,
                        "modified": 1698663040.6779406,
                        "name": "/dev/shm/incoming/2023_10_30_105017_00_00_K_102.kml",
                        "sha256": "460c17699ac478fd82eee844c2f02965437c715c42036891daabc820a55ac218",
                        "size": 977
                    },
                    {
                        "created": 1698663040.7179406,
                        "modified": 1698663040.7179406,
                        "name": "/dev/shm/incoming/2023_10_30_105017_00_00_K_102.jpg",
                        "sha256": "61a695e6007cb6c60a6ccd4a20c617faee69299ed357f682011066e66daeb60e",
                        "size": 1277731
                    },
                    {
                        "created": 1698663026.6979406,
                        "modified": 1698663026.6979406,
                        "name": "/dev/shm/incoming/2023_10_30_105005_00_00_K_101.txt",
                        "sha256": "44ff6a4292a9d57f2e489b329406bd9504072c6790966fcbf4b7ad3d04413e07",
                        "size": 2046
                    },
                    {
                        "created": 1698663030.8079405,
                        "modified": 1698663030.8079405,
                        "name": "/dev/shm/incoming/2023_10_30_105005_00_00_K_101.kml",
                        "sha256": "648f357be2e5b6f1d1916c0ce18eed5af55d67af7e520ab4d2f119b81010860b",
                        "size": 978
                    },
                    {
                        "created": 1698663030.6079407,
                        "modified": 1698663030.6079407,
                        "name": "/dev/shm/incoming/2023_10_30_105005_00_00_K_101.jpg",
                        "sha256": "edc381282837e3759b91a5abb33c5994deecd0f0a8f9d98601d774511d70f725",
                        "size": 1319238
                    },
                    {
                        "created": 1698663000.6179407,
                        "modified": 1698663000.6179407,
                        "name": "/dev/shm/incoming/2023_10_30_104942_00_00_K_100.txt",
                        "sha256": "29658cc180eb990b516a0755bedb3ddc4227e7f610af6c2c3287066d92443101",
                        "size": 2038
                    },
                    {
                        "created": 1698663000.5179405,
                        "modified": 1698663000.5179405,
                        "name": "/dev/shm/incoming/2023_10_30_104942_00_00_K_100.kml",
                        "sha256": "c243f227aafbb3e234fd746549d4bd4db44e42c9934531ec64f861bbd9a6e7e5",
                        "size": 978
                    },
                    {
                        "created": 1698662998.8679407,
                        "modified": 1698662998.8679407,
                        "name": "/dev/shm/incoming/2023_10_30_104942_00_00_K_100.jpg",
                        "sha256": "ba2ca25ddb1d45d252cfe66cf55033f7115b30c157de0f320f42558e4583d7a5",
                        "size": 1290322
                    },
                    {
                        "created": 1698662996.6679406,
                        "modified": 1698662996.6679406,
                        "name": "/dev/shm/incoming/2023_10_30_104915_00_00_K_099.txt",
                        "sha256": "203c6c4d34c30acda72ea9f5b97e0969ec393d5b73372e35917fe5fd22477a33",
                        "size": 2049
                    },
                    {
                        "created": 1698662983.7679405,
                        "modified": 1698662983.7679405,
                        "name": "/dev/shm/incoming/2023_10_30_104915_00_00_K_099.kml",
                        "sha256": "e778bf69bcc6f0609c077c110bf02b2da4fbdde9d9ce00de3bb89e35aa3e006c",
                        "size": 975
                    },
                    {
                        "created": 1698662973.3779407,
                        "modified": 1698662973.3779407,
                        "name": "/dev/shm/incoming/2023_10_30_104915_00_00_K_099.jpg",
                        "sha256": "9cef33290522027cccb971afe2493810bd51d33a20106d221e89e6877489b554",
                        "size": 872681
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "ad92f6e4ee5eb92e70840964ee66fc77c71c00c4417296c09a151674b5ec9148",
                "size": 27604902
            },
            "errors": "",
            "filename": "2023-10-30T11-01-56Z",
            "size": 27604902,
            "verified_at": 1698683743
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698567435,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "FXn1UpDNdnuoRa7hzQt2oAp+p96pQUofddqeD+fErJXGIKBjBW7tMwi7HRKg6K+ZPNUfhVVH9Iq9QPrBMUi+8wuyJaOzo5lN0DQsjig218HB80E3IgNNDQJzDj4CbJvZjtns0A0eT0uAaONa7MJOVMQyvqGWtzJNgZ8JlzLFYXEJBKVYK4U5leKQTevMGCPcjO2U7umQw5QviWVkYV0i/Zy0CDvdGIXRT1vEhNmt2ZuYZdg3+75M+imbOCb9EFMQjkSrjaJ/Q8SYdrXEIq+eW/PjTU5usshxxaNz1RUtu5tj8/oq66hem+s4czs1VFpiBzzpnvIkni/+GfdX9fGgaQgQQuqNaTIMWtvo1OWPYIiwQr5FxaIpPz1tV7moQLSVMzKrGfFOLe/sDqW+x56pkTbMizo6a6TBkyAcL/HDW20Qc67SBKiYiySv2/S2F6VCWzVPKDOxMzKVU+kBH8gFtxlY3WjDJ1/puu8/wLBtoTaIfpbP44kUXS2dJKNupfJyQKxYfjqI3IGWJFJMD4igAnP83duZTeGthPsLAtkDxGe4+yjOVsVc1GT8kKFir1lHn81CpmFmRT1XPMQP4y65oWSHSE3kVpEtfhsxzEQfFMBqb8I4cp44wJD1gaHj8hAL8gs4YIUkNP76LM+ctTlalk9md2ntfrxN0yZje1KVFyw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-16-29Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698567260.257969,
                        "modified": 1698567260.257969,
                        "name": "/dev/shm/incoming/2023-10-29T08:06:02.ts",
                        "sha256": "f9f5a380dfa3e86aba54d6a5d8d3bc91946d4e952933cadec65f7ae19bcdd7de",
                        "size": 90574640
                    }
                ],
                "mission": "OSP3",
                "sha256": "ba82bfab7207331b39b7f22e33bc708dcb765c09ac90f396c40598f36b062870",
                "size": 86822927
            },
            "errors": "",
            "filename": "2023-10-29T08-16-29Z",
            "size": 86822927,
            "verified_at": 1698578699
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698567365,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "hv1BPgkIcGXx+YNpFc0MLRsjyTr6J4JJqRwgUY4q0nj7vIBjiv0v2aPxTlQ9p5xNP7E2TXvvg4QwBuXH5bQyB5XOidnbTF6pf+uHo35rrx5fqmVxtYsOSoNiKM7Tzm/pRVfMEZ8zE3z4dwAfhckYBdjOBpkbRPl1aJ9NiwQvGZYGjg1yvl5hZcDPrAT1uPV2wWbGMKYxjImp2dO8vq/7+Ytf8GUv5rdncEAGT/L44P6bnmQVTYyo4OeZxzIKsulDJCm/kHHfyO9sf+5ph11s0ZkfgIMQNYsWF5deU8Q/pL4rPeUcTYumPwykGNBWUj9Gb3t0xWtB0D7cbAmfg+QzfafXO7BZUU9xDyl/hSE3FX5VOkgNlevxLG4g7gE3FPDJYdkTdqKNBGcxxuUra1qiH7vh8TNDz7iiK1UABwaAC0VGGsc8V/zdbSrdsduv2VCLs4NidGuyvW0+svwDB7zUG2Q7231DdazwYHzTBzW9BvdCD7CYsg9SqWQkr0lmA+nM83ajVWElA4V/W++PlnNlsS6NNpEm2ZfPxI2dLcK6BFl2gBDLSFp1fH0OAumSFZ3x86pVZBwoiYyCIczR5wPCrbLaqQVE6DjdjQoQqJwmCaXfD5VwCe5INVDKrqihSCVpO7NUvRCdr0kZg3NdjiiBKf99Rd/y0mdVsRxxl2yin9E=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-15-47Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698567220.6008658,
                        "modified": 1698567220.6008658,
                        "name": "/dev/shm/incoming/2023-10-29T08:03:39.ais",
                        "sha256": "d9a6f05ffa1cd65de006f90f5c70606c2685f4e34670e8355556478e779821bd",
                        "size": 302420
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "aed4cd381257ed5f3493fa0289308e0283105a8353adc9664b11ac142f053cfb",
                "size": 82672
            },
            "errors": "",
            "filename": "2023-10-29T08-15-47Z",
            "size": 82672,
            "verified_at": 1698593955
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698567761,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "UUBLQB9+qzhYAleIYSxr+zjhfM89xi7Gfue5RU+6UddzE6JFrPTSCkwLoGG8mA2GraUDIu1LSqLrfY4R5534QJDDJHdiXjZaSb3VnX28RnZbeIHajCU8MN1oBXZDq9XLPV41cjHRVGROgDX5ESdiJuCt0nV1a9qg9GuAvMrKxqVVNR80MBF1Al1kVkc0d+2FDNOWMz7pKDGxXSyCcmUh3//YHDuKiFEwP00hI7bPni3tmAorYlf3EwbrHXz9fpYFv6RAqzkGz9eu5wcpQHM4iA6twh+AAl3Le10BOuMLLYVBy0kULUvce6wFZdIxuxxL4gJbk2OeaSEWrSmRG4HqYhOA4gZVxW3UctK1GYuHtvkjkg/rsFPX9vqzA/dUNqH8BSe/5S+JTs9AfWMQtHH0rah4HjmceNGrZisYn1qicyDk3PhNirRnGagUFiD5dQWUc5BvlCE0co6+vvpEkrsHsIIxXuHs6Tfru866sS8bFoR6MC1DgIVfcJ/2I+gMW/AoyGc38gPnQg79Xd/QtCRGu/MBBTMic80BAtZlczdRWe+TsBKZwsN+yYdBVjuQQQ0Np5ko2ha/6sD0egtkB8LvNTEotpLF0qKA3yw4aI0mMMve22HEJDrMrqIH9WYEG4kaeNqMx6+xmFl+/oWhoVcNjHjLUDENIDRxsd7PhsAeul8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-21-19Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698567553.4932342,
                        "modified": 1698567553.4932342,
                        "name": "/dev/shm/incoming/2023-10-29T08:09:12.ais",
                        "sha256": "b619939a1b59225e3da959c3e5994072ac30c2b50a4e22a559c0705189fd5629",
                        "size": 525287
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "e9c39e7bf035339359652d4aae9529bbec5b6d5428afce769fff4ebd5430a437",
                "size": 160957
            },
            "errors": "",
            "filename": "2023-10-29T08-21-19Z",
            "size": 160957,
            "verified_at": 1698595011
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698764816,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "Mpou/X+EAUJ55i5Y3PDUw7npKZqMXEiLV/YPT+J0zNnkpw+f5tobLdpDAr5xeeK3UjQ3Ld52Fyrd9DgRbKqqAyPE4xACm2wKJDaMnZgW4AaWa4q4ApZkXsMvM1xG8a1IJA6kQjBGO/aRTrOuikBULJQN7vPIsAfndev0S0uSpqvzWiLxOUykDxFjUVh1S8hdpJ65GGwR+PX6fdklpO4olehpoDfkhUl4BqEO3HJnAUaHO9WDX/5Ao2RL8hocIgW0zu/L/ycLdZqBheLqtE0Gun1RMOVdtHbqDjd44JzXHT6ORru1c5uS/+YBMVDz1vMVL9NdRmVsCHsm8bL4eNH4rN7cWBvrkB+OGfamw49RM2v1R8OsXOL6i2bk/Jizq2ZkN4NWAn/+2rlcfyTD9PqjgFPUKexY5+a+PE8YLQ4I3u9MWGQjVd3fG+fbw9yx41csBPcLGFE3fKEpNHkplC1iE1UvZyyVid4rqrTQimCfSR+s3mv4CI28fiMSyGisB/QIQ2vWAnX8KbrMVg+sQkpPP6Y3g8/2U577Y2F0ke4JhVyXg1mgvuyFip7ewvxa+iGtJrTXkAOdGnW/3SC2UJ4xszDu9ikPHt7WuxCdVacE4GNbkSxNo1FibPAyj5U5rztcjyVI0xlBl/Fh1IhNCJhSOaxRx+pzNgTz2QdK4IWugNY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T15-06-53Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698764688.834362,
                        "modified": 1698764688.834362,
                        "name": "/dev/shm/incoming/2023-10-31T14:54:40.ais",
                        "sha256": "80c22115c809f1b671e7441a7310a1051e32cf5e69c9f4faaa9b2e84fa216c2e",
                        "size": 5893
                    }
                ],
                "mission": "EFCA1",
                "sha256": "99513fddf0f63fae9d37719bb8f7c9966f5067d790c060583e70226bf4ac5362",
                "size": 1713
            },
            "errors": "",
            "filename": "2023-10-31T15-06-53Z",
            "size": 1713,
            "verified_at": 1698781674
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698764985,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "M8Yyp51fenQxGHYhOYwUG0q93c4wA8dm7DizMuQ5B/Nd8vM/RfeZbpAnStYsIdwagT4kBUn5GP9JqnN3W7cwSOkOnVtyngnHgvHTm8irlokNGzXZxZpQ4lAixEY5HKiw1a9FKr6gKltnxsrbnOFEnB2LV5dmBAO4kNijqGuDPJTCqb/HYOkCTHNjekxcchs2juxDE5lMEhlG5P9AR0oyT1y6gqHhfTqxUgLGcfe5EDS6pyPJpAVjNWWuJ5wg4P+0fa5ZgXjJNS9C27dvU2IeJaPICy2D+rE1D2mPOMb+UHEmydFRcriGYgBdTpZoB2nMrkaNfFl4hgUYgLtpAZkS0/5CW7Iim2QgmHpfMsTUtzHhSGrBZpLZL2JoPpAkFzsPPdPntzxjGI6OtYsNk3mt3pJvKDptCTWHH5c+OCAZmuAQG8fOgbg6uZw6ErYD0p2c7psvaw9sq8T3aLxgw/ghfLMao3UmkqF8+d0xxDtD9BLeaSTghi9vi5u9KYGU4XRKflYv27HJDwaUI1+b80dQvdu9L5gvhHyOq1af/18pEKMk6Dv2I25cWjyxTFotizZ+fjngbK84nILrPFqmJCYn56ttfQWedGFA1ABI4fnx4XxLHvjkxZfsxw0yOgr5QpIOGiNW4zfdLz4O1KJBJVPgi7nh0BQBZzHu/s1oC9IUSAg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T15-09-25Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698764846.263036,
                        "modified": 1698764846.263036,
                        "name": "/dev/shm/incoming/2023_10_31_150709_00_03_V_009.txt",
                        "sha256": "cf99b9e846ed311880e387f7b236a2e15aa37d06c4a6d9f5811f1b368969f679",
                        "size": 2026
                    },
                    {
                        "created": 1698764845.933036,
                        "modified": 1698764845.933036,
                        "name": "/dev/shm/incoming/2023_10_31_150709_00_03_V_009.kml",
                        "sha256": "92133bd78b1174958be19b8c7e4a0a9f7e31833f937d86e63188b8edf443d5d0",
                        "size": 977
                    },
                    {
                        "created": 1698764845.923036,
                        "modified": 1698764845.923036,
                        "name": "/dev/shm/incoming/2023_10_31_150709_00_03_V_009.jpg",
                        "sha256": "a661aedf78dd681105a24df74b17668cf5c522ac8211e56512115ca8691ad617",
                        "size": 1095693
                    },
                    {
                        "created": 1698764818.433036,
                        "modified": 1698764818.433036,
                        "name": "/dev/shm/incoming/2023_10_31_150635_00_03_V_008.txt",
                        "sha256": "8540fb2eaa951c1c106417de68592fc2d99f4d9d220334a0d72625bdf0f6cd07",
                        "size": 779
                    },
                    {
                        "created": 1698764818.023036,
                        "modified": 1698764818.023036,
                        "name": "/dev/shm/incoming/2023_10_31_150635_00_03_V_008.kml",
                        "sha256": "4aeb220ac65a78f5e3e759e519e657cdae64855aa3cfed3c14046770e1f62c71",
                        "size": 892
                    },
                    {
                        "created": 1698764808.3030362,
                        "modified": 1698764808.3030362,
                        "name": "/dev/shm/incoming/2023_10_31_150635_00_03_V_008.jpg",
                        "sha256": "95f95c30559265945b1e962ae60a72614ab594843fb2c795cf8e7faf94cb8aef",
                        "size": 1123172
                    },
                    {
                        "created": 1698764789.0830362,
                        "modified": 1698764789.0830362,
                        "name": "/dev/shm/incoming/2023_10_31_150613_00_03_V_007.txt",
                        "sha256": "79da546012f4c10cc849b36eea878b38762c8aafe8148f21743873fb37c0703e",
                        "size": 1524
                    },
                    {
                        "created": 1698764787.713036,
                        "modified": 1698764787.713036,
                        "name": "/dev/shm/incoming/2023_10_31_150613_00_03_V_007.kml",
                        "sha256": "22b5b14c1f240bc1501eee324dd1140b048dbaea74d3ddbcb2cebfe796253b67",
                        "size": 938
                    },
                    {
                        "created": 1698764787.503036,
                        "modified": 1698764787.503036,
                        "name": "/dev/shm/incoming/2023_10_31_150613_00_03_V_007.jpg",
                        "sha256": "11b5d51a8bb2c5d0415a40cd760b1676b13704657a76c21ff8fe7b4f299b79ae",
                        "size": 1019486
                    },
                    {
                        "created": 1698764784.5530362,
                        "modified": 1698764784.5530362,
                        "name": "/dev/shm/incoming/2023_10_31_150506_00_03_V_006.txt",
                        "sha256": "ebbf602c741e27a25c9b9bdd3d269717ef708202eeca1009f4dea97c1d7c6306",
                        "size": 1529
                    },
                    {
                        "created": 1698764730.203036,
                        "modified": 1698764730.203036,
                        "name": "/dev/shm/incoming/2023_10_31_150506_00_03_V_006.kml",
                        "sha256": "9416a70de2ee8dc987e876d029000d27e3516db4da3290bc8c439f566722f5bc",
                        "size": 936
                    },
                    {
                        "created": 1698764723.6230361,
                        "modified": 1698764723.6230361,
                        "name": "/dev/shm/incoming/2023_10_31_150506_00_03_V_006.jpg",
                        "sha256": "d704db0a50da35518f850e1c284bc3186e914526d07d0cb252665a1642ff1a37",
                        "size": 1089178
                    }
                ],
                "mission": "OSP2",
                "sha256": "e4cd1289bb2d20c3087f1af3ecddae417a600f60b205d1929e537cae1704e7d0",
                "size": 4333947
            },
            "errors": "",
            "filename": "2023-10-31T15-09-25Z",
            "size": 4333947,
            "verified_at": 1698783396
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698567900,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "kI+W+lF2/FZEKOuRvQ7sRd7D55EFKXYmZfIc8oMyL/7heDmKteQtLifMFqWO9JnBWPJfGcJ9aHKR5pNHmrodQGkXWkjx99R1QmHwgJDg8GcafHBMyrOU7+6VsYYy9tjTfZaPzNjlxMeY+4SgO/IJPv8YMlXXwoBc4zJryiyP8ts60L21T/Bnzp9CYfRt9ZsxC33azfQfNY4Qhk07jS+cTPB8yqWoxRKP7bG10ibLmEywFVPz2Q+BERgeTsralI5rksoyGPEGelLU5wMds0e466mfowGw3LnZl6bsjEcDNRtpthczQ/tqxOaDK1qLwZ3VhCxQgeNfJcdrPdXrHtM7Z6qog+u8+yMXjQgevfuKCJL1OIhYm9tCPCWOHGns/o6m1tTnzenaKhXHC71v6SYhqUGlBBwULR9NDkfsH1MVQ8LkfEiuXsq0SKW89YRczdgoxTO8ksk3BNGmhwOkjywqUXNlRlWcNVU2y4/lL5O8VFL73viO9spah69VlhkrVLIaLIdzYDiwKwPacUhFQdPwXwyc5Vnl/555fvAzDANlPGE+J1w0vg76yzz2ruYE9vrf1Jcpgo7ryhlGRVEZn9IqSpPnbOoGT+pzwmlphX4o+EL7eTyt5/qFQ1tzj98Nd9oiGM6DH2tzZce1JgujrpNhS/m4rR/N3HuN55DYIdHl/9M=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-24-36Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698567752.3409402,
                        "modified": 1698567752.3409402,
                        "name": "/dev/shm/incoming/2023-10-29T08:12:30.ais",
                        "sha256": "9ee50a08cb18cbb65b0c5ae23cfa5b96656c0c5f4a8563759f6b9c3a60558b94",
                        "size": 110396
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "0873a52bd9382655ec4ac587e62eb83aa7d556e33f37a07625a1a1699de50675",
                "size": 30585
            },
            "errors": "",
            "filename": "2023-10-29T08-24-36Z",
            "size": 30585,
            "verified_at": 1698579405
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698567964,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "aJKoof7OVLEBa7eRZGHl4QS9N4XkZjb1ccpWOAfJ4eS8+Ash/HRbSbT00NzuTsP2P1BvpdRxr/IPv0WzOYLvBK9Hdk3H+5vb8y8FH+oE+th//ZmpDXVHyO2RU6H4Jdy/kNLSRUdxJW3UICn5egERKvO7zEt81AT8PBGobCuHcaWZVVJHW9aW+G5Nb7woO/w/ElZSDvsjG1XnJaJpAZO6n0CRqAWbwrPv+dzSoyMr9oDSBwgmy3Zs/lNIE3O+9ujYIw6QMYfvQmdTYOs6M1kJGGbV/rFrJA+Ev+WT/lwjuHvW3u37dg1xZuTgMWxDDDnM0Nkz2CqIYkI4gXSZEKzyeMSIHv1dvb82lRYQxQySb0BlT6PUE8FRoQ2BaEFmQnHyMNjlxelpJMCQSEoYVA755UrbPNwhXLDT50+4ELWjiPoY3P+0ryp7ePRNYIpOJyBGq3XnaV5CoJQfazG+Q0t7IxfUXn1k1Ix76KBaR8okMhWnsMvtWBUdwgnYFndCP9tQzraSHk9L914YcA5nKGDJEv7+n33FLI8LUG2v5fPzQG4I/LOIu5YWVtQ0jVvwreYTI0szCDxX94LrP70xcrrdQrOpISnxi1gLHOjHj5toZkcr4MEaoY7b2wNXXbGg1hjc8eUQ2vkeLny0uWUds2akJav+C9DJrRcZ5Z8wvw/a4fs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-25-45Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698567821.3408659,
                        "modified": 1698567821.3408659,
                        "name": "/dev/shm/incoming/2023-10-29T08:13:40.ais",
                        "sha256": "f6087e7fa03adaf53690fbf9f48573e601ada3bd65fb777b0112aeb43c445638",
                        "size": 841410
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "6983341bb0c06a92a1cca0d7a554922ac8e7d5906f390470fd5035675e14c1b8",
                "size": 237905
            },
            "errors": "",
            "filename": "2023-10-29T08-25-45Z",
            "size": 237905,
            "verified_at": 1698593994
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698676576,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "h0OSR+x06ic54C9EAR0yIogLjAsCiv8XMu371R5LqoPVrNlfsxPhPZbwagnQFem9ChfAUAjoMTnyzStSGGsiSft8pMha8H8fzITGH690Tw3g6kM1WTnediioeE5irBS6LUcOBa2hG7/vEIf5d5SSZnlFmL6OFzmityxXxiBqCdb8egkzWAC+fARH8/ajR5KTE9qJzQcwOZ0HbZkHHLOZlp+s6DY1XjXGUdmLuAQbad667SnVNshf3wgidykjPqK70v4InBnnERhSd9uRwyJ6NiBt0CGaMpf+Q8tkAISKpuEfRRSdRgrU2eeoOyaNPCl8srqluAGV8Imc+yF3BQKXnAOC+TI4yKBHfyk+9XfNJupZsPxigu616BB7OPiZqUNoiinYQNzguUCCnIk1iI/MDvQGv1zk9Cib364AP6vmDgoSRwCl4VdKxmIagKPhbK+OtmTN03HlZgCr0OA2ca0pEolEEM6J41OhqkDkt3qB/Ih6FtJdG7woir88jLyKNCQnvszLx5hEqQjC8RdJPUDAXuxc4g18AXtbQRVdVV9jHHUt8l8O6hQS/Jj15WnOI5idtV5X18wvESSlRdFbcHgJ6CPaiKwZ655m+tWxvnapj/w71/yrEQ/JQCeJlqAxZ8y45jMapMknYwJlC3zJy8gtL2Wwi79q6o2Y0fc9BTOY1Cg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T14-35-55Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698676428.9786983,
                        "modified": 1698676428.9786983,
                        "name": "/dev/shm/incoming/2023_10_30_143329_00_03_V_005.txt",
                        "sha256": "4012c0ff5bed08b6bec758782e22c5974ee872646770c1e61596535d05e7e225",
                        "size": 2035
                    },
                    {
                        "created": 1698676428.2686982,
                        "modified": 1698676428.2686982,
                        "name": "/dev/shm/incoming/2023_10_30_143329_00_03_V_005.kml",
                        "sha256": "fdd752b24dd52efa5690b78134a3def6330f01c6c2f7a08a61f8ad9f8fb8d8f7",
                        "size": 975
                    },
                    {
                        "created": 1698676428.148698,
                        "modified": 1698676428.148698,
                        "name": "/dev/shm/incoming/2023_10_30_143329_00_03_V_005.jpg",
                        "sha256": "42ce44abf2c07a54d596fcced47c1a988653476526f2896542e1f43df98e2226",
                        "size": 964881
                    },
                    {
                        "created": 1698676384.7686982,
                        "modified": 1698676384.7686982,
                        "name": "/dev/shm/incoming/2023_10_30_143248_00_03_V_004.txt",
                        "sha256": "afc70e836684b714ac00b08852882d7d0e181a95033498afc875b98aedf6ae9a",
                        "size": 2035
                    },
                    {
                        "created": 1698676384.4786983,
                        "modified": 1698676384.4786983,
                        "name": "/dev/shm/incoming/2023_10_30_143248_00_03_V_004.kml",
                        "sha256": "d8ee6bed2093cd65e5c4a30dd9d5be38d94629d40ef83523571a4401a5db00c6",
                        "size": 978
                    },
                    {
                        "created": 1698676384.398698,
                        "modified": 1698676384.398698,
                        "name": "/dev/shm/incoming/2023_10_30_143248_00_03_V_004.jpg",
                        "sha256": "323e3adafc963069d58ef601b9289b716ad1c6176ad4ffa753b15f911bf126fa",
                        "size": 979538
                    },
                    {
                        "created": 1698676362.1686983,
                        "modified": 1698676362.1686983,
                        "name": "/dev/shm/incoming/2023_10_30_143228_00_03_V_003.txt",
                        "sha256": "1bbad34110dabaef6d38a482f491f93344b2eee0efb92ee230bd5d9846be3b13",
                        "size": 2035
                    },
                    {
                        "created": 1698676362.0286982,
                        "modified": 1698676362.0286982,
                        "name": "/dev/shm/incoming/2023_10_30_143228_00_03_V_003.kml",
                        "sha256": "17deb67205cc33b0d688d86cb00b5ed60d3096c209cb76731bdbd2330c93da22",
                        "size": 976
                    },
                    {
                        "created": 1698676361.2086983,
                        "modified": 1698676361.2086983,
                        "name": "/dev/shm/incoming/2023_10_30_143228_00_03_V_003.jpg",
                        "sha256": "8f2f88049fe6550c0fe3b6c66d8446723e93f15273ef439d57eb0d4d0b59182a",
                        "size": 1002439
                    },
                    {
                        "created": 1698676308.1686983,
                        "modified": 1698676308.1686983,
                        "name": "/dev/shm/incoming/2023_10_30_143132_00_03_V_002.txt",
                        "sha256": "b226150c45650edde37618c8e96c9765c3b0f2a29aaf445f9aa49fdc1b65db0f",
                        "size": 2034
                    },
                    {
                        "created": 1698676308.0586982,
                        "modified": 1698676308.0586982,
                        "name": "/dev/shm/incoming/2023_10_30_143132_00_03_V_002.kml",
                        "sha256": "399ba609236757b41f57ae2fb0fe5ce6e2a1ea1cac32cceea21616dcbf6ac7bb",
                        "size": 978
                    },
                    {
                        "created": 1698676307.408698,
                        "modified": 1698676307.408698,
                        "name": "/dev/shm/incoming/2023_10_30_143132_00_03_V_002.jpg",
                        "sha256": "eeacef5c08adc7e044378b417b7ca4f43a2c5331efe09980f5bfb87c0d2bb867",
                        "size": 845108
                    },
                    {
                        "created": 1698676306.3186982,
                        "modified": 1698676306.3186982,
                        "name": "/dev/shm/incoming/2023_10_30_143014_00_03_V_001.txt",
                        "sha256": "99b7b21736f1c352a460a7163069d1a614fea720f91f25f0c1b59df2f56f4642",
                        "size": 2039
                    },
                    {
                        "created": 1698676231.8686981,
                        "modified": 1698676231.8686981,
                        "name": "/dev/shm/incoming/2023_10_30_143014_00_03_V_001.kml",
                        "sha256": "9420367c5f0a9e3140af22f190478267e677207650282c94b2035e5588decb85",
                        "size": 976
                    },
                    {
                        "created": 1698676231.0986981,
                        "modified": 1698676231.0986981,
                        "name": "/dev/shm/incoming/2023_10_30_143014_00_03_V_001.jpg",
                        "sha256": "4d02a3ce5b61d617c01cca3da4e594192d60e3fd2cefd904f62b5b8dff49d161",
                        "size": 965591
                    }
                ],
                "mission": "OSP2",
                "sha256": "7d7dec07746cb6232674955f6449cc4addbc2ee5a8e1c25f6909d78d5014ead7",
                "size": 4762416
            },
            "errors": "",
            "filename": "2023-10-30T14-35-55Z",
            "size": 4762416,
            "verified_at": 1698753115
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698568276,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Xo/oe4YU3u/17z5uhPeprXSQGCB0I7LiKJMAu23ywjG81gPNP1GSqz7MNbyWtm+G1WC/ygu1JhVp1zjVoNmqv+RWrqw1xewmptTTH72BODu3n2nlUN1WetM40BXMqoR3UxgpMm4u1tHsHXqFeYcBf4NnR/RiwPwRn5UJWczTBUh/OqyY2Nqkgy3gof9+L8Gr0L6RBlP25+qAn9Gu2O1A5sYovYx0h7Jk11yfgzWP3Ri1aZdvop4QXNlgqRqZ1V7/atS1cEfrwIx9xdZrbT5xBjHdi2cC0cKadx6yQVGOpegKhFfBvoxZLbRvdepMyEZnEprT+z0RCAz/o4LgtTsn6xgvz1Yn6Gkvdjto4ToMe1IpC/kribKc75v8h0bQH505o2RheyJxVocazQ1dNPPk3HdticQgS1XxDz3kdtIgtepgFO7mvb1B3E5zSrpxXWwl4KT52QBKRnqzraG+QLtKmRkTE4ppDn39XliI1PLrCONzcQ7bvXHXPxi5R7P58DpdaEhBaw3zoEW+jzPl2mAtYTJyfxFw4bhZAH5+GWh4pDtndtL1fYcFkloZ0ubnkaPY1EiWeiUoA2z+tm9yoljZbkyt539uTUMxxBgQoPyv+/wzHwSN8yv2J85LL/bXeHvpfgED18+H9YCV2e/A+zn0oaAA0H/9M74ne/zSsIOq9QM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-31-04Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698568107.147969,
                        "modified": 1698568107.147969,
                        "name": "/dev/shm/incoming/2023-10-29T08:18:26.ais",
                        "sha256": "8e72bb16eb7bc3a2ac7c0d4ddc2533ca2f9f6cd0ac2cda1f23fe94aa74c4b3a6",
                        "size": 99793
                    },
                    {
                        "created": 1698568129.3579688,
                        "modified": 1698568129.3579688,
                        "name": "/dev/shm/incoming/2023-10-29T08:28:10.ts",
                        "sha256": "ace2bfd53c9a170a5c3c3fc64bf8e63647951a870e46fa7def41ed3ce01c069f",
                        "size": 6281268
                    }
                ],
                "mission": "OSP3",
                "sha256": "e687ed019f4867f8d66f5712087cf7406224160d7f960b40547ca6b537cc2890",
                "size": 6028796
            },
            "errors": "",
            "filename": "2023-10-29T08-31-04Z",
            "size": 6028796,
            "verified_at": 1698579084
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698568530,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "uBFSUSJJn3vVX8TMYeaJz0Vv3YrX6HGtPBP3ud3fNYMlNdZyfh8WaLOhyN6lQ/DoODvcmiumc0MNJTyd6NomIE0mPJGE8qMCMrgsiWR+SPKtMlXt2ggYolGRZjDD8x4XeGa99VciRhF1uiuOoBimMtkdhM2m/ezyEtUc1qc93EWuWcEVQEHwMyDtgxNsW7J9woYKYuhTACm+pQlbIIa/eBU705nYIQHeh26O3LfqR5V+HDwtITPyo/YL5YqcgLOBWy9uUtRnVhtaFnfPVugeuPz+wIsk/qeI8A/mfk7QBL3OJOze8Rbw/h/nxe+akcA3qMVLl61yIiUgMwVDiYMi4l7L0WFtBQL25mpqSTLi8QprlOg3OCwFcFxxviv5/lbDvioo497u6cIaRY8Tr1kPhyY0EWovnFkT1o8GR7GO/VvNxEBFhN4lDHQTZ2IDu8p38WE1U2grKCGAEEsyfDC0YfkS/n5jj0if34iZavgJpqF6fWUICJGXqjFwTEFGcpAySO+w+Rxb2QkrXkb4CTlLDImK5JeoiktVzTwfe1U8dB5+m7wehuKeltbs2oiPfpseI1lco6SHO4z/2FNnAu17cAeh79vHs/O5SZ7nRhOlbxjL/4WNiWfvPI/tapnu3xqM+ulcBg/d6AZCGWhHkMhukpbo0a8AWW+7ejQO0BevM8I=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-34-28Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698568353.6009402,
                        "modified": 1698568353.6009402,
                        "name": "/dev/shm/incoming/2023-10-29T08:22:32.ais",
                        "sha256": "7f1aad40f7a1b656843cc640329fbda37a31ce9f41ab8fa6f3458d7b11ad3708",
                        "size": 85024
                    },
                    {
                        "created": 1698568459.5309403,
                        "modified": 1698568459.5309403,
                        "name": "/dev/shm/incoming/2023-10-29T08:04:17.ts",
                        "sha256": "ab4d8e2c27ffee6154bf42b322e9c6c5f85c5793e532acb521a5f9b4ca7394f6",
                        "size": 395995492
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "c9e6497371c0e255a7ad39648db76062ebd8142bedc34d9315148a748e419b42",
                "size": 384192529
            },
            "errors": "",
            "filename": "2023-10-29T08-34-28Z",
            "size": 384192529,
            "verified_at": 1698579641
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698568337,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "e7HbRigS60RZmUseQ34VzO/dIMG3wXZHuNZgwUFzqt3DJDAYvhJQwS/urBoRJT8KdA3yrPRfG70i6zLOZasEIsvSi8+JDqyjkcSZjFRfEb5t0vptwRyznQ8qmX4H8xvH/6NWcJ2X2YIp2WluiDCtWCjrnU9YZuadLKFX4Ru4YupovvRsC9kRvEN7/iC3+RiHZiziW1cAIwj3vwueYT355UoFHdmn8BdWuElkTXZ/KPGNHzRoovMmESv/4U+QWpdCXU9oUHZYpelluALtK3RxiQziLYwKTwMr3yloT8LjWOkP7f89Vl6m+ohTjd/irHQ3Mhf7AUUgPZRJ7J7d9m3v+gJ4zVN0y838Q9wH5UC8rmL2wji9GDzoT85Lz428btJK9EsRHSCg8G6AeTazCFdgwJ3m2MgmwwetPlQAb0QTwC59VgVNAv32mImcSTMrWVLgDr1mgAUrp7KWyw6fjjJPbWmyGP3LmAzMNxBCeIQ9HngludaeYKO4WHJIvaNH5hs+Yr+U4X3HuzXQTnQXqsQhtM0ktBjN0WkUv9ogmMGzdcieTEOprZDYdjXZJur4HRf4Ph7EIqy4DEgb9zQgHl+6iD886dFMfi4vOFlDRvwrCixaHs5BuB/a4y4Ih4/A9QEXYvob1/8QeufT/wr6OvTXrwDhd9a3TFBx/orX6UnCBOs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-31-29Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698568162.133234,
                        "modified": 1698568162.133234,
                        "name": "/dev/shm/incoming/2023-10-29T08:19:13.ais",
                        "sha256": "f68a48bdfe2abbf6a8c4c1f21b8d1b106b90c0f0f0521c489c44d16f73dafa63",
                        "size": 274807
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "b425e6ad659ecec5f665e07dea90a9fc7b05459d3ee400006b6e1c8fe17ce67c",
                "size": 82675
            },
            "errors": "",
            "filename": "2023-10-29T08-31-29Z",
            "size": 82675,
            "verified_at": 1698595149
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698765655,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "e1Lc6MYhqxFKDmj2NEjP4j3ZHb+4nKEI94IGuBRk0I7dvQebo6TleUvzby+3SWc7hpKmXC8M18Lxa22YrvQtYlRhMqG7hdioCJqXpM1mg6Puu4AD5gKQKwrR3E6qLLyWYJf80WzoJ7OhEI2/7aEn3+081L4Mfo9jK+OTF+5itf895M30NEDQAjJEXJfTvP+gg/RELaJKwgRM7DDlcWPiqXBPQ/zgdYC7fzOhoOP53pZhxG2wNSOkrsdx5F+ayg33ufyokfvd6BIoAtg9BRjsIQQQONl1w5dPlTnqGaZx4HVrIBHMMdG463of83o470ff8DALv/3OpqQqFxEVHXXUjqBoiuogfqIP5Bm+31ydtxobj8n90/XqcWHt0QO1F8eGGWWPAN8eMQeckxYHt3iDIA/Z7xi/lTJNDEFJ2bwD72gHe05jz31X3J/zhe+RmhTFjsNj3de9GJ3w8s5mOUtW9ThbElXaq7bEOKNVJMbwSYem6ERuAqZ4hW1hZX64HseN84xGIo/YeJbLBn8bwVFSGumV9GU6caDbAP3cexO5lkQ/kSHVvRWJ6RwyjsPeH5HuCmp4/7DbfoocstK/++5qyTDE50LyQhkEljdV547eHM5X1xtqm+hKDdefAxJSV+KPF+U9sCiIBJvE4xTqqfr+gM7nhoMT6dpLWdmJNbHV/AE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T15-20-36Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698765519.5830362,
                        "modified": 1698765519.5830362,
                        "name": "/dev/shm/incoming/2023_10_31_151809_00_03_V_015.txt",
                        "sha256": "5fe8a3bab33ceb7fdd87f2398f013d32b10e413251a413c2e6f80b7c444a514c",
                        "size": 2034
                    },
                    {
                        "created": 1698765521.193036,
                        "modified": 1698765521.193036,
                        "name": "/dev/shm/incoming/2023_10_31_151809_00_03_V_015.kml",
                        "sha256": "59c6c3cd0348a599c3bc3dd4c998e56ab1e32041de236b5a1ad79f5808ecc0f6",
                        "size": 977
                    },
                    {
                        "created": 1698765511.0830362,
                        "modified": 1698765511.0830362,
                        "name": "/dev/shm/incoming/2023_10_31_151809_00_03_V_015.jpg",
                        "sha256": "d4b97400e1e26fd71e2c099999114ced00a9cf1e7c2199c601e14770ce75db68",
                        "size": 1073858
                    },
                    {
                        "created": 1698765435.723036,
                        "modified": 1698765435.723036,
                        "name": "/dev/shm/incoming/2023_10_31_151652_00_03_V_014.txt",
                        "sha256": "fd9ca648bfb8218a049e1ea8038b0d72760f687ebfaf84ab6bb188e19dc57aca",
                        "size": 2033
                    },
                    {
                        "created": 1698765436.173036,
                        "modified": 1698765436.173036,
                        "name": "/dev/shm/incoming/2023_10_31_151652_00_03_V_014.kml",
                        "sha256": "0fccedce6ff25bead409e9f774e495924fc015fe1f5f93b287c528bfc83e8514",
                        "size": 979
                    },
                    {
                        "created": 1698765434.903036,
                        "modified": 1698765434.903036,
                        "name": "/dev/shm/incoming/2023_10_31_151652_00_03_V_014.jpg",
                        "sha256": "06fd2689a9ffd4623b9e1c51a92f8de92e29a31ec9463faf4d34e4abf84b6fec",
                        "size": 1001599
                    },
                    {
                        "created": 1698765433.1230361,
                        "modified": 1698765433.1230361,
                        "name": "/dev/shm/incoming/2023_10_31_151631_00_03_V_013.txt",
                        "sha256": "4dfaed7f63dcb0f503349eb4fb73b3e34a0486b71d3aae6929b876941a086e11",
                        "size": 2031
                    },
                    {
                        "created": 1698765422.213036,
                        "modified": 1698765422.213036,
                        "name": "/dev/shm/incoming/2023_10_31_151631_00_03_V_013.kml",
                        "sha256": "eeeb4adb6d6c0d01e60c82e96bd4d4f6ae926ea37317400e76fd795e9c042905",
                        "size": 973
                    },
                    {
                        "created": 1698765417.8030362,
                        "modified": 1698765417.8030362,
                        "name": "/dev/shm/incoming/2023_10_31_151631_00_03_V_013.jpg",
                        "sha256": "c38d43836169a31d690a2d3eb6732c553f840f5c489b123ef4bd5a6ce0e9b8b0",
                        "size": 1014438
                    },
                    {
                        "created": 1698765367.293036,
                        "modified": 1698765367.293036,
                        "name": "/dev/shm/incoming/2023_10_31_151536_00_03_V_012.txt",
                        "sha256": "1dd54a30df40d5d212fe265b57d14879b160b058373dbb398776ac49f5ffd6b2",
                        "size": 2032
                    },
                    {
                        "created": 1698765360.723036,
                        "modified": 1698765360.723036,
                        "name": "/dev/shm/incoming/2023_10_31_151536_00_03_V_012.kml",
                        "sha256": "b806c3d3d17a35ae5f12b3c7bc9d498fbee60079721e2e7b0d7514b4232701d2",
                        "size": 978
                    },
                    {
                        "created": 1698765374.1230361,
                        "modified": 1698765374.1230361,
                        "name": "/dev/shm/incoming/2023_10_31_151536_00_03_V_012.jpg",
                        "sha256": "5b7419c25d98e0b3c0e3a33c4471dd6a0680e03bcb3ebef2d31e552c207726a7",
                        "size": 966940
                    },
                    {
                        "created": 1698765280.793036,
                        "modified": 1698765280.793036,
                        "name": "/dev/shm/incoming/2023_10_31_151413_00_03_V_011.txt",
                        "sha256": "10bd252a596e6e033c0b02d20fd0914f02b839092d1d3462e4a352b863e1101b",
                        "size": 2031
                    },
                    {
                        "created": 1698765284.483036,
                        "modified": 1698765284.483036,
                        "name": "/dev/shm/incoming/2023_10_31_151413_00_03_V_011.kml",
                        "sha256": "755fb53f0900fe79478b1bbf157d67150369c67a8fe907e9946d3131135bf9f1",
                        "size": 974
                    },
                    {
                        "created": 1698765270.1430361,
                        "modified": 1698765270.1430361,
                        "name": "/dev/shm/incoming/2023_10_31_151413_00_03_V_011.jpg",
                        "sha256": "7ff2b6bfa04bb081bf8d79316a4edf0328f48cee9591cfee97b21e74ed494adf",
                        "size": 967252
                    },
                    {
                        "created": 1698765183.5530362,
                        "modified": 1698765183.5530362,
                        "name": "/dev/shm/incoming/2023_10_31_151246_00_03_V_010.txt",
                        "sha256": "f9fc8b018c29e280b826e3b5c57d8909e7de2864abaea793a595ebc633c021a3",
                        "size": 2031
                    },
                    {
                        "created": 1698765192.953036,
                        "modified": 1698765192.953036,
                        "name": "/dev/shm/incoming/2023_10_31_151246_00_03_V_010.kml",
                        "sha256": "685b291375414e795ec633fdd45b97944c403354a319f86c4679310622dad9ec",
                        "size": 977
                    },
                    {
                        "created": 1698765192.6430361,
                        "modified": 1698765192.6430361,
                        "name": "/dev/shm/incoming/2023_10_31_151246_00_03_V_010.jpg",
                        "sha256": "6afe7f4a4dd6edbb145c0e6e5b5841e001693d71a396f45976ae72db9c044115",
                        "size": 1038497
                    },
                    {
                        "created": 1698765055.1430361,
                        "modified": 1698765055.1430361,
                        "name": "/dev/shm/incoming/2023-10-31T15:00:53.ais",
                        "sha256": "1601d7c293120a7323957f4df306c3911a12697d078d44c5e1878ab35e11ce94",
                        "size": 77502
                    }
                ],
                "mission": "OSP2",
                "sha256": "bfd8148ab0984496435cb33d444202ccab1128c73d824bc822b1b305f710811d",
                "size": 6088723
            },
            "errors": "",
            "filename": "2023-10-31T15-20-36Z",
            "size": 6088723,
            "verified_at": 1698783403
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698568715,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "BKMhJDxivUdXCv5z9E3dBxFZM6H9cdBurej4hSN6wfUgc/ZDF/bwTGgAupjSA9NnU8njNLllhd2xlAxJd1B5WbWqEb1sOJ0t7gOd2Sn1fe7vT0G265fIMpApmR/RZW8Eir3JWgADIqxHrau2gNcNTlsak39MaUaRjSFTDGSrUNFXhv0CrU4aYArj1AsV9xEiPdfmsFbr1FLaSI511dAj6rkgrBBCndgLilGmAY4CBrEEEhnHNJigILO1fJZfTdferKlUcdI88lvyRHfgqRwyYiRjfIKBZIgXwMSMUMLEBXXBw9bQcZlMC7oZQ6XbX4Cr0nb4Vp6D1BoWnGBxZFiRq5nSf0UsMsh2mfaXS6C7SzpgR9P/OsjD//kJYJzw1UqPkiLkcplsacwVZ73l7h2EDID9PyVgXEPNWyylNcYV4/6aR2LJ64tR495PebeHyqoYlDJnZP/Kz6UW2BhNi6DILQ1i995CYHLzkn2/KIjitH6w9tYqrRq/BY0yXzrHTN0Dq2D+Pe2+h8JqeTW/TqsdTaGsWjUsSDLB3tGxxeFh5LBuxK3wNzKTaW1kzLVl9GbDeQfY/ouUIq6dg9MQWZmj++5/ogpEyne51c2eRoniJ2J5y4uNDU0qvqKL9COU0M6kAXOD0Trur6brsWQzyMsJpMENF4N2ms37qNyncjXJOVU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-38-11Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698568564.897969,
                        "modified": 1698568564.897969,
                        "name": "/dev/shm/incoming/2023-10-29T08:31:28.ts",
                        "sha256": "dc267d1d3611d84b4017c439d0e96151455db758886acd551db2c3f5eee845c4",
                        "size": 44744000
                    }
                ],
                "mission": "OSP3",
                "sha256": "3688b5b8514c51eab5b1987fbb8029ca8f6535058c072feb8b51e4cc0fd1f4fe",
                "size": 42754073
            },
            "errors": "",
            "filename": "2023-10-29T08-38-11Z",
            "size": 42754073,
            "verified_at": 1698578925
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698568996,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Xc48Y8Jx91GY9e9bEJ52TrCCrD1eSa514gKwRRQpWiP2nAYsXXX6IhTrEOyDAxg+PEKGVfcmxoguDfzGjEjRCzQtd4FhVxHKlrVNANFqvC+lZ5tgfBOz5cCcsCs8vHMZdiFj/ky4jNDDq5/hL5epae0RW5p+nHLnDmIks/m1qEqoaK0qhjOBJviGodH3A7sym6SloIvCDHauetgaVqx3OYgt4hlgHdNve+W3MFDECrRJI1OMCuzMr6le6S9WMXU+Vh4P4ZLLe0W4/wPeKfCfhcJRc+14U8PKwCeAEQXkMP0wLcbdlj1UcecQvUHpm/9ryRmOWSP/m5W1EXZXysVrfiwC5uAtuQ1N98FTseFUZg7Soe5dks9bJVULwlylzVJ0P2GpBwS6oORRaxwZaWnERmBbJ5u7RE0YCDM86Isn9RBDyen5bTpj1Q4wN6jEWBvNciXXilUAMuj9jpt7vHE3eWOHIIxKSegeeIzcaIjK6NKoCfKQo29Wl00RXypzmdkRBIMKpzaVORuxjxoJnEmNXdz0+bJMA3qvDv65R7iR5TTq2tFbsPhRY8JdkeNSofvTKvuibJVYOjCDOYfdKQAe0yvh70jvvHrQ1AMxTOSF32TKc5Zp3PX8yKYckMnDwc8q36Oq7YqtxFi4EUxuWpGkXllaaGs2Iv0U0xEYJLZU3sc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-42-42Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698568956.9209402,
                        "modified": 1698568956.9209402,
                        "name": "/dev/shm/incoming/2023-10-29T08:32:33.ais",
                        "sha256": "bbcc9fd725bb6cec0a39bf329ed986b6d525f657e47093f02f2ddc886d56b15a",
                        "size": 91635
                    },
                    {
                        "created": 1698568953.2109401,
                        "modified": 1698568953.2109401,
                        "name": "/dev/shm/incoming/2023-10-29T08:34:43.ts",
                        "sha256": "5e696ae6609c258f3734f36ed5d1a71f66ca3e5668746a4984ec4dae954ef19c",
                        "size": 105091624
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "6235bf7b9c1f3ff403ec66ee51cbedd6511e74cd12e2836af60aadcd807c4b00",
                "size": 101467309
            },
            "errors": "",
            "filename": "2023-10-29T08-42-42Z",
            "size": 101467309,
            "verified_at": 1698579902
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698568651,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "nr4QAGFSnlDnYncHcuXeKbXhU9/OXPghGcrT2iOCnWJ9iBuWl5nv54cbpp5TOSGJwG1mqGIzXp+A1Mm4SGWwispz1H14twI9Q73NjPEJHixDqdC7Zz0RLm7e+FzQhnJ2NizRSJCJj0gX/IazvSLMkRgPnSFOTZBBdBYaWsEWwVmG2FFUhtoevt7p2mA9a6h1QroErFzgEB3GQHipAuI/w/48kXKPXNzy+O/ofFEt3YmYgvbA/vGwKQbl4NUnRz1AV2DrM3aHznfxSJJoaSJIdUhl5mU4mu7ate7GkQn7Loi9bc3Nwt3s8QCRJvFqCoM81c3NtMOzIzks99Ge2/1iVWiAMdEmUwxEJpV9ntDoSGrRVpLW1VwMFwXlMiswlqmqsN9A+1X/otTsg0A9J8U72J3QAAeAo+c+B44cdeLb0HOc3P0orUCu5IEF67y09f14etywuqOk4KitoXF7jdxp6hmgor5ObVHlsmHZxT0upML2dkk78wb8Ihv7bXWJViYnYh9Pxwj+flLyoSJrEudnyhvhvZJc+i8RfNqSePxYi1RR7x3nvn3jBkaJ9g0S8bWW3yX1ENpxfBdJLC6L2m7kIa/3cZl0+mfjG+/aspVdeTG2T9LcVW+FH3rvb9v8GGVjLQGWG2Fd6ldsiwUAUiAITslGvJhVQp2yHrD6h99w+90=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-37-08Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698568477.6509402,
                        "modified": 1698568477.6509402,
                        "name": "/dev/shm/incoming/2023-10-29T08:34:25.ts",
                        "sha256": "b82ea262ad5b8e95a55eb7339756f267a0a97fe487d3db507ff1df6858b97623",
                        "size": 2325936
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "eae370d9b917b1f294fd92893d6aa62957effcf2febfd50277e9a6c5b6465ec9",
                "size": 2236899
            },
            "errors": "",
            "filename": "2023-10-29T08-37-08Z",
            "size": 2236899,
            "verified_at": 1698580032
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698568571,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "tBpofoEYBEIWeQ/hi02ps8UWBWn2nw+rputAY1ZVSQBkqcKEh9dNs5RMfx/3f5oRPSJ4DFW8wTR72eGSN5yok4PidaBm980dxrmEU4Ma4jk8+w6Lrst3wACANjV5IXK3L0kr51O4Y88uaB9BsJ+WcNox0G9yFQcUA+ReeLe3dXEFNrDh3KBWlTGdndxS+5CT7bf1WC34OMbBtfR87o90S+TQXTihqT+ZuZe+8wtascff9YhUoGEM7G7OinKt8yExW7utmju7BieP+tQmzdweZtoVB85T5mrlMLzyUXKGGMHQemks8JR2oHCmH6qrgwWRx4nDFsYSDYmyDJ2p1rVpu6SmKnb77qCNRhDjMlxV5vg6AafRbo5rrVZ/iBgIdTQc/gpACdq99xGGqNOSq78/d8W41Ek2HRUhB/T3T1CSe2JX4ZAuyD38L9+E5s25+Rugf5SlyVez68aQ3h4hgejtS47Ra2OlS1FWrEAw+uqb8/k7AGmMzvte7b6teXyOm26uhWeBsMFdaLjwD301bYCuZY5q+nP3j2R9n8SCNHGmX4G5AxuifdheOGrvePtndZnFxk+jskqUikePENwn4XlyMV1iOcX/vsxU/qpwmEo2ZWct/KG/UILZP/EjvsAJzRvHTaAi2hvfdo6noydshtrULffAEM9kb0beS8GXhJAvod0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-35-49Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698568422.1308658,
                        "modified": 1698568422.1308658,
                        "name": "/dev/shm/incoming/2023-10-29T08:23:41.ais",
                        "sha256": "e02948786799d00b10dea0c6b52a1e34c49f38f77f71b307de97d972d606dde9",
                        "size": 998681
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "eb2955df7909171ac0e407a210082b5ab656bf2083189b7d61d6a188efd5d1b0",
                "size": 304008
            },
            "errors": "",
            "filename": "2023-10-29T08-35-49Z",
            "size": 304008,
            "verified_at": 1698594324
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698568910,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "EThTURn+FdJBLRGVK+LLh5zw8AndhROVLRd1qrfT4z1H/lYUHsZdCw72bQIAQ9ys20RG7rwDm0JqNeEifZeilB7VdCkdYpEhuF1KBepv3e8NDObX+hvJ12Bh6ciS0OeuFAevQCsMyfAdej79te6mig7uScPsUN8i1XXR4Nme1C+fIV4bCKTjwSJ31kUdRFQRQPpjCZMFjJZezLAzUk0RqMqEgtwCgc4Kzv6KO2bTAkfTfeDt6NSmwOXjIKytaaPIfmT4w9xbhvUABsYmz7M9sy/FBc49M/6mdlMkL3J0bg9gr93cPyNE7pzvmUBza54EFqDhoAoM2fE23/oPXlWG7Np9Mk6SmjWCBAx/+8Z6curvxp1iQc/DoYZ3tFRCyvBdb+6k9sdieyRuVy9WqfJbZCcdXVpC1c5lHXVnhE4sPmoxJUXqtw3CA2AuoucEfl0sYgZX+D29vwV7AmA9B/1j5JA7v5d6pxEyGuOar2Zv0P+x2gTGnHcO7K32QFNZst+kbeWoBVvbCQVK1/FHv1i0xIIV0EudOpzha1v1aRqvfo+sFubYeC8xX2EqCPhuJ/hQhhp0bkWg3WCsVhSDlt3WzZGPzIQKkXQWwlXeaEt3C+qhIymZLraMAW85vlhoMvz6EUDudn+wNr7cNsGgpfijPvpKQUkmndqQy9w9NuaGKP0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-40-39Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698568833.423234,
                        "modified": 1698568833.423234,
                        "name": "/dev/shm/incoming/2023_10_29_084010_00_00_K_015.txt",
                        "sha256": "d784672eec47951eb890513ed9aa85414404555675adc847fc49fd7bb8625da8",
                        "size": 2048
                    },
                    {
                        "created": 1698568833.073234,
                        "modified": 1698568833.073234,
                        "name": "/dev/shm/incoming/2023_10_29_084010_00_00_K_015.kml",
                        "sha256": "d4fa6654c327a0bc6d8e58b5a4f022a5a25c90a75369df9fc3f8dccd030e8f53",
                        "size": 974
                    },
                    {
                        "created": 1698568832.873234,
                        "modified": 1698568832.873234,
                        "name": "/dev/shm/incoming/2023_10_29_084010_00_00_K_015.jpg",
                        "sha256": "f19051108c19d20b3acec9cc1197c82258394915b29f8e51a50fb6847faca79f",
                        "size": 846884
                    },
                    {
                        "created": 1698568819.9932342,
                        "modified": 1698568819.9932342,
                        "name": "/dev/shm/incoming/2023_10_29_084006_00_00_K_014.txt",
                        "sha256": "a84045635e363319b8fcfec6fca99e4f6f0eb3d31fada7352540fc27c6daf7c2",
                        "size": 2048
                    },
                    {
                        "created": 1698568819.793234,
                        "modified": 1698568819.793234,
                        "name": "/dev/shm/incoming/2023_10_29_084006_00_00_K_014.kml",
                        "sha256": "a20ca7dec53d5b348510780e7524c165a062cf24b4746708f3f20159589c2bd2",
                        "size": 977
                    },
                    {
                        "created": 1698568819.6932342,
                        "modified": 1698568819.6932342,
                        "name": "/dev/shm/incoming/2023_10_29_084006_00_00_K_014.jpg",
                        "sha256": "ad404e37a6b955a62daa3026edf98d484a53d6a32a7ddf642aea50a7968d2fe7",
                        "size": 826747
                    },
                    {
                        "created": 1698568785.1932342,
                        "modified": 1698568785.1932342,
                        "name": "/dev/shm/incoming/2023_10_29_083926_00_00_K_013.txt",
                        "sha256": "2e3d007fdff3252625b4230bd42dd19228bedd3b8995155b5fe1308c8abbdf96",
                        "size": 2048
                    },
                    {
                        "created": 1698568785.113234,
                        "modified": 1698568785.113234,
                        "name": "/dev/shm/incoming/2023_10_29_083926_00_00_K_013.kml",
                        "sha256": "ee3c35e4cf31579e4b3a353f74f243cf7825e21bc7ab2bfd16aa7cb4082c49ec",
                        "size": 978
                    },
                    {
                        "created": 1698568784.133234,
                        "modified": 1698568784.133234,
                        "name": "/dev/shm/incoming/2023_10_29_083926_00_00_K_013.jpg",
                        "sha256": "568fca8be6f5d1e9f2aa756989834e81d9049cc667816773d0d25fc762093235",
                        "size": 1058775
                    },
                    {
                        "created": 1698568782.123234,
                        "modified": 1698568782.123234,
                        "name": "/dev/shm/incoming/2023_10_29_083917_00_00_K_012.txt",
                        "sha256": "333d6ecd8613e6f29fd6d69e624dcda79ace350041d524e765c358ff8482f7ac",
                        "size": 2048
                    },
                    {
                        "created": 1698568782.0332341,
                        "modified": 1698568782.0332341,
                        "name": "/dev/shm/incoming/2023_10_29_083917_00_00_K_012.kml",
                        "sha256": "38a7aaec4d3a3865b64c65d5eb8e61027775967122960c7c0a4929628a95ff42",
                        "size": 977
                    },
                    {
                        "created": 1698568781.053234,
                        "modified": 1698568781.053234,
                        "name": "/dev/shm/incoming/2023_10_29_083917_00_00_K_012.jpg",
                        "sha256": "ac3a386cc63d45a3ae1607bf51b5cce3391e2fdbfaacb65382b9ae4b24fe8677",
                        "size": 1078906
                    },
                    {
                        "created": 1698568767.9332342,
                        "modified": 1698568767.9332342,
                        "name": "/dev/shm/incoming/2023-10-29T08:29:22.ais",
                        "sha256": "2ba13e2613eb026b0c1e575b9dd181a7bc2aedbb8d8b9247a517b8e45cb3a6b3",
                        "size": 71111
                    },
                    {
                        "created": 1698568756.813234,
                        "modified": 1698568756.813234,
                        "name": "/dev/shm/incoming/2023_10_29_083854_00_00_K_011.txt",
                        "sha256": "114cc1bf1c922314519407c4935fa851be43c58e5ad617177a8f2b9fe5f46614",
                        "size": 2047
                    },
                    {
                        "created": 1698568756.553234,
                        "modified": 1698568756.553234,
                        "name": "/dev/shm/incoming/2023_10_29_083854_00_00_K_011.kml",
                        "sha256": "de074b02f668503ab40200f5b78535a9449f31091848d8f8ed3caa8030c5c793",
                        "size": 975
                    },
                    {
                        "created": 1698568756.4632342,
                        "modified": 1698568756.4632342,
                        "name": "/dev/shm/incoming/2023_10_29_083854_00_00_K_011.jpg",
                        "sha256": "a2e026342268d21f2768bbf686f12d51531ec2bba42dddc7dda446362be6bbd1",
                        "size": 971613
                    },
                    {
                        "created": 1698568689.2732341,
                        "modified": 1698568689.2732341,
                        "name": "/dev/shm/incoming/2023_10_29_083751_00_00_K_010.txt",
                        "sha256": "fb46360e963a4551e707b8bbb5e433583d040cf463bb32642ed85bc1e8b9533a",
                        "size": 2045
                    },
                    {
                        "created": 1698568688.7332342,
                        "modified": 1698568688.7332342,
                        "name": "/dev/shm/incoming/2023_10_29_083751_00_00_K_010.kml",
                        "sha256": "2ef87559b3e73d0400b5a182dbc205d49792ea24bc2078ade7423c03538d684f",
                        "size": 977
                    },
                    {
                        "created": 1698568688.2332342,
                        "modified": 1698568688.2332342,
                        "name": "/dev/shm/incoming/2023_10_29_083751_00_00_K_010.jpg",
                        "sha256": "6c888516fd57ba3fae527f80602c3e9e9f75f5103b470567125a7fcb5e08b919",
                        "size": 1004916
                    },
                    {
                        "created": 1698568666.103234,
                        "modified": 1698568666.103234,
                        "name": "/dev/shm/incoming/2023_10_29_083728_00_00_K_009.txt",
                        "sha256": "67b302ec2d22cd3f53a6bb6ac25718947cfd377b4467ae516db50b1acbc46554",
                        "size": 2048
                    },
                    {
                        "created": 1698568665.5232341,
                        "modified": 1698568665.5232341,
                        "name": "/dev/shm/incoming/2023_10_29_083728_00_00_K_009.kml",
                        "sha256": "3043751d01b355f387e0364db6c36293107d8435001935518abde9a0ad6f7d51",
                        "size": 978
                    },
                    {
                        "created": 1698568665.2132342,
                        "modified": 1698568665.2132342,
                        "name": "/dev/shm/incoming/2023_10_29_083728_00_00_K_009.jpg",
                        "sha256": "2ed217901cfc99c51026aeb2c5fe337a5d7775724e02f1ade231eb296cd9b5b4",
                        "size": 974480
                    },
                    {
                        "created": 1698568664.4932342,
                        "modified": 1698568664.4932342,
                        "name": "/dev/shm/incoming/2023_10_29_083724_00_00_K_008.txt",
                        "sha256": "f9217fe5667ff2512ef830acb90fefc9c0cfe309f332697470835fcdf24c6c8f",
                        "size": 2048
                    },
                    {
                        "created": 1698568664.153234,
                        "modified": 1698568664.153234,
                        "name": "/dev/shm/incoming/2023_10_29_083724_00_00_K_008.kml",
                        "sha256": "c099062d6f23473aa60902970cd7daa4efd72eb461045769fc59ec365ed28771",
                        "size": 976
                    },
                    {
                        "created": 1698568663.7732341,
                        "modified": 1698568663.7732341,
                        "name": "/dev/shm/incoming/2023_10_29_083724_00_00_K_008.jpg",
                        "sha256": "66ff3e2202442708bfc5bed2495dd0944fc03e6085d8b9d60e5dacf53d9b72d8",
                        "size": 1148228
                    },
                    {
                        "created": 1698568662.7032342,
                        "modified": 1698568662.7032342,
                        "name": "/dev/shm/incoming/2023_10_29_083721_00_00_K_007.txt",
                        "sha256": "b2b20612a83f58317524a8d2697a747433f30165bd00eaebdd36e4b1f02dd333",
                        "size": 2048
                    },
                    {
                        "created": 1698568662.563234,
                        "modified": 1698568662.563234,
                        "name": "/dev/shm/incoming/2023_10_29_083721_00_00_K_007.kml",
                        "sha256": "6748e4bb94a770b4c26f47fc7a0e60364e768db0f00bb6d23f9bd099466b521f",
                        "size": 975
                    },
                    {
                        "created": 1698568662.103234,
                        "modified": 1698568662.103234,
                        "name": "/dev/shm/incoming/2023_10_29_083721_00_00_K_007.jpg",
                        "sha256": "11e5d978073479a78868d1475b525e7aeea68e7fed87ca782421666f94e19c4f",
                        "size": 1175161
                    },
                    {
                        "created": 1698568650.393234,
                        "modified": 1698568650.393234,
                        "name": "/dev/shm/incoming/2023_10_29_083717_00_00_K_006.txt",
                        "sha256": "8ba5279fbc9ec491acad769fbaa8e60aaf7fa762bdf2f759fb211e5d50bee52d",
                        "size": 2048
                    },
                    {
                        "created": 1698568650.293234,
                        "modified": 1698568650.293234,
                        "name": "/dev/shm/incoming/2023_10_29_083717_00_00_K_006.kml",
                        "sha256": "2566ae32fe6e3836811016a06b93258b1537ef3c64a350d9853381515d39f219",
                        "size": 976
                    },
                    {
                        "created": 1698568649.9732342,
                        "modified": 1698568649.9732342,
                        "name": "/dev/shm/incoming/2023_10_29_083717_00_00_K_006.jpg",
                        "sha256": "c1d8f1166be975caa975327d7483140e5a1d1ab33c446cd42ad3ff48b526cfcb",
                        "size": 1114781
                    },
                    {
                        "created": 1698568607.293234,
                        "modified": 1698568607.293234,
                        "name": "/dev/shm/incoming/2023_10_29_083631_00_00_K_005.txt",
                        "sha256": "5da89e0fcf7ee0fc32f3535f26bd217f6314335c997c03bfc10ce1e709899c8e",
                        "size": 2048
                    },
                    {
                        "created": 1698568606.873234,
                        "modified": 1698568606.873234,
                        "name": "/dev/shm/incoming/2023_10_29_083631_00_00_K_005.kml",
                        "sha256": "6ca2922296be80efbc03efef9628a2b3d3280050b1f7ee947e5bbc2c4b3c3b6a",
                        "size": 972
                    },
                    {
                        "created": 1698568606.7432342,
                        "modified": 1698568606.7432342,
                        "name": "/dev/shm/incoming/2023_10_29_083631_00_00_K_005.jpg",
                        "sha256": "e5ea3f5efded13b38ccb1d6d767c3f8a89f2a7da0255ed973a03176a2931a2c3",
                        "size": 905384
                    },
                    {
                        "created": 1698568605.823234,
                        "modified": 1698568605.823234,
                        "name": "/dev/shm/incoming/2023_10_29_083623_00_00_K_004.txt",
                        "sha256": "74302474b9d45ea5e94109608a2bcc68e612d317b64d95bb4473442edde18bf9",
                        "size": 2046
                    },
                    {
                        "created": 1698568605.6832342,
                        "modified": 1698568605.6832342,
                        "name": "/dev/shm/incoming/2023_10_29_083623_00_00_K_004.kml",
                        "sha256": "211bdb6d82dd0b716ae202e4783ea1fe720635c3a90075cf6c74c7b1556996f0",
                        "size": 978
                    },
                    {
                        "created": 1698568605.2332342,
                        "modified": 1698568605.2332342,
                        "name": "/dev/shm/incoming/2023_10_29_083623_00_00_K_004.jpg",
                        "sha256": "3d07446c0f21bc97ff44c8a2bb04430a7307c1fdce0f460b178a522074bf62a5",
                        "size": 908562
                    },
                    {
                        "created": 1698568572.063234,
                        "modified": 1698568572.063234,
                        "name": "/dev/shm/incoming/2023_10_29_083553_00_00_K_003.txt",
                        "sha256": "ccdac3e28720ba566bcebfe940d2c4cdb5b676d0ddf0c792bc0118c4e635c63e",
                        "size": 2048
                    },
                    {
                        "created": 1698568571.393234,
                        "modified": 1698568571.393234,
                        "name": "/dev/shm/incoming/2023_10_29_083553_00_00_K_003.kml",
                        "sha256": "3ea34774c2e78d7ae25ae68fb833e515f823e97f1e533614e59303d20039c882",
                        "size": 979
                    },
                    {
                        "created": 1698568571.083234,
                        "modified": 1698568571.083234,
                        "name": "/dev/shm/incoming/2023_10_29_083553_00_00_K_003.jpg",
                        "sha256": "2a90deae494b13709370f209ebc7e2f85996656ddcee4a935c730772963d1231",
                        "size": 827876
                    },
                    {
                        "created": 1698568570.0232341,
                        "modified": 1698568570.0232341,
                        "name": "/dev/shm/incoming/2023_10_29_083550_00_00_K_002.txt",
                        "sha256": "a432cb6cd14fbdf2a5eddae38ac8bbe475fc22677c1655824cb004db85de6940",
                        "size": 2046
                    },
                    {
                        "created": 1698568569.113234,
                        "modified": 1698568569.113234,
                        "name": "/dev/shm/incoming/2023_10_29_083550_00_00_K_002.kml",
                        "sha256": "7bce920b1564975b65b69cb54f6baee76da312b58842b746e0fa50c0ff7e741c",
                        "size": 979
                    },
                    {
                        "created": 1698568569.0132341,
                        "modified": 1698568569.0132341,
                        "name": "/dev/shm/incoming/2023_10_29_083550_00_00_K_002.jpg",
                        "sha256": "efd9b278cc5bac011e83edec43d9432c982582bc8b7a7b6849b9bc59a2ae0bd8",
                        "size": 821317
                    },
                    {
                        "created": 1698568502.0232341,
                        "modified": 1698568502.0232341,
                        "name": "/dev/shm/incoming/2023_10_29_083445_00_00_K_001.txt",
                        "sha256": "ed9e5983c71e7270d78bc9013a361ba68f1c3e1714c6e6edc3d7f5b92f942ff1",
                        "size": 2044
                    },
                    {
                        "created": 1698568501.4832342,
                        "modified": 1698568501.4832342,
                        "name": "/dev/shm/incoming/2023_10_29_083445_00_00_K_001.kml",
                        "sha256": "c2502b1cb0631e25561e47952991111edb1503e57df514b04bdbaddd938b4999",
                        "size": 979
                    },
                    {
                        "created": 1698568501.2732341,
                        "modified": 1698568501.2732341,
                        "name": "/dev/shm/incoming/2023_10_29_083445_00_00_K_001.jpg",
                        "sha256": "281b1195306508cfa2116caa7a4abdc47e9bdc76b53a098a2479fa044db59c3e",
                        "size": 1063191
                    },
                    {
                        "created": 1698568831.913234,
                        "modified": 1698568831.913234,
                        "name": "/dev/shm/incoming/2023-10-29T08:10:31.ts",
                        "sha256": "5621ddfd267c32e33d90826bf51c6010d91b07d62d80827502201ee251b1628b",
                        "size": 441875764
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "32d7aab53fa90b182b7bf8d6c63ad4c760847ffb5d3a56eda434a5e4b852bc85",
                "size": 413279293
            },
            "errors": "",
            "filename": "2023-10-29T08-40-39Z",
            "size": 413279293,
            "verified_at": 1698595758
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698569159,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Mooh2B4HLzpi1PzMUWfI8sQxous4gF786d5tM8CmhIjKjMasz7AG9WnVos4foENrCjGCBcfwEb2dMarjVjktTnBHCgD20/VvycHzlTr1X+C4IpqTtfRDXIzpbWgoQO1edzN/2fibwJt8PnXgbt5TGFdoY38ImwDYcNk+m0a00FAOgVdWX/f0gB7+pPxxOU7XISfsL3cLtnBLOaTgL3naC6yPInFA3Ojitd4i09s0LOAmRWrZmjuBjilWXInJOc8BPredRbZFxq6qN6XntO8wCI8/SGdS3dHClS91jB+LTdT+0deHsX8M74E6gvm1/Fv7m9O6+5QPtcgkBQ1HqbIN4uGt7Z5BlPSjsi++6Va1MM00AduEvEuxLoS2XV7JSVMBWksU+SFtqXxn34RePf3XzTCTIDsauSJ+qo5dNIKIc2YTh/8dKoDzy51AUFxJQ2gBHfMhcIJ+BYMckNqL+41y2YmE8V6deabzN1Wm/b4qI7XhSwSzTnvbhguIuPODxyKrIlhLyjUw1NscRNnlsr+q8/WMQdr8kGK4QUWVnZUrnAi8Z9TiHUZGR550bCzRoWRJTtG5ZOEeZxJ1oJdzEVOF5cTSF8+dfAbD46VVH2jisb+0MEvHqOYqzfRd8JazRRHRGpcBfV7/TEenAaYzHXkrTBYz6dK8cVur2D/JOPF0AzE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-45-32Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698569001.3379688,
                        "modified": 1698569001.3379688,
                        "name": "/dev/shm/incoming/2023-10-29T08:39:25.ts",
                        "sha256": "c7e5a37e7416d20bb9a911c9c269b6e34473c61453c35ef903ec2112a31e98f5",
                        "size": 43515232
                    }
                ],
                "mission": "OSP3",
                "sha256": "a9493a83f83cefd72be2e8de179851b6851a0613501ccfe3af8a2bac3f785f31",
                "size": 41686696
            },
            "errors": "",
            "filename": "2023-10-29T08-45-32Z",
            "size": 41686696,
            "verified_at": 1698578932
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698569170,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "DWVL9UVRl8J2pZOCgocmicLxTSWSAPoa3El933W8F87dMx/Ophb7JXOmk3pSZd1VmHtfHDXq58mQ6KCjxwWZFF4Xxm2x7fuIiGB8i6zl5c/amcj+tLbuozn6aQ+Zk1kVA8TwZsvIpu2PykzCXEJFI5SatCj7kFv3dg+0v85cUv+3deESl7plSPKO6MjXXlt4Z4+OyhDXlZoa5tvbUUBpjqnLTu2cdrZezixRcdQXS9Edz3y1X9M72DM5gfD/P8Say33Yy8o8jhlZ5E15ap1Jpg0T8CIojryq8Eq5XRfB46SIi5s2polTYYEqvqu1WQuAXoL59rs8B4IwActEpOM11jBj8Vw7dYbKk+TEql4GrxK3HLfRXWDVOMSKb2EfF6Vvi1b1FRu00ZzOt3258YivcYE1D3rNDm1T4DRI3JteZUNHT8Qg0FZsoO00rSnJMZmf8Fvs7XHnATVU/CvO1RP6Q9VqXhRxjCipLYQNx1h40aZiwAzZPRV793mAKGVWwyTNAbBDlRSjKnJpJtO65cIaJgwZ4umtTFOoT7GAwUmNoOVc0+DT8dNWboJQ+4jJirxeyLEj5WJYaEOJyStJvWao5ypqrcCoyyV3HXFCwoZy9jcnNgLr+Y5EAUxcbg/p6ceTk6ASATgMR5Sefhpu4hx30t8EHb1qJVOJqu+xD1dga+c=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-45-43Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698569014.9309402,
                        "modified": 1698569014.9309402,
                        "name": "/dev/shm/incoming/2023-10-29T08:42:38.ts",
                        "sha256": "74d34c906cc523d71733a2f40b51e473c98974451aba5100b4c0f73daacb13de",
                        "size": 12317008
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "e6e13d863b87d1f0de1d8f0063751bf331af8635f5d535e1a90271077ba4af3f",
                "size": 11881029
            },
            "errors": "",
            "filename": "2023-10-29T08-45-43Z",
            "size": 11881029,
            "verified_at": 1698579939
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698569059,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "Dje6fmaDWSx58VfH9lpn61PfyNC6HzMYY4+QsI4uM7uXhmD4lczvCN6SNsHm4VT8KR6EqCS0kgY/HErRDCl6+oZRRatKDaTU5C4k+Kgh/cFvyPShUuMlVBNQ5yPkNCovh1FR2Z7p+ghMlSUAgI5FhT7eDHN8ehO4Ws5DgST4Lr5RPzPfX2HeKsYfx2Rh3AWX41hY/fWodZtCITp5cUixP74SdIRMD2PP2L7cnVquhsTm7A0Vv1Q7xHwvO/JOuBYgGKPTeu+jxcZuk99m9imLz1iUQr2Hi344hBjr15UKUY21Z2GaB4ZrCGzh1OP/GYswwj9ohtEeXvJzQKGZRIDQAv3SBUoxHQe6Ybt/OVhpzxmNgnjGl2XQFTnHpO9+F4nTp81az3tEKiiI6/CFZynUtGXvMizLfTNWzKicqj5dADk0krUVXnLBTOjHh/DywBsb+J6dA+q2WKJQ3W4kIqBGkJ8NrRlj0snolY0vi7BffXecvEpJv1bEM74eKTlV/s5hcuED0z41rblWytIKABVYuX0uxYKbNqMkjLwd/2gCgv8OzM/G1UNReoEeG/+/CsZZuJbuqMw5ld5SGohFRXURoES9ysoCKdPnT5hT/I3UQ14HshinD0iZge699Hsxptnj5xfx0fg4z3uEcxnIzyzqmhAyOf3cfaF8sxvlNnl153A=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-43-36Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698568878.803234,
                        "modified": 1698568878.803234,
                        "name": "/dev/shm/incoming/2023_10_29_084056_00_00_K_022.txt",
                        "sha256": "5c01d1ad236e9d28a0ecea490f9be2946492c7587447ca3edabe233f8f751198",
                        "size": 2048
                    },
                    {
                        "created": 1698568878.4532342,
                        "modified": 1698568878.4532342,
                        "name": "/dev/shm/incoming/2023_10_29_084056_00_00_K_022.kml",
                        "sha256": "547b00d5158ccd1a05e29d3754d60c5f2a6bb18c29b1e55f7bedb3a3dfd3fdb4",
                        "size": 977
                    },
                    {
                        "created": 1698568878.143234,
                        "modified": 1698568878.143234,
                        "name": "/dev/shm/incoming/2023_10_29_084056_00_00_K_022.jpg",
                        "sha256": "70bea0abf80620c85aa54b07d185db0f9e089373bd5b499da58cdb742f732c6d",
                        "size": 848262
                    },
                    {
                        "created": 1698568877.403234,
                        "modified": 1698568877.403234,
                        "name": "/dev/shm/incoming/2023_10_29_084053_00_00_K_021.txt",
                        "sha256": "52137ead4eaf76e2293545ab55658cc2d8b196e864bcfee2481b2c2025ea117d",
                        "size": 2048
                    },
                    {
                        "created": 1698568877.103234,
                        "modified": 1698568877.103234,
                        "name": "/dev/shm/incoming/2023_10_29_084053_00_00_K_021.kml",
                        "sha256": "6a9ebb19d454a67ff973d1143c8d7571d22a13a4f465113bb966d79917c75b68",
                        "size": 977
                    },
                    {
                        "created": 1698568876.5132341,
                        "modified": 1698568876.5132341,
                        "name": "/dev/shm/incoming/2023_10_29_084053_00_00_K_021.jpg",
                        "sha256": "4c2c391a767ddff103d5773f005bd485a0848e33cc51b77339c666873212a281",
                        "size": 883534
                    },
                    {
                        "created": 1698568875.4532342,
                        "modified": 1698568875.4532342,
                        "name": "/dev/shm/incoming/2023_10_29_084050_00_00_K_020.txt",
                        "sha256": "907cf447172d4f32be2204e4663fd61a5f488700dbcbdd83fd3dcef70ced91ea",
                        "size": 2048
                    },
                    {
                        "created": 1698568875.073234,
                        "modified": 1698568875.073234,
                        "name": "/dev/shm/incoming/2023_10_29_084050_00_00_K_020.kml",
                        "sha256": "ffe35745ca1b56cb097ad950bdda12ddab23a75935291c21d9c3f69c382a9bb9",
                        "size": 979
                    },
                    {
                        "created": 1698568874.9432342,
                        "modified": 1698568874.9432342,
                        "name": "/dev/shm/incoming/2023_10_29_084050_00_00_K_020.jpg",
                        "sha256": "da2178f725e45c6c021d494ba83c0cf1c1b0d239d9454688f7b0ce96146ee0a4",
                        "size": 887071
                    },
                    {
                        "created": 1698568873.0132341,
                        "modified": 1698568873.0132341,
                        "name": "/dev/shm/incoming/2023_10_29_084048_00_00_K_019.txt",
                        "sha256": "75913609bf7628af1f42cf0193082374fe9cf785bd03879eb16954cc7e78285c",
                        "size": 2048
                    },
                    {
                        "created": 1698568872.903234,
                        "modified": 1698568872.903234,
                        "name": "/dev/shm/incoming/2023_10_29_084048_00_00_K_019.kml",
                        "sha256": "2168da31b5fd7d805c8338bc5cc7ce0b119e2821b716c443072a6e2c23ea7256",
                        "size": 979
                    },
                    {
                        "created": 1698568871.9632342,
                        "modified": 1698568871.9632342,
                        "name": "/dev/shm/incoming/2023_10_29_084048_00_00_K_019.jpg",
                        "sha256": "6da80ed4b6de76ecb08a4c09f3050dd4a685f69ab7578efdd00601e60e7d7e25",
                        "size": 909481
                    },
                    {
                        "created": 1698568870.903234,
                        "modified": 1698568870.903234,
                        "name": "/dev/shm/incoming/2023_10_29_084046_00_00_K_018.txt",
                        "sha256": "60a9a2ece440c956c221ed707351714d8f41d44e9378735ec004a391be200f95",
                        "size": 2048
                    },
                    {
                        "created": 1698568870.553234,
                        "modified": 1698568870.553234,
                        "name": "/dev/shm/incoming/2023_10_29_084046_00_00_K_018.kml",
                        "sha256": "58562183880140830ffb6fd6bc88bdd19ce60e94a30a18470694a137f82b1d79",
                        "size": 975
                    },
                    {
                        "created": 1698568869.853234,
                        "modified": 1698568869.853234,
                        "name": "/dev/shm/incoming/2023_10_29_084046_00_00_K_018.jpg",
                        "sha256": "a5282e491b6922607305260f4bf96d61bdce5c0aa9a020152fbb0be1b03796d5",
                        "size": 815430
                    },
                    {
                        "created": 1698568858.083234,
                        "modified": 1698568858.083234,
                        "name": "/dev/shm/incoming/2023_10_29_084034_00_00_K_017.txt",
                        "sha256": "8c27b260045969b20d3f08ee905defabc9f718fccf3810dc334f17748522373c",
                        "size": 2048
                    },
                    {
                        "created": 1698568857.7632341,
                        "modified": 1698568857.7632341,
                        "name": "/dev/shm/incoming/2023_10_29_084034_00_00_K_017.kml",
                        "sha256": "0d34f67578105c94657b15fcf5fccf429bb7e8031b0adf26d1b9e083cd4c1c27",
                        "size": 977
                    },
                    {
                        "created": 1698568857.633234,
                        "modified": 1698568857.633234,
                        "name": "/dev/shm/incoming/2023_10_29_084034_00_00_K_017.jpg",
                        "sha256": "73284f733b6e6747d3e36d1c2a0b2b0cae253ee1ebde2ee43636e3616d7fc193",
                        "size": 803481
                    },
                    {
                        "created": 1698568846.313234,
                        "modified": 1698568846.313234,
                        "name": "/dev/shm/incoming/2023_10_29_084022_00_00_K_016.txt",
                        "sha256": "9b465ae93417351a11f86f32b0a48298732b7c3a1f80c9abf48b979814a6b10c",
                        "size": 2048
                    },
                    {
                        "created": 1698568846.073234,
                        "modified": 1698568846.073234,
                        "name": "/dev/shm/incoming/2023_10_29_084022_00_00_K_016.kml",
                        "sha256": "76e470cf383de53c7e45eaa02839ca0a019eeab1098bc15fded3775c699e0959",
                        "size": 975
                    },
                    {
                        "created": 1698568845.5332341,
                        "modified": 1698568845.5332341,
                        "name": "/dev/shm/incoming/2023_10_29_084022_00_00_K_016.jpg",
                        "sha256": "4b33ee195b4842eb3bd017f5b34db304ecded5e4b3ebcb17d121c33fe90da724",
                        "size": 784466
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "8b25262d3646c9981a60a93992bf6948dbe043a92dfae440d3cbc8e1007a61bb",
                "size": 5924936
            },
            "errors": "",
            "filename": "2023-10-29T08-43-36Z",
            "size": 5924936,
            "verified_at": 1698595442
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698750975,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "BrVFwYPxmy9POR0xN4z36sYGc8DUycWWs3Ma6jIL/q1aeTrHOBU5Vb6FbIkISORl6na71OFCIxpGcC6fFLIMGvtL+R6B4GddLe/aDWaORS4Td69+93eVTPRKG795tU9Q43Tqlh2zRjTuZwMLpGkYI3cxlh0CJmAB3lRtiZqX7HY8M1bVz7AwyStZIe1rF85G9zChnXnYDzxRd+xfUMOU/P2HiEUWUuuv2bS+ixtNeK9mCcs9Q6kTn+7UHOS/rR4rFNXicx8IloPc4u2R1NQcecaj2mGEAvyqhB8HrQd8mLGePOmS9GiW8Kl7eCIp625IkqA8yijlmfzijaC/GKgPgjhbPqNbr8fJ2lUxDBj1baKW2DPQ8cEJon7Aq+MYOTV/nokRoF1AqWuEUtt6CFgvdmfnLIbfKfXR7v6xly0bUMZBFIEcpdpPI2UiphA24Jtdbgsdxiyj4yoqb2d3ciXFkKxajMXSvNZaFWkNJpGaOpA5aFr9G4jtu700SIJJ0KLy8Bf8zWZ85cDQ+sO7q0gzRbHbCHa79SFFGHzNeu5jJCMWd99iYU/rryXB808hqiDWcqpSCKXvIb852TdFX0u5iz0+sjjph3m5WUhXU8Pf65M47A1jngsLjkd3okd4SJpDuwYXLf0idpjNjv1/J1rtNogAoKzE02/WIFlCphuWJDc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-16-01Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698750836.4114184,
                        "modified": 1698750836.4114184,
                        "name": "/dev/shm/incoming/2023_10_31_111341_00_01_V_061.txt",
                        "sha256": "5fae9d4066458199e6419a3c57b42be74aaf2c98df9a9f7576cbe6de6f082c90",
                        "size": 2042
                    },
                    {
                        "created": 1698750835.6414185,
                        "modified": 1698750835.6414185,
                        "name": "/dev/shm/incoming/2023_10_31_111341_00_01_V_061.kml",
                        "sha256": "0ce4480b9eea7780a04441eb9f02d1165e692bb9ad4732aa4a242bf2a34b96be",
                        "size": 951
                    },
                    {
                        "created": 1698750835.5414186,
                        "modified": 1698750835.5414186,
                        "name": "/dev/shm/incoming/2023_10_31_111341_00_01_V_061.jpg",
                        "sha256": "e2481afd825644dbf268636d0de1f0721409bd499413c886eeaac417cae92b96",
                        "size": 1433749
                    }
                ],
                "mission": "OSP1",
                "sha256": "91724d251f4e84deb309ce50ade3acde1e12105c46457c5fadcbcd49de63b42f",
                "size": 1435521
            },
            "errors": "",
            "filename": "2023-10-31T11-16-01Z",
            "size": 1435521,
            "verified_at": 1698765929
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698569176,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "lHiGyF+ZJ1R+TO0hfrilN6n0OoYqmqbH/a8/hpMWwlEn1tBqdUn2+WiklawGUWlb/cg20LcS2LgWrlviCLd8UlN+FZ01/IUslTdoDUQ5L56dlUPuSZNB1U3oh6Y3Y59eEgZwsfiE/HBjnhLMyIv2co4Vun4JLcdXsCabU7HCGRkvV34Qo3XGx2hOsXAofuROBmtvdkVljw9YwwfjdBrsqjeM9SVrMwJT8fyAfoI95lEttRsQrCn3MQ9v/Be5JK4Y8ZQblALkOLqMjzJbwAOftUS4qjPxn4uK4nJtGqUy/C51+VjKHH6cSjzLKra0U3V/8QYH1bRvY+zXFWB2RFkZ3ccz3G74FfwxRoKLvBrnufSZIfPrTa/XjL4CFXNMumv2ngG59WVY2/FrXJnqboLRLV38rzRlhiTWU7fWQyl6jC6ooZxJvDMlA7ARdwH46RpbYTCNkCxXhqyjIBYLdufnXbVK57oyXrj1jWCuQp4kTpwqzwK8mfpL7lhZKEImm4ykv8E7YDbE+dWngep3/r+FmQVqTHXfgoEXsnJO3THu7KMvdFoHSTHPDKMFmE79jXBxud5QkiWDjP+Q3G/dJ3IKrjduBKuCbB7De2L1n4l6XN6XXOaGcCyvB87jFtVPFH2lCa1W1ubEGXoymQ1RLHFxNhGCQHlT3vL+1At2maofQPM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-45-53Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698569023.990866,
                        "modified": 1698569023.990866,
                        "name": "/dev/shm/incoming/2023-10-29T08:33:42.ais",
                        "sha256": "1e09a23d46ff9825babdf9b3f1053565c945e2e5c0dec2846bded4e025e9ace8",
                        "size": 1005072
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "63ce934537b970fe4ec33d7a05af6e6b0cd0ff518f32d6c1b9e9c494f50f1719",
                "size": 308645
            },
            "errors": "",
            "filename": "2023-10-29T08-45-53Z",
            "size": 308645,
            "verified_at": 1698594401
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698677380,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "oSOhpQxQnJz/tZsIqNxRndtImTASo8AioGlzxr32ud6ZXuujqN1PrKdIeQabxDllEwhvZQmwwkm1346fdBiOiqFXu7/Z1UZbuOD+boBfsXvoNZsFamxqUmsJ2ToaUQ+JC1dvjgniou09HVwKHtGzW8wYS9VixiO31UD2K6EyDeixPCu4ymw7Hf/kopCj6CfUkkEqMTQ0GQGr9SQokqEVjK6S+sUe5oSBDsaNjuf3tQ0VTfgbXyfss9g1NEJeLZA9y73FHiShjnCf5pv7RBT4+O+7SSjeQaq33grPDhv63ujR3vQUvb5cx4ReHwhXiLZS8kywNn2OYUb0dtAXP/aP3e0W9yqYTxMp/imzuPjdo+p8gZQ8T8ER9483ZKDpf274cMN0XKu2NZoAfdRbrEbWawcle5FQ5qhHnMw55OB/cwfD1uKoMw5ukT9Ftcgn9WQksOYteaINVjl3UV0ZEXIWdVCDSR9VWwyrDIvuxuDwqRsm8de5P+Rxqixa8vCCls5nQpyYu72OlhHHpPEJy/TS1OYgdQbJ4+sei7XlVJMQ+ynXOhj03eor2c++nqnF82tzOoeC+L8jNjd/t8rIEcfzvAAwtzZsEj/EU+Qj8eqsoYFxcF66w/v+2sa13sqpGvrsbj3FPAOWcjOk7KSP1U7Lg6s755CfxyN7LW7kREHCLzY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T14-49-21Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698677237.1086981,
                        "modified": 1698677237.1086981,
                        "name": "/dev/shm/incoming/2023-10-30T14:37:11.ais",
                        "sha256": "efdfe5f5d755e4bed3ba5c39581d37bd3592ae4460ba7a87fc3f0e5616b92c27",
                        "size": 66952
                    },
                    {
                        "created": 1698677126.9786983,
                        "modified": 1698677126.9786983,
                        "name": "/dev/shm/incoming/2023_10_30_144509_00_03_V_012.txt",
                        "sha256": "1b4f3ad20f13aafe014cf888dcc457dcea8aceccc8fe27a48f6c9686241f31e1",
                        "size": 2024
                    },
                    {
                        "created": 1698677126.6186981,
                        "modified": 1698677126.6186981,
                        "name": "/dev/shm/incoming/2023_10_30_144509_00_03_V_012.kml",
                        "sha256": "e28bb7f5dab74082d8c8fbe5b86e9c2a29fb9f87c0b273e63ca4afdc419a9014",
                        "size": 974
                    },
                    {
                        "created": 1698677126.2186983,
                        "modified": 1698677126.2186983,
                        "name": "/dev/shm/incoming/2023_10_30_144509_00_03_V_012.jpg",
                        "sha256": "c9e1cbdcc9aadd00d011d8a19bf0637e1d9d5884677f528011f392ec8efdf12b",
                        "size": 1010031
                    },
                    {
                        "created": 1698677125.908698,
                        "modified": 1698677125.908698,
                        "name": "/dev/shm/incoming/2023_10_30_144351_00_03_V_011.txt",
                        "sha256": "0c7561ae7898abe733daba6b83702b917dae7986957744e9af3fcd35ed665c54",
                        "size": 2030
                    },
                    {
                        "created": 1698677052.1186981,
                        "modified": 1698677052.1186981,
                        "name": "/dev/shm/incoming/2023_10_30_144351_00_03_V_011.kml",
                        "sha256": "67c5ece79cd7fc878aee60b660731f1e7c15ba84bf32ce5ca511b597500fc418",
                        "size": 978
                    },
                    {
                        "created": 1698677051.878698,
                        "modified": 1698677051.878698,
                        "name": "/dev/shm/incoming/2023_10_30_144351_00_03_V_011.jpg",
                        "sha256": "87df6f43d89312c4930e2b13b592dbedec576d5322f02c15233a5e41838784ba",
                        "size": 872831
                    }
                ],
                "mission": "OSP2",
                "sha256": "d41497fca32eacbcf9ad92796fee4ae2c6a28d97cd909aa42c5b7f5413af9149",
                "size": 1903610
            },
            "errors": "",
            "filename": "2023-10-30T14-49-21Z",
            "size": 1903610,
            "verified_at": 1698753145
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698775456,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "M0QzgXY0y1ADbE/DX0jXceA2N0TxpE+p4/xK9m1V7SUARIFSzT/HJdMocBeMf0d+0RqJBXhrMOVn1pnEzNUUzDPESdn/ATqUyFsQIO/ComhWWqgS2w1516/xhnezSVOXXHeEEHZ2jejhqkUsLwxK3MOZnI5HAff2wl/jLuz1gCN303vdbZBnIeoAi0wOcWfgiwChnGU83Om74UPCXefz42Dz5Fnb1rQ/oSFXdfwqOa//j9Y8ZpIs+CqbxIZLglRs1yTWwslebmDz5E4l7hON+VCAunnI+ogH5ymNrziMELSzMoJ90JKbQmK7wz+iZDW9C3SKwBDVqXXStO6QVH4za/7tXsQYJ5NjdQQ9VYkBm5q3hOUbWwB4YYGchZkFWfrQlJj8zXhNmSCIjyKt0IASbvV/jg15BPdfjeFhYhnyCKzz5Cz3Mttob4xrpbby2+xSNkrdVACD3e0RcWywCrPhaqDMRSel6vZzZP6CJW8gKcRCM8VBq381DXqz9qStCU5I/Yf+ZBUXUNkWf11LgxcN0uSCypbSYjCGqXejqF0ZJmoXQjbZDJaZ1vC5acwoLWUDUI5QeuNf+nhgHo93GltmcIftmn6GeFDPuLNiTpQ8VMK7Uswunqvv3kkZA+x6zv/kC50NOxViaQ6j9t9TfznxFyNokxfxZxcSbCo73P5uMXY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T18-03-57Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698775308.903036,
                        "modified": 1698775308.903036,
                        "name": "/dev/shm/incoming/2023-10-31T17:51:47.ais",
                        "sha256": "281e1d156b7beffe9657ebac3407e382bccf6fdd77d196c8a3e0608f21a358f5",
                        "size": 74247
                    }
                ],
                "mission": "OSP2",
                "sha256": "4b3ad4f76266fbf5bbedc3685e81d24fa3b02d518e61f680e07ee367ffb678df",
                "size": 18749
            },
            "errors": "",
            "filename": "2023-10-31T18-03-57Z",
            "size": 18749,
            "verified_at": 1698784083
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698775026,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "sCNTVs1inooYHTp2+LWhOZzq74+aRUv3aR1FneoTSheBNTI060Wf5brc5mePBPN89YQVjCRCZ38lXBt95rDZBCMcu7rZ/I9anVeyfaXK3Ch4T+3NedSrrbbN2rKxaVkyff1DxpTCY8XIgxrWv0Ep1/mDcmXlzjtAuZHjC30SMlssCv44VQWI39R3xRoWo0adYH3gEhbpFOSi0EUvUQmLm5cOKSvnLIUnIt+O5FcHQc7oL5h8WEp3RWoI5UkQVjI0XuKZmFRrHkBqnoEhByQHWbazirF2LV3Gc4ZpeTmakSbx5L0PiyxwHhEzejSQY8j3ZOAbMkLC19kGJtq9pseSXhMcXIjUZ+wF7Vs7eyIAILNz718WbZuWZo07hReBfflo6Td9QW+ICx03gt1yQ+xwBFXUuz3vA/OgKhrSKzrwCyfSqSqGFCjFiY6o77YJoGPc5j/+megdNaff5RxeF6fKoLlT0C91VxZGE4saXM5TKVUq+QYoj1qKdP9IDvXbvjoW93DMIPyMOMJDHzahe346edFAbNfvXjsWbYyrvZ/MUCcAC6ysjwPKLYOOevU2cZZzaQ4Bi/kcLbIP5kFd1NjKnHDs2M+dNdzX32pKoiJoIWamN433WCfYEn/g/ussSlZawFX/lqaX0F0uVfKtnKNdOapR2499CC43KJl5jTTGTIU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T17-56-00Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698774950.283036,
                        "modified": 1698774950.283036,
                        "name": "/dev/shm/incoming/2023-10-31T17:25:49.ts",
                        "sha256": "017483cba1de4dff7c1df88f46bdbafc6a9b7f4e156f22c53ece4d5aed35a71d",
                        "size": 409292168
                    }
                ],
                "mission": "OSP2",
                "sha256": "05af288f44b0c8e21859c48b2e016031ed0269f48ae622807615c4f03786d693",
                "size": 395298958
            },
            "errors": "",
            "filename": "2023-10-31T17-56-00Z",
            "size": 395298958,
            "verified_at": 1698784450
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698569711,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "msncKJEEWDgsztI22Kxhs7n4S7SKjJ7x7qaEfR/83DIbyUva/uRqZlRl4VUherIXDn/CFmufTBAJ2c2p4M1Qa3u7FXFAyHIC3Q2K1pbi6bRtW8XjTKRzb7IdvXl2xmippwWSqLcU50r3MnpAEstNjrP9ykyVQ/B+8na+4wxXjKXvOyiA+W0LJpaQ2pppzo7Zvg21qKdmPEaHVL4lrg1CD8FV0Yx1V6MtAc4aBORxqfcOj+RkmNVodx/VyEjVm/5+3XTYZAktoqnXIKZkLQ9aSNigYZtFfhV6CZMtoYhUU2tTWteA4z9YXlgV7BLqDF57+nZwX1L3eUrCW+up9jDXyD8wQXugpUkcBA2+fgJpyBQUHeBTwUVuzwkwLbcSrcGguW2MpLU9oe1Gw9+/TmCVXijmtEXYRoYxMJKznAaLyEGrtfOHpZn8Lko2046LvnrXmmZvJ8u/0ejqKiG740WFftAjxV+kNB7KY+5Hy3Du6DDya/At/LcyCkTbBSorGCBnWRtW/SEVN+/DBS+sVJONwotmZItZzueWb+7jruGcSXxB2uCMf7zw6F1CvY2LrZ7MrUtklupNv/edY7fIW6eXPSBGMrxgWC9gjLG6NtgTT9x0rZSYvXOg/CztnJO+9UegsEkxqbVgM6UEVG4cn0bQSjtK7Zw0jtsYDTaYiPWCEv8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-54-45Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698569560.2609403,
                        "modified": 1698569560.2609403,
                        "name": "/dev/shm/incoming/2023-10-29T08:42:36.ais",
                        "sha256": "60685ba23023a5dcfcdaef35ea2648cc54bf5faf979904c3cc8b8e85c9e0141c",
                        "size": 70005
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "c289b9a5cdc75ea62e52db705f924a47f2e8066a27aaf8ed5310da9fe46e3895",
                "size": 18240
            },
            "errors": "",
            "filename": "2023-10-29T08-54-45Z",
            "size": 18240,
            "verified_at": 1698580102
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698569468,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "oFY9x38ZbkJzm+yMUyO8uvp30ElImu0+Ajqu9bPelNh+KXnBV3IENi+t+Lc4DO4BpiWrjuP8pkiJC1hG15tinZSQ1LTFNqRjC45+QqQZ2qpbyWZtaaLgIDwxqksc3QDpG7uYpyAXxOLiN9TRznDjTMOTLnAD1b/7747aQFPbUgFPEf+X2UcjAqKsTAO1KIOQlexnyT6EhjIDplZNuYMR5KiDRsD0R0+216Go44zV1uh8N+D6dJNWCRK29xCuZ262zOTtACzMF3oDWq5N8EN3cygMZEO5W0J1ib8zSBkzHE5RHFHh+NgfsV1qzMggAoi5bh5eiqRLxf5+v+7MAFvL09LEZF+6P3QFIH6F/JBv5oYAdiNJBBh0XD1dqQdM/ZAbIoou2juQpiHbWe5WfG9yxL1ZQJbCSPr7sid755DCrP0veHTkD+zmMgJ4DMyoUe6J+AXA9eHtOgRaVHCVLdUdlAU77lyqE9uTXW7KpdLid5jlMLoX+3r6qhZW9tepN+QyStNnJTkmJkohPsY9sijYSov6mFXCt9DmwhQ/pyxS6084dpz3yLNLbQavaq9KnBnJrkfax5bDTo4DXMXh1Y8g7IuAwMqokiIXZxYKbPuSZ2tjh8qhWQWVYfwqYKXWsBshnFUd7oHn1+9cceNBjzQPL9NBrvh/Ad15gj1gnPgpp5k=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-50-36Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698569304.5509403,
                        "modified": 1698569304.5509403,
                        "name": "/dev/shm/incoming/2023-10-29T08:43:40.ts",
                        "sha256": "4a2883c18b2dc033793bbd8fce6ce944f6c0624fb65043058e3e900646fdda50",
                        "size": 63374236
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "c634f196f10e4b92d28db125e43e826e6c0bc9c191ceb42633319f67eb3eb349",
                "size": 61138373
            },
            "errors": "",
            "filename": "2023-10-29T08-50-36Z",
            "size": 61138373,
            "verified_at": 1698580149
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698569788,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "HixrCisT668zdY5pK6br0wEkl0jIDb7FjAbh5zH3VJQMt8tHHRaNLDtRR0SfyD2bwXrSlv7M5jaqYWXbnnqfF8X+NPojl4Erc8oRhJEY4Ap/mO4+3wbQrdStfEKzkqq43bo8GjxlegyMQ2lZvZ9GnJoahiWy2EgNLeT8nai2t1LOOPY8dV8dJYsCiOCqM0nr3oD33Yc/XSRfJA2F0P+ZMrna16ukwvjW0Y3RhmFFtXCdEL8oT3zYEfSaWjP6pwBgTzfRYMs6hV5Z38fHZKRz4tLLNj6peAegUe+qSNpj/c9BNkZ1SQqNFmjeemT9MAmGJjKlTOfo4fxtEwV2X8kC1l9OZNkF3bu/NW9OHKLUwPtk8vj2p+Ui90SeltH+Nn339LfrLhC0BpijQ07lnhHarVVGKMCwGN+U+u9L29hvWa7gZbhzgyhSPm9z54RZDhPt4sB5i75IGTiKiNyINNUq0qIE/qyrjebvbJI2QKCvek1Zaqcza5Q/oygNSDRWBD4NC4MFoTWCqv9evYXbaMMw7GCcZAixstgeLUwzW00mwZ6LvFqEjt2/jOnJ9fx1YIPjKaKccgCBsgVAhCZfp/KcDJwHZxOkRJCHKDMT0rr/g2ajq7aaYNjAkRUu7ubsUJ8EeAD7DkIiVesgnmprKXEhuijwb5dcfCFrlgpd5DBXyCA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-56-05Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698569640.613234,
                        "modified": 1698569640.613234,
                        "name": "/dev/shm/incoming/2023_10_29_085336_00_00_K_035.txt",
                        "sha256": "47372e7b55e3690d4bddfbba8fed4423f4e4cc4ad2b16ffa30c92bd8f767996f",
                        "size": 2050
                    },
                    {
                        "created": 1698569640.4332342,
                        "modified": 1698569640.4332342,
                        "name": "/dev/shm/incoming/2023_10_29_085336_00_00_K_035.kml",
                        "sha256": "1ac691c666173080701cb0091d2531620941eebbd189551459aa05d19f1f32ec",
                        "size": 975
                    },
                    {
                        "created": 1698569639.903234,
                        "modified": 1698569639.903234,
                        "name": "/dev/shm/incoming/2023_10_29_085336_00_00_K_035.jpg",
                        "sha256": "a0d54e838e0365d02527a5368a329332274f39d10e24f7b995960e66a82f11da",
                        "size": 1132277
                    },
                    {
                        "created": 1698569617.423234,
                        "modified": 1698569617.423234,
                        "name": "/dev/shm/incoming/2023_10_29_085319_00_00_K_034.txt",
                        "sha256": "f934434f60d2798f41e046f3a5e63a31bc695cf776f83b54912c445fa1967bee",
                        "size": 2050
                    },
                    {
                        "created": 1698569617.133234,
                        "modified": 1698569617.133234,
                        "name": "/dev/shm/incoming/2023_10_29_085319_00_00_K_034.kml",
                        "sha256": "7b9e9408f4628b42e6a49013043f865b377610b05d44ef9c5bbac247840a04b4",
                        "size": 977
                    },
                    {
                        "created": 1698569616.7732341,
                        "modified": 1698569616.7732341,
                        "name": "/dev/shm/incoming/2023_10_29_085319_00_00_K_034.jpg",
                        "sha256": "88d65a67143624e46561aa6e79e6fe41f8961a4addc7fffdc25151126665906a",
                        "size": 769212
                    },
                    {
                        "created": 1698569594.373234,
                        "modified": 1698569594.373234,
                        "name": "/dev/shm/incoming/2023_10_29_085257_00_00_K_033.txt",
                        "sha256": "29f26219807b140089578f9e9585ffbe05c2221c9833c9d559450b1308926e5e",
                        "size": 2050
                    },
                    {
                        "created": 1698569593.863234,
                        "modified": 1698569593.863234,
                        "name": "/dev/shm/incoming/2023_10_29_085257_00_00_K_033.kml",
                        "sha256": "dc533f1e2501bd4170c548bc6bbe522813e0312dd087b6b26bf238739c362866",
                        "size": 978
                    },
                    {
                        "created": 1698569593.333234,
                        "modified": 1698569593.333234,
                        "name": "/dev/shm/incoming/2023_10_29_085257_00_00_K_033.jpg",
                        "sha256": "dc777208c581a63a9bd137fff8b68dee7df23b12f68e49419dcf157662499292",
                        "size": 884255
                    },
                    {
                        "created": 1698569581.7732341,
                        "modified": 1698569581.7732341,
                        "name": "/dev/shm/incoming/2023_10_29_085238_00_00_K_032.txt",
                        "sha256": "0386975b95729a4e86a72c148857583f351e9ed9e333c4bcbe6151471e62be46",
                        "size": 2050
                    },
                    {
                        "created": 1698569581.673234,
                        "modified": 1698569581.673234,
                        "name": "/dev/shm/incoming/2023_10_29_085238_00_00_K_032.kml",
                        "sha256": "5625badb92c10a8f3012176644cbb3f259eceda25239ce71e09ea0aed6e9c1c8",
                        "size": 978
                    },
                    {
                        "created": 1698569581.5032341,
                        "modified": 1698569581.5032341,
                        "name": "/dev/shm/incoming/2023_10_29_085238_00_00_K_032.jpg",
                        "sha256": "679493f16bf7009e6ff38850bbc8e850ccfc1f05431e69bd2c8b5f563fab47f3",
                        "size": 993765
                    },
                    {
                        "created": 1698569558.903234,
                        "modified": 1698569558.903234,
                        "name": "/dev/shm/incoming/2023_10_29_085223_00_00_K_031.txt",
                        "sha256": "d4b1c379963b0649e2fc5c5e64b002c087c2c9682f72877c70f7c4ba179646c8",
                        "size": 2050
                    },
                    {
                        "created": 1698569558.373234,
                        "modified": 1698569558.373234,
                        "name": "/dev/shm/incoming/2023_10_29_085223_00_00_K_031.kml",
                        "sha256": "02a83ac924e3dc91767c3db086f707e92ed573cf3cd4531599355d2dffc826e0",
                        "size": 976
                    },
                    {
                        "created": 1698569558.2732341,
                        "modified": 1698569558.2732341,
                        "name": "/dev/shm/incoming/2023_10_29_085223_00_00_K_031.jpg",
                        "sha256": "392acf496928ddfd18616e465da0508293010e877078f3bb7a1dfd4538d1dcb1",
                        "size": 685783
                    },
                    {
                        "created": 1698569546.4732342,
                        "modified": 1698569546.4732342,
                        "name": "/dev/shm/incoming/2023_10_29_085206_00_00_K_030.txt",
                        "sha256": "f6e8be9e125c958958f1757dc6247ee8a4ba8e81080c8107ff8008f9a77193c1",
                        "size": 2050
                    },
                    {
                        "created": 1698569546.2532341,
                        "modified": 1698569546.2532341,
                        "name": "/dev/shm/incoming/2023_10_29_085206_00_00_K_030.kml",
                        "sha256": "053eb47bac19806bdd702dfe56d0b7f2b62b8108537f9eecbc6b27e582b275de",
                        "size": 976
                    },
                    {
                        "created": 1698569546.073234,
                        "modified": 1698569546.073234,
                        "name": "/dev/shm/incoming/2023_10_29_085206_00_00_K_030.jpg",
                        "sha256": "c7e1ef285daf92bdeda379af6aa2ce302d3d7a7472f4ddfb98e6a36cb8e056cd",
                        "size": 850767
                    },
                    {
                        "created": 1698569513.823234,
                        "modified": 1698569513.823234,
                        "name": "/dev/shm/incoming/2023_10_29_085134_00_00_K_029.txt",
                        "sha256": "5af2de3f3d0be22eb2be0432e1290eb62fb4d29a7a8e210835f27e3a663b8c74",
                        "size": 2050
                    },
                    {
                        "created": 1698569513.4632342,
                        "modified": 1698569513.4632342,
                        "name": "/dev/shm/incoming/2023_10_29_085134_00_00_K_029.kml",
                        "sha256": "53afa3b68238cdfee3a7bee06b2efb2183713f8013f1f408030f8763ea0048fa",
                        "size": 975
                    },
                    {
                        "created": 1698569512.883234,
                        "modified": 1698569512.883234,
                        "name": "/dev/shm/incoming/2023_10_29_085134_00_00_K_029.jpg",
                        "sha256": "95dc058fe40d775b8be50f0469badead8dffd73836e820e4c0e1e1a400c16a9a",
                        "size": 1101924
                    },
                    {
                        "created": 1698569512.413234,
                        "modified": 1698569512.413234,
                        "name": "/dev/shm/incoming/2023_10_29_085128_00_00_K_028.txt",
                        "sha256": "293f075e98d9100e47cfe4b25592775a83c763cce30d3778cc79cfcd8799aca6",
                        "size": 2050
                    },
                    {
                        "created": 1698569511.823234,
                        "modified": 1698569511.823234,
                        "name": "/dev/shm/incoming/2023_10_29_085128_00_00_K_028.kml",
                        "sha256": "4aa9f7b043ea5ee31d0166baf721c88d063570e6409592de887960499439cb10",
                        "size": 976
                    },
                    {
                        "created": 1698569511.553234,
                        "modified": 1698569511.553234,
                        "name": "/dev/shm/incoming/2023_10_29_085128_00_00_K_028.jpg",
                        "sha256": "129fa9f6b812ddb9b30423ed2106d5a7ad8d8807184343062e5ce9046bbaf547",
                        "size": 1096531
                    },
                    {
                        "created": 1698569500.133234,
                        "modified": 1698569500.133234,
                        "name": "/dev/shm/incoming/2023_10_29_085122_00_00_K_027.txt",
                        "sha256": "8cf327b208c1176974c395465ab8685730ff36347e07c0c8d3581432311e4a34",
                        "size": 2050
                    },
                    {
                        "created": 1698569499.643234,
                        "modified": 1698569499.643234,
                        "name": "/dev/shm/incoming/2023_10_29_085122_00_00_K_027.kml",
                        "sha256": "13b963a694f1f21744c90809bb069032b2e951c3717829955091d85872cb93b2",
                        "size": 976
                    },
                    {
                        "created": 1698569499.403234,
                        "modified": 1698569499.403234,
                        "name": "/dev/shm/incoming/2023_10_29_085122_00_00_K_027.jpg",
                        "sha256": "fc7724f59787524b7e2b1fc90faecbb70deb3fbabf94917961ce9e7b4f8f2b2c",
                        "size": 936912
                    },
                    {
                        "created": 1698569498.603234,
                        "modified": 1698569498.603234,
                        "name": "/dev/shm/incoming/2023_10_29_085114_00_00_K_026.txt",
                        "sha256": "3c364c05cfc5e08c7cd66e8e1973cc17bd8282b3ef8ac8d22c884b2fbf2f89fa",
                        "size": 2050
                    },
                    {
                        "created": 1698569498.333234,
                        "modified": 1698569498.333234,
                        "name": "/dev/shm/incoming/2023_10_29_085114_00_00_K_026.kml",
                        "sha256": "b831bee1358b0089ef299e4bb6bfdc30961a1fea061e17146409683f53f1814e",
                        "size": 973
                    },
                    {
                        "created": 1698569497.7632341,
                        "modified": 1698569497.7632341,
                        "name": "/dev/shm/incoming/2023_10_29_085114_00_00_K_026.jpg",
                        "sha256": "4d0362b2b5f1e6921df86772d967cb6d3f677ba920d3d7f12c1af87e642bf59a",
                        "size": 1073814
                    },
                    {
                        "created": 1698569475.9632342,
                        "modified": 1698569475.9632342,
                        "name": "/dev/shm/incoming/2023_10_29_085059_00_00_K_025.txt",
                        "sha256": "651bce9fb4f28fe181ad1067706c9f1bf033a6dd15421acbf9105637f182ff97",
                        "size": 2050
                    },
                    {
                        "created": 1698569475.173234,
                        "modified": 1698569475.173234,
                        "name": "/dev/shm/incoming/2023_10_29_085059_00_00_K_025.kml",
                        "sha256": "f52941c91abed2fd2c887ad36f74638f2c928620bee030bae4bc751a8717032e",
                        "size": 976
                    },
                    {
                        "created": 1698569474.923234,
                        "modified": 1698569474.923234,
                        "name": "/dev/shm/incoming/2023_10_29_085059_00_00_K_025.jpg",
                        "sha256": "eda1e39f4b9315e643a14b7e8abe163083bd401a0a170e5794f6a8af7c8abcaa",
                        "size": 1149762
                    },
                    {
                        "created": 1698569463.0332341,
                        "modified": 1698569463.0332341,
                        "name": "/dev/shm/incoming/2023_10_29_085046_00_00_K_024.txt",
                        "sha256": "b0f87d72ab30bb87b0940df65c4460788d4a9ceeb87323a335d7cd28c103d946",
                        "size": 2050
                    },
                    {
                        "created": 1698569462.793234,
                        "modified": 1698569462.793234,
                        "name": "/dev/shm/incoming/2023_10_29_085046_00_00_K_024.kml",
                        "sha256": "4e23a456f85772124be25f664c2025bbe0f6f8f94e25fb6cb8fd03ce78f8cc4c",
                        "size": 976
                    },
                    {
                        "created": 1698569462.423234,
                        "modified": 1698569462.423234,
                        "name": "/dev/shm/incoming/2023_10_29_085046_00_00_K_024.jpg",
                        "sha256": "61401eee0b39705c0df0a36533535b9b94cfc9c979d2e6ee1a5a59c696d71f5e",
                        "size": 1194190
                    },
                    {
                        "created": 1698569450.653234,
                        "modified": 1698569450.653234,
                        "name": "/dev/shm/incoming/2023_10_29_085029_00_00_K_023.txt",
                        "sha256": "fc1581e152a7250f6f2a358bd4da76c3e56cbbff957075232321fbee165555e8",
                        "size": 2050
                    },
                    {
                        "created": 1698569450.5132341,
                        "modified": 1698569450.5132341,
                        "name": "/dev/shm/incoming/2023_10_29_085029_00_00_K_023.kml",
                        "sha256": "211ebec20d440b8c5ec28b4a8180fd4831c0a762af7e209de89dcb62b94d1353",
                        "size": 980
                    },
                    {
                        "created": 1698569450.413234,
                        "modified": 1698569450.413234,
                        "name": "/dev/shm/incoming/2023_10_29_085029_00_00_K_023.jpg",
                        "sha256": "7fc5c799580bfbfd322ecbd262e4cef8abbe9e9d1af9e59bf91c78b9b9e8e8c3",
                        "size": 1137225
                    },
                    {
                        "created": 1698569370.2732341,
                        "modified": 1698569370.2732341,
                        "name": "/dev/shm/incoming/2023-10-29T08:39:27.ais",
                        "sha256": "2e58d4db2c565f002fa7638dd524469732797a5a8b953c6fc7acc7e6621ce9f3",
                        "size": 119736
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "07491e433802ea6fd4670eb1277719d41855e8c7f1d620786210c3018643f3c4",
                "size": 13044582
            },
            "errors": "",
            "filename": "2023-10-29T08-56-05Z",
            "size": 13044582,
            "verified_at": 1698595585
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698752250,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "wFdbEJkkJibzBQ05YAckLsZNWgis29ITuwdWPhSiXWVyo0U+AIYEZ9ZuY4WIFaSJiiBvGkw4D/yGogkleQa3rREyb2Fq4WSzgMxCv2tvzpsoUn2Qbsg82u/pm6o9hnJL/O6agnLLtqNfBZSw/tYjHhCy5o2pkG4iEQTEgHLQAPpMBqnPyY+iwFHDQHjhJtc4fHqvDd+uF9DhV7G3yy1HkSVR8rh9gwpbbClx7w0XKnxB29lefpufFCJ66k5YdC9iPY6AYAIkS3DghimG2Tjko3ka8six8oDHaPMSEUKvJcrZbYjVHxEqqLg3sSvwWA2U4xjdt+DVHGVqqzBpnjhGurn9QODQtMCiEqXXPi4tf+8rKDrYqJRaBwigDjEJ972aumMJ2KfdguvGcKmFIPTgiiDomOZgcaMYVxKLvgpkc3PrZnZfqt2KB0OGRX+RFeCyric4bbKIayKQZZTLI0iDxZxLXUSkwyPNt2+7Kdg1GVDTFY25L6EQAu9gDnHvJar1CVP9kCMdebLKjiuHE1v02a6ExqyogZySlCU9SRMtlZvWZDcknB/8nLlPJxRLwt/jYHKgqR64j90o236HsmQA/l8GHVgbZSchZMo0DHuBkqq41/tG/Wcx3QJkRlnjJeilLTpAgJ7v+GxsE4HPoecTgrsdw1ZtsH3ifIwUpp2X3iE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-37-16Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698752113.1814184,
                        "modified": 1698752113.1814184,
                        "name": "/dev/shm/incoming/2023_10_31_113457_00_01_V_065.txt",
                        "sha256": "c0b582e4ac99fafb54e6d0f6b2afbe6a33bd0f2d8c6d234dcaa2240fa6537f88",
                        "size": 2033
                    },
                    {
                        "created": 1698752112.9214184,
                        "modified": 1698752112.9214184,
                        "name": "/dev/shm/incoming/2023_10_31_113457_00_01_V_065.kml",
                        "sha256": "56c01c5d492eeaea80cc4fc4ed861307afbdf67ebcd88fe3cf7e88eebcfd735c",
                        "size": 953
                    },
                    {
                        "created": 1698752112.2914186,
                        "modified": 1698752112.2914186,
                        "name": "/dev/shm/incoming/2023_10_31_113457_00_01_V_065.jpg",
                        "sha256": "fe059a540431f95d6ec8a9e5b382265bb701851c3d52509fbaccf9d061f198a1",
                        "size": 1536036
                    },
                    {
                        "created": 1698752111.8614185,
                        "modified": 1698752111.8614185,
                        "name": "/dev/shm/incoming/2023_10_31_113451_00_01_V_064.txt",
                        "sha256": "ba6ab99b781019e13b729f7cdf8f9b17cf95be34058000940f26cc51468745e0",
                        "size": 2039
                    },
                    {
                        "created": 1698752111.2114184,
                        "modified": 1698752111.2114184,
                        "name": "/dev/shm/incoming/2023_10_31_113451_00_01_V_064.kml",
                        "sha256": "858cd2f2e4bbb7a1ed28d56544a3c7d275754cb3e79c6d183b5b6e7f12b56208",
                        "size": 953
                    },
                    {
                        "created": 1698752110.9514184,
                        "modified": 1698752110.9514184,
                        "name": "/dev/shm/incoming/2023_10_31_113451_00_01_V_064.jpg",
                        "sha256": "38a8920b75ae7193f6266ad881741065e7fb506b9fbc0f1ff829144d6959b860",
                        "size": 1522603
                    }
                ],
                "mission": "OSP1",
                "sha256": "07ac9806aa89a08264dce2409104d44f7570d013db8797e9372e25fd8eb10f2d",
                "size": 3062203
            },
            "errors": "",
            "filename": "2023-10-31T11-37-16Z",
            "size": 3062203,
            "verified_at": 1698765933
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698779669,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "jXXyCvVzcXBLeOY3AV/5uxrDWe0prejXTeHfdQWC4rR6IAbMJ/c31lEieNJ1nikOWGvO4F9nwA448eZOPHMnCgLVrPclsOzsx1nxHWD0o1o8/4ujpgES7XOlBCeYBWIWo90oUWf4XooBLlCIftiNHnFJud7DOmFhhmdPXq7DKJ3NiYyuzg0MJyxdoGTFc9ilhxAihNizl6afl2YIspUtU6kWwzN/iyJvXaD1nT7aPzLdd+wDH3zrijgc21h5/8AcvvGpY/V4Cg5ydPdNDxQ7mHcL0aIt6z5FAISKIzPyK665emaCWMxD0JxR5LNoB7oDZ4k1g5MBV+gMDcjd6BnPZN+mjmC7CDocOY1jIkpjg8PUDXeE4aYiJm4Ru2VpoJrOksXuudNXpcXicL46NHAxLFmeno64+t4YqUY+i4hq6drVsNyiOTeCBh0YiT0zWc1ASE1TPDj+rUuJpfiUOBtjRICVSFAEF7+J1whybow3/bZ99r7+CvLiH1jq2qv6D9RjbXtm3vupMC79voqFOKXvqmY0Cm4+2uaTKipzwSRO36T//FY0O+B5FbW5lYCXM4md7PsDvNIa0Htm2PhER0NtWc8Ga8in8bwOXnig7C68JaKroapcPNTM77itSL5xn/d/rigUTZAhSjZjOqe6aH9xvqYGT9eemVLRDfgBvEREeeE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T19-14-13Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698779525.8530362,
                        "modified": 1698779525.8530362,
                        "name": "/dev/shm/incoming/2023-10-31T19:02:02.ais",
                        "sha256": "784c360891596d6c843b021688d270fde064ee2adab3e72170ccfdc70318af92",
                        "size": 222750
                    }
                ],
                "mission": "OSP2",
                "sha256": "6d4034b400834a94fec56ecd6c540fa4979e33431ac936715d5bbd9ca26bbed3",
                "size": 56558
            },
            "errors": "",
            "filename": "2023-10-31T19-14-13Z",
            "size": 56558,
            "verified_at": 1698784456
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698570055,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "pThBViEz82oLXBe4yTN9r+o8wISSasekMBo/p/icrebtHD/FGGf/4ILvveS1RSVxGjLQtBf1Xmoo1s3KJivBmFqSlv7HAfCCEJlUStKPj96ugDl01ZE1y0aqHbrz/85DAGoKp4Qzz5nHL2nr6DguCtnpij2BIUy4eddIOyHe3KMNPtu7lDVVSjjUAIRSFLBftPiRN12CdI2d+zUAypUn8fAGFinqnmRMqC32o8Q5Kd4LNVEV+9Y++bx0CbDCcLOmks/atAO3R2sXYoZIv6uZy1HVe1PlTsl+PgKmn8aP1mO7YSMTRf/EOnWf5i9Ts0YGi5KnPc33r2QV91OUkkBBP2vzE4ZqS00w2UKQ3x8JQMGv2URxhFwS16rfWbGClm/FHIfj2tKhMSoGjOgNNpB/MzRtDg/Gdf+q5g4Kd6pz6HhqUSSvlRZMfHAXzyYUKuYnsuG4rF/zWCfj8LEgratdtcwZocak8EnfERig3g5j6WlHoC+RRtXat8tMMNExCCwwP+yNt0yy7KKbCbJn6JP1H5tBV5PuN6dMLwkbNz2QxzYdWqdrELexHLVDRyHAGhU2nFlGQn0Ylzt4wki2KTZYwj9qJLkxlfV2xAhPTP68jzA1QDF1VTjzbnRP9FrGzTkt/p5KXCzZ0Lvb8bohAwQ4EBpqDgGp+muNa1gLsk4V2+c=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-00-51Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698569910.0979688,
                        "modified": 1698569910.0979688,
                        "name": "/dev/shm/incoming/2023-10-29T08:48:29.ais",
                        "sha256": "377a769af663b873638df2832030793387129f2a65d12e9d85ec3d8f0434f763",
                        "size": 398958
                    }
                ],
                "mission": "OSP3",
                "sha256": "3797685c7e55f55c6fef4dce109694655c94121445cc341cb416dc1ac3f3d38c",
                "size": 118304
            },
            "errors": "",
            "filename": "2023-10-29T09-00-51Z",
            "size": 118304,
            "verified_at": 1698578917
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698752719,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "rUifDa88QEo3DISmet722H0aobwBfjhxTSjmNJIbwu0xUt61jlyggdLcWQaV20fTU6qJVv1ffQx3cENvdfW+fSSDQwJ8erk0nb3mNiuE5DA0yNqsrzZPYvgWTw0hbucD1wFGYFt6huiInErfqJpT1mXt4mcHFFVEqm3Bn7VgLDiMPUJhcksQxwFCzS1KRRaLO4fPb9VjnP3WbYh3KUJ87dkRoQW5wtOx7teS8BGis4ptobmuM4cJGrOS4iC4SKb/vsDxqeV7OPA1JoVnuNA7CUsXm9Mccj0uJPNDmfkPZqxXjwWiB4JajfUqFjotqrdyzI8KgeH5BTLp8SRLvgyrBZTqC6AE5iauxrlhb+dVOEgf6HULEkyuwBX4NVMOZ1SXJdn0n/3AlL82R2j+tAQcjflSyaEI9u9YSPbQDaGFONVzxy14tafh+QLPSCHRHKzcXYVVnZvS9UG3snpZqYnXCv9Mmo4SAaYcV/e+JUIjfDHBlhCHJsBDrEnjp55VPLIpgvf/VRZATKoJU14XquXBkUzj86LBGo8nkJn7Jd5y9RS3tKGNUy5bFQ6vx5BGOTnIWqH6zc1zZfxhKq8PZHHsUdkCnVfhyjSMyx98kPbC62aOW/GmHhN9//SVgUKHGtCyZ2CsY2FROZkBac5qRe1tyiu3f2lOge2gOu4cl80b+wo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-45-07Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698752579.7014184,
                        "modified": 1698752579.7014184,
                        "name": "/dev/shm/incoming/2023_10_31_114243_00_01_V_066.txt",
                        "sha256": "288dc8ded35a02af5cfb76c75ce78b57c2d42b39bfb165fadde5276d34795dd8",
                        "size": 2038
                    },
                    {
                        "created": 1698752579.4214184,
                        "modified": 1698752579.4214184,
                        "name": "/dev/shm/incoming/2023_10_31_114243_00_01_V_066.kml",
                        "sha256": "d59382af5143e9d2d6de4814d6b3d5b18dc4453b6777e4fd87c83c048d678554",
                        "size": 952
                    },
                    {
                        "created": 1698752579.1214185,
                        "modified": 1698752579.1214185,
                        "name": "/dev/shm/incoming/2023_10_31_114243_00_01_V_066.jpg",
                        "sha256": "f3d354fea4e162bcddc51865e54aaeecc918851f42335c325ae2b05fbc75c3cb",
                        "size": 1549523
                    }
                ],
                "mission": "OSP1",
                "sha256": "640af741c4c0164c58ec4dadc2442086b2d7734a355b2854eeda4062c1e2251e",
                "size": 1551100
            },
            "errors": "",
            "filename": "2023-10-31T11-45-07Z",
            "size": 1551100,
            "verified_at": 1698765938
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698753699,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "usyVZ0dRKffqayJ9FbM/o2/8cxrvPBjm2zSR5HvE7NqgHT8a66j8qu/MYRZ2WWnxh+YITDbj++Vkn+EkdQJJWa74NMY2Qfi6p5yPvnmotSDqShtf8Wmx4/jF6Rm8pWe4y+m5Oqji55JyDEbq13q0IAXAOg7IeQlnVvxVMg2oFYsCLIXrlA66USYo38L1oDDT8s9LJF7bj1hH2zl8C6KEKkImzXsT0lWHkloizqgkDmZOVLOHJ8lpkcbyJ3ZzsW4/dnS/uM6zwjDUPOYvs/sM+oOfyafpzDpsrVIp2L2W8LiVrwxeKtoRLZQB2S2m/33GiAJa7fqou/FskbFqUO/5odeIinjVSnaU1ltCq2M6yLHYPGu5gFYR8PXEN8oGgsgFMm8YlK5c6QokCuoCTwYWqX7zttFJ9nkht9VNcfowTWh6SZNdNbjvBj9MdnAsCs9rzBali7KdJl+i9bjEs44MAW6rbx6S8KqJ3ms0/HX4NLcT2gvhmNwzSrLTQWzQFLEzkB4DNXOpSHz9pzG9TGmjDkM80y2J9QUSebcMc5ca/hT01J8IKwHfVLNAV+XOZ0Hr6rAL9XQ+grfTA7+iIRwe5LacNf1LqE/MPcvRBGvlPCalGWefl1jK7fQQEnFLGS5EeLB02T8BYXM9pqtub0XM6/f7UKQ+DCB7b4XlRVbWhuI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-01-26Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698753561.0614185,
                        "modified": 1698753561.0614185,
                        "name": "/dev/shm/incoming/2023-10-31T11:49:20.ais",
                        "sha256": "5e9b3d9959be0c99ef7610a1cf95f9ee1a84ccf34d3c3a2bd2039ad6545088c5",
                        "size": 281558
                    }
                ],
                "mission": "OSP1",
                "sha256": "2d189034b86fda23ad033ff3f4050b86de0bebb6388a14977137b5919ff7e2a2",
                "size": 66563
            },
            "errors": "",
            "filename": "2023-10-31T12-01-26Z",
            "size": 66563,
            "verified_at": 1698765943
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698570318,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "oEciztnlNNrvLRUInmSWBk0zxGkFVDcXDgdGn5NADgKwql397AqjuJ+A1z6m00pG7vgFcrhsbxcS94SA5OUBeBZbPJSou91Zeq3v3v2giudAglvfzjQ7DDZyAA1r5C7n9jc/3Ey8j/nRiI+zXMpXil+FGW1VNJOEO2bTntrBHdc/6C/ybMxYaAOM4HKMMOIvlGUhkBvTYZNwngRYjkeNT+kw/tOLHS3t+TiiFvYepWQhpt331QRld8dW7UN2AalXqkRbCzP9XYLo1DtyWDd7C1axdFOtd94YuxOFHmu1A1bcl3VFc6/kcsnXH9D1UK4ix4vWHE9pXEpvzn/jeDFcn5rdEFl6wJ1OSUICr3BCsZokM1idnZZwdArCRHbODjwCH2/rR/kpIVmQGvYzq7GCKn1RogEKFKkcyIxktr/1TeC5kmpCpnFemP8sk0z3PpNK7cSjXqt2QUE+5ENkAx7xcpdaZUsKT0l/h8ssW8+sbdT5aLHeTSdcmWqn6uZBTBvrrPUjnd8S7tUhJI2AHv4/YF6qTTDkPZUPhuFc0cI7+uyyhzCba6UJBnzz3cXu8+bv9pb+v0YjB6veuqhT+Z7SLn3IWIsH6tbkHrnBMMUH7T/nr2U1QtFPAb2G3k0wb59k88/yKbNZUcH6uWCmxnNTNBRv21IlOemkGVmcKN5TStU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-04-51Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698570163.3809402,
                        "modified": 1698570163.3809402,
                        "name": "/dev/shm/incoming/2023-10-29T08:52:40.ais",
                        "sha256": "45813fb76dad026c5a7a5b3406c248c740ada5944e87ec2845ccf4cad31c9f27",
                        "size": 102335
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "eb01f0239c725d3ef192059d8afdf8396fc331f333967700efc873a06c9196d2",
                "size": 27785
            },
            "errors": "",
            "filename": "2023-10-29T09-04-51Z",
            "size": 27785,
            "verified_at": 1698580353
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698648526,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "GZJgVrFhuL7Oia6KcBujpI269VhMK7P9hrQiATTFDWMcbJ/+oXEInTq2muRTuuOk+BHQpmD/D9j5zol4Ku42Sm1ySxN+tiYsqhdptEcGtTtzwdSZeFwvmIU7RpL1faolCG4fKf0jUmRyZewMOfZfoxn8G8AJ3hokc853PjWlwYjHAptsROOSt5KbLfyKWFwZiepyC4G1ys/DmMYdd0u99aNsxxVIBvUixQw/Npc4o+6cOiJ23K9XC66K6E0tBA8YyGsGha+gIUNj9G+BCgrETKVW7SSFYwyuXD+/PdaNbEkHZllDsy0A9N8sll43uxLe/AP0nh85/ZNfqJz4x34vNdWLkhQuRg58EXDMmM3i840L1nFuYO9vcu63OBTriLPEPhEQVV8Qh07rqh/ZdjFxLQ+lMlOao60BDyI7YndfqKyPbkwcEWzA42jPm/OrMGxnqLca8/50ms+QZ4V/RKp4LE0U5pyJbt9T20ii0yJMblqzVk7WVxGUOfVFkIilLgf1UNiDZD/O3Tkzm6wouCl63+1StLbfOKJv5MMIloG6JM2CyJ/EQNfktY1BvHvZzFRtC2rjWIOg+FvorBeti4e3PcrnuPJrgsK+SzOXbiyrmZSGcBiAMZ9DhvT3H+qjgtYKwIrm8pFywOBVLgdq8suf+B+UivfzqzCZvldZnPMMqZY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-47-42Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698648453.496036,
                        "modified": 1698648453.496036,
                        "name": "/dev/shm/incoming/2023-10-30T06:17:32.ts",
                        "sha256": "bdc37afc5ab95ff83ff7e22a34e16c414be545b7701f4530692b88457b7058f5",
                        "size": 402275820
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "1933a0f572ac2fca7340ee182c58485777fb8a86bbe4c2576262980f2333d36b",
                "size": 387964795
            },
            "errors": "",
            "filename": "2023-10-30T06-47-42Z",
            "size": 387964795,
            "verified_at": 1698665907
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698758504,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "PIA4T3jQlLFFiT8VhyO6AFc0/9ispjhv1AABqCeJ2+8weTNU0f7+Tp91dnm0BHpnZ6PiEi3JDBZc1aKfp8PwB1Mlw1+BTUtROF4JRCkyoANFPYIWMpyJoXW0Nd81EAGGYjfghZpmvsL7+BBH17morwmkphXQK5wFPXXSa//zO3McX45ee11FN1Px000jOFHSWLMvFSWyW+U1fho1Izpce7RCNoMSKd/NfYIWbqDvU71HfLACyPw18WmDEto8X9XjlFNCMUsfsjaQ71QyMcLMc5J4zEPMarxa/yddlY5Q53K9RGqC8gLIZ/GUGhB0s3IbHB/EwzctfVbSk5gXtvEfHECnSjxuZVwWnfM3GpfJc67ysBMFGYua12xBClw7hP2L6AOJWMEjc2pksfKds8qsQQr2LFYCXO0qNYwhVyTgUXp0Jd9bURDnMfZ8mw8yBUVyDor6LrxUVBRdXESLFvMEDL8Qioh2qOjbULZLM+PDmclKsLupif+72yt2O6crUssrdggZ7dMT/p77qhggEzK/9krAVpg0P4E/tbqGyjtOO91sJYVX/EEBd06LlW2vz3H1vPQOFtctjPgyGURiiLdx4sMMkpDpozmeud3KNoqcCpLrtWWL+ZecuvpBaoCUxmq56d41TREaxlXAowTwcKC0PRWlvriPXAuUecoEGnZ+kgg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T13-21-33Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698758369.0314186,
                        "modified": 1698758369.0314186,
                        "name": "/dev/shm/incoming/2023-10-31T13:09:28.ais",
                        "sha256": "e6e7de1cc798c66e3312478abc104068810bdc538a327b3654a82d91762e504e",
                        "size": 229376
                    }
                ],
                "mission": "OSP1",
                "sha256": "88fab6bec3ab50532d1230cc10e7401f41d466f9fff74de350e82133bc62c99a",
                "size": 55084
            },
            "errors": "",
            "filename": "2023-10-31T13-21-33Z",
            "size": 55084,
            "verified_at": 1698765951
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698570642,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "TFxjKRERlMoBgQjUuQTuYKF2LX8yos4vRde6wOciAnnHtetKFDCvN0EBzQyZMLFEIYM5UdAz7wqTCcsPobU0ARWSaG1vCTOYJ8e7DOF/fO6/y9N3R0kOpQJTDv3v/HjhNc992q5+jKuYh0dxkbFkyPtn8mPatYj0BRsRem6Gl5Pk7FeXT+U1nBro55CWLq0sSIORNxVB0rLJ5d5VLfD8sZOprJAhVxh32gLSEzIXK/QznUYAVCBep+uyuLehLtW/6mU78x/1MBF5fSi3MnggcFd/2uY31QBEykvK2kfo26ilAV4uknX3jXc9TBDSvRYD0iaYTeeuPw9oG5x2Wxcyh+1MsrrYfybWNab66ZKo0Ute+o0xg7aSIv+kOGcefaCgf4QCG9c0kEZYagZ7UsqBeoWC25odLc0vSrlttEPX2vNeGcUYGWz5ddgWBfYRazQH4zspFXyZXAmGUD0eBnifi3Xs9ypNfhrY1Ziza3RnwoCF/SbAiPWvHKsDgMXZt+wUyjkrlKerD90WnngDpKPI7YMkvfLH3HTZK+5QTJR5f2RoEE0ZATQbJC6+7QuS4yIghV2tT/noufNAPE3WT/747UvRzCMiurosCEBgMx+vtZRDhg8HYos66tfid4BKUrs6YRt35Ahefp9m4KPxwlzGYIUos8SVIxOuTLysMAzTHJY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-10-37Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698570511.0679688,
                        "modified": 1698570511.0679688,
                        "name": "/dev/shm/incoming/2023-10-29T08:58:30.ais",
                        "sha256": "09e81b512e58efdc686be6102fdaaae9647cfa8e17a34054e944537395b0024d",
                        "size": 410839
                    }
                ],
                "mission": "OSP3",
                "sha256": "9ad991fd33de30d39fe1622ac2474f9b5b4b229aecb353c749d6143f6ddee978",
                "size": 122111
            },
            "errors": "",
            "filename": "2023-10-29T09-10-37Z",
            "size": 122111,
            "verified_at": 1698578176
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698570913,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "VHze07uGQv/Ip7Nw+epp/buqwwAhJp3nbmTXWbVO1bWxZwnwt2njcCmz+/MfjgOMSRJzdBV0BEW2zDpDBZScaPuDyTEtA9cw0Ob4k8S1O6KMAUGPKXJhpS+9g5YWp4mmRGI5AyhrqKKyHhLnZXdBhIpl8QZd4vNfkwg+hRG50NTK35Itk2WPlgAEJ+uqBFHBNgOUGdRBLsLr6oaUkKHFb8ttBKRttdsADmEZSMPYZ6ndiDm7eXuHKzLitbVJSjdYdSHMyqDgUss6zwLcM5C1VzW0oltGVMcZhtq6AjjHfSv+LXVBwH+EWS9Oe7Lu5HUa3NuFdYLswXIj5YF5vOzxdqtL0OHzWTRdakJ0cb9crxDDoegYoghCk6AJahWteDjL5ffnwOPSryGFFX3UT7KgA+7mbqQe7I+xmVv49ATaNZf5ELcUL1Z9EuJfW6YpPTtMBIPrqQKgb/uXFcdAYsf8LMb+uG0zx2IdcUmuWTHvBqzCtYPcu/fbS5pbMrug9fBZeCvC5UdTRzXhBdAK4vTGSl4Zn8MAxFw2UFLG+JoLGekNzLzssdWyWt2uJtDb7F5e6nFvjVbxKC+N7N2Ho/cyT2UJidq2Fd51JBC9Vr1aHJ02xl4zXmihxIVLXdTMPEzS4mHLSd/VUCOytcHyIlYrU/hfFYFBmmrKLrQ2wH1NVHw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-14-49Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698570765.48094,
                        "modified": 1698570765.48094,
                        "name": "/dev/shm/incoming/2023-10-29T09:02:43.ais",
                        "sha256": "f71852118274bd2cb3e473e9586e4696d0cc9a2721900bd2aaa0f58875668b36",
                        "size": 122667
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "a0bd63666b9fb03cdc7d7e077a16c977c1b8448535e6ebdb85168c8b82c62365",
                "size": 33383
            },
            "errors": "",
            "filename": "2023-10-29T09-14-49Z",
            "size": 33383,
            "verified_at": 1698580347
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698570881,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "PC/lK3PN4bb9kY4t7z4zrf0Nh2VsadVVyQgSg8SQUk3y/cNUSh/DngY0gVKggG+KByLKZmvwLOfPJ7VZNV2b6/ISWIQDoo87OsERPySbwyWSevvwwt7sDydJbfW0rJ9ey5DujqQbjQ+Ofr6jvWCSliV4ZQ1NdoQfqz3x+fckdx8FvH4tYKKuaypC9U47B1tbRvcqIZxeNpv1L6tbMrre/sIsf5KTysiEC8qAe1haE5PtaSl5MEfx5dNk+G2mYYPz6ZHimLw+X75ugZ5+9yGi4iCg+2IsWKlXRp2gdj8Yst5hmYUz7H3fQRiSKcbSkc/Vsd+Wvqdfpjdbna0N52f6BUrrNcBLwwEFmyw4J2lSId5058FsoaW9qFMfQ3PLYW/w8UZnolx7Y2pyObRLI2k+LwQA86/aIqvmM7YWiNj+jzbzREHWefDazGFBJ8kS57iWVxX909lZ3nZi4WPq26tCZnZM/eX+87dWKeJoxX2tISjpydJhgP1HkcQXHnnVYV2ZvS8dKV1xfhvlwMB2fqQUYmJPOXaTqUh67f2d5ewsdoOczC6q7hKA45OYzz/1S1nUAPk6RbvKo7ruQm8Rz6KSBJazM6C1CCTo09rR7ccFL1ek+5lgPc8CMlPjhXKflwK6OpLOohMziFABTF+VNuRojHSsQjQEA8qjldTsXKEzfEc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-14-21Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698570733.313234,
                        "modified": 1698570733.313234,
                        "name": "/dev/shm/incoming/2023_10_29_091157_00_00_K_045.txt",
                        "sha256": "bc6eea616ec409ff8889aa32e112fb059dbb352e93b38876b7bff39be07dc294",
                        "size": 2050
                    },
                    {
                        "created": 1698570732.9832342,
                        "modified": 1698570732.9832342,
                        "name": "/dev/shm/incoming/2023_10_29_091157_00_00_K_045.kml",
                        "sha256": "0801ae71744f45f5ef184b0522840069c106fc77c2d04581286976a5b60eaf0e",
                        "size": 977
                    },
                    {
                        "created": 1698570732.583234,
                        "modified": 1698570732.583234,
                        "name": "/dev/shm/incoming/2023_10_29_091157_00_00_K_045.jpg",
                        "sha256": "1616f0ee38220e66f08d69b7908120b3a506e46b3a8a0cff3ddd1d679a56e923",
                        "size": 1044811
                    },
                    {
                        "created": 1698570721.413234,
                        "modified": 1698570721.413234,
                        "name": "/dev/shm/incoming/2023_10_29_091138_00_00_K_044.txt",
                        "sha256": "27793c0e46f87d489fb1378c877da421de08b1f05f0f201e436a44f14107b916",
                        "size": 2050
                    },
                    {
                        "created": 1698570720.873234,
                        "modified": 1698570720.873234,
                        "name": "/dev/shm/incoming/2023_10_29_091138_00_00_K_044.kml",
                        "sha256": "92990da93f8130d9e653b6a5010418c0835890421beed1e25c17e095968f8655",
                        "size": 974
                    },
                    {
                        "created": 1698570720.4632342,
                        "modified": 1698570720.4632342,
                        "name": "/dev/shm/incoming/2023_10_29_091138_00_00_K_044.jpg",
                        "sha256": "e7c399a036a2ff7421ff33007de8334459e67189a38ab422f52b125113a1c28a",
                        "size": 1041597
                    },
                    {
                        "created": 1698570687.323234,
                        "modified": 1698570687.323234,
                        "name": "/dev/shm/incoming/2023_10_29_091112_00_00_K_043.txt",
                        "sha256": "4375c02bd3a63ad8f79de3a556d4b3f6add11c9c58056cf0117779297e3c3d9c",
                        "size": 2050
                    },
                    {
                        "created": 1698570686.9932342,
                        "modified": 1698570686.9932342,
                        "name": "/dev/shm/incoming/2023_10_29_091112_00_00_K_043.kml",
                        "sha256": "28666b33fe0a77bdb4c3e74a67707b73b062a5eaeb09eccfd20414e88670cf4f",
                        "size": 974
                    },
                    {
                        "created": 1698570686.853234,
                        "modified": 1698570686.853234,
                        "name": "/dev/shm/incoming/2023_10_29_091112_00_00_K_043.jpg",
                        "sha256": "b89821f109e9f064c096766383351feddba491cf491eb77cadeb21875e2542a6",
                        "size": 1129220
                    },
                    {
                        "created": 1698570685.9432342,
                        "modified": 1698570685.9432342,
                        "name": "/dev/shm/incoming/2023_10_29_091108_00_00_K_042.txt",
                        "sha256": "d77fd84105501168a89d5cef54aa2f2f91c690efec8c2c5f759cae8bd9d3a64e",
                        "size": 2050
                    },
                    {
                        "created": 1698570685.7832341,
                        "modified": 1698570685.7832341,
                        "name": "/dev/shm/incoming/2023_10_29_091108_00_00_K_042.kml",
                        "sha256": "b200622cc1fb5df018e9372a91c00ebdf9357b62bc0e66ac23d8e1fb4a905af6",
                        "size": 975
                    },
                    {
                        "created": 1698570685.043234,
                        "modified": 1698570685.043234,
                        "name": "/dev/shm/incoming/2023_10_29_091108_00_00_K_042.jpg",
                        "sha256": "2cdc0b860bb833268ade751d539a8b074b07d2ba7d63e36ffe3ea54847f8aa21",
                        "size": 1097382
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "af1840917f57860b9ab9183f27d4f9fa3695ae135ea5eb0d2a72f0f28d97b6e4",
                "size": 4316790
            },
            "errors": "",
            "filename": "2023-10-29T09-14-21Z",
            "size": 4316790,
            "verified_at": 1698595614
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698570729,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "sotiLyYUhps0R8BirMW5iP+WOqQcvPZ/tzc2cmW6v6ou2km4e2XJha7NKgd1TBP4hxELnxtYSFu2M/PnnEr0fwxEjaTC0RuvEDBkFj/8oRK7goI/O2ooyAPrkOSMN5ez/00eq7+7zjutHr0OPIYYXmcRStN/GSHgE0sSQb5+G80oP0JpeI4eARt9O6yEW7gOGKnTmzeDMENsxvfkPl75qiHZFNBDeuOyf0S5tJTMD4mXW8gH6SjL3oaR5532KKxr9G38f85ZHrDCm0v33OYFsbKt6B25RFwu0PkvRJMS5UTMQMESGay7gKZzjJIIXmdEwjSCT+wj+UCy27zPVI7aARFY+A6OUUBs0GZJUwm37l9PV3AWbJ4rNJJH1fv5uXu5IIdYHGXAg9DYH5ws2gXFoSKlZ8MyLK0B4upk8FQYwdFyiDdM1VHDne+EpzBt4CFmSobVaJ1gUKV6omthMCokmvoibWOtlKAbQAqZcr9iamOSTt45heUEtJRMF3HhNAtv6+BKhx31+jHuoDgs6dqSIjpGRLH9AxhwJ0EhYSN/IxqrfIFukhWKY8rftBBF9ymEKbTjLtNXwisUdw3jFboXtWuK7fu6w7ZNor7lBYGxaElCgnlD6abzQu9DyTskmJbXnOAwGUfWWwPXJt/2R4Q0CDHNow++bogiMuucy0csmXU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-10-47Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698570630.7532341,
                        "modified": 1698570630.7532341,
                        "name": "/dev/shm/incoming/2023_10_29_091014_00_00_K_041.txt",
                        "sha256": "2d1bbf289aca17e928dc09434ae77007743bcbecbc4ee2731a29a01931c143a1",
                        "size": 2050
                    },
                    {
                        "created": 1698570630.653234,
                        "modified": 1698570630.653234,
                        "name": "/dev/shm/incoming/2023_10_29_091014_00_00_K_041.kml",
                        "sha256": "bc931745913c27c04d939bf886dca7457d15e675c9894435f1e139b5b0491749",
                        "size": 978
                    },
                    {
                        "created": 1698570630.073234,
                        "modified": 1698570630.073234,
                        "name": "/dev/shm/incoming/2023_10_29_091014_00_00_K_041.jpg",
                        "sha256": "a82668a92e745ec6885d87dd0799a6a3e935b4a83012a60566ae23a5ff5a291f",
                        "size": 1081646
                    },
                    {
                        "created": 1698570629.0132341,
                        "modified": 1698570629.0132341,
                        "name": "/dev/shm/incoming/2023_10_29_091003_00_00_K_040.txt",
                        "sha256": "eda884972c28ac7e09e8a12e41ec9d39d309ddcf0101cfa748bbcff88438ea59",
                        "size": 2050
                    },
                    {
                        "created": 1698570628.7032342,
                        "modified": 1698570628.7032342,
                        "name": "/dev/shm/incoming/2023_10_29_091003_00_00_K_040.kml",
                        "sha256": "b39c196d6bb8b8f15f2b91cb6d22231aa441fbe1e49183a02c16a445b0d3016e",
                        "size": 976
                    },
                    {
                        "created": 1698570628.573234,
                        "modified": 1698570628.573234,
                        "name": "/dev/shm/incoming/2023_10_29_091003_00_00_K_040.jpg",
                        "sha256": "72aba3f3c63a428012e3addbed9abc9c4dca30ca9498e7420da09ad78ff2f1f5",
                        "size": 1181953
                    },
                    {
                        "created": 1698570616.583234,
                        "modified": 1698570616.583234,
                        "name": "/dev/shm/incoming/2023_10_29_091000_00_00_K_039.txt",
                        "sha256": "788452e7186d00bdf3bd6a68f64d4225ffb3d493a1e0945f1d84790f6e6fe85f",
                        "size": 2050
                    },
                    {
                        "created": 1698570616.393234,
                        "modified": 1698570616.393234,
                        "name": "/dev/shm/incoming/2023_10_29_091000_00_00_K_039.kml",
                        "sha256": "ad47c27c985e9c6c51d6839a1d8657efa283990b455933fb64517cd65d7dca24",
                        "size": 976
                    },
                    {
                        "created": 1698570616.313234,
                        "modified": 1698570616.313234,
                        "name": "/dev/shm/incoming/2023_10_29_091000_00_00_K_039.jpg",
                        "sha256": "8a79338d0a9d96c9f35a060fd18b5f8603a384aad7a283303968fdac90c398cb",
                        "size": 1156360
                    },
                    {
                        "created": 1698570604.423234,
                        "modified": 1698570604.423234,
                        "name": "/dev/shm/incoming/2023_10_29_090944_00_00_K_038.txt",
                        "sha256": "d60fe36570e9d65977a265273f85fc2d02b75c0ac5c37bc34d77d42caf6623d2",
                        "size": 2050
                    },
                    {
                        "created": 1698570604.2332342,
                        "modified": 1698570604.2332342,
                        "name": "/dev/shm/incoming/2023_10_29_090944_00_00_K_038.kml",
                        "sha256": "728edda86d8d12d09418074384928061db0ad7cd987fdcbba3faa3b5051faabf",
                        "size": 971
                    },
                    {
                        "created": 1698570603.843234,
                        "modified": 1698570603.843234,
                        "name": "/dev/shm/incoming/2023_10_29_090944_00_00_K_038.jpg",
                        "sha256": "5db2ec9fade2311041f18db8ed83477c51563bcfb6633c296dfe9974b7fa6a95",
                        "size": 974161
                    },
                    {
                        "created": 1698570575.173234,
                        "modified": 1698570575.173234,
                        "name": "/dev/shm/incoming/2023-10-29T08:59:31.ais",
                        "sha256": "b12141a3c1a180daf9fa29a005ea041b913b596969929626f89218c6a7648cc6",
                        "size": 182757
                    },
                    {
                        "created": 1698570638.4332342,
                        "modified": 1698570638.4332342,
                        "name": "/dev/shm/incoming/2023-10-29T08:40:38.ts",
                        "sha256": "d01ced6cf770047a9d80f00469896c31b87e1c856f03819f06a3acf6e4f71e0d",
                        "size": 439933348
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "7afff7f061dc60afd7f5c23b13bb235484b99b5b24d86a5c644b17b6733903a3",
                "size": 402427200
            },
            "errors": "",
            "filename": "2023-10-29T09-10-47Z",
            "size": 402427200,
            "verified_at": 1698595959
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698585816,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "l3BiMe6Dd2OeLoxlah4gipUMDcBo8haD4ADFexjA9PjfuVuDTYF0WMEowf9Q9JU49gPwdmjcVYWyg5iZqnrP5clxV5aJHfwnpdmBvmEmIKvq/EmxKbdIlVMFQAzqwSvbr3Mzpp+XLyH4XDu4kqyKONriOMeF/oTLTo9yqR0165r+jTBTD8BBEeCgfRXNlvL1ycOjy+HDiI2AypiXalQTDVqNGccIkGY4szn//3wAQ8xQlzxriWijrgvQEXMRV6I/OLV6twda1IHyb4bEWc8n/W8JXjw2TycK/QXp9oMDhpXzOSzMb34+prI1ro6G98TAcQgOEykYhPjFrDzr2MCs0E3F/DKJylO6RvKAYEpDXYcbaKb+vnyvcO6rF47igkvV2Wkb6zTlAEYsX2Na5Ld3cwgvmcwJkSZhidxUDpdSvdHnQDiTj2ifhZuLWHr0qgOrid/jPW8ylhtdw+pqW1OML/4sKgAWA0T9yKVYCnadYostX1NeCkQGRKgUD5qBTywmw3od0e5DbZFuhDStdRWiD9B98tlHmD7UutlCE2i/v4df79Ks74a0OGGZx6DoPsWb174Crt/AXkJp54VrOvRyNpr4oswwyExhLpFgXqVXBSa6HDQkbvA5X58VDMTiuUZlv0v02ud37kI/77oxoCeHStnoWPnyQTWhgRawJ6fsKVg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T13-22-45Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698585640.5232341,
                        "modified": 1698585640.5232341,
                        "name": "/dev/shm/incoming/2023-10-29T13:10:39.ais",
                        "sha256": "f05bc6aa9ea8bb9d915cff7ccc5ce6a9882a9eb825ebe8ba1dd2942b970c577e",
                        "size": 863660
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "054adcb13a472c03b6b104a7c1939317d905411e0a01c06554b0a2481af437f2",
                "size": 258882
            },
            "errors": "",
            "filename": "2023-10-29T13-22-45Z",
            "size": 258882,
            "verified_at": 1698597133
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698759112,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "J/gfLQJGeHxEX8Jgyh+IEVWXSf8LvWwPQlDePJhYfz+FtonKeUUOo10VCU4Sfb2r2swRzHLeVpUdZLZ4EhfBWSlmg7SYpTm9mc77FSI3KgkIDCdOIEXGITIXR6Voq+M6QH30MBP6xcjf9L0hgg+eUxoXiWPVtvrpPT9FnS6YX0xltWMFDiSTV1NlrCn5x5BA//fHNJ6HqUHOg4bpsE2hG6f/m6ohyPgwK3eSN5kUHZ9Qf5Dxrf1GllcApHA8VBFkbmxqhyXXX05lrVT4xcLUuCQ8nLSpgt8HRglsgBLlLkG7CeKm4IkkuLaFStUOd0upe2gH9HItsW6Jlt7u+Os4W7BDZqhnSryxLy00nm8PeGEvow4+jMxwBfIwLeL1V2B6AD6WqvhSl2zvg06Fh7LqOOHwThTSu62rWTky3V86JzfRPyQeZA4cjindijUh/me0LSPtdJeJqQGBrQAbIBtOZzuTEdibitef8ZUiqympBKpCLB7g2bhgkm3NqLgJc20sZ5X0vheqPKM/bRcmoeS135wPPrNTEB27CF1/3G4yH3FhDwsWAJVimKX5cgC4SuvzQdKoODeIMGdoQUI0fBDg9zbI0F/Q+IGkD6LiEQANz0RszQAA430nGKLasMDKE1ulKGILPubDpyhLp83u7BoRqhj6KxnWj6aw4AL8MVj237A=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T13-31-44Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698758976.8614185,
                        "modified": 1698758976.8614185,
                        "name": "/dev/shm/incoming/2023-10-31T13:19:29.ais",
                        "sha256": "b91b002862232978a22447de161985f053cb8cdb072036d73e48aec89ce6efa9",
                        "size": 96100
                    }
                ],
                "mission": "OSP1",
                "sha256": "17759d061df65d34385b75cc32815d06afc9da0c27cc2e88360c1b07cf14ac68",
                "size": 23336
            },
            "errors": "",
            "filename": "2023-10-31T13-31-44Z",
            "size": 23336,
            "verified_at": 1698765955
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698570970,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "gGDWYMApNCv3PrpFjR65W13aFPebNCnMTZ+fIxMsARtDgNq6yeTPR/KLhUUh3tKIKbQW/hnLMUvpDteEGp39z0l1fpO5lnlZT0ygT3aKRsebdffIAchq6ZUHq/QB3wA3Ujj46TliZ59g4KDVnYE7E35Sw2Kozeor/ZInDolXCt/l7F/5NpenfSC4MmKm94Mins2TGCZrTw0RWy1SP98dsr8lcq0D2G8pCkqcVwdH/Kd2wBtXrBIUDHWetkIMqGioPdSb0D5Nu7dhvN7FcTNyixgPnqm8yVpD7BrquYVvTDZuNsxF88xZgjUnzHLXJUR41bS22OkjflVkrqxlQQWqxVqHJ5MTUwAOh3jlIKEyvGa9GKVmkuxG4B5Gt2FHOdM2XsXi/m52HTfIiYLa0NJYofVYsgY4G1LTI0GWZsZJhC0oyxcbxvFzY73sfSKs2imAdih1NL7lea115/Ib28Gv7wA6Kbvm3fFu/b7N+zWKUHyeOyZNMv+aI2BcY705YQL2eSxqv/R6lTzXkRTC186JUiuY6NFGa3fouFJan8MUyaZe9yH8mIP1XoiIT6dfF/M/F3cj2f5gJ2cXzetZaqm7Ak6FuoBSi55MknV0Sh36z9qhGXsKO2iBG8cglaCF4WaiG1+bNsCAfKRbwp1H0lqydrna6WL0Vc6RMWf1guQTKno=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-16-06Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698570839.887969,
                        "modified": 1698570839.887969,
                        "name": "/dev/shm/incoming/2023_10_29_091337_00_03_V_013.txt",
                        "sha256": "9bf827d0886ccbf06b16d8966409ea41bc3f324daadfd8d8700b54776f828f9c",
                        "size": 782
                    },
                    {
                        "created": 1698570860.407969,
                        "modified": 1698570860.407969,
                        "name": "/dev/shm/incoming/2023_10_29_091337_00_03_V_013.kml",
                        "sha256": "7416bc2030478645b5f149d4aae5140524322fa83b4717225479f3c894a6d864",
                        "size": 892
                    },
                    {
                        "created": 1698570838.757969,
                        "modified": 1698570838.757969,
                        "name": "/dev/shm/incoming/2023_10_29_091337_00_03_V_013.jpg",
                        "sha256": "7818002935ae0cbaef797fb055f8449df3c6ad6a6ef281c001788185c74afaf4",
                        "size": 589082
                    }
                ],
                "mission": "OSP3",
                "sha256": "26c866eecadefecfebbf884132bdc4c8cef3cc78d3abad48101fb9e9d73e8d95",
                "size": 582376
            },
            "errors": "",
            "filename": "2023-10-29T09-16-06Z",
            "size": 582376,
            "verified_at": 1698580384
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698571037,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "gbBE0xnwGnVAiASXIkOZ0VF1RMrwhnqHfvwAh0Weu3vxGex20EHbHWm9ImC43+0CVNFUQd+EbmyprznwhPy7ST7w4EjGU0TBkhM6H8HCYQ1lpML1O0WH5SsliekPyWxG3m6jzGDjT0QWtcL7l9MzgTuslT61OWBT8oJyawQQcGowLQB5zrglpoYBlRN1mM1hcXgxR0nrXWOqaG41hP5nZVe0A0iONxpDzGtb1KB6AEkrszVb3hsoVTiq0PyhAFZHf0JMynLlurA2cu3nvQLkHyuDaWBFFvzDSTLdBLS96H4Pyx8zV/OXfRGEKDA/dbJgJSA9LOzFME5VpG/WOmQ45EY+Ko9R4R6yFz81T01tup4fMperevx7Zdlv+QnBC9BeeveKu4YmYCKohvI0JKcYplukKmX4eJ1VO+69diTPkapG7Aqe5064OoC2LKEyfNu50WN9zqIYWumKbhE1B9+ExE4EIVO43Y1fz/Ykvt+YAf/i+xuXRb8QIkcm+8uSnAcAZiZ7MG17qJMiAVSuqRstzFRhSEu5ETYxcO2FdnK8EHaKe/3hlUECUvwDfJqBsWCKa9vrgXQ+mvynB4VPpSpasZeGywzDhDFHixAZs43oDx3HHaVuZ3DAQQQK0hkWzMc307iXiOgLEusNPDTHJjx6aDeKtPy3NWDu1XzS8sF+yno=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-16-43Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698570874.543234,
                        "modified": 1698570874.543234,
                        "name": "/dev/shm/incoming/2023_10_29_091416_00_00_K_046.txt",
                        "sha256": "dcb5c95413635063176be5038c7fa4ed851700548e0a59fee1e8d6fa39ab17d5",
                        "size": 2050
                    },
                    {
                        "created": 1698570874.0232341,
                        "modified": 1698570874.0232341,
                        "name": "/dev/shm/incoming/2023_10_29_091416_00_00_K_046.kml",
                        "sha256": "ff4e1932ca2f2aba1e18106f09e375ca0072602efafd954bdfe09d5462130f9f",
                        "size": 975
                    },
                    {
                        "created": 1698570873.6932342,
                        "modified": 1698570873.6932342,
                        "name": "/dev/shm/incoming/2023_10_29_091416_00_00_K_046.jpg",
                        "sha256": "767bea5e9996a4b4267d1ab4c65c540080ad3761c319fdb37937523b0ddd0943",
                        "size": 764465
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "650d8f8d28c15575c71151fac3a8010e21a6cee5e65db41fdaf4b26375b4d62d",
                "size": 758047
            },
            "errors": "",
            "filename": "2023-10-29T09-16-43Z",
            "size": 758047,
            "verified_at": 1698595667
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698753099,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "gsshmE+73M6dmTKy6SJn6GlQ6Z0I07LvU8vQsRIiea+1yUjKZHg9NQ/kliXqwA5GHWKr5n4eplYIjiYPjRevidzM5FMJPPy3WsdQhb4ZdIXu8SO18Z6FyhgMawzlkv09UUgDSmhgM0GP46/6h+GtUI1PiMI2z2qoSdU45V55Jv0l23FA5YknS8Fhf4R/09d8ITOpw/DwLV1MNR4/BRb5rCHkf8Jl6nxsVyvzRxpSdipG/u/N57D7m1wAONP043EhDe7XWqJiPXtL5V9kZi4gdQxIZvysJceHN/EhLrljLJCOPbcP1hsKUjCGxWSukLGtKNcws6DhAPHyiXQk8zhy07WSrflXiIo63hD1j1gqZy3Iwys2EjdEth6j70FFhkhYpCPR5kg4g47hJEYN5x7ovqTDMVp6gzlmoZF02pHL48oQ2CsqDJhRfKHl9rdmGh97/u/AykzXxUgjrrEZgAfJNuan58Sp5eCOvOAecTDvIxFURu5mDEMtS7CbCx8dGxGPEGkW9l65ZQHtsGYqywdjxiqpG9bvsO0iUJ3RcDBhUD0Jr5z+wCXNIWf4G/viwMFqe9Ztzx+/g5XSTL8q59sDFAtWwkqvfpeluoGORxqZt1fMU60QZmSVIxcn6p8eLElWH6ZPEDNesOULHXbFcSwDVhMlVRDexS1MGUqpga80ILo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-51-29Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698752960.3014185,
                        "modified": 1698752960.3014185,
                        "name": "/dev/shm/incoming/2023-10-31T11:39:19.ais",
                        "sha256": "ea5d367ca0d10dcba6b9f98b12c36a37683cdf0a3c083abd67d7f55c9afe3fd3",
                        "size": 319944
                    }
                ],
                "mission": "OSP1",
                "sha256": "8bd94ef292fc19ada6e78ecdb49c17cbcf46313beefaed4c3ddd07f6cc067d8d",
                "size": 77338
            },
            "errors": "",
            "filename": "2023-10-31T11-51-29Z",
            "size": 77338,
            "verified_at": 1698765977
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698571842,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "HL/iIjAJxhJMs2ye9wzGqSGkLnuuOqPj13ElZjbmf9qNm+MTpvnywQzWrLQb2qc77Bdz2PTvmsi3VLx7PaBjdH990gVyN74EOgqvFRKWpugo4vfTkSV3rdBbXzM7j1P8IYTpL7fA9YKlPEnAr4sLudMVY6NuPPlEOOyFLqnz+kXD58FXHCEQhrZE+EGkP02VsO9goUP8/gfZ4OGgkaHtqrvf+a2N32hF1/b10poYkeSt8+89CUYQ1GZpQy92ljmvO3IfPT4w7O9+tbFUYB/5jugkoRJp+7Uf0JqKEh5wZ37YwTBwcA4KImpljMk08NZ3H/3qsDeM15gt/ExJ4vWlOjYuOzmXuqJt9C5ulxnRAaG4CaOFnpMljRTlpoQ+6rGlZ9Xhwprgjo4iRU+mDhKPRymM0qR345YrSOund/YW/kwUnBLcF8DvfFimOD0wnXwGTAp7lywXRdPgLkwwCXyiQwpKVYDJxEsXge6TVrHT23b3U1+lycl0C4tCtS3KP3AV7kkSBAknX4SR/PmvYEoAG6/HN4Dtl2/w9HHMuCfQF3moBSBQqP7onrsnIAVAg0j4Gf26swZP3P4JK+uRSvEk14k2tUIXy5+cXyvpU714LixPnLM1LijSSmyLwjvwxdPRNj7H1kLUw0TOtnwW8Qoz8NlyffgkDguFyae0+Ho6Yyw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-30-39Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698571713.887969,
                        "modified": 1698571713.887969,
                        "name": "/dev/shm/incoming/2023-10-29T09:18:32.ais",
                        "sha256": "53710444ed9d0e8d673912fa55ff586a001ab0c5d8319a898b42befcf67a3dc8",
                        "size": 290374
                    }
                ],
                "mission": "OSP3",
                "sha256": "1ce7adac34f53055f2c0caa9a79dc04cc52721980ab8bb872df136e9dd9d028c",
                "size": 86970
            },
            "errors": "",
            "filename": "2023-10-29T09-30-39Z",
            "size": 86970,
            "verified_at": null
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698761207,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "cBZ8qzM70GRhhiR2XR45nKCtT3njZG2c0C5VqzyBeSB6e3cQkwF+fIwfxSbIN/YWaGYA2LgUhLv1Ghh6NdalYA1J7G4N/7TvKNVYo4/3VMT92Ee0XorbQ3FH5entMRNNayMwBJRUjv8xbbp4pVMFLgfMlOnsYDBomPjxYbQ+4w6iUmijw/MZYqn/6RkbQ9c/GWk9wFwMk0LGoobXoVqCBD7NKYHT8LIUEQ85oIVloBZz1L4dr+gqKijqQ/x++vRWgUVpo7BJ0wvuqZ8u73v0WfRPUQyefPJsHSAmKzZP9m8FY8wVKJiepSmtNdrdvqNE/0DSkzuqFBeWQSQ9VZKXqvCk/LLIqSQ5sTFTvtambBfRMZF8PX3rahfa1XCY2YGaEAICU7Gm11aEQ/8epMcmMf1oNWBzQDNqi8nYCwseHwjDDtIcGy//81P9ypbKzWjmAzou16TvNIwxx3fF8dtdlROflfOLdtrrywj6JdGlE65r0zCXVCEsxGANOyjRJJgKdQ1t5g9It63aUH5OwSaTukCZ4lIPiTNQ0nZpia1OA4dBoTdIzLtp6tLHjngLhbt79+dHwb4+c+0g1d1P62F6YKn3VvBD6QWS4Rv2mMJpZY2W4OHjD4EV4b6pZBgIMO1ycQMj9JTXXZ+bpsTgoQd9fpDsildmF6d1L4Df7nDsdS8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T14-05-46Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698761015.6130362,
                        "modified": 1698761015.6130362,
                        "name": "/dev/shm/incoming/2023-10-31T14:02:29.ts",
                        "sha256": "549ebab92bbe346d74e5b36a9c45aae449ebab2d8058f374a76c11426390a5a5",
                        "size": 15128548
                    }
                ],
                "mission": "OSP2",
                "sha256": "c55f54a5e8d5da593d83b8153a9d86814aebef5fbe58803fd70ba4ce51350c9e",
                "size": 14529275
            },
            "errors": "",
            "filename": "2023-10-31T14-05-46Z",
            "size": 14529275,
            "verified_at": 1698784511
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698571535,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "haplTf5vr9KD/89EExmp81Fz9HzGJEv2jlqNn6jphVU4T9aCF7+ssRvawNCYioG51MMfWbphi9Y4sDJ7uJUGAen3C7QkzyME8vN7GFLd80xJmL3tIBJuq4IzplcK2Ui2NH9aq+9W061sfO1s5PMq7wfptGyzK78f7yKg1sfrohApTShmgQdpc03K4YJFhGCZ8hr/qufj9cZEfITzystrXbTeA7VJklRoj+IHw82KoGEZimzV0Kx1oPhpFznAT/iN0zpMZZtF2W9WM7J2yD53jYsuatqxoC/DM+ezcu5WnqBHE89nzW/DSa7sT8/J0FZLfQxiiIhoHlmNlzVxKm3dQjBYo9Tr4/BQq4ApQRwFe4lSZXeaAHAqR8r6/Y8jdlS1nt3xM3dkijkDF+nQuvWwaNLolqoaToN6k+H4v6pkNPVNUCphEnhnM0rvMelXUUtV6qBZTcDnghqwrnmmmAKgdKAvS+HEaohYEPXaqpLxCNVGZ1/gAPmkz2fgQZUU7zq5llawTMgB8G70YtVC5mfkHyZYPj76a1UKEUNRe6VnlvSE11B1P6okvquCVtHGZKuV+KkE0xvscYGp1V6AJcelB/SppX02SakdigeCzKzwFrsvMUbZS0SoW0RadLmTtlF4fxelbptdLkFNmdQIppSfXBgGcpG1V2Npskuq+6cq81I=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-25-11Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698571392.2809403,
                        "modified": 1698571392.2809403,
                        "name": "/dev/shm/incoming/2023_10_29_092242_00_00_V_018.txt",
                        "sha256": "4c023c13eac6ad543460e603e4703a1344f5a6d98028f751dcf0de1a8061f5b6",
                        "size": 2030
                    },
                    {
                        "created": 1698571394.2809403,
                        "modified": 1698571394.2809403,
                        "name": "/dev/shm/incoming/2023_10_29_092242_00_00_V_018.kml",
                        "sha256": "5fa6eee3deca9df25f05bfff9eb279ebc131e493cc2bf8526807efb2ef47463b",
                        "size": 979
                    },
                    {
                        "created": 1698571390.5609403,
                        "modified": 1698571390.5609403,
                        "name": "/dev/shm/incoming/2023_10_29_092242_00_00_V_018.jpg",
                        "sha256": "2543ef62d4b428df68cc87ff1d19226aed4ce1adbc962b46a1cac835d1b22c21",
                        "size": 945127
                    },
                    {
                        "created": 1698571366.00094,
                        "modified": 1698571366.00094,
                        "name": "/dev/shm/incoming/2023-10-29T09:12:45.ais",
                        "sha256": "ea6967880b666abf880650af9425a9b4bbe868cc6963cda855b0d78a136b3e21",
                        "size": 186118
                    },
                    {
                        "created": 1698571366.8609402,
                        "modified": 1698571366.8609402,
                        "name": "/dev/shm/incoming/2023_10_29_092229_00_00_V_017.txt",
                        "sha256": "34b4a0164be444b35cf77c6eecd4f42ee3125f3108caf2d681810928dd7f93c2",
                        "size": 2032
                    },
                    {
                        "created": 1698571374.0609403,
                        "modified": 1698571374.0609403,
                        "name": "/dev/shm/incoming/2023_10_29_092229_00_00_V_017.kml",
                        "sha256": "3ee48213649bbdd96e4d6cb47df7b1e747f4e88600b182f5d2be3f3a3537c8b7",
                        "size": 976
                    },
                    {
                        "created": 1698571373.8609402,
                        "modified": 1698571373.8609402,
                        "name": "/dev/shm/incoming/2023_10_29_092229_00_00_V_017.jpg",
                        "sha256": "fd52be98cece68cc0ffdcd582fb694992d8d507da00f6738ca2812820ed0a458",
                        "size": 892865
                    },
                    {
                        "created": 1698571363.0709403,
                        "modified": 1698571363.0709403,
                        "name": "/dev/shm/incoming/2023_10_29_092216_00_00_V_016.txt",
                        "sha256": "e8daf5cbbef6b04b8988be99f424d3cdd5beaa26c5053f7bf27aada7416954d7",
                        "size": 2036
                    },
                    {
                        "created": 1698571361.7709403,
                        "modified": 1698571361.7709403,
                        "name": "/dev/shm/incoming/2023_10_29_092216_00_00_V_016.kml",
                        "sha256": "c59a1ec9134d02593b3b2c60715c5d143384b15fe1744b33ebe73e4e7db3cdbc",
                        "size": 979
                    },
                    {
                        "created": 1698571353.9209402,
                        "modified": 1698571353.9209402,
                        "name": "/dev/shm/incoming/2023_10_29_092216_00_00_V_016.jpg",
                        "sha256": "5eded0bb3af62823966862ed61d84f74c8e9ed4adace90f08497b551eec53e2c",
                        "size": 852736
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "837d8293aebfa6b46d22dfb287bbd6af684126c61e6fa6611e53da4b570a9306",
                "size": 2739863
            },
            "errors": "",
            "filename": "2023-10-29T09-25-11Z",
            "size": 2739863,
            "verified_at": 1698580276
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698571788,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "l1JNm/75krJ7B+vg8H44ONIAL7LincqddSwyWcuw375kiOln5lSiAS4mlqB8DgYjpWdS20IqccZPQo0NWD/8mIuwl8VHf8JDoBKo7sbBXeax5ccN/ne+yYwsJ8dD2GmHRdbz+f9ZRCbDaUbSs4ZI2O/JobBny+PMZGaE3M56yoDlw/PUW1tQkqG2mE4ZcCE3MUAFNKUrUj3Zw4sUzJlKj2RhfyY6Fl0jJDzxOkgImt7xEwTMWw9UlB1Gq3h0g5UZZTAz051Kljf1jkMT54AY4Hl4Flr+FqyRQ1r/zY/EveiGg25CggKBTx06jbAOoLbnveVKP/tTImzCl6r4DmfxIJmM2OPx7TDKZ/g9sfeIscuzbK7+59AgZ0Hx+k9qIENCpB5NXdUAHeAjeNRCiuuH4qJUmEZ/5n4+o4OaQoU+UCVrECix+f8Vspwfc4fOTpu7qaXizK47cHMcBGEm/nvObQnyPnUTb75AwBlkMWRyhEZBr12q1w+0ygbLhd6MA2ppx7Y6IEHOXOA9bHk6WgASlx2P8XYj105mMJx0aRZzVKWtw/edtu7yOIAlX6KvLKrrje9xXW2dTEavLwAzt49Q2fg0OKGghR9aQnYMMz26fEwWfY6dR+0BgeVaD5YDVFCGcWoyLDuidRkk+XwTsM8b0JpQXz3RdkKfZATURorKCiw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-29-21Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698571637.5909402,
                        "modified": 1698571637.5909402,
                        "name": "/dev/shm/incoming/2023_10_29_092656_00_00_V_023.txt",
                        "sha256": "ede45de6b06668f2fe63ceabfdd5182af4a82315150b3bcc77ef96c41f561f7b",
                        "size": 2032
                    },
                    {
                        "created": 1698571638.47094,
                        "modified": 1698571638.47094,
                        "name": "/dev/shm/incoming/2023_10_29_092656_00_00_V_023.kml",
                        "sha256": "68757038a1d996d20040e3d120e759581367a9f054af33967c6dfd8c1c53de0a",
                        "size": 977
                    },
                    {
                        "created": 1698571638.3709402,
                        "modified": 1698571638.3709402,
                        "name": "/dev/shm/incoming/2023_10_29_092656_00_00_V_023.jpg",
                        "sha256": "5424f0503889ab63a7a023fbf2934be839017d75d9ef09ad0c15a1231847738a",
                        "size": 907216
                    },
                    {
                        "created": 1698571634.1409402,
                        "modified": 1698571634.1409402,
                        "name": "/dev/shm/incoming/2023_10_29_092646_00_00_V_022.txt",
                        "sha256": "00251333690e32d98927779d1b2c49d9fcd0d1c1ca3c4914cb51f524a74399d0",
                        "size": 2032
                    },
                    {
                        "created": 1698571626.3409402,
                        "modified": 1698571626.3409402,
                        "name": "/dev/shm/incoming/2023_10_29_092646_00_00_V_022.kml",
                        "sha256": "e9997c1c9fbd4b88da6c8949f826f6f28f3286de6cab2fee3b9624681f5a144c",
                        "size": 978
                    },
                    {
                        "created": 1698571635.47094,
                        "modified": 1698571635.47094,
                        "name": "/dev/shm/incoming/2023_10_29_092646_00_00_V_022.jpg",
                        "sha256": "1fcef9377b5b16c4a4d9ca130e98e9c99393800232ff5e72466dc2877554a076",
                        "size": 996193
                    },
                    {
                        "created": 1698571623.1609402,
                        "modified": 1698571623.1609402,
                        "name": "/dev/shm/incoming/2023_10_29_092643_00_00_V_021.txt",
                        "sha256": "e4ba45b99f8e47739fa4acf5f200b7ee3ce7afac52ba3fff1c7f705b1c6ea4f4",
                        "size": 2030
                    },
                    {
                        "created": 1698571622.5609403,
                        "modified": 1698571622.5609403,
                        "name": "/dev/shm/incoming/2023_10_29_092643_00_00_V_021.kml",
                        "sha256": "341fdf584e23d781e163a28b0f77dff63435e31f2690b89f6bb5a1eaeda6bde7",
                        "size": 980
                    },
                    {
                        "created": 1698571634.3609402,
                        "modified": 1698571634.3609402,
                        "name": "/dev/shm/incoming/2023_10_29_092643_00_00_V_021.jpg",
                        "sha256": "1ab6f089e406af664ab539e41ab0b39ad0de59bf536648ddac1bb3a4a807ab69",
                        "size": 900364
                    },
                    {
                        "created": 1698571614.23094,
                        "modified": 1698571614.23094,
                        "name": "/dev/shm/incoming/2023_10_29_092633_00_00_V_020.txt",
                        "sha256": "8d4e620b1ba933657ffbfe5f8e266de8d8735e9d211c42128a87fcb2e17d262e",
                        "size": 2034
                    },
                    {
                        "created": 1698571620.3809402,
                        "modified": 1698571620.3809402,
                        "name": "/dev/shm/incoming/2023_10_29_092633_00_00_V_020.kml",
                        "sha256": "39279cd7425343f106bf59a6ed2664c80c4e3c112c4839fa96f02f7ce160fefe",
                        "size": 976
                    },
                    {
                        "created": 1698571611.6309402,
                        "modified": 1698571611.6309402,
                        "name": "/dev/shm/incoming/2023_10_29_092633_00_00_V_020.jpg",
                        "sha256": "15cbb5d2f0874d52208c49e4781e76cc03b049d027b4bd78b57e3683b39e980b",
                        "size": 646865
                    },
                    {
                        "created": 1698571609.7609403,
                        "modified": 1698571609.7609403,
                        "name": "/dev/shm/incoming/2023_10_29_092625_00_00_V_019.txt",
                        "sha256": "85289a39706c30529fc9be6f8d9585fc46d045cb2a8c3d36e7245ba687916dd4",
                        "size": 2039
                    },
                    {
                        "created": 1698571608.8209403,
                        "modified": 1698571608.8209403,
                        "name": "/dev/shm/incoming/2023_10_29_092625_00_00_V_019.kml",
                        "sha256": "0d5c8c5672e1571abd94491ccbf048f8e0889a34271a29acc772e40503324546",
                        "size": 979
                    },
                    {
                        "created": 1698571600.1309402,
                        "modified": 1698571600.1309402,
                        "name": "/dev/shm/incoming/2023_10_29_092625_00_00_V_019.jpg",
                        "sha256": "d7883add5d5ccbac9f946da72e0129975b485a0c1c9d0acbb32ffc3831f7c70e",
                        "size": 871631
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "99c1ea9f3facd3e167f5cedf7ce02e65f1bc76056e16b71c1bf66fd97462e7d8",
                "size": 4311181
            },
            "errors": "",
            "filename": "2023-10-29T09-29-21Z",
            "size": 4311181,
            "verified_at": 1698580297
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698571956,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "A+FiTIvwX1E4uo2OqZDRV9CLDnGid75ypql+h9D6tt8oTf97b8LQxdrVYW3PBUv5dY+fbrX5kITevwo6l1DLIrv5luOCq0HxnCDLKiQGkQHrAVzBdy0rg2A2FJIu0l8lNGgxmGOoOsrp6WFMBjnJzmgQaLr/cMNeAJVaCZDFQBiA844kkX7yYYd8eXuGLQqAh/M46+6MJXgi1KpBZyrSXc6kOdFw1t8nHaQAI4bdHV5WXJLjm59nOStHGuhchCspe0KdT4oWhhWu20JyXwGGuG/e9a/BnkuG/7+lwcqdBNlJ30Cy8VriqnxRQ8wv6pYt2cLLWtHgltcPWCPHtUSeypHPRihMoYUD4uXFQOu+CXSy8zIku9CqR9IwiIo/n+oZB9ANyNWU/vo/6zLwX5V4dG5SSvoYzESTm3FufusmYy2DR22hpJ01qsPKw1NBziPaSF2BkaRY086ulWtgF+GqEigntRICpVoe0/J7/t4W0HlvmeslKmZKtjo7WtNJeR26Wsk/ZcRrvWwTMHsEemwvrnybTuMhacspQmCespsEW98qiguUUbtPXa18P7xqzqQCS7EpYprzCE8iHqJclNRh9gJVdOorsiHIiW+jUTnT6E8zyatX7ASdMLp8qZubMRUizodWASAjOAYTnz/4CvgTsYBrXYiJvKNN6DZDeIfm+QM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-31-47Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698571780.573234,
                        "modified": 1698571780.573234,
                        "name": "/dev/shm/incoming/2023-10-29T09:19:38.ais",
                        "sha256": "987c38482a09b2e06ae1a79bb15fe7b0efc0a3f7b90cc511cfc8e220c98397d1",
                        "size": 187976
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "8bef544dc0d87adc724b8abff713221905a5479de4b5e7593fbd4a86e32f6cd7",
                "size": 55953
            },
            "errors": "",
            "filename": "2023-10-29T09-31-47Z",
            "size": 55953,
            "verified_at": 1698595674
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698571538,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "J4NzSU7thO8P45YqC/wFGz32JhslSUgeEW9UZ/sIcLOWW/NlLf8Y5D00a7zY2KFlgz6MMbgVCgUIsWny3yHASGD15YKykPLe66Ig4PThddVuIYe1/euKhtFgSgQXGr32TNG9gkJJ+nselPVkE0L/I5+kOfw5oIdrcx1SZmq4hCuFwca5koDhYBx3cFS8uHuVylEIyQiHbAuD4bXhD6NoAOubg6ZjmmFQH45Kno6TOc4sHJXBFM6MBnehOkpA1EOvHVY2iLK8aET8CzpnOsXTWDn7ymCbBde2MVsLB7gdcTRSKG6vJfS0FCskX6x0voa0l+4vXk7p/pxXSlud1ct3ypRgYFMxA95i0vpG3N6pPvcCowts6iKREvz7GytY+Bx+P+jxKW78rxoORx/px2rdv3tDaOODNizOQ3sEbc0EnCnNLOFwrNEd5Mar1ZN5y7fjc2+9kfqPDvz6Tty+Le0NaNnxnd0AmhZXkg8UtcRKSHIKaUTYnVCUXSW6+Wbb7ApzHivnsYHoYztjSd3MUejg+FSo9GqEMPlqjGQj2ng6/gFPvLSl9h7pge7bG0zH13QPuQfbXFOgcMI6Gkt608X+T+tbiGKKmbaJL8+OOItRCv/inbrsPJ9TGpbCU4juKLFCJj76/AB2qVGcngNl5E7ghJj6Qpq76Z4Ru/dMMHYYbVU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-25-17Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698571389.093234,
                        "modified": 1698571389.093234,
                        "name": "/dev/shm/incoming/2023_10_29_092246_00_00_K_067.txt",
                        "sha256": "b1fd2ab93bf0e40e61445a34608fdd80611190ad82a9ffbfeadab0e72e529445",
                        "size": 2050
                    },
                    {
                        "created": 1698571388.7032342,
                        "modified": 1698571388.7032342,
                        "name": "/dev/shm/incoming/2023_10_29_092246_00_00_K_067.kml",
                        "sha256": "61d97fae4594e4f4e61b4962527eb2cc5229b44fe613ff2ecaf81708c440fa7b",
                        "size": 976
                    },
                    {
                        "created": 1698571388.2732341,
                        "modified": 1698571388.2732341,
                        "name": "/dev/shm/incoming/2023_10_29_092246_00_00_K_067.jpg",
                        "sha256": "601732ad4fc340ba23f3f358af718565edc28018f0694f30a39ffca00845f8a8",
                        "size": 1196598
                    },
                    {
                        "created": 1698571366.2232342,
                        "modified": 1698571366.2232342,
                        "name": "/dev/shm/incoming/2023_10_29_092232_00_00_K_066.txt",
                        "sha256": "3edcaab37149db53fff2a4f7fb44a12b4de41b8364244070d4bd0370675a3d83",
                        "size": 2050
                    },
                    {
                        "created": 1698571365.7632341,
                        "modified": 1698571365.7632341,
                        "name": "/dev/shm/incoming/2023_10_29_092232_00_00_K_066.kml",
                        "sha256": "06b537b22e70fe4d423e2c0445307a98c6db974bcc26c1281ec06916bedb369f",
                        "size": 978
                    },
                    {
                        "created": 1698571365.633234,
                        "modified": 1698571365.633234,
                        "name": "/dev/shm/incoming/2023_10_29_092232_00_00_K_066.jpg",
                        "sha256": "2b7d172c283759ab95750871414e5d9672d19ff9b853fb0115e6a9053b4bde16",
                        "size": 1034686
                    },
                    {
                        "created": 1698571321.7432342,
                        "modified": 1698571321.7432342,
                        "name": "/dev/shm/incoming/2023_10_29_092141_00_00_K_065.txt",
                        "sha256": "aa55e5fc31d94b2df802cf2ac65374dc973f314691b772203db4f2c54bd3e8ff",
                        "size": 2050
                    },
                    {
                        "created": 1698571321.103234,
                        "modified": 1698571321.103234,
                        "name": "/dev/shm/incoming/2023_10_29_092141_00_00_K_065.kml",
                        "sha256": "c33a04529794cdce1cb7231af8171528638f9a6f3626fdfa4151f3e325ee74c4",
                        "size": 976
                    },
                    {
                        "created": 1698571320.9932342,
                        "modified": 1698571320.9932342,
                        "name": "/dev/shm/incoming/2023_10_29_092141_00_00_K_065.jpg",
                        "sha256": "db0c68ea39367fe4236b2cb50baa1142a3993fae8799397770192374fcc8db15",
                        "size": 743628
                    },
                    {
                        "created": 1698571318.9332342,
                        "modified": 1698571318.9332342,
                        "name": "/dev/shm/incoming/2023_10_29_092138_00_00_K_064.txt",
                        "sha256": "3a8a9238a15b81985328718ffed44d5179c38061bf6056867c7f1122d3dababa",
                        "size": 2050
                    },
                    {
                        "created": 1698571318.803234,
                        "modified": 1698571318.803234,
                        "name": "/dev/shm/incoming/2023_10_29_092138_00_00_K_064.kml",
                        "sha256": "40ed89b73296cf304181fbb09ddaeae1a6b40f69db6350b2686293f460e7ebfa",
                        "size": 975
                    },
                    {
                        "created": 1698571317.913234,
                        "modified": 1698571317.913234,
                        "name": "/dev/shm/incoming/2023_10_29_092138_00_00_K_064.jpg",
                        "sha256": "0390b6580d9a873b2fe4bd4752f022ed1420b09a9d9405cf89699a903f370347",
                        "size": 715661
                    },
                    {
                        "created": 1698571306.333234,
                        "modified": 1698571306.333234,
                        "name": "/dev/shm/incoming/2023_10_29_092126_00_00_K_063.txt",
                        "sha256": "e9bec647a815c3035a93db476937289dcc0d50f1039fef5538281f80e65064f0",
                        "size": 2050
                    },
                    {
                        "created": 1698571305.7732341,
                        "modified": 1698571305.7732341,
                        "name": "/dev/shm/incoming/2023_10_29_092126_00_00_K_063.kml",
                        "sha256": "ac316250b2f50fbf93fa5f6e2a08222bdd3fadef14745f948aa3d18a0825f38d",
                        "size": 975
                    },
                    {
                        "created": 1698571305.4832342,
                        "modified": 1698571305.4832342,
                        "name": "/dev/shm/incoming/2023_10_29_092126_00_00_K_063.jpg",
                        "sha256": "0d8aa080b819f12762735dd0644e3781b48d22e5d462986213174ce45fb4cd7e",
                        "size": 808651
                    },
                    {
                        "created": 1698571293.923234,
                        "modified": 1698571293.923234,
                        "name": "/dev/shm/incoming/2023_10_29_092112_00_00_K_062.txt",
                        "sha256": "595afc2cf15c526178f6106233da86a04af5cb9b48acee854becd9d83a3198ce",
                        "size": 2050
                    },
                    {
                        "created": 1698571293.653234,
                        "modified": 1698571293.653234,
                        "name": "/dev/shm/incoming/2023_10_29_092112_00_00_K_062.kml",
                        "sha256": "1af96d8b18b5979d469a256b0eee2d482a87303ce7cc67fd2dd7b0b218bffd57",
                        "size": 971
                    },
                    {
                        "created": 1698571293.373234,
                        "modified": 1698571293.373234,
                        "name": "/dev/shm/incoming/2023_10_29_092112_00_00_K_062.jpg",
                        "sha256": "e2409dec747832d2b84ff217d05e1cb07e9a1369a8b823763e76bf845da3f1f1",
                        "size": 1033133
                    },
                    {
                        "created": 1698571281.543234,
                        "modified": 1698571281.543234,
                        "name": "/dev/shm/incoming/2023_10_29_092059_00_00_K_061.txt",
                        "sha256": "51cbd4b088d8e4776047418f43f44de6d8ee288a85425ecc35bb168da0d4641f",
                        "size": 2050
                    },
                    {
                        "created": 1698571281.413234,
                        "modified": 1698571281.413234,
                        "name": "/dev/shm/incoming/2023_10_29_092059_00_00_K_061.kml",
                        "sha256": "b81a5929589a880b8d28d92ee2f70ce22fda41b24aeca49bdafdf8ea3cc4a7e6",
                        "size": 977
                    },
                    {
                        "created": 1698571281.2132342,
                        "modified": 1698571281.2132342,
                        "name": "/dev/shm/incoming/2023_10_29_092059_00_00_K_061.jpg",
                        "sha256": "2ab16dd463379f3db8fd67eee95333d50d365d12dcd4854422812685b8e699b4",
                        "size": 1075594
                    },
                    {
                        "created": 1698571280.133234,
                        "modified": 1698571280.133234,
                        "name": "/dev/shm/incoming/2023_10_29_092056_00_00_K_060.txt",
                        "sha256": "ccafd4594581428352915b35b2c31ea3b7c34f8cfacced1ac526eb9279fda0be",
                        "size": 2050
                    },
                    {
                        "created": 1698571280.063234,
                        "modified": 1698571280.063234,
                        "name": "/dev/shm/incoming/2023_10_29_092056_00_00_K_060.kml",
                        "sha256": "2b4e9e2b5a2195ca3dbf7cd69f17ca35912ffa25a98371376c1c56026249b23a",
                        "size": 977
                    },
                    {
                        "created": 1698571279.363234,
                        "modified": 1698571279.363234,
                        "name": "/dev/shm/incoming/2023_10_29_092056_00_00_K_060.jpg",
                        "sha256": "0e9f9765af92769262d58063a2e53593d3afbb250392a13f9751536c172f2835",
                        "size": 1128160
                    },
                    {
                        "created": 1698571278.303234,
                        "modified": 1698571278.303234,
                        "name": "/dev/shm/incoming/2023_10_29_092053_00_00_K_059.txt",
                        "sha256": "69a58fcd8da14642420d3bc178f049a209031b4cd327b9b2dc80d816ace0b1f7",
                        "size": 2050
                    },
                    {
                        "created": 1698571278.173234,
                        "modified": 1698571278.173234,
                        "name": "/dev/shm/incoming/2023_10_29_092053_00_00_K_059.kml",
                        "sha256": "2e8647fecd83b566082d0c04892537b04da915c543317afe3c3a215c8de87e16",
                        "size": 975
                    },
                    {
                        "created": 1698571278.0132341,
                        "modified": 1698571278.0132341,
                        "name": "/dev/shm/incoming/2023_10_29_092053_00_00_K_059.jpg",
                        "sha256": "52085ea588ee013e77d35a0ee84a19c357896292e0ca3413f4ab286e91ddc8f8",
                        "size": 1334737
                    },
                    {
                        "created": 1698571264.833234,
                        "modified": 1698571264.833234,
                        "name": "/dev/shm/incoming/2023_10_29_092049_00_00_K_058.txt",
                        "sha256": "d447fecf10991fceaec703cbdeef1a97d8ffb2914ea415182f4c2442a68c8ed3",
                        "size": 2049
                    },
                    {
                        "created": 1698571264.7132342,
                        "modified": 1698571264.7132342,
                        "name": "/dev/shm/incoming/2023_10_29_092049_00_00_K_058.kml",
                        "sha256": "4148fb6a47ab0175f7007cbee6153a44bc6c2a3b51e4d82d56ea649939159f71",
                        "size": 977
                    },
                    {
                        "created": 1698571263.823234,
                        "modified": 1698571263.823234,
                        "name": "/dev/shm/incoming/2023_10_29_092049_00_00_K_058.jpg",
                        "sha256": "1fcc209202862aee11ac2b73fb8aa53d8455d0ef0ebd86e30536df3fc79ca64a",
                        "size": 1322119
                    },
                    {
                        "created": 1698571262.843234,
                        "modified": 1698571262.843234,
                        "name": "/dev/shm/incoming/2023_10_29_092045_00_00_K_057.txt",
                        "sha256": "df083fcb94042f271185ee107cf8bdfe57107282a91e9ca430b44246a4b62151",
                        "size": 2049
                    },
                    {
                        "created": 1698571262.7632341,
                        "modified": 1698571262.7632341,
                        "name": "/dev/shm/incoming/2023_10_29_092045_00_00_K_057.kml",
                        "sha256": "3d05dd10555ca53de4916d29741e15cea7d0c6f91d9877dc2043cc07e9504343",
                        "size": 977
                    },
                    {
                        "created": 1698571262.2832341,
                        "modified": 1698571262.2832341,
                        "name": "/dev/shm/incoming/2023_10_29_092045_00_00_K_057.jpg",
                        "sha256": "a3d0d4abff33cf8a9699c0433f13b8b3911690285277ee9579946b6bc370dab7",
                        "size": 1199761
                    },
                    {
                        "created": 1698571197.7632341,
                        "modified": 1698571197.7632341,
                        "name": "/dev/shm/incoming/2023_10_29_091932_00_00_K_056.txt",
                        "sha256": "ad1e4cedfdc0b29a249bf94ad5e41887c99ae2b7a110d6c22582a1766441edaa",
                        "size": 2050
                    },
                    {
                        "created": 1698571196.9732342,
                        "modified": 1698571196.9732342,
                        "name": "/dev/shm/incoming/2023_10_29_091932_00_00_K_056.kml",
                        "sha256": "7fd3f91f02c32104c563efb1b57f706b227f1d34b7dc8f1dadf22b1d6f022727",
                        "size": 977
                    },
                    {
                        "created": 1698571196.7232342,
                        "modified": 1698571196.7232342,
                        "name": "/dev/shm/incoming/2023_10_29_091932_00_00_K_056.jpg",
                        "sha256": "ef66912248a0897d061c339207518bcce019192b4c6bc93dc536ec62adffb852",
                        "size": 771420
                    },
                    {
                        "created": 1698571184.813234,
                        "modified": 1698571184.813234,
                        "name": "/dev/shm/incoming/2023_10_29_091921_00_00_K_055.txt",
                        "sha256": "0eaa5376021de19eba82c25a2d1474c2cc774114e038727a2119387660d012d6",
                        "size": 2050
                    },
                    {
                        "created": 1698571184.613234,
                        "modified": 1698571184.613234,
                        "name": "/dev/shm/incoming/2023_10_29_091921_00_00_K_055.kml",
                        "sha256": "31c3665c647d7b9778fb4a20eb7fe1433a503100fc2129f2e02ba7505ab15709",
                        "size": 978
                    },
                    {
                        "created": 1698571184.2832341,
                        "modified": 1698571184.2832341,
                        "name": "/dev/shm/incoming/2023_10_29_091921_00_00_K_055.jpg",
                        "sha256": "1d2b6453b6983f140734f60d417d88d26af07288c4132f7882b91fef9e486280",
                        "size": 702167
                    },
                    {
                        "created": 1698571178.373234,
                        "modified": 1698571178.373234,
                        "name": "/dev/shm/incoming/2023-10-29T09:09:35.ais",
                        "sha256": "0f3e2c767710ac61e70dd93576087c75bd11a19f11c40287b76ffd6cb909b18e",
                        "size": 218711
                    },
                    {
                        "created": 1698571172.623234,
                        "modified": 1698571172.623234,
                        "name": "/dev/shm/incoming/2023_10_29_091902_00_00_K_054.txt",
                        "sha256": "b403b3c3aeadb57b8b7aa1d92aa2e6c0b1419f234b5ddfe26645dcfb73ede96e",
                        "size": 2048
                    },
                    {
                        "created": 1698571172.4732342,
                        "modified": 1698571172.4732342,
                        "name": "/dev/shm/incoming/2023_10_29_091902_00_00_K_054.kml",
                        "sha256": "33dc335b407ef8928900d73c5b0cfa577b4338c55b0d695372356a2623a9a152",
                        "size": 979
                    },
                    {
                        "created": 1698571171.883234,
                        "modified": 1698571171.883234,
                        "name": "/dev/shm/incoming/2023_10_29_091902_00_00_K_054.jpg",
                        "sha256": "f421783b198ccfe8f6d163ce30e752d30db155e975b9242e9dd15a9efc756051",
                        "size": 993424
                    },
                    {
                        "created": 1698571160.353234,
                        "modified": 1698571160.353234,
                        "name": "/dev/shm/incoming/2023_10_29_091859_00_00_K_053.txt",
                        "sha256": "a6ca8ef6e4309c6b0503dbf13c643f2384923b6374ab56d8b8463cd0f5a3d99c",
                        "size": 2050
                    },
                    {
                        "created": 1698571159.333234,
                        "modified": 1698571159.333234,
                        "name": "/dev/shm/incoming/2023_10_29_091859_00_00_K_053.kml",
                        "sha256": "6069c11c3f331ec67d297996b315fc712bea16e39eb4829cf8b4e83db3abf204",
                        "size": 977
                    },
                    {
                        "created": 1698571158.313234,
                        "modified": 1698571158.313234,
                        "name": "/dev/shm/incoming/2023_10_29_091859_00_00_K_053.jpg",
                        "sha256": "67c5925969665ff175550909fbd711ec2ffde9598618fb9065fa41ea04f2e9f6",
                        "size": 1064074
                    },
                    {
                        "created": 1698571156.293234,
                        "modified": 1698571156.293234,
                        "name": "/dev/shm/incoming/2023_10_29_091856_00_00_K_052.txt",
                        "sha256": "b0f52127bf95b776b22eb102c1adca7cf314f8101165ff2b5d1211cbd94ac590",
                        "size": 2050
                    },
                    {
                        "created": 1698571156.103234,
                        "modified": 1698571156.103234,
                        "name": "/dev/shm/incoming/2023_10_29_091856_00_00_K_052.kml",
                        "sha256": "51f3bbc0928538ef0f079244695a0e3c5f251d841712ad4eac67c50f5edb9ac9",
                        "size": 978
                    },
                    {
                        "created": 1698571155.2732341,
                        "modified": 1698571155.2732341,
                        "name": "/dev/shm/incoming/2023_10_29_091856_00_00_K_052.jpg",
                        "sha256": "386a0deb92e8811ebc95bb239fafa3553a6ec5c1e4b2f9640124d56e90f8fa4b",
                        "size": 1019394
                    },
                    {
                        "created": 1698571154.2232342,
                        "modified": 1698571154.2232342,
                        "name": "/dev/shm/incoming/2023_10_29_091853_00_00_K_051.txt",
                        "sha256": "def8169252526513fe98ed17b9db15febb7b51e61bb032ab4f58a9b06f837a2a",
                        "size": 2050
                    },
                    {
                        "created": 1698571153.813234,
                        "modified": 1698571153.813234,
                        "name": "/dev/shm/incoming/2023_10_29_091853_00_00_K_051.kml",
                        "sha256": "fbfd7e190ab0416014e9430a9fff1e4f8c0be34c5514fc23163f4cda0ba11955",
                        "size": 978
                    },
                    {
                        "created": 1698571153.2332342,
                        "modified": 1698571153.2332342,
                        "name": "/dev/shm/incoming/2023_10_29_091853_00_00_K_051.jpg",
                        "sha256": "efdeb470dc6c97a4a29dee17b081d456185cbac746fcf39d8d00cf830ff91747",
                        "size": 986552
                    },
                    {
                        "created": 1698571131.043234,
                        "modified": 1698571131.043234,
                        "name": "/dev/shm/incoming/2023_10_29_091829_00_00_K_050.txt",
                        "sha256": "708047ae471ea57c1be24f49ba9ce2a5f778c467163205d80e901751aa25941e",
                        "size": 2050
                    },
                    {
                        "created": 1698571130.863234,
                        "modified": 1698571130.863234,
                        "name": "/dev/shm/incoming/2023_10_29_091829_00_00_K_050.kml",
                        "sha256": "bcfaaadd46fa110628d491891c5d3f3b15e317ab2a6a94799a27fc9936f03028",
                        "size": 979
                    },
                    {
                        "created": 1698571130.1932342,
                        "modified": 1698571130.1932342,
                        "name": "/dev/shm/incoming/2023_10_29_091829_00_00_K_050.jpg",
                        "sha256": "33063a721e414969c0dd70690adaade1afddbffb10fc2d3f02a97c627b58537c",
                        "size": 740339
                    },
                    {
                        "created": 1698571041.653234,
                        "modified": 1698571041.653234,
                        "name": "/dev/shm/incoming/2023_10_29_091657_00_00_K_049.txt",
                        "sha256": "16f66c42fa7db662d9b17a6e7908bde1d6ddb58d2b8a8513e5c1638e132106c3",
                        "size": 2047
                    },
                    {
                        "created": 1698571040.9732342,
                        "modified": 1698571040.9732342,
                        "name": "/dev/shm/incoming/2023_10_29_091657_00_00_K_049.kml",
                        "sha256": "88071ba5de7a5ed59dac1c33b605062f680f61e0ccea92fc6f17d26e0370e295",
                        "size": 978
                    },
                    {
                        "created": 1698571040.843234,
                        "modified": 1698571040.843234,
                        "name": "/dev/shm/incoming/2023_10_29_091657_00_00_K_049.jpg",
                        "sha256": "3353719bb4fe20898c945bb7da90299d4605106857137755392699ebdaf4cfe7",
                        "size": 756661
                    },
                    {
                        "created": 1698571039.9332342,
                        "modified": 1698571039.9332342,
                        "name": "/dev/shm/incoming/2023_10_29_091654_00_00_K_048.txt",
                        "sha256": "93fe449dc9d3dbed751cdf50058ec1e052caead677b57763571e52630e975ece",
                        "size": 2046
                    },
                    {
                        "created": 1698571039.7832341,
                        "modified": 1698571039.7832341,
                        "name": "/dev/shm/incoming/2023_10_29_091654_00_00_K_048.kml",
                        "sha256": "f4a54fec1de616a1d722d651ff9e45916b319c4559e3549c3c149217b95c30fb",
                        "size": 975
                    },
                    {
                        "created": 1698571039.573234,
                        "modified": 1698571039.573234,
                        "name": "/dev/shm/incoming/2023_10_29_091654_00_00_K_048.jpg",
                        "sha256": "30e1d15d0aa346eb4073ab364e67fcdca20ce83bbe39015f49be7facbca984a7",
                        "size": 800793
                    },
                    {
                        "created": 1698571016.573234,
                        "modified": 1698571016.573234,
                        "name": "/dev/shm/incoming/2023_10_29_091634_00_00_K_047.txt",
                        "sha256": "9d88c683b4d14b5e01ff0bf468b0cbc1515c9f02d384af8955bd338edfc4b7c9",
                        "size": 2049
                    },
                    {
                        "created": 1698571016.2132342,
                        "modified": 1698571016.2132342,
                        "name": "/dev/shm/incoming/2023_10_29_091634_00_00_K_047.kml",
                        "sha256": "3e461198ae63444b3f7d0d01d5bc521bbc6d730d8e4abb91bd61167fb76f0701",
                        "size": 979
                    },
                    {
                        "created": 1698571015.5232341,
                        "modified": 1698571015.5232341,
                        "name": "/dev/shm/incoming/2023_10_29_091634_00_00_K_047.jpg",
                        "sha256": "b31c8cffa9d043d39851973aeb8533790f306c2d0b7fddb233f6b2dc9148c7b9",
                        "size": 765459
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "85f82f1c3edd8ee4ee0a1542ce7f5224fa1136f89dc1333438886413f886a809",
                "size": 20232196
            },
            "errors": "",
            "filename": "2023-10-29T09-25-17Z",
            "size": 20232196,
            "verified_at": 1698595712
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698648993,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "uJcZPMHCHSBty7ekXf9ehFBo2reBSRgLFDXWmYBWx8xe+J2p4pfru+yo16dhj9d7WPN9NEO0p4lphQDZur3e9ZHXOghLy76FvMPhSxJf7m3+AWsNTTHlOAnPlsVtXPXQpdjgW0NZcoeGFZtoR0m9kuougt63sW7/7BsHpgQZzG7U2mN/ZgEnNqLns9Om0Ip1w36l4qiP3X1czOD6HBG3B4eXkwv5k0wIETA8aYqwvfw2F74WcWB8Oj+AVH8UR0bN3QGXtRMFwNkmeyblTnkrmB+6pT5fiGBSM9EMttlQbz72zqBIOCg9P7jDFk1SUvGLE/cs3iXcsfll03RJ7vvtiPALY8jBGLt1CHd9jWERe+hkSLM8Ijz/TFg8oCzUiRWkJxVnLUik+iCrmFLGodnbWs3om4IHsxY2vLQzHbD3R4/wU5f1tjuvtRryL9is5R5zV5YqNhF+cYSO6rMYSutCrj9rAMQ9tW4e2ZX8mL+M9GZUyLrCW8Y4m0VFl/6rx+XSkm0+nCiIexpLaxYBGynsn66gFj2Y8Q3Ye/GaSSnVrtBSN4oOZtM5vPkSPraya+e9zyMO4xZuz55CJUgr6TplUqtvu7nz61k2Gndx+WyJxtxRsKliRq49IiVegW3rHVHBaCMdXkNuXKR6GfhubdObbpEZx5eqztWdW6YKxWurUGI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-56-09Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698648845.116036,
                        "modified": 1698648845.116036,
                        "name": "/dev/shm/incoming/2023_10_30_065345_00_00_V_023.txt",
                        "sha256": "ce2d77d6b93a1bd267b21a3c56382da69dbdc11fc7303a15d776c5535651bd74",
                        "size": 2032
                    },
                    {
                        "created": 1698648860.616036,
                        "modified": 1698648860.616036,
                        "name": "/dev/shm/incoming/2023_10_30_065345_00_00_V_023.kml",
                        "sha256": "fc8a824db583920ca13749608ade3bc46d1dfd40ae6961cf0402a6ccb51129c8",
                        "size": 975
                    },
                    {
                        "created": 1698648844.636036,
                        "modified": 1698648844.636036,
                        "name": "/dev/shm/incoming/2023_10_30_065345_00_00_V_023.jpg",
                        "sha256": "a7bbc2cf92e087b17dcbf9ca594946a08bc0ec7766d36a065388b58ef296907e",
                        "size": 1180158
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "d65cb53f5e446c45e62314fd2ab73468f3793e582bb40f74c47bd61e16419177",
                "size": 1181937
            },
            "errors": "",
            "filename": "2023-10-30T06-56-09Z",
            "size": 1181937,
            "verified_at": 1698665929
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698756067,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "l0vJ9Q5FT53QCyQe3Hx9p3oGT4YRRn7wWuYuVYNn77VMNYStlJHDChMQlS/HFOw2IL47DSA1IYKOP6F+jW2QvO5P7iQrpWFyeu1x3TxGM3X/rimhqJ7VvjR77s3QPPkauzfD+3DlUQWJ3qTRmbjHeUUFi7OWYtp0SaCl6NCmzQLpvipt5WOidrrBtLE3tQ27QeD/dw/yeNGkSVs0hAICJL7ri9K/4VyiQaGVCcUhtMx5HrINEGeO9X54ffxzK9DGsuCwtgDxKkj/+YxZeL4JUzAklC1LwLqnX5tXdP71EySF/XCHQ8+DIymfK8kUA6VPawqKy1XmRRxBJ0lB7FAHEDAEcJASw5U/3gau8FIqSDmjGKG36cEj9fstqof11TYsIMVak9wMzCBjWbEISqVzXJmYXusd/WjeO0+seVKkOmFm3HMiNndMCuF3O+CCjV5xKQ2pS6Z1kyGpe2mVZtntnAk/5QZkZ3nRpkHndkQ/+CuBb2RAMgB6MZvnIQQP70sBTCY9ZcomOG11z6wjl1iwTt2Z/qBMFCvs7/RtM8MoZZxxHVrCNtT0RsWF1TPrDtMUAdcUJTKNSMGWxDstKKfrgp7pETcfOm5wZsmrUuQSsB9+ZCqFeWAHQIgkh7FrHmPqNfjrrg7syL/zt3AhrWDIt0Uv4+Ulqdje+ThJAO3S1ys=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-40-01Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698755965.0114186,
                        "modified": 1698755965.0114186,
                        "name": "/dev/shm/incoming/2023-10-31T12:29:24.ais",
                        "sha256": "5ef2a11e87849426f3e055a823955a603ca191b0a5254337f0322f01577bf985",
                        "size": 271481
                    },
                    {
                        "created": 1698755989.7814186,
                        "modified": 1698755989.7814186,
                        "name": "/dev/shm/incoming/2023-10-31T12:09:49.ts",
                        "sha256": "ff26b56ad6f8f3236b4ae5eed4a63f347577e73099ee83e24b9ca1dcec9ff26a",
                        "size": 410524884
                    }
                ],
                "mission": "OSP1",
                "sha256": "922ec062808087c2b7d54630de5695d32d7917a80d53732f136b194730210344",
                "size": 396008026
            },
            "errors": "",
            "filename": "2023-10-31T12-40-01Z",
            "size": 396008026,
            "verified_at": 1698766000
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698573926,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "bLR79kuTpNxOnddyEcbvg3nfjk57uBPCX2R3DsBwmSsNBPXQKj3dDd7YrCpBp4q4upKKA6RngftOHgmM/aSoOrUgmBZLv0WoSLzFQYri+AQsSHlNsMYz9bJoQCNpERKpO88bzFkkU14zznl77GDpFbDLw1q1/7b4OmPZxo9PhnkZA3bQnAx7qSEqjhY+cE/ztrpw8Bv2AwYz6Z3WPOswepN0hj6uh20E1zD4LJULbpvI6xe2xbZjhiQAT1n6RQiSSj3O41lYxkhdIu1UyyAAjfZljaI8twutV1aWe7nfDr8msyGicKdsAmnow2g9DDmAHuAj7OmnquRtTsIZgnoxylz79vWn6gXBt0/FTgKJFLQY9b8fti8hndWc/vNaQgxHy8wnKtIkO3TFnI2IWshJy9J+bHVVopr3zm2uzZH7QUO3QrEoEIycEZYi5xXNMXPat4rAmCa4znUiCaTFq89+h8BSf2IOPVRsWLV6MF/JQ9fPd4BUesUv/amVcRFDTAU5JqNtp38QYhLwjnWg/kYOijLiSukLasnboAu35pFdJBia3bIPzxZVay9FaAC5rcZJa3XW8T86PTvAYMri2qtX10sQH//t/ZdA7ivLAs4IOnycN+z4rfHOPNYQANSKppCC0xdYO+OjxgK/d7eg7OGRFsTC/BXkmLhTEUm5BDgJJDk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T10-05-00Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698573777.49094,
                        "modified": 1698573777.49094,
                        "name": "/dev/shm/incoming/2023-10-29T09:52:54.ais",
                        "sha256": "409696b346be14aa7678ca8481d526c9bb8640216d48dba523b1449e6f49e62a",
                        "size": 270071
                    },
                    {
                        "created": 1698573664.0509403,
                        "modified": 1698573664.0509403,
                        "name": "/dev/shm/incoming/2023_10_29_100039_00_00_V_042.txt",
                        "sha256": "86c8d03afe0f6ec513815349d63fda27df2ff47168994acc1fda2927f29d813a",
                        "size": 2036
                    },
                    {
                        "created": 1698573671.2009401,
                        "modified": 1698573671.2009401,
                        "name": "/dev/shm/incoming/2023_10_29_100039_00_00_V_042.kml",
                        "sha256": "38aa556567a10ba5bbf01dc8e703827f5d650c31a57ea828424416c01b3966ca",
                        "size": 977
                    },
                    {
                        "created": 1698573662.25094,
                        "modified": 1698573662.25094,
                        "name": "/dev/shm/incoming/2023_10_29_100039_00_00_V_042.jpg",
                        "sha256": "11f42b1de99acb916119e02276e385c3b964d820aa325c98d36c0612d7a16e4f",
                        "size": 831286
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "9df963c58b43c8fb6fc913b603bdaacf8f00f321d8934d869bbac406d712eabb",
                "size": 903896
            },
            "errors": "",
            "filename": "2023-10-29T10-05-00Z",
            "size": 903896,
            "verified_at": 1698576488
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698572223,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "KFYCgBW8H1vVqmMPxp26SfMrHnRBGLtkYcZjCWRMw7dSOxiE8Acxp4evQ2ZPEunCcT84P55JSvdaTDVsg9fcOTMB1EfpvX8tDbFoMHXdPklJrMHBIv9ETHKc3ngP7cSH0NPlM9A1ShRcrAkIAix/kByBhzwWqN94rCJOABua5L4xvJpo4AwKAF0oAJHLJ541PfeP0YMaXBZug1UrK4l/f9QL+LsT8DqZ9zGgtmmilNdOM3HXMma7FM812gbW29cAcWbsaX70iOmq2R5NI6rTiJMLm1YV6JuFz9zjBe0qQ1Jk8spnwN07m6+bYTnbhByIMFMfF6xBSd6SIkmgp++lJtc4w69Rpz38yBUj45ECXzPArYcFqpWCjCJLaywK6p12q6z1XbifVEMF7/DHB24gwiryDMLi8JVwdJKHv5fnIzP5dwod19pFWb/FYGb48FQg4mG5HryMfJmO7GNMHyvm29b21S54qS7AnZdvjU5ROls12kU8hL3uk4Io0st8wtvM7x+gldVxwQd72yta0qxk8Z9DbCghZKSNJrhyt7BHZg9WlcBfRbCADQMsaWfnCwK2pWDZPJ87pdSszVPlhUmCyPiGNKd7WExmR/6QBLYzMuQYmufvSAPbakXvgyvGGaaFLOXdOl4AEkCQTUru+ehDa0TMukF4FN+oDOchbgrRFHs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-36-35Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698572079.4509401,
                        "modified": 1698572079.4509401,
                        "name": "/dev/shm/incoming/2023_10_29_093417_00_00_V_032.txt",
                        "sha256": "bf7ba6615b971d595c416a57cc5fcd208094ac187824fb07568a5a28b76833a6",
                        "size": 2029
                    },
                    {
                        "created": 1698572071.8309402,
                        "modified": 1698572071.8309402,
                        "name": "/dev/shm/incoming/2023_10_29_093417_00_00_V_032.kml",
                        "sha256": "dfad8a57d64565b218b2265afad78bfd03ce3b650f2eb9823330e0e610be1765",
                        "size": 977
                    },
                    {
                        "created": 1698572071.7009401,
                        "modified": 1698572071.7009401,
                        "name": "/dev/shm/incoming/2023_10_29_093417_00_00_V_032.jpg",
                        "sha256": "e368deb55924bdad47ef64e1df61919506b5b8e2f57c4aa1283970480260bd69",
                        "size": 1206159
                    },
                    {
                        "created": 1698572004.6809402,
                        "modified": 1698572004.6809402,
                        "name": "/dev/shm/incoming/2023_10_29_093309_00_00_V_031.txt",
                        "sha256": "87a3fc9263be49e0917541ceb4bca3d5a2f59e1f223274cfee61d2fd7877026a",
                        "size": 2031
                    },
                    {
                        "created": 1698572004.5709403,
                        "modified": 1698572004.5709403,
                        "name": "/dev/shm/incoming/2023_10_29_093309_00_00_V_031.kml",
                        "sha256": "e7792d7d23ad6c252df48aa5c989cf611d41b5a697bf6544bdc4b9e15fc7e7fe",
                        "size": 979
                    },
                    {
                        "created": 1698572013.8409402,
                        "modified": 1698572013.8409402,
                        "name": "/dev/shm/incoming/2023_10_29_093309_00_00_V_031.jpg",
                        "sha256": "cd9486fcbb35491116cf05dbd35b4a9e3e53b72fbff19920b80cb2a3c41c1595",
                        "size": 641403
                    },
                    {
                        "created": 1698572003.1109402,
                        "modified": 1698572003.1109402,
                        "name": "/dev/shm/incoming/2023_10_29_093305_00_00_V_030.txt",
                        "sha256": "5255572aff89629396c43584650b9b19edc4f79c3ecc73a5092aaae394bfed1d",
                        "size": 2037
                    },
                    {
                        "created": 1698572003.6209402,
                        "modified": 1698572003.6209402,
                        "name": "/dev/shm/incoming/2023_10_29_093305_00_00_V_030.kml",
                        "sha256": "169fcc2caebb4f5f97e706ed6367616c8937ab5193bdbe83600a7dfaac36bbc2",
                        "size": 975
                    },
                    {
                        "created": 1698572002.4509401,
                        "modified": 1698572002.4509401,
                        "name": "/dev/shm/incoming/2023_10_29_093305_00_00_V_030.jpg",
                        "sha256": "76f087039f49a818ce79d3f37b10f2f773a554de59f5005b96358c1abab6cb09",
                        "size": 547242
                    },
                    {
                        "created": 1698571980.8909402,
                        "modified": 1698571980.8909402,
                        "name": "/dev/shm/incoming/2023_10_29_093230_00_00_V_029.txt",
                        "sha256": "c073b852f4c2b111da1766d119c27066e36758beee9143f301364204272a1ae2",
                        "size": 2033
                    },
                    {
                        "created": 1698571994.0709403,
                        "modified": 1698571994.0709403,
                        "name": "/dev/shm/incoming/2023_10_29_093230_00_00_V_029.kml",
                        "sha256": "ff44c3895edced586c362e6b33394d311a7ac3ab4fe5fc9be724d984185617fd",
                        "size": 976
                    },
                    {
                        "created": 1698571989.6109402,
                        "modified": 1698571989.6109402,
                        "name": "/dev/shm/incoming/2023_10_29_093230_00_00_V_029.jpg",
                        "sha256": "f00014ff3aa1871eca101b678a0ad9d60562134701d96c032f65b003a80b1695",
                        "size": 808011
                    },
                    {
                        "created": 1698571978.0809402,
                        "modified": 1698571978.0809402,
                        "name": "/dev/shm/incoming/2023_10_29_093221_00_00_V_028.txt",
                        "sha256": "eb93b07c8466cdf0aa2182a6802c7f0b3ccc88d4d609b8ff20f2219a96cfc8ed",
                        "size": 2038
                    },
                    {
                        "created": 1698571970.50094,
                        "modified": 1698571970.50094,
                        "name": "/dev/shm/incoming/2023-10-29T09:22:46.ais",
                        "sha256": "68d8d872df7574b8f2c79a506ea82da423aed0123683f37d6e809ec69204d48f",
                        "size": 314158
                    },
                    {
                        "created": 1698571973.5709403,
                        "modified": 1698571973.5709403,
                        "name": "/dev/shm/incoming/2023_10_29_093221_00_00_V_028.kml",
                        "sha256": "e1a20571ffaca79265cffb0a2f893a802b68b1fec2eece449513d877123ea58d",
                        "size": 977
                    },
                    {
                        "created": 1698571976.6309402,
                        "modified": 1698571976.6309402,
                        "name": "/dev/shm/incoming/2023_10_29_093221_00_00_V_028.jpg",
                        "sha256": "867267b45db94e04128a75c66a5de3e06b7e14eac080f1a7e7634ac55fa429e9",
                        "size": 541422
                    },
                    {
                        "created": 1698571955.0309403,
                        "modified": 1698571955.0309403,
                        "name": "/dev/shm/incoming/2023_10_29_093214_00_00_V_027.txt",
                        "sha256": "dbd84418867a8fb1ab3a5e6b323a46ea079bbdad908a286c68b98808e4b9f561",
                        "size": 2032
                    },
                    {
                        "created": 1698571964.0809402,
                        "modified": 1698571964.0809402,
                        "name": "/dev/shm/incoming/2023_10_29_093214_00_00_V_027.kml",
                        "sha256": "d668435f2ddbcff9a9ad205d27f985b02b61ca1dcd5d33a3db754c5bd20384a0",
                        "size": 977
                    },
                    {
                        "created": 1698571952.9509401,
                        "modified": 1698571952.9509401,
                        "name": "/dev/shm/incoming/2023_10_29_093214_00_00_V_027.jpg",
                        "sha256": "42ca3221c4e280121763b530d01dbeeaa4d6a02139494fed948d733923dbdb11",
                        "size": 549798
                    },
                    {
                        "created": 1698571951.9109402,
                        "modified": 1698571951.9109402,
                        "name": "/dev/shm/incoming/2023_10_29_093210_00_00_V_026.txt",
                        "sha256": "aa1d1cef1a6f784a7f470338e2261a85cbed0f9cc00f39eef4d3975abccc5d56",
                        "size": 2034
                    },
                    {
                        "created": 1698571952.3809402,
                        "modified": 1698571952.3809402,
                        "name": "/dev/shm/incoming/2023_10_29_093210_00_00_V_026.kml",
                        "sha256": "fdf18b54cf715a125c384d88293a7d045a51f658fef6009c840219a868c681eb",
                        "size": 975
                    },
                    {
                        "created": 1698571951.1709402,
                        "modified": 1698571951.1709402,
                        "name": "/dev/shm/incoming/2023_10_29_093210_00_00_V_026.jpg",
                        "sha256": "8166638f29a28c21620abd5dec2864525acb3a70eda2f5c1c7c0370ed6b4be67",
                        "size": 927917
                    },
                    {
                        "created": 1698571944.3109403,
                        "modified": 1698571944.3109403,
                        "name": "/dev/shm/incoming/2023_10_29_093206_00_00_V_025.txt",
                        "sha256": "16780ce838f89fc23e46dc48af6e3697293ca0c982f464f7d3804237d468c1e3",
                        "size": 2034
                    },
                    {
                        "created": 1698571943.9309402,
                        "modified": 1698571943.9309402,
                        "name": "/dev/shm/incoming/2023_10_29_093206_00_00_V_025.kml",
                        "sha256": "b4e4497059167b277e5ef815b8978d1115432c243fb1e463092deed6144e556c",
                        "size": 978
                    },
                    {
                        "created": 1698571943.8709402,
                        "modified": 1698571943.8709402,
                        "name": "/dev/shm/incoming/2023_10_29_093206_00_00_V_025.jpg",
                        "sha256": "8fc1be0c22974fea3ea2a7ba62b61b4a4742c211bcc18cfe0defb3149b140726",
                        "size": 1188567
                    },
                    {
                        "created": 1698571927.2909403,
                        "modified": 1698571927.2909403,
                        "name": "/dev/shm/incoming/2023_10_29_093130_00_00_V_024.txt",
                        "sha256": "123b670ac853742916581794a47b8b84c23dbe9a9b255320a7b4a0e7f892c80a",
                        "size": 2028
                    },
                    {
                        "created": 1698571906.6609402,
                        "modified": 1698571906.6609402,
                        "name": "/dev/shm/incoming/2023_10_29_093130_00_00_V_024.kml",
                        "sha256": "94b3e8896316a998f7f4814601e1bca7cb5b241393d91902b7118bffc9079bec",
                        "size": 976
                    },
                    {
                        "created": 1698571906.0409403,
                        "modified": 1698571906.0409403,
                        "name": "/dev/shm/incoming/2023_10_29_093130_00_00_V_024.jpg",
                        "sha256": "b1d09aa8caf3ecf8cd760c71bd764eff0152923dd6a8689d5bc6d2b8dc4a625d",
                        "size": 1051464
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "009ebcc910e3b09803004abb1b730fe51b761f6137187134cdcb0e03fbcda115",
                "size": 7516072
            },
            "errors": "",
            "filename": "2023-10-29T09-36-35Z",
            "size": 7516072,
            "verified_at": 1698580518
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698572491,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "RQ34DjJOFT5qAWVkwmV45/oKFAjiGg9LiM41Cyghve9j7cIvoQlhXK6juHR6B2EhzM0SHLgpuBOhGJOrtSpRj9ViFFxvG8ODB2Kir5sJbpqRavp2/2ftapdTKYAPVNGCdYJL3pp8assnHl5LBHBAtS7F+k4r08F6CIpsEXpYrA8+gbSysRqpsteuJzJbRaUwJ8thCyUbfElYpd3LGUpytsIc67H+/B/R5i204zMUGs70mQQz4QT5l5U40bAewAcJwrR3ZQtw2Fi51FtnFWJpTL7mVVgYM/M+OXrOHMjh5mx1731KtKiYaY1ZLKtLeokQHAYKouNadB/rlN6EDtsvBDI0km6aXv8ImnVrOX+ixC3KZKSg62ZEHJz7R/D0WDhsutPk4lfyjx5mqsB1v8HvDfht2hURyxqx8gz4l4XnxDVil4MGFOZx2W0ZimRf4nSrrTfMqOHezAb/OoRtUsDZqiiByvC+u1sH+5wAuWyTT9p7jPuq6ArRldM8BECnUeKcCADurbUUsvYx6Rcf8bJaq7NvZNIwxSzjZD9zJ3bSNypx6QpNcu5/oXAuN1oG2UgLQJf4g7URxXlM7JaWbhMEyr9epSbHA8F5U+AyhYo4geYWnqvwbKWox+V2QpjwRm5OD5hCDJ/RRPTNr91ZuMAqT7gAJaUVfThLxnGVzaW5bOc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-40-52Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698572403.1009402,
                        "modified": 1698572403.1009402,
                        "name": "/dev/shm/incoming/2023_10_29_093921_00_00_V_033.txt",
                        "sha256": "2ac2c9fc58419e02828352e6bc51ae45159a727126374f6d72bafb84b221fc9d",
                        "size": 2032
                    },
                    {
                        "created": 1698572387.9409401,
                        "modified": 1698572387.9409401,
                        "name": "/dev/shm/incoming/2023_10_29_093921_00_00_V_033.kml",
                        "sha256": "cfa24e20f2c3e306abe7d8e8c7363903a7b9835fad2d7c5698522226b4033198",
                        "size": 977
                    },
                    {
                        "created": 1698572384.6109402,
                        "modified": 1698572384.6109402,
                        "name": "/dev/shm/incoming/2023_10_29_093921_00_00_V_033.jpg",
                        "sha256": "80f486f76e64492b436c12c24be6638b7a90c803405ad25009f0200d91f76bd1",
                        "size": 1119672
                    },
                    {
                        "created": 1698572441.7709403,
                        "modified": 1698572441.7709403,
                        "name": "/dev/shm/incoming/2023-10-29T09:29:48.ts",
                        "sha256": "f5fd4872310210b97fb76e40f34b5b02ad8a5a37c52f49ec96a3c118ef726b6f",
                        "size": 145183188
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "24326d28c45b6534f8bd59d7599268697eeddbbfd9d325769c08b2c7d9a2d0f6",
                "size": 141097852
            },
            "errors": "",
            "filename": "2023-10-29T09-40-52Z",
            "size": 141097852,
            "verified_at": 1698580602
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698572930,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "CGJrrlDdIY2q4g/r45yDozQ2VSgYxLXddgO02ykUoCmSEv8BMNZGI+raaGyFHzMVVCcS1Nnk1vqVyDoXElcgNsuU65hBEJJYn87lD13G3RmE5nwFTvxfZPaNfEXsaUSd24EKOSH6E85/L/qEEK5m0UxVnJR+BNJDOA9RWoDOUyYgPo6wGMvdnFDChNSwvN8A9XlM8Lf7GL00UbMSfuo4Hz0XKOBL6ys3yLadPpKs1/qMcNZHSgYKYVdmo2DO1dyGJspfAkwcL4+9dO4fyYPLevWhBmRXaTPXGVKRFF+WM6Z1v74Y+r5j9V4RQWoByYFGceBzH/MrQzuN1+p51WlhB++96f0xIsJTB3MC7XAPm+6Bwy5CLFRO5aVmC19kalwY5B5sARdv/RNwKDlt86CYBsr/Cs3rajfr5CeiAYe/RJMbQ/8yutfd0uKGYuTsHByDHh2SFJoVTRBlfNjzyTr4cF8cbAph3XZw7El4wHbTzoqgaAyF/5XYzHmb6R9Rei5xm8KAQsUpCPQHy3m7CSGQUGCRWk/fL0U+3zpuMtACt4y8OBuuoiwj76kUj8YGfDKexoH1tYd7MrGgvMpREXeIVl3CF/Rvw40vcBe7PsDiA5VYZBmJINwBD6sIzjGJhP4olvPH/DO52Ybl9dAQpr/OkmLJaiTtijzx1juWAY4fsjg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-48-15Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698572764.7109401,
                        "modified": 1698572764.7109401,
                        "name": "/dev/shm/incoming/2023-10-29T09:45:17.ts",
                        "sha256": "5d03ae1d3edbb358c603216db62dcaf0b6625bb074595bb2c2b12d3e2dc510fb",
                        "size": 11363284
                    },
                    {
                        "created": 1698572626.4109402,
                        "modified": 1698572626.4109402,
                        "name": "/dev/shm/incoming/2023_10_29_094319_00_00_V_041.txt",
                        "sha256": "d7b0fb433d6ead8bf8e89668d77f6c0376e24c9fc1c2d16f535f113605648deb",
                        "size": 2035
                    },
                    {
                        "created": 1698572626.0209403,
                        "modified": 1698572626.0209403,
                        "name": "/dev/shm/incoming/2023_10_29_094319_00_00_V_041.kml",
                        "sha256": "09125f03f91b74c4792ad7185fb6c7b78f2728cb41e58e56cb5b6f42d1a53411",
                        "size": 979
                    },
                    {
                        "created": 1698572615.8109403,
                        "modified": 1698572615.8109403,
                        "name": "/dev/shm/incoming/2023_10_29_094319_00_00_V_041.jpg",
                        "sha256": "f83167d2ae514904ae70c1f39a9743cc807707ba6b31a9ae4b7eeea18c1ce777",
                        "size": 1028724
                    },
                    {
                        "created": 1698572611.6909401,
                        "modified": 1698572611.6909401,
                        "name": "/dev/shm/incoming/2023_10_29_094305_00_00_V_040.txt",
                        "sha256": "39fe629cee83467ccf1d9f353090e0aae1f443b3b04aa794436d2cdf5ff6450b",
                        "size": 2035
                    },
                    {
                        "created": 1698572613.4609401,
                        "modified": 1698572613.4609401,
                        "name": "/dev/shm/incoming/2023_10_29_094305_00_00_V_040.kml",
                        "sha256": "723f62bc89a0b696d1fbe1187bd9c5b47d1a45eed0dc396e53fed28fe9515de9",
                        "size": 977
                    },
                    {
                        "created": 1698572610.1309402,
                        "modified": 1698572610.1309402,
                        "name": "/dev/shm/incoming/2023_10_29_094305_00_00_V_040.jpg",
                        "sha256": "90cd2195f1544cd46a43b6f1f3d5bb6051caa40aa6d7cb1277c655a174bfd391",
                        "size": 1265195
                    },
                    {
                        "created": 1698572600.9409401,
                        "modified": 1698572600.9409401,
                        "name": "/dev/shm/incoming/2023_10_29_094248_00_00_V_039.txt",
                        "sha256": "4240f6f57621c2878367d53eff5387a5da31c99cde2559cc25844a57f0ffcbb8",
                        "size": 2036
                    },
                    {
                        "created": 1698572601.7009401,
                        "modified": 1698572601.7009401,
                        "name": "/dev/shm/incoming/2023_10_29_094248_00_00_V_039.kml",
                        "sha256": "b4362fd730130833bd224d139a8ba28961093d2570bf5cc75ee670b3ce1124d1",
                        "size": 978
                    },
                    {
                        "created": 1698572591.7909403,
                        "modified": 1698572591.7909403,
                        "name": "/dev/shm/incoming/2023_10_29_094248_00_00_V_039.jpg",
                        "sha256": "1d93bc8e62f35d5ee71737c4a9e31072e00327b2b41ff1ccb77d1ff44a1c8384",
                        "size": 1389044
                    },
                    {
                        "created": 1698572581.2009401,
                        "modified": 1698572581.2009401,
                        "name": "/dev/shm/incoming/2023_10_29_094239_00_00_V_038.txt",
                        "sha256": "6f094ada3ef6910ca8870cd4a8cef07a60a3d3119aa0482238e91ad65176fa36",
                        "size": 2037
                    },
                    {
                        "created": 1698572578.5209403,
                        "modified": 1698572578.5209403,
                        "name": "/dev/shm/incoming/2023_10_29_094239_00_00_V_038.kml",
                        "sha256": "f7a60db508a1725830d4e890d44b8910b950064fe1169d87155526d8eba47ae6",
                        "size": 979
                    },
                    {
                        "created": 1698572582.4109402,
                        "modified": 1698572582.4109402,
                        "name": "/dev/shm/incoming/2023_10_29_094239_00_00_V_038.jpg",
                        "sha256": "3c5e4d49f9f28f2f5f8b18d9b2dc06aa6925b6b397b27d7f2af75f3c1522534d",
                        "size": 940311
                    },
                    {
                        "created": 1698572572.5409403,
                        "modified": 1698572572.5409403,
                        "name": "/dev/shm/incoming/2023-10-29T09:32:50.ais",
                        "sha256": "90cd21f74a9aaad074ccf12c573aebdbbeeaf6c95c5cc387b62fbf2ef4b806e5",
                        "size": 250814
                    },
                    {
                        "created": 1698572572.9009402,
                        "modified": 1698572572.9009402,
                        "name": "/dev/shm/incoming/2023_10_29_094227_00_00_V_037.txt",
                        "sha256": "204820fdfac6186e196503975354a4c2d87053dedcc09ed3d4349abc39755a3d",
                        "size": 2036
                    },
                    {
                        "created": 1698572570.6809402,
                        "modified": 1698572570.6809402,
                        "name": "/dev/shm/incoming/2023_10_29_094227_00_00_V_037.kml",
                        "sha256": "4753f6b73778662204530d1bc52f3d93d02be67818b626a71fbd0f76b9b773d3",
                        "size": 976
                    },
                    {
                        "created": 1698572576.3909402,
                        "modified": 1698572576.3909402,
                        "name": "/dev/shm/incoming/2023_10_29_094227_00_00_V_037.jpg",
                        "sha256": "f0d04ad9ce05832986a1114a60ab42a53b17016fe9d0e6b42d500f0334a5eb41",
                        "size": 947560
                    },
                    {
                        "created": 1698572549.3509402,
                        "modified": 1698572549.3509402,
                        "name": "/dev/shm/incoming/2023_10_29_094150_00_00_V_036.txt",
                        "sha256": "21475547edeedc9bf1c9aa9cea407e0a44005cdd304117fd8bba21d21bbac3d7",
                        "size": 2032
                    },
                    {
                        "created": 1698572540.75094,
                        "modified": 1698572540.75094,
                        "name": "/dev/shm/incoming/2023_10_29_094150_00_00_V_036.kml",
                        "sha256": "c417849aecd6b3b6399db951ed49736834d00bf1eacdab8ee04abb368bc4ab44",
                        "size": 975
                    },
                    {
                        "created": 1698572541.7709403,
                        "modified": 1698572541.7709403,
                        "name": "/dev/shm/incoming/2023_10_29_094150_00_00_V_036.jpg",
                        "sha256": "7d1d174b8543df6e908449a561d362e060febaf36f787f5e399aeda4dc46ba15",
                        "size": 454955
                    },
                    {
                        "created": 1698572519.8009403,
                        "modified": 1698572519.8009403,
                        "name": "/dev/shm/incoming/2023_10_29_094127_00_00_V_035.txt",
                        "sha256": "e01a20f3aa23d4a61e01d3e1c7f202af27ce00819a2919ab697831377ed42275",
                        "size": 2035
                    },
                    {
                        "created": 1698572522.9109402,
                        "modified": 1698572522.9109402,
                        "name": "/dev/shm/incoming/2023_10_29_094127_00_00_V_035.kml",
                        "sha256": "3f2fbd514ce5af7fc09472287f7f8d45a6e8b010407b9b0622149fde3d2f06d7",
                        "size": 980
                    },
                    {
                        "created": 1698572519.6909401,
                        "modified": 1698572519.6909401,
                        "name": "/dev/shm/incoming/2023_10_29_094127_00_00_V_035.jpg",
                        "sha256": "33c7d53cc6fa6e91665a1a85805680f914be9b5551fc86510eab17ac9e748aa0",
                        "size": 869635
                    },
                    {
                        "created": 1698572511.5109403,
                        "modified": 1698572511.5109403,
                        "name": "/dev/shm/incoming/2023_10_29_094118_00_00_V_034.txt",
                        "sha256": "13575986f38eef33bbc5fe1fd64ac8aaf699bff480c7ab9822fbd09516e947f7",
                        "size": 2038
                    },
                    {
                        "created": 1698572497.4509401,
                        "modified": 1698572497.4509401,
                        "name": "/dev/shm/incoming/2023_10_29_094118_00_00_V_034.kml",
                        "sha256": "8b029726cb8d76432a5d71bb3c2d366847a4623c8d6f228086affaa69fad478e",
                        "size": 976
                    },
                    {
                        "created": 1698572497.25094,
                        "modified": 1698572497.25094,
                        "name": "/dev/shm/incoming/2023_10_29_094118_00_00_V_034.jpg",
                        "sha256": "1e41385d3f61b8146a8a51c72c2dcce2c11ddedc501563041856247ac86a1ac6",
                        "size": 1022797
                    },
                    {
                        "created": 1698572711.1909401,
                        "modified": 1698572711.1909401,
                        "name": "/dev/shm/incoming/2023-10-29T09:40:47.ts",
                        "sha256": "954452eea4a66da75d4b43d5298f05b8db6bf708ff20f96c768943be0e119350",
                        "size": 58125464
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "67fb5799689a33243de630e428ed922fdeb9b9eba7178328c4f254d15c16f3e9",
                "size": 74996868
            },
            "errors": "",
            "filename": "2023-10-29T09-48-15Z",
            "size": 74996868,
            "verified_at": 1698580773
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698572931,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "U+dLsrfaFjTgp12J9PWsudCNuC2R3QY0gHl1+Eteu3GXU+9gvWQOfbIlaAbsfkPs6zk1mq59m05fO4eDArfh+X4uAk9j7bnPNX0DZ99NHOIaEhitDnYP7RiihCMBkMVx2ykyPRlvfrFBRWv6APgAYGtVAhqJqsfXIJSzIqakywC1+eGb0f2S876z8SBwfbppPTn6N+4ekmX3gr8xlYdr5hEWCOUJBE3xffA66Ype8ludedoudd64M7Rr687JTpQ9lebQQTCjMP5d0pQQH9rkI4BpYTrXpdQizcoO+L3EgI8eye8HGz0Ri30UeTBFvXh2iEJvjwjwVstIi/ryca+o8lkmrhukUnwY3C2p3fOTRvyt9uSVpS0mNq2y40pb0eqBP68FlMuXTyULErvywsaMvmT+QjGg/AiJwtLvcxPih2omFx3vxCtXrgkUaNA1nPxAkqO9k6wkLyUPnNzWBGoHn6evIC6r6b02/LMPMVp1p5D2Z8ld+AZq3lC9zPqrpkK4ureW4ReSc+6Tz6Fq3/JDVSYs4XUoKoq7dayru2gUMmE195LI4q9RPPjpNLzxiMENunGnanmLw30Vq+mZTBvRdkNHXPp5ZhyVG8+AS0S6btsDyAyaELUvRbpQDIrCLT5MWNOxERGslkViOpNKfpaGECOAyAlRlhZgEH8/MLMGcA4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-48-17Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698572770.7632341,
                        "modified": 1698572770.7632341,
                        "name": "/dev/shm/incoming/2023_10_29_094546_00_00_K_089.txt",
                        "sha256": "7ccf2011c59e9503842736f8d731cb9a0b8bb08cf96e34dbd3ea97591af0318d",
                        "size": 2046
                    },
                    {
                        "created": 1698572770.603234,
                        "modified": 1698572770.603234,
                        "name": "/dev/shm/incoming/2023_10_29_094546_00_00_K_089.kml",
                        "sha256": "f612dad15a65de2ac6fec711a7bf18c6120637ce23e30853ef732361dc7b4670",
                        "size": 977
                    },
                    {
                        "created": 1698572769.883234,
                        "modified": 1698572769.883234,
                        "name": "/dev/shm/incoming/2023_10_29_094546_00_00_K_089.jpg",
                        "sha256": "3e38c4d5919af349f9cd81491c81b04ad77e1e319d71c5e85d48477152f2b0fb",
                        "size": 825075
                    },
                    {
                        "created": 1698572758.2232342,
                        "modified": 1698572758.2232342,
                        "name": "/dev/shm/incoming/2023_10_29_094537_00_00_K_088.txt",
                        "sha256": "c94d7a7b7d536a63aa771ebba90979fa586a8e4fd01f97f360bcd027c4a73a4f",
                        "size": 2050
                    },
                    {
                        "created": 1698572756.893234,
                        "modified": 1698572756.893234,
                        "name": "/dev/shm/incoming/2023_10_29_094537_00_00_K_088.kml",
                        "sha256": "b402d0e05d09bf3dd0952479da8d4580ee5092e3fed20b5b87a4ca6356d3bbee",
                        "size": 976
                    },
                    {
                        "created": 1698572756.4432342,
                        "modified": 1698572756.4432342,
                        "name": "/dev/shm/incoming/2023_10_29_094537_00_00_K_088.jpg",
                        "sha256": "c434faecfdc31b84b8e7555312cb8f15058c2a564f71476a76c31dd155ae281b",
                        "size": 818768
                    },
                    {
                        "created": 1698572733.9432342,
                        "modified": 1698572733.9432342,
                        "name": "/dev/shm/incoming/2023_10_29_094518_00_00_K_087.txt",
                        "sha256": "31b0926fcb8f8c794f777dbc5a1adb723b74c4de922454eb6452a6b1f13ee2ca",
                        "size": 2050
                    },
                    {
                        "created": 1698572733.2432342,
                        "modified": 1698572733.2432342,
                        "name": "/dev/shm/incoming/2023_10_29_094518_00_00_K_087.kml",
                        "sha256": "88f3b7e98c23bac15c37b4509dbd90e3dcc71d82bdab53a95d1ac5c7624bfc4b",
                        "size": 976
                    },
                    {
                        "created": 1698572732.923234,
                        "modified": 1698572732.923234,
                        "name": "/dev/shm/incoming/2023_10_29_094518_00_00_K_087.jpg",
                        "sha256": "38c8e4d89ad168336d62016c61e124282e2179101ea83db37ac1abdbfac74d6b",
                        "size": 713571
                    },
                    {
                        "created": 1698572710.423234,
                        "modified": 1698572710.423234,
                        "name": "/dev/shm/incoming/2023_10_29_094447_00_00_K_086.txt",
                        "sha256": "6b4e5a38447bae966c65c61d2a7afeb850f8608bc8b268cea304a26917aea20e",
                        "size": 2050
                    },
                    {
                        "created": 1698572709.893234,
                        "modified": 1698572709.893234,
                        "name": "/dev/shm/incoming/2023_10_29_094447_00_00_K_086.kml",
                        "sha256": "93229e2c9f5cd6617d5cc954ee9193953d87cefb78ca104bf7d469d8236f1e0d",
                        "size": 978
                    },
                    {
                        "created": 1698572709.7232342,
                        "modified": 1698572709.7232342,
                        "name": "/dev/shm/incoming/2023_10_29_094447_00_00_K_086.jpg",
                        "sha256": "4278eccd4f4fb3f173ca17af7697f92d1d5ecb52b1e5e58016bde4df53efc452",
                        "size": 1150137
                    },
                    {
                        "created": 1698572708.6932342,
                        "modified": 1698572708.6932342,
                        "name": "/dev/shm/incoming/2023_10_29_094444_00_00_K_085.txt",
                        "sha256": "110c71a66d2513cde6c305c5e093357ccd62c9cea87544f50f1ee3b86da5e64f",
                        "size": 2049
                    },
                    {
                        "created": 1698572708.0332341,
                        "modified": 1698572708.0332341,
                        "name": "/dev/shm/incoming/2023_10_29_094444_00_00_K_085.kml",
                        "sha256": "74388c3e785b4d777a3c958414278e22150db47df75d49f17d722d7f972e0ee2",
                        "size": 977
                    },
                    {
                        "created": 1698572707.653234,
                        "modified": 1698572707.653234,
                        "name": "/dev/shm/incoming/2023_10_29_094444_00_00_K_085.jpg",
                        "sha256": "6369ab9535b9d984e6656b10c7206452afd8e8cf9daf762ff0bd6e6b372de260",
                        "size": 1148129
                    },
                    {
                        "created": 1698572695.903234,
                        "modified": 1698572695.903234,
                        "name": "/dev/shm/incoming/2023_10_29_094441_00_00_K_084.txt",
                        "sha256": "78126227130287758822c41cb5327a6daa8e8c7af6ee94477049260befec4a3b",
                        "size": 2050
                    },
                    {
                        "created": 1698572695.643234,
                        "modified": 1698572695.643234,
                        "name": "/dev/shm/incoming/2023_10_29_094441_00_00_K_084.kml",
                        "sha256": "447ee3d19062b2de79b4f53f5c662a469672f9a67f19d8ce191b8bc96bd27495",
                        "size": 977
                    },
                    {
                        "created": 1698572695.4832342,
                        "modified": 1698572695.4832342,
                        "name": "/dev/shm/incoming/2023_10_29_094441_00_00_K_084.jpg",
                        "sha256": "bc2412bed2fd082d1d7af51dcb40cf10ee4cd80b9bedb5145ae24ba0eacc5dd6",
                        "size": 1099582
                    },
                    {
                        "created": 1698572694.863234,
                        "modified": 1698572694.863234,
                        "name": "/dev/shm/incoming/2023_10_29_094435_00_00_K_083.txt",
                        "sha256": "7da71f25f327a8d1d9262ec2f4fe28b5895423a303893920992b980f7038c9b9",
                        "size": 2048
                    },
                    {
                        "created": 1698572694.603234,
                        "modified": 1698572694.603234,
                        "name": "/dev/shm/incoming/2023_10_29_094435_00_00_K_083.kml",
                        "sha256": "b0692254c02dccb070faebb0946d5312c6b765a6dfa554b199bcf8f23cc1b72e",
                        "size": 978
                    },
                    {
                        "created": 1698572694.4332342,
                        "modified": 1698572694.4332342,
                        "name": "/dev/shm/incoming/2023_10_29_094435_00_00_K_083.jpg",
                        "sha256": "0cbf3437a2958474e20c17f9eef5404b1f2894a85ce6654955f51eadc55250a2",
                        "size": 1062117
                    },
                    {
                        "created": 1698572650.293234,
                        "modified": 1698572650.293234,
                        "name": "/dev/shm/incoming/2023_10_29_094349_00_00_K_082.txt",
                        "sha256": "64a0eee1532a35ae60a7989aac72bc8eb7c1e77a24dcfbff2ef26a8dd55c9a37",
                        "size": 2050
                    },
                    {
                        "created": 1698572650.073234,
                        "modified": 1698572650.073234,
                        "name": "/dev/shm/incoming/2023_10_29_094349_00_00_K_082.kml",
                        "sha256": "e8c0bb3cf2df0f19dca5bda5784854fd275b32c8e62df88710584563062b71fa",
                        "size": 975
                    },
                    {
                        "created": 1698572649.863234,
                        "modified": 1698572649.863234,
                        "name": "/dev/shm/incoming/2023_10_29_094349_00_00_K_082.jpg",
                        "sha256": "fbb058b2b5a52ff534cbc97b1ba1345427dcdfbfee9e71b274d71a1e9f6cb1ed",
                        "size": 1097693
                    },
                    {
                        "created": 1698572627.0232341,
                        "modified": 1698572627.0232341,
                        "name": "/dev/shm/incoming/2023_10_29_094317_00_00_K_081.txt",
                        "sha256": "da620af8e7ea71c784cdfd0cb343daacffb264d32684af2677d401ed1f12c5c0",
                        "size": 2050
                    },
                    {
                        "created": 1698572626.9332342,
                        "modified": 1698572626.9332342,
                        "name": "/dev/shm/incoming/2023_10_29_094317_00_00_K_081.kml",
                        "sha256": "995637236ef2cd39af4e7cc793545d81e7b98757cd6b1c7cede414e78a81c823",
                        "size": 976
                    },
                    {
                        "created": 1698572626.593234,
                        "modified": 1698572626.593234,
                        "name": "/dev/shm/incoming/2023_10_29_094317_00_00_K_081.jpg",
                        "sha256": "1b274e8bb02b3c0015a33902ab2fc05d558003111845dad0cf914e65cf503c43",
                        "size": 699095
                    },
                    {
                        "created": 1698572615.393234,
                        "modified": 1698572615.393234,
                        "name": "/dev/shm/incoming/2023_10_29_094311_00_00_K_080.txt",
                        "sha256": "fa31a2da7a25fdde4e8975cd57fa6e6d8e43ae99bfb00867981c29da7b8882c5",
                        "size": 2050
                    },
                    {
                        "created": 1698572614.853234,
                        "modified": 1698572614.853234,
                        "name": "/dev/shm/incoming/2023_10_29_094311_00_00_K_080.kml",
                        "sha256": "59f7be6bae82cff597b2e84a6d5c27ff59ec4f33d378fb3b1d7e88f3de5ba1d2",
                        "size": 976
                    },
                    {
                        "created": 1698572614.593234,
                        "modified": 1698572614.593234,
                        "name": "/dev/shm/incoming/2023_10_29_094311_00_00_K_080.jpg",
                        "sha256": "b5bcbf1366b32041c33e4c475e7981b6e4dfc5dccaa2aa48e73ef4a11e712cf0",
                        "size": 835804
                    },
                    {
                        "created": 1698572603.2432342,
                        "modified": 1698572603.2432342,
                        "name": "/dev/shm/incoming/2023_10_29_094305_00_00_K_079.txt",
                        "sha256": "95b14fa8275889955248c7dfaff036d466c1891b71cd0425ac40f925a5a1cb65",
                        "size": 2050
                    },
                    {
                        "created": 1698572603.0232341,
                        "modified": 1698572603.0232341,
                        "name": "/dev/shm/incoming/2023_10_29_094305_00_00_K_079.kml",
                        "sha256": "80aad4c8b79c2acfdb5f1068517a304413f5a05b47e518d012be2e44817004a0",
                        "size": 975
                    },
                    {
                        "created": 1698572602.4732342,
                        "modified": 1698572602.4732342,
                        "name": "/dev/shm/incoming/2023_10_29_094305_00_00_K_079.jpg",
                        "sha256": "ea26fd74ba176a3d36310be86084ec12d2928e4f41a1d538f4d1c00f9ea3f8f6",
                        "size": 1156532
                    },
                    {
                        "created": 1698572601.423234,
                        "modified": 1698572601.423234,
                        "name": "/dev/shm/incoming/2023_10_29_094300_00_00_K_078.txt",
                        "sha256": "0c1a187d0e08d90fec0e5cfe91bbff5033876bd43126950a3055ef456f40b2bf",
                        "size": 2050
                    },
                    {
                        "created": 1698572600.9632342,
                        "modified": 1698572600.9632342,
                        "name": "/dev/shm/incoming/2023_10_29_094300_00_00_K_078.kml",
                        "sha256": "0ea0b44534995e667275700389a9446fa644539a6d32cd9004d424bd0a642f54",
                        "size": 976
                    },
                    {
                        "created": 1698572600.373234,
                        "modified": 1698572600.373234,
                        "name": "/dev/shm/incoming/2023_10_29_094300_00_00_K_078.jpg",
                        "sha256": "5759c6f78a979406a13794991c8660e5a9304440dff56e231d1b38b9f3fde662",
                        "size": 1224167
                    },
                    {
                        "created": 1698572599.323234,
                        "modified": 1698572599.323234,
                        "name": "/dev/shm/incoming/2023_10_29_094257_00_00_K_077.txt",
                        "sha256": "4bf090de8d3dd48162362fec0f54634e3e1c5657ef59c3c40cc6ba7b2fa8e19b",
                        "size": 2050
                    },
                    {
                        "created": 1698572598.913234,
                        "modified": 1698572598.913234,
                        "name": "/dev/shm/incoming/2023_10_29_094257_00_00_K_077.kml",
                        "sha256": "e1293fa7a9266d4b003b4f46775b520c3f8ffdfaa00ecbcf77dbbd6daf95d5fa",
                        "size": 978
                    },
                    {
                        "created": 1698572598.613234,
                        "modified": 1698572598.613234,
                        "name": "/dev/shm/incoming/2023_10_29_094257_00_00_K_077.jpg",
                        "sha256": "c7e117455b1c814df51bfe8b7ef0d019c839e18267170890d65b70c69db2ef2f",
                        "size": 1225528
                    },
                    {
                        "created": 1698572554.7532341,
                        "modified": 1698572554.7532341,
                        "name": "/dev/shm/incoming/2023_10_29_094218_00_00_K_076.txt",
                        "sha256": "f9155a2a9c03e6f2814e45efd36087b119ced5c0f10245c51519e811a3ddf9e2",
                        "size": 2050
                    },
                    {
                        "created": 1698572553.813234,
                        "modified": 1698572553.813234,
                        "name": "/dev/shm/incoming/2023_10_29_094218_00_00_K_076.kml",
                        "sha256": "d5407cbfe95e888e46e535d54a22495bc5285bffdaf13f6160b874b1469ec3e1",
                        "size": 977
                    },
                    {
                        "created": 1698572553.7332342,
                        "modified": 1698572553.7332342,
                        "name": "/dev/shm/incoming/2023_10_29_094218_00_00_K_076.jpg",
                        "sha256": "f36ae1c5dab53e4e73818443e9784bd4504b24d4edd420a3b491fbbc2962331a",
                        "size": 1182437
                    },
                    {
                        "created": 1698572530.143234,
                        "modified": 1698572530.143234,
                        "name": "/dev/shm/incoming/2023_10_29_094144_00_00_K_075.txt",
                        "sha256": "98bfae2ce38b1b28bd825e3d2428f675c74cae99014ea2d84aca1d6d9a97bdc5",
                        "size": 2050
                    },
                    {
                        "created": 1698572529.2832341,
                        "modified": 1698572529.2832341,
                        "name": "/dev/shm/incoming/2023_10_29_094144_00_00_K_075.kml",
                        "sha256": "5c2793a5f504bda00de3026d8bfd8c2f8f015c1904e5f47bcba4520adf3c708e",
                        "size": 975
                    },
                    {
                        "created": 1698572529.093234,
                        "modified": 1698572529.093234,
                        "name": "/dev/shm/incoming/2023_10_29_094144_00_00_K_075.jpg",
                        "sha256": "f4104dbdef7bfd5399c94982e03b438a75335e4031abcfa32e36ec8095dce902",
                        "size": 1110679
                    },
                    {
                        "created": 1698572493.633234,
                        "modified": 1698572493.633234,
                        "name": "/dev/shm/incoming/2023_10_29_094119_00_00_K_074.txt",
                        "sha256": "93e9c6a744ed4922581204f883979b149a2fd5453dc77572eaa08216743e4967",
                        "size": 2050
                    },
                    {
                        "created": 1698572493.4732342,
                        "modified": 1698572493.4732342,
                        "name": "/dev/shm/incoming/2023_10_29_094119_00_00_K_074.kml",
                        "sha256": "70e5820ada7ea3f2526339140a4f3d2a121b04cd0b03b84af7d138632325e37c",
                        "size": 978
                    },
                    {
                        "created": 1698572493.093234,
                        "modified": 1698572493.093234,
                        "name": "/dev/shm/incoming/2023_10_29_094119_00_00_K_074.jpg",
                        "sha256": "bd0f47ae7a4694a8d933b6e567c0ac6e4b42a86794c8b63ce98325c91621b20f",
                        "size": 1136167
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "f76093518a382f83b2c976b07ba1ead7b8d61290c064d09d40dd3a140add7d34",
                "size": 16484685
            },
            "errors": "",
            "filename": "2023-10-29T09-48-17Z",
            "size": 16484685,
            "verified_at": 1698595847
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698573812,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "Cxqf2fR+V2Jn6AZ2r6wQKTLQDAt103FOil8tuT45h68fGh5oFPydQMjIvpoSjDNmDWUdgjCqQOO+1n95wvdsFl+JLSXHK9uVEeq2RavGDl3KNwTuQ5LFYLP4AxElVH/VA9v0qyYs4bFcbWXnOxyWgAkRUamSA2077pstN8ptjes+qnJOWeNbf3Tlnk5ycyYDlk1fGjEE2VeqfcBh81pdAw6Cm4xe21SJdtLv9n9td2lBFt92se8D6XV3P3/AAEX2RccmmmiKni68HvAnyKHYbQH3dQHiP6QFILVVgHYdIsPGnd1SxiKWCvkYg8g2NcVvJ/fK0Y7mOmE3WLH8lXJxaQiwUooJ7KQu3tIt+BxMz0J9QjuvDtys3kYfHRFEEp4Fqc/jcLMGY05CZf0gKgmqSXXrCxFwbOIRzg4KyrUpkdwDunC9QOtm7N3vl6aFbMNNS6YR41HfVZzHEzW0ORgOP0PmXtquNdFNn9yF0O9giffvXfzaXfNzHH0vDzvK8zb/NdD6SVBcDEVkeoeYI5BZe72MxWoCdaBQJjQvP/WpRYEPvx5e7udQWcyflycX5jQRlSGMwU70C+wNB/vs8n1+9XRllOX4O+EZ3WSOMK/eAaA2MfyroKt2RXEZq5Rrx0jGsuKUX0n+o3VnG3fva7z7Ua339r/ic8sSg1hKtzs5y80=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T10-01-56Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698573591.103234,
                        "modified": 1698573591.103234,
                        "name": "/dev/shm/incoming/2023-10-29T09:49:46.ais",
                        "sha256": "2b5c111029273acb539a7045d0a59678950c13928765d6c3d25b77503603d4eb",
                        "size": 151438
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "1df496bb7cacb92cec8b31d7a791011b977427087df85e29b10f0ae5e6fe2912",
                "size": 45303
            },
            "errors": "",
            "filename": "2023-10-29T10-01-56Z",
            "size": 45303,
            "verified_at": 1698595862
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698573178,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "t6YEdQNfw34vqpT+WsFP3pwAlakjIHO3Jfnv6xevbcun5zk3T3pSHOgt2Ii8emdkeGUoiBXHuoP/Y7XYr/+NE/LpC/icBiw263kBnYTzVolYBGbBj3DxYsCdInze6wTPX988Nk6X56/EiOmZ2GAQ6ite42D3SRwruP5YrmDleN7RKXW+/ilbSGYsl0BBVoXClZxHpWonkz2N9NrNRctePMNnearXZFGfQTlwgq3W8TzQBZLI7E02r3oRn2oxVNT7D4rz2wqFZnLkOenjleL4/j/0burCazVcwtbUtihyvx8Fe+e7mGK2IjuC13wOp1vTB6AnC0NQGCghBwlXWOPJlQoAim+k1ZBqL7OcQC/e4nRHokE7ZENa5gMDMYJW4jGqO1+AYENzzprgE3cQ6bJAel85Dn5L46tf+rcx8YnZaMRQFCi8C5DoZ0u30fV2alAie8vk2fsBQhUdiBtzx81ngiOLxD1Oljg281I5haeleZb18akzF6Cb+Impwl0k3ui+MJONuvsyZNYrHrbqf7El5kLFSQ/+sKA6f1tsO9T9P2j8XXVs/vpL46MudqECjjttfZfkhENqbzAJiXjScAGWNFlSfb0O4SB4b/54NfMAJvRbfuJNN2gCZ5/PuQMOLeeyrBGc1frMrbAifyXttUpJSkqSlkgOOMMWJlTpSqJeAo8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-51-52Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698572986.153234,
                        "modified": 1698572986.153234,
                        "name": "/dev/shm/incoming/2023-10-29T09:39:44.ais",
                        "sha256": "840bf2f22a3b39180a4c0e754c0e354b6000b7b4e7cec4eaa3108c20b327d8a9",
                        "size": 187249
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "202264ae90b888a134c7dbc2cb4ef23974b1dbcdc703add5e20d97a079e1f943",
                "size": 55420
            },
            "errors": "",
            "filename": "2023-10-29T09-51-52Z",
            "size": 55420,
            "verified_at": 1698595865
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698572522,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "dgRfKmp8gadGNzd70ese5hUhwgFZXKUq+WJBuHecZr2D4/V37wV7wdRvNsVs+Amq7eXTQY4El0Glv4K9swMTKnWOGi7WJJVROgjFxhBJ+6b0c2CvFs6lISDdZxRDdiF57zvMSXsnnQOV/phqMVyYkIgtk0vhr3mQg8+agK05RaBIKdJV+6IAYssjOA/6zTTlXz2A476VdQCu66+CoUOn7cE2FKMXrF6rcdpH1RCmDFUy1IqFKExDl9a6smEspr/q/7WULEixwJnnXNdag0FUX7sTswIo2vYpg/LSPxSJoahC7+vxRF5AKtVDWl4sLqBQeI8LNZF3dsMGxXzCbBCkoCrZ3cnSQKdkbTowYsWzOlZXltHWfqJYt2PwtO3tsHc7pUeKaBRpx7b/1dUzZ80fxXIqRAjVWwmeD/AXpLHrUjw5VWQ9Vwr9GcU0dArA348eG9wLtLBHyOXIOedluoVc/RhFR61zHo3fOCY5UIw2teKkpyabxPZTiYPtCCSk8p4/jZMOY3sXGs+F5/X05fhyTRn8pyzW77MVzc36t38L6nTOT8zUxn4qBv3EMatpbsZncg8ob4UOLOakOLWo2XR5T3PnsSdeiAd9amISpqPGgsh38eRGh7/NB5kNiq5nf1zVaZyGNLZmQzCAqyCaKwUuzrvg+YKj3J88Pbf2SbIb3Go=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-40-51Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698572438.823234,
                        "modified": 1698572438.823234,
                        "name": "/dev/shm/incoming/2023_10_29_094015_00_00_K_073.txt",
                        "sha256": "544a4e7d8a7c164bf6c0ebcb2f45a2ea3c81927ed229970b140745073475b439",
                        "size": 2050
                    },
                    {
                        "created": 1698572438.583234,
                        "modified": 1698572438.583234,
                        "name": "/dev/shm/incoming/2023_10_29_094015_00_00_K_073.kml",
                        "sha256": "30a09b4a9064aa8c969215671d3153a234332c138c4d618b1022aeca7afff0b2",
                        "size": 977
                    },
                    {
                        "created": 1698572437.9832342,
                        "modified": 1698572437.9832342,
                        "name": "/dev/shm/incoming/2023_10_29_094015_00_00_K_073.jpg",
                        "sha256": "552c8426ac458c3d30b41e9be9ef6dd15c3a7021a083a625997e4e0899c457a3",
                        "size": 916922
                    },
                    {
                        "created": 1698572437.0132341,
                        "modified": 1698572437.0132341,
                        "name": "/dev/shm/incoming/2023_10_29_094011_00_00_K_072.txt",
                        "sha256": "89d9980eb29da2cfe730aba72aded5dc43fcbae48948523e20a7617acc0cc1d8",
                        "size": 2049
                    },
                    {
                        "created": 1698572436.553234,
                        "modified": 1698572436.553234,
                        "name": "/dev/shm/incoming/2023_10_29_094011_00_00_K_072.kml",
                        "sha256": "7572a19a76bb4f03bd2f8a82ff607144371c5bfb052967050014fc5645c10aee",
                        "size": 974
                    },
                    {
                        "created": 1698572436.323234,
                        "modified": 1698572436.323234,
                        "name": "/dev/shm/incoming/2023_10_29_094011_00_00_K_072.jpg",
                        "sha256": "0c018265ccea7ca7fe46ebe9bc057a520419e50221da52983a06144a3026632b",
                        "size": 880537
                    },
                    {
                        "created": 1698572424.593234,
                        "modified": 1698572424.593234,
                        "name": "/dev/shm/incoming/2023_10_29_094007_00_00_K_071.txt",
                        "sha256": "26b14fc759f3c46e2ca1f17a2060c4dd39ac905c55e37dcc70fedcddb7ad7411",
                        "size": 2050
                    },
                    {
                        "created": 1698572424.423234,
                        "modified": 1698572424.423234,
                        "name": "/dev/shm/incoming/2023_10_29_094007_00_00_K_071.kml",
                        "sha256": "0c3f6ff5d52ec26589077432912d67ef3d47e513f1713c42a976105d49a4e14e",
                        "size": 977
                    },
                    {
                        "created": 1698572424.073234,
                        "modified": 1698572424.073234,
                        "name": "/dev/shm/incoming/2023_10_29_094007_00_00_K_071.jpg",
                        "sha256": "cd9248614ed054dbcccc81cc695ef33a240c44dd1413e4b949fc06b85f24aba0",
                        "size": 1095059
                    },
                    {
                        "created": 1698572423.0232341,
                        "modified": 1698572423.0232341,
                        "name": "/dev/shm/incoming/2023_10_29_094004_00_00_K_070.txt",
                        "sha256": "16680d8988c6195ececb126cc1c02ee7cceba72c1fcdf786ceaff2d593647797",
                        "size": 2050
                    },
                    {
                        "created": 1698572422.323234,
                        "modified": 1698572422.323234,
                        "name": "/dev/shm/incoming/2023_10_29_094004_00_00_K_070.kml",
                        "sha256": "69d9d55847514f10491858fc066c95716c4711599068293aa51a4c85dd2ccdd9",
                        "size": 978
                    },
                    {
                        "created": 1698572422.0032341,
                        "modified": 1698572422.0032341,
                        "name": "/dev/shm/incoming/2023_10_29_094004_00_00_K_070.jpg",
                        "sha256": "63ac0564c97637d1e205f5306860ea1b3477a3983f054ff221dcef7993fb29bc",
                        "size": 1092200
                    },
                    {
                        "created": 1698572387.653234,
                        "modified": 1698572387.653234,
                        "name": "/dev/shm/incoming/2023_10_29_093931_00_00_K_069.txt",
                        "sha256": "75fbf65cdf8c6e7234cc94157d643abe451cea92114cde1a6141691d3069b557",
                        "size": 2044
                    },
                    {
                        "created": 1698572387.5232341,
                        "modified": 1698572387.5232341,
                        "name": "/dev/shm/incoming/2023_10_29_093931_00_00_K_069.kml",
                        "sha256": "a67acd278c3f495fd5341f8b5b258bb8835442a98a67dcad5dc2c2e5d81bb29d",
                        "size": 980
                    },
                    {
                        "created": 1698572387.413234,
                        "modified": 1698572387.413234,
                        "name": "/dev/shm/incoming/2023_10_29_093931_00_00_K_069.jpg",
                        "sha256": "9fc8b132deae080aac84c5228eaa965f6a49a04da49c49bda513465c264db13b",
                        "size": 1061813
                    },
                    {
                        "created": 1698572384.633234,
                        "modified": 1698572384.633234,
                        "name": "/dev/shm/incoming/2023-10-29T09:29:40.ais",
                        "sha256": "dfb670e74ca629c868f2fde9ed3bc6b0c24ea81ea5af874aa01edcd0b87535d6",
                        "size": 148836
                    },
                    {
                        "created": 1698572352.623234,
                        "modified": 1698572352.623234,
                        "name": "/dev/shm/incoming/2023_10_29_093855_00_00_K_068.txt",
                        "sha256": "666ca008870e7c682d639341df7e22bb2efc67adfe0ee43ac0637d5620520cbf",
                        "size": 2050
                    },
                    {
                        "created": 1698572352.0132341,
                        "modified": 1698572352.0132341,
                        "name": "/dev/shm/incoming/2023_10_29_093855_00_00_K_068.kml",
                        "sha256": "47e0e56b4058618e650b61d1283b0097516598ee53616f08fe9268a0d17683df",
                        "size": 978
                    },
                    {
                        "created": 1698572351.623234,
                        "modified": 1698572351.623234,
                        "name": "/dev/shm/incoming/2023_10_29_093855_00_00_K_068.jpg",
                        "sha256": "3e0ce7cab39cea8f9bcb3d8b84168c82ba4da6518b3173aa957564fb0377032d",
                        "size": 968022
                    },
                    {
                        "created": 1698572444.9532342,
                        "modified": 1698572444.9532342,
                        "name": "/dev/shm/incoming/2023-10-29T09:10:44.ts",
                        "sha256": "816862100eae2b8b995fcdc5b4c7d7ebacdd288b7a236a5ff190ee018c9b83ff",
                        "size": 441299168
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "073b3b202387b39be88619f94ded01254c79927270a05afc3dce2e859818b5fb",
                "size": 404756996
            },
            "errors": "",
            "filename": "2023-10-29T09-40-51Z",
            "size": 404756996,
            "verified_at": 1698596111
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698754258,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "PYjjiSnSOrBtXF51xGs2Ph2FiROoZthM7eMdpRB8O9KxRqJaLqdEwCKmSUstoUdARc1w0Bhp2Zu228EtsPji9MIIBKNr19jEDJxYFZP2UQj29M4GElYmFFbH3UuJYM5Ja+y9ox2Vus/llIauNLemlTcv8NVed73/ROic/Q/IEx2n8lpy98xmavq15UrOzmODNclj4UuzZILsg+8noWyjTglM6/zw1J4xS1Z1Wh0hQzNebMqqJLSs3WolGpvKKX0d0j+08jtXu4TaGPbxr+7UcAbBiJNWG8qwz2os+qRz6gNKU5f9l/Ugt0cerZKed8krVmYICdIEskG99h8J8Xc5TG5aEgm7TMtuxcremgoDT7kQACb9ZQdFkeSLm550wNhTGvgGzzWRk/9zFlQs3lFB744dvhbRAmWkDtUQZN1flUqR+gzZZoeUFn7VBgzrAt5fq71GHNKiFef2ecXrPTuGbuZma9Muk5fcE18f57ba4CS0WPSgM+8g/FisUBMYCN28k/3hv9MaI+Xpm4d2hmXviauOrOJV7CsHd67C8YhEDcosr8LfexBGfVN+16VJRUHwtelg4vVfZNW+eK7IwTI+sv8zoN6cFZDRRcSQk0Df5DFSlVncslgzhQ4CIx/qEmesfXfj98gGqE7QIfquOkwSTaRgF0k8k8PEyNDfbV+RwlE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-09-52Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698754162.0614185,
                        "modified": 1698754162.0614185,
                        "name": "/dev/shm/incoming/2023-10-31T11:59:21.ais",
                        "sha256": "ded13025fafbb280b347f1f87ec541216a09b44d775901003be54aba27e3af44",
                        "size": 234126
                    },
                    {
                        "created": 1698754183.5314186,
                        "modified": 1698754183.5314186,
                        "name": "/dev/shm/incoming/2023-10-31T11:39:43.ts",
                        "sha256": "0765866ad4fef9c302bead3af325c87935169f82f4bd2c560010ef618ed63694",
                        "size": 410877008
                    }
                ],
                "mission": "OSP1",
                "sha256": "67f0a0a233af2c054f3bceaaca4ee0ba17360abe7bf89d23a957c4c66592a1cb",
                "size": 396460730
            },
            "errors": "",
            "filename": "2023-10-31T12-09-52Z",
            "size": 396460730,
            "verified_at": 1698766043
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698757869,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "hNd7by+WMLb0OpUnQ3niHSYQSZ1PrOJthn11exwrep9wDcw2EyR9zbe9QnD6FFBAMiDKsrwOqvofHhECLA24qx01ITXIi7it3ayRwF2w0h9ASv22tnkTHHb09RHscisTzsO6Nwc3y5kQkMDcBoEzRg9v9COe90vPOIPWWJU/fwgoJNfYAQkkbKSOwJk0VyhiKf+EPoumGxjJT1HfFI9TGoFOvBNP6+KqCikRS6uWMoRUBWwLNPB6ZMTlQjX+HcWqCY6YldoQWam2JkesTs27SFpN0PlumG+WD3Ufpd8IBqrMSMn0MfaypEZQZLZEONo1e3k+m1BDIjbHD+igKMFqA5OEIlJ6oapZ1szM/OP5ulHJy5fCcxi/ZPhZrsyUB+t8ducXBWu6lOqba67RjtNueYjYt0zN9V0NcUKTO6JJnTAAabgYIyJEznxe0793tKWqyNRz9TbVyxc/1WJihGFEv0RFwiRbDbR2DIbgIKtIIPk4v+4eN//NUeBR4m1GAiC+xyhA9ErIELsPayUhdZoA9JvB4Ix07EdTMtOn3MoTd9PFkRm8VJvHA+BetkWYoIg1RXfzXuCMEeQDL0S4ZuWnxKJwTT5ADlhQzlITVEqPiZL8a5/xav1PjEn+W2QyATzncv7qbW15RcYiPkOfdihVLNKIVWLryhX7aVTe8gVwdig=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T13-10-07Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698757768.2614186,
                        "modified": 1698757768.2614186,
                        "name": "/dev/shm/incoming/2023-10-31T12:59:27.ais",
                        "sha256": "405f5fc042ede4fd5c291b7583ee0bffee5fc11d474b1c26a8401ab1510cec06",
                        "size": 264088
                    },
                    {
                        "created": 1698757796.2314186,
                        "modified": 1698757796.2314186,
                        "name": "/dev/shm/incoming/2023-10-31T12:39:55.ts",
                        "sha256": "efa7e5610beb80a0f39ca097c8c8c7b5f2583ee3c3a9c292fac353de18fc0267",
                        "size": 410740896
                    }
                ],
                "mission": "OSP1",
                "sha256": "ba9b53b25fc26b841b9f7274f50d327c03e2dd2ee5e95de8e37b59c090538d3b",
                "size": 396258040
            },
            "errors": "",
            "filename": "2023-10-31T13-10-07Z",
            "size": 396258040,
            "verified_at": 1698766061
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698574638,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ndyEezFiV9uVO3Hpx9MCZaPXzxYbN/NGGWPft8ee3XX4CCCqKNfK/rg+EsZMu/+0tKDO3h6Qpd2AOTXq+pg9RxoAhOWdviwyTJXO8MpQ/Bnkj3T4Nnf4KlA3hPHKjUx6/M9cbUwln1IuLkKf6RwtmgqgN4lCuq1Ffw29AXJ805b+sF8KtYG5GUa75rs253t3yZ1E0Wa2EtVugpIp0yWH9VA7lUWpso0gGrRMXeRXqpFqhNfxUNB4shfpr9LgLNz6yqx+fOulrybC4OYR4ZH84JnB4OQ9568JFnQkBgCOUBSzJVLDMhD3eGIhMQg9wPj9VypPG8HmC3Wa80D0q3sXm8qLowljt3ZOZh/9/XhGMtsWNkQJ5waZ8rCoAXsWKKzmcDFpFOsQV8zqs6ZNRXUXxtKLstQ3x0F+XUEzVq1I4ngVW+BKvAlYNSeUwXv0B7/m3WAQ0wvWDMndorCmEq0sPbabgeR4g+RDkYwBbOqlh6Nk1jDdbZxzc5Js3qu0NIM39ZObGO6rhckx/f7TtGA63ABMjxjvS3c8EmuQan09aEx8gFdL5EVuPD0aprbtTqfOyXWQbjYJU1n5+07ucB0kcLIwwwUskyxrOZjqQsuulGVTd9zutJim7x8FJ06e0nDEU2wDNDKmM+kNQvENSOyCFc0vhGu0JCJNzc4JzvagYl8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T10-16-20Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698574573.2009401,
                        "modified": 1698574573.2009401,
                        "name": "/dev/shm/incoming/2023-10-29T09:46:10.ts",
                        "sha256": "0b9479d17e7b3e2866bc2b10eb3fcc1faacee1abecaaaef13ccd521254f8412a",
                        "size": 387329256
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "7e0164993e17c62976909a93d5055533aedeba0b5ac572e4d7cf50ddee5b7bec",
                "size": 377540700
            },
            "errors": "",
            "filename": "2023-10-29T10-16-20Z",
            "size": 377540700,
            "verified_at": 1698576744
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698575597,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "wd4GzaMwPxVoNQg2wF+cBYD6zEtY2rvz07qNS2kTDBlxaJX2wAzkXbUvq+baFC/aSlig6bcfynKWzGbvtkx/hQ028rzDsN37OHCJZHVjA7Yueqa8E5HMzvyUXWDO1veZSikFfJntzAHrHOfpbWmKbAaaxwyM55jJ1FROk7Gs2dyqpD5snvunBPUq3Oy++Ih0n91rRZd11LvpQjXyTYAsAVMGIugmQaKzYhJbEDWXzf4PoQ7DPRtyRvBAQ3ON/1O/05YfR6NOsAdfOJ1J6B877LNOVmGMJmwrN3UClTJ7Y3JiTLGGIt67McpuHh1v1cvfI3COR06qwH5GzRRqiHeDfTLo4Ov/bnM/CLHOyXsd0z48ojjXQh9vQbl3N5OtbZs8Mccr5v24jrRR3sRaYtRcKP3EEhaQgIIjEoIph9MgE1YRmUCzeXdBmbd3vilQ8qQa1wy+b6sgx89VesnoCTa8EDx6KZQ7Z64wvj/f325bxuyWAjldWim0oAvTsTKpP0W2FtZ8T96u7taPz8i81WDCJNKQTmBKOkvw4N5leV32G/AXw8/Ndb0arPc0bFhJNE84sw11HUI9W60bL8gXGNFkL0XM/Q7JECrHf7w4yUir2GZNrt+RrvNWpVlpBUkgTLbPYUGDctQSLVnaAfAdDYZf6LQhLFUPg9vvLa/lYFqPjpU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T10-32-14Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698575408.593234,
                        "modified": 1698575408.593234,
                        "name": "/dev/shm/incoming/2023-10-29T10:19:59.ais",
                        "sha256": "d02dfb6f4aef94b680e0c22bed991d892d7ca74039f8fa51111ed9ce0210ba2b",
                        "size": 236145
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "44d91d370c218dcebfaf40894a16f48c2803358847eda09fd2d8d963a68f1cd5",
                "size": 70059
            },
            "errors": "",
            "filename": "2023-10-29T10-32-14Z",
            "size": 70059,
            "verified_at": 1698596034
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698576171,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "EddUbtsf1ZxHkpvkF4LnOKj1jAhXdx74D1MKHZk/PS2dCQTN0NiXv6ByzR8MUPfn7SRgORMTMkq9T0RJodSiJIEHVX1bn4Z/EZ68Ze3rNMmTZhJzSkbr8cIStyGQuU74P5zt1vqgO1WUncQQp46Z+RC9DJELFwyQuKqK6HDXc8mCzr6aGx57I7W7Crb6dWTiHjXV/Mm2Q6gl71+aghtVUtz6/vjB/BtVzUMjuZqkMvJewh9tvrPbwofvOhEYQT/OOGAz35OSB0UhEYaMs1IVjsb2NznTvktTV+ZoUu51lHKn4t3kH5C5PQMVJCxi7na1owQBDNSrBu/R+y5j5Y3xSXE//14A9o/0Ecy33SQj0+uo40KaxzRF/op4uWih+F6XdiUpOFT92GOpqUoz/DLgdqfdsVyS4zak+l8LjtyvRJCfixphREU+1fleJqzMryQK0qafPEs4a82efAB/VZImLdqeL8dekV8SxEbs8TVlhDTDWkxZnOAd2AHhILG8yVMyM2GBGHNZzbZVscLE4kVlyx0NCIDyRv3T6vn1a3lJcwOg4FqbaiFS9zLe8CLaPVegZ4zeEQ/3qB6fMz9qfc2njF2+KocSFHyF+nBhqKKA7b2KvvHVp4DFNLYgn3fQaSj1DckbNAeQmocBnUWD6ppxwS93NX/qZQeRQhOr5c68XyA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T10-41-06Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698576011.4832342,
                        "modified": 1698576011.4832342,
                        "name": "/dev/shm/incoming/2023-10-29T10:30:08.ais",
                        "sha256": "fb016885494e8a9276815fd26a2b6471294c43b1846758482bd0987f92d1371b",
                        "size": 151352
                    },
                    {
                        "created": 1698576058.113234,
                        "modified": 1698576058.113234,
                        "name": "/dev/shm/incoming/2023-10-29T10:10:57.ts",
                        "sha256": "c7d7a5ccbf83803162c6a9aeb3b6666b3ac2e5653e8f5d2736128ff114d51572",
                        "size": 463895828
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "3a580096a797c7cf073c71fdd83fca3b262001b098741a98da5e2dd0c8a9d50d",
                "size": 420652895
            },
            "errors": "",
            "filename": "2023-10-29T10-41-06Z",
            "size": 420652895,
            "verified_at": 1698596589
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698573329,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "vGkU8JQjykl+3xsovlkdUQa4iTzD+TT4XJAUHkpCFKmNyuZBuZa9kBh9VhMr1hPXgsPCb+89dEMoZThF0kh+BDAvCkQFLz5HZoS/DT6G/dfE4I6bZcJthAyMTJY/ysJaDrgpl32b/7oy1XdbohI4scmRQU8uS+WQSGfQk3OFcPgu43h1pv9F2oyD0Eq6X/WhEfkNa46K3/2TKdXM8deMYj6tBOo4Mf8v+qg7L3j+p9Lurlq8JGsqM83HGycJgXhNS5PJtJp51HjoyA3BxQYnxM0NdWGtE1ad0FdB6qV/y8T0hHhaTpvVqun5/8OLCvhNAXtw5QymDasbDI7B70rT9kK0sCduJNqPtdWysZ/cjkydptpu5rlDnmnXDrnJf0icJbVFP4tt5XCII2D7DPUCEfcL8rO1xmcVT/zZNcfwYXF32ytBT3ane3GCV2pu3+zeCCoaq/k9zMOv2REMvZpTmZxmYimzLavUfz6AoUyLDPiEixdeypVYGZgqPJtnYiTSupaz698PtzKIOSLKvdoag19Fd5/2lV9DG2YTpaImoTODvcqNxJHLQY5wO4JW1bxfu9csdGhMMgx1tXUv7ExocTkSaI0xT8Spj4a94ZLCty0VdScPGTQHMMj4ANnPz99RR3kDb4gQ3LFwbDV8gsVsSpoCYbBOxW/2vSKYqievmEE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-55-02Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698573174.7809403,
                        "modified": 1698573174.7809403,
                        "name": "/dev/shm/incoming/2023-10-29T09:42:52.ais",
                        "sha256": "1a731e8dd55b7c8f7321305c4251a7d7e8fde8ce4ef33b6bda06495f7da6ec3d",
                        "size": 259519
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "9935bd80efece01277e89a87eebb479f6de1aa92a463f3c0f6c80442544883fe",
                "size": 70882
            },
            "errors": "",
            "filename": "2023-10-29T09-55-02Z",
            "size": 70882,
            "verified_at": 1698576465
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698574526,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "C6ym/MqpHu/h2v3PusoA/xqIQyIoX4VBFjnw3plpfkSegpuUkMeNihMg7pxrF3wlYCz31HAJV0OdaedcoXIepJM8wYhGZPIbQdqhsciU5ztdrT8ZVKjDeRaDtRlGo2DHMPJ0anCL5SqgchPra2uX2avkKU8/zAhFBBbEWTYSDjIM+TOuqHlq2CTg6fBHTspNWdP093MoaoA2aQs10Um6o4K7MBix5s9vnuhKQutgcluHrJez3LA+3JCRbAeNjblC9yOP4qYDtbLgJKJlg3vyCkVUo7qWgPYZxS3Oek/HXKO+AGKtjB6OZ/a3/JXIhNtSOS8+XAo0yUMCYXzuHVuuDaRaWKPcxpxon7UcrqjFIGoZUdIwQt1EkSGuiJNie6G2rRhnpfmdUIqdBHiMRWIyntlamXwG79vDPgQZWtdQqV9Jl1Ylbyw6pUWn8mnXeKqe3+qmoMB6weazzex/NDSz0q+fyhSuVEPriftHhMUAdPWv3zMcoE5Et8bFDEs8kTMjQqG9TtcXGBLtOQaMY3+O+DVcjfyA+Xx6wqY2GfEL2Ng16R4xXmgK9W0EvTjCgfBb5vnQNi6xMD3a8pMksgdZlSlDrudXyRSr13IIlCTRh+rHXwt89bODwGyPdzx1QrcfdwH90Z29cCYVyNFfoeH6pfISwhHnmV68EbyM0Wj8p1g=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T10-15-04Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698574378.75094,
                        "modified": 1698574378.75094,
                        "name": "/dev/shm/incoming/2023-10-29T10:02:57.ais",
                        "sha256": "ae3f16ca480ac3733e6c05131967cea1b6755e7bf911003da94b718f606ce938",
                        "size": 451384
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "c4b5b1f91ab483239c246cd7bc546745c5a686eb3c2ada034e6bc86f693bb14d",
                "size": 131326
            },
            "errors": "",
            "filename": "2023-10-29T10-15-04Z",
            "size": 131326,
            "verified_at": 1698576496
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698748465,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "iVS6Pzpc8okSYSH+Qp6SiR1eh+HH8F5sc6On87I68AotvSmbRefsJHH0kBvQReMUGZYgPdX/S156WUJ0YL9MSdTqD9L2GTW6Ew8D65nJyc0rkbtMGGeBbUMivIFEPGYELMwyU4+UQLC5o0GdovhncA+PtEDAt1kA4ymztEN4t84ExYKiMYp1kW1K//uq4koG3XczkjcrdJVrEPdLbrJFg8+xpwe6ob5eYAFTjq502O9k+iVMMqIf/vb+DQ9acCO1U5UiRH3E9Mu+kUt8/1JXeQKIiWp3Peku4lx7x4LC2F8BDFdSAq+AMU6QI4oFQG9G3IAQgDHPLwWSJEt35XEKNISDwAArxFq6NDoEDBDrgLLIHttMUMhzuGE+ldvnchEFwRPezvzKy/3rXQEHjodSCFqNT476CbZa8JcFEcFOdBWeDfGEOj91jFCoksePxA7yScHjPb5ympUqvhHBcGNiAa3lrECSaVUNI9TXSu18xSbPrOg9QpjZz7jarME18sfcCTH9wwFMzFhCChv/3leR4FhZhUttEp+cHI1hE6CV1lD0Xy5lno5ME9lbqOM2i2O2q7eW3rrGOVtpSRhTy8XRme4Srs0wn4oWQiHPO+gUlfSzgpWpoIboDbbqXrEaXQfO0CXp4DiFg5Mz2q9E4Nxm1PY5ZEbNC6rkfZEtVMNfYp0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-34-13Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698748327.7314186,
                        "modified": 1698748327.7314186,
                        "name": "/dev/shm/incoming/2023_10_31_103153_00_01_V_056.txt",
                        "sha256": "c7a8684c09f3d71ff4ec40bcbd5aa9cb31541743f2de0bc05c27d3a2c3ad2806",
                        "size": 2058
                    },
                    {
                        "created": 1698748327.5014186,
                        "modified": 1698748327.5014186,
                        "name": "/dev/shm/incoming/2023_10_31_103153_00_01_V_056.kml",
                        "sha256": "2444a39b4561b32fa25471c50d3b056e93e2a5a7506c149958e4a16af24429cb",
                        "size": 953
                    },
                    {
                        "created": 1698748326.9714186,
                        "modified": 1698748326.9714186,
                        "name": "/dev/shm/incoming/2023_10_31_103153_00_01_V_056.jpg",
                        "sha256": "1d69dfc563b04dcb5ebb9c57f2252543f195c9de038dce6af009d294510be66c",
                        "size": 1397155
                    }
                ],
                "mission": "OSP1",
                "sha256": "d3aad908b2ce070c414461979ed037c317b796a7462cfa9109cce7c74e28ab45",
                "size": 1398969
            },
            "errors": "",
            "filename": "2023-10-31T10-34-13Z",
            "size": 1398969,
            "verified_at": 1698766065
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698555182,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "s7NDkEZGR1zhegEk/XKwsIH/JEAeglO3yp1JvnpvPeCwJgmf8bpwwvFvjnYbBwPS6TXz3sFeheElNIvQLViHMaXbQCoedQGzRCGny6Bs/G1wTSp2URH4Up1Qu9mlm3KWSaazsADbQx9W9LFGn/l4NwX9sonpIDdwe/lhePHzX9M6jEBPzANsU/8SuLJbbobAh/qHXEyyL9m0g2StSLlInPNeOSTmGJyRWm3LAVbd4YqcUBq5ZgctqXgWwLJbo83j9aSRKoLah49Wx+s3ayxoeW9Q+RQVIcGDYXyfr5hVi99OyLdytnlYT2jWmGStiuD6btXwwkrz3fZH0OUkEYhB3mqJ3Ts0jyh0CQB8ZTj33D0D0ANF9d4hE1nt7VkNu8Z1z0RdF9ldAiFHzPpDOKqH0BK1WLWye0uHRMFnIFkMFnLP8HhnetTJQhQ94GD+DhdnsrwuMpiNQ7IrPm58LK1jf0nbE/+EgEExvN5gVGB4WEtcpZG6qojE195iQN+xtoMHgbcMI1w9LJiR5W09p3m7RxhLK5uIm7oCyadTnWEeyqtfsVWyMBS7EyIu9tgBZI7qkzf3bMpvv6hhBoD+JUxAj/j8+qGHEgmPtUhzQ1lzhsbEBX7ZXo4z9623KPduRjKifJtIR8qzVC+wvDpEJTezFUfBRZB96nOOwQRSi6MRFuY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T04-49-01Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698554818.0109403,
                        "modified": 1698554818.0109403,
                        "name": "/dev/shm/incoming/2023-10-29T04:42:43.ts",
                        "sha256": "ca7775dce67bd34c9072f2ab5a5337ba62d14de24e72ee0153277b9c37a7dfd9",
                        "size": 14258108
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "f486a5012326922aa16641b1926e39fb0e8b56a6487d9954f5bbe8f4082721c2",
                "size": 13674380
            },
            "errors": "",
            "filename": "2023-10-29T04-49-01Z",
            "size": 13674380,
            "verified_at": 1698576800
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698555832,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "WKlIk9+cPpwPIsxYzoCLNtIsYajqBuudx6UR8OPO+aIyz6krIsB6GzBHkmv+6EMz5l9g+DTzWQsu4hnJOSBq+5224jmkxft595S13K7MYL9S3tduLNkdHYrHwoTq8oi2vOHKkyXrXMofdJtunPuboUQuokEQOyqqviKPTnI1b9xa7RkHHBxe9IE0OWse1XKyY6tlmd1Uq2dpsOMl+heOx6slviRl8aahQkfCGp1sGaHn+ReGq754ACLRZci/55I2TQ5v8mED6KPZwn3rsRnIzxq8Xt8YljHLpjAVQ2EneOnIEyK5pAhZcqcq+pncUxvqvBi6PiXR2dxcjF4Q+Y2+TXD1TiXKTXH+7VfgrFBRjsagv9dCdiwx57umauXXpfxwtjfWM6NdoGH+rXhh1f3C0joIjRXShBZCrEjGpR2NVYvlua2CKQgN7y2N+EmrGCbemVvNpBZ/xCMACX5M751qPDC84hEujf+WGpRZz4GNVlJaRKTWxMPK2RtcE1k8CIOOjQXUmtPSYs6jXPAsddy8DqKfkol9YZ3Se7fncAMlUPo9uiI8NZmAw8HsLXRCxoFpCmP0rZOT8w+X6KW5Y6HHsuQQufzbLPn9xjPU4QOz24vdiSQDpCNY70Ih4BMEoICN7J2MmOSPgRW+sLIUXzMs0pQHfnXOaYJYS1B1ivlnI3c=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-03-27Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698555683.7809403,
                        "modified": 1698555683.7809403,
                        "name": "/dev/shm/incoming/2023-10-29T04:51:16.ais",
                        "sha256": "1769f3598d93e515ddec87eacc2eb3e6cda43f28a460a42e391edd8810985ddd",
                        "size": 7422
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "5eb135d2cb633b62d459846af49ff2f0d5967cb4c7fa0b503b36ead42862dda4",
                "size": 1629
            },
            "errors": "",
            "filename": "2023-10-29T05-03-27Z",
            "size": 1629,
            "verified_at": 1698576806
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698556442,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "K6k3L5Q5Y8tMzK+5hdgNCipUqV56pgeIczKDBJPovfrhlXcsiVwqBxhEJiUUsf0itOdP1W1/fuA121L4hVwwPVdtyPcy0jPlH9D4ktqBGv9muSzBvyN5PK3l418QA285W0W6l7NQ5zVNqEIpAekcvZI+/MR/jaJErrDBzctxfoG/ZFtQK+ZSmmpQGGGoTawW0lbFLT2J0JFlbPmxcdoKUrwmPDx/jegyMWLy0Qcr3T8aAzYm3L267mCu9MsZOYCA3gaWckbZUMb6dT5p62UDr3SbbBq4cZxGPrYwqgOyE6xOSdBLuRRWZzdclHx8qTVUcEX4X14XB7c55FCxon0pCTsAlR59hYaa1sH6VqQZgvx2sJt+dGW4wPbNC+Nvi6BTXc67+FyM3K/WOPaSsZZtZT1+AmiKwbsEO6b7oiNQsydbciSc5ACYnLe9iwfYNfyL3nt+DK0b5ClcYvNbSOV0qmDnXumIlxa48k7WDkMIs/SN9AALoTTmeHGZO1IvlPNtUdJHHostLgXl814icRlR902EkN4G+QAnDnPYDcEGHQhkqE+sdGbCjRwyNqu5ootcAYIyN+g55g1ghWT0Df3mjN5mV0EB5MpHQnyqyslK3gVDWOUSm3OSWVm16rv+bU3IbefjOz8+HrQ7W9sBhRzijh6J1dyjcjEZ4Bl1Avazp9o=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-13-40Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698556294.7009401,
                        "modified": 1698556294.7009401,
                        "name": "/dev/shm/incoming/2023-10-29T05:01:23.ais",
                        "sha256": "9c588cf1c84ccecc1f1345a24e64b2c015b06c0d549f7049209dffc6850b7a99",
                        "size": 7373
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "7ba77ed0e590947f71bb73bf1926ff217bb0d30347effeca3caec4c4eae19c08",
                "size": 1622
            },
            "errors": "",
            "filename": "2023-10-29T05-13-40Z",
            "size": 1622,
            "verified_at": 1698577329
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698556565,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "KsCrmRWIX6T0HBGZ2SFLjK2ykM8ih5DppuOlPOc4Qsr6lCuniInIPzylEWY4N03Bto9qwY2J0kVa5sNJjpZEq7kX755mlsU0/aE4M9dadpwX6kKg/q7JF5arLTPNDftOVwryr8KTA+DfBLYaQC/MWUGFX7nkVsJYUcC8oTS+zihVwcJwUfI7wabiWfrCR8apvg7Bu7OdsXfaoLgg5kagAh2xkB4I8rJZQYNHbRkRKKur3DZpVa93odrswYkujnzbOf8Flyt+HEY0fdcxzhoFJdAPFnYXWqZbQclNYeXe0o7vPVUkiyA4gIt1bxBH+9fJti+xB+OBowtC9QgFiVc+TeNKnvunYGEnz5qtvqfmFfJDQYIN5jlpNAEJrSvQflMHxMU02Pog/+kaCMUHeFVLFthaEmyBPtTkKWRZvHjR7v7r4r7M7ZY3R/vBFrIJb8j8S26DiaoQ6zccCpcdb5CITTsUEyaAVE26ivu0bmFvUZPH3Nqj65uefoFDk7/QPdrUyNvkwWokxZeatg+9z0ivGQGaJbtkcoyDrpXPaZsvdXMLgw33VcThvsaT/YNpp7BwE4YkwQWsyBngfN/P88UCRGqqbAABuApikCnn7GPLn2tl0elbBRkGCk9XPkTzjXr68dtdVzo21W3FDVE0CCLSA2fGImS1mYBdBAnUJMH6AHc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-15-26Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698556519.99094,
                        "modified": 1698556519.99094,
                        "name": "/dev/shm/incoming/2023-10-29T05:09:26.ts",
                        "sha256": "a826efe1e9eabc8ab2aae455fef05e797bc40b454bbba78539abe0417c3c1c20",
                        "size": 213147068
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "19ffc6921358555205502490b61683d90014ebf9af6850f135d47fe65f627daf",
                "size": 209944018
            },
            "errors": "",
            "filename": "2023-10-29T05-15-26Z",
            "size": 209944018,
            "verified_at": 1698577425
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698557155,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "UVctzi2jLqj+hTiccsJWzowRqo2gS2KAwHxNYhWdeOEr2ehNyVLKiZjEJH32VQ4dKu/TgCbAA2lav7YyJflImgt8hx0LA38H+k/N/PLkGyA81R4zdav4H37EE5nd9DxkWmdQjR9oD8PwhUssDhVef+cRML40ZWY8H8D0AoPYVcPRtQ335C9IHbqlHweHe+FSpaPZdoHGnHGUbJD80rjPZxmVqBBjC2Xk4idXBiKv1vjJZtfzpoTqdACD+DuEHSEcMQUi9ZAgfgRVEeRzdQCvOYINWMjPHgmQXk+2DxTmQaojk9hMggkL2ztfolRYmsFkDFGfqfAbUAUJyXjksLjeuoeE6DD21AYNGMy6V75c5VUkgI0HScmokfx7/JSmml62amUCVesQOAhFB7aQIglEIKhUZUTjcmSMYHUK/CZmHxOsjgDwYmaii1sZ7aal9B3ms2WQIpk7T9Q9wkO/sTkSW4XdwNKqxoi9hSiElv5LFWmIgUfw7u24uLgenH+0zEHSh0WMwmBu/WYrAeCFlWtmI/8vooJLlrNCalelfVfDtCFZ3w4ra48/v2a/224bLjU1DlUotVUH1QmiWR6KGgTKoNMgrLTUIHVDf/WbynsJIc9A2ELJR4j3EJ/THdXoIaMuzkRrb8pxO0NOcDC7pP1uLQdNfOX/S0aCoHInYKXGxMw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-25-28Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698556895.1109402,
                        "modified": 1698556895.1109402,
                        "name": "/dev/shm/incoming/2023-10-29T05:11:34.ais",
                        "sha256": "7e86088a07ed092cb4e70163b8178da1c87617b23b2b860eb81b64ad5546a653",
                        "size": 400321
                    },
                    {
                        "created": 1698557002.1809402,
                        "modified": 1698557002.1809402,
                        "name": "/dev/shm/incoming/2023-10-29T05:19:24.ts",
                        "sha256": "67ce25f70d10adc8a8b2234d6a9d3677ddc5a33a17f90812348a5fedfcec5a5a",
                        "size": 42334404
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "cd96787c025a90651ac579314832fe8213efe75f9a4a64c1d1dd9f174e3986ec",
                "size": 40319129
            },
            "errors": "",
            "filename": "2023-10-29T05-25-28Z",
            "size": 40319129,
            "verified_at": 1698577563
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698577688,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "SKBLjYLe7SwL2g8mmTdTxcrSgJ/MpN5HKKgt3oAq2He7psP2ixUpY00IrSREf49DbT14dJF3gQzphLFeeFv5m2gYqzotkNf9VXu1tdQ4lbS0JP7tyVlWTpAdx125zNUWOiNl26MXeIpWh3JSWIHKX7nGY0V8c4WrOrFG1iAPV5JfI5JxI29CRxp1+K1A+vcVExZmr2mdZPsXVkvyQyexW2+fpTT8FQd+Oflvp83f6PvfKCoLp5VbOnaWreZkzsZ+b6eNYlrVLe3PNji7kZGPxOf+P3tKt3cExV3tAhBx3+RxduRMTz36R/6NJHyvwMu68POUIMHkcF7M7OBujr371+FMZtyDPzQuTIpVBwQLKSadEowWJFGxUJM7CcURyjYW22fOVTJNdNhNlIeSPy9MXJDan2BobbR534/6kYxBA4J+gtJXAkdv/5XksksvoEhDs2KOuXi7ByCaHGqnyvH95AR88I5tY9nKe2m5YXioszB9DA02XlP27e6RKV6+IYXtABZCnmUS69IguXKlcv1vMsszc7PbLIkDx6bCikq5R4Fgl0u5HfHZRizqJn7GIa6dy4z5BFY28K1SkTBK8Ty85LYHrhWJwuOZh5z0fNT/fCN0UljcJDBJzahAXG0jYmbAO0cEoODGdNKj0GI3GoUFAsUD1w4mMRoX2mk7mYUquhY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T11-07-35Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698577532.2432342,
                        "modified": 1698577532.2432342,
                        "name": "/dev/shm/incoming/2023_10_29_110506_00_00_K_102.txt",
                        "sha256": "ac2995b642cca1dd891a90e92d30d0d131ec0c333041075bd3aaa6fa13077166",
                        "size": 2050
                    },
                    {
                        "created": 1698577532.123234,
                        "modified": 1698577532.123234,
                        "name": "/dev/shm/incoming/2023_10_29_110506_00_00_K_102.kml",
                        "sha256": "8c4edc3f8d70274ed8f60b5518e917927b3b42377023381f5e3912ed1813e388",
                        "size": 977
                    },
                    {
                        "created": 1698577531.9532342,
                        "modified": 1698577531.9532342,
                        "name": "/dev/shm/incoming/2023_10_29_110506_00_00_K_102.jpg",
                        "sha256": "a017fb3d19ee9cc6b94f40cb6dfa9a643e1d8182ba143fa49f977a5615c114d0",
                        "size": 663320
                    },
                    {
                        "created": 1698577519.7732341,
                        "modified": 1698577519.7732341,
                        "name": "/dev/shm/incoming/2023_10_29_110454_00_00_K_101.txt",
                        "sha256": "33517d21dae71a726c6971e921c208dd98306590d4dc42e1e5d15f5514a72b43",
                        "size": 2050
                    },
                    {
                        "created": 1698577518.9732342,
                        "modified": 1698577518.9732342,
                        "name": "/dev/shm/incoming/2023_10_29_110454_00_00_K_101.kml",
                        "sha256": "507cadb0e4b76e0e4c9a689377aae63fe4ebf26c38b897293849fdaad6e9143f",
                        "size": 976
                    },
                    {
                        "created": 1698577518.7332342,
                        "modified": 1698577518.7332342,
                        "name": "/dev/shm/incoming/2023_10_29_110454_00_00_K_101.jpg",
                        "sha256": "bd888eb7fae2c830665c4b42a545712d613fd522525a633bf82760e794e9b1d4",
                        "size": 889060
                    },
                    {
                        "created": 1698577516.903234,
                        "modified": 1698577516.903234,
                        "name": "/dev/shm/incoming/2023_10_29_110450_00_00_K_100.txt",
                        "sha256": "a544f8dfbe4c198e31c4033bf1cca0a0168ec81ce1031e4134c6878973451e2e",
                        "size": 2050
                    },
                    {
                        "created": 1698577516.7332342,
                        "modified": 1698577516.7332342,
                        "name": "/dev/shm/incoming/2023_10_29_110450_00_00_K_100.kml",
                        "sha256": "1e6128f61c517d8254a6be23227954c65a3d99106ad52aeda5bf8abb159beaf2",
                        "size": 976
                    },
                    {
                        "created": 1698577516.653234,
                        "modified": 1698577516.653234,
                        "name": "/dev/shm/incoming/2023_10_29_110450_00_00_K_100.jpg",
                        "sha256": "3a09b84d6e702575e0ba045f453fc85001c6d70e59de4889c2fe9e1cbe6bff81",
                        "size": 887185
                    },
                    {
                        "created": 1698577504.603234,
                        "modified": 1698577504.603234,
                        "name": "/dev/shm/incoming/2023_10_29_110446_00_00_K_099.txt",
                        "sha256": "ba4825bf18912b98ca53065372589bcf3e0180e7344a0df3209e2ed908639915",
                        "size": 2049
                    },
                    {
                        "created": 1698577504.403234,
                        "modified": 1698577504.403234,
                        "name": "/dev/shm/incoming/2023_10_29_110446_00_00_K_099.kml",
                        "sha256": "637d2bcbf5b24d60a009af5ec44d5d82df6f0acd6e8fa9274944f3449cd51df8",
                        "size": 981
                    },
                    {
                        "created": 1698577504.103234,
                        "modified": 1698577504.103234,
                        "name": "/dev/shm/incoming/2023_10_29_110446_00_00_K_099.jpg",
                        "sha256": "13b7583787f5cb0d9797d2858c61ede9803ec5dc7d22d7e5feddf011abb22701",
                        "size": 847579
                    },
                    {
                        "created": 1698577503.053234,
                        "modified": 1698577503.053234,
                        "name": "/dev/shm/incoming/2023_10_29_110442_00_00_K_098.txt",
                        "sha256": "5fc64fe0d8fc261bfa37cd18aee39f2445e94ce75300fcd02c4b51c7db230a7a",
                        "size": 2050
                    },
                    {
                        "created": 1698577502.593234,
                        "modified": 1698577502.593234,
                        "name": "/dev/shm/incoming/2023_10_29_110442_00_00_K_098.kml",
                        "sha256": "d346e720b33ca770ac4cd273d4d6feb843dbc44ae06cd081f93563fcab8153c7",
                        "size": 980
                    },
                    {
                        "created": 1698577502.363234,
                        "modified": 1698577502.363234,
                        "name": "/dev/shm/incoming/2023_10_29_110442_00_00_K_098.jpg",
                        "sha256": "7c0e2ae5fe07582cdbe19f6e8186afd352d16ea852897def692eab41d873ed67",
                        "size": 819774
                    },
                    {
                        "created": 1698577490.913234,
                        "modified": 1698577490.913234,
                        "name": "/dev/shm/incoming/2023_10_29_110429_00_00_K_097.txt",
                        "sha256": "5f9f86e9f0086dfdfbd1225478edaf3efbddf0f6a22ac6bf9637608cb20f5ba3",
                        "size": 2049
                    },
                    {
                        "created": 1698577490.2432342,
                        "modified": 1698577490.2432342,
                        "name": "/dev/shm/incoming/2023_10_29_110429_00_00_K_097.kml",
                        "sha256": "334455c59ccff861a798018468d31764454574e1c82afbf58fb49ecfb14f7005",
                        "size": 977
                    },
                    {
                        "created": 1698577490.163234,
                        "modified": 1698577490.163234,
                        "name": "/dev/shm/incoming/2023_10_29_110429_00_00_K_097.jpg",
                        "sha256": "2abb3ed2026a0456aeba701a70d72b8ebc47a91d30ba23c0568acbaa574471bb",
                        "size": 834615
                    },
                    {
                        "created": 1698577489.1932342,
                        "modified": 1698577489.1932342,
                        "name": "/dev/shm/incoming/2023_10_29_110424_00_00_K_096.txt",
                        "sha256": "51d940c492d85e25903d2276f5436700aa0c186b80bb6f3fc64edd736e5d335d",
                        "size": 2049
                    },
                    {
                        "created": 1698577489.103234,
                        "modified": 1698577489.103234,
                        "name": "/dev/shm/incoming/2023_10_29_110424_00_00_K_096.kml",
                        "sha256": "7668952a7374029a5833afd909d1694999a845c1ae779f41c2bb241d295b26a1",
                        "size": 976
                    },
                    {
                        "created": 1698577488.7532341,
                        "modified": 1698577488.7532341,
                        "name": "/dev/shm/incoming/2023_10_29_110424_00_00_K_096.jpg",
                        "sha256": "f78a0f7888cfa55fc6d4cb53a881b5b1207904c2785722f2ddeb6992506d48e9",
                        "size": 837145
                    },
                    {
                        "created": 1698577466.0332341,
                        "modified": 1698577466.0332341,
                        "name": "/dev/shm/incoming/2023_10_29_110403_00_00_K_095.txt",
                        "sha256": "6dd5d121ebeb8415caa070b82b24ca41f040fba51d0f087b4843ca37b0943da7",
                        "size": 2049
                    },
                    {
                        "created": 1698577465.7032342,
                        "modified": 1698577465.7032342,
                        "name": "/dev/shm/incoming/2023_10_29_110403_00_00_K_095.kml",
                        "sha256": "dd769215e0493db38502d2d8901e941eb9868185619f636dd95e4f384ba88778",
                        "size": 978
                    },
                    {
                        "created": 1698577465.573234,
                        "modified": 1698577465.573234,
                        "name": "/dev/shm/incoming/2023_10_29_110403_00_00_K_095.jpg",
                        "sha256": "8cddafe477bdf4c57a6fa4fd78eaa80cc58bf17aa4df579da68bfc61ced43b32",
                        "size": 871845
                    },
                    {
                        "created": 1698577453.893234,
                        "modified": 1698577453.893234,
                        "name": "/dev/shm/incoming/2023_10_29_110354_00_00_K_094.txt",
                        "sha256": "39e12425b37b79844ba541462446d3ea01a7d66e7b7a6ceb7443dec930e7086c",
                        "size": 2046
                    },
                    {
                        "created": 1698577453.4332342,
                        "modified": 1698577453.4332342,
                        "name": "/dev/shm/incoming/2023_10_29_110354_00_00_K_094.kml",
                        "sha256": "710da45bdf12466422082f80a3d74e941004c50f849efeab18e70f173ddb1977",
                        "size": 977
                    },
                    {
                        "created": 1698577453.2132342,
                        "modified": 1698577453.2132342,
                        "name": "/dev/shm/incoming/2023_10_29_110354_00_00_K_094.jpg",
                        "sha256": "0c54c4db571b38b0a88df69e305b329e57c36785b5681e4c1952d52432dba6e7",
                        "size": 823173
                    },
                    {
                        "created": 1698577388.613234,
                        "modified": 1698577388.613234,
                        "name": "/dev/shm/incoming/2023_10_29_110245_00_00_K_093.txt",
                        "sha256": "642ad42b2e3745513c7a153eeb1a22ac8cffce0db16b50d2a2c384772c306f97",
                        "size": 2050
                    },
                    {
                        "created": 1698577388.2832341,
                        "modified": 1698577388.2832341,
                        "name": "/dev/shm/incoming/2023_10_29_110245_00_00_K_093.kml",
                        "sha256": "0599c3361eae3a493df64434d933307e8452ed469107b12fa4e56b19f421ce9d",
                        "size": 973
                    },
                    {
                        "created": 1698577387.7432342,
                        "modified": 1698577387.7432342,
                        "name": "/dev/shm/incoming/2023_10_29_110245_00_00_K_093.jpg",
                        "sha256": "4aeedcba3bada0f92d8c0f42d381dc1940f12d5eb7e98eb8ed440b4b055cfec0",
                        "size": 1015903
                    },
                    {
                        "created": 1698577376.613234,
                        "modified": 1698577376.613234,
                        "name": "/dev/shm/incoming/2023_10_29_110234_00_00_K_092.txt",
                        "sha256": "4c66da5a2b82d860822330ab4fb781433991f06ca194da491febd2d92aa07051",
                        "size": 2050
                    },
                    {
                        "created": 1698577376.4632342,
                        "modified": 1698577376.4632342,
                        "name": "/dev/shm/incoming/2023_10_29_110234_00_00_K_092.kml",
                        "sha256": "3832419a62e343481d623b191dd39734f1aa10a62ba2d8e478d9348f8442b307",
                        "size": 977
                    },
                    {
                        "created": 1698577376.373234,
                        "modified": 1698577376.373234,
                        "name": "/dev/shm/incoming/2023_10_29_110234_00_00_K_092.jpg",
                        "sha256": "2db70c1da1376c9d18d17fb0a42df2c4f9fcfd02c0dd2d952b7b166108c40b53",
                        "size": 941240
                    },
                    {
                        "created": 1698577332.823234,
                        "modified": 1698577332.823234,
                        "name": "/dev/shm/incoming/2023_10_29_110155_00_00_K_091.txt",
                        "sha256": "7474e33e98709c636f513494ba3369c317ad46856a3be07767c1bd42b7dc4c0c",
                        "size": 2049
                    },
                    {
                        "created": 1698577332.573234,
                        "modified": 1698577332.573234,
                        "name": "/dev/shm/incoming/2023_10_29_110155_00_00_K_091.kml",
                        "sha256": "8d1bcacc6dc12f275bf5b428370548fe6e593a8509b9659e711207084f536f6f",
                        "size": 978
                    },
                    {
                        "created": 1698577332.2232342,
                        "modified": 1698577332.2232342,
                        "name": "/dev/shm/incoming/2023_10_29_110155_00_00_K_091.jpg",
                        "sha256": "a59073080e415122bdfead2bf3e2acd7612f0b8e4487b74b048931cb875c1056",
                        "size": 951727
                    },
                    {
                        "created": 1698577220.173234,
                        "modified": 1698577220.173234,
                        "name": "/dev/shm/incoming/2023-10-29T10:50:12.ais",
                        "sha256": "8e8fe0e2bda0bfb88eb1b70507f77dd2d072e4e06aad0e3efa43af97658be9b4",
                        "size": 89103
                    },
                    {
                        "created": 1698577212.7132342,
                        "modified": 1698577212.7132342,
                        "name": "/dev/shm/incoming/2023_10_29_105951_00_00_K_090.txt",
                        "sha256": "51040a633b5863e6115c9a1d454e563b5d2d97bc36ec0f6d5bd343ffa28c31db",
                        "size": 2041
                    },
                    {
                        "created": 1698577212.5332341,
                        "modified": 1698577212.5332341,
                        "name": "/dev/shm/incoming/2023_10_29_105951_00_00_K_090.kml",
                        "sha256": "18aaddb04845108c6178032f06064ca4614637d3a440ea63fbc49aba6af6d6b7",
                        "size": 975
                    },
                    {
                        "created": 1698577212.163234,
                        "modified": 1698577212.163234,
                        "name": "/dev/shm/incoming/2023_10_29_105951_00_00_K_090.jpg",
                        "sha256": "b2c37fc7a18d0bdad34fc2058e8366143187612ef9f6bc138888a8346923c5d0",
                        "size": 858040
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "2baab1d015045b0428a69ec6a42c125ba799816ec5f57cf3b5413b85b197b65f",
                "size": 11247002
            },
            "errors": "",
            "filename": "2023-10-29T11-07-35Z",
            "size": 11247002,
            "verified_at": 1698596193
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698558853,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "MumjxOPjuyTfWxzn/kBJnf9sFCOhdvhj7P1KlhPm8JxyOPwb6JhoBHhDzEgLOV7bCStkbEvQR8mqM4VKtr/6dyMOwK/jtBqsppne663umXAyBLlPRdQZOLPUTrwRNX/l7zg+HKZX7O8aiXEqRJ9meDSmI7MqeWe7sTOnQYKmsDgjqgD6lF/fyesmFqR1F7gsx0+M1qvMqpeXKufJ5nR4SSxf5LZravmuddL3ZNNGf0VLOIlmVyC9b1Y+sYYxgQBk29TMLkxxqD8gS0b0qPYk7kudQQoaD+vT/Vq1M47qsFenmMJm7/+15T+F0kjpkWLWGS80IsRLBt0gtj2imqypj3iJPTDjFQ1RMXPM2OEsPLYojOpaucf/TknenOfJ6QaGEIPc1EMYqAjYfj52+QGrowHQDdV60AYNLj/HgSYIA2L+Obm1j0DhA6EpCTNmbd2YRnLvYxui4fDKDY8Oe8TMTjsKdyB0kbDwa0adkA6zXskAQqzx1TQ6DgvTZ4MLfW9Y8ixIT/wg05iQDqCYyjcy1FuUp3LWoZLW3ETdK1+VKf18LhwZGYrrsySGyQIxv4xkCsili8KNLwAGWRDagHPrL9cReQucIz/nfgGykYoldYAaXsrjov3LQGMJbF1z0ZoN0R+e6RiJM5wtz4xmWz70f5IuE5fMMccs+VeDel/lGYs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-53-47Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698558701.6609402,
                        "modified": 1698558701.6609402,
                        "name": "/dev/shm/incoming/2023-10-29T05:41:39.ais",
                        "sha256": "69ee41d4132b782a10937a218efa322bed7c9110a8c9444425b85b83423d67ef",
                        "size": 313192
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "853e9c480a4ac02398246e2b4c105fa5d9200210c0777056574def05e27a8c9d",
                "size": 93123
            },
            "errors": "",
            "filename": "2023-10-29T05-53-47Z",
            "size": 93123,
            "verified_at": 1698577740
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698559012,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "vxOzrBW4ky8qbYMD39yjQgV/+zJlORGJ+R2+Yr7vADthiW5RdKX5kanvNe0N66Rusabv5CAFxsfGhaPFnEk3VQz+eZ4ubVBEMkS5EYDfA3lnWW+VeiIXucN/NLDFZcD6w9uQEdxmF3pMgid7//11kxvYZcdW/yGxEtWjCnt1x2DMcvQlqpjek9znW+o1Iuz+DHf2DZHUGa2Wi/UkWmmsv/iwxCRceZcKhqs1KoOR5AOjV/dO/yShHBaAb62uNxvsyJiRsDdBYozdTbDxCOeqUWzMAAv5/IJNhhIwl43pxi/VRD76C+laRjl3W2ng/QD7FMCSDYtwsBS3/A54fgC1Dzh8cXnKtTmnE8hufUo8Asx4G1cc3dkQ2C4N/zLnGYRTOUu/LcZyIBxcCSYGR4Idm9dov3UJCZXE9YLN2rrWb/wgTJj2f3U16fLXhhPbeYirZp9jDQag0G6f839IImkZd/FeuNral5w2YGaPRQujph9j/SyPbXqxiTrKsZ9WkQ/KcDy3NIJWEsTxKaTUd4T2jiIRDheOCllLiqOeQkaWHFLos2/xxNe71EvQtFcsUgTYlMTHPEQT3+FcoyppbghIpeODy58pBx9DsmfhjmvekOoD9dMdjLOooraA6rBz7aNGBwB9uwUcTKJFxLaOYq7nyOxU77h7YJol4TcCv9jcPkU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-56-26Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698558864.5709403,
                        "modified": 1698558864.5709403,
                        "name": "/dev/shm/incoming/2023_10_29_055404_00_00_V_014.txt",
                        "sha256": "6e9a6ec871086a5de07eab68e9accc6ead84a2097fc93ed1cd38c61f4deae312",
                        "size": 2029
                    },
                    {
                        "created": 1698558865.3909402,
                        "modified": 1698558865.3909402,
                        "name": "/dev/shm/incoming/2023_10_29_055404_00_00_V_014.kml",
                        "sha256": "ae8c975a69b2f29f1c9dcf9715a2655fae0e21f2e7d313dd4b495239d37a12a0",
                        "size": 977
                    },
                    {
                        "created": 1698558866.4609401,
                        "modified": 1698558866.4609401,
                        "name": "/dev/shm/incoming/2023_10_29_055404_00_00_V_014.jpg",
                        "sha256": "e60230b23056a8ab708c34200d94eb6d1cc26e0737f9ec7f2a747b35c71b6156",
                        "size": 1026218
                    },
                    {
                        "created": 1698558850.9509401,
                        "modified": 1698558850.9509401,
                        "name": "/dev/shm/incoming/2023_10_29_055350_00_00_V_013.txt",
                        "sha256": "e7317fb973440d6448c43a201d71425bcb4dc9d73caa7a9bd9fdda821cef8cdb",
                        "size": 2029
                    },
                    {
                        "created": 1698558860.1409402,
                        "modified": 1698558860.1409402,
                        "name": "/dev/shm/incoming/2023_10_29_055350_00_00_V_013.kml",
                        "sha256": "5d4b8c3316d52baa5734764486541e237b47c1db92f4a0483312431bc61501bc",
                        "size": 976
                    },
                    {
                        "created": 1698558849.8509402,
                        "modified": 1698558849.8509402,
                        "name": "/dev/shm/incoming/2023_10_29_055350_00_00_V_013.jpg",
                        "sha256": "634f6d97c974fa2a27c7adfebe042805d56e11b3d2cdcf9872e9c6ec6ddedfe9",
                        "size": 1253327
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "f2f166977f8df220add364b4683236ae023379704c49c30338d11b5fee5a16dd",
                "size": 2282546
            },
            "errors": "",
            "filename": "2023-10-29T05-56-26Z",
            "size": 2282546,
            "verified_at": 1698577781
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698559453,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "qSS3L3rf30Ho96xU4jRaIWJ1buXtVi3BODDl2CjkYTOA8bfmKiSWbmTvl5k1JuZPABrEgiCFapDW6qqZRJ3tQKCB+uZ6jNprpt4tVliTgJki5uIplhVGVEBIQjhr0k4o2+GEt9IdmDkKMzmP/CYGV6S9tZp/ufvvnbZQ4ILEU8nED+Xw/y5J6x3hhCz9uvnjai7MKD/ok0t/UpfdvNqjEFcNnqXNfmEzP43+gHMgEorMDPu7hYraLdVpZIqwmHtRpqyIN4sDZXolklbM2chhOVrAOifpWsgCX7h+HyI+y3k/HFYlZlDRc9Py1OZiAJA4yiJFDJfhk1ADIH8CbMDkv6WIFFe02vs6U4RqfyqoFC5AAvj4gPEavUkerof3zmxN1V23x9/QLi1pXlFOZTq72VMOh+3RYlksb1AbyBgdC596CP7eqEL/YyNCI/w/WmRfkyE2afqiCZ22YmWm5HJ8wixsWMCYnQ/iPN/R8af3ujMtnFh6KyEPKkiIbbEvDZ+nJFAc28yz1A9EFnd4j5dEqftpwd1b46TtS4JZdt/RYHHAYMg4GgC1PoNqm8BUVPeec+h4ZVFWxS2CW5RmP0BDyym145LMKbyozFHNGk0pBPDmAxstWNEqOYoGzxTxhFr8Q4b/NoL6suP9VyoNKGAlbhlS555+yGdEEy6nEJXR5oU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-03-48Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698559302.6509402,
                        "modified": 1698559302.6509402,
                        "name": "/dev/shm/incoming/2023-10-29T05:51:41.ais",
                        "sha256": "e8a925a9e6c50ba2937500750efa8e7651600fd810b04728b27b99fc4bd7e147",
                        "size": 355681
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "a063bb2ee886e1fb6ec4303d16f8b56f9165b03216c1f56fe145d745b76dc477",
                "size": 105159
            },
            "errors": "",
            "filename": "2023-10-29T06-03-48Z",
            "size": 105159,
            "verified_at": 1698577790
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698559833,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "q39RjTEJxBvLvgkMeQKr39cpMidl23ZQg4dpCQ970yYuZXJA8ezwatak6ePbneTI8OA3pry02yKPTj4pV25szxdflVCv5TIvk8nwGZLfB9NkvNSME9KsefNxWphOiVNDM1eacxDYzUlUKClndH8idk9AtM9N10bSi8BorEl+Ps7BfSpr2GNi7UY7tZNqUYlmFXNXbU0xYvnHv6+PQ4dVzghFuNJy4kIx1fRqavXh+IuApITs3zZPFNLrrdbM/CqYtiMS+v2ouYvvZSyxW3VX7mZOZOs3GQcJgn3UJpZeEIZzkFVX7KLujWqvpNMRcU/D5oIvMSdXNySk+8XaEaalwq1Z2gQ8vE1eMTxxhb1zE0yMIU4ADlM7zA2NkNsQ8x6Xs3uF40h3Tf0DCb3EFhsZ0fvXvoixQ/2ihXzRn4SUUBcjq/MsWn7HjGhrCl8VtXs1hii9iSZirvPO3SXVBHnnk0heGLG2I5ozsz5pWqD7K95KY4Wtpq1KvDoa4rQ0BRDrwZ/sd4A8XVs8hKJMbIBTDp7zELPr02jlCfgYdVYYOUa3eqeqYv0MuaK0YY0SyYQu6MDnGOWXGktTKjeDyfY97Wd09Ojr3FbjgEegiReSxJ1URSfFEwGZnpKqZJajkfhLxlGavM+qNxgFAepGPUv1DFQsGxnrjy0ph0o4k4jofd0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-10-07Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698559685.3609402,
                        "modified": 1698559685.3609402,
                        "name": "/dev/shm/incoming/2023_10_29_060734_00_00_V_015.txt",
                        "sha256": "0a9f953a9515f4433d1943f1e5b46f5007fe7753c90510c1ef03db2c13a0d4d2",
                        "size": 2037
                    },
                    {
                        "created": 1698559685.6809402,
                        "modified": 1698559685.6809402,
                        "name": "/dev/shm/incoming/2023_10_29_060734_00_00_V_015.kml",
                        "sha256": "5ea059c7c6b2c5aa2746d1d2c7a22ace84aa0ad7c21032569f954953c3c4c0d8",
                        "size": 978
                    },
                    {
                        "created": 1698559677.97094,
                        "modified": 1698559677.97094,
                        "name": "/dev/shm/incoming/2023_10_29_060734_00_00_V_015.jpg",
                        "sha256": "c9991413ca24090f47a073a492fc75caa5c63fde67ad184363c786b6fbb02a7e",
                        "size": 753212
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "491e22e3c4d3830364c9f0060b4cf940efa7c8c28fc25a62282f6cb03efccfab",
                "size": 751493
            },
            "errors": "",
            "filename": "2023-10-29T06-10-07Z",
            "size": 751493,
            "verified_at": 1698577979
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698578039,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "l/KV6YHRbuWVGNoCZrFXt6i33MKdDtJVz0ZszcT6yUj3okEG816IQlNshUk52dJFx+83pbLGGYdgzF9br8CZEkqbEBCaRU+7fnRfYEXB2y3H3Frgg8j3E+Ssy+zh0fX3fG82F2EeQ2o6Qov5WHIie9b3xV3UDSZuNs31bt3i9JYZ/rK4sjlSsBNslMn2Ga45/khbTSvjOh++vGUZI2EJxK7bBhD8daULG0rz+E9YdgPzgRR3qBvdhJ5/vQKtShU909kCx/GvdVE1frI2Li5EurWNQ2QHwejjsVWXUQgmc/Tl6Iq9P1MVuwzM4nPGeoY+QlNDqangKv01iCQhB3htf5tgvvqUWeM4zaMmRTkpyM0AlsOHtMnEZwV6ts7Z0iu6Jywa7dzMnebrUC6mwFz6ZXFwncyBLFBnG3DRzReQEdYmDd2D8Q0BPzjwLmU9Xq+XaNXDrKtUIXQSbmGXits01EV+nzfcRTjE7wUOHDh8cbQgvDhJabKwoJgRV+66u6276T48kigNGVgIkaN/HXfuO/QOK2p8kvfEaX0ftdbObnotwyrlpA5hQi58z2zuunMp+jpOgkrdxNSnzDJe/kJIA+EQ4tiSsaTLCyFATGZPPh8noxiYKS1UiJv9rXgq1jm7v/wa9demwSIMU8Nt3hNpii2jZoiDVn8kcSxQlZR6pTc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T11-11-16Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698577822.043234,
                        "modified": 1698577822.043234,
                        "name": "/dev/shm/incoming/2023-10-29T11:00:20.ais",
                        "sha256": "9df170f0b4258f9d7e1e78d97bc748691cd8324207b548c192eb45b62e5aac73",
                        "size": 92739
                    },
                    {
                        "created": 1698577806.343234,
                        "modified": 1698577806.343234,
                        "name": "/dev/shm/incoming/2023_10_29_110951_00_00_K_114.txt",
                        "sha256": "4fac98a81318449346269c3b58119ebbb7fa601fb5211673bd7bd6f63292d8cf",
                        "size": 2050
                    },
                    {
                        "created": 1698577805.853234,
                        "modified": 1698577805.853234,
                        "name": "/dev/shm/incoming/2023_10_29_110951_00_00_K_114.kml",
                        "sha256": "6fce3901db318f42ab3741d5e03dc8d0947bcdf52d56c92fd63d69f7b49fd4e3",
                        "size": 978
                    },
                    {
                        "created": 1698577805.5132341,
                        "modified": 1698577805.5132341,
                        "name": "/dev/shm/incoming/2023_10_29_110951_00_00_K_114.jpg",
                        "sha256": "8c2c3b8497476d5ca7e493e62af19c9d99fba61c76f08e7e0cc916378b589faf",
                        "size": 1012041
                    },
                    {
                        "created": 1698577804.803234,
                        "modified": 1698577804.803234,
                        "name": "/dev/shm/incoming/2023_10_29_110939_00_00_K_113.txt",
                        "sha256": "38b6a86b8e593c452a88c5d06be02c88fe84069cfdff1046f8734aa0d8c9c5a4",
                        "size": 2050
                    },
                    {
                        "created": 1698577804.5332341,
                        "modified": 1698577804.5332341,
                        "name": "/dev/shm/incoming/2023_10_29_110939_00_00_K_113.kml",
                        "sha256": "2030a04aa12855bbaec9907e2572bc369e74befc03df069637fe0a291c288614",
                        "size": 976
                    },
                    {
                        "created": 1698577804.4532342,
                        "modified": 1698577804.4532342,
                        "name": "/dev/shm/incoming/2023_10_29_110939_00_00_K_113.jpg",
                        "sha256": "249e2a718d1d68c483fc6312c16d8eb50bbd5183f913a855a7bedcd93bf77d87",
                        "size": 1118467
                    },
                    {
                        "created": 1698577782.053234,
                        "modified": 1698577782.053234,
                        "name": "/dev/shm/incoming/2023_10_29_110925_00_00_K_112.txt",
                        "sha256": "8a7860c6c9e338400085d31baf7548fa4a05e640d01ad2135be46885fe920d73",
                        "size": 2049
                    },
                    {
                        "created": 1698577781.643234,
                        "modified": 1698577781.643234,
                        "name": "/dev/shm/incoming/2023_10_29_110925_00_00_K_112.kml",
                        "sha256": "720a44c592a49bce2cc1e64c022d9e87ea60d08111ea0b9dba75d50811eb0f31",
                        "size": 978
                    },
                    {
                        "created": 1698577781.0132341,
                        "modified": 1698577781.0132341,
                        "name": "/dev/shm/incoming/2023_10_29_110925_00_00_K_112.jpg",
                        "sha256": "4bad8fcf20a8eb8f2c8e49c1da9113618436e0e8fb1d2588c8226e4c8b698b07",
                        "size": 760204
                    },
                    {
                        "created": 1698577758.853234,
                        "modified": 1698577758.853234,
                        "name": "/dev/shm/incoming/2023_10_29_110853_00_00_K_111.txt",
                        "sha256": "e1c6cdb8f8332f582dc6eb5d66d048b5944d293b4c653fcbb37fe1a23278d32b",
                        "size": 2050
                    },
                    {
                        "created": 1698577758.4632342,
                        "modified": 1698577758.4632342,
                        "name": "/dev/shm/incoming/2023_10_29_110853_00_00_K_111.kml",
                        "sha256": "77c680861b220cbc216031020f72f0d4ab8b697086a7637cc1bda817c932c622",
                        "size": 979
                    },
                    {
                        "created": 1698577757.9532342,
                        "modified": 1698577757.9532342,
                        "name": "/dev/shm/incoming/2023_10_29_110853_00_00_K_111.jpg",
                        "sha256": "b972d4d4b3ae8df883ec412925b3864d14353b2dae3b5f471f46c2e014328837",
                        "size": 1069026
                    },
                    {
                        "created": 1698577736.2232342,
                        "modified": 1698577736.2232342,
                        "name": "/dev/shm/incoming/2023_10_29_110835_00_00_K_110.txt",
                        "sha256": "57eab6d6dd2d422675499edc9308cc6752144804f9bbec567399291bd9b433a3",
                        "size": 2050
                    },
                    {
                        "created": 1698577736.123234,
                        "modified": 1698577736.123234,
                        "name": "/dev/shm/incoming/2023_10_29_110835_00_00_K_110.kml",
                        "sha256": "a52ae4d455be54588c62a01c8e4644031828b6924b0760700e91e4afcede28f8",
                        "size": 978
                    },
                    {
                        "created": 1698577735.363234,
                        "modified": 1698577735.363234,
                        "name": "/dev/shm/incoming/2023_10_29_110835_00_00_K_110.jpg",
                        "sha256": "bfa9bf31e3fd1ff83cc3d1e5298e5d830e6135e98964c1ab0c420e96ea59ba4f",
                        "size": 1169655
                    },
                    {
                        "created": 1698577734.303234,
                        "modified": 1698577734.303234,
                        "name": "/dev/shm/incoming/2023_10_29_110831_00_00_K_109.txt",
                        "sha256": "cacc06587dd87256d64c3d6195970cb99248ff64b7181d3e5608912cc3472209",
                        "size": 2050
                    },
                    {
                        "created": 1698577734.063234,
                        "modified": 1698577734.063234,
                        "name": "/dev/shm/incoming/2023_10_29_110831_00_00_K_109.kml",
                        "sha256": "e5a69e04556b9a8ea57e5af1a89104ee4663d4be54ed0c48c37884fa40772363",
                        "size": 977
                    },
                    {
                        "created": 1698577733.923234,
                        "modified": 1698577733.923234,
                        "name": "/dev/shm/incoming/2023_10_29_110831_00_00_K_109.jpg",
                        "sha256": "69949845b1f7d204ad4bf4f80a1309572125757d39fb4079d542828e0eaf801d",
                        "size": 1188740
                    },
                    {
                        "created": 1698577721.913234,
                        "modified": 1698577721.913234,
                        "name": "/dev/shm/incoming/2023_10_29_110827_00_00_K_108.txt",
                        "sha256": "e220466529e5d40bafcee6532d34296c6bc902a9bcd60c63737a9ca1ef79a13d",
                        "size": 2050
                    },
                    {
                        "created": 1698577721.413234,
                        "modified": 1698577721.413234,
                        "name": "/dev/shm/incoming/2023_10_29_110827_00_00_K_108.kml",
                        "sha256": "6c4b96429052f30a42310bc4c068b84bca3ea415ccaac15ef82d6eba28dbf456",
                        "size": 975
                    },
                    {
                        "created": 1698577720.883234,
                        "modified": 1698577720.883234,
                        "name": "/dev/shm/incoming/2023_10_29_110827_00_00_K_108.jpg",
                        "sha256": "1dffcd17a6c4cc36870fc7a2dd28dac7771f70af9151bfdf562ea260ff06bb8e",
                        "size": 1062234
                    },
                    {
                        "created": 1698577675.4532342,
                        "modified": 1698577675.4532342,
                        "name": "/dev/shm/incoming/2023_10_29_110727_00_00_K_107.txt",
                        "sha256": "2d576c64ff1bf50661fcdfe49dccc796822dadaca36c7b78a6baacc700d292f5",
                        "size": 2048
                    },
                    {
                        "created": 1698577675.073234,
                        "modified": 1698577675.073234,
                        "name": "/dev/shm/incoming/2023_10_29_110727_00_00_K_107.kml",
                        "sha256": "ab9c0afe9f8356e20d0df0101704b08f1c0c627ed1b48d7cde241731480e68ea",
                        "size": 976
                    },
                    {
                        "created": 1698577674.9832342,
                        "modified": 1698577674.9832342,
                        "name": "/dev/shm/incoming/2023_10_29_110727_00_00_K_107.jpg",
                        "sha256": "3fd28bf47da24774cdf54878950ef353c577d6ab65e7b9ff16defcba9c7ee16c",
                        "size": 970627
                    },
                    {
                        "created": 1698577673.923234,
                        "modified": 1698577673.923234,
                        "name": "/dev/shm/incoming/2023_10_29_110722_00_00_K_106.txt",
                        "sha256": "e39487285ed7ae455269130be3f3404f1abd27124c82c226a2e454e724afd193",
                        "size": 2050
                    },
                    {
                        "created": 1698577673.403234,
                        "modified": 1698577673.403234,
                        "name": "/dev/shm/incoming/2023_10_29_110722_00_00_K_106.kml",
                        "sha256": "6c3a36987f07316fd07260748be526c6609b283023c2633b184d1ce8fe02bbba",
                        "size": 975
                    },
                    {
                        "created": 1698577673.053234,
                        "modified": 1698577673.053234,
                        "name": "/dev/shm/incoming/2023_10_29_110722_00_00_K_106.jpg",
                        "sha256": "8591af3b2fa2e9f4cd42efaaff78e88c34f0181f534208ffd94a36d10bb6d126",
                        "size": 981756
                    },
                    {
                        "created": 1698577661.903234,
                        "modified": 1698577661.903234,
                        "name": "/dev/shm/incoming/2023_10_29_110718_00_00_K_105.txt",
                        "sha256": "261586b33d4a42d6b78fd55d686b1d1239722559c32b4f32a7a246f5e27f240d",
                        "size": 2050
                    },
                    {
                        "created": 1698577661.2232342,
                        "modified": 1698577661.2232342,
                        "name": "/dev/shm/incoming/2023_10_29_110718_00_00_K_105.kml",
                        "sha256": "80eeacee517a01cdebf1f8beb282616a3fb2476e3f201cc1f1093e36ad757084",
                        "size": 976
                    },
                    {
                        "created": 1698577660.913234,
                        "modified": 1698577660.913234,
                        "name": "/dev/shm/incoming/2023_10_29_110718_00_00_K_105.jpg",
                        "sha256": "658d01ea4b389ce9af8bdd6d3e7e1fedae5bc62326d051a50c03a0271a0966a3",
                        "size": 1044866
                    },
                    {
                        "created": 1698577658.853234,
                        "modified": 1698577658.853234,
                        "name": "/dev/shm/incoming/2023_10_29_110714_00_00_K_104.txt",
                        "sha256": "03d6bc173535b8d2dcf703c6c4a5847d0a1bcf9e712d92efb160599a66175c42",
                        "size": 2050
                    },
                    {
                        "created": 1698577657.833234,
                        "modified": 1698577657.833234,
                        "name": "/dev/shm/incoming/2023_10_29_110714_00_00_K_104.kml",
                        "sha256": "d24c1114bc26e7ed19b310460a4213b419046b378ad6ad06470d52289af5030f",
                        "size": 978
                    },
                    {
                        "created": 1698577656.843234,
                        "modified": 1698577656.843234,
                        "name": "/dev/shm/incoming/2023_10_29_110714_00_00_K_104.jpg",
                        "sha256": "50c05bf148f57782205f76cf2b9f488cf41e965419853f500bdd4357b7a80671",
                        "size": 1067564
                    },
                    {
                        "created": 1698577654.7632341,
                        "modified": 1698577654.7632341,
                        "name": "/dev/shm/incoming/2023_10_29_110710_00_00_K_103.txt",
                        "sha256": "0402bc8b21c938e915d4706df126bf6f4d54aec74bed135a5b6d58e074d84c10",
                        "size": 2048
                    },
                    {
                        "created": 1698577654.4932342,
                        "modified": 1698577654.4932342,
                        "name": "/dev/shm/incoming/2023_10_29_110710_00_00_K_103.kml",
                        "sha256": "b579d9cb597b455757cda9a6a40dbad3bee3a76c98007d3e8c18791492f810cb",
                        "size": 978
                    },
                    {
                        "created": 1698577653.7532341,
                        "modified": 1698577653.7532341,
                        "name": "/dev/shm/incoming/2023_10_29_110710_00_00_K_103.jpg",
                        "sha256": "2e6fd8a46a7acee4427ee9123a2a92689fddc304dc5ca621c984b64e67de29d0",
                        "size": 962477
                    },
                    {
                        "created": 1698577864.633234,
                        "modified": 1698577864.633234,
                        "name": "/dev/shm/incoming/2023-10-29T10:41:04.ts",
                        "sha256": "79d8490467ca89155b369f472fb5585032de6fb4b3c3af3ea58aac5e7cc40535",
                        "size": 482747340
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "5ba1b08ed766f2b92a1a55c5c3fdbb9de38ce6d54208155d977b96a2b484f62d",
                "size": 453108144
            },
            "errors": "",
            "filename": "2023-10-29T11-11-16Z",
            "size": 453108144,
            "verified_at": 1698596699
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698548577,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "daZvYesDeH1l4n1ilcD4es+GBFGzRjiT/2VnDbX4SXI6tAPwm+37X0EHCAYxLNKir5G/OkRBiuI6d2bLPf6iV1v7DlLrN/aZPn8bb6SqA+k+NJ9mceaMBx+glBfMQISjidY5llsd86nvOwD1dbb9HD8wglOBI94Zy0pkApnsDyT3MIghiQU0HFl786oChopmJRXbZ5iEx5LsaKqKICAoveUj9A3vTq6Sm32rjVm1rTjmSdpuxL6hFME70uiX+gx7Eo88jeUACVdp5ARgLqHbzKT1uVgURlAmkyJ0vGb7y6BHMaoEnCPgbs4USG3GeGEcxdQd/0kDU5u9dxNTm1gydpA5eWf2w9F6ITS15nuYKFO1ItNdbT0ziIKEfR2fN6ny+7jZisMvUVVzGsNL0Wjhs1DmLsZkUHVtNxIn7zt+uzkCIKYf+1JwzJSscQ0hrKj10OwQzhO7BLJ+K8AVsT9q/p44GPZYEfU2nnUqEjTwt2/H/FB2OatI30+2iA50M+nsJQwMi/49RoSCm/qNwJ7+j+wySSnHDYaWhGLYprzcLtGlJyp+zitafpwjEjWAFwxK7bo8jaSCsEZufEoOBz5nglhKLBvQnLixRAxGnVi/GQSeDy63yLY32N0ZCQ0MvLFqq+dhHBdPJQjHpCX6yvsZxtiH+V1yCYt5103S31ImkXY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T03-02-21Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698548414.0579689,
                        "modified": 1698548414.0579689,
                        "name": "/dev/shm/incoming/2023-10-29T02:58:52.ts",
                        "sha256": "9da011c049273808f3723eaafd8d8c8cefdff00f94ed8ac14da4c3efde83c95c",
                        "size": 3884832
                    }
                ],
                "mission": "OSP3",
                "sha256": "e5a0e6784e75765f284f2f0a62f22e9086ac9b371468a67b1138326bd662ae71",
                "size": 3505301
            },
            "errors": "",
            "filename": "2023-10-29T03-02-21Z",
            "size": 3505301,
            "verified_at": 1698578130
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698571244,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "V+Hkun/PJKwqWFgGcRcBfWJ5mdbncCdGIL3vPVsx6L7IflOG1Oawg1sYZlD1VFTFkzRQxJjaU+5XACcCfMeXS9nA9YvZ0oRDOamS33xq+TO64HUgJnq3TWHx2qDtEvWCR461BZVFCdXQota+TD00PUZa23Sd3QIlpen5jjHRyDpj2nG+w0yEIv/jvETyZkMyyy6BGT09XmsGKucHJg7kidSu8LIFcJZbj3MIbaMbK8HzJMJf5V+eQqHw8DHoaB77bPZt8Sjt7DXramv6XSdM2I1qyGD/5EiYq+dYRd9igobnLOFfw9kUl6x5ZcxlHej4wd2l+ES12bgZuCkzhtdHgiKmHczLiP29VnohVya+pkvyhCCivw8rj9ZFB66qhpTo/4woewoKuJ1twMZoof4x/umCWZ9eObyBdQDIU8ZQiEPEtLIH2zYTaKBTGCOmviZB1xY1woVQwZU/7IGPnP6c5kTvNOTHRoz/bbQlfIZzvoDXmuE4jnB6wuFNWE4frVpLUMDFtQZYnwNRi0ZT/PVG7+v7PJZGIiqlCo9u2kzGqcIG6iNyTc/lOsjawWIwL4tUoP10EWqvt9g9sMt9Uk07C+KhOqgxmLdF6tNOQntI9grNhF5Nz1+VZvppI6glwEMwHoz6iM5erbDN4mqiGOEE1da9abJM6Qp6Un6hQSiRNGE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-20-40Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698571112.0879688,
                        "modified": 1698571112.0879688,
                        "name": "/dev/shm/incoming/2023-10-29T09:08:31.ais",
                        "sha256": "3c7ef6f331ed8ea48c474da3093e31586811cf631f9c7d0c53887c13f5ce88d5",
                        "size": 472628
                    }
                ],
                "mission": "OSP3",
                "sha256": "a6798a95ca0b12b065bfdc56cc312ae5f86a70b96d5d7fdecc117101489ed6c8",
                "size": 141981
            },
            "errors": "",
            "filename": "2023-10-29T09-20-40Z",
            "size": 141981,
            "verified_at": 1698578138
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698553688,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "QR1tzhaZJTYcrRxobxN0ycSMosZ5c65GgdTyJUOEh7OdGDd3xVnBnC3+1PM+lNL6nHrMl7zXQoUGluED08344J9aYEnPBce5dEdGMH984OSPmeoMBD2VCAOkgmCQAeVIM+Flppa9ou/r/sAwu3b4uccEQoJOVtJYGdcgG2xWMwUL0FU/uIbnRQ2nSPt/5VtIcxep+Dd/m5Xkxybf93hyjI72WUCgdh4y52Qh3hjtUmx65alU1Bk6QfiHJDjlImuXBnexno8VWPYPJKR5wQhKLBfV/9Mm8SccsTid6x49bzsJJriLx+tV11FM0qFcOH6WCetdgCqYH9NjpySgaVw7kxkLy83/WKOe4GkgMR4urTt0I3eovUfb/FTVoSRF2+2bwagTbrUx3izSYt7asybDSLcHYTXJY0AnITr41JMU2Vx7sZa0MbFer6F7m2/jg+i3L0rL1MMeND0TzdYeaYsmloFactm/z3xpnXdoKLuVhPTJRpATHuqW7shMPqOY+n/4QCWRJWH+LQ6dUIuadAFT1/xEabJ+0VcwlZPbXWbM0azu6sySLPnxelWpJJi36MbBEZHYa5rvpZEJuxWp/h/Fo2y5+dyXVHYWXTVXNo+s5tXSyKgsP+uhnDQxmZnONYA5MiFLG1s7qpCW2z16l6eQKMiQWMyaYp/VXmwyUeIJqbo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T04-27-58Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698553593.8779688,
                        "modified": 1698553593.8779688,
                        "name": "/dev/shm/incoming/2023_10_29_042528_00_03_V_002.txt",
                        "sha256": "e9fbdbec879b52cdcef29fcb43f0ab14c2746900318641387fcfd5344f7dc6c3",
                        "size": 2031
                    },
                    {
                        "created": 1698553561.8479688,
                        "modified": 1698553561.8479688,
                        "name": "/dev/shm/incoming/2023_10_29_042528_00_03_V_002.kml",
                        "sha256": "7e7edda1a2b99c30041f4ec9d3c6ed17600bbecfd8772ee3d5137ff3470634bd",
                        "size": 974
                    },
                    {
                        "created": 1698553554.0479689,
                        "modified": 1698553554.0479689,
                        "name": "/dev/shm/incoming/2023_10_29_042528_00_03_V_002.jpg",
                        "sha256": "ca04cbe840ee25cc956fe055c060e99077d109869c987188ac04fc5c5a2af952",
                        "size": 623262
                    }
                ],
                "mission": "OSP3",
                "sha256": "98d7f0a9dc5899082dc33f1ee089c74973ffc0cf96f9a30ec445ce8a08233e74",
                "size": 618723
            },
            "errors": "",
            "filename": "2023-10-29T04-27-58Z",
            "size": 618723,
            "verified_at": 1698578143
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698550802,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "qhnpavasqIEm/9+609Lw8/RDHED6HsnlEAY/yeRdpbPoow9ftKNWPrmH0wLorgR1G3trAt35KquUlC9MV7MUq1XBB3VhwlCJvvK5i+CMTCvI9gb38LBN3N9KRc2tulyZz9e95Hh7mHBx445BMEG8N7RTghIZyUIm4jqF6CEASfrENJngJmu2qnp1jU4crTFT/irInaviZTgEtTCuCnMxZ2QNGxa0thyJth2L6nLfURuyrSw5CrUcKXOsBJOb2vuvlgAtH/DeboMRVrmoNboUTXhc9OZgphudR7MZpm/Qxc2+eHLbX6o+ijnReEVjHn3x68JxCDGceq2hkVuk9JD1c8ssPvPolfOTzT7GlNDmtVlkSUT+UvjUU4B54i+2TpHB1AZsJucHPLfC1cBaXFs5vt+SoWVYn4fuFVl0PLvUBySz/9DE/PY3re+TJ2kp5W8gUDygRVxB3t1VggD+SoS2zdLfPDaCqVBdSxWtZoBgyxFVvd1HqixksdGfpZif8r7VUeinhfV+bFNkqKO8psAGLGgfK5wx2AkvJoxrD+wgfb6tHG5Ga/8x4HQZVWAM2TJqEEIM482iuGZqnfzYzvOCjdPXxoFq0cbUQu2o1BVrmQyC5d+8GtH7KLcGpoms8Cxmw1RlXKBT7hTf15zb4BOFXR6boUmXt714UJIa9cvMdXM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T03-39-55Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698550672.1179688,
                        "modified": 1698550672.1179688,
                        "name": "/dev/shm/incoming/2023-10-29T03:27:51.ais",
                        "sha256": "910e6d97d6aecf9079f41acd88f73273b5c0c58e81da49585e568843823d02c4",
                        "size": 316184
                    }
                ],
                "mission": "OSP3",
                "sha256": "ad755ee712c6e707d0441028b76f37849d7f228568fa047f788eca301a77e076",
                "size": 92527
            },
            "errors": "",
            "filename": "2023-10-29T03-39-55Z",
            "size": 92527,
            "verified_at": 1698578159
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698561034,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "EBZIlC3NhkDb2PAYKvM6jOVr7TEM7gGIwZMS3nokNRpHUwllxFIe8c8k/1aDPAkn6t2MjDy2iLSy6XRdLMVI/G270QWdoTqqBALnRZwEPMNbBjJcVVl7yMpndmB6XCuBrtrUKLlhtsNC4QX0GCVCgFujKgU/F3+Gq/oXtSHV5k7yxo7jM9mAlK9pZ+2rUl5yUS6+uFnV8uEpD+kNalzyldzf3+QbHXDzxEan4U7yN+RY4D3dxEP9+13VGU9ElT0URqfFOd8Ju3WIj6teBW+YTGLXhYfNKbG8Bf5aC89ooJDwZNlAyauiUPwKSzPhgYhBg7HvkgjU+F/BsEfQb9PBpu9nJCFng0Yv9FgQs9TixNoWZb5muKu3w59f2Zg1btC1yOAoVe6d44xjrWMTCiSzVoG4OagsL5aWuPLJhXIXbDHiPrIjlZF0+y1Pvvah3rQqININy+OlPMFtdOJolIutRHrcxnKjT6ZpaxvfdEDtjmCZI9LzxzeQUrVOrxVpZC/Td+UbfQlxcL/1ICJgbZqkeoP54y5iji5Ne1xbatigl/SOzIORZrPSmpViYdoYSQiNZ48MjIwN6xOpCS+TKQVcm3xkBvNTSkL76yp+SjiFj4ebT6nZjLW5VzZmWq92yRzjWb/MJAMd6B6HPWRrPH4SjX/y5UMVaK+mw+3J1RJxzGw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-30-19Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698560892.977969,
                        "modified": 1698560892.977969,
                        "name": "/dev/shm/incoming/2023-10-29T06:18:11.ais",
                        "sha256": "d968e817c1af568190edd9019a43ac9fef986642f1b2e6f16e83069d27412d09",
                        "size": 63050
                    },
                    {
                        "created": 1698560858.417969,
                        "modified": 1698560858.417969,
                        "name": "/dev/shm/incoming/2023-10-29T06:25:48.ts",
                        "sha256": "4cb64088aed329d8572188d947476358f3e4fb67f1b8431bf57adc79df3d6278",
                        "size": 21539160
                    }
                ],
                "mission": "OSP3",
                "sha256": "0a9d074b952bf29584355199755340e388d3171b1eda734e0285fb5a3cff3339",
                "size": 20703190
            },
            "errors": "",
            "filename": "2023-10-29T06-30-19Z",
            "size": 20703190,
            "verified_at": 1698578406
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698562601,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "C/A37zf8FBgoou8V3evlI6tR8LnRdi42iKuW/FUXYq7WYaT8Ma0VMfWc2/VeL+qerK06cjU4s/gR/2DsWzu6MraKIO9AcND1xaizgtP72bZhLpyyvOdUThUJNdHNUjN5frDOxqZ9D48wNM6W9vzXgLXV9ihKL6d+D+iX2XTHbQg2mLs/EuBW/m8bqXtEK4IzZhCPLjeUy1UmQDuz242pMK/RTS7mCw4oLIxUuaqyw13hhjS6ERPHkyq+jhFtji5tH4J0h1vDzXdFDzvrNgcKQ5Z/YhmE51YohsY9F9SyFqeqtzgCjlCPuo3b2ZXKVTdl2UHzYWveORJTDkBXraHtT7QXbZ4FIGJabCJEx7xq2xztaHFd4nzh0Ol+p54Emi4Vt9vbMhKtGVcY02MWBbUhG0CewJt9Oj5NpzT93opsU4DQZYZFy35ciD4ZVErIJVV1pJH9Fp9neloJL4XIlOaXQxDaC7nacKIu57WUHs3EjmYpPgPctrKKrDBfWcVSqicsDVUdOozLoZiY6+VSJXSa9VfGELjl9SwDR66QboWJiHl/B1518YxoV+cJse8azpcypu026GH30UipuJCJFbWIwKmSIoSk7JEV4kuH7kiCLhq+fkKKRh262yEXFAKq4X2ecWbIhfhugyRLcfGwSFqaP8+OAJyuEzS+0IqVV1csZQo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-56-23Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698562455.0579689,
                        "modified": 1698562455.0579689,
                        "name": "/dev/shm/incoming/2023-10-29T06:51:39.ts",
                        "sha256": "206a93adf13759883ae05db8fb3b04f20c1d4708924efd35a4e94c07690b8afb",
                        "size": 28788628
                    }
                ],
                "mission": "OSP3",
                "sha256": "c71e3235b03b1dc665af681556457214040020605d291308610cb017a3b335fe",
                "size": 27596348
            },
            "errors": "",
            "filename": "2023-10-29T06-56-23Z",
            "size": 27596348,
            "verified_at": 1698578502
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698564420,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "azJF6buMtZZxm9xP3xD+QgN9yKKhrYmb+ijpLcnPfEKQQDX5V4G6RAbZ4R+KY54yBX5C2jlIsOIToloxIBJUDA3Gx36kGA8ln7lARrC7nPsif4k9iD6JqMWaxBgrxHlbhfCm51ClqRdoGu63RE0Wj8YDQYRMsBck/HgbmUxAAcUMqN+Wq5ssUYyfJIrQLXzoRHC9wp7gv+BF7Kyw/F0fDNvS0sRgu9UkwqaZ+1RG2wvI17T11gKuI5hNfP6SQ0QOEctSOitJSxBm3YirBFzdZeWiw14taNzcow6Y10ssYL2HVBe6rESDaW2FrJVFSnC5zwHLoJ7lfK1BGM7+IliM0+cVG7cGcLQ7rViPo9jw81k/DKesWpoDWQrlN7Dg+LGq0dVASVNaNKRmEZXRVj9AACMDAXkJQBz0tVNKGWOEyOMDNi1e02eoGXs98eR4s68FxUNjPG6UxfJIkCeRR/3GqQCyCWOK7yBIgPdGNHsPOosqvJOIIEhj1kYkGE8LjtH1GBTbxqlw3CleECL4tyR5wp6cXng2JiFBOA//IoScQUPRjAl89n+xulfB+gEQsXJImRNq+GSf1UGM+h5l+ANZaFyBkqkoi0lYzaNfMMm2l5kM9B17scq4ZqC3QDWSQr6kwBWTnJRs/3ULilvoelB/lQtFyriop5tDuRWZ5K9Jg7Q=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-24-34Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698564262.5979688,
                        "modified": 1698564262.5979688,
                        "name": "/dev/shm/incoming/2023-10-29T06:54:21.ts",
                        "sha256": "0a96d09e5d7eef38181b011d81b06a704a18d7da8d95f467be0cf65fbd68f0e7",
                        "size": 324826212
                    }
                ],
                "mission": "OSP3",
                "sha256": "5851de5c9a9dd7662c75c016cf270599d0b1633b69c608da9bc6c0bacbbe2af0",
                "size": 313029200
            },
            "errors": "",
            "filename": "2023-10-29T07-24-34Z",
            "size": 313029200,
            "verified_at": 1698578565
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698567186,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "si5g53jUzEMz4oFgovUQ5d7IfNLbteDypjZPcMzeVtyhGu7xiKjwMA+6hm3jV3mocpFYTXrBN/b2DbxgHemyo5Q/FSjAWn0yqnFqv28ZpNFO9NHho8b1SuOBzRfdUhfbcFQraNVahAnku+W+DFM13mQQus+00qRliA3YBt6b8PqHZWybxuyQaHCA+Bf+rW4YQYzVlDupDjSC1sngXzY0b2Ki8Tat/AzZJBWzIisiBeme13iaFhZaTxulBIGLhU6pzDh0yopqfWd+/52ieBdU//45x0ddJg0fu48mcxNfANitBnNIKof8tKVNMwTOh0sT1S23uC+5Szru6RDLTTD3Mla9gxxJ6WZ2xVPMImm1cvpVPXUxzoZ+xWDrsgSRy3l6XqJRSQU0gbCVnXZuMlQrg+gugcjGHNQpB6gbzNTTap6KoiEFi1ZCVV5PYjuPu2Wx21H4rweHrFXxRzo+wozGYfobVKfAR68uqjUPCGLCrWjGl6KmUeSjClqV5ZUMsers/F/lI7ATWJg0RtVopUOw0pEk5bVkL051kDQN5rs6fG0l93tFpt/ZvGdWe+7xPm/BFhVUQ3clvLWfiBpnvmnCjZHP6lxgSL7MN0L0Fi6L+RyvnatWo8Vjjsl9twvx63penRQOeHG076jE4iKo0xHP6hlA/SytT6KUfopVCdRoNLw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-10-31Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698566905.1279688,
                        "modified": 1698566905.1279688,
                        "name": "/dev/shm/incoming/2023-10-29T07:58:24.ais",
                        "sha256": "979b0c91b63da3909f9dc385bf59a1557a81c2a2b3d0bb19dc4ec579679f4bea",
                        "size": 57468
                    }
                ],
                "mission": "OSP3",
                "sha256": "387b39dcdd78f9ec933f91686144c2c33769e1e59101abbbcf25aa935b246298",
                "size": 16394
            },
            "errors": "",
            "filename": "2023-10-29T08-10-31Z",
            "size": 16394,
            "verified_at": 1698578580
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698757030,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "qdXM0aE1Fhj7M+7WB9+Il/K29/gqfZVCxjktArW+K6uwrJ7pUjM3kEMNDx974cZV3Gd2lEs3EvMx6R41BOXwKESXHPu3cXEqIEsa8EUmDex84t0udfxQQOUcYPxf0+MYQljqm1AloPfcbWIIhtxeljRm+2iK3qRBGcS0T953mILQB6US6Q3ojznSSi3qpfPxRBUE7hp03Co/tv4cTxKw+jDmd5jtPFg/NAQ+L0G0H4HwPQSqGJcJVVMR5/wwv/87etlWQZVJ3IH99ingBg9Yr5lIw2AwL41j/gTb+lVW2GqHVQ+dVfO94021GptjxpnQkjC/MBguvnx2uisLEAIETvjnZ3XbZqM6T4tesLPEMwRtjZKR2ByalWDqQw673BJNj7FzRIu7AxDRncwEs+536hHJz7Gfs5Fb96cRq1lOG/FiHcWpQt2HMEk77k+NN/xvoLizhYUp7Ha12EvE8Rxw0XNo7Rp3X2NHjvv8Cd9V1QAyaGSuZgjp1heBnZB4FJJtrNsBaL0NL8DPX0VV0WerttTdTGFkZv9UDZKwvdds4My58+2f2+kOi6mIcGY/t3vQKYSSRDzn/WBxWb7rUVNE1bSykLImt2o/U7Jf1wbg3cDFPXgWP7ndvb/4eZ7O6X4NMdYyyiTzRfvUhVJ0BVipxdjSp2Xkv/4B8afhdmhNpi4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-56-59Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698756896.2114184,
                        "modified": 1698756896.2114184,
                        "name": "/dev/shm/incoming/2023_10_31_125441_00_01_V_078.txt",
                        "sha256": "69e17ae49e12f2828f2d88a5fba65ecac2e30248a36e11a512eb23e2b3888a8c",
                        "size": 2041
                    },
                    {
                        "created": 1698756895.9514184,
                        "modified": 1698756895.9514184,
                        "name": "/dev/shm/incoming/2023_10_31_125441_00_01_V_078.kml",
                        "sha256": "6c60053c078cfffb906e6efe764a0f853782e948d7bd19569a07c035864d9d5f",
                        "size": 953
                    },
                    {
                        "created": 1698756895.2014184,
                        "modified": 1698756895.2014184,
                        "name": "/dev/shm/incoming/2023_10_31_125441_00_01_V_078.jpg",
                        "sha256": "18f50b48de60f691ca7c662e023b4db314c8415764904f2ded21647f62c1df44",
                        "size": 2045073
                    }
                ],
                "mission": "OSP1",
                "sha256": "30950ccd7ff6067e53a63c8a65009b606071f5dafc89cda291749641553de296",
                "size": 2046878
            },
            "errors": "",
            "filename": "2023-10-31T12-56-59Z",
            "size": 2046878,
            "verified_at": 1698766153
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698567657,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "uLxuFXtecRFLkUuJ5b2ExiQkPbhKmTpZma+e0IgsTvgZLoBrKr14DaD21Cr3tAfyIYIxYp3ZCuWXDsCMDXxuUjeuLrmads+N3sa0Jwe0AoN17c6CS8Imb+QhjD1622EiELHuFODZcziiknPWsrsMGGCEoPEHzmTvKl1CjFY0yeBQ8b77YfKlN2D8CLCOuq0lmenGZuKrcmDyqqL32hM9has94DrUNb5roMRckUJ0CoPzUOaClJ0r+uoLFVC6tfm59wi6NcspliEfLTwy8K8/PIS27vIVBwXQTJq9RcoEk4+tSwLniTe/Vt6pcXR1mE13ifbVa/IE9W4HKVT53hCe7+1G9vYF+5FF/Eckv9kmasimzT/1zz6Tq26asB4z9f3dBebSFj4uxwwzalCCiyL2zJurPu0tAV4VSs2k4Qxca8QwYSNVT/YZszPGcCVYCtdi1LN8kRGFaud4Eo2ynoLe7ytVPnEZh5IBMQ1rDIw11hZywTi6JOMkO+K6AuQVkIsN/J3aoI2CwCw3kr15WbF/LVO/GXoYYa7y81oPmUM/68JaxizC87ZQ+4xHGD7kUUmdM7dnnrq4kK9mg5nnvsjY40xNvM9S9FP78abKRUBFJBQynjTqct26Z6m137XwxM+0uBS3Zn3lPkAtEMsK+9aKtjQ3kwdSRCj5pcGQerFSSrI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-20-34Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698567506.667969,
                        "modified": 1698567506.667969,
                        "name": "/dev/shm/incoming/2023-10-29T08:08:25.ais",
                        "sha256": "b6bfb2e4ab9af70459da28602c1a319677081ebd7fc21f35ce626ce77315f6f0",
                        "size": 80355
                    },
                    {
                        "created": 1698567448.3479688,
                        "modified": 1698567448.3479688,
                        "name": "/dev/shm/incoming/2023-10-29T08:14:25.ts",
                        "sha256": "db5b82fd66c5bf21b83a16e36146b1e0eac11aa902503f656cddccebeef03e5c",
                        "size": 32158528
                    }
                ],
                "mission": "OSP3",
                "sha256": "c35877fbd08d0951955a30fef74869054c5e4d4d082c13531a2bc408325a9fef",
                "size": 30782319
            },
            "errors": "",
            "filename": "2023-10-29T08-20-34Z",
            "size": 30782319,
            "verified_at": 1698578620
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698568426,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Vt27UuRp1V+uU4T1mEFHgtpIVZVXe+zgjQelcZL+8zsvIlfvynWiT2nVdgtkJxsVHhZJrJAmepP11NhWmN8qUx4Hf2MwOounfrgwFpB8UhkF82+e4D1HfEqelquMRgcEpI8AgEzthNMA8sGogJyV3xC2vsiZf3Q71zXZkNfPRiZIJlK5tuVzDygPhjn8iFHBZqK6YI1xxcrACr1wlVLuZ2EG437RYJZK6U1/nvhJ8CbIkDbGGD/gvtPZuQavhQghXe+6W6JHPyAzGgHv/CrhNXiSs4xcEGNzvbkpW84eXrpIUTP9ZWIHG89tH/oYT53efZMrNpZiQiXEcsj98Ng/vm9/qTsz/9/NEYqG5aeTh17Vw2R+Ng1DUWniqBfIUhyy2GIJhzdvNcS31L5xRxi90gQVwEb/p0uZFlKMYv0P4XAB9BycIb4HOcgzeoI/2A8IVky5YhJC20cuzCFzF4XiBB/Oc09KsBo56BrIXm9wxdtXIlXXQkrs58tXHzKS33qg7OwhH8TyxcQrXAVoqRtzfYfS2tObA6RDUYqXfIhYbf2NnI1Oy+tRSy/1rAIhZYs3pwtTjLX8f3eZVtvwWyDeXXuDgtoLh3O4WKjGWUW/ZbdvyoYEyuMeZZLyxQB+WqZN0VKVt3DzORg+e8wRAgsqPfP6KUY7r82UY+mMFiWanhg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-33-28Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698568282.8079689,
                        "modified": 1698568282.8079689,
                        "name": "/dev/shm/incoming/2023-10-29T08:31:13.ts",
                        "sha256": "c96a95babfe1cb5d661e5586e1021f4034ddb1ec71341bbc9e79aa8bf193f477",
                        "size": 912364
                    },
                    {
                        "created": 1698568267.647969,
                        "modified": 1698568267.647969,
                        "name": "/dev/shm/incoming/2023-10-29T08:28:55.ts",
                        "sha256": "94d511bec600049ef8f4bf7c6303f7d688bcbeb706b898833f8c073d9e92db0f",
                        "size": 21122740
                    }
                ],
                "mission": "OSP3",
                "sha256": "9c4864acfe7670d245eeba212c35e92a868e88611572a3e15c023d15afb68340",
                "size": 21033499
            },
            "errors": "",
            "filename": "2023-10-29T08-33-28Z",
            "size": 21033499,
            "verified_at": 1698578645
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698569448,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "nHKwLyIG1sPkKIW1Xu92gg/BLKhqOQ6hu1i2/LEriEvTI3UtC2kLyjiPrO0u175jR44tIqz/SK9VOimRtuToqS5KnxCC9FPg6OCXDHO6mmc4yxPwKn3GQMUEZpVnXuybnYFqK93E+zci4Cvs3RI3invgnWdojR5GNJe+OGh2FCgYLu+08ALBJ0SxAWLNqVhcZm5ekIeFQRumt9KWevQFudbk3zY3DAVBTGzG2d6a7HdeL0G1Vdr21kk9WEXLHg6vobqLu3Lv9I8yuzU0/nyGBLhi3aKLHFxyyqcAMDBVpMCatrDeYFPFhSQRwUZHUYn6UiN1QgB5TLypTJVin1MkTIxfiNeyGSh/G9hkX9GF3hyR6L/HSCHup+zVTBe0/Arty9C3buzs0nysgthWMXHy4rJ4CdHvDBAIoC8V9enHW8vb3o2PNgUG//v9DALn4FT3hzEXHYBtX6RXF3oi7e9/xSFkBVvX/mgV0+dTd7o7cly/dVrLs4P5EL0P6oF+gGjc2ciUOkeCtu+e+KRis7b2dyUhLE0gYcO/Xy9jlxqBCR1MmWAMD0pHzkeh5A/EuxGvU6YIyB1BkesVe5g9raSxnw7WDcJDYB313Tvt1TLUVbHSOk4j7nwu9ytbQg2iWGLN8TLLQ9c4x+7j4Z7MPK+3hFMTwFf216SuRBcJe86li0E=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-50-36Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698569309.0579689,
                        "modified": 1698569309.0579689,
                        "name": "/dev/shm/incoming/2023-10-29T08:38:28.ais",
                        "sha256": "58e3e5bbdb00e79eb2c00de90c0255967dbd77decd16810a464bea47ec78e244",
                        "size": 323275
                    }
                ],
                "mission": "OSP3",
                "sha256": "acb4996a9c18f3bf8008b34047187ed441ec420d2fa60c3fe6eb59939bd8dd25",
                "size": 94699
            },
            "errors": "",
            "filename": "2023-10-29T08-50-36Z",
            "size": 94699,
            "verified_at": 1698578656
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698568909,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "QOSLDCkgaROgMkfF0mqm7x0Ml9qVV1d4XL+An633EnyzXuQC85mB7KV/ztSBVWlZ2Cen24aGjbv0tkQ3BBRIV2ESBG92ZiJBaoKshySqGK8EDs/tq8V4XGU219aSSK8iAC0dxle9u5z0EvKXG4emcmJ6TO1MLzhCz6I4hjOPB6v1vj9LI5Gcwxro+FFCFjI5wVh4A+k81LXruAGbp4CEk7Nix9S41MDXsF7Kf/95tvx41yJngaK5agzg989P995LXILToJmd2L3Hq1FYp7dM3kPlPFZcQSBp3qffTAO0bxRnBCpLcR4HSDMp/4FdomoLUC85YAqc4pAUeV5LtVO7kB/Rs3SS1xYkTOqttUnHJSNMoExH3SdG10TUHpOaJ7eNbagjcACQl6XyCTcZc1yegbgefGlMRFCAogHXbK3hPw8cZscvxRxDkpsaoR8jdjxGgL4JrtvuMFvJ1yknp+eWbrtuOfZak/b/Lc2YIh6LIymEfYGc63uVhGNkxnkuCFm9uvB9hdLxY4IyOanmuomuLTbQOiH/NV9Pz8VK2qwjpoH4MgW0dEX+8EphTxUo4YUUS3ELD3AEFg94aGe6fqajO/vN71LCihWnXKJCgoUNiF+4CrMXTLwJI6VSC/zPTe1H5ccvqrYZgmXWYoAvsEkKNMKncaSUTE6tBFOMLofeJ84=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-41-27Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698568760.3379688,
                        "modified": 1698568760.3379688,
                        "name": "/dev/shm/incoming/2023-10-29T08:38:22.ts",
                        "sha256": "da3423450a633b5415465660122251ff01cb00b888f8e92eff203bd4b876b68e",
                        "size": 10316688
                    },
                    {
                        "created": 1698568708.1279688,
                        "modified": 1698568708.1279688,
                        "name": "/dev/shm/incoming/2023-10-29T08:28:27.ais",
                        "sha256": "c522617b83a7168637c23902ddbfb6b88d6ee88d24e893db1ed71c5bde6a5caf",
                        "size": 186217
                    },
                    {
                        "created": 1698568697.0979688,
                        "modified": 1698568697.0979688,
                        "name": "/dev/shm/incoming/2023-10-29T08:36:10.ts",
                        "sha256": "667268253fbb58fbbfd0d9c53b9aea60bc08efee748bc4bc0aca579f8cf08118",
                        "size": 20419056
                    }
                ],
                "mission": "OSP3",
                "sha256": "b8fe6ef3b3800d9e8e5660a6095678324a9a0c29ca1b4606dd7490bee02544aa",
                "size": 29423720
            },
            "errors": "",
            "filename": "2023-10-29T08-41-27Z",
            "size": 29423720,
            "verified_at": 1698578673
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698569931,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "v5s2oElH4Q8IVKd1OugfO5JTRvPa4oYlp6kr71ahZUSYHdZiBc5mtZ436b9crXhKrZOJJHl8V5gKdcD01FbZ4orZcMOnQHc/OX8zGKnNQEjJ0I13pRAy99gKwF5BSvuw3dK6iR2B6W5S1DV3tfE3lr+m7zCTbhfUjxtMcB3wQahgsUruMFNHiHYcfpw4ZaASkJEjUDP/K0bRryHR3AFJ5a2FtQPRJPv3na8MlDjkFywJCoCVs0ayhuy43Bt02T97WJ1Zn9Izeg+D4YdPRnYvMUSU/OzkF5qQm1z5yJQowqdx6+TnI4QdTJ1e4F3uyE5OdhnBK93lbZ3Qf3ZIZls5Z4O2c/4BdZEdv7qAAqAc+pW364G/j+bxZIi1M66IRkJkIh919j0xRUzlml7hLtpqUMoLkfaEstQ3UyjuBJwdGA43IhRVckDFgCEv8pPPR94nYCbgtVhesc1OGn3jmDRJj0lXB9NTLfWcAhuE2cyKgIS0O94f3JrzLek3J166KxXTywEOyDbNwAHg4W5QF2p+mts/1Vd+zYoIB6d3XJYs5aqHozMxlm4SlTdq1+WDEI1tq3USZmXj3KeHXFxXJC+5tpYhff4lL4CG3M9tqnBIEsQbwGSlpONZ/eyju2BEMoEwDhRBs/p5U6nAiR9xubjCSFEa6vxilrBKKVaiZXq5q4k=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-57-41Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698569854.427969,
                        "modified": 1698569854.427969,
                        "name": "/dev/shm/incoming/2023-10-29T08:43:26.ts",
                        "sha256": "293646003eb4ac22e853090288a7d3f0729817f3b6a432c53ca543189ffec7fb",
                        "size": 166637560
                    }
                ],
                "mission": "OSP3",
                "sha256": "0988b4f21d0463719030bfd37643ca51c1c1688361ef8031fbec36c2b09b8b1c",
                "size": 160231735
            },
            "errors": "",
            "filename": "2023-10-29T08-57-41Z",
            "size": 160231735,
            "verified_at": 1698578756
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698562741,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "uFb76YlZQnK4QamjFqnLaJiXgsMfFfuHff1LWUASYs8Q9CeQIX31Aol3B4fcMuCl4cj/dog/pNKrm1BCNihRlHZ/aAa38bnqEIsI0txUFX5LKcnP3gB/PLJkIXndvRqamII97cRvGgH15V3WzwlB6SxZgUGlld9O7oncux+ktgNrx/fUmYFSRPyhYndwDmN1LB7yQiXOOFHjEbPJ88Dgqykqe24LJDJ2Xe54W4NYGQUBzqj4sXrwYG7jDAFbqOCxrARlA9ZYJ9h+EeVIz7mzAP33aXnRdaafQoXPZmXOgTLMRjzRLBCucCQltlJjD0dIYnrcEsC0CyMUr7zf+OTPtv8qLes70mO0GI9ZANk2B7QV2kTJQtld9jyUpB/+pcP+JFwM/ElGAxhLxco6mpeuvRNTJ9oUuorSJYWqUVPymhH1utiEdD26YbywIfmYxrjy4S1vuRTBGp/r7+HJn1a43yBE6KcMwYUyT5mKgGqImkVSB5jIvKF0PdtUq7cx0Y7YboD8QtQHo55uiOnP5rsVt5hDLjcByIvqKVuGoCPLJZjVeBpdsKQPc4KnVUGD3M7tg2YZoPUeKqlWIylUAY0T62lropAn5vI4LU7rpE6PLdOFH/jxIaTpb2u2LZ5HrrwcDxnXzNyi6BZ0LJsdKUmFG/WfnYnXXuXyJidJc9TAcsQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-58-11Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698562682.3409402,
                        "modified": 1698562682.3409402,
                        "name": "/dev/shm/incoming/2023-10-29T06:38:48.ts",
                        "sha256": "98171ef11da3aec02da9f29d19e4df02731eaeb1c9bbaf0d3458ca79cde9d5b8",
                        "size": 259242976
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "d903bf028bc0995660ecafb0aea205888c26d9fcd0faa74d603fa7a07baaaa5b",
                "size": 250159965
            },
            "errors": "",
            "filename": "2023-10-29T06-58-11Z",
            "size": 250159965,
            "verified_at": 1698578778
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698563689,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "mQAa/5aCgk+q6Pq1bBuWlXQhvvGR317uf+OIOVuK61adbnDEZGvHY4mTViqeCz1ukUN0Kp9DKPSXF/MNauDxjVeyeqg1PcDq65QeRIvpyoqpFAiaIfgaJp2qnk7dyvHVPAqYWDIb0AHNXnQmANxfRdFigin86m5HaRfuxB7mCJrvk5zPDQt9s7qyfRcHkKfGQYJ0zc2QumigB8vfVGa7Anl/cRtha1B/5zsMRBTXtJUs+4dvjtWvWyXzflQHoZ0UZTdwXYbDVtkL8JsGCc50VDKml0LDdusRnSvhkIHg+r5XOZyr9TWwTxdborNx2NPXO3s3v/ilCl3GYN7M8LSocAAYlhLz9/rZ4YlSYV7GUL3Do5uWnlr+6IqxrHnRgFm9jC+cPs5a20dRVFjMuBBHZikwxPkI3tbSkBejpvzjNz6uXbRURhEJ+orPLstKwRCEvYW0VQ3xl8GEX45zRI8VSt7/jRIJoidv5Obdo5lJYFPK4tz/cnEt81XUVFwf1ybjYJHIemOyFbxhE9TvbwUzZqEn1xwCPLe/8ufnVgb/cgNwuwY/MEqkXlcfp99yIaAwphpVUgEtIyBSd9rUMJAtpgjrOzFi2GniQYcwDZAQt3MpvVt0LpSq1LJx+Nt5RA8VZyKU+zfIl7i+VJFo73gmEUqY3hhZTvP5cjb7b7Zr0IM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-14-24Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698563537.97094,
                        "modified": 1698563537.97094,
                        "name": "/dev/shm/incoming/2023-10-29T07:02:16.ais",
                        "sha256": "cc972238a6b1bafb2d7790c2461ce72347d8662d5ed5aceec8cc66c31fc8bacc",
                        "size": 228794
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "0aecf95ce39d10fb0740386653686db6cffd4aae436e8040e932dd88a0cf1cb5",
                "size": 65364
            },
            "errors": "",
            "filename": "2023-10-29T07-14-24Z",
            "size": 65364,
            "verified_at": 1698578848
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698563083,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "JFa1O4OqOOOiFHngZUYnrNC70j6pTA55KTUFkKgbrG4WFDtpV+BNN/NviNrgVQtcAZR2Dbv6iQXNQ9CLDD7HuKI12ZuKM6cftZ43bwY/tmY5B9O5/jukTC6c41ok0mryA1d764841F3xGSg15DoYb611I9mMVWDbZGbYAnhz20Nga3X1qSASyWnvZ1HZVWlzV1LEa3OvuD03jyKBkXZ686JmG20Dv0FAPttIrsBdKlbz9H1SW+7K6XWe88y81L+b/ylI+f65Uh/5oAJw2Gddmu8u4TpqQX8ODdinXlwFb0H40+WUp9FkBivq5dit0/A7Z9H821fcC+tC8J+SqND5jAKN83rVpk3S+8BDtepnHOAWvD3wUgTF/IPFfdfvYRAy6eoUry4ID6+j6FkxfjUnnY3sP7h5Dg+m9H38RsSFUZhi3QJmzdOjWqPDkLlzfKAPOxNyOWSYeWmkzChsIh+xz480EX4aB7fC7Z7LBnqlDipAbPvtv3ukgR7zb9UiPC74VZZI8f0dDwUMBKPrEaegHfOok2qrYtxzP1gvi9A657JkMif5/SfCmiEkoi+jB+uYjjZ19gdaYkZ+ILMRTmnPBRyLxU7/2lSFNOvsbRif+8vc1I7kMC4jKjyLkETgOPqZCpgMEswUbZZqM7kdin7J5TOqoqK1IIQZSwIQ9AxNzbQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-04-20Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698562936.1709402,
                        "modified": 1698562936.1709402,
                        "name": "/dev/shm/incoming/2023-10-29T06:52:14.ais",
                        "sha256": "462d701d9a73221d5d97c18ff6d3092989f74b92d5d9d710e1313d05e593de16",
                        "size": 148212
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "fde301f38b30f31eacf50435af8511f8dbb34db623a508b8a69edef7c9b5e9b0",
                "size": 42116
            },
            "errors": "",
            "filename": "2023-10-29T07-04-20Z",
            "size": 42116,
            "verified_at": 1698578864
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698568146,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "eJ8glqH0ICLoq57CMDWrMnvyCR0yxLdqS1EnqTx5VIwqsylRUiMw13Asmg6ikb1VW5KB1QUXu4tFTNfIqhxieSzi7WeVVtEFuFJ/fJrlR6FJfXrw2RUtbNYDeCc02uqmZVnXfhYKKNvumpNm5ow4hA27LJyibP+fRh7j8tYKLrE/sTAvqhGcENrlaTLG2kt9bMpPBM0RaDBhKiMbo38Kd5HC0FNefSiYTe6h+Cji70TNvuajtINV3VoSQuRzZL7NuLS9kqH2L3SwHehBEMDsaXQ7LMKsYSgnBKKXhwj4v0zSudVWZRk9koJQJFxwkpZXkbtBVwuNsTzq/4WEuwF8SB1ezwHtoORN/3WPsHnnUTaixoJ1GxcS38s6jOHqX+dBAvtED+KCl5QijNS8SZ893Jg/t0L4kQsTso8jbwl7MHL2j3AosAAoN8Uy/CdyBHJsutLshYSQwPphIMazfcrc3eRW/sBqITfcCCJEFHgZFAatV4eV7gIegHtT8RHxXq+e6bUSa9RN+89UXx0joAwkpMyhDsN2ihnnDlO5iGOXvIyVGfPt5TNtplFKI1k1RsbQox4CS5YWQkEp4vef4bFCgghWFo1Rbo5WThXi7rFOjr/FFC0CmwvM/eOaLKw1eFr2yFKV7PEkgInTf9P1elcsOJR1pPVFeiIRTGilzyuEn3c=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-28-11Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698568084.1179688,
                        "modified": 1698568084.1179688,
                        "name": "/dev/shm/incoming/2023-10-29T08:17:34.ts",
                        "sha256": "f343edf7316720f6d4de2a40b73dc054a6898b64ad9e368ff3924e1261f00091",
                        "size": 115422976
                    }
                ],
                "mission": "OSP3",
                "sha256": "afb9170c3b288ceea847ab1ed273b2c6b3b000fa2cd11d2f8b488ea36434bdd3",
                "size": 110540767
            },
            "errors": "",
            "filename": "2023-10-29T08-28-11Z",
            "size": 110540767,
            "verified_at": 1698579015
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698565493,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "F2724IbXYmAaK74E/jnMMSN7wzcX7tOTCJlPNbrndtvY++Ywj8RNnmqln9j4wOXuYPj0KdY35A57KiBJZoL40rEtEefYcJNyjvP81hpSLAoYvhG4KB1VIrL18Iukjz94P+rJe4LL1y7rNb3jh5OTJqyqTigD/Tvt6ZchE8fvtFEEnezy1mFrYpeYODY0Rxvr3lrrcKwgx3D40cZUs0N81jTzFI8JMo2NlnxAJUSBwe6YjZ470foFexcjOZ2RnaHGu11XkaLR43rOnJfLoopFPhB86eU73fAAONsHHJF/hTsfZZrdmaouzvHapM9BG2PViHYmEky0zvBfr63TxDKNClIMGqJU0RWiYIRsz5/ntl5G4nns9oAj/7zhhZjb5I2TSNdP8J+S8rpQxbUiXh9VGCZNN6bvJn07ZpH/RB7fhQ02vEdoyRhUJhTPEv3eX+2i/9eBuJr3DFeTCHnDamPQRJgf9HQnzFBXJRvpEmhObvOBv2UN50htN1pd6WvQxE1q0IKhbg0FUeNihbAUbgFKFIsOimT66X9pTbLikPD5SZtAz36d58x5jXmnArN+wn45I6UuFnQ//hPRJUuIRT0ioMaIzrigdSFEaRUqQO6BfCiMPTRXx2tsIJNrQwZQoFtYCOde2a55eISJQzEJztYrLxKp/lMBPGHZPKDs0eyKSjw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-44-27Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698565343.1209402,
                        "modified": 1698565343.1209402,
                        "name": "/dev/shm/incoming/2023-10-29T07:32:19.ais",
                        "sha256": "e4cc44fd82cfb9b5d7cd06cf4eb59c64673d7a36eb41e36385e2f844114f2b63",
                        "size": 103610
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "4fdfb836b6cba22a58f70ca875899fec50422270aa4b978a9125ccfd7c213c63",
                "size": 28222
            },
            "errors": "",
            "filename": "2023-10-29T07-44-27Z",
            "size": 28222,
            "verified_at": 1698579031
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698564888,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "QelNBJxGvZDdY/qs0uM+384DHGM1n8aQ16udnsyrpXB2XoS00+IGEl0WfYkNIDjmybMjc0mkEreofWdHqnFe/iecdQVhJzsFODkxxKWAVAIDRM3PTgaKVuuO1Eg3OFsZlSFPcYfC84YqfWnWrDxUKTP03TqDLtnvD8sZBl438D45Jak95YXRKBY+pE5hva1E8iGaU5Q/l05ShXtLkyb3NbYYLgI613d4U8comiFuiXl7+Pw1T+bugE0KunJ5dkCfp+bpcjZoxjRPT0MTXKvpPPtRfi3HhIbx3kc7m/CR0QVPTvQXPf6HevX023kEGcBKnUGB2thja6bIVkyrfJGnjWjxgmngG5pWjaWnQtIihDu3UZCVEJO/wTvATNQ3m0G9rOlWo7NTEgNk/dC23gs3cLU3ougmWl9PvBHRu2koLcPrwh5fF2Iy7AsZvnbX5xpmm63dFCs01usvIF3f3fLpYwrnarL5Y9G0ATzZkzbzFs3HOtf40pZtqCU2KLdL1NVIsOMhSbtMBpe0L0ddwwoRX2xNPG83pXyT7mII/a/teVCy/KCtwh1UICWSIFeHy61enTV/ADT4dvxEqo0yOo1gX6lyaZiYu0dEUsSkpSOMLFfgjaxFGtupWOskxLMSzxRRCd5UBSvtip5aJmqQas2suCLqS+KZrDGIlau6NYKnsYg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-34-23Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698564739.7709403,
                        "modified": 1698564739.7709403,
                        "name": "/dev/shm/incoming/2023-10-29T07:22:18.ais",
                        "sha256": "436b0aaea35311dac62ddc07cb733a612e5553e6eab9b52aefb041912af50080",
                        "size": 103205
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "274c2f2a03e73c8401fc1f7a3f1fcef74f9bb36abe6d8d2281e06fa98e4ee135",
                "size": 29042
            },
            "errors": "",
            "filename": "2023-10-29T07-34-23Z",
            "size": 29042,
            "verified_at": 1698579063
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698564661,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "eDogNZpHjgQmVAhsRWXWnWMn8xL24pRdpOdZuuT1bnAKeUg853cnPNDwfdyo7WY+eAr3d4UNjwuMaupkTomIq6uoEFdckqD3zhno4NvKJmbW+QKH0oRLKQjuweDcBlpLv5y/gCjzomHVPv3w43DKEVPlvomsa1pkBR1mamOYPqEVSuugTh2NkCpklsm0mPghFk5LSvMYkCHY40LlBaKSd0+vISmtZwFQXIEc4PhEAWJzOlq/oHxGP1yjEVAZySdOxikVjF4zJbXGxDjV8rcwTKMJfObErUN07PMKeGpzk0FtfvOli4tW4rQ4oREswM7rKQCtG+WZ+KS4vd13Vh0PyEazFbq57IXPHBqs2LgujgzdIg/kg6M+oRcXTf04aNrsrynAqsp4NJVGfCft9hsvTWox+Z6URR/bs4Gzc0J3KtnWCjsjDr6F3KofUJHRYZ7jWT9Gv4J80V+a9x9G/Dmtkn2sEkW5GHBO79GXsr670teFeMYfEjJrRSgk3Pm9IsF461y84IWj558ePdNoGTjAmo+rsZ8lVdiqeIDmFPeuoHNwDvXraWQ4BG8llDEhA+wxU/8ty7zTYnD0WJyEpLtwaRNivFnKmsyYxdEfcnKm+X7MBjD8ieucQiEZ4SdgLMZJ7qK9VY/0mGzR9rYe9dRRx3AghlQusI24fnho6nreebY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T07-30-02Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698564594.48094,
                        "modified": 1698564594.48094,
                        "name": "/dev/shm/incoming/2023-10-29T06:59:51.ts",
                        "sha256": "6d4767b6015453abebc6914a1c587af8955a3f0ea9220213be80b34b5cd8a92b",
                        "size": 390744276
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "ce337eeb722b26fd3ffa029ee6a3c168c2c05ccf522d44223c9bbbc71def0f9d",
                "size": 381000514
            },
            "errors": "",
            "filename": "2023-10-29T07-30-02Z",
            "size": 381000514,
            "verified_at": 1698579108
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698562484,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "GJiiPzqKOOkgoUUhw/0JbpolBgvKHrG2j8BXcl7wgSBZjZGWCzAfzTQVIJnbPBlXxOHuBIs/piMjmKRzwshOUt4GdTNfThbr2qZ5hAmF8SUvpeUtQxve+xNeJG3mq6chJcpy5Rn7b9WzMM47s/x0N9er6uqF2kSYIvaAfRIWPPMP5eohW65ml8rCghhgAnantFoT9l5ekcGL6ZNZ1fjiDd7PalSaoMogOUETWX7FYQ75Ewjzqa0ILhr1WGoqyVILNymYzErceuu6IB5Jv0+vvsfdreEUCpaUXII5jWrEolaDGyjW/jdAbzlqIHwWjFchum9swbJgEl810fyKNL4yi9PgcnGPS401yDie8pp/kv6qNApJt5GQoFvqR6nOkR2CrJhSF9HrE7DlaZFZ7RLSzS4GYBKfsnjxTds4N0VhkHowb4oTF2XqcrdG5dSDB8B7yM0OG4g7h7Wt57pRdOcNruGK6aaOkXISyjyOXu9ZpgkV75SsvLr0xAvIIVeJijE/vdxwKWDp2jsJNxbem4WtVXG1Y58hY0J8OKUA9lLprdEfv37+jlZal2T93jSnfMNJMhAlej8SVhZS5cuRsVf1ZkeQHRDbWxflTCYa++TWUKOVtteNGvYH9vlX1t6pb/zs0HH1/rUaKwiyDbdWFo1DJ06re9oZbhfcLMH5wyDVvh8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-54-20Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698562334.6309402,
                        "modified": 1698562334.6309402,
                        "name": "/dev/shm/incoming/2023-10-29T06:42:06.ais",
                        "sha256": "a317798ddaed8abd3c63b189947911fd718e7574979dce6b9061b3f9a1663ed9",
                        "size": 122263
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "f68e2c4c3edeed0564d876c45f5b581590f733fa223781405d58b9688b7e1083",
                "size": 33934
            },
            "errors": "",
            "filename": "2023-10-29T06-54-20Z",
            "size": 33934,
            "verified_at": 1698579194
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698566442,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "dRJJb8mkKLq4ccsAnT4L6LqHx5l7Xl8EyNy+YNgLDdoyNXMvvrnTe35x80W7J4zwbGItHB3pxRwY2s6TfHS6ItsXbO+9cRuTHaVo48XuHFBIzkvzQaRFfusHwo/fqRSBIT8lBkURqRiSf5vCb2A+B1ORwHNiN2MtRar5eKCqsnq2XBU/Zxm8PHKIQ2wCg6OB7UjAdbAOaN85BmbwF4pnoImlHeXgnlDFLxjiyGfqaJj6pm+DzkdqVoRjsWb8eCBbACzenFvf5AVDFbsgiDJ45QZ2jzIu4JtL6CDIPM6f7qyjqTBXW7DAOnxCK4DGcM64jLokOGCDTthGBvdXXhYNBdjtJc2uSCtbLNYmiY+vk0nGDIexxASJZ6zGhAjYjW7I8uhCGC6wVRvhSGu8EQckwDxryhOUvLAzvMfmu/VQjRDk44cYTOfKf/C/TevybrqPNrv8oqwny1oxNenD8fHhZM1/1Gezl+lfZ7i+VnqrOdoWCUQJIPOwSDXbi6PNKCiLX8+SRFIQqBZ5tlQKH9+dg9ccYKDITpONwR1/gfpu9yhE0irPWVIUPvwPrPFMtWfoAUR3POj7mgwOVLyf2L54QVkB4bzIpzJoE+0X+E4puqbsHePmEFZ+GKs5cRL2xAXNwekZfDIbOTxT+EbNgOcDAZlV28jlTSUhq7HPr5ZUICo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-00-33Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698566304.927969,
                        "modified": 1698566304.927969,
                        "name": "/dev/shm/incoming/2023-10-29T07:48:23.ais",
                        "sha256": "cd7b5481ae0d5f229941ef5424063af99526ddcab2ab101fe9986fb95c3a7fff",
                        "size": 59043
                    }
                ],
                "mission": "OSP3",
                "sha256": "a6b7db2277ec7e4a6e18a08759da86974c6d5ee501cab34d459fecb344a74030",
                "size": 16716
            },
            "errors": "",
            "filename": "2023-10-29T08-00-33Z",
            "size": 16716,
            "verified_at": 1698579199
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698755498,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "J0qFJltNy64CODv3zUFTUGvQao4dT1gyShzUPVA2T4l0fOmuDL4Yf13Fxnc9jWs0UMoiULGr2S4/v9Bc8MTeeOz0VO9STvjgHWdapk+02X4vQQ1imq4pl4rkcbebPakL58TtDgfIqMbmicOle7rVSfNvVrHGNbZPuva/RgxqpGQS1GyWcnhhhItGmS56UOaJ1Xmp+FQXSKHntWaQdxjCK6Q7zl+RnqDEAPevAzx8xruKs0Wlm+vpDuBZazZdXxVxOnmBMoHKblOlSC7Zj/GuFYbBzG302ekmSmOOa5yUxzM3oJBsv0iuK4yHmDaeI0rc8NAytGGnMk0+irqxfQeUTWXoPc1UtqeLNjgxcpbCqp06uZDq04LbLUo3GIW2l3FE+wN4NuH4RhUuyhHmwca1Q/khNhun75PvCYEnvLzuFe6a+sxQaCT3gS8bM+Rko8weeGPantfEAEwS1H7fbfTwnsjIMHw20UPu4Q1BMzlPxLOmm+dLcM5AjoVyl2T7K0mZXYTgeM3NT3DEk+demIIzg2G9KyA+QslCy9HdtCDSyGwxwjTbukH0salfo06FPNjD57k8D3imNVfm7lgzfRfawcy+va3Bd6+vVfUqWGXDbUXzucVgmuNrT77TAAV7VUBBwennTU3w/ya9I1egTji7motTIvOH4hlamsTRPJ7YmTY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-31-27Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698755364.0514185,
                        "modified": 1698755364.0514185,
                        "name": "/dev/shm/incoming/2023-10-31T12:19:23.ais",
                        "sha256": "39811ce02b1009e86bb932c88315630c6069b0ac5a1e93f47885c3b046e04468",
                        "size": 245324
                    }
                ],
                "mission": "OSP1",
                "sha256": "41a0e98c8e66cbe2623ea04c092c22c865c76a100cd845a8e1e414968300b35b",
                "size": 58534
            },
            "errors": "",
            "filename": "2023-10-31T12-31-27Z",
            "size": 58534,
            "verified_at": 1698766250
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698561680,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "JYq38g2UUR2WQWQzLVL8cGuKhty6iu0iMvGA4PTpsVmzS4UVpumlDktem2vL48Npt36Ym9N9fcP5XamHhPIId4qo5QBxfFxmhwhLkXo/qVyA7ktG7ZOLJyR4zwcC6DiLQL8qX/Na8uRbgqyDUYe32zUUeKMHWth1JFORv09SQR4PxQ5e2KVyVRGbxVJjFiyG3B7cjAXmgzZF17An66yBlNFoLeWilhVMGUXy7CBnbsziHOtOpPdXxOkhO/4B8VQ1by7gcNL7og14cosFt2FUYK+zSqsPSBDHsXtTcHrsPskaJl8RXWMedr2xi9b9Zzr8VPy5IlynN6AnHkj9YLSQ8C34qMxvZD7cdqfa/hnLXTQLEKmE03lwwUM05GnnhWbjHfTNl23I+bjNoKIBiBOxI45YHtgmCiEoc5W+Wj5IzqA1JEvf5xsyRxkQvquoSSBkHYvHc4JvQaTx4zNh+1S4Q5ACDvcOaUwCTGoyU9H2Ku1VI855l1jvRsWQTI+5BuPZMZIBGvwtRxkEcgiZ6FVqoPG1oTwsJsQ+6d7OdU/5h6EwJpLYKS1cG6fazr9Dsk2h5QE38gy32RS1mQR0trqK1ULSbJau7AZsU2SbmMQe2+TtiQkrO/hY2utHym2XNXTA/51lLYvoJLGFgPs3oX7sHX3vlcTiC4B48uiGYDSO5Yc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-40-53Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698561522.8109403,
                        "modified": 1698561522.8109403,
                        "name": "/dev/shm/incoming/2023-10-29T06:38:02.ts",
                        "sha256": "fbcc191b1bab66b6608d4b80521041289722fb62fd6fb9f1b8a877ffc40ad7b9",
                        "size": 8494404
                    },
                    {
                        "created": 1698561477.3109403,
                        "modified": 1698561477.3109403,
                        "name": "/dev/shm/incoming/2023-10-29T06:37:49.ts",
                        "sha256": "9a2b47a20a232de6e6ea59a0f1244013120812264a53d8d5e3c6d4f39b7c722e",
                        "size": 395176
                    },
                    {
                        "created": 1698561463.99094,
                        "modified": 1698561463.99094,
                        "name": "/dev/shm/incoming/2023-10-29T06:37:33.ts",
                        "sha256": "2c90065afec938b65a14ab75755f3ef846acb60537080563d0368683e89167d1",
                        "size": 1178196
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "2acfdb924104d0dfc50a3bf53b1a5a90a873ce8774c2c0be983722dbfa238a2f",
                "size": 9690211
            },
            "errors": "",
            "filename": "2023-10-29T06-40-53Z",
            "size": 9690211,
            "verified_at": 1698579209
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698562245,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "SsVCudLvF8S7BqpYSk0Smq7ltxO6aTAHmBikEXJCRyzSb2OKGzsqP4NjbRIFZ3Yq7zb0tJs4zTWtGIC5orpnBHl3mpm3zI/dJiFceMKuJwolXDYUfTF61xQvTZ3zgH0vAGefksWvujdfJNFO+U4+IYvazJmJ+8Kdk7dj8mNk2WRY+eWd3d6hDFsLpDuj2SpLELAWvpNsoFDwl7Qj752xuzqjVHbHuMxivdK6l4IqWNH2ZeShrrEoC+ZrgGm9XZ61vdSpGC6RGWo8djmvav0brR2w/qqzKisGEEDYHTh0e+VEZB9IB36KtFMEXMft7ySTGnz9QatrAC9aC3JpJwYg92oul5gEmHKt3XmHwOszh04rh11dnayysHRIirkFw7lSNYJSYlcJxYZnjHTVnVANc3FKj6fBnMpk7ce6Y0RInipNDEjVUUYtlc9GZ0TqDlZqIarQMOeUVKYq9ZomFq3nEA23WCiCS1M2z6MAtIsW2FLCiAjmOMhn12wiOsFc1ihN+lwSyJTQnGHyYOA2X9lA1Qv+17hMmLTH9T33vF8Rbp8Evnw4WDocYsOg0aa+03HdmwtPCyCl7sHvZhmBWayfMB8aKA8mpLSeUljm2UEe2EM8dHseq8+qMYzwvrbfkSax/Oork6psspSdiW+LEhJaJfWaOm/ok4Giak5lxxFvXbE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-50-20Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698562094.0579689,
                        "modified": 1698562094.0579689,
                        "name": "/dev/shm/incoming/2023-10-29T06:38:13.ais",
                        "sha256": "9a871b6632ea95ef4bc37a90699734cf7fa14c24393ca62ac08db2c600a6de0b",
                        "size": 72863
                    },
                    {
                        "created": 1698562055.3279688,
                        "modified": 1698562055.3279688,
                        "name": "/dev/shm/incoming/2023-10-29T06:45:41.ts",
                        "sha256": "a5fc7f28ebb85c14d156f8ae4d4b10c888e64a6231b867da8e4a92020cb507a3",
                        "size": 21898804
                    },
                    {
                        "created": 1698561936.0779688,
                        "modified": 1698561936.0779688,
                        "name": "/dev/shm/incoming/2023-10-29T06:43:28.ts",
                        "sha256": "4fe62897654db9930b398cdb9144d4a4ce59f83b49a6f41ebf0a6f02cf259ec8",
                        "size": 24421012
                    }
                ],
                "mission": "OSP3",
                "sha256": "9d981de0a880672907415d34121c14fc9a76b9ee3f045008812187353b67ab40",
                "size": 44401050
            },
            "errors": "",
            "filename": "2023-10-29T06-50-20Z",
            "size": 44401050,
            "verified_at": 1698579345
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698567301,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "k1B51tU0j8hC9w6imYFEk7yukRMET1ahfK/14uiSbFZ0PXX4JMqn6L392wrW4JPnIwBcSN5VDK7QtWDHpagaJKf8x3QrrMK8iejODQTHU1lY3WFqRoaAj05ENEN8x8BvvyV87/h0wYlFr83oufb3zZhbiDcZ/j3B8swHPvZWkoVcKD/8P7Cv7xocyPNLFX2+qyZB2PxkTQd3kGML6MwzVCbtCIlzZ6Cx8yVlRYbCUPPteNNA94kFkW61NCoaPifIcl+qi7EQZtahntbR9qg58egEadFhc1r2X4f7p0e+r6Osao3Ptq5zRT3Gi47hqbB+ZZ8pB7ViTqFuqL3/Vp9g1cIb92/CvhixpH+Zan50BA3rd79LVyLbDluvAq8Nvhg+EiF99PxudcuviqyVuDT3QWXeyUzTUVuiWLk5hLcWe7rsJV9luMcadHpHm4pmfQzdOwMpJLHBk9D7W1w0b/Do6MDhCGnhIIpCEHugLdLvb+CrfkG8fDEBJIUA19G9+Vtx3fp6E2taxvs4eB+DK+bZjMTLHXupZ+wbhP/ZAILO1lJRhUX9KSsA9ZdsBTgJst2ueHh7SLzI38BouGrwu2jZRSlQhHW/QxX9wv1REU/paC5IBheJBzD99LtsL1pNUNUMz3BeJNtte7Y8oe4ef0EUozBhCKj+gi+g8QiXo7MPMTg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-14-38Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698567150.2709403,
                        "modified": 1698567150.2709403,
                        "name": "/dev/shm/incoming/2023-10-29T08:02:29.ais",
                        "sha256": "fcf5afe9b9a5226a3a67efe858f689105faa024aa9f686df860918ec39b4a2dc",
                        "size": 99755
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "0acd4475255489cb1b77b9c6c9ef6e3c714acf43ff9232dd212315221d766202",
                "size": 27263
            },
            "errors": "",
            "filename": "2023-10-29T08-14-38Z",
            "size": 27263,
            "verified_at": 1698579399
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698560883,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "IU6uFy5Xjr15o6mWzkk9ReaRru7TEzHgHzp8SQHJtTM/EaKGpTfp73/bIjopR2VS1shPtSxz9KIV9tdm9r8X+QyGjo3yXjOSSzLuMUF182VQXWIRENX1W1mjb0uqQ1c86uSMuMziXAW8gMnMRJI+w6YHBRsQu/9FMtidLB4rsKrPvQWtz8U0im51nC4ZoVjT0CZU9ZNqtBW09TnLXX89DIsfVzx2QiLV6upJC/S5cFXUQ8KtuoqxmvHlnrE7TCJWQt1brUi+ChfIkb06jwnOMFt7bhRHvaU1f14i+I1DFkCBaPJSdh4VGtl42GvEWSzytqw3m5nhwOjNI3UgHXYyyMbgejU9aRKmBs7Dxp+XPMYkxjWoW2tl/GQYq0O2LZ+JD/t017EdgQVaEOVW9ZxEs17LeChtdpn1LxPdiKZfzhqSsr6lL5FD9WJx5SZ2s0k/NkVJqSH3Jo/NZmW8d1lZVXR00KAcl6dbElI/WnN/u+Y10peNwe7griTXy2u6Rfx2ibqrb6j7S8NEKoJMZGm41WIrWEoHxn6DOcBl/gcylrsY0SRpvYpDSq90tKWeIXt0+I9rO5fPDQ3UxweHVb0Nr7aFldTsMsdoEq/l8GGO57ManQWFU6STu2jkZBs2RV//f+FFO3uUEZQTRmjs7si+og7E0BOPUXy0OBs7WXWfwwg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-25-53Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698560743.227969,
                        "modified": 1698560743.227969,
                        "name": "/dev/shm/incoming/2023-10-29T05:55:41.ts",
                        "sha256": "6733d6e1974445c2c7380bc07c018772b1236d3b0bd09acdbca0abb6d948e79b",
                        "size": 291726744
                    }
                ],
                "mission": "OSP3",
                "sha256": "891d235c9d3f07760e73282dee2ce62ad93dc62d9042c7d8498b099ea3328ea9",
                "size": 283882975
            },
            "errors": "",
            "filename": "2023-10-29T06-25-53Z",
            "size": 283882975,
            "verified_at": 1698579444
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698561271,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "U8HQfmMW2pgIa7sCL4TvF8rqHRZvqQ0haaeKTVpfyO9HNjmGco4MaR8GC/wY6s8252XHY+TzF/bsij6NOTBSH0cKcg5VzbzM+TmAO4G86THTkSccMR1wsiS/omn/jt7r1+yz5JX+KwLI0Zh/ZX/LRpgSjtDyE1kUESEigwVhTcY59A9kLkExX0Uo27udbhncf2EGA1VcAY1apiX67tYtPLl/g3SI1pEib27w8zALXEpX733FmiwLkWrX/sVELSudKyjMV8NeXg9R1LEuarmTr4jkrZVeXybN9mlID1sNmukBdfKsZqusHU3uxzy+qxF7TmnlKdAtcdd1K/hjzrFlMUQULs6bWWSSo3rVl6sQkkHNk6TT1V2M3S4MmUUzMGpNQ3B4yTo9HfWNFzpOQlA9b1FUUGGWIeEL0FShFkonmOYt8M4Kedy1ZvLHsWeBUhSi3cmfVkr1kANwEURLkGgDIw3zBilfVwwOj+W1I0z5vfEfDZ/s/RLAEK+4x9sRWPKlV6g9K7KGNPtO9uOvorOX5Oir/argeQ/3xw7z2UZUc06v1SL8mumvdMbGtz30pR0gu/NadODZ/I+a69+vs7to0IlXll+yuPGk4/nMs+cRlOYJXIx+9RmwY/lVXP1ht2sLvelJTWclB9FgX01a3LXJ6Ym4Zp1MXhrQNEBfGNi2T2U=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T06-34-06Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698561118.00094,
                        "modified": 1698561118.00094,
                        "name": "/dev/shm/incoming/2023-10-29T06:21:53.ais",
                        "sha256": "adee53d43fc9b0bd6163cd5425f0a0d0f5fb3144da33d4598c4a795f49144507",
                        "size": 155167
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "dccd63f68d958605efde2b21bd0740646208bbb6264790ad52c6246876671382",
                "size": 42713
            },
            "errors": "",
            "filename": "2023-10-29T06-34-06Z",
            "size": 42713,
            "verified_at": 1698579607
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698558618,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "IwPuZbH+hV9mOQJPct59Q2F8FH6RQs+yfqJMZlWGb6fiQz7cRALSH3DDlf4SAedzqD91dE4P6pJR5ymtmwDMFkQCTIUuJcViiuKnSmYqGqBZjgCsTQ8hvEaY/hQOIYTXbgOpcEPW20ee7m4HzAoIftqGsFv7fCuEhz2Bds57w9bSGyPjwwJg/JQzew4sHIhCexVZH21z9jJiQ1vsCodxdz+l6IkmiVBg1uAulGNsKztIoRXyUFx+CNyz6mPeNSM5MkC5KM0t/TNxaYwx/UPxGfTC3uJqc88whBasdxPCr5UDKDcfoxkcibL3fR3q2odCvP7XVD77uyWUDmQCa3yHD7AfE7uGsGgaRrUR9YwRv1IJe+U3EZY6L2yugSIL/TBusDqElvYyQiDFbMD8sZ1wzbwUKKGIsJa8kF1D5/fa3fkDlSlmO9rLtf0LmbfnVPLSIAB0Ti1U0fv0Kfr+9z+pUJxkpiLt+92JlaSYtbSEdN7EMZMH92tB7wyd7G72KwilGBTA2Et9wI7tymlbVe02Mca0iix04k7E6a1ZSXI4wr8dFx20bDmh9N/47fK176UfAtUq39PC8E/8ZIRSoHc0TmautRQ9+zc1n3UnzWqdVuBl0ueBEsl49Pp0mravvuirCSaXJpxB2Kei59TtFTm/V9bKPs5p6JzFHeF4IsQsIv8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T05-50-09Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698558486.247969,
                        "modified": 1698558486.247969,
                        "name": "/dev/shm/incoming/2023-10-29T05:38:04.ais",
                        "sha256": "19e350aafff39bc14e65cbc85c2e2afe9f82614f188e0c487e090a30ec874fdc",
                        "size": 61361
                    }
                ],
                "mission": "OSP3",
                "sha256": "a94ba22480e068e64451ebaffb9883f71b26d1c1e85f04d259e0949535d4a973",
                "size": 14482
            },
            "errors": "",
            "filename": "2023-10-29T05-50-09Z",
            "size": 14482,
            "verified_at": 1698579821
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698579753,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "hPw9VQGAJYsoMYktzNxNNndP9BrbwviWmDA8jINGnFHDEh3KnKudB0BlZyk/VaDZCbqUylLKX24ydKVdZAoyc4sLDlM1PID/CI0KxVupb3ZqPYoTehmbIZ3cSYY03/5xxLUaMDkBepKKqEosU/6uhIOnpqi/jJJGOSbaEHB9TpnpIKh7xVRWnycnPRQFEy2qFU9vzKdcENtfANsmD8qTFgYKWAS63hwPL8d6H7oapJMM51v80bmnpBJ9ZKSpvz4V4mPWSvWtgaQvWByHIDuEpDN1Is+yZgGB6AWGYPOBTvyAKcMgybZsgDHztyFtdiPa/81m/dYbb06bg051xcirj/rfpQg/tvY/NXFm1AzyZJZdhgByd4F1yN9GacK6r3ijSyWh0zBFSX+JJQLlT9A9eK9nTGB8joMcYG12HanKlVbUqKp2rILuvWHWr7xHjCmhO1cZDkw4EXo9E+LElaim1nW2H494TSPf8LRl8T+2hyD7LKeUFn/iy5koM1KkzAfpXECKFDCciaKkNfFo8jkV3aNekJ55ANqgCX5yIAv8UWoa0ZXDRCuigjrItT1Bn10uPkAwcn5FrxXT0dAFId+Gp1/s9rVnINau8aZPM0KfhfXah5aBuAes+ze57Fkkslvto3zQbxb4/DnitlK3kC40TUaTKUqhITwm/Io5DzvOMNo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T11-41-19Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698579676.7332342,
                        "modified": 1698579676.7332342,
                        "name": "/dev/shm/incoming/2023_10_29_114057_00_00_K_117.kml",
                        "sha256": "04442536907945ed22d97d93c507ffec3398ad7f788893090bdbb1597b942f53",
                        "size": 975
                    },
                    {
                        "created": 1698579676.633234,
                        "modified": 1698579676.633234,
                        "name": "/dev/shm/incoming/2023_10_29_114057_00_00_K_117.jpg",
                        "sha256": "ed913e39fb3d31f9dac2871cd8439336c46c6d73a953428bb6277f39b72444e8",
                        "size": 886290
                    },
                    {
                        "created": 1698579665.1832342,
                        "modified": 1698579665.1832342,
                        "name": "/dev/shm/incoming/2023_10_29_114044_00_00_K_116.txt",
                        "sha256": "2f9ae9d5bc5a3377af8b1e052a16d5ddf0434784d4b9a5332d5f51fb3d0aec0c",
                        "size": 2046
                    },
                    {
                        "created": 1698579665.0332341,
                        "modified": 1698579665.0332341,
                        "name": "/dev/shm/incoming/2023_10_29_114044_00_00_K_116.kml",
                        "sha256": "feae2f77738bf37070d576200d3874287c6f60d53cba002b1243ef16e8e03b93",
                        "size": 978
                    },
                    {
                        "created": 1698579664.5332341,
                        "modified": 1698579664.5332341,
                        "name": "/dev/shm/incoming/2023_10_29_114044_00_00_K_116.jpg",
                        "sha256": "d516089638bcf8ff7c159cf5744310bf3663b0caf851de1fba06d10625de932d",
                        "size": 1008573
                    },
                    {
                        "created": 1698579627.393234,
                        "modified": 1698579627.393234,
                        "name": "/dev/shm/incoming/2023-10-29T11:30:26.ais",
                        "sha256": "2c9dc9b12c4575bb406d18343af3762a2a8aa178f6c9b270cdc42a3388b5ffe9",
                        "size": 298844
                    },
                    {
                        "created": 1698579620.1932342,
                        "modified": 1698579620.1932342,
                        "name": "/dev/shm/incoming/2023_10_29_114004_00_00_K_115.txt",
                        "sha256": "158a3148bd95abe6247fd4da9b9bf2fea1fc9b5a163def262f0a2de163891377",
                        "size": 2042
                    },
                    {
                        "created": 1698579619.623234,
                        "modified": 1698579619.623234,
                        "name": "/dev/shm/incoming/2023_10_29_114004_00_00_K_115.kml",
                        "sha256": "6834bfa8f6838d0e0d039320117b53bdba83497e09a9d2dc543e0fce72ee25b1",
                        "size": 977
                    },
                    {
                        "created": 1698579619.4332342,
                        "modified": 1698579619.4332342,
                        "name": "/dev/shm/incoming/2023_10_29_114004_00_00_K_115.jpg",
                        "sha256": "b4271f26e94a7041187f26b5a24fadcf2011315a93e1c8325200159889480cb8",
                        "size": 897137
                    },
                    {
                        "created": 1698579671.2432342,
                        "modified": 1698579671.2432342,
                        "name": "/dev/shm/incoming/2023-10-29T11:11:10.ts",
                        "sha256": "7cf004a142d0e53e83a8dd87e8ac5f37b39aaec1d29ffac1206f81c111b94163",
                        "size": 474786292
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "013ae8b8e8141732b0406281f2e943c781d47f5eb3b009464924881c703c62ed",
                "size": 435046678
            },
            "errors": "",
            "filename": "2023-10-29T11-41-19Z",
            "size": 435046678,
            "verified_at": 1698596930
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698550446,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "SS7CvISsyUdPJvvdh0R6eTxGjAz3P4zcaOv91CbaUG7rNJIsWcT+cCViUKGE0dxDqNOxPSV+vmPX/w2wqjMKL/q9A61hS8PId1p9CnN7293MQNNkrneQi8Mvc/jLTReG0noH6s5Y0Gjinb+M6lT5s9Yl+a0JSauAqFTHFqR9XJilmqbzvgcTkvZ05EICJ7qBd8XoWfT/9cpFuDqRi2PWfpO35W7MawAYGz9GxA/LTyOFdsadWW6Xf7fZsoIkuVbGSLvinRQ0lxomuSYBxqRB8jqWpMdZ6k/4UdchJYMDM33dkr2xlheTSz3eHPFk1Bjq4qCG/CwFDUoBzA3EXAVMIUWWWwqdyWXEC4TmA0CVPHza0VaQOZscgZDExpmaAGPN6y4hqzV1FG2DSZAOty4BxfCxQqCUO4TBIOnubK35rWXMS5gMOUG8am8XFl9Fcl+YLbOuYqzT+wrbTm+QIuP5tBDA4TzHy8oYZnyTLUGM505irK13waQh3FtNyd5DIijfzac6XTlftKzoNtBFqLev0h+ozscv+Fq6xajK8X1JTS7NAmOFLzXWeOHuAMibziqWhshctj0fiK0Zh8eWSSYCNSbluAlbbYrF+/PA9HXqPWTA4NP6MF2mBf7ljbvXhZStevJcOSxWYTYdDVJiACoIr1/QtwPeGx5Np7PAgEUvziM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T03-33-21Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698550271.917969,
                        "modified": 1698550271.917969,
                        "name": "/dev/shm/incoming/2023-10-29T03:22:21.ts",
                        "sha256": "60a8a45faaab27664623474e1089de52cacb2aadde4bf7fbdf40ab98a5276bdd",
                        "size": 86240112
                    }
                ],
                "mission": "OSP3",
                "sha256": "bac06433ba62c4e50cc2fa6e07bfd157f56136c011725136c98754aef0166d27",
                "size": 82573767
            },
            "errors": "",
            "filename": "2023-10-29T03-33-21Z",
            "size": 82573767,
            "verified_at": 1698580070
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698554464,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "TA2JA0aNNF2HuPbRoplNGX+BFZ4vqedqW73634FAGcuxwe4GSlYjU34l+87uKgK5lGTI8Me20+d1ZPB3lC/HiHZcbmF8+gN3exouTAnFjJMxBf5W/RNcOB/GJIg/9uRl+ruK0REx/GO2ywf+xIFwLfyA96rnPHB+hau51Ey9s+VP9UQHPHsKDz/riNDeZWz/iho/mvTXinidvT0mpsvU1+SwfQS02wR2wzi0ahGEdIzduJPiHi5OXaCx7NKfQbqp/ij+iG0I9FaiT4u0kkD+R4NjnvMJDh7/o8tcV+Sa0C6jfSmshmTQMAYb2pKyfPFrHAPPTjDM4gwHh/t6hUqUmz5UjgLhH4bpiq2VOZc8zH4yBjkBWJBEUIy4ejoUR45MjNWROOXym4uTDoIW5v1xFLg/+4XePaKUE8sTHA1P244ZeI+rP+zExjHTVS7Cs4GHB5fAcfo9akFJWx2AExwnG/6p7Oj63dIbLtdMncjgTtVDYFQlFSVkGThamxjr4M3Ci8nkIFfiEOXjlzEU8jULeXN5yxZgaGf3JEkYFKrzFwm/FOOBO4+l4dvPsEu0wjjZyqLvxCdVQuoQRXOEtzHH8DR75WA5iSAoXZbt5tfvsq3TBEanDoheFCxui5+lVEuPPEKOPwIYGhgY5d+U2101nZDrW1FRbQrGRoCl4JKSV70=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T04-40-45Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698554314.8079689,
                        "modified": 1698554314.8079689,
                        "name": "/dev/shm/incoming/2023-10-29T04:38:26.ts",
                        "sha256": "4926f03ccc06c967666c8c72fb50f6b8d14745bd694b0a8581b4e91ebf8100f9",
                        "size": 1043964
                    },
                    {
                        "created": 1698554278.6079688,
                        "modified": 1698554278.6079688,
                        "name": "/dev/shm/incoming/2023-10-29T04:27:57.ais",
                        "sha256": "de87f3c63a108a270db8d00cffb50f7a07ce216d44cbe7b0865cb8120f48f33d",
                        "size": 85371
                    },
                    {
                        "created": 1698554300.727969,
                        "modified": 1698554300.727969,
                        "name": "/dev/shm/incoming/2023-10-29T04:35:32.ts",
                        "sha256": "77233e1c64f522fe061bc300aa230b491f4d68c37cd562982fa75bcba8a5bfda",
                        "size": 29509796
                    }
                ],
                "mission": "OSP3",
                "sha256": "a53d03873b180ce858ade9de56081b4372c54e48fdf4734ec3d3573076121ac9",
                "size": 29316158
            },
            "errors": "",
            "filename": "2023-10-29T04-40-45Z",
            "size": 29316158,
            "verified_at": 1698580083
        },
        {
            "aircraft": "G-WKTI",
            "client": "FRONTEX",
            "contract": "OSP3",
            "created_at": 1698549599,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "H/5+mhtn6GAxb6y4+obL1cTWMUSxNeqGW9Yr95ILstzZZKo9JO9MwliNrIj+EK0d3DdjyuGtUXQU8hZtR7nEIDOIlYwbYmQr/mLi8R21el5U4vOEyS6rLAKav8cbCNM5G+JleQPxiBbxvM3v0cJJ7q93xNLUzrgDuhqlXkyDirXFv1UxmMC3j/eMgqH1AmwZu1zdKSrE1bCtdAmLx8V6UIHnfrA3juqyqmUlyHSQC4T6FlgTB1r1/d+94r+X2fjy6upQ2W+zyiRwBK5P5b7cy1nEB9U6xgyOWn2b2nPqgutSyvJNFOUGbHmyhAZVujlA4XiDrKe2EImpMrGVxOrr2c0I0yeiplRD52P++WWYK22Av9fWpr0nBqmolD6HOnME/jVRdtEnaSQQagD5wcaAatuXOAha7QW6kxAB5BBOY5ugK3O7crWK52FnMMFaMHzKb+GykDE9t7eDQ0pKfI4Uvbo2dwF/abDga734+pStwBwHpis0gNa291rzNsRg8/ytMZj3q4XKTwbHoNabFx93fGntzmA0ympB6WK0pdE+0lGwe2QAQahKQlw34arLqeOqmdo7oFP5JpeSggDgbCQe65Gwbv1S2Bu183w796+iEMvi/MQXurTF+PxU1zEf/VnXFTsh9XKcpl2D/pqDqV08PfpLBQm9ObbluIFOYD8G4FM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T03-19-55Z_FRONTEX_OSP3",
                "inventory": [
                    {
                        "created": 1698549470.0479689,
                        "modified": 1698549470.0479689,
                        "name": "/dev/shm/incoming/2023-10-29T03:07:49.ais",
                        "sha256": "6022f1abb3ef50ad8d0a9e250358b9f7e4ad623aab2f1907647902f38fc80291",
                        "size": 370060
                    }
                ],
                "mission": "OSP3",
                "sha256": "b9bcb9ac043eb4e83caa9adc13672e89cdc848fbf782fccaacf388f41ac29955",
                "size": 109532
            },
            "errors": "",
            "filename": "2023-10-29T03-19-55Z",
            "size": 109532,
            "verified_at": 1698580092
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698569849,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "XSdqJLtZMZ23HFh2fIG+INi6jaeDbwbmJVTwpIerdadKbGnc+9iWtldZFkcot6ONFdn6m4zmQrIxAO1xfN2+ptWztNMamILl+cV8Thr28Mo4m3PGxDmgXCZZI890g8k3uFgi0OEetu/cpSImKpbIk0LPqHtP+IBDwriJ792AO3MQ9K7HvOU1f1i5ae+tg5rut24Iq3ojOTTU3wv5E5HzMvNbwM3jfspZxZPedo5+XEea0cjznJvCjdx7FopWqzMhz7aEMfqUSzB/+HSo2hv84YoKXu9vPtKALEFTiToCxgcLwAr5RGl0NhsuGdbbTmu9p5FV607ynOzpT0iPmS9we+MOPjBG489ygkI/O1IJ2VjAHm0CBr5RbzNZYo8KdfrZy6ws/AfGeQOxWxArjYi7HEaBk1q0Thi/SSKcToR+Dlaj0p4C2+TtlvaYOw3S4hTgtnNjvEt/CYmAA/zqUjxOWl+dU4SeAXc3ffhgsUYpUvXB6DLBwypftMfVpZ4s2dYV0qZTMEfCEDHBAG4IC3AlizGangwOtQ8hewN0DAQUqHmTVGUmXfcZE7l9qN3lNlR69tDCJmGnO0gKL9IYMWWZ/cHdtdfV/WKpiBj6DQx48G5FwGRUgxF24Hg5xuXirIu00wu+xO7r76r3vVn+4P7cPduM2zbl0qJVL4o6GwciMng=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-56-54Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698569808.00094,
                        "modified": 1698569808.00094,
                        "name": "/dev/shm/incoming/2023-10-29T08:48:30.ts",
                        "sha256": "ec33be55868892b1ed748132816fc1cbd03f361e1ad89c8bb5ecf0ccc4459bcb",
                        "size": 111509568
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "a80e1787438d2cd6ca9d0a328b9dc2f6989ff661e876affc33f94c78207650bd",
                "size": 107588831
            },
            "errors": "",
            "filename": "2023-10-29T08-56-54Z",
            "size": 107588831,
            "verified_at": 1698580162
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698570134,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "K3RDiek87BTI6bv/x51LuTIe+18zauV9PMxizKq+MjK+Eoqvh/VPrqa3Wukem7TsRvecEDA0sKbCeSIdM5eV1CgIMH9+8X25EpBWr1zVQkEBhxnv86wWZvlVpWSbuq86CFCLZxtTiwf/yeWg3I4VAhFAeiYEssPfAYabtQr7mEq7wR51VGOD56e3WIdPmDp8bKnUEluRBMYxd3jTcJP0guSfnNn+qmNKDLuGeR8HlnyxZpj4T+qW7Mv4P3VrsHvNUHVsF99hkPiXgNuipWJqFFsBVeTb6xaQOqk8XAms+J500Lsi52pzXtrDQq4FHGd0/ziXAVd3p6bwFnNe3jtMQx8ahryVMqxfrnqU6YJweDIMWiHd1UsvgvfTzKVtKLtegO6Z2ChHuaSh87dCI0VTUEydIU17Os3RBft6K2CyT3QZSkGvZF49qpTjgUcF9nlW6GB/+pdPIfoLIr+YmMvHaEak6FVYJnBLYEZJ1C2h8kslR7b05u/UcGhF3W9IqfCDNwWW1ZNi22cs6/Jpp4w86tx8wzlrXrXDx7ZE4axUdsohmMqd/uD3ymzU5DOVShF+xHFzbubQOgACiZu96tog+uPmutPvIDxzRI0hP/nJeJrP4aNb2yQQDqSpK+yuckp6/at2Hq62z/7uTMoB7VodPrkvldSxit6szBTZ28a5A4k=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-01-44Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698569974.9309402,
                        "modified": 1698569974.9309402,
                        "name": "/dev/shm/incoming/2023-10-29T08:56:53.ts",
                        "sha256": "e0e87860f262fd97a41505c7484031a16e0b9e5e7d2f7733f1c6c793942250d8",
                        "size": 35143028
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "f2f8015d1b77b2437c5a9c1dd7c6e74c085c2513a2fc34f90c24326cd2b46bed",
                "size": 33884954
            },
            "errors": "",
            "filename": "2023-10-29T09-01-44Z",
            "size": 33884954,
            "verified_at": 1698580269
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698580080,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "b3Bzt5aBjBCP3ECNm/eFT/xr2l4OyiIhynwGPZK2penP1fC5j0ysWoIRr4phFzrhQJSXN+aNmE9KojMvA8VIkIV/t+vdvzgbBCcZhXMdjUx744C8vUMJ5l2WWuVTuqoqLTrEbU049KmV1pRfBlmIS0EPq6ODeF6EBia7WI8KldqGmGsmx6Sq5ENmu6YIszX3VQotTxUmNAB69tO5r9oFgfCcoCOP8QDr23BoRfNGKKvg+BO9cvzShhc1qeRTJNYiRXitVo9BQRI6Qt1IVUkKeuV4++LYS4P/ag3SWeAlQTGGB8KscK8HBG72bfe4PTkFvvxQDoTHf2D1LB0JgZ6s6ykjFDwk3aJ9z8gBcAr/mCI5G8TC/gb3Yd/CAH7UPwdoLIJx3cT6fCw80dDmGBfIR06bvpAcPWYNp52dE2x8gCDHLgmJ2aEh+XlmPJVJ8dDQIBEi+Xy2DEClHIlUkZ5NpXewlewjJaEXptnu2MAbb7hcNN/o5ollhNlBxfeV5FkjTChQcjMOUhstmQ5IPkwsXgvDhiV2POMbcHlX94gQy4aQXrfJ9ywDzLYybUtRf2VwRI0DMhRWa20TJ143k+YTOi0AzzjJlj5MkWWSbyLfyod+0rF0Ha2PoHxsaf42RqGMXd2zxyTl/eHY+3iWrgizk2eC3udlCljdogxuR+Aw6gM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T11-47-37Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698579929.913234,
                        "modified": 1698579929.913234,
                        "name": "/dev/shm/incoming/2023_10_29_114503_00_00_K_137.txt",
                        "sha256": "1ac03a832049963ed21dcfd1a0c8f20a399df1f40961a8f80d09d94aee214997",
                        "size": 2050
                    },
                    {
                        "created": 1698579929.7332342,
                        "modified": 1698579929.7332342,
                        "name": "/dev/shm/incoming/2023_10_29_114503_00_00_K_137.kml",
                        "sha256": "dc4fb44aa9236292b02d0b1e30032590f9334bb920ff90a696a87d96d850e612",
                        "size": 976
                    },
                    {
                        "created": 1698579929.113234,
                        "modified": 1698579929.113234,
                        "name": "/dev/shm/incoming/2023_10_29_114503_00_00_K_137.jpg",
                        "sha256": "f9eb7356187eb8105acfa4bd1774b63637167a46cff3d1c4f8fdaec863f54a78",
                        "size": 1132851
                    },
                    {
                        "created": 1698579928.0332341,
                        "modified": 1698579928.0332341,
                        "name": "/dev/shm/incoming/2023_10_29_114500_00_00_K_136.txt",
                        "sha256": "4cdee1dfb630b305a67740ecb4c5c8caa490f7b6c6fb29c233e08e77fd625171",
                        "size": 2050
                    },
                    {
                        "created": 1698579927.7232342,
                        "modified": 1698579927.7232342,
                        "name": "/dev/shm/incoming/2023_10_29_114500_00_00_K_136.kml",
                        "sha256": "0ce0b4c03430081e48ea60f328b531b8056969b08f1b3e6e25acec1eacf23c98",
                        "size": 973
                    },
                    {
                        "created": 1698579927.343234,
                        "modified": 1698579927.343234,
                        "name": "/dev/shm/incoming/2023_10_29_114500_00_00_K_136.jpg",
                        "sha256": "a9bea4b02fb9a16a695eb371ed46c1877e975a2e5fb1774ab9933c62b7f291a5",
                        "size": 1130637
                    },
                    {
                        "created": 1698579914.9432342,
                        "modified": 1698579914.9432342,
                        "name": "/dev/shm/incoming/2023_10_29_114456_00_00_K_135.txt",
                        "sha256": "3535d8dc7a4667103ef51e2af392e01ad1a31039fb232637ee0551dd23157fe1",
                        "size": 2050
                    },
                    {
                        "created": 1698579914.593234,
                        "modified": 1698579914.593234,
                        "name": "/dev/shm/incoming/2023_10_29_114456_00_00_K_135.kml",
                        "sha256": "369fa3db9e31b929f52085fcba0d3bb49a8ade9f1593104c12e41331673fd21d",
                        "size": 977
                    },
                    {
                        "created": 1698579914.5032341,
                        "modified": 1698579914.5032341,
                        "name": "/dev/shm/incoming/2023_10_29_114456_00_00_K_135.jpg",
                        "sha256": "61be7958ec4568cc5814fc8c50fb09b6a221c110f3fe15cd2cf1173a5f0418e3",
                        "size": 1143458
                    },
                    {
                        "created": 1698579913.4332342,
                        "modified": 1698579913.4332342,
                        "name": "/dev/shm/incoming/2023_10_29_114452_00_00_K_134.txt",
                        "sha256": "59e000a5386f99bffd0aef6f68fab825b79c3d9828aa5f525129303b44b16ff1",
                        "size": 2050
                    },
                    {
                        "created": 1698579912.5332341,
                        "modified": 1698579912.5332341,
                        "name": "/dev/shm/incoming/2023_10_29_114452_00_00_K_134.kml",
                        "sha256": "dd26ca95f02a6f5c77b998c60279a8a0f0d16b1f0ad4d2863f6290f31b4b9d6b",
                        "size": 974
                    },
                    {
                        "created": 1698579912.393234,
                        "modified": 1698579912.393234,
                        "name": "/dev/shm/incoming/2023_10_29_114452_00_00_K_134.jpg",
                        "sha256": "deb21550ed25a919a8e525192f0f15dea47d95ad70dae4ccbd1d323b4da09b6c",
                        "size": 1186233
                    },
                    {
                        "created": 1698579910.4932342,
                        "modified": 1698579910.4932342,
                        "name": "/dev/shm/incoming/2023_10_29_114445_00_00_K_133.txt",
                        "sha256": "6f6e2e9af97374fc9b7c579d6a21909d8ed42cbf17d62089f23537ea5c1c0d9e",
                        "size": 2050
                    },
                    {
                        "created": 1698579910.313234,
                        "modified": 1698579910.313234,
                        "name": "/dev/shm/incoming/2023_10_29_114445_00_00_K_133.kml",
                        "sha256": "3fc6230cacd1aaefe1ea3087cd37b5fe9ad948f5cb16711aa4e7ac885422268c",
                        "size": 978
                    },
                    {
                        "created": 1698579910.2132342,
                        "modified": 1698579910.2132342,
                        "name": "/dev/shm/incoming/2023_10_29_114445_00_00_K_133.jpg",
                        "sha256": "79cc6e0af717f98e06956327e1aea7b00c7a5b9fcbca929316615dd482fe92a7",
                        "size": 1134824
                    },
                    {
                        "created": 1698579887.4832342,
                        "modified": 1698579887.4832342,
                        "name": "/dev/shm/incoming/2023_10_29_114424_00_00_K_132.txt",
                        "sha256": "16a24cfcfc5d60aa7803bb316438198615eec297e55ce66590239a2af10f4f37",
                        "size": 2049
                    },
                    {
                        "created": 1698579886.9832342,
                        "modified": 1698579886.9832342,
                        "name": "/dev/shm/incoming/2023_10_29_114424_00_00_K_132.kml",
                        "sha256": "c3fd7ca3467dfa2477e660567ca0c2af7f9cbacb155a849cf383bf06790eec6f",
                        "size": 977
                    },
                    {
                        "created": 1698579886.4432342,
                        "modified": 1698579886.4432342,
                        "name": "/dev/shm/incoming/2023_10_29_114424_00_00_K_132.jpg",
                        "sha256": "71704122cba2e470bdbb88e74d2ebabb236ec795799b27e48a7f6964171812d2",
                        "size": 1563926
                    },
                    {
                        "created": 1698579853.123234,
                        "modified": 1698579853.123234,
                        "name": "/dev/shm/incoming/2023_10_29_114349_00_00_K_131.txt",
                        "sha256": "5c216b92cf507c202e7055cb44bb9635960e0d5478456f597dee17cc7226e064",
                        "size": 2050
                    },
                    {
                        "created": 1698579852.853234,
                        "modified": 1698579852.853234,
                        "name": "/dev/shm/incoming/2023_10_29_114349_00_00_K_131.kml",
                        "sha256": "381491ed8e8fbb777fb9c957cb21ade2755bf0a8abb83508f011eae24cdd862c",
                        "size": 976
                    },
                    {
                        "created": 1698579852.2532341,
                        "modified": 1698579852.2532341,
                        "name": "/dev/shm/incoming/2023_10_29_114349_00_00_K_131.jpg",
                        "sha256": "458c6a5acb457587f6ef1e5f04c0dd3adc1e194732dde62fc471c6128f5ec378",
                        "size": 958304
                    },
                    {
                        "created": 1698579840.7232342,
                        "modified": 1698579840.7232342,
                        "name": "/dev/shm/incoming/2023_10_29_114334_00_00_K_130.txt",
                        "sha256": "69d1e6567b2af6801c8456b76f6831dbc4baf068bf74b22852aba304d7bafd9c",
                        "size": 2050
                    },
                    {
                        "created": 1698579840.563234,
                        "modified": 1698579840.563234,
                        "name": "/dev/shm/incoming/2023_10_29_114334_00_00_K_130.kml",
                        "sha256": "d51c4d830fc85b42ed805ffcd00bcdfd5f0685b49b99bebb87a07ca6d24f2c46",
                        "size": 978
                    },
                    {
                        "created": 1698579840.113234,
                        "modified": 1698579840.113234,
                        "name": "/dev/shm/incoming/2023_10_29_114334_00_00_K_130.jpg",
                        "sha256": "0b9b889441a9c2eb7165a5a5bec0f8725a1c6df615a8d44da62acfe883fd7e1b",
                        "size": 674470
                    },
                    {
                        "created": 1698579839.043234,
                        "modified": 1698579839.043234,
                        "name": "/dev/shm/incoming/2023_10_29_114331_00_00_K_129.txt",
                        "sha256": "65c5c90cc0fbc9e0fa676abf567639751196b3995bd94f902a285e6d867033a3",
                        "size": 2050
                    },
                    {
                        "created": 1698579838.663234,
                        "modified": 1698579838.663234,
                        "name": "/dev/shm/incoming/2023_10_29_114331_00_00_K_129.kml",
                        "sha256": "0a806cb635e7b34a03f89334020fcafafc6b1fa2cd191307dd2eb868de39e828",
                        "size": 974
                    },
                    {
                        "created": 1698579838.5032341,
                        "modified": 1698579838.5032341,
                        "name": "/dev/shm/incoming/2023_10_29_114331_00_00_K_129.jpg",
                        "sha256": "d528d4e89eadb0485f005a99aaef6751b00f493b84c3d2adebfd344b6a48ff1a",
                        "size": 692770
                    },
                    {
                        "created": 1698579837.613234,
                        "modified": 1698579837.613234,
                        "name": "/dev/shm/incoming/2023_10_29_114328_00_00_K_128.txt",
                        "sha256": "de7c57dd79ac0016cd3c58935450baab42f2062961a130e61563c8cfcfb63bc2",
                        "size": 2050
                    },
                    {
                        "created": 1698579837.4332342,
                        "modified": 1698579837.4332342,
                        "name": "/dev/shm/incoming/2023_10_29_114328_00_00_K_128.kml",
                        "sha256": "0b16c27b98824eae2f9c3b4c238a509705636ba62ab4b20d6b73d77913c9e0ea",
                        "size": 976
                    },
                    {
                        "created": 1698579836.9932342,
                        "modified": 1698579836.9932342,
                        "name": "/dev/shm/incoming/2023_10_29_114328_00_00_K_128.jpg",
                        "sha256": "be6be1ce1418bce18c67ca416c9ac3638c6d0eec8aa1936eccd7fb0535220300",
                        "size": 677699
                    },
                    {
                        "created": 1698579825.803234,
                        "modified": 1698579825.803234,
                        "name": "/dev/shm/incoming/2023_10_29_114324_00_00_K_127.txt",
                        "sha256": "aefb8d673bf8c3c9fe7a221e3acc04323c91a4fc008ea4c758f5481e18eef2b4",
                        "size": 2050
                    },
                    {
                        "created": 1698579825.4732342,
                        "modified": 1698579825.4732342,
                        "name": "/dev/shm/incoming/2023_10_29_114324_00_00_K_127.kml",
                        "sha256": "5b2cb4246476337697e3eabbf8746ea36f364e63e74c6e32971c279cffc596ca",
                        "size": 978
                    },
                    {
                        "created": 1698579824.883234,
                        "modified": 1698579824.883234,
                        "name": "/dev/shm/incoming/2023_10_29_114324_00_00_K_127.jpg",
                        "sha256": "b6365144c9595283e790c74d34495ebf6090e969456d63f76d599065331d51e5",
                        "size": 672127
                    },
                    {
                        "created": 1698579813.293234,
                        "modified": 1698579813.293234,
                        "name": "/dev/shm/incoming/2023_10_29_114313_00_00_K_126.txt",
                        "sha256": "6b89dfc85f75fa5a077173bda3ae14adfad0fedc8d8446066ed245d70b9b3c96",
                        "size": 2050
                    },
                    {
                        "created": 1698579813.1932342,
                        "modified": 1698579813.1932342,
                        "name": "/dev/shm/incoming/2023_10_29_114313_00_00_K_126.kml",
                        "sha256": "8dfade2d2a35fe07a7b892f12a762b464c55ff67cdbc57243569e67be5625658",
                        "size": 978
                    },
                    {
                        "created": 1698579812.423234,
                        "modified": 1698579812.423234,
                        "name": "/dev/shm/incoming/2023_10_29_114313_00_00_K_126.jpg",
                        "sha256": "fbce312f611f45c0adf4121c3c0daa8e4da150d83f5e65ac601d5af0423be0a1",
                        "size": 1027925
                    },
                    {
                        "created": 1698579811.353234,
                        "modified": 1698579811.353234,
                        "name": "/dev/shm/incoming/2023_10_29_114310_00_00_K_125.txt",
                        "sha256": "79b85bc4b735f1dee27b2024f298735f839ba838313a62b42ff507f15c3b9397",
                        "size": 2050
                    },
                    {
                        "created": 1698579811.153234,
                        "modified": 1698579811.153234,
                        "name": "/dev/shm/incoming/2023_10_29_114310_00_00_K_125.kml",
                        "sha256": "f08e93229bf98eb92ae8ad02cb056a189bf41f7e8508797585f000f365ba5a22",
                        "size": 979
                    },
                    {
                        "created": 1698579810.913234,
                        "modified": 1698579810.913234,
                        "name": "/dev/shm/incoming/2023_10_29_114310_00_00_K_125.jpg",
                        "sha256": "add5ed0889a703628db3e6d9a0017a1583e253a2baf5ad779dd007ae16b71163",
                        "size": 1125318
                    },
                    {
                        "created": 1698579809.833234,
                        "modified": 1698579809.833234,
                        "name": "/dev/shm/incoming/2023_10_29_114307_00_00_K_124.txt",
                        "sha256": "c7f8d44eda7bdd2abc25ddfabf991c01afb2f3c17cad40bf9fd86ceb82fe7b04",
                        "size": 2049
                    },
                    {
                        "created": 1698579809.303234,
                        "modified": 1698579809.303234,
                        "name": "/dev/shm/incoming/2023_10_29_114307_00_00_K_124.kml",
                        "sha256": "33d2a97f7b4b9c7fd1ab6decdb9c42883524195f701c6d1a658e12de38d234ea",
                        "size": 975
                    },
                    {
                        "created": 1698579809.093234,
                        "modified": 1698579809.093234,
                        "name": "/dev/shm/incoming/2023_10_29_114307_00_00_K_124.jpg",
                        "sha256": "5aed1926b038aafbfa8d690569e0b4a9e685eac364449b7b3eb8c16587e5a9d6",
                        "size": 1186208
                    },
                    {
                        "created": 1698579797.383234,
                        "modified": 1698579797.383234,
                        "name": "/dev/shm/incoming/2023_10_29_114303_00_00_K_123.txt",
                        "sha256": "ea035108307b1cdf5322aa1f62fc22ccc37b1a2ae0fd24021a9fb8b5a9deadea",
                        "size": 2050
                    },
                    {
                        "created": 1698579796.9332342,
                        "modified": 1698579796.9332342,
                        "name": "/dev/shm/incoming/2023_10_29_114303_00_00_K_123.kml",
                        "sha256": "b973814d7886f2e3aab98b72515285b32931fe37bad3718215bcaa1751974b87",
                        "size": 976
                    },
                    {
                        "created": 1698579796.333234,
                        "modified": 1698579796.333234,
                        "name": "/dev/shm/incoming/2023_10_29_114303_00_00_K_123.jpg",
                        "sha256": "974301b5fd0ca11dd4769991013c46f173b5fd0c648f81d386e1c1910cc490f8",
                        "size": 1175326
                    },
                    {
                        "created": 1698579730.4432342,
                        "modified": 1698579730.4432342,
                        "name": "/dev/shm/incoming/2023_10_29_114150_00_00_K_122.txt",
                        "sha256": "fb679d3b1e6463649213c5c6e23b6d5967d80e95c0d689391f8063b051a9298d",
                        "size": 2046
                    },
                    {
                        "created": 1698579729.9632342,
                        "modified": 1698579729.9632342,
                        "name": "/dev/shm/incoming/2023_10_29_114150_00_00_K_122.kml",
                        "sha256": "3db31a5ff54668a7bc59049886cb2b8991510a3265f3bf33d9e0b11aa25374d9",
                        "size": 980
                    },
                    {
                        "created": 1698579729.823234,
                        "modified": 1698579729.823234,
                        "name": "/dev/shm/incoming/2023_10_29_114150_00_00_K_122.jpg",
                        "sha256": "9a5f4a637825a3a1b9a306f3eacbb726692dec36e3dd0b4ae272684c21e275c9",
                        "size": 1153929
                    },
                    {
                        "created": 1698579717.853234,
                        "modified": 1698579717.853234,
                        "name": "/dev/shm/incoming/2023_10_29_114137_00_00_K_121.txt",
                        "sha256": "0dcd2ca68ea028ab9fade31273eb60727132020b6344a6924b59b0d0d5e1e82c",
                        "size": 2050
                    },
                    {
                        "created": 1698579717.7632341,
                        "modified": 1698579717.7632341,
                        "name": "/dev/shm/incoming/2023_10_29_114137_00_00_K_121.kml",
                        "sha256": "33d3970095db09a563f84305282718a43133cbbd289a03d697e0b363f13ddb08",
                        "size": 975
                    },
                    {
                        "created": 1698579717.1932342,
                        "modified": 1698579717.1932342,
                        "name": "/dev/shm/incoming/2023_10_29_114137_00_00_K_121.jpg",
                        "sha256": "a4203973e4f1d5d3e0e50edc25e09c00d9f46f49b363d7fa4d6789fd8c1098fb",
                        "size": 1091459
                    },
                    {
                        "created": 1698579716.113234,
                        "modified": 1698579716.113234,
                        "name": "/dev/shm/incoming/2023_10_29_114132_00_00_K_120.txt",
                        "sha256": "e78748b22f2bfbb2260dac00f60c1e599f5865cfa26d6e4fede73a025861e35c",
                        "size": 2050
                    },
                    {
                        "created": 1698579715.7332342,
                        "modified": 1698579715.7332342,
                        "name": "/dev/shm/incoming/2023_10_29_114132_00_00_K_120.kml",
                        "sha256": "e7c7906aaa25d84e56402187263bf62d5b77de043203cdea3659d1c5905b511c",
                        "size": 976
                    },
                    {
                        "created": 1698579715.353234,
                        "modified": 1698579715.353234,
                        "name": "/dev/shm/incoming/2023_10_29_114132_00_00_K_120.jpg",
                        "sha256": "e30ec0e4f1610765d88d309d179baccca3879332e0976dfb4d3200c6239764fa",
                        "size": 1121041
                    },
                    {
                        "created": 1698579714.293234,
                        "modified": 1698579714.293234,
                        "name": "/dev/shm/incoming/2023_10_29_114129_00_00_K_119.txt",
                        "sha256": "8648f29532bbfd2179141c7dc8e2203e8220ecdc9d373a1ce7af93e48a4653ed",
                        "size": 2050
                    },
                    {
                        "created": 1698579713.673234,
                        "modified": 1698579713.673234,
                        "name": "/dev/shm/incoming/2023_10_29_114129_00_00_K_119.kml",
                        "sha256": "97cce40c35d911b464ca22f79f90055064b31a7eb035818bb2ebffd539f63799",
                        "size": 978
                    },
                    {
                        "created": 1698579713.2232342,
                        "modified": 1698579713.2232342,
                        "name": "/dev/shm/incoming/2023_10_29_114129_00_00_K_119.jpg",
                        "sha256": "943a61cdddb910739dc2c00ba3433c799f762934b912f829b03815112035e525",
                        "size": 1147415
                    },
                    {
                        "created": 1698579700.9932342,
                        "modified": 1698579700.9932342,
                        "name": "/dev/shm/incoming/2023_10_29_114126_00_00_K_118.txt",
                        "sha256": "6bea65378807b116c401947c355ba0aebb10f5a7a8f7fefe85dfc24226bc4a76",
                        "size": 2050
                    },
                    {
                        "created": 1698579700.403234,
                        "modified": 1698579700.403234,
                        "name": "/dev/shm/incoming/2023_10_29_114126_00_00_K_118.kml",
                        "sha256": "9e4eced0e5ed17f288ab62cd247dcf978aa03231f493d592e16d1f8c504c730c",
                        "size": 977
                    },
                    {
                        "created": 1698579700.0132341,
                        "modified": 1698579700.0132341,
                        "name": "/dev/shm/incoming/2023_10_29_114126_00_00_K_118.jpg",
                        "sha256": "707290e2e1983380c657a60fe56f5978d081fb57dfa5ad825abd891cc2baf17a",
                        "size": 1075536
                    },
                    {
                        "created": 1698579677.313234,
                        "modified": 1698579677.313234,
                        "name": "/dev/shm/incoming/2023_10_29_114057_00_00_K_117.txt",
                        "sha256": "6bb0784abc6a03fcb003e49081dd552e0a3ca6a84c04117d8500e03a322ac073",
                        "size": 2050
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "dbc1d0cdb396d4f83ecb4660d54af9136fde0a643018b8ea2087f6d3736e2d19",
                "size": 21068207
            },
            "errors": "",
            "filename": "2023-10-29T11-47-37Z",
            "size": 21068207,
            "verified_at": 1698596482
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698754902,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "GQAUqD24gb9SWkJRUlNsvrbhdeKvTKLHoAWTov0u+QqjcDAB2trYAZdauxxkvozplfAh4e7uIJn9pZ2oz9hCDW2Y8PtsCcEi344yaVBjurxO0goDjsebcHVFy8+mttrI7awfTdBfDuJtoRTz2GgIaY+nYTPsohEdAdOtcFfgIEM4eNWnoY2Y4stOl4oYn7Jsh2dfuAh8KeBaJs/Yv01A83ErZjB0Ptf7PyI6wUR7p/z+35td5VlNEYAi9hI2idNOMl4zx5HgF0tIL5O32wtXlthAZTdxgNMNpA33Dw28P2a9qXH96ZW3qTgXvHeLD9AdlZVWbP4KmD9oZC/zaR7i3NQbBv+zzBhN75+/oqhMlNaIZuGPcaQAeoJ11zW36bxG6ch54JZq+IysWgLuYniRsNsDBYC9CLk61ADVQRw+LFPdSwptGxSsfj6zUc5zJdrQAnUfSNZoF8dW3o2Lru83CXn8ovFm/JZNldNRpnAHhDQI33NHpLgXH28uupXfRUyuAuMkXPEKQl373KDBh0tMNkJ8eZP7dNcD+05ul6X/1tgbYpgkdfCivM5OjT1ZMRsJjTwqG7lFYL5pQAdPSLc9pnTl7rUxvhlTnN8ms1mgjCDInMFwRnC6kxw9lcbCgx8k/pRaisqGQftINPYjXo9KCo72LS6n9e7lGI8EgPWXUbY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-21-32Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698754763.3114185,
                        "modified": 1698754763.3114185,
                        "name": "/dev/shm/incoming/2023-10-31T12:09:22.ais",
                        "sha256": "78fb16c6ba21bf069f50ebf6a50efc468ca0cd56514f91a89dce434e9659c574",
                        "size": 206809
                    }
                ],
                "mission": "OSP1",
                "sha256": "198798d2f5bfa6f31e8c2aad0cd47593369580de570508648e43334a671c73fa",
                "size": 48784
            },
            "errors": "",
            "filename": "2023-10-31T12-21-32Z",
            "size": 48784,
            "verified_at": 1698766253
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698571850,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "gxacuYU7h7DdJ0/ORAPC1l1aQJjCy/gqUthJAdMc/tNk1NebTN7O32NOqbiA9wrjt92CoyscnDnNdZXtMfaLfDU5SdFLMoRSGDhSKjXGdQz01u2To/xw6tPBtnyunf8dHbBFyXA5VLRLulUqwNxvqMBFR9wSogMLPbV2BDBiU4WqlahnSOGauNrXh9HfXuv2fqy/7wLrTgj38gf7MbSOKa7zef8BZiWIJgwr0Ab1tdtXKf5FqVkxT8BiZtYoqWHvMTNRXDPwdE9Sk9asXysi7ZfOq9vqqpuCoZX1mFUIitRVHOScNNC8spppv0IawraB6QcK0wEkgixtY/uQ+jNseW1ERxDhF+jMM93mfyZzbg2oDpsHMfMnafJWj2nZtVwoqSt3WCn57cpbF/tHY39t2V+GnyAqwBtF+Wmc4w3eoCZSHSwi8MoOSCiqBilF95ZomhP03RFEXft8HOSidFrrxSwycLnsRXtVuYPoCAOJ26IMKF7PzdgahPfcMu7Gcb8cmEFgcgDCaICrGmP2c5eOTP3KdQmnhBvliVNm1d4S6nxPJNmiuW25ORT+mweRp+VimMetVomeq3cs5M0bwKTxKQBsyVH1xu9ubNuuSb2VyF2rHMRssGsTmFBZh9VryJlhPXOCUR8+sVQdJpD+8LXR5PiGeAy9nwE90Quknk5X0rE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-29-48Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698571782.6209402,
                        "modified": 1698571782.6209402,
                        "name": "/dev/shm/incoming/2023-10-29T08:59:40.ts",
                        "sha256": "1d76de60507a9cffc87b58d71512a398aec0ce7364715b305764c21ba7558c1d",
                        "size": 392174768
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "ad6338a7077b7b173c0a5deae54fdfd78bbed8097783cdcf17d540ce779c4165",
                "size": 381552658
            },
            "errors": "",
            "filename": "2023-10-29T09-29-48Z",
            "size": 381552658,
            "verified_at": 1698580490
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698756231,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "OnPL5XZBA2lTuZCnDKQeEIu8R2Nfd/XudNiel0VsoJ3PfhUhjoBIPs7/UWM4yw8gxqe1Tvo40U0R0dT4D5q/bwdw8fZoTK5kbmYqOAKKYwfIwflTDTDSUAmQ1q/Aiqv7mxoMJVyZijs65b6pD+uSUCE4plA+9lFfJNYM+lYlcuHw0MsCVhYrZOIgZLgnEaoeUyCa+6gcMoCZYDjJTSrRr7HdklYgKrdamG8PAROwNOykEbA3zIgSiuKAJDLWvFYcyvJb52mGTo2A4uXhq7aZAUeS68XRqjB1fxggjL5ZRQ+FDgSu9MIl5LYeBOHpAHxFKGS6bwnUxxoSXysAy76qcZ4JzFF320iDzZbz3cTAxsXeks6xTyvNBhbu7Vq1RUXmgp/N9GBtEMF89lzdOIyRHWiVHOAZVWBViPEZLcx5DXyIDxmWE0W5hP1K9O+PVfTinCyazVKhk6L0Dcc+AFyYYCxTnYqKppnTG8u8OrYDnJjMCkqsrMMIOtEC55MVILQkBO5brVv7AX5MqbcKjQQ+NYtkRbjkiySkzHxI73/MgX5tggFc5+mp4rM0PK+t5Zh4bJxvYoOf3ia5A3ALZ7dAkmyitSB9677kWi2nvyxN/H8K7A1zr97bz2GiRRzWFUtd53rk+K0Wo7KFdFTyH5p8JehzR5MIbRO/j1tLBmINArs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-43-39Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698756092.8414185,
                        "modified": 1698756092.8414185,
                        "name": "/dev/shm/incoming/2023_10_31_124117_00_01_V_077.txt",
                        "sha256": "c8948f33b9102413426581479c3cb3dd693ff80792f68e048199d61ce461d8c0",
                        "size": 2042
                    },
                    {
                        "created": 1698756092.5014186,
                        "modified": 1698756092.5014186,
                        "name": "/dev/shm/incoming/2023_10_31_124117_00_01_V_077.kml",
                        "sha256": "da128b5d42707a5085c009d822f45c1d49c93959765c13fad1ebd8b65d44234b",
                        "size": 952
                    },
                    {
                        "created": 1698756092.3414185,
                        "modified": 1698756092.3414185,
                        "name": "/dev/shm/incoming/2023_10_31_124117_00_01_V_077.jpg",
                        "sha256": "362e800827886698c99e60be3656730f9d9cdf1ebab88c8df07351fb26019c17",
                        "size": 1284627
                    }
                ],
                "mission": "OSP1",
                "sha256": "a58fd0f7f39c574d0a516ce707639c5768ac1d101c903a3c20f486f41d4276f0",
                "size": 1286272
            },
            "errors": "",
            "filename": "2023-10-31T12-43-39Z",
            "size": 1286272,
            "verified_at": 1698766270
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698581020,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "SSw/n/AKH4MD8wO5XTq25HdnFJsSA9c0btXg1JdzxdTv12dRu9D/lX94IBMSokFNEpDFLqajKFKvtzrPbiemnpLx9g6vv2drNPO6aBstYnuVCRwfrTyvUnvr8HTZgPRTXGyNyhtSCumJwOFD/73KMQ/C4gTrR4/+/7yYkMTsGRymqNddoiuPyKsW0ydwhFJcWcTUqa9iKIbhp/j7e/8cA8DWGSZxP7xLyOcu8DjILA4wJHwTkv6GEL3V9COzHshhfgPGtCmoxoPZAczXLMb8hYSugU8gTuOe06w3emOYUN02AmiJE/+XMAYx9kBwM0lBBxlorgLGpR6b6moxKAXuWPbKpXlqj5cLeInYyfZZ/bN6NyT12ddNZDXt3gLc1JUG9i0CqdNs7twUaCgNs+xXC0LIgFsGqqrDD/nyYWEd4/i2fSqZEC8++5i0p39IcpRGHZs6BbSNeishN+oCy2OhzSPdMeDYRXfWlmgxpxEB3HhNP2VPHEkuu2ZZK7wUdKdhl7exudap1cZ//0h5wyokGL2HV1AvAzXXH3mWirDzO+B7kEB6SU1mzWqNGNh4IaVFa8qb+H9cyRnvt+Z67i7YRKzsV0GL/xM8khmZlNj+IRFzTk0FPUUNfKXWWIMAntFZyD8jyTdQD54MwQ3GB2iFwgHoQJA3p8wHP3DLDxDJYwE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T12-02-35Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698580830.0132341,
                        "modified": 1698580830.0132341,
                        "name": "/dev/shm/incoming/2023-10-29T11:50:28.ais",
                        "sha256": "ac5a70cd194f0c03f846a51901b9dabb2c70cda96f608e16b29732ea86358564",
                        "size": 377048
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "fe839445950f24adfeb541c911efbe71462c38e1a607110bfbd4446882603356",
                "size": 113977
            },
            "errors": "",
            "filename": "2023-10-29T12-02-35Z",
            "size": 113977,
            "verified_at": 1698596650
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698581556,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "rcSr+ksQ+R/1h6FxBFJ5j+eK7i+LfRgp8Zvb7N+F0qFmNiKh0sLjwvmLXBGf9aiQCuTOQaX2wi4ztUucPB42Ck7rzM7rkbZJzAk54KkTg2jDyg+9i/ytG9Azq+gWMSUf/V+M2eQUA2niIRa8EYxiBCXlzOnMq9C92kQDHUolnIe6KDez2y7afW0QQueoKF2519zpdt9rlxq2MnAe8EPc+3rme36fAGZUDwPii2Bcqc3AGbNHpJ0lESp8FGbNI44xTu5u5QXlQYxGEz9XJbzMznn1cpMRte9y77YIN0p4NBLUrb5EQ0o5PdaF00vguet+LnwHECc805CrbTo5V3QpvtGrXSF18gxXXyJSyn8RriGkJUjxWj3N+Sv/W2u6HNiPC8ONfz4oZKkLQkRMFndWtY07/gva8iGr6xuuzKKC05M5ZjWnSlzA9zPTtG22PZ4t8j0+g9qe+burRudwnd8V9ERTX0HbgZkKoDGRCZCLhisKfoiQppYbfMvGoCL8nrFjpZQcBsOR3gUS1tsp4j+G1A1NGfR/JnE/Eyo3ubDiaRzzGPAaZlAxY+0fqWjkvvMHkq5agLtIawpWh8gXGZltO5w3g84YcfBI7KL8Y6G+gJ40FE8A3orBqDaZH2UdoxEiWUeT35w86V5YiDaDes2RDibOG9KPtMT0s8IjAHiGTZM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T12-11-24Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698581431.7532341,
                        "modified": 1698581431.7532341,
                        "name": "/dev/shm/incoming/2023-10-29T12:00:30.ais",
                        "sha256": "d5152faa6010b22fd26b1aef4cec5fc85442fffc26f96b29096e8d91014fa31e",
                        "size": 445840
                    },
                    {
                        "created": 1698581477.843234,
                        "modified": 1698581477.843234,
                        "name": "/dev/shm/incoming/2023-10-29T11:41:17.ts",
                        "sha256": "2137402dbc45486ec2eaa979b9b0a4cab65f0445eefcea01d1f48ccc3cba685d",
                        "size": 451247188
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "db82357721eb0c2e87cf06e6a96f8d322f99b3b6e0c830d4a35c11bdb3dace77",
                "size": 408903134
            },
            "errors": "",
            "filename": "2023-10-29T12-11-24Z",
            "size": 408903134,
            "verified_at": 1698597185
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698756702,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ronVJGSVWs/9536x4ylVTjyGa1dsrOIZYfaxlYBSmZcDo0YUTzQ+qsgxX/rlnoESnASdmsc3TuFnEanX7O0sIYRYOqwRpg1B5zUnbtKjFwfE+T43wJrp7WoijgqYjQxZAEuuKt4V04yh2wXgmMOc6z4ij+PDd1Bn7JdnlXc81PiUiMowYiGfAS3gj7tGgIB60vR+3pZM7kcGPINfevHuztuIzWiglQbEN17bBMIbxC3KfMOuhzi0/tcR1wolK8SjQLwJuIsh3ySr8Fr4HmU1/hmUTpthyp+y+L6YyINeg9a3FehPshLcv1nl3rlo9MlM+LEVS7ce0E/QVZuUFOthcPKKW3yaJY5emGy2wg7zi0zDQ0F/4e8MSBm2pCPE9xjjGen9Wj7TXL0SeHIY2c2Sax2AWrXlXEN/ce6hi4qj9cesKRIKuBfTjEM826KYoIXIrJzkXpujLdkLZsaAMOzZ/OdQk7bdS+YjB71DPbI4MmEGEGO8ltm464dOU7IE8a3rFgx8f0QGwYhdlKFx4NDCNvHlsn/3KfES9t5XaiHVhtvkVWbSK/os3VBZRc24sROjdS40jNhW4t38qLszO+F0t6KVkOr9SyqsFSHMJb6LUqVdHcw8w6dvA6atmHsksyYIOI6EZnG6TpwzNi1Up3DhAu+vEkHk0T4/caYjWrafJAs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-51-31Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698756566.0214186,
                        "modified": 1698756566.0214186,
                        "name": "/dev/shm/incoming/2023-10-31T12:39:25.ais",
                        "sha256": "ef64516ea9d0cb155eacb51717d8a6b83e7006665a034da84b1eea7bf39b80bf",
                        "size": 269710
                    }
                ],
                "mission": "OSP1",
                "sha256": "fd4fb961c1bbd2ed5f467ec75f6a0a6bced6b99f7fb4db237e407aef58b2cb9f",
                "size": 65082
            },
            "errors": "",
            "filename": "2023-10-31T12-51-31Z",
            "size": 65082,
            "verified_at": 1698766295
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698582182,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "VqISfvlVBvxVlMJ69J9wP6tMuxZH2M3iHuhUbi5BnS4D7kVNHNMaX6bC84Tg1cKQXnF2v2U9z9EnPTmAi6I4ZkNWqZKgmrQXw+k3I6G94HpUujujHST96cVIFhL8baHF0+HYWVmN87PgGK7LqRT1jmyCr0mRprPeQm3kdJ7QBlSBngYIWkBFuDC7EtlXm3Jwp0SKo1QRc+Au1tDYH85CAIGyun5QWlG+NIqGNkJ4FApEy3oF5v4eDfGAHQE1fEeTdXcillI6tRIVGY2KCWduvr2lW8swUTu2NRfqqjwCpxiBMgFXa7xvWSslrcn2c2j2cHoq+9g+mq2KDxWMHJDgvzIVgV3mD+2fSmNt1wlXyE/HfGl0zgSaUFq6bL9CBkylLenDsKlOTFzYAkNJlNpQerYFekx6TWiXgt9UWaw1Gbqir3U9WLELaNH6pJPmpNVKu1yWOgQ0UK8dve15j1Pd+jmdor8JEQJVCyFASRXyFaLjIPnvDz6b5Ai/f1+T3vhaqoLS4SvK+lZsnR/3/2OQcB72nvWs6Fibk57qwFzDklyHEGVBm+CGjO4bGfJy0hGMjVFCLDb0UTx14XP6vpY8Q6lB3SZq7ogvKpYihehIEP3jdj4ZGEfNDW1Fkcv3eakk3s/JXdXUIUyVp2JEn0y/3+w/VeMbpqCYFsGe4OFuVEM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T12-22-38Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698582033.383234,
                        "modified": 1698582033.383234,
                        "name": "/dev/shm/incoming/2023-10-29T12:10:31.ais",
                        "sha256": "51977ef9bb9b8c63b35f0f6e07f0dfd6480ca1e41f8e4eabe978394b9e62ff94",
                        "size": 481823
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "ca8045912993093112ffd99751be99964d4ae408ddea0360c450dca04bb85376",
                "size": 143799
            },
            "errors": "",
            "filename": "2023-10-29T12-22-38Z",
            "size": 143799,
            "verified_at": 1698596785
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698583731,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "MNAHMlvpSiRAgGH0QxPUtzX5noZtU8FzYEfcdxw5ugPvtfhEcbeq6lx+uhJNHy5F+GAHP+GgXtmKo2K2ytBXRWjC/eSM7FMCdIq8ll9Y4N4SM+37/nzhQQJzbDZIEa8OgCibrcg5QmGPivPsiXA9A9MJBUyEetdQvgI3TzU2L4Px2J2oudLKVhPLL/GPJR+1hRSiLtsRhKoXHaRBONP67rBmgYiw+Fugi75CJ+UiQIdNrnxjLpgZhdng3eZmip6F89XQojugUJSqiasz++m2KLgTTNQ48VJltJG/DZI9ZIopnAqaQbwW4RduED/LA843HS6QQpL3VcBAxM1DqnLLYc1/qIsvu63tOz7E+Vm2mtoORxhReNTW9G/CeFo5vnYQjrhcgLxBkIrv9tnepHSaLTGd7Nzgs5JDVmTLTw52Rpn+6AEhOxzKxD5OkXurtvWf3zfgXmgMcXUOtXZ4Y5tM7/r6XBPQ/k7wHsoHQ4W97Xi7kOqKWAXpEC4eWqfDIAzulbrG1fSP0AS5LFASJ9JeRt+3E8BOpsQURaWZqWunlgorXtuDDlY+hnV88m4cxnvu6i51yGI+vgDu974d/8MgxsWCHpuj+kvaw+Gy81cfS9zth9jSayOi1JnjsW0f3AQT/bK5z3yrPSaUi5WDq21sFac3HLICafUV3fqkGqYG+kM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T12-44-46Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698583361.413234,
                        "modified": 1698583361.413234,
                        "name": "/dev/shm/incoming/2023_10_29_124216_00_00_K_169.txt",
                        "sha256": "461d1acdee33b81a12c072e401de8f071c5b11b5ed10f2162f607a1699344d69",
                        "size": 2050
                    },
                    {
                        "created": 1698583360.903234,
                        "modified": 1698583360.903234,
                        "name": "/dev/shm/incoming/2023_10_29_124216_00_00_K_169.kml",
                        "sha256": "fe79f5075d603c453d4025e73dc1e313a62d5bca67a3754a54d1071220883944",
                        "size": 978
                    },
                    {
                        "created": 1698583360.373234,
                        "modified": 1698583360.373234,
                        "name": "/dev/shm/incoming/2023_10_29_124216_00_00_K_169.jpg",
                        "sha256": "4d679816bcbd36fc2ce6a596a66d91625691447bd3f546eaa0cb0bfcda2744f1",
                        "size": 573758
                    },
                    {
                        "created": 1698583348.5232341,
                        "modified": 1698583348.5232341,
                        "name": "/dev/shm/incoming/2023_10_29_124203_00_00_K_168.txt",
                        "sha256": "4400bfbe15055ecefe3f5c840181e9b8fe1fb9466e7461959ec9eb9cfe03f81c",
                        "size": 2050
                    },
                    {
                        "created": 1698583348.143234,
                        "modified": 1698583348.143234,
                        "name": "/dev/shm/incoming/2023_10_29_124203_00_00_K_168.kml",
                        "sha256": "cfefa0102d6cf9c6b3b42f6bfdc341bf3e88a11136ae7c636399e64ec263bf30",
                        "size": 977
                    },
                    {
                        "created": 1698583347.4732342,
                        "modified": 1698583347.4732342,
                        "name": "/dev/shm/incoming/2023_10_29_124203_00_00_K_168.jpg",
                        "sha256": "9966cd48483d9fa86b91cdf513f7255cda98b04715d46f0ed57f60e03524c431",
                        "size": 368836
                    },
                    {
                        "created": 1698583346.413234,
                        "modified": 1698583346.413234,
                        "name": "/dev/shm/incoming/2023_10_29_124200_00_00_K_167.txt",
                        "sha256": "e76aebb31df3253f7ffecfbd267ca0e93e8541e731f72dce8f037e6b34dff672",
                        "size": 2050
                    },
                    {
                        "created": 1698583346.083234,
                        "modified": 1698583346.083234,
                        "name": "/dev/shm/incoming/2023_10_29_124200_00_00_K_167.kml",
                        "sha256": "59408dafdb1c6ac88083cb27dff1717b5cbde5d5f157e6b4518ef1d6002ae6b9",
                        "size": 974
                    },
                    {
                        "created": 1698583345.7732341,
                        "modified": 1698583345.7732341,
                        "name": "/dev/shm/incoming/2023_10_29_124200_00_00_K_167.jpg",
                        "sha256": "4d2c11e1e43e72a3f28952c7936e60256c111986a0d10b303478d1adedeb38d9",
                        "size": 488951
                    },
                    {
                        "created": 1698583333.873234,
                        "modified": 1698583333.873234,
                        "name": "/dev/shm/incoming/2023_10_29_124156_00_00_K_166.txt",
                        "sha256": "29cd7fc07473dedc414ad3115cd30402d515c6657c60239f268130b95d24f30d",
                        "size": 2050
                    },
                    {
                        "created": 1698583333.7632341,
                        "modified": 1698583333.7632341,
                        "name": "/dev/shm/incoming/2023_10_29_124156_00_00_K_166.kml",
                        "sha256": "fa54f9fd31d906bcb7b12f19540dd68218033c1a6d6f93f85b5c6cc7cf4cec59",
                        "size": 977
                    },
                    {
                        "created": 1698583333.403234,
                        "modified": 1698583333.403234,
                        "name": "/dev/shm/incoming/2023_10_29_124156_00_00_K_166.jpg",
                        "sha256": "49c5d0093bcc5b9e1f4841898b94a30c5528b2fca24f57c021368b28b9f75c53",
                        "size": 456494
                    },
                    {
                        "created": 1698583332.343234,
                        "modified": 1698583332.343234,
                        "name": "/dev/shm/incoming/2023_10_29_124152_00_00_K_165.txt",
                        "sha256": "9d3c0d7138906602b6eaead59d6754d1df7de573611a66833c8ae82615673ac3",
                        "size": 2050
                    },
                    {
                        "created": 1698583331.903234,
                        "modified": 1698583331.903234,
                        "name": "/dev/shm/incoming/2023_10_29_124152_00_00_K_165.kml",
                        "sha256": "e9496434b6431064c8ce482258b2bfb7dec8eb230d55d2c710f9bfebaf6f5974",
                        "size": 976
                    },
                    {
                        "created": 1698583331.7132342,
                        "modified": 1698583331.7132342,
                        "name": "/dev/shm/incoming/2023_10_29_124152_00_00_K_165.jpg",
                        "sha256": "a6bfb1aa6df57ee08cfe272dfa226118241318ad1bbd75281225b6ea2a0ae17d",
                        "size": 416283
                    },
                    {
                        "created": 1698583309.403234,
                        "modified": 1698583309.403234,
                        "name": "/dev/shm/incoming/2023_10_29_124129_00_00_K_164.txt",
                        "sha256": "2bb47f9e059b0fe03427269259664ecbb1ce070579319a15e124ef8fc466e421",
                        "size": 2050
                    },
                    {
                        "created": 1698583308.613234,
                        "modified": 1698583308.613234,
                        "name": "/dev/shm/incoming/2023_10_29_124129_00_00_K_164.kml",
                        "sha256": "a660257a90ddac96e527c5a384d094325f016b083f598ca19128ca64b060f336",
                        "size": 975
                    },
                    {
                        "created": 1698583308.423234,
                        "modified": 1698583308.423234,
                        "name": "/dev/shm/incoming/2023_10_29_124129_00_00_K_164.jpg",
                        "sha256": "59b4d779c6dc3a3e07e4dfd8cee32281acd85ede9df3717befd55dfbea4eb3b5",
                        "size": 1113671
                    },
                    {
                        "created": 1698583306.323234,
                        "modified": 1698583306.323234,
                        "name": "/dev/shm/incoming/2023_10_29_124125_00_00_K_163.txt",
                        "sha256": "5881bc9b8c9c83e97d7320296af330f7b46167522a6ad8f726fba528888e3f6b",
                        "size": 2050
                    },
                    {
                        "created": 1698583306.2332342,
                        "modified": 1698583306.2332342,
                        "name": "/dev/shm/incoming/2023_10_29_124125_00_00_K_163.kml",
                        "sha256": "0c9e239c6099c2b7a32f56fbe2f909cf4feeaf8846cf8a177724f85763702baa",
                        "size": 975
                    },
                    {
                        "created": 1698583305.303234,
                        "modified": 1698583305.303234,
                        "name": "/dev/shm/incoming/2023_10_29_124125_00_00_K_163.jpg",
                        "sha256": "f32a4ae735edd90a051a49d482900cd8184b12bfe94dac4f79b067350daad608",
                        "size": 1123085
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "0bbeda9432666278ec21754046b0edb6f1fcfa5e894b5625bbe7d9670d022262",
                "size": 4474176
            },
            "errors": "",
            "filename": "2023-10-29T12-44-46Z",
            "size": 4474176,
            "verified_at": 1698596909
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698584648,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "a1pOwiwf5ersqt6ePtNh7XLDXYdOHVQ2wVtl+fNmjFuqYCHH3qwEyHUaNTc/HJMKrwOAUi5lS0xxTRhbrA9IM3LGBbxjNQE6r5VOC1Ekx5EZ2lqwPFm9wPWIocSaCHoCMZNRkG60iXKbVtCltgpH2GyH1PuH1yXg8ui4UTDMBbmOjm0XzbAg07yBa7rf5IsGiBU4YZMOVGM0xXKdn5CGi9zvVr/jdJoHMMgPuW765jCF6lpTcfecC8Ug60KuQyYTMevKg7cQMPrH0Y3SNLnL7MWSkB0DiFpazqWMEglGXWNhEfTi7T9cW/C4mo9UafrlHk7TFlzD2k52qcEM3DAXEEH+bcw536mukebPSuHC6lt18FxzJYsrv6qqN+VK96gt+zyon0x0tPPXkZ0aT+r1WJBw1r/jhamRVpWENKOobS93AslOfwPbVO57Lq2Hp4NfI+RbYmLhDhOiQiqacLjgYke/pU3K63y/kG5Mm9aJwHsQI7hK83U0nOnlogoNefJB/lQum5ib83vg5nsKlR3I9dNaM8GhfQGELH5sLyra2o5z0irfQLTQWPEYph5ewT91tg4kxlf/N4FqCp5edYKXUsMZjcXSuNgyMIOEjJR1AvQlzQomcTzqku5JNt/BQgu3CnCN6Mmcf88kbSkuSV7MION4khkJIPmBqzGOpFGikd4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T13-02-42Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698584438.103234,
                        "modified": 1698584438.103234,
                        "name": "/dev/shm/incoming/2023-10-29T12:50:37.ais",
                        "sha256": "631018bb0c69a9fe7d95e38b4df204264f0d2dd95abe69c61414d5338f321286",
                        "size": 738260
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "3a48f4e9fbd6a85990bbc81980643b2b3f22373972251a705d196e92c5e7cabb",
                "size": 222590
            },
            "errors": "",
            "filename": "2023-10-29T13-02-42Z",
            "size": 222590,
            "verified_at": 1698597013
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698583988,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "NCL8e7SqUPKDi2JBYHXAMC7TiVmSxWG29Mu4UiBb4DA5ksHCEbAEm4GWK3owgv/Ujyu1GkvbFGgChUgdY3C7yd9n2t0YsEhI9L/ZgGt4RMzjzbvg8CypfNbF2MzmQU8nira1bVKX4eM6/zV7CzOJVnnD95rICN6NibrNWruJ4AZjHU3OWYTqE0xudCSuN+EA55dcXhCQpY0iXVHdtvdmR7lZ/gda+d6GWGoE55+ScKtJkOLz4BJ3FddzPjZmlEC/9ne7fhJK5BnNWRlVaYNLstGTs635Ty6YBXyzRAVVCbJxk3VCCmqCOuvLcND5XPi4C1cIMIGvZG+SO2lZAeQGm8gVVxop9VqXaLUmThXriXlIUbT0xPDKVCwG8mzIWqf+eYeFujBI4+sR2ac4+L67KqLeNZM0a+kckhmvDa395drCswrc/v4QPBgAATFzzi9VE8zA5bxiTm04WqSg7O4mdAY3kiEp01tL6ECaWBsd8xCHIB2vlQIfaOp9Ut96movxkOXWm7ApD4T7QSfaugLBVq2O1HjFk0Nm9A16IxkVx7lsNbFb/R6yq8r/PGOnyEdbRVGuugboiMb2S9A7Ywia8AFlz8YvrPZ5zvv3vJ4PLVW8OFhI3yP2TI43G0wD6oe8FoFjfhkIx53fzd1sWwnVbLNLgqeedqfk2ag2jY0vY+U=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T12-52-44Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698583837.623234,
                        "modified": 1698583837.623234,
                        "name": "/dev/shm/incoming/2023-10-29T12:40:36.ais",
                        "sha256": "76492f809b18486f5b200b2f8ffb51d4f10433e215dc5c4fda97f0aeea1c3f04",
                        "size": 725716
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "8262ba71f4b78ef9a1359b78ca1e5d4fb6b1929df94b3aa68506d6a5be7ed7b6",
                "size": 217480
            },
            "errors": "",
            "filename": "2023-10-29T12-52-44Z",
            "size": 217480,
            "verified_at": 1698597017
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698583374,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "rn7TAOLbhcY+mVlOxrTtcbHaxZq/srsf7ZJn8L1AcSfGuRlbFBNU8tKKFsbspE3kqZkbGy/MyZzoKrCEV7MZy3Dvc0ktxGJhDa69ux3S+r192qR42ySARr/5m+HvYffAjzqlLy1ArGkEKuEGb0aWodWNY6FpRi1IcVmkQ9r78mTzDXlcvieBpK/M4ADJnq4Btagu5THr6ntJzIbrMVrMxOCmHe4UcqQTrDzattRqKpMZ65d0E1oztbRmfqfrF2H0PRfRcRAoewLuGqf51tagZaO0Q/U8308KiB+cFJ9wzxtqgOi6dDih3WhS+USQtfMQir94/YBjrCgSPLtGNV/iytT1xYWlCUaRUudJYEFVMrNL2rrpoDxcWss3rAbSIFXEiGw4CjvNAWYcw9MoAuKr/dxGB1H3duxG4SI8fcYBYIWCy1p6cLPJE2Vay0aDHQiZfJ2S+nApLQROKowSRHtj5Q++7dZO7AE1qZt1ebwqKiHhKO/DWvKurAagpu+ZSuihw/9udN06OUOPoUBsut8wPfta3bIvXEsB+EiO0sKkLFc2m4gQTb1W1Sr64meJoK6LpOhpZR6luekxcmX+PpiDGRM44KnGY8lEQ0VVIFQF1vUWb+HOHjTQWrFeqLmS3x5F+BTz9b5YwqP+juX6IejKklN2WKplVReHt1ChhZ13MAc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T12-41-34Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698583283.0332341,
                        "modified": 1698583283.0332341,
                        "name": "/dev/shm/incoming/2023_10_29_124103_00_00_K_162.txt",
                        "sha256": "4523c18523c442f1c515d1f8502e3d144b84f49a68f398b906cb132052b373a5",
                        "size": 2050
                    },
                    {
                        "created": 1698583282.873234,
                        "modified": 1698583282.873234,
                        "name": "/dev/shm/incoming/2023_10_29_124103_00_00_K_162.kml",
                        "sha256": "2d6322ea185a31a4959295d3327207ae9dcf847816631f9e397960385ba65d25",
                        "size": 978
                    },
                    {
                        "created": 1698583282.4332342,
                        "modified": 1698583282.4332342,
                        "name": "/dev/shm/incoming/2023_10_29_124103_00_00_K_162.jpg",
                        "sha256": "23433ab2b4d7ab9e5e6259706c9ca5a3a424ecee1bc5f597a0ad21d40fbe3340",
                        "size": 863248
                    },
                    {
                        "created": 1698583260.573234,
                        "modified": 1698583260.573234,
                        "name": "/dev/shm/incoming/2023_10_29_124047_00_00_K_161.txt",
                        "sha256": "cb2fc9aa7c13c63133907502997a57728005aa374b2727a2631d392152d1b523",
                        "size": 2050
                    },
                    {
                        "created": 1698583260.353234,
                        "modified": 1698583260.353234,
                        "name": "/dev/shm/incoming/2023_10_29_124047_00_00_K_161.kml",
                        "sha256": "8330fcbb474687b6be5f17a20059944027f579eeb39f010d648fed0b94aaf5a4",
                        "size": 975
                    },
                    {
                        "created": 1698583259.7132342,
                        "modified": 1698583259.7132342,
                        "name": "/dev/shm/incoming/2023_10_29_124047_00_00_K_161.jpg",
                        "sha256": "67c6c4466648a41e5e8d4afb3d652ba52b737c4cefe0dc6661e603f917a6d09a",
                        "size": 815147
                    },
                    {
                        "created": 1698583236.403234,
                        "modified": 1698583236.403234,
                        "name": "/dev/shm/incoming/2023-10-29T12:30:34.ais",
                        "sha256": "9392c9f4f3032adce72cf11acaf0f5923f90f6f078d9727ec601ff0300fef909",
                        "size": 621488
                    },
                    {
                        "created": 1698583216.093234,
                        "modified": 1698583216.093234,
                        "name": "/dev/shm/incoming/2023_10_29_124001_00_00_K_160.txt",
                        "sha256": "0b51f9df91bf3659f64b60cab986d5a110612b895e61b58f3418ce7a1ea2e56f",
                        "size": 2050
                    },
                    {
                        "created": 1698583215.9332342,
                        "modified": 1698583215.9332342,
                        "name": "/dev/shm/incoming/2023_10_29_124001_00_00_K_160.kml",
                        "sha256": "dd9cc92493d46baa6b4c3ee78296979dbd94d0aa1204697d4e76c5b29f3e6831",
                        "size": 976
                    },
                    {
                        "created": 1698583215.5232341,
                        "modified": 1698583215.5232341,
                        "name": "/dev/shm/incoming/2023_10_29_124001_00_00_K_160.jpg",
                        "sha256": "329721844c53c54d8736657a1614304250121aada3a373154b4d35a4c0c6ad55",
                        "size": 666582
                    },
                    {
                        "created": 1698583214.793234,
                        "modified": 1698583214.793234,
                        "name": "/dev/shm/incoming/2023_10_29_123957_00_00_K_159.txt",
                        "sha256": "9f4528ddb9599dfa72ab548ac23ad75d2bd907df66c782760cb7742f6003d52a",
                        "size": 2050
                    },
                    {
                        "created": 1698583214.4632342,
                        "modified": 1698583214.4632342,
                        "name": "/dev/shm/incoming/2023_10_29_123957_00_00_K_159.kml",
                        "sha256": "92504811f516d1315bed96d3ac6a9d872c118b185d8b34011f6b689b308b040e",
                        "size": 976
                    },
                    {
                        "created": 1698583214.043234,
                        "modified": 1698583214.043234,
                        "name": "/dev/shm/incoming/2023_10_29_123957_00_00_K_159.jpg",
                        "sha256": "cf623a4341897033265c9218ab4d04f1738c1526a03c31aefa5f7f981a7d7c74",
                        "size": 657949
                    },
                    {
                        "created": 1698583192.0032341,
                        "modified": 1698583192.0032341,
                        "name": "/dev/shm/incoming/2023_10_29_123930_00_00_K_158.txt",
                        "sha256": "a64bdfb2d1b45de6d842d74c384c4df53f04b7b6969999c0fe39d96b4bbce6be",
                        "size": 2048
                    },
                    {
                        "created": 1698583191.5232341,
                        "modified": 1698583191.5232341,
                        "name": "/dev/shm/incoming/2023_10_29_123930_00_00_K_158.kml",
                        "sha256": "2b23e0837e7bb87815b80972193f2d07d5f3414aec78bdc22b5e73a1d697ac9c",
                        "size": 974
                    },
                    {
                        "created": 1698583191.333234,
                        "modified": 1698583191.333234,
                        "name": "/dev/shm/incoming/2023_10_29_123930_00_00_K_158.jpg",
                        "sha256": "e076af14f03b24885536e2aec75b35bb6fee1b1c6087aca64170cfd15b303c51",
                        "size": 1098673
                    },
                    {
                        "created": 1698583158.423234,
                        "modified": 1698583158.423234,
                        "name": "/dev/shm/incoming/2023_10_29_123854_00_00_K_157.txt",
                        "sha256": "a9071c0ffc7b71264a041c0264096159d1ad70828aa96a1406674cbe75441ab3",
                        "size": 2050
                    },
                    {
                        "created": 1698583157.9932342,
                        "modified": 1698583157.9932342,
                        "name": "/dev/shm/incoming/2023_10_29_123854_00_00_K_157.kml",
                        "sha256": "c3fdc03909f40c395b91870648b4d44ae3a06b08c24503faeead75e6234f099d",
                        "size": 975
                    },
                    {
                        "created": 1698583157.423234,
                        "modified": 1698583157.423234,
                        "name": "/dev/shm/incoming/2023_10_29_123854_00_00_K_157.jpg",
                        "sha256": "f6cd524a02cef586e06871af489ae49e69c30438f73a50532c806f01d3cdb53f",
                        "size": 890502
                    },
                    {
                        "created": 1698583156.363234,
                        "modified": 1698583156.363234,
                        "name": "/dev/shm/incoming/2023_10_29_123851_00_00_K_156.txt",
                        "sha256": "0e51aae48a435439e005814f61e7a58d34f0ac4047da70f30e6a3bffe5d494ef",
                        "size": 2050
                    },
                    {
                        "created": 1698583155.9332342,
                        "modified": 1698583155.9332342,
                        "name": "/dev/shm/incoming/2023_10_29_123851_00_00_K_156.kml",
                        "sha256": "d7f9e2f636be721f83178e751059da5a48715db3f1016f6d5e09fc5d80b7cbdf",
                        "size": 976
                    },
                    {
                        "created": 1698583155.593234,
                        "modified": 1698583155.593234,
                        "name": "/dev/shm/incoming/2023_10_29_123851_00_00_K_156.jpg",
                        "sha256": "080412d89e345af28c6f4d555c991228d7f33dd1c657785d961b0ff3aedd8734",
                        "size": 976217
                    },
                    {
                        "created": 1698583144.4632342,
                        "modified": 1698583144.4632342,
                        "name": "/dev/shm/incoming/2023_10_29_123847_00_00_K_155.txt",
                        "sha256": "2196aa4afc8d44b455ea3dbff90f2c1a003fd776c017b81f1617c9967f303c66",
                        "size": 2050
                    },
                    {
                        "created": 1698583144.2232342,
                        "modified": 1698583144.2232342,
                        "name": "/dev/shm/incoming/2023_10_29_123847_00_00_K_155.kml",
                        "sha256": "7c068b90369c88a3a8a3480e1f988d0f9400f1bf8d294dc31f61bc086bbe85ca",
                        "size": 977
                    },
                    {
                        "created": 1698583143.923234,
                        "modified": 1698583143.923234,
                        "name": "/dev/shm/incoming/2023_10_29_123847_00_00_K_155.jpg",
                        "sha256": "acb8e6b80c3a0f9773ef116283fad59eab8cfedf3c6a6f171b38420c3284b26e",
                        "size": 1070870
                    },
                    {
                        "created": 1698583143.1832342,
                        "modified": 1698583143.1832342,
                        "name": "/dev/shm/incoming/2023_10_29_123841_00_00_K_154.txt",
                        "sha256": "9453c9697eacc3e3dd3e93a9682319dee63feaef8353ab2f86bee15130f9a6c4",
                        "size": 2050
                    },
                    {
                        "created": 1698583142.853234,
                        "modified": 1698583142.853234,
                        "name": "/dev/shm/incoming/2023_10_29_123841_00_00_K_154.kml",
                        "sha256": "63f538cab12b1bf37f46eb7215212fe108be1164d8a33bb9ee6b25832122a612",
                        "size": 978
                    },
                    {
                        "created": 1698583142.403234,
                        "modified": 1698583142.403234,
                        "name": "/dev/shm/incoming/2023_10_29_123841_00_00_K_154.jpg",
                        "sha256": "e2bb2fd2498fca3974b50d384f33c74e5963bc7f2bdf478bbf4aa11adb6975f9",
                        "size": 1128501
                    },
                    {
                        "created": 1698583099.7232342,
                        "modified": 1698583099.7232342,
                        "name": "/dev/shm/incoming/2023_10_29_123750_00_00_K_153.txt",
                        "sha256": "73c0b7547944f91887a42d77eee6b98481f3dcda1573bd9305aeebbff99deb92",
                        "size": 2050
                    },
                    {
                        "created": 1698583099.4632342,
                        "modified": 1698583099.4632342,
                        "name": "/dev/shm/incoming/2023_10_29_123750_00_00_K_153.kml",
                        "sha256": "5f3568fe833b8fea7078e9bdc435ef1a57bdc92571e36151954c4f4e1bee2378",
                        "size": 978
                    },
                    {
                        "created": 1698583098.883234,
                        "modified": 1698583098.883234,
                        "name": "/dev/shm/incoming/2023_10_29_123750_00_00_K_153.jpg",
                        "sha256": "18668724cfe0322a87afb30db8a5c29f0af0258e7192819d48fbc0efd9f77f45",
                        "size": 781770
                    },
                    {
                        "created": 1698583097.833234,
                        "modified": 1698583097.833234,
                        "name": "/dev/shm/incoming/2023_10_29_123746_00_00_K_152.txt",
                        "sha256": "b17d910ccab9a82c2bea526b7069dea3f1cd1fbbd6a6ff2e092420ae13a8b4e7",
                        "size": 2050
                    },
                    {
                        "created": 1698583097.663234,
                        "modified": 1698583097.663234,
                        "name": "/dev/shm/incoming/2023_10_29_123746_00_00_K_152.kml",
                        "sha256": "8b79716af4c18fbd06db9bfcab270a9bcc6053a4033cfefaf7107febfa484e34",
                        "size": 978
                    },
                    {
                        "created": 1698583097.413234,
                        "modified": 1698583097.413234,
                        "name": "/dev/shm/incoming/2023_10_29_123746_00_00_K_152.jpg",
                        "sha256": "fcd9e82f6bdbdca6bde876870b0e49e339c755dac5c6b7448a24984eabc2af8b",
                        "size": 1026960
                    },
                    {
                        "created": 1698583085.4432342,
                        "modified": 1698583085.4432342,
                        "name": "/dev/shm/incoming/2023_10_29_123742_00_00_K_151.txt",
                        "sha256": "2454e4ddaa13c4a126f733440eee4e2880f717714de98765a67f49515476f67d",
                        "size": 2050
                    },
                    {
                        "created": 1698583085.123234,
                        "modified": 1698583085.123234,
                        "name": "/dev/shm/incoming/2023_10_29_123742_00_00_K_151.kml",
                        "sha256": "7daa74bd3b4a8f5099828f5cbe4c435e7fb4fd7944ac01e27976e54b2cafa23d",
                        "size": 976
                    },
                    {
                        "created": 1698583084.393234,
                        "modified": 1698583084.393234,
                        "name": "/dev/shm/incoming/2023_10_29_123742_00_00_K_151.jpg",
                        "sha256": "8881f7fccde81079bbd1d1e9b8ce35e01306c2b3a734485d7bfe691c08c2fa90",
                        "size": 1137471
                    },
                    {
                        "created": 1698583083.383234,
                        "modified": 1698583083.383234,
                        "name": "/dev/shm/incoming/2023_10_29_123739_00_00_K_150.txt",
                        "sha256": "717eb23e27d4c1faa90aef6fa91366493ec67f7fdc9022eb1281f82b52784c8c",
                        "size": 2050
                    },
                    {
                        "created": 1698583082.633234,
                        "modified": 1698583082.633234,
                        "name": "/dev/shm/incoming/2023_10_29_123739_00_00_K_150.kml",
                        "sha256": "7d3c3f849049512c1e04defd98377586e09a4d338708125254f016e3641ee42d",
                        "size": 977
                    },
                    {
                        "created": 1698583082.383234,
                        "modified": 1698583082.383234,
                        "name": "/dev/shm/incoming/2023_10_29_123739_00_00_K_150.jpg",
                        "sha256": "d20a35057021a795548c89501a2c958c881463910ac05db1da990758329c039d",
                        "size": 1100501
                    },
                    {
                        "created": 1698583080.303234,
                        "modified": 1698583080.303234,
                        "name": "/dev/shm/incoming/2023_10_29_123735_00_00_K_149.txt",
                        "sha256": "3bec785a76eebcae09d3131d077d3e7d12543cf215fc964a04bde0e2bfe6b0d1",
                        "size": 2050
                    },
                    {
                        "created": 1698583080.123234,
                        "modified": 1698583080.123234,
                        "name": "/dev/shm/incoming/2023_10_29_123735_00_00_K_149.kml",
                        "sha256": "bfc11300cf1deab8aa599b2835e6682802cac387501da55b0123edd81f11d47b",
                        "size": 976
                    },
                    {
                        "created": 1698583079.303234,
                        "modified": 1698583079.303234,
                        "name": "/dev/shm/incoming/2023_10_29_123735_00_00_K_149.jpg",
                        "sha256": "a437e7eade9ef6e8f7f71760a0fa99265adbd1fd6bc32988623168eda7645118",
                        "size": 1054497
                    },
                    {
                        "created": 1698583067.603234,
                        "modified": 1698583067.603234,
                        "name": "/dev/shm/incoming/2023_10_29_123730_00_00_K_148.txt",
                        "sha256": "230efca3f63c8fe37dee7fcf3562dca9ccd2698ab5aad0b4162002a42502b21a",
                        "size": 2050
                    },
                    {
                        "created": 1698583067.1832342,
                        "modified": 1698583067.1832342,
                        "name": "/dev/shm/incoming/2023_10_29_123730_00_00_K_148.kml",
                        "sha256": "c5c6ecb95a404af0581aa0c2d1fec51642e9aa7b9b9ec7fcf9b1581b46859d22",
                        "size": 978
                    },
                    {
                        "created": 1698583066.9632342,
                        "modified": 1698583066.9632342,
                        "name": "/dev/shm/incoming/2023_10_29_123730_00_00_K_148.jpg",
                        "sha256": "564838de4c35a508e7b5836e5af741d67ce1bf62d2f909501fda119789ffc41a",
                        "size": 970944
                    },
                    {
                        "created": 1698583055.323234,
                        "modified": 1698583055.323234,
                        "name": "/dev/shm/incoming/2023_10_29_123721_00_00_K_147.txt",
                        "sha256": "16d674093622f63b3cbf4eb8ac6bb481db45a9855677d8f1121ad711c9b13999",
                        "size": 2050
                    },
                    {
                        "created": 1698583054.9732342,
                        "modified": 1698583054.9732342,
                        "name": "/dev/shm/incoming/2023_10_29_123721_00_00_K_147.kml",
                        "sha256": "1590bb5608f3e2d1cdc31a182881e00fe713ec2ada2b876dc4cdb4d8dcbee194",
                        "size": 978
                    },
                    {
                        "created": 1698583054.793234,
                        "modified": 1698583054.793234,
                        "name": "/dev/shm/incoming/2023_10_29_123721_00_00_K_147.jpg",
                        "sha256": "1bdbfd6491565bf1e26d45fa49bde40bcc1c2935a2062721bda484ee249de692",
                        "size": 1157360
                    },
                    {
                        "created": 1698583031.913234,
                        "modified": 1698583031.913234,
                        "name": "/dev/shm/incoming/2023_10_29_123649_00_00_K_146.txt",
                        "sha256": "fa47ee34c67f3e06ac3a930ab75f85b6f515de5d094c3f5daf35fdafb2ef7124",
                        "size": 2050
                    },
                    {
                        "created": 1698583031.563234,
                        "modified": 1698583031.563234,
                        "name": "/dev/shm/incoming/2023_10_29_123649_00_00_K_146.kml",
                        "sha256": "54f97efec832d10d134ac865df50ef2ed45f01283e9960048bbb596e6001530b",
                        "size": 975
                    },
                    {
                        "created": 1698583031.293234,
                        "modified": 1698583031.293234,
                        "name": "/dev/shm/incoming/2023_10_29_123649_00_00_K_146.jpg",
                        "sha256": "892467a466d6ea89cb0f89d6db87df538740dc0a38a1e2b56ef51f441a859146",
                        "size": 711380
                    },
                    {
                        "created": 1698583019.4432342,
                        "modified": 1698583019.4432342,
                        "name": "/dev/shm/incoming/2023_10_29_123637_00_00_K_145.txt",
                        "sha256": "718354ac55bc537ba411b630ed554a2a135a51de8f266584904dee3303f18509",
                        "size": 2050
                    },
                    {
                        "created": 1698583018.9932342,
                        "modified": 1698583018.9932342,
                        "name": "/dev/shm/incoming/2023_10_29_123637_00_00_K_145.kml",
                        "sha256": "8551a2af9e808cb26e02553fd0f8c85e5c303755952196c5e05f9ae91a502e93",
                        "size": 976
                    },
                    {
                        "created": 1698583018.4332342,
                        "modified": 1698583018.4332342,
                        "name": "/dev/shm/incoming/2023_10_29_123637_00_00_K_145.jpg",
                        "sha256": "3c836da724dbcd8e022bc60d8077c691601740f45fee17472ffcf2eb5e63a1c7",
                        "size": 840037
                    },
                    {
                        "created": 1698583006.4332342,
                        "modified": 1698583006.4332342,
                        "name": "/dev/shm/incoming/2023_10_29_123619_00_00_K_144.txt",
                        "sha256": "66d2d97e2be65d4d8385c3c24e7a5e12b96f28495c1e0d16f315ed872d06d823",
                        "size": 2047
                    },
                    {
                        "created": 1698583006.2832341,
                        "modified": 1698583006.2832341,
                        "name": "/dev/shm/incoming/2023_10_29_123619_00_00_K_144.kml",
                        "sha256": "8a5b757388da94450fcb65aeba3fc3be7a6778ee4ba530d0a37b350d21cafc62",
                        "size": 980
                    },
                    {
                        "created": 1698583006.113234,
                        "modified": 1698583006.113234,
                        "name": "/dev/shm/incoming/2023_10_29_123619_00_00_K_144.jpg",
                        "sha256": "e33fe5cf67a351247dc1f2f00588df1024446ec43f90c53b295d0bb70db749f8",
                        "size": 940463
                    },
                    {
                        "created": 1698582994.103234,
                        "modified": 1698582994.103234,
                        "name": "/dev/shm/incoming/2023_10_29_123615_00_00_K_143.txt",
                        "sha256": "d7837e936a922172c31eeb7d0db4a6572a82efac753a66536e3a67fc62872723",
                        "size": 2045
                    },
                    {
                        "created": 1698582994.0232341,
                        "modified": 1698582994.0232341,
                        "name": "/dev/shm/incoming/2023_10_29_123615_00_00_K_143.kml",
                        "sha256": "d0c792f71e38938256fc4cfa4a15b812c4fef83167bf2264aca2a89bae88a6d2",
                        "size": 980
                    },
                    {
                        "created": 1698582993.893234,
                        "modified": 1698582993.893234,
                        "name": "/dev/shm/incoming/2023_10_29_123615_00_00_K_143.jpg",
                        "sha256": "eb61e7a2cd5ebf65f29a8fc801ac5b6bfb3152a3f4b605d7ba60e59372d62508",
                        "size": 857748
                    },
                    {
                        "created": 1698582992.823234,
                        "modified": 1698582992.823234,
                        "name": "/dev/shm/incoming/2023_10_29_123611_00_00_K_142.txt",
                        "sha256": "5c52c3884198743064e716c1bafbaff44d2bd4862d2c842f1845fa668cf85f33",
                        "size": 2044
                    },
                    {
                        "created": 1698582992.0032341,
                        "modified": 1698582992.0032341,
                        "name": "/dev/shm/incoming/2023_10_29_123611_00_00_K_142.kml",
                        "sha256": "371b21f0eb6db8efdd02542ee6aa35b8373fbe55e86c54996a2297557055e11c",
                        "size": 979
                    },
                    {
                        "created": 1698582991.793234,
                        "modified": 1698582991.793234,
                        "name": "/dev/shm/incoming/2023_10_29_123611_00_00_K_142.jpg",
                        "sha256": "f0745a298d65c43f043513fb7293bf3ffbaac4156f1729608a6640508cb1dff7",
                        "size": 708116
                    },
                    {
                        "created": 1698582947.403234,
                        "modified": 1698582947.403234,
                        "name": "/dev/shm/incoming/2023_10_29_123528_00_00_K_141.txt",
                        "sha256": "1719e2ea062c0929e90587509f872547cb2c98be8a382365497e8d9575150673",
                        "size": 2048
                    },
                    {
                        "created": 1698582947.0232341,
                        "modified": 1698582947.0232341,
                        "name": "/dev/shm/incoming/2023_10_29_123528_00_00_K_141.kml",
                        "sha256": "33e7becece32363f69c7add924d07c16ee05f4591a23c2c4fa3545f1eb6a3d70",
                        "size": 978
                    },
                    {
                        "created": 1698582946.413234,
                        "modified": 1698582946.413234,
                        "name": "/dev/shm/incoming/2023_10_29_123528_00_00_K_141.jpg",
                        "sha256": "40379d012f583f8490df1d07b11e44ebbf6f02619ef3b467e9a520825de90d81",
                        "size": 688737
                    },
                    {
                        "created": 1698582945.353234,
                        "modified": 1698582945.353234,
                        "name": "/dev/shm/incoming/2023_10_29_123525_00_00_K_140.txt",
                        "sha256": "9242c32a2d1a52dd4bc041e0fb619fbfa8859b9451bc68e5ff43cef8cbc17421",
                        "size": 2047
                    },
                    {
                        "created": 1698582944.813234,
                        "modified": 1698582944.813234,
                        "name": "/dev/shm/incoming/2023_10_29_123525_00_00_K_140.kml",
                        "sha256": "8118e95dd2e1d754038a7458c656b8e9cdbacf6c408d579342426bd2f345c549",
                        "size": 977
                    },
                    {
                        "created": 1698582944.353234,
                        "modified": 1698582944.353234,
                        "name": "/dev/shm/incoming/2023_10_29_123525_00_00_K_140.jpg",
                        "sha256": "6707b3e33bd96507e5c2d0e6f469050f4dedb675fa75973b25dc86da8ccb6411",
                        "size": 675087
                    },
                    {
                        "created": 1698582932.5032341,
                        "modified": 1698582932.5032341,
                        "name": "/dev/shm/incoming/2023_10_29_123510_00_00_K_139.txt",
                        "sha256": "3bbc5db15602060693f8356bff66662c1c44bcb3455d59852519d52494d1ec77",
                        "size": 2045
                    },
                    {
                        "created": 1698582932.2232342,
                        "modified": 1698582932.2232342,
                        "name": "/dev/shm/incoming/2023_10_29_123510_00_00_K_139.kml",
                        "sha256": "19a5ddc6d29ae7c33061224bda67440fb40e1e40eb4fa85f4764275687fcfa39",
                        "size": 976
                    },
                    {
                        "created": 1698582931.4732342,
                        "modified": 1698582931.4732342,
                        "name": "/dev/shm/incoming/2023_10_29_123510_00_00_K_139.jpg",
                        "sha256": "355e89c286b71561d6b53005c22a0148fdbbb5b947a932c828aff5c5505c31c7",
                        "size": 1089595
                    },
                    {
                        "created": 1698583284.4432342,
                        "modified": 1698583284.4432342,
                        "name": "/dev/shm/incoming/2023-10-29T12:11:23.ts",
                        "sha256": "4a2325e99cbdf673a20e418a89a8889ca8705d76bc5b9116f13c7236f9ae93bd",
                        "size": 470914432
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "54e07b6c74b6d69482de5782f36f7ff52b26f01bb26ffcb32cfa3c1799f69531",
                "size": 450258461
            },
            "errors": "",
            "filename": "2023-10-29T12-41-34Z",
            "size": 450258461,
            "verified_at": 1698597491
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698585169,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "XWcIeah1S7nH6cfVMeQpLdgO3xL7cHBqKzBTH7jEa8JypLF49vEIKO1MF7cJBJ1QYxOB1DA1Igt7vxnQRbx5xrR9wL7+S0hyz/sM7Lw3a9pxcpxUNtpA7Dx+otnccxvpDxMmhVjKM8cqo5CrmoHPBO46s3LyPXqfQWswUO15JRiwNGF0Gh5tAgyT+yd8QQt5SxxNZ/berKbYmPCD4HsZj/twYtGZe2f9tUEVMfbpZDxIl0wpfUhABnHqIaGhE8wDUnrkbcIMDdPgHoumfQlmr+OklWCPV4QsluKW/H9GrTe94HnoRtLo8N7nj/MgNC17HW2emRqiyy4HbslQ/A26QLNi0/0w2M9L3D45a0W2OVWfLG/In6uJfqdo8aUneqkiKCOGnc3pPXoe4zCrXy9pyH6HNdAK0E75ANo6O0alGYLb3FS5fgZ/CcuGMj9646xuY/FkpFxqkTx9eR+ZnmxanP8C91kwGQqHo3Cn2VIUiOd4rfr6U9HVutNDOIxdhVNCiznLwUqXvJsqYC6efQGdyFmeIvvLxzO8kncHHhRgbAvlq7Dpc/xSDi7Z6ImlmrnYuafZ2vVKtoMAe/ndf8ESuLxAbkzc09dEVqwmMeZc8UAEz+rUKh4P6ktxISCNGn/DlG428PwN/DRZEtygKEm0FWg/jEBkLWM8IyQx7SrNi7w=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T13-11-40Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698585039.653234,
                        "modified": 1698585039.653234,
                        "name": "/dev/shm/incoming/2023-10-29T13:00:38.ais",
                        "sha256": "ef5e6350d54bd2bb809b017d137dd304522f06cf00290cf14d6a95b2882e2984",
                        "size": 850166
                    },
                    {
                        "created": 1698585090.9632342,
                        "modified": 1698585090.9632342,
                        "name": "/dev/shm/incoming/2023-10-29T12:41:30.ts",
                        "sha256": "1a74de33f732ece6ab2846890492b2918f8209c62548275d6313e25d07f56849",
                        "size": 438024208
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "d3581837bac09e427380cf31a8f06093b33a5a0c28f17db111bab55785139f77",
                "size": 396460392
            },
            "errors": "",
            "filename": "2023-10-29T13-11-40Z",
            "size": 396460392,
            "verified_at": 1698597594
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698780266,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "RXtWzZloVgVipO5b1V+lMN2VIaEpfs9HHAtRdznTCve6D4z3RapLiI5b+B7NXiIVMv+1ZXDkF1jv6iCa+2MwxIF3693/BxQHIzSozQfpLJg1iv/S+uaI8q+mhovb4ZkuOnOM3gQFQm7FxnpbeIY52EA4Bqchtg3y+pd28qC4S3gbn2pZqgyDcgrxUt9NJdcrBqf3At7auVBZ4FY2iePn3Eb8EzNbbppDsDKNI/qymoVg+CGvSAKbbyX7cq+yJvImtvtxc+d0fO5+U7FDS4svciCTNRRpdnuKUjRc0f3Kb74rUiIuyMX3ndXecwd7NQm1WPWP1fDeIW3wv0ORosRCQWVX3Mha5AqR3CcEYVXzfaykqOIkwPGrB2LZeBZGP4wgr9/KK7JqL8Bnjw3OUUsDFYGJb9at2Q9G49BY8rlJZp2g5LDimasFPo/XDRflsl06JABGGA9TERElElyt4X7Pf1vab/bMuUcmtP/M6b9APCfkGMA8F5h/8dPzTMw27CHhi0oRyjgl3piTtZDmHTAH5HOPUPSOVhr4CTb91mFDxjJTcjM3cGd67Sjmbxh7LxlbPC7UY6+Mv00xfQYeaUD0Ikxeq05+1xEvdGAgOJljeEN7AbSdXpyi18GtuK3hVC4Er0EHqAdgAvDpsq5pi8T5OM818V8opvj1LOACbOsZAfk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T19-24-12Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698780126.153036,
                        "modified": 1698780126.153036,
                        "name": "/dev/shm/incoming/2023-10-31T19:12:05.ais",
                        "sha256": "6547473c95f739c3784af3c57cbffa103478719f4bdb0e94a04cfe1cebc65bcc",
                        "size": 74434
                    }
                ],
                "mission": "OSP2",
                "sha256": "969dff6a7d5a94dfa6ce1bc55afef5a152157b0fe9963896397a361c94f4b6c3",
                "size": 18422
            },
            "errors": "",
            "filename": "2023-10-31T19-24-12Z",
            "size": 18422,
            "verified_at": 1698784513
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698566762,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "qBV6U9djPTCQBcbeNa2zr1ytrVny+wAuBsGlOqAnwg0uoxyFL9S9GUw/KPv1W1ABXLiZuJA4EXuGu3rCg4YhkiCjVEbYnJqa0jcn/rddJjFfvATuv/jZOvk0p4QkG7eqssIuRX1lgvBh0EjfjipP6uAS9377/7TavkEjWK3RIbTrv+EQ2gbWyjN2G81osKsgB9QzFWsyTEPw1BGOXUdWKdz3GN6cLMCadN/RsWrET9Vjdde93UCXFghASJR0qVPczup/y00mRfhOYuD+X0mdA2je0ykZ9CsiRHlLowc0epvG0RezvktspF2KnBGcfW8aSeHQm5TAXxBB60O1suLZm3oTgz+zXRYBOmzzHhtv/MhnqPod1a7c24BTitp2eKgX8DJLjEczaZsG/E0noXrXecUXeCsSaKTI0u2dt0ijMPj+bPvFE4N0W2CwFABQ5w+gzzYPUPX8y/uk5wWQ8N++hhxB1HZc2hbg1jZjM5GDvrnHf1rQJ+wPXhiZn7z1yAbp0/G7OWA458LOrZsfDCJkj00MahhNnoStnHIrsXx0K4BW9lNrL26AjwuxNWXA2KRNiFTAIdjxy8qMqeBzer0orL9VK1wBPtv6qI8Gy1Xpatms52DByCltNDCCjTKJunvwTLaIZfGHsmnDfN6Jfd1YFrIszXplMKhP3lFYih70N6g=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T08-05-43Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698566619.8208659,
                        "modified": 1698566619.8208659,
                        "name": "/dev/shm/incoming/2023-10-29T07:53:29.ais",
                        "sha256": "446db712c2f93d4ce810c05d7bfd6db5ae282e580ecd2659dd8637d8054c2cb5",
                        "size": 8494
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "8fada0908efbcb6a3be6ef486fb081cc5abb86f86bf0a61ba24f2b4e37776366",
                "size": 1882
            },
            "errors": "",
            "filename": "2023-10-29T08-05-43Z",
            "size": 1882,
            "verified_at": 1698594143
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698581273,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "i5Sf/VYQqr6b/HYeAcSLsmYHwg8BKGLxHWv2Iv2B4iWCrxdR2f4+qTM2dqkhb7OU29Tsb5c2qeVYAkaQKJbHiNvcTS4eYN/cD6Qc8ysJtcDBigQ6NABxYNi+5Bi1Mmel3JgfJiyk64bNXCcHovCQH5Wt0PNzf+2Npi09xXFU8KyaDy1YbjFy8ENVDFO7UEQoVILTxnJB/NAJPFqHIyflThRLjbtk3l1XfHCPHGMqJGF3Wa1d/R0Hpi0HgMO6RK1z9vS1jf8j2IvAbynJM2V3Rs4mc+pijCb+DrP82dBfWrztwnJj+KBGK5uZtGS6BVet49kXicLcovDN3WcMMMK48PeKI50f+ALAStHHyyKcWmoRBPQ656/WvFpY1wBIOP04htUndCBWVKfP88qxJYvnKqo21JzEpuKRFik9EY++UGCwDhy/2GC/q9LT55NPZtEKcut6NfWF2bC5U16Urk27qv5Dp6x+x5bLhFQaidV+5TTPysnVAggHLZNzSMW5qqZRWeF/Wts7ynwU7Yi3415lFcYTZD+LoPHBiif84QUbOmG+TyPGk6fUjpo4jzfzxP7FDc4Zs/Aqt4lP/+jOb6/9o0bpFABaVieVHQPbapnJkFtJDiitc0ep0xT/R/xjXQxT28S3Jng82+guseXKed3K/wpf2Cl+SeTOjhJMjZSBu/s=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T12-07-33Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698581127.553234,
                        "modified": 1698581127.553234,
                        "name": "/dev/shm/incoming/2023_10_29_120509_00_00_K_138.txt",
                        "sha256": "801d7963328a3aacc884d0cd1295adb27a71fc336f08409326dfe467a8a6ef79",
                        "size": 790
                    },
                    {
                        "created": 1698581127.423234,
                        "modified": 1698581127.423234,
                        "name": "/dev/shm/incoming/2023_10_29_120509_00_00_K_138.kml",
                        "sha256": "e4e3babc0e174525fe8240b450b6b7f3a2d7874719895609d465e00d6eae5fb9",
                        "size": 933
                    },
                    {
                        "created": 1698581127.2332342,
                        "modified": 1698581127.2332342,
                        "name": "/dev/shm/incoming/2023_10_29_120509_00_00_K_138.jpg",
                        "sha256": "a4a0c94ab33024697de8eda54558a7c3261232924f0cefe420c4e510dd33e4e2",
                        "size": 574083
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "a3b9198fc376a7ebfc1a325032ac4597e4f860f215e2562e62a630de4a8af4ce",
                "size": 563383
            },
            "errors": "",
            "filename": "2023-10-29T12-07-33Z",
            "size": 563383,
            "verified_at": 1698596671
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698586390,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "kTVn9iP1inpcmQdlWfqX4cK9LjKT2tILdXvDBw7ri93Lk0+GyRbtjsXA3lqoGLA4mWTJsKnv2BlrfFEMzXIUlzVh81rP9NjypcEtPctINe0WPVTDt1USS2hx18g5LFBn2Ns/WNSUMRTmIvIpLDl7SePMeZ3ObR4TlotjCiojhjOsdMvv9QLhhXpe0v4wQLH4sQ4Dm6ADUyg2Vft8+R7n0DKvWyltQC/3bWSwpaT1jushPOOD0ncaw5X9Ck/HwwYXPCVf9m6Zic1DN1gVmy+YZqWSOhMwsCnbR2X5lbLHzu8/hccW3PyMRv0F9XWJDf0cRo3NTBM3Gbx8FOJhdhC4C/rdvJlt3663h4iFIgKfiRQzisskavYZtvIRSpMoc1lIZCwp48YtUlJtSCSj6V9VBm2d55E5TzpAqQk4yOszRuo4RcfNgZJOb1iXuDYDOJpAmvcZRKRURDy4Z5sXNw5ZWkFJ4Pvl8T/aSQB05SfE2efqijkbepErhrHuZIlFPmPxdyU8kL65dCOAEaC27j1pQB7JLALyhunWlRqix8q7khx1LUrowqYnSxnGCf1KbzFam+A0zc0LuAVMCi6YVI2eTMOLAQYwXSpbbOrvVLjhvj3MXCdwwQm6yODfjEzUNFRAPYVV8fE2m4ryiR+rcFWwYRo1vRmdDwhwMj+beNNektk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T13-32-49Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698586241.873234,
                        "modified": 1698586241.873234,
                        "name": "/dev/shm/incoming/2023-10-29T13:20:40.ais",
                        "sha256": "3cd5c157d5e0526602596e9b2821b93258ea6b47ffee887219f2bdc4ce2bc7d2",
                        "size": 580649
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "31c8721869fdba3399724f2b90fc588bdcedb7f20a1a0433e43b2cda1f68471d",
                "size": 168014
            },
            "errors": "",
            "filename": "2023-10-29T13-32-49Z",
            "size": 168014,
            "verified_at": 1698597238
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698586989,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "NVxCC5MaGynqZw6GrD9AANy5/rKHDL9/SCVrKjTRJuRCDcppUwJ0+fj8IqkkPjubK3fdBkyZz4nYQZ1jHdnz1xWnFIEtFayDXs2sdLy1sVf9Di9+PkURDGhryGWWFWHfkg7UBf0wsLjMysx9yHWMR30vhJQjTo80GZ88bYtEYFAvXfQYWnSB0pkjm20F7yMggenXplLYurpdvpIAkC0F6xHqyB3gwSIj8M/5LK6aWusb0atRKLdU54ASEnFsrAWNMfOY8NMDa0ZWQb9EL72LqSSd8iXaTSIc7FDyJmXnJmKsVaId0u2RJFqsISrUSiZahPysYHHPdDaWLwbpSUUm2wqBnhxPiE9ELXsru3T76kiN2+DvG99ypcoPM5Ahet4MnU1Kj+2PRJJ6k68yUkVu/qCYMAKKiqMhLOuv0XWFWLZ7XtwSvtjpD/9wRmcpLyPLhSaSy1Q9S4Mnk3EM2oY3GPrVI/0CfHRs17tMXsFkM0dkJSGXbe8wHGrn2BfsJojOGZdDKMNCvoy3mg9MxaHmJu2p03uQcm3dgpaorH099j1OY6aygfLTNFof5hWwSp9Brpy06Q8ykaeeJPWp8I1X4tKEzNmGneqVcQ+uMj6QW+PVPOIPayV2nRlspZlHm0zc8zeSV3syayN+F6aYYvQzyS51fNxRWuqGld+abfe1+EU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T13-41-47Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698586848.2732341,
                        "modified": 1698586848.2732341,
                        "name": "/dev/shm/incoming/2023-10-29T13:30:41.ais",
                        "sha256": "c13171ff886ba8297af04e90f8d21213ebf58a86dc9e6f410ff1b6516410135d",
                        "size": 193005
                    },
                    {
                        "created": 1698586897.563234,
                        "modified": 1698586897.563234,
                        "name": "/dev/shm/incoming/2023-10-29T13:11:37.ts",
                        "sha256": "d371cd825c915a6377b381b57c1e3b704c38e019698fa920be54aa5988cee964",
                        "size": 444273892
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "33dea8bb3794c7a84fdbc2d720d4461c116f604940d34519bce794b481d5ab93",
                "size": 401119907
            },
            "errors": "",
            "filename": "2023-10-29T13-41-47Z",
            "size": 401119907,
            "verified_at": 1698597680
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698570116,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "QJwC+96V3D2Eo3bcjQaMVcNs9pi8qVT8Gjl/qFfFhh1KR67b9z4JEJo3MyZvQoBpGn2ILq066MwZ9HsoIElr9YpzcowH2QUnoW9sxN/ENCI/CGVfCdGiOR9OvtzKMA7xyzRs++XDGSkr0/BRz0ReM/g44fFZQ5VSXfd/b3r+1olvfN0R4/G0fCk0yle5HNslLq2AFcecjxty1qmcVeAh8MlaKfHydTqWrcHxB0JvkyJ0qaI4V9gxw/RTZnyUiOUtLNV1yY+BoUju9dchOXJHOFzi7hI8Hk2wChYsUC0/6RRivJokULtPCW/ImZtRXcgMnsrsDoH3gkFtUQ8IXtM55ZiAkG59hneFC4HvEUSCYgZUpg5XXkRUOI29lLgWsZop0R385Jz31Zf8+J9DNnAuOyvLlPMTwd/KEHETLBoh4e7oHC4yKn7qWC0wt9Bu2E7248bNdzsEtWcR3rkUhZo4bviknkD1Looa2XDCYRbPb8wARxUgzTYYwfw0tFV5RPHjE9czc5rZ3jsJLqiPJNLU8w24HDT3wG7iFxiVVNT2wonmtFTtAj6rbvt42IeedUhVuYjH0rY6axwuSo+eMYcLdqXB7wFX/5Wsqpq9gdHaxTpdid2ck7ktqXrw07ruSAOKVWOxTqhBKyVvyVYd38tGqgio3c9y8w0cIjUy2mxAH24=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-01-40Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698569971.2332342,
                        "modified": 1698569971.2332342,
                        "name": "/dev/shm/incoming/2023-10-29T08:49:30.ais",
                        "sha256": "ae7f41b452bcb68864815f3908db36317e2cbde0f3abc23dde2560bff778fde1",
                        "size": 131538
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "6b07f71d55f373be95391b9ce5287db0f59b90af39e049f633491bb012d27268",
                "size": 39290
            },
            "errors": "",
            "filename": "2023-10-29T09-01-40Z",
            "size": 39290,
            "verified_at": 1698595593
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698570578,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "c5YdpfvdZRpFCaaCThQmKZ9p153Z76DRi6A7yUEJfBjBaAp4bgITY0kfdUN2PmSn2YZNQr0KSVRswG7KQ98JZWrgVG9wi1y6eo7KVtb+4ZDf4tTE2rZFG9UkXy+JfYz2m832It9JaH6ROWjrpbxaaEbF/iQ2d+CxgUwfQ/ZggAOJkn9Db1pI7rqaP+O7nE6hrvhvKMVs3RnQ+klxIJGpMomvTPWi47ryIl4TqZY39TuwvcvzFv20uhCJLWz5ikmRUFz1tZq1pOhtBlL2Yk9PsN+agJv5TIImLHdeBC3VrcVId3DoNt+ftXccLs82SLg66eAgIAaiUtI/tARCq41m/mtJsT+s374PzxD3CL1BDDbT6QMTMHOaHp3awHOPATjOjtjEbZE4k7/rjv9MCc8ffAx4ujXhR82A3NCOuIdE1GUtoojPO/4ezmQeasVdh8DgPUethdo0YADDUvb5YUXX6fcaOllkFqy+hucfro3hV0rtKOh6+pnFsj1fOp2kAnzfr8I1lW27LEU1WudebFA9pdckTFSd4WcVuBdYOKTc08SW7i5aoRX+SbkLwNAmfHgGtAd3Rk7mp64W7YH+zGgHDOWJUkKzUu3ZawmXITsU0HWGIdOMQFcEfsR8A6jd9rIjDGxgvTpy1Y0Po3aOb2nc6NbwsqXP/1ZsazVfEKB1+yw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T09-09-07Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698570421.343234,
                        "modified": 1698570421.343234,
                        "name": "/dev/shm/incoming/2023_10_29_090647_00_00_K_037.txt",
                        "sha256": "d6a193c71b83ca7f3e8394e2b9bfe3aa9ac0b74e9e3b39fbd6474d04ec52422c",
                        "size": 2041
                    },
                    {
                        "created": 1698570421.113234,
                        "modified": 1698570421.113234,
                        "name": "/dev/shm/incoming/2023_10_29_090647_00_00_K_037.kml",
                        "sha256": "14a016c7f299045eb2385ae1135ae93c367bc97fb63dbfa16ac591103a1a862c",
                        "size": 976
                    },
                    {
                        "created": 1698570420.7832341,
                        "modified": 1698570420.7832341,
                        "name": "/dev/shm/incoming/2023_10_29_090647_00_00_K_037.jpg",
                        "sha256": "3842eb066389a3d94e02f1e6c41f303e9dffb9ab5dbfa3068f7963b1c7f34e79",
                        "size": 1039397
                    },
                    {
                        "created": 1698570420.063234,
                        "modified": 1698570420.063234,
                        "name": "/dev/shm/incoming/2023_10_29_090516_00_00_K_036.txt",
                        "sha256": "c90d29738f3dcc1b8d31b07d112117125b47ec4d635430a4ccb2f5ce5fa6b18a",
                        "size": 2042
                    },
                    {
                        "created": 1698570333.383234,
                        "modified": 1698570333.383234,
                        "name": "/dev/shm/incoming/2023_10_29_090516_00_00_K_036.kml",
                        "sha256": "224ed42c0b493eae1a4c3988a6aebece7c21ce531fd1a49f99a289c047a52837",
                        "size": 976
                    },
                    {
                        "created": 1698570333.2632341,
                        "modified": 1698570333.2632341,
                        "name": "/dev/shm/incoming/2023_10_29_090516_00_00_K_036.jpg",
                        "sha256": "d6072f011daadec7ee4e8d449a353fb57673221c1a5e4e3946d3d7e2c2ad2398",
                        "size": 348259
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "2a78d9ef695a7525242228e0a497d23340fbece102dfe202c0a21fc45d89db3c",
                "size": 1371495
            },
            "errors": "",
            "filename": "2023-10-29T09-09-07Z",
            "size": 1371495,
            "verified_at": 1698595597
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698574955,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "s/+p+g8ivhPJf4jwuR/xs0J1BfrSaY38C2F9Xj0VNDpPyKZrdo3ZTZRIJlfjqtw2E2KYTXZoZ9HOLDmPuyuCKKQrIU0XCMzeOE9IParvzUqxim2hyAw8IpJ2FDqOeAwLSrvAf1AwVewYU91yKJRZxk3dCXAdX2XlcDYdSvocs4IcnKgMLOGZM/fNSJhgYOteWZqodwMTjKEjVeawUSO85g33hFuSNi3BpU8w/MQrpBgDZYP4862cqrQkLclGr34imlBbFW2IduVpKuKknHMTidVG3+n9JL1AeSMiQ70cH2Fbelu1iBCAvreXOP/v3fe5C6LmoBGybl+RdL7+nA+INZUZ1lWDEzCeUntsXnQy7Y0EDYd/lrhuKikgcBJ3yf+erBP9jCanGP6po+dJrS5UnkGl9CU0YVBOTqce8alfDsxCBDCCqH/Fo7LoNdgSd8tZxmUxPWi/jOqVVerZcSzeFn+tQ6M2qv0U2QHrVQk8ohBQ3XeUZ123dBxL0tcrXOwyxEekWC4DUuFex/JHQ8TnUO0B4DiFBsZ8lrfbokkAoi8Xpg9+z8L7cyl6oWLGddHY+AbEuhoFtlVnvN4oW1vREPwNWHyjlZZqGVeAObFCVv4z1VndEI2hYgwe8gO8feFbbQMAfFn6dC5ut5awuUnbfQmzZWFR5CPqbvTFyT+kd6g=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T10-22-04Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698574799.153234,
                        "modified": 1698574799.153234,
                        "name": "/dev/shm/incoming/2023-10-29T10:09:56.ais",
                        "sha256": "663c7f7c38e0fc9ae1441ad02491274a5b0015b5076d4b027b2c30d6dacebc81",
                        "size": 221305
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "7daa36b0d0a85a4d3b7ed199e15c488c1ac0cce3ec06639f03ad27111e2ddcf8",
                "size": 66078
            },
            "errors": "",
            "filename": "2023-10-29T10-22-04Z",
            "size": 66078,
            "verified_at": 1698595937
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698576756,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "eeV/WnX7FZwB0bgXRNPCyqxHnrGEPTBurOMWkTHUmsKH/5rLouTfnwoiDXvVyC7pDKRUdD56RvmQk5rNcJ53BhWCHVlxcgHkQIU1xaW1x++ifDs1RLwB7ZD6ZQiSwqBcme6shoOxPuyxPnrpAu0lE/+00f1Vfgk6YKY3cYoxksnRusaRuAqCEE3WvcwdvGt7z/Gk+YiR3/9qN75oPD6Mpxz2K0FeQb8l7rv9Dxn8itlbugOQni6zyiP5n0GRePwnF97g9r3eElaj0NP8otnXVBMz1+8wCk9D7g/mmxCsgm27pKA9BZSbQwnSJ3HTzbiUJRIrQnBA4POy6VKDZWQx/gMUOJPj5adPj49XwSF0OiSl0GxDoxz8qvVP7Sw9suN+OAjlzz15BFSLI+FjDJMzKX/pNUvpi9YzRRlGkHLLXPB+v/YsE6uikJSTDXbjK9wkduMMZ1GRbhFHQerq/H6gy/3t8R5xvOJ7B5V3OnJoyRV5dtBxfY77zc2EEJAITrEdJgwxxpLO+PZaf6AJ+KhycRNxrC8W9IadsjbkoIg09R0Wl123JfK2BqsoYDBAN17NivdIuLjMEvQztS+M6eQqXc+ImSsqHuVTYCupje9Izc8oHechR7duTcAs4BFF/OVdj0iT6FrMHO8CwFotMCfrE0KDNfYmiFznJu1rogW0Qdg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T10-52-19Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698576612.363234,
                        "modified": 1698576612.363234,
                        "name": "/dev/shm/incoming/2023-10-29T10:40:11.ais",
                        "sha256": "4ec8edc5547825a7b0d616a6560dee4a293c92839ed94225aba63ec334a8a68e",
                        "size": 124168
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "6b2c91c4425a87613163db821d714e33b7028c27029ccca4d03117371af807c1",
                "size": 37356
            },
            "errors": "",
            "filename": "2023-10-29T10-52-19Z",
            "size": 37356,
            "verified_at": 1698596092
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698578581,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "k7fy1HpdJbQtOCqe7FFdDzBEcoeiXVKs8dlrmQaZCH33iWTvPv3c7e3S9oJVOO+2VPhJzi8euDw+UqBPsmOQTRrkxBbDDgnymdekPk7S/rSmVTE0Zu165tTP+9+SSr8N4RT3C3yB96CHEftMiq5aKxwJUBTjE4WB307Z8/JxFe/QWkclQkOD/rt3UpjQ7zCq+amc4alvXxFSRB1h96pUL1V8TEeHcBtfEJhpjRL2RDMbsVX1h/pFSWtSsW4TyNOLBjN+02QzAtzYrlbTO9q7GPld28GFNC2ms8cmEpYYaVU2F0Myh2eZk4iCEBWTB1iHIiRPjGwHVVzkhgzMgxHMCZwoCUojU4aqNR54zU50dz4lhqerR+k6cITdRS4f7ZjjLhYMXp8zIEn4Cwj7hVFRNCV8QSy0VMO+P+x2y3D1Qm3biXbaul3mekIaee8A30AmhAtittPGJdCZ7J90KbFhUgG6u+BOjrHT6GSjFnxI5g7cm5J3n7DQsmQOA/eUrNufeXUm3981TevxGkKBIRCvSzDgR4OKbp6/d9XgccFOXiXTMMVzOgzcfOftgZbkIR3AnjhfKwan4P06SIwxbERmxh6z/3WuhHO9mtkz9XfJy9lf1yNdswBtrkue+242oO3RS/0QTbzVFFcqOMq+bXQ0bCc+0kmQZEBJu3hOm64La1A=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-29T11-22-30Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698578424.413234,
                        "modified": 1698578424.413234,
                        "name": "/dev/shm/incoming/2023-10-29T11:10:22.ais",
                        "sha256": "1cb27e905f003aa17a64d3d3ca5154379dbb5d5cb78cbe8b50efe3f64b953d19",
                        "size": 150091
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "8fc49947784f15b27b4108c1ea22d1846512a22abb40530af0e3a3455188b7bd",
                "size": 44305
            },
            "errors": "",
            "filename": "2023-10-29T11-22-30Z",
            "size": 44305,
            "verified_at": 1698596223
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698642595,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "qmUOh10CobomLZOLU+FsEl+2jUPzUflyV6PV/I//BouDaE5LvPcjx0jTyjFX1spSNrDu38NOw7xTEdGpNpZGaCJxOof7iJ64mRf/QuHitbNc8M9DuaRGDRBZasYCYEatb/Hnc6FN/ZTcYS/uQyqommHS1o5KKrgK53amghX9LMD8jx0+PcHWmofLwed8MSw/ZJ0ei3RR4Fyybshb7p7A6DDTvKuc/P+2ladc597ZS7oa0I63TlFpVm990oEFAfOAtDV4yxD0j+I4wVd6Jd/AuvtE1s8QeomCTP4on1Vdib8H/ZrQWCy0WaFCILiLUnbZ1L4M32m8IClKoRw8ry7QXlhvJ5HSXL+Lxk3frDJ6RKf+T+ePozZj05Isj7zRHACjqZc9l/gG5q1Ie3/3HR2JxIZmSvLi9QelmiaY9f+D2OalAoX8Nt+M0lwSV8/O8vPlTE5SnKvNknjjEj51biG5jkq/R6t3aU+Iac70ZgPun1/QSJ1bkLDiN03Oke3mZAC4jqyedqEx9dhcpgIonnDi9eiEwqWXEI7wfXT8BllwwLRSMhHVGZq5C4Eyst4BstTShP9sSmi11zqcYWlbyvgldKKnadi3WYa+NDXSRGce/wTuMl5/D9EgLIXj52RUs/7+U+noQYa+W44y5sm+7ffFlciADa9zF649vssBz+A65ec=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T05-09-00Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698642433.166036,
                        "modified": 1698642433.166036,
                        "name": "/dev/shm/incoming/2023-10-30T04:57:06.ais",
                        "sha256": "8ab4da67a5b06126e40349cd7c8b41eca412bc3a6e07ac1a661e6157f28ee4c3",
                        "size": 8411
                    },
                    {
                        "created": 1698642530.546036,
                        "modified": 1698642530.546036,
                        "name": "/dev/shm/incoming/2023-10-30T05:01:53.ts",
                        "sha256": "3daabf1f225441742998fd76b92e3d46966879ad7831731ec07d4a57e9549e68",
                        "size": 354918056
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "a25ba5b6ccf0936b062bfe31a118a5c5c4d7535e546d650715861d37dc8479ad",
                "size": 351061687
            },
            "errors": "",
            "filename": "2023-10-30T05-09-00Z",
            "size": 351061687,
            "verified_at": 1698664395
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698747815,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Pc2HTe34TMCdp0L3aCHU28xeHmNsm1eYLlmrpboZrMfFUnHzp5sTGspNREJaxSpcQwjal6iBAN9CBFBa1M7PpFdXDd3CmHEVPDz4+RaN2RGVx4lSBSHUbjWdXkt8JYv2Wa/LkyRcvUyJm1fbMTfrfvx4tURgK3XuhABUCokHk0t5VPuMwfuyi5oy1dpv655MO1YYsS/xIij/O6QvdkP/0jAuGRNNkhSt7y34X/1qZugaIy4ArTmP3NnjSQEjwxKmdChxCNdEAr243woTHo3ifPR+v3Pnr/GkLcIGK41zxkP5McZ7+8JE2uxs1g3J5UefyJxYKisympObj+XM5UM2N+QYQ0JZoFIPisBlnPFbBeLvMPgHZexc0TsnjeeXv4Eof/XRKL1/0lWEb6iztawzvIartzrKWH9vG5a34o79MDG5xx52wNLFq55swNUz/n/ZRKLkhoi7TvF9WAbKudtwb7EuuS973SpvHI5gb305X/ab2CRBHIjeq5CS3GdKLBebBa4ezaEtTdjpKQSB3Y9eN90LHspCI0U2yAQ+RthSLhGMRtZoxDXOFtt0b0MEra8n2dyQLTmYgVMETXj+GD8D+UNwiI2qquuprsISvAiUKG4ajaS/hMHe7oBWOm0+wpjiHcBZG39NELysMBWeIor1AL02AN9YFNih2T3W6kbjJa0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-23-24Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698747676.2214186,
                        "modified": 1698747676.2214186,
                        "name": "/dev/shm/incoming/2023_10_31_102055_00_01_V_054.txt",
                        "sha256": "83ddeead1976a5d537c446ca2a6c3d713e83ee0e6983e4ae308a1807e5eeb005",
                        "size": 2052
                    },
                    {
                        "created": 1698747675.9014184,
                        "modified": 1698747675.9014184,
                        "name": "/dev/shm/incoming/2023_10_31_102055_00_01_V_054.kml",
                        "sha256": "a93b13296a55271f35eaf6013d3b2ae5d00c20cab544471e1e618782492c06e0",
                        "size": 951
                    },
                    {
                        "created": 1698747675.3714185,
                        "modified": 1698747675.3714185,
                        "name": "/dev/shm/incoming/2023_10_31_102055_00_01_V_054.jpg",
                        "sha256": "41c44e13de3aa79e9332d4e83d8bd4d623bc4fed7d11519378d49bad002b3397",
                        "size": 1303239
                    },
                    {
                        "created": 1698747674.2914186,
                        "modified": 1698747674.2914186,
                        "name": "/dev/shm/incoming/2023_10_31_102054_00_01_V_053.txt",
                        "sha256": "22f7b518471b2fcf56eacd8b2e06ff0d29d6a10734921512c1ed7e11863612a7",
                        "size": 2057
                    },
                    {
                        "created": 1698747673.6314185,
                        "modified": 1698747673.6314185,
                        "name": "/dev/shm/incoming/2023_10_31_102054_00_01_V_053.kml",
                        "sha256": "81f2839be69e92200e818ef3f2517b4a51110a029c63b4781c3c27c43f497bc9",
                        "size": 951
                    },
                    {
                        "created": 1698747673.2614186,
                        "modified": 1698747673.2614186,
                        "name": "/dev/shm/incoming/2023_10_31_102054_00_01_V_053.jpg",
                        "sha256": "1b3205050a667a5f0fbeadfd4f104d8edd593739db34fe6e98b519b6f5f2f1d9",
                        "size": 1326164
                    }
                ],
                "mission": "OSP1",
                "sha256": "590685eba3db19b2dae384d7c06b293e4c3c0db5a7e7a189cf846282f52a56f9",
                "size": 2632979
            },
            "errors": "",
            "filename": "2023-10-31T10-23-24Z",
            "size": 2632979,
            "verified_at": 1698766314
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698642287,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "lBkz6I0I83uiuwPTM6ktAwz7rKi+lLxUwfCFtwQyibK9R8qy6HAzWvpoikKNVtjoNo5t9rZMhervzuWmTZ22h0dNxzRkSl6QFRczwvmPg7YApa4h8Cd1hPSqjCB/YuAE+OFxs3Md7H7QZcQ6hiC5ZF/nL+QN9X4Mk3bqhbfRh07/AWqPu8jnTeuOxD1jSWKoYKMVW1bO3Nbgul1vqzwcREocpK2B4o+1hyZi65ht27HMQI2HRG5iuKMKlM4pCh/hqsbteuKzf8qyGz9JjX1HPkwFTBW5yfadggMHsVZzSy2lboo93rBWz1wwqrAZtUSrzLb4tsT+PYD/MdyJtgX6JBqCn8okwL3L0MYFhkI+Px1hyyhIv6b+B7cDwPTsXZmQ2wNS+LvUI82RJiaE0+WPFnLLPS9uP2ou5+/1F6W7qSIKmmtEyJqB2RkNdS0HhzAzoG1g1L0vuSb5kJr/pV9ir80LY1AsA187lA/hD+JSevXcR3nL8bS3VwYj0I1/HT2QS70sdcIXZmbbKed5QkjgtnMMfEx0NNadGyWLaNzqnLXjzGdKG2q3U9Y8i6EM3ConM1hg+0fjqI5c5N+zPkSsSTfl5nFNHu7IKQy3xb18eYH3BMQjobdQMRqhm7dm5pxgRjcF+l/96j41uqE9sSuurrL3+4JVQ3iPbrPerHBoYQc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T05-01-58Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698642107.696036,
                        "modified": 1698642107.696036,
                        "name": "/dev/shm/incoming/2023-10-30T04:48:25.ts",
                        "sha256": "bedc0203bf9ff2542b4f3887b60f8c480a36b58ebf3223295b7bf3ef65cfacec",
                        "size": 564842616
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "d960c2119d8c9d825c3b6bf5a3aaaffc0af44021fbee06c99ac3fec6b62cbf6f",
                "size": 557748245
            },
            "errors": "",
            "filename": "2023-10-30T05-01-58Z",
            "size": 557748245,
            "verified_at": 1698664182
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698643055,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "mzezv7oX/e5ol8IGSn/bx+JwgWedj8JIxgD3wb9XDBDmC7pb7gPOXio+KjtGzrzm1IDburaWMmllh4LeFLpuKCwQ52An3zMZn/YTACnc+q8doCxiNKl5mqNUnkQvKRC1abSpj1Ws2vkJRljXbnDXosjfu1GjKxBs5gAs1j4oJMHCvh7zi0yX4tHQKWwdUmCpyYUksNhnJj9HPM4ea0bIrrd279Wl9njmm4ZRyt+bQVAXuRi9JsM110lSTYs1M1GbgdHd/QdttV9KpESH4wRLKdSva5PVtgo7W+UiX52Rx/yZoDExRTJVzuMFwWgsUnoLeQq+lQcS1cqKYPK6zgPqiGW2YcAmCFFGmGt63rEVeUcqlg6lxJk7U8T15J7d6MPUmcH7GfgdPwMd9Kjl2YNNFVcqy2Xc0CXbzJMBoEjmomfjxMpuz6GOgJ5qWKQoOiVt7PlXFnSgixgGXAEQmhvWnpwY1b9bOMmJ1pQd5Ma+vvcwQeF/YMSkD2K8XUbzkGqDUEJc7KFumvibImW4jqgyeCrpdg1cR7wuqMgTtnQQ2bA8Co9IcwX6HkTDCLbJ44DiWb0embc7Y/T1oyBdC25CLXc7zbyyBqe/AjuZVkyrgo6DeBeTDemUHjjfI882NWXHUpTKsFZ58/qMEQNbxME+vVGHPoSkx3pg68Ki8ZcULUo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T05-17-01Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698643011.466036,
                        "modified": 1698643011.466036,
                        "name": "/dev/shm/incoming/2023-10-30T05:08:56.ts",
                        "sha256": "082ba3e25ce67af28b44f424bdcced28ec49e5f7e49bd02608183200d691cad1",
                        "size": 113001912
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "b3bbf7125753cc2efcbb4f92329644d1d17613922686009904da71f68d852ea3",
                "size": 109219952
            },
            "errors": "",
            "filename": "2023-10-30T05-17-01Z",
            "size": 109219952,
            "verified_at": 1698664462
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698752448,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ITEENdH7rXJVNfiq7/N6XvlKeMHRE4N7ADRx2F9ZTlDMAGFBmMt+4dNHIC2BOmhPa8AAnXdpl2761NELmRol46O3BUqH9nU5bQXphjWt+57XHFEC2MpJA+F1MkztG10AA992NnmKa/LwALirbfmt71MJk5A7a02W+3Czqt9eKvkuNBbe6OYkmK0WodtqLsZ8O7dGoMW8q0nqzN5czC47W5XuObVCyGX+7570wR0Ie0/NoiLUUKu/kfGffE4Ivvm9ve6dTEw7eyoehsjUDAVw9zgnd70d4X9TiFVT/89Bs+7JT3P2i+s7aTiewia/aGsRh89rmyqpaLNcF5m5FBVVreQFVEK3moNsdouY0HgNc7lex69Nhd3mcm0k7p5PqrapU6R7me4XfLnj/scNAoiMM2P3HBagEpyhjy69EXgZdI+zXHHPjckuYjUIiHsyepuGXt0cv2dR2VlLJliHtBYjqHUm3RhIERDNFzmzWS2BQicYoc/Y7sXeDkrsrIW4N3pEpxGsuJZyF8O7l+DyIdyuqXgBImh6A8LEAhdNFZ2Vp2qYkSg3u84QT4+0+wT7ab4t1hh6PC29iJKl2HZo5vOiUplVHy3zdVpLSxXQJsiFXa5bBpMBFLuSXg+DfrBS7reJ+SlERPBhm54ugTU/Yv1S5NTwUy5Zn6lJ4NOcH/qwvwM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-39-44Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698752359.1114185,
                        "modified": 1698752359.1114185,
                        "name": "/dev/shm/incoming/2023-10-31T11:29:18.ais",
                        "sha256": "c9b95191baac85d48cca436d98cf7af056caf90788e698002a662a6f511f8d2b",
                        "size": 309299
                    },
                    {
                        "created": 1698752377.1614184,
                        "modified": 1698752377.1614184,
                        "name": "/dev/shm/incoming/2023-10-31T11:09:36.ts",
                        "sha256": "ab509f856782b172d1ea93ad6747f8c669c9affe3405cf6033de55e8c06dcd85",
                        "size": 410735820
                    }
                ],
                "mission": "OSP1",
                "sha256": "65fcedd452845e762c19e4141fdb84da79a23e3baa84d04452da8b07db2baa05",
                "size": 396311588
            },
            "errors": "",
            "filename": "2023-10-31T11-39-44Z",
            "size": 396311588,
            "verified_at": 1698766331
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698745625,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "tosV842S8NJtyawDL4rXsBCSrYuaJbSPVA5uRt+7/aZaVxHAJsUgQYHpiCqXw77GGN4ibCPKIlZCCnVk1i0znF+bPtIjd7j793g+DDiYaRwENfoCk4TTfCA+sIc2+3a9J8x/dfWFXUtOE+as5H4DQGFVDf2uajH/sr4Atwuxzfugfc2/jtTT7ZRXsQuT+nyMK/7VyfRrJbjvLhgj/wSnIpbCjQqSV8mvbanhlOvhoYNVfIKfYaVXxuibNvnufZD2etYfjx2AQEOuGUQWio+xvhCgS327Z7e62ivIkTfCIoAnlAjzbiKErwwwb4QczZ8zlbbnxdY0ddSzMQkf2UlOm0VlC7vvVeLJkVhvzY8jvLBK5NQoFJLT8rzgxTo2tlKHsewiMCuL9+ZivXoiS0BsiWjX7nmQEbhrGZSziiQVkOU/2Yp6C04EHDMkSI8DNiaN/OibZPP+k3i3/NF7MvVzHjf705NrTvjeQGXhjVNi3rCqGoKFQ27KIORsfAhMVhKpSnHyK5as8kWmZwaSj25vt7H951OHRc6/zK5bha9n7yGH4qy7z35FUHSfmlTNJqlPRCA3Z/bscYmrowlqKUFBLGZhIkR2IZkh5TN1VhSMJTrEZkmNiDnFw1vhSWOj2rrvOTYrJtK4L2o8j7IFMtYz1czcvq0PQJL7mzilS/A/ovg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-46-47Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698745483.8614185,
                        "modified": 1698745483.8614185,
                        "name": "/dev/shm/incoming/2023_10_31_094427_00_01_V_043.txt",
                        "sha256": "5091d77cbdcd6b7812c976fd3cd8c353c83f4b54fcbf9966db24d21c79de393c",
                        "size": 2034
                    },
                    {
                        "created": 1698745483.5214186,
                        "modified": 1698745483.5214186,
                        "name": "/dev/shm/incoming/2023_10_31_094427_00_01_V_043.kml",
                        "sha256": "211faa64036746dfd6d1fbb4bb7d170afc990abb76034310709dda1993344e49",
                        "size": 953
                    },
                    {
                        "created": 1698745483.2114184,
                        "modified": 1698745483.2114184,
                        "name": "/dev/shm/incoming/2023_10_31_094427_00_01_V_043.jpg",
                        "sha256": "45c9890967782372b70931409ad929de25c2ed1525e50d305e37bb9bf7d838eb",
                        "size": 1452044
                    },
                    {
                        "created": 1698745451.0814185,
                        "modified": 1698745451.0814185,
                        "name": "/dev/shm/incoming/2023_10_31_094355_00_01_V_042.txt",
                        "sha256": "70f28176bc899bcba0cbfdddb7986afc9e0df096a1e7e717ac739984204ab522",
                        "size": 2037
                    },
                    {
                        "created": 1698745450.6914184,
                        "modified": 1698745450.6914184,
                        "name": "/dev/shm/incoming/2023_10_31_094355_00_01_V_042.kml",
                        "sha256": "fe0b255e3bf57a4fd84a09ef0498852af1d479e04e3758ea011c288604c71138",
                        "size": 951
                    },
                    {
                        "created": 1698745450.4914186,
                        "modified": 1698745450.4914186,
                        "name": "/dev/shm/incoming/2023_10_31_094355_00_01_V_042.jpg",
                        "sha256": "9a6d0039559e981ab9f14c78912c76b7b14263af6278664cca4f93f4413710c5",
                        "size": 1449150
                    },
                    {
                        "created": 1698745449.6514184,
                        "modified": 1698745449.6514184,
                        "name": "/dev/shm/incoming/2023_10_31_094349_00_01_V_041.txt",
                        "sha256": "5df72e83336be27bc151f69684be7282e6ec668cfcf686284f1c1ac464897e5e",
                        "size": 2034
                    },
                    {
                        "created": 1698745449.4314184,
                        "modified": 1698745449.4314184,
                        "name": "/dev/shm/incoming/2023_10_31_094349_00_01_V_041.kml",
                        "sha256": "25146652aacfe0ab3571ce3926ac59f4e28cc47a3bdb8f90999a6f28c68e170f",
                        "size": 950
                    },
                    {
                        "created": 1698745448.9714186,
                        "modified": 1698745448.9714186,
                        "name": "/dev/shm/incoming/2023_10_31_094349_00_01_V_041.jpg",
                        "sha256": "a2664f1d5a730b207de613345ad986abab4f0f7565c049f0cfbc7bef1c36fa9d",
                        "size": 1470217
                    },
                    {
                        "created": 1698745437.7614186,
                        "modified": 1698745437.7614186,
                        "name": "/dev/shm/incoming/2023_10_31_094337_00_01_V_040.txt",
                        "sha256": "c79092a7d8585bf35745e5a9d716eb1fd7d1008e39eaa1a195757d96385c424b",
                        "size": 2034
                    },
                    {
                        "created": 1698745437.3114185,
                        "modified": 1698745437.3114185,
                        "name": "/dev/shm/incoming/2023_10_31_094337_00_01_V_040.kml",
                        "sha256": "ab74a9231f23a8f44b08591ed7d207c84b9728da6cd9dd06c6a202b19ea4c1be",
                        "size": 952
                    },
                    {
                        "created": 1698745436.7514186,
                        "modified": 1698745436.7514186,
                        "name": "/dev/shm/incoming/2023_10_31_094337_00_01_V_040.jpg",
                        "sha256": "4b84eedc85fd935b808c12167b9cd26ae61f37e4a7310d0339108a54b951f9dd",
                        "size": 1499610
                    },
                    {
                        "created": 1698745414.8214185,
                        "modified": 1698745414.8214185,
                        "name": "/dev/shm/incoming/2023_10_31_094313_00_01_V_039.txt",
                        "sha256": "fe525014926fede710ba24e28e618832b42d3ccbf06a0ec10670d0780121a4e0",
                        "size": 2036
                    },
                    {
                        "created": 1698745414.3714185,
                        "modified": 1698745414.3714185,
                        "name": "/dev/shm/incoming/2023_10_31_094313_00_01_V_039.kml",
                        "sha256": "6b3928f3bf308157d5de7c8a66059f5bf99647a13ff846ec113a410ef9841fd5",
                        "size": 952
                    },
                    {
                        "created": 1698745413.9214184,
                        "modified": 1698745413.9214184,
                        "name": "/dev/shm/incoming/2023_10_31_094313_00_01_V_039.jpg",
                        "sha256": "f21a70ff9ce28b374163921a30d761816ad74e9935c16aacc035823365a0dea7",
                        "size": 1541876
                    },
                    {
                        "created": 1698745413.3314185,
                        "modified": 1698745413.3314185,
                        "name": "/dev/shm/incoming/2023_10_31_094226_00_01_V_038.txt",
                        "sha256": "77864143c8971e496047e783eb9e87a71817320bfc12cf354247e4b6f9ad6f98",
                        "size": 2040
                    },
                    {
                        "created": 1698745360.8814185,
                        "modified": 1698745360.8814185,
                        "name": "/dev/shm/incoming/2023_10_31_094226_00_01_V_038.kml",
                        "sha256": "aecdda9f4c2ce340507dc6ace2269c6f47faeae1551e5a2fb362adc2f4c67694",
                        "size": 947
                    },
                    {
                        "created": 1698745360.6714184,
                        "modified": 1698745360.6714184,
                        "name": "/dev/shm/incoming/2023_10_31_094226_00_01_V_038.jpg",
                        "sha256": "0ca26222717e3cea1a8d4aca6b7d593d0e9877f801bcd6f879cb8f94aa62b1fc",
                        "size": 1428343
                    }
                ],
                "mission": "OSP1",
                "sha256": "15b945784af1ca6b559ddbd40340800dd71044ccfc48043e835207c3b9e4105c",
                "size": 8851973
            },
            "errors": "",
            "filename": "2023-10-31T09-46-47Z",
            "size": 8851973,
            "verified_at": 1698766372
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698643851,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Z952M8iTzdDYn8WCTTde5Iy2PyUVAweHZ6wOpzdUd4G93vChGKBusZMBMcbm8wfl7qK25uB3tBxtcRuLLTkYaCrQo1cme7hMCRcLce5DNnz1xE3fTXl7RKYqi6wVTB5O4R+Prl9O82aSC+QBTBj8OT0NhqgWdUEOOLbcZVB1tbNbRqp3AV2H+CGv6LT6SoxFzvGZCt0TrA5OhTqcegn188dK5jst7aorXzKctJSJ48bHF5RSKX/x2DVjtv5g3p8kQ4keFAVSoFECsKd6KFwostTqum9liv84UyWjmQaB7QiXobuQhhg9+gjrnCW2ajIxr8GQ4ekoR5cw7uwFK/RGcYvirZ+D5xnQlj1OLkENkAwUYQoQZewQ+gIZZzJ+XrEYJ7V5ZSv0jmXFjaDYZqHcYb1rkzA47zfv5yV5/UjVtsm393QK4inhB06V0EiV6APTZqCbfu2mA/QH6fF61yTac2JuDzzGTvUy/tWKaGjWHd4LZZnZJeCUB3xoxM3BQrDUH7fsrr6vs7sDthJPfbVWF6N3gTppmqAkXIhZWkVQyCH6gMymVgOjpzGPcbOG6BTW3lNDCQhlVPsFdwAaYhDnyMWooB2hnwShasasHT5zwlfy8OxDyjlGtUOkRpccrQ6uOGiG1pcNlYnnuHW4vdVpIuktvhv+GY+ZbKiLg2J1OWE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T05-30-26Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698643702.886036,
                        "modified": 1698643702.886036,
                        "name": "/dev/shm/incoming/2023_10_30_052800_00_00_V_003.txt",
                        "sha256": "9014a4f51e37a13a800c7006d1f68ca9b6295bf2d5c21d81df880fe7df5a409e",
                        "size": 2036
                    },
                    {
                        "created": 1698643707.976036,
                        "modified": 1698643707.976036,
                        "name": "/dev/shm/incoming/2023_10_30_052800_00_00_V_003.kml",
                        "sha256": "2fe5b9578c239e18502cdbaf1ed9a80c6396d88f2080942f553e1b97463b371d",
                        "size": 976
                    },
                    {
                        "created": 1698643714.956036,
                        "modified": 1698643714.956036,
                        "name": "/dev/shm/incoming/2023_10_30_052800_00_00_V_003.jpg",
                        "sha256": "2c1f0f7c3f4ee0cfdc155e25a916fbbf10b36fbf4a1a130d9061386b5653d7fe",
                        "size": 1128861
                    },
                    {
                        "created": 1698643635.736036,
                        "modified": 1698643635.736036,
                        "name": "/dev/shm/incoming/2023-10-30T05:17:14.ais",
                        "sha256": "10afb80595769bf6bce61758a077d568d2075dea20dc73fbe07a2f42c6314d35",
                        "size": 553283
                    },
                    {
                        "created": 1698643596.206036,
                        "modified": 1698643596.206036,
                        "name": "/dev/shm/incoming/2023_10_30_052616_00_00_V_002.txt",
                        "sha256": "c25bb9111be23ab98ff6623e8023894504b45bb6b87f49252dbddd6424dea70f",
                        "size": 2032
                    },
                    {
                        "created": 1698643600.186036,
                        "modified": 1698643600.186036,
                        "name": "/dev/shm/incoming/2023_10_30_052616_00_00_V_002.kml",
                        "sha256": "6aa489745d1030dde329cd84878be8fdd23cd5caf30dd3b95adba61cbd54ae9d",
                        "size": 976
                    },
                    {
                        "created": 1698643590.406036,
                        "modified": 1698643590.406036,
                        "name": "/dev/shm/incoming/2023_10_30_052616_00_00_V_002.jpg",
                        "sha256": "e30b1b14a1ef8b2577a3437eba94dd65dfce7caa0e114b06e1b35a77cf71e752",
                        "size": 888320
                    },
                    {
                        "created": 1698643588.736036,
                        "modified": 1698643588.736036,
                        "name": "/dev/shm/incoming/2023_10_30_052604_00_00_V_001.txt",
                        "sha256": "f041751544df862d76e0912eefd2dbe6471e8d0ab6f309aff4fafb88de32b479",
                        "size": 2030
                    },
                    {
                        "created": 1698643581.196036,
                        "modified": 1698643581.196036,
                        "name": "/dev/shm/incoming/2023_10_30_052604_00_00_V_001.kml",
                        "sha256": "0c1e748e702586ba095ec9c4f973f6be825d835cfad2b1a556a2e570bab44b73",
                        "size": 978
                    },
                    {
                        "created": 1698643581.026036,
                        "modified": 1698643581.026036,
                        "name": "/dev/shm/incoming/2023_10_30_052604_00_00_V_001.jpg",
                        "sha256": "260062b92bb817c1e46626a6be3dd4da1c75ed3b70b4d072bf6f9a9d541f29fe",
                        "size": 842010
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "8e9c2ae689c9de79eb69487d8fc1af530743b939b69f43f335a7ff5bc791ca6b",
                "size": 3016971
            },
            "errors": "",
            "filename": "2023-10-30T05-30-26Z",
            "size": 3016971,
            "verified_at": 1698664651
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698644187,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Cc4iaKaAaKcvIsjVHjKhsUofb84Kr8/UMG5iCjA4HnSMIxc0FZS67YFb1OC1y0Usto0pRLdv2SkDwFsC3ruufr611lD8yCUAS/1l+BUvt0ippi3yIkVQH583+QntK3jnUTR/ikaTwHIX7dFj/qeMfmRDjL+NagSmnUHEVj+/VpmrnytoIWA9NO/KKvlMebdHUVPPDg9oLu8yOTsz7qAHTfIKAY79ENenrsW+NconzNYPHnAvbzlctsxSqln0LpTPVyPIlNDhQMIv+dhLlkfHmjGTIz06GVxHHS8DHQnXITn/f7Cq9P/C94yZcE3RP+hyky/RVit/svil4Qy2HV67KQQ1+fUkWivEt+Yh8q8CG3Gc7pE0Br4qp4GuFaPiQv0dwNQUCxVjfFJUfqWqf9fx+PjRSEDUck+awzyZdihTnZ16ExtpRTUuR5iF9TZpMMrAvjSv5ExI9GEIFBQ1AYxCe5j4MLKvT1N8+8HaMO4tQ7devRKFKv/kCBjR7pKrnSY3gjWfrkG9ILl7+rEZvSZO2jgT2xUB3uAs7ZB8RiHXoxVHLim4G40XdM0GhBmuL9ygQ9wFpmHB+ZORvLAdn9XjBPC7/8pc8sd5ysKLzrePQ9Vmyg5c/JqcWKsCsrEq34u0Ok7r6Lid09GMzKR/5nEOyPu36ZgYrq5i5l7emOHW34U=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T05-35-42Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698644133.096036,
                        "modified": 1698644133.096036,
                        "name": "/dev/shm/incoming/2023-10-30T05:21:11.ts",
                        "sha256": "926c2168c236395c6f39270ba1838113374d155527238d9e504f2a382295f531",
                        "size": 190164444
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "ee583d6f1cf4dd79f7f18b61567af034af2972e0d8b250160fc5033de347827f",
                "size": 183477987
            },
            "errors": "",
            "filename": "2023-10-30T05-35-42Z",
            "size": 183477987,
            "verified_at": 1698664834
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698644388,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "lOE2dAR7UMHIs1meA3wQxQWUswTtwFAMLVN1MW8zR6NOlFNahCRl7YPOSC3ABtVAmX3foSrvC5+J8u16W/A+lJ5uV2lcJxNxWynS6efN5LkQLsEDDnlwNfRb67HBsJ1KdbSUgkAWuxUPKsvn8kKkVC6y6XVe0djFdqUV9b5M4s2ewoBs4aLfhEmoKTZErM8WWIF3KUn0B5NBmGF1zOmtCfzvs9WjVQZjwsMtAotawpFd5EJgo4EBru0q5JTcLZgxlXSf4aG2owFhhtA7x+XMSZ/uhki9to3untn4fvEK7VqgBk0Etn/gNOS4L2i0pE2Bp5tE+p4c9ERaaUMYDhy2JQgiZzBw5Ghsg4F7v1G58x+ji8XrOoEnyCNFCgvxLGz0f9No6hZtFMwfivA7tnKQ3LPch+isv+e9WmvwO3B0GCR4WF9g6BnYcr7Tz4tZgXwSEUnLfGzfYfizKxC3TlU/E0+58+YVef8qfKyDPksMd+U5aFZLCmT9FEI0YSaanNFI36aNVYVTB43FyChBAJFpQgX2ooq3DYTJhum/xAbkaPZiBDIvNK/ZujN3QQrXvXGGMfXTM73cWWy+3xO/W2ELCaHn9SCONpaRnPGSIFFhGZ6Cia+lyJElDjaWx/xLlWDcFQq+FmGeaDeVlJkOMHKJTiONQpHsAOn33Exi0VeB8yo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T05-39-21Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698644236.706036,
                        "modified": 1698644236.706036,
                        "name": "/dev/shm/incoming/2023-10-30T05:27:15.ais",
                        "sha256": "e1b36fe6b74fd8ffbe230fa4d2cb92c3a6d951a4bc5f9e9310e0a06f271e0510",
                        "size": 474450
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "a45c10d3174ec4c9bf528512e8e61ba2186ea8e9443bc966e4d6e1774f36678a",
                "size": 136690
            },
            "errors": "",
            "filename": "2023-10-30T05-39-21Z",
            "size": 136690,
            "verified_at": 1698664838
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698644989,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Y8cZFw1kerx9Gp3Jaya8kWAKg592lCf2+TWAz+pfdbS0xkcB/IPBqnQEJmQxneDIYtmBP7qUXB4wP+awBYWhhAf9FkNG49EW9PFtr5GokdYk7VjIZDEfyXm8/wU5CnwF5UPbyTIolHLUb+9OXqLCCy7nISt2BS7zTW+FWpg1qdk74oPuM5DWw8oZ9sotScy2Y2PZeKyuk3GWoh1k0HQ6AFGA+POsh1DerNv7d1hNrTl+sNZCcSEWWX/13Lb454hTSQY3PKH2InJVYjdx9dFMr4DGPDRMUolN+vHM0EWUNtm2lo0HJvDJIGJwhNVr9U7eA7buuKMH52y7qF/gdYj7GXih087JEzGEj/1v1TfvESOQ1jQHEnFCeXRkPoVyILYN7p0vdXwy7nX92j2fjjVMpVz0zGG9H4VTisV/GOaixKd9a+6nuYtLPp6hz3qyJJP4IuK7IHzo/uy4j6cKoqaJaE0i9wFkiJQTMWgJcd5/BSp/tv24nif9RPb9CFutLrhAUwjCqXKJKSkZTJdwD3HAHyH0M6pHyIgRGUSOyQjYQ4siRV54x3ExC66kH/vxSJPbyAtXKLH4fGUecs3We4dlEjAxamY1764hhMW4HfKszxUyAXwg0/9sL89tMBMjvM/whsBDHp2DtofmDx0ggZB/ErCr5Hdp8fkGqzyuMzXf3rI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T05-49-23Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698644837.046036,
                        "modified": 1698644837.046036,
                        "name": "/dev/shm/incoming/2023-10-30T05:37:16.ais",
                        "sha256": "5079ab5ec4ccf99cabf597cd62d05238e444b75b8c41c8796972320a0833c8d9",
                        "size": 425355
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "30af51e2e3adf85939a6be723ee64de605b33fa726920fe165ed3cc9186296c6",
                "size": 123138
            },
            "errors": "",
            "filename": "2023-10-30T05-49-23Z",
            "size": 123138,
            "verified_at": 1698665160
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698750470,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "C3WsnUDXEgy+SVol7s1amOlGo+eX1XzAwJXJWswicW/AKbcd6gNa14pJF/XIiiTOHOOl3rNxpCVzS5oTqWxXK+kW1T8M8fA4CSRMjxqwZan5C9/HFCo5IM0frx+yIDKD1RNS+EtpI1CH1LFu1GMQN3SH832YJ1TSxZ0lu/TLrZOaat9/hzUR/oQ8UsAfiHgs2dINHhABEb+ds3jPbE0KBgPNexNP/8pzpZJ6BM3cLry78SqcMRnXYPy+7LttJo9bWyCK2pHk7Dk5OyYejVwmY3xIEWPrGcgAWDMAsiXKz+Kb0pxKVpduvLiIDiarIA9BWGUQtXtvuDdMhGsJ8mWjV+NXqxA0hXsc8Hy81ThkI3QGpKR/HR3h6leXkX2DOEHeYZ2NciUQ9F5+8zYe2z03JuquU+GRUEAZJS0rzmuvwBW3f6XwFSUa/LyK221QFYzqEOyPtWaObufD8k3gwsaOGygFXbbYzeEf8MaGmg1u28jBnFEb9AjsI2+kDgCmfIgPrI7rmV+tO0dY7Vo25TaFVN4a0afysNdiTb0YBdKtKag/gnZwvRH7rBKI896xZilHKEO6CTCKiZzM8yFDPiqI46S/msrI2agU9SdqMvQOLciVAqh5QwhUjmGnWU0TiLld9thiFegdXnQTOmVkeUkzZQuxSvuwhBghxRgqraWWLYc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-07-36Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698750331.7714186,
                        "modified": 1698750331.7714186,
                        "name": "/dev/shm/incoming/2023_10_31_110512_00_01_V_060.txt",
                        "sha256": "1016af5e4c22e0de0b98e330c3ad52defb3a4129368ebeab4ac67cc69591da42",
                        "size": 2035
                    },
                    {
                        "created": 1698750331.6514184,
                        "modified": 1698750331.6514184,
                        "name": "/dev/shm/incoming/2023_10_31_110512_00_01_V_060.kml",
                        "sha256": "d7f89a8912ec9a9d8a1308832d657d7a9c4d7ab63f51113a3f07abde257af7e7",
                        "size": 952
                    },
                    {
                        "created": 1698750331.2314186,
                        "modified": 1698750331.2314186,
                        "name": "/dev/shm/incoming/2023_10_31_110512_00_01_V_060.jpg",
                        "sha256": "7fe158f9c6087d7dfe28860af402e8205801ee90f4f42ebc90a887ff6639be5c",
                        "size": 747771
                    },
                    {
                        "created": 1698750330.4314184,
                        "modified": 1698750330.4314184,
                        "name": "/dev/shm/incoming/2023_10_31_110511_00_01_V_059.txt",
                        "sha256": "e0cbae952767ca9b42b142857dad858649533583e799141f507865c078db9640",
                        "size": 2041
                    },
                    {
                        "created": 1698750330.1514184,
                        "modified": 1698750330.1514184,
                        "name": "/dev/shm/incoming/2023_10_31_110511_00_01_V_059.kml",
                        "sha256": "929f460c3be9f745cf966d277e96abd5d3d977fe624969331cd3f2c6ca984fa2",
                        "size": 953
                    },
                    {
                        "created": 1698750329.6514184,
                        "modified": 1698750329.6514184,
                        "name": "/dev/shm/incoming/2023_10_31_110511_00_01_V_059.jpg",
                        "sha256": "69b93627ce98dd64ecb55f3bf8f26dcab2fe307e3fffdd29f42feabc64f67586",
                        "size": 749298
                    }
                ],
                "mission": "OSP1",
                "sha256": "b04e9d894a7cf0bb39b6a93165377ca9ad1819a44dc9908a80b36ebc39cdbc7a",
                "size": 1482159
            },
            "errors": "",
            "filename": "2023-10-31T11-07-36Z",
            "size": 1482159,
            "verified_at": 1698766514
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698646550,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "NYfbk0TgIrfOb52Cva74GJo6BG+CXl6WYy4eublUvoVYfSYBDTDGMZXlJvmayrMrt54jIu/xH+C/1dOKYKEWudUC7NewTHbC4lRRBCs8/3imGJD238bKJRbQGvga9ICbuoQS/nRTqdpE6KRW8TaWERk+GQxz/drriYEbV2Czd9zDuUy8Cx+M1Ok/QhvYMuY6IZ2N2qdvSkuqYBGg1Pz5BIbCTUzza5St63G6t/lQWCDeTqtCRpCuD81H7E6Cez7Elr2IPdvFtRFAQQkEZdeyzgLbDzgxCe71+YnK/RgWWLzxU4zrocwLFTTnw33M7i0+7vHecJTxcP7rqKzQ0e9UQXc2iklnuH67U8rldom5t6FMvN8FNA+1zIGcZ3LKSPT+q3xC8s2cj7CLPz284E/gGS3MM2uTYnz1Kj/6mxWTiQwz9q+lvKWIa9BM2Wch438uk0A9CVnP2qz7kuGCtFiB7+yMa4n1gXeOvCzRZOIXyWHNVNV4NUHdDHQh6stRvGs1IOdiR+DB1+cHsY6V9EXVQDV1vkxQ1lIsHu7RQMh/xGr5DkJL6pY0vuelxjtOSPALhLMfmb9xEvl77qHTVTCajsFLQpu1Z/AaJOpT05QKZAY6eZLF3OAJoIRE2yc7eEAuQ7Z9GP7gEXrSFl7sv3/BJIMX73M37dAWzGAwluVwtdI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-15-25Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698646408.056036,
                        "modified": 1698646408.056036,
                        "name": "/dev/shm/incoming/2023_10_30_061258_00_00_V_007.txt",
                        "sha256": "4ab91b4c0a2ad2fa3ccd9166a000131be1a044bf99b1199de4c59afe44a6d3b0",
                        "size": 2037
                    },
                    {
                        "created": 1698646406.566036,
                        "modified": 1698646406.566036,
                        "name": "/dev/shm/incoming/2023_10_30_061258_00_00_V_007.kml",
                        "sha256": "82e206684e271f5b694f48f73f446ef8ffa27031c69343d500e8ca3ca2b35a9d",
                        "size": 979
                    },
                    {
                        "created": 1698646406.696036,
                        "modified": 1698646406.696036,
                        "name": "/dev/shm/incoming/2023_10_30_061258_00_00_V_007.jpg",
                        "sha256": "04a09e419ef1a80af8c69f16bc3e1c1dbb8b4c9293ed22defdcbc6067b47b683",
                        "size": 1286391
                    },
                    {
                        "created": 1698646388.336036,
                        "modified": 1698646388.336036,
                        "name": "/dev/shm/incoming/2023_10_30_061246_00_00_V_006.txt",
                        "sha256": "6a30e61c1e24daac0fcbca56278d5ce008d01eb16927a34cd48ce1fad713a78d",
                        "size": 2035
                    },
                    {
                        "created": 1698646395.046036,
                        "modified": 1698646395.046036,
                        "name": "/dev/shm/incoming/2023_10_30_061246_00_00_V_006.kml",
                        "sha256": "addddccbe878767020ff203e142d8868913c682bbccdd06848d630de008f4fef",
                        "size": 978
                    },
                    {
                        "created": 1698646394.966036,
                        "modified": 1698646394.966036,
                        "name": "/dev/shm/incoming/2023_10_30_061246_00_00_V_006.jpg",
                        "sha256": "0018d693013b4036a2c84cfc646d1c2730b4d0cc9199bcd095abc7a73b6e2343",
                        "size": 1148421
                    },
                    {
                        "created": 1698646382.386036,
                        "modified": 1698646382.386036,
                        "name": "/dev/shm/incoming/2023_10_30_061236_00_00_V_005.txt",
                        "sha256": "3e4f23a3e2520cc6b8c6ac8e4f57c74d599ce5ce3c25acb97480c809f5077237",
                        "size": 2031
                    },
                    {
                        "created": 1698646382.006036,
                        "modified": 1698646382.006036,
                        "name": "/dev/shm/incoming/2023_10_30_061236_00_00_V_005.kml",
                        "sha256": "a487e8cc47de7723e6086a4942fedfa3158aef6b20ca7f17c17fbd7ab0327eb2",
                        "size": 975
                    },
                    {
                        "created": 1698646381.336036,
                        "modified": 1698646381.336036,
                        "name": "/dev/shm/incoming/2023_10_30_061236_00_00_V_005.jpg",
                        "sha256": "23ebedafa6cab8f39ea6462e673160c0e6311122fabb5f24705d8446143c5a1b",
                        "size": 1091656
                    },
                    {
                        "created": 1698646380.296036,
                        "modified": 1698646380.296036,
                        "name": "/dev/shm/incoming/2023_10_30_061225_00_00_V_004.txt",
                        "sha256": "82861a32f1d659a575bdbfc67e2cc48153543627971f9a7c3ab1f4d44ca20887",
                        "size": 2037
                    },
                    {
                        "created": 1698646376.146036,
                        "modified": 1698646376.146036,
                        "name": "/dev/shm/incoming/2023_10_30_061225_00_00_V_004.kml",
                        "sha256": "da96035a27ca14d6cb9b2018e5681d8a13f8770ee4df689c1ca9f4ed34dd3c96",
                        "size": 980
                    },
                    {
                        "created": 1698646357.816036,
                        "modified": 1698646357.816036,
                        "name": "/dev/shm/incoming/2023_10_30_061225_00_00_V_004.jpg",
                        "sha256": "3bfc76bb032a912101ff338ce6a710deb21cc02a1505394c5f2d5608af7e54f3",
                        "size": 1283750
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "45b1d0812705fb003bed3878f73d861b514f2678aaf232684c79eabb05fe1f5f",
                "size": 4816883
            },
            "errors": "",
            "filename": "2023-10-30T06-15-25Z",
            "size": 4816883,
            "verified_at": 1698665548
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698749492,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "atc2bxErSeZc6keNSd7bkjT10+QpzADxUiy+RVqWD/YOqvA66STrIU72R5BwJULUzoAplaQHrXlkly2onqYRIqZ+pqNKqncuMQooJjbKRYpPyvZt06hN9MiD5Z5fG+EomxwkWochK96Gn1uDLtl0MVaVh1oihKgsTNB8YgrpXIroEuNgpZwLMotjRtenVBqxkf5RW7YxVXWRnaWGakAb+6NAKOeqDNetn8xOix0P9cAMvuD4S0HMTgWENLg4DBW6qaghVGCEQWWOba2AJ6SXGsw8Rbb8VK3GUtcMFswba0HWqykRvK3pfbvQvwHa44DTztSWvqm0l/EGx0dUJqB8QpHqH/WuUmYnPfaNsRG4GS15mNJl2JuEnxHkyVGl48BPkKXAwWttcRzlS1L34wN+B26GyJ2VAH4o9r63/jo+uqt/HhoqZjWbQYZ1NPKF6/g7AUV3OCUqTYsUiPXiySGMAod6ysDo8M6LAdQHZoqw3RP4BLWYO57YTn0csVrnC3+eRJpiXc7MFwlGSAzCd2/dyPpMGRQchw6gzJH6lqSqoHmMivIAkfobOzjYGX5u/q8YnBKe+d/ZtU0Blzxk/RRBV5W3qQEf4im4sTE6VDB5g0HeVMRmqS/EMQKQ8TLFn2j3StgKrAaFoT3Ft2OfVfwZb22kIKWqwv/er54I0XcsEeE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-51-22Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698749354.2514186,
                        "modified": 1698749354.2514186,
                        "name": "/dev/shm/incoming/2023-10-31T10:39:13.ais",
                        "sha256": "abce0386995da05601d251aeefa8a838b980d8991412866141bc115489d5a031",
                        "size": 278692
                    }
                ],
                "mission": "OSP1",
                "sha256": "f95483fafdf7a653156c3f1c0bb1b3def006bcabd9cdf6c063375a406475bd75",
                "size": 65834
            },
            "errors": "",
            "filename": "2023-10-31T10-51-22Z",
            "size": 65834,
            "verified_at": 1698766566
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698645945,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "dCq2campuj1P6ATZF1VsJxMj6VLUikcz1xSdzFmFTMq50xEee5aYyIPdcffcpOcCkvM/Cg2PJTeF+TEiw7mGkV+4wZCcLdCnYZcLtyc4TQKtmq3h0HmamVxolRbPW6tETuwoqh7ZTMYHXkfSm+AzXlhffPeTEMAqcbS0TYUeuFS6H2ya9u7qwvCCjPllv1n4cIvoKND7osV1VGV9LpCSEZwdeA6zKLgg8h43hKFY2LmDzlIFFo3irWvgxMOR2Yk6pS/X9KjHHfP9WEY8STrXFS6//hxBL6N6Gwfgnle2rzQG+vk1lBrodHcCNwnm2Zy83WD9SU+88mkmN3rQMf8T1x3efi+B/cQyjX8UqC2/jWJWp8n9BBFK+0dXGpLuOpmzYZBASe4Q89rIYG7pSNXLSuSfIjJxKV+dFru7TeeJybYVOm04T/nzupfNzmDTBOKEOCg03zc4mtBv+904H2SheMf4OfCbZVUQFS8t828Fe/DAN8BkvGB77c1b0t1UsJqAxFQ8OstoPaeYkHQkGpA9e3LNDcZzfcbYbqFC2Hb4PSy8jI03mdvZy8yAdZou1s4Tcr4WkS2MtdDMqu5o/7nTYFyXZscGtba0L0yKp71TnzL9lJ0MqCvwZ1wrV62WER8pJ9pPyVu4MppdhQ17xI0Sp4Y/CS4w6sFanA8Xcr7dFSg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-04-48Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698645881.266036,
                        "modified": 1698645881.266036,
                        "name": "/dev/shm/incoming/2023-10-30T05:40:36.ts",
                        "sha256": "53d8b6257defd801b68d308504b858ed9a742b4d36a9794b20789120138cd98e",
                        "size": 325682928
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "b36e85c7a3c42b6fbb6bff9ab800d9b3dc48deca484dfcc99184e37b79f96670",
                "size": 314379809
            },
            "errors": "",
            "filename": "2023-10-30T06-04-48Z",
            "size": 314379809,
            "verified_at": 1698665393
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698646690,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "O2VK9uwG8PplQE9kLVt/a763d4bpaQTZM9/J+LCvIgRoCTuq1eXOdlzfj2XqH6SMXH8c/XDukrnPJ0l1JStnl6LaMsGgOQd/9rUhzxNCjzubaHJXd9Ho0fnAAfqPEy6+O9vuyiaW3LZH7J+oi5xtCJlm1ZMyEo7YciTq/Kb5eTTGHaX3BsT1ReRHES7HVfo1d3inYa9zDOj2LCovF5+y2SNFMwwqZV0P0g7pwTB1VLiW0h4cuHIRMyajo6YlZpUgX6Qmvc8oUH0MOEOvpuslUEMGm8dAXkZlGUx43nMiM33onLyIcpZqZi6xk7st2Ctpw+zT/h92WpJKb1AzcBc2XvXrjNA+PDRs3+FXizu8ORHZOVMlBld+Qz4mfilmuMWAeBeE6+IscN7qQp7LlOtFTJ3ztfztgsVcfF5iIFQf0RPZlmaIKwFd9w1X/Nsi7la7bHvBnKzxZRm0xw9lDGewJkZBxEVkQOCjOzj3OQDSK+rBSVH2sEZYL7ZMHO48IPzoHYh7SOvYRNCW9MUnWYYuzPiNPuqg+H9eaktybkT84wKiBvP8a6nBIIpflgTJlo/a2mQBdYdmfdKl1U0NuKlzWWSBkQmQP9FPWcvP9g/hoeHMq0GbAyq0XYCzKq42IMMVPxKtEGppYXk9nQ0aUoy4832JoY3ouwMsBygPbeC1mKY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-17-35Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698646644.636036,
                        "modified": 1698646644.636036,
                        "name": "/dev/shm/incoming/2023-10-30T06:07:23.ais",
                        "sha256": "a49dec15d2cda1a7f965e71ffdc54d107f2a0b3b9f0f705ff0511659c366ecb9",
                        "size": 262297
                    },
                    {
                        "created": 1698646646.986036,
                        "modified": 1698646646.986036,
                        "name": "/dev/shm/incoming/2023-10-30T06:08:49.ts",
                        "sha256": "4e0087cf30920ff16f3f289f4fe6bd15c959e0ad553a6884108870654e6eca99",
                        "size": 116637644
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "c24df2b76ae62b4c87aa6667cf5e6fbe7d06d9ddcec38a2017dade3ff2c0d501",
                "size": 112583435
            },
            "errors": "",
            "filename": "2023-10-30T06-17-35Z",
            "size": 112583435,
            "verified_at": 1698665621
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698646361,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "L09c+Zw0nIqe3m2UINpdQ2vHcw6fGXK+jkznJWKD58hDST4sf2eSXYPgFa2z1/dwUAF3MW0FhRbt+1rbkTJtKlThJyLr9gtY6nmFfj/cOBw3ej5VET9mbdRB2H/XOCpMDrSIMAn1/rohNsUHaD0hCKGO+yWghYU04M6xpmMriLK465NyIud/wy+qCq0Koik+7qcmvmsLriG2xOptdZaJ50rwvD9zq7ICp6zziJ82qcEXL09xdHlfn7qUVfNMqafHU4AHyAZpYSVjL7emRb677bm344s7yY+csw2eTVPGMS5q9hG9H/5Uur4TIwE/fij7mMNT/m9YhYDSU4J7nkdzLdctVtYPykEiksJFivmxMfeCEIi8HBtQK06vZIWr4z0stzBt4VqsKXiF9iNAWVhvIdhjljAj89K6rKeTEMgugkfZ+7nb0f4jSFGenyqbb2gRUjrg5dKEsghdXlSc6PlBzG+N4yMxN4E57mXH5tjsjGCsjP5p5wD/XkBId++n4nWjWs68Bhz6WX6tbM4EFEpWZzX2FH7JiW1/1SdFwGn7Y5Y3pQMJuC8GR7jQmkDm3YheUKzSHNmrlilqtmml/5jmIHvSyVniv6DT+W8qrEFpYZSziHy4NGGKK6i8RAQw/4Ad1ZI6UfYPz1CtgqQ9qQft8laWIOnMnkOy8q5SUv6yJSk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-11-56Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698646190.6878288,
                        "modified": 1698646190.6878288,
                        "name": "/dev/shm/incoming/2023_10_30_060937_00_00_V_001.txt",
                        "sha256": "13dfe5d6acd1f68aa6f9e454f0be214ed08c285c4cea4010338e03f35448b3d6",
                        "size": 780
                    },
                    {
                        "created": 1698646190.6178288,
                        "modified": 1698646190.6178288,
                        "name": "/dev/shm/incoming/2023_10_30_060937_00_00_V_001.kml",
                        "sha256": "07a317e4aee2c278b6032247aad4d248a25aafdb0da45f38d5600bbe63dfb6a6",
                        "size": 923
                    },
                    {
                        "created": 1698646190.0378287,
                        "modified": 1698646190.0378287,
                        "name": "/dev/shm/incoming/2023_10_30_060937_00_00_V_001.jpg",
                        "sha256": "7459371b754f63245ede667ffe952984a7309e13cc68e744eb38a0d57e204876",
                        "size": 1806674
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "9a28ed4d0fa7b31e54cd61f7a1972cf59aacb1724dcb7eb6eda2386cbbc9c1b9",
                "size": 1808120
            },
            "errors": "",
            "filename": "2023-10-30T06-11-56Z",
            "size": 1808120,
            "verified_at": 1698693459
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698780871,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "cC48xjoSrNZo+4aV9RRcAOcCyQdIZIOFgajWxEpUEw6Nw/OfJyLl6ZiShqjRZzZAtngjWzGiVCYp/NGpiDLdLttqV7c22DpE6C2uUs+yQ7URxcSjfPXEYdZpTNv1y9G21rEWilIBrwpkc4S13cRlBbErMkLS+iF7VBR+57T6cfiyEFRMXufNaDhhTDL2Nl1tfDtyytpOd8TbfVRT0LCsEfotcqUw4CUdCh2808tqV2j2UIuIJ5oDxZSp3mYJ7s82au1CjNU6X/cEppOYkBs9/Yncft25wex0EqbZza2aNmwKPwN/p4UQlxqtC+dnQ0LTO2uRSvUF/qaNXkEtW7zRH6ItgzxkXVVJfx5qxeaMsZDJ7ezYZRzCuf6glK8geXTATXJIhshbkPNgvxOiSiOeze7rDBqesHEh9g4qGo65p0IEjX+w/Uzm++dEy5fwWs/pEyTFrtP3PArRqLBI8T2Kk+J2hyFZekrPKzDpydMyYo+brrkewiN5JhmObFu+/GZLvPll4SQtsj/w4UqSotv5H4HX0KxE+HehX2ZOFn08+fE55hE8Y6qG2QfZ6l6dJwBQ/wgZncizcMb1plZCm888L+Wo5aCyjf9DJINEQ8jpoAss6GsO/hzJGxmIiSMhtqWqk4LbiynrSS/FNDHcQ9zolaTyoVIqy/VG6kh+9TgN9g0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T19-34-16Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698780727.473036,
                        "modified": 1698780727.473036,
                        "name": "/dev/shm/incoming/2023-10-31T19:22:06.ais",
                        "sha256": "5f39dd803b7e3035ac6ab6553caf63fd48861c6d93b2287a1c078d04f7ef50d9",
                        "size": 79032
                    }
                ],
                "mission": "OSP2",
                "sha256": "c07cca0a7d6d269f830cdf68a7b814f830229966495bb9a736a069d1ab82a961",
                "size": 19580
            },
            "errors": "",
            "filename": "2023-10-31T19-34-16Z",
            "size": 19580,
            "verified_at": 1698784544
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698736692,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "bD1HeCCYquE4cLjmuRMVocztUx3EOtPW1gs/Q4o68iqtSISDt+Qilb8YGNpfOg5hRTm+fGX6dJNrk3Eni/eID4Ne6zm2MyiJ9Awh1eBGlRPuI1xji8aAC1tQ4Tj6/vui18RGVmqYGq+use5qAtk1A3QtA7LJOflLVFvly/SD91abrjYSlUM3dwvg1qQgAaSZhwfnDDWzSLKzsPTYyMp3+VswROowrP2Ibh4oNNPc/Otolcqj1+2Xj0KFm+uPubP3Cs7i/dEM60IBu7ZxWQV9EhMoUcMyIaPP/krzK1B9bJesVtaewQTN2tCBcH/wkmoXehfYir822X6+q8zbOcQW82iNoQLBeKguCSsrz+tEWwMUsO3DVmtweclCPWVF4cNQILWmqF7Bzky2FdPeLbmvqLrSL01Y2+kgzuzM3dl2KYj0Zaus4OEv1mIRY8u78AWFklHbXqUiK7XJspZ1pWVWWwup980mwcXdcFZAadoPYS2rfpYOdhZvGFBfEJdbes6QBJZmS8OQNTk6KeEBt/arQMvQcFCI6utaAjNeXtvaCHrjvpLFc0IdssQE3avoqj6zhN3LsD57wPUwTbRjE+IRCOYH3YoF+92yLacQ5mdBO+bWcKUtEL/FWqkdCYKwDa0sqKWB011z3lVM0INxYLIlEO1Vi5v2HHih7LiMn4vlpOk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T07-17-22Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698736534.55126,
                        "modified": 1698736534.55126,
                        "name": "/dev/shm/incoming/2023_10_31_071501_00_00_K_001.txt",
                        "sha256": "9fd840d20199fbed846ed315b25c80057d23b2a2e4769619da17f4f95f24b828",
                        "size": 2044
                    },
                    {
                        "created": 1698736517.00126,
                        "modified": 1698736517.00126,
                        "name": "/dev/shm/incoming/2023_10_31_071501_00_00_K_001.kml",
                        "sha256": "71965c65e227ac3f569dd3c2ee7f733105ee258958d5169e5ddae67abae69c29",
                        "size": 976
                    },
                    {
                        "created": 1698736514.27126,
                        "modified": 1698736514.27126,
                        "name": "/dev/shm/incoming/2023_10_31_071501_00_00_K_001.jpg",
                        "sha256": "dbe79bf443c36db7282564ff7a65126e6b4ffa681f730b6f59ed9ca14168f07f",
                        "size": 759206
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "38615a4e283d7142a995b06ee15f9b787c6a3089889b0af87c02ad56c75f845c",
                "size": 750893
            },
            "errors": "",
            "filename": "2023-10-31T07-17-22Z",
            "size": 750893,
            "verified_at": 1698766615
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698647417,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "TgklQU5DroAr6Q9zNzRcNxpti8gq4xqsi0xaRJoQ/YLIcqdQyRw829qO6hnS7vWzqNVqaYqc/PcU5acB5Z5KSGWkef8xVIQ0lYkTg7jR+RAQIzHIFqnw79zlWcLiN86ewA3XicV77nv5h4bmkh35UfJYC2zoxCAfnx1phhcyQwoIN7cpheboUb9AcBysicRipIDoIgaeaBdQPpK2elL4hicePdi1ijbO9jHt4d89uK+9zVr1M7WD4O3gZy3ViLjj/V4vBHiKWMl8LAlCXQQF7cht44Csu0BZSiJ2Pe0of2Ozynur/vFPRkTJUiVi9O1i7GbmURDJeemfh8hmwtlCS3V5cqmWBpAOY4eDyfXQb33qn0GqZPlNraDIHRJf4qtfCi+82utJKKeXKdodPaD9mAgCZ/Bhnr2MiKO1ggkvROAyCtfdRmprmzKlH//ZfjUSo89++Rvvu9bYDW3rzbXFjdTcn17Odys746pqvRV90YlnpB0b22MAnwuIB4SCYEjT9chKRCyrOVIy5pOJhR0ljyxO57awIClE+nQK/lrLppO7X8aFPk2W8oVK/72rQCd/fMreDbsfGdzg8fzihR4VdC3Mi3QIkvxX/gSN/pCWAb4bu9tTrRD2gPuPf1HFYVz/NhZnMKTBr1WY3DrLveElgelHTudkM8uvghrzo2te2ZY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-29-58Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698647271.9578288,
                        "modified": 1698647271.9578288,
                        "name": "/dev/shm/incoming/2023-10-30T06:17:48.ais",
                        "sha256": "ded355d5c511691e53cf248b7fd91c7d6bad7a432819c89044f00f664b9565b5",
                        "size": 29849
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "048f1cdf85ce9003e2d461563237a73e91bea2baae81f2d6cc42b2202aa519e4",
                "size": 8309
            },
            "errors": "",
            "filename": "2023-10-30T06-29-58Z",
            "size": 8309,
            "verified_at": 1698693207
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698734723,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "sRgLjSLboAhhiy5p0uqSwpEqvX4nTOeVMqt5VukUNe4qpyj+/f7GjQbmGZLeZx/HyBmCsjIS5qpmG5HcFkA2xWKNBjSg1NqHG72qlN1R29LK7YURcYWyJeOzrxChUDAEw7MIJvCv80Wp/YQrLTgeK3+HBo6/T0o2NvDmyQ6AYjvtzWNtkzvgNhuOPudgPpuYdzJ5cslqJ7XLKa7B/QYeebS6mZxUOIcNZQQBSPwe1Q+/UJzsBiayoMhFdp9bpycE3fdNkVaWryTZ50TVect/SoWrBzHJM6QOb/i6mSsS29hiTyDEk6aj06bZb5NG0hrO+LCX85v7MJ8etvcH/xn8Z8IK1kIL17c4TFPCNP9xO3Vl3yiiNLx35j4wDm3sjxM85aQ62aPKJSvR+aGkPL8tXl8dgEgbbIC/m2670MC1BzyKNFAasIPjmXepKPBht2OIUmOaxsXuuv9Osi9z4afQZkIGJX9Czmo8wKVvLEn9Sz99yYROkZcjwD6aw/nq+OLRPirEFweLLa8pJS20g47k2AeE4KlTobiN9qn7o+BOzYzyLfc5DpFeWA1yDiTBla3hP56sMundfegvuxA3rHCcjmiXJNTyAwPV21mtU5MNHrm6JD4q8/TjkfJCFX3OmSF9X4OGAxr6YMC8N8KEtqkezuU2clb8S2heYSyCMQ8Zemg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T06-44-17Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698734532.63126,
                        "modified": 1698734532.63126,
                        "name": "/dev/shm/incoming/2023-10-31T06:32:11.ais",
                        "sha256": "5a6ea536f1a4c2ccb1ee60092e240a7c4adaa29fc6528dd298a7338c802306d5",
                        "size": 14843
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "c5bfc56b004a79d71071fbcd4bd43205bb13c1461b1fcce8446451494fd7d316",
                "size": 2493
            },
            "errors": "",
            "filename": "2023-10-31T06-44-17Z",
            "size": 2493,
            "verified_at": 1698766622
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698648011,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "uRWJvXJVpF/Dsd/U9s3/hw5hK9XlHgw4eDx2l0qE3eynBqEusdqKBNVi2vtloesD43gqvkZ4K0Q15NiVx0iTRS9am0pYTtj5Pvk7i/IMtXB2MPBdaFP3Jv2s/5gPXlgNczNLDBKnDc57mSzWXCvkhs2bC8Nc3+EdyFhQbPomETQ3unrpbkVkg1biq+6cHbNmvf5tHyQX5nICruAMMvJIliZMJ5ZdX2bZK/kZv/WBs3LK4TDZOa+RbA8ziMCID4rqNICCPvHtPQ0srIpsb4khbjUJWPzLM5c8sZIY+OU0ubOpTPLDQIGFLerKSaYOIfvAS2ZxANOKm4t4zkN4IJvcHuzBBcu/0Tqk5SnbIJd+Vf4KpCxbnbM1fMfweKll6NQmAF03oQ6pxjQl2j2/D7pYl1IrzAq/7MGrvNthpdelVNfYo4UNl5cvt3hVtW3EyphzfTMoOKHj3V+elf+QckYv+qPjsC80Ol9xdNuP23ruhATXdFMlCFzzSDKcVtVQBAIbegmzqxZJb/lTFXAYT2zlIZeonFuyQcH664+zKRc09py3XjM91Z5UvA2O6eRM091s+3QzH+OSW+XQjOz202XOd6mWh2KMELzu0HxruwW5Cv4VvGYE+HNHYsfWh3R8VjQ5Gu58ml8cjTEQtqlQsMuaoRvK5RVIMPYwQkL+Mj3Vaz8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-39-44Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698647856.376036,
                        "modified": 1698647856.376036,
                        "name": "/dev/shm/incoming/2023-10-30T06:27:30.ais",
                        "sha256": "ebd165ebad6cc0da7f324f6a384a662a8206058e10abcffbc3384f8a1bedddec",
                        "size": 209553
                    },
                    {
                        "created": 1698647843.386036,
                        "modified": 1698647843.386036,
                        "name": "/dev/shm/incoming/2023_10_30_063657_00_00_V_022.txt",
                        "sha256": "4c7bcf14c07815f93e71e68704f76ee8095e3b8dd6a74046b0d2a0707ce9f21b",
                        "size": 2040
                    },
                    {
                        "created": 1698647842.036036,
                        "modified": 1698647842.036036,
                        "name": "/dev/shm/incoming/2023_10_30_063657_00_00_V_022.kml",
                        "sha256": "d532e17d1c5590e7ffd1ca80d72f4c9b607add6bf9ce0c81f865314e5907ea70",
                        "size": 977
                    },
                    {
                        "created": 1698647851.276036,
                        "modified": 1698647851.276036,
                        "name": "/dev/shm/incoming/2023_10_30_063657_00_00_V_022.jpg",
                        "sha256": "2666d5060aaa1d2c65b8e25fbe7b7262aaa594e89c4c2a92ee76c7b5db4de9bb",
                        "size": 708490
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "383cd6f1f83a5fb8daac2149da6c9d2b82890373be9d59337c24c3be425caef2",
                "size": 759861
            },
            "errors": "",
            "filename": "2023-10-30T06-39-44Z",
            "size": 759861,
            "verified_at": 1698665676
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698647821,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "QIpua/bhsls4rf8TtC51tbxRoOGDKU1sR6xs+Skg9JcIEfT7ut1LftAslkcgFN/5Ycvi2xM5YwEnXZmC2RjD5Ur7VfM2+maK3joVkK0bd1foi2gOtO6LCH7eV1mZGHChna8VqSfuwL6mRqml5QmJItyEUUbnqY3RjEfUS+oQlAgs2e7hCxh8yAijbr0v/SnmzLGXR+G+P5YGUy61C4rTaT2JQJO7oyiO6p7ht8nz6VQso0kQv6aDAIX8nQz1Fth5i8h/AOTrzQZnu8GWxVHAXW+QIuiaIA+ePrlkOgDgbsRNB3EzFpPVilbpG47U5IOpgZe4rNRJUxFZOLM86qlQCagHKLDDvzPcoBB0++82qLaJM2DD5hu7UlPuMurguMAWEWear4GB83sh85r9k2QCr9IMJqyO/ujcQEGxbXFVlun4aqCqaiFHK00M+tsI4MHKJiQLm0ZRuTcZMyhdm2uo8JrZy8Pa9oiUXb2gV04dN/TsIf2s/n1o4QMvsi1QPyyhkSj88ZlrXmEnfV6tSXFhshmF8rTj1Ksk1ucn7Jd/2nbnGbKfLMzb+bGWUQS8wv+4H95pwf/1jvXdkhzuIINsUJgBqQTqXsO5+XWC0dJMJz13BugZ+x8Z+1vq+1kj1nEs9g1Tdo2Oo2wezDkDqYcW9Aeh/FmoWg/2plUSWFJzpfo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-36-34Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698647670.906036,
                        "modified": 1698647670.906036,
                        "name": "/dev/shm/incoming/2023_10_30_063406_00_00_V_021.txt",
                        "sha256": "6de77c5dcce3fdb100d42b417620cc99cf338db533b868d9fcdf200e8c92e028",
                        "size": 2033
                    },
                    {
                        "created": 1698647668.526036,
                        "modified": 1698647668.526036,
                        "name": "/dev/shm/incoming/2023_10_30_063406_00_00_V_021.kml",
                        "sha256": "26b619a7de09a7855e8700b46a160af1ba101cc6c456535a0677f74916258022",
                        "size": 977
                    },
                    {
                        "created": 1698647688.386036,
                        "modified": 1698647688.386036,
                        "name": "/dev/shm/incoming/2023_10_30_063406_00_00_V_021.jpg",
                        "sha256": "bbfcef76e4480580921f2b5fdadebd922edf30b6a5c74f47783f45f08639a306",
                        "size": 918340
                    },
                    {
                        "created": 1698647625.656036,
                        "modified": 1698647625.656036,
                        "name": "/dev/shm/incoming/2023_10_30_063325_00_00_V_020.txt",
                        "sha256": "51d4b781e25a470c93c86d1c084f152a101595b3fee7be3ac34b0792c7cae1c8",
                        "size": 2033
                    },
                    {
                        "created": 1698647633.876036,
                        "modified": 1698647633.876036,
                        "name": "/dev/shm/incoming/2023_10_30_063325_00_00_V_020.kml",
                        "sha256": "03e5582bc30b32cc38830c1c72696dfe782eada7a0614f37e28a69d91893c913",
                        "size": 980
                    },
                    {
                        "created": 1698647633.956036,
                        "modified": 1698647633.956036,
                        "name": "/dev/shm/incoming/2023_10_30_063325_00_00_V_020.jpg",
                        "sha256": "76ea1cf72c9bca39455da7057cd8aed5f361e081cbb9d7732e662ff42f3698c3",
                        "size": 889821
                    },
                    {
                        "created": 1698647611.516036,
                        "modified": 1698647611.516036,
                        "name": "/dev/shm/incoming/2023_10_30_063306_00_00_V_019.txt",
                        "sha256": "5f538a7627b50e4252b56df45bd4ff72c34f0ba28b53268ab0f5dfa9b6d6e9b2",
                        "size": 2030
                    },
                    {
                        "created": 1698647602.856036,
                        "modified": 1698647602.856036,
                        "name": "/dev/shm/incoming/2023_10_30_063306_00_00_V_019.kml",
                        "sha256": "1f547ca788b17e7e73d4b392a7c80d1a7d45da7241e098069a188ab52969e0c9",
                        "size": 978
                    },
                    {
                        "created": 1698647606.816036,
                        "modified": 1698647606.816036,
                        "name": "/dev/shm/incoming/2023_10_30_063306_00_00_V_019.jpg",
                        "sha256": "4ec48dac37fe0929b4597e56eabb34875fdfdaf5c216713a18ef0d01cf82c439",
                        "size": 1013942
                    },
                    {
                        "created": 1698647587.766036,
                        "modified": 1698647587.766036,
                        "name": "/dev/shm/incoming/2023_10_30_063235_00_00_V_018.txt",
                        "sha256": "1ce4061d8a6152e9694d7ff16b52a279ab83eac150931e7f6068b812d97fa9ba",
                        "size": 2032
                    },
                    {
                        "created": 1698647582.096036,
                        "modified": 1698647582.096036,
                        "name": "/dev/shm/incoming/2023_10_30_063235_00_00_V_018.kml",
                        "sha256": "30313a09ae891bc5636bb9d9148dc757a608b7e8b2b0695163caf6a05345944d",
                        "size": 977
                    },
                    {
                        "created": 1698647578.766036,
                        "modified": 1698647578.766036,
                        "name": "/dev/shm/incoming/2023_10_30_063235_00_00_V_018.jpg",
                        "sha256": "cb42606b8ffaff0bf47115a70acf85c10942963112b6577cf21d07fde99a3d08",
                        "size": 847419
                    },
                    {
                        "created": 1698647568.836036,
                        "modified": 1698647568.836036,
                        "name": "/dev/shm/incoming/2023_10_30_063229_00_00_V_017.txt",
                        "sha256": "de6130fcf701d8ac26e8158ac1ac3173cbeba7339f3690f133ff89a0ffff6b25",
                        "size": 2031
                    },
                    {
                        "created": 1698647576.886036,
                        "modified": 1698647576.886036,
                        "name": "/dev/shm/incoming/2023_10_30_063229_00_00_V_017.kml",
                        "sha256": "f6347fd766a275ff7c1c2bb115540ad4b28bac81705515f25318030961dc366c",
                        "size": 977
                    },
                    {
                        "created": 1698647569.146036,
                        "modified": 1698647569.146036,
                        "name": "/dev/shm/incoming/2023_10_30_063229_00_00_V_017.jpg",
                        "sha256": "72f0dc93d0fbcc750b9438476b76b192184956cb5dc57cf97bdee0e4d0d4bd52",
                        "size": 852013
                    },
                    {
                        "created": 1698647558.906036,
                        "modified": 1698647558.906036,
                        "name": "/dev/shm/incoming/2023_10_30_063217_00_00_V_016.txt",
                        "sha256": "01c06c4901fb532356587f79f5426ec584dfb241dbda30b2d401d66a637c5ca4",
                        "size": 2032
                    },
                    {
                        "created": 1698647566.726036,
                        "modified": 1698647566.726036,
                        "name": "/dev/shm/incoming/2023_10_30_063217_00_00_V_016.kml",
                        "sha256": "62c20ea830ab4d73012ef5333ae22197532942385496caedb66fbc8d64f80b3d",
                        "size": 975
                    },
                    {
                        "created": 1698647565.576036,
                        "modified": 1698647565.576036,
                        "name": "/dev/shm/incoming/2023_10_30_063217_00_00_V_016.jpg",
                        "sha256": "def2b930904025235b611b798fd42667600b4f43f85749d7249d209e12eabd4b",
                        "size": 787998
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "abda78a8e05846f2540b97cfa2ba034c8357d5a6122fe13cc9a702d40c950d15",
                "size": 5304416
            },
            "errors": "",
            "filename": "2023-10-30T06-36-34Z",
            "size": 5304416,
            "verified_at": 1698665695
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698646813,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "D0GLDpNCpjPa/jWdQ7dO9VLjg8TooGZ9mi8N73QtQMchtaHN6IZ5KZeM1pCxbKIOitjVC07YwlXHvh0WPM54BIQa9cVQWWREDpBh2IfJJvwyI2o3BjY7rtpsP7+wSdpek4MuM6Tm8kUmksGbruI3GJdDbq7ZB+OY0M1ZA1ySP/OE7VeUkW3YU51jCpZADVTedD3U2zkcZJkf4cIZxp3yh/9tWPfxMOJYkK0QMTLc1w24YM532XJzzhz0AZYQeC8TJEK5eP9i1YRDjF9HBP31aErGmTMTi13cOf9289ecA3ihBL9zi+c/KPqJWTTFxl35865S/ZPWxJdJmTPj5OuLtH4f2+JBvY8QZAbVulQnlK5Gfk8ZSy5h/nSsxE77z3OVIDKf9/FlAGwfdTIfZ0kc9SyXiKpuW1LpB2ItCQgdMHuCGtGbAre08xzZFfAA2jh3PNntnpYOOXY8NxfEwcUaFuvjUch+9e6HOYpDWTLdIQVgeMpUYo4ZKygk9QnB1llNYYtWjVd4fnEqlOKazesPn3adKYummulYdYOq5SYh5YcQWNE4tBBCLmjPfIrdLpY1VGqs8zUw2lmrPXr6yBlbJpa0K96LpTHE9bLQqCMBRr9SiYadYA7tbK0uvjRUvqcTA8WzaYmiSpfXlkWa+4jDUEbGp/0+B8USIDRSNIn30Xs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-19-54Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698646668.2678287,
                        "modified": 1698646668.2678287,
                        "name": "/dev/shm/incoming/2023-10-30T06:07:45.ais",
                        "sha256": "48b2d3394ab01a674126b444967714db89a6739064449e95e60031265974de4e",
                        "size": 11050
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "cd514139742be89a315441831c4de45da8861454b6ab46e1aef0bb2441483d61",
                "size": 2816
            },
            "errors": "",
            "filename": "2023-10-30T06-19-54Z",
            "size": 2816,
            "verified_at": 1698693242
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698648015,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "vewEA15YSfCdgw2ieteRfOqqsXmiucBmXpAth4ISTwa58zlb+Lrto4yBvzlJa8ubRh1VPDggEsK6EarVJCpbxLprmxjw8ef47wYTfs34+3ZFICXR+6osBP1dOMxtTMuN88zzEUkblzWCHXz2NpI1JPi8070HtBwNyw4j3cgvgeDshDtrpvL7kn6XhT8PV2QBTqO9/glQoh5dtZ6STlegl7VAqak8he4UA4577vdB93vrN4uwcGZcWgvThutuVJVo9eGhRC2EMsXO67an9uzJLL4O5NnTi9CAogs1KS+DYaQCquILA+u/82QiOUiclouicVHm7gic4aVD1ERbfN7Dzfd+noyt1QpMujOh2jsupoAPYmEK6PWmQSROQ4E45rqAFiQBuMHt2Jx8WYPQ+by0HBELugALwGtY8sPqQl2/KUyxyT7TRt15mAlEHemvWPl99lI2jfSX395wKwKJhNCz3lqw7MWrzHJZikLxW8mVrhSlNslX6D1DqfAvpNtd7kxOZHuP2MY77msIc+d1KJ0UTIyUNL6O4qlGu8+ZAl7qANWPtImiWP4mn8409EN93TM1V9M4FAl0GpaKFCs+yF7pOSnsGnLmt4PKUjWiqjl6JrkSyF7sCXowUDag7MGkIdgyq3JMfpdbxatPKrccCaSy/Pio0T919eYJKzI+qiAIjQY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-39-56Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698647872.0978289,
                        "modified": 1698647872.0978289,
                        "name": "/dev/shm/incoming/2023-10-30T06:27:51.ais",
                        "sha256": "c7927776d5572fae6ae46112bab168f84cc643990bae45638c4663a3618c338f",
                        "size": 621922
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "7b4fa129f9dd0ff09547711c130d8786018e55fa49c67c9a645b2a9278474a99",
                "size": 180366
            },
            "errors": "",
            "filename": "2023-10-30T06-39-56Z",
            "size": 180366,
            "verified_at": 1698693576
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698746063,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "MPrBtOFjHRUfaY0H58/XVDGaHbR5b8PpEu2/I5oD3xnR8ERpatof1xsREQUPc3NNJY/FxNPB2TRk1mZATd923fFtK4ajUCUefmQg816YYhomK6tEIWC8q/GxVtowKK5Y0o3la6rxFK/DXUbr0/0/x/WJEmnOB4y/pNh3xYoziJZpUnN7SoAqHogbW51hSSehKylQGFVZhIXCpd187r/jqxkeKrgF5AQA9dbE5yRr3mH7n2W401tIDgOmaMNMPx0YEbAdi94wsvLn446oVIQSj3hqMOg9zxud5I4HwYJ2yBbOz+x1GfF4tgp/CykXSoz12GHoBV0JcDu9bqGavSErgenp8+YFqV+0ikrNL+CA4ql8ftj1LRZqm9IYP2boKpr9FBAU2bx3tv5VGxgEhoww7i3ENF17KMBPa5Rig0boGDVlmzMTQuMGJZPhugxwSZpKkixyGue6oRdbQLODponsLm9BTRPvlsnvUDWfdfX/Sm7iYgondSaaPXuyZo1Bf/s5X//wSDSL4iLEhO15zQa6ayOnc34eNbnAvJGZv4uiC/Yfo8lzKUd5d7PBkps5GMxkZzpwi19XNpYlOup5cCwMpdEbAgVMcKUI50A9LWTGoi6l539fmk1JQP/kgeKnuvFIYUsWRSL/Oc3qRosKyGFHe2jkq75Kz0DxfKQ7W1yuZhs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-54-13Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698745929.4114184,
                        "modified": 1698745929.4114184,
                        "name": "/dev/shm/incoming/2023_10_31_095146_00_01_V_047.txt",
                        "sha256": "c8b7d3003c9946a7caf04fb190f783dbe23d4792ef4253ef638be57f75c112e2",
                        "size": 2034
                    },
                    {
                        "created": 1698745928.6614184,
                        "modified": 1698745928.6614184,
                        "name": "/dev/shm/incoming/2023_10_31_095146_00_01_V_047.kml",
                        "sha256": "92391f15612939e243b56e03b700dd85d9983ead54739201135b94fc93043f83",
                        "size": 950
                    },
                    {
                        "created": 1698745928.4114184,
                        "modified": 1698745928.4114184,
                        "name": "/dev/shm/incoming/2023_10_31_095146_00_01_V_047.jpg",
                        "sha256": "b52d96f4d78208ce88a62d47af00a9a143a4fd0853ec3bbfe4bc29d6a3701a9f",
                        "size": 1330349
                    },
                    {
                        "created": 1698745927.3414185,
                        "modified": 1698745927.3414185,
                        "name": "/dev/shm/incoming/2023_10_31_095143_00_01_V_046.txt",
                        "sha256": "f48cf17ebbe4fa777871503e8ad4945ab656827294a74bc08a3c0792375abe70",
                        "size": 2038
                    },
                    {
                        "created": 1698745927.2314186,
                        "modified": 1698745927.2314186,
                        "name": "/dev/shm/incoming/2023_10_31_095143_00_01_V_046.kml",
                        "sha256": "5d886cbc9d07b732ad72eb2b528853506fb3181433bfba7f28bb888283ecff89",
                        "size": 949
                    },
                    {
                        "created": 1698745926.3814185,
                        "modified": 1698745926.3814185,
                        "name": "/dev/shm/incoming/2023_10_31_095143_00_01_V_046.jpg",
                        "sha256": "9df1906dfb706c7f13bca8890537017777d17621001448ce937d918b2bae81e3",
                        "size": 1338073
                    }
                ],
                "mission": "OSP1",
                "sha256": "462b909d7a028d6647ea6ca61903f9330307efe3f7eb2ed1fa0c8cc71677f426",
                "size": 2671323
            },
            "errors": "",
            "filename": "2023-10-31T09-54-13Z",
            "size": 2671323,
            "verified_at": 1698766699
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698648650,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "HVaCCKe/0HZlxv2evNWr5hLcDo46ANpGTd527308/qGJqFAu2hucKF/xxFYQw5ks2e/5P51GknNIc7i8l4PxV6fMfw70z+WDMNPzFbYZrmmI5x6f82UuoLcHmoXr9Bdha/+c/d5ZGp1eKMSCp9Mh42OLy0pvSOWBZZ6/+4gvLrMu9WHsH99kcvwLofOpUD4LB6m+YqoSSARwq4V91sDBPgMtUWVl8VTRSdW3CK31okrYm0CgDfm4OWqJWRoUP5v8RXHFORt7tufO9ZdExoHGog/KVDbFSFzMqW4AN059KI4bfUuTs6zw4zr99C9gfj9FF1ymnH/K0g6rwWSm264lGc8YWXAiOv/KEMu0kbzEp4/5ZWK9cLpIjcbXaNTkOelYhmG5Fl9/YztO2l7ju6MaMqL0kQPP1+Jap18jfdF6TV9lUjWFFVE+nb1GInkiaFT5il6hccUMLnpUWEfoI64fPBLRUqpmH19z+y9eRHPQUOK/3X8eC8n8YxTeoLHnflFKu1f6sE7sw0YhW9Plp3g45D3tmfmrhPRZFhJhZ/wryCs5x5Rc6pzkyoPNASqUb9Jjh3say8spvz1ud0Pj0PQy3B0S/Zpz9ZEy7xnmzifruXmhGCm5zpOlWlBVLiwMZ+3lLn74Ux8dgPBPNqfayEJfN6L6+lAKHzyaSw3P6oXLulI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-50-23Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698648460.266036,
                        "modified": 1698648460.266036,
                        "name": "/dev/shm/incoming/2023-10-30T06:37:36.ais",
                        "sha256": "a1e19e315fd880b68b8de9d6fdbde937e67ef26f271c3a5c7b1cce2e0f3816aa",
                        "size": 211548
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "6d5b7b7370a2e5e079fc65bc39239092c39fe42b924e0dea0126f6f92b37c226",
                "size": 58888
            },
            "errors": "",
            "filename": "2023-10-30T06-50-23Z",
            "size": 58888,
            "verified_at": 1698665911
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698649130,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "cB+1LlKRJsBq+pBeivGD797qLkwmqkSW3Jte/jiOGsU+lCdhGZmwrBJjpNnKggqDPHm60vFkDxeNJAzEnFvjmvwBvMmOE6/Zvo1fp6HT2aI9To8tCr9N82zolE+wG2SAaXwh9mOYJVR7gxCZB3zi0mWIGexZnx6gKINNxWYBu0XXdt4GAeoiAbk3u1iyByzOevIFpXOI0jxxH/fFoOS7SrQ0E25czt1/5DWYZ8I8UZPIQqOozgZwpLqtBzY0RIe5fRXNNXtMXbuy9991ssVh0w5QzvY3nW4EY3Vx41G5aoOcEXf6gEA+lx4rFs6RehjSnCNn+ojyw6cWdciLcPE48ehFnEBHgJbcdFBwZ/5r6N1W1vi/1R5oXzzQ5Tr/0D3TzPAHWdKcM1yZ/H7P5oY6KcMK7O5rgA7lFOzPMAVnCIfDw0ZJfZW7XD/meayHhXkVyvFtVgTlKsbTARmlSI7dfW6fc8p/yG8N0Yfei8OAkiYaJWMWuOMXtoLuojwyWBckwpnqZpTct+kxKiGIH3KpGR7hLAP1O/76LzI8frELL5mf+yie+DgcvvquPza2oO7V02SobOkVXxnOy9UFpRdGTlwF4icNfSTGZ4I8DIPk0B7pLEeqHpKdr1ncs619a7pO5ufj/1iXZ7sSb1YkVrTJehRiu7s+RxL30MlIDw+6BGc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-58-12Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698649062.516036,
                        "modified": 1698649062.516036,
                        "name": "/dev/shm/incoming/2023-10-30T06:47:40.ais",
                        "sha256": "6954e284ed23c89470ada435b4a2e9c0f27bf868fc2ab523c1261ba2a259cd3a",
                        "size": 244671
                    },
                    {
                        "created": 1698649032.186036,
                        "modified": 1698649032.186036,
                        "name": "/dev/shm/incoming/2023_10_30_065643_00_00_V_024.txt",
                        "sha256": "1399a84fe5e3bbe87d13dc3eeb76f8a676aee445a014f20dca0625686222ac9d",
                        "size": 2028
                    },
                    {
                        "created": 1698649031.746036,
                        "modified": 1698649031.746036,
                        "name": "/dev/shm/incoming/2023_10_30_065643_00_00_V_024.kml",
                        "sha256": "9b3473e21166c621c4e508874814774a0d490f18c2a44a7f1f4e2d398abad208",
                        "size": 977
                    },
                    {
                        "created": 1698649021.556036,
                        "modified": 1698649021.556036,
                        "name": "/dev/shm/incoming/2023_10_30_065643_00_00_V_024.jpg",
                        "sha256": "60be56c2f104ae1cfe803da248578a83ba58df8f0e29252ad221d09707d8f85b",
                        "size": 832654
                    },
                    {
                        "created": 1698649084.686036,
                        "modified": 1698649084.686036,
                        "name": "/dev/shm/incoming/2023-10-30T06:47:39.ts",
                        "sha256": "1fa7840749cc9ee9f38ee0a6ea0fac2c458e2038b7a5e6ec7e6dacc20d0b94f3",
                        "size": 141184616
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "7149afeef8a9db85a6b650a8e1be3a2eaf119dae113200e5ae7ca416829d046e",
                "size": 137181622
            },
            "errors": "",
            "filename": "2023-10-30T06-58-12Z",
            "size": 137181622,
            "verified_at": 1698666052
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698670569,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "k4Bal0/GZE6MLzHYYU/hBw1dJuFcH7MorHfzWbxWUO7dFDEVBrW/fC22p9SpMTi2BtcKOG108LnNMNb1H3n783k7LIlQJQqYZ8/D3cxMUWFvjzB48NIFlvJSFO+7P2wuAh58NCz2WAw8IHOiFKPJ5O1bN6hdCYwATeMSsj2xQ6DUGd0npRkADtPJI3hQo1u/sLzlXrbxppzt1cAlbV+4PIFUBbaBKJnYsCmO8gvHr7kJy+2/D1VcpI8/N8w9Xrh88AfUXIDtHyXuZ1AF6pjvX3mxYZoG+9dbxU6AeP69+V3gpdku1D+6oA2yPIzBXm38mpMtE/J8YK4Ela24z3To+GTYbl+JPXWmP4l8TBeT8aw8t1J2BOpfHfXKM47BrnQ6qZw5b9VdO2PcXMKscTIxOBLrmy9vkDi3YWS5+VqOpOz4UOLtCjGY1rw39jA1o3ktIelEgAzFcCQUf/5JA2RggnF0z0rNAkzvn0EiX3OrxJlL83PTNK9PMl/1YoP7sC3ulVPVmZ6Q7mY2DJOpDQPnYRG4Yzc3K1PtDGsjUpdgz1d+qIdXK4eqgFjhPokwSHD6yQYn1t0qSba2rNa2FLd570uNVH1RKLD8mez1TfIdiZjS0dQkW/gbWyPskvVlYjOQXQopcCGrhUTk+KUGev1XoPPZgV3Jmo76EaSDlKRflcQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T12-54-44Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698670371.0979407,
                        "modified": 1698670371.0979407,
                        "name": "/dev/shm/incoming/2023_10_30_125215_00_00_K_143.txt",
                        "sha256": "4fa778e69a8c05ec97f6d029b289d8b9e615d2a23ae76c396f8c7ec1c80c4b9d",
                        "size": 789
                    },
                    {
                        "created": 1698670367.6479406,
                        "modified": 1698670367.6479406,
                        "name": "/dev/shm/incoming/2023_10_30_125215_00_00_K_143.kml",
                        "sha256": "8f99cff1affe88e646ede9ecf891594129ae0c0b26ded6de40f1d31ff331a720",
                        "size": 933
                    },
                    {
                        "created": 1698670364.2279406,
                        "modified": 1698670364.2279406,
                        "name": "/dev/shm/incoming/2023_10_30_125215_00_00_K_143.jpg",
                        "sha256": "2a47272aa4696ebca89d4d9f918a284a69fbc005e3db9dd5aa21b805ff312eaa",
                        "size": 571015
                    },
                    {
                        "created": 1698670355.5079405,
                        "modified": 1698670355.5079405,
                        "name": "/dev/shm/incoming/2023_10_30_125203_00_00_K_142.txt",
                        "sha256": "ab9e5ced6b17bd7ea0984d2d9a2325ce673eb2d370745bbf9915aafbce8f94fb",
                        "size": 789
                    },
                    {
                        "created": 1698670345.5179405,
                        "modified": 1698670345.5179405,
                        "name": "/dev/shm/incoming/2023_10_30_125203_00_00_K_142.kml",
                        "sha256": "b90d8f58cacca7580f23d888504fd5155179d97fa5d7b273aa4074e2d4366151",
                        "size": 932
                    },
                    {
                        "created": 1698670347.3379407,
                        "modified": 1698670347.3379407,
                        "name": "/dev/shm/incoming/2023_10_30_125203_00_00_K_142.jpg",
                        "sha256": "a6b53b8e63dabaed1b051b7270ffe5c2945771fb6642195c96e81ff886ef1f86",
                        "size": 618909
                    },
                    {
                        "created": 1698670313.5279405,
                        "modified": 1698670313.5279405,
                        "name": "/dev/shm/incoming/2023_10_30_125129_00_00_K_141.txt",
                        "sha256": "a65ff83402f8fa89460abcc1252d86fc31f34b7a667bbf2a3b51f1528259751a",
                        "size": 789
                    },
                    {
                        "created": 1698670312.0279405,
                        "modified": 1698670312.0279405,
                        "name": "/dev/shm/incoming/2023_10_30_125129_00_00_K_141.kml",
                        "sha256": "73d7a88a51d7c31cd4075afd64c9a37ce687683a8f865abfcd256a9c8e5c8acd",
                        "size": 933
                    },
                    {
                        "created": 1698670311.4379406,
                        "modified": 1698670311.4379406,
                        "name": "/dev/shm/incoming/2023_10_30_125129_00_00_K_141.jpg",
                        "sha256": "1755a4bffa31bcea687a591395ddc02c0dcfc6986bcc895601fa68ed94e2ff95",
                        "size": 605275
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "c2543e860fcaf0f1b29ff8f61dfd0e44d81d39b002ff19f7b524ee14bc5b0d6f",
                "size": 1767725
            },
            "errors": "",
            "filename": "2023-10-30T12-54-44Z",
            "size": 1767725,
            "verified_at": 1698684422
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698649645,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "th1ijZwVGBQdMUe3IqW3oDowh5eDQutp2989tbj/pfulza3LNSKOMkvGhZ04Cb3S/vqxjmlZAujNcg58IUqi6MhbX3jNI1aZzj8vm/LDh2gsL0o9dXfQYBa5GP/PkOL4HlF/cfdOQEOu698OiQxwstkXQsRobcvBQIaXG0OsVRmvTA/K7xCOm41k3A0168Q9lBDn3ngN9auZzn1d+I2Dkkxh84nGAaXJLV4Kb74mYRR726LEiZnH9Eq0/s8y/P+DfbyPdeVAh0C2Oi8tsTaIv3OrT/jgCnk2XC0r4bl/jZbFHlDTYxXFiRbnFGz/CGN+vcTkB6+tdAQ2FP2ZIhFoonAYRc8Pw2AN7eFCkjp8dQ3DOYA/PsqProHS4SGZ49QjpIPj+c+WNjJkG5xHPRxgyZMKqBaxkgRZdJSj+/mA2m1AaPC5igKLw2bRN8zFQ97flxu9dpB8vuses0TA4japP2yCmEHYHjAxb1iTVDBbssQSUQGOefCSZnTHGBPU8watCv+YxS3eMuGyPDE7iNu63HK8TBN9I1/IIr0WEJtOzr4rkN+8aY1gFkVgG/fuKKibdFaRmRJZPyizg3/+pE3UXaL00781BHz1yW+gM/TC93WXnn1ZsCDTtpURbYzJzPcSd++AfjH2hbCtmTnJH6PLgF+bVF3+uSdst2oBnOl21Dw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T07-06-05Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698649491.1979406,
                        "modified": 1698649491.1979406,
                        "name": "/dev/shm/incoming/2023-10-30T06:54:50.ais",
                        "sha256": "1b8962a742ecc6ce2b0469084ac15974f7a3747aecb68f1884d0017c2cc17a08",
                        "size": 825486
                    },
                    {
                        "created": 1698649555.2679405,
                        "modified": 1698649555.2679405,
                        "name": "/dev/shm/incoming/2023-10-30T06:35:52.ts",
                        "sha256": "d191478f2d44eaf724fdc8e981508f418dc5d446c51a1babbc91cc6677b24a2e",
                        "size": 440691552
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "0907e0aed55d47e0a2abab75ebb057e18285fab143a9f3d764bc19b8fc415509",
                "size": 396405233
            },
            "errors": "",
            "filename": "2023-10-30T07-06-05Z",
            "size": 396405233,
            "verified_at": 1698684899
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698648714,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "SG5HkJsAH4IyowLEIuJq8URlZlXLQLUhAQVOAC8zCFdgq0/jGRRpBXhrbeHBMKEP8zlhTn0yt8aGOzkwDHNek+FwqnuDpZzQC54EcsfjbShETUNkpdfUtHT2YvAPb69GWMu0DnKmEzNzWkhlZ5n/Gj3kMU7dr2cY/3zDuE7ulFj4ay+VHzhrb17Z/X3QRdjF93Rlt5iQWWRaDh6PrlFaG6Qa2WtbehVNejaqzAoaw5dZfd0Q+hFJ1+aBMLbqvE3h/H/bfPOz5XSYfJ+PM/wSOQKihPPtmtqg14X7qMkH6508VffLR9V61zoHqwU0/th0YyUzX2TDEkqrpyjPTi5sKwlqnnJLeD/2mMPVqW4aR42yVC4eO9JKXVqemjR+FQgF8Np7F09gRI2OVhjxyViaOly1llbcPtEJHuFUdnh2f6JGgeFGxW9a0F4wJGXFDh23FXt1g6qt2FljfJvEsgh+wsPxCYlUQTXi4vkz8HtpQKEOjXku4+FtTJyHhD7JhZtMDHL99yCMzXFSrU0JYulw8qwqH3sSbmT6LhJCKNCF4yx9WfzV9K8kU9qWOjAKv8vg5rV5Uz+916gdE6EdGkcdOjf2iIvuhjVUjgl073hI3POrQf47XUJ/uTuzoDyvftW7+529qtj2eIOYFVG2x0LBXq+ByZyZJfmGRE5kpY2cdAU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-51-30Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698648567.1778288,
                        "modified": 1698648567.1778288,
                        "name": "/dev/shm/incoming/2023-10-30T06:37:52.ais",
                        "sha256": "8edf63ec1a494f1668c4e2c4f282b79438c83f846f126badf701a61eda0910df",
                        "size": 746650
                    },
                    {
                        "created": 1698648567.1778288,
                        "modified": 1698648567.1778288,
                        "name": "/dev/shm/incoming/2023_10_30_064717_00_00_V_002.jpg",
                        "sha256": "5d68d79b05af59d79330d2ba877eed348c9e93db5da40ee408d22ef57299c67d",
                        "size": 958762
                    },
                    {
                        "created": 1698648567.1778288,
                        "modified": 1698648567.1778288,
                        "name": "/dev/shm/incoming/2023_10_30_064717_00_00_V_002.txt",
                        "sha256": "60645a3cdf8d98491165e5e4f6f376f3edd4d3ab99052596768d991b4134a0d8",
                        "size": 2030
                    },
                    {
                        "created": 1698648567.1778288,
                        "modified": 1698648567.1678288,
                        "name": "/dev/shm/incoming/2023_10_30_064717_00_00_V_002.kml",
                        "sha256": "8e8dcf6b1e10acce7f8f689b10c12cddd9c52fcf603a4505c31a458b9d5c3917",
                        "size": 978
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "2f6f6eaa2085427c0b3fae67d932eba7d32e7b5093b418cfa47a899298635b3c",
                "size": 1177692
            },
            "errors": "",
            "filename": "2023-10-30T06-51-30Z",
            "size": 1177692,
            "verified_at": 1698692900
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698745353,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "SvG0UTonynynL9SoQRemKvlTB7va2wWjXrPtXhAe/ESjwImrrcPmgtrGJOKHyyBzl+YJuuzzKwsotSbu69MgrSUu2976mooYLjqoXLUd65TlCeeE3ajXpYvm/kDL9AjFfWkKfxdDQ+bBq0ZssdealJjIWlr/y9/DYxHuX9LDBZXR/OiI23B0YuLzbswji15KPgkBhd6JN5Yq0a8WQTImO7bXSpMWt+/uYJDmm0YiACmMldZo7mccn894gDdbdpx16B7oXzaQQDxYrCApodfxFYbOYytVqIfSypMIXcYyt/qMfcYLot3GsdZrRp9XylolBH96Z9Rarp7yty0hwEC6AtQ8tEDoSHNAYNwAu9T5FA2DvAkb5yzxNhUI5bhsl3I4AZBn7Lvgrkib2F+5cPQmWwpApQs1t0OUKNR0NicX3emoXUzWDsxnJL2RdFD30USxBmQfv73plG9yv1VNeCClBDgF8F0/JYd7OAK2x/qkV+rDMjwtmrTGLhg43xr9mkL7QrT1XRpUK+CFkEJON+rYVTfepAb5nqvRw8Byh1qm2f5nmJYsgWXZeGVFI2XpeLTIiBrp25fmYpDwVh6rmkL1UuNDSOhqOHeogIMwkOAzJp3Bbye8L71mjjTREvR3MGlkvK3374EDyC73o/BsLjarpqqMLyWBWGdrGpIrXDHnGsQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-42-14Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698745181.5514185,
                        "modified": 1698745181.5514185,
                        "name": "/dev/shm/incoming/2023_10_31_093918_00_01_V_037.txt",
                        "sha256": "b35ee1e0d9dad3d2ab4d62c9c4cff35bf4cd842728a7391dac41f51392f40d92",
                        "size": 2035
                    },
                    {
                        "created": 1698745180.6014185,
                        "modified": 1698745180.6014185,
                        "name": "/dev/shm/incoming/2023_10_31_093918_00_01_V_037.kml",
                        "sha256": "a934201d2fb194eac3121a56bee394724e7d2fe874fe9feb7d6603d53fff83e8",
                        "size": 951
                    },
                    {
                        "created": 1698745180.5014186,
                        "modified": 1698745180.5014186,
                        "name": "/dev/shm/incoming/2023_10_31_093918_00_01_V_037.jpg",
                        "sha256": "8b450c8c223f97bde48675183a9a922d2838525e2b5baf7aabc934d841c6cdf0",
                        "size": 1382976
                    }
                ],
                "mission": "OSP1",
                "sha256": "83c076459851c41117aa9c5c8deb7e2c0815b65d7fe8cdc8b2e03a7b05aa8278",
                "size": 1384751
            },
            "errors": "",
            "filename": "2023-10-31T09-42-14Z",
            "size": 1384751,
            "verified_at": 1698766741
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698650838,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "EPxnWse679b3+llq0l4bHT6jna16X36AUE92r7K65CaXTuNLdC9FCo6QCM+S1cYY0BTF5FL+O9n+KjhvllNZWDUQL9v5NwkPHWU5RTSluvUk4rNJlYKbqvS5/KsS1bbTkbypf0ZGYiv75zkulNPu5sYBd/ypxz8tOHva2yt/ayR4HeexYs3EahO4EjoC2ziE0aOb8zgPUBSShDUtnix4kVAArxYlUyHzgELiJ7vq9sCxZ37DaxqjU/rtkY/H4wSI65EpbhzFhOKR5kz94VhDz9h1zX9Jtm9P22Kp0l5EEf3NJzD6E5xU6Xjf13z7K5P0X4V88+xlhbLjpZ3tSXtPDW2YgPLiHvfEiN9s4SRH343cGbk32YOe8hn9robQ1i4YKnPrvsL6aTtB5irneQerEtPu8wltQe4+lOsrUAxM32hQexVn2nU1+TP6Sc/NmWwZCGCyw/NZVQeseK0gmBJ+0ZKHmGJ9tIvTrW+RXKeKW4XsTO+rDrI9hAT/+kZ1E3ed/9vnSoy3oV64yUr/iHwWt+vqwJL+W4kievcn6YjMhVT7agUPdXckE4Kpx/mZEor51wH1K20n87b5dwdwEikgO6cSEZ4pIoiEQMOtuDoUeZlafMTRli0j/j4iWQTsxX3xTs8AoYNXMbMxX7DrpMsk+CxIr3sVvrVjlqM5LUvyoDE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T07-26-58Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698650693.1179407,
                        "modified": 1698650693.1179407,
                        "name": "/dev/shm/incoming/2023-10-30T07:14:52.ais",
                        "sha256": "ba4622ae5943d1019e446f8b4ff866e7ea17824f9c79597d374792963b84b350",
                        "size": 912542
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "d4715de339dcc1efb5cd300300a055e3c295b643cb297f468d52670aade44c8e",
                "size": 278062
            },
            "errors": "",
            "filename": "2023-10-30T07-26-58Z",
            "size": 278062,
            "verified_at": 1698682391
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698651466,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "Gaq0GHMN5Ha4ReNQaU8dUd1BXu1DEd19/P4IpiT0PN0YuIdgs4A8RA170BEnBeEzIeSvd+sSjFlUWP8zgXqicnZ8IaZsILUxbzirZdkEXpSw5kZbON/1/o71sFwJRvOwaeW1mqbI/vPpCeNSIfqq12kzpgKrxjWOzPmrNQfcUbHKSngUeFXR5Fx75nYErZITprPtWnJV0by1twMrxayos2/fHgH1/4j0G4a6lt1/+uFwuJAeyq+zNFi9jFTPsv2pKf7i45o5RQWByGszTH1veFsMxmghDoOGLp6/5jnMjd6dOrlUkvUpGXPNNlVQ6xHJuTFqGBGFsFIHEirtlqCtc6Lu3rILDDrWJfzmYS8259cv0+foCgOVuC250IQOwV1cZvIR54JtAlNmO+n77cp7l7oxXZi+c3E3T1tSz6q6gvJ/MOFgNpNyr52yaKGxaOs8X5/Q2LaBUQIbYDd3I1jDEo1x93AoFROMfnKFNWE4yFD+R7HqmBE8l+MI2Uc5Totmn8izB2wmBVL9oN90pS4mWLOinfsra6+3b/QZ6eJvMcGxq8zGMaKVZkWH2TP1lo/dwyFyBqP3XVyL76R/RexQciVX6yF1H+JEj7AdZoUOMkT3Xq4L2hn5R2pvCk3yJmvtMY7rUK/4LuqqoFSwpzhm2xiNzrv9QQ6fhgY8W5Ua5fs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T07-36-08Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698651294.2579405,
                        "modified": 1698651294.2579405,
                        "name": "/dev/shm/incoming/2023-10-30T07:24:53.ais",
                        "sha256": "ebddd0bc86a442e237d028864cabe90820ebd499e465e43dde1a9b14da261947",
                        "size": 940844
                    },
                    {
                        "created": 1698651361.4679406,
                        "modified": 1698651361.4679406,
                        "name": "/dev/shm/incoming/2023-10-30T07:06:01.ts",
                        "sha256": "638c0d3d3d76a5389f8f380496458f427b5acec31c2a1a15d21a1b06cc1eae2e",
                        "size": 441743600
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "e99937b25bc9a22eef0683f17d77c4e3f0a9af7051709d055f0a1d9e02f3dbbc",
                "size": 397904858
            },
            "errors": "",
            "filename": "2023-10-30T07-36-08Z",
            "size": 397904858,
            "verified_at": 1698683043
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698649825,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "aMTvTZvGWxQSmxz5pF6o5L3nGhvOt08qcB8rVsoDiObRtnRwdxDkpcucpolqI8bmLMzeNtFUWArSmGDZP0Sbd2pfyosTGy9H4DsjCEK6WL5jiqMIBaOXhl8X4JHtV7PqCA+ciog/bhrxGvJNG73OnBw9lfw1FJVShXvZu8kGbX9F/nCp30x/lJmpBJQNhZi4nxJEgeOucT4HroBcwwJnYklmZRZi27hFKBulpjCWOXuBOcyylc+ft0Ej8ACMEWlPiTFL06mxoJIuDWeH7a3asC37tzNyKoMS7iWGp65tG5jyX2O5J8cYhdtOI4RokEZXfvFk6kKDlh8T167nCFmKP3bqCrELWW8OYdrF+fFUWhFKZgCcAOZcvvnr3EBoP31NQxGgDaQvuMDTtrkepLIY8hioLeUcqOt+jcVJw9DtAVECzw9gILqjDlhLwVAxMf2sH3ylhgGciN1UiQtqcypFS+1pyE4V31Q1ONsbPVqqOuBH1lG/JOAPI8LSY6lrqQbUpvApY54oFLjKZbWE5TcCRw+RQJ3AQeO2IdGmI6kuzVtKjz5JTUJrsjW2TlQN8fIHZ76XIFGlqJ/d+LXVMaB23dt7oyhAYvr6AiuVOawbKvhDgbjT4fWDudhbXyPfMlG/sYCwXYGn+5dX2rI9H5nLL/+wTmt6gmFoMOhA/uRxpGA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T07-10-01Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698649676.057829,
                        "modified": 1698649676.057829,
                        "name": "/dev/shm/incoming/2023-10-30T06:57:55.ais",
                        "sha256": "ca55680c061052dccfad99df5fd5d64e43c709aeac8b58082a2473f25fae8377",
                        "size": 571667
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "72088b543c4a9d02af43763f81a3aa3fceda9180f8792ed600cb44e815060cc7",
                "size": 165195
            },
            "errors": "",
            "filename": "2023-10-30T07-10-01Z",
            "size": 165195,
            "verified_at": 1698692853
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698651076,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "fZCUc+OzugMXInRwowMXgsTtYM4Y/d/HcN3B0XJ4dC4IWXVfGN7NiiouMS6NJ+A6K7PETunvoFnXlG0EN8BXcENeQAYtIRPEq+7lnpE02Cw9M9xtozYVx9q4zMIC7vB6cjQGEF5TUHBIq2p988UeQiO8KrDtcUZBVVimK+5q81Tzn/FVODCToxc10E2bLQwjeSauUdkAvVlh5w5rLn5EXly/61drhdQJdVgNO8tkQYyUDE4QmNkuZwVL2dH9vX7m1q1A4Yths7kP8iqpx4bsaARbPhSbKuYWnEl+8KUdLA4/XcfuP/RCEkDfelaRT3PtG36bAFFeEHmCxPy0CXaquv+3VY0PKNY3hRs//UZbLi82tn7vM4Z5Dgpn5xc8s+jwUj3k6UeGqVE492uGIQvA5gzWMaUTi/wk0kKNpR5xdDElAhbs+aiUvBVwyMwUt6MSoxJIu5OhtW/lmG56VGM4A/xL7Vy8lvvdGtYazFUqggr25uxMqMZEzNLBgrUtQJTah91GdQrh8YUqBOKBJuVKWbX3affKjDRfpL9YxXNWGVL6fu7EVDvvgn8OpFOJ0iq1s3lTTs6/Jek7pTvKU7XA5/KelAFdvQqtaBheEYsLrq8HLWHVopAhlHLlm5/s0t9lvqRXuvyuP9OaVbc1G1lTap2hPmWXRsV1pb/L1FgQMFI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T07-30-52Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698650927.8878288,
                        "modified": 1698650927.8878288,
                        "name": "/dev/shm/incoming/2023_10_30_072834_00_00_V_003.txt",
                        "sha256": "6571e4edb661d2928dfaa48265862b53ef528e2058dd76b5a766904b971fe69b",
                        "size": 2034
                    },
                    {
                        "created": 1698650927.7778287,
                        "modified": 1698650927.7778287,
                        "name": "/dev/shm/incoming/2023_10_30_072834_00_00_V_003.kml",
                        "sha256": "54b7243780318aa6d396e5f4fee6cc01b2f49f712ba14b6435654b6262bbadcf",
                        "size": 976
                    },
                    {
                        "created": 1698650926.9778287,
                        "modified": 1698650926.9778287,
                        "name": "/dev/shm/incoming/2023_10_30_072834_00_00_V_003.jpg",
                        "sha256": "824ea9e07d80fa85913383b85539a0d2a322339d2f8ea28439bf12433c7c51d2",
                        "size": 1139531
                    },
                    {
                        "created": 1698650905.077829,
                        "modified": 1698650905.077829,
                        "name": "/dev/shm/incoming/2023-10-30T07:17:57.ais",
                        "sha256": "b71672d5d77327e4ab9fc4e118983c6b91b8caf5329523ff3820349a0f58409c",
                        "size": 602776
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "5eb49d41966e1b50976e3c13361bcfa9531aff1cf9a0f498eac9c4c4c99c899d",
                "size": 1319524
            },
            "errors": "",
            "filename": "2023-10-30T07-30-52Z",
            "size": 1319524,
            "verified_at": 1698693515
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698650531,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "tVhJCZA/Ns+JLmg0sQHCKK3x11E5ZbyCGp89Vva5ODFBgNjsSOOFHDckwYViQPp61FAWdvfuRfA7h1VoEs9IEHRXe6DIl+rZuWPSMQrmI6yE74xGaNuVHsM9bHq1b3qIE5U2WhKSl4ahVzKw3el7Yk3qS4GMzM2Gm8rR94ehx3fgzKk1ldPEUtpeknn1p859/IuWcBvFxXAwN7cgnS80F8ipsbzq/h9uppH1KeRUGqHh24d3dO4/14/mYEk+A/SqjEOxWxQcQ9EyXaxBLCudy5d+JHIO0Y/bdx3H/c2GHSjWSfcd7KvdqaIVDHvZCU/ikaCB7oiQYhsZzeSnsiWrXBlbwsj0tFWvbt7/uELQOunBr8Wbki8eIcf1DoaxPx8Yc7pGGwTuvV5Kfi18Ne89FKGlqrPREb4leKKKUs6JrnRALJqeMjodle8SkqGlFB7p1ScczXEt4bc4tN3r9E4vgRjYCKUbRUehTuzuQihIOQ4K7ZPEev9vhlpFR6YktCEIC4yv9XifGT/th8AiAhZyYs2Yd/4qtoK8MksXsFWv7ZeISOxndTEREimZp2OOT8nZibLpJphkYQPkZYY8w/sGEsq6+ydh3unXf9QDBYlqPpGrdmZcb8Kl9/niE99TCSiRlf30DOasHYmgl4Lh4uCgDZYOBE0bystlsracZ6m2HwI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T07-21-48Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698650385.4478288,
                        "modified": 1698650385.4478288,
                        "name": "/dev/shm/incoming/2023-10-30T07:07:56.ais",
                        "sha256": "ebe67ba784b8d0ef4fb0ffeb7d9a171faa036dda2ed0bb9dc9ed0471decbdf53",
                        "size": 581006
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "0ec1ac865206520a4ab465927bc75bd9ab73acaf29c391f5446f7548362caa76",
                "size": 167563
            },
            "errors": "",
            "filename": "2023-10-30T07-21-48Z",
            "size": 167563,
            "verified_at": 1698693571
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698744087,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "NGffrqLWWkQitC9isB/1XvKgacq8zCzP77kanHdcX+f75GM29xpX9GY21mWLjsG7KAckWeZI25FTTmqX8eSWzuP4Td4zwH5oz5id27TWcHLNP7UOm2KafmydyOFtklsyKlMqP3DiZEYPwCoLGTL3yTcCAWq7Wo4nIFHsc+wDtQUNbi9QiLrRV8Z7W+Bh5MmvGHS7AJAAIgoFbRyDstXxDTtNq3v+EuW/NDy0gZUezO6yENBr7p6K6K4iPr9Cr0Ic6SwPvc0ObDhHgsTZTHWa44OzIek0fHlOe2yoJwMvvpnylE58XxYI2mjiTFz0LrpLhSSsY8v8YfQoZ0Sw3xUWRvE37sqrrsNeUSHOZ+nCaZa60v9jan85lgRsF5d66/7hGK5Gp3fWl1O28M9K2HdkIPTLo+Gw0PKySY9DQP9P8wnqn49f6DRJUeN/nQ+GWkSL/B6g2Inc9GmJJc79XMeMEgUF9cGlNLNsSfRf/4/DQSPifXmaovszuD3tGEiTuwqgyme3T9E9s4RMVBinp431WtWHfS10JDQzmwVgy4SFM8HT7gGE1PzwV14PjJnPrdJp+6tIwIFjYRnHsVlyCB8q+WOxM2QZblViLjwuBsOfLs0UcUsIfBsmOsYxqjIH6uXEoGVmoV7kReBeJM7HyNbr8NJUIGyUe/Xq46qnBC7vXFI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-21-13Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698743945.1314185,
                        "modified": 1698743945.1314185,
                        "name": "/dev/shm/incoming/2023-10-31T09:09:04.ais",
                        "sha256": "32f7479d0e7066c36688ac5b71d10857ecd0b9c3bd9af710881b148d33c62cdd",
                        "size": 261121
                    }
                ],
                "mission": "OSP1",
                "sha256": "edad67b459ccad4abcc3e970cf4cdb5b8fefe89dfc7015f58b2a8c4fbc536500",
                "size": 61827
            },
            "errors": "",
            "filename": "2023-10-31T09-21-13Z",
            "size": 61827,
            "verified_at": 1698766793
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698652041,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "YlIKs+CmgNFH1vNF7cbJTAELAKp2QpJwwR5bft3Ce6AAI3iIKjmnzEwdbhqbew7IQzUw3y6kiv5L2V8UV1qF9dHd+dZHVznN4oEiR0Qdaecr96iwcG/umWoXavNL5vW/XC3RFupp7NgV8ZxPRS4uPt/XfUMjYA4Xp3G6qpVVRQm6COA3VWxiCpJGrMNQrZZE982ujQVzHPzKsG9r+IQvEe3xVWeGmefxhreeQtKUhD+bTXogm1kxAzjZGwWIGztXrbHbmCceg+swidmnrrgIDHIymIAKCHH3qIlgXuS4aU8CkAgMf+txEcwFr8bJqhF5TH1NmMT1nLIzSONc8ls0GyLH8HSiePGon4y3ITJ0QI4RLcnwDwj6fDId8mwDZhcQihBBrC67fOJHShgQOB1gGn5mv7ZLEZcvXHgO5TVQWFqP/o/d+Jzbf8xDozyyKyQofbUUzgErw6847RPUU4coalqnzTRHbTKN7pdJm7NYBkEkN3Ku2uRFgkLCMB2K+MRg2N6/VTDSbTXlISHyeF2+QjMkycb4Z4BJ6o8Gcq8sGRNl8h7cYs+LBzAo6yB1Tt6mqKsHhGQuSJ5KfzsBFoCMJKIBuA4brCfd9gAUqt1/H/xUKmkEI0yopLtGvl4AIBiTZrXQG1fOLI815AHupDsOMwbsmb8Zqc9MmzEm2t6GFaA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T07-47-02Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698651894.9979405,
                        "modified": 1698651894.9979405,
                        "name": "/dev/shm/incoming/2023-10-30T07:34:54.ais",
                        "sha256": "fc39da0336566d053407d8378d5d53a9d3447fa926e1a36ade84d973ab437d4c",
                        "size": 843521
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "03411820cb5cee0b12a3c27385e856048264d5f870af557efd6c59c42df4e0fa",
                "size": 255450
            },
            "errors": "",
            "filename": "2023-10-30T07-47-02Z",
            "size": 255450,
            "verified_at": 1698682375
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698651632,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "TecHWdveOHkjrowtK3YHYCMQBzMyofJMgelQSH6Zq5e/A5iCad120rkBYu6DHSS5qMZkfFwYY2fblXfVw+8deQPrDAhTrEcrMWQAlpK3u4eClPCKGdsKF4nEih3CxGjyqTE3+e4hni90psxVLcoSih16GYURXwDfxKSVdcuKihXRgjLeDgeQgrFluz18ZFpo1Y2IsspDod4ScPlQJjMCTb0kzsqJK+90Ws0TVYn8vqOmf3LZGzZWpC0abEcsBpfCgbIWAiGALT2VyUJayHFZguy9OJVKz9+gLz8orL+hu1TwUf+DlK4+i1Sg1s/LirJB1aTZ67DMfjVPXMIcWZwKwWKLZNZ2j6A4QlhgEd7LmQG7waOJtoGyxJJRMt+k2soeHjnGbIa6lgTNfOc9DUzO+k7lWX7X6LQJjdGKwK2dwdZIjgCMoymhxJuevX061P2NjjO4BYypAm8xOwWeQkOy/CiNuWR3FS9sieSZ0DQ2VIWAAkv+9wtlZWhRhMOx8sJuTEJwf+LsYzhy/UlbrRvLhz1LJbpKsT1AOaf7z6lf0GQvYJO0E5TsOB/1B8f570bhs7JgSgYX237QioRJ50BJs9FoM//e2owN+0iOGwZ5zP/Xb/GuLMaU+Jy5w90KrDE4kJ2j36Jr5jJvFvCa/nkBo9EV7MhMmH/U64flGAt+WXk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T07-40-07Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698651479.797829,
                        "modified": 1698651479.797829,
                        "name": "/dev/shm/incoming/2023-10-30T07:27:58.ais",
                        "sha256": "89b8693c473ab8caef3046f78a03630013f92348fcdb050992754b87872e5de2",
                        "size": 627662
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "9a91633ead10114940b14646a63a9d6625ec410ed57de7ce3e349d424d30ae4a",
                "size": 186578
            },
            "errors": "",
            "filename": "2023-10-30T07-40-07Z",
            "size": 186578,
            "verified_at": 1698693164
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698652229,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "faCbFSYpwaoTEfBmNHlW44TZwThHQ/WCFNRjPDdPHs6lmE+Lc9hVBbjdkQZu20X5VyVX4JFRJArphUMb6TXcjuWlpJkq5yAtQAa4RXAGz3ZZBNZrzM3rrjJ10JPioNI85iumQlRt51xqLiPVA+BixSwtyS9RaqlxektC/1+KBXFyetUOGbVgZN5dSefCP3ioydUitjtWyXbFi3lB+Q5wpDYZem11oMKG6hpCZYVuOMEOo2bCRDqHUGa0lRlrYKnKvlXYCu9rov20TployYsEPbgh2RyWONsu3VQpoZ03B9LxHCvb2tlfO6MSWOqy5nmq9PqEIABzhn2laGzPdZezrQ/rGCE6QuyZ/R+dwhy7q9ds+8TZetUyhbTRFh1cjR473L596SLaJOsyEZNjG+WH1KoZSp0e4joEbcpcYZ2rGvHWFkIyLqmc4glRFvfYhM96mKm/02Ooec1DUXB9wNcXxuoOXZPdJRxDNjg1bk1yfwYq1x9GuT3kFkxMk1LLrmX88PAuYWMOIh+p1qgfUq5qOCWT6up2l5b9KhjCJPBMgFVlK182t0Stz6rgrBiIGA93RpTMSJIsqjC99VTjq9nPP/BkWEn0aTuAUAaetG9zXKagqy8dQqPpmwAMQe/tBD/NDkcM/54Ih3yYqYqi+scRB4dIhhBnaMVHQgCyO3aMM4c=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T07-50-05Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698652080.327829,
                        "modified": 1698652080.327829,
                        "name": "/dev/shm/incoming/2023-10-30T07:37:59.ais",
                        "sha256": "c2170f87df586376c3c70a85916830545c340147fadffedcdca1ddf8f15c78cb",
                        "size": 637493
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "2412a2fed9498dc4d59d38a28fbc63afcb17fcfa499fdb61654aae430a6e5c49",
                "size": 188497
            },
            "errors": "",
            "filename": "2023-10-30T07-50-05Z",
            "size": 188497,
            "verified_at": 1698693335
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698652466,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "YFZUDTJJL9en1kVzCbpYOp4svGFb4bySKc++WHzZCS3WfnAP4UsuEq4WAeNV8s9ZeelbLjFV6KOobKsp6ZLmc3Yhlo+sVR2JJId92FcRMp9l8hsRUaMLWqrkMDo5JZcOSy5ufRDE2W0VGYoNLt25BThaLycD2eQza6ReeV7KxBT2gCLq/GviUzcENCh0kzpkhl/PQuN03DMJ1GoMubNWe/c3zpiLIYxLiPL6pnLUbRaPJwNuB/qYBDXAw+/+xM2nhBo+ytVs+PtETS1Je5iIYHqoA0F6nUWE8teMs16kCvWAgtinHBD+rMb7AbGyQ7eAeVBODEsaUXj8cLCYO+tVoKtu6NHr8lugdih9VKCfKRNQWDTxvwEpK5O+JNsbWG2oJ9OR3m4ZNPMfWJ0oOr0ETbp6d3Jh5CwDXyDy+8ddxJQDte2JYmMk7sJPZC31gWeymUiFHCG1irab9fRBvPowZ3+nGiRerS1Q3IHApwO5WphbAbre9X2zKRqFm8hrRittS023b7sIXNDdu8roD2B47YhKneDWm2Hnoi2Xo9xoiNBuhCQqkIOH1f7QIM49tCmqkuNhGf6WiVFr03tN0HuMI/jM7dQVGW7VZxvDCB8DaLlctNY6awdN9hZXR2GT5eHrK41GH0jddSe2VC5ZeGnjqMDOXcyQoV9zLsWoILyHcF0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T07-54-03Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698652317.4978287,
                        "modified": 1698652317.4978287,
                        "name": "/dev/shm/incoming/2023_10_30_075144_00_00_V_004.txt",
                        "sha256": "291bd343ede3b16bcbffc0fdfb305a04ed080d73e31163b4b38ac6a0faf2405d",
                        "size": 2033
                    },
                    {
                        "created": 1698652317.3778288,
                        "modified": 1698652317.3778288,
                        "name": "/dev/shm/incoming/2023_10_30_075144_00_00_V_004.kml",
                        "sha256": "b692db6f16124b695808ac07b38998e6f87de3322a7d8a8f75e9c9eea1510838",
                        "size": 976
                    },
                    {
                        "created": 1698652317.2678287,
                        "modified": 1698652317.2678287,
                        "name": "/dev/shm/incoming/2023_10_30_075144_00_00_V_004.jpg",
                        "sha256": "a49d1b41c276922a209d55e971227884cda78c0214e4994049bfe1dec5eddfb2",
                        "size": 923086
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "e81975ad12903e28958fb6685af14119f804dc7ead227c17cab1ade83a8e4f1b",
                "size": 923572
            },
            "errors": "",
            "filename": "2023-10-30T07-54-03Z",
            "size": 923572,
            "verified_at": 1698693509
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698766829,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "khOQrl52q7kNgRrV4VZesA3AjC6IoEnrPdRpC3PNX86IFgrjutWMmj3TnQoXSKjybDlodU6FXt179399s42Fyz62j/c5f7Ejvmv95emdN0N7WWzPbk6Zh6nIgLQtI0OIrp8AKTKX5I0lqk6AAIlBl97280zPx1S5xCR3ygSBajHH0diPZ0fEPYWpkmdxVWaUoElRugXIVoUwlJyCcArR9E6PogowEGnMYrOLfdTbBQTQcvSo5T+WSBOhDkCpbjnwt6z8CcaNEaYB+ls5mJXhnvFwtjon4Cy8hhcWHpX8DnmVRVNoFSYukAuBPCnOlmg3hswHJD0kFFQbYNl1BsrnAoz4bs/puH2xMrx6K6C3ES8nmUAYdAxAtCjsbxoqeAkAbfkV2vwlIJu/DQ1tt0zUxCPG75IVhneHboK/k4fptHhLrwp+VBOdcvbnj/hUpEc9c2h1TJ3WcidcyiP3pOmQQKC1bPcYPOjP8njyAZEmVzjN3PGvFh7r8bPtyEi+RX0rG0zmWYHBSbhR1TB13/YsLhh9SzdTz+WRLOG5RrPIv0AwBVJJvhjXhZS6taMqL371q2jJ4BnyDirSL0L8ftQMnfoH7SZBpNNvmFqw3SHIRakftz9ITOfOi0esYw0GEJ2xVS5y8cXW5MwILxByt+hYTk/5D7tAGDahd4EQKhuCXt8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T15-40-04Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698766686.8330362,
                        "modified": 1698766686.8330362,
                        "name": "/dev/shm/incoming/2023_10_31_153735_00_03_V_020.txt",
                        "sha256": "b667734009e6cb48db0709183920a24295372ed6f37d57836fb99f8c62786d45",
                        "size": 2029
                    },
                    {
                        "created": 1698766686.5830362,
                        "modified": 1698766686.5830362,
                        "name": "/dev/shm/incoming/2023_10_31_153735_00_03_V_020.kml",
                        "sha256": "5b893025f83313c68113226311cf958254f94c351b1967e3e9448deeff68f6cf",
                        "size": 976
                    },
                    {
                        "created": 1698766682.0730362,
                        "modified": 1698766682.0730362,
                        "name": "/dev/shm/incoming/2023_10_31_153735_00_03_V_020.jpg",
                        "sha256": "695ce76b4ddc33d43a706df12ad410929f6b1eb89913516aa3e468d305431912",
                        "size": 1014212
                    },
                    {
                        "created": 1698766605.763036,
                        "modified": 1698766605.763036,
                        "name": "/dev/shm/incoming/2023_10_31_153616_00_03_V_019.txt",
                        "sha256": "1945d106c970944335fd38d043c10f5dba3719647b466b5d2e916c158b8bdbb9",
                        "size": 2036
                    },
                    {
                        "created": 1698766603.463036,
                        "modified": 1698766603.463036,
                        "name": "/dev/shm/incoming/2023_10_31_153616_00_03_V_019.kml",
                        "sha256": "3a329e9e9cdbbb7698268ffd97c383f28bbce38ce7c8dd4a3d09b27be5ee1d3f",
                        "size": 977
                    },
                    {
                        "created": 1698766604.1330361,
                        "modified": 1698766604.1330361,
                        "name": "/dev/shm/incoming/2023_10_31_153616_00_03_V_019.jpg",
                        "sha256": "fb74e4df053edb5d9a2ddfb944866abbdfb9af822810ea4d945353cfaa5b8558",
                        "size": 1035176
                    },
                    {
                        "created": 1698766596.5630362,
                        "modified": 1698766596.5630362,
                        "name": "/dev/shm/incoming/2023_10_31_153611_00_03_V_018.txt",
                        "sha256": "1e9bcdc273327c2053f67d6e74c3e28e44e57a68cd6296cfaf70157b9de0d619",
                        "size": 2035
                    },
                    {
                        "created": 1698766597.153036,
                        "modified": 1698766597.153036,
                        "name": "/dev/shm/incoming/2023_10_31_153611_00_03_V_018.kml",
                        "sha256": "dd1ba13b8f2b959612b87e3cd9aa4587f8b781b63a82705f54a44297d941ce77",
                        "size": 976
                    },
                    {
                        "created": 1698766587.283036,
                        "modified": 1698766587.283036,
                        "name": "/dev/shm/incoming/2023_10_31_153611_00_03_V_018.jpg",
                        "sha256": "2669e0c62741cb9f60dbe4f524603a80f8ac8dc5ff141320f16f8e796af99663",
                        "size": 781330
                    },
                    {
                        "created": 1698766585.413036,
                        "modified": 1698766585.413036,
                        "name": "/dev/shm/incoming/2023_10_31_153539_00_03_V_017.txt",
                        "sha256": "f0941f9ca211c31e06138461f967c5c5bf62858fe8fbb962cbed922b25c2f038",
                        "size": 2034
                    },
                    {
                        "created": 1698766563.8930361,
                        "modified": 1698766563.8930361,
                        "name": "/dev/shm/incoming/2023_10_31_153539_00_03_V_017.kml",
                        "sha256": "370fb1ff38610b5b548cd0a224573c9624adef961ce5f7139455c4294072c5ed",
                        "size": 978
                    },
                    {
                        "created": 1698766559.473036,
                        "modified": 1698766559.473036,
                        "name": "/dev/shm/incoming/2023_10_31_153539_00_03_V_017.jpg",
                        "sha256": "e89fb39f04c5191d8c05ca4a167edfcf689c54f81c184edfc09e76da5fd39b10",
                        "size": 966054
                    },
                    {
                        "created": 1698766553.3230362,
                        "modified": 1698766553.3230362,
                        "name": "/dev/shm/incoming/2023_10_31_153529_00_03_V_016.txt",
                        "sha256": "51a94d4a98331cea27d255c2153b0720fb603be6a3c26960ca24679168f83604",
                        "size": 2034
                    },
                    {
                        "created": 1698766553.253036,
                        "modified": 1698766553.253036,
                        "name": "/dev/shm/incoming/2023_10_31_153529_00_03_V_016.kml",
                        "sha256": "ad93b3557d2c1d4220e29839c238ed39884c2e269299ac2a156b9086e679b9d5",
                        "size": 974
                    },
                    {
                        "created": 1698766552.523036,
                        "modified": 1698766552.523036,
                        "name": "/dev/shm/incoming/2023_10_31_153529_00_03_V_016.jpg",
                        "sha256": "0a3f983e78c54155a609f25901da2b78cad5da1b7bdac843dffaf495efc2bf1f",
                        "size": 1020344
                    }
                ],
                "mission": "OSP2",
                "sha256": "c1ededaf4062f8b9d2f3e647a062275afa49d6fe875799ee3df0dabe9735cda0",
                "size": 4821131
            },
            "errors": "",
            "filename": "2023-10-31T15-40-04Z",
            "size": 4821131,
            "verified_at": 1698783432
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698742900,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "jzLMoqtPFOYRxNDgXHT7SyYusfhnKRLPgHT2kYLbvCEXEPtWMNXIq5rO+5k5SKutHQvDlNKaBc0dWX+XXOwizJ6i26KhDucWfs1SfHPB1CptZ1hsk212IK1tOA1RS3rxytXGclzjfyKlgS5B7SbzOv2FNk06EEprsEymebW4kjnmnQEIeZSQ4vbepLVD4a3Sbva77xuOnNWAvVWOtCw67t0ITpe8+BoeXg1Nl+ZJYcTl5d3Lxgxoy7ufrGXM6cjPObnLGFTBCSzUAL7es1a06IzDkgJA1eJMRUbHFNttToxtdBAsvgIUgDx5PKCkZnN2H1YRlvqyLdiUaypITMLr+9KW6XE+ufSJuE8dQU45x5e8rr53j6tEv7C01dQ+cb/Y6i9mwXdcgpjWWJWJAlaw4zYwREkDH0o0M0UsuSoMqhXVgXVm678NnAWBPeff0l7P2us2SBPSuq4lGtgRCP48ZGGQSvfZR4t0fj0t9wjSz9X8otozmHKoNE1QCLoFau1Udp5RIIYX5ikoq4EDhjeYvqzJzKSAXEvnDkFSFsYmeMIi6GzrzcpkGtUt1L6jlMmJWwUAsBHCLTekJvnEh3Npa5YSw8sSxAc3js1byt3m2hY3TIx8fryE3hF7Eb0RysOYIV2z/ZgnwcV93QCGEyZxUDT0TZ7a09tv5MCTcmJDjiU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-01-12Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698742743.0314186,
                        "modified": 1698742743.0314186,
                        "name": "/dev/shm/incoming/2023-10-31T08:49:02.ais",
                        "sha256": "b55457989859fcd1a4b5e5f448241c2c15cd00f7454282700a129166e8e501e2",
                        "size": 213356
                    }
                ],
                "mission": "OSP1",
                "sha256": "e3bb56463e8673d8bf5e12c88deadb363df0393a7a6f45423322a03ef99f1bc5",
                "size": 49386
            },
            "errors": "",
            "filename": "2023-10-31T09-01-12Z",
            "size": 49386,
            "verified_at": 1698766850
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698776469,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "CtOneogXQVZE9kxlN06gsDTFrd9zmQZya//tSgRy4jO+cflxpHV0lXc9S0O5Mo8ZvYjF5seEhQG9mFG5HpfDNQ8+xwojtDIkaDYQzWQjfpjUIjgnxqoOevXqngs0T55jxEMguWJpBpan5onRsg1F8pFyk2Me4lTu5c5Mt4eSfz1/N/5pESZJei7of7bbwxqZOA1yjnTAevPLGVpyU3FSu4x56/GzMdnRcDpuJOsRqeNTlgBzNvImrgDxCl94+vnlaPHdWuViMIAm0S0C+CU9hpPtYSaK6s0OZl2Zsvb+zQUPc3c/6F77JyIwuTY7Ml/vmBN6l/JdkuK1wew2IRCQ91hUYDLBDAKsOl5O/NgV0qneBmcUgV+8QBzmfW42Qo/mtHYXSabm4k4lt7UwuMeanaklokXsNdjxC0V8gM8/pLolT8L8uvakL4GyFKgG/gT8JbqgOUesN8TJzqajCE8To90Hb+VoBnzV50RLjWF5qq1N1WszSeuIOWYQ9/ZTHC2pw8OELLW1vRuN3v5Uf/LtNXpG+KNZQkaFD5zqiIzAWNIFBKb8LCty5aLlmStl+SxfuAGRqxi/9tmFs/JwpyYBX+KDUDfj5ltB+11gxDAFk6/Xh9hReNLsFkYAvkY347zRNfQTOZQhCYIPDxN5EV+y0V5+9AA6XflJ+OdLYVex3h4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T18-20-53Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698776302.193036,
                        "modified": 1698776302.193036,
                        "name": "/dev/shm/incoming/2023_10_31_181756_00_03_V_035.txt",
                        "sha256": "95865401c771d3cf017b311145399d1688860bf60218dbd7811453fb224cec0b",
                        "size": 2028
                    },
                    {
                        "created": 1698776309.163036,
                        "modified": 1698776309.163036,
                        "name": "/dev/shm/incoming/2023_10_31_181756_00_03_V_035.kml",
                        "sha256": "f428e0b6152206e5ed92e2f7437256f64ad94ef9902ae895665a2c752464df07",
                        "size": 976
                    },
                    {
                        "created": 1698776295.733036,
                        "modified": 1698776295.733036,
                        "name": "/dev/shm/incoming/2023_10_31_181756_00_03_V_035.jpg",
                        "sha256": "2e74888e496fb4aa18616bdb550540f8d8d6aa01539df55802e2bbd96f755b58",
                        "size": 1086538
                    }
                ],
                "mission": "OSP2",
                "sha256": "0d001b71f6615737a42493f872b8c9660a9928dc1ffad6b83c7c71b30f370b91",
                "size": 1088357
            },
            "errors": "",
            "filename": "2023-10-31T18-20-53Z",
            "size": 1088357,
            "verified_at": 1698784138
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698653010,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "slUpJL7a08bv7GzB9AU58SyAM1i0vHIuy6f7VXHs4jsczTD/myI4KKc7LMJbJpOQattZCQFz2wdVmpTLqgYo03Mha0dXJJ30l1GnDlqOdiPydpWjlI07hPsaIgc8GI/b3Mj5zf64rvPAQcoT1GR6ycY6sJhnF/3Rl7P3E5xFR4+yiXrQshey94s+jV7iKFrxSqxuaeZvFZ2JggnDzMxt3dbjx2ZPeyW7r6QYfEyU5exPTXaiuWtMX/uQaubluznsHEC/sV13x/0BLXJGdgI80EpcHWkkfbLn2yewIIHzihw8Q7DmVrIhSF9ifajl8DZ/dvPfIOjtYatUJ7p43eDNNujBicEbFpnaYVQglHXR2BVsHrdoOJEVnPR/96tRfbAqueYRftPTDWTtHkiqil7Br2UzlZOflt4bl87C7QuWEZg8ZOpodAcgbVDC1UvD+mpLQ5R4DCiU2YfMkzjDsnOwBxm5Myxo+ELq6X/4x5zXQdkc+2HnowBRW0eaQHxAUgwcueg0FNDmNzxYXhwLHRvqmlP7Q41eyUhD054230DIwxF3/uUHeT3VZ1VunD/DHkkfO1dNoewebBncHQljUIqQGzNFT8WO7PJGnchm/4zQsCKMDJrS6h2MMeJSV8vOUlxaNo0a7+n/mR6KjwXi9Oz/DnrAZMGq79coodgaymHUoEk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-02-45Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698652954.256036,
                        "modified": 1698652954.256036,
                        "name": "/dev/shm/incoming/2023-10-30T07:47:08.ts",
                        "sha256": "6182de35db89afc493217f36cc749952bcb29a34aa960aa2a8f0ee6dee61bba8",
                        "size": 201130296
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "3eb3639a0e7d8b381526d8761733ec6052fc32f8e94402ed1137f685eef86118",
                "size": 194013860
            },
            "errors": "",
            "filename": "2023-10-30T08-02-45Z",
            "size": 194013860,
            "verified_at": 1698666324
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698653245,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "h+tjwwjPuadCo/X7cWSdJ15NdIQIlOVZtQXMu86bE0EzvgBXCga73MQrUc7gzt0pI0+o1HBj2AC4cWxSibTQr19AgbuCmJwzM+tqs2gvFGJRQwpUHVoJfIYaiJ/9P27UiBQMqTntvdR7lLrfMf05zD1hRgJa4g/dLZ3vnw1yvziW6KwwYziMufk15WOf4WYI2Aif+g1XEceynThfW3LaFprUajZRW6tTq3hwmdseBTNyaAflVB1Jj6cSew/Tx4wBu1iiqbmL7nKtTsUL89OXGrR/j9WcnsRbzWq0o/ARAXJ6jwXd2jsYM565127gZeVs0SU5KAHTH7o7MPC7kanonj9hc/77pQgbhwa5iJF9//I/3uYssCC1RLEIIjlzwc+/c2+cxFmzht0CFNuiVudVrZJbkmH5LM7KxwsdbzIOSIYRFzYOf6Kziv+B6cBBcczulNqwnASw5TJWNKbHtX80oPQy9k3MrJAN8bqrq9J8fO9/uqw3cs84EIxpRXi6DurZiuoWmap9CFa+D+Wm6ssT66WxqRyipXVE6MLfdyAt4ko3jb5AgM/nXmyvXJPt8V6JcPbljf/E5KDalyDNKMXUIMsFfD2gJcJ9LiBTVpj2L9034Qi59RvIF/QWba5IEHmH+6JnKeqU+HeNvyJ/b5AnZQ2K3nPyJ78lzEDPjcEoYwE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-06-57Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698653089.436036,
                        "modified": 1698653089.436036,
                        "name": "/dev/shm/incoming/2023-10-30T08:02:40.ts",
                        "sha256": "e56f3a17a1ac30dca6483cae3313cbc8cc386a30c9d9bdf7f007dbde7c4ed493",
                        "size": 27838852
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "1b5cb506cc1d0b3b859ae8c1161504fc0afd778f6d5af1c08b1049923aecbb17",
                "size": 26840389
            },
            "errors": "",
            "filename": "2023-10-30T08-06-57Z",
            "size": 26840389,
            "verified_at": 1698666417
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698653343,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "T2sIMUjLKPVEInfRYDO8VhKmo1O2d3b6mYmUNReY70gWiMW970/RO9TJnRKyToVowqFsPah5FpDObkMO9nqX+b1PEwO/P8tzsSq6PoHTnqWTikz4E61HkiHNx1b7bgpQvNHJ3oGnIbQLJKVldwpiaux9EnqdquQhzgblUQfQ4dytAgpwOw3j/75snga7yVjt3lgVTzg4kXjHVa8chXAFU46Mrq1bpPdkrwLNAkU8R5E3ZcdG2ejZOlaL/cusvZytGhcMm2EOVEd/wmHzb27WoKpRQJXIBnOOj3vbxcoeWpLom1x99BmCPANCFolDq6IgnmuYyCqSyhaL9inRGeUCVbraR9xVHWrnqickgvAIP2GWyonLy+QkpPSs8UkfvkKOFiNFXRNmFRDleU6JQLLDm4WukzL3xFPcYBGWFR6IsC56CiFkV6tUGTtNg7BCVDr+sZ6Pe3BSRjDiYhSgPFKdRkmVzvAQghESEXkdQ2NjNF/qX9y6oXiOcC8TLxEVb2SwFHCzH1E5KZuAS9T0pgFV/gM39XnvG+i00WX36inl6TsJ524G8vmsp/MAV7ojH8Djne3PhGkie+y/9Cexi/pekz8fptgM4NdtC8LPqq5iOfEW5XMxtyjxNllqDTsQPoGOyDGZVDR2QLFyvaytRHiNNp0yO/77+mLV4trhEXrekFQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-06-16Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698653103.1379406,
                        "modified": 1698653103.1379406,
                        "name": "/dev/shm/incoming/2023-10-30T07:54:56.ais",
                        "sha256": "877694b90a04950b71ee7617163424a80834ba90cfefe616256bdb5a16cf8cfd",
                        "size": 424234
                    },
                    {
                        "created": 1698653167.9079406,
                        "modified": 1698653167.9079406,
                        "name": "/dev/shm/incoming/2023-10-30T07:36:07.ts",
                        "sha256": "1bf3a042b88ad012cba8d2c4ddbf4151080229138d14a55ffa4dc191c1e37cbc",
                        "size": 439618072
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "d5b9877059f0d23f2ef6ee61e7591d63dabbb205677c2ec4388966cb6a5e0006",
                "size": 397758081
            },
            "errors": "",
            "filename": "2023-10-30T08-06-16Z",
            "size": 397758081,
            "verified_at": 1698683090
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698652829,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "vfzemTPuBPeWJXTKESDiD9GMtTH07w5Do5KRnjGbRrceSbWVQRPPcsNKdd5tVNynn5yLtvA1YFEF0ta4rRq11IjWAF5MKRSaFLn6rR42bOsYZqvK8OHqq0OTlxWPLgza2p9kaIqeI70AKlPKgIazpuCdAa3GJHoZ5xaVUMOaawzHODMk5H/LWD8H+qVaZdPQdI7+0+nGi9DQvGuJfMdRSVGWtEfbig70rCPWyzA+16l7WSIMqWrLrozKF+rpopgxe6rf6dwIA5Pv8GYwZsRYtt5gOl7OFXaXRFdJRsxxmO07yVEXwkKMPETI9xyrUH6PR4feKxwFXZkunm88QtOtOCaqYEjZ67rtHfEA8vTjgg8S3AeSyq+IDCWzyk9Mn2TmuonEIOi6GYAStREP3NYSKVbFfAVIdWAR+l90ytquNdXJNm388/u3ciLY6KDsUqDutskx29kY1v0JmshGzcP6JMQXvkgBZQUOxNZyzBVBJxGSiYCFfhDYAdz+YY71TnF3pCOxAxpvr4IRzNQCJh5L/KPkhvMl1ivetc+DJP6ClTI3ybT28M5eA+8Ezs+wEYRq4gjsvlgG0j2LXXdctYw8d9EuB/lnXcTPFw/nnWfCHT4A0HGtM3TUocE/seeZswvQmfxdx5tzqSb+ejiXylMazOmQMXCSZQ/L9KXFJArCYLE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-00-07Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698652681.4178288,
                        "modified": 1698652681.4178288,
                        "name": "/dev/shm/incoming/2023-10-30T07:48:00.ais",
                        "sha256": "f070bde3e6e36cc70e919696d934c0cbd80b9479329ac544c807f9d1445e26db",
                        "size": 716826
                    },
                    {
                        "created": 1698652621.3478289,
                        "modified": 1698652621.3478289,
                        "name": "/dev/shm/incoming/2023_10_30_075646_00_00_V_008.txt",
                        "sha256": "201c0c2a452edb183df0b81c291e34a15b2e92f843cf999ac9a06665333d72a7",
                        "size": 2033
                    },
                    {
                        "created": 1698652621.1778288,
                        "modified": 1698652621.1778288,
                        "name": "/dev/shm/incoming/2023_10_30_075646_00_00_V_008.kml",
                        "sha256": "89b32913fb1d2a0a3813ea2c03fd783640295d985b6f890590e87c51f8392db4",
                        "size": 976
                    },
                    {
                        "created": 1698652620.4778287,
                        "modified": 1698652620.4778287,
                        "name": "/dev/shm/incoming/2023_10_30_075646_00_00_V_008.jpg",
                        "sha256": "c816a508410c41bee54367e3d9ee950f609d80b7cb40bed768115a726608e5ec",
                        "size": 670323
                    },
                    {
                        "created": 1698652577.7778287,
                        "modified": 1698652577.7778287,
                        "name": "/dev/shm/incoming/2023_10_30_075554_00_00_V_007.txt",
                        "sha256": "a40ffca2fb1fc033c8fa8a09bf556f0a1a4e9c817e87ee03233ebe993ebd0c77",
                        "size": 2033
                    },
                    {
                        "created": 1698652577.557829,
                        "modified": 1698652577.557829,
                        "name": "/dev/shm/incoming/2023_10_30_075554_00_00_V_007.kml",
                        "sha256": "543ba6f5f7976428342dc4e2bb3ab901c3d1e3c92b2b95d728901c9ad2acfbec",
                        "size": 979
                    },
                    {
                        "created": 1698652576.9678288,
                        "modified": 1698652576.9678288,
                        "name": "/dev/shm/incoming/2023_10_30_075554_00_00_V_007.jpg",
                        "sha256": "cb32b1ac9b6a5022df6f4e97b2a89b2e7fa1a2a07f5f3be54b4576bc693f5db3",
                        "size": 633677
                    },
                    {
                        "created": 1698652565.3578289,
                        "modified": 1698652565.3578289,
                        "name": "/dev/shm/incoming/2023_10_30_075547_00_00_V_006.txt",
                        "sha256": "aafac2a564d74942a038cd89c39d211ba200a8d939fa52f3533574e6424c3f85",
                        "size": 2034
                    },
                    {
                        "created": 1698652564.797829,
                        "modified": 1698652564.797829,
                        "name": "/dev/shm/incoming/2023_10_30_075547_00_00_V_006.kml",
                        "sha256": "a047521004e86d984a1ad717d27417a2b6eaa96bf58f0d8a0092716bbf17eaae",
                        "size": 979
                    },
                    {
                        "created": 1698652564.3378289,
                        "modified": 1698652564.3378289,
                        "name": "/dev/shm/incoming/2023_10_30_075547_00_00_V_006.jpg",
                        "sha256": "e0f97e66253b48d9dd33eac431a447e19c0a57ca22b69515fb8859eed9493814",
                        "size": 776475
                    },
                    {
                        "created": 1698652531.8678288,
                        "modified": 1698652531.8678288,
                        "name": "/dev/shm/incoming/2023_10_30_075509_00_00_V_005.txt",
                        "sha256": "e2881ff48ca1f4fb7a623374b0fa12290b2b5b1df5bc89948bf102c45bee90d3",
                        "size": 2032
                    },
                    {
                        "created": 1698652531.1978288,
                        "modified": 1698652531.1978288,
                        "name": "/dev/shm/incoming/2023_10_30_075509_00_00_V_005.kml",
                        "sha256": "b2f03967ae281f0a679c0dd98f10a1316ccff8319c0bdd979c81481f7aab9277",
                        "size": 977
                    },
                    {
                        "created": 1698652530.8378289,
                        "modified": 1698652530.8378289,
                        "name": "/dev/shm/incoming/2023_10_30_075509_00_00_V_005.jpg",
                        "sha256": "daa369821b15d3b8e3d8c04f2eca0a901e355d853a42e8992760653372b8a3c2",
                        "size": 1228603
                    },
                    {
                        "created": 1698652529.7878287,
                        "modified": 1698652529.7878287,
                        "name": "/dev/shm/incoming/2023_10_30_075144_00_00_V_004.txt",
                        "sha256": "291bd343ede3b16bcbffc0fdfb305a04ed080d73e31163b4b38ac6a0faf2405d",
                        "size": 2033
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "74d6d895cebe06a7e789c1781043d844a0e6d8a44b1a466631215c677e2de95f",
                "size": 3510005
            },
            "errors": "",
            "filename": "2023-10-30T08-00-07Z",
            "size": 3510005,
            "verified_at": 1698692911
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698653489,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Ss6q7i6JutX0dFPEGKNlBNxKxeH80/quWEpxtNY/KcA6T8IIX6yIS/5uD5KANOu0yZSbqmGmIFxnYDoXJNFpvjWX850MTfRIAqE2fZOA+/QELmEM9etZSo+Jyb8QlH2kSfAfQ6sf2XR//BR0H4tBw/f2+YCI/3OcxgcK8OcWB8GEe1ZW5N1DIR96rmd3tKUXKfR1N6y4fOaqkAT11/H1NngK/ZivhLBOZ3zu3WZqsIij54cU6h/NvzYaqjGY9gOBGszgfcQebnlvA7R56yrCkEI0zLmnChkkQYgKdVHyJK+5I5lkGtS/+Gkgkiv0NtcU+D+g+fVyLB4Rp5ylpQvzakWd+nxI8f64Izs9IE9EOHFiSCfIzYsc9zy6wLfHAP7abok72CRzjyNhIK8xSq71vvmX1Sj0KRHkCGvKgngE5N5n6pPtZbpnyANDYfm++mH6DoVbDvfpoYLOw4Nob2mG2QZlJDMLQMljjXhJYT7nXj5b52sXKYNodiBmhtsZA56NdpjAO0hjnbLjvY5DcXJysAo2NBCapz5dz2kNL8yE3fF+uiqYZZIow1muPprM7qR3BLZlRfAhStgs9D+k5GsWQ1LC3ShoVrzAUl63zt1Rn1vaVhlBmneGS0PUO1OMD9ht27wyFgBYgf3Xz5aczyow8GTRkkOZrJWhP9nsYKm8FhA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-11-05Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698653337.5078287,
                        "modified": 1698653337.5078287,
                        "name": "/dev/shm/incoming/2023_10_30_080844_00_00_V_009.txt",
                        "sha256": "0993eea9363fd1d72087ef57361345d6087bbfd6e13cdc1e7196e102688b1eb8",
                        "size": 2036
                    },
                    {
                        "created": 1698653337.3978288,
                        "modified": 1698653337.3978288,
                        "name": "/dev/shm/incoming/2023_10_30_080844_00_00_V_009.kml",
                        "sha256": "ca83b426a8d7d09cf0576775901b1425f25ce7752e3c329eb3596c49875569dc",
                        "size": 977
                    },
                    {
                        "created": 1698653337.2578287,
                        "modified": 1698653337.2578287,
                        "name": "/dev/shm/incoming/2023_10_30_080844_00_00_V_009.jpg",
                        "sha256": "b42f3aa052492ff26a8e7ed98f42343d9c4ee90fc9c8382fe59336c99495fde6",
                        "size": 1022600
                    },
                    {
                        "created": 1698653282.4678288,
                        "modified": 1698653282.4678288,
                        "name": "/dev/shm/incoming/2023-10-30T07:58:01.ais",
                        "sha256": "7463fdc91c68ca281af87e8103e52ec4563d83252bdd8de52efdd8351898797e",
                        "size": 666138
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "1ce666ff9c398291ed96f24bae217a21f0eb64bf9e61413447675c416319133a",
                "size": 1220501
            },
            "errors": "",
            "filename": "2023-10-30T08-11-05Z",
            "size": 1220501,
            "verified_at": 1698693540
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698743704,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "H+Qr4Bawjq2txlmb2lqK1Idqa8U13fqkvpNHBFp7I0qffEMcvnS38tANfk/bKZdz+I1uAAK60SlfcgiHVmuMZd41bazRq4h73noZyvhcaySUTKixHBar2bVlhXQrUhIRg70m/MxGSVs080f6ZG/qCUkOvh/dMmGNylAU0sgVPAlqyeXEpXuYnemCGFXq/ozXHvMOb+dOt+Re/NycTs07eeDnnG6LxL+LqhsT1A2P6RkQM7a8lc6Pj/F0JJNyO3FNNhdrqrvzMYON9RIn+lz5HUE0WkA8TveUKvjG98o7a/+h5NVEHRyCyBfgt5TGuCuW06b13Sfrba5QUZ7b0uIGqc9dR92KcgwyWoWsry/CVGgIxc6G8elo6fu5OkQpIXCD1a3+WNUoSkpylY6jjj43rzN/MjScu/s3V737+gIS9W9IoV6h391zFXX74bWtAMJoDfrTJB7eZQnF7waLnlpiZ7he/JicgumtN8IGd45SivPQ126aOuZ1BrYfj6osb3gtSxQA+1vlwcMTX1WLcbD1zbuUfrmu4JfC1vNGXBmQqIn22AFXvOf592YNL8hcfsVDUdF9Of0tdHq4DPKQt8a9bk7IU5gilSQv85GAjtVtbSB6a06V81+NhTTXIsKd8KzwdlMBhq7MbV5e6O6r1aU4JYmVAyjv8jYlt/WPsPg14XA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-14-51Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698743567.2614186,
                        "modified": 1698743567.2614186,
                        "name": "/dev/shm/incoming/2023_10_31_091226_00_01_V_020.txt",
                        "sha256": "57d8b2f7a7370a07cda07906dde71d6d6c55d2ae25b408db9ddcc9b932e42877",
                        "size": 2034
                    },
                    {
                        "created": 1698743567.1914184,
                        "modified": 1698743567.1914184,
                        "name": "/dev/shm/incoming/2023_10_31_091226_00_01_V_020.kml",
                        "sha256": "95e646ef9b9f59a8cc811dc329fd09758911ecb5a753ee09915965f9a4619abf",
                        "size": 952
                    },
                    {
                        "created": 1698743567.0714185,
                        "modified": 1698743567.0714185,
                        "name": "/dev/shm/incoming/2023_10_31_091226_00_01_V_020.jpg",
                        "sha256": "b5f133f30f8250a4607464bbb13b879a5a7f1879f7586b03e0bad54dcb455c8b",
                        "size": 1433426
                    },
                    {
                        "created": 1698743566.0014186,
                        "modified": 1698743566.0014186,
                        "name": "/dev/shm/incoming/2023_10_31_091225_00_01_V_019.txt",
                        "sha256": "bc2fafb3a9f99e812358dabb94235057d87812ef03040265b7a211558d78231d",
                        "size": 2039
                    },
                    {
                        "created": 1698743565.2214186,
                        "modified": 1698743565.2214186,
                        "name": "/dev/shm/incoming/2023_10_31_091225_00_01_V_019.kml",
                        "sha256": "4ae6254c0e232b6a434f2eabbe718ecddcb6a79a6284cda9bd9b1412c05f9b39",
                        "size": 951
                    },
                    {
                        "created": 1698743565.1214185,
                        "modified": 1698743565.1214185,
                        "name": "/dev/shm/incoming/2023_10_31_091225_00_01_V_019.jpg",
                        "sha256": "425d21091cc520f2023095f47e012ae3fc869abb75cdfd38a3f240a329fd50cc",
                        "size": 1357981
                    }
                ],
                "mission": "OSP1",
                "sha256": "66d19ba380e0da8465627d083a0da1ac1e83165b78424946e6e56d573e30ae72",
                "size": 2794926
            },
            "errors": "",
            "filename": "2023-10-31T09-14-51Z",
            "size": 2794926,
            "verified_at": 1698766865
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698744488,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Rud2qHxubTcS6nxUvTENaq/9uJ0Pl9AKHgpKWnjfZBd4p7DfAyO1d3/36oMNxDOsVW7R1x6HBMFDrdR6DdqVwVnvnKKZxUldGTLzA2KZB99OHrU0y+GaTOC0ax1rLBs71VFA3uEk9vH3s7UH9pQvMuCIm/NXhDwI6hakqh+ZlOruxZdQnKXnpaRquiSHpwKk+zG1AxWG9QhSDEHPtl9v9rGYd68tsdDH9SPBSOhj6zNsNptUj7Lke8DTo4JYOBgEQ4krdtM2ikCjZUg507yiOjR1XY9c4esLC7+OH9NhjYBlNvS9H6kGNdZQPK5+OxzpvxQ3zitV85TAvRgSZXIJJKYwC43ZiSN7sjWKjhjt5L4WTwS82aFHsAxv6NsZckywiWYWG+yPVuKXSqtUYe+RijkWbpTnn7gFSBmit6tLHiRGc8HssVHHuoJUzvdFm4R3sr3PhE1e9wyOy7DgmIy8CqyEpR4BCpkLN9jyM/9SyQqpO0SfxY+RS84ZAzAUz56fU8lJdgixcI3WrREz48UfVfHn1oLAb8m71aorhNKcIyQigMY6ma1rYlbTuBIFbG1wAUTpVlglvSaMImrm6zFSFBukhoHTMLXv0K6wekhb7bg4/pofW1WQBdRW6+cYRPMDr3lrXN6zWqer0W40LumGphyXuhFX95sTVUPdvq4UcW0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-27-56Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698744349.2714186,
                        "modified": 1698744349.2714186,
                        "name": "/dev/shm/incoming/2023_10_31_092529_00_01_V_023.txt",
                        "sha256": "7bc3b7c69227b4388052aba62efeb871a1c8037d19ea197f2dfbbd664dbe891a",
                        "size": 2034
                    },
                    {
                        "created": 1698744348.5514185,
                        "modified": 1698744348.5514185,
                        "name": "/dev/shm/incoming/2023_10_31_092529_00_01_V_023.kml",
                        "sha256": "d919975a655d4df8ef7b61080b771af6a391a9b97459ab19b0a24ba999ab1e80",
                        "size": 950
                    },
                    {
                        "created": 1698744348.2614186,
                        "modified": 1698744348.2614186,
                        "name": "/dev/shm/incoming/2023_10_31_092529_00_01_V_023.jpg",
                        "sha256": "e06d0a5f198439e6a8f362147519f1e9dfdb59f6e866a693bd50601158428b16",
                        "size": 1598675
                    },
                    {
                        "created": 1698744347.2214186,
                        "modified": 1698744347.2214186,
                        "name": "/dev/shm/incoming/2023_10_31_092524_00_01_V_022.txt",
                        "sha256": "11dfccf107936ef1626bccfafa5555a34244489eee8c9676f125ad8fe0588573",
                        "size": 2038
                    },
                    {
                        "created": 1698744346.3414185,
                        "modified": 1698744346.3414185,
                        "name": "/dev/shm/incoming/2023_10_31_092524_00_01_V_022.kml",
                        "sha256": "8c4b6b71c2f4f234597dcecac673a9d58e2ece788f407d40354b5a5d22b5fe10",
                        "size": 952
                    },
                    {
                        "created": 1698744346.2114184,
                        "modified": 1698744346.2114184,
                        "name": "/dev/shm/incoming/2023_10_31_092524_00_01_V_022.jpg",
                        "sha256": "9579dfbc373c90f06f52af67b83c36a800f33632fab04d38b9031660c3440e02",
                        "size": 1587864
                    }
                ],
                "mission": "OSP1",
                "sha256": "e8714e608e8f79ce8b2b77e78dcba7dc95eba48f3636c5ec7dbd810d329dcfb6",
                "size": 3190112
            },
            "errors": "",
            "filename": "2023-10-31T09-27-56Z",
            "size": 3190112,
            "verified_at": 1698766872
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698654041,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Hqwhu8dOz/oG9IuGQkXvbE0Vg45vaSTJV1vm5QSwtdSnuKMVGPtUwX5lZUGVL5evGbAU0/qSYca46iDwuIhXi+cToe7TnNbk7LPi5+vOs6sbDwJEFDbxRQI61G6LehfLd9KfMK0rTt8IEJeyEEeCnUTNE7hQ0Gi9i7uOueWuzIOnY/JiFG3RcdQ2w3XdzjOYaGP1fbD1Lu11CUUN0Rq31sFlj3ZI7oATcdccpFs/E7RIah9nTG+nI4rO4hCfnjEafx6mqjIlgWYFYBs6DgrcnAMRUfg6rtYq2CXNt1pvNjxPK8CHk7K5DjptMftimbUCkv/uv5w1qUKy+gjeCW8tD8OR6/++cjvLpGGvcEw9U0XRUXtqTV7vchudkPm0H027mJyYEhlQOxczj5slv0qCA842xL0PA1/JSagXPvOBdkEfmEGBbvU1GNoqg1OACquDNEiHBXOT0Mpfvf0/QUPTduBqRCuA3m1/QyB5XGAoUwef2GDD+xygBNGKC+WEp/jjMdCNETu8ALeASRO3FmZYbmajMP/UkhqSBhazEdbxJrYmZTDlFOZ2dYX3QQsSGNptuXruUaIcS+rL9kpOhITKI490OMnkiEBZZweKVUp14CTYU0zF8MJ6ILnurpOR88w5U62+gnX7XXIGqvqO/CCXj2mElfW6eWwtkmMqx4sL6gs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-20-14Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698653890.336036,
                        "modified": 1698653890.336036,
                        "name": "/dev/shm/incoming/2023-10-30T08:08:09.ais",
                        "sha256": "8ed1955724e16f54727ce2b5ddd2ad06ea210ad9af374137e56e425ab8c880f0",
                        "size": 97965
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "7f660e5179dd2294b3849d61d3637e57c743b23f3281f7cae17da07e8b643a81",
                "size": 26420
            },
            "errors": "",
            "filename": "2023-10-30T08-20-14Z",
            "size": 26420,
            "verified_at": 1698666542
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698654164,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "q3ulLrAzY3y4tDxiqxHX3+xBxiMsUMIUXREVX+tjGi2OFJsj8ljHWvXbFIoYz4YpUR4DlwJOm3Yo3ht0x7EH1KcGfy1Tq7wCUaHJ3ZbrQvf1vSfiX55Yin0nR4RYBAOsG1YV9CV2kJ5KHYi2I0w2FEUYdK73oBcQ+Bx+0zJN/r3FEWijDvHKmG/AslTLrFWz1mOe2qOh4oiEZemNlsnelq3xwUDFpGpJ8LLVphaGhlDynyDdSbN15aGg14pLzBhMkvrsLaJOrhVvs05uMyuaFvPvsEJBTV8Y9gZrO7TmCnrneMBRvarntA0JA+P4LSQXULELShexOYHl/1qBWyCSCEabX0yI+OxqqCVs79dXCGaSfDLey0m4bXcGrsDFCkSKio7G3gqcjZwydAOHARCUrG9MnIYPK2+6RXea2KBU/JnqeCkgo18kOzb5jcLpLE4nuU2lPPXG6JNcBNhrdofgrn6LV3Bg84Cq8BpbhyIqG1sxXU5NxtRqF1CvR1OyBCL1Hx+7XMf0n9OgS1qwxMtlclJodM+xNP/iAeYjzf2jWmh5CrNOHIeWoiU/ABbc2AXgskU9crWQDILsxVDiS85McKoT+F9U3LQ/VyNmpyUi3Cp3jZZVe3Mb+3r9ehg4p8craOVdVcDynBtBkmaLRkm8gf1lQjGVlPw7INpJGikJCfA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-21-25Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698654074.7478287,
                        "modified": 1698654074.7478287,
                        "name": "/dev/shm/incoming/2023-10-30T07:51:14.ts",
                        "sha256": "895780eb736455d2c33a4dff907078a2d567d9b2e780cdc36f3d9482f39c5afb",
                        "size": 427181496
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "895cdfe111bb9070db8be2380797e4d64d4ca5e717f282eef56b53465c13a705",
                "size": 400696447
            },
            "errors": "",
            "filename": "2023-10-30T08-21-25Z",
            "size": 400696447,
            "verified_at": 1698693190
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698654033,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "T1pftPILP5sVlph5kaG/JFBBvfS7sZzcZl1VgR8tNo4IIXXs/0iIzg8ET5gVorAa6h24/1sHJJcgOJip1rp24cg7w1ndMKI4LqT3rlqWvyM5ljBJ2BzTrJ4EFKA18iHZnnQsOgZcMvhKqhYmK7cd/ipk8DSP6l1vw7i4PwJMS/h+19lU4VHb3q5/gDu+KttH8vluHVSrtcge46qjJ60SfQBCzea+ZRyppb13sksM1XGXCRNSxLBs4ORTqZy2h/PBhGUwc1vubOGpt8ljVa/wTGHpFkNG6nMH+Lt4eA+KSX0Ummdmwkrp0GRLQ35inWPzTysx0E/wzITjoIaKxiCQNiFJANqzRzZ7nTle9KZnDD0pLUCM6wt0vYDn2mwtMknxLD9WMLYu8eUw9hcDXGzcradGeCvTnHst7Apd8KS5TBXCK9lZzpuYGAQFHUMdpPLn7C8GEylmLMxSsVOc1DwvmGRoWHXRyWcd6QJlXsHyolFPifxBs/sijX2wpCuPcuiIyNGzvLU63l+45Qa266nAPmY1QSIFURyANoyCRBO2yf13QLFtjJgdxaXYA4ePbcywl+K6TV0eYvSc+ZyLLNa0/LlkIFeyKZSWEfejHB5H9vAbmWRfTkpQtu/2+SI7O7XITi8hKasGgAo0immjqUSAvgmWjKtZxrMob943m0MaTXo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-20-09Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698653884.047829,
                        "modified": 1698653884.047829,
                        "name": "/dev/shm/incoming/2023-10-30T08:08:02.ais",
                        "sha256": "8dd1f1dfcbb2122a317c1e7fe8397966840ca16adb892891815aafbd2670a41c",
                        "size": 573093
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "8599ae2adfa69873728324df6c0a71d2fca46508d7ff05bdf6370b18c023e16b",
                "size": 166066
            },
            "errors": "",
            "filename": "2023-10-30T08-20-09Z",
            "size": 166066,
            "verified_at": 1698693423
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698743245,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Gf+XF1kg09TFl+H1/Q65bPRUxkSioe0jSkbtoiwvNCTncBjdFrC26YNsm91F0ENftoi5dn9ipx5qm9Uw1RcNGa/byaNisLvzUI42v8X+uv5o7P+d19eZWLenvtXzANLsbAZIySrcbJMgrk8FJmWVywf1J/KEmHMbN9O34eUwfEPrj7K85uBP7Og0rmr13cTqUr4S+F+ZBBX86VCN7LKXtHx9n5ssJy5N3nYguQEeN11QSV/L0FAyQIpz9EYsNWsUQzPHMOzcZLWrvfEdt81HbkNoIae3pH8M8UzoFw123NmP9MgxaolUkpYmhEjNW++Mczp3Yp4IbTiYuzhYtwSWAiMIrRK26BDsWpfD2TWuz2T0PUbpjxkGEroQkQbn4aDK/jI3VOKNLwnY8QK1BUkKTlHSvq4Z8LqcXHNABOpm8iVuKAJKu//Aezf/cEBQzpa5e2wzoJGaB8u955q/TeKRratVA7mQ+pofuuGM/iQYuBYF2RvgBuJBCInJczrO0GvrQKlA3Hpk89d8oMLq/QdT7uqBw66Zmwbzn0Rl1I9K2P2aLIlp1eAA9FUwChHWeaWQX9+oUMlK3DtjhaQWAwZrkseYvxLhGnAb6GoIiKe66WVAkVaNVmbqWThKTev1HJlQfgvFVAiat4/CILYUfCFGvJGX9K14dVVRw7faWRVP1bA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-07-12Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698743104.4914186,
                        "modified": 1698743104.4914186,
                        "name": "/dev/shm/incoming/2023_10_31_090444_00_01_V_018.txt",
                        "sha256": "9dc519ec28c6b3039b05d0b77fff73e641dfc65a6631723e3b65351f06bb8e74",
                        "size": 2041
                    },
                    {
                        "created": 1698743104.2614186,
                        "modified": 1698743104.2614186,
                        "name": "/dev/shm/incoming/2023_10_31_090444_00_01_V_018.kml",
                        "sha256": "d714c177b800e7f945b1aa6216a9fce2fbb4d5347fd3f070c0460cd3a81e2403",
                        "size": 948
                    },
                    {
                        "created": 1698743103.9214184,
                        "modified": 1698743103.9214184,
                        "name": "/dev/shm/incoming/2023_10_31_090444_00_01_V_018.jpg",
                        "sha256": "9d6eddb571aa425169cf3482fcc6ed674bf6ec6d3422217993783295ac3b36a9",
                        "size": 1271846
                    }
                ],
                "mission": "OSP1",
                "sha256": "3ae4e4592b0d6f34258e64e6391679b33a8e99cb310bfe6e1ae60fee56230c7a",
                "size": 1273563
            },
            "errors": "",
            "filename": "2023-10-31T09-07-12Z",
            "size": 1273563,
            "verified_at": 1698766893
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698654810,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "AKG2g67tzCfUb30TslCQgkubSxzILjsF9nKcGSB/45gWBDQ2bjpBgsIo8FM6k7jIurGLrhi8eavfLAQHuAwJz0YSxvV3WzB9JC4BqjnAg+/ULU+jvas0SYI2yFwwpGMM3UBCk9DQHDKz+xy04w3CXiuRSFyTi6xGHB4BheE/E54NUgl5ntiwb6HOouYZzS6Q62Hk1XmFNk3e8TQUjbZAbwD4pl8wZWdbK6yuAdtTE8frnlZOvnFDJFS30G+c/dBMLSNXG9GDz2ETtvokG61/5KwwpviqFEfdNU1tk77n5kKT5kNXApzDyc9aYQYR9x+Nwqe6nnD8OaKyICPfLeOPocypzjcoM2KNQHxle+5NCrmjLqJvXgMG3M8k5yY4EzE2r6aGBXMT8stFr26zu5klf1PvjzQDdGd+uAfS7Me1kE3drAjscj1zOc/cBkm4y6H53wrmoLRF7XqAyos9wWsZaTsbyt2Dwmos3okAE5iL8AmLN1LmfxNXful2pi9S+x0VFUSSzulDGgpoK1LTlbnZw4I+c0X0Nq+Yc6I9StLv4dV5rTKTKdLO5sQWqtCsPhcL4tV00QKDFtY/+RPtApUVrjmDljFrcE0cHOrrGEXKD6X7ICKlFw6TBj+1tFRcngehI0R5nNIvCwEISEYBOS3WDQfUm4ggWaEPzV8QWdFVkCc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-32-33Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698654747.176036,
                        "modified": 1698654747.176036,
                        "name": "/dev/shm/incoming/2023-10-30T08:10:00.ts",
                        "sha256": "4791fdca5fa81a33dbfb4b291da5bf539a7007accb105866d578a8a781139d57",
                        "size": 301929128
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "56be4746c4b7fccb1f0baa116c4abfba5054b198b22539d498f1d27511729075",
                "size": 291314723
            },
            "errors": "",
            "filename": "2023-10-30T08-32-33Z",
            "size": 291314723,
            "verified_at": 1698666720
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698655281,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "v9pN0qVzNwvYjvl9Yt7tkM2STZxW/pt5S/Y+eSflR5zd8NmiUAFscUZxCA7nO8YUaIJ1SKUe8yrdUcRxmSZb7zo9C8+9D5ZtH22ldyvFJpGxvkdMhExMSP3lZ8waQiCoJo33lZswhjkXbblqTmszvcVeQh+/XVlA8buGe+WLYezYxFpQCpWR/+7FMToRm/tZ9eK6xr2bCBHj7/By6zRMYm0neBZkpjACX543OEdQ7jIfo45oSOLEVRBqtvGcHV7SlBDJhocuol7pM+NlV3xVzf5R/jPOjnT0zastJFTyqKVbf44zAbxndUT0/Cl1xYJvAWsgrj2lo/AhcEu+dByT8ZEmd7lL+rNQLw90eYV/sEoKsPqimg9pnt2ZiraftTK6VVpLj2JZlxtDy4UuWB0cj/i65p8bW/IVUBna9CRBtX1J2BOtH/b61KkheRiOqefZiHFfab6NGGAQQqjPJ07cLxTTBBtSwUkyrM0oO50ofGA2NsBSQJJeF96jo2DstkGhS47Xa7q9Z129IJtMMjLNJM2oEcSFnoBXTfumZq8EMQyHudNpa8t1ZzgkmISqEPBWISiKM5akM0ikw5umiyiyBc3NsFpmi7ugsordq7LAiStLKY3XR7DkGgVgf7QHlKJKVt8jBkahDqM9y0+obtnxIx1BDgxdSlcV2V9NpMIXiL0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-40-56Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698655148.016036,
                        "modified": 1698655148.016036,
                        "name": "/dev/shm/incoming/2023_10_30_083831_00_00_V_045.txt",
                        "sha256": "cc43e18a4dc61f9a2e6c68f438bdcbf8ca63eca44fbfa6e19eeaffa995ee19e4",
                        "size": 777
                    },
                    {
                        "created": 1698655141.456036,
                        "modified": 1698655141.456036,
                        "name": "/dev/shm/incoming/2023_10_30_083831_00_00_V_045.kml",
                        "sha256": "bc632c6f5e48e5fd9fe0620d4a25c8126fddbf45217b33118a2406ed19343d78",
                        "size": 932
                    },
                    {
                        "created": 1698655136.836036,
                        "modified": 1698655136.836036,
                        "name": "/dev/shm/incoming/2023_10_30_083831_00_00_V_045.jpg",
                        "sha256": "6632a333a704b6696d976cd4416d7cdf797c085c1e2219247e315ee2e29c32df",
                        "size": 929269
                    },
                    {
                        "created": 1698655118.656036,
                        "modified": 1698655118.656036,
                        "name": "/dev/shm/incoming/2023_10_30_083816_00_00_V_044.txt",
                        "sha256": "7559e3dc4ba4d1f786a06547167518fed317fe2d13b77b3b8ee193056def0cb3",
                        "size": 2033
                    },
                    {
                        "created": 1698655115.306036,
                        "modified": 1698655115.306036,
                        "name": "/dev/shm/incoming/2023_10_30_083816_00_00_V_044.kml",
                        "sha256": "e2e86fa3005c3d82c5e2b8ebedf50ff199e531ba279a238cdfe602c2e8715837",
                        "size": 976
                    },
                    {
                        "created": 1698655115.376036,
                        "modified": 1698655115.376036,
                        "name": "/dev/shm/incoming/2023_10_30_083816_00_00_V_044.jpg",
                        "sha256": "62ce1e57539d233c5defa32088b4a12886a5e5a583e51a75921bd2d7c4562ac7",
                        "size": 828436
                    },
                    {
                        "created": 1698655097.316036,
                        "modified": 1698655097.316036,
                        "name": "/dev/shm/incoming/2023-10-30T08:28:11.ais",
                        "sha256": "ef1c6a56a7614328cedf701898a7fd44ff9e0f53c6a53aed32c7023fa7f9d7ba",
                        "size": 92501
                    },
                    {
                        "created": 1698655085.636036,
                        "modified": 1698655085.636036,
                        "name": "/dev/shm/incoming/2023_10_30_083730_00_00_V_043.txt",
                        "sha256": "fede4417cbf956877be5b03062af7a77a771f480d8b1b0d804576c64c6039a77",
                        "size": 2028
                    },
                    {
                        "created": 1698655085.436036,
                        "modified": 1698655085.436036,
                        "name": "/dev/shm/incoming/2023_10_30_083730_00_00_V_043.kml",
                        "sha256": "4d5f1fd1e339406e9cf6a4ebb29acce4ec7c50b24c5838452af2d11c74a9d569",
                        "size": 977
                    },
                    {
                        "created": 1698655064.416036,
                        "modified": 1698655064.416036,
                        "name": "/dev/shm/incoming/2023_10_30_083730_00_00_V_043.jpg",
                        "sha256": "b3535c213334208b35989f996f2be1e30a4eeb1b4873e9427cc17c933bcb579a",
                        "size": 906772
                    },
                    {
                        "created": 1698655051.746036,
                        "modified": 1698655051.746036,
                        "name": "/dev/shm/incoming/2023_10_30_083716_00_00_V_042.txt",
                        "sha256": "229ce22c2d99cf745f5a43117f7c24dfba9d2b4fd87ccca712df10322178afb8",
                        "size": 2034
                    },
                    {
                        "created": 1698655060.956036,
                        "modified": 1698655060.956036,
                        "name": "/dev/shm/incoming/2023_10_30_083716_00_00_V_042.kml",
                        "sha256": "376433ebc4b87947fb4c0497617a0f36b5af534eb837cc28bb840e125c2ac4aa",
                        "size": 978
                    },
                    {
                        "created": 1698655055.556036,
                        "modified": 1698655055.556036,
                        "name": "/dev/shm/incoming/2023_10_30_083716_00_00_V_042.jpg",
                        "sha256": "25fe4b1ff4c94efac8dd2d9a994b5898b37323d974f68a660be5b14d5ce11206",
                        "size": 844234
                    },
                    {
                        "created": 1698655042.376036,
                        "modified": 1698655042.376036,
                        "name": "/dev/shm/incoming/2023_10_30_083705_00_00_V_041.txt",
                        "sha256": "d2287be3181e5d083aee41b7c896cd9651847b849ed8c15e31e6957ec1adb231",
                        "size": 2035
                    },
                    {
                        "created": 1698655050.176036,
                        "modified": 1698655050.176036,
                        "name": "/dev/shm/incoming/2023_10_30_083705_00_00_V_041.kml",
                        "sha256": "4c7c407416ec67d7aa456752ac96f37f5ffea57ac54fe5e384874c7f3a95298e",
                        "size": 980
                    },
                    {
                        "created": 1698655049.296036,
                        "modified": 1698655049.296036,
                        "name": "/dev/shm/incoming/2023_10_30_083705_00_00_V_041.jpg",
                        "sha256": "0de66893a8a23ba9ec1e09f7d2d0cc6c15def7ae0a595dbb43c275386c2a10ba",
                        "size": 1023244
                    },
                    {
                        "created": 1698654968.806036,
                        "modified": 1698654968.806036,
                        "name": "/dev/shm/incoming/2023_10_30_083532_00_00_V_040.txt",
                        "sha256": "15ef2330e98d1c503647a35dc957de847caebf6bca2a45c42535ad532ded82b2",
                        "size": 2035
                    },
                    {
                        "created": 1698654957.996036,
                        "modified": 1698654957.996036,
                        "name": "/dev/shm/incoming/2023_10_30_083532_00_00_V_040.kml",
                        "sha256": "e856157e7ccf80576ebfafb8d0166bab48683c0dedf00e4c455edfb5c3c00d40",
                        "size": 977
                    },
                    {
                        "created": 1698654967.316036,
                        "modified": 1698654967.316036,
                        "name": "/dev/shm/incoming/2023_10_30_083532_00_00_V_040.jpg",
                        "sha256": "d09aec0e75dd9c9c735386190d3c30962355ffa0d40ab7a0d713e6b7488f339b",
                        "size": 930790
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "054f422141f1e69e6c46ae5d1c9c31d5519b24458d09bc6c0325e454954ba0bf",
                "size": 5488354
            },
            "errors": "",
            "filename": "2023-10-30T08-40-56Z",
            "size": 5488354,
            "verified_at": 1698666755
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698655309,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "QBbOayw/AQCXZcE6KxtiI0+dCC0dwiEHD2A1Y5LOlQpVjYeE3MBSrHXfE4c0NHOIIGYbGF+r7r5n8xGUkM422dGsWZ0XxOT0FSfmzlQy7E0lIRBzhFJsYGI8FJnZkL3cHxj1gdJMPoDnTqgf2F0q6O8LGpHP15FkLkmruHtgttCVZKLTGCjQw1aUmGlVgNWUwy+z9TeFI+AF5dI4WU8sOCkIX+hv2pz4IqNuPvIynjAdv2Byd02Q1H1g9JEiFMnoQOg0Dht7VJ34uP1elnAfAuFIo1cVG4kZN7YwXUc6QHjBo553K5FBOl7NfPnkHLbLKCS0dKIh2wJcmsRDicXDS6ML6HIcElzKW6XLd43rrqVrNHJyI/Y5d4j8CfdjI5pK6lAUxg3dlvoaTCnoOfSzWnwxRLtgXVbh39+mHkXyvl73+ObKDnuiSyuP960uaK1nhhLZJQoh0c7l/wcFp8/4BqfSpPVO9qwC5aD5+a6wZhg8vQmpDGMszKpTG8jX7fqu2oqJG2HOZuyOi54rTGpfJ4vHRbAE/Mw/Tr0r1VvYwuboXO4VOqiUAiq1JeY3VGjMlGO0iLDANhn+rKxomSzbFG1RI0ElH06HN0waQvCXcigkpeUs96f1YEz8Qa7jGEuSN+e+E0nqS2SDg86/exwZ0BvIDSgLp14d9r46s6ZYXhU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-41-12Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698655261.876036,
                        "modified": 1698655261.876036,
                        "name": "/dev/shm/incoming/2023-10-30T08:32:32.ts",
                        "sha256": "f26c4d026cc3ef4999ac2451666340cb2b2089c9533715e0b80f819b5d564e3a",
                        "size": 113576064
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "77365b54ac1c302540e4ed180a3559c524222983d14a3ede40ad64a61685fff3",
                "size": 109489407
            },
            "errors": "",
            "filename": "2023-10-30T08-41-12Z",
            "size": 109489407,
            "verified_at": 1698666844
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698655096,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "NgnqsqtVvURIGtNHAGElC8ZhnPq9ZuX1GM+6taMUCC/8sEbllvoEwAjbNSmneU2KBtEvytx+t4nGSYGs11IalgHdYE/qMQ024y/kLqvIhNQL11R+YGJpB4lSoX2DRSdOU+eDt8i5/SnTJKjD5dHoQC5qHZIwh+XKsIg2CbC6KQ2C4QvATlJxZB7oUpPKQwPEbCR/Tt3rpNmlvGRoab7KYnyXVtYj1Hdn0kifN0mt4eX9OpIaEu0HcN60F8dmStbvk6DkeAF708lISMJj3mhi46e8WngifjwLRISnYWZ81Ya9a7AHnMivTsIKP12jYVmOZXrqkNRPIFI8lZzSdFRQ/8lG5fX+ecs9F/VSFy7KUtZ1mtv2sW+zzmh7HQMXNbdZ1UrmVWMBtPYffOoQcdy0HQtSRbvp1VisxqARm2Y2KQwO+mNu4+OcyWivuyzC963P5vg4bv15KIPnBqsoBid1AGPMbDxIHz0lp6v95AeURvw1BNOyVkV7W2/n6hUspM406ZTRlgUAsG3A4su1LibXdvM6qVntYaPRHaHwvWEKlzWp/sWCmiinoe9DGS+lWxtXdtZ1A/NPfZc0GqG9FRm5OiT241tTPAhU7wJwpjx7HLO57i1t0Xh5+cb2e+/r0vvgZwjlY2gdu3o8wE8i7amf1zvWfpdNTzGTkYiLAXvu9rQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-36-21Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698654908.0779407,
                        "modified": 1698654908.0779407,
                        "name": "/dev/shm/incoming/2023-10-30T08:25:05.ais",
                        "sha256": "c5439c250f1b229e62a9dc8428e4558590b9e90f1b7e9450b830fdad10b16234",
                        "size": 306402
                    },
                    {
                        "created": 1698654809.8179407,
                        "modified": 1698654809.8179407,
                        "name": "/dev/shm/incoming/2023_10_30_083308_00_00_K_017.txt",
                        "sha256": "813038a01e0a185fa694215704dae616405f03fb4afbfe39cebaf2e2dbb07a4b",
                        "size": 2044
                    },
                    {
                        "created": 1698654819.8479407,
                        "modified": 1698654819.8479407,
                        "name": "/dev/shm/incoming/2023_10_30_083308_00_00_K_017.kml",
                        "sha256": "9a9758e20617b26a268a44331608434af60653b1d19d6c9e1ebe8e01d79815a7",
                        "size": 977
                    },
                    {
                        "created": 1698654819.6979406,
                        "modified": 1698654819.6979406,
                        "name": "/dev/shm/incoming/2023_10_30_083308_00_00_K_017.jpg",
                        "sha256": "74f24d87260fbc9c9b249d80f17ed6cfa23ee6d3e49a013e03c3886406773e1e",
                        "size": 901157
                    },
                    {
                        "created": 1698654772.4379406,
                        "modified": 1698654772.4379406,
                        "name": "/dev/shm/incoming/2023_10_30_083224_00_00_K_016.txt",
                        "sha256": "7f9e4e3e81d946efad30ad487b998833bd15f85ad313b4a18f1946679bd8e8c0",
                        "size": 2046
                    },
                    {
                        "created": 1698654770.8579407,
                        "modified": 1698654770.8579407,
                        "name": "/dev/shm/incoming/2023_10_30_083224_00_00_K_016.kml",
                        "sha256": "12f5b1fdb818804fd1aea44791f232775c843b9a3451aeeede4e8d9a50d6f0db",
                        "size": 973
                    },
                    {
                        "created": 1698654762.9379406,
                        "modified": 1698654762.9379406,
                        "name": "/dev/shm/incoming/2023_10_30_083224_00_00_K_016.jpg",
                        "sha256": "5fa65eee2ee541c9372a088728b179a80e502112225d772a16b4e8ac8f3af934",
                        "size": 775431
                    },
                    {
                        "created": 1698654698.3879406,
                        "modified": 1698654698.3879406,
                        "name": "/dev/shm/incoming/2023_10_30_083105_00_00_K_015.txt",
                        "sha256": "57203138f4f325ebfda756fcb231398483e18834e19f76a770aac2ead15de6c2",
                        "size": 2046
                    },
                    {
                        "created": 1698654696.9779406,
                        "modified": 1698654696.9779406,
                        "name": "/dev/shm/incoming/2023_10_30_083105_00_00_K_015.kml",
                        "sha256": "16f3cb3dce0a2224b893371b48f67d801a34977eceb578423becc445d2d48dfc",
                        "size": 976
                    },
                    {
                        "created": 1698654686.8079405,
                        "modified": 1698654686.8079405,
                        "name": "/dev/shm/incoming/2023_10_30_083105_00_00_K_015.jpg",
                        "sha256": "751e99dda2ed19f87dbc9f128667eb5a6a33c82ae4a1c01ee27190d71275603e",
                        "size": 1034525
                    },
                    {
                        "created": 1698654636.7779405,
                        "modified": 1698654636.7779405,
                        "name": "/dev/shm/incoming/2023_10_30_083018_00_00_K_014.txt",
                        "sha256": "5ab5791ba1f2eb7ac42c26afb506e287b1ca3051b2b3519bbb0593fe6c9a391d",
                        "size": 2045
                    },
                    {
                        "created": 1698654632.0379405,
                        "modified": 1698654632.0379405,
                        "name": "/dev/shm/incoming/2023_10_30_083018_00_00_K_014.kml",
                        "sha256": "3af0ca8a4406f36c3f369607992a5e5280b746c0ebf90c3369b1414278845262",
                        "size": 976
                    },
                    {
                        "created": 1698654641.3079405,
                        "modified": 1698654641.3079405,
                        "name": "/dev/shm/incoming/2023_10_30_083018_00_00_K_014.jpg",
                        "sha256": "fea5dd3d2f536f8f90525a853e32c41dffd2a08167d4981194147682b893ff76",
                        "size": 687528
                    },
                    {
                        "created": 1698654611.3179407,
                        "modified": 1698654611.3179407,
                        "name": "/dev/shm/incoming/2023_10_30_082951_00_00_K_013.txt",
                        "sha256": "a98fa04788ac5d02e0f727c5b0c2a4047d81c417ce9162f2c613c48df3e5423c",
                        "size": 2046
                    },
                    {
                        "created": 1698654618.4779406,
                        "modified": 1698654618.4779406,
                        "name": "/dev/shm/incoming/2023_10_30_082951_00_00_K_013.kml",
                        "sha256": "f03d1a93cc68237c85ed86223e5cf17e4c3bdcc36c3f11042e2c2b34a53f30c5",
                        "size": 977
                    },
                    {
                        "created": 1698654622.5879407,
                        "modified": 1698654622.5879407,
                        "name": "/dev/shm/incoming/2023_10_30_082951_00_00_K_013.jpg",
                        "sha256": "554a4f9ac491fc47640f383e76cbe8772bb0884d889818dbec7b12c78b022e96",
                        "size": 689052
                    },
                    {
                        "created": 1698654596.4379406,
                        "modified": 1698654596.4379406,
                        "name": "/dev/shm/incoming/2023_10_30_082922_00_00_K_012.txt",
                        "sha256": "8ff28ce3b3c4cff1fee856ded79a218c3e4dd9254c753831c51e702c9562840d",
                        "size": 2045
                    },
                    {
                        "created": 1698654594.9479406,
                        "modified": 1698654594.9479406,
                        "name": "/dev/shm/incoming/2023_10_30_082922_00_00_K_012.kml",
                        "sha256": "4a83cc559e9aa19a7ba79b5ca419276fc1ba6e3adcf29fe8d83982a5a71f2ad4",
                        "size": 980
                    },
                    {
                        "created": 1698654593.7779405,
                        "modified": 1698654593.7779405,
                        "name": "/dev/shm/incoming/2023_10_30_082922_00_00_K_012.jpg",
                        "sha256": "75a837a828cc89a1964a164e255f28564b0670af008962e96647e9a15ab564d6",
                        "size": 653801
                    },
                    {
                        "created": 1698654565.9079406,
                        "modified": 1698654565.9079406,
                        "name": "/dev/shm/incoming/2023_10_30_082910_00_00_K_011.txt",
                        "sha256": "5684a2431b90920cea1a5794e19447a82f0933b2a6fabcf11fbfaf708f039107",
                        "size": 2046
                    },
                    {
                        "created": 1698654565.7979405,
                        "modified": 1698654565.7979405,
                        "name": "/dev/shm/incoming/2023_10_30_082910_00_00_K_011.kml",
                        "sha256": "23a8761ff6cbcde2dfa87bfb1b1d31a8e08c10ae727f4fe61e30e03a6f45e318",
                        "size": 978
                    },
                    {
                        "created": 1698654567.2379405,
                        "modified": 1698654567.2379405,
                        "name": "/dev/shm/incoming/2023_10_30_082910_00_00_K_011.jpg",
                        "sha256": "f926bf0203a1eb3bc2e88ed6cb7bcd7708d28fc8b17ce21f43623e3dae22475e",
                        "size": 1179447
                    },
                    {
                        "created": 1698654551.7379405,
                        "modified": 1698654551.7379405,
                        "name": "/dev/shm/incoming/2023_10_30_082841_00_00_K_010.txt",
                        "sha256": "ffac3aa4985493b6cdd7a246599065a0904980c784ab8205fb7b44bf48095da2",
                        "size": 2046
                    },
                    {
                        "created": 1698654550.3579407,
                        "modified": 1698654550.3579407,
                        "name": "/dev/shm/incoming/2023_10_30_082841_00_00_K_010.kml",
                        "sha256": "3c49f95bd3c7361db047b1b07bfc6b61b4745342114d20820de341cae7d1ed0a",
                        "size": 975
                    },
                    {
                        "created": 1698654540.6779406,
                        "modified": 1698654540.6779406,
                        "name": "/dev/shm/incoming/2023_10_30_082841_00_00_K_010.jpg",
                        "sha256": "014fbdc6985366dcd1a007fe667489bfa7edf54fda0eb672f0b972260aaa8ee4",
                        "size": 1205913
                    },
                    {
                        "created": 1698654538.0179405,
                        "modified": 1698654538.0179405,
                        "name": "/dev/shm/incoming/2023_10_30_082832_00_00_K_009.txt",
                        "sha256": "7cb0117760024d7aab6c7e0cccfe71459b7c871d250a92cd6d5c78fd8de5ab3a",
                        "size": 2046
                    },
                    {
                        "created": 1698654537.8179407,
                        "modified": 1698654537.8179407,
                        "name": "/dev/shm/incoming/2023_10_30_082832_00_00_K_009.kml",
                        "sha256": "e89175c8fceb623f92057e90f61609f476468bd4629f6db98a3a591fe5a674e1",
                        "size": 979
                    },
                    {
                        "created": 1698654527.9079406,
                        "modified": 1698654527.9079406,
                        "name": "/dev/shm/incoming/2023_10_30_082832_00_00_K_009.jpg",
                        "sha256": "1b079a03418c2ed54afbb20f8d35671b3f576460a0020554533915555549b565",
                        "size": 1134540
                    },
                    {
                        "created": 1698654471.0479405,
                        "modified": 1698654471.0479405,
                        "name": "/dev/shm/incoming/2023_10_30_082725_00_00_K_008.txt",
                        "sha256": "08134b9ec6d9e516bd76ce6a8807c85f20bc9238d6b692b56155bd1b6e9f3a61",
                        "size": 2046
                    },
                    {
                        "created": 1698654462.2879405,
                        "modified": 1698654462.2879405,
                        "name": "/dev/shm/incoming/2023_10_30_082725_00_00_K_008.kml",
                        "sha256": "f422127ee727bb04df959796c5e02d667254b40bf764890a085c4eab21d318fd",
                        "size": 978
                    },
                    {
                        "created": 1698654462.0879407,
                        "modified": 1698654462.0879407,
                        "name": "/dev/shm/incoming/2023_10_30_082725_00_00_K_008.jpg",
                        "sha256": "b5d5f6d1805cb1fcb7ce373badba2bf706b394c49758acf16df9f751129fef85",
                        "size": 1140772
                    },
                    {
                        "created": 1698654418.6379406,
                        "modified": 1698654418.6379406,
                        "name": "/dev/shm/incoming/2023_10_30_082639_00_00_K_007.txt",
                        "sha256": "e43b95ef3c9f8dfb8c5109d8d1f9eb848a9cc75576b8c23821e7d2ca961a6149",
                        "size": 2044
                    },
                    {
                        "created": 1698654426.8179407,
                        "modified": 1698654426.8179407,
                        "name": "/dev/shm/incoming/2023_10_30_082639_00_00_K_007.kml",
                        "sha256": "ab94b4757a04d16dffc5fff8b146ebeaf99b8701810a01d367b9026bb0835834",
                        "size": 977
                    },
                    {
                        "created": 1698654423.2679405,
                        "modified": 1698654423.2679405,
                        "name": "/dev/shm/incoming/2023_10_30_082639_00_00_K_007.jpg",
                        "sha256": "bb14cfd9e4fd520ff649ca73a40f859e4dcaffc4ccd0bb774be75d53a1e29e1c",
                        "size": 991032
                    },
                    {
                        "created": 1698654384.8079405,
                        "modified": 1698654384.8079405,
                        "name": "/dev/shm/incoming/2023_10_30_082601_00_00_K_006.txt",
                        "sha256": "91b34c4c8c46ff65f91576dc72fcdf1f3b805bf9f87f272ee0a697f0633920eb",
                        "size": 2046
                    },
                    {
                        "created": 1698654389.5579405,
                        "modified": 1698654389.5579405,
                        "name": "/dev/shm/incoming/2023_10_30_082601_00_00_K_006.kml",
                        "sha256": "355bb7dbbb59449bb82f05462074e0d82f85f2865f9f6abf85e38ffdd34cd161",
                        "size": 978
                    },
                    {
                        "created": 1698654394.6079407,
                        "modified": 1698654394.6079407,
                        "name": "/dev/shm/incoming/2023_10_30_082601_00_00_K_006.jpg",
                        "sha256": "f68e9ef372099febd6b5de5d64c5b5e38f16c41d1269ea59232564c6837c4d80",
                        "size": 1140653
                    },
                    {
                        "created": 1698654368.4879405,
                        "modified": 1698654368.4879405,
                        "name": "/dev/shm/incoming/2023_10_30_082524_00_00_K_005.txt",
                        "sha256": "85517c35bcabcf5182f48583c29216e02496a20e02c3446c8064b8d893a0cb68",
                        "size": 2044
                    },
                    {
                        "created": 1698654358.8579407,
                        "modified": 1698654358.8579407,
                        "name": "/dev/shm/incoming/2023_10_30_082524_00_00_K_005.kml",
                        "sha256": "8350066c455fcfa8d31245835c06e0091e300bf9b48c7f7377460ded8f487cfb",
                        "size": 980
                    },
                    {
                        "created": 1698654359.3979406,
                        "modified": 1698654359.3979406,
                        "name": "/dev/shm/incoming/2023_10_30_082524_00_00_K_005.jpg",
                        "sha256": "fbeb683b84f1eb208bc685ec809e01ae4142028f02c3d57a64fb4f9af1cf473d",
                        "size": 1154996
                    },
                    {
                        "created": 1698654347.5479405,
                        "modified": 1698654347.5479405,
                        "name": "/dev/shm/incoming/2023_10_30_082515_00_00_K_004.txt",
                        "sha256": "c2635dddaf1e8284ec3d33d3f69bc425a6a51b89f4cb284ba94de4c8688f91fd",
                        "size": 2045
                    },
                    {
                        "created": 1698654343.1379406,
                        "modified": 1698654343.1379406,
                        "name": "/dev/shm/incoming/2023_10_30_082515_00_00_K_004.kml",
                        "sha256": "0295846c20912161c1f7e08216ed88174e2c815f802f5576b317b175db2b8dfc",
                        "size": 976
                    },
                    {
                        "created": 1698654348.2679405,
                        "modified": 1698654348.2679405,
                        "name": "/dev/shm/incoming/2023_10_30_082515_00_00_K_004.jpg",
                        "sha256": "72bce1237d82e2dfb9c6890c20fee120c5857431e65724023e02f99311e87e39",
                        "size": 816696
                    },
                    {
                        "created": 1698654305.3479407,
                        "modified": 1698654305.3479407,
                        "name": "/dev/shm/incoming/2023-10-30T08:15:04.ais",
                        "sha256": "ff9102a35418138a6d8b27632df8795fb7a93145c14b9af7c625b8bb6a72ee09",
                        "size": 367531
                    },
                    {
                        "created": 1698654264.4579406,
                        "modified": 1698654264.4579406,
                        "name": "/dev/shm/incoming/2023_10_30_082359_00_00_K_003.txt",
                        "sha256": "755234b44cf560f6bab58c05232a7dbe8321d748dc64ada4a2a40da8a52b0ab4",
                        "size": 2046
                    },
                    {
                        "created": 1698654265.1679406,
                        "modified": 1698654265.1679406,
                        "name": "/dev/shm/incoming/2023_10_30_082359_00_00_K_003.kml",
                        "sha256": "24d85705992f23e351dfc01d5bea867698b4ec5f2e5a7c5b786a3eb7f2f8b13f",
                        "size": 977
                    },
                    {
                        "created": 1698654259.1679406,
                        "modified": 1698654259.1679406,
                        "name": "/dev/shm/incoming/2023_10_30_082359_00_00_K_003.jpg",
                        "sha256": "a23e87069c7f010a83790a8bd16bc48590666ee023563098870b98c8924d0f53",
                        "size": 1111323
                    },
                    {
                        "created": 1698654258.1279407,
                        "modified": 1698654258.1279407,
                        "name": "/dev/shm/incoming/2023_10_30_082250_00_00_K_002.txt",
                        "sha256": "f06778be6d78dee6b73030d106416efc6f233525bfa12bc26ef8fd48a4649493",
                        "size": 2048
                    },
                    {
                        "created": 1698654198.7279406,
                        "modified": 1698654198.7279406,
                        "name": "/dev/shm/incoming/2023_10_30_082250_00_00_K_002.kml",
                        "sha256": "59971e3c274e9a4b45b53f9ec9b7848a194adb81f6cbef385c601101ae515dd4",
                        "size": 977
                    },
                    {
                        "created": 1698654208.2079406,
                        "modified": 1698654208.2079406,
                        "name": "/dev/shm/incoming/2023_10_30_082250_00_00_K_002.jpg",
                        "sha256": "51e9f886dbd32f6fbe2eb5b1146d959ac71808c5ec38c2147225ed777ca18f04",
                        "size": 1109253
                    },
                    {
                        "created": 1698654974.4679406,
                        "modified": 1698654974.4679406,
                        "name": "/dev/shm/incoming/2023-10-30T08:06:14.ts",
                        "sha256": "75ae825e957ffaf593189a8d074247aaee0290f5a37e9fc6eae1caf86c61853c",
                        "size": 440973364
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "e2db627c9edfada6198ec71c314d4c1fb89ce62a69e315ee3fe0b8ef38434c32",
                "size": 414209280
            },
            "errors": "",
            "filename": "2023-10-30T08-36-21Z",
            "size": 414209280,
            "verified_at": 1698683113
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698655232,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "UL1SNFsdXhjERXfwNATBcG0RshDuu9a+0x/iA/aJMvj+ay8I+sruCqjM0ArWEeEvfGUg8zxPJoHFFwAc3S9peSSt5cVCT8VvsfEMS4W2FycD5z5KqFUKH7CYWov6iSVJlVZ9vYntuv/s4HEMjRh7hbvEewCFtAEfvroLSBbztpY//qkiu0NukTqUTIvUiUS/OjkeUYaWA3rfGgzs6x8Sdtf33S4Dd159vIoSbW9cfejgxznkQoa9nEEeoQ1x1zYlFxlrUnw77QC+JQjRwOuhYkPXw3Pg+sMXTSzm1klZJFGAduFNZyZtyAi4HhOfnwGtMqG1iMAODsK1h2a9vM7ya9i2ahAQz/DK0cKrAcTkPtMuQPwrzNJQ0Qi8+eJnqrAhVnooTacPOIxVSRq1TsKAAmqsvzERb5RpCiFG+c/4h7heFdHIyuKq4+oRY97NhC5DdbMrOpi4ri/+EiG6FcsWgfdbaw3NeSgjGt2m1NwnZkmhPTPYAnDCQOnpMtMgQe+QOdzYEJ5GPS36rpmBaG7sZVflGGCRowOTZIXGjP+ePXg8bp38V7mbslSLg6P7W7xPh6WgqVv36WdDG+eX/jggSseALWBXtewf1edR3k9/UMoRKQJ+f55QMLdMpPxlOt0BOsNt0fxehpR1f3iyr8qtExTfxaJyhM4bmN6jaypatHE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-40-10Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698655087.0278287,
                        "modified": 1698655087.0278287,
                        "name": "/dev/shm/incoming/2023-10-30T08:28:05.ais",
                        "sha256": "4d7c8fbc030a49c98d8611c2b84cc356c0eef1437eed0881ebb35e7c7710b77b",
                        "size": 676962
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "fb5b4d283366ff0c63009f46a7f55648e56ae342d75752d8d1f4a8ba748ea7d4",
                "size": 198201
            },
            "errors": "",
            "filename": "2023-10-30T08-40-10Z",
            "size": 198201,
            "verified_at": 1698693389
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698654636,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "TAmN7PtIdr9AKyXJbizzKUG1GcTxgl+CsMrkW9A0cXtCcX3z2QkocGpg/9MgwaixvUbSBoHbR9kG3878c0R6htsoM+/OQ+Z9QHtP887JAdLdp2Z2gwJsKlsuNytdfJ8i2WXbjDRLLHacSnusujewrmInPYKf3hG/SCAc7pqMN5sjX8L9eIk6KocU+q4SNpxfobmdZwaoAX75WYSNgjI+8+lkrcdH/lk2SZ0++srzFI6Vtbm48Z3ZirPa1LIpDdQ3HSRARGOiXQJYlVUPA2mNJQ8DAyShnnKx1uS9A0LAe2IV5lBQMYTHuaCrn6e68Fb1u53Yyn3TtioAOpLolXWKsXRppqISxOZnAmZn4uPTPiF6Yc79k22BIj+DWSxARxMvDk7NpEwHbdU2aUp/u6uMrLcNT8OInZEbkJhRp74nlq3E/DGyC6SORXT7AjZPq9GXGcuo15wu9V3j8KuCSnLlwcFJ+8vcKgXl3o3AxCpJoW7ccE/PdaIFF8nfqAq2Rh+KADEvGwaQQnE0akgyxdq8ooD0bkegGl+YxLJ3+MZVcQFNDg9XbROJT+1C8vKiha4mwXfJKX5Wt9slTpvZ5I3AEgis/FCXmvZB2eRxigokTKNTW8QjzAm/n2DSnW7+ZHqi5QmxoE2yZSiQBfF4xdUP+ltkvgt0ZefZk/Nkvij/YuA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-30-12Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698654485.4878287,
                        "modified": 1698654485.4878287,
                        "name": "/dev/shm/incoming/2023-10-30T08:18:04.ais",
                        "sha256": "752ec6101f81388850fd00e2a07a36fb5b7852b2b6f3de4ef73a97e894cca0b3",
                        "size": 637752
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "5b67f27e4315f2b8d35239073bafe6dab61318c97772ef66aa5083b3d7044102",
                "size": 188604
            },
            "errors": "",
            "filename": "2023-10-30T08-30-12Z",
            "size": 188604,
            "verified_at": 1698693481
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698740278,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "BT4b+4qvYfj6aPf430dXh/tx8pJZnWr4oZBhwqt1nm44XlpjiBUeEEZCTtOe+9aZkGZFya4RpQXfh0HdoP+sQVAZXUtP0s2cb9Hb2DLEnuQCFGyAAIvTWb9JXqEneOL7Gr66P0eVhz1gmMwCcFvqvggp6VmoJ/IKKCLdI5jB2raKVhxKEcjAHPZ+msXo+yRimKQGD7YqtaUPI/nL1uY3hcoTp9xrWlXHuFI3OuTAeuVdkYgzpF4QOU3mISwY5U7nL43z1Tv4QDhg7h1r4Y4Sh0jMcb+c/OmB+4rmdavx10OQyalmkTL4Q1N19/euUxlWPx3gfI1yo++rPekwW2EYIm21bg0a1/S5jITco1Xc68kAl0TivRFotv0nOVJl0UHyVakWj4/Z2+PEYyAlPm6v75pycZWsBoq+BHcNw3rS0UW1mgM2PhzVa8UYOYMQt+O/9hHES2oE3904TNUcrDsiGCP9xl2P6W9hOgMZMEv59vLJvueMB03zaIleDcE+RUyiei/WLYmIhVU3YfyefYB5p1KOq3hrju3INuY7Nf0gT01dvHfce2wXjDwJjOAPrSNTYiF5P/h7B72b+AWjhH9PfcrhALkk4Ly9TGKKLtDqlONLBtB5StZv2Ij5u7G8EJaEwwTQN197OxJdIFaIUMpuM5KRUaJ7r3J3AtA2K94auec=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T08-17-38Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698740141.50126,
                        "modified": 1698740141.50126,
                        "name": "/dev/shm/incoming/2023_10_31_081515_00_00_K_030.txt",
                        "sha256": "30e1e50c780d1b1de94e4f414e9555051a1c4db51036d9f04dcab960a2114b27",
                        "size": 2047
                    },
                    {
                        "created": 1698740137.04126,
                        "modified": 1698740137.04126,
                        "name": "/dev/shm/incoming/2023_10_31_081515_00_00_K_030.kml",
                        "sha256": "8a9dc132326c6424206d99bc620e5c01f87a8bd997ce0bdfafce639646b0eadd",
                        "size": 977
                    },
                    {
                        "created": 1698740132.36126,
                        "modified": 1698740132.36126,
                        "name": "/dev/shm/incoming/2023_10_31_081515_00_00_K_030.jpg",
                        "sha256": "fea192ce5ec47a6380d635c77c64618c9e7b9d2fcbd8729447c45db6ad45ddd1",
                        "size": 820116
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "130129d80a4140f9fcf35f5a54fa9d577002b7eb96adf46b7e26d1a5de1867f0",
                "size": 814528
            },
            "errors": "",
            "filename": "2023-10-31T08-17-38Z",
            "size": 814528,
            "verified_at": 1698766997
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698656462,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "RYfk//XAcS/h0dIiCrBRKsGg9Pv7x8Nh9H9TWtl7cbBsNqemq5szprg6lXvzs/NF/PXx2uxRKMc5PNdDuk1Gj0N35oXZTQsUQU7FogFQytY++iv5+18XIsJtUI9qK9+fO+EN5LEXYXvnr4RU83Z9ouxSvhbN5U3oRywMaUIH3ku6dOPnhkFL84e1Z9ZJWCxqDB6hmT1JBINwrF5bjq2XtNd5lSlVl7y4ZLoTtWZKiOc9Fv8pjkUtEu2UzHHXX7p+00qCA6qD8ns30Hdzt/gTQtAMP5gwpId3D1xdL7fCD8pkZIkYX/xKDFHwvkm0N2No/V4AUc0TJ5pryFGEpScfYLvG1XVgQ77me1I9AXFb5GSMtsh0JxC0Kpv4WSKdhb9giI4gCFuXCFt2wpxzysp4fL007c7O7xqYBcvil7eEw2BjCOk1QhXit6aRkKTuM/3z5yGWoyt35Zglbb0XAd7RvMQhnFsEGEpFNi9M95aZGNSSaRMV0NLG4KyFixlz30/BtmixBpvARBBPb+exF8Bd9RVQESX/4GuJCzazBWHxHz17gMbKp8HjSNGIUf+z8ecHZQCm6e0+jdOLq9oX+jDkJRT3FMUSwYNKHaaCOcV1LQSe3mycsboKJj5xkKSl6KOfvvhLrws6xKUE9bxhzfTnrPyfTkwab5wzMgfe4yyzgSc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-00-30Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698656305.676036,
                        "modified": 1698656305.676036,
                        "name": "/dev/shm/incoming/2023-10-30T08:48:21.ais",
                        "sha256": "2c074f2c9fb9dc7c49007b6e21b4e9e35eb30d016b777540ef651810745b2fdd",
                        "size": 91006
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "616f743b15292ca8019f14ccea163cbc148464cb5a60f14de5b8c7cbc846ce66",
                "size": 24885
            },
            "errors": "",
            "filename": "2023-10-30T09-00-30Z",
            "size": 24885,
            "verified_at": 1698666850
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698656260,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "p6uNiE1AoRoOBB2tAl219XwEtYnsOkjltVkuCTbiUSRlgR2yTuQvtMSOaUeOleKUfdXg6lBmb1APcPB7iWfPcycKvpDcU4/w8gY6GKkP9wyealclouPL0TTiHdgxAdvrsaaVd1Xyh4E2UbKUNKpYyvJyHYEzww26rpKc0aPqYxe3DzaiOG2vZxqEnY9TPysY3t/aX/p/wu7fBrPWrSUqineDX1se9n/q2Bm/rkM6iRi1/Mm6dLL+6vfWDRDh+3bkw1LhL/Z1oEKm/Nrx600VcLZ00m6rbetHSal91rfpFWqFJvNKeyYetSGUMrgvPJRKJn7y/WJSGnxo7d+gXLDawoksvfsgR3bQ/XlXJXTO8c+nOJTnv6OREn3HdRdpganuSTHxO8/9T9jCQQSAjrV0Z6LgC7xj1WlXds+mOTQ9rFaiQ9fgvydJ+oVdwb0ZRhqCqWYDXA0xI2YQcCoxnSvD/kRTSVwFFLyw55PORJK1huJ+QRc930YMGkTd9F2rdfeNKkKnthSdi3I0x+W5k1MZvBJf3TZmkFrq/9oxK7oNuNJtF86QicbXts9S0YA7kFNhDeZMOZuCbLDOkqZB0HPyqRpNhz/uC6YF1HsqYpFNdAJ38rsefGfH3J4OPuU5rpbay835yVrCsf+ZfxYcjF9b8twxLN1hucnjtCDQQsB8bN8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-57-20Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698656112.0079405,
                        "modified": 1698656112.0079405,
                        "name": "/dev/shm/incoming/2023-10-30T08:45:11.ais",
                        "sha256": "2a5a8b36b6a14f45df4d885b4b9135706b9d0c58969e24907ffb5f14d6460218",
                        "size": 241024
                    },
                    {
                        "created": 1698656058.7779405,
                        "modified": 1698656058.7779405,
                        "name": "/dev/shm/incoming/2023_10_30_085355_00_00_K_036.txt",
                        "sha256": "f0f7e487a8a0ea8ace6895e08c6462cb37305b4b6891e3d224ce0b7b62a5ce1d",
                        "size": 2043
                    },
                    {
                        "created": 1698656061.2579405,
                        "modified": 1698656061.2579405,
                        "name": "/dev/shm/incoming/2023_10_30_085355_00_00_K_036.kml",
                        "sha256": "e756aeca10242b3c1c745eaee0c4efd08cf5d4d3d306ad0bcf7991c90d1d17d7",
                        "size": 977
                    },
                    {
                        "created": 1698656059.0779407,
                        "modified": 1698656059.0779407,
                        "name": "/dev/shm/incoming/2023_10_30_085355_00_00_K_036.jpg",
                        "sha256": "ea53264f33a80174d6c32e0ec790e013482856b58f1a7c24a82e0bd3d3c8e6cc",
                        "size": 794313
                    },
                    {
                        "created": 1698656085.8379407,
                        "modified": 1698656085.8379407,
                        "name": "/dev/shm/incoming/2023_10_30_085336_00_00_K_035.txt",
                        "sha256": "cbfe91dc0c23c27a332cad801d54424d16eb1e1689bd79c89e5bc4635cb1fba7",
                        "size": 2043
                    },
                    {
                        "created": 1698656051.0179405,
                        "modified": 1698656051.0179405,
                        "name": "/dev/shm/incoming/2023_10_30_085336_00_00_K_035.kml",
                        "sha256": "3625651e7be05b2330bb9cba85a8db1186b46c6d1e96ce23c63fa0f771017a7d",
                        "size": 980
                    },
                    {
                        "created": 1698656040.9079406,
                        "modified": 1698656040.9079406,
                        "name": "/dev/shm/incoming/2023_10_30_085336_00_00_K_035.jpg",
                        "sha256": "0b7de065a81acd9bed17d352073181628f7c5ee28905daf1081bbd6da37f445e",
                        "size": 836113
                    },
                    {
                        "created": 1698656030.4379406,
                        "modified": 1698656030.4379406,
                        "name": "/dev/shm/incoming/2023_10_30_085332_00_00_K_034.txt",
                        "sha256": "c3a4af0cdf6eee01aec1ed7df0d488719c225fac8a49cef991a27a8689196bd7",
                        "size": 2043
                    },
                    {
                        "created": 1698656029.6779406,
                        "modified": 1698656029.6779406,
                        "name": "/dev/shm/incoming/2023_10_30_085332_00_00_K_034.kml",
                        "sha256": "14f9092a409f5d9e3561ff80b85edc0de968a7cd0c6a71f58336e1194c0d6db1",
                        "size": 978
                    },
                    {
                        "created": 1698656039.2079406,
                        "modified": 1698656039.2079406,
                        "name": "/dev/shm/incoming/2023_10_30_085332_00_00_K_034.jpg",
                        "sha256": "6e3228356b84086fb573276d2ad07cc0b976b27d927ac7d9794df64948abfc21",
                        "size": 818538
                    },
                    {
                        "created": 1698655993.6479406,
                        "modified": 1698655993.6479406,
                        "name": "/dev/shm/incoming/2023_10_30_085244_00_00_K_033.txt",
                        "sha256": "b1bc60751d2821b109d199efcb78e1ab882d54a850b587cece69d23009698235",
                        "size": 2043
                    },
                    {
                        "created": 1698655983.9079406,
                        "modified": 1698655983.9079406,
                        "name": "/dev/shm/incoming/2023_10_30_085244_00_00_K_033.kml",
                        "sha256": "4cf2bbd13428f253410ced541f5f7f5834e8f9bc29b398ce0105cf919bac0afd",
                        "size": 978
                    },
                    {
                        "created": 1698655983.0479405,
                        "modified": 1698655983.0479405,
                        "name": "/dev/shm/incoming/2023_10_30_085244_00_00_K_033.jpg",
                        "sha256": "0c64d1220c80e7bdff699e71a3f2f1b1d1f2007bac858c4bbd70fb8ab120be8c",
                        "size": 954322
                    },
                    {
                        "created": 1698655965.2779405,
                        "modified": 1698655965.2779405,
                        "name": "/dev/shm/incoming/2023_10_30_085222_00_00_K_032.txt",
                        "sha256": "33b535ac59fc8d56633a905f6749958b2e1a8479e12ffd79703f86ee4ccce1e5",
                        "size": 2042
                    },
                    {
                        "created": 1698655979.5579405,
                        "modified": 1698655979.5579405,
                        "name": "/dev/shm/incoming/2023_10_30_085222_00_00_K_032.kml",
                        "sha256": "0eb0d9ab74692bd2ee1dbf1a12c4284d6cee1e1b154bca9bdc9732f919aed91d",
                        "size": 978
                    },
                    {
                        "created": 1698655961.3279407,
                        "modified": 1698655961.3279407,
                        "name": "/dev/shm/incoming/2023_10_30_085222_00_00_K_032.jpg",
                        "sha256": "2bc6f0108c7f5a21d68eb03e0d8da124aa77dcd8cdcd6629b2e12f78a88f8373",
                        "size": 800081
                    },
                    {
                        "created": 1698655958.2179406,
                        "modified": 1698655958.2179406,
                        "name": "/dev/shm/incoming/2023_10_30_085217_00_00_K_031.txt",
                        "sha256": "b03c548ffc10929e90db7ef3d4575dd5b54d4e428d7d1af748440587124e3a66",
                        "size": 2043
                    },
                    {
                        "created": 1698655958.1279407,
                        "modified": 1698655958.1279407,
                        "name": "/dev/shm/incoming/2023_10_30_085217_00_00_K_031.kml",
                        "sha256": "04324826474f933da8db23f778a43470b81cd07e7462bd900e41c5a71c52da38",
                        "size": 976
                    },
                    {
                        "created": 1698655957.5779407,
                        "modified": 1698655957.5779407,
                        "name": "/dev/shm/incoming/2023_10_30_085217_00_00_K_031.jpg",
                        "sha256": "d653aed22304d64d530d4e29ae8221cc0e009d83468c76bfd8fa744e4fdba612",
                        "size": 826392
                    },
                    {
                        "created": 1698655942.2179406,
                        "modified": 1698655942.2179406,
                        "name": "/dev/shm/incoming/2023_10_30_085159_00_00_K_030.txt",
                        "sha256": "0c319c38addd0f6af7e34c0322c3e7a6804ca8d4f6b445f945d30dd04d6754ed",
                        "size": 2044
                    },
                    {
                        "created": 1698655937.6179407,
                        "modified": 1698655937.6179407,
                        "name": "/dev/shm/incoming/2023_10_30_085159_00_00_K_030.kml",
                        "sha256": "47d94e677439a6b31c4d72ae2fd1a1d9ba8e24a3b9e10704440fcf5c470cb217",
                        "size": 977
                    },
                    {
                        "created": 1698655941.6479406,
                        "modified": 1698655941.6479406,
                        "name": "/dev/shm/incoming/2023_10_30_085159_00_00_K_030.jpg",
                        "sha256": "ee59b58aff950cd4159e9b2446db6b264d64098a71d283c354c787a96164b431",
                        "size": 672065
                    },
                    {
                        "created": 1698655933.3879406,
                        "modified": 1698655933.3879406,
                        "name": "/dev/shm/incoming/2023_10_30_085151_00_00_K_029.txt",
                        "sha256": "0246ac7bfeb13bbb99536e061f499e4c534119455cace558caef27f7eaa078f8",
                        "size": 2043
                    },
                    {
                        "created": 1698655944.4579406,
                        "modified": 1698655944.4579406,
                        "name": "/dev/shm/incoming/2023_10_30_085151_00_00_K_029.kml",
                        "sha256": "ebb42eb2ef3d3c20e86beecd3469618e32aebddc344254657af015b10e6affa7",
                        "size": 978
                    },
                    {
                        "created": 1698655932.6279407,
                        "modified": 1698655932.6279407,
                        "name": "/dev/shm/incoming/2023_10_30_085151_00_00_K_029.jpg",
                        "sha256": "00fd1ee914d708f4edc203036a99790747e4109cff2b285044abeed5cfda390d",
                        "size": 667000
                    },
                    {
                        "created": 1698655872.3279407,
                        "modified": 1698655872.3279407,
                        "name": "/dev/shm/incoming/2023_10_30_085042_00_00_K_028.txt",
                        "sha256": "cc229bbec1f13c6e0de05a52008aa3bf50072f47a46f2a854a6e3ca6421ba41a",
                        "size": 2043
                    },
                    {
                        "created": 1698655871.0979407,
                        "modified": 1698655871.0979407,
                        "name": "/dev/shm/incoming/2023_10_30_085042_00_00_K_028.kml",
                        "sha256": "facc639552989dd02f741d38062dbc3a80851bfbe5613047dd14cd713000025b",
                        "size": 977
                    },
                    {
                        "created": 1698655886.8779407,
                        "modified": 1698655886.8779407,
                        "name": "/dev/shm/incoming/2023_10_30_085042_00_00_K_028.jpg",
                        "sha256": "37c41b05f2a971a11f63028fcd93eac59ae0cf824fe7c0635ac2f9ce3fc14aca",
                        "size": 946161
                    },
                    {
                        "created": 1698655875.8779407,
                        "modified": 1698655875.8779407,
                        "name": "/dev/shm/incoming/2023_10_30_085038_00_00_K_027.txt",
                        "sha256": "c2a7d146333612cf897b48db46ff9aa74b4e9ddd90b81fb0e3ca253c003a5a2d",
                        "size": 2043
                    },
                    {
                        "created": 1698655863.9879405,
                        "modified": 1698655863.9879405,
                        "name": "/dev/shm/incoming/2023_10_30_085038_00_00_K_027.kml",
                        "sha256": "54a32c8a7665559b8abfedcda63ba1b7786510113dde69b1fee7aff2f847c2c2",
                        "size": 975
                    },
                    {
                        "created": 1698655863.5479405,
                        "modified": 1698655863.5479405,
                        "name": "/dev/shm/incoming/2023_10_30_085038_00_00_K_027.jpg",
                        "sha256": "374a7640b4fbb36f6f296b18feb63c867559fed6f4023e8f90bcde0ef37b5ed8",
                        "size": 1004481
                    },
                    {
                        "created": 1698655857.2079406,
                        "modified": 1698655857.2079406,
                        "name": "/dev/shm/incoming/2023_10_30_085033_00_00_K_026.txt",
                        "sha256": "d646a69e0e38559d601f38133f2c0de7ccd80574c93a098d2e428e8422870acd",
                        "size": 2042
                    },
                    {
                        "created": 1698655854.7279406,
                        "modified": 1698655854.7279406,
                        "name": "/dev/shm/incoming/2023_10_30_085033_00_00_K_026.kml",
                        "sha256": "58e6d4f929113a8cc5a1da84f8af96f858bcc801516f322df6f8e86d14ef6dff",
                        "size": 978
                    },
                    {
                        "created": 1698655853.1279407,
                        "modified": 1698655853.1279407,
                        "name": "/dev/shm/incoming/2023_10_30_085033_00_00_K_026.jpg",
                        "sha256": "196aec69a0d57121dad52730044e340cfbbe62367feb306a6923e598741643d2",
                        "size": 1001626
                    },
                    {
                        "created": 1698655843.8479407,
                        "modified": 1698655843.8479407,
                        "name": "/dev/shm/incoming/2023_10_30_085017_00_00_K_025.txt",
                        "sha256": "9d6f2cec13b79715bc26a28f034ff7d9d74027212ef5d5c8c91b2c265bc6a936",
                        "size": 2043
                    },
                    {
                        "created": 1698655841.2579405,
                        "modified": 1698655841.2579405,
                        "name": "/dev/shm/incoming/2023_10_30_085017_00_00_K_025.kml",
                        "sha256": "92c40f44b46fc6e7b746020969d790fca0c5c0e47ce46221f1f67536d033e895",
                        "size": 978
                    },
                    {
                        "created": 1698655849.5979407,
                        "modified": 1698655849.5979407,
                        "name": "/dev/shm/incoming/2023_10_30_085017_00_00_K_025.jpg",
                        "sha256": "98999bac0a72607972362af3000c40c7b504aff45c73797033c2b33d65a70c0f",
                        "size": 977700
                    },
                    {
                        "created": 1698655805.8979406,
                        "modified": 1698655805.8979406,
                        "name": "/dev/shm/incoming/2023_10_30_084944_00_00_K_024.txt",
                        "sha256": "6404c0403f466a0e47d693c4fc3d5deb4d0078466e3576f1d34a897c928ebb41",
                        "size": 2042
                    },
                    {
                        "created": 1698655815.9979405,
                        "modified": 1698655815.9979405,
                        "name": "/dev/shm/incoming/2023_10_30_084944_00_00_K_024.kml",
                        "sha256": "53a6289fd99b58e8c28ee134d17a392da2e70a2d34181ebd9382c10e21349ae4",
                        "size": 975
                    },
                    {
                        "created": 1698655815.9079406,
                        "modified": 1698655815.9079406,
                        "name": "/dev/shm/incoming/2023_10_30_084944_00_00_K_024.jpg",
                        "sha256": "c5dfe0c97901c36d7cbb9fffb204c1d3914c07372e0592f50f04b64d8e46b2ed",
                        "size": 838168
                    },
                    {
                        "created": 1698655734.7679405,
                        "modified": 1698655734.7679405,
                        "name": "/dev/shm/incoming/2023_10_30_084831_00_00_K_023.txt",
                        "sha256": "7f7ef3a6a936bbf538e11460d4a941e2606043f0f6cd80de72a3604662f2c089",
                        "size": 2043
                    },
                    {
                        "created": 1698655734.5279405,
                        "modified": 1698655734.5279405,
                        "name": "/dev/shm/incoming/2023_10_30_084831_00_00_K_023.kml",
                        "sha256": "19cf82b3cc961ed59c071caaba8f23d831c856b0fd53deadf04f53ed547f6cf4",
                        "size": 975
                    },
                    {
                        "created": 1698655727.3279407,
                        "modified": 1698655727.3279407,
                        "name": "/dev/shm/incoming/2023_10_30_084831_00_00_K_023.jpg",
                        "sha256": "6e4a28c32e7497fdbfbf8c6637765c2a9c0af17840cfe7831c754e3b6b027965",
                        "size": 945190
                    },
                    {
                        "created": 1698655726.2779405,
                        "modified": 1698655726.2779405,
                        "name": "/dev/shm/incoming/2023_10_30_084812_00_00_K_022.txt",
                        "sha256": "401bfd85b2729917605a49df0105a39623f6e2f0cb53a41d223cb6ec42bcd689",
                        "size": 2040
                    },
                    {
                        "created": 1698655725.1479406,
                        "modified": 1698655725.1479406,
                        "name": "/dev/shm/incoming/2023_10_30_084812_00_00_K_022.kml",
                        "sha256": "803b9a6ccab1b8e96b17ee33ee853aa0018ff667cd94f9955e10eba91f974f5a",
                        "size": 977
                    },
                    {
                        "created": 1698655720.5279405,
                        "modified": 1698655720.5279405,
                        "name": "/dev/shm/incoming/2023_10_30_084812_00_00_K_022.jpg",
                        "sha256": "b3690e3a883b12d6f571ac49a7758b6e8a4cb58a29e52daaf70fc454c7618af7",
                        "size": 812664
                    },
                    {
                        "created": 1698655709.1879406,
                        "modified": 1698655709.1879406,
                        "name": "/dev/shm/incoming/2023_10_30_084803_00_00_K_021.txt",
                        "sha256": "b1213140c9e39b38d3b8e0e0c42ff692313ff19a7b4075b035214af18118184b",
                        "size": 2042
                    },
                    {
                        "created": 1698655702.5979407,
                        "modified": 1698655702.5979407,
                        "name": "/dev/shm/incoming/2023_10_30_084803_00_00_K_021.kml",
                        "sha256": "a5a2f50450ea9388d78d89495a4e0151b172b6dc519a26e66bb425e7f59d9384",
                        "size": 976
                    },
                    {
                        "created": 1698655707.2379405,
                        "modified": 1698655707.2379405,
                        "name": "/dev/shm/incoming/2023_10_30_084803_00_00_K_021.jpg",
                        "sha256": "aaf069ee7f6bd1ed61e57c01e3acb00d008d815125a1cd340d817799cec45d42",
                        "size": 941476
                    },
                    {
                        "created": 1698655582.6379406,
                        "modified": 1698655582.6379406,
                        "name": "/dev/shm/incoming/2023_10_30_084557_00_00_K_020.txt",
                        "sha256": "c5136cf3eccbe77a7e7fd9ca703605dd4bc05e2f31d8ba7242dc7d8f9ed3845d",
                        "size": 2035
                    },
                    {
                        "created": 1698655582.9379406,
                        "modified": 1698655582.9379406,
                        "name": "/dev/shm/incoming/2023_10_30_084557_00_00_K_020.kml",
                        "sha256": "f51063c217eb99fb26980c3ee7d8c988540870881bbbe6f77253ab47e4f62832",
                        "size": 977
                    },
                    {
                        "created": 1698655579.4879405,
                        "modified": 1698655579.4879405,
                        "name": "/dev/shm/incoming/2023_10_30_084557_00_00_K_020.jpg",
                        "sha256": "7be468463e2463b1b00caa5c15a5c1951f4555609d4ccc3fdb1d33fc8bd99f26",
                        "size": 857295
                    },
                    {
                        "created": 1698655544.4279406,
                        "modified": 1698655544.4279406,
                        "name": "/dev/shm/incoming/2023_10_30_084501_00_00_K_019.txt",
                        "sha256": "63f7962db94c7594e9c9fd7c25bf6dc7275a6bf25a6485566eacd84c56813ec9",
                        "size": 2049
                    },
                    {
                        "created": 1698655532.1879406,
                        "modified": 1698655532.1879406,
                        "name": "/dev/shm/incoming/2023_10_30_084501_00_00_K_019.kml",
                        "sha256": "9df07021fce9c841bb923fd55a0c54da75bd3f10c1c2af8fb303ffe60e132160",
                        "size": 976
                    },
                    {
                        "created": 1698655523.2979405,
                        "modified": 1698655523.2979405,
                        "name": "/dev/shm/incoming/2023_10_30_084501_00_00_K_019.jpg",
                        "sha256": "17228ca807f5287a392dd4902b7342e32cf1d0b66be05dd941bdaf2953697630",
                        "size": 839701
                    },
                    {
                        "created": 1698655522.5479405,
                        "modified": 1698655522.5479405,
                        "name": "/dev/shm/incoming/2023_10_30_084501_00_00_K_018.txt",
                        "sha256": "be67be806684d6ee8cdcb75962c3423434d7d42424b4f349fb7d3d4876bbecf2",
                        "size": 2049
                    },
                    {
                        "created": 1698655521.8379407,
                        "modified": 1698655521.8379407,
                        "name": "/dev/shm/incoming/2023_10_30_084501_00_00_K_018.kml",
                        "sha256": "8db77912698aa42366830abc06a951c85bb3c309b769d1b47af6abb2cded9615",
                        "size": 978
                    },
                    {
                        "created": 1698655521.5179405,
                        "modified": 1698655521.5179405,
                        "name": "/dev/shm/incoming/2023_10_30_084501_00_00_K_018.jpg",
                        "sha256": "5e3a10acffb790b27837d349c1c38ddfc7149ace7bc6ff17d353a73758ae6c2b",
                        "size": 837428
                    },
                    {
                        "created": 1698655510.9979405,
                        "modified": 1698655510.9979405,
                        "name": "/dev/shm/incoming/2023-10-30T08:35:08.ais",
                        "sha256": "176b24f3e4cd933b9b4577587d44de213e7a4852b92d25ed934f5b5170b15920",
                        "size": 334584
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "4b97f1c46a4ee8435d0d632ac9b638b0d555c515719eeb49cd4a72e99e5baabb",
                "size": 16502537
            },
            "errors": "",
            "filename": "2023-10-30T08-57-20Z",
            "size": 16502537,
            "verified_at": 1698682550
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698656543,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "oS4+MvIKsCZNVZgascVXiwagrgxlbkEiyRe7XksPYVjHmD/aE+b2nyWxlhyVbDTBZajWJNm2qKaWH9tnJsfmRLYRXFOHsVEYlIkIUasMHWmp36ChlEyptcnqtAy2WAW6QH8OeZaC16j8cTp9wjs1dh51M5qnBB1L5W15XjpGJ7wbmg0Oj10k/+S1lVpGuHTtB9DNvYEz4+oJo0Mm9OF61VnCrnUccgaH9QP97CfHJn8jTqXeHLjuV2g45QilmM0m1IUlSvh+MPXrz7NKFNQkDcPPZOya52gxi62RvbBZS45fp+nihIO28SWFoxgX7BUeKJnOyq4U572Us6GjUSgYNtBDbY+3Z8bGL71ZB5M5OoZLXMXo73sDjCCyJQSQjrXbqn0/7t9ldXg4IO8WKUq2p3encBtF9IYag9YLwXv9PorgwkgqwRn6zX0n4DxbHFdH11oZN2jZGzgRzTJuRgV1zC8fPb1PlOTA98NL222FHwNF/jtpvweRz+sk+FL7BvlU75FYrp/k3Cv0uvPPH2Rqrk9ok7CPXUbaBQx4CyBT8wKEjFlHR3nsxyD2Cq8I14TZlvT/OGvqgp9SvWFe+MNzv2iZi/wZbRC8T7SuNCEoHgeM7NyKzeFw7AhzRNupgnq6PDs0/Xw1ll+vUrnDYzGcbbAfIa49sv0NIOjY3T1t43A=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-01-49Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698656385.3079405,
                        "modified": 1698656385.3079405,
                        "name": "/dev/shm/incoming/2023_10_30_085927_00_00_K_040.txt",
                        "sha256": "a679a5db41013beb19b797567e4634231921c485ac6a12df9836d63c8c4189ac",
                        "size": 2043
                    },
                    {
                        "created": 1698656383.7079406,
                        "modified": 1698656383.7079406,
                        "name": "/dev/shm/incoming/2023_10_30_085927_00_00_K_040.kml",
                        "sha256": "1f40838dc7ce9c599babd8e83411f568f50a63309e86045895b8e5a3b699c6fb",
                        "size": 977
                    },
                    {
                        "created": 1698656394.0179405,
                        "modified": 1698656394.0179405,
                        "name": "/dev/shm/incoming/2023_10_30_085927_00_00_K_040.jpg",
                        "sha256": "2522f8e62ef81db039ecf2595d7b6a5fa4b9b0a643627c6cd177638856b07e5f",
                        "size": 698596
                    },
                    {
                        "created": 1698656382.6779406,
                        "modified": 1698656382.6779406,
                        "name": "/dev/shm/incoming/2023_10_30_085922_00_00_K_039.txt",
                        "sha256": "066cc8d7477f512ca5b1673102c72539af9d7761f718329a274a134556546d86",
                        "size": 2043
                    },
                    {
                        "created": 1698656382.2479405,
                        "modified": 1698656382.2479405,
                        "name": "/dev/shm/incoming/2023_10_30_085922_00_00_K_039.kml",
                        "sha256": "4919178f5262c8348b824a430a4e62f2072f774a146b605bdadeb20f1d8b33d5",
                        "size": 976
                    },
                    {
                        "created": 1698656381.6379406,
                        "modified": 1698656381.6379406,
                        "name": "/dev/shm/incoming/2023_10_30_085922_00_00_K_039.jpg",
                        "sha256": "648b5b5f0148810e66bd1cfe7ee145cedc848d4ca89e271b01a26be26a676e5b",
                        "size": 689306
                    },
                    {
                        "created": 1698656326.9979405,
                        "modified": 1698656326.9979405,
                        "name": "/dev/shm/incoming/2023_10_30_085827_00_00_K_038.txt",
                        "sha256": "ed2537be751a3b87b935d07b309f781ebbf9e942c003cea632bc2e85a87c3b5c",
                        "size": 2038
                    },
                    {
                        "created": 1698656326.8979406,
                        "modified": 1698656326.8979406,
                        "name": "/dev/shm/incoming/2023_10_30_085827_00_00_K_038.kml",
                        "sha256": "52edc6b01800365236efd20d726d3d9abb84bf26e83a34ad3ac6386bed06a59d",
                        "size": 976
                    },
                    {
                        "created": 1698656328.1879406,
                        "modified": 1698656328.1879406,
                        "name": "/dev/shm/incoming/2023_10_30_085827_00_00_K_038.jpg",
                        "sha256": "40beb12e42df3b2fc9767dd3b7346b7fb89eb3994e2569317d64c928738f4c8c",
                        "size": 1096980
                    },
                    {
                        "created": 1698656325.3379407,
                        "modified": 1698656325.3379407,
                        "name": "/dev/shm/incoming/2023_10_30_085825_00_00_K_037.txt",
                        "sha256": "8e7963ad46bc823881a161ff3dad3dd35c2818266a1c2b50a80fda64d10b8b44",
                        "size": 2045
                    },
                    {
                        "created": 1698656325.9579406,
                        "modified": 1698656325.9579406,
                        "name": "/dev/shm/incoming/2023_10_30_085825_00_00_K_037.kml",
                        "sha256": "958e2d950e0a4b638f5b7d7d16ef6e09adaad9da170012c800649bef02a83f54",
                        "size": 978
                    },
                    {
                        "created": 1698656324.3079405,
                        "modified": 1698656324.3079405,
                        "name": "/dev/shm/incoming/2023_10_30_085825_00_00_K_037.jpg",
                        "sha256": "40c0ea40127f0f1546ecdeacbf8b289d8d3b65d82b627e19a39f04aaa1271056",
                        "size": 1100106
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "a0ecf08b29ef5cdeadb676430c3c55e5ece92ea69118fa9a5a80bced83c74d7d",
                "size": 3572619
            },
            "errors": "",
            "filename": "2023-10-30T09-01-49Z",
            "size": 3572619,
            "verified_at": 1698682832
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698656867,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "AwsG3hFC1UhTxIEbE0sWiYztPJzMUPMthdO87PAGoklRpA4oukfQ75YMYkjAthgrD4E9Sz9u+hOEuTiv4+I2lqDagr+lvWICb52Qrp8S3GH0hjeHA4UfYvujBUTPxxqfy6dFDbyp9x06NQe8GvSuEiZPHE4g6m5uxcXIUC0oszJV47SrgPYsf1kJ5WrReyBQh5oIGsgJSqtRL2ZsHK9jfy75MbdQZXnVHZXzJahmp0KF4BsBUUYL8nhkcSKnO1Iu2PPD/ITiw5QuIrdMgJFsKB2I+Dz/l8VT3x1xt11WyxXqZW9iXAzRvtQWR7eUmUgW5bP55Wewphu5ZyyYCYIJfLwk0OhtIXIEJjjNfYVncUW7BdFG9X9u42FnR+mciWNWC2BY2NAHiWMdCfmekSV30tJjNyqiJTpaF/bffoXFPLpbPIcKj17pGqwCpGG5LmTuqUxskDPtZf/2T9pZe6Tka3W/4Z36MMDDJzHjJmHAxkOccuVGsEv1VwR91qlEtN/WdyQAMGc3QWvtBJNYJSTh4gXp0gxDjBQEpEeB9J/agHyYx3MkfI8jba3GHwOYHj84Lu4ORQvsmXDaQKjtMC7StjI8F7bLZfeB/Mq1rMxCIw/zvYDU2yH0+nvrt30AWZIrqwZgkRJss4NwjmoFFgIunUuevGCp/25V3IEDEpi2biY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-06-29Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698656763.1579406,
                        "modified": 1698656763.1579406,
                        "name": "/dev/shm/incoming/2023_10_30_090540_00_00_K_047.txt",
                        "sha256": "673c92b09e665f3b6eef8d3c60fc04511063729527ea4d5a1f3a62b9fae471f9",
                        "size": 2045
                    },
                    {
                        "created": 1698656763.4579406,
                        "modified": 1698656763.4579406,
                        "name": "/dev/shm/incoming/2023_10_30_090540_00_00_K_047.kml",
                        "sha256": "05a45442c9d1af430a3ffdc1d861bd38f3157cec873efe075d1cbcb91ac2bedb",
                        "size": 976
                    },
                    {
                        "created": 1698656761.0479405,
                        "modified": 1698656761.0479405,
                        "name": "/dev/shm/incoming/2023_10_30_090540_00_00_K_047.jpg",
                        "sha256": "4b13b1cf6fdcdc221c4859a5b60dcb06d003e9eab8407521c81255638b48de7a",
                        "size": 999582
                    },
                    {
                        "created": 1698656714.0179405,
                        "modified": 1698656714.0179405,
                        "name": "/dev/shm/incoming/2023-10-30T08:55:12.ais",
                        "sha256": "f9983b119750a4062ff63c8d16a26a0752b3fd63e28ffd012dacce2f61e5ab04",
                        "size": 396944
                    },
                    {
                        "created": 1698656706.7579405,
                        "modified": 1698656706.7579405,
                        "name": "/dev/shm/incoming/2023_10_30_090449_00_00_K_046.txt",
                        "sha256": "ca93d38a99c1065924c4251f6a4f3e774ff8d420542dba1ebe20a3ff1945aa13",
                        "size": 2045
                    },
                    {
                        "created": 1698656712.7979405,
                        "modified": 1698656712.7979405,
                        "name": "/dev/shm/incoming/2023_10_30_090449_00_00_K_046.kml",
                        "sha256": "902132bae154944ecb5a06961fcd5a31f2524d5b0c76c335a3a1b7e7d5e22776",
                        "size": 976
                    },
                    {
                        "created": 1698656704.9579406,
                        "modified": 1698656704.9579406,
                        "name": "/dev/shm/incoming/2023_10_30_090449_00_00_K_046.jpg",
                        "sha256": "008baa702aaa3e1335847f482d3fcf2db4df47e69cc76c2b620e99ded51823e6",
                        "size": 696161
                    },
                    {
                        "created": 1698656703.5079405,
                        "modified": 1698656703.5079405,
                        "name": "/dev/shm/incoming/2023_10_30_090425_00_00_K_045.txt",
                        "sha256": "b3edb4519797ab7aba74433f99298c383827d7970f65f8894f02aae6e71ce739",
                        "size": 2045
                    },
                    {
                        "created": 1698656703.1379406,
                        "modified": 1698656703.1379406,
                        "name": "/dev/shm/incoming/2023_10_30_090425_00_00_K_045.kml",
                        "sha256": "f01b0c267a145ab2ae85615b5977a534524416a22d9e4621500b4ffd6afd2fbe",
                        "size": 978
                    },
                    {
                        "created": 1698656688.1479406,
                        "modified": 1698656688.1479406,
                        "name": "/dev/shm/incoming/2023_10_30_090425_00_00_K_045.jpg",
                        "sha256": "a5bb1d6e0cf96158d355b9272504d6e446a5359ab0324ea3f130471680936c0f",
                        "size": 1168628
                    },
                    {
                        "created": 1698656587.1979406,
                        "modified": 1698656587.1979406,
                        "name": "/dev/shm/incoming/2023_10_30_090236_00_00_K_044.txt",
                        "sha256": "5bbbd6b02e44d906ce9d14df013b0936660d4ed33c56d63c3a98de12a3bf25aa",
                        "size": 2044
                    },
                    {
                        "created": 1698656590.0879407,
                        "modified": 1698656590.0879407,
                        "name": "/dev/shm/incoming/2023_10_30_090236_00_00_K_044.kml",
                        "sha256": "2b2fbd15239608c4a10fd9a6d0b0a82c991162505804ba30df600633588ab5bd",
                        "size": 976
                    },
                    {
                        "created": 1698656590.0579405,
                        "modified": 1698656590.0579405,
                        "name": "/dev/shm/incoming/2023_10_30_090236_00_00_K_044.jpg",
                        "sha256": "95e693468f0b124a4f1b8ef831fb2bd05173ca2eda41771731c697d362b923e5",
                        "size": 669878
                    },
                    {
                        "created": 1698656580.9379406,
                        "modified": 1698656580.9379406,
                        "name": "/dev/shm/incoming/2023_10_30_090233_00_00_K_043.txt",
                        "sha256": "25902d8850bb26af1a3d0bef9f645f23e0827bc45540e0b7bcffe5117348dbe9",
                        "size": 2045
                    },
                    {
                        "created": 1698656582.5579405,
                        "modified": 1698656582.5579405,
                        "name": "/dev/shm/incoming/2023_10_30_090233_00_00_K_043.kml",
                        "sha256": "e85db14e47d59f395b48c319aa1cc1f11f7a04287098ff704cd392ec86cfe8d3",
                        "size": 977
                    },
                    {
                        "created": 1698656575.0079405,
                        "modified": 1698656575.0079405,
                        "name": "/dev/shm/incoming/2023_10_30_090233_00_00_K_043.jpg",
                        "sha256": "f6f09d3929c713799d0989a7c8132e7dede144e1e62b70f3dcb0731c957ca53b",
                        "size": 664988
                    },
                    {
                        "created": 1698656559.9579406,
                        "modified": 1698656559.9579406,
                        "name": "/dev/shm/incoming/2023_10_30_090220_00_00_K_042.txt",
                        "sha256": "87eda4ae944f2fcd23d5af0c3ef360882475a7957285b7e02dacab70851f40fb",
                        "size": 2045
                    },
                    {
                        "created": 1698656559.4279406,
                        "modified": 1698656559.4279406,
                        "name": "/dev/shm/incoming/2023_10_30_090220_00_00_K_042.kml",
                        "sha256": "db3c3595ef468e3c16ed7f3ccc5a7f2b2923a7227584c5da7d74461f8f00a327",
                        "size": 976
                    },
                    {
                        "created": 1698656558.1879406,
                        "modified": 1698656558.1879406,
                        "name": "/dev/shm/incoming/2023_10_30_090220_00_00_K_042.jpg",
                        "sha256": "7b4b07c4993e573a901bb8f22f81107ca293bbd7dfa246e16353e2e984f2bdfe",
                        "size": 1089034
                    },
                    {
                        "created": 1698656557.1279407,
                        "modified": 1698656557.1279407,
                        "name": "/dev/shm/incoming/2023_10_30_090214_00_00_K_041.txt",
                        "sha256": "0db0f342e3275f4d2d99e8c9f8f5c7684693e101cbce6d0f9be34040e1ba5b62",
                        "size": 2045
                    },
                    {
                        "created": 1698656557.9379406,
                        "modified": 1698656557.9379406,
                        "name": "/dev/shm/incoming/2023_10_30_090214_00_00_K_041.kml",
                        "sha256": "a4e181fff8b782a4b4c65d299dcf0aae1ceafc162136b34d1b8180574c7013d2",
                        "size": 978
                    },
                    {
                        "created": 1698656556.6079407,
                        "modified": 1698656556.6079407,
                        "name": "/dev/shm/incoming/2023_10_30_090214_00_00_K_041.jpg",
                        "sha256": "e4e6771cd9512262e37722b5394ca5c8b41d08c757f9706ee76b9b704c65ec70",
                        "size": 1135161
                    },
                    {
                        "created": 1698656780.9879405,
                        "modified": 1698656780.9879405,
                        "name": "/dev/shm/incoming/2023-10-30T08:36:20.ts",
                        "sha256": "53e6220ff4c97ae7503bc0e0b62399950e28d5ab0fd2804c6c28a0088a58725f",
                        "size": 441401816
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "136b6e028b07a332f5402951f572940391bbc3d578709dcb7a4b8c9a918db889",
                "size": 404893771
            },
            "errors": "",
            "filename": "2023-10-30T09-06-29Z",
            "size": 404893771,
            "verified_at": 1698683438
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698655966,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ihgvqKWIbLXD6uDQBfc/p34tMR8TMJggZLsA+04In2RyjmgM3HHASvL6ddBqzOJdMEYkFE6WJssl6BldZrNugrRiTDAYgc/u6CW3Q2dya/kLcMO9PStlB1qR0zN2WzHcvJxJgInNfvepXXEMhDT7EVuMxFT1/pisbx5UHnbtjqkqHMfjQTreH+vr5yIXBcPYddckBD/UY+5PVTYO+Q0plnO4sLXIJnRVya2GpI4UqBqd4aSl2PxfkuBWIZl1gINP7ghKCyXvmL16ojFpzioNJo31EpIaJf3qbf5N98a/nn0Auw43ajUNFQoMt0slE/8pJP0CU2E1aVJOXrOU8Thp9gBuMRpohkcRC6a4JOm9q5oqnXQ09HbH884zbrdqaNlLx7maSeTD7khQ4Zw6gFQY0Hch+D13R1lc3KzAoUDo7t5/0ezt2/nWk8aX6/zxbheqBcmsM5khlNwsQ0xACLsO5mMmgE3iHLA6Q5PC6Xsdnv28kften8yyAnDo7aKcnjTimb2CP9XdHdaqdyCuFnKZdXPsakIcKbo3YDVWVSMOjy40e4jMa2pE2iJBSJc0rSQy7Wu1aUQJVq7sMAsV55P6U/5+wj2OYPQgrLbM2FCatBIW98OQgswJ+u/wu0d9kMeBkMfKpZ+2RzEfa5OjDvkMqhZ530G8sqidXU+Eji/mXbo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-51-30Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698655838.4978287,
                        "modified": 1698655838.4978287,
                        "name": "/dev/shm/incoming/2023_10_30_085018_00_00_V_010.txt",
                        "sha256": "ea4eec9d8a9ef09de95d659f1551519a02cc84ca0c6e7891fbd5358c1ef353f0",
                        "size": 777
                    },
                    {
                        "created": 1698655838.1978288,
                        "modified": 1698655838.1978288,
                        "name": "/dev/shm/incoming/2023_10_30_085018_00_00_V_010.kml",
                        "sha256": "68235c7c1b17c9b93eac59e7d62e44d3e1cfac6fe783cced5552d8cc370314b6",
                        "size": 892
                    },
                    {
                        "created": 1698655837.6978288,
                        "modified": 1698655837.6978288,
                        "name": "/dev/shm/incoming/2023_10_30_085018_00_00_V_010.jpg",
                        "sha256": "399b1b9e197d1910fbcf780af197ad5518e6ee8dbd31d1317ec1868072c650d0",
                        "size": 1026641
                    },
                    {
                        "created": 1698655880.4878287,
                        "modified": 1698655880.4878287,
                        "name": "/dev/shm/incoming/2023-10-30T08:21:20.ts",
                        "sha256": "a3f8f198b82eb63db811f1ab3dc9ed79d95e818bc40bbe1100bb49497bcc927b",
                        "size": 427006656
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "b8cee805c0adb5bf2e9ae29384d78b6b9fc883ec12eb7921efb6af8137ef1e78",
                "size": 401825624
            },
            "errors": "",
            "filename": "2023-10-30T08-51-30Z",
            "size": 401825624,
            "verified_at": 1698692897
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698655838,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "XN9LUdzW5fjQHcTPUvZACXqKvY8phQ6N0x8zHrYOS/IDHFH3Dbogghqi3MmdpYiL6wuPhhUsUoMPuWnTmrzuQitRK7DMMlMfsYdtvJIM4HA9kJt6zgCNwj7q2kZtQldMgiC/B3+73IAjQnQJdMSdkq2LzvyGrIjTpgWqtZSifXIxNtSlONafm7xe/Scx+1dPVqmeH0bd1WrA/AOmef6/WbSLjr2EQuy7tQgMMgYkuU1hJ7hnhU32iC/Ieo5yQTQsVhIyjTr8SbhbVppOCUH0R8aWF8G8tpFi8xE8Lylg3BNuzdf3GUYe8BYnqYh4f2k6XMS72kd3E0fXXEtTgwG2yTO8Tuj9bBH9sFKp+3t8roNbOKuWX44S5M+OHFM260e6uaUKIidETY2JNoPTeOQxvYMT11nu9munTgS6nbiVXMWkC/DBc2LKb6FHKO34K2kT383kUqrF9SvvcbUy9bFNCsAgLGBn+yx8jy+xsg3FjZsHcX+biAqD6HKTB5pIMZVDSuasPxo/WNTefX+AVSKpYWUCFLdzHrpGIMSBfpa4L0zt72r0HqSmqzAGQWWhTF187R/IV97S8a/P9SohPikMEu2pXGYQoXwaKa987ZquRMcMeKGbHnzCwTEuAbTv6/cI6N6La19hKdv3gBX3peiH2R2rJ44l1c9MbgQqiPEiFJQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-50-14Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698655688.2578287,
                        "modified": 1698655688.2578287,
                        "name": "/dev/shm/incoming/2023-10-30T08:38:07.ais",
                        "sha256": "88d17a26d728e8d4913e19f801b8c6c5e96df1f26aa7c85eb4be963251bbc1e8",
                        "size": 717065
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "8a34be7eaf3af1a121fb19ce534a1574a16af3d1565ae55cc6e067c1ada65eb7",
                "size": 212051
            },
            "errors": "",
            "filename": "2023-10-30T08-50-14Z",
            "size": 212051,
            "verified_at": 1698693400
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698656441,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "i3lE5yqtna5bdbq4V+P8icFtroXhEwhMz/wjZMtGAs7zPwr9gQ/7LnC1BLSHxNcne+jRxrqC4s7SI3aGL0d137PZg5CO9hhh9dDngYRfexQLZJlFzLe8KzJhmBU+uXnbuhwScwSPhd5lJqG5CYOEWUBv2Q8d+BSfzkBYAgUNVCItbm8BjSzo9T7xZo+nvseS6bnWoj2Yi/QAPWcI5JNrwur/a0YrC/AvFyGiX1hnUH9cE9UO1lRjCiidTZXH0JFeW7D8q2WotBPXmWDMLqOL58nOFQqGibmhkpSy0U4ok2/ZiOqNMeYH6oX3APZUEiUCL4PTjhFv3GCXB77dK0JP8c+xKZ0JhmvEfZbfOPmTSqPEc5bjvO9zAAPj7YAvAblxFh5+t36COB3M/VbNsz77orLSy2lFi/gL8evhHG4B8oB7eXqLprK15VIxC+yOKeJdkqY6kkwXP+s/KDkYApxdB1iXA88oTVd9+k/kELDhcxNOueIQ5+bv8eH9/K34cqc29wl3nFPtAZxg4oCqv6qZhXWvA/KIINvBWsUQtpu7XZV9bG0/gTc2VbdMMH9XyCTtoga1VZdWduHFQJGIDcxWM+PjB4DZmBQXSfo1QTtQD1MflkbiVFHjJKx/g39RyVQuAaqaT5wlaaJoV0k2jfD3g9W1N2vgJVe71zGpfQaac30=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-00-17Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698656290.1878288,
                        "modified": 1698656290.1878288,
                        "name": "/dev/shm/incoming/2023-10-30T08:48:08.ais",
                        "sha256": "c6595b7398debc6a7c38457277b11da653bdeb8ca92862c687e6ea806365905d",
                        "size": 663449
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "7996ff72b713ff14e1b19bc36716a9e9a6581608a942e9a5c769d278bbe5a9e7",
                "size": 196417
            },
            "errors": "",
            "filename": "2023-10-30T09-00-17Z",
            "size": 196417,
            "verified_at": 1698693444
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698767482,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "GHe52ZnOCdr38rAps2kkOl7OIb8TSo4A3mZtZhE1ZMW6R3SxhbFIHUktOtQoWGccW1t6o3ZR5Hx1XXeoAM7/Gxin4cMbFtM0ANos9nqM5GUE/nRGyvz297PVkSl8EQAQb8zkINb9eGTcu8nk6x1qVfrV7aHtAm2g8HZ7jXhgwW6Gdu+kMbNo+3vHaY5uW0eniIngAPUpq6RXt0kB6LU4Mf1RXtpB+mjrRRLhYLBB02Nzv7piNSu2PGRWf6oSYLM0wT8RoXJSa3/mptPXslji2Ud3igZTqkRLiYDIp3R6nZcHa2w6uSnpVXZoZz5nUvu4zKNtkeJLVq3FqnozQfE5LhLJMw+t6vjF5xKWJUJ3uLRkHV623FclIW/pJNMkMpHa5FfCB1C9E6DeE4mG8m/IZwYdUETtlQiQ1tjl6CF60jIu+szi08oj53T2jnvwbeBpeZvSGsVGeAOLmDFO0wecxZ5tZkLlwvzTV02HSTKGjdGYLTPeHlkH2l3M4w5lisu4Q20GcsPbfRl+O7KBe9rX6pbA98BH2IujV1Fuu4eAk6lamGjQvUTgf3L2MrCbYLHCUkCy4p0uWOqRtrGiNtx22JKiFc9NyO0fApD27GDAClLSKuDcQz1RWD+RbVxDppLsFviYR9Vbr1oWPBXPhp8rOj1xtgo37tqxxXjhOcMUQxU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T15-50-48Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698767326.163036,
                        "modified": 1698767326.163036,
                        "name": "/dev/shm/incoming/2023_10_31_154831_00_03_V_026.txt",
                        "sha256": "599dfad5b4d539687f020a46450dd2890447ec264bd2afa71b7a9ef08115d2fd",
                        "size": 2029
                    },
                    {
                        "created": 1698767339.3730361,
                        "modified": 1698767339.3730361,
                        "name": "/dev/shm/incoming/2023_10_31_154831_00_03_V_026.kml",
                        "sha256": "e29c7cbfcd7191666e6f6db80bcf4c3e5a74c25ab3306d32b652206f07add7b4",
                        "size": 978
                    },
                    {
                        "created": 1698767324.043036,
                        "modified": 1698767324.043036,
                        "name": "/dev/shm/incoming/2023_10_31_154831_00_03_V_026.jpg",
                        "sha256": "2455862e85887aa88e527eb2e7a9204eba5752bd2f6755e35b96a484c774d631",
                        "size": 1094672
                    },
                    {
                        "created": 1698767293.233036,
                        "modified": 1698767293.233036,
                        "name": "/dev/shm/incoming/2023_10_31_154758_00_03_V_025.txt",
                        "sha256": "81fe64bd217d75acc04b28ef4e827192aa150839642c381419bede8fce3306a0",
                        "size": 2032
                    },
                    {
                        "created": 1698767297.413036,
                        "modified": 1698767297.413036,
                        "name": "/dev/shm/incoming/2023_10_31_154758_00_03_V_025.kml",
                        "sha256": "0c1b270e203a53c573342073853f0c34580db435435868bcf72c6537d1ce18b2",
                        "size": 978
                    },
                    {
                        "created": 1698767298.213036,
                        "modified": 1698767298.213036,
                        "name": "/dev/shm/incoming/2023_10_31_154758_00_03_V_025.jpg",
                        "sha256": "f8643a09ebb172c463b932dc88c62f716f5609bca026bb1afedeb09013af26ef",
                        "size": 1060511
                    },
                    {
                        "created": 1698767224.243036,
                        "modified": 1698767224.243036,
                        "name": "/dev/shm/incoming/2023_10_31_154634_00_03_V_024.txt",
                        "sha256": "4ee62376860992f5f88a89cbdedee8cbdfba03edbeff32d880022de74ff8c1da",
                        "size": 2033
                    },
                    {
                        "created": 1698767214.533036,
                        "modified": 1698767214.533036,
                        "name": "/dev/shm/incoming/2023_10_31_154634_00_03_V_024.kml",
                        "sha256": "7d4451d4b93107a2368e40a4ae8ff3bb3daca1cf107c5308fdda23d0257dac1e",
                        "size": 976
                    },
                    {
                        "created": 1698767220.3530362,
                        "modified": 1698767220.3530362,
                        "name": "/dev/shm/incoming/2023_10_31_154634_00_03_V_024.jpg",
                        "sha256": "c86893210c7b75d1fc7f7d755c38a0708cca2021d673d249960e50d4d5849de3",
                        "size": 1012371
                    }
                ],
                "mission": "OSP2",
                "sha256": "372d48fb3812053d51b2278fe91701e3890b8e39e48e9bbac141cf32c600ac4d",
                "size": 3172779
            },
            "errors": "",
            "filename": "2023-10-31T15-50-48Z",
            "size": 3172779,
            "verified_at": 1698783464
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698767113,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "epsm04DJDFk9UTJBh1KOhxbIlnjRG5+e3CuBJhf09PLncaanK/g+RI4CRKF+MeQ3F8j5YpW7SzzWpwpJ/CEI8CfeN6yixM+eYVojP4EGWoeYNOarJUaMBIfyEjL4f3Z2qrdB3i+fchZNYpIHCphsEnl6EmmZLCOPA8FWUnGgrrLnpEiuDQWO1Hw0I7K/YsxLoKFEVucuMbOl5mw46SQp+z4yn+ZQnWlTBL6nNw7mfWDzxXwFojC75Wl2gTrypOoCS0wPwdTFtXpKrmKZYzBUr7ma4+RAWmZr7U5XNa07Y48y39tKelzpMBr1iVIrKhGqKg/nuMZbnhHRoiKkRmqyLcEisOPXOHIbMeWuzfRaSoSp/K1nz5DtnYICpg001gf9B/OqjtTKco+Xkq5fqqoflu/PGfXmSqseyY8yF/M5xQGENeIaKBSmzIv7kwMlRY2cw+LUAStu2BL1khfD9pJ0MVm4bwVppETVPZkp3cyXcSJaNdHNlDSj4tahd3eLS7Htyx56ga5zg2X7x1nCiUs4qQG5DcRMMuJBA+oJ9wJjmLN9ss5iNKqIqbU83wljax/GrR2iGc5G4w0kKjd8yIw6H9TcfO+jl53B1C6cRzh3iX3Tn92SWj8W2dst2j42aghcMgCevjCl8AAv6ts0+aBT2Fm3vLs2v0Ed1rbcKJDZKIk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T15-44-56Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698766978.933036,
                        "modified": 1698766978.933036,
                        "name": "/dev/shm/incoming/2023_10_31_154231_00_03_V_023.txt",
                        "sha256": "908be6a0550a369724b3c47b5e497ef2d460d0a165ee513ebdcb49a221840a21",
                        "size": 2035
                    },
                    {
                        "created": 1698766978.773036,
                        "modified": 1698766978.773036,
                        "name": "/dev/shm/incoming/2023_10_31_154231_00_03_V_023.kml",
                        "sha256": "5d0504c513da5c30569616335e032e1af8571049fb5c48e4cfe5889c4ef2bc47",
                        "size": 979
                    },
                    {
                        "created": 1698766971.703036,
                        "modified": 1698766971.703036,
                        "name": "/dev/shm/incoming/2023_10_31_154231_00_03_V_023.jpg",
                        "sha256": "6ca12ac1ae4d44d08d9249ac16b8109259e616d5c4399ebe9c9bf63143d69256",
                        "size": 1101185
                    },
                    {
                        "created": 1698766906.703036,
                        "modified": 1698766906.703036,
                        "name": "/dev/shm/incoming/2023_10_31_154131_00_03_V_022.txt",
                        "sha256": "be664862f953d8a45288b4dd40fb27b4c84496ec3a1f660dcae1d9bc631f16fe",
                        "size": 2034
                    },
                    {
                        "created": 1698766910.533036,
                        "modified": 1698766910.533036,
                        "name": "/dev/shm/incoming/2023_10_31_154131_00_03_V_022.kml",
                        "sha256": "fcf936ea3b0d98cd9008b51e98ce710917852a5b639dd0b014268f6277f689bc",
                        "size": 975
                    },
                    {
                        "created": 1698766929.793036,
                        "modified": 1698766929.793036,
                        "name": "/dev/shm/incoming/2023_10_31_154131_00_03_V_022.jpg",
                        "sha256": "5bf072c724f3b385dc7728ddb35067bd5aa91176612fd692149673c6e9d5c7ec",
                        "size": 1054889
                    },
                    {
                        "created": 1698766874.8430362,
                        "modified": 1698766874.8430362,
                        "name": "/dev/shm/incoming/2023-10-31T15:31:09.ais",
                        "sha256": "4c44366d7886fdb299a72e7dede05a0dc54a90f8fd61264ab09583edcba33d81",
                        "size": 68487
                    },
                    {
                        "created": 1698766840.943036,
                        "modified": 1698766840.943036,
                        "name": "/dev/shm/incoming/2023_10_31_154022_00_03_V_021.txt",
                        "sha256": "09ac606c018457556e0b42f956b6c903266ac735f296739f263bab0801842d2e",
                        "size": 2034
                    },
                    {
                        "created": 1698766843.723036,
                        "modified": 1698766843.723036,
                        "name": "/dev/shm/incoming/2023_10_31_154022_00_03_V_021.kml",
                        "sha256": "f0dc0787e930789d203b7e17e7fce6557a13e4800e3e7a2e8ffaaf94d48d5803",
                        "size": 977
                    },
                    {
                        "created": 1698766840.243036,
                        "modified": 1698766840.243036,
                        "name": "/dev/shm/incoming/2023_10_31_154022_00_03_V_021.jpg",
                        "sha256": "854ac62b02597105febb1750452fe58a24d0c8399d011920e61b2629daf0ce97",
                        "size": 1029204
                    }
                ],
                "mission": "OSP2",
                "sha256": "6bd50cd3d744099b17d4fc5a7dfff02ae9d466cebfb1ff7099ed67c9698c0583",
                "size": 3206131
            },
            "errors": "",
            "filename": "2023-10-31T15-44-56Z",
            "size": 3206131,
            "verified_at": 1698783544
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698678592,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "gfDzP0GVzPDXC42AnFR2dh/6Gw02a5KcPqG6tTLm2Bu2zG2pLquEX+wWu/Zrki6yWeIjRl92q6whCnEKmtKbmbArbJzVSRG/ilmtVUbM253XRPjsRvBBjavF/pXFQPzqOPFJjjneLDsPXawCS7pn0b8AVXOdq/wZ7ClN+mSBJ2EKHR06h0yY/2YYAGJ2HWVVu2UCmjTteSRGZFHtz0auOVEc6P2vlFUIx6Gpgqb1VpchFriFxxMLpYqz/STc+HSBBvEZf5ZWgVm1ufToCqcb5jjh00lhQki12P0MyWe39fzoPNRk0fwZDrn1R+Rh78dgNkfMO1NO55gRPL9JDk3H3KsrgEp4HkV9k/HdWG930ThmXVjC2JYwKKTcH0LW35xztGsr/zRvauvjt1SrCaneW+SApIRVtYduddnvsMwIawPQK7iKyZCwtprSRuZWEIQ2umgTaBKcbazazc3FMjdNuMW1SQckiYIWtxmwPPuJgb840uT4tLDjURwp+K0l2edmA+YjYzBKryWBLmBJWMH2Qmk1F92SaSEgdq/nqk/V3MRkN4xGvDbl2eKKrIJxv4/QJeFrXclnHTPVPDjr1R/o3iD3pjO8nscZyGiJmcGpXRodJNADvg6JIPIDQR1Dgcu+pmZMzH41vZr1B9PDiTvCekTVdWuGejibdYlQbHK4sag=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T15-09-34Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698678446.0886981,
                        "modified": 1698678446.0886981,
                        "name": "/dev/shm/incoming/2023-10-30T14:57:21.ais",
                        "sha256": "6c5f0428fc308b073f0da21fd5e691a3d1f005f5ce7703fb594c38703c4db5d3",
                        "size": 71409
                    }
                ],
                "mission": "OSP2",
                "sha256": "0920783697ceaa6136cb8bbfde5a7ddeb0b59559494b898e2c830512b6b5d172",
                "size": 19306
            },
            "errors": "",
            "filename": "2023-10-30T15-09-34Z",
            "size": 19306,
            "verified_at": 1698753187
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698657145,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "jpexmCpINlF/OmMlBgoMl0hfYgi5Eq+Wzs0cA8abvauiS9ts/4PVmnsJ6kRlHtbvDf9vJBizjhga8jt8URfbgQCcNXJNCzT4Tva2AAJK6T+glko9YvuFnObYatdixLm1FQw9BHXdMSt+uDYIOUYoInMg2+xhQnDG8dzdcZcG5oHeoH3R9a/PtjKf8IlBf41uoJ8IABnmDcI2q7qMAyj0neXOaCYQqh38vmdGqZ069eI/FfD9lMc1b/B/sMY++DRIJUw4k9oJNIcxuPXx/d0ew6hIvqTr/RxQGh/MF6+7eeyepUYEs86bML/xcZtIlpqu0dHxNJsgcZoKQFwnLhgEpOmeSKspbnuZu5XYs9YioM5DNb7IRFP0db9OyrUjxnu7pcraR+nOIknKRqy2JAxvEtp1wMr5QvLTsScSgL9CcLCktlA+cpY5GxxMBkf8j06HbTOgRvRwRo/0s+9TTwj/rVJEQjCBeA+Nuyk/H3YZYh9PJr9tzzN4Nrb/hwDRF3Ru1ZH/Q4ENdOtLboWEYEGimSJiG6vTsN64JUGLIygbYCKmSM00ileT6PSUE2aC/HK4O1fzaNSpc5d+UCyLhdLDeZ5FVn5OvIHB+sxQhV4PLUb1MehO0jFTxxn3TJI5BQOr0c5Fb/4WstQJ/ynraekFt4aU7AA+awGb3U+EZ/J0Ago=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-11-19Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698657068.536036,
                        "modified": 1698657068.536036,
                        "name": "/dev/shm/incoming/2023-10-30T08:41:07.ts",
                        "sha256": "caa0feab8f27ded0351599e49fcb1e6c4a4291ff4edc4473a6349ac315dd7af5",
                        "size": 398967772
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "808ea80697ed7c46dc17b0eccf3e8db8594a4db0591f0a6b8178cc497b93f92e",
                "size": 384839163
            },
            "errors": "",
            "filename": "2023-10-30T09-11-19Z",
            "size": 384839163,
            "verified_at": 1698667135
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698657665,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "RWBBAwWaPfSGK2BKj16aJ5+7R1kkHcDpG5TAzJsQZql6EFfh9JQgHD6aeIMhrHJrTIzlDGvbooBbg3XXUZPrtApjEtOFysNNvSbxqG5o2Tx0fKHVAvWWjLaqBkjbwQe3MRNeEyj5VyVUjOfNvVHsug0+vAmojJUtbBmPpDGZhp8mxdxMiAhteToas0nskKNKFjXIpE0Hspl1ANYvmC9OLAjP0ZJcHw546lUa/alMDWRYmD8c4z/6YDq++smrnUGtC4335OvLuj/JB0hgObTp2S9glQEnNc+pyGkNNIXumqxIAl7dUBKFY6Lyff19hzCNiUM72BxypEm165l834+2/r/L4mhZEh4f4iXg44+nl+EcNTXA4Y1JFTUnSxItH1cZRWKN0wES18BM1iTMo35/DTmnSzRc67+G3eIJGpebLFcfIWrmO2zLX4ojq8w66U1a2HnAej20iwP/5ScVcrmbT1sp5sDPnJlr4Ply06r77+nlDuvFl9X3Zza4dE3ZdSo18tCMrAHlKfFZlFCZy5S0nYSuNMrCX5V+AKMG6OaY0L9bnfKM94tWpxIz+m9Zd2amYrzTlILFC8kqzY0zMY6sFbdoBKh8LflcVxARxzFagVIQTi2qv1ZD7OfSoIGRihYbhQubMrw/SAhY+LTtU2Unj3rVXuGAk3vdnS6gu9nhbOI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-20-38Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698657510.986036,
                        "modified": 1698657510.986036,
                        "name": "/dev/shm/incoming/2023-10-30T09:08:28.ais",
                        "sha256": "77419b02d700270d938d0518f381dfdb928715a106f687fee6d2eb21b237434d",
                        "size": 105171
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "412064ded635432bdc71df01553a7b33f1f76865422245dfb1e345dd2520b83a",
                "size": 28295
            },
            "errors": "",
            "filename": "2023-10-30T09-20-38Z",
            "size": 28295,
            "verified_at": 1698667137
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698657289,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "NAxrhLHD4jzAR+6pAvxsvgHu+rTuopIM5SyanCMy8rC0WAgxdQBXXEsNRw/U/B9WTbyQKcwuXFCmq3jbsRnhgJl6Ki418CAyXc/1aNU+IbnKd+gnDgz/9Y8kW4xJhKR9UV0/LnwIkH+fgR68VsEsL67PrjzjX85fWnn4XIRC2MX4jtWXZjlpHR50RiCVZ4DzJu+davPwAhLmPlLJ5RG7x4SYjpeUKKut8mtr+dAa7aY8pry433LvKCqGNc2yCdogNEWfsyaHWJKsYcLkr+O4ryovUA96FijkYXVFaU+3Lcy5rkWRtmHBYbNnfd0wA5OrM0MwBqpAeOjY+wiyQPDSlT/0PA7tEyiF5t9ppx9W5A2058eSCPLBY+tbMsqpXCkI921grPMyWAw9ZT5elNT7wcHRiVnEIbkmUXt5nOFNwGQcFBl9CF0Swb6OWpsBEtCet2aBuozRafVUYdXd6m9YBtzTdIP84pLU6vI87SWD/55bCiCrxv3ApfoFDqtaDBW2bll+1e17vAwlTun0j0TyfwR4N7veSPkoriZbph+N2YFGILCl9i///aTmfm4j7pXMvThSj4rh5ryY7RQ/rCDdxbXJ0Snig9ZBkPaAMC6leT3tnBhEm5vI+ujqe5nhqNCAIgNDdrkEBRUlhBWmVMmlWl9JNZ4IhkojZZwmtc4DW+c=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-13-59Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698657128.3179407,
                        "modified": 1698657128.3179407,
                        "name": "/dev/shm/incoming/2023_10_30_091136_00_00_K_057.txt",
                        "sha256": "ec0ae817076293428432a0e035f4ab61188d58a425af76c8cc99c1676439acd5",
                        "size": 2044
                    },
                    {
                        "created": 1698657116.4679406,
                        "modified": 1698657116.4679406,
                        "name": "/dev/shm/incoming/2023_10_30_091136_00_00_K_057.kml",
                        "sha256": "48702916d6146c239edc3b5c5acae488d20d84004ffc893be82c9bc298dc5a7e",
                        "size": 978
                    },
                    {
                        "created": 1698657116.3179407,
                        "modified": 1698657116.3179407,
                        "name": "/dev/shm/incoming/2023_10_30_091136_00_00_K_057.jpg",
                        "sha256": "bbb73af05a9e490873274a1f7f8a0cb35ce9ae9bffe0944f69ba60bb21a956b8",
                        "size": 1021756
                    },
                    {
                        "created": 1698657061.2479405,
                        "modified": 1698657061.2479405,
                        "name": "/dev/shm/incoming/2023_10_30_091033_00_00_K_056.txt",
                        "sha256": "4c5c80ada5cf6195e310d51ad09dc750aed43a93e9e83b235288b8d16278aa49",
                        "size": 2045
                    },
                    {
                        "created": 1698657057.8579407,
                        "modified": 1698657057.8579407,
                        "name": "/dev/shm/incoming/2023_10_30_091033_00_00_K_056.kml",
                        "sha256": "7a726779d28936f7b8bd8041496b6eca3af6da1beba32f752beb8f99b10a954b",
                        "size": 978
                    },
                    {
                        "created": 1698657052.4479406,
                        "modified": 1698657052.4479406,
                        "name": "/dev/shm/incoming/2023_10_30_091033_00_00_K_056.jpg",
                        "sha256": "48cacccb77786c6309c6ce29f55df64cd9682169a8f681a04c6d634316d78bc5",
                        "size": 584020
                    },
                    {
                        "created": 1698657046.3979406,
                        "modified": 1698657046.3979406,
                        "name": "/dev/shm/incoming/2023_10_30_091030_00_00_K_055.txt",
                        "sha256": "eee0be0edb44367f1d54beb39a1e28da3b6c78544cb14432901b5269c6a0abc0",
                        "size": 2045
                    },
                    {
                        "created": 1698657046.1779406,
                        "modified": 1698657046.1779406,
                        "name": "/dev/shm/incoming/2023_10_30_091030_00_00_K_055.kml",
                        "sha256": "50753395b4c6f2b918c25e1c3bb723c91115ffde546fe3ca6bf939f4819f6bf6",
                        "size": 976
                    },
                    {
                        "created": 1698657046.0779407,
                        "modified": 1698657046.0779407,
                        "name": "/dev/shm/incoming/2023_10_30_091030_00_00_K_055.jpg",
                        "sha256": "9f70293e2ca3df09a8ac90f405cb5e7b4278e5b675d0439ca8328f89aa906f93",
                        "size": 903462
                    },
                    {
                        "created": 1698657001.0579405,
                        "modified": 1698657001.0579405,
                        "name": "/dev/shm/incoming/2023_10_30_090937_00_00_K_054.txt",
                        "sha256": "5236548a649ba67516b8d3a05ec2da8bdb61814324c84aac17251ce32b0dc64c",
                        "size": 2045
                    },
                    {
                        "created": 1698657000.9879405,
                        "modified": 1698657000.9879405,
                        "name": "/dev/shm/incoming/2023_10_30_090937_00_00_K_054.kml",
                        "sha256": "ef97e7f3a65db57b4674407d976538614305fc0dad669db5bb5e2bad32ed9d3d",
                        "size": 977
                    },
                    {
                        "created": 1698656996.4979405,
                        "modified": 1698656996.4979405,
                        "name": "/dev/shm/incoming/2023_10_30_090937_00_00_K_054.jpg",
                        "sha256": "e1d57c5ecd39714c630407934cb6083d3db3ba907b9142644610b9685b756e6f",
                        "size": 969651
                    },
                    {
                        "created": 1698656990.4879405,
                        "modified": 1698656990.4879405,
                        "name": "/dev/shm/incoming/2023_10_30_090928_00_00_K_053.txt",
                        "sha256": "e0568d772c9acce1c7ff389e0fd34cac2ad39967ae230b392b7ea9a16c0e8457",
                        "size": 2045
                    },
                    {
                        "created": 1698656990.0579405,
                        "modified": 1698656990.0579405,
                        "name": "/dev/shm/incoming/2023_10_30_090928_00_00_K_053.kml",
                        "sha256": "87f4e5041f8720a9e8d8c744733e9b64dd010cc702daa86f7384a6e829ea0e5b",
                        "size": 975
                    },
                    {
                        "created": 1698656989.6879406,
                        "modified": 1698656989.6879406,
                        "name": "/dev/shm/incoming/2023_10_30_090928_00_00_K_053.jpg",
                        "sha256": "71fa985e4bfdf4d4bee346190d523b80159372d829cec4d7b741853a1c08bb0d",
                        "size": 1061994
                    },
                    {
                        "created": 1698656969.1579406,
                        "modified": 1698656969.1579406,
                        "name": "/dev/shm/incoming/2023_10_30_090904_00_00_K_052.txt",
                        "sha256": "28faf1e98acd8c022f89dc06ce4cb649f8669cccae01f28b7c4a4a33508e98df",
                        "size": 2044
                    },
                    {
                        "created": 1698656977.3679407,
                        "modified": 1698656977.3679407,
                        "name": "/dev/shm/incoming/2023_10_30_090904_00_00_K_052.kml",
                        "sha256": "89fe8eceb27f06bfdbe9565cff4965f43e7a96e6808b2450fa9fe2db588d7d12",
                        "size": 977
                    },
                    {
                        "created": 1698656968.6079407,
                        "modified": 1698656968.6079407,
                        "name": "/dev/shm/incoming/2023_10_30_090904_00_00_K_052.jpg",
                        "sha256": "867eb57fe85bdd3d736745f3a0b06c84593dfb2ae96575b6541c1a07c53cf093",
                        "size": 1152848
                    },
                    {
                        "created": 1698656956.9479406,
                        "modified": 1698656956.9479406,
                        "name": "/dev/shm/incoming/2023_10_30_090858_00_00_K_051.txt",
                        "sha256": "c7677342b7e10c1bacba633cb9b477548b6ace4352ff4caa51f6004e96e52916",
                        "size": 2045
                    },
                    {
                        "created": 1698656956.1579406,
                        "modified": 1698656956.1579406,
                        "name": "/dev/shm/incoming/2023_10_30_090858_00_00_K_051.kml",
                        "sha256": "abb5236efbbf9a355e747ac8c1bd0af2a5b487df8a4778c9266759d884901549",
                        "size": 978
                    },
                    {
                        "created": 1698656965.8479407,
                        "modified": 1698656965.8479407,
                        "name": "/dev/shm/incoming/2023_10_30_090858_00_00_K_051.jpg",
                        "sha256": "ba07f332a7cbe8996d79ec9b37aa2d3601bbaf3b8ed3d7ee486852673edb30e7",
                        "size": 1176432
                    },
                    {
                        "created": 1698656954.0579405,
                        "modified": 1698656954.0579405,
                        "name": "/dev/shm/incoming/2023_10_30_090854_00_00_K_050.txt",
                        "sha256": "834dd1c3c224cf0be472fff0be9ef143f8aceb8877927c356fa2fb8806a1de26",
                        "size": 2045
                    },
                    {
                        "created": 1698656953.6579406,
                        "modified": 1698656953.6579406,
                        "name": "/dev/shm/incoming/2023_10_30_090854_00_00_K_050.kml",
                        "sha256": "45e4d7eec882df8d01be8c5ad587a348f3a9518963335bd83b0cd6b0d9e53f38",
                        "size": 975
                    },
                    {
                        "created": 1698656956.2779405,
                        "modified": 1698656956.2779405,
                        "name": "/dev/shm/incoming/2023_10_30_090854_00_00_K_050.jpg",
                        "sha256": "b0a7dd2e74698d8a887390f1463ac7d5bbbf564ca43c772b6c7ce0c7400c01a0",
                        "size": 1301088
                    },
                    {
                        "created": 1698656809.4379406,
                        "modified": 1698656809.4379406,
                        "name": "/dev/shm/incoming/2023_10_30_090619_00_00_K_049.txt",
                        "sha256": "b7c3a74ae5a3a079333aff5b18510d7a273892e8b71cf905084002c32d5fa6ff",
                        "size": 2045
                    },
                    {
                        "created": 1698656819.3579407,
                        "modified": 1698656819.3579407,
                        "name": "/dev/shm/incoming/2023_10_30_090619_00_00_K_049.kml",
                        "sha256": "1c9662095c6e68a0e1e2fcaa086c0f55c373ededc976bc2353b10d9d88123a5c",
                        "size": 978
                    },
                    {
                        "created": 1698656798.7579405,
                        "modified": 1698656798.7579405,
                        "name": "/dev/shm/incoming/2023_10_30_090619_00_00_K_049.jpg",
                        "sha256": "3cfbe0a6a2619e17cd75bde017f8f5f21a1c13f410e9f113ce38616e9d35189b",
                        "size": 1178476
                    },
                    {
                        "created": 1698656809.7379405,
                        "modified": 1698656809.7379405,
                        "name": "/dev/shm/incoming/2023_10_30_090614_00_00_K_048.txt",
                        "sha256": "0379cabc71dec17d9b9a0def792ba44134f0138f0fedea7321ca3ae50f7b129e",
                        "size": 2045
                    },
                    {
                        "created": 1698656797.6979406,
                        "modified": 1698656797.6979406,
                        "name": "/dev/shm/incoming/2023_10_30_090614_00_00_K_048.kml",
                        "sha256": "8d3ad3ab3d9b8e7f1ef6ed8840f9163782a4eba3f3ba86fbc9178ec2501a83cc",
                        "size": 976
                    },
                    {
                        "created": 1698656795.7979405,
                        "modified": 1698656795.7979405,
                        "name": "/dev/shm/incoming/2023_10_30_090614_00_00_K_048.jpg",
                        "sha256": "a510f30746ef8def8476cbf0e5d208ea5a57fdf89757e75d257f15e74c6e1046",
                        "size": 1114976
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "7cf5ba0c2f9f9638f3cdec406440160d99145fe0619b30ea28ed0fa36952f46f",
                "size": 10470186
            },
            "errors": "",
            "filename": "2023-10-30T09-13-59Z",
            "size": 10470186,
            "verified_at": 1698683096
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698658063,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "libTipQS9MIZA1RtCjNLyjuaJEghwUecpX5rQfwj8dmwkNLMqBjPFXgGHhFIe5rPzxToT/hWGOIsW9j4aEXO+Fs7t7yAX2f6csT9aoGC4tdiT/gI9za8mX3/1QfMj5NyoR/PNJ7tgBJ8PYzZieiRjmWAMX3Oh3qL+Rh7g87KlKBaa0QPJyuoToPqA8q7Zq2zOE9y5N43N6N/yVvI7Pvjao8JjZPIOyS2ZJHpFNICDT86MuGCjya3qjvfI+6+eq73kys4d6AcEHgTxi8MTBZDO5ZOIgih462ghyE0ZtIYSpin4wP798fmQDqaeMRVoDgI+HFpran5WEM3bc1ptKTJRoTssMyhtBmtjfN8rOMoT8oqwqxuNSnhWxqohtI+gwDzTfuFGjMKkpXBR6KVseN21BD6bbsFWovdvfkpZLGc9w9eX86Bi5/JIICfVnMi7jnXkl0uOnGGVy1WNvuHBHP/uDLmSjo9R59NbRX5m9tvxiPurKEzdArzBanzTr8Fu4C3AtDngLE5mg8uz+ufg2S1UC5utUfpgyAATxw81oFm2emIC8sgQkqB3Y+JQAiIoXD2B1c8safP04iFfkvKYXtSECj/IJBk/qiQzZtU9/gLMHOwhOR9q04MlLVE9k/hX5fOaTqU1E1Sck//c5mmu01exBBOTwwU1SAF/j7SxMDBLfk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-27-25Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698657917.9179406,
                        "modified": 1698657917.9179406,
                        "name": "/dev/shm/incoming/2023-10-30T09:15:15.ais",
                        "sha256": "b195a4a4ed9bf764d5f44cdd2f18677d9a4e24ae7bfc574733c4e061c7318911",
                        "size": 306794
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "04ac2644502f4df75104092dbf7c0e0d5a509694405a8df7e6b767798d515337",
                "size": 92149
            },
            "errors": "",
            "filename": "2023-10-30T09-27-25Z",
            "size": 92149,
            "verified_at": 1698683124
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698657775,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "NH4O1f+aDKxksM/8vPB30nTmf/faaAUUOVRccS+l0uJgtxhv4t/C6WZU7MwA9tEtoLzqmGFI43AiFZ+2VqF5Cfc4DLp6U9pYAuK1AV7OK25VsQNzAaDBfIpbuWIfL2dafBz3h6Qw3oXYTsPUbMVUGZvg6ZzBZCPXInrf26HSfnzLFRc2ZmdXlIgiUSL5oiFT6kQE2EfXYdWbUttQw3FXI/RWuIpGKBU7isD5yxYjlyJMoqJgIaAzsFXBwE6Lc7sxJubp3iwpd5QxP3my7wmtnQhgDr8acl+3xozo66FxsCTZaYQYmOV5YVXQxeQAjlm2nTUDWfKS5yqcO7RYodu4/lxHmm7Ib442vrJPXi8Nvq5qPvUFE8K/JaKQGEuXhnBPCLhFUgFMoofLYmHEFD9jVDXdaZCw7lo1Rx+0YO+268D9FMjzEEVh4vpOMtnKYH668Vch2Jx3R/j25bqEh4tlIRFAvZ2mdntLB09xdD6ZzSv3e1HvE5522tzWWmtt3EWISBolRX73nGfXIH7UJP1aE6cYBssfuvTcgqFTOpy5ZiNI15WMVYbEE4pXP8QqMePaPnm5iog2E2fNCIhprD0x+cdqNIst/txeC6Zx8iuYL6EG0Uz63Sol+aiHSB+vST8ZeV1XX+9rocmqDBs+OauvbBFmhm5PyvKoILd1W75+0OU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-21-31Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698657652.3778288,
                        "modified": 1698657652.3778288,
                        "name": "/dev/shm/incoming/2023_10_30_092039_00_00_V_015.txt",
                        "sha256": "53baedf4363bd52ade0ace9e9fe53468b3cee3714d5593dc55e831c2fd95b257",
                        "size": 2021
                    },
                    {
                        "created": 1698657652.0878289,
                        "modified": 1698657652.0878289,
                        "name": "/dev/shm/incoming/2023_10_30_092039_00_00_V_015.kml",
                        "sha256": "655b81018586aaf7b6cac0e97c26c8c91d36270d56b6f8f5efdc5ef8ddf59705",
                        "size": 975
                    },
                    {
                        "created": 1698657651.4778287,
                        "modified": 1698657651.4778287,
                        "name": "/dev/shm/incoming/2023_10_30_092039_00_00_V_015.jpg",
                        "sha256": "65466c6ddc27fed9508ccbf96db45841b3065c05b6d0a9e72efc48684c64d8d9",
                        "size": 779568
                    },
                    {
                        "created": 1698657535.7478287,
                        "modified": 1698657535.7478287,
                        "name": "/dev/shm/incoming/2023_10_30_091840_00_00_V_014.txt",
                        "sha256": "e7d23b0b6e880aaadb8c277e2f876d78dcb842ef7bf639e7a96d7b3388082e1c",
                        "size": 2026
                    },
                    {
                        "created": 1698657535.6178288,
                        "modified": 1698657535.6178288,
                        "name": "/dev/shm/incoming/2023_10_30_091840_00_00_V_014.kml",
                        "sha256": "cd0cba7c297df04d31ebc1d884706e1e3e83decd2465ed1e1f779296f8230b1f",
                        "size": 976
                    },
                    {
                        "created": 1698657535.1578288,
                        "modified": 1698657535.1578288,
                        "name": "/dev/shm/incoming/2023_10_30_091840_00_00_V_014.jpg",
                        "sha256": "d4e78fcabf9c501cfbca748a89aaa4506685848095051f96fe91f1a5d9990743",
                        "size": 939557
                    },
                    {
                        "created": 1698657523.9978287,
                        "modified": 1698657523.9978287,
                        "name": "/dev/shm/incoming/2023_10_30_091823_00_00_V_013.txt",
                        "sha256": "2dfd61a07375b061e68522ae4ba17f764dea7cc448914a5221d9fc10b0cb642d",
                        "size": 2027
                    },
                    {
                        "created": 1698657523.567829,
                        "modified": 1698657523.567829,
                        "name": "/dev/shm/incoming/2023_10_30_091823_00_00_V_013.kml",
                        "sha256": "16a74799a00847b14e7b371d04dc1199a7da4ac08dd015a4b2ce45b25b77efc8",
                        "size": 978
                    },
                    {
                        "created": 1698657523.4678288,
                        "modified": 1698657523.4678288,
                        "name": "/dev/shm/incoming/2023_10_30_091823_00_00_V_013.jpg",
                        "sha256": "044c1fdee70d1344097760c4b8d8a8896f5f5412c29534867430ac4df8a67d86",
                        "size": 875837
                    },
                    {
                        "created": 1698657492.7678287,
                        "modified": 1698657492.7678287,
                        "name": "/dev/shm/incoming/2023-10-30T09:08:11.ais",
                        "sha256": "4b9c6c7954dd96cc9e5168ebca27c2a6ccd27ba091dc0e1ecccdc707e152da52",
                        "size": 633380
                    },
                    {
                        "created": 1698657491.067829,
                        "modified": 1698657491.067829,
                        "name": "/dev/shm/incoming/2023_10_30_091749_00_00_V_012.txt",
                        "sha256": "7b5c7e7a555af0ada76304e54e1cf8b4d11a707d20b957f8278ce97d5f52798a",
                        "size": 2031
                    },
                    {
                        "created": 1698657490.9778287,
                        "modified": 1698657490.9778287,
                        "name": "/dev/shm/incoming/2023_10_30_091749_00_00_V_012.kml",
                        "sha256": "51a643f5c5f5c182ec8d82a49b494d91449c871d0a186ad2ca1704bee03f56a9",
                        "size": 977
                    },
                    {
                        "created": 1698657490.3678288,
                        "modified": 1698657490.3678288,
                        "name": "/dev/shm/incoming/2023_10_30_091749_00_00_V_012.jpg",
                        "sha256": "0820449b4fb7d8dfcd735e30cadcc393ef5a0c0e2fe851f94a87d2f015b2de82",
                        "size": 782099
                    },
                    {
                        "created": 1698657489.307829,
                        "modified": 1698657489.307829,
                        "name": "/dev/shm/incoming/2023_10_30_091713_00_00_V_011.txt",
                        "sha256": "eb49fb2ab7bcb7c86b5b9f9e1daec09ff5a9ee7e78ce5232ef613b6af24375a2",
                        "size": 2028
                    },
                    {
                        "created": 1698657446.8678288,
                        "modified": 1698657446.8678288,
                        "name": "/dev/shm/incoming/2023_10_30_091713_00_00_V_011.kml",
                        "sha256": "4e1981b6a6babf6b95b0c1cbccd483838875a9826b252226fd6d7b10ba7fffd3",
                        "size": 974
                    },
                    {
                        "created": 1698657446.4678288,
                        "modified": 1698657446.4678288,
                        "name": "/dev/shm/incoming/2023_10_30_091713_00_00_V_011.jpg",
                        "sha256": "603c9daa7d27f7fd54e06595a18d908ea357a95fb4e5ad7e8b4f204a057f51fc",
                        "size": 987466
                    },
                    {
                        "created": 1698657686.067829,
                        "modified": 1698657686.067829,
                        "name": "/dev/shm/incoming/2023-10-30T08:51:25.ts",
                        "sha256": "5dde870c34cbbb6b3a39b6418023765d4cad7f0495324a8c46671c14053bbea0",
                        "size": 427276812
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "3852e86ac859cfcea5d1ffa8b82b298021691915f59c6b8550df2331588f0dbf",
                "size": 405457495
            },
            "errors": "",
            "filename": "2023-10-30T09-21-31Z",
            "size": 405457495,
            "verified_at": 1698693562
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698658265,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "S7AfHkJOSkEcbwipn59deLHxa6IPUotVL3C2y025Pu0mRFO760FlhBmsYoaYhMOGWod0BbAK3/AyiMzkzDMPz09rZq+Y8PqQYp/91wm9t1TXZZkmvTgafb4ohbTJJh1iS+kFDwEx0fXNVzSF8FPcTJPZfEIpcdPrvretwDR2JkvfM5fJydQyOi1bdPOFUrTlnEWso2Q6wk0mfUicoTLcA+Gkl9c9BGZQ5UR6Xr/UTZzcD8bsZtFO4leQ1dlFQRiOCJtH7NHkHDzJzlYKKFb23aVS9gttwYdGQ5o7ZLU2VcN8ftrTcwOXhJ6il+RmCNEU0/wTezFVp8wtLyNJNZnjTNE4gjBnOE1Cr/niR5fxdHR0ckyoBgiUYbmYfwxeFl/A2H/cYni4dVbag00ZYCYdMV9LndxlZHhr38a7I/VzvoiADEBsuUmZgp7oaGpWYxt9D1TIe4rHn3o+2mL7XkfhvXYlAUvUFk9EJjqfWFxBDLPViqkoPUuoz23VCBxaNVO8up/4zgug1cmapHufjyJhZTKcd5kP4Ge1OZhf4ZubZZ6eWUvD3Yet7Bdv+1ANussgSIL06CXT5mtZ3DOk+SwBlqhIPJMHE+4CqJOrnsv2vNSrbU1KwIIP38Ndq5D6aONS73AekEpsCZgKcklKBtUYFmQK/hOusw+hvbj59dIHezI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-30-41Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698658117.036036,
                        "modified": 1698658117.036036,
                        "name": "/dev/shm/incoming/2023-10-30T09:18:30.ais",
                        "sha256": "46ab3f137ee8ebc054438b83df00a47fc06493077beda80922e13a9f6c042573",
                        "size": 140088
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "25c5cc97d176b3cb7fc117c8ad0dfd77c34884c3eb1b69fa861b93aa71f1c53b",
                "size": 38174
            },
            "errors": "",
            "filename": "2023-10-30T09-30-41Z",
            "size": 38174,
            "verified_at": 1698667278
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698659026,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "uycNxok++blWYzCyKAGYGYIWqAWBPB6qeEYgDGj5Xjh4i9m1KUlu0A14KeepV4bt9vicg+zys82ibB/qQNFXxporA8PZsFOfBNuJk26scldSqLBPk86ddN9j87jUIWZCmuSZUF1Vu+vkzHvbT3+5oF7ukJHQ+NAzQmxjEXrNYf1eleTKQ0Y9lwdDApS7Ti+EV5WSiw4p5Ob9cS1IptwQ1oHwBuBXpUIyVDp5J6K5CGPvNJ8RTCQaVUBI9vX0me3FfgF9VkaPB4U16HtTirVO2SlHLM6oJzq8NUUGTTdDb10t0QmAGs9THEMqj4AUubeOB/rYMUEzCOm0AjwwKxWdSlHgnq0BnXFuhUPYOwaLhp7CodUafuISxY9wdWkkach9TSSMyiIyuClNAyyUoYg5k73/ejknxaNQqQChVHISGSX9Dx69m+ys3S9pA6u5L2sc5fZSRd4jx3wuPCPyy/SV4hINtDnGdb/LDvSV7GksEXnmsISDow30QfFwX1FncyUlOEL1qxpRKKt71Vl8Qg6GMwAR4+DOdDtD2XAe4sDH72WjGTJbUnZZk2hUD1D0bn6s54stZ4d8QzDNhyzUlS9S1qLJ3PEacWN8p0WjJGXjULsQJatQUpQvTgIpj6DnlLF7boG5y/r71NfnO5medk2Ag/fIMh6WRNT1pel3aFu04mg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-43-21Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698658874.776036,
                        "modified": 1698658874.776036,
                        "name": "/dev/shm/incoming/2023_10_30_094058_00_00_V_047.txt",
                        "sha256": "85c981b491dab39ef32381a845f295f526122f6c1b6600317005707dd16455c8",
                        "size": 2021
                    },
                    {
                        "created": 1698658897.566036,
                        "modified": 1698658897.566036,
                        "name": "/dev/shm/incoming/2023_10_30_094058_00_00_V_047.kml",
                        "sha256": "ececcba55a25eb28c90c346376875f88aa0f72b3e2c3a284e928e7267bde75bd",
                        "size": 977
                    },
                    {
                        "created": 1698658875.056036,
                        "modified": 1698658875.056036,
                        "name": "/dev/shm/incoming/2023_10_30_094058_00_00_V_047.jpg",
                        "sha256": "949b40e3979494e02228aa051b820bc218513876b06a5ef67e9fe478bfa9810c",
                        "size": 970368
                    },
                    {
                        "created": 1698658769.296036,
                        "modified": 1698658769.296036,
                        "name": "/dev/shm/incoming/2023_10_30_093903_00_00_V_046.txt",
                        "sha256": "4ea7b0c524c83ddf4195cbdb98030631c4575c4fa5bc767b19ecdad3cdd1ee2f",
                        "size": 2026
                    },
                    {
                        "created": 1698658768.066036,
                        "modified": 1698658768.066036,
                        "name": "/dev/shm/incoming/2023_10_30_093903_00_00_V_046.kml",
                        "sha256": "e6085a0970db54c7da7f783d678b559bf1c309ab2b765f8a46be8fecdc79a8dc",
                        "size": 976
                    },
                    {
                        "created": 1698658766.716036,
                        "modified": 1698658766.716036,
                        "name": "/dev/shm/incoming/2023_10_30_093903_00_00_V_046.jpg",
                        "sha256": "81445a80fbf8a7088ad715b13dcad497d554621584b5839137fb47f05325d7b2",
                        "size": 1079749
                    },
                    {
                        "created": 1698658721.366036,
                        "modified": 1698658721.366036,
                        "name": "/dev/shm/incoming/2023-10-30T09:28:37.ais",
                        "sha256": "36520290c7f2c189b39e0263c227781862aff886ca390b5c9aeb8e5628082fca",
                        "size": 168821
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "00f541fe42463f812768f94373433b3c7ef76ebb1bc0da8fd747cc471c8fbfff",
                "size": 2097009
            },
            "errors": "",
            "filename": "2023-10-30T09-43-21Z",
            "size": 2097009,
            "verified_at": 1698667287
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698659011,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "EPtGdBMTaUqp2AC1UqR1CO08zOP13aXgA0JvszQoGEv1xDeN47AcRlOVI/4fpGDvNWUcObUwbop1vzTdb7XRfmjud66h04ymY/CTdbKrBZQfWroDItn3xUHjypc0gyPRkU6SeMcfF+agUb4bmGxfcPHHE/fXXKAtEJqToSoicIwdbSZHW39hY5VAoV4exxb15G9CQyL+w+Sjd79l9HsAIUmZIJQaBtHsliAB1afGXwH6J+DM80HpXykPlrduNx76tmxQnXfhcxEiQsn/bIBN6tSIT8w6K+qzVh35jTPZts4BnHEkCIAQ+AdTsjXbJHo7DeFwkXwTPVBYJd0VKOrlDVfCB7ohwCpov9sQ5DIiKxU7ey5OiFf2yYao/MqN7RCbYJQLGg/4gRrkK8+S1tpugtsJ169E00rf22icD+KEFsUmdplsaBxexz4SkLwrk5F9E9ebGzhg75Ufzn+msnD59D2hVW/vpwTQtcVnNeXXlDrjgBfQd0MulifdZOJFJ7b4xLBCzDktYc9fJj9SK3NZUpklOw8vUZxrPqQxj91o3d4FWUj27xTmE4zYVmuFqqk4sk+R8gtN/Sf1zt9NTBrp2wau7lRIzXAqLDXeWl8d+Ko4ZlzFc5gq8QhNTAF+nJ1AGYvaZN50wb3WjUZel+5aBqoH+oul/MWKK2uAqLOzHyM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-41-52Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698658787.8879406,
                        "modified": 1698658787.8879406,
                        "name": "/dev/shm/incoming/2023_10_30_093929_00_00_K_078.txt",
                        "sha256": "9c093eb1aef4e54611e3a8e3496e790bc3f7c99ddf221d1067a261f17caf2fd0",
                        "size": 2044
                    },
                    {
                        "created": 1698658788.7979405,
                        "modified": 1698658788.7979405,
                        "name": "/dev/shm/incoming/2023_10_30_093929_00_00_K_078.kml",
                        "sha256": "7abbf7aca823b26933ca55027d60095401530646a9419bc733d760a6ae06ee7c",
                        "size": 976
                    },
                    {
                        "created": 1698658788.6979406,
                        "modified": 1698658788.6979406,
                        "name": "/dev/shm/incoming/2023_10_30_093929_00_00_K_078.jpg",
                        "sha256": "1d3696899437628a22edf53e4f1e1ed5807a62282790b51c224d876bb353ba28",
                        "size": 1176868
                    },
                    {
                        "created": 1698658786.7079406,
                        "modified": 1698658786.7079406,
                        "name": "/dev/shm/incoming/2023_10_30_093925_00_00_K_077.txt",
                        "sha256": "24072fb1d446b49cff043c1036f2efa1f9f56db2a09068a0fa91c472a44b0ac3",
                        "size": 2044
                    },
                    {
                        "created": 1698658786.5779407,
                        "modified": 1698658786.5779407,
                        "name": "/dev/shm/incoming/2023_10_30_093925_00_00_K_077.kml",
                        "sha256": "12f1cbca00f199d3cda154fec778cfc84262cc035a1344b1a4607bd2b0d38826",
                        "size": 976
                    },
                    {
                        "created": 1698658785.7779405,
                        "modified": 1698658785.7779405,
                        "name": "/dev/shm/incoming/2023_10_30_093925_00_00_K_077.jpg",
                        "sha256": "9811d2b00069ef57da8eace9b3a999844eaf9677df3b052da78bd4f0bf3089a4",
                        "size": 1315623
                    },
                    {
                        "created": 1698658784.6979406,
                        "modified": 1698658784.6979406,
                        "name": "/dev/shm/incoming/2023_10_30_093923_00_00_K_076.txt",
                        "sha256": "39ae45b3129efde2db5088ada152899f41f01c64de88fe3f8e9963ac2bd06ec0",
                        "size": 2045
                    },
                    {
                        "created": 1698658785.5379405,
                        "modified": 1698658785.5379405,
                        "name": "/dev/shm/incoming/2023_10_30_093923_00_00_K_076.kml",
                        "sha256": "4de3aca02d9ec9cb2aa6d3227431bdf12ad9d10fcb611786eb4b5681d313dcec",
                        "size": 977
                    },
                    {
                        "created": 1698658784.1479406,
                        "modified": 1698658784.1479406,
                        "name": "/dev/shm/incoming/2023_10_30_093923_00_00_K_076.jpg",
                        "sha256": "7a89e25f29daf6049a07297f9d5111011580cbba8022c86a72d2b20b68f24931",
                        "size": 1256640
                    },
                    {
                        "created": 1698658750.3279407,
                        "modified": 1698658750.3279407,
                        "name": "/dev/shm/incoming/2023_10_30_093856_00_00_K_075.txt",
                        "sha256": "6f4d0a1e0f9f907685833f7c7925c710a3422a71c8ad1c65013ba46a6385fa27",
                        "size": 2045
                    },
                    {
                        "created": 1698658759.4779406,
                        "modified": 1698658759.4779406,
                        "name": "/dev/shm/incoming/2023_10_30_093856_00_00_K_075.kml",
                        "sha256": "81fc651401333827be41498934e8c6ff4762fd2afcb72b4b901b9b64fa2f2e85",
                        "size": 976
                    },
                    {
                        "created": 1698658759.3679407,
                        "modified": 1698658759.3679407,
                        "name": "/dev/shm/incoming/2023_10_30_093856_00_00_K_075.jpg",
                        "sha256": "ceb563ec21d8d815b91119d7145cec64482ba3ec9cbb9e3b235cfb46df9fc2a1",
                        "size": 1204324
                    },
                    {
                        "created": 1698658746.5679407,
                        "modified": 1698658746.5679407,
                        "name": "/dev/shm/incoming/2023_10_30_093844_00_00_K_074.txt",
                        "sha256": "d4b1136984cfbc0709eee6fa112441312dcef407d8d4a59f89d8aa773332a8fa",
                        "size": 2045
                    },
                    {
                        "created": 1698658740.9479406,
                        "modified": 1698658740.9479406,
                        "name": "/dev/shm/incoming/2023_10_30_093844_00_00_K_074.kml",
                        "sha256": "24513d54225d7d338e3b0ae243438effced0188aa91c777ca933be00b720bf93",
                        "size": 976
                    },
                    {
                        "created": 1698658747.2379405,
                        "modified": 1698658747.2379405,
                        "name": "/dev/shm/incoming/2023_10_30_093844_00_00_K_074.jpg",
                        "sha256": "aaf46709b7e6782d143c0634955194e2d33f049441adfb6b3ece07d14d324075",
                        "size": 752228
                    },
                    {
                        "created": 1698658727.7079406,
                        "modified": 1698658727.7079406,
                        "name": "/dev/shm/incoming/2023_10_30_093825_00_00_K_073.txt",
                        "sha256": "8bc0b11f16fda9430b0a44026ef427d946637e1e446aa56229aab666c5dd85e9",
                        "size": 2044
                    },
                    {
                        "created": 1698658727.4779406,
                        "modified": 1698658727.4779406,
                        "name": "/dev/shm/incoming/2023_10_30_093825_00_00_K_073.kml",
                        "sha256": "04aff800963701ba2e0ad64e6a2e83226e2a8b1697e303db9b9144bcca3487f4",
                        "size": 977
                    },
                    {
                        "created": 1698658727.1279407,
                        "modified": 1698658727.1279407,
                        "name": "/dev/shm/incoming/2023_10_30_093825_00_00_K_073.jpg",
                        "sha256": "0c10fcccad2b52d1a597233ca802f5bea731d773bae556a1203cd4ebe1dd8a0d",
                        "size": 1115848
                    },
                    {
                        "created": 1698658691.2979405,
                        "modified": 1698658691.2979405,
                        "name": "/dev/shm/incoming/2023_10_30_093741_00_00_K_072.txt",
                        "sha256": "d95b89426b0c11aca21a01cfbd5e153b6840243c1119523072da4a134a27d5b1",
                        "size": 2043
                    },
                    {
                        "created": 1698658690.9779406,
                        "modified": 1698658690.9779406,
                        "name": "/dev/shm/incoming/2023_10_30_093741_00_00_K_072.kml",
                        "sha256": "c8316f6895a9de7cabce2528060e5bf01af010426e77cb5f8575d37e4218a803",
                        "size": 975
                    },
                    {
                        "created": 1698658685.4679406,
                        "modified": 1698658685.4679406,
                        "name": "/dev/shm/incoming/2023_10_30_093741_00_00_K_072.jpg",
                        "sha256": "b70f4d96c38ae34719c2aeb2a048e3fae1fb087c50cafdc693d554676a76b965",
                        "size": 1069880
                    },
                    {
                        "created": 1698658638.5279405,
                        "modified": 1698658638.5279405,
                        "name": "/dev/shm/incoming/2023_10_30_093703_00_00_K_071.txt",
                        "sha256": "78c3c6da4a1ea90f43fa0961cba084acbd3f466fbb65149fb0ead1b8101ff1af",
                        "size": 2043
                    },
                    {
                        "created": 1698658641.4979405,
                        "modified": 1698658641.4979405,
                        "name": "/dev/shm/incoming/2023_10_30_093703_00_00_K_071.kml",
                        "sha256": "2d49b264fd9f774338fa671179c23200b30dc2d137a6600a31b8d5f5bff13031",
                        "size": 977
                    },
                    {
                        "created": 1698658641.2579405,
                        "modified": 1698658641.2579405,
                        "name": "/dev/shm/incoming/2023_10_30_093703_00_00_K_071.jpg",
                        "sha256": "92124fa861c04bd2d5481c99964b9b71e240da5dfce131faf9ccfb7e95bcec8e",
                        "size": 1169288
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "44382f28359db9cea43e7fb2d0afeea58d06ee0dc37a5302a8cd40d7e4b6d32d",
                "size": 9065785
            },
            "errors": "",
            "filename": "2023-10-30T09-41-52Z",
            "size": 9065785,
            "verified_at": 1698683128
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698658661,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "ZxtPSXe1mzt9MDPXsWwQqmXn/q2qlfNrR4ZKUS9+mQFzkd0LfLyqClD9JYCdLyNpxL1V8U+rnnLD/jxnkFa9Xx6BKUDg1G0IbM1oYeiMfen9rOdyodkuZmWAY7l7SJlYkTM8b+jtNUeZgMsjjfXtKS5ayfKHh7ApKkOKD0FzHze/X+imAEPzodJ+Oqy2J/h1xBT9Ngzj752ZsLcnrYNvh39fOQ0NLpB6WfTvCqGhlHIjQ4c1GVFKhgHRdHcf1SecEJXd8wvaXHjNrVFh9R4ATVFQe5IzwEQQW56DL+u8LXo2CmavSSeaHGZrzb+HtH6Y3K5WqZCvLKBQtE/W+G83P6DtVye+TfVOqklIfTVI1qs22aXxWoVPpCXvRabbVnDmFeH33t2s2nZxlpBSXQlbR9MMpwEC+Mpz1eLq7pJfthP0fttqDcobcgpmnL5+DY8gj4lMqDcaF1KfrS7AZB+vBB64u0+irigYoGy4l+IX+X+6pnBqkb65AFLvyp/nsjXLgCliEI3Sp3uXlQDPF3za0Vs+GRnIU38ZHm2t2O4Pa1XyWobesQkWYAoP7IBtKHgGQcGui2I+xy6cK7daG3yFgD/DPySKApNIr6alaWmaDk2ArHIsOCB0VRtPRuPs66Y2KOgUjTUJdQW3oqBfHY7y/CPQGiUbuuekUYc3XbhANy0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-36-35Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698658574.9679406,
                        "modified": 1698658574.9679406,
                        "name": "/dev/shm/incoming/2023_10_30_093546_00_00_K_070.txt",
                        "sha256": "7580a58678a744d5e7bcb8e001ac74c28180d318c592afaef9352259f0d81e16",
                        "size": 2043
                    },
                    {
                        "created": 1698658574.8079405,
                        "modified": 1698658574.8079405,
                        "name": "/dev/shm/incoming/2023_10_30_093546_00_00_K_070.kml",
                        "sha256": "b9297331eb78cd5843f0667b24169d9832e8c67f73c607b787b1f2bb307467e6",
                        "size": 977
                    },
                    {
                        "created": 1698658570.7179406,
                        "modified": 1698658570.7179406,
                        "name": "/dev/shm/incoming/2023_10_30_093546_00_00_K_070.jpg",
                        "sha256": "0b397d354c7fe5342937730beb355e7aa36e64101af8d3fb1cf7e98c8ec77216",
                        "size": 749710
                    },
                    {
                        "created": 1698658595.1979406,
                        "modified": 1698658595.1979406,
                        "name": "/dev/shm/incoming/2023_10_30_093541_00_00_K_069.txt",
                        "sha256": "aeb125f08295425ade6265ed163f4cb41a47e9f294cd1dbe40fde4bfcb66d5b9",
                        "size": 2044
                    },
                    {
                        "created": 1698658563.9579406,
                        "modified": 1698658563.9579406,
                        "name": "/dev/shm/incoming/2023_10_30_093541_00_00_K_069.kml",
                        "sha256": "a6346fd41f71a08424510cf9fb36011e02ba3a4d59b78b89a019b1a5edc4db6b",
                        "size": 977
                    },
                    {
                        "created": 1698658566.8479407,
                        "modified": 1698658566.8479407,
                        "name": "/dev/shm/incoming/2023_10_30_093541_00_00_K_069.jpg",
                        "sha256": "032877bffe8e4ce91fbf2c6afeacdd1fc63750f4cdd616dce7672a37060bb1e0",
                        "size": 754652
                    },
                    {
                        "created": 1698658552.7479405,
                        "modified": 1698658552.7479405,
                        "name": "/dev/shm/incoming/2023_10_30_093528_00_00_K_068.txt",
                        "sha256": "9e07e9ad2d22aa94dd398deb8eb6d712ed444a46f70d0d364dc761699745deb8",
                        "size": 2043
                    },
                    {
                        "created": 1698658556.4079406,
                        "modified": 1698658556.4079406,
                        "name": "/dev/shm/incoming/2023_10_30_093528_00_00_K_068.kml",
                        "sha256": "dc749306502664bec5e16d19b076bf585099001c2cdb05cad067a56b071acde9",
                        "size": 976
                    },
                    {
                        "created": 1698658545.3279407,
                        "modified": 1698658545.3279407,
                        "name": "/dev/shm/incoming/2023_10_30_093528_00_00_K_068.jpg",
                        "sha256": "05a36db7ed7a004d3c436d7ff7c60b89f27268e4f922b577564ccdf6db29501c",
                        "size": 977335
                    },
                    {
                        "created": 1698658543.0379405,
                        "modified": 1698658543.0379405,
                        "name": "/dev/shm/incoming/2023_10_30_093507_00_00_K_067.txt",
                        "sha256": "c46f2bf7a250c1a9c80866ab4c0f7b688e727bf4d48e669d0c6b38f2b4e8de82",
                        "size": 2043
                    },
                    {
                        "created": 1698658526.6779406,
                        "modified": 1698658526.6779406,
                        "name": "/dev/shm/incoming/2023_10_30_093507_00_00_K_067.kml",
                        "sha256": "b543e505e4dcf3493bc5d22b47c5a7bf809235516ee2f808cc4bfaf402aaef20",
                        "size": 975
                    },
                    {
                        "created": 1698658523.4479406,
                        "modified": 1698658523.4479406,
                        "name": "/dev/shm/incoming/2023_10_30_093507_00_00_K_067.jpg",
                        "sha256": "a8654661452e12a30d2f495148353edd5210ffb0c322c4dc5e8a2bec7f5de98a",
                        "size": 1273884
                    },
                    {
                        "created": 1698658521.1879406,
                        "modified": 1698658521.1879406,
                        "name": "/dev/shm/incoming/2023-10-30T09:25:17.ais",
                        "sha256": "4ac5c143b9dcea2709d14d84bd4d7a66d932c7b0fe7b4aca7fae70001528f594",
                        "size": 209987
                    },
                    {
                        "created": 1698658520.9079406,
                        "modified": 1698658520.9079406,
                        "name": "/dev/shm/incoming/2023_10_30_093456_00_00_K_066.txt",
                        "sha256": "e97d289e122d0c605503e9070ab8875dd5ebe56206f1c881500ac1b0edfa10b8",
                        "size": 2044
                    },
                    {
                        "created": 1698658520.6079407,
                        "modified": 1698658520.6079407,
                        "name": "/dev/shm/incoming/2023_10_30_093456_00_00_K_066.kml",
                        "sha256": "a94a539799da70ce8ef9becdbae6a557588d5fe06dc729beb1861ce3aa29f6c0",
                        "size": 975
                    },
                    {
                        "created": 1698658517.1779406,
                        "modified": 1698658517.1779406,
                        "name": "/dev/shm/incoming/2023_10_30_093456_00_00_K_066.jpg",
                        "sha256": "4512794456297a28cd6470d0247dfe3ca95835bc1cb561d9ff7cd99b88600d5e",
                        "size": 1200855
                    },
                    {
                        "created": 1698658497.6379406,
                        "modified": 1698658497.6379406,
                        "name": "/dev/shm/incoming/2023_10_30_093409_00_00_K_065.txt",
                        "sha256": "709259eff20c513bb0a6f84cfca3beff1e375c3b81104532098ed3b449b37c14",
                        "size": 2043
                    },
                    {
                        "created": 1698658466.5879407,
                        "modified": 1698658466.5879407,
                        "name": "/dev/shm/incoming/2023_10_30_093409_00_00_K_065.kml",
                        "sha256": "9c4c6012135adf5670f2cd3c5ff0c0fe86f558c985342b2a3c209971c6d06f34",
                        "size": 978
                    },
                    {
                        "created": 1698658475.8679407,
                        "modified": 1698658475.8679407,
                        "name": "/dev/shm/incoming/2023_10_30_093409_00_00_K_065.jpg",
                        "sha256": "23f18c8168f66a488fc7f33b41146f077dfbf2e61229b0d058bb2a5490ad2839",
                        "size": 1058003
                    },
                    {
                        "created": 1698658453.4179406,
                        "modified": 1698658453.4179406,
                        "name": "/dev/shm/incoming/2023_10_30_093344_00_00_K_064.txt",
                        "sha256": "a535c258a4de90c9159b199f2fd3ac90a432fa6f82db156e4beb9c84f58d58f5",
                        "size": 2044
                    },
                    {
                        "created": 1698658451.0279405,
                        "modified": 1698658451.0279405,
                        "name": "/dev/shm/incoming/2023_10_30_093344_00_00_K_064.kml",
                        "sha256": "60cf4b1b2a03b495af6a9cd4bdc28cb5b94e4b9c38645729676328976800a4e3",
                        "size": 977
                    },
                    {
                        "created": 1698658446.2779405,
                        "modified": 1698658446.2779405,
                        "name": "/dev/shm/incoming/2023_10_30_093344_00_00_K_064.jpg",
                        "sha256": "4a1a136a2ed1a9d8d21ab66421030fd556d06cfdebd1eb4971749457ec05b145",
                        "size": 1162001
                    },
                    {
                        "created": 1698658435.0679407,
                        "modified": 1698658435.0679407,
                        "name": "/dev/shm/incoming/2023_10_30_093328_00_00_K_063.txt",
                        "sha256": "e93b8a1f8e49245eec4055cd37539c34777fe6bc5ec32e103cc1730b69c1bc89",
                        "size": 2043
                    },
                    {
                        "created": 1698658441.2179406,
                        "modified": 1698658441.2179406,
                        "name": "/dev/shm/incoming/2023_10_30_093328_00_00_K_063.kml",
                        "sha256": "ff42d291a9706c6be60db1da75bef84f6774e9cf22dd181f2afac32d6fe7bb16",
                        "size": 973
                    },
                    {
                        "created": 1698658440.9879405,
                        "modified": 1698658440.9879405,
                        "name": "/dev/shm/incoming/2023_10_30_093328_00_00_K_063.jpg",
                        "sha256": "e5091fe1393397f0f37bf9720d5fd65b6251c4d2107b1663160911c2a6f1f115",
                        "size": 757687
                    },
                    {
                        "created": 1698658421.8279407,
                        "modified": 1698658421.8279407,
                        "name": "/dev/shm/incoming/2023_10_30_093325_00_00_K_062.txt",
                        "sha256": "bc4e5ec095bfe8a355f0d131615fbe7f4844df4f6090829fc120bf1cd13bcd8d",
                        "size": 2044
                    },
                    {
                        "created": 1698658421.2879405,
                        "modified": 1698658421.2879405,
                        "name": "/dev/shm/incoming/2023_10_30_093325_00_00_K_062.kml",
                        "sha256": "a4c966ca7df1e575d03097a86c4e75fe64f545d8b2b24c88d6eee8795a59451d",
                        "size": 976
                    },
                    {
                        "created": 1698658422.0879407,
                        "modified": 1698658422.0879407,
                        "name": "/dev/shm/incoming/2023_10_30_093325_00_00_K_062.jpg",
                        "sha256": "23fc7f3aac35f9dfee72cbc0d379e848b6f10f5ca1acf11bbf55c5786399dcec",
                        "size": 764821
                    },
                    {
                        "created": 1698658376.8379407,
                        "modified": 1698658376.8379407,
                        "name": "/dev/shm/incoming/2023_10_30_093231_00_00_K_061.txt",
                        "sha256": "b3805b49b1086ec6aeb66c4c9857a094ecd1b27bc673598d76de02acc2fb43e1",
                        "size": 2044
                    },
                    {
                        "created": 1698658395.2079406,
                        "modified": 1698658395.2079406,
                        "name": "/dev/shm/incoming/2023_10_30_093231_00_00_K_061.kml",
                        "sha256": "77a4e349e04947f369dbbe4b7611768029c8beb5e4e6076b036678f382517867",
                        "size": 978
                    },
                    {
                        "created": 1698658376.9979405,
                        "modified": 1698658376.9979405,
                        "name": "/dev/shm/incoming/2023_10_30_093231_00_00_K_061.jpg",
                        "sha256": "158a9f403467ae3f3c5635d70d2351e5115fe6212381bfbc8edb8fff692eb715",
                        "size": 1144243
                    },
                    {
                        "created": 1698658357.9279406,
                        "modified": 1698658357.9279406,
                        "name": "/dev/shm/incoming/2023_10_30_093213_00_00_K_060.txt",
                        "sha256": "c604f715a378c998799aca0b295bb925e64887dff674fc2c4277ef6397457057",
                        "size": 2044
                    },
                    {
                        "created": 1698658350.6079407,
                        "modified": 1698658350.6079407,
                        "name": "/dev/shm/incoming/2023_10_30_093213_00_00_K_060.kml",
                        "sha256": "78b6ce44562c0630690d1779268fcd8df791e09c9ee2cd536f4db8ab36629d9a",
                        "size": 977
                    },
                    {
                        "created": 1698658350.4979405,
                        "modified": 1698658350.4979405,
                        "name": "/dev/shm/incoming/2023_10_30_093213_00_00_K_060.jpg",
                        "sha256": "1b8f8972c5c4b1038f72ee309537ce65bb7c687da3166f77cadd56556136ea55",
                        "size": 1160825
                    },
                    {
                        "created": 1698658361.3279407,
                        "modified": 1698658361.3279407,
                        "name": "/dev/shm/incoming/2023_10_30_093209_00_00_K_059.txt",
                        "sha256": "7a1d6b31486f922fb57f56819c92c3e297e015ad4e37b4472723c0fcfd1305e0",
                        "size": 2044
                    },
                    {
                        "created": 1698658348.2079406,
                        "modified": 1698658348.2079406,
                        "name": "/dev/shm/incoming/2023_10_30_093209_00_00_K_059.kml",
                        "sha256": "14e95724feb17fe6d3cf84e9deea6eafd0deea045e5bc4ee42be7d4c033c4deb",
                        "size": 976
                    },
                    {
                        "created": 1698658347.4079406,
                        "modified": 1698658347.4079406,
                        "name": "/dev/shm/incoming/2023_10_30_093209_00_00_K_059.jpg",
                        "sha256": "e4d375e2e9d0253aa54dc773dce625e6ac4d18e4978cbe7ddfc0ebb6d4c175c0",
                        "size": 1146397
                    },
                    {
                        "created": 1698658347.4779406,
                        "modified": 1698658347.4779406,
                        "name": "/dev/shm/incoming/2023_10_30_093155_00_00_K_058.txt",
                        "sha256": "53826899c5039efa7fc593eee1bce703774d182f9433d0325cae981373efeaff",
                        "size": 2048
                    },
                    {
                        "created": 1698658335.4779406,
                        "modified": 1698658335.4779406,
                        "name": "/dev/shm/incoming/2023_10_30_093155_00_00_K_058.kml",
                        "sha256": "67ca6fe03c807f0416abc45250b4dc14303e859c2d6eee71e674a62c0c1ab77e",
                        "size": 978
                    },
                    {
                        "created": 1698658338.5579405,
                        "modified": 1698658338.5579405,
                        "name": "/dev/shm/incoming/2023_10_30_093155_00_00_K_058.jpg",
                        "sha256": "c549b5403a91eb18305bd6179dfcf4a85bd9dffedad5ff39a1d933e3996074c9",
                        "size": 580746
                    },
                    {
                        "created": 1698658587.5079405,
                        "modified": 1698658587.5079405,
                        "name": "/dev/shm/incoming/2023-10-30T09:06:27.ts",
                        "sha256": "b040b7d1ecc990c88e837036e0cff566417e8fd5b215947d443bc39cc5446d3a",
                        "size": 440039568
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "2df77556559855bfa83ae4bd96b9b62a4b76484d9bf497f92184aade4d8ff46b",
                "size": 409893940
            },
            "errors": "",
            "filename": "2023-10-30T09-36-35Z",
            "size": 409893940,
            "verified_at": 1698683638
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698658249,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "c/axPDgRke3cJRjQ5giicTFJtlPj6fe8JHOL3wtMzl7jd9U8U4PNSO5qXo2fkire21tWJ650Rao2YjW9FXlUSec7wwQoKDUa0V0Y99lBrFXBiLds8y6SqmhcyeWdqQcQpBCuo365oy4vX7g39Siw33zoLetvNk6DGRjs7JkqkjKnU+mef8q4FUSaBSNuxQUGHWUtfDtngJkJTyC9jJIJEszYbQC19z7Guc0DU0FIcxlkNt73ltOSOn5Glr0p41VMs3tfZmIRknbMCgANkT1MlMOrJoeaM/SgvGMmHoYunxLbrOQlNGuAJSw54Gv7yuHi2qL1JC8RfeZoInG/IHdoVmtbkOXFbuEwXiIZGNozHp5m0LS5YMwX4qYPpcxYRSAUz9kxv7ZCIAO9PYqav3TpC/x5pO8p5ACSpzvrr1m/VnsY04tHM/Awn0JoGeaR1sIwzWEkI5DM1HDdqTUInILvxxRzF5q99IMI8NPRYwfWVhH2yTgzrAXXIdYXUcolninTnJaZGo4rmiL9dF/LhKHk6kbT/rlWFNkjbia7ygrDG7oZzRK8xmfM7FL7R02/H/efnhI1/3hjLqEmYlhfyqgaKZTRpdf+ziwVy/8AQIC0MEMpLXcOsg6ggHMvsXyjYyfAZ3P76V0yefNumy0XIRz6+48Lyt0dVlQEt/TTq8YEpkY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-30-22Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698658093.5078287,
                        "modified": 1698658093.5078287,
                        "name": "/dev/shm/incoming/2023-10-30T09:18:12.ais",
                        "sha256": "d95722bee3d7798ebc1dce2f0383e69d66ad3a1a2ba2f3317786647e9825408b",
                        "size": 648318
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "03c086e10e2fcceac9e3dbdc6a55ba427252102f74d6f4f6726d74416e8aeb94",
                "size": 191826
            },
            "errors": "",
            "filename": "2023-10-30T09-30-22Z",
            "size": 191826,
            "verified_at": 1698693439
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698658809,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "li3H1TgYNpUmrhtGRoeyYQccctRx1XhTxlKPw3FHxpFJ3dvNz38AxCrpcpmhAa9sW+HfgWR7cebDwB21o+hl9gFFMNyVPUMnAEzrs6NMfynYhhAdi/cPIlITNN4GayJ5fLaD6/csRoT2sitH48N2e0vpiQLh9JDsz9T4gl+khGT3JMgwcJKslgS7zySuuOdt+FiRRWEb7uRmC7RkLGvbY1cmtrvcfOF7DE1ZBaB+goUS3MDC8aFlP4Bt2QOE60ni2B1fHz8yFwl77wTVUwVtDKeRfBhWI3oiqj0R1mQl0qX7BdxHaxbkWGNWXOGF99tXDRglflsNiiuBu8i87d8JPENb1WqR6koRBZEQuLZZ3ZL3kjCLntTA4WzDuSRYnYDMkc4L0oU7oUT0l8Xg/d6oQdOVYvA4iq2UZEihJeBo0i8ofmjJqRIv+kLZO8ix556HQ52KGwGIWOhG4g0IX11hMzroal8fmUTX+yRzPmVkbaHV88DvIBJ6VY7zaLDizAo2d4y3Z0kEbpSqtmCmQQiLhvO3Ok3rUnCHJfub3XyQ4pimo1pv8N+MX73O6AaL+uBpSj7D1tQLAVVz01mAbSt1DeYWL867HMzKuHh1pyK83Y1+ufLHXLyFheiIecoCeFdWhLEQddjg21pZEKu+aOyfwjAIDYQC+B2EzrqsodnbFcQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-39-30Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698658647.073269,
                        "modified": 1698658647.073269,
                        "name": "/dev/shm/incoming/2023_10_30_093155_00_00_V_005.kml",
                        "sha256": "f2952b1a763e6836bda25e2c522cb36d1dbb7aa0f5da9b1915585efe692f3885",
                        "size": 897
                    },
                    {
                        "created": 1698658647.073269,
                        "modified": 1698658647.073269,
                        "name": "/dev/shm/incoming/2023_10_30_093156_00_00_V_006.jpg",
                        "sha256": "6a4b75a62b5b318977d69663878d85bb75d6bf59113cd63676c22db9092f2d2e",
                        "size": 1294063
                    },
                    {
                        "created": 1698658647.073269,
                        "modified": 1698658647.073269,
                        "name": "/dev/shm/incoming/2023_10_30_093154_00_00_V_004.txt",
                        "sha256": "71707fa90b52fc3d367f6b35a047b9293d506a0a8929607ec6ca9c2bcb89baba",
                        "size": 783
                    },
                    {
                        "created": 1698658647.073269,
                        "modified": 1698658647.073269,
                        "name": "/dev/shm/incoming/Greece Area TTW - EFCA.kml",
                        "sha256": "1582c49a94eeb18685b6e21ac58e861004cc718ca8545bb45632a1a80e6f2103",
                        "size": 11125635
                    },
                    {
                        "created": 1698658647.063269,
                        "modified": 1698658647.063269,
                        "name": "/dev/shm/incoming/2023_10_30_093154_00_00_V_004.jpg",
                        "sha256": "e37f31b93fd8830d7dc0d4b3263941638616311801f66125b3f72ad9e5cbcaab",
                        "size": 1198915
                    },
                    {
                        "created": 1698658647.063269,
                        "modified": 1698658647.063269,
                        "name": "/dev/shm/incoming/2023_10_30_093155_00_00_V_005.txt",
                        "sha256": "0ce2ccd5f789cfd0b89c6a99af1d4474e5fd25bf96269d70c184c2fca79562ed",
                        "size": 783
                    },
                    {
                        "created": 1698658647.063269,
                        "modified": 1698658647.063269,
                        "name": "/dev/shm/incoming/2023_10_30_093156_00_00_V_006.txt",
                        "sha256": "51a767f3aec2460fa4a3b80a60f3e46ae2c2c67c059f67646fc67e43b1b6e717",
                        "size": 776
                    },
                    {
                        "created": 1698658647.063269,
                        "modified": 1698658647.063269,
                        "name": "/dev/shm/incoming/2023_10_30_093156_00_00_V_006.kml",
                        "sha256": "cfd60cf65ac800499bf1e20167a8a6c1008fbf7c1aeb5e78a88873e7ad64baf7",
                        "size": 898
                    },
                    {
                        "created": 1698658647.063269,
                        "modified": 1698658647.063269,
                        "name": "/dev/shm/incoming/2023_10_30_093154_00_00_V_004.kml",
                        "sha256": "fe4cc871c149f59a34f104f8f6933fb59cc162728c98df83177749f7cd241bad",
                        "size": 895
                    },
                    {
                        "created": 1698658647.063269,
                        "modified": 1698658647.063269,
                        "name": "/dev/shm/incoming/eez_12nm.kml",
                        "sha256": "bb3db46bb9bf69d42a145124e5a467d6e52adad6d8d0913cc2cc873a0f960527",
                        "size": 14120600
                    },
                    {
                        "created": 1698658647.053269,
                        "modified": 1698658647.053269,
                        "name": "/dev/shm/incoming/2023_10_30_093155_00_00_V_005.jpg",
                        "sha256": "ba0a9fde445f379928a99897186c66b634722f78d0dcf2627e615af9bacd3622",
                        "size": 1276713
                    },
                    {
                        "created": 1698658647.053269,
                        "modified": 1698658647.053269,
                        "name": "/dev/shm/incoming/2023-10-30T09:19:04.ais",
                        "sha256": "bbb74b939757808184b9b80c0f6e77806c121828d9e26f5a1f707a28c2cb4b2b",
                        "size": 3499
                    }
                ],
                "mission": "EFCA1",
                "sha256": "12d472adcbe690e4cf032de9a00a56e64510928059d2bc6260915c26ae4d59fc",
                "size": 17842778
            },
            "errors": "sha256 checksum mismatch, please check upload (a4bff9c3e8a0302555cc619746c7e5eed85188410d161c05b59a0fcbc20337b1)",
            "filename": "2023-10-30T09-39-30Z",
            "size": 17842778,
            "verified_at": null
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698674966,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "nw+x87DGHEaY85tLIOQsXy/jsjz4hEiKBm0eOfVfPhcumURQso5EiDnjrMaLPXsk+G4G+dWWILs0KqR1PAqoyHVHozIR41wWrJ8QMK4Qn/wMm4g58fpagKGPzVea5RJZJlsfq2lybH26uB5iHxPo+H9FF9LFC1q2ufWV4ADco/w9t6v2oP82dA01pAmrnlSLNcQrGkes/hziLgnFw1uBdsS5I2f9IRqlhCe/iSEuPPUMAuWvbdBnhKjJMT7CcyLgeGGEbV/Cd+IPdT1jBQ3haPyCeUzt/nNId70t3Bm9NeBbdMqp0WhNMKQdp3CyTkQfP/bdx4dsFBi8fRBS7rGJkh1HTH5X+nKVivisl7fDyGNs3SXYTCj/8BX3a33Na4cC+RY1ihdoRIMVYuq9k+dhUo2UYe/HHXY6vtfLojyLrh2STpC4GY4IN2+WSYGWCTZ489fVQLbwk8wDNqJasE90dsuFkWwcoJn0+IllsC0mINs0tg9HHdJ8e3ix+9FPhzKY4D67S3FnzoVwDoMe0NVo40gQxs1YpZGLqgtk1X3J/pIF7xsgI0u+wQ2CZg65JWz2VzJ0OwJ4aJVUldq9Gd8DDp2zGr4sQt9hmM+DpTXQtGX61CC3Dz7ISH0NEolSpDfUckd8dApeloY2yqq/dtAUtyTcyAjuscYALO7CJXhIits=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T14-09-13Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698674826.0986981,
                        "modified": 1698674826.0986981,
                        "name": "/dev/shm/incoming/2023-10-30T13:57:04.ais",
                        "sha256": "5130080c981a78dd2bd249f2984af91c372ca6641d9add043a677a24ee29bb69",
                        "size": 27195
                    }
                ],
                "mission": "OSP2",
                "sha256": "0ae3d3f59629e0c0e9fe7deab981edd6905c6f65d0ab3c9bff6381afdd35f168",
                "size": 7382
            },
            "errors": "",
            "filename": "2023-10-30T14-09-13Z",
            "size": 7382,
            "verified_at": 1698753087
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698749637,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "IT6aG5fQAzPwZlc4hXeBfVx+xHRPfHvxoDbSzPxwADT5Vm970nXb2/BhsbiSFnAPdOCZkE1AKuf1KL4KDMZxFG5GISqzcbi3761Ex8t9ZrvAGibh7jFd3a16MAmQzNRpuCpOvfrtijbv7Ab5ZjmZQL/MNPTNI7ufDACHQKmYK68HgNOm74ebfOBhvbE37S+UuYfi7kbAj7ZU9CmkZ6/dz8khcyVg8SZTkarEQLig+uBGax1MXWt7zYZyducKJEY039cVhNZ2FvSSLjmPADTYhArghoiwPd8tzFgnsRKJW/aRBtcN+GMI7qivUf42H3minzOjC8X1IguganOC/adpDGWIqgISFtDq71JVjS5m9kUwyUKQsBxGqzcXUa0bf/+PToI+XEg1d8elCH5meuLBF9SN7Mo+ifMoywcnuGno44jWlzU1I8g3lahWf+Eg4kQtnRSCP4xmYMMaeJEulUDtdVqNMF0G0obEd2/n5GKfoNer8JK+FG3Xgwu0PIa8MJFSmwEWzNbR9BgmRHRwt5CHAfEcN4sGLdaWK6Y0Q2BG9S4oP5bQ6CAMExqz1JlPkeuv6m/+Ov0CZImZ5hgLmBdr7T4GPNhRBXnyiddicd8vZ0SZeC/q6qFuc/uTccmjPE3FMf491kj38p+L6h2HPLNE5yGZNcshHLFCtJKBXZuF9gI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-52-01Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698749397.71126,
                        "modified": 1698749397.71126,
                        "name": "/dev/shm/incoming/2023_10_31_104929_00_00_K_124.txt",
                        "sha256": "fec9ed4b352a31fe145beeb22d84b95daed1ec2062f3510c43502f4c1167ff24",
                        "size": 2037
                    },
                    {
                        "created": 1698749403.84126,
                        "modified": 1698749403.84126,
                        "name": "/dev/shm/incoming/2023_10_31_104929_00_00_K_124.kml",
                        "sha256": "9aad3427a29a37def7e683ac1ce0a64be085473681bdb773e316fcfabbb242cb",
                        "size": 972
                    },
                    {
                        "created": 1698749403.88126,
                        "modified": 1698749403.88126,
                        "name": "/dev/shm/incoming/2023_10_31_104929_00_00_K_124.jpg",
                        "sha256": "8ae81528fa2d1ae5d480de82781ae6bf2669e289ad40d8a9ecd4d28da85a7b46",
                        "size": 1152107
                    },
                    {
                        "created": 1698749393.42126,
                        "modified": 1698749393.42126,
                        "name": "/dev/shm/incoming/2023_10_31_104915_00_00_K_123.txt",
                        "sha256": "b431afaa8721c97f4f13caa462aec9475f1f8ba310c725d87ebbd303b6e3b586",
                        "size": 2044
                    },
                    {
                        "created": 1698749373.05126,
                        "modified": 1698749373.05126,
                        "name": "/dev/shm/incoming/2023_10_31_104915_00_00_K_123.kml",
                        "sha256": "bc78fddf59bb9faf01cf04017a4d33deb9748d1b26a7fd270b15970eabccc22d",
                        "size": 976
                    },
                    {
                        "created": 1698749379.11126,
                        "modified": 1698749379.11126,
                        "name": "/dev/shm/incoming/2023_10_31_104915_00_00_K_123.jpg",
                        "sha256": "dbef03f555f7e709f5e2ff486ad446510d6f9bbf36b19135151a78f9a25687ec",
                        "size": 610254
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "75f12af756e0147060ed7eb8b60376ba0c44b55f850b6f3b351930098c63e6b1",
                "size": 1754569
            },
            "errors": "",
            "filename": "2023-10-31T10-52-01Z",
            "size": 1754569,
            "verified_at": 1698768045
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698659582,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ZSFFJduJQJDn5ms4yS7Xjt/3+RxopXn+a3FuZdTT2bQV3MZO++Fb3XValUx4SgcG6+GdEzXqU4N7hqh38+Qz+wZULx19l8uKb+OSbgeFCQWHLtJeV7HyLHRMLk/Kg0L9N5OIre80Wx2uZlXwcwmFgYT9WUksZgGYr0cggOmHFlcbSO8phZF5zXO4rWrxPRLcAwog1M8Vxcr2/O4V92XgUj0zpAOWB9fi31j5iB0rkZFRcGUXnumvVD0KDVc17E46EaXMBTDZGlcgSlfRYAWut7XM30hPX7JDYbm5x2rVNaWYKa9/MmVW0lXN3PoLxcVsWK2ETEi44jItMWN2DshgNP8uMNIA5P77cEWKBOtPfwyP1g8wjYs/X2utzm65cDOkuktKqlrnJNfRgOI66gAvflk/LHrtByykU8AxVfQ0PsLcyEo2Ww7oEJmLtk9JCm8MTYdFpzj4WAXL3lU5TsUdRO/l0e3if9b51PMYoR/tlXQIQkVLdOhjQZ/7TdOFTA8GVQgYiAZfka5mShF/sj7MygK+txFmSkD6VYoBt5jbfpyEqDbE1PnEQvkxvFXrA1rKxZfK2tpsyqiB1qtBPWJwGT11ZWzOUIlao+2Eaz+F5pSafdaDn2yuaY8+L5J1krrV/nnZvYSYQpzpL+YNTLekuIGbjDi3wDJUekf6FbHKlzQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-51-39Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698659494.4778287,
                        "modified": 1698659494.4778287,
                        "name": "/dev/shm/incoming/2023_10_30_095117_00_00_V_016.txt",
                        "sha256": "7d503aa7b2ee4bdb8404386f8ab0d18ce029877c98dddeeeb653b5b311cff232",
                        "size": 2028
                    },
                    {
                        "created": 1698659494.3778288,
                        "modified": 1698659494.3778288,
                        "name": "/dev/shm/incoming/2023_10_30_095117_00_00_V_016.kml",
                        "sha256": "588d11268ce16cfb66f0662cb363c7c069d66d641047ad0148b7131b1f39cc57",
                        "size": 980
                    },
                    {
                        "created": 1698659494.077829,
                        "modified": 1698659494.077829,
                        "name": "/dev/shm/incoming/2023_10_30_095117_00_00_V_016.jpg",
                        "sha256": "54ae98541a45a220c9a880f5de67471ae84d5b940b7fa2b08ca1ea8264c55b5b",
                        "size": 944705
                    },
                    {
                        "created": 1698659491.8478289,
                        "modified": 1698659491.8478289,
                        "name": "/dev/shm/incoming/2023-10-30T09:21:31.ts",
                        "sha256": "cb072e74c63c1d67eddc74ad78b0b1b9c6366901f5c69416cd537b410209b121",
                        "size": 427175104
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "a3471a9f7e223679c3ec15d44561946573bb7a29ef2404f5bf051d0f5c11e400",
                "size": 402077048
            },
            "errors": "",
            "filename": "2023-10-30T09-51-39Z",
            "size": 402077048,
            "verified_at": 1698692877
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698659448,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "QUiqmO664SzpqsQams3doutTB0sm2xSQeAKI9tfhDBGy5+okC0GCsVgSmEgM2piiCvvt6excIH72rOMyq+Lm1PeYnBUK1B03Cp2pMS88wVm9rGYR1W4mspngwX5Os/H8IiR0gtoIIE6lQLggxOKU1Qj53ILNnQ1VIo77bbk23qt1NkD3LaJjhjSjlNwizCS6aGHmMS9s0s5703cr2IZg7xxIfs8H/o+1I5iqciVRj0l/ielyEadP0wnG1UEpVel1D50fUEldNwor1IIxkedLxfP7JsvFz4+Ysl6pqvTl5SCKr1+XY1w6wpRtczcLxJZH2MyM/oXhXV85YuKQZHwavXKExrYG+JHuMheGdutBK5r3sw4kUbvr/+swzbZ4wdEGyg9v8KTIkrY8rl606ELAkg3poM9HzSduhj78yHmx/I9XLzEu5F5LLz4jc8PI5gqF5159esBDMFM91Ye3qbCkAPggnus4/dRkcTnlehHh0q7AGnRoZr5EXkteq5EKx9C3q2id31eqEUwvQBmdlayfckiUTfXOWaKnZ7b5Hjmvq4B4WU2tW8k1tBCAYaclMe93q7nWIyWTNSw1736s54JD4Rpyd5KzsrG7Tvqr+rTEdV1yDMlA4OlBQZRYx7EMzeP3hiJuDiMW1WKufCh6zeEWd3kTwnLyDDIPerSNDzbrEwI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-50-24Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698659295.5078287,
                        "modified": 1698659295.5078287,
                        "name": "/dev/shm/incoming/2023-10-30T09:38:14.ais",
                        "sha256": "e7bd59fbabca7c933417bbe858ada0e7797e89cc79ee5937a6e52ef2d3559d6a",
                        "size": 656228
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "741d346dc82c34120131c006255f0ebb54e8ee92e0534d01d0d1ca9b6790c04e",
                "size": 195397
            },
            "errors": "",
            "filename": "2023-10-30T09-50-24Z",
            "size": 195397,
            "verified_at": 1698693371
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698659814,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "Glo9YC0+4NPURMcFCn7RKdrtVM21oFcWYi/5ICr8UgBtjf9GVULfdzQrTO5NkHWSkY6oKZyuxG8L3EQX7bam1uLhnUb5gF4g3HfmVMv0U/HAaSFW0k1xO1rqmjyfSEAd4dexcAsxjIB/k1mi9YEhpLKvM3K5EZ4QcrxUZqAHmUZrSaZBgKFomURTcJPFGuCB6fvickE+s8N4W8pBbwmSj06PLCDQ/40/NB/R5TGKL5G1woPcAedoWREyMFR8Igf7Crd7m9JLrH2v5NzEAjF6mHEk3zFPtWQEAGPTVJXhu/93M/Lh4XE+TW97dil6rIrSNm5sJU1k9jXEnC9L37qR1r+5NI23mfX5BHmCFPzPVWdE3GgR+mHelCfmnjlRbzMHLiE0OgyYcnz438gYttgZXo5ZZ6Dwd2xij5GBE/tNyNrIsU8Knhhc81YzHXH1tLoNOn1ZqGdjAILYUT+fumRRGQqxZKcrfynWq3S6cKlGJjToiNlCEJdnjhY+qH1SAbpUuMikLFaArWdgyfBSJFa7WnvR95OCNB4Utf7FVmSkkXyxWhPxzhhtNY9cB72UbHLR0CoHXtt5hLeAQT7UIxIYkbPQGSH9oo97SiTZmvsf5TdP3B2xuUmRzEWTa+86kVd4wr/YZhROQz9y/HPEuTGGQ7fRj4HFBdcxHMLUDMqBL1I=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-57-03Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698659698.303269,
                        "modified": 1698659698.303269,
                        "name": "/dev/shm/incoming/2023-10-30T09:44:57.ais",
                        "sha256": "65b3403eee904e2378fe556ada2f5dbdae509e25df5523b70954ac18f555fa74",
                        "size": 23542
                    }
                ],
                "mission": "EFCA1",
                "sha256": "c771aaf38b4406f1aeced7e429713985fdbf1aee484336c4352fa80de1da47eb",
                "size": 6393
            },
            "errors": "",
            "filename": "2023-10-30T09-57-03Z",
            "size": 6393,
            "verified_at": 1698698589
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698746731,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "PGF4AewdiMlg3Hx/Sbn2sC8Om9uOlarqG/hz8IBoHhuzgpTzBaNDaJrO/pc8XLYq3U6jhRTS+Ur7d8oKNmymX89UA1eQk/BGE8earv5C6vHb/kd9P45yYaGfm/0Cq5GStZzXz4Fe1Q2iBz2CWcZCs2rhYhyyjxXHiGC7Iz4fKj3WhsWh1CHcSYsw7fxfFldIAhSmaoKwsFw+MpXhunKDi7h5J6n+DT5xtLPRQVwVpvQuVY2rCuFArk0fX0qIZONELkj9Q4SZLbtXM5psbe2oPxZQuYWvVjpcqIoRX5yCc/8hc99VwP7SVHD4sWYwUfEWCb99UY+OcpCvQ9zEu49e7lIr60IrgmDdLsH2SGitbjIVRjHA39WKaGr6fWL9Fx/13TJ0HyUagUNLrMaXRCb1lCjvIdY91ts+2/Fwb2PGImBEv5F1tzvCzMo4b6nMzSHnEF1yS7XeMZBvQuegvZRDzIJd7sAEtyVr23/47RoCAG2zxkt9L3N7a9vY8Q2q7VpBPWhpq/3DwYtcS7U7607k28UX997z/NQmSfDVMlFbFmiZIntGHWrurJU9dvVuz7gtxFKh7vbO/P8A1olSOJLUCshFuh0RTkV5nn6E0GEZh7pgwAs8MRrTzgbDRsInjQ5DMSiWLbGlum9fbIdCmcUxh6gaBJzIZ3PkTbQ4VULR9Us=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-04-02Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698746568.20126,
                        "modified": 1698746568.20126,
                        "name": "/dev/shm/incoming/2023-10-31T09:52:41.ais",
                        "sha256": "41f085a612e056465f628d4631b1b8d08a615af5ec1b4126334385d066037f29",
                        "size": 165029
                    },
                    {
                        "created": 1698746631.81126,
                        "modified": 1698746631.81126,
                        "name": "/dev/shm/incoming/2023-10-31T09:33:51.ts",
                        "sha256": "b55067922bc341940b4d235e6fc454c413b4a834ae1fa9b82de02f1e356ba532",
                        "size": 440702456
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "72267764fb390e97aad41072e95b10d267c6ccee4af465ed29ca2d5f14d573d6",
                "size": 397588175
            },
            "errors": "",
            "filename": "2023-10-31T10-04-02Z",
            "size": 397588175,
            "verified_at": 1698768105
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698660069,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "MmgBBRHdN4bJVN8YF8sAqHTkuAKnV2vJfhtyDYOpZXpwzbvV8qhws0U6VguRLs1hWRp6xIRWGvf6g6iSXsiotMlTskRxf1nHVk6QqFJTzWXG+vmTkpNODxkppFrakgZg5VmcI+HWxO7oaZ0JRY27d7ItFk2UNyX4QP9icVK5drE/hV33EE6OC5BXpgr2asqGUHCDbR5KYx3dOMNZNEYtqSgiCKA45pJAWDF71I5+vyyblwjAMdK9EUcbCfc3bhfw8tJ+8Z/EIduOUknw1O7zZOkum8Wa9vruSC4Eo4Xb5WhGz0mo1ynVaIUDX3NQQgpcjVmSAcB4eOjucvWWeIOq8qFZCYZcGuVRdZjXO+7gUWmk+6RZqzSYe1IWNgRSnTCMWpy7/1jRPdttRvtShL0wMlqZDKpu4AsTLl3zhVUbsV27iYhm2DPWp2kyus4GgZ1vfO+OAKgJJc+u6c27JEtGrVisWsZdJPz3KAzhEIJlRa4hYU3LjdsWRF6XVTUeTyW92uX1UZ/Nzd3+MYKLl0IavDynaroabcdCuWw8lY3VMXLe9sys8lAHN90+E4XZHPwXofpTuHjPV2D6I9+v2Tw80EFbJ3pO4XYZMmB5SPHdH/725IYuXErr3Ee/aVO7YKvBOg7vMELQ4IfpIj6V1i1OuADVzXJHX5AbAUEl67WvjD8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-00-47Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698659924.026036,
                        "modified": 1698659924.026036,
                        "name": "/dev/shm/incoming/2023-10-30T09:48:42.ais",
                        "sha256": "397964af1ebc833c3f0a7adb01c401f01561b5acc738ee1b8b2b299531cb60ab",
                        "size": 402731
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "870449095eb692075eac882709ad20296994bf75a5ec5039d1f6083dc8d91b44",
                "size": 117100
            },
            "errors": "",
            "filename": "2023-10-30T10-00-47Z",
            "size": 117100,
            "verified_at": 1698663732
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698660474,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "SElPnpP7D1nkTQ+r6/KOKc5tHvhMJl5nraOpHo9msMPYx/i81W6ep+J+0ZiibVMp6tA/eTqdPlUbfog7OmWADMydM1ygSjDhEdASLsdDtvWWSgqRa+QLZC/uH0zr/4K++JTT2cI9q/eE7voMJf7yQ232mC+9XU/ZwW0KLv3BoRdadEOih0+v0FmHUSilFvQ1mPmDQEhK2tp/QV/dKBqjEk7JpksEcBv8JuN1MR9U4mu7mtHR31wN+6CeIW3tBHBplSVRNFId2BVsDc1TVHoveb/2YKgqydwVogrdm9WPi7J1ECxwlGTqI3LK0Kgki62CDBs4+sBCF0oyYpEu2CgHvE1lZK74BpM0KynFF1GPAc/9EPPedijIjbM+SpIYBjcyA2KdCSi7YjH6QEgV7sP9WJvvMD/RuIv4+FZAStwV15nyyh4xOuAt1izNkczahQLkyJN0QodnmEaThB0FTJ3wmQGej1xVSCHCziP4FkvrdC3Jv/20v+5NQURblsagb8JnpTL6pH5zfrxBoaJ+SabUWszKJE0HEVpmgOEoq90DU7hDpIZq7TubIFg5sJFrNv722dXqo1Bl5/zp2DgeJ6lmIqY9vhbWSIWi1MaWA7DctCxZdKzTNjQURQ7MtW2PBmYyq0EYKg84n9wRwqdLGCkbZ81HU6NEGekb9+HVGbc7m4I=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-06-40Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698660333.2379405,
                        "modified": 1698660333.2379405,
                        "name": "/dev/shm/incoming/2023-10-30T09:55:29.ais",
                        "sha256": "71f9b904991ef10d54878f26ee41614417273203ab3f10c7c61f2827eefea924",
                        "size": 164749
                    },
                    {
                        "created": 1698660312.8979406,
                        "modified": 1698660312.8979406,
                        "name": "/dev/shm/incoming/2023_10_30_100449_00_00_K_079.txt",
                        "sha256": "81c30849f95e3b529dd749025e59b90289479252543f9807af44baba86271105",
                        "size": 790
                    },
                    {
                        "created": 1698660312.5279405,
                        "modified": 1698660312.5279405,
                        "name": "/dev/shm/incoming/2023_10_30_100449_00_00_K_079.kml",
                        "sha256": "95e09a8248a73df82bd4c93412229c1e512470aa1e3dcc299bd41a8d336c0e1a",
                        "size": 930
                    },
                    {
                        "created": 1698660312.2179406,
                        "modified": 1698660312.2179406,
                        "name": "/dev/shm/incoming/2023_10_30_100449_00_00_K_079.jpg",
                        "sha256": "7e6bc4c06be7809caf82dacf656f2f6994334aaf4145c9ae29286eccc0105de2",
                        "size": 552692
                    },
                    {
                        "created": 1698660394.1179407,
                        "modified": 1698660394.1179407,
                        "name": "/dev/shm/incoming/2023-10-30T09:36:33.ts",
                        "sha256": "cfef1fd810f2afb172c6dcd46c311a5a84d8a1d2690a49953d8fb1d742a1a45b",
                        "size": 441961116
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "0ad5702052c69088a11e16722bd0f6d89701392c77a0f1317db246a26f7feffe",
                "size": 399254948
            },
            "errors": "",
            "filename": "2023-10-30T10-06-40Z",
            "size": 399254948,
            "verified_at": 1698683852
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698660258,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "NHiowIUlx+RueiuhxC0p2hJkoiCKGQ0HfX9NuxSWEcx4i8bzK4BLlZwM5SqX9NSr3xX/l1GlQKYnt92+mS8sz2tYiEmMOQWy8X9X46ycpgmgO7qfGyVjFhhZ1511dd2/+mabUp0d1UQaua8N2IRir8eh/glGGKQSr+Pr78EAsjVCtpAlEOsB1TuhLVaNlotD0zqrVu/zOg2dqcwzJUEncm49CLmwc1KWznm4vJzzFqp0zL7mgrHRz8AIzzyNbqGZoaIaOr/yhsfVG/1QGvrwmNGL11sC9YTfkrGQ/0EoAqLIz1S8Nie9W1gaPft/mn+dj4MPH9YZHfxwqMhhE9s8g2ClKWP9GIztKPu3AviSVTdXb8PfHhgbAxxlQ/YhCqoI5YrFikhkE8pc90mhTb4+RY9jTEilbJpMDX73Bl+RhkJuCwc4mEuCdxreGrZdi5xeOtZqfWquVF9wMhCuS/xQgndebZHtaLxdx+os/3eDNeqUafBj5Oqvp/72VMsc9f7IPghPvo8UVkgLdt3VvHjuoPYGtSiCEQwY8u2Dw2CSyfYGTwjYxBgZrD0GB4W+091L3VB/Ch5ilAVjPY41wtHgefpQ9u7O2HWGMfE0+K7z8UEEA7nZxl8jB2ZBo8sUfGrTU+Me1bRVTcLboKlaShsdbHDDwNhTvZzbAOSzYtSL8Hc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-03-54Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698660111.7078288,
                        "modified": 1698660111.7078288,
                        "name": "/dev/shm/incoming/2023_10_30_100135_00_00_V_032.txt",
                        "sha256": "58e8930007dc713a8455067c2d71241daaffddc1069ce62c2c8ccccf9f1d64b0",
                        "size": 2033
                    },
                    {
                        "created": 1698660110.9878287,
                        "modified": 1698660110.9878287,
                        "name": "/dev/shm/incoming/2023_10_30_100135_00_00_V_032.kml",
                        "sha256": "e99b8b7cf2a364cd298bafba41199639132e84496ecd461c354183ece7f1436d",
                        "size": 977
                    },
                    {
                        "created": 1698660110.6478288,
                        "modified": 1698660110.6478288,
                        "name": "/dev/shm/incoming/2023_10_30_100135_00_00_V_032.jpg",
                        "sha256": "26fdb47ccffaa9efc1f18c9d0ae44624d4a57cd4b65e55b6d7752a57955d1948",
                        "size": 717957
                    },
                    {
                        "created": 1698660067.1878288,
                        "modified": 1698660067.1878288,
                        "name": "/dev/shm/incoming/2023_10_30_100054_00_00_V_031.txt",
                        "sha256": "3098dbd6862fe52e29121e868003a90f2d16695fc90ea9739983868491c52317",
                        "size": 2033
                    },
                    {
                        "created": 1698660066.9678288,
                        "modified": 1698660066.9678288,
                        "name": "/dev/shm/incoming/2023_10_30_100054_00_00_V_031.kml",
                        "sha256": "744436bc67ff92b2b8a2aaf66f070ed5b67eecbd9fb3b26aa41c4b5437e4fb30",
                        "size": 976
                    },
                    {
                        "created": 1698660066.4378288,
                        "modified": 1698660066.4378288,
                        "name": "/dev/shm/incoming/2023_10_30_100054_00_00_V_031.jpg",
                        "sha256": "b691b349159cbcab55fd4f2b69766e4faedeba047c0ef6791cc2391bbe1f7469",
                        "size": 422519
                    },
                    {
                        "created": 1698659970.9278288,
                        "modified": 1698659970.9278288,
                        "name": "/dev/shm/incoming/2023_10_30_095912_00_00_V_030.txt",
                        "sha256": "106e7978696d32ba379f1c3b32fdab218180538c220365d3d5479cb6b2b544c9",
                        "size": 2032
                    },
                    {
                        "created": 1698659970.3378289,
                        "modified": 1698659970.3378289,
                        "name": "/dev/shm/incoming/2023_10_30_095912_00_00_V_030.kml",
                        "sha256": "a60dc054256772604b85cdadd5a0aa45ba3886209d0dfb6fc447540fc3fee4c9",
                        "size": 978
                    },
                    {
                        "created": 1698659970.1278288,
                        "modified": 1698659970.1278288,
                        "name": "/dev/shm/incoming/2023_10_30_095912_00_00_V_030.jpg",
                        "sha256": "bbb35d5fcb03c7a726f3cdb708e6f86e1347fc053bbcfeaafb4ef1c1bc003555",
                        "size": 698181
                    },
                    {
                        "created": 1698659958.9578288,
                        "modified": 1698659958.9578288,
                        "name": "/dev/shm/incoming/2023_10_30_095903_00_00_V_029.txt",
                        "sha256": "7edcfbf569efa50fef48ac123bd9464081274d01d356dc424bfe01eae995e4c9",
                        "size": 2033
                    },
                    {
                        "created": 1698659958.817829,
                        "modified": 1698659958.817829,
                        "name": "/dev/shm/incoming/2023_10_30_095903_00_00_V_029.kml",
                        "sha256": "6b0b6374b0640008e4a9f44d0f0dab62d350b62b3ba3b0cead5d1a2715c98594",
                        "size": 979
                    },
                    {
                        "created": 1698659958.5078287,
                        "modified": 1698659958.5078287,
                        "name": "/dev/shm/incoming/2023_10_30_095903_00_00_V_029.jpg",
                        "sha256": "5f6c503b947101bdf2f280b2fd980df96fdcb4ed33357e61c7013b77cc5dbe90",
                        "size": 637710
                    },
                    {
                        "created": 1698659947.307829,
                        "modified": 1698659947.307829,
                        "name": "/dev/shm/incoming/2023_10_30_095844_00_00_V_028.txt",
                        "sha256": "a5233148f11c5cfbae43ed8b42b2ce7e3747805fe0b4eaab998801c273949a31",
                        "size": 2033
                    },
                    {
                        "created": 1698659946.797829,
                        "modified": 1698659946.797829,
                        "name": "/dev/shm/incoming/2023_10_30_095844_00_00_V_028.kml",
                        "sha256": "6bcf2b3c1fd0c3672ff428ce0a8455aa663a6214e6fff876749918d516f3b72e",
                        "size": 977
                    },
                    {
                        "created": 1698659946.6978288,
                        "modified": 1698659946.6978288,
                        "name": "/dev/shm/incoming/2023_10_30_095844_00_00_V_028.jpg",
                        "sha256": "70e8c3fad34aa44f8ca2fcc1ba0f6788dda2a6c1487827a6598dc01a80bef57a",
                        "size": 799379
                    },
                    {
                        "created": 1698659896.077829,
                        "modified": 1698659896.077829,
                        "name": "/dev/shm/incoming/2023-10-30T09:48:15.ais",
                        "sha256": "799ea7a6b997abcd171b92369398889eb0fc925426f4fb40311dc7ea62b6b99b",
                        "size": 633758
                    },
                    {
                        "created": 1698659892.9978287,
                        "modified": 1698659892.9978287,
                        "name": "/dev/shm/incoming/2023_10_30_095758_00_00_V_027.txt",
                        "sha256": "289cd52cf354f2bedae5c4323e81728b46583bf1483713f3446519a9bf0dc6b0",
                        "size": 2034
                    },
                    {
                        "created": 1698659892.1778288,
                        "modified": 1698659892.1778288,
                        "name": "/dev/shm/incoming/2023_10_30_095758_00_00_V_027.kml",
                        "sha256": "fcfca135767a2d84248d139fefec1c3d52bb237b96f2fa59ebb7530c03cfa5c7",
                        "size": 978
                    },
                    {
                        "created": 1698659891.9978287,
                        "modified": 1698659891.9978287,
                        "name": "/dev/shm/incoming/2023_10_30_095758_00_00_V_027.jpg",
                        "sha256": "76871a185018fafc394eea1f8c31333d51c80c92a79f5f79c1c90d5fae66932b",
                        "size": 864518
                    },
                    {
                        "created": 1698659847.5878289,
                        "modified": 1698659847.5878289,
                        "name": "/dev/shm/incoming/2023_10_30_095707_00_00_V_026.txt",
                        "sha256": "ca1883d45abd957cd4ec1f0ae0ffc0f154a81d9700e416b5150647e134fe52f8",
                        "size": 2033
                    },
                    {
                        "created": 1698659847.3478289,
                        "modified": 1698659847.3478289,
                        "name": "/dev/shm/incoming/2023_10_30_095707_00_00_V_026.kml",
                        "sha256": "30995c9d33032564bac8ce227a46fe2fdf63fb5c09055a90d082f88fc4dce8e1",
                        "size": 977
                    },
                    {
                        "created": 1698659847.1978288,
                        "modified": 1698659847.1978288,
                        "name": "/dev/shm/incoming/2023_10_30_095707_00_00_V_026.jpg",
                        "sha256": "1e5cc9abec9d4eb7aa4eeefebc330f47332e3beb836fce2c8c54001435367b03",
                        "size": 395296
                    },
                    {
                        "created": 1698659825.2678287,
                        "modified": 1698659825.2678287,
                        "name": "/dev/shm/incoming/2023_10_30_095643_00_00_V_025.txt",
                        "sha256": "1eb85f80217df491cb0a9a38d540a0501a285bd5d41c0bfee00213f01c7641db",
                        "size": 2031
                    },
                    {
                        "created": 1698659825.1178288,
                        "modified": 1698659825.1178288,
                        "name": "/dev/shm/incoming/2023_10_30_095643_00_00_V_025.kml",
                        "sha256": "e7a14dfa60d96f370b332ab04f54b54ede25dbf8073712cc0d57cea216e6e011",
                        "size": 977
                    },
                    {
                        "created": 1698659824.7478287,
                        "modified": 1698659824.7478287,
                        "name": "/dev/shm/incoming/2023_10_30_095643_00_00_V_025.jpg",
                        "sha256": "59c05ea561308782f4a1af413cc176eff1ffc80c2735864f38d4a17a3473df28",
                        "size": 537093
                    },
                    {
                        "created": 1698659813.0078287,
                        "modified": 1698659813.0078287,
                        "name": "/dev/shm/incoming/2023_10_30_095635_00_00_V_024.txt",
                        "sha256": "bf8a85c83c6413758dbaafb79e5ea9c3b0fe55e7239850cff612232006c3e95e",
                        "size": 2031
                    },
                    {
                        "created": 1698659812.8578289,
                        "modified": 1698659812.8578289,
                        "name": "/dev/shm/incoming/2023_10_30_095635_00_00_V_024.kml",
                        "sha256": "3caee13586b942c538e81aa0b34eecce84b37460c6cddbcbb47cf45b0a4fc06a",
                        "size": 980
                    },
                    {
                        "created": 1698659812.4478288,
                        "modified": 1698659812.4478288,
                        "name": "/dev/shm/incoming/2023_10_30_095635_00_00_V_024.jpg",
                        "sha256": "6226b2fe5d0b83b1d2a872191ffa99f5fcef58438fd5b157ac538744e613db90",
                        "size": 789942
                    },
                    {
                        "created": 1698659811.3978288,
                        "modified": 1698659811.3978288,
                        "name": "/dev/shm/incoming/2023_10_30_095626_00_00_V_023.txt",
                        "sha256": "afc27e9a487510b9323bd2509ddfd93878e3ab3383592308484873760590eb4d",
                        "size": 2032
                    },
                    {
                        "created": 1698659810.8778288,
                        "modified": 1698659810.8778288,
                        "name": "/dev/shm/incoming/2023_10_30_095626_00_00_V_023.kml",
                        "sha256": "5eb5c8c949addd14ba9159cf5c1a840c02aa52cd7cd71cf617afe7c8e3d5403f",
                        "size": 977
                    },
                    {
                        "created": 1698659810.7678287,
                        "modified": 1698659810.7678287,
                        "name": "/dev/shm/incoming/2023_10_30_095626_00_00_V_023.jpg",
                        "sha256": "41420a3824151443ed43eb0709c33f041fc013e0804a187e019f37bf1882188e",
                        "size": 460763
                    },
                    {
                        "created": 1698659788.4878287,
                        "modified": 1698659788.4878287,
                        "name": "/dev/shm/incoming/2023_10_30_095611_00_00_V_022.txt",
                        "sha256": "4646a372cdbab26e823d9da674cffa11c0b36238e595b0c4d2a71df70ed8a52f",
                        "size": 2031
                    },
                    {
                        "created": 1698659788.047829,
                        "modified": 1698659788.047829,
                        "name": "/dev/shm/incoming/2023_10_30_095611_00_00_V_022.kml",
                        "sha256": "a7829574ccb13ea940439c1ed72b921047f186568b4576f8119235e0942fdaf9",
                        "size": 977
                    },
                    {
                        "created": 1698659787.8378289,
                        "modified": 1698659787.8378289,
                        "name": "/dev/shm/incoming/2023_10_30_095611_00_00_V_022.jpg",
                        "sha256": "203b7f2f8d97fb9bc4dc1070636f6ef662b14db80db589cf173206c9487f3d46",
                        "size": 743645
                    },
                    {
                        "created": 1698659786.9978287,
                        "modified": 1698659786.9978287,
                        "name": "/dev/shm/incoming/2023_10_30_095604_00_00_V_021.txt",
                        "sha256": "97e3316ffd0d78897e2cc6da9448c26143923d779ab00c75961eade8ef02cbe8",
                        "size": 2031
                    },
                    {
                        "created": 1698659786.7678287,
                        "modified": 1698659786.7678287,
                        "name": "/dev/shm/incoming/2023_10_30_095604_00_00_V_021.kml",
                        "sha256": "8bcb40cc57588a1edaf3092ea2fead1d00bea78178916f9762cd5f52f3b94031",
                        "size": 976
                    },
                    {
                        "created": 1698659786.4078288,
                        "modified": 1698659786.4078288,
                        "name": "/dev/shm/incoming/2023_10_30_095604_00_00_V_021.jpg",
                        "sha256": "c04c5648891f10f4c9c2f7993b0b559995675fbf9922ae029b4bbe086ecb80e1",
                        "size": 734505
                    },
                    {
                        "created": 1698659764.7778287,
                        "modified": 1698659764.7778287,
                        "name": "/dev/shm/incoming/2023_10_30_095548_00_00_V_020.txt",
                        "sha256": "69b13497acfc4136c0caecbcc0441d7848f436980689da10aabedcab8762d7bd",
                        "size": 2031
                    },
                    {
                        "created": 1698659764.5878289,
                        "modified": 1698659764.5878289,
                        "name": "/dev/shm/incoming/2023_10_30_095548_00_00_V_020.kml",
                        "sha256": "f52493d0cb21a3b4a8308a7c6e8036bf866324d2d6f6cdd2a58d28d99e75eaca",
                        "size": 975
                    },
                    {
                        "created": 1698659764.4278288,
                        "modified": 1698659764.4278288,
                        "name": "/dev/shm/incoming/2023_10_30_095548_00_00_V_020.jpg",
                        "sha256": "728cf4898f56430102861bb19ebb0b349fbf847e90a342c64951126c98e26e48",
                        "size": 774772
                    },
                    {
                        "created": 1698659753.0978289,
                        "modified": 1698659753.0978289,
                        "name": "/dev/shm/incoming/2023_10_30_095540_00_00_V_019.txt",
                        "sha256": "3d10bfb8a8e60f1cd0e099802c77bb5a80d27acd534f82e0a5660130753c62d6",
                        "size": 2032
                    },
                    {
                        "created": 1698659752.6178288,
                        "modified": 1698659752.6178288,
                        "name": "/dev/shm/incoming/2023_10_30_095540_00_00_V_019.kml",
                        "sha256": "80272ae913c0f83144d1d1cc80163570dcfaa5d8ee7dd5e7718d35eb602b5e84",
                        "size": 979
                    },
                    {
                        "created": 1698659752.3878288,
                        "modified": 1698659752.3878288,
                        "name": "/dev/shm/incoming/2023_10_30_095540_00_00_V_019.jpg",
                        "sha256": "6811653074dd2b506e850084f737f8fbdc4a2c248c8993a74dfe6ec5e5d1e65b",
                        "size": 797498
                    },
                    {
                        "created": 1698659710.0878289,
                        "modified": 1698659710.0878289,
                        "name": "/dev/shm/incoming/2023_10_30_095451_00_00_V_018.txt",
                        "sha256": "dd2aecc6a82a860e2ac7517c34ab4e7643e8a55a5e6c5d444a2c44e25c614e9b",
                        "size": 2031
                    },
                    {
                        "created": 1698659709.7678287,
                        "modified": 1698659709.7678287,
                        "name": "/dev/shm/incoming/2023_10_30_095451_00_00_V_018.kml",
                        "sha256": "8589ca7ed4811da76deed4242542e3b49a059dd32c5eeaddb825432734d848a2",
                        "size": 976
                    },
                    {
                        "created": 1698659709.1578288,
                        "modified": 1698659709.1578288,
                        "name": "/dev/shm/incoming/2023_10_30_095451_00_00_V_018.jpg",
                        "sha256": "3d9bd66c7ad17d856e1894cc813735ce39981258dbd1a08c49f7244edbb73463",
                        "size": 735369
                    },
                    {
                        "created": 1698659645.057829,
                        "modified": 1698659645.057829,
                        "name": "/dev/shm/incoming/2023_10_30_095346_00_00_V_017.txt",
                        "sha256": "b9da722a82e945d8fa9583d059e823e2bef7ffc71578ea74396ad08e91c1c938",
                        "size": 2028
                    },
                    {
                        "created": 1698659644.4778287,
                        "modified": 1698659644.4778287,
                        "name": "/dev/shm/incoming/2023_10_30_095346_00_00_V_017.kml",
                        "sha256": "903c535d24a6e8426f0878b78c9979aa08ec6e7df12c86a39555c8406f000659",
                        "size": 978
                    },
                    {
                        "created": 1698659644.0178287,
                        "modified": 1698659644.0178287,
                        "name": "/dev/shm/incoming/2023_10_30_095346_00_00_V_017.jpg",
                        "sha256": "55563708d937d463d617493d05548fd3bbecbd159caa7cd49222c7a9655b7a91",
                        "size": 892304
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "76d62acbb745eeae4c3d824a1ea7fdad1123a690775850aab33f432db2b9ec54",
                "size": 11090555
            },
            "errors": "",
            "filename": "2023-10-30T10-03-54Z",
            "size": 11090555,
            "verified_at": 1698693675
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698660443,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "lAtD+SykfgNw771LmSPjaSW9T2LFmob12ZF+X+qpkA40gj1QtFBkXewZHeE6S7lpIWw0LRzK7XqQhypgQX9WiYyCi4biBF9PLQ5JAmKt28p0hOpO+3Hfsf709LG6FIpcLKfyFlnR65kCk2Dyf25+hsqSYtTeqddOO7UPi6fFch9/VGMmxDJ4LWV9pAOlM1IzuQfHC+d/gEFyEgqlYtyDRJde4Boqvr84M0y2t5OMaLpL6571XIRSW4dfbaOHvfmtgPZqVt2d2xvp9Bh6wbCBy6II6UPvtgLPmqdMVHUl1QAP6ZCd+Y9hOUqkrIgYpMX1YjZ+mKakTxtSQbcpA6KdJrhbdDVUp8yjwaRIoSITRZEWN3bqIWxuW31bqiR561y7vKGoFxL1rv9miBCYEf9FSPjG5p73oicFrDaSLPGLusDE0hM3j2r88bfa0pRjZWLIPsLPoa9CBOOf5cz6mr8c85mFQyV66xE3lCSOfrgmdEAkiMZ8HjLlWnstt4kU0ayj+7gqhjLQdtsxkUKtcmKd5xgpvrvIbhMobBc9noOuPe8Nf6De6q63jV3rjkH1RHMEDKutqKEfhTXh9T54/3IbR+1jnZWNGyClC9jYO4qtFXn5AN84dWQ1QguUshancZndDBarEEVXQALarqQJjLAPVaG0+4s8uITiT8Dy+Fy5KhQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-07-07Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698660303.0132687,
                        "modified": 1698660303.0132687,
                        "name": "/dev/shm/incoming/2023-10-30T09:54:58.ais",
                        "sha256": "b078510c947745075b6df76268071666b7271a997ea63e47ee7194283e6f249c",
                        "size": 29889
                    }
                ],
                "mission": "EFCA1",
                "sha256": "f8d830c364961f5061249ada859d43e9a7bcf4886ef6756f950d8898aac53bd4",
                "size": 7350
            },
            "errors": "",
            "filename": "2023-10-30T10-07-07Z",
            "size": 7350,
            "verified_at": 1698698577
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698768831,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "WFVKxIaIQ5oLzctCb187C+GLx0H500bNR7VpjKD1k8pOWwOgKAFo48ZfN12nzXIfCIwzddrplOlFecT6H51Fnw81lHW3X9pyi7wyRDDvLIS/ZoQphlPSL1i3Fw66g8HoGu59G0CGa/1MuSAzh/WqERUqQ6EJhVRX+zyRuuUfI7RGigOOBkTJEiAE1Ia9g7PkTawaOtcopIoro871zM/iVJIlIanSsuKqIPl8odcrEVpywZ8a19hy0Hn60Y6wZhjUTRKwtDbFybSCTCHAzuqdm7HKzNMlalf5ROeoQEpflq9QX77vk6MyXtJA5rjBlS7kprfar0P1H8ur1dxzCWMmQEAQIncttbIsL+2CkqMClJLR5lWA4yYfE/azlaqCrLCMuCU/3QwbpSvrYuUe+OOUYDY/8LyyFO8T+NpmlKcfQiSs9rg6HAqGxQ6SIL5CUaq+QIkq3T6aFBnQw2K16N15ZuYKP4Wrn/EWLYecSfUiUnlGxnsLDKa36X97Z00i2ykcaoBf/gqWJTEl9p3SU7CgVqYSvSqrJSLFMRuOC2Kf4HUYuW7QWC2uZrQivbLjpeoCGXsiKmwUTenewx2qnm9xt4oWwEFsX86HCZQnzyWlGRCUBVmx9jwDilVIg26B41U+F8vKe5J5dkMRtBeWLLTX2JTC4DWyqAXwCm3C8o9ocGc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T16-13-32Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698768683.993036,
                        "modified": 1698768683.993036,
                        "name": "/dev/shm/incoming/2023-10-31T16:01:20.ais",
                        "sha256": "deb7a7f9455eec6043a5644e238fef1c957e1ea344e5f20a05a01ad70448b911",
                        "size": 56377
                    }
                ],
                "mission": "OSP2",
                "sha256": "a146e1af4eb61af42cb1ae6120de7d492c472fbae3a954f0dc98707a05a8fd91",
                "size": 12068
            },
            "errors": "",
            "filename": "2023-10-31T16-13-32Z",
            "size": 12068,
            "verified_at": 1698783527
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698768227,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "gr11P5Hx2wv1ftE2UMZk/n/jb48vuQ8csKKg/at5Zv2vLZuU5QDNlrZASBweAt9mbvQqtrISqFtRy7cYRnTo0usYp0YTJv/MoAs7BdH2u55uKCNk7IMW3u58jB/dOlkthEEKGMataYNL+WLenBmh3xEH58pkewcTPuTjvmNZ10LQnRhXTj5MFsUV0oAuWCIy/ni8NsiJDK7V/Vga0Uu1gvTDWnSZ/i9V0FEoCwMaX05wkaWwnqWFADu3Lv9lrC0WFqRwkILc7kt7dW4+molvqqzTgNWQic/0jWWBEunDaI6oUt9r04v6YU0IUVVC6Nso2RjcHj79TzXzFbfdgy7MVPGOFtE48CIM1BZDSx11kgZVAHVpHD2qMzGf32BpsrgpgKZS3u1yclbsigzhTGnBvNTHsTCT2tpYEzJBfdt60VB1hkGhSR1WW7BGl9I/3crXdbx2sT2OpzHisfYrKStX0h7BBKHXkcE1mWnZbxXeFGEPfjRqDZLmE4+21ShYns3KTG/CPzhQXOOyHgGh5EUJgO/BwF8kFlskVZVAn2lFDPQsssNuKNw9Ws0vNPv3S+vYtxajSaRNafsxq8t/6+UZmCt6GDBIB9wKiVXmHYnce6dKFsqUf8sE9ysM1DOxBjpxGvE08SX8DcDf67yg1Q05d3wP8leep8xX80rEers4QWU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T16-03-28Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698768080.3130362,
                        "modified": 1698768080.3130362,
                        "name": "/dev/shm/incoming/2023-10-31T15:51:17.ais",
                        "sha256": "81343049ae579310e995775ae27760ec17789f7291965f9884ca266089198268",
                        "size": 75244
                    }
                ],
                "mission": "OSP2",
                "sha256": "889f810c38664470f80fb8d67ab2e7adec73f480c77ccea39bdd21749eea7936",
                "size": 17829
            },
            "errors": "",
            "filename": "2023-10-31T16-03-28Z",
            "size": 17829,
            "verified_at": 1698783551
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698661088,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "GMaYeTsI5bCN3OCAHUVFZ452QSCY1ZO3xbZOGW/sqPfwv1ujm3OHEV77QuCCLsWmx98Wx0KACiw7MmaLCMjto6/p1BbMLuOzSw9f6FLcUHwb7PQYsOMNNAl/LQoIKfEhCByMK6Fbu63qgZTH3ClG+SggrdQ4J3Q5Wxx5QYdS/oFzSutvV2OMPaa8X2eVwaYxDRQG5rnMWapRfNZOlyVyJPwDC2UGiPvfDriMENRbsDjJkAu8dwxHtQd+18lFqIUalmCSZy7pYH0xsgKJZp2+Rm/NMFCiO4p5ggFcHZhUouH9VQdf66COIFtMmklw7aMjjiIC7Q2G+f+qveAc+DHBueZ9W63jEXQdqLiRru0W6baxzFQrdXbF+pChi/6hFmQRkNxvyoRI/Kz3BYbH8WtIprAnZmtosUmoTbqs+Lqc3lqW8R0NpvRx35PUK89hBXffkh4r0C/NjpVCUs98U4WL8IufgwJhtxdLh5i0e0u4foAx/Q0PG8fR4jPWtNCoKYHRKcDAdnp6BLVs7Qmj/TVvN+dgoWeaQtatM9eyhxnY1Q48vcp5bcp9+/wk3wx7KBKMT4M7xQ0r/a614ZZT+cFRxxjB/deTFqXHoo5V/hBXpkTcH0JiqkhnZb2CNV+3J77lT3Cq38SU94tMb3Y3uTxdOZ/6ksu4t5iqyaAbDipNK6Y=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-17-45Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698660939.7379405,
                        "modified": 1698660939.7379405,
                        "name": "/dev/shm/incoming/2023-10-30T10:05:33.ais",
                        "sha256": "ad689cc347ad1c47b04f1de68ce0ae9f22f017ece28f1964aa67e788cd799401",
                        "size": 100112
                    },
                    {
                        "created": 1698660925.5079405,
                        "modified": 1698660925.5079405,
                        "name": "/dev/shm/incoming/2023_10_30_101503_00_00_K_098.txt",
                        "sha256": "0ea0caf82c68266f12e9561604ce612b70fb2cf694021724b4e54e772a8d40df",
                        "size": 2045
                    },
                    {
                        "created": 1698660924.7579405,
                        "modified": 1698660924.7579405,
                        "name": "/dev/shm/incoming/2023_10_30_101503_00_00_K_098.kml",
                        "sha256": "1770b4bba17758f80612f46b4c05698dc8bff33ec926f39266c4ad6004eb16e9",
                        "size": 972
                    },
                    {
                        "created": 1698660922.3179407,
                        "modified": 1698660922.3179407,
                        "name": "/dev/shm/incoming/2023_10_30_101503_00_00_K_098.jpg",
                        "sha256": "403dee92851fc37efc3136836b668f9d08c74b9a4877159bcde496ece9b3006b",
                        "size": 1578689
                    },
                    {
                        "created": 1698660933.1179407,
                        "modified": 1698660933.1179407,
                        "name": "/dev/shm/incoming/2023_10_30_101451_00_00_K_097.txt",
                        "sha256": "b11e8a613f7273d02c11adebecdddebb08f85da3ea66c4941f85bf7fb5be79a1",
                        "size": 2045
                    },
                    {
                        "created": 1698660911.4279406,
                        "modified": 1698660911.4279406,
                        "name": "/dev/shm/incoming/2023_10_30_101451_00_00_K_097.kml",
                        "sha256": "b8912ed924d6154b36e8bcb3fa54dd006fe12599332c4ad5f6b47449ffcd09ba",
                        "size": 977
                    },
                    {
                        "created": 1698660908.4679406,
                        "modified": 1698660908.4679406,
                        "name": "/dev/shm/incoming/2023_10_30_101451_00_00_K_097.jpg",
                        "sha256": "732e85b6fd7edce676b9b8979884b8ae2a24e3d09376fc520060b732a7cb82a0",
                        "size": 1014656
                    },
                    {
                        "created": 1698660892.7079406,
                        "modified": 1698660892.7079406,
                        "name": "/dev/shm/incoming/2023_10_30_101425_00_00_K_096.txt",
                        "sha256": "3cf0197cddc34a3e1343061d8c58d4e4fd0f97004740835542bd2ef2055fbad4",
                        "size": 2045
                    },
                    {
                        "created": 1698660899.5479405,
                        "modified": 1698660899.5479405,
                        "name": "/dev/shm/incoming/2023_10_30_101425_00_00_K_096.kml",
                        "sha256": "8c3dd62ef4142d96c02ab79b0513bc8c08ac9eb7f3d739e62b4f780d3fa3068f",
                        "size": 978
                    },
                    {
                        "created": 1698660901.8679407,
                        "modified": 1698660901.8679407,
                        "name": "/dev/shm/incoming/2023_10_30_101425_00_00_K_096.jpg",
                        "sha256": "9ca102a407f658fe5f9e40ef8f05d856f05f2329e3b56769533c44722ed8e170",
                        "size": 935955
                    },
                    {
                        "created": 1698660863.9079406,
                        "modified": 1698660863.9079406,
                        "name": "/dev/shm/incoming/2023_10_30_101408_00_00_K_095.txt",
                        "sha256": "433d44f7007b5a7e1b61fbff0f27db71d9d2d06339720dd65f68ad4926fc2550",
                        "size": 2045
                    },
                    {
                        "created": 1698660864.4479406,
                        "modified": 1698660864.4479406,
                        "name": "/dev/shm/incoming/2023_10_30_101408_00_00_K_095.kml",
                        "sha256": "435b199052b7fb18901cbf507bd1a02535b84ad8e08b8d8c5a3c2af22fb157c1",
                        "size": 976
                    },
                    {
                        "created": 1698660864.0979407,
                        "modified": 1698660864.0979407,
                        "name": "/dev/shm/incoming/2023_10_30_101408_00_00_K_095.jpg",
                        "sha256": "e3a6f0efcbe1916f10e862d25b294ebd05c39dd2a5aa0edacc12ab06923b5cb2",
                        "size": 965874
                    },
                    {
                        "created": 1698660816.2279406,
                        "modified": 1698660816.2279406,
                        "name": "/dev/shm/incoming/2023_10_30_101319_00_00_K_094.txt",
                        "sha256": "5d4ecca6358eb0318c57594d6091c7bde1a6b7f4ffc1a9efe3092b417d819d46",
                        "size": 2045
                    },
                    {
                        "created": 1698660815.6679406,
                        "modified": 1698660815.6679406,
                        "name": "/dev/shm/incoming/2023_10_30_101319_00_00_K_094.kml",
                        "sha256": "2c3dbc6b9e7159095e616f193d5920a6acb7f990ed6d1286e079dff0a7d14754",
                        "size": 979
                    },
                    {
                        "created": 1698660815.2679405,
                        "modified": 1698660815.2679405,
                        "name": "/dev/shm/incoming/2023_10_30_101319_00_00_K_094.jpg",
                        "sha256": "582ecb30f1e39a56533187190636fd65eb504fbec6c5a03af867e67478be6694",
                        "size": 911467
                    },
                    {
                        "created": 1698660786.4079406,
                        "modified": 1698660786.4079406,
                        "name": "/dev/shm/incoming/2023_10_30_101218_00_00_K_093.txt",
                        "sha256": "4d210e5e7535a929dba91298374126e35813b9c782a16638d719f0b445e1c0f8",
                        "size": 2043
                    },
                    {
                        "created": 1698660770.2279406,
                        "modified": 1698660770.2279406,
                        "name": "/dev/shm/incoming/2023_10_30_101218_00_00_K_093.kml",
                        "sha256": "606baa2baf107756c86d49ed6581e3c1376e0980533ec042d3687ca69aaa48ac",
                        "size": 979
                    },
                    {
                        "created": 1698660760.2279406,
                        "modified": 1698660760.2279406,
                        "name": "/dev/shm/incoming/2023_10_30_101218_00_00_K_093.jpg",
                        "sha256": "4cf6701b0dfbe9bd1fa22a14b5b84e28dff399688f4164dfd9e29390f4e5a748",
                        "size": 1309225
                    },
                    {
                        "created": 1698660681.4379406,
                        "modified": 1698660681.4379406,
                        "name": "/dev/shm/incoming/2023_10_30_101052_00_00_K_092.txt",
                        "sha256": "3d4de24e76fbd59ed34c4121d1059989e7ed873edf4f1e6edd8040891a196ff9",
                        "size": 2046
                    },
                    {
                        "created": 1698660682.2579405,
                        "modified": 1698660682.2579405,
                        "name": "/dev/shm/incoming/2023_10_30_101052_00_00_K_092.kml",
                        "sha256": "e00fd41f38a9cab8ec9bc870e43ba9716da15c5838ba2d97076c2d7ba0beccf5",
                        "size": 974
                    },
                    {
                        "created": 1698660681.8179407,
                        "modified": 1698660681.8179407,
                        "name": "/dev/shm/incoming/2023_10_30_101052_00_00_K_092.jpg",
                        "sha256": "5a2c306b820c3d8147de3d106298c0f28bc6f5e9f771608cb2ebb5d4f1a8cc80",
                        "size": 793471
                    },
                    {
                        "created": 1698660663.5979407,
                        "modified": 1698660663.5979407,
                        "name": "/dev/shm/incoming/2023_10_30_101041_00_00_K_091.txt",
                        "sha256": "f83d9dc8439e07e7c787d31641dd78cc1928f257883e4b054f1b27383b13e0fc",
                        "size": 2044
                    },
                    {
                        "created": 1698660664.5579405,
                        "modified": 1698660664.5579405,
                        "name": "/dev/shm/incoming/2023_10_30_101041_00_00_K_091.kml",
                        "sha256": "50f07a4215f9d6c40625c17c5f0ddc249ff28ee700e25f42c94e9061a606cb87",
                        "size": 978
                    },
                    {
                        "created": 1698660669.3679407,
                        "modified": 1698660669.3679407,
                        "name": "/dev/shm/incoming/2023_10_30_101041_00_00_K_091.jpg",
                        "sha256": "dc61b692759479fb006a3c9553edccc062301c2e660f3183f40e7a28215097ae",
                        "size": 730998
                    },
                    {
                        "created": 1698660658.4979405,
                        "modified": 1698660658.4979405,
                        "name": "/dev/shm/incoming/2023_10_30_101035_00_00_K_090.txt",
                        "sha256": "712782d25ef29c8fd0577899cd0eacad9352a8d1232ad85909af0730bd476593",
                        "size": 2043
                    },
                    {
                        "created": 1698660659.3179407,
                        "modified": 1698660659.3179407,
                        "name": "/dev/shm/incoming/2023_10_30_101035_00_00_K_090.kml",
                        "sha256": "fcb01659a6ab9007d72dc9c50727f17e6f6d87f2eb9560d76a1f7b6085c24217",
                        "size": 979
                    },
                    {
                        "created": 1698660658.1079407,
                        "modified": 1698660658.1079407,
                        "name": "/dev/shm/incoming/2023_10_30_101035_00_00_K_090.jpg",
                        "sha256": "b3c5d7c5907fb6f51a672eebc142dd387731c0c957b21de44329e4ca7d54bd70",
                        "size": 706980
                    },
                    {
                        "created": 1698660620.8279407,
                        "modified": 1698660620.8279407,
                        "name": "/dev/shm/incoming/2023_10_30_100957_00_00_K_089.txt",
                        "sha256": "e7ee9b5adb12f7c35a65578e943479c00dbc9ad0e07ff64b951d2ae9bee6f88c",
                        "size": 2043
                    },
                    {
                        "created": 1698660620.5779407,
                        "modified": 1698660620.5779407,
                        "name": "/dev/shm/incoming/2023_10_30_100957_00_00_K_089.kml",
                        "sha256": "dd6c0b89cdcd3599844affaa8d541eb471f834c4a89a158ef08e96d8639b93a2",
                        "size": 979
                    },
                    {
                        "created": 1698660618.9979405,
                        "modified": 1698660618.9979405,
                        "name": "/dev/shm/incoming/2023_10_30_100957_00_00_K_089.jpg",
                        "sha256": "d24ca83cfafadd032cc701114ee20b1126bc8e650d137d36ae4e01943d2e9bcf",
                        "size": 1228304
                    },
                    {
                        "created": 1698660611.3079405,
                        "modified": 1698660611.3079405,
                        "name": "/dev/shm/incoming/2023_10_30_100949_00_00_K_088.txt",
                        "sha256": "0e01516e1c087c620473944edb69a0f92f3f6ad723e56018e942cb2424960783",
                        "size": 2042
                    },
                    {
                        "created": 1698660610.8179407,
                        "modified": 1698660610.8179407,
                        "name": "/dev/shm/incoming/2023_10_30_100949_00_00_K_088.kml",
                        "sha256": "ddc242443ad214771b21dadbbe973b982cc1c5becbf614200f3fafb2aa9ecb39",
                        "size": 978
                    },
                    {
                        "created": 1698660610.3279407,
                        "modified": 1698660610.3279407,
                        "name": "/dev/shm/incoming/2023_10_30_100949_00_00_K_088.jpg",
                        "sha256": "c5b2a671b25a0b62f51ba1ef2679020277596b758e42de015352c20980594113",
                        "size": 1307464
                    },
                    {
                        "created": 1698660575.0879407,
                        "modified": 1698660575.0879407,
                        "name": "/dev/shm/incoming/2023_10_30_100903_00_00_K_087.txt",
                        "sha256": "43bd79a1a16cd4795d3328a9455f916c740829ae3585f624aff4447f8e1375a7",
                        "size": 2046
                    },
                    {
                        "created": 1698660571.7879405,
                        "modified": 1698660571.7879405,
                        "name": "/dev/shm/incoming/2023_10_30_100903_00_00_K_087.kml",
                        "sha256": "29a63d842b0ef2e54634f627af5e9986d19d5add774f7c5bcada6e62cf028b66",
                        "size": 977
                    },
                    {
                        "created": 1698660575.4479406,
                        "modified": 1698660575.4479406,
                        "name": "/dev/shm/incoming/2023_10_30_100903_00_00_K_087.jpg",
                        "sha256": "2c16a04617dfae7dfcdd4cf57b1d24b789595989d398700ad28e9c6c7041f053",
                        "size": 961534
                    },
                    {
                        "created": 1698660559.9279406,
                        "modified": 1698660559.9279406,
                        "name": "/dev/shm/incoming/2023_10_30_100852_00_00_K_086.txt",
                        "sha256": "677d92f95992d10ed9674c2c1ed6be9980321ff3b37a821d7db68a53bf2184ca",
                        "size": 2045
                    },
                    {
                        "created": 1698660560.3879406,
                        "modified": 1698660560.3879406,
                        "name": "/dev/shm/incoming/2023_10_30_100852_00_00_K_086.kml",
                        "sha256": "bc6c38a731aae3b8bb5cfbd196268463fdbcbf5c9b14384c3c3be38a9acce3b6",
                        "size": 979
                    },
                    {
                        "created": 1698660551.4879405,
                        "modified": 1698660551.4879405,
                        "name": "/dev/shm/incoming/2023_10_30_100852_00_00_K_086.jpg",
                        "sha256": "c4d1882cb7817f18d410ea24721e01af4e1d3eba4ff8952b333d476dfdd501ba",
                        "size": 1205218
                    },
                    {
                        "created": 1698660542.7979405,
                        "modified": 1698660542.7979405,
                        "name": "/dev/shm/incoming/2023_10_30_100841_00_00_K_085.txt",
                        "sha256": "6294b5fd87293a2e487276632fb385d5b237144b68e52560eab744988c0bb6ec",
                        "size": 2045
                    },
                    {
                        "created": 1698660548.0479405,
                        "modified": 1698660548.0479405,
                        "name": "/dev/shm/incoming/2023_10_30_100841_00_00_K_085.kml",
                        "sha256": "afb1c2a6589a54d5d3ce18fe25d9c6fb83f8e96b257cd33e06b35a1b1b4a133d",
                        "size": 980
                    },
                    {
                        "created": 1698660546.9279406,
                        "modified": 1698660546.9279406,
                        "name": "/dev/shm/incoming/2023_10_30_100841_00_00_K_085.jpg",
                        "sha256": "66597369fe133abd2d8ddadd7e2fb99bcd3cadf6ad55929dc26581fa705da62b",
                        "size": 1355770
                    },
                    {
                        "created": 1698660533.6779406,
                        "modified": 1698660533.6779406,
                        "name": "/dev/shm/incoming/2023_10_30_100830_00_00_K_084.txt",
                        "sha256": "8090c2206973afc2c162f015884aae12a87af45f182eaded4925c56607045b8f",
                        "size": 2044
                    },
                    {
                        "created": 1698660536.4779406,
                        "modified": 1698660536.4779406,
                        "name": "/dev/shm/incoming/2023_10_30_100830_00_00_K_084.kml",
                        "sha256": "90ed6455cfc9a347b67fcc1275d7ec08d5aca8ebfce695f3baad84f6a95d4185",
                        "size": 980
                    },
                    {
                        "created": 1698660538.4279406,
                        "modified": 1698660538.4279406,
                        "name": "/dev/shm/incoming/2023_10_30_100830_00_00_K_084.jpg",
                        "sha256": "e6a4a08705d250fd2dc7b137dfb66e328b6784d3282e669e8015a6e9ec6e6d0b",
                        "size": 963666
                    },
                    {
                        "created": 1698660462.1079407,
                        "modified": 1698660462.1079407,
                        "name": "/dev/shm/incoming/2023_10_30_100718_00_00_K_083.txt",
                        "sha256": "cdac457de0f110b21c215e8846ddea5f9156ff17c50bf6e15e28dcbcc9c2d149",
                        "size": 2045
                    },
                    {
                        "created": 1698660459.6179407,
                        "modified": 1698660459.6179407,
                        "name": "/dev/shm/incoming/2023_10_30_100718_00_00_K_083.kml",
                        "sha256": "27c29f34984e2b80a6b0956c0b5dee7b436d7ab81344f3193d65b5ed02322b5b",
                        "size": 975
                    },
                    {
                        "created": 1698660458.9479406,
                        "modified": 1698660458.9479406,
                        "name": "/dev/shm/incoming/2023_10_30_100718_00_00_K_083.jpg",
                        "sha256": "952c3f9d34bc2e3fcc43e47bc348b8e09d0ef1cfe971fc0e4476ebae0828a481",
                        "size": 1349447
                    },
                    {
                        "created": 1698660456.7279406,
                        "modified": 1698660456.7279406,
                        "name": "/dev/shm/incoming/2023_10_30_100709_00_00_K_082.txt",
                        "sha256": "6f5c8940881fd4f838dfc31c5dbb8cada7a7eb99fa330db2fc97392362cfeaab",
                        "size": 2045
                    },
                    {
                        "created": 1698660449.0079405,
                        "modified": 1698660449.0079405,
                        "name": "/dev/shm/incoming/2023_10_30_100709_00_00_K_082.kml",
                        "sha256": "edf1221d1d9d1f5f37ee187dd04c48965a8e9a75b7eaa780d2d7eabdf4abfff4",
                        "size": 978
                    },
                    {
                        "created": 1698660456.2579405,
                        "modified": 1698660456.2579405,
                        "name": "/dev/shm/incoming/2023_10_30_100709_00_00_K_082.jpg",
                        "sha256": "1c7cd2e0d93213cbfbd3ed33d973385bccbac50963e7ede16776df2e19bfb8e0",
                        "size": 1266065
                    },
                    {
                        "created": 1698660457.8779407,
                        "modified": 1698660457.8779407,
                        "name": "/dev/shm/incoming/2023_10_30_100701_00_00_K_081.txt",
                        "sha256": "c0729c0402c0e0d0c985f6becafedf8119ec9a7ae10334289d724aba4667655e",
                        "size": 2043
                    },
                    {
                        "created": 1698660444.5979407,
                        "modified": 1698660444.5979407,
                        "name": "/dev/shm/incoming/2023_10_30_100701_00_00_K_081.kml",
                        "sha256": "0a4579511535ced77af246d7c97f424f6c6c1241e4bd4018df594ac8065a912e",
                        "size": 979
                    },
                    {
                        "created": 1698660443.8479407,
                        "modified": 1698660443.8479407,
                        "name": "/dev/shm/incoming/2023_10_30_100701_00_00_K_081.jpg",
                        "sha256": "21ba573b21b77f9dbf54d44a9f148a7178a09537b4e794147f2200b68cc44d90",
                        "size": 1019591
                    },
                    {
                        "created": 1698660442.7779405,
                        "modified": 1698660442.7779405,
                        "name": "/dev/shm/incoming/2023_10_30_100636_00_00_K_080.txt",
                        "sha256": "e1c7b51f1fbc1994482d0f8cdcb920b05485d1698b19901b9495bbd53831debc",
                        "size": 2050
                    },
                    {
                        "created": 1698660438.2379405,
                        "modified": 1698660438.2379405,
                        "name": "/dev/shm/incoming/2023_10_30_100636_00_00_K_080.kml",
                        "sha256": "1041d058a21a416d51c97e098f745f6fbbc430c3090fdd7a9f470d82505618a8",
                        "size": 976
                    },
                    {
                        "created": 1698660419.0979407,
                        "modified": 1698660419.0979407,
                        "name": "/dev/shm/incoming/2023_10_30_100636_00_00_K_080.jpg",
                        "sha256": "443d38935ca0c37d4d94783edfc9907a9cd010d204cac4ae151a72ea44c816b3",
                        "size": 1032314
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "fc1bf4db15dead7ebedd7356acd805c21f764900335a0d192deab310083595b5",
                "size": 20665865
            },
            "errors": "",
            "filename": "2023-10-30T10-17-45Z",
            "size": 20665865,
            "verified_at": 1698683400
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698660646,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "RKICg4eUaRSJIvXM4cpVcdUJWo/bldmdd6RWWO/fqoWh1Vni1jNDhianu6ltip3V5ctSwRjX01YKCwDGvwNnrBCQaMtJQ4IBY9mvIrenwLe2j/TpIazF1+YvX49ScHZY4lqpTg7FgJghVvejeK45M96YXztQcRjR/ENofVeFuLSDkKRit6yuiEE9PQ0o3RgNAa+WBuphBaByWdeav83SJSsi4tCSodSoFO62vwRzB1UKjtUC7T5VMr7IHWlkn5rfF9bIZf1KRhtkNh8WltAym1V0sD1hSh+DYEb+EgRGuAGcGONts642A3YDNbhul5OG2p6qAKTWFDuPn83C+9xzMHn/xGGGVcA9qzB6tF8Fm+l9wowFyvIu6NAch6EsErcwGfNssMEMwEjYxQfGkzJ7R+RP8H++kVbjM55usfeXvCfQ1FLYZh/FZD/sSLtr6I69VEFQZNxsxcMspK4dIowsKb/g/FPwrtP9SExLNMVCZ26pGq17QzEW+OBkQOtfjoWplC2/2ysNwRtNqWOXy0aizcEsLHeqf5NRt43oiGJfcRyVNmACvpha0eXGWeVi9T9jSbqaWS7bQHDffiKHGn4IvYASBxPvQazyYhQXjsvTDToMKZ2dgky7JO6v2ESaO9/mmdSGj1XL3ycrF8R7hw7o6ukGh80jv8ckNYEdfMBGPls=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-10-24Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698660497.8678288,
                        "modified": 1698660497.8678288,
                        "name": "/dev/shm/incoming/2023-10-30T09:58:16.ais",
                        "sha256": "99d0b5c36342396a95c546a176e7e08a020b497e5cc41398b74ed34d9ac14ffb",
                        "size": 619934
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "94ceaad3ffc1ac11d6154fa7b9e552f2c5d43692a8b6c48644f8c8b45e5b45ab",
                "size": 182272
            },
            "errors": "",
            "filename": "2023-10-30T10-10-24Z",
            "size": 182272,
            "verified_at": 1698693326
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698661640,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "ngiZR8CTChgojWn9aL4aMc08Nj0mK8UkqUlpzmgZCdtxGcEFbW6sT4c1ymfCBmPE97dDfj3y119maEVJROfQ2294zMu97S3fy2/gfwOkr8/V7sIHV44ZJrS3NyeWehVqZmm9/PrVxZZhK3jeNtjYQuIEzih+6A7lwAm0EbdlBbit8UldlW9eCNO80gUhWX/nAufVAYnFb2uzBIN9H65zTLVdnjBRnLK5YaLcgVGLDh0sWfstT3SvLWAbZX79fqBtIKQPw3cNgphpkVCHGOPlglgkOD70dyUWalV1nZx18AvBKfFU6d2VtR9vKZaYdStVo3+mANs5wBqbPuWTY+mvFypzBpP2nsPAedwXU4XAyPSp8EJGQKqe18QbIwS+Pw1z2PEsiUNa13hEeh7t7jyBhpz8X/yjGhphicpu0xLLTx/v+9+AoeD7dCGIlMDm9U3J627bpXlVOmduZubcdKWPmZJxewXkUG0pbfleGOVIhA//nBtY6svkkpAGTsq2bCVwSLjXQtnF8Dsa6IU8e3e3D+hDjoZaxqc9/oekyEchr0JCOT1dVsQrs3mJjyn9utxNmkJFQv97qZAf27yEvZ7bVuAMO+R6ZGymX8TjOoU2kk9eQoHB0L4h+t+BdDaPzSpjjr8U82cQ084Dy3/Mr8+jauTHAGBy7Nhr204isAH5US8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-27-15Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698661511.2032688,
                        "modified": 1698661511.2032688,
                        "name": "/dev/shm/incoming/2023-10-30T10:15:06.ais",
                        "sha256": "0c1b296e23cca60507308ac22a786092b3c7f9611339dbc83c4f26f1f8ab15e1",
                        "size": 7330
                    }
                ],
                "mission": "EFCA1",
                "sha256": "0c4c3fa25baa03e91c004e58a1ef05a16891c6e5c6f0487577078b68930c98d3",
                "size": 2551
            },
            "errors": "",
            "filename": "2023-10-30T10-27-15Z",
            "size": 2551,
            "verified_at": 1698698575
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698661026,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "nKVP3jOd+R2BKygc5pi2NYxuuzF1iglG66Q/4UFSp8LnFqGq/bisaoqKsOCevuYeEJfDjh68/IXb6xQ+IH6ft+5OAqGbQRXQd5+8KPzVfRSxEGBtjRRvjkSnJNDS8LYuGvBaAemgMNz8ncBgmM7l/eiH51dU9WwGnATLa/Scq1s5F/T841hRFuFFsBy1M/OZEExErlXnVfBo/TxNr3CGy8Vmg+eHfHQ/24+8rOc+llYtpjGZhV2+biuXE6CTcZRf5Vf3gftZw294egatuMd3cV1BICKfpRxPTpGHyIvdJMkh8m+P18XTnpu0S2JatJuamMdlMJtx/0XtlmAx1hnURQO5dVe0aSnxK7aCxcj9EK+8DZ6H35aVk5hMIsfjjWKRinAUJ5yWtqcbUjEuwf/T3VAzre3UOvGG5F5pjefsgjcYchYPW4IuHYBuncSGd8vet12O6mzRq61Jdsmyc81CXOP17flkKCs1Cpg75qakcMxsqNWOmibyqL/nMerMs2jhmJQ0cT7X9alb52pfKjXnx2lHeXthMUrzuLMiO+xtJxDcG8CKDBiPJ1LAi27ISetcXpW4iiQu42LaZkawhOBLqcYuT6t05yy1iHbvRh1jGiyqUMJ/ojwiT+mTLDVEbuUS1bYmCIuSlcICgOAgdO3zzkvXGToAdpUU+3UtkBW8GWY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-17-11Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698660906.8732688,
                        "modified": 1698660906.8732688,
                        "name": "/dev/shm/incoming/2023-10-30T10:05:03.ais",
                        "sha256": "d99666fb770979bf573886809ad15936c3828d07630160083bf1de693991b239",
                        "size": 9484
                    }
                ],
                "mission": "EFCA1",
                "sha256": "bcfec92bf180190ba8c71080b52e6a40f85f6ab4141b5104440b8dfdb03c1040",
                "size": 3219
            },
            "errors": "",
            "filename": "2023-10-30T10-17-11Z",
            "size": 3219,
            "verified_at": 1698698584
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698769607,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "GDSp1LbSvBMuHSoX0WSLLG4sTSFB0fx50WcCsABkOy7BqAbERaRrBiSuZTIb2ULiJS1iVieKoxyhKqsSkBuKyhpU+rE0wYj3/johTqR3bN1NgOauQEavxKqHQ5naYEZ4OxcLQsQuz4dPxZhqeDBGxr7bHSQcjvXDuqINz4QR+pfN+TFoIpvQafpXVJv/qM9IDY08IZxowzWw2R8X9cRfOJzoZ89J0MzRQ2Te/0yhqcLCM1+6YvrPAjdG6VPBCV7/nlE9jarOGIIVouxoCI3n245dPKqap0L7JqWo0Dj7Z+llSLJSraudMFECLArKVaV8NTMove8oesengHart94kStCviUvaRy11lmNwVJ2Szpi/kSLO7/q2QYjnDWBM4sCDhfpGHrPK5mxhqrpePBgxXjnDqK2x32rxdPJTBurTlk1u94SKyMEa2jdhjpyl3Oid4XnAjt9jIZId0qy4joww7mt1PIHfMO2N9/k5ZbzybWJ6LTq3C/f9PxfzyeexQvq5bn6+0/TcbmpAUXNaffcJ7i6D7nf9XSYs4P2INQRK9vFbIK6s1SDBGBIxm45CSwD7Qpn2n5/FHOrGN51DJXai2XU7J/BKHU+7Q6Zhl0FXlCi25HK/3597pj8ZTaWIl+v3+48DAo5DgzJfq7FrBvFEohKW9+BIVrRU0ADoUN/XYb0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T16-25-39Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698769530.473036,
                        "modified": 1698769530.473036,
                        "name": "/dev/shm/incoming/2023-10-31T15:55:29.ts",
                        "sha256": "115cfefcaa110ebdf36d261bfe2f2591e63f86fcea39ab908c269afb8b31cc46",
                        "size": 408717264
                    }
                ],
                "mission": "OSP2",
                "sha256": "83d98fad5d757302fbc72b8fce03ea783f75b1267d047330e9cb33ecb3289378",
                "size": 394670657
            },
            "errors": "",
            "filename": "2023-10-31T16-25-39Z",
            "size": 394670657,
            "verified_at": 1698784282
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698771244,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "oTURbMveDVmFOBDmEsyF6i997Ie/2IqziDmXp6n5yS626sNenPE/kxY8/EGeVTFkcGPZRLrxNyXoZqRh4N0XCBF19hf1u4ux6aCZp5fTuginCCkVOWREhIQa9VdzyZqQcC60EBI7QFMBBClOgwWZhzNhqhhRZRK23DCVHJK8ax6gxBoqRTwDJktfWPavABydosSLFeyVeJ9D+ht27qgNNT5E2ORCcmRA/QHjA+NLjxHH+n1sQtAMxNJA5hiBtn/QIJEioUFlPerBmNvlRYjxggsijrDOfO184HERvsbmNE4Jvushn/66CQtqPUyB44zVnLYWGw7ujrj1hGyp+Nzf0HBfwZC749zSi5IYPgl0X10tBJBfk5dAvbWFR7pP9VlGxnXV0fzhRYxxjyGAy2rpz/mPmyv/V+GOD1MSkEGCmw4a2z+J55Rl0tQOaAjmpmG1T7CXS3SZ/wmCmDm9eE88w2cuhAVquDhDF1WZFoQwVwXCPwoguA+xwZvvsSB6G5LYeMJpbhQRa6GLtocwLbv07JIlPxumH6/0t2hcsSqLJS2319pglL7uToWy9VUzvsujLfXbfSboUDVFZxpWpmtAvI/jR8md2y7Fik+Q+xGJURNaSho0H06R/AIqJaD5eQJvNErJvaDvI9X3iM7Sfnml53pmTzYYrLXbJrWHWG5x0Jw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T16-53-44Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698771096.543036,
                        "modified": 1698771096.543036,
                        "name": "/dev/shm/incoming/2023-10-31T16:41:35.ais",
                        "sha256": "3db14d29855d1812d8f9239b7e56dcf94b9adc8ade912af9b34b0b9fc7d1cddb",
                        "size": 62708
                    }
                ],
                "mission": "OSP2",
                "sha256": "5d453b911a6127b23cda2530c2d5d55b43dd5b9a75211f4cf4ff9282c08ac3fb",
                "size": 14881
            },
            "errors": "",
            "filename": "2023-10-31T16-53-44Z",
            "size": 14881,
            "verified_at": 1698783488
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698770640,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "SPtDzVJNdxTIqwAL21TIkdL795jQPlWGY5b7wVoioaO3Hpk3q2uLwC3XgkTwIR/L2dbb8NJlaNDoHAgJa2dU/yW1iN7Uz+N92CLzqmeb58gsJlJpjEU5S/Ta1INj3XLw7zXs1Zc/wu5fKFIDHx4AFUl7c+/LV0M6T7dvY01QDDhyDHCj0seVydx/U2aif6JmUaBp9NTWFTpYOPwFxymPhALBhgzjCHLG+1ef0/qX09oh5psqfsyACoiN08/C66eC6mAurIfEr6TYlE8/fmeTuQohdBS1SObpJQV8S1O5aCqvqd6G5Lk8Escg1AD30P4LJT23AuUBJFIQ65+Z1mLMHRKrenY3bj3L4DW0vvjpgj3MyO0GuczbDcxqSbkOBIIivEmS3CUIH7WMrzny3XvjwnWRJwZyVRtGJFeBM1NKiDuTMDnuhixwNb8bXM0dZPnpwwiqXthg9fC96jd+q6z65Ov7RiIFuLluT0TKtF8v2sjGaygt1gtWNPTlUC/KNZdrTUP9ccIeVmioWFIIdNZsPyQoXtZ3viooTxKeF+EQknffYIApKTgnWGhvp+jmkQZo7JApPdWVg43HwWYCqK1ParCIRhhmaB7eaUY2JGjPBtJxQiTsD/EVZRRLwq+KdL8kg8fD9ayIcbQyzusijp3UKQp/GOkP7Zg45+ShQIo7flA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T16-43-40Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698770495.003036,
                        "modified": 1698770495.003036,
                        "name": "/dev/shm/incoming/2023-10-31T16:31:29.ais",
                        "sha256": "00cc2366e8b5210073fda472ff7e16b9d0d7266617b8914717948fd7edd88b1d",
                        "size": 63095
                    }
                ],
                "mission": "OSP2",
                "sha256": "1c19e285df351b8cff2d163b488cfe88aa5bf2bca163510ad08076206c3f720d",
                "size": 14688
            },
            "errors": "",
            "filename": "2023-10-31T16-43-40Z",
            "size": 14688,
            "verified_at": 1698783536
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698771418,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "gltySlKIqQEWS/4/c0P8jEaQe/4cRkR4G84N6WYgegqdC9ueOrpEMtQ5cQVQ9b8nG9aErr3WWzzgaFHAot/3Bdn1aUwXX6PtxHdwfoWzDO52A5f1eGFiSbETNpIuau8sNoMw0N+Vxj2lHbASi1ihJiWQJ3eYylrehqGTaCO3zidiok4i7Sc/5EjXXmXv9efmvZ6awvuq7+PceU2AUh14fig/0feMosDD5vTvL5UaSRdWO76YvW1OCJb3uBrt6luA/9Hq81cGVK6Od+8ZNtpoOBh4BCDcp/XO7Y+r8AKHgMyRDa+oh39GvuyyYecYPkIz3Z/cfVarFRCd7hwCt0d0EyIaVMEE8QW9VZ4WulaRlVqb5Cd67Y+4t8RCIfG1oMkqPpck+46yYV5Cwv4jllCSiOJVaJwsberd5nQnk2R7US/fIG6PSJ71wLlomnbtaTzcoaQVLTFp4La1uTc4OAUUth1xzK8QxgnS11ip56vjGn9yCd/BN/a7CZUQArz2zj7CFxY57W5NqxaOAMOi9JxpqY5b0wWowyWss5sME4XF2CKYV/VKQ0q8n7Vb0NXkHG0iwmdh+AzbO2NJdsjoSy61CfrWOOGkCIwP2/ApX6qkKgeyZhoJAv3MPfoWdFfUBPXvsSJOZsuOMyWVK3YK/Da4KKYD0qSbL4btrNqOVpruXTk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T16-55-48Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698771336.993036,
                        "modified": 1698771336.993036,
                        "name": "/dev/shm/incoming/2023-10-31T16:25:36.ts",
                        "sha256": "59d2404c0c8701131f7394235d86df3b457903e7444f9bce4a2e5534dc2c000a",
                        "size": 408312876
                    }
                ],
                "mission": "OSP2",
                "sha256": "3acc7bec99441aa0ab326f0335476efae54840490496347d75a1d3e7c07a2c8e",
                "size": 394368828
            },
            "errors": "",
            "filename": "2023-10-31T16-55-48Z",
            "size": 394368828,
            "verified_at": 1698784075
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698772444,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "YmTLejqiQ5gY9LvcsHoyxgufViwxIy2cjSQ7GQENO8cVCEgNcBgjEIJ8K0ngENPrf1tbThY+bVHtGLad9dVgpYdGCTBVeZPAwCKIWq+hauxlNiOLBvFoeGl7cL1/YLxYYmxU8GuXsoGiiglx3N1wIMPsZEx0J1NXJQSvPz2YMsvtI7i5RduqUKNPG3enSHVPLbhFK20y3mwa+Dj3irAlkMj2erR97RN0DUHwo3f6KgJAojFMHXxdK62QOBf1jsNvQx1Q+4EpPnTbwNskuGqMlopZcaBc4D2DM2Poxyc5ljseyfKrbGJABbpLcxRZAFkIteENtAC+izuAo9AMVPN+q8cXfDwRW5QK6HwrjCS23hGnt99TkYiObcPZ56Ec5P2+3/MlSDQfQtnfoKe2IBy8iruvvO24BO6GDf3aWnsU/Xsu+jNw94epwFRueg2+hp0dunNbqoQtDLcKPta3YMEYzPJLwyb/lGj3VpY0kXv8QFBo33J1Nx55fmLUcyzYhVMEjVmTzTVh+UMoLI3EcRhO/jJUDFCdXumop1X+wJyT2JEYeuTFUTjjA0wEJuqqDUUVaAqs4YE8m8CqK5yInhG8DicBQGwHUa8Jbe3oSHOksSUJBf3wVeyneRxryolY2CXo5o5I5na1AmzAQUecijgDtfTWanrw9+4h4bs9ewYGrNQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T17-13-47Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698772298.913036,
                        "modified": 1698772298.913036,
                        "name": "/dev/shm/incoming/2023-10-31T17:01:37.ais",
                        "sha256": "d8781ccd4a6ae108be6f9b83d896e05fb5efef4e599d0b09ed711e1cd983a8b2",
                        "size": 74066
                    }
                ],
                "mission": "OSP2",
                "sha256": "859fa25d42771fd37166037d4f3edad8df073ee28963f74b7555f40f63c7f611",
                "size": 18459
            },
            "errors": "",
            "filename": "2023-10-31T17-13-47Z",
            "size": 18459,
            "verified_at": 1698783497
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698773153,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "qXWePM1UsOwSth9pPKuPbrHefiRqeN3xD/4/1bma4rl0gWgjBz706PFCIpGVHirwySKAGRcvhOELJ9OCvw/iJuAn2AQ6nvA7blau748gzv0pBH3DpBJgPPRIj0QmYYMucbLtbUuaAmOaGO5pyrU1UkOSiYNldFc8ERLznzWf5rL2c3NfRE6Zazg+jK5+1ecPQMDorQkHWLm31+UnNxcqgP9Wetz/Wb9z27yGeZOSriZU3VJZXQtmjc5gPmM6FoFfGArhZ101UBI21whkQbBNthAmPYjRy5V4wwbHUODtjAy9ygBOwNi7sN6avpmm3Yk++OriiJmhtvTZosR4gS4hgTGQlwvtUH8KACzWTDMBPUD8pCdw8Yz/vksHTG3bsUD82ONdbT2qANuKG8Zo/ioPFdKUpPjE1qx4E/NpZal3xiBEPdVfdsa9kdAl8qUEumoArCZoz7ggs7fjyaw5YLBQwShesdDg13u2H4KuLkbklOPZuOju9RnrWU6Mq8W6JD1aij2p6gS1PtAcfJOq5B0jyGHJYtXuhkD5cQkImXN62F8eMKSxFv/LX0uO3PhqiTPIE3MkaX/Lm9D9JamDsqXOeeYRmM18+foAcjXt9vPFuAnFCVcmVCkgb/4m3dOydj9UTUJU52cn8II7EBc4Kfd7QTJQOPyMWo2tzqMha582umw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T17-25-33Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698773016.423036,
                        "modified": 1698773016.423036,
                        "name": "/dev/shm/incoming/2023_10_31_172305_00_03_V_029.txt",
                        "sha256": "48898fd417cb8cd14a8ade56115b9f856c3d5071bef122a88011ebb823f2c97b",
                        "size": 2034
                    },
                    {
                        "created": 1698773009.943036,
                        "modified": 1698773009.943036,
                        "name": "/dev/shm/incoming/2023_10_31_172305_00_03_V_029.kml",
                        "sha256": "575c450b08897b1cd8816e8e0495eeb0d404750c0725a1f3859c602f201b89a1",
                        "size": 975
                    },
                    {
                        "created": 1698773024.5830362,
                        "modified": 1698773024.5830362,
                        "name": "/dev/shm/incoming/2023_10_31_172305_00_03_V_029.jpg",
                        "sha256": "1763433f8d04683730fa52e1dd98c5d3862687efa907e0ff7baad74cde54346b",
                        "size": 1265839
                    },
                    {
                        "created": 1698772936.693036,
                        "modified": 1698772936.693036,
                        "name": "/dev/shm/incoming/2023_10_31_172154_00_03_V_028.txt",
                        "sha256": "bd3bf1e110eaf6caaa2743382fe7edfb180c458770bb3244ff21b3a9e69d6aa7",
                        "size": 2032
                    },
                    {
                        "created": 1698772932.273036,
                        "modified": 1698772932.273036,
                        "name": "/dev/shm/incoming/2023_10_31_172154_00_03_V_028.kml",
                        "sha256": "b1e04f54ec0415e7566db31a4d8cdc38ffa793f502db807c8fc7cce6c4f0a2ab",
                        "size": 977
                    },
                    {
                        "created": 1698772932.1330361,
                        "modified": 1698772932.1330361,
                        "name": "/dev/shm/incoming/2023_10_31_172154_00_03_V_028.jpg",
                        "sha256": "4854ae8a154068f8c09f57e348d8970fcd53a3b14c230f4a5fbbfc28b7a734f7",
                        "size": 791316
                    },
                    {
                        "created": 1698772900.283036,
                        "modified": 1698772900.283036,
                        "name": "/dev/shm/incoming/2023-10-31T17:11:38.ais",
                        "sha256": "3cd8ee8cd94c0bd6526e3bb8bcc68ed482cc98108e940c3be596a8c867a514e9",
                        "size": 73182
                    },
                    {
                        "created": 1698772872.923036,
                        "modified": 1698772872.923036,
                        "name": "/dev/shm/incoming/2023_10_31_172057_00_03_V_027.txt",
                        "sha256": "61217cdd046871a8410b80a03addd644a2eb49ef189c1441860748c1005ae1ba",
                        "size": 2030
                    },
                    {
                        "created": 1698772873.1230361,
                        "modified": 1698772873.1230361,
                        "name": "/dev/shm/incoming/2023_10_31_172057_00_03_V_027.kml",
                        "sha256": "3e29116dd0efdf2e03e3c90f04bc7512486b0e866e5b719a7dada0feadd5df04",
                        "size": 978
                    },
                    {
                        "created": 1698772871.8730361,
                        "modified": 1698772871.8730361,
                        "name": "/dev/shm/incoming/2023_10_31_172057_00_03_V_027.jpg",
                        "sha256": "f08e01f2fdd687f12023855817bca86be1eadac0928c165573506d6bb74e9ad2",
                        "size": 1047814
                    }
                ],
                "mission": "OSP2",
                "sha256": "c009507295a81a2be41b87239f150a4e20110208e578fe63f8409cbec8ce6a04",
                "size": 3124940
            },
            "errors": "",
            "filename": "2023-10-31T17-25-33Z",
            "size": 3124940,
            "verified_at": 1698783501
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698771842,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "so1bVlj0ykBJTN8NJhx8kR/jTl6F/grdbc9v+BkMWkowWKZO2CVOBRZERG562BFf+X0eI0ZgdUa61peNpPAIm6z5RFlqF39Ij4D7WE06g1bunuoYpH25/5IlRFStX8sDpcXByVY4Ai7ub1krODvLqLn/id9zrdZCgAm6nPy5ruJDDPxeqtcVdhJceaqc72CgbRNCCGrEz2uZ4hLp0dXGNL+IPbBchnRET3lyd7H0RkZ4Ea8oerTB/ZnuvLimrsqqQLc0C4S4NXOjs9ybBMdbB8WgVNq2P2SSs9L/lNRxH3C6ziTmMZo1oCKCMyh20pH16yBpiDvnQebOePE3zJK7N4H1pZUyoGgyg+DvIjrk5BITy/TVA5qC7Xl1d6WUhCiOb2DvjZ0Q5gTWtes3JRuu8lSR2u/yIcGgIPiZ9mu089W/NKSJpfpjv1L+OwzfDyFfJKYos+sxctDkj7ZHPDUoxwu9iyvEpAXmSUaBEoZP3dbbPpAQdcFxZL3S1v/K9IgqJT49JN4Mv9eNdr9bteSd2bxMrGCTxaUtyoehMgPDmYBAbTcFSMBrBHaYgEZfpDD9Dgm7rvk4mwD6IDuUwbQe1WHOzTDsdxBnaCPvEgaZ8vORb4MJazKz/WRdjefOwe7MsQjU8IUCTWN6J88WPvzZeXBic9auJPfStt1NcNbUCxQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T17-03-43Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698771697.403036,
                        "modified": 1698771697.403036,
                        "name": "/dev/shm/incoming/2023-10-31T16:51:36.ais",
                        "sha256": "b100af1181bf8c24c18b90d6503ee00b340a500ffca959ff5551f0ae74f1ce3e",
                        "size": 67844
                    }
                ],
                "mission": "OSP2",
                "sha256": "2f97b948c52f39408ed26d2deb00658a029e891404ee39b7341dfae2e255c38b",
                "size": 16916
            },
            "errors": "",
            "filename": "2023-10-31T17-03-43Z",
            "size": 16916,
            "verified_at": 1698783519
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698773455,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "upyjBjfGrjIZ+zHvc7R8UO49lD1WdvcM0l/Ar2OBrN3/5tBi6onGp8bA2x5HgLdAlOELBkNkx9y9tdky1BRLISRjLXSROa4IZ/ufNbpXW9YeB/L6pWI62RLEaLZzf0WTA6ZJ8j2UC21EwJCZGEKQ5oh1nZVyKrk4YQBXK0VGuzhY6R7lOcQEd/XSpBy352Qd7Jnu1q9oz5v4CPaViZPVMq5746e9ANtYavhISw2jX/oSRv66g26Nvhd6qYROkbiS69KA9C6nXI0/iUkEDrVYo5HSHxrWL9Ijr458KMLoLizqiTUjPgoeQ15wi9k4tjfeg2BypZC4/nJAI/G4H0c4YeMCTIQHxzlBYU0bCgPvBsdjyY1WtahfpO++CRRVcSIF78T10UhWvJ5xn7xeD2LRIWhxdpq0huuKPO5bb2HY4SlzPBtd03gJNiPK1ZHwDnZw4sebK2oynYhQuR3/KH7kU3xQZxR0SYkTBjaDhso8CNJFXwcsBsjXHAXCNSO6sHlrTQP3JCqyEMvDM7WmxLcWC/CbKgLiVsornku3XZMhHjRB2NuApOxLzOe7Lc1bfGw0iLqoG65M1BDjBEqbx6lH4CZBQrGjGc8/TlGP05ADcbbWGNKcfIwfBw0Vu8RgVuJ83cvGdQ50mMDrjhdoYVwxjpm+BMV9+yc569TZtZ1neWI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T17-30-36Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698773314.8530362,
                        "modified": 1698773314.8530362,
                        "name": "/dev/shm/incoming/2023_10_31_172811_00_03_V_030.txt",
                        "sha256": "da58dad5ecf074fca801ff472d4208d39af71a0c608496d242b44a33ff792213",
                        "size": 2037
                    },
                    {
                        "created": 1698773317.913036,
                        "modified": 1698773317.913036,
                        "name": "/dev/shm/incoming/2023_10_31_172811_00_03_V_030.kml",
                        "sha256": "c1b47317455c2041c83bc761f11d0b624f5ddb69203db1b6941fec778570c5de",
                        "size": 978
                    },
                    {
                        "created": 1698773311.3230362,
                        "modified": 1698773311.3230362,
                        "name": "/dev/shm/incoming/2023_10_31_172811_00_03_V_030.jpg",
                        "sha256": "fa20a8df923545d50782dd59a15f7bed192a2332d9cbfb90d38f94e5137394ae",
                        "size": 1072364
                    }
                ],
                "mission": "OSP2",
                "sha256": "56adcbed7d6d0f279c2ace96ab294ff55ea031403945931bf9bc470caef06f7a",
                "size": 1074131
            },
            "errors": "",
            "filename": "2023-10-31T17-30-36Z",
            "size": 1074131,
            "verified_at": 1698784058
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698773223,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "XRirB0bJjfNNOXCLrEOEpBsWCrCJYfwppd+hsm5XRLXLTQvLWl2Sm8IEVEnJgkZr1zvDeAxl1kXgwxrlEYjuOPV417l/lpJ+686R9mibq1xyQ5mSP41B42mkL+BTnbktYLWN8twsfTqpvdfAvBdO7+M51xWCl9U3LP0ey8mjy8jTwsp9a9lYwyGnoMWq5hMLAtY6Nupig5Rcw2YqD6YqIIUXqDUzAbe4SBkZdVMbqgtvFlD0NqtlOF4jqeCSxdlxtKfuwUXUVGB+2bjbmoLjOMVGUMkiBCUVuciHIQ/SnriCDSDdqK1ky9EcbzXrW7ab8hDWfq1+gy0d9pgRjRBxCJpXqrdaj9enuXqxBCyKQRC2EoMcD1uzRmBciZ+z1csr7tkB6uisR6CTxn83auOeUt5j5KTR37UMqO8VCOAsVwl5wNZymG7t4MORvPUARkniU7ceJkqGI/mdUxxAEiDyIm6+MHkHjN/HhkLnLYOCurnN/K79OlfIzVYVulK0L2PYFpQMd/lKAb+Sagj/m7PXGPiL0x9yM/QP1ghSD6/NyeydtdU736Okt+iBwQwMZPbDamB0X8k4edDMNQ3bthht7DSqEYl7Z1KmuR4OCjU/THp7KetubLL8aIa7ahYEvvklPZD3MIfjj7MV0WQQkkSl7ymE6Vx7P7+nVkK83kMg71I=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T17-25-55Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698773143.6430361,
                        "modified": 1698773143.6430361,
                        "name": "/dev/shm/incoming/2023-10-31T16:55:43.ts",
                        "sha256": "9742cf4d96a9dbd6363f4a2d30c42a5c575a0ffee50aae906436acdc0c75d28d",
                        "size": 409916892
                    }
                ],
                "mission": "OSP2",
                "sha256": "e8346c0a1ea18323b7b2092b7a41caf13b7d0a0c3cc20af64e40da3c35107c89",
                "size": 395776820
            },
            "errors": "",
            "filename": "2023-10-31T17-25-55Z",
            "size": 395776820,
            "verified_at": 1698784155
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698767797,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "JdagTtxkSOMhamc61cOmOrvKJVwMOgb6d2TaU1tAtXu9UxJedeba1ek0KNnths3U/M0eGOpt2rf+xV0YazzTPN9R5JoQ2VEMZP/j17KzZsgpb70MH4dgWoe52sLq7Rx+z8Bekjr4k6cj/QAXw7gtGP8Wzaj3tgeumK9tTEfAJixoU7vntAEsi5zd10bEcKs+EofT5ggVJ7S/8xs5DhZxEwNb3YL9E8h1NQ0mMoC7aXVt+UNCrdpue3Xc7sppBUkKTaBG2qEkL7vQbermo+9CkWVLJgt6JNLq46aMvyzRX6+FZfr0bi1Q9mDTX1UrX6xgbCL5CPHVEL1L8l4HTQdMzGCNdAvCWrriDFEQcF/zgJATWRXC+ut7RF+/9vLaUnbndzMhL8NqHa9Mfa3/4Ksvn5L0x29+2Iza+jeIPwGblsOF/UD7WZ01sboMyDGvrOYqx0p7nxO8D/sinEIKTB3F2C/MA52msf9Oj9keZMD1oTwzuxub7T3hOESmvSvPyUnVlxHsjN9KqYDgI8rixeWfT9u/f3xLxBVSybGr5PYXMv4M0t8QPCuCJh+5Heeg5X+OJDVgF/CDKjPZ34V/LODdQAt9bSkEiu3VbPQmujK7IwkCz51WjZko0RfoLD/vk7bTkj7jfmiTXqM3FGfLWuZmAtsRGKlnOIIVU2YVKy7J5zA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T15-55-31Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698767723.703036,
                        "modified": 1698767723.703036,
                        "name": "/dev/shm/incoming/2023-10-31T15:25:23.ts",
                        "sha256": "3e7d8a5a0738471db69f05910eddbc498f039ce055b3016decc2f5ef716accd1",
                        "size": 408508020
                    }
                ],
                "mission": "OSP2",
                "sha256": "175b214124b1ac83fa402931ae9fb6e095f9728d846653ff059287eff95aeea9",
                "size": 394005010
            },
            "errors": "",
            "filename": "2023-10-31T15-55-31Z",
            "size": 394005010,
            "verified_at": 1698784191
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698660444,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "CPjQSW2l4pyIig9SpGtX91gBog3gILUAYuFJE/Jo6C5gmq0LQly1KPHFcLLJdox+GyjtqqM1hx0vf1OpvFjCRlWtt1I3lsz9HuWJdFlkoosT8UCWgnZ+DLqo8Crl295NmlXKSy3nFUQsX2+lb4qN4NXcg1oYJZwb5e96eZJVS/Z0HLPXhyAF5UpyZqNrnGaRZw29oej0jJkg3EY/rHk1WW0Qa2tx7EMijXS/WxSgpxvE3+SGqLKEOty/QOItGK2nztStQJeKjmZS8xkyfaU9xoUM2gZALaMV6fwKZsxVd5oqk78o4Dr6+G+mZpcJmLw891zUrijF+gcyws5geOfX1E3nJQsM587IVi4R2Sw5xlctjuCD7Oj+6lO2Cdn7vek0NRsbdfmvhW9BRSA48IPJhvz/6a4zjaxAXPfGPdb5U6HczIh6RuhSgoG/LyhGJvnO19EwGUEALBBuxWXMrlW8nunVONZxQTHnmrwUi4X48szc3ZFZ60RqrrTnYxFvH3obhIJUlqISIJNAsQiVrrgwMu8mNJWOvcaoPXbJjo1zEfdQyBdFh5ukjE6JWy0Mr+s9uC3su/f4/dT91oPM+pMtsFMZycviK0VCstobwTfp/9WcZfenbabXhtFJadiXesIMMI40H5i0z/FGX28xBhQoOE1Ko9lbbRFJoq9TJsXlHGw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-06-51Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698660403.526036,
                        "modified": 1698660403.526036,
                        "name": "/dev/shm/incoming/2023-10-30T10:04:46.ts",
                        "sha256": "e28cafef52b0009e1b625f5a6936b4ef0b54fdc37a9f1bc754529c085096233f",
                        "size": 25570632
                    },
                    {
                        "created": 1698660280.146036,
                        "modified": 1698660280.146036,
                        "name": "/dev/shm/incoming/2023-10-30T10:03:53.ts",
                        "sha256": "4f121fc839e4a8faa852385db4e8e158765b24ef994b9c08370c43bd948ec956",
                        "size": 9894440
                    },
                    {
                        "created": 1698660226.986036,
                        "modified": 1698660226.986036,
                        "name": "/dev/shm/incoming/2023-10-30T09:57:42.ts",
                        "sha256": "41ca95659c0b203de0e4aeeac6e017388c7e0a8ded1067b7dff1294757ffc1a1",
                        "size": 79452936
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "63e0316116939a2ac34d055d19ad85f5578e96a9722101249e9277e4d840dddd",
                "size": 110520703
            },
            "errors": "",
            "filename": "2023-10-30T10-06-51Z",
            "size": 110520703,
            "verified_at": 1698663809
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698642131,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "I1rL7H+KxRJLx5uqgG2M47/yb72mrXaDhFoYgYB0dzYKGegXdzy8Lf6evXnRWouHW9E0wHiO3qYm/W9zLvdd7c8lAbSMTvu4IhjrKSsuaZC0yavlVliIdSmtY9AG1HdvD27y4bLYsFXPK37rFGM9AEVvPBVfHJmWPeiRPR+taE6cAerbumf9r24AJnpu3LkE/ZCmcFuAPTuL8NarleUGqT7WhInRsGn77APiRNtXYqtXf0XLaPVoa5Mt1t94B2IBKnofZYFwFMuyffFk+ps0wv1Lx8ZoHIaSq5WTF+TG9WHebO0tTziWAEVwaxRhbpS71FrC0l/KFUo5r5y31suSWz6ziX/VXH/FRuxaeXqS4YlyLixQj66a8Wi0tQmqtHRHVdMAuH0tZsY8+Pr/qJiYPvg+pPthSubxZm7EQ447ow7rf50g/ShqGFwPLHD1g9cRT8wGgTCJ2Xvp4M43kiNYlmsOfM88W+0SH2/71wgQrzeQj2KEkYwIZwmGFC3eyvdaS2HXAkFyxgo56mzcOAOOVoXA6r032VkYSfOe8k+7VPoC3iCDuOsO+Tkb4kSiJ5edVazRwNs3Erjnrh9qaj0mbZBFLsYRSTxe5GDMd0/vjjf7MjqsG0icRgrKN1134Q3cqXcWGCLivtUtPffNxPxCkdGGWrc8aQRwli/xFOR2Z9o=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T04-59-12Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698641826.346036,
                        "modified": 1698641826.346036,
                        "name": "/dev/shm/incoming/2023-10-30T04:47:05.ais",
                        "sha256": "4688f3b7d6e50cb9007e4b90732f18dffffb312372bc880abf52209b50ce35c0",
                        "size": 7324
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "4e151defa0ad66e8e7a53e44618c518ee85f3c682b4e167580bb4dda10a047e4",
                "size": 1796
            },
            "errors": "",
            "filename": "2023-10-30T04-59-12Z",
            "size": 1796,
            "verified_at": 1698663816
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698774014,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "tCl8AH+y8qDcW1gjptzIzqCctQYPSZ8OpZ0+ZfUkSlCtKpZxidsmNcrOK4i1UtQmsdlwU4+F/Bsedcgq7+6ERy6mFYaFCn6XodDDpAuVQYwz9sza1p/GFZneMsE5SfTGDJ8hYSoOsYgCvIBNgZiyjg4yHvID1ZXOA6oDxw467DXqlDLhhIg7VOgSNfMmZLb3qTtqBGnoSbjTS0sTkI630Nyuw5T0XnQW4XVFVcGFTYPzYeFA8Mv8THaGPx2GU1c93MHMmpJxI4xJYiTsv2lQP6sD7Sww2jb2S0GZLRJnlVTac6Fj0bcbOq5K7PzV0coDw5TMB1gsVKBerC6crS3UdAgj6mrTM1KWAzX6OpSiAqgYqw02dbUQdLDx/AnFi8faXE3EpOpIIojv3Hku5MfRDEBOZnWIyv1cx9iL5B+iuvJotOOzEryH7hz7kDs50nYdAwJHdA+uO+/hDcmRq7eEHFxqdhXeAJriYYVvaeSCy+pFUuIpcjGNo+HddSH+IjBmXas6t3aBZCIOCauJxGQXglHHoT0NpeBt+ZA9tjqTmWl68ynyAwMZinBYQOkgpZwPrClmY4lPZvzbfQoWyB5H+DCVnQ0ninImntu/XDq4WcECDRS9nQsAuqnuiZJUuZE29eiORQe3hi1cmcwz0xJJ4IQLOtQxOGHcqXHm8gNSqbk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T17-39-55Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698773871.713036,
                        "modified": 1698773871.713036,
                        "name": "/dev/shm/incoming/2023_10_31_173734_00_03_V_032.txt",
                        "sha256": "37a7cd0ee70c96384f3bb59b63384e40c035dc6af107739feac8fa7de41b3077",
                        "size": 2036
                    },
                    {
                        "created": 1698773871.543036,
                        "modified": 1698773871.543036,
                        "name": "/dev/shm/incoming/2023_10_31_173734_00_03_V_032.kml",
                        "sha256": "6a0fd65f2fc84f4ebc5d66d97153a71b9a710e0bfc971921f8264d6c5e4cd92e",
                        "size": 980
                    },
                    {
                        "created": 1698773873.3530362,
                        "modified": 1698773873.3530362,
                        "name": "/dev/shm/incoming/2023_10_31_173734_00_03_V_032.jpg",
                        "sha256": "92626c701cf6a8341ddd4a5ce3c725283bf0b14895bd29bf619c0f6b9f5556ad",
                        "size": 1057689
                    }
                ],
                "mission": "OSP2",
                "sha256": "68062772fe20f28d683d0645b24ae5053186734014e800d5f9d67a9013fcdd4d",
                "size": 1059466
            },
            "errors": "",
            "filename": "2023-10-31T17-39-55Z",
            "size": 1059466,
            "verified_at": 1698784096
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698773651,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "aAPqIwLiUeo5PI73FG6NzqvaVGYgK45sXVkAbGBK/r0MKJO8cHKcUb//faqyr2oT9Ge49ZRK2yXCyt2/ZI4KQYe6sP8ZnzlIRPa9AEBucGVtqVCOZKCbmDqh2bT3DJbdASa9rbMrABCy9g3FmUr0UM9IILCSxcaUqh8+yeXQGGoSvPbqtpGsO21cnRHqadPrfZ6nZcD7QSy/ZRxaoLR5j8FKjR4ey7LpapXHIY1VoiG/YW62rGh6dDCddOV+IvnUi69X0YhejyNoPfYuErVCnpD2bPZUL79BgFHTVBx4geFDpQlDoEOhgoEQ22Yz5zlMAJWr0ZzTs75jpuYxoNT2BB44gjI/jdWHcbcvLvkbTT0j5hhyx4bW+TEpjTJeL+6HPAVXQIUmF70Ukqx2LFQnHQQuk6Hmr4B3dere0rzHuQxZnnyWOhz1767jEqaBRw4Lw5vXgTfmpFasj/g1WiuCDtHfYOiFZWbPOntEVWU7yV47dFihqMBqVDyYEA6ASjoiVIesZa1x+C53gvYkRZif4MpapWg0R0sPJXEFtNxF8shmBc1DQ/7x7UylMdpBGoAJBvDLkKuqcEeqpQeqCk8krNHQrcZ7uB/pbKk6NQKcbtsxbzlkyeeI+UgflaltwdUqA3qsJjBlCEwor0oCPCzTAZRQ/lRLdc9lGOsoLobujUE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T17-33-51Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698773504.763036,
                        "modified": 1698773504.763036,
                        "name": "/dev/shm/incoming/2023-10-31T17:21:40.ais",
                        "sha256": "0c3e5894e93a4942e395e1c6da492406186fd5a8efbb3fe0fec131405b630a61",
                        "size": 68498
                    },
                    {
                        "created": 1698773461.733036,
                        "modified": 1698773461.733036,
                        "name": "/dev/shm/incoming/2023_10_31_173037_00_03_V_031.txt",
                        "sha256": "0abb4cd07d3b1e2222960e82a1c182047594200d7f74d058fb08bfeeaf471071",
                        "size": 2035
                    },
                    {
                        "created": 1698773461.423036,
                        "modified": 1698773461.423036,
                        "name": "/dev/shm/incoming/2023_10_31_173037_00_03_V_031.kml",
                        "sha256": "66b091073cea52091daa76d6c330b5cad479d23404d42c6187fc18dcb6773cfe",
                        "size": 977
                    },
                    {
                        "created": 1698773461.0730362,
                        "modified": 1698773461.0730362,
                        "name": "/dev/shm/incoming/2023_10_31_173037_00_03_V_031.jpg",
                        "sha256": "a32cbb00438a655bc7dc25630740b34b8994e498f6e7f4872a33c790adec048c",
                        "size": 1067220
                    }
                ],
                "mission": "OSP2",
                "sha256": "ba5b12550b6ab1ff741efbd9962f675ea77a877875b8e92efb6d8c7212674262",
                "size": 1088041
            },
            "errors": "",
            "filename": "2023-10-31T17-33-51Z",
            "size": 1088041,
            "verified_at": 1698784325
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698679196,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "s9ApgLhjhKGwOQz+cC70j4A9IKjHZMXCmqjwNFjyJAPFsHlAhTy5c2X0lpLqAf2J82ZE4kaerCz4FRUb8JgFlCbFoB8kowMUubVY9UPqo4jsTv8XGytcGdD0x1lk2Xe+YDC1cLcjKS0bNSOqYCvUp/fHMKNOTLa4Te2qb5LuuGDXOVYPlyo/Qpdcy4XY3tQLGOwnpW7CZbBi3iBrRfQQQ52tlR3iDgbPzbTtBYSgA0kn28dzuL4WbXqINwKa8TGs01YGmEtK9yMWhDJMB1rK11iPzR60p8+y4ggqloKUXWLi/CpiULLjtrIGiOO84fZ1jk/793Y8icIAgtcMzpKwN/zaeq9jI0ea9J8ew2CI/4OMEWO1QkBV7GXz0PJjpfCk+Ok8IDX+yz3/72E5V83qqwAT3/SD3wbRVPF+Suk4SljnTnv2rSt46mvCvAcrF5tOtTvp4uPnpND88JITZNdxp6vqcT1uePi0VOsPfFpcsoNtzSoOiMt9mNhkBOXNsA+d3vcQDhGEUK61kjbt4yO+XI/Uhkasl26QMSDwxuOt0bbRECshvz1TQgC6BcaAYfYaQaXWWozjBoNF8UQg9N4vSYIuuOyc0G8DeS0pZlOa1S6u89BRcoLzB5Gq3CJm8Nk4hheP/Rvygi07qAHIZdmAxPN3m8mVBmQhULtuB1WTO74=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T15-19-38Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698679049.4986982,
                        "modified": 1698679049.4986982,
                        "name": "/dev/shm/incoming/2023-10-30T15:07:26.ais",
                        "sha256": "2a5214be45ca4742d3dc32c1419ab6806866d5c2a65f84f2915795047cfc4841",
                        "size": 51163
                    }
                ],
                "mission": "OSP2",
                "sha256": "4898a09c9492542f260872c13a725f1c113229120224c5a4a01daab5e446805f",
                "size": 14155
            },
            "errors": "",
            "filename": "2023-10-30T15-19-38Z",
            "size": 14155,
            "verified_at": 1698753158
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698679372,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "M4i70qDQpAvwToPBhrNZTPUDHZvLwiRz4YSx8idwGLBSwpplpMHW1CwllaypljewHpQNbHDg0Yn3oC3PmoJTFwUE3uz67yWcpmqsR+EyCRoDv/Tfd1osldBv4nrmU00hZQiPzQ/+Z2v46A2qugoqV/1eqzTATUYZ4Bijg720ydXubjXwOHtTndHbfkTLhZdWvnRyThMF2L38ZXhW+xVng2BKafehxrwCqdAZpupvMHV5EyzF/PS0AOFYG2Zbatw0AxayWNWO3fHf8VU4PKZLVqz2AyU6yHWMGx4BnpWCDbI8Ri/3cppNVbApoV0ip4EoEAojJg/+g3hGlMfu2eKjVBRcRO4+tfDBlqIESObyPR3G4ARbZx8LzBeeVEWczAHTqEkdbFVRjhwqREI4i5KJZ2QvbLYqNViRI71qzBQDYz4q1clB2/8L0dNCMG9jX7HT3Ii3zdL4VkTYaiCN2ZlNgzQg7LHuMU7kM+dNaUAm4gm4r38NhNg2KpqqFI4drpKNZbmo7dgmFDex/PtBHvx9GqYUjYnAt/r37WidTgI2B7jKJWIj1HWOKVWKgehye715rDNjCXeKpRwWd6beK2XMNvwWh3FprD7OTIuimq5eHY18wywOErvkh9euxMvMRy4PtQnQ3UCIEz6hKCuPX5x4CTawhxnT1O9a/sY7xn4PcWY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T15-21-53Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698679302.9486983,
                        "modified": 1698679302.9486983,
                        "name": "/dev/shm/incoming/2023-10-30T14:51:42.ts",
                        "sha256": "72da5d7a21d6ac640c9e02913006a3a5b71f2f64ec0e2ecf2af7278f67e0d037",
                        "size": 410812336
                    }
                ],
                "mission": "OSP2",
                "sha256": "906283a21e02c66cd710e91584b6143414b736bc97077e561dfaca717d95c499",
                "size": 396837886
            },
            "errors": "",
            "filename": "2023-10-30T15-21-53Z",
            "size": 396837886,
            "verified_at": 1698753554
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698776055,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "SLxYqVfwNTvWocSa1aTTGE/dbCIA1pLZKueOKH+EJlHv7PGP29GPYUTiguszR7fXWW3kCMWGsn8v55Y5n8pdlOHX/MUFRCduHXSb0qlXKpsSLnUN8d34t+r3Srub/yePicqTQBpT/xjxMAxG+fTDhdH5tk/aTNmaogo9EoVF9AK0fS2wIHtbRiPIQN5zxoBDPHH6LYbBCSWuiYmds4FnD+MUGwa72XsnX1bAREimdIWYOnAze5xUJzHUY/Qb5Bycbv1xR1cdU5slo7nxYTkQGlRQDImYGV0EGzTmYV2ArE7OsfbwFWwQHtcEzpSACsfyDghEigqzvRPuU8LoGbLWlMPWtS+kF2SrkwoT/TYVvCcbhQAzkGTdFCaMf28SGMjW2fY2L5l0DXzBeq26mnZY+RMyuCWMvBlGxR/x8S4wdoy7FE1REZ5wijFpoPg0vNGfsQV7LRfoMbstg3L3iUrvPlWiGgoMJZby3aHEEXHPk+gqE5t/VjaRmDessRYeFAwqKYVVNsSN7cG8RLtb/oh5JeZ4aJmPdxdSKPkuHjBXqdsvwjuKWCvBup6qDiHSwo9juYoVwBCU5FYcmmuknU20ww/C4cqx0ZUXfS8zHg+x0Anp3tL2X7uC4ZQuKVsSMbE3JsyTXdLQ+U3Oe2gzS58fRSx01a9DIQ/KmlOwHf0hRYg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T18-13-58Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698775912.8130362,
                        "modified": 1698775912.8130362,
                        "name": "/dev/shm/incoming/2023-10-31T18:01:48.ais",
                        "sha256": "8a4a7e6ae85088acd8c1cde1c9cd9ffc0875260e0d2d68e5d78527b86e1f28bc",
                        "size": 70255
                    }
                ],
                "mission": "OSP2",
                "sha256": "7eb53cbafa270abec8540bc06c5b8ff176c58419816c32be95e3c051cbdef47b",
                "size": 16634
            },
            "errors": "",
            "filename": "2023-10-31T18-13-58Z",
            "size": 16634,
            "verified_at": 1698784193
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698777261,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "HQEIh3rAvXq1Qnm7XV/YKOTx1O7ntaffVJShsqSdtaw5/6ZjVHnUKay8kiGZR770cknmRNd9qysYSskc7AX0/tQ8WIxQ++0Dz1LFmUAYcWhsJiEQd/o6XH61AYEUVgM9SE/z2Ee9O6WCvOg53bFvTeuAdRoqVK1X/Nec63uUIsktw1ab+M3c2FLUl8c3uq6aoJxrT9FpDzFEjKGHPr9LqyPFjdbOiMDDFc1PF7uqvSF3Avq9rl/4CqNsgq7X4mc6CYnEFEVYqe0NSA3V5fDbEHuZlKhVzokhkrBIPt/bMiS7X74OBxA8j6UJcS7kRUeBrt9pRYR/e2DFqE0EionDr/VqAk8XsHaoDd/PH3xwwHDA7PMFYPnEePY868mo7ZQ/d1UHUpI9KH5Zf7AAW9F7kEjwzXN+c5gonEMge0muoEwiD/40W+3ewpPSzLjQmLjSeRcameheGyCI/S6KvMPxqbLxbGlY6I347KPZdOXY+KhCvVcuyRBrL/DwMdeHO+K9TTr2LW31fvgnZY7LOy/oxbp564E9Ege3XcoIJzS731IS83XsbVGKDWAYwMmECKgHYOHWWyGCXEJogVi2nha/9O2aUpRbQwUCq49qhNoZ64dO9mxGINT4YBpqGcLcZ0+jF34Qbnxadxdn5a8YJXtpzyO8h2OSXqJSxB9TrvOXZPg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T18-34-01Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698777115.3930361,
                        "modified": 1698777115.3930361,
                        "name": "/dev/shm/incoming/2023-10-31T18:21:54.ais",
                        "sha256": "afb57a34c78befb80a3181e2b3b0455aacf05fa1bb862865e9680070b90049a2",
                        "size": 70987
                    }
                ],
                "mission": "OSP2",
                "sha256": "c5d49a6faa1175e928493569c35a2925b2c3702f105d418b55853630cf227c1d",
                "size": 17110
            },
            "errors": "",
            "filename": "2023-10-31T18-34-01Z",
            "size": 17110,
            "verified_at": 1698784257
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698681608,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "BYnG0IIdYd09CxZFS5dBQhL7Z7GLRTcTXEucxEwzI7FCPeMmMisDY5nJlK9LjnYD+d84ZcYfPe5BBaYY0QrDURCPudrKIVVlhddJnj015lWiDb00UzIbiuW0HCl6n/KM4lOSnOoyn8qWcruth7QmFW0fVlPHQ/PaDMLnsjA00gYwtkWEU3MXysDJYn7rIoigkA9YtNeh/52t1LvhqcQ0OrTVd08Y6ga5I91JqX9F1ePrQ+E7ywQZ4BzKaeeUwHIwTEsIjcK5oO6sQmy1xyeBieS0fBH9u8rSDO8JNyDnCntwZacJk9JFrqMECzRRoLJ0qTMaHWpOJemyW4+/Z6HtOuTPhBzA3fudaOLG5NbMFR7bwbNM7tRlidSuRSNW3AbX7q26B3zYNfwuA2tB2wZZcvdyutD+UuY1rngZ6oeJ40y05Hlt8FdfuGBp2Rj7lxJXaT6MNkJ+kNB33zmLQec7VWkIT5697AgYvTPVkvFqwM24f4EWSZCZ7GNJHdIFXm+bi78G+PVVPakhjPJrkFGwKHRq67+kqkdTd7S1CgV7hb/pgkDJnoOt/q4cej7+V0qmtcTnvt5qcUsy/OWVEraHJMfxoSvzWlRX9+WyJoaY0nKvwKAN5ybbBqLc3h5MsxJsxaRYwAZE3IVuaHdHFLO6QfXDQ/lCUDqzM/uqpJRaEVs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T15-59-51Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698681464.1686983,
                        "modified": 1698681464.1686983,
                        "name": "/dev/shm/incoming/2023-10-30T15:47:37.ais",
                        "sha256": "7654a582052705f124faf830483c850cce1afabaf4eec53ac9513ca223b695ca",
                        "size": 44889
                    }
                ],
                "mission": "OSP2",
                "sha256": "4905a4ccfa81aa1e2559be3a1f39b9d71228d42558af256feebe86042d8f2ef1",
                "size": 12306
            },
            "errors": "",
            "filename": "2023-10-30T15-59-51Z",
            "size": 12306,
            "verified_at": 1698753310
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698680397,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "nayQR0K2LGW0YGbHLHmfuiVj1fwooEGFNhF04ivdIxVvYGrbbBBeRlfLlcFHub2zlUBAfjnVxqLo8dVFLKq+71YLQec84XNykrWhoxu/qSEovaf0T7Dx8Bqg6RcjwyRqkjAxd0jxZyRYQOFxKw9tT79ioJw3phw0THEo46XJNsEVVK8SwNqZAtB/S1LQPDkEZbkTVCqEJUchmqtJ9UgiBXr7yqCKiT0qcbtq7DPcdZGH4GvYtH1+mY5lZbtk4Dkyk4rseo4vX8FAHNBiwrD1zsQ9miFOVQ6Ed/zEQ8OWO8JsEFO58CiiCu5WfMdg4QM0Br3gM2vjbEaf6OIAi4NosQiDmOVtt1cCxkB27WLg9ApeNvXh72ZwmQQHgAjsIWtyc4wJ2jsRBliAIjw7g72qg+lkxyUcvVStwViOTKqiH/MEl3mlezULSoCrL8SPtmHW1ZAYcMbRtH+tPf3zHlc+zcIYLOyvYDPOOcjEd8oURn2wc736EZ0Cd9wGeGrmcDkYvzpNb0YQTKlHhBd119leiijw/toin8AqpfVKrUgap0U1CF9kAK+BiU5RywhBLl7bBN78vFNzJPo4kYz6q5ES02V2kVN48ZBlMNd3GUGChd/nT2InplAZh04QTSGA2xg+hfIxnUSH2aqmG7eddfAMFf7dhFBMluFgmQz/MeJI53s=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T15-39-41Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698680256.408698,
                        "modified": 1698680256.408698,
                        "name": "/dev/shm/incoming/2023-10-30T15:27:32.ais",
                        "sha256": "fa5bc07f8fe02a269935dc1946db43bbb6f71a5c735e1c24d4d2e0e8a0f9b1c1",
                        "size": 45242
                    }
                ],
                "mission": "OSP2",
                "sha256": "2975b0fa9adb931a0a0806c7698b3aa0d58c32437672a37658b2edc23cc7f3dd",
                "size": 12210
            },
            "errors": "",
            "filename": "2023-10-30T15-39-41Z",
            "size": 12210,
            "verified_at": 1698753184
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698681937,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "v1gNioEIoPp3FIcyZutcOMY/2CVxaOaF6pProg7pJ4Q9eEfPHMaIUr5aL8CyZlUrSH9ZzLuApp4kBapnhnuZccpERiBb/QFG78H4igtv7yZskoDcDZuYlgFuhl4W9v5KhBDzyAFBwFxvBjk0+I2oos5pRElgi8JfCnFE/IkxW5Esox7+iB4nuVj8XTmYJMaVcOt5TuxAfKYJSeVCEX6aFmisHLBP8InXp9rxsrK2VDC2tUCMvGmdQzXplAiA20xzetCjrLk0y6ez08Jg7lOPTUG3TbtpgPo998LvLUMFLx1tIBC124huD5GKTXfAktoJHBQP+YAE/kjxMZ4wvXdBXAhu3oKyct/KI1OhqL5nHFuQSQLlGo7ihYkwGlCCvqMW6nayj6Fxc+62BsSp+uFVENa+BNbfSCM9EzPUDDQxsXALKFFdJjqsCiQzPJQZ0V7+6rH9n8wa/uXzj3GdltdyUQsAYlrOXkhasdUqu023U5+lm1y16qsV5NgbFMemWK6tSFapjM5tyvQhnznTiG4qcHHFNzO5lrxL3CjbtFAGnF9DDQ8FhLy+djHKoWYA0j74AHVx4yXt0h1Q+OLqc70I2B3nWOJ4gF0wDSLQQTC9HKT4Q939tAz0CpJh4iFIgNevuxUDeMZgr6Lt2QiCOQEspkAm6Qqzj7lGyTq3AExm8e8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T16-05-19Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698681795.0786982,
                        "modified": 1698681795.0786982,
                        "name": "/dev/shm/incoming/2023_10_30_160255_00_03_V_015.txt",
                        "sha256": "dddbef8113b6a7fc087b7aaad08a71badced683ae9a4b512fc207dbb9f1ca614",
                        "size": 2027
                    },
                    {
                        "created": 1698681794.878698,
                        "modified": 1698681794.878698,
                        "name": "/dev/shm/incoming/2023_10_30_160255_00_03_V_015.kml",
                        "sha256": "aef7a58080773e28972c308f9f70dd11f37f202bd75fc5f60a747d850efd8e17",
                        "size": 976
                    },
                    {
                        "created": 1698681794.6186981,
                        "modified": 1698681794.6186981,
                        "name": "/dev/shm/incoming/2023_10_30_160255_00_03_V_015.jpg",
                        "sha256": "1f6d79da1b800dc48d476cc39a20dea519264a24c6d0fd39b94b19fc008837a2",
                        "size": 965144
                    },
                    {
                        "created": 1698681793.5786982,
                        "modified": 1698681793.5786982,
                        "name": "/dev/shm/incoming/2023_10_30_154630_00_03_V_014.txt",
                        "sha256": "1376bd6c253a1d7d25025f34ce77fa2f3fd0005caf236d31ba65b56a97b7ccf9",
                        "size": 2030
                    }
                ],
                "mission": "OSP2",
                "sha256": "bd1f3ad07c139b05c37baa136b7dbd073d01713aef8d8089335608dba69424c8",
                "size": 967484
            },
            "errors": "",
            "filename": "2023-10-30T16-05-19Z",
            "size": 967484,
            "verified_at": 1698753407
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698681004,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Wg47nEUJFzYKQ16/xi60+z8AAwKG5CJS93YQgAFJ8uFUtlT6JEWj92K+p/mF3gTblGJoC3DkGNXOdho2QCzgWt2WRJ2/0Tm26niPzEo0HOM/MkWHXzbWaS5lVI00agqE6P4IERTXTpK0a9tzk5+qX9s6VK+uMAbWXRMKzd+vBFnpN5R4nNtk1H/HeouQMc1eKUaYYWWwPleEcL8rQ2y4M740lHYSiFHSCYFKeNCZksKvMaX/AhBgFGrWMSjT9GnCneSIqQU3DZo+tyBVt1HUH00iniWl5niirmeQvu6n7kCZWwyJ1oWbpQHXzKQKuvCozwYd/SvDEfqqui1gBZToRX3UmblGXn2jJ6bNS5n/06rChxKa2H4l7avE1UOUrjqKhOB+ymZzl4i8c3m744JwZigqrWIvjw/PjLtn8/Ei/k5EVVGzhaqFruPrVD/trcGjL7z0DlcnVEUKEm81F9UzB3n7vkqm7lWWTES+vsTY6stBuEppmpHymjOpAfdlWFV9huvn8SNYWyoGq7Al0KPbfhzvaqV38A47A0c2dlQa+y1+U6jM5hxv9Pz3Hj6cYfhhzP5ehWqUODOwU8t3xkNC1syo7H6TY+Mvb2dV/RNDTnz7jDueFjRQASk5wMKn9vzrrDu7sadfWcfv8p3mcIh2ucu6ILa1up8NrrNSmKAxzcs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T15-49-45Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698680857.7986982,
                        "modified": 1698680857.7986982,
                        "name": "/dev/shm/incoming/2023-10-30T15:37:36.ais",
                        "sha256": "c4c71947b3dd62a8c3eaa3c7e441737955b31c8675e2e8d0d81aded87c4f3b2d",
                        "size": 49406
                    },
                    {
                        "created": 1698680809.5686982,
                        "modified": 1698680809.5686982,
                        "name": "/dev/shm/incoming/2023_10_30_154630_00_03_V_014.txt",
                        "sha256": "1376bd6c253a1d7d25025f34ce77fa2f3fd0005caf236d31ba65b56a97b7ccf9",
                        "size": 2030
                    },
                    {
                        "created": 1698680809.3486981,
                        "modified": 1698680809.3486981,
                        "name": "/dev/shm/incoming/2023_10_30_154630_00_03_V_014.kml",
                        "sha256": "c91e638597fd9ad1280f182e46245c0e283d6141eed22c750e126f4eb853a93b",
                        "size": 975
                    },
                    {
                        "created": 1698680809.1786983,
                        "modified": 1698680809.1786983,
                        "name": "/dev/shm/incoming/2023_10_30_154630_00_03_V_014.jpg",
                        "sha256": "6bf086acb5845aff6ad6506d0685f4a3a867f1b6d5f14d4415b77c0ef72180f3",
                        "size": 1056172
                    }
                ],
                "mission": "OSP2",
                "sha256": "ae5f819192f6764bf743d2f3d98d23cf1ab775a2550ec72b771d5b29e89a99a1",
                "size": 1071591
            },
            "errors": "",
            "filename": "2023-10-30T15-49-45Z",
            "size": 1071591,
            "verified_at": 1698753207
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698681184,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "LV81eqwgeQJJOOh2XNhcw5cjmztUAYEX+FZJTcucFfHFY790RsdGA4JPR5Tf05pcp7aYPS4ZYETCx8H2KVIAVv1GAb3N4T/UekccEeRUFAa/YUtkTh2+C46Dm+pdSkdHSQUlphBaVUkDQbLjZgurN8Sh/2qikct5hmk/2H9AuwYYdlmyBrknNrNYzMfS8DWKsRd7PUrWE4Js+Nj0tgcYXzk/tFBt4V8QsN3PxG+qHrSDEgy0xzy1QHSGn3OzkekbvRqOkY9FI6/E0CxSM+pUyPlSq5PDXBFOI7deiaTINDdH6vVE/0eykImvvGThgTVFNuUcmyFTzx8S+eol5I9TCFc0W8VSAbzWYAk+n6XYWi2tnQKAfvZiZaZcf1ePkySFQFiV37oQ1JoFwFLHqgZPQ35J04MQCBXH377HjSrZQ3eovWuQ25OBkY1M5C3W2njXYj4wUmgtOiSVbhSZW1TE0DiTcvAm5L/tPspyevKug4fAxXFs3wG8Vofw3P7ZaZQftIZZ8s32yuxAQwGgh4h4Bh2/q61JW/rNNXZAbqFxwnrq+748WW8AWQxTj5/PxnVSRW63OW5eYjO63pNSRYykDROScc3/dQz6G8ipTLq9rwHrIrzmEph/XtISBSI5x3qj3JzhB9R9/sRxxtWUg2TA+CQ5qUp3Ny/nHbdISwIaZi0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T15-52-01Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698681109.5086982,
                        "modified": 1698681109.5086982,
                        "name": "/dev/shm/incoming/2023-10-30T15:21:49.ts",
                        "sha256": "7085b9ffed01aa9c24026be6c98931b5c6724e9cbbb95d907873bcfc6a3aceb7",
                        "size": 410892048
                    }
                ],
                "mission": "OSP2",
                "sha256": "5f58b85956921090c3776217fe0fa5341ea60bffa6544fd5a75bf3262150235f",
                "size": 396906081
            },
            "errors": "",
            "filename": "2023-10-30T15-52-01Z",
            "size": 396906081,
            "verified_at": 1698753632
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698775733,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "sMW7toARDLZCavINILIqDj4dNUGoCohbfE08reDbrGhFGYoYyXEkTQrjYL8DetyTmlkhmMrpti406PO/Ufen/Lh6la6IU1fWMnaIJdultYRmcy9vX8h66DVL2iwYJ+VohEl5IxFJvPxvCd8NOuRtPscYO+9K0S1g55Xge4ecmQoZEXiGgpUNi4IGl/PyUejVB7gm0T55dB6J7K9pjBJukeXCzi1Rxcpn2BfK94U8g0QEUfitU249yWP2tWg+IwkWYdhQe4VvvvPHdmRK38mX4EhnDIGkeTb0yD3g1p4/2RxdZtFEiFoJkXlRMNZBLwTriXckT9WAo+gZvOWnv50KxpbZpp6vSei/Y3OEcpwhKv2ilrE0iIR/1XQ0Xv9rtzTt4zpXddVSd2RqHBnuShw59nW/juwy5AjuGHZ7uTjr0ySI1IBvZkgg13nbPZze656V2eMAEQP7n0pnJvo9c7WYTS8ePu4wXXGhxZKak/3Uw4d6zCh3SaeaQgES35hvzDedZso01gUea/n5c17CfU3xVKohB5Cph7krNep4br/P+j10Y8C9ES3MY6NipvB2d/TbqsjkfbdGP3XP3CoaauLXKLIK6q+Y/7yul66CoDZNxBPXCRbCKqwClm2R3oaNczT4W1MLN0OKXSS+1LfzxzdGQbCppc4qR2JTx1P0RLw5q4I=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T18-08-36Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698775599.3330362,
                        "modified": 1698775599.3330362,
                        "name": "/dev/shm/incoming/2023_10_31_180608_00_03_V_034.txt",
                        "sha256": "ccd5fa6fa8f42e987f93e7cff4c7c3b8adfeef788f32563aab96d84345870690",
                        "size": 2028
                    },
                    {
                        "created": 1698775591.453036,
                        "modified": 1698775591.453036,
                        "name": "/dev/shm/incoming/2023_10_31_180608_00_03_V_034.kml",
                        "sha256": "dcf97b9741805f5d1c5afddb15d98adf5306e6684d289849f4220aea6028bb24",
                        "size": 978
                    },
                    {
                        "created": 1698775588.6330361,
                        "modified": 1698775588.6330361,
                        "name": "/dev/shm/incoming/2023_10_31_180608_00_03_V_034.jpg",
                        "sha256": "4307cdec13b2fdd4b37f72721f34e8cfa604d52e492dfb72646182503163eafc",
                        "size": 800325
                    },
                    {
                        "created": 1698775587.5830362,
                        "modified": 1698775587.5830362,
                        "name": "/dev/shm/incoming/2023_10_31_175114_00_03_V_033.txt",
                        "sha256": "cbd8d507848afdff14d24c4cae348f8f51c22af12b4d767c24c5ace32710077c",
                        "size": 2031
                    }
                ],
                "mission": "OSP2",
                "sha256": "a55c29ca44b02a461cf7969f91ece4cd353588ca01e7103c2fbf09aafb08d896",
                "size": 798977
            },
            "errors": "",
            "filename": "2023-10-31T18-08-36Z",
            "size": 798977,
            "verified_at": 1698784099
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698774850,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Hn22drsL5pMhNbeR5HsOP+0Dgo4384VHT9DoDvyjUW/dNrI3OIy7etnZSbvRuVQrHcOs/MwhilAHR24GFkjajKXbqLVDq6RZxiuPCE4+qY03arkE9HylyLECc92FRaz/+3VjfrPEFiB/MnmygkO/CMvQa0fx/Us0Dewy+UoI1wH8djZFcdyHp8tz29XmkOMLd7dalY2ND5g5PrghiVp3lmKSekRuA7SRsf1+25w2fPwejh+KHiwrFfKb7TevzEWrMz10MQRukkvNFw79mya5vjmJGEwacSAbK7zn6eAc70tuk0M61kusl3Q0Byu1MK+yuY84zRGDLOlwTQ5djksuoNmxpOcSBsy0tIAPwzu0wijQkl8/2hhnf4dSzerpc6N7LPqOCEVfSQWrXVpw5DzLKaRmVsYZkr2dAGQNu7BLdWtaPTlU2TzOeBhoGh8pBPG4QHOBPv1NoK19AZznGyYMBK4hTWHI98Evbz75DGrdgNSLQC1Od5vWVn8Lfj1/VfGpQxPb6yntlKch9iXZKqmVT7ClalH1vvgRkc0SOBFRUGmn/XnGLxeKIoONdGMqyJ54KdMsqgpVkvDlpJpDeQK5SFmm0vVb008UcteVYif2oPBivRovUOd2MiquzzRmXC+qPfGb0P07bhHeLA31zvsWM9Cu96MeTTRGAKUgEb4Fx80=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T17-53-50Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698774707.3530362,
                        "modified": 1698774707.3530362,
                        "name": "/dev/shm/incoming/2023-10-31T17:41:45.ais",
                        "sha256": "e82d43cf32c1834cb7d4b23ce816292c4b5b261574ee372917cf9e08aa545fa9",
                        "size": 76304
                    },
                    {
                        "created": 1698774694.6230361,
                        "modified": 1698774694.6230361,
                        "name": "/dev/shm/incoming/2023_10_31_175114_00_03_V_033.txt",
                        "sha256": "cbd8d507848afdff14d24c4cae348f8f51c22af12b4d767c24c5ace32710077c",
                        "size": 2031
                    },
                    {
                        "created": 1698774694.013036,
                        "modified": 1698774694.013036,
                        "name": "/dev/shm/incoming/2023_10_31_175114_00_03_V_033.kml",
                        "sha256": "c9c71702dbeafe9e32875cc8cb0b17c26feb70e3ff0e4c656a15cf85e6a81b9c",
                        "size": 976
                    },
                    {
                        "created": 1698774693.5930362,
                        "modified": 1698774693.5930362,
                        "name": "/dev/shm/incoming/2023_10_31_175114_00_03_V_033.jpg",
                        "sha256": "4e014dbfea1f754d1bc6109dc24d05be0216bd7b64c218ec7e5ec3ee4c8165a1",
                        "size": 1070355
                    }
                ],
                "mission": "OSP2",
                "sha256": "15727dcc1aae318ffc61169ffe3976a273b6bede1666ca82b011da859abc21f9",
                "size": 1091220
            },
            "errors": "",
            "filename": "2023-10-31T17-53-50Z",
            "size": 1091220,
            "verified_at": 1698784132
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698648496,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "Zrs8IvMyzO46tqclgoTSpBr959NYbjII9wQpIb72LJ1+cFUuRikSQuv8uXG2d5DpW3Nh7qEG5AdiB4BVpfFV5GtRloMXUyB3YZf8QGClOawImTvdutO7zRXpQKYsePfDmeeqVNuC2EQ+ByOH7Ix6sa4FOuNmyp18Izmr44R3PJz4+QZ6dq5hi3aUeP7HLmun4HTLKI4pxzyyNSzJF77RyIgf9pAUZx6fO0KHkbYMNkMvkECVij2QqmdpW4Dn8+DK1QwoS5PhpnvRf0hXLxmB8SOAOwcvr1Jb1uW+XjRh3BUHl8VvZLFAUcZD5nJuvbHg4D0E3NjHSUcJzWOsF//ZSCS1tBKaHwL5DQOvVr2L2BCeuvQnmWcYzpiOg7Il8WPWh+nxIY9g1c8xUJk9YB5Lo7Fg+HRvl1kyTPHbF37NK7Q/d35rbGvoO4gE9a5i82cHWXxg26JW+Iou1GXOgJ4CD5yVLMMbB7melLEts7JfMwcTPjx11dK0kQM+n8StzjpFbELJV6W0TCXn5KSmDIG4EZDG33BOLM9Hc4YO8kJSk3yZf5zzYXAUCBCAbsYmBVzdCW2Ko1sl3LmsBE052ff8gztlUC51R7jFyXSAdRAcd0E5PF7gygd94tnLk6wS5XVo0oOWIzUBjKyYjwMDFlfbhOfSwu5q3pjHMbt7LKq2Blk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-46-52Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698648283.3179407,
                        "modified": 1698648283.3179407,
                        "name": "/dev/shm/incoming/2023-10-30T06:34:38.ais",
                        "sha256": "73a49d056a02beef74abdaa7631448896a65b9281872e2dac0dda7ed2bc6d06b",
                        "size": 11157
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "375ad37bbba3ec7d0c23631aa9ddf4824692f5541587c7f3f1c4d2382389a35e",
                "size": 2134
            },
            "errors": "",
            "filename": "2023-10-30T06-46-52Z",
            "size": 2134,
            "verified_at": 1698682353
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698682211,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "EJwv28AJ1tJ0WvUGXzguL61/bc7vOD6sHCZHn8CIM4DvhClOh2I3jOgBRF7ALAVVz+foIgSRBEDwah62MSHyz26yxm6eyl7zz8mpyhWu/5BNq/ijO1SFbzUalUOixTggAgn0DmJPnYdsEvj0Llj8p4/geOrYxDs2t/aQadpKufLn+80vvthhtVd/VQt45aoXHjpDzytCk6ymmzkGcrZjffCABdT9IKyOYjuC/c2Xvzz/tRwRa6Al2jO9shMB/NM1pOwtiCVpb1STCPYW2YSSRU5bZjVK6XXpDRcDrt2mPz8NxVlXqcAOt5tBWCqw88A56dyh8oGsdY+owUuFgbviCUDxHeT6eQJcV1GfCR5cdQbFTU7crGQEpuAfO7Ly5r5hKbUFdoCRDBjePRWZqvrmLoY1vdfq1gT+B/VuHzyRg2ewWvI62IRpKHMQ9QT3VLXu5GqvZLLhlWyv8x18GmhZRvl25uovPMWcXoNoDd+NWTK21P2AvjITE3BhLM9jklQ801F768iNKK1Xs1JyWoN7JZe4lHoHf9bfbZwtz6USlySTJ2uITmddvu3TzAR7UN14K/Y3ilmt+UmBJCzKB4q3xGlwEVEZ0a+/B7kt/lDuDW1m1zBMOkmEDPF0US3Sn4RLgAoRD66RPnNYmzheivZCkmEdi3r6QZ9HWCUt8z6k7KA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T16-09-53Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698682067.0986981,
                        "modified": 1698682067.0986981,
                        "name": "/dev/shm/incoming/2023-10-30T15:57:44.ais",
                        "sha256": "2d27fbbf59c98ee936fb70b67d85ec6ce330c489138273c43c90f79ed357b5dc",
                        "size": 40169
                    }
                ],
                "mission": "OSP2",
                "sha256": "e518dc7043f67850f1df2f0814626f3d1f88120444f6d232b7609fddbf1be745",
                "size": 11096
            },
            "errors": "",
            "filename": "2023-10-30T16-09-53Z",
            "size": 11096,
            "verified_at": 1698753439
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698650255,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "VB4kOwVGkRPYZ3/I/sxQSeJzepDVn3vm7lRh2Fb4M4FbiS/9NI2GLDGUonZi5QGNRmi8N4hXLLaA+SpL1uTI2R6lefO+WBFHJLdBG6n8pydXwUNS7T7xOHGoBJram0p+XzlRg3DOe1gFk5QoeK1qi1p+saQqvWVgfAvW3JiZBHb7s93mCtCEx0OSGkgn0o4m6baYDN8wyaq79H1ya+APNcyDnmEnviaop+Zb2wJGqnkvLfETLo7jeiZVU2yQSp+IC5gmw7AgTxs8Q7EnioR2MrPt+dzJAIybuKgJ+BAA3/FvKX+opELfKtNtihee/AZd5ViSX8a0wodnXNivKr48uWf/I1XfeJXJ1qCCj2uXAJ+u14o9OFeaHH2/cv49yLIHqTBx9rpEWpaz2cbBRSylOysCEL3HGq7BBDP+Wl76DnTUY0shkBFmhA/g82R4YnUCoN04+xIpi8k7U425GS66BJGiOUqWBHB3iR7ThoSNJLsGUz2SLbCmwOKRIKYREt79fJ75YywT19eR+bCp6ByWM0fyehsGPuIACKXvzN4qbUwOdc97JxMCKWrUFxlngGmEmr9te85Nr5oCZhsbRU/in0SJwtNV1EwE/KXG9TltMzMGTBffbQLz25ERxW+LnRJWIjlhTmA822UMJwjAu7MYsqyL3yoClDorv/eGIiAQIyw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T07-17-00Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698650092.5279405,
                        "modified": 1698650092.5279405,
                        "name": "/dev/shm/incoming/2023-10-30T07:04:51.ais",
                        "sha256": "3d35223e50e85c7cb42f406a918a9596cc977e6e6a35337536c27bae5139b4d8",
                        "size": 970455
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "3018601656f9b2486e50c17cf0fc2253723857b37e7d69ac83b128eb5cafb5b2",
                "size": 297844
            },
            "errors": "",
            "filename": "2023-10-30T07-17-00Z",
            "size": 297844,
            "verified_at": 1698682360
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698651588,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "cEAeSryS7zhPPAUKUlTnjtL3LyghwZ39+TvxnUO1u6YwF+Pa/YLXLRNz7rCX7xOWPVIL9AL2WLfr790SeCycCsJAAJ/j8iAfgpTPHL05vB5WDx8DR28R6z8MJsmSnWTYotA2e55/0/sgj+gVAZCZOF7k65MUkpr8c20qcpKREmuNRLiJ+5WhQS5gi7aCp6v+Wru4P6tCYn+OfibTlWnjMrBYd82M7GY2pXcMAPf4XvKsioDnt9qrAve62ppqN0tyyigmgudTSDGG8EkzdROlViE5HFQq+GoQLV+xHIrC2uTaixfquz9Q+Utm7nOFff26U4GrcpYPAkNYgixXWBiFogeUzLpppoZYSCKcNARQPTWJ+90So+dqCzrYrNf2F/2FD35z9HMYUSBpZX58mGfjC6fnm8XvEdqfCDxbfA9Y5Broxxsiq9VmY53mXXJHrJQMrhKmPyHeaDjO9guA9B2fO4WuoP/iEr1NpBR+lXU6OkBoSmVF2LuF6J3HGv5Nh+/Wih0Spd4LvDlz+3LLtKiyv3IwimQmI1VUt6i0HqKPgW0B1wSIg+FgJuH8LD3yFiR0jDblqckhT0/Ain8cfZEIEoaM9jQG3hpy7BCTLGDJ2qhDHC9bUVO6ORZS7MF/RjEx/5VsgclnA9ZV3DbDdUZVP7onKgDf/W28KwTCJ239rQc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T07-38-59Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698651375.1879406,
                        "modified": 1698651375.1879406,
                        "name": "/dev/shm/incoming/2023_10_30_073555_00_00_K_001.txt",
                        "sha256": "ddc4a3bbf928d925473005e99c2715f373b18f65f8072a2409aa8e4688b96b01",
                        "size": 789
                    },
                    {
                        "created": 1698651371.4979405,
                        "modified": 1698651371.4979405,
                        "name": "/dev/shm/incoming/2023_10_30_073555_00_00_K_001.kml",
                        "sha256": "a786456c375647cc705aceebfab8bc24703a17dbb20f6f20aff255a8c03cad20",
                        "size": 932
                    },
                    {
                        "created": 1698651372.2179406,
                        "modified": 1698651372.2179406,
                        "name": "/dev/shm/incoming/2023_10_30_073555_00_00_K_001.jpg",
                        "sha256": "a6abf5196e0fa23fa8291445b54088b9da37b6c53faf452e8b092f46a7462add",
                        "size": 568629
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "b64381c75c960952221fc8c8f0543e0d37dfa48c560cc5e177b39fe3cf140396",
                "size": 556684
            },
            "errors": "",
            "filename": "2023-10-30T07-38-59Z",
            "size": 556684,
            "verified_at": 1698682362
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698652641,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "lROcjyQeTtpAOGktyMkD07u0XNYeDD1bBDnBxocEamV01tdsxOpsJeaLlT7HLvcfSYxZEutl2HAcVLZSzJ4RrUGFpu6DRzFHg6Yho5Mh0rr/L+9kNVEdyrIHqgMfdAegIzlqOXzNVTbCc/U9p+76K72JVFBkwIuXlBPqvXUY65BFKE+xEfsXl7z4BCyDAGWjTKO96cq6/ibYQMrPutGe+HwlSQp/PAkY1wT5MEPEKzmJFDa9rlFHotwUHr5W62xr0KHhy0d1C9lpqZNXC4igFZsTlDmhZ1mq8fzhGxAhjJVODXYId5pE6zbqYQfqhBeRvtWywOp+2vaErJz6x2b5VY+Y6WPBh2toJddYn0uPhidjiQMR9kIFbhxC9QGgH2kGpXZmWj3Ld/LMV3vggGUti+Vxi8Ct2IahB/2swLN30glWz6iNNPa2zVuWSC5wumnL1jEjfFeJcdkDZprQ1wFq36cKVe0RIt5/XrvBKC+llfbiobXqsc2yZwG7+E2R/qZohtgYMq1MBnV005uSABdXnlWODSuYEj+1D9GSBlxpuQb1t1yD5trSQ3QpO0mJ14MjXKB3RkNkjSWS2k+AOzIQagPjSDI+Lq2bQn1E65b7HIDCu1sQioyvXSDeBO8ggQt+TlbE3kJJFeEvdom2df1XYwU0ypGxxISnWMDC0ICUCHc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T07-57-00Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698652496.2879405,
                        "modified": 1698652496.2879405,
                        "name": "/dev/shm/incoming/2023-10-30T07:44:55.ais",
                        "sha256": "0cb0c0785f1272b3622ef9bfd23cfd16575b88525e67303e24ee2c24c2def204",
                        "size": 694258
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "7c10e4a61f08ff866260f6e6fc992881ab39b6cbbaefd17e6279d7a7ca110548",
                "size": 207784
            },
            "errors": "",
            "filename": "2023-10-30T07-57-00Z",
            "size": 207784,
            "verified_at": 1698682368
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698653850,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "FcowxXzAgyJYpCLpjx3zPtXbKSMxJze3akWepwigNYANaP4gwLh3rBSt4Ra48EFY/pURxcuJFDDStmJvjmV+b358BfkVGa8iOAU4IhP7DZLkUNrLHfsAeHP9rx5FAljs7dJyk5NdiC5fBQo6VKRud2Glrd3OeuPJUofQ9tIOPjLrdtXicrUtR0GUYq3xnDbwgyp+34RG2KGsW8z7kbUrsa0xYcFygGcS2VjZOnFTRsEaiSVKq1dSbQf1ZhFfbnXDduNhJx2a/aLzmWkgEU8oe1PcNLQ2ISIWJZJpD1YLxa36/m9pP5h7RRKvy6b5CjMXCTBCJMY5dCUFi1iGFh7CWhzSTyjxmtF4ortCRPkHpCQ5J8khjJ4Nyv9atKjgZPCjpHGLRlRz244G7sP1fiycpWANGhT9CDMlet8Bt1W/GuMZG59fRn3PsFXI/VMQqHQEXOfIpqRixdSxoS/8l8b4zvRWBS47bxeguXibdiZcn3ewxKMLQZkDQH0qSda1qL4JYiKtyIVnqPVoZgs6xpyGFBRuQSbcWczj17d5xlLl0OLei3X4yQGwVjMwL68OzxWoQQ/AgU3jyLFZTw+8kcJ3ZwbqrV5urpcErVQ3hoau96ii5bPuTA1+pV3BGImNWypfJe17k4Lf+C04M5q+PyvuJuYlo0VZL6/T0GAAaYt2XbY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-17-11Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698653704.5579405,
                        "modified": 1698653704.5579405,
                        "name": "/dev/shm/incoming/2023-10-30T08:05:03.ais",
                        "sha256": "78585b37328c607eba006e4b8d6b4ca30f398adc91d535a5a05fb0720b272efc",
                        "size": 462607
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "21905bafb5ad0962f6a46d4eb62ba02131d15f8db47747918f5fdbb1e5ad3fe3",
                "size": 135863
            },
            "errors": "",
            "filename": "2023-10-30T08-17-11Z",
            "size": 135863,
            "verified_at": 1698682371
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698649035,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "eT1KLj26HYe1vIvDNgW3XjUxPVIziSY+trD/e0S3MTl9uPU03gEi+WT7cMA4MOzB9FSuz5u8sFi8EJG8QWtDEnXDDOc/Bk4wi7TWnnXH970C7jFdqtqJvjxReLxnHXSWSUFWsbP5MvdBDB5Npk+Avq2iRk9u3VWpFaDNcxc/XjJ9ladSZAX1FJLNds7O5w6WSz2pJdteMY/sjTvVffEBPmS9Y+SYiVxOl2qeBjA7RujasWjrZTkpRN8kGQ/T/9/jrrON/Io4QL64KzuNM+fwFycjbNjkSDnd6G2g0j/HGFVQfwnpnIOWgUe/YSG/5QdZbc/mn0aXb/u+jmuCcAfluQLEhUQN2XQnOZg4WyT16kdkATCGpbnM6Gk+XAO9coTdPueus4sYWsKq5wgC7nTspyBUO1Vcum7l2EhKDHEa/iPnhdqOd4loXdGJeS/KRK0QBGM8d4LeTCpUAAzzEP3cilR6pFz/RgZjAMGH2tP+bdIZPIJ1WqVpvafK7r5vB/8CvU0TxXWLDvS4ZEn4zFPg0kY9txZLBw6xARqwuemnRx49+XnWiM4/4zscd5BedR+XfCJY1hoZ/5u4bbWRY8tw5Y7titCJFK+7p1d+xJnb6t9HQOGeIiya1vGIIE9pEMiik87KG43gSxjX2QdmWryEh/aog2RENosSGydXZgKWvr8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T06-56-56Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698648890.8779407,
                        "modified": 1698648890.8779407,
                        "name": "/dev/shm/incoming/2023-10-30T06:44:43.ais",
                        "sha256": "b26e562429cf1787bf5c487792de9436efb07c3ed7f6985639cb8ed3b6dc0305",
                        "size": 10755
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "3d71996496bef05d55916797783d6401725f8092774c0419ed6ec5a9ab506fdd",
                "size": 2227
            },
            "errors": "",
            "filename": "2023-10-30T06-56-56Z",
            "size": 2227,
            "verified_at": 1698682387
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698682833,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "T+MpL/IwLr/80gYTCmSD/TeW/52ReqQ/rAyp193lTvhZFXVcIMHuvXN14cQRA526ivvNK3ie79Yr4ZkxNiEJAJsfNuu/fybsgknDk5MaYQHZjG/LN2eKxubZcAlmlT7ZJt3ZYRkNn1fNi6tn5x/EPF/n4wZPnl0G1xEgWCtJMzwRhUSuUaS9Sm9iqNIAdhum0ALgBJWxsJUpbAMS5DM1+k5xxtM+A7AVlTQ7KKs9KDUmrNjGwkp56iBFjjr/CJJFcYlmKWnW0OQMVBMk55ZwMkiJZPRWb7vVhJq+U41mAptoWA06qlgALaq8Ht8wafClCV03Bg6wjFkCc9iverKV4f2BZCm7qAOcOpYjZzMMLeVd10Odg+6pzaDgB6bmynnxdA4NzIqgYIXn9qR07SfJqqKZ6HHESlmU8y7L3mmWnmxHhDQZgUgWVaKIgd+Y4gy8KePlu5gyNwxK4DQKBbq4b28veukfPWKtMaE3SWnzs3D8QjB2yMGD+L74NusEkdw736u3/3v/BEAAPOGg1PD1bUhxdFwsnUsU52HTAbLMXiVjmjo8R9+IxZbNobvPR+sFtu4P+JMJda/VRkoEBM791I+FoVZqCoKerVLmWUIvhv6rb80KrsskzsRjlD6TqT9eT/SVoRXuoS4x3l/Ie8k2W+CuiAQQrsevJE/OJpM7nbk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T16-20-15Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698682688.0986981,
                        "modified": 1698682688.0986981,
                        "name": "/dev/shm/incoming/2023_10_30_161751_00_03_V_016.txt",
                        "sha256": "57694156ff4d231da121908f90f6482d8a17112ac985bb9a539a2e3657f43164",
                        "size": 2027
                    },
                    {
                        "created": 1698682687.7686982,
                        "modified": 1698682687.7686982,
                        "name": "/dev/shm/incoming/2023_10_30_161751_00_03_V_016.kml",
                        "sha256": "1f242d165b0fc6c4d8aeb7804843ff2a68d5b153ffbc30dae12f45eeeb183cb0",
                        "size": 977
                    },
                    {
                        "created": 1698682687.1786983,
                        "modified": 1698682687.1786983,
                        "name": "/dev/shm/incoming/2023_10_30_161751_00_03_V_016.jpg",
                        "sha256": "bff981e19d5fcd3b4c81b247e5661eeb3127edb7cd5b6500680c7d3a39f27c07",
                        "size": 1023313
                    },
                    {
                        "created": 1698682671.7786982,
                        "modified": 1698682671.7786982,
                        "name": "/dev/shm/incoming/2023-10-30T16:07:47.ais",
                        "sha256": "913969a45a2fb886c35260b98ecde70b96322b68450ec989599612367d96a140",
                        "size": 39617
                    }
                ],
                "mission": "OSP2",
                "sha256": "1f495545211cdf08645fe4d1decb978870d81fdd73966db38cfde762715867fc",
                "size": 1035731
            },
            "errors": "",
            "filename": "2023-10-30T16-20-15Z",
            "size": 1035731,
            "verified_at": 1698753491
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698682991,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "D6FDF4TVB/HMT+7bbA4aZT7BlTm5+O8uC/xiysoFwrpKk2xoP8nWJhUihJgXobmdZT0H/9/v6EcVtjZGJ4P7qnekVc7DFU+eJ90ItyuooEYP9vFUGNCNAvuUNwj9KuHwESgDvRbbz0LJhKidU7sdd6hyxWX197F+RyukmiDsxui1oLbm1HC3ju68NNa+xhcdA0KkjHYK1XzTJ/JDbcAKYBxaRdfOcIQ3tolRiKYSwuYcjqiwSj3C9l9A0L8WGzJg3N5/sYDK67eiVZoRAZy35fSJoIFjoeQes/pbYIh6pqsDEJjfrGD++NsiCeQi7iVTuHvOkMIfF7H5Thk9rQYwadTAAGghNGcil3AUjgpto3379u+rBmTBqQy1uuMqydBQQ8uVd6o9tdxsiSz4CJUVDqjxbl7n28/KPUTnALAVkOn/l+CpgxTXluZ8582Ja1y2kXJeInCOns12v87cVY0K8nVVFmMe/z45TXuqbjosJfD0wme+qksnl2KzBegYg7wzGKDJEs3+jVk0XgEdLDTQ6Mo6ypJOt5XAsukHVVsWmxQ9NVocngHoK6E+we6+Grhbraz3T2/wsa5aWAiEXGUO5MQnI59jNLp6rbK/LCigj+EOsPwpVnv793yOfigT89wtGlRxZaAJi70hfueBXVEbzNaO0+856lKD5en6t460vEY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T16-22-06Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698682916.1186981,
                        "modified": 1698682916.1186981,
                        "name": "/dev/shm/incoming/2023-10-30T15:51:55.ts",
                        "sha256": "79634a2d641bd48a820b9dd3e0a58d12aadc00e2d8ae3d64d0a7b0ccb993e990",
                        "size": 410954088
                    }
                ],
                "mission": "OSP2",
                "sha256": "c8b17c0e090cad8e99af50ec0a27577165b94c70d5329f3882bb6a9cfe92a94e",
                "size": 396922269
            },
            "errors": "",
            "filename": "2023-10-30T16-22-06Z",
            "size": 396922269,
            "verified_at": 1698753921
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698657570,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "C7P28PGv1dbAh1kdOjguWdnJpNX2ElMT0A5pqcL1PsarpcGTQKopSA0yvjaDYOrrDVHT44r/hVtsOJoG+oGPkZFK8BDq6kKNT+8UXk9R7kMEd4Z6NWkwzY2NxHTq+uM/5rY9M9miZBwo5ksjJitG1WuDc80J96d2s1qF6AkXPDJFTjNUF6GPg7sdYO1xmJLvFLVa4cDK/jcfsV44rtLIydzBI7ZmeaW8D4nLS0RJ+M6Lo/SH9qmknZGkCQe5ef+lR4mH9KMw/XGHxegUmCAX0pnheFnpVhKy+xg3NGJeIpdUqZx+Tuz4hXv2e4VJKTa2mP6jYk1UdbdWUcBJuzeG5HaLKSSEanadkCP0CJ+YxckGXR2UKLUIoiTq8LkXgpXvExOy8QwEaMi97Z2Tn+pukWyDKISYyxFARSW/IeYcAKOkeu05RjiB97rEujwNUfh5RuC0T4pq9t/84aB2vFywU5CTgBABP4l/bTouE+dwAbVXiZK+HNhbcnuk5CtJ2Q6ROg1Zpe5hTJ9PPumnLJ/xI65RiO4+9JBNnWDJmrJHiG72NfPvJTxZ24Noo0fNzjXNV7JpqgFTOGkSJf3ekEJotUpVsqbejxx9URDjkrga587aq6POzuxUtL5BIXwzzK0jPZt5Q31JH/Gm5ZkzvunyRBKzgv6fnkqDP1bVegOaJ2A=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-17-21Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698657315.3479407,
                        "modified": 1698657315.3479407,
                        "name": "/dev/shm/incoming/2023-10-30T09:05:14.ais",
                        "sha256": "51b797dbc55fe8c2d8129c53a6899a4738aaf7eaa1e21aee9939bd117e7a9ed8",
                        "size": 418339
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "935ab721aaedcd1718cc3b60332c42aa16d6025b7ccf5e6dd557fb22eed64e79",
                "size": 124438
            },
            "errors": "",
            "filename": "2023-10-30T09-17-21Z",
            "size": 124438,
            "verified_at": 1698683059
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698659269,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "TaOgRXyxjTXLKLddiBntGEurTPoWGMpBujwAOUirncWX7t8n5AF+9j87ZbHMYJFEpRV0IbojcldlGVxwYUA78wPO4pdU5S4sh4Cjbmo0Ako3AXN45DORx5sq5wL4OW3Fi+QZzvZkv7Fy2op/lXHLQvzBxlMoxNVvUaRnaztN98ZKaVwgdSYaruAdQudoavuupx+gQ2b99s0gDRI+ge138S6x5vK9eEgzJMDDnnNm9n+yfTHeBv922emjrxc7MB9jtWCQBR4IvDFLlhdcRGPIu/wrQYmgWkReUg5v5jrVQLGPXQnVBy0Q+J+T0WRCLmvqubF/VEbvq+LRndxcRGbuqZaNybhwUDUtiCYp1fVcGIetR9k8/K+G8JmTc0nI611gpbDbei0JseNMMqQHGl1WBYSRIrKisPDcPZ3hYos554aqQJEsQ3I9ev1qGA5LyhNoIXi5KJLk46/6IkdxIUUkbtObts7o3HfVthTi7YxJD5S9VwTR90zoSRfp0uV4IDfhITDNTTQrzyB9A2xb0+Kdhh6ADFxxZIkQfOd9No/8qESBUdw/FN0pIuzeFNEOfZBqWyWDU+Pa4NLbFbpl+WTxIXgC85IBqumTT0TJ4sfXu73VmEwhUeeus4OC5ZFkA0L/XaLfwDoih8iI+SStcw0hKBTZUBGK1Jy4ZSZSB5KgsVM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-47-32Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698659126.8679407,
                        "modified": 1698659126.8679407,
                        "name": "/dev/shm/incoming/2023-10-30T09:35:21.ais",
                        "sha256": "304581e55b6ba805126a8e790d4fe46faaf33b88bce3c90120f6a3776feacabc",
                        "size": 200402
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "8b0a5d28bf2e5603dcd60c4242d0f00466b9df311f8fef8f7fdb80c3eda39f7d",
                "size": 57977
            },
            "errors": "",
            "filename": "2023-10-30T09-47-32Z",
            "size": 57977,
            "verified_at": 1698683190
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698659906,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "TQFqZB0YwRdHR5O3LVqSJ41tDB5Ux8H62G7yyeaNw4iNVB1g3w0iFRAKxdXKUqvkOhiV46QiOAkUweMEx/UFgtZdeVKTe49TUXAlpP45REhW3RqVPuSZbH2OH11B5x8T6YznouRDCcPJuGRW+wTeMoyYMyh9hUneNwm1D6ZZCp0AQQwHuIgA62MewcjpRGuyeqf2osXdlDXvyxgcwprFU7V0jYJ4UUu9dqNAV+9To5dL+nqvaKDiPD2W1h6FEoNcVw+kY3NggM7zHyp741CximxmOP0KuO72zXM0ERAnuRYNxyrvJLjFioysv/3HKk91TpRR8dCTqFh1fJI3rOFbfNRTOOduK1ANjw+81stuJ5mScW1mshp7UQCfJKtLDkc5S6YzqX9kPsIzzB+hBXpKty9zIcsJ0KlyR76ELqAZixekiEbv797rNxU4Ff2wOKK9RM1KWfwfPoWmjeyBYLBJvjsa87IvwrjkXLGqOrM1f/JkNz4CSjF+UL2CAkaPPYdffrLnQ+OdnARcqIwCu/ZN4yF/DV95Lae+BAe2OV+3fgsP4ivzfOsDTL5vBErg+JbeqMfFhFWX0tmc7X1Vp9Gnz/Vav3i4ONWdnSpzKo12rqCcPXscAc87cYrvIjCre+PDEaBZqlDvp2GOTlC5Ycqvn2jy2J3kbmAe9UgUmI5Pebs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-57-36Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698659729.8379407,
                        "modified": 1698659729.8379407,
                        "name": "/dev/shm/incoming/2023-10-30T09:45:26.ais",
                        "sha256": "092f793f7557599b48799764f78051c780ce0a2a85c2230930261bdb3661cf84",
                        "size": 167856
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "08c073a99f8a52669a66e9e155d6535bb584440f0a9ade3c919a4f582afd6fe2",
                "size": 50076
            },
            "errors": "",
            "filename": "2023-10-30T09-57-36Z",
            "size": 50076,
            "verified_at": 1698683223
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698661684,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "H6YpsY/xP+bX92WRQkoWS4fI1KBSUJ9MHnq2Tke628U9FJ7fOLX38zJmFRbhKbO06poakyltQzb5bZP1Pom+qOg1F4/gnnVjLqExZq69zmN4rucYDvqi73hhui6HVTl0U5vk5WKomK9kbNE74Rd3t3C083EapNa4RyjVyX3oruGJqc3gtNBzP6mQIb/FOPo5ObQeNSfEUUCAZyUU5LT2gpIpic/1hVF67QC0NDXqHeiNNZY2rDlXuBnTNEhEhVQdaixnmkjFONAvgHbCdi5x+1Jdf9qPbxt2CNqmYmdYibYxV4TLljnLpc6vuXkk2zGw6UCs2moX726PsrACzZt5F3rz7YYff6uxt2WZMf3qg+uLw7yijYFf3+h+VncGT6M612sX8ASPMCuqDL68QG3KlImSTaa/JlQLBdDaV2DT1+D9kjqpIT0QtK6zDsQQF6lvmnq4tEoSbH9P7uWd9SJD3ONTjLr3+d8jLvW3O9vBO3+EvF073dTgCX4Y+5FG5nh+zgWzcAzSyprfx5YfQNfEMBglbfaHLMsLP4CQuFNGbhicGJX9DAauHVK3x0hS/VIOJltza6iOPMH1YDgd9/1mqMUaGI8iMuZXezzAi9yxgau9i8NslS1RskehBt/suu5zG597L9bUX1jPuhfzG5LXD+WIphuPvPtLLm8MLPalGo4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-27-46Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698661541.3479407,
                        "modified": 1698661541.3479407,
                        "name": "/dev/shm/incoming/2023-10-30T10:15:39.ais",
                        "sha256": "9d7191bba6ba7577531022c4a6844d3201f6ce284079880bc6e42501842e0355",
                        "size": 122277
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "0021b8db254f074802ad95a68487557bece6c306eff456601332b76de222c352",
                "size": 36797
            },
            "errors": "",
            "filename": "2023-10-30T10-27-46Z",
            "size": 36797,
            "verified_at": 1698683497
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698662905,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "dPYJIJVd1zEbbuOXGjOdf4eJlS89iN5bZWIi62OvqGDT8pWXizJ7AlYF5Vk++gnCzinvv6q+iXPnWN5TqFt289WdfvD2/ERgJKuTUGU9NsPssU+69357fZqE5GzNP8BkFZae2khJM8Y9mkGr2FIEjm8QH88RDJgK7kMIc2nfRfl8KvZHYx9Wm+IbafHacvGRuCwIuom17u6x51dMXHva5GccW0n8HnADwE40jBrNjurDMJzhluaxQ+Fodc+7iY8XBk5vMeohGeZsJUVlRM6Vm/juWTK7FFidc9Rxt97drSfp59DWaPGJ7uVoSR8IMnHb/YsRF0X0dR0affb4DgMSmtmSQIIboZ4ODhSayWcAafQKm41SqDDDB1zibSYYUdEPhxz5GrqwzXTGFRrbaFAYAqtVmBW8nMXAPJU+N1y03f+sve2LVPcUJIZHUHYyNMEwbcekRF1hTXMUE/4rlmsBVP6RGBZ2G2OleuRfxPcXOjIxlb63Ec1died64EkTrCu6xpy1u4B5/Fm5ERc7oR5B9eCmkcf9TQ4LNk3H+kBBh/ePVSqqomL9rwVgcryk/VgUjtBk+ciPwGE4WytN60nGIH8lpzMZIeQ6tIktZ0BdR9zcJ+hZJF14wBJ4XHMUA4+Op9F7Z103VowhM/buF6K9IGeGMQQrz4AWA6hRD1Pf34M=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-47-51Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698662745.8379407,
                        "modified": 1698662745.8379407,
                        "name": "/dev/shm/incoming/2023-10-30T10:35:44.ais",
                        "sha256": "c1104f10503a0c889d8446f8c6ed1959f749f6dece0f7089aa9f031d313a7e7a",
                        "size": 309582
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "30b27280eb522dbf3d4037a4083a1622a0ef6e31cf83e53c379be54b7b10b0f0",
                "size": 91839
            },
            "errors": "",
            "filename": "2023-10-30T10-47-51Z",
            "size": 91839,
            "verified_at": 1698683516
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698664712,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "XxBwUA00TTd480xolRduJsYpsufHCpvtUcS0EJuDaSqqmwimMrAqpCPkHrmuLX0sIyn35u/ccLlrCLEPQ6W3KFynkVXKljsX/GnuCe3ErWxS9yo7vmrwKDMg41bk2+DE71NTIlRnRveJIsp8kwAWZ9xl1EjvasR1PuGRG8kBxqJb3vhT8yBCfcXgA8KmYB3zhiJLkbfPo+rm9tFy97bAqsGC84YK3y25kXGbpGTpw87Vtr4jF0ospd5VQjlvZjlYM8A7DUGnu7O4GlJOeAVvDUzR1Yoij2BcOF0Y1P+0bgSIHwC/T+7MO0swLFfiVLYChBnw1QRk7Bt1dHUys/29o2jZZDP8b7wdU1uHrfPUM2+Qh7LNyQerv8UsLYQM7b29IMYGeQ7oC2W28g/P/oo0C03sXKHSHiZ6mwI5Sx6f3HgHVmdUKOpwH79uSw7YmNxDE1dJhrAx18BBw3qk4pDqhIAw8wSHRyim2bQj7DIyf+s35gNFRzPlgn7Oorq19dhxS556UYKpCU3zHP13Phf/+IwYq7OZ7Wx6YhITo2uvfbzl5k2tb7RxvsJ1smMmbsC3GqOLIpKypXGw9oYIkqDlSnyD2mgNy+5e3/kl55XgUqietyUrS7qcgQAnJxCNpvExbYKJjxlVneUtCpcxRSBWbhW6O9KjR7nTuGp//haV5n4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T11-17-59Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698664551.0279405,
                        "modified": 1698664551.0279405,
                        "name": "/dev/shm/incoming/2023-10-30T11:05:49.ais",
                        "sha256": "62dc1af6c4373f549c47b75dddbacc674a8bd5cc95ff9c94bdb9be5f7350e59c",
                        "size": 311108
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "ccd3bd7086ba622c0803e7342d3c509628076bdcf534ffaf4a92235207ef8588",
                "size": 93956
            },
            "errors": "",
            "filename": "2023-10-30T11-17-59Z",
            "size": 93956,
            "verified_at": 1698683835
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698667629,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "LID866FYaRVTpkmsLd/zG4M/zADZeRMPGMDWK1Q5OQ2ztLrvXns7AcdWz2dg+MuvZdAwv4e0R8Rklnte5AtU1IuGm5uWJ0KgiKvoliDEbhluAfhzwIBAyeQuyhfgTlQZy7/4SJOKTpNZVZTENoNRKv+OG37rxWpGMwYaI9MPCDTYv9r1GG1LxlYFDD1Se4SMMMoDYCDmACnjCC1ngHuUCBAsAb2hyQPQVXMzIy+FyMutqAJQOTtYjXYPZ6Fgv+vPPtZTXU5IOrJ/nW+Ll093cJfF9uVqS7VjOXReaEbX6Z1MqCLOuMr5L2fPKeRa/i+CoJijePZ2FEFZMTPVjEICod9HIeHgmnCksF+C593C0sc0GtqlEDyiAcxnOuRdnSAuHWVsXI+Lj/Qu8lBV7IAwkZLGJfBWcq2SUm0KOa5fd/pW3W+ydpvaNFP3zxJciXiIDcASalpzBI8evwmZ/yhHOPdGduphBYZZugxsD6jgEp5nC3nhrKlLuI3QllQjm4CXTnvZzywLSkkPSq8Qs0/wsRhr1+5++xuQt4EV0hcuxaG+ieO7pXNOOntEJHOKQBBr2pdrpWDvvl5eXuHlrqmZ9zck2TbrWbwnqi/KjPuvCbAPF9SxURTXMFawNqRFHbrZwcJmvB2FsRoDr59yUAhMoKRHfwwzjII+Ju5T3B6Z7kY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T12-05-23Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698667412.6479406,
                        "modified": 1698667412.6479406,
                        "name": "/dev/shm/incoming/2023_10_30_120252_00_00_K_140.txt",
                        "sha256": "9b4952f22fd60ea4f995122f5faa8740c748b954de6022f5b235def85fdab4ac",
                        "size": 2047
                    },
                    {
                        "created": 1698667403.9379406,
                        "modified": 1698667403.9379406,
                        "name": "/dev/shm/incoming/2023_10_30_120252_00_00_K_140.kml",
                        "sha256": "c7f44be11e4502f49e8b9a14566d57362d90b43a60460b463b06b01b7c954a4c",
                        "size": 979
                    },
                    {
                        "created": 1698667400.5579405,
                        "modified": 1698667400.5579405,
                        "name": "/dev/shm/incoming/2023_10_30_120252_00_00_K_140.jpg",
                        "sha256": "3cad0c022ddba4189ae8cbb800ce83f10a0ade6e869aef393feeb8d806df06cb",
                        "size": 738296
                    },
                    {
                        "created": 1698667396.4679406,
                        "modified": 1698667396.4679406,
                        "name": "/dev/shm/incoming/2023_10_30_120248_00_00_K_139.txt",
                        "sha256": "ca2fccb23703d73564e91e8aa87bb0cc5431a0f692ee0a29846035e958e2ce77",
                        "size": 2045
                    },
                    {
                        "created": 1698667390.8079405,
                        "modified": 1698667390.8079405,
                        "name": "/dev/shm/incoming/2023_10_30_120248_00_00_K_139.kml",
                        "sha256": "47c221d720866ad53626912f583c69cb85e550ce7ca2f9106da2456596c1049a",
                        "size": 976
                    },
                    {
                        "created": 1698667395.7379405,
                        "modified": 1698667395.7379405,
                        "name": "/dev/shm/incoming/2023_10_30_120248_00_00_K_139.jpg",
                        "sha256": "0be6166560239906969029d19043867225a8bc5350ee5e02907b970abe975af2",
                        "size": 688725
                    },
                    {
                        "created": 1698667353.1179407,
                        "modified": 1698667353.1179407,
                        "name": "/dev/shm/incoming/2023_10_30_120211_00_00_K_138.txt",
                        "sha256": "03bc5b1a79929d968e46c06095a3d7e491d833f8bff9d77c476d79fe8b83f772",
                        "size": 2042
                    },
                    {
                        "created": 1698667353.6379406,
                        "modified": 1698667353.6379406,
                        "name": "/dev/shm/incoming/2023_10_30_120211_00_00_K_138.kml",
                        "sha256": "93c8343ee0b13876fb2bbeeb7349fc1fb9c581d034e1ac45cf4077aa1c443adc",
                        "size": 974
                    },
                    {
                        "created": 1698667350.3379407,
                        "modified": 1698667350.3379407,
                        "name": "/dev/shm/incoming/2023_10_30_120211_00_00_K_138.jpg",
                        "sha256": "b01efc2c315e36a6d045a5858c50bfe89bd202d94d2311b4efda62aefdf6d688",
                        "size": 607964
                    },
                    {
                        "created": 1698667349.4179406,
                        "modified": 1698667349.4179406,
                        "name": "/dev/shm/incoming/2023_10_30_120204_00_00_K_137.txt",
                        "sha256": "04f94576e180b67ecf573c54fa36d7ac4a8fd46f69eef8fd0c7b7fae5a21d074",
                        "size": 2040
                    },
                    {
                        "created": 1698667349.2879405,
                        "modified": 1698667349.2879405,
                        "name": "/dev/shm/incoming/2023_10_30_120204_00_00_K_137.kml",
                        "sha256": "8f1ece59df1190a254c499ed7f52c9c50e60e5d41a2fd2bb769948076ee395c3",
                        "size": 976
                    },
                    {
                        "created": 1698667360.1779406,
                        "modified": 1698667360.1779406,
                        "name": "/dev/shm/incoming/2023_10_30_120204_00_00_K_137.jpg",
                        "sha256": "aee666edbb99119eed5cec9b170a9dda6a9530cb169b2d6b16b454cb85b8b22c",
                        "size": 603975
                    },
                    {
                        "created": 1698667347.3379407,
                        "modified": 1698667347.3379407,
                        "name": "/dev/shm/incoming/2023_10_30_120157_00_00_K_136.txt",
                        "sha256": "528744a5011ab0c190bc34e7ce82538a2c464ce0e417aa826ac17433d8f7a0ca",
                        "size": 2044
                    },
                    {
                        "created": 1698667342.9479406,
                        "modified": 1698667342.9479406,
                        "name": "/dev/shm/incoming/2023_10_30_120157_00_00_K_136.kml",
                        "sha256": "1ea895b69a32233d0c7f1ae48f712b7cabcbc63a2da7854e7d3c3f1ec8d77c42",
                        "size": 974
                    },
                    {
                        "created": 1698667342.8479407,
                        "modified": 1698667342.8479407,
                        "name": "/dev/shm/incoming/2023_10_30_120157_00_00_K_136.jpg",
                        "sha256": "13881e8faab344209eb7a355c7ad52697c8e5bac72832cca8ec2d68335e6bd64",
                        "size": 627570
                    },
                    {
                        "created": 1698667335.3179407,
                        "modified": 1698667335.3179407,
                        "name": "/dev/shm/incoming/2023_10_30_120149_00_00_K_135.txt",
                        "sha256": "a3a7824a50aee493e3660f770a9bd3d4294495ba3fa3508d9d3914580b7fb778",
                        "size": 2042
                    },
                    {
                        "created": 1698667335.6479406,
                        "modified": 1698667335.6479406,
                        "name": "/dev/shm/incoming/2023_10_30_120149_00_00_K_135.kml",
                        "sha256": "9b21882adbef27d5cd47b3865d00325f0b3345034ef8167f161becbb6b27ad84",
                        "size": 978
                    },
                    {
                        "created": 1698667334.4779406,
                        "modified": 1698667334.4779406,
                        "name": "/dev/shm/incoming/2023_10_30_120149_00_00_K_135.jpg",
                        "sha256": "400d9d6e4da0f1d4b98c5fcd9f7f2597c89ef79913e12deda3b3ef12d31e8e7e",
                        "size": 696751
                    },
                    {
                        "created": 1698667323.8779407,
                        "modified": 1698667323.8779407,
                        "name": "/dev/shm/incoming/2023_10_30_120144_00_00_K_134.txt",
                        "sha256": "92f4fe4f16cf6ca006dacc1b572ee1bcb7334143b70ae56757ba21238a70b7ae",
                        "size": 2043
                    },
                    {
                        "created": 1698667325.7679405,
                        "modified": 1698667325.7679405,
                        "name": "/dev/shm/incoming/2023_10_30_120144_00_00_K_134.kml",
                        "sha256": "dd870483f2058f0691d8841e751437385fe7cfe8950ef989b54616e138bc046f",
                        "size": 974
                    },
                    {
                        "created": 1698667331.8679407,
                        "modified": 1698667331.8679407,
                        "name": "/dev/shm/incoming/2023_10_30_120144_00_00_K_134.jpg",
                        "sha256": "e071b486b8ff259366761cb1b2435038b29f5e6d46c776d997ca55484bdaaf7a",
                        "size": 697286
                    },
                    {
                        "created": 1698667320.5879407,
                        "modified": 1698667320.5879407,
                        "name": "/dev/shm/incoming/2023_10_30_120126_00_00_K_133.txt",
                        "sha256": "3abea1fc34233743d43bf29ea38c8099c2fc4ccb658b2299f9ac6a7c969f968e",
                        "size": 2045
                    },
                    {
                        "created": 1698667316.0979407,
                        "modified": 1698667316.0979407,
                        "name": "/dev/shm/incoming/2023_10_30_120126_00_00_K_133.kml",
                        "sha256": "37172bee18e392076a7c54036412a7e68719a39fe7b004a60099ffe6ce99c2f2",
                        "size": 978
                    },
                    {
                        "created": 1698667315.6279407,
                        "modified": 1698667315.6279407,
                        "name": "/dev/shm/incoming/2023_10_30_120126_00_00_K_133.jpg",
                        "sha256": "ce106293444a746de78ce63f1aaf50129617fa7aabb52ddec51e1adaa3080106",
                        "size": 682285
                    },
                    {
                        "created": 1698667301.5279405,
                        "modified": 1698667301.5279405,
                        "name": "/dev/shm/incoming/2023_10_30_120120_00_00_K_132.txt",
                        "sha256": "e20840e7d164f5282fb34c8dc3d960059c62d0cae4a89ead1d3e7fb4fc391acc",
                        "size": 2046
                    },
                    {
                        "created": 1698667307.6179407,
                        "modified": 1698667307.6179407,
                        "name": "/dev/shm/incoming/2023_10_30_120120_00_00_K_132.kml",
                        "sha256": "bec4ea88439cc6086eaa64aebce204bdd21ac56ca1e2a977441a259fce45c42b",
                        "size": 980
                    },
                    {
                        "created": 1698667297.2979405,
                        "modified": 1698667297.2979405,
                        "name": "/dev/shm/incoming/2023_10_30_120120_00_00_K_132.jpg",
                        "sha256": "ca643a880e8463415198884f11cd9d6975dbbcc27e7845ace89233be24fe8f2f",
                        "size": 736571
                    },
                    {
                        "created": 1698667295.0079405,
                        "modified": 1698667295.0079405,
                        "name": "/dev/shm/incoming/2023_10_30_120107_00_00_K_131.txt",
                        "sha256": "bc47dbc3e362f758302cafbf2e331e2a7266342bef4ba56728bbaea0388830fa",
                        "size": 2045
                    },
                    {
                        "created": 1698667294.7579405,
                        "modified": 1698667294.7579405,
                        "name": "/dev/shm/incoming/2023_10_30_120107_00_00_K_131.kml",
                        "sha256": "56237cd28972ad095c4c8dde0ee49b08786ff81d16e4970d4796e43de2ceb66d",
                        "size": 977
                    },
                    {
                        "created": 1698667291.5479405,
                        "modified": 1698667291.5479405,
                        "name": "/dev/shm/incoming/2023_10_30_120107_00_00_K_131.jpg",
                        "sha256": "2060da9e51a42f594f65936a8280873a4fa13eaef19c2914572416ec9709a0ef",
                        "size": 1040469
                    },
                    {
                        "created": 1698667284.4079406,
                        "modified": 1698667284.4079406,
                        "name": "/dev/shm/incoming/2023_10_30_120059_00_00_K_130.txt",
                        "sha256": "eba070325b14d0e9ba31ce6530c4ff56dbf58e25108cc746a47be85c742ff3af",
                        "size": 2048
                    },
                    {
                        "created": 1698667284.1079407,
                        "modified": 1698667284.1079407,
                        "name": "/dev/shm/incoming/2023_10_30_120059_00_00_K_130.kml",
                        "sha256": "39cefb86882d4cd4f0470c6c18be445af9647e9312c92d3f12677feb0aed3e49",
                        "size": 980
                    },
                    {
                        "created": 1698667283.8879406,
                        "modified": 1698667283.8879406,
                        "name": "/dev/shm/incoming/2023_10_30_120059_00_00_K_130.jpg",
                        "sha256": "fa114a1a7b3ee1932107d59a4f9c47456add6acf621d9b1e99d014bf559226e7",
                        "size": 1070461
                    },
                    {
                        "created": 1698667278.0779407,
                        "modified": 1698667278.0779407,
                        "name": "/dev/shm/incoming/2023_10_30_120052_00_00_K_129.txt",
                        "sha256": "d75bf29a80bf05abbdbdfcbc4912551b2cb91d0d454afd85f656e94581439f2f",
                        "size": 2048
                    },
                    {
                        "created": 1698667271.5879407,
                        "modified": 1698667271.5879407,
                        "name": "/dev/shm/incoming/2023_10_30_120052_00_00_K_129.kml",
                        "sha256": "15164cae2502fdb00edced8373d17e020a049eebc9b54ff527a63cd43f30c7fd",
                        "size": 977
                    },
                    {
                        "created": 1698667280.7179406,
                        "modified": 1698667280.7179406,
                        "name": "/dev/shm/incoming/2023_10_30_120052_00_00_K_129.jpg",
                        "sha256": "44ed8f4150750cd603f76db25b047bd0a7e14fbd8684ebbcce15f44883256398",
                        "size": 665964
                    },
                    {
                        "created": 1698667257.5979407,
                        "modified": 1698667257.5979407,
                        "name": "/dev/shm/incoming/2023_10_30_120030_00_00_K_128.txt",
                        "sha256": "dae8aa40badbb27477bc746f0e0ee7c9d2cf5c9e688d6b1a3ce9c96283326ad3",
                        "size": 2042
                    },
                    {
                        "created": 1698667280.7579405,
                        "modified": 1698667280.7579405,
                        "name": "/dev/shm/incoming/2023_10_30_120030_00_00_K_128.kml",
                        "sha256": "16665d21af46c8edd594b8c04e40c72fa554f4f9b5e116b2225b99e12000a9eb",
                        "size": 978
                    },
                    {
                        "created": 1698667249.4679406,
                        "modified": 1698667249.4679406,
                        "name": "/dev/shm/incoming/2023_10_30_120030_00_00_K_128.jpg",
                        "sha256": "dd9d194b526fe3b102c9c9e5bba266388398b4d49e3be07d4d6cbb95dab0d87a",
                        "size": 689856
                    },
                    {
                        "created": 1698667246.3979406,
                        "modified": 1698667246.3979406,
                        "name": "/dev/shm/incoming/2023_10_30_120026_00_00_K_127.txt",
                        "sha256": "8e3665cdd556d206fa4ee89532742164b68447344591953f556147668690c6b0",
                        "size": 2044
                    },
                    {
                        "created": 1698667246.2879405,
                        "modified": 1698667246.2879405,
                        "name": "/dev/shm/incoming/2023_10_30_120026_00_00_K_127.kml",
                        "sha256": "5424f7f5408ee814761c4727c61a8d84986ef0ec726380964554d062e04b734a",
                        "size": 978
                    },
                    {
                        "created": 1698667245.5779407,
                        "modified": 1698667245.5779407,
                        "name": "/dev/shm/incoming/2023_10_30_120026_00_00_K_127.jpg",
                        "sha256": "407929de1004bb4e5c117f4e1ed0f08b2aabb0da654e929253ee0f508ef740e3",
                        "size": 657255
                    },
                    {
                        "created": 1698667233.8379407,
                        "modified": 1698667233.8379407,
                        "name": "/dev/shm/incoming/2023_10_30_120009_00_00_K_126.txt",
                        "sha256": "92a91b70fa6e0e4506bfef2afb3d443cffd331a13802cd2e21df13ac3ab13b8b",
                        "size": 2044
                    },
                    {
                        "created": 1698667236.6979406,
                        "modified": 1698667236.6979406,
                        "name": "/dev/shm/incoming/2023_10_30_120009_00_00_K_126.kml",
                        "sha256": "9d2f188e70d1f02473b7f5273245bd04d052d2deafa64c0d3935f0528c702167",
                        "size": 977
                    },
                    {
                        "created": 1698667241.7579405,
                        "modified": 1698667241.7579405,
                        "name": "/dev/shm/incoming/2023_10_30_120009_00_00_K_126.jpg",
                        "sha256": "066ae246d3e1a237cff5bca64cf5be079f0fb8c0d64b8fc674469c8de7126694",
                        "size": 814773
                    },
                    {
                        "created": 1698667220.2979405,
                        "modified": 1698667220.2979405,
                        "name": "/dev/shm/incoming/2023_10_30_120005_00_00_K_125.txt",
                        "sha256": "ada4613644295cbde87db62ec982ab73b1ff37115ca12f43ab064d67bcc76a79",
                        "size": 2045
                    },
                    {
                        "created": 1698667219.7079406,
                        "modified": 1698667219.7079406,
                        "name": "/dev/shm/incoming/2023_10_30_120005_00_00_K_125.kml",
                        "sha256": "320f8346cd5118c56b14f3aac48c074400f497cf9d4f4cdf2ca6fc3ac66c316c",
                        "size": 974
                    },
                    {
                        "created": 1698667219.5779407,
                        "modified": 1698667219.5779407,
                        "name": "/dev/shm/incoming/2023_10_30_120005_00_00_K_125.jpg",
                        "sha256": "f4c40fb094412fdc41bee7f75cfc11c2031454f595c380a869edd4b944896646",
                        "size": 689376
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "8549e07b86b6762926dbee8a39670b72af046c1803f388feedbbdf2e1bead8ea",
                "size": 11644871
            },
            "errors": "",
            "filename": "2023-10-30T12-05-23Z",
            "size": 11644871,
            "verified_at": 1698684132
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698668322,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "Et/rCX+u9pP5KvOQW5s24utVeJqdHH0qyW8RYf3D9BoqDaODO6ruGw0PvSuvggih/2a9yA/N/SzJACeP24PxtVGcz4iNeD91+ViBpDbd5RF7PaTjZwj4UgYSmGOlQV5tg6QJmXUXjQTzMx1HmDxhg93fEyjvlH7LJhNYkNUM5srjTfo6+FP2VoWW3S3qJbqNw++NeIVI+szeGMlzVtT9yQPn5mJKcu5ma6PW6y7eoi21uJdeemsme7smhCWnUFm/IMPsA2zir1L1yx8ZbiO4aKsXWK14RPpQiHiPddM1ay6UudVm2RgjZrrALdsQabiVy9SuApg3XiyYwU4Iby4fTRyfdk2/gnR3YRkQfngPsMLIVjkxG1/vLL4QrO1n2cmXyvfSf3wdJudUOuOWNgIb740IMH+Urywq2qyjP8KrIGScg+I+nrtleRiqc3SOHOmDe0PP8eTi3oQy8ud/ZHK/hhfa4p1hn8bWO4qO7LRCeLH5JbUrkEhtrlJ8DYjrBfmOjmgvXgOV2bexn/2iY90Pm9Q7yQfIBxD3PYt/tAOEH+Ef9PDhQbteeQ6Syx1W4LP+wq4edj0K9wPP+HZLCVNHIDwF4HC1de7bun9mtY9BwRvn4FFZIrbDK+6Rkx+IQp9P+ZVMBgUf4IcE32fyTg/rGX/A8Bj6IKCN1eCGF5TTfyI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T12-18-06Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698668159.5079405,
                        "modified": 1698668159.5079405,
                        "name": "/dev/shm/incoming/2023-10-30T12:05:58.ais",
                        "sha256": "42b04b909fa6a56daab668a5d72e3226663bc226e9c76bd5dd16db97057d0bea",
                        "size": 580411
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "c18ddf465803f66f9005057e21276194212370c44f44e3c5dac0a8c8372dd0fd",
                "size": 173210
            },
            "errors": "",
            "filename": "2023-10-30T12-18-06Z",
            "size": 173210,
            "verified_at": 1698684187
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698668920,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "wODa7pmdTxUfxdCADf4PN9mHUDyqvWmOAFRmNl2iHqsj/n3TYVP+dEW4QHNjPixhBHXbSektd/1ZWUrguuQLa/5l+JZy9I3JlPnRZ/gXx1dGU5gzZdP1XyIN+b2Zc9Mja/5hmVSaNww8lmGsq6t5I4hegH08F/Nspiz0gXhVZawmswiwTJJjkLCGDuELK7bBlRcxAx1SYBDcEmnb1hzoR4+/5qDnjTJzdoDvB/kbD08OkpYuG/5FRctNByoOEftdYraAZMBwx8hVrTwqb6x9NKesQslFeId7WxXQcKTNqe/N1I2EvDHrmt7itttw7ZQMrgTDQwo4nZvYezk1WPk8RyNiJJJEYy4EavZ1FIMjq6OMSCXrk6jfKRG8tAdyYoRX2+M+uwCmEmCDtTrfI5eqSewXW2xkmZdVrHEHyqfl6l3vteBkKWzTEpuAD4F2Rl7dV203bHS8Auw5mIEIEfyHHsPkPt2pNjIIe3Jmr9Od1wMqJXHDaIMGW0uz8en///akxE/uFXD5YpHzeZEsjp0EgQvR0YQrkwhJvsYqyhcrhiocrqeu2g+SX/PhEKCgMMZb6lCq1OnHm+5TrixcBn00Wi7fe6oHGwLmU93MgWUpwt3q3mfnXIYMpSDNmT9U7JGY2CBDgWER51uXvEprnem+H0q8baSYdugUOUNsZ3kW0vU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T12-28-04Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698668761.2379405,
                        "modified": 1698668761.2379405,
                        "name": "/dev/shm/incoming/2023-10-30T12:15:59.ais",
                        "sha256": "44a590ef9cac584d92a55548321514af014ca11f6277be8e183aa3dbcea42d91",
                        "size": 667125
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "887bf13228d892eb5909613e0b7999f72791d6729f4b2d42810122a57fa75371",
                "size": 198312
            },
            "errors": "",
            "filename": "2023-10-30T12-28-04Z",
            "size": 198312,
            "verified_at": 1698684262
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698776861,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "B90hAd5qcwdAyQwLjuy64DnlxEEf4JBAy7yADL8sum26uTKjZqS5YSn6GIj2MF5pQkR6epSvrLT+gcbgLidkLhDrhy1Seq3pJsVA7eV68B4KhvI0fFkM77BWmgCyuMFOR1Brh9qPwXN9HRcx4p8QHasKAP7KndcOVh4kooJ9XsFII0q7ZK/HjZFZ/N8aXPKct71FNpK42SCrL1Q3iI11UNrB2CqecohZcgvWO9udUHWVWmOVAVv0kYyP56WPm8d6yJwD0dzt6lLPyw12xP89c4+1Oqb9FMiZokAXd7CMOxkEjbpNfHQW297Fzdixmb5nzpHI5FqYNOzQATJX7XYpHozmXKfjLvr5RjvMGC2WO8PQYrNBqY7nzUnv2HluBjNEXHbUk/tEKmPPK/wSckFbBSBYM2235IDSFOYPoD27M/fQnR/etiJO1gkDCgeME3e8vWg+ZXuSY7Q0vFTp7fzEfWHVt6YTdrJqxTTvT5Kcmb0vu84avfUqD6Hz1kfoxfvXuS32F5vLv9KDuC02cOi+QC9Dcneq0XvsHvEMt6Z0i2v/D/NkrGS3tlcetgVbPKK5Agd4q2tv8TbgcJc+WITouRA+9Rfn1WvzF68Og/Mb+tHg03nWsmqWv9HAw8u7nA7JGSeyKhI8Wy8yli52ji1A8ruzm5iRt9Ie+zx9G14EsIY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T18-27-20Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698776717.243036,
                        "modified": 1698776717.243036,
                        "name": "/dev/shm/incoming/2023_10_31_182451_00_03_V_041.txt",
                        "sha256": "e04404417fa8f8d2ab86c8374cbae009d509c6e8ba7c12c07f278295ac2893c5",
                        "size": 2035
                    },
                    {
                        "created": 1698776721.013036,
                        "modified": 1698776721.013036,
                        "name": "/dev/shm/incoming/2023_10_31_182451_00_03_V_041.kml",
                        "sha256": "cad552ec35ec960d5f0b4b86582d61c3d13ca7e7cd92f9dca32e12d7c7bc5f96",
                        "size": 975
                    },
                    {
                        "created": 1698776711.1130362,
                        "modified": 1698776711.1130362,
                        "name": "/dev/shm/incoming/2023_10_31_182451_00_03_V_041.jpg",
                        "sha256": "9dc867f05b128b5c0e0303a65f822e56b2814c5cfcdc192869746337f5d26f9f",
                        "size": 1082209
                    },
                    {
                        "created": 1698776688.3230362,
                        "modified": 1698776688.3230362,
                        "name": "/dev/shm/incoming/2023_10_31_182421_00_03_V_040.txt",
                        "sha256": "12838eaa58e71a4be71039af6deeedd293a0ad0a85a497d32893eddf3e95f46c",
                        "size": 2036
                    },
                    {
                        "created": 1698776680.683036,
                        "modified": 1698776680.683036,
                        "name": "/dev/shm/incoming/2023_10_31_182421_00_03_V_040.kml",
                        "sha256": "264efd036c504a0d24aabcbfff5985dc628d6874bb80519b188a77d2b59b4238",
                        "size": 975
                    },
                    {
                        "created": 1698776677.653036,
                        "modified": 1698776677.653036,
                        "name": "/dev/shm/incoming/2023_10_31_182421_00_03_V_040.jpg",
                        "sha256": "a0d81b8d7bfeb2f5787c392c3875acf0cdcc0961c82b633fc22f3217c1e98752",
                        "size": 1076564
                    },
                    {
                        "created": 1698776641.913036,
                        "modified": 1698776641.913036,
                        "name": "/dev/shm/incoming/2023_10_31_182331_00_03_V_039.txt",
                        "sha256": "6e47b7dc5e538c31169ab4faee603ecf4c337b8259a81deece080d9b1ada81b4",
                        "size": 2032
                    },
                    {
                        "created": 1698776658.3830361,
                        "modified": 1698776658.3830361,
                        "name": "/dev/shm/incoming/2023_10_31_182331_00_03_V_039.kml",
                        "sha256": "95d999cabc8e620581cbe2d2f13e4ab9dca011091a3ff12769558212e9eda2c7",
                        "size": 976
                    },
                    {
                        "created": 1698776641.233036,
                        "modified": 1698776641.233036,
                        "name": "/dev/shm/incoming/2023_10_31_182331_00_03_V_039.jpg",
                        "sha256": "57e139ddee08804703ba8328ff06fcb62b9521885966f3dc1e8efc45bb1e6c21",
                        "size": 1070608
                    },
                    {
                        "created": 1698776623.8330362,
                        "modified": 1698776623.8330362,
                        "name": "/dev/shm/incoming/2023_10_31_182322_00_03_V_038.txt",
                        "sha256": "d341e5c9c3578376d0497664f640e69aae3f16fff40d3cb44bbd1c635f8047de",
                        "size": 2034
                    },
                    {
                        "created": 1698776621.413036,
                        "modified": 1698776621.413036,
                        "name": "/dev/shm/incoming/2023_10_31_182322_00_03_V_038.kml",
                        "sha256": "e681dd63c72ae39b7d987e48343c7a1e1ca664a74af34fab132358b38fccb2e9",
                        "size": 976
                    },
                    {
                        "created": 1698776624.1230361,
                        "modified": 1698776624.1230361,
                        "name": "/dev/shm/incoming/2023_10_31_182322_00_03_V_038.jpg",
                        "sha256": "3c19cb76545b4555edbdfa7ed2a64db255887d766b446b5722fbd0dfa6146745",
                        "size": 1073473
                    },
                    {
                        "created": 1698776595.793036,
                        "modified": 1698776595.793036,
                        "name": "/dev/shm/incoming/2023_10_31_182226_00_03_V_037.txt",
                        "sha256": "ad1ef435ae94afe393229f9c6ee91304ec60d65e3044c7e7b9af8020fe856bb9",
                        "size": 2030
                    },
                    {
                        "created": 1698776568.8530362,
                        "modified": 1698776568.8530362,
                        "name": "/dev/shm/incoming/2023_10_31_182226_00_03_V_037.kml",
                        "sha256": "547df159c5ffaf88971c09585ea1537cbd0a5febcb8878f08757c0d664d9b80f",
                        "size": 977
                    },
                    {
                        "created": 1698776566.533036,
                        "modified": 1698776566.533036,
                        "name": "/dev/shm/incoming/2023_10_31_182226_00_03_V_037.jpg",
                        "sha256": "25dcb1ab04d01a28102d0fcef3be6cd58efb9d5b36b974ec4d044e788e921bbd",
                        "size": 1060014
                    },
                    {
                        "created": 1698776514.8230362,
                        "modified": 1698776514.8230362,
                        "name": "/dev/shm/incoming/2023-10-31T18:11:52.ais",
                        "sha256": "cf00ff3d85a69306fe8e363c7ebd6312b08961e62732e111cc2ab8486b420881",
                        "size": 75611
                    },
                    {
                        "created": 1698776501.003036,
                        "modified": 1698776501.003036,
                        "name": "/dev/shm/incoming/2023_10_31_182107_00_03_V_036.txt",
                        "sha256": "8b35c86815d3aa37b704ab5eaa9757065f0f3193dc7ba338f59598e07b9768be",
                        "size": 2030
                    },
                    {
                        "created": 1698776492.0830362,
                        "modified": 1698776492.0830362,
                        "name": "/dev/shm/incoming/2023_10_31_182107_00_03_V_036.kml",
                        "sha256": "e586fc88179aa30766979481100f9aa233f1e5f11fdbc67c9f250bfb71d9cc42",
                        "size": 977
                    },
                    {
                        "created": 1698776501.223036,
                        "modified": 1698776501.223036,
                        "name": "/dev/shm/incoming/2023_10_31_182107_00_03_V_036.jpg",
                        "sha256": "efc20efdd79c4fd5a8cfa15790f145a83612419d49138f6c8c9bc66cb385ef11",
                        "size": 1044742
                    }
                ],
                "mission": "OSP2",
                "sha256": "ebb426b1b3c6a69499c72f324342693ac024be0a517ab16f932e7faa9b02cca1",
                "size": 6435675
            },
            "errors": "",
            "filename": "2023-10-31T18-27-20Z",
            "size": 6435675,
            "verified_at": 1698784224
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698665884,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "u6MlA43UZAD8nSr8fR2a3YWnWgdJV6EwFxpcJAprKfrv9fn1OF8IrO/CkMQKoPbCEBYU+waBraWgIsTPD6m0U71v62tE+NI9Vf++dxBLq1oI67iXdnrGZCOTlqxUpXBdYGBiqlazFCok4DnrmQEVu+spM+wk7aW54qqsLJooLdUcUtft5nuIkiJIgecLx8eCCNF+dyRm2hh7AhWQQ26oiDVyDetLPg3V6NelkhLu1K9cTV30oQr5u3W7NxykPiXgtmSBAlllRgv/ZC9jiHXQRBJQhcq/X6EefEFvc1olotErquOLnsqgypkqsA4DYBpqpbdOUYqenYg8dorz9I1sZ34gAQQ4disvR1hy1ZcubFDnmKEYdoWpOZmOnSahZWEAhpSiL0NR5/i7SIhyvudO6rtjOMDjDHRw3den3B76R5bpBvpCVvtSV8ycCk+PGAkh0fnDGQ5MobB29EAIreCaF3IolDddJPg8/DrwsGdGhjWGp9f7w9l+AOE3udAAUF8plnZWTF9KTjuuHsmMztoFVVi0EsDiG/L66F967zI1p7nlWjxM3ri31CJSZQHAAScggKC06zrAKQ10Y6i4qKPj0sLWz+Y/5FKkWCJCmbAKo5BbCVyeb/RS4Hwnk7tAATWYtNmFxvScAeq8KTW/9+hVGDJ27hvUFcBt9GLvio9OyxA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T11-37-01Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698665754.1579406,
                        "modified": 1698665754.1579406,
                        "name": "/dev/shm/incoming/2023-10-30T11:25:53.ais",
                        "sha256": "f573a5da4094f57aac5a8710ad69ada24cd1c59110b9e4c798ff1b59e926e291",
                        "size": 315962
                    },
                    {
                        "created": 1698665811.8779407,
                        "modified": 1698665811.8779407,
                        "name": "/dev/shm/incoming/2023-10-30T11:06:51.ts",
                        "sha256": "49b295a679f15b3799ee8ec9078c7732c063f02d1aca7441cb9fe3f7c0f099e9",
                        "size": 411555688
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "f93886dafad63eefc2ab51ac6d0d59e278140f2ae0658ccc97d7a2f5232d5718",
                "size": 368071959
            },
            "errors": "",
            "filename": "2023-10-30T11-37-01Z",
            "size": 368071959,
            "verified_at": 1698684357
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698670109,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "RUFsmWczxF2HZDAoUjWEmFljdA4dLOLPTrvdmkN8H15eElUsyZDeOaoFd3CblT6ihHMIOExKWTwyp+vHJnarwuQ6VTkUDkV+0jnYUheEimFb/eN8yS6YqGCKMBNnjHI/ZJJUi4DjqAoo453yFSJQwhpy6ni9+amRgbpA2+jfRW2FlouJSL76FXoWzojhS2vAAsBlHcfS3pLuM6eC2DwAFlBlo324KeN4tJLLAVLuWZkrnB9NzKqamPdRvfA/uHFNXw0vwIcfUD0twmQn3JQCJ8OaTGScJsn7kUZ6HEgNh2Y8Sl6CVhBSEoUBKBQ3HjjKmLa2hinzbFMTmjLzq2JjBAX9vbnMUntOCOaU5B12VZ+gK/dsSCWM21m/4lGZRpaZa9BuWHTQTgUwd1Y0uNCZEPT3eH0tIHSPfe9I8eP2hDrc0zydyAnLglCUt4KNzSiKC62UUYTr+LG8TQWTyRAFwWA46jZbTG8PqfGBNC8yQuT+ufjvlDLCXoO+TyzyndmCkqOsILDDmnoCqQxX5b8XZpI3SY+iqcrZk6JTgdwJPyXHYLqlueA8b4HiXmV1TanrpJIpDbH/BkOes7np37oWzPNiK8bBv+6c1Cy//BmJrLes2C1onvwRM5PyNkCMNdwsMZwhZdnYs/ymE9FNziTVxnnhupielHJJqzY4kKYK2Zc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T12-48-10Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698669963.5179405,
                        "modified": 1698669963.5179405,
                        "name": "/dev/shm/incoming/2023-10-30T12:36:02.ais",
                        "sha256": "2ff399912edf8c073fb3fa75c3abf53f615410e50185ced3adb46310416ef2df",
                        "size": 804609
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "144910ac11554c94dd98bcf276f21fe83474c123f71af831d05963fe6c31b54d",
                "size": 242207
            },
            "errors": "",
            "filename": "2023-10-30T12-48-10Z",
            "size": 242207,
            "verified_at": 1698684359
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698749731,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "VqJyC03W7iKwrXI5qtcW4QDeNjUg0nUJg8sYyq/MMVfVSPSoZfM2AiFbXBhCoTP/uu+E1E/NavjFoBtsdEVKeFNS+ukCv9y2d7rsvwB/NJciZhfBCCKGMJO0Sw4GkntoitkT1mip3+Lx85fRxP6XqOi9fbw8rOwVn3umgMcMYHN02mwtHkCCuBSjBqiy13Q33dbyASIDhqFxIrrnchNdGRQZlFxIT32TUB1zw8UBRwxV3pJqFgQ7D8ZP2q3HxNS4BBArKiVe75PjGxlEM+BJwBriN6FrSp5FKsW04vMx7XZGdmYsqfXmdRLbE99Iw1vZlr956q5BF1bauTzocmEC68ePU45qELKAvqFBFKaNVsdx2sqj9E348Grftk0ICz6yI2itKlBrbCiArKwW2Jdrl5Ry26ME+OfzfbuWPMpbq9lSLRyjqT9K+h+2j5V/lPa9u4epZj5Xc+dlIXes4EENeUmo/etNZyc3KwN4/gUUTVW+jtnGFsHOejyKPXGCWa9Y0GKRxSQz3wNJT7zup95u0kcoWKtB+T8657EgZrc+/QByaDAlZRHB7VrlQOcBjXilSlIO6vgRf6NK90C+8kjbO3suTru6+GZ/Bt2j5ZamDe2isN7TAPSZ5EydCjizbo3sl+fKmf08UnPfrFJ6b7bTqu1pCnp7e6a+Si9mXvXm8s0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-55-23Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698749599.554362,
                        "modified": 1698749599.554362,
                        "name": "/dev/shm/incoming/2023-10-31T10:43:18.ais",
                        "sha256": "2fbd972b6c51870a5cf515d0ea857b854c12a3a971802eac8099de3be1c7a9c1",
                        "size": 108068
                    }
                ],
                "mission": "EFCA1",
                "sha256": "7c1d3152572f7640df00cda1d7bb16eb696ed906431d8205dfbfb8d65afae0cf",
                "size": 22683
            },
            "errors": "",
            "filename": "2023-10-31T10-55-23Z",
            "size": 22683,
            "verified_at": 1698781851
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698670942,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "E1Yoq/pVeC4ur2WLvQe2SY2sR0sAmFoWBhpDDElnhxcftEK/6ttC6abdhS7umBrJjYhBDI6d9yyAP1Ha0HttCsfKWnzEjsPLk1+0Q0ChBUfJeRuOHIi1FJTgniQ3d7Uy8cWtXBMyvR2C+1LclzSbEddOqVO7A+1Xp/PcYTeVubMLJvJaVOmfMhG74I0lyy7BVWGI78Y6MUzm3hlzVvLm5+Q0CrS3shH2zRXogbU8YuoNB7gxfObWxfFwCpj/WzyuL3DNnhdO1cdiT8W5Eum9srxH3ESigrijbnRB+NibZBj+pUZCv9GXIFtgjOVFmtcwqm8cKkg26F3kPfxh1bS5JIhQkujJLn359Kgi1vSySmAxi5Xjp1ISydNixJHPiLJmvCN9qSffcDhN+RUVq5oyChBtRLM8BxfcKdxI4xdmEUIl//sL5xzkDj9NrEuLkx6iYBVVZgcawUJNiJT0jYX9bSvp1jY8O5Dm7VyQ4tZOLlUEe6K0LotRFFQmnPHz3lj88YPkSVFeNsHFbSXi+xt5Sgk2Evb0KQfA+guMCfOicrmklm4jajDKy1PLzWvkx4mSvdFdKz2SPh4VoHHpEujpRiF/APcSC+LRdqRBzWMh5RUwwkZpCU15QBQDRdYr2dSDSQChWlmzkvcYJZGt8gMIFAwSpILfWMjTwYuEHDVorAw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T13-00-57Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698670754.2079406,
                        "modified": 1698670754.2079406,
                        "name": "/dev/shm/incoming/2023_10_30_125834_00_00_K_146.txt",
                        "sha256": "cf989bb481e8cbd0030edaae38bf2f91851ab5531d642622a589871df9544a31",
                        "size": 789
                    },
                    {
                        "created": 1698670739.0179405,
                        "modified": 1698670739.0179405,
                        "name": "/dev/shm/incoming/2023_10_30_125834_00_00_K_146.kml",
                        "sha256": "ca33b9fbbe5effc003d2c116bc38c5e02a094d25b0cc00f0b69c7d0a0f51bf18",
                        "size": 927
                    },
                    {
                        "created": 1698670732.9779406,
                        "modified": 1698670732.9779406,
                        "name": "/dev/shm/incoming/2023_10_30_125834_00_00_K_146.jpg",
                        "sha256": "e18eb5280f6e5febfc38efb17d44e3a51fff3838a690585f5d543c838c981a6d",
                        "size": 505838
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "4f4310b2b7097d7fb87566b31a00ad3ded4770db8eb22afbab4147752e7695d9",
                "size": 493320
            },
            "errors": "",
            "filename": "2023-10-30T13-00-57Z",
            "size": 493320,
            "verified_at": 1698684496
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698671174,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "Z+nj56g3El1+wyX0GgG+iHwtUXJiumqWeNj5JmNq4zqyzh4aP/l0WTySgXmSmTpMzrKIXS8uhhcMjgHJwMd8UzkN+A+YxSRud3UlNEB3OnwNqS3BKtuOpvC54wumP8x1xgML3Om601V9iLuQXlYwlgEHtIDNicroSBlKD63b6quFl+LgL3eLiRWhxhPyymByn266ZZvr6W/h3npTFc+5aK062jUJvaFKQYoLEj/s81GQVABKGRoAyYePqTUOKCUK1D447KfWl9NnQKvWUqQZskCdmEKQsWjCT83S4Va8st2CvzO93WSBs5zRedRmwiHjf8XQ01HfQa0QDxtRZIll5iep7g7RwV50hXPjqRIM/9QlAvf/DzBEVvpfAjip/y6notMyaC8ad2MNqjldH31aLZIt6voZt3cTc4eUJcTbahefCS2c6DTBOtk+VsCeAu+iL70vB83MMg5Jpwv6rVJxnAG99qchTDI4LyxslJqWXuX0197vIzW3sMj2mTUXG1bTBfMOw8+51tnvE5x/VY/A7+jmBkN1DgKPMMPMKbrrmtJYO+MSmqM61rpICrJN9G6JWhnhS/dxU2LNqBal62BWPFqzYjihHoMAgntCRqbyJDOpdxxKiQc3Kq34nxTgF/1oFAney6eQBr6fadVRPY4GIvhJMyzm1QjZ5hrAyrXwVt0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T13-05-37Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698671011.3179407,
                        "modified": 1698671011.3179407,
                        "name": "/dev/shm/incoming/2023_10_30_130306_00_00_K_148.txt",
                        "sha256": "f50e5d50584113f3103ef013a73c0c077299584b08251d0d3ae9c770fdac8599",
                        "size": 789
                    },
                    {
                        "created": 1698671018.2479405,
                        "modified": 1698671018.2479405,
                        "name": "/dev/shm/incoming/2023_10_30_130306_00_00_K_148.kml",
                        "sha256": "f15dc0ecde7294e603ebc65a571bd4ede59cbcd20d02a58cdca70d60fb2adc39",
                        "size": 929
                    },
                    {
                        "created": 1698671013.6879406,
                        "modified": 1698671013.6879406,
                        "name": "/dev/shm/incoming/2023_10_30_130306_00_00_K_148.jpg",
                        "sha256": "f9bfaf59481d4a209f50dc1a39f8f8e8fc5024f2421100c43710cf0636a0cc4d",
                        "size": 608645
                    },
                    {
                        "created": 1698670963.0079405,
                        "modified": 1698670963.0079405,
                        "name": "/dev/shm/incoming/2023_10_30_130201_00_00_K_147.txt",
                        "sha256": "00eee19ea325197403ccda638b88a5a221caf5e3c273928fca3bf89259e68b01",
                        "size": 789
                    },
                    {
                        "created": 1698670943.6679406,
                        "modified": 1698670943.6679406,
                        "name": "/dev/shm/incoming/2023_10_30_130201_00_00_K_147.kml",
                        "sha256": "75caddeacefb4368c8043cc747756ba805a68b023db6928ae0657b324a25f3a7",
                        "size": 929
                    },
                    {
                        "created": 1698670941.7779405,
                        "modified": 1698670941.7779405,
                        "name": "/dev/shm/incoming/2023_10_30_130201_00_00_K_147.jpg",
                        "sha256": "7188cace949f79d295c189a7ad50bb8a56ecab530316600cd429c95f5a0acac6",
                        "size": 434525
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "c0822d5bbcb7e02324626a29c0715b97d1112a7986822d08acaf14b85f43cf8c",
                "size": 1020605
            },
            "errors": "",
            "filename": "2023-10-30T13-05-37Z",
            "size": 1020605,
            "verified_at": 1698684531
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698749775,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "KyoHGCFUsSeNI+r+iWbLyucbzNPWrAdya4wFf5RDbE1Etv3qFWvE9mV2QegKVIGRA9PuzDz1p5rZBn5B5ZxJAhjE9ToOXh4TNvenoTbEMgmgAjha7a9TkWtYXoVUlpHWAnNXAYFT4bLlP6duiQsxmXnE24YU4cdslaTDovC6pi3w8gwrKLDKx6vQfUOz7tDgD1Tcj0xVw0k3AJJ0Re+yPnf/5MBFy4p5bG9VP6/y/uLya1D/roKHdmJxxiz5xAkd1cn1BNav7xb1gmzIwU42zYVfnlBCxyY2Ou64XmSv6aanhBv44s8dvqswekuDOqb+nCSw8aIF6fwckFeIhCivINQfHQ/is22aQr+nRo6rP8KH08EGZH1cXP9IdvCTzEr7NKK0xo58vX/uxWGXgN+SaDdqcNzUrK1T60fUjhsCuTMh4CkVutV1wzn6cYPEpocF3RXyyKZk747EfFBXTg+Y2Nb8c/IlCw7cJzI9ooFaO7O8yn7g2/R93A/9CWZJ3OYp3Y3GPAt9VVO/ujRh3o40r9ugnbhBXyNJuPn+ioHpDO+KxYJpNL/uPOLI5uF0sJp0tlvwWkh0ACMKyf4ApOrdT7q2YGW5yGcgU8d/lbXUjg86MeF9lGgjxvLm78Oq/POuHTTNv6sBpXXBzKgifl2PupF/p2QGwmWRdGV8vnjhZ/I=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-56-11Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698749646.0897908,
                        "modified": 1698749646.0897908,
                        "name": "/dev/shm/incoming/2023-10-31T10:44:05.ais",
                        "sha256": "a0a9ce726cf682508ca93599d44462a5f71f481fc0438a1c4f26f48db2e972fe",
                        "size": 278815
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "c6ece73218d863ea1136beca5a91047c8d3415b85b98203ca4172c092c2d79cd",
                "size": 76078
            },
            "errors": "",
            "filename": "2023-10-31T10-56-11Z",
            "size": 76078,
            "verified_at": null
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698659473,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Z2d3xIseE+joomBdaLJlINI1u1nqRqnwQSaocjIeRGCDlEZqwMvcjWHj8A7hR/O92sv/hgJXgKTSBtKML1Eal2kyawWNkphDQmOdgWXL43j0eOthfH2rXINzIgAATDiSAP0wW+lfrVZqf+rzt5o0SAnXAm3CaNqYqWkXu2v58/FpqlBPfd103K2eWODQbx4w+aLnWdnCdU6ltg326ry+NPaCp/LUeExODA9S+LNsQd8PHS9Fiy0JrwXXvzvT43wB3+SsMc5wPkJuSTMMzjVcr1j4XJp2bNeotvsFuMsXKoQpHc7+gMrEfRyyaZ7Y6JvND22n+6PnFdFcVGmQEmU19evEOaeYDMJLtTAXiTxnxewgSf6e40dftoYT5Fe6PExEM7kLo+BZR/ml6+Eh+GkzuqiQwCSN/MU80Cjepzi2Wxyt3n5rYU2o6s/R2DamO8jAf8FABvOHV46/lzUpbfjjVwLpX8eF1bRXiM0UvvvOX6qmZ9Al+O23qpfjQV36wair8cI2fdyAsGfS8McDJlAwxFph4VZNK/bpk3I1j4KJqNPElHM+Ar9PjNpTauro1WyrmhAkA2Qi9oL9vs6oRv9H4S45Y21y0WtvvDcp5Py/RVmbjYD6CAgrxh3NvI0Kbm0cMyciPmR0d6pNEauCAG/QVUiZXjUkNg5r8Wn4nvioMhk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-50-46Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698659322.376036,
                        "modified": 1698659322.376036,
                        "name": "/dev/shm/incoming/2023-10-30T09:38:41.ais",
                        "sha256": "6d3a592a16288e987c152502732d9400cc148a8cce808d16d53cde043ee103bd",
                        "size": 248774
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "8f1ffc976afaf42142fc7d6ee73aa9e42663e9a000cebc25b4e22d0f295508dc",
                "size": 70765
            },
            "errors": "",
            "filename": "2023-10-30T09-50-46Z",
            "size": 70765,
            "verified_at": 1698663433
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698667756,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "ukye2JTnltANPRU9cE6p0jOkkak3Zo9Y3z5ykJT3wZxSmb3KCRIbjifkL5+c/g4K/A73zg2CyGpUXx/FbaxZgmJxmSNv5qJcNN3wZRgvQSalN7RMPc//fs0Dtxyr80rqLwQCOL3Rgj5htbNBy4Jr6jKFKQtoK07GCUwS37l1KEp0WsOqR1oBKEEYvyDfWoEHcY6OxbS+xU759oThgA3xRD/jjuqPGy6pkMPabgEutuE9feif4uYcYdOdwnjuCEGRbGo2Jw2Ucc19PWLoEShRVDYXOWBgGz8UBZILTO6D6y8Km6jcrIZUrfq4qa5MPvNtaGm2NhcWSX7ByoePez+nmHegpBP9MY/iIr9JMi5/R826iRXa2/g1dV24sE65zMvLaMfHOgAHFT3ieldU2NyBosXrmkyIswnBODe+6w3f2iS8jlxzlzi47pV/UcIRMo220N/xLFAHhArTRrFNOqoZgwt6hxsmcQOXFUm9jCyajl6GNO+FOi3f3uLFqQXqQxa7x7Sk4VQ2Ki5eKW4fnX1MgydkdGqM5J/WY+oEWBHw+BRCDCnwDUfXzCAGf8btMKNW5pahVAtfAGDEqSLOZa0V4j3g2R7xWvx6Y9U4bg1yTB0b2ZkncMukmY3frVLSrqOc5gWc5am4kfZnsal7zoSZ4FqF6h6Wl5L1J0EzJTs8h0g=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T12-07-10Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698667558.4379406,
                        "modified": 1698667558.4379406,
                        "name": "/dev/shm/incoming/2023-10-30T11:55:57.ais",
                        "sha256": "41d643c096364b0f2403d5eedbdf419f106e8f8b4a44ec33a0de54c500d3a6ee",
                        "size": 538459
                    },
                    {
                        "created": 1698667618.4779406,
                        "modified": 1698667618.4779406,
                        "name": "/dev/shm/incoming/2023-10-30T11:36:58.ts",
                        "sha256": "bddf983a68f5008dae2cb1ee9197a8099bba25c40181ed09b281ea14b18476e3",
                        "size": 471454180
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "46910f800136d16796c7db01c03efb17d6105aef512de48482eb85c018452fe3",
                "size": 429333638
            },
            "errors": "",
            "filename": "2023-10-30T12-07-10Z",
            "size": 429333638,
            "verified_at": 1698684649
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698652232,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "aBl232YH9LIDuWhazFoQC1w7OyIjEYf7edBipGXROj36X57xu/dPDJhalVjw78jziDmAOvwNOsTT9JlQzZDKL9yqno7Xw/hOPOzZeyE42bkN2ls/XzkF+Mr0nBqln/1kPEOUYmRI59fwQIGYixlz1ymUWS6O8N2b6YUPPFQNo5847FFav+vFAvU6dRdCQNU6/ozJ+cT1kytUjzJpmzV3dhP3xDKZ7q2rC/paU7zAaIRB42ik7dhLhwYoLiPG8Czl634d4IX4O3KbLaFN1aKRxOVpAw6NVOgzkqx7j96FPpVJxqU4l6uuYksfGLQ0zfYgqiwKL2oslov9IdkG4nSOKdwMTNocWN5B75HGSCbu3wwdseUU2zs7zQno7g0zv7dOyhOQdciJSIyFtQyGQC1211bd8SIGnxqcf2AgJ+IlAoz7wA7q1bq4KtmN0cpM57FZJx/Qe5luDA2Csj3tLVZomcG5u0cFeLPSuwFHXMyTzPl6XUgFxxm+MyhXhY8TfTv0w4NC36V3m+nwBC12qxeHt0ocfgxnHFLmjS9KqUTLqQwtPU7wKJthifyuQqaMAxEA/P61SnNIDAL1ifx4oBlGNkCQbBLIrnXy+987rlbiBbGk456Ym0Cj3NFmRo0h5qPpzLSDHZWwcaFjdRFWR/V7ve7xneQrxmm6T6WF5o+541k=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T07-50-03Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698652079.036036,
                        "modified": 1698652079.036036,
                        "name": "/dev/shm/incoming/2023-10-30T07:37:55.ais",
                        "sha256": "84cb16790c4803edba635b07297e5d06303766c9e773ea5f7a269de3f20d020d",
                        "size": 100441
                    },
                    {
                        "created": 1698652020.526036,
                        "modified": 1698652020.526036,
                        "name": "/dev/shm/incoming/2023_10_30_072136_00_00_V_031.jpg",
                        "sha256": "6dc4895f24cc83a0bb08d43cdda8defb8d0996eb4d612f4af0421f31a486abaa",
                        "size": 822544
                    },
                    {
                        "created": 1698652020.526036,
                        "modified": 1698652020.526036,
                        "name": "/dev/shm/incoming/2023_10_30_073435_00_00_V_035.jpg",
                        "sha256": "b903a04c65c50fb3ed658d44a8f23526f793e9a0b34ad6b0e0e5c3b0d133c11e",
                        "size": 999251
                    },
                    {
                        "created": 1698652020.526036,
                        "modified": 1698652020.526036,
                        "name": "/dev/shm/incoming/2023_10_30_072136_00_00_V_031.txt",
                        "sha256": "87adfd1fd3084adacea40d6b32cc1a16727332758f8450d10d3c45906dbfa9c4",
                        "size": 2035
                    },
                    {
                        "created": 1698652020.526036,
                        "modified": 1698652020.526036,
                        "name": "/dev/shm/incoming/2023-10-30T07:27:51.ais",
                        "sha256": "f3fd20c0b3f6384c8434490cb2e4a9a07f8321aebc613e8393f9e3d84262d7e1",
                        "size": 144642
                    },
                    {
                        "created": 1698652020.526036,
                        "modified": 1698652020.526036,
                        "name": "/dev/shm/incoming/2023_10_30_072136_00_00_V_031.kml",
                        "sha256": "059938c51dd45f9411627aac4e7c8a1a71220e9827b1c454d5b8758ed9bc8132",
                        "size": 977
                    },
                    {
                        "created": 1698652020.526036,
                        "modified": 1698652020.526036,
                        "name": "/dev/shm/incoming/2023_10_30_073358_00_00_V_034.txt",
                        "sha256": "4daf7fd48060d61ace90a469533b9a975119b37a9f2262af1f0fb3c17d5bdda3",
                        "size": 2036
                    },
                    {
                        "created": 1698652020.526036,
                        "modified": 1698652020.526036,
                        "name": "/dev/shm/incoming/2023_10_30_072058_00_00_V_027.kml",
                        "sha256": "f91455fd99d53a241a51f7dbaf3ad2e085381e2f54a04560dbbf9cf467718703",
                        "size": 977
                    },
                    {
                        "created": 1698652020.526036,
                        "modified": 1698652020.526036,
                        "name": "/dev/shm/incoming/2023_10_30_072148_00_00_V_032.jpg",
                        "sha256": "38bb4dec3ff5007827cd9bf4bc0b199b5d207db65a1d9a49164a500d24c417e6",
                        "size": 944243
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_074046_00_00_V_037.txt",
                        "sha256": "8eedb2da838c9f35c78dd4160d7ae97c43e5b5e72d1c85511164e18b09617d3e",
                        "size": 2039
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_074455_00_00_V_039.kml",
                        "sha256": "118a44910a3d86ed74a11db31b275376a1db2ab7e2db0296637ff7a5c2dbd27a",
                        "size": 978
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_074455_00_00_V_039.jpg",
                        "sha256": "2561b8622ed3e545e2a177303710dd1c24dfb1bc6e9a4b3a10358445d920c938",
                        "size": 900772
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_070410_00_00_V_026.jpg",
                        "sha256": "7e1c3282969126c173ef028b7f3391a869cec346882c1838a4a64155b64afdc5",
                        "size": 1005707
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_073435_00_00_V_035.txt",
                        "sha256": "9414939e27f5686e8e3276e61059b02e511592871fe961293f8ae563aee6480a",
                        "size": 2038
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023-10-30T06:57:42.ais",
                        "sha256": "b62dc52c95cb8d96a4f92934fe874b25c30bb856db084b09afa9d8a3ce8b2868",
                        "size": 238714
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_074205_00_00_V_038.txt",
                        "sha256": "73f322656c44846b981a4b0f07816a56990feebee16e02d8b2e3cabdf5102323",
                        "size": 2035
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_072109_00_00_V_028.txt",
                        "sha256": "3cc7d0648572a4ed1f61973a480dbd87b8fceeb921bc39a3a9cf7b88501d96ad",
                        "size": 2030
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_073435_00_00_V_035.kml",
                        "sha256": "0200a6b2d4b89fb248fbfc3dc20225a47e179812a31d974113249fc5a35302f7",
                        "size": 976
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_070305_00_00_V_025.kml",
                        "sha256": "a2ee837858fbf2aa4875590f67f263deaf8f37f12e082cf143e9b16b38c42ac6",
                        "size": 975
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023-10-30T07:07:44.ais",
                        "sha256": "f703a0e5080b98715382d685fd1d79f77288aca740d6391d705eee5f2479eaa3",
                        "size": 206888
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_072109_00_00_V_028.jpg",
                        "sha256": "89337b4c7af1357ab27e527f0d48cb3170fa45081131d32524f5baff92220f8c",
                        "size": 857656
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_074046_00_00_V_037.kml",
                        "sha256": "9b58df36d716a926724fdbc181faf000800381a103d5485e6bea671044ea77e1",
                        "size": 976
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_070305_00_00_V_025.txt",
                        "sha256": "f3eafbd157de1759ba014ed1c13bfb21a00eab813eb3e84989e4962e9dfa3261",
                        "size": 2029
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_070410_00_00_V_026.txt",
                        "sha256": "0e7cf1b046156ebfac36815291271ca4b71e3eb56b65c176dbca8b8ea006d100",
                        "size": 2032
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_072127_00_00_V_030.kml",
                        "sha256": "b6f9c69e72027f8ef510a5fae1d18fe87caadddbf2ad56e60edb52a8fb3a66e1",
                        "size": 978
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_070305_00_00_V_025.jpg",
                        "sha256": "b9c68388ccaa9a342918f269861d29d1d6ef9de20982d6c5da5e25c9e7306e8f",
                        "size": 959473
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_073539_00_00_V_036.jpg",
                        "sha256": "069fd02351f4526cc81359e3b16c161dde5b7d08057fd8dfa11d5ebf85cda998",
                        "size": 1069862
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_074205_00_00_V_038.jpg",
                        "sha256": "d642d047912e033de825654c2140e30ad5de89b19bb422648028a5e325ff08ac",
                        "size": 972188
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_074046_00_00_V_037.jpg",
                        "sha256": "86f7121e1830f10ef537d40472a45756bf299f8b28a4a262dbfbf039a1c15045",
                        "size": 1423257
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_073539_00_00_V_036.kml",
                        "sha256": "d601af3eb841548b4bd7a07b7854b2748399917ff9a989d41c87b649ebf512c4",
                        "size": 976
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023-10-30T07:17:47.ais",
                        "sha256": "ad4615330cc3089bcc1a29ae904f5a6cf623f78bf31c5576bca275cfe2e7cb01",
                        "size": 144177
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_072127_00_00_V_030.txt",
                        "sha256": "951e98dc20ea07e8632da1b2f90b0b77533d226d9e8b25e7fb8274f40f4f63ec",
                        "size": 2032
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_070410_00_00_V_026.kml",
                        "sha256": "d7b60c39d7564c374f169e119d73b9ad00e467f3f4a171681112a808725b6f0e",
                        "size": 978
                    },
                    {
                        "created": 1698652020.516036,
                        "modified": 1698652020.516036,
                        "name": "/dev/shm/incoming/2023_10_30_072109_00_00_V_028.kml",
                        "sha256": "6c74dc0bad6acbcc8465dfd002bc6fb8da9c9e14fdc2d822e07ee46b2ef8ba09",
                        "size": 977
                    },
                    {
                        "created": 1698652020.506036,
                        "modified": 1698652020.506036,
                        "name": "/dev/shm/incoming/2023-10-30T06:58:10.ts",
                        "sha256": "b3e33e18cc525841e0574369f79c07fd286c08e694ac4af15fe9eced3e45e24f",
                        "size": 26793384
                    },
                    {
                        "created": 1698652020.486036,
                        "modified": 1698652020.486036,
                        "name": "/dev/shm/incoming/2023_10_30_072058_00_00_V_027.jpg",
                        "sha256": "470fd2c9f44723673e69a4abe068e60b5a89b4e821f94c268cbc9900315b65ce",
                        "size": 1063742
                    },
                    {
                        "created": 1698652020.486036,
                        "modified": 1698652020.486036,
                        "name": "/dev/shm/incoming/2023_10_30_073539_00_00_V_036.txt",
                        "sha256": "03484b7f9e2d7bb5c82f955618aabbd7089b4829fa35d7805be5a43639f2db7a",
                        "size": 2038
                    },
                    {
                        "created": 1698652020.486036,
                        "modified": 1698652020.486036,
                        "name": "/dev/shm/incoming/2023_10_30_073358_00_00_V_034.kml",
                        "sha256": "6cedcaf6b461a15d23a1f7e08d83e46f421f8c8fae41b5e99fe92c98a92d1e50",
                        "size": 976
                    },
                    {
                        "created": 1698652020.486036,
                        "modified": 1698652020.486036,
                        "name": "/dev/shm/incoming/2023_10_30_074455_00_00_V_039.txt",
                        "sha256": "d677583820e8fd89b792d1c000619f5259913a43eedb6e00968f4fdf04791883",
                        "size": 2038
                    },
                    {
                        "created": 1698652020.486036,
                        "modified": 1698652020.486036,
                        "name": "/dev/shm/incoming/2023_10_30_072058_00_00_V_027.txt",
                        "sha256": "e81d50482bc15e6c007fd1e221f37b68dbbd9e7058bff93dd785da8cf69aed4c",
                        "size": 2035
                    },
                    {
                        "created": 1698652020.486036,
                        "modified": 1698652020.486036,
                        "name": "/dev/shm/incoming/2023_10_30_072127_00_00_V_030.jpg",
                        "sha256": "aaea246d92a40be26d45036772beb0c1d7563fbc37a5272860dd213abc33f6ad",
                        "size": 912596
                    },
                    {
                        "created": 1698652020.486036,
                        "modified": 1698652020.486036,
                        "name": "/dev/shm/incoming/2023_10_30_073358_00_00_V_034.jpg",
                        "sha256": "452ef90bfbebfaef554d0c38bf86b5024fc5f711829e5628300f4e459e19e461",
                        "size": 1003535
                    },
                    {
                        "created": 1698652020.476036,
                        "modified": 1698652020.476036,
                        "name": "/dev/shm/incoming/2023_10_30_074205_00_00_V_038.kml",
                        "sha256": "9aadabd82732c20d43ef254aea47cd6fd40d4fdee40b47b1441e5009bbaf6104",
                        "size": 977
                    },
                    {
                        "created": 1698652020.476036,
                        "modified": 1698652020.476036,
                        "name": "/dev/shm/incoming/2023_10_30_072148_00_00_V_032.kml",
                        "sha256": "3816f97fe912746c8bdd4e4fbf865e94aba7680c66dc26513cd22ec79a397f83",
                        "size": 977
                    },
                    {
                        "created": 1698652020.476036,
                        "modified": 1698652020.476036,
                        "name": "/dev/shm/incoming/2023_10_30_072148_00_00_V_032.txt",
                        "sha256": "b71fa9c7a819aefd7a59cc0a4a02a22c19e4c71c45ab165a84bf38f238ddc7ab",
                        "size": 2033
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "3f11d4a88b71b9e89d08c0dcf3e51a2e07d785807799eab04ec09426424cbd4c",
                "size": 39000101
            },
            "errors": "",
            "filename": "2023-10-30T07-50-03Z",
            "size": 39000101,
            "verified_at": 1698666203
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698671929,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "rdG5IpSTWu3MrSU0szJ22gNuxzU7QdiMSygXXdpPv7Zq8k6esbotcVvlXXtdjfv5ta9PhaQRP1mEVac3zbuODJRzomay+mLQBSakWERksFYHJxPGaqgmphQRGMary+zoU3npB0D8ornBFRqh53arcYk252djI3QyHrGV3enLQJAW76do6jloP7xGJ3HzHtS5LOqqMgxnyszwrFH4Bl3JYBl+WG/d63ICDu0/h/uXsHx4RlPXbOxiuY+ALxOq8/28PRbmsC3QFrBm9oFdqZIrkpTCvDbpdWLrMjhyrLNW7pWlN9iaocZNp5WKNUkNYEYK9+2rhZrMkfvraJiQQGyB6/SiUCMuKTKP5BGlc1MFbg3s/bSeGdJ50dUuSKkFhau+gdAEnANJw6njHRtmBy7jvW8W1xDOOJIXaNrxD/nCqdqEKen+YERIqIzsHovKXeQ1uWKiuYYJFzzs1Y9sqg/jtXq+xvfD0n2KMXjWqMalyGRfqLRY8MR0uMJilZZ5FzmS/iq8ROo/DhWu0Ej4Gl43KRv18Zzn/ehr896Td5J7p5D9uoO/3jQps2nYDpq96teSQFlzg92tZW9vjXy1GmoKZ8gB05tY6OlZgUJDAhRSxAgeiXQnawFKxZkR4hAUs+1PqkMaaFX60n98ZmaHZDOnuhRqqWD5L4mrQugHTQncrhc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T13-18-15Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698671767.5179405,
                        "modified": 1698671767.5179405,
                        "name": "/dev/shm/incoming/2023-10-30T13:06:06.ais",
                        "sha256": "d04e226a792c97bc390088821b9748fd09a116465099bb4ab391fb9e13612e20",
                        "size": 418338
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "2ebf4bcbcc1dd0754c16cbc3d076a4d99d70a328e339fdd085cf34163a027a52",
                "size": 119610
            },
            "errors": "",
            "filename": "2023-10-30T13-18-15Z",
            "size": 119610,
            "verified_at": 1698684651
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698669496,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "Z5/CaTc7n2GOQBkn9nc0HH0vDSJ99bdEZKpGssOu/vwnabTupon4Yq58q6c61WypkzeQXzmtbDgMHy5fzVHCMQEcZ8xZnmvjesnSU53tGGcW1+6uJo9v4lQwnUtHXwdXRoMIlxZpgOyR5svIq0xYLmUsjYFgNSWsVy+Z4D9gT+Hwi4FfxTei0+WrMNnOoCN6GuIybiqyJ95QxbmFNfPPQ5rHKdqih8INoJICbk6d42TAy7D3e0SXJ+9vCneBTZHoxxwMEOnSBlVLDasn3AUaZze0af7AEeDOvFhE8m+5nCAugL3pagFiyK9XrWmfLd0bUuEhG/VlwgnyhE3QwYIXLSjbVw7GE1aNGo546p9pKihX3n4LI1YwGzyJ0z3kYPtOtdXAQcKeiChKY/TpT7JCRa/alBVZN23ydJ6Sk9vNOHAstyiLRhpVGraxMAahV5S5t5uIqkJgYAl4buL9F6W+MOcaSWwDgDYMtZYlgN7Nz5SWUwEAJMisq8xM/eJKWD7lUp4OYYQat+fLHYhX45sPJwltFTuuag/Zyp1GCj+3UYdodI5RSYmFqWnOAmb3ijr4WWCfodFROZQhYcRuE00spI1mJnM6ZIgQhnYKPe1R+m31/YblG7zVIk646WaontUd4+pHnV6Xx7BXlnRF4ddAA5I1LwWgZ6EwZoWfQxgJaJI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T12-37-14Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698669362.0579405,
                        "modified": 1698669362.0579405,
                        "name": "/dev/shm/incoming/2023-10-30T12:26:01.ais",
                        "sha256": "0d3a81e95775d3788a67a6514d5a80f5d380ab1ecbb02203d4754a08bdf9c74a",
                        "size": 707216
                    },
                    {
                        "created": 1698669425.0779407,
                        "modified": 1698669425.0779407,
                        "name": "/dev/shm/incoming/2023-10-30T12:07:04.ts",
                        "sha256": "9a0f6784237b9c2178732bd18453a70c92ce0973560b1136dc6579e770047e98",
                        "size": 441585680
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "d1a7bc7a8eff3e2b3c7b04db049609ffc7a105f57e7fa572ed0e37d4026e8cbf",
                "size": 398987880
            },
            "errors": "",
            "filename": "2023-10-30T12-37-14Z",
            "size": 398987880,
            "verified_at": 1698684734
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698750093,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "M9ZueGjUMml8o8WRyFUvhl/M9WyEYc/trLYbc4YU/xR1dQqBtCZDvTZZZEom3s5SZkmH288C76TaiWWF8AtpQ/7+O1Acum6rTjy7SDYIGgBHyOsocQ5UfHt0wn1n0PCqTlSEyLMl8Gkp+4tlRnJ7KzFShz4sYTe5uA3jm6aESsGcclndf6U11XhFBhjwCTXfgZyFllwRiePWC2Eht8ssOHeyEZ1KzvgKpCorI8AXDhEZ0NzGL/SZ15f97feSLGgwl5DK4Ep7RbRwlqz3gmykiPJO2VPFYGBPfz2O18OXxoQaWe7g2Te4bRJoGqeg8Ew/Er3UoM7UghWAj0KiCqneCQ0Se2U9gEYzg1ueijeAcrFuAJVBN7VoU5kC3O4gMrMEwUf+ahftzEGFnJC6N1c/TQ3XxgU4ndTHqhIKIcGCjdPIx3BnjUO139Uwnh+6VaLUmjPpbl0lgF6fPYdYVQ0C0GkTnhr3z3BqG2pJcFqN9OhoRKDI9k504cO1h+7XE/hxEJ54NxvLPTLCMZyTxi/yptmEPe/0oCh4NNLlFMusWTFxGMpHfzl8ZCoF8XP/kVp7yKb1r+xBBXygbH7xF6BuwF9OeJr2VvndkWwWRUV0C3t4acjaTc8AtjhEaQBFENu3ruKskvk5KDNpeUE9KQvvoPz/Kydw2OhU2Rr26hvaLjM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-01-20Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698749955.2814186,
                        "modified": 1698749955.2814186,
                        "name": "/dev/shm/incoming/2023-10-31T10:49:14.ais",
                        "sha256": "adec2a297392ff72d8e79d0f14e9e4a07e9b9af078d244135898bcd70d3b451f",
                        "size": 271097
                    },
                    {
                        "created": 1698749932.0714185,
                        "modified": 1698749932.0714185,
                        "name": "/dev/shm/incoming/2023_10_31_105837_00_01_V_058.txt",
                        "sha256": "e3f532e509fb5b2a2b435af168b7783d4d691ea5f10579b8b401f9debb88f90d",
                        "size": 2042
                    },
                    {
                        "created": 1698749931.6214185,
                        "modified": 1698749931.6214185,
                        "name": "/dev/shm/incoming/2023_10_31_105837_00_01_V_058.kml",
                        "sha256": "9a5f77237c166c88d58f40c44e164caf770bad74a508d054d63bea4647e67066",
                        "size": 950
                    },
                    {
                        "created": 1698749931.3214185,
                        "modified": 1698749931.3214185,
                        "name": "/dev/shm/incoming/2023_10_31_105837_00_01_V_058.jpg",
                        "sha256": "8370d677227375d854527ee0bc59948aecca7657eefaa79693e2b0571cb50d89",
                        "size": 1935328
                    },
                    {
                        "created": 1698749930.5814185,
                        "modified": 1698749930.5814185,
                        "name": "/dev/shm/incoming/2023_10_31_105830_00_01_V_057.txt",
                        "sha256": "c161bb33eaa6480e25e88281ed60e307664344f6b489d216803781e66feeb628",
                        "size": 2042
                    },
                    {
                        "created": 1698749930.3614185,
                        "modified": 1698749930.3614185,
                        "name": "/dev/shm/incoming/2023_10_31_105830_00_01_V_057.kml",
                        "sha256": "768bf5479f81cd71e81cc9677638916aa454ec91af032c608747b08d31b2f7cc",
                        "size": 953
                    },
                    {
                        "created": 1698749930.2414186,
                        "modified": 1698749930.2414186,
                        "name": "/dev/shm/incoming/2023_10_31_105830_00_01_V_057.jpg",
                        "sha256": "706b6225bbb4f459e8cfd9474cbff15df6b5d625e34ffb1b444b6721f9e9e5ea",
                        "size": 1819792
                    }
                ],
                "mission": "OSP1",
                "sha256": "cd0ad5e0eb8fe81146fc88ab3dfb41c2f4796e8d9cf09c27d7c535a7907792bc",
                "size": 3822681
            },
            "errors": "",
            "filename": "2023-10-31T11-01-20Z",
            "size": 3822681,
            "verified_at": 1698766556
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698671374,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "ZxyrKYNySN/N3f9ZkmVyiO7d7EOvxwdGSY/PgcGB0sj3bNE5VZm0aTsu6/iM5PVnY+/JCZ0+FzUYagPrORY5GzfsjrzL8xlXAhnKnohn1esXzUOsnE9L6xwUtsNiuOKFR9zyBje+MTdlaWp1Re1PhD43d1IFdOaSxJ2POQORbZc0bg9k66x1CpIVzKo3aok2qUG+TH1FD/Yns8f6CY7hgTjiOLu28Flsa66kSTqVGujc2eSrm9ho0AbjlA/gJm8WYEVLgV3q9EsyfXMT3QjNA29WyCtAQ9HAIJM/7Ndpb3MxduLeAHpPTw5+U7Y8BzV/EPIM+I2FTRl9+62U7suHVTp+MJmrvWafGEfdb2ocYX9zt1aK4HofhKT88oyuMYSwCB/8eOeHm2f/jQQv8WTS1lsRu5PIWrWxsNo39jpObMRgEGOzXOE1YnH+8fCUbp6G6Ms1NWpk9z2nMNgMEdPnNwsWrMmghUbebaKDcl4JH4LX9qls2pHa2JpmSojw97JCgNOk14THeROarF7QH63q7SZ9MOIXRew3KnDx/OmCiFlBIRR9nuXAo6fyJcDfG+mp3OMlMWF+zi/kLQTMTXLBRZgJUoVdVp8ozizZwq7OcPB2mD6EXEp2vW+vcaA9pgAvwjSRZboOqZlyVrzlN5oKUiXRnOf5oJZfiIRwv377zBI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T13-07-22Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698671166.1779406,
                        "modified": 1698671166.1779406,
                        "name": "/dev/shm/incoming/2023-10-30T12:56:04.ais",
                        "sha256": "a54fee08ecd06246bf15b40ffa6ab608b5d0eed3d6b26080cee39d2bd0be53b3",
                        "size": 840169
                    },
                    {
                        "created": 1698671231.5979407,
                        "modified": 1698671231.5979407,
                        "name": "/dev/shm/incoming/2023-10-30T12:37:11.ts",
                        "sha256": "a14d1616536d485766e51182dcdae7cb7195a3c88978951317f6f9a64d5f98e3",
                        "size": 418901224
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "f8d2eb9f63bb3dbcaba9a2756b88f79df090a7411a651ad02666ce48629c1a1d",
                "size": 375100988
            },
            "errors": "",
            "filename": "2023-10-30T13-07-22Z",
            "size": 375100988,
            "verified_at": 1698684822
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698686607,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "wHpsExoijvFghNNGrjDZ3w3pvse2gki5teH86n+T0+mAzVSuzY8QPa0HpiK3Ku9LAeC46RFY2vLt9hBtQZ6b1MelMVLALxqpqLlzNEnnuDbDqGe2ZHLF/MEtw8S1VJUrorJfCPb58HcnFIEbSc9gUxOEY1CGHHxXKypjoemi2ZMnb1lusOjrXxkJCH1d9W9XSsxWTiq9KHapeC2sxFUt3KnrqpaIZLMGy7blsQVkP7cPculAR5NJY59DvTyyzdBY8YYqgkryEhOKHNvy5Iq76KvLjxIC7e7Qi009qG8yvv4DtIvdB8I4MBlaXP9I+NbaajjF2PPut/UKDsY00i8QJ4hKjzz3bATnysNzNgnUAPIiv0QCBSmNnLjk2QqKxPh2vJfnIWgyuCcPhR5dNaqC3s5SZ6aWDev0nxkMAFbb14DpibsNN1h5k1BHR5rrPZWCdbdOBKpJuAMSl+dnOinWASDCkql/c9cTMCHw0839sITYOORlB7mIKfLO8ssuBsTPTF92oCKpg4Eb9ecYlNmCEeR6rIBSR1AIiPeBUOGHPUANJ4RsnmArn+bMKCcYb4fTw8Df6NtW5oe71esKVsFCaRRwUiT7q1Nkra207bd51yXLksu6oXyn9Yut2ls6WY5bw12zYr3IaSJfvFzvdK0OBOR5jDrhzVdwHHaytZucwFI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T17-22-19Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698686529.4486983,
                        "modified": 1698686529.4486983,
                        "name": "/dev/shm/incoming/2023-10-30T16:52:08.ts",
                        "sha256": "a56de36154740f0cd1a782bb0a2b3ee48736b80280e7fbffa2abfbd3a7d02a14",
                        "size": 411567532
                    }
                ],
                "mission": "OSP2",
                "sha256": "6f21c6dd32e4c954d4f730ee23d5168958698066e008a9581786b96fba40fd7d",
                "size": 397564548
            },
            "errors": "",
            "filename": "2023-10-30T17-22-19Z",
            "size": 397564548,
            "verified_at": 1698754212
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698652836,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "p0OGfCdS69jVW+IzoqKjgImRtxLPljiH9XMzlnz42+PUAgjRluifuyMi1XfUyopa4U2Eqn1f07xJ3lP3sleDC4DS+AVTPqzad56z0S0Q9luKhQSBvr6Wke9Y9OtdxjLXLzTBbsmrj4zCbhg287/KI2R2XjNJAqlTQ+6yNn4dyaR84yndAw2yfvTxOcjC/+96aXGFq5UcqEkgTI5RVS13wNjFx+Q1Z8mQsuuxY8uW86tdVdx9PaOHcaj0mE1mt84O4gjG5QagL31YHVpSCnr46iHzd3aLchtxksRu86Wl3FiQphEofOq0mM/L49Ioo7ifzoRb09wopHx8ajN1NKE4SwYbJI9naLoSbCdyYlmuEhu5kis37Jsy+kdzUj0yTFDtbt3ck8Y3Jy802UwgIWh2W0kkBc8eFxXs0I7uByHx105AwWp2l2yIkHOnxEjgbVT90krWOnV9xu12o4sWhauOpI1l4u/mH5u90tLNe9dlYT80UFMofzxf3sZaIboFIfqYbCu6ofhwbNAJthXujGbawzjJjufM8PXNArdUe9uljCJ9HKCe/6Pkxz4Q5w3KTKt3z8Z8/NS3s55OdLQ47iJPmH5Bjq2dk6xCmf5STrP3B972/nY77zST/Of/+Xea+Yyi+hqd3s8iYfzqr9iG5RO+uEtm2IDk08PWn3CxONvwLVc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-00-11Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698652687.356036,
                        "modified": 1698652687.356036,
                        "name": "/dev/shm/incoming/2023-10-30T07:47:59.ais",
                        "sha256": "7082a696279bb5fdd5d666bbf4971899359e2b5538a7e1755a75819cb8d65a81",
                        "size": 119963
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "de0241e4bf02ed7ee5650eca5e7bb908b698315e33d5e4605679454353371efa",
                "size": 32760
            },
            "errors": "",
            "filename": "2023-10-30T08-00-11Z",
            "size": 32760,
            "verified_at": 1698666221
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698750102,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "hvGK2hXX6Dzfldm8wLEHnrAUbkUMFt8SeuRHZ0GcIVkJfCWctranC7JIRWL0dVzRt7ARfGhX8lMOBEl1F7wil0Z2H3w+coQNwxzK5e2U+DiBBGzCfN6BK9F+NSx8UUldJoQONyjNDQnRT83Y7j3mp0W+o7+OPfVUfa5xcJZj9MLTFZlNC+6Lz5E3WxBJ6kxccEIAayk6s0kqj0FBTah9Us0jAgfY8lfvnRfLoRUHwXuKUsQkl7R1ntvHUvHtd0QGxyuMWqOUNoRTtXfvKULiPx9RbSltj7o7+OH2I++eiBb1Z9APn55s4cr8FHKddg1EFmNEsR4iWQP6ef9Mvp0QePKm+0xo7vsVLIVx+A9OwHNZFPUo0RLshzAX34uRUnmy02kHkBuUMswn+z0F0P5qnfCrGKIwRThhlRaSTKONQQjjex1U8KRZLSyMEJfmrefVv7geX6lpjCwQBGyC5Fem53xZpOeHaEnrUHsP29N0PZkOoh/sQxE7MxfsPbHe6yhOKRme9rDngGomfnXoCmDQQNiYBYMPX0ov86GIZ2U9Ts3bVL0UPVW+nouf6chW2/eStgYVaMOcGjKQaIbP8uN6sALBL101JC3xwTGpGTbl4LsoFZl/WtFZ8p+Blc+FT6MieMGqtlZk8v74JO4ZKRXsQPEmPwWO7tVl4czshu4t9Kg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-00-36Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698749931.99126,
                        "modified": 1698749931.99126,
                        "name": "/dev/shm/incoming/2023_10_31_105812_00_00_K_135.txt",
                        "sha256": "bb33ce3be49ca3d1bccc907f02bd8abc1ff5ba4bd793edc20608f5f9c2175f01",
                        "size": 2045
                    },
                    {
                        "created": 1698749913.25126,
                        "modified": 1698749913.25126,
                        "name": "/dev/shm/incoming/2023_10_31_105812_00_00_K_135.kml",
                        "sha256": "1bdf6ec0e2513844bab02b775e8c2329ec154bc8f07421699a6783bde96ad986",
                        "size": 974
                    },
                    {
                        "created": 1698749911.70126,
                        "modified": 1698749911.70126,
                        "name": "/dev/shm/incoming/2023_10_31_105812_00_00_K_135.jpg",
                        "sha256": "a703c54be8a77cd489942df709945b0f9bef2fdb39cd41df66d832ffc4c893c2",
                        "size": 923996
                    },
                    {
                        "created": 1698749898.39126,
                        "modified": 1698749898.39126,
                        "name": "/dev/shm/incoming/2023_10_31_105754_00_00_K_134.txt",
                        "sha256": "6b5dc3b8eb077c0ae9ee025f4606b484c28937d071e826aae67cd0b527998313",
                        "size": 2043
                    },
                    {
                        "created": 1698749890.42126,
                        "modified": 1698749890.42126,
                        "name": "/dev/shm/incoming/2023_10_31_105754_00_00_K_134.kml",
                        "sha256": "3714cccbc1c09101a99a73a7f9a8f43dc4e5655a1933b2d7661745d211bed51e",
                        "size": 977
                    },
                    {
                        "created": 1698749891.22126,
                        "modified": 1698749891.22126,
                        "name": "/dev/shm/incoming/2023_10_31_105754_00_00_K_134.jpg",
                        "sha256": "613253c24c8279616d3c3bfdc5b43c97e415761c95be9dd069fb4422e38d2d38",
                        "size": 1188277
                    },
                    {
                        "created": 1698749857.11126,
                        "modified": 1698749857.11126,
                        "name": "/dev/shm/incoming/2023_10_31_105716_00_00_K_133.txt",
                        "sha256": "4873534ef869cb31d6846d776bbef340d9509edd601411593c7d58e88e6735ec",
                        "size": 2045
                    },
                    {
                        "created": 1698749858.95126,
                        "modified": 1698749858.95126,
                        "name": "/dev/shm/incoming/2023_10_31_105716_00_00_K_133.kml",
                        "sha256": "51341c5956435cbc97280666b0d0419b0b3731d6fa610f11001fc8a5ecfdf9e4",
                        "size": 977
                    },
                    {
                        "created": 1698749852.87126,
                        "modified": 1698749852.87126,
                        "name": "/dev/shm/incoming/2023_10_31_105716_00_00_K_133.jpg",
                        "sha256": "a9b981a5839e17dfadfc0387773e7e6488376783089d3793411e448c043ef761",
                        "size": 892562
                    },
                    {
                        "created": 1698749809.22126,
                        "modified": 1698749809.22126,
                        "name": "/dev/shm/incoming/2023_10_31_105621_00_00_K_132.txt",
                        "sha256": "e16eb1268623709f7d56dea4d124ff8eeeb58f8802489c29471c37d251208795",
                        "size": 2045
                    },
                    {
                        "created": 1698749816.62126,
                        "modified": 1698749816.62126,
                        "name": "/dev/shm/incoming/2023_10_31_105621_00_00_K_132.kml",
                        "sha256": "90c285da0d36be4f93af5e2c7d9d3bb8fa99e41e8f2a32ae762578c3ed8e3006",
                        "size": 976
                    },
                    {
                        "created": 1698749809.86126,
                        "modified": 1698749809.86126,
                        "name": "/dev/shm/incoming/2023_10_31_105621_00_00_K_132.jpg",
                        "sha256": "a47b0113ef07ecba592fd5cf578173cca3d799b1e5e172b33d1000ead8d16e72",
                        "size": 1069751
                    },
                    {
                        "created": 1698749794.47126,
                        "modified": 1698749794.47126,
                        "name": "/dev/shm/incoming/2023_10_31_105613_00_00_K_131.txt",
                        "sha256": "b96e3d82aa1028431c3835b4c5512839484e94e862e4590b729ad6b600d1f623",
                        "size": 2045
                    },
                    {
                        "created": 1698749803.79126,
                        "modified": 1698749803.79126,
                        "name": "/dev/shm/incoming/2023_10_31_105613_00_00_K_131.kml",
                        "sha256": "c34b5c7c0ae203b25a21f6bf2fa474b3425612a36690745567958035bf4fc1f1",
                        "size": 976
                    },
                    {
                        "created": 1698749796.10126,
                        "modified": 1698749796.10126,
                        "name": "/dev/shm/incoming/2023_10_31_105613_00_00_K_131.jpg",
                        "sha256": "21f938b7f0c8c212fd094ab7ee3ee29719cfa7ea20438048a40d57a4f86f6522",
                        "size": 1133506
                    },
                    {
                        "created": 1698749705.78126,
                        "modified": 1698749705.78126,
                        "name": "/dev/shm/incoming/2023_10_31_105435_00_00_K_130.txt",
                        "sha256": "aeea48427fd24ff248bb67af9ddf3c299c30564f00a1e6b1919511c544d98ecc",
                        "size": 2045
                    },
                    {
                        "created": 1698749708.88126,
                        "modified": 1698749708.88126,
                        "name": "/dev/shm/incoming/2023_10_31_105435_00_00_K_130.kml",
                        "sha256": "9283649d050c9c0d3220d4846883cf304b4a95e14fa223be42b23584b76971a1",
                        "size": 979
                    },
                    {
                        "created": 1698749701.30126,
                        "modified": 1698749701.30126,
                        "name": "/dev/shm/incoming/2023_10_31_105435_00_00_K_130.jpg",
                        "sha256": "131340268ea9079c397fa7b155999fa1a48a028606cd5a32f9ae4b9f5bd3c572",
                        "size": 929649
                    },
                    {
                        "created": 1698749678.89126,
                        "modified": 1698749678.89126,
                        "name": "/dev/shm/incoming/2023_10_31_105409_00_00_K_129.txt",
                        "sha256": "c31d294d0ce4bcd66c724ca6a1fc35fbffc54fe89dff0692f36d394749c64d67",
                        "size": 2045
                    },
                    {
                        "created": 1698749672.17126,
                        "modified": 1698749672.17126,
                        "name": "/dev/shm/incoming/2023_10_31_105409_00_00_K_129.kml",
                        "sha256": "66ef1c8d881770993baf9bf1b265a04417e779669d51aa30dc8fe96286ac4e5a",
                        "size": 978
                    },
                    {
                        "created": 1698749667.04126,
                        "modified": 1698749667.04126,
                        "name": "/dev/shm/incoming/2023_10_31_105409_00_00_K_129.jpg",
                        "sha256": "5f84bdbf79a78fe772f2988d6315a246b5b9fbc101865acdb6c283aebcf4d0b5",
                        "size": 740347
                    },
                    {
                        "created": 1698749622.38126,
                        "modified": 1698749622.38126,
                        "name": "/dev/shm/incoming/2023_10_31_105326_00_00_K_128.txt",
                        "sha256": "9045b600d8464805c50ebcb722bf7c2eea66f4ffec615d7e94e30e59115f0a6a",
                        "size": 2039
                    },
                    {
                        "created": 1698749631.49126,
                        "modified": 1698749631.49126,
                        "name": "/dev/shm/incoming/2023_10_31_105326_00_00_K_128.kml",
                        "sha256": "a1c0a20cc9bec16af742248b65a4117b0e20ee4cacda10edbab04d6866de2d77",
                        "size": 978
                    },
                    {
                        "created": 1698749627.11126,
                        "modified": 1698749627.11126,
                        "name": "/dev/shm/incoming/2023_10_31_105326_00_00_K_128.jpg",
                        "sha256": "abfd66b6bb0c4b788f7fee54b4267314549effc2e5fbabf607c371eb8449c4d5",
                        "size": 933336
                    },
                    {
                        "created": 1698749618.57126,
                        "modified": 1698749618.57126,
                        "name": "/dev/shm/incoming/2023_10_31_105309_00_00_K_127.txt",
                        "sha256": "3c7cf8e7db45ce2113ee3b70ffc9847f96ad28692bdf4288b0a3032c8212bb8c",
                        "size": 2041
                    },
                    {
                        "created": 1698749619.40126,
                        "modified": 1698749619.40126,
                        "name": "/dev/shm/incoming/2023_10_31_105309_00_00_K_127.kml",
                        "sha256": "5ad2b667c3ef5f5087ee3014146fcc9720247fcac48a99d3c260359f4ce2621b",
                        "size": 974
                    },
                    {
                        "created": 1698749616.08126,
                        "modified": 1698749616.08126,
                        "name": "/dev/shm/incoming/2023_10_31_105309_00_00_K_127.jpg",
                        "sha256": "73ed6ab8701e46915d7b4cc575fa2fa1ab8293ba325ae4351336c4b9cc10e520",
                        "size": 715502
                    },
                    {
                        "created": 1698749584.46126,
                        "modified": 1698749584.46126,
                        "name": "/dev/shm/incoming/2023-10-31T10:43:03.ais",
                        "sha256": "e91c2c313efdfbce18c6051f033c757ecf2f48c3a2730e843b3b1b40dfe2b7ec",
                        "size": 296217
                    },
                    {
                        "created": 1698749565.02126,
                        "modified": 1698749565.02126,
                        "name": "/dev/shm/incoming/2023_10_31_105228_00_00_K_126.txt",
                        "sha256": "f0a3ca51df22120d07feec2f6be57c7b3003bc19b10714e774d12c17de559306",
                        "size": 2043
                    },
                    {
                        "created": 1698749563.81126,
                        "modified": 1698749563.81126,
                        "name": "/dev/shm/incoming/2023_10_31_105228_00_00_K_126.kml",
                        "sha256": "f85eb5ab111f2148f608d04701ff7585be84f2c7fe4d933b130a12d74659eaba",
                        "size": 977
                    },
                    {
                        "created": 1698749578.32126,
                        "modified": 1698749578.32126,
                        "name": "/dev/shm/incoming/2023_10_31_105228_00_00_K_126.jpg",
                        "sha256": "0f61b4229cce26598b1611b6f167694e89cef49a6ab213adb6a49b8e09b5e28d",
                        "size": 1127701
                    },
                    {
                        "created": 1698749534.04126,
                        "modified": 1698749534.04126,
                        "name": "/dev/shm/incoming/2023_10_31_105150_00_00_K_125.txt",
                        "sha256": "f4cfd6812a155ca977ea4f1a016c0bbf716d69648da18fc3b7fabb9a90480bdd",
                        "size": 2036
                    },
                    {
                        "created": 1698749535.63126,
                        "modified": 1698749535.63126,
                        "name": "/dev/shm/incoming/2023_10_31_105150_00_00_K_125.kml",
                        "sha256": "2c17254446877cfd864769a503c4a951f224403c7c8f0d74f0325fcf2be9384f",
                        "size": 973
                    },
                    {
                        "created": 1698749530.18126,
                        "modified": 1698749530.18126,
                        "name": "/dev/shm/incoming/2023_10_31_105150_00_00_K_125.jpg",
                        "sha256": "4a824706e858467e52c61395ed91cb03d15a820f2c9052bdccfd786778c6ccd5",
                        "size": 1026535
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "40c350cd4a8dbdbb04ec759affd98803d4d882d36544ae38c6243efa84a4afd4",
                "size": 10753273
            },
            "errors": "",
            "filename": "2023-10-31T11-00-36Z",
            "size": 10753273,
            "verified_at": 1698768120
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698685824,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "PU3El4fMcqEMS1ncuyxipwh1z1OV0IJ5chQHvzlQoQtfpcCJIuagJUhrlLvic6iYA4jgukNFYWZ4o9gxhFSVbL1xjOAvPl9Q2uK5CB/A00UluZFqnxCp66k7r/WLycV5wGVpwvdB1PWy4XECLZtkGYuquCwrNWVp5gLgUuPessK9EBva8r63o++U8blSWPcBVNook6eb+ewvOK6hEwooNgbe5DZS+7Q9XpXQEPa/Lju4SZBa9ph1AtujwUlXWSzIyvgM2a0+KwwbQMOfLABkXr7RdBC1w/vwJQwcoHJ1MzOilTQ1jZLcIee/mgWkzmmtH71LALL+hGJIthIh1db8EmmF4egDP9gB4q/rCSiLlnxJTZlGp7PWF1YzV0HD/qhT4yyVTtjKNsnT+239d8QptIS/49d2THC9r7s9qJ7Sj/uhqIm6BnSSoeSvKII+MDfVtN0UBXxIEJXFrpnOGBu7/Dj6J9ZIw6ztdeZObxH2twfdc4t+oSW4iLQi5wE60PMxPajxHoDpyFTPquMIfHhyQzHwpeLAKsWL6xq5rUGyA5ZgUw3VIzppSNckfOQ81RwfLGiY7MICHPulcVmjWFYoWVqfTbgmZ6yiL7x/OsTf5cuj3JyI5dkC0QpkB2JRB/bIrhO4+pGLcdhow9ulWV73DPmqVcE18mX6iErKHCxc7no=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T17-10-05Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698685681.2686982,
                        "modified": 1698685681.2686982,
                        "name": "/dev/shm/incoming/2023-10-30T16:57:59.ais",
                        "sha256": "1e71e04c1f997ff96ccfc4933ddbf10921c6d607e41eef74558c83b904753e23",
                        "size": 44680
                    }
                ],
                "mission": "OSP2",
                "sha256": "a916c9abc68fbecfda214b1f373f99ddb9f384b012e155e805ed365274b83e03",
                "size": 11977
            },
            "errors": "",
            "filename": "2023-10-30T17-10-05Z",
            "size": 11977,
            "verified_at": 1698753684
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698777696,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "WVxR6G+hdsnezD8bBR8GK7WyY/UOam9dxTQRZ9EreRh78GtWmD1N15naKqGJdWdV+YS/tIRFtJs4wDyBhXUeAvfhADRWof2mxer4rJw7oZVlVsJl2G/AFvdlGquHHp2zIh8qjQ9BARkRYo+hGpLxtGZkybFWBRJoIMeDE3y6KxXngKAbSEy8pAadmCOWZ8AqF6f8/AxkCig43i9e4Gc2wniT5uDebXKjmhwPvZnDaR9bSXKL4KuicsTaonyM+RpvEl7u1GrvskktOM+P7TA78ZYGWU05dbLs40TBFkZBw7Yu8i2O1EIbqj196mjNbqLAv5vhi0VLJyOtEehdrh3yM9sy/tiCgjK6Yc2gNNjoMIvDV5PlPOHoOQh13Dy0KBCPt9pTmYTh1Wcx24FmpmxoirCyNaH01fZGILitce0Dnwe3APcb0gugpDI2KTxS4xTF6iTFQCk1jwLWwWGieAy6Ui3gEqCWx9AZyn3alupAiq0Iy5pbyRRA6TG9poNde5WYhL67wq4czeWwI5bJD024FiqRGBE/DPYXuQzUCCQ4Pp8JKqNIvPKLCcLx1bUHox/875vwcSUu68TWcYT+eTuZMOIZLA7hoT6Lw5ERdh0aKHo2W6UjnbeY0/S1AgvbRj/2azjTrqhN39nWBbUhCKvBRV34H8nLe8wdCRe06P4F5o8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T18-40-47Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698777640.8130362,
                        "modified": 1698777640.8130362,
                        "name": "/dev/shm/incoming/2023-10-31T18:18:12.ts",
                        "sha256": "7acce3f98d29ed69ac3e497d9ba80be060afea5f981ca5bdc35a70355653ee22",
                        "size": 307330932
                    }
                ],
                "mission": "OSP2",
                "sha256": "d8a8d9f6f535315770a7d4082cd23166b25d9232ed1133d7b0a7eba78ae0c50d",
                "size": 296756682
            },
            "errors": "",
            "filename": "2023-10-31T18-40-47Z",
            "size": 296756682,
            "verified_at": 1698784525
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698687196,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ulV+vAThh/2aBkG+khRKuIfA9Qxk52pPja9lOoRkH/UoGn7KDjNLeYF1+mlj3SUTpsAkENdgChfc71zsnksBBw3VNu5t4enqgdeRkaP6ROiS+E5rLY8IXATqiB8WvPHe8vughD7vufcfOelVMEtybHcOBwrsPfffkgaGOV+o/QByokpcbTSnGH8zAG5xYm9agVIE5aQVkKqjiabm1hyvR8uLeupNaOkZRTJRIHNphJDu2FhHJvrDhECaBj2Vr5CxhiMgo2sy/wsEBoAKZs5yAPqZtvIXZrHCyJZW/Ot9lvGkaGiSr8l+DUbY3SxSduPGIKJEqYVD38lLzk2G7lPH0/fBozdgXuu1HEeVod/9mOUPFztW3y7J9RVkY58aFPr/MxK10w/10ut1VE4MT26eZltht3cda+viZCUdkN96JPudE01MSKQDWVJGvqYDfe+MJaZwC9WddXCSiDpwq28LPZY0UrWV9iDnO9qXIBemqbUZRmTDEmTw/pSt16qVCiX31Yy9b/o9TmfqLF8T+GbEweJxIC0lawADsEd58fUa+uyIJtqTtmsPSHIYXnTE84Mjkvf3xdoc98CSxczRksGgMOdEcxgjzJDfX0xU91RI8dnHxRJ3eQcGHNErZA9BTn5xnXJAPakQzhMkDn6KbxSgLnQQ75IC2Y0jpqB1hYNyKHw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T17-32-58Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698687054.0886981,
                        "modified": 1698687054.0886981,
                        "name": "/dev/shm/incoming/2023_10_30_173034_00_03_V_017.txt",
                        "sha256": "d1935f22f99f326c7fa0d8c92a92ae93d9ac6357b57022106c55c77736901008",
                        "size": 783
                    },
                    {
                        "created": 1698687053.7586982,
                        "modified": 1698687053.7586982,
                        "name": "/dev/shm/incoming/2023_10_30_173034_00_03_V_017.kml",
                        "sha256": "e54c7ab818cac990a77110cf86795a4a938a5c492527cfd6355461ca91745c9f",
                        "size": 892
                    },
                    {
                        "created": 1698687053.3486981,
                        "modified": 1698687053.3486981,
                        "name": "/dev/shm/incoming/2023_10_30_173034_00_03_V_017.jpg",
                        "sha256": "c326578bdd6635ca68dbc56068f191f120ea5d66f7f27643af1a2bac223271e0",
                        "size": 892418
                    }
                ],
                "mission": "OSP2",
                "sha256": "1fbb52c7cfea74119b5692d7042cf0f79932cde7f01994bc56184ee5dcaefa9a",
                "size": 891918
            },
            "errors": "",
            "filename": "2023-10-30T17-32-58Z",
            "size": 891918,
            "verified_at": 1698753952
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698687636,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "QE27HKocVVTcY+xXNP49mXrez7iGWqt9EWdOYnmDnw9H05ZYW/rXecrppy0XlZhmIeHancQ0O9ES/v9G/wLwPPlqulEagFJxYYqdjbMnR4FMDhr50EVwu3EO5LDuCX7rb+m6d/Tinckm4hg/cMW1ZuvTDqRoVsGfsf+2By6cu2RgSBcCrs4VU+CWRX+80WX69qvOBCdDgbSTEAjNuzNrSt4RnypcMYKzIuO0sERM/kt2tVnbJSWhyBRHOOKBF4julCICAi9BdCNL5gdLQXJYQjztVU0P/i+hwILk/yktgidDzgOC9ElyaOK9DsVGuNXzoCEWiw2HAez94xZc7yGg7Cr5n4huaKXBx32U3xt2C32cLM79liWbRY+aB07Eu+TnrvSSVw/HXWYIGOS4363rZEQCTgYe2k1/VugwZy3k7wnw1B94gL2+AXyX0NRYEK53StrpLg9U11xwCoWCNbQ0T9ssgxb+cmT9tKpJGWBheMAdbdLAWLHwfpL+pDrOR80KStlMQZiDat26xt6Ix/nHJHCBnNVsfE5HEl3CF8zPNiMZEOedRVP+peJCMNXmIf8b7Z+58SoZWNhCA57p0STNXQV0tEHMwNRD47TlM4qDlJdIB0hu5GLPp5N7k57ZBQ+m1l2NMU3D21rlPudwodUInjoEm5b2ZUWw4zwtjWu4rps=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T17-40-19Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698687491.6786983,
                        "modified": 1698687491.6786983,
                        "name": "/dev/shm/incoming/2023-10-30T17:28:07.ais",
                        "sha256": "ed7e6a26627f1404e6f7c46cec89a3d58d91b59f9959560794dca2e07301d95c",
                        "size": 39619
                    }
                ],
                "mission": "OSP2",
                "sha256": "f554dea0024b7a32a487d8cc6ae71b21d1edf6e09fc0b56bdf755645698699b9",
                "size": 11301
            },
            "errors": "",
            "filename": "2023-10-30T17-40-19Z",
            "size": 11301,
            "verified_at": 1698753978
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698688239,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "DBnXigrRVA1Lc1mmnFewNgeSmrV0LIgAH/8A42WBCUaxF7yxe3bWRm3tII871PkyQRorn5G0PmmYwnSBiVokKThgXrODqt8Q5Wd1DMU73PnAkAIKTJXD9DERSpN5mr6V4uA7myuG0v7JVc/82FFpEqrOwt5QDKlq4sBUohppbmjlQdaAXq4yqWJfO3R+4GdzPoiDIR5No7thl2iTQUJ611SLBKUi1BkX/eLZkBY+iTF1ASVLEmE2vFFiacr4YCoIK9mgjU0LSfIoIjc23fRZQWNQK08A4TxCaDbWLnXpX49n2wqZD1Mmrb3olEll9HfmNKg2V0SZEVrl68GMlWYgbW3MzRqFaFGp+7AJwZWbkELVS5q1FEo8xih8FGeWPGJX8rR+QF4gXXNFkAF5OvDGZm0JVrE2rhf2Eqrn8P1J6YXHi8O3UMtvLtQGWVpW5ShLMLbEFOmETS7DsJHo2HJuqERX7ccEE+qiAmrrQfis9Sisrp/S5VcTAU2dfuwbMCMGygAGvdxovHxscu1N9T6oK9RcqCE/NxE6J7kC7rgTPloXvHU35EgtvORo7Q45oNALuSC4lk0fTDdn28qoU0yRMCoVEhzWZ77KOuUpANxJPNeLqgVRX3DVKrXZSNRDJ3tMWmY6PhmI4bGF4yuY3mGsn9iCkgF9eEatOSdF0YeKtV8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T17-50-23Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698688095.888698,
                        "modified": 1698688095.888698,
                        "name": "/dev/shm/incoming/2023-10-30T17:38:11.ais",
                        "sha256": "9a4976ea6f59132d1ed4750263eda336aaba10122bbd5faee19a438d9ae01f17",
                        "size": 39800
                    }
                ],
                "mission": "OSP2",
                "sha256": "735050861cad2b9d692c16c74fdb186f5a984964d027dccb0dc93ebb3614b561",
                "size": 10915
            },
            "errors": "",
            "filename": "2023-10-30T17-50-23Z",
            "size": 10915,
            "verified_at": 1698753989
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698688409,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "g+5MP+ksG+xAhVXVe12RE0nJp7DrseT5KHml0IfRe4STpGEztUruuzWcetiGgmJhgrqdoWaALFJKlTUzuJfa9oF6esOBXJsaaJSpeBc5X7DkuqZ95BeN7RM/yUwCKEDd6e6bzr6BGgv1NWPCRRtqz3feTgBX/qN6yACX+Euc0IFTpb3WjF0W+r7dPHP2802JLMangcxROmaiv2kEDylAFs9ksduzkUxS6A19vCsdSAXcqlkE3lSswCX12IR+hQc6RLQJIWtmf63SntJ2PEvJkdUOmOiIJu4svtaF1S0pLWL9NYDr0rJVHBvR78Tiz6YD+P2DsYcDtr7gz3JyA29ypzpiVT072X3AJL3SLsWY6F7SMBWYP7hnkzxAK8ZyWuN/u+znqtR1WOIT7+KDIZbvxEleiKOSKoqS4jTsA4lDFFlgDkdrnrjQiVS+dDv91yaK5NneCkMGN7+pDQFmP1fDaN6Z5Qc933MA5NhJDa8b0Y3t4Fb+XiSW0Dw5g1NZ+lydN1nkv1SMG2uUdhOXK7zHQbION4xdwpX0fwObcRjGjowxoZzmftSHseHosa7e6SS+QzVO0UmwNjlOYR5JL8A1dgjaPR4T1FOdDf179mt26JhNAXhhorkS/I3q2RQ5KHx/3LIsvEbp8xKrebaRS11oz0YpV1Q9ua2ttyTNsmhYPu4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T17-52-27Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698688335.9286983,
                        "modified": 1698688335.9286983,
                        "name": "/dev/shm/incoming/2023-10-30T17:22:15.ts",
                        "sha256": "3371587f34ac064d14da4590327d209fb3329a6aafb25cfdf09a59a94b03d729",
                        "size": 411109940
                    }
                ],
                "mission": "OSP2",
                "sha256": "6302dee81c42f66398dd703ccd6edede99e7b0fc30e782917fb320cef294a7b0",
                "size": 396959243
            },
            "errors": "",
            "filename": "2023-10-30T17-52-27Z",
            "size": 396959243,
            "verified_at": 1698754503
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698664112,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "wZoorRU1QRwxdj8KoHDfJE4C8JwoszZT/dQ18umlwx8ax2bMBU8fUwMjKT0Toj3xFPMMUfwiDXL6OOzjDQjbdn+fFXFJBCtWA/u9aI8CKgoLV0fX40efoZ342aCdfxN0Abmp9dnfiVmL3RzkNj7TS+il7wj6o7RwPFLl9G3GeRvVLAfNxLqSmmJZJUYlO3lK1MIr1OXcXhWYnnB1W8cSLIQN50fyH+hrQJVgK0rJqnJQHQe9+wvKwHyUZInAIO0uxjScFUdRINRo0Dy2YQhgn4QPKHYIdm4IqawEL4WZ9Wj745P6mKvvh4j1Ac/MfNLePTgRSpVBECLtII5Rf+3lawAnqZUCibFJRs3/3qX3rEpavGM7n4gFMoO8NPjtw9j4qSt8XaIpmk1NAZMT2PD/QNjJNwzGHGlheuN6MYgAeo62KeIMGTD+VfrJF4hH55tbAEZPgaFW9tftfPu6jMWRgkWDaiqEVDQ1qLvROTaYhGvHm4BUSVORlA5fTXc2Jkd5VqiwaadwClQphTMzW6gRAZ8jMBwEBu3eYVHDEA/i/Y3t22XRXtWkOSa8byUTTNQZh+tuZbHVwk8eHXScrLlplYMI7rRbReCv5ATlol+O4AAKgz56JmjPvrgjCwpe8RcXxoj/mmVsNkiX3QcBY/z1iHSekzQybX7lfABEzmpqxRQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T11-06-56Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698663949.7879405,
                        "modified": 1698663949.7879405,
                        "name": "/dev/shm/incoming/2023-10-30T10:55:47.ais",
                        "sha256": "847a64039ecb237da14238618b5b97265bda917abb3726fc8b7b63ed85bb6e1d",
                        "size": 343430
                    },
                    {
                        "created": 1698664006.3179407,
                        "modified": 1698664006.3179407,
                        "name": "/dev/shm/incoming/2023-10-30T10:36:45.ts",
                        "sha256": "2a7e32f9285bccf1f1418a720a8274fca3d2d7344a74e869c10ca2ba0f4120e7",
                        "size": 436227492
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "12225ff4e33c8843e4102b142869039e7e4d72cdbab805078f2f107cedebbaa8",
                "size": 394065867
            },
            "errors": "",
            "filename": "2023-10-30T11-06-56Z",
            "size": 394065867,
            "verified_at": 1698684209
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698644588,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "WT+6lV7t7WsXQT9ceBU1KSL6XeTywbJqhy07TqlXtMImwQzRS7PzNQaIKbeaLv7q/OqiLi5122tBBd71tN90dypf8zOdrkMR19om/ajIRrViqD8keW5Ew/oarDBx6gf3nPqqcL/Z6zM7DMnBCwmx3qhs3pgopLrBG3vZOPfZBRaUFKRhOfEnubewuoiVa8YEmCCE6hKEdfx2Opx4qU59URfpFP9CYL2EoyfkXeK0Nk+1g+qlPZLi8mh3qnw7rXJfqymCtcgP6Dg83uVxDfl0FyjWgAUPhpKa1mM5RY0H11ktphvjhEod8M9o/NBl2TTpHyfK04hH1DW1D4gLKEXIvTLsU7iAggtBf7hXJAjn5kzadgaUaWc2GvslFkB8nD7pWOIaDo6vlQash4gkH4avGCKdR2bK9eqZoGq7mIIbIRSwR5jtcBs6KcmWToLXibNL1hyVH1lNhazR0ZpwTl14nUP4Tcjry/83hpAVDYUAAHhBKsRX8uXPF7Q/+40v/sTteCp087CcKIXryzzse5JmrdxfFWPSq+l0DrBW2XJVTqxj06QxJDQI9G3TUl1NBeUaMuapRkWdMXcxLWSOGIXK5z59enehT+VIo2BnNJeEx2L24sUjL9i5/N74PqhXCltjddievs4wtsmVtXPgb6kNjtxhiw7s0gHtd7XqmJT5mqk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T05-42-37Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698644430.696036,
                        "modified": 1698644430.696036,
                        "name": "/dev/shm/incoming/2023-10-30T05:35:39.ts",
                        "sha256": "50e31cc6d17e13af52e1bae76cb0e7943dec673bf761a5d2ef233cad741c222e",
                        "size": 64525736
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "5ba9d1a3926463adfc2d4fdde3de7916e116737e80360d54853afa3d4c910b32",
                "size": 62268248
            },
            "errors": "",
            "filename": "2023-10-30T05-42-37Z",
            "size": 62268248,
            "verified_at": 1698665151
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698645593,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ljCSbcQHiDbjQIBC9j9jYWH14z48FpsmRoPgBi40jDknjF4YbF4aaEJTmxtvYi7QwryN8cx+v81Fvi/zBQYSo+RFDdQ2SfYQKYQNCG8RhURRunHJa9HGFLYktxOkSNPuIDuUIclYsXf4eQhs3/OAB+TsGkKcmi+9WN/uG+1Jt+5Wk0T5mtZ71BGPdCaOZrPpDgRtgGXzIsX9tRGABK6SfxEv3fZmI++LTEfFMHeyNuJE/eXTogNnvtbxAa0B3i6pWkJLB+YP8TPR3LY5zFjc9cnHAQxOalljfkgYtZzzf2HxrCcneaMAyPEaU0YVOWF5YwS/Ai9oQ7pgLUtP6/pP03QbUY7K24L7ttVqj7pFhoLZuuScptalF/sKnEJn8RAYHDvW0wFZM19KOseKC+mL+O33444XQSSp9xNFVAKWRq5oL+/XrQS/z+T78HMQhuhHKIyRrJ7dSvj60I6qnOYdcyCPrO5wc402+DguyOXawXSSzIsh0sfB6sGuBMXN1NZH2E1rohhbVLZjTT+2kkb3JosNiDCJajqGVHjQbluYPvA3psU95lJOtDTHppdzBjluBvF0AQGwzaz6+nC6Use6Mk0CRapanzHsFwgj9Rnfid0UF/S+byot8fLH7cBQ7U4BnT5+RP5kTU/nq5lNW9naPpw1vev+SsVWaPeCswbFLJA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T05-59-27Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698645438.676036,
                        "modified": 1698645438.676036,
                        "name": "/dev/shm/incoming/2023-10-30T05:47:17.ais",
                        "sha256": "0e10b4644435023c982b19514034a642ef02425c3a883ec2a30235d1789dc139",
                        "size": 310289
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "39e0d3e196ff6aef8f630136dd86277ccdb27b25c486df472026ef10a7b0cec1",
                "size": 86894
            },
            "errors": "",
            "filename": "2023-10-30T05-59-27Z",
            "size": 86894,
            "verified_at": 1698665174
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698688841,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "qysMcmVI3hVqfZlxltcBAXECisaxtB375fV+qdLAByfa8AlkAC14YYJ9PNDGgfjtB0GbJP0v/bEBPTa/icuI4xFN3sh2d96324bBHnpz3WnBH6p18BRgbxG04tumUk63VUx4DtpFjZ/qc0sFYjBepgNzO4OGROivYT0M9Z+VNYPJOUDSQYPT9V99LpxAhDfF+Kialk5oLrr1TQAVetGMdcBxlPxjugyjh/6uKh5hEUVFtEeLqnZ4eDRgu5XWDEqU74sHalTXkX7vgj4vx+WdN7fgjk6LFB0bFCOATeD3DRrmL8S/C1d/OrvtgaxZn68mpLvmgr1u6nCcan7w8bsUXpXVp9IEtTD6GHkSXRA0w4R9No8Z9Lg7XWx6XAINXvf8kj3Y6vJHnMAThjVUC9NBePVQ9QNDWCjpp8cM5iTNSR9EUIqG2+a5NZl+BTE/le/j3CzZUo9jnKJ7NAH2n5daBQ1WDDUDGYE8NDxMUc1zr1Qwgoiu+3VCsbnmZ/kRiOMkFSQaqqPdake/eNILpAam4hRQ/AwxoYZ3RnZ3gBGeaRO4eix/mvt/Pe8skIc/mrO9/Hz/uz5dZwkSnxFWiNnN98NAA6XU2vxNXEGcc26h/6jW7O2FjWd1qSZu21MhbYy9srs46UJGCh8vSA44/UbXR/o+LB+IHygYVHJ5vG0puSE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T18-00-22Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698688696.6886983,
                        "modified": 1698688696.6886983,
                        "name": "/dev/shm/incoming/2023-10-30T17:48:15.ais",
                        "sha256": "4781d289b0c1700ab28e911f2a222a0fcd607e79ab7d3e4f3916c4b3c2c8890a",
                        "size": 40854
                    }
                ],
                "mission": "OSP2",
                "sha256": "55d6be481c424ed6c8150a54711338d039f7cc25734e00c43eb1254079fce718",
                "size": 11305
            },
            "errors": "",
            "filename": "2023-10-30T18-00-22Z",
            "size": 11305,
            "verified_at": 1698754081
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698690222,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "wYF2pzHq0juS+ikgY6VdfJlHtIRuKx5dop9JOz4nXqLHCLTyeXkY4o+6W6nwAoIwKpZr+jkGLjG/ZWXtGUQp17OIAlnfthsmw1duWCWc18F5wzivRK6avpgWYggbtZ8knK00uycd7ZzKp5QVTS4Tl7GnytTiQE2NEJzeame4MdM6c4nm4juXogTM6hWxoNwxcw8QtZbI+5WiFjmgxhCrM35G6OLMqfSM2EKS1g3afgGf/jPOYKtr99tPZtSeWRDqfqzFcg2XneNP8Uhjih4VI6zQ9syPndExAfE2eWdHkq2uXedvkDyC6WBuN2M3aqfE1cov9b8/wiaTzT6qrWlA0S+JlQRkrgvRSlydNQhLGruux9I6sDlp5UIyRWvNIfKEW0D+E+3TDw7gTJyJupFxPV6OfNvl08EB8V60infbCZHd9bBt6qkR3zfrIaC+1+XOv2Ddrh3Xp4jL782hk+WXxXt40W/rRWXgvl0dXVEOJ52e3ZzJkYh4pc2hrHD8HkNmrbcrus6kuanL7RdznJ2PwtE8xh11FrZQovq1k0rSKe1baTnfAzypev18sWa17uoWlLY9cpMqYiVyUqfBUDBRlJ9h9jG9v8ZABN3AMktGCpRC/aJrD8P4Xzp86xv2MBAobnYwUqONjV6NkNUUuI+k0fPjMrK+lsz8lxumMiJw7s0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T18-22-34Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698690142.5786982,
                        "modified": 1698690142.5786982,
                        "name": "/dev/shm/incoming/2023-10-30T17:52:22.ts",
                        "sha256": "b6f3b8293ea73275e03722ae86e53360a39957b010465f91fb37b39d221a4666",
                        "size": 411661720
                    }
                ],
                "mission": "OSP2",
                "sha256": "57b03433c6a9cf0322a26b856132b8ec2b765225957b2e2f47dcca4c441fc62f",
                "size": 397747072
            },
            "errors": "",
            "filename": "2023-10-30T18-22-34Z",
            "size": 397747072,
            "verified_at": 1698754573
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698689445,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "YnNpvHZxdv+sBvdhAPLPg4Yv8Z1ZLDOA8orPn8dur6p2im64m1fPvK80ZQo+stMe4WJl8+EV5HRoa5NWAAERTpxWuLAGdy1fcJ/jTJkolmsK7ysCifreE6qV0e1lpPZ6OVOy++MWY9ctFYgRKUCues6Ux0//Pn3j4GlZKG7BVxqAvSVaMFz7hmIhAmt46GSVijh3Pv5Tg3mAoGPxPjKDI2XbRvLMk7Y9DJAp4MPlcMHXFdrQ229Y5S4xcrOGJBMec5T0bXMCN+/1wdGY6XwC+JOoys+K7N31adsL6bMd7th6v9IdPo7pfpWHkoqNH5NFOdhb9+7SiJ86LUrZrqIb6g2hsZdcQA5YooctOsssPNc+btH4kHO2s5sM4XhXfPtnfRQpmwwoUEzq7IeGQ7DMXvesD0Ik3PktcbxY4RvTWkj0j29zMz0NCr+NO4hEVqTVXMj+I5nZX4aBNW6054lUSTMa8o1yzYE2wkX07GBv81ZhN+Yk+Z99JhCkWomLQNEU6PVM1Cea3scfVxH7iQPfq/rHyQxjFyzbBue7ezGnl2n6EPCJzqr1H5mzWrtKG32/uG9Wl1Sl4bmm3vCyEyVhCeBKSX4VVjF5tqsoRoe5FmsOm8qDSAIuD4H1z6fc7We2NUH3bERJm4g4CbkmvReh84PqmlnW9aMSsI50x4n8RcU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T18-10-26Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698689298.4186983,
                        "modified": 1698689298.4186983,
                        "name": "/dev/shm/incoming/2023-10-30T17:58:16.ais",
                        "sha256": "8a3c65d2ddbea2267ea0324479d69222e2a943689dfce6a7dc5ae0ca611c59b2",
                        "size": 38477
                    }
                ],
                "mission": "OSP2",
                "sha256": "098e79012a154655cbdb0b785011a378f373c8dfb0318078b67f6737386513b2",
                "size": 10705
            },
            "errors": "",
            "filename": "2023-10-30T18-10-26Z",
            "size": 10705,
            "verified_at": 1698754132
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698690769,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "uSlI1r+NPlKtJBfPu99s1cbBXj4Ti1v4zH4Pr33ioZ2Zc5hJE72TmdzkXAbqxsKPy/SOQHFvyLtVUF9UQs98VyNyfjNx32V62ZQXAXGTMaoho4J91a375jgj24LLT3fYpaneAr04tQWBkKsxgqGMI540X5OhLW+mJC3POxMcGkPovW19iqx1DuZ3O6fKBkGw+rtYJcuX5G+7JLHlG5s7ypCn7fG6uNcpgOlwBRzpxFsLKC5neO15MbgKLC29fOfotXtxhIdpFY7u3FpmeXkokj/TGVkqik/56KNRKZovmIfljIAyhR9IJ3zl99wmxbg0+jT+5RDHdsGyzTnuj7MjWlyEsaYvvQdKwPVPDQKui2zwhWW3dO/Xjom6jeBaqRvbaZS/0uToZxfu1fMmMgmSg9q3DfDonS1R+WbBcsKdSwm2z2WvsyxK+8yOt9w4zvfF0vF47YiDIj5xTEN4fvWvC38qB2Ksxaq4dfh8hotk9+4PeKr/VSyddMp6u5iYf7Fh0M82NQhD9WG5zZhFM24VN7onyjV40SVtfApdKGsT2HDXjfy036323OqcAPcpg3Sc34u5suUciiRXpsE7CBLVYbZ+oqM7OTgyXViZNKbtLKwF1PiMq1mfop8wz8EfxRlyuPCbtrWPAJA01XJULMhnxYdy9B6gfHzVhdH6Y6GPPwE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T18-32-30Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698690624.1686983,
                        "modified": 1698690624.1686983,
                        "name": "/dev/shm/incoming/2023_10_30_183003_00_03_V_018.txt",
                        "sha256": "448c35c48207e9031d94b9f7f243b8da2f7b9b085912ed25dd0968fb966fed9e",
                        "size": 2022
                    },
                    {
                        "created": 1698690624.0186982,
                        "modified": 1698690624.0186982,
                        "name": "/dev/shm/incoming/2023_10_30_183003_00_03_V_018.kml",
                        "sha256": "19c97b6a023e354a9644a2f262a73051efab34627098b5a9e9c9c5a0486a1497",
                        "size": 974
                    },
                    {
                        "created": 1698690623.128698,
                        "modified": 1698690623.128698,
                        "name": "/dev/shm/incoming/2023_10_30_183003_00_03_V_018.jpg",
                        "sha256": "54869122622bcd590c1f30e1503a5b5e8636d7b0eb67d303a63cc46cd415b873",
                        "size": 1039732
                    },
                    {
                        "created": 1698690502.2986982,
                        "modified": 1698690502.2986982,
                        "name": "/dev/shm/incoming/2023-10-30T18:18:20.ais",
                        "sha256": "cfffba35c3ad1d2047940b1e9ef590cfeb27df2a1526ebee32b103838a2218c7",
                        "size": 48509
                    }
                ],
                "mission": "OSP2",
                "sha256": "9302aa424774af086ff8cfdda47efbd6cb2d84ee8aa51c323ddf3e5be387a475",
                "size": 1052231
            },
            "errors": "",
            "filename": "2023-10-30T18-32-30Z",
            "size": 1052231,
            "verified_at": 1698754195
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698691247,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Uk6JdPluNwAWFuyEVQFb8QAXCGS9FICVqaYZ5V9EGPrQmHt4H/1+iW6SyAv0ui3TenKCXkkIwSyvbS/nMHCDoDEA+FU3XGNRX3hMmn0z3+KpGFCjvSbTyZ3fFeFLBKcjb/q6EScueTB7h65/q53QB0Wd+KNMaGex57/Mvi2oa5Uj9yw/iC9Jsg1YLMv9DTNphkUCNaVyYgI4yhtJIKkVuVmGrdBwq5hHWt1btJKiaCfjeu/nI/c7kzc5KhbkZ/Ft6pOfe72u66/TZEcU1RlxlDCFsircuf24nZHlr3Gg2RQLqesy0H64lf8xf08H8Ry4TmzTyglcld9rY30PBV481Bb0tB9NxcBdEPyGzChyQ4leRN2nNCE1Aya56hfdUZQ8dy/m17CI2QT4YhbI/VNs/irJgVz06friKhxVjYrkriWmA3xSkfz46K7l5OxqKYri91JCYher6q8EqrZZ0BOz55Bx1eFMlrtDnR1y/CoHTZRIbeOzkHcAP8s0O7RW0g99bKYjAneBBDdte0zseZk4cYjYmesVMRowiMtjHMtuqTA+SDA22Z5I/DAFCMxE+I8CH6WgbsusgbhHF2g2afF/LGTn9gxp67ImYTpyLZPhxdJE2Kxy8fRvwjMSOBS/b47agS91Ckr7KdHeEdFRqO2+yc5GpixWAjnIRLHhbEVFfnE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T18-40-28Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698691104.0386982,
                        "modified": 1698691104.0386982,
                        "name": "/dev/shm/incoming/2023-10-30T18:28:22.ais",
                        "sha256": "17fe06af80bf821f7e360f80be0d7648667f3a80c0979133b76a77d9b07e5543",
                        "size": 54560
                    }
                ],
                "mission": "OSP2",
                "sha256": "c3ffae5555e3e2e99ca9feaf86bc5ec84056c08e5433079d07008dc6bf6b3760",
                "size": 12565
            },
            "errors": "",
            "filename": "2023-10-30T18-40-28Z",
            "size": 12565,
            "verified_at": 1698754242
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698779064,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ktwfa2312+MlWqVqcxH4e9ThH6tHx/yq6DH/lRvhR6N4XmlhnO1O+U2DVqIphlLMFwFdfi6uiUdjzZo2BloGa4ua5NTIhIqDp7xKDmurZqH7eU2MeXS2u9BeUxh+yTIEuIeGRhnWRuC/84ElsuWnPS1YA53hWJ5IDj6ssxNmYQ52vi9ZuvuJeM8fq1cUVucMNArtS3iBS8RTDdFtP94bXcTB19XyaGKSa1FSf7WPADqaS7bD77WzbJ7tfbSaDRld3aKSRrAGSU+GPFWhhUdPJTv0hVvvHh9hDwbkA1h2nMfA5n+Fx4eqgxMm1Mhezy58toWwiiJPnSjuw3AMU6hVSvUb9dHfBRohwJxpBtvwi/aYT5tQNXXrcFLPH4K7TRp9Fh3F67aqKADKwvfYICVqo3+nqVQBVfDOOkB1OujKXCNlT3pBLsIfAte4ytlrDvZl8w6XxPyufV6wkeO56fAVQBxhy4lOqZC+3fPXResca1paAIraO0mwR2GtFqP/3CbvnWifUp3sPlO8Zy45vnm/kzUXyq+16gqurppuTcWDi9zaW15ifhYBHDvvP0+HV27qEBo2jr5Ny6IxCbXmXrMYXPVtG8J0igqKWKkiakJJHvqgTnB7QAUkBBF1oQMpvGrbhIwwiyyinUJcAmv65igXIVTOZD0QIJdkfFrgEVeREpc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T19-04-09Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698778922.693036,
                        "modified": 1698778922.693036,
                        "name": "/dev/shm/incoming/2023-10-31T18:51:59.ais",
                        "sha256": "478161bd39604cf7a1e214525f454a53d12d2642403cc92683a7e4bb15ba30ab",
                        "size": 365600
                    }
                ],
                "mission": "OSP2",
                "sha256": "dc86aa5e5dd78df319b1739f8427295fa9193c449080b07603dddcaeb9ba14d9",
                "size": 95934
            },
            "errors": "",
            "filename": "2023-10-31T19-04-09Z",
            "size": 95934,
            "verified_at": 1698784459
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698658844,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "flabo7dmaUy1jtLS6ziqKGDm0xvNrGEt0MdmzziMx0slCPytveuEGt9jDtK77RzqmxazPwvQ1IpQS5intkIDtRKqyztFmyGQf+ICQu/ZnQGgTBQ+tcO+J86iUN6cP24tD1Fs5wDrZJ0J8NnIQjUdseGtB3F4lizdSC6kd33cJq+4sOhfOFz6/n/3CukFH0MLIHCIWj1x6hYKhQiPKnTcSOknd7cX09OEkETD17yUAxCQ2SneMzPv/8ylQXzTUu+OxYjcaprQwdNJ69+ZXVgorxucEJKwXpOPPi3Xj9HC+mCbFQRBvQNRap0SEHv+w7AE2raWU8iz26XgCw4a7dqvV6QCSSmRVjA/KUujaQwcECqCICSj5WhWP+xDNWT2SLqAlMmlmwLnESVqi3bVolw5Q7Upyi/rgCJxJxJ27ZSeQnOHxzzgXFJKKHy/vYRYbkc610QJotAeAk6hIwYpv04xzTjsU3CUQMj4jrfy4Rf1OsLCOsFmfJFcCSEhJNtYV9hZwh31Txqt/s4pVR1VxEdCwGYSFSkz1qb+CSsr0r9b9uHzlol2gH/NSD7Smt5Stew1zl4vgIH6OFpxnddx+moPPZQvKaTFfBrmv1v+DurV26cjfDNaLq5qUqvTQVtXo8zXfqppkmSTV25cf11AQwA3fB4esR6RtHfh+tOGa2Xhio4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-40-20Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698658694.7278287,
                        "modified": 1698658694.7278287,
                        "name": "/dev/shm/incoming/2023-10-30T09:28:13.ais",
                        "sha256": "c4392a9b9510fc89987fd5ea7187f47f6a05df17fd1830996f827a5e18d354d8",
                        "size": 651568
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "faaa9d2e4ad9efeb3d583a07efe19ba3b4e3d8cc5308d3ffc678bed5e6029729",
                "size": 191157
            },
            "errors": "",
            "filename": "2023-10-30T09-40-20Z",
            "size": 191157,
            "verified_at": 1698692906
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698661389,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "bGNRQucxArv6iKYI7JR3nPrPjprq9TvGZBTAXEmjMuBoHAtArny65enEszJUEsQ3G2nulDr1h5fvyF1T4yemd16dczyRVdBi7VuvVUNxAnxIZPoDUlIy/zRfS1ZPcCVUBKxiiP4zom+pI/M4AaO6svAziNdoDe/riyr+4BRkloMTqxewkjbxchR4EzHqOIz9vKBrprAUw16lKXp3f7Gjr8i9aX2tuKVLzniEURMuPydmJSc2HKt0QZuQcNvG5/0kETDmbMmVPqGh9j5HLhgW3cES7ch/V31anMqFd+cJPDnpVGTyTzLb2XYVxukYbqHpalQiYTRX4alq1bhmrBZhpzdhuzvXNMH+pkTOpgoI6DWlIxKy/HkcieKpIy2FCwaZm+Z5hxitI/7A4s6DCCoaZ4OnaoBDBPF9tSitT2clHSr/KuF27+iBZxoyqjQQkLDFxu9E8KzHHrGknlgWILxlD47k5FblUPKftv5af6Bsk70Dkol8le1JL8PvEjS2Sl2yTebLyCTZ01ItqFjoleSvFz62sqUqU51MF+bI3FOp7iqc9LaTpx7vr1JkPj4lpnnZHHyPIubCPRtY7H50F5bQjzuuKiIIGK96l3Gn3+HrI3TPYD5/PRrsVa1efTC3ISv0OknNy6CKiP3Ap5nDa9GMzAtREcfkwceRXEcJwNEbdL8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-21-44Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698661297.547829,
                        "modified": 1698661297.547829,
                        "name": "/dev/shm/incoming/2023-10-30T09:51:37.ts",
                        "sha256": "b67c0ca9eaf694297761fe8cd7173d43e760cea7859597483e86f538f8732eab",
                        "size": 426903256
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "3cbd6543c6b8640be60a4a01ee45906ec90da570a1ac6cff9cceebd8c9cbe193",
                "size": 400238365
            },
            "errors": "",
            "filename": "2023-10-30T10-21-44Z",
            "size": 400238365,
            "verified_at": 1698692934
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698657036,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "MwfpTpqB7C9sVWkRPpbv8oaKr87gJtlsaB9usRMlC10cfmLOj595nV5O/EdH495hRHkQ75jUxy4Emh/KrM2OUnqdYQ0xlnq/c6V6BsoCGr1ii4qZnRG/bXy+6/38tjTov4Hrq4Fo3A40YrTzZ+93CkUUg+dvg+S8uYqHW9buJDJUIfiUbVatRezunosWIAlqqy+jwBc9zqiKST7MvUhtGFuDq2EVz0nk54YqX3QgAgL8uYXJbLqJD7XGNPuA2NI7sLHNT87v1egH1dXCDdsafhQhF6j7V3Cz68NC6JKWLvk2pIWn7rRSKIYRAX6SWVHBoOdw07svtR27rjhg70n/4Ts9oRTl/JqGc3tgWBMhcx8+GaJdRV5oqLelMcAUnOJT6t6oSgxB+oInjjO0+d+7rTJyi3z1VzDWi+jcJMksw/UQGohdZmk/X7Ah78wQyiQLDkYTedex/nnLW7iWiuc4D2WD6KRcdKh8vxsHrUOCPgMQUIWy54MH4gsNf2JUbrBb1ktKxBEIaawhvdvdyWWM8x02LB5jZxoIQJB9WH6Ym4QlNNKQ20aMaHQC0FjZBVE1g1kTcw8fPGaIixTWA0nVSTsIfjuxw5zfBzc9mX4lA1DimIlEQ16E90Qc+qkW2rLhCBSbo+yf/w5tYlIRxIIJS47VKeBilggx+0N1J2zGaRE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-10-15Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698656891.547829,
                        "modified": 1698656891.547829,
                        "name": "/dev/shm/incoming/2023-10-30T08:58:10.ais",
                        "sha256": "4f46a8963eb27eae00f0f70a7809d0f6b4ef655eb29c65f62ecb2b14bf15ee8e",
                        "size": 673369
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "b6b0d3dbedf6c07187d6aa8293d36b21e96ab7dac0b6f4e1968d2858126addfd",
                "size": 199283
            },
            "errors": "",
            "filename": "2023-10-30T09-10-15Z",
            "size": 199283,
            "verified_at": 1698693145
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698676960,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "NjCSsnD15SqlfUAwNM3WS/hdYrwZzTTeeTUGqrUzTtocmke6kO1MYleWioLh9cT1kaz5qSFiSVrqHiE7etN0EBy8js7gAWOhUi4qLfC+ZjwJfPJjsURKn16vGQeQdkNxZmXUnZPILXLEycLU/I/JWowAWzwAmynUH4KYf0ai2zjLt7cPlLlVDw5poSdGiimIqgoFjMhuwG4hbDM68GjgTw69hVizD9bzZ33I37SzZ6VcdRjJsxfdZuFh9e6BPcImFMq9xSueuzsO8VtjA0CRMoqPYSBGfUCBn02fez9TVNNztCgKr8hGLuc+yNyqxkx74obsDlri/22NmEkkUaxSgYZxBBtFigYbD7ROnsLl4O0p/WF8Wnw9ivdacJUq9MPz+5dROzYNZXUXgYg8iudOFFsI0xKskrGs7rkT7xthYIkVBYaGJ7VPg8m1FuaIRzVdHj9K+3r+VEJHwSYXWphGlk2HT3iTti+5zvUAet+sSGXFzkZmkr6b09ylNvbvJPsGOgITozBt8iPljzwMnWCRjLv1cyna92DXCPpIyh5+jMdKDAlnkwf9SY5TcHg1bqg7m90c/bYbvO6qk+8EO9vbfaFv7g3ETQk+nj1Q/xfr0SeyaxP40Ea994raCMZ5gRNVt0i0wGJuKdtBL+caokazYk84Db4Ev435fEQljzhUANg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T14-42-23Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698676819.378698,
                        "modified": 1698676819.378698,
                        "name": "/dev/shm/incoming/2023_10_30_144000_00_03_V_010.txt",
                        "sha256": "ef8c633a6e7c11641e9f6988476491765510202155be3163735f72bf5a679830",
                        "size": 2033
                    },
                    {
                        "created": 1698676819.2886982,
                        "modified": 1698676819.2886982,
                        "name": "/dev/shm/incoming/2023_10_30_144000_00_03_V_010.kml",
                        "sha256": "fde2bd34399e7607ea0b556bfaff8cd6d946db73ca7f4c56cbb26493cd6ab29f",
                        "size": 978
                    },
                    {
                        "created": 1698676818.8386981,
                        "modified": 1698676818.8386981,
                        "name": "/dev/shm/incoming/2023_10_30_144000_00_03_V_010.jpg",
                        "sha256": "18eaa02f625cdea12aa06933b804c61777d258605fffce21318ce24a7451a0d5",
                        "size": 1052010
                    },
                    {
                        "created": 1698676807.6686983,
                        "modified": 1698676807.6686983,
                        "name": "/dev/shm/incoming/2023_10_30_143948_00_03_V_009.txt",
                        "sha256": "ad4f7bd966bc4ae005d624d81c351532376b07f533ecef88e9879f5c8be48943",
                        "size": 2034
                    },
                    {
                        "created": 1698676807.388698,
                        "modified": 1698676807.388698,
                        "name": "/dev/shm/incoming/2023_10_30_143948_00_03_V_009.kml",
                        "sha256": "dc836b70458c5ad1f2aa90c21eec8257b3df9911b5883a0b6926bd47163c35f3",
                        "size": 976
                    },
                    {
                        "created": 1698676807.1186981,
                        "modified": 1698676807.1186981,
                        "name": "/dev/shm/incoming/2023_10_30_143948_00_03_V_009.jpg",
                        "sha256": "cdcbfce0193c024fda6f253f07f5d87d0acea978ef79669fa5035e084ac73344",
                        "size": 1020668
                    },
                    {
                        "created": 1698676752.7886982,
                        "modified": 1698676752.7886982,
                        "name": "/dev/shm/incoming/2023_10_30_143850_00_03_V_008.txt",
                        "sha256": "0a379a704c82a98a50b86f95c82447db7d0abec3c3d8ef178bdaf3b1ee4803e3",
                        "size": 2033
                    },
                    {
                        "created": 1698676752.7086983,
                        "modified": 1698676752.7086983,
                        "name": "/dev/shm/incoming/2023_10_30_143850_00_03_V_008.kml",
                        "sha256": "d7eb58886267ce2bfd8a6a95f9d5812a381320c658555fd173e82631d526ffe4",
                        "size": 976
                    },
                    {
                        "created": 1698676752.1986983,
                        "modified": 1698676752.1986983,
                        "name": "/dev/shm/incoming/2023_10_30_143850_00_03_V_008.jpg",
                        "sha256": "47539c2d979e1b80157f530c97fa91192933f889979ddd3b607b0dcbb451a190",
                        "size": 1029286
                    },
                    {
                        "created": 1698676740.5786982,
                        "modified": 1698676740.5786982,
                        "name": "/dev/shm/incoming/2023_10_30_143843_00_03_V_007.txt",
                        "sha256": "a6135dd6739d48ed792ae94760720c479665f9892700f67012b12933453c1766",
                        "size": 2034
                    },
                    {
                        "created": 1698676739.9486983,
                        "modified": 1698676739.9486983,
                        "name": "/dev/shm/incoming/2023_10_30_143843_00_03_V_007.kml",
                        "sha256": "9ddd5094db86df11c683b9c9957ae7e6ff8930829f2f7f2a652c295c9abf7699",
                        "size": 977
                    },
                    {
                        "created": 1698676739.5686982,
                        "modified": 1698676739.5686982,
                        "name": "/dev/shm/incoming/2023_10_30_143843_00_03_V_007.jpg",
                        "sha256": "aa0c527c74b7e469952e57e39e40c030b525677e6c8e5eec45c4c70e11d945bf",
                        "size": 998576
                    },
                    {
                        "created": 1698676738.5286982,
                        "modified": 1698676738.5286982,
                        "name": "/dev/shm/incoming/2023_10_30_143836_00_03_V_006.txt",
                        "sha256": "a6016f6d1047106181a17ccf0f0478031afa728fa35d585cdcbb4c38ce3d55b0",
                        "size": 2039
                    },
                    {
                        "created": 1698676737.6086981,
                        "modified": 1698676737.6086981,
                        "name": "/dev/shm/incoming/2023_10_30_143836_00_03_V_006.kml",
                        "sha256": "9c2419936bbfe9614eca955b2a259b672030041ff2b42e3783a802f9439d759b",
                        "size": 977
                    },
                    {
                        "created": 1698676737.5186982,
                        "modified": 1698676737.5186982,
                        "name": "/dev/shm/incoming/2023_10_30_143836_00_03_V_006.jpg",
                        "sha256": "4ee30850de67550557a5ea17c9194b0914da2e06e608906131bacb0883274890",
                        "size": 954067
                    },
                    {
                        "created": 1698676631.7486982,
                        "modified": 1698676631.7486982,
                        "name": "/dev/shm/incoming/2023-10-30T14:27:09.ais",
                        "sha256": "ac57741b671cc421cfd023bb4ae3adfa69db20af52abe5623703ed88b2395c35",
                        "size": 68415
                    }
                ],
                "mission": "OSP2",
                "sha256": "ddafc5ed3c73cbaa4a3d114e8ef947a8591130cbfaf46928b4c9cb66bbfe317d",
                "size": 5077608
            },
            "errors": "",
            "filename": "2023-10-30T14-42-23Z",
            "size": 5077608,
            "verified_at": 1698753135
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698692453,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "oNbkqjBYZgtP7yg82pRxHMZXVrI7WU+LyRg3uq1jJFseUTvbRWSj+U+fOUastYw4bUE8OI1YLOT+oV2tgBg18eSj09oHMCi52MBxlVju04Xm3sEDShZT0DLsaaIg3yR7U3C30Q5w7QoukZa1tUUcZPftPdWPwMhmMwFkVpGJ6cYdLyyLsoVMb+o2zxPdqJEBHFPDokkyKpoMmRmpuY5C3skMPg/MZR1KF95ucriX/6C4/DqWSyLrmsQdQUNQkRCbM3yEtfR7k+p2vssIH4TxSeC6TzF6zdjphnOf2gJSmeOdYo85mr55TNEbGHroyHTeE7AqQL2db9l1JYDJwpWA+5F09vksqetzbbqFfLGqgEwWwJssranUclwwttw9sH3Nufs12DzMsDwLLQCEGrfBWadkRaRh/cQE8LvnKf2WqvYHHxOVRvBJE1aYGKsYQRtSJdbHypWDxjl3roRY5z0YmuVgmQJxN3PYJuCnhkQW84a85Kpf6bGFsPnA18niZBtJ2tYJcFUExFYdKBGdH7T/hURH6Xyuj6HO0ZoUAKwtHJtY34ETV5zvaimOJHkLoHFXSiSqppq937bkNYI2YKLQ+fxUSbKpMSRVQVsit8rXoicTxQdJQZUuvRUArpysd24S8crV35aQ8dFIAipryMV4tlzqb8wzN0i1Ms/iv6taQKI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T19-00-39Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698692312.2886982,
                        "modified": 1698692312.2886982,
                        "name": "/dev/shm/incoming/2023-10-30T18:48:28.ais",
                        "sha256": "3f07b3002b6584c7715b32d449218621f0e30228d0e35451f2077e53ee97e237",
                        "size": 172064
                    }
                ],
                "mission": "OSP2",
                "sha256": "e1c4998077b4c42ea996ff6685cd520c7ebe989344573691c1d918772e2cbfa9",
                "size": 49543
            },
            "errors": "",
            "filename": "2023-10-30T19-00-39Z",
            "size": 49543,
            "verified_at": 1698784506
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698664858,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "egOFeiKF3gbPo0QPg9GmzjH858nQPAVD6TTPSyjYNoxwgaR95Ld4PmRzhRbpUutqkyY5tbJOKqS0SziIgdhQSd2TcwJq9xdPVMk5cTvBaHQXJ2XrdxHvOuVdHsrk/uI4Cs6CWYbuxKvol7J0N3Yf4Rfn44Uh8noxLUsyMC2DWntahV2fF0krEgzd0aYg0+cN45bGSD+1LVZyjJLwUe8XwzgD782RdES9owFSHstL0/CS6l1uIOJj8skOOfPC8jGdaH029XXUgbk5adVJznNyObPKOrB7Wu18CYgC0KTqeTnRIjH8Tn5/D+JqhdkfdDqB3xxASdEczECjIhCzVEWObrChhrMOMR3zU0GBCQUuZCCkP4EB7bGsP+s6E20PqMt7gnMtGZ4mDb3nQAxFBeoLv0oVRGkaJey4Im2AFXHa5jbcGzdwWkE1a6C1ZPf3HIXe7dWUnGUxg/p/WK+ZnZTpXijGTqAK7XqzjL33mpVybrKqkv569oUGj6dmfTjvaZCxyL4JSQDH3ETz7qDk3JT8t/1kGAiS1nG6mpKYZBPgVOw5hRK1jYsb39TN5aywRiWMgPAN6VOF9p0lUUwAusJJOkIQhD5fZl17LFWzehiPfWbSEdgInh1qvGWFd3s9KzETHYvtCLxDitmdEg1S8QgupigkXZtm+xu1X71+8xBvxhA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T11-20-36Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698664708.047829,
                        "modified": 1698664708.047829,
                        "name": "/dev/shm/incoming/2023-10-30T11:08:26.ais",
                        "sha256": "668cee0e93872608cd8d985cf82b0beb597ae9f77c318c8440ab94658a267fe1",
                        "size": 636793
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "fb9aed209e4d78f9583e2f864e31f6926583b4ab18c50ffecce4ee4850669e22",
                "size": 184219
            },
            "errors": "",
            "filename": "2023-10-30T11-20-36Z",
            "size": 184219,
            "verified_at": 1698693150
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698663192,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "BWckwOGw64tyW+nVLRH2YsgQMs/0eFm2hMEStKJhQnQPw41NKgM8V6Ot4iDtJ9HVM8ayACJi5/M62HRy41KIvdRWtJROjvjvBW9CbKtb968eHEUrnnpoOxO6QBP+PQzNrWEswBaf7nDG84JyTk3zL/POCtDaNtqLaGZU3MayjWX8wuV5HpRVRg7jIiwbWV6caNxIeyV8x8GuNRAI1oZYW4E/SXjMz9gA9oZm+zYduKKkBCEAZNDwbAk1LXczQjPUmCA520HGtIalpeZJWbXvx7VGSMlz3ZviYiSEY5C5EltxgQdcrapwcyqpKRqjWhbk/XGRWJya+QGNVsvmP8QlMHRzk5gtGWxG+eSolHoQGpdTZRBGwRAZC0oGe4bPOIz1cgqWnaGAMvL1xpz5uSrf7LMuCFI2tc5NXqoyuL/ISl6RMcxlX20Yqr0druTf9r0zpDjsjoerYzsaz7oUA6b6MapRsB18HAOZUbiIZuYqbcEu0n/OSqQavDqBTQqK0Tn5I9j0Oa2Ezeq/XMvehA4u9Wkpvi1N1E8aYDmrDAY015F1TA+wa1kG575E7XSK6JzNNBA3fXEPxdUjgNq+gGKJo3fREcSclLH6Bwk4bIb5Dgji3Y0wUK6aHOHqQ3eKrDDgoDa0CKmCSjXWkFGba6dCWUYkrvItiKp0BBxLTEHVIjU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-51-49Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698663103.327829,
                        "modified": 1698663103.327829,
                        "name": "/dev/shm/incoming/2023-10-30T10:21:42.ts",
                        "sha256": "2998cf0ddd3f231dedc85798369169bf553e20aacc72da38b96129b77cb9e258",
                        "size": 427258576
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "ac6daf249477d91458169bbd169d6da3228d93a089c27d28204e867ee5a1790d",
                "size": 400832102
            },
            "errors": "",
            "filename": "2023-10-30T10-51-49Z",
            "size": 400832102,
            "verified_at": 1698693237
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698661246,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "W0xu/rAaz69N8oNiHwkgJvruD9KpkVjz9hqHQY/uUzkrEEErGIh9ypCv9GL3a/pmoiSDUuvQbzHApvctGXVhP9VdC+af2f5Zxr2ofWLDP4/mepJjmhUuJAjAqU5rsGXaQpGGntozuWWxALz8O5orphGfQus6+pqQJYr7vfBwSuy1nBwCEkxUnWZWnGmPTsHso7oWpajVRDDAg4PWbXMW9kmuJF/+xCQlBSAViQjjfsEORJSdotaJgiuAk0mvCZ9WLhjJ6IImWrCJ2y1Cv0zcRz/nz1h4Ipse/LsEP12OpeV7z5uURLv/6A0qMtbQftMzjOYfDKmNoLvEvLj7MAYh8MkQlvy0CzVxs4u/xLcxNkK6i7XA1h7WVLq27jRMVw7rIsS67VKZiqpSwil6n8APOSjYhZKnULEq2//ObWTrXt30+wMbQBiptQrSOoukZ0byXxo+B4Knz1Plvna3CLQTRDsh079kIDyDHhgCbjW7lPtaB7w1BvcKZ5QRrfrI1BILaJypf4xw+xgnvB+SSay+yz07iY+GC9vnY0MSNHjgGzrml1ZRWlX6iH03kXWajXCY8LsN+uld8GROp7OO4HDQ+a4C3nQR4MD1xVbZeCF9CR3GDXvVBh6JxZcez8Wj7eGN8HZYW5scm4vWVK3XYJ8c17FX6Sg156oGqGAcTazimeA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-20-22Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698661099.047829,
                        "modified": 1698661099.047829,
                        "name": "/dev/shm/incoming/2023-10-30T10:08:17.ais",
                        "sha256": "a4b82b7fca1c721872fbaccde94dcded48fba9c831c44f13ec712a2061208236",
                        "size": 611475
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "42cced64726642ad73dc0ec347a5cc3a1848137abf16e10e59e453286042a09d",
                "size": 182426
            },
            "errors": "",
            "filename": "2023-10-30T10-20-22Z",
            "size": 182426,
            "verified_at": 1698693434
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698663948,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "n7iJT4cueVNlE1FjlNtjCUZSCceXZ8gJ36navH81IF5sGXHBL4zBhjuYvEOZTRdTpF9uECDZrEAMS1RmKkqwbzmkJCZLlcYRutCUnQXDs1rbQ0sjRrB5yzxRZd7xcrZVu6JCtpczIOnLF9sjhsx8ne4F6DXok/3lcwqjz/s1mXJzFnWBQSKArt7/ow9i7nn1y+YROYq4xXC2r3eCPE20sV1yX+niJQ3enyfInd+H81W1sqjxxrj2osh46iSjMEfO4gqR/txb1GlUv66jsbH6cW4lM7qLQBU5J7MUL+cPjbxIrWk2XtCYtWtgtgoLK9cYIf6LjuSgCFmv+WSkWZC1qBP7m3hEedEb/x379AiD2nVIhmcSHkHdbQsRaWMFePUpIoOaWsn8dia4oyRe70hIlUYaWXs20u88D7QqgtU0miVvTuOKpD+uepl+aY0QeqgEDCJS+7nd0GCv+1BymVsaFoQjBRbmXdJJxV+JMbXLT7wQus3FtjTY3U9RQFDsCk48Q67B7rNQyc3L71WDwNNLINZcjBiPvd93Y3XUkxpYi9NhTIsVGpc2TZ73G0XZ8zQGzSbiIBU9xMCvHYfW/mBtfdEWol0p6IFxPV1xK+lT6uxcFK7pin3vJvsbOR4f8VxDkPwMMkKUORx0VThmr2Mj+q4+ZN3rC5barilFgCBukiE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T11-05-01Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698663815.8478289,
                        "modified": 1698663815.8478289,
                        "name": "/dev/shm/incoming/2023_10_30_110311_00_00_V_043.txt",
                        "sha256": "69eeb0e8bfe92f36c0d6e1be635b0fb82232ae5bf4be866abaad58769d5d5ee5",
                        "size": 2032
                    },
                    {
                        "created": 1698663815.7178288,
                        "modified": 1698663815.7178288,
                        "name": "/dev/shm/incoming/2023_10_30_110311_00_00_V_043.kml",
                        "sha256": "08fcb645a883eea5bde89c08f9a494749cea55afb18c4ecb27425f71c3cd1df2",
                        "size": 980
                    },
                    {
                        "created": 1698663815.5978289,
                        "modified": 1698663815.5978289,
                        "name": "/dev/shm/incoming/2023_10_30_110311_00_00_V_043.jpg",
                        "sha256": "3c26230ac7a82cd3bdd2145a6f6023012e51b1148fc83f3c836c66ad1bc6942a",
                        "size": 739793
                    },
                    {
                        "created": 1698663814.7778287,
                        "modified": 1698663814.7778287,
                        "name": "/dev/shm/incoming/2023_10_30_110309_00_00_V_042.txt",
                        "sha256": "41e3862f7044f70452b363f08f77d71b2b9d836e41ee83c60debe0f1d083a9a1",
                        "size": 2032
                    },
                    {
                        "created": 1698663814.6378288,
                        "modified": 1698663814.6378288,
                        "name": "/dev/shm/incoming/2023_10_30_110309_00_00_V_042.kml",
                        "sha256": "a3f853059454f81a7f3d9ccc9de36d175633543805b7399cd40ddbbabc403be5",
                        "size": 980
                    },
                    {
                        "created": 1698663814.5078287,
                        "modified": 1698663814.5078287,
                        "name": "/dev/shm/incoming/2023_10_30_110309_00_00_V_042.jpg",
                        "sha256": "24eb308ee41dda153b699062f1fa937c79197266783189d7a9a52e3b84c68c30",
                        "size": 793494
                    },
                    {
                        "created": 1698663802.6378288,
                        "modified": 1698663802.6378288,
                        "name": "/dev/shm/incoming/2023_10_30_110301_00_00_V_041.txt",
                        "sha256": "0ae6e5640096d42d728af046685f33ee8b488af5340ecf830b0101a047c83c37",
                        "size": 2031
                    },
                    {
                        "created": 1698663802.4878287,
                        "modified": 1698663802.4878287,
                        "name": "/dev/shm/incoming/2023_10_30_110301_00_00_V_041.kml",
                        "sha256": "117038c215282d26b67926b3a7a7f869f8593d33427915db63d334859603b573",
                        "size": 974
                    },
                    {
                        "created": 1698663802.2178288,
                        "modified": 1698663802.2178288,
                        "name": "/dev/shm/incoming/2023_10_30_110301_00_00_V_041.jpg",
                        "sha256": "e187e80d594901dfd43afa569c78bd1671ce7aaa4b6abe94fa3cb137480f74ca",
                        "size": 842437
                    },
                    {
                        "created": 1698663791.1078289,
                        "modified": 1698663791.1078289,
                        "name": "/dev/shm/incoming/2023_10_30_110249_00_00_V_040.txt",
                        "sha256": "15f9fce3bb79908d77711b6fb8b4ac9b29c8b1d0ac028fbe22e9285068d6d9b3",
                        "size": 2031
                    },
                    {
                        "created": 1698663790.6178288,
                        "modified": 1698663790.6178288,
                        "name": "/dev/shm/incoming/2023_10_30_110249_00_00_V_040.kml",
                        "sha256": "960fcabbb9004cf2ed32da25e124dec3afa3134003da07f1ad07501abfb2309b",
                        "size": 977
                    },
                    {
                        "created": 1698663790.3778288,
                        "modified": 1698663790.3778288,
                        "name": "/dev/shm/incoming/2023_10_30_110249_00_00_V_040.jpg",
                        "sha256": "6d3e889dc65ad3a9dbd05a77866f679c074de751421d4874bf6d93d0cd13c78d",
                        "size": 877142
                    },
                    {
                        "created": 1698663705.6878288,
                        "modified": 1698663705.6878288,
                        "name": "/dev/shm/incoming/2023_10_30_110130_00_00_V_039.txt",
                        "sha256": "a63b0aff2dbd9578b68b895edb176ded74d27327c3c51db444392d150033f581",
                        "size": 2027
                    },
                    {
                        "created": 1698663705.2378287,
                        "modified": 1698663705.2378287,
                        "name": "/dev/shm/incoming/2023_10_30_110130_00_00_V_039.kml",
                        "sha256": "b95aff837b33f78f8daf3615d68da29fa97e9b0f76ecd34db1eb092c0c5af325",
                        "size": 977
                    },
                    {
                        "created": 1698663705.1078289,
                        "modified": 1698663705.1078289,
                        "name": "/dev/shm/incoming/2023_10_30_110130_00_00_V_039.jpg",
                        "sha256": "f453557597d816b004b585ad356fcf899b0c0c878ad6b7307cf64350e8fa1b3a",
                        "size": 1173464
                    },
                    {
                        "created": 1698663672.5978289,
                        "modified": 1698663672.5978289,
                        "name": "/dev/shm/incoming/2023_10_30_110052_00_00_V_038.txt",
                        "sha256": "515235b70435f8d9870421a67756400773ef2904535549e5b59f6a81e8992daf",
                        "size": 2029
                    },
                    {
                        "created": 1698663672.4978287,
                        "modified": 1698663672.4978287,
                        "name": "/dev/shm/incoming/2023_10_30_110052_00_00_V_038.kml",
                        "sha256": "6205d5c457b638f4ace4a61ec53e7b0b088542655a1ef5873b1245fbed4ab27c",
                        "size": 975
                    },
                    {
                        "created": 1698663671.7178288,
                        "modified": 1698663671.7178288,
                        "name": "/dev/shm/incoming/2023_10_30_110052_00_00_V_038.jpg",
                        "sha256": "a2d82d99fc8fd6bb44e96927e1f12c67fdf4b5d92fe169528377d076db1be1eb",
                        "size": 1113768
                    },
                    {
                        "created": 1698663670.4378288,
                        "modified": 1698663670.4378288,
                        "name": "/dev/shm/incoming/2023_10_30_105821_00_00_V_037.txt",
                        "sha256": "4a5c766126143f0af3f169f85549c7d9766c13611b1baaf7a7405a5e8e3c3ca0",
                        "size": 2027
                    },
                    {
                        "created": 1698663892.4378288,
                        "modified": 1698663892.4378288,
                        "name": "/dev/shm/incoming/2023-10-30T10:51:48.ts",
                        "sha256": "cf9e7368a4fb1421f67ebe0237e2fcefe53de99b94744e2962b61c4bb42d094d",
                        "size": 186438660
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "86afa19ebe2496b6dad72af0b3e6ec9a1e92c9b08ef24bdf506d592698f3b650",
                "size": 180188771
            },
            "errors": "",
            "filename": "2023-10-30T11-05-01Z",
            "size": 180188771,
            "verified_at": 1698693658
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698662453,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Cce8epd1d9GUGve7LfqMmBIDpfzDWEV3zTiLTfPE1h/NEJaxpsCkuN4rKQzB9B3nZ5soWbs4nOE7yX+hb3L1LMQ1ZqTgafV+SpByUsESIxVE1PqlDAAeBynQpUZyzqK6fN6bVRTD4zkdzktkXOFnFr79vXUjpt7izSNHDF1jUaytBrgZMUs/duVoYw0GYTb1jo4JqcFruLvVUq9im/LGm58dcLB8ei+26hg4KvVoFDLNaklhB4lnwXhGRLQEVejvh1qf2N0Pj5ZLSdBU/esAzvha7bZnWTtjcv2djGxWTOI8RQtFfDo6/0mTEMpwKnN+dDY3ZhiJVc8Jek6ZBwhBLMmKsU03MWRljIitRUjcnMKyr7PbAcbLiNZ2HNfozod1dvEKiKHT9v09BtzHTuIrUSWeXDQ/dgNsZYZ/Fx6p9lYAN/6c01QMouMGvJJ3KSfn+HcMWCPh+rjrxFSSJ8c8YuubFNhJzjDU+zZT6+yCL4oOtw80eOouDfdltf5RzFJlrmZN2Am9GvEStnlq1tUmi9TCMmvKoo/tUYiTiwAvh7iLY8pNKxktRMBSJf440Yiqlhom7BJicdWQ/2+XaKbfSSSnm0lZ/zQPIn+aJnt4Z1TIMRu9LSacUZYhLRCwkHAjib7Vjw7jDrplOCP7ng5mURctO+WkLm9o7RIuJ913l9E=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-40-29Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698662301.807829,
                        "modified": 1698662301.807829,
                        "name": "/dev/shm/incoming/2023-10-30T10:28:20.ais",
                        "sha256": "f35fa71f13cd801baaa6a47b3523953f8915d09d884876ae8a80f79f4af4b50d",
                        "size": 670035
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "1fe273a54aaa094e31442208df92508c7f3da9ad81180e31d22280280d8561f0",
                "size": 196993
            },
            "errors": "",
            "filename": "2023-10-30T10-40-29Z",
            "size": 196993,
            "verified_at": 1698693725
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698765421,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "gGNbWTZS4/Qi1x1n+HyPMCQu1ybxtxvmgXVkBjoWPQcLdo3llSQ8OL0LNl8Bttd3rkGpWS+5Hcc2+KniYnbK1dFpFEgTXP7ej90Jv/njy2r9TcAJEnDn5r0M6CPwCJACT7KXBdNEh/+k0kuZaMqZAjMPsMgX7dQhm8z5xdICY4p9nlARdO0TAcsMS/OGqLAwwTBVAkO1e3mhIFoUWfg89lNycSsCyE0EMmoe613z0W+tToD9Dr8hJ+VtT/iQrogptHPkV/W9UB1oePOmkqHHxfUd2rABmzh7HT729pQcJbXEi07xirafxVn3B8Rlvd3zolHP12NKSwX4wxMSUzLjdArCRzuasCC7KZSKnMHlvUtvJ4SwhHwWIJdNKvFA71E/wDOCGnI/0+7hQjL16X68HXe7M8i/ADS9RbZbT5sxKgeFulO4Gh1F+8GiFZ5uRrI2Iv4irVjvWz/1JbjS7rDY3QeAtZ/JwwUdsBxd/TJzc0Ns3hemwTfGaikDWWb27U7pnN8v1uu9ul76Pui+2rH/kkLoVIB5X+oHKrfUoWiHGeRW8PJw8ZKyYJI4J4y2prYSh+j+vtm4nd97qybQcFOjXl4723+YIsM8Chhwa9yUWxA0FDUuyEUNgPMlsLKn9hdWafPbdtnHPM0TKNH2ddg9rfZ+HulnIb7JPKU3e9EfZyE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T15-16-57Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698765290.544362,
                        "modified": 1698765290.544362,
                        "name": "/dev/shm/incoming/2023-10-31T15:04:48.ais",
                        "sha256": "fe9b5bad0d16b6fdb0a61197a44713af4820b2ed5921c01b730c608c38885b48",
                        "size": 7815
                    }
                ],
                "mission": "EFCA1",
                "sha256": "38a5a6d39c5d9dd569c64d6d8173dfc592cb4bafeea7d9269d9213e2e2b096cc",
                "size": 2274
            },
            "errors": "",
            "filename": "2023-10-31T15-16-57Z",
            "size": 2274,
            "verified_at": 1698781586
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698764212,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "XIc5GwXA2jfoISRDLOaHuKQdJXqADALkkpIvfq84symft7awNKkDA+jsdpVLdiyzmxVqx/023ROe0QzwY/k8BLW5/K7AQ3ZzKt1kBbicCGqfUV5yygrp1it6Po6o3hQ7UOHqMFouDjuu1NJwMwO6qV4YO8JWbEVQFTLHCbrDEZ6En7dXH/jg0OvFAMfM7dmgP1Dz/iELiIKKoR3cHqtbqpsPnj2YOrQSOV+cUz7ZIjgcyEjoVXJSALWLK4AK44i6pGCEhZdl+q93e3f/+rM9trSJDV1NpH0JO/jdaKj1YCDKcQ2yHCZ+UEvtyBm9EFlhNKfmKONb8jLMG+GVmlrd1GN2PMrU6zAKcETuTeyDU9p0hfPrLltwZTkGAxjh6hevDuIJCgz+v78jObAwVG+43rsFdgUoyP3VA1W6llOQRfajyYvMN+FJSjVbZ0bF4KaGMcYBquVbFh5vgFG4NkZQdtsqoE1XAEIqwg+a6Q4/F+NSWeQFvQ/sB7w3nJiQFBvtP+IQ+JBcRYuRJhm4Gxu+cSQHc6k29h8PEvbVmrdzpLYilLiOAzESu9z7Xf/lgEVFgcO6MrksxiKs4AcCWtLXQJXe6AyN4YKhAr2RTZUMoroxDW+/QVAwFQ6PD1V7Mc24pc9gXY/qeK8SGlYtQzhgMwcOcpCRIaoihw2blhsMQCM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T14-56-48Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698764080.744362,
                        "modified": 1698764080.744362,
                        "name": "/dev/shm/incoming/2023-10-31T14:44:37.ais",
                        "sha256": "9bd6f59b816a6869d507ae0d6b4d3e524ee4df9aa1148a9c54555dccc81a91dd",
                        "size": 5313
                    }
                ],
                "mission": "EFCA1",
                "sha256": "7541f366e9756f4614d5d7161733cd9250d82e274948d2dec99376434cf87065",
                "size": 1556
            },
            "errors": "",
            "filename": "2023-10-31T14-56-48Z",
            "size": 1556,
            "verified_at": 1698781589
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698781474,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ctZHtXwWno3QpOuKvVGpDctfcbZFVZnq+s6mx7b3nWn/IH/ud1IvwlcaaQzPEF2js+cH6hjrGh0JfdT14cFZ/zGSORieMTRULzdvAxXrgsno/fHx9kfj7bUjtj7/a+zEYr+V6dSN+DYYfa9O9TtFg9idwE5nr7n+fY4GloUc/ARNNn7t7NEJPzNVV+dZD5llwN5IqtbAC/2ZtD7+7s/ccGlrfbpBkSLbVas4qpDN67klUc0cb4XvLW4rxow10VQ6+pY0zIfKCCf8WK36qXMOjHp3gOfozcA3Qo907+hQcTb42UfPNTDP7ysYVm8hOEEH70jKD3BoQ+APIi/cZIxHg//j/LWGaTJ6i3Gq5e1B/nF0EJQ+ifBP1YgPcAGevsNhGEG8za/ya5hMJqC7J0ITEDuErOZ/2bWF7R0QCZWJxWwLsA4g8ZERmn1BU7oOPn15BoskA+nl48rFEYjfaR/RFArHTeCcohszfcfyybYbOGxmTKyg0ZduWBY/k1eRyoGzuW3HxjgPC+Pg0xTfySC1DU80QwGY2pXqLo8oaPOn8jbreSaq02ZUGCArTZDaKQJso9yjmH0zE9/QsD9y7xlg/EVqkSX9vHcSjodYi9zjrdysqBw3dRpOZlZLgWXpOccPK16rITzW+qe15uukm4tV1X2pxxZE4Z+nkyc9F0UUfU8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T19-44-20Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698781331.1330361,
                        "modified": 1698781331.1330361,
                        "name": "/dev/shm/incoming/2023-10-31T19:32:07.ais",
                        "sha256": "ce8a4704b0bec700f42d3522f2945020c13a912d2853ecdca120d41e5b993b3c",
                        "size": 64845
                    }
                ],
                "mission": "OSP2",
                "sha256": "011ee251866ab1afd9fbf2dbe78c41e705fe6493dbc4efaa4a03c39e5a89795c",
                "size": 17531
            },
            "errors": "",
            "filename": "2023-10-31T19-44-20Z",
            "size": 17531,
            "verified_at": 1698784472
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698759377,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "G24AG9/6r+Fmpj8eU8P4Q0zsng4MRuzs5CJGbrKz817CJJ22RqQck3XJrWdIwElnMLXQ2JSExsNJYlWzkxoTy6Q42X8vsP2Ku/X/hwPf5SuzuteeZRuGckLP8MDBEErENxcE8qxFh54Z66nOsee5TkwHU22lRJ/PZEqEDCiaEj8JX2iToNVZD0kCGA51Kst7VLekYB4yDFYb8jm26rlAKaY3aYHDEcTqqqy5Yxd/IGAMnDZo22zOnZLBJNjNe2h/pCkXK3aJFhFTnuSqc0yH0vkdBxm9V+ODwRM6RLxxmxzVPHEiJIoRFRlY7FNjOCW+ILSMQtflpPEyfsXWc3Q1PfVO/meDknKh7tOEpqaVp2jmhZX202hZ/sIHl2cwFnXUki4KjjRXK87XE3+WwVS8WmTVjN0JB0GpSzND1IxD/IGN+xP/KOXgrpD2Yze4PWhFPkTb02oELNBCqiOGz1izlWwdFVd29OP7WiQMr6nQkkunCWJVDblsUiLrgNzgdHBsVfTiCI7n8HBn3/7Rrfo+9sPmPH8fnwoR0LIIwXsYgI8aXm55tPtkFKK9x5uSlxH6TyN/u0xvbkwK4xL35lqP19qKRTznmF5kcgjvPaWBoe1E9HvgYRZZyDAB03IWkY+XfB3I8/Rka30tHCZh980SqFn3nKv1BZ4g2p7Mnm5C2fQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T13-36-09Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698759244.4843621,
                        "modified": 1698759244.4843621,
                        "name": "/dev/shm/incoming/2023-10-31T13:24:00.ais",
                        "sha256": "930521fe8c1628066524eff4703a23bd5be436cc71e46a7d38fafd1a32d40976",
                        "size": 87132
                    }
                ],
                "mission": "EFCA1",
                "sha256": "b91e14b423fae697456acd98a081c617c42348be0c960e8bdac1725e7c71a4cc",
                "size": 15962
            },
            "errors": "",
            "filename": "2023-10-31T13-36-09Z",
            "size": 15962,
            "verified_at": 1698781591
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698758175,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "k4+leOCt7V7NrzhYFH/ZVCvEup+/Mq7uEd+OPuwMBB0pWjIuZOV7hvVL61/oa80HCYTt2iPQxzGStQL6WpujyaMzVss/OkeT00eDrUSMlKi2TMSxgsgMPa33pqLaqmGGJtNQQeCnBdshiWNrry/rf5gTCMyZ0yknxJNEiVBVph8cF2SyKAuCMkanUyPNb1o4SaQJuiP4ygtW5PKoFKPNqb++zYbnvmeQ/qeW6U81w9prpfG63hq17qALRE8YoIjPP55OJeaJabUtrRZhBYomLsLtmuopL3Q/sQbRfpmMOBOi55Jfi3+BpDnomMrfQK5sQyi18mUyc9JWMjGMUxVKnxmocsOMkvnMi16G7jETjV4THnKilVuS6APGg5r/HxVgPrykjEolh5qedyuaQu+ZegbLtauInaGbxcUpu4zBTyX0d1zI8WRSblZ8y7jtApSMZC/zZ4vUc6j/6qIzlTWe8rv+OOrQpYjv+n3wbe7YPI3TV4+oN8KURVoKBdoOQqi+zwc1O3vxpN6IBtCiulF6U39Mnensugj4uNwNWHqa2KWM3UmOF5ndfwgsp1WxM1HFrg65ShUf+Y1vSFAhgpz3o5NXJNm/btdnFXaTo1uw5F2XgQqvpRWsLOte8eorOl0KvdD2iIXZwKD/IwTqnKaKrSezLyv4n48CkZVvkBFBQoc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T13-16-07Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698758038.344362,
                        "modified": 1698758038.344362,
                        "name": "/dev/shm/incoming/2023-10-31T13:03:57.ais",
                        "sha256": "7358a984a94e49788bb2b793d3d14e2c1719de57915cbfe160ca16342ff5eaa3",
                        "size": 92180
                    }
                ],
                "mission": "EFCA1",
                "sha256": "623d5d83fe3e3696422263e405886b743822d75f0f60b42c30d9e651ad963a12",
                "size": 19363
            },
            "errors": "",
            "filename": "2023-10-31T13-16-07Z",
            "size": 19363,
            "verified_at": 1698781599
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698758774,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "ufglJLNhswpAFv1fe4CafjK0g1SgjHvNOs49MV4aWxJ2+p2o3G8iHonvAFwadOp3KdZCFwsQa8kJxlNk31pOIexCj0DPXeN2wLGK0tJHzxY6o8G1aZfc6onEyRUFj/0BJAoLJmnkFg8mWIVEmJgV/ohJ0yo0pj1nkKpTWdjZ5nNiaczBNqufUteZ1g9qOHM5RyETdvc9xg7V9j16hE1sfBa8kmcr4tELPhkbMr96W8RTyTXnVfXFa4e8xJtbdrYEtfuVFin3LrPUn68ShAwRVc77NWjIdCyJdgbRrZHyk7aTFI/nCWNy9gXiRwzJgOacah/nIgyXLUhHMIQEeyOaIBoND31exfHm8Sd3n95aHO4r+KfIFdnflordbKAIWaahMeP8VYlKx2/VLDC7Oc5mcRxD7BJtibrkbEbSgkXsYrAdZxRywKWi8QJqEeLbo/oYuOmmj3P45oDSoExTCxRWLX6/ZpuwtieHBdyYiK3gUE64R8y0OAMiB1+1QNnTiqokmxjvqESi/ILWbDYiwrnVTZ4iWp6x5QSuorAmHoMTUKpncaZwL6as/C50W+YNsQJ/8HrDIw21Stv4oVc2s1FAiQj60UP0xQW8ekVKVS7eMWATHxpAUP5HzBsEvZe7Xx6RKe7pFd/nkb32iJ27wqtFwUIUGoZFwBlQnI4RhKOCQ1o=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T13-26-05Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698758640.524362,
                        "modified": 1698758640.524362,
                        "name": "/dev/shm/incoming/2023-10-31T13:13:58.ais",
                        "sha256": "cfaa670729a62b5f85032ed5514c0ad88b3a8d792ee05a7658ae68a8c2ddfca3",
                        "size": 84385
                    }
                ],
                "mission": "EFCA1",
                "sha256": "797a3e27f1b30fd1f8b5ce2d89804be9083742d52ba5badda356ce54c25bc883",
                "size": 16464
            },
            "errors": "",
            "filename": "2023-10-31T13-26-05Z",
            "size": 16464,
            "verified_at": 1698781607
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698762042,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "fOmV6AWZrWO0cakypcHBt7rZGjLXuCCdJ0v9S0aL7zljbWn2HZEiuzuPcfAT+ApMYQuzQQ7NUzjAIQRqZpJ+kdsBRjSf/i0JCqRNUhpkg7VIYIEL9+l7iLV6P52zpldg3tSr6w4zfI2nlBdDknCaN/4GEL/Zqg8uMAmlwngE43SRsdfFLzH8YvKMcd9ii0t+mcMpaVegx5uL11FB8mH7pypIcpZWfptd8YzzvAJIDNzJcE9Pyhl9sGoTpZy86snlU3i68zrtT9kA3Z1tcu85vcf5OJ/7v7MB6XUA2TLlumPQYjl2W+KEl7u0xERQuF4B3Pv6qufYkRXYyRtjS6l5x4Zd3UwsH1VrUchuEI599Lm4rCYc5OlrzcyHypWgcQdHGoaK8+TLWRPrFjXaCv2pgH/aBjTSuXwuz5zWjlKFUCPvQ9OTQw9QWz9H9VLwfhaTtWhembjHQ7mfvMyqmObmd5V5kCpdAc1rIDCEkPscYgD05gEJbuxNM+jw0cGHhHsZhguSh6eHBH0SdjZ/GyIL65XmFyx85xlBYzQus1OY5XKqCAb6ffkuiDEDtTjwdvRG2KhS/wdQY7N61YuVaBt0LJQKE/SdLUCFeAvRkU32PSLWO+EWxDGK9mD7kuUXIsddOIRN9nAlrHIT+XsPCIr2f7SHahAAJpwoy0JbJaap0WI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T14-20-38Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698761916.744362,
                        "modified": 1698761916.744362,
                        "name": "/dev/shm/incoming/2023_10_31_141810_00_00_V_166.txt",
                        "sha256": "33f9396c707bb3dc74b1a99ba8c3a994dc188f83dcaadcd70f4fbcb13d9c138b",
                        "size": 2046
                    },
                    {
                        "created": 1698761913.3943622,
                        "modified": 1698761913.3943622,
                        "name": "/dev/shm/incoming/2023_10_31_141810_00_00_V_166.kml",
                        "sha256": "50aff81be10d591bdff5f77e3e85d88c96f922b71cbf29219c3319fda5d71dad",
                        "size": 980
                    },
                    {
                        "created": 1698761919.6743622,
                        "modified": 1698761919.6743622,
                        "name": "/dev/shm/incoming/2023_10_31_141810_00_00_V_166.jpg",
                        "sha256": "4328d28b13d14a7c85e39509d76c0a3efb65f480179705dd2785c139bc699f55",
                        "size": 1354676
                    },
                    {
                        "created": 1698761912.344362,
                        "modified": 1698761912.344362,
                        "name": "/dev/shm/incoming/2023_10_31_141748_00_00_V_165.txt",
                        "sha256": "81bb8b38bc40cc909ce8be11b4efa038561313dac42c622d73d1f909c25f2dd2",
                        "size": 2050
                    },
                    {
                        "created": 1698761889.054362,
                        "modified": 1698761889.054362,
                        "name": "/dev/shm/incoming/2023_10_31_141748_00_00_V_165.kml",
                        "sha256": "7aee13165f57b858fa1a5b05d29f6609e052943bbb3af3690bc961a297dd7d3c",
                        "size": 981
                    },
                    {
                        "created": 1698761888.384362,
                        "modified": 1698761888.384362,
                        "name": "/dev/shm/incoming/2023_10_31_141748_00_00_V_165.jpg",
                        "sha256": "883f52f9afc95cce003ef2f7987577f8eed41e8e0e1eb9d3b6b2e0db2a3dd8c9",
                        "size": 1404490
                    }
                ],
                "mission": "EFCA1",
                "sha256": "1e5954aeb2747cca7b8cdb6a916405aa09d8d3288480263f67483eebf3e27736",
                "size": 2762652
            },
            "errors": "",
            "filename": "2023-10-31T14-20-38Z",
            "size": 2762652,
            "verified_at": 1698781624
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698759973,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "HMfwOCIuXf8J4+ajwYdUef+xA0CNbxFdsASBGZv6V3EuRUgijC8g6DfOk2JFRM3SrZTJNaMXmHtr9txiXZkf3x0L9JBZuIHmMI5yudqXCDCUg1xDANAeUiEgEA+TQRq+RdsJRIVJ3qs6N16nQC0Hd5S7noAfZkbwswTqqDcl5N5M+6oPBXpNsfwt3W61FuzJIEJcN89xRScUBVs0Ezt4ZShgXhg5nUmAME14Y5VeKUPqXeYW/ZZOsCqthnH6ngAKfjCQrxclvm22x/hRjCr4+NWGL0QBgBFEIzAL2jNTBDHRozt9qr0cHtCJV6/GKeYze30YM8C0w02SFLN5Yms5eCwQ7RJF1Z5D9Aosk0gbcnLYvSPiQObyiHXAvB7Qm5ARRZMbyCus+KmPCbb3p8IfFvb7ICz/2jPol2YI2y+8AYRiod8SSY1Q7aIW0kFdU52lf+rLtyDE/1Kw9qlwnbxZh9v3gMqLraHAn2RkkKF/I0QnB5dCPy8smqEPx+nbuECebvTBS7XtbYbpOn/zwPQxcoZ6zI1KyHtrHAOOd/sBvezTe94p0td5OlILUqH48OHR0UNkU6G6oYwUci/10Qq3NDHcnADbv9EnOPt1lxNQ/EhbBDua0FqnQX7mbsbzfXwyU5q7a3tfpg+daFCuWLurMDM2uzI5iXMYunerdlgmlow=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T13-46-10Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698759845.9443622,
                        "modified": 1698759845.9443622,
                        "name": "/dev/shm/incoming/2023-10-31T13:34:04.ais",
                        "sha256": "a8a827a7db80cdd52cef622a015a2a8b79e91651a2efb93192950e50558dd09c",
                        "size": 75866
                    }
                ],
                "mission": "EFCA1",
                "sha256": "6c27314a587757ab642a2f32027fdc19963efff4675b78a3d28df602a44335fe",
                "size": 14328
            },
            "errors": "",
            "filename": "2023-10-31T13-46-10Z",
            "size": 14328,
            "verified_at": 1698781629
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698750417,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "qeCrVSdHx1Pdb8rF3Dusjb/r0gjqa80/GitWJW9dEu84TfgsFJXh/lwBCfyqfwZPXuAFIVSAchEgVTIGNauPtIHrnzuRwKTCcji9fD8QzVKfX+Y5h3zN8R9s5zYKWVxYA+HxYBMzaeZYxPUe3XM5mAF2JKV1QEOHEZhplGPQxHg0Ue/XzOtZNJolfv2AdlEuD7ao7IPKaY9O0AwP32IKRrbc0wDWxkOYTnirs8VLTvWomSQarsjOdqlkIcYl5CXq3v/i20/D0cyWpqn69hg/S5c8nfF5nsQhye0iJFVFMRU8RpCu1khU0Uo77lUT2VfbeGmEdQ6tLuWQHZbiooxcUHbIvqWwKAo00/asBLAuOqyc08FkeJZGqEzmeQ30nE1FRxiPfwe0CXijdSOvR1UACzSk9SYciK0Q+Zc2xm+hHGC3thC6wgpOD+S0486VK35Zzqrw1o0/i+UGv3OC6DsuDwskMLpcTINp4VhZ9eDv+GizP1X/7rRLXOf2ri/6svFfDJK2AUhwkFUBTrlFBvSiA5kqOt1/E8k/u0ihEfU/5Wkf/SXf6NMIm2HcqOWmpZnMd+zA2IR3l/zP+e4/9xl3Rj5O+/U+wf/rt4aiCN5uJJIntp+9CBm9aAi5Qg0PUADC80x3Vy+oLNvwI1hTtY7Sb0Koo6fQQ9qDXlrQApULcc8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-04-10Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698750186.52126,
                        "modified": 1698750186.52126,
                        "name": "/dev/shm/incoming/2023-10-31T10:53:04.ais",
                        "sha256": "ed98030c5d807108de383e9be460d32d3f621414d67513401f20d322c5d7f22f",
                        "size": 326627
                    },
                    {
                        "created": 1698750244.10126,
                        "modified": 1698750244.10126,
                        "name": "/dev/shm/incoming/2023-10-31T10:34:03.ts",
                        "sha256": "3af7b5f32385399816c4fa7d281fa549e3bc4219589aaa446a84a402374d7613",
                        "size": 440820332
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "2323ed4c14d011ecf78a278fb345dc5817efb8f6203f4c685ed49d5904552daf",
                "size": 399274265
            },
            "errors": "",
            "filename": "2023-10-31T11-04-10Z",
            "size": 399274265,
            "verified_at": 1698768637
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698762998,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "PGVoD6GIk98Wqq7kLh/XwCE+B2plaPEreP95H/MrhffvkhIvrYEy+AitfxpWSZ/Pf9O98Iiw4Op+cs/G5krZiAqIE1TirHk07LoaW0a9bPbNoDeOld3m6OqMvqYzSErnCg7CVHGgJt3fF+Z259XcZZhUbcXsu8I/VL3tjATKY+YwVGFK1ZOZNRqWmvrga5JZ6q9/1jKVnaZhw2tQjUyAEMdBSCMcZ3PWofWxwEDzJHXzu2/lb2YVGMSuwn0ViTLYS/Wo2GPhXCPX0buUKU+UHP72Zft+3hQk8AQ6iaI1qEQp6b5U3lS8Gu0XJNLJZeDkyD5SaqcGpLW+lnR0jxZs0by9upi2A78uURrPtLlPcm8eA3cCraQlnLnTxfKRiOlpuGAHTDDcmq4fUWg7SjM8ya3opfT3ZCVYYPyS3FV3aAddBHxacihIl2loIgn/24ZjRft5oycsNxnMlPUFDYJGxBAtkZy+VeG70GO+q83w/KFU5IQyeVf7FJ0tobYBLM3PkK+Q5V4MeJfDQgbiguugmGAJ7ZjxheVXyV4BMFUZr9tkiDKECgYbEpTm/lhWPZFcR1dQlYtKlQg/PAirz3AwzP/IwXQ07WuuijFZXlqVmgmL+02lMxcqpjF3GwuTCIs+TYRc2h6PXjVwOxm0FhYeMtYT1TwOImRI/CES9fqvXd4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T14-36-34Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698762870.1943622,
                        "modified": 1698762870.1943622,
                        "name": "/dev/shm/incoming/2023-10-31T14:24:18.ais",
                        "sha256": "95b6cc2129d256b489b447efb091a685f73398b09caaeee56f91633441f68326",
                        "size": 5263
                    }
                ],
                "mission": "EFCA1",
                "sha256": "e60c28aa377994647610f1a3a79c573412669e5308fb24c78a31e50f8a2d6980",
                "size": 1511
            },
            "errors": "",
            "filename": "2023-10-31T14-36-34Z",
            "size": 1511,
            "verified_at": 1698781635
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698750379,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "gW7OAoJFJ3TXLZOGkDNEWKCvAYk4jXmS8LQ/OLYkmrb03nUcshPXqs2+zK0AsvWwq6b8SF08Gpuvi1z2GSAcHbz7///l/XPYRfoa+BGjRFSanoshGejHu+pGnV54GaiZhoudQaP9Kpjr4Qi6RszqVo3unXEOGVucBkXLk9p+eNGbDnRE4KcMaqFnwlYqmFxgcUVG5NP+Ln8B2EPsi4ZiSXHa6k2Dvdz93eIpHgJDwXhs/HAaNtkC9TzsdTsBJf2BVcNeGHBDPajtXPvxDmXJ6O0B/zyNxDTYhxp/qkDiV0BGbdYmUFyubUIJaCThgQyzY9LfWOJuVkABE5vyJfMrt1c8rS+jiPg/oM1ri/GDLzaJRyMT5thwyLGlbqSKwmQcPB6H0Njl/w1CjtmNl4l/+43JECRZzvC/pqRv7z3W5kdipPlMooIfyV5KQUJt4gHPOpWusOltGoxZxtb+TBIZkQjg9FTDMpnchJRdo/zKvJQe5A8JaWcLa4Ez/ztOEODX7LxZw+zNCZGo8EnpiYsk7qpjsXPPnRyf5eHWT9K+Ycrhp4+7AI1OVAXgoEiMqfz9V3Uh9p5jrdJCOKzAcToDt9EAQTzndCa7zdtq5sA1Kbmv5DPEY0htgd1r4bvnXT4E85SQgepd3TVKpT6vkEmJFrwvoj5qD+avSg+y4Gy6Sr0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-06-16Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698750247.009791,
                        "modified": 1698750247.009791,
                        "name": "/dev/shm/incoming/2023-10-31T10:54:06.ais",
                        "sha256": "e770302c284b642d58377a3c46c566154eed84b8a8b765e333204ddfce1205fc",
                        "size": 389999
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "1776c58649d24aec3f53f33e997d1855f6ec1423c688cb749c0ebfc59e8f6155",
                "size": 116203
            },
            "errors": "",
            "filename": "2023-10-31T11-06-16Z",
            "size": 116203,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698750111,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "OSNUrWXL2wXgAJh2QBCASBsdt7rw4n9ygg698lYXXs71a1jT1H3SlW4EByVj1tgzBiaNgI/9YIoTRQEQYqjg2krFAELOH5ZspxQXJmBSEQpk+WBc8FMrB5NYNbdFbK0uuQ1b79gP05yk1SAeIc1hbVjpbIni9d5NHP3e35jjaiCtiaiPhKbKHL4abXu9k8hQKPTldmqHQjx3MBYqOtuXOcNK3no3O0NtocFlCAlUx2+e+APWW1Ei0wvQlbrsNmeF+l6ciu5J1AwBdFxuuK4V52GqMpmG1RaOY7Sh5wjK48wwL2b8FHUGv2xymHe1hRjJPmvLT81CvZYC5r+8WbBU/xZUnHjglXuL0Ac9fFaIBCDdAe11otqQsO//irR3v+xbJYZy55copOagx6bF2P+FMhdHCGhravJpJ7ZaG1lrio1ph+2bwJ2g/5nCdc7Ccr1VR6S04NE2/XF88mAeiiOvWg7staHjadB/9n/wpzGFzNwuXpSr05cPAW8fnAEjpkLp1dGaJrXHHGW1BrG8scGhe6CGhQdH0/gvixToonEswR+CrD8Vv+ec6zQyKLkH6N1HtIAM9BL8ncuCKRie7uGI0G10hRg02lUX7jyGIGkANiyBoF9p9vhHHOJLqbKtwDCYb8/v7ShrrTy3wFR0ZMRpf3tPtn08os/tmhrLe2lzcc4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-00-56Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698750047.9443622,
                        "modified": 1698750047.9443622,
                        "name": "/dev/shm/incoming/2023-10-31T10:30:47.ts",
                        "sha256": "6c83083706d871005ba40de712a3736bc6815886feea27078bc97b6c660480f1",
                        "size": 409958440
                    }
                ],
                "mission": "EFCA1",
                "sha256": "0732fb1d7e185afc924c55fd1fa7ec44ed0a04e4d6cc19f4afa3e3be7de67363",
                "size": 395228914
            },
            "errors": "",
            "filename": "2023-10-31T11-00-56Z",
            "size": 395228914,
            "verified_at": 1698782020
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698751147,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "VFr4EMdwjCMX+lq0DK4fXeI1FE4ygQkB89pjCYuZ35y1PvbztgeW/wDrvjXNgQoZlhuU6zf1qVmnwyEwSnDQv42Q9ga2xCLWM6wV4EbxDDqz1SQdyRDSA6gCHPJWci/jCkGDxp1xtIfKF/Lb5jD9q+9P9XqP/llG/Ah9RPAxq84Fb7CJBga7L1rTkQwioGeMzRHQ5TOzHj3p9+iLFRtQmL8b3jETePgckNGzWD9wH2cngBw9y8yjkqcZ981GSwiR5z0GLQXfdpOAL/776bf0SE65DUdG9RmWeNBRZoBz1xR+eab5948j7H26QgJbngLywHxdKWx6gienOukf66DjGaA8A90YmKHog9jEVP99Fb7XqKZSbzoyxSGSt+zsEG/l0wkgIp6wwlGsAxn1NOu2Qxvrxdv8py3b6e7ABMG591K9emdQ08Nk+jyhPlukWIbZkMc4OGRsC6xiBg+lqE67Ija42l/YkEit4VTNJNVWGu462gCv8OHbVGdClGhRI/nF/46YHdEEJxf3B7rY7YHH6yj2ME0KcgFg2uJ+LJXZlYyUPLOZmfh/yYrG3epiWT4tpSThJhzP16LNsBp6KXXX/LDhepnaeXFcJIs8hzSuetyoxsiQt4AKhG6NGv/nYVLVv7wU8K9hAU+KO7sZ1ZgeEwlGfF77GC9bCGlEnNg8xDw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-18-53Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698751006.3814185,
                        "modified": 1698751006.3814185,
                        "name": "/dev/shm/incoming/2023_10_31_111632_00_01_V_063.txt",
                        "sha256": "fc7b58ca57f8dfc35e2890d8506d951a03ea5eeb52edd88929f940f3bceeb4d5",
                        "size": 2037
                    },
                    {
                        "created": 1698751005.8914185,
                        "modified": 1698751005.8914185,
                        "name": "/dev/shm/incoming/2023_10_31_111632_00_01_V_063.kml",
                        "sha256": "61b8676e4840b1dcedb19911f53cbc69db86e9580fb38745c3f06a334c9f469d",
                        "size": 952
                    },
                    {
                        "created": 1698751005.8014185,
                        "modified": 1698751005.8014185,
                        "name": "/dev/shm/incoming/2023_10_31_111632_00_01_V_063.jpg",
                        "sha256": "57e5959dca022d0f3e1e246135433a9411782f0f85d1b5421a8459c3ef69e12b",
                        "size": 1368533
                    },
                    {
                        "created": 1698751004.8514185,
                        "modified": 1698751004.8514185,
                        "name": "/dev/shm/incoming/2023_10_31_111631_00_01_V_062.txt",
                        "sha256": "319fa83e4510944c17045dac55cab49089983002bd3bfa23cc969f9322d864be",
                        "size": 2042
                    },
                    {
                        "created": 1698751004.7414186,
                        "modified": 1698751004.7414186,
                        "name": "/dev/shm/incoming/2023_10_31_111631_00_01_V_062.kml",
                        "sha256": "b0d2a114766ff44f824a4dddb4ebf59fb5c549412f2f14b85f125e5cdf7db39e",
                        "size": 951
                    },
                    {
                        "created": 1698751004.3114185,
                        "modified": 1698751004.3114185,
                        "name": "/dev/shm/incoming/2023_10_31_111631_00_01_V_062.jpg",
                        "sha256": "0dab04283763893960c127d7670d5e6c856603b600aaa25e68d9f8c1f39722e9",
                        "size": 1373653
                    }
                ],
                "mission": "OSP1",
                "sha256": "ae2f07fe0e9f417edfc518092da8b4b3071f7023170dd58d20027912390ff470",
                "size": 2745108
            },
            "errors": "",
            "filename": "2023-10-31T11-18-53Z",
            "size": 2745108,
            "verified_at": 1698766451
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698760578,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "nzJVy6LtMCS6ieoOBmKmzhbteSGIv0zscbeSsvV3oEZCOCW0ciaTAUYSelVgNhJLzvkDTYgfRG3SjMn11+Y2hmo/0Mchj732ZshI6cM8jbCUHjqHpLfygEDcVsYWHOv72iIGVSyqZ145ZD8JkKSY6SLxcCUZGx4atA2lCymCdweWmjiXctIIHveekdyIn5+z2HjwahPudYU+Zumru+d5AjS00BY0HPmQE4ir3dqwuGmnfD4U9CXSoIAFRrkMwBQaaqgYpMj+n1WeiAito0JDqF4njzPDhPuO93ziAg3ySWTUVlEqmITBbDgttz9rd82SCDPweMsdTuZDJB4jx/XceqM5fajecON78yI7mbmeXlKhWODZFEVibVgyIxPv33jlzXX+g7dTgSlN1/nX9Taz2WRh2aCw2lBqcuj3RLhclu+T9TR+Ojlj+BGLKPGCy+2o/Kpi1xNRywuWv2+aVmbQ1G7zjhqyl65djvp/dlFeWA9NKI+rsEnh6QT7PvVFwPLTkY9kNyEc8V0vsSAn1kGmq/PhpH83JRACALVmyRfr5JrV3kSxv/RMaNRWzV5cVZpP/USHpI/g+mkQtqO/Ufk47+W4N5wc2w2uSYrwNYJvT91OxPPB1fJGxdOcZaKwIWUYPw0CTeheiqCDjPAZW6yQIA1If+tskHPtW2VUAIzl9Qk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T13-56-14Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698760446.334362,
                        "modified": 1698760446.334362,
                        "name": "/dev/shm/incoming/2023-10-31T13:44:05.ais",
                        "sha256": "8b0a60bc6e3abe2bf9ea964c8a894df239787d5a3a039958db269f49a5d0674d",
                        "size": 51471
                    }
                ],
                "mission": "EFCA1",
                "sha256": "de7ce608a7a8364efd0b5ac47923137304747020b7e728989843cec7272a777e",
                "size": 11157
            },
            "errors": "",
            "filename": "2023-10-31T13-56-14Z",
            "size": 11157,
            "verified_at": 1698781638
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698750977,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "iJCVSdA68q+pZMPjsw6DyHEYiFV7FwPH0KpMvQLuvigY1yMpt3b428BI9pY35BJXWVvdnFKy7dDtpbJ2NljHg+5d9mP33z6IvhSYGxT77nxZYyHY8CGHOK4Y9m34lFtGKsgl9i0wH9rzn7PPu9vGEfunIigqORDFf9e1sx0OHe/iZfAzEUtkJF8m4w5VZfaP+UCr4WhHG4Q7j4QRyu2QplaHsonkX4rJPF9cqVGNKCKpmWaNsiNfrhx9mSc2uyc+eJqsmgrqoEOrW1WVGP94kg9RpQ8PRUY0eVP3eOsjB/XA+o8K5Zo7RbE8XYSHd6llMBZxIU8GgSx5uAFuBKOD0OM25Cj7RkWEkqxgZoKPKhED/ttNQ1TJri7ViSU3ArDuSWdR16KYsRxFEEeNjDEQr6iSRvg1WfM/tK+vws/WSadqeTsQmx46/mfctp3MmUMA1p5MGKjTmlN23rcVa2YWzd9UoxbD1Zc5Y+5lbwzbDVXuiFJWpLe1gtEMHr8kvvE0TzGVYyd4V87B7nL2uietSZ5i3d7TgHssvjmTtDgHxTYa5B6GuHePhW+CYUtVJN592piY8cCxWfadxtHzfIjJMMJ2Y8VnirrcwCJqSauBFkU7xyKBYBC6UGDT8qUyWoa9VswRVTkWflgUDOQgQf9no3YvMu7PeidwA4SRDZdROvE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-16-14Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698750848.3497908,
                        "modified": 1698750848.3497908,
                        "name": "/dev/shm/incoming/2023-10-31T11:04:07.ais",
                        "sha256": "bed7b3188e8f58eea9b6f33d2385f4be2d8e4fe433d9eb01f479a0a7cbf5317a",
                        "size": 399320
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "11c0adf3977d2a0854043833d3efe311aa32a919256dbc76090107377c178c17",
                "size": 118787
            },
            "errors": "",
            "filename": "2023-10-31T11-16-14Z",
            "size": 118787,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698750947,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "cLD8oPYOt2Kqzc9VgwT7mfZ/SGFEuzXCptoLHtNyLsc6Aqh0iY56bC+CHVUUisua8grWv9YpCgXKn87+vgfJCdb2CIcA0EtuUWW2ntMPc28cg5qWvsi3RfeqrVV6/lU0a1xO1C41jeyahHwOhSU33i+DE7BdKtSeBlwH2QAX5UJd2SdT7pTMYmEVzf9rTHXGlcL0C6F9m5vj9O8PKwNK9s0sN4FKIkdZktTGFd2wtAJfS+iMxrx31iXEN8OXMVk6wUHvffaRdVD0KA5nFrZc+Gm/VzKS0jcs2M0alqZPyGwVlkoagZYA+97qFC9Sjog/9vnTSWM2k+MwBMT2FqO5brKrNjhpzb67FTz50F7T4bPEENOXIiFuYlf6wdzVcjqph7K41MdIa/eJiXjd7Sqrja8lN5lhwDb5xKRT02BE65zHhS2bL4AqUx0qrvIwyb5yrkNRR/2c4ZEUBWzCRcYwb5J1xflsA3VMaGrh0HmMiOJ25SNFEAfCrrtCQ3rDNh8e+AX7qaKMVQlUC1rLxnv4e35Tr6lVig3Jf0kmG3WifihWO7cQHsQJxVgaf4z5xPvHfistK4t9ZQOtBhSPrMt47IVFSUhm3tif8FJ463y/prqHnTEO2weZN5gG8IBn/visCljuHMYDrO8kAAOc6N4wA3LWdxV03OvkRZmtQfDp+CQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-15-39Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698750814.544362,
                        "modified": 1698750814.544362,
                        "name": "/dev/shm/incoming/2023-10-31T11:03:21.ais",
                        "sha256": "a3c1717a0c0a2114f43d360f857f26fc87238190ea945606b2564a5fdfbbbf50",
                        "size": 91564
                    }
                ],
                "mission": "EFCA1",
                "sha256": "278782afd6ac82f58d2c8d9bc01963a68a112cc523c78ca24211113cb13e7de3",
                "size": 21427
            },
            "errors": "",
            "filename": "2023-10-31T11-15-39Z",
            "size": 21427,
            "verified_at": 1698781819
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698751291,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "IV28LoQefXlRHp4oZIZ1SXcAbSw0RlirNpM6FBKgA32QdITUpBf+wXrxC1F3wbo91mfyBRRxF7r/QrQlJJPMMatxc8bv15Ig5LEwpid3BaGsRpMFDhhHgpD+AYh5FmtER9qT+59OcVHE3DfLQROKnn2OpwtebEMkM6RDdIU7kZR6U+9ibWIUuaG8ryhIgHMpLU86uEo4WCaqj89WYUqKkXfuq6UHaNZVMh4333jX8szGXdzUGZol3A/vrxTnQLcdlDDKWxpnKBcas15ivcuKonquCVuOrrYJsSN4re60eBBMwbbVvOBNwnb/QQ9P14WLre2DBpXjchdb3WnICTB+6UsbpDXU1S4p//W3lR77UTdEGdSrkhJyl6wUjcA44Lek+62TD3teboJeZwFSQanPnWjxobSaMBUm4FSqVAn1WTaROEcMAKhhD7xeTUOlxl0fdo1SyYyMeUUPClmLn0/sRGR6YbPQuYbDmbuwUI7AA9E7bg2suDzV0TdWAIZc3kQLNCaNNyxvgPdmZnY7UYcLkBE0Xe2G3fzb3NaRJ/S3aKseXRE92v08GGC75oqKgo8fuvsqb2qzqAUN5tA6Z/R5pOIkIKI7To8yvclpm3LkpD8Nf2IU5YNH5LPPFX6Uh7r0aWbhEM2JBiPr4/X1/71s8ZVNqjjqFq9KvL626OjLN/o=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-21-21Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698751157.0114186,
                        "modified": 1698751157.0114186,
                        "name": "/dev/shm/incoming/2023-10-31T11:09:16.ais",
                        "sha256": "3875b28efe8d2ddd590fe624df93f87e640b60bcf8b3c7d1f6e5664acc6e1757",
                        "size": 232395
                    }
                ],
                "mission": "OSP1",
                "sha256": "a179cf7eb63bddfd1f95e70e2bc7bae6dc3b8b5380826c34818f31e4f5a39bb6",
                "size": 53813
            },
            "errors": "",
            "filename": "2023-10-31T11-21-21Z",
            "size": 53813,
            "verified_at": 1698766468
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698751581,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Lf5YdtCwnqKHME6JdGJf6GLWo7mX/x/dwLGPY4p+TaJ2qFh7kbZN3JyoNyn/2M0cQylNdo2gK1sOM1QDPu2nLcawFlWqDDrrQ0leyvNdKNYXifQ+7ClN7WcQ1DF0Sa6FuhfSarD6+fdwIfdgKedumuYDKSOzxJEE4PaoTU/UUdYK6qiDT80Rlcp47LrkxjmSCfjju37BbmNfG3EGLF4Vw4emObQLVvN0St4I5l88xaCVlq4OSQTRkqxj05xe1NEPEwir9eR3yp4MboeV4X5Vd0BTrwOvJIso4rgV8130mhnWTpZeVFVGYFUGweoYmciZalgcGkI2TupIhv7YMWrFJeW8KujYT6PcPh57wZ4Nz19nzvrmKTUwJdCqRZ+k+zzb//12JzIjaYXe22liA89HkXqmBh+OpHvGtHTsrrjNiAeoFhirKNJQrQt+qe2QbfH2ZFIjizaEfeKl/fRBZTgFhoQunOb6y/IZ6IoCpMPd9jDmSvR8elVTvUnr4q/UXdqXyNHllU7yKwg3NXaJeERFkzbkju1qjc0jDSGvIxtVbHy0AQAq1vZhfujOoP12Ot8p9a2ewHsbmBnv5YxjS5wtELdVBd4h7+/iEsZimEhGieMlA5NUAswOcLx9oIQJfY3Uxb5Riett2Q5reTuGmLY/Xai42s5t5jV7cLXWIZXK0fE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-26-18Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698751449.0397909,
                        "modified": 1698751449.0397909,
                        "name": "/dev/shm/incoming/2023-10-31T11:14:08.ais",
                        "sha256": "07ece0f8a83dbb06aad089154d3383000827eb89f35624a5a369adb28eadcf48",
                        "size": 374485
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "cd8fd0be9fe68ac9555382ece40f420e2e3b09b56b9156328b44d8077a211555",
                "size": 103698
            },
            "errors": "",
            "filename": "2023-10-31T11-26-18Z",
            "size": 103698,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698751235,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "WZ9NZVcdClgH7omVQcx/XP/soAZfOeAlYfIAzoPmLPGhbFzLlc8YcsUQIk8d+quZgzHWXgozmsVX3IF18aadQXy9SGyUe0+EtJrWGc2RXTLN546grOD8CrGaGu7UBVsGPBL9WyafPEHrtno4WDBh3p83t6Z5cziV6TxGcBD5Fop9mrN37iRGUDWPt7PEFAgmyQZbojD8wfhy45Adi3nJ7SYlDV3fXMOXI22gkZzt8q+8nrHJDV1tSF4Rw94EXOVNKi3vfcRk2vleBPV6M+GtvTqr50rfVKD7JeFvobGaC4l94QhMpSd8L6y+qbvEZQ8SzkXrc8lPZKINtLlZ4Ol/GAez/6Uuy1kjpN2FVNpCP4yPFXhEO3HxiYtugzrpgTyio14kWp6sezv3/ToXX7um5CsdrevbLyOr2dzODRdQzKNfeh+JPD2zqWMiG5B23C8RVUr2Q6PXf911eaIkPMi1SpSUA9rnb5EnKkzvEXtZI5OaWQg0NbesFb/GX+DwjTwnr8x/RW/x1svMKGkjidToYeuaXjF6uZDE2RFRiksUkVULVRthMe24GmwZq0wpzIg3Z8Nq1PyIER0Eo6S9AlOmDh+uY866K02SM1mo/WBJSvC307A1T3BH2f1ra4J5y3m7DfQMxI93hAVI+MOKv1RFkf8ZOHl2SH3RDWPO2SNRa10=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-20-01Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698751085.71126,
                        "modified": 1698751085.71126,
                        "name": "/dev/shm/incoming/2023_10_31_111739_00_00_K_139.txt",
                        "sha256": "b13e845c232fdd7b583a0d056a308c154496972d36b318e95a300e136aaff4e9",
                        "size": 2038
                    },
                    {
                        "created": 1698751089.92126,
                        "modified": 1698751089.92126,
                        "name": "/dev/shm/incoming/2023_10_31_111739_00_00_K_139.kml",
                        "sha256": "4935661c17d32338928b59d2d13cd66ef5fd85563c918eb9f48d1ef59f602a7b",
                        "size": 976
                    },
                    {
                        "created": 1698751085.61126,
                        "modified": 1698751085.61126,
                        "name": "/dev/shm/incoming/2023_10_31_111739_00_00_K_139.jpg",
                        "sha256": "1876df31f3104f63b6ca15de12dfd5d5e6c627bd033d92810649cb0388c1bdb3",
                        "size": 1107996
                    },
                    {
                        "created": 1698750991.39126,
                        "modified": 1698750991.39126,
                        "name": "/dev/shm/incoming/2023_10_31_111557_00_00_K_138.txt",
                        "sha256": "da06b5f0c9f7d0a7fe99213f87a9417100925d0406b054faffe9b1227ffba2b8",
                        "size": 2042
                    },
                    {
                        "created": 1698750974.50126,
                        "modified": 1698750974.50126,
                        "name": "/dev/shm/incoming/2023_10_31_111557_00_00_K_138.kml",
                        "sha256": "d31166d7161bf0731494321719c55ee751328a34919fc9da9912862aa3fb0c26",
                        "size": 977
                    },
                    {
                        "created": 1698750973.45126,
                        "modified": 1698750973.45126,
                        "name": "/dev/shm/incoming/2023_10_31_111557_00_00_K_138.jpg",
                        "sha256": "bb575d2faffed2ea3b74b6bfa413ebe33a78f700b30abf62ea571853060be9e7",
                        "size": 718558
                    },
                    {
                        "created": 1698750972.40126,
                        "modified": 1698750972.40126,
                        "name": "/dev/shm/incoming/2023_10_31_111539_00_00_K_137.txt",
                        "sha256": "5eee571078d9b7609883022ffcd57a25634aa109cbe626c3e168dd633be95c2d",
                        "size": 2045
                    },
                    {
                        "created": 1698750962.68126,
                        "modified": 1698750962.68126,
                        "name": "/dev/shm/incoming/2023_10_31_111539_00_00_K_137.kml",
                        "sha256": "b31faba77a10acbcedd1e84a2c2d8f1420895f8ca8267d86ba13af8e8ae120ac",
                        "size": 974
                    },
                    {
                        "created": 1698750969.75126,
                        "modified": 1698750969.75126,
                        "name": "/dev/shm/incoming/2023_10_31_111539_00_00_K_137.jpg",
                        "sha256": "077de8c58ae42a4706106e506335386cc64d271648f05c4d06e033e75a661bda",
                        "size": 1099819
                    },
                    {
                        "created": 1698750889.48126,
                        "modified": 1698750889.48126,
                        "name": "/dev/shm/incoming/2023_10_31_111406_00_00_K_136.txt",
                        "sha256": "ce1cd95f14e271691b2a2a5077dd4b0a31880c85ca2f2c6c38250b3824b356a2",
                        "size": 791
                    },
                    {
                        "created": 1698750868.98126,
                        "modified": 1698750868.98126,
                        "name": "/dev/shm/incoming/2023_10_31_111406_00_00_K_136.kml",
                        "sha256": "71d186a32e13ba570c6c840d54ddbd01fa5e201c1aaf8ab71841b58d956ab74f",
                        "size": 893
                    },
                    {
                        "created": 1698750878.45126,
                        "modified": 1698750878.45126,
                        "name": "/dev/shm/incoming/2023_10_31_111406_00_00_K_136.jpg",
                        "sha256": "4602003cd65505cde5d72b2ef767fbd321e62020c710c3f585c542440749b9cf",
                        "size": 671248
                    },
                    {
                        "created": 1698750789.53126,
                        "modified": 1698750789.53126,
                        "name": "/dev/shm/incoming/2023-10-31T11:03:06.ais",
                        "sha256": "c94f32b06ce273961ca40867eaec2cccb13285be2e2250d5f08d845e87caa535",
                        "size": 277490
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "fdefb0c800c70ee660bde4959557b56e9e3d4cd713235e7c3ee5b2f51e5c801f",
                "size": 3667418
            },
            "errors": "",
            "filename": "2023-10-31T11-20-01Z",
            "size": 3667418,
            "verified_at": 1698768181
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698751692,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "ZJJmNlz0JmhyGAIsowW02HfUPpj4M/tEELWo/JhmGX7/YeyEiH/s9ITjQJohcTLKJh4Al6mibHFQZdoIoLibrRvb/d/tg1AYptCXpDtNuaN2tp4wEltXv7aaGXDlc7edVMGS9cUwV5YTOuDN6vhelNZwz0GW0kwZfwGg2O6LToR0+HE3f7oltqyZVUPpykiE8kIs8NTGBp+IugcNJIFRemK47PYho6h30u7DJaHDFs3ByW7DOa77OOhqnIuYTeiB22pVee29bWdMJ6AstC7PSihvM2MZHJkI7UsEqeTrPipKaX0lG5rFHnrTXGEgQVrlU9ne1gk8GrXS9sSp92/H0G2lafETtF+z0D0YeM6YLAzGO3y3CR6n3yer8yjzdRVlMKEUA7EMUrqnbkX1q80RI96yuOIy3JAVAj1bMPgLifafHCQ1SYJfhRq2ot7ZuAIWkHG5qRKEyr585GLVhgykHCF3QK78khbmUUsYfYxigZDIN/89EnaU33VlLN483xXWIkCFEH2+c73tX7tvqa6KOj6K93y29IRsary6sNBQnEAKrHGBJAh7sdLfGbAbrd+/xR3YG7ipzOc48DaiG8JCILbJeJ3lzLWpjue6cHL0pxlTdLw9GBIOKSlKMrLDMev3krWsyjEbR5+WQemZwXnF4XsMVEvYDev6kP39QyvgOx8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-27-05Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698751502.58126,
                        "modified": 1698751502.58126,
                        "name": "/dev/shm/incoming/2023_10_31_112438_00_00_K_148.txt",
                        "sha256": "6a08ee55f2d674455acaf3c68a5006ac61f306047a63ab258286bccc2e1e700d",
                        "size": 2045
                    },
                    {
                        "created": 1698751509.92126,
                        "modified": 1698751509.92126,
                        "name": "/dev/shm/incoming/2023_10_31_112438_00_00_K_148.kml",
                        "sha256": "cf90440368e8ad0ee29ba5905f949f37eb7600b2ad5bdf27ea4baf80ce499067",
                        "size": 975
                    },
                    {
                        "created": 1698751500.75126,
                        "modified": 1698751500.75126,
                        "name": "/dev/shm/incoming/2023_10_31_112438_00_00_K_148.jpg",
                        "sha256": "6928e8f21fea37f6f97d80c8750ec80734b86ba85adeb514fa930791aa839ea0",
                        "size": 985268
                    },
                    {
                        "created": 1698751499.69126,
                        "modified": 1698751499.69126,
                        "name": "/dev/shm/incoming/2023_10_31_112437_00_00_K_147.txt",
                        "sha256": "855e6286b85944124c932f881e566fe483faffe8f75c8da697e213d08699168c",
                        "size": 2044
                    },
                    {
                        "created": 1698751500.32126,
                        "modified": 1698751500.32126,
                        "name": "/dev/shm/incoming/2023_10_31_112437_00_00_K_147.kml",
                        "sha256": "e23c90d2422a27fdc2d0390359af62e40bb80fbf0621791a9553dc9f7d914da4",
                        "size": 974
                    },
                    {
                        "created": 1698751499.03126,
                        "modified": 1698751499.03126,
                        "name": "/dev/shm/incoming/2023_10_31_112437_00_00_K_147.jpg",
                        "sha256": "dab7f3ba60bac6e2c638a83793b01efe7a13e21f6d0b8e17720c857d62b3f44f",
                        "size": 824320
                    },
                    {
                        "created": 1698751473.69126,
                        "modified": 1698751473.69126,
                        "name": "/dev/shm/incoming/2023_10_31_112408_00_00_K_146.txt",
                        "sha256": "a863aa527fa8c743046d462bc51d3fa81c45e38e71675e021c615f4d715ab2f8",
                        "size": 2045
                    },
                    {
                        "created": 1698751463.54126,
                        "modified": 1698751463.54126,
                        "name": "/dev/shm/incoming/2023_10_31_112408_00_00_K_146.kml",
                        "sha256": "f24e1b2a11dbf516120979f4bee389d670dcfe30fd9b6c257da6b5098d47ea05",
                        "size": 976
                    },
                    {
                        "created": 1698751463.05126,
                        "modified": 1698751463.05126,
                        "name": "/dev/shm/incoming/2023_10_31_112408_00_00_K_146.jpg",
                        "sha256": "e2542e30490262fa67a2fbbb3d716ba109f5db76132c7f130df07a26a6523a6f",
                        "size": 870901
                    },
                    {
                        "created": 1698751440.87126,
                        "modified": 1698751440.87126,
                        "name": "/dev/shm/incoming/2023_10_31_112342_00_00_K_145.txt",
                        "sha256": "41088952ea79d45f891c8fa6efa527fa7c5c4e4538a64d94d149382feb2fe5e3",
                        "size": 2045
                    },
                    {
                        "created": 1698751441.72126,
                        "modified": 1698751441.72126,
                        "name": "/dev/shm/incoming/2023_10_31_112342_00_00_K_145.kml",
                        "sha256": "5c15a130105d0b3b63031b9b521e8e8bb475650d4774a71dbe6e95532019a36a",
                        "size": 977
                    },
                    {
                        "created": 1698751443.77126,
                        "modified": 1698751443.77126,
                        "name": "/dev/shm/incoming/2023_10_31_112342_00_00_K_145.jpg",
                        "sha256": "f9d79979a8afe9a1f404110c72e1f53b3b2421f5624e7f2cb97b5cd069807c89",
                        "size": 765066
                    },
                    {
                        "created": 1698751405.72126,
                        "modified": 1698751405.72126,
                        "name": "/dev/shm/incoming/2023_10_31_112301_00_00_K_144.txt",
                        "sha256": "8bf0c048853ac22f6b365691a4e6bc1034c9715c9ae23c0997226a16fa30394d",
                        "size": 2042
                    },
                    {
                        "created": 1698751411.61126,
                        "modified": 1698751411.61126,
                        "name": "/dev/shm/incoming/2023_10_31_112301_00_00_K_144.kml",
                        "sha256": "9e4cc714552e15385ba4d8d189bdb19ae2054f51444d441286c50a39a94cfb6c",
                        "size": 977
                    },
                    {
                        "created": 1698751404.67126,
                        "modified": 1698751404.67126,
                        "name": "/dev/shm/incoming/2023_10_31_112301_00_00_K_144.jpg",
                        "sha256": "33443b4787c3dda75e28769dcecd46d6868a7f2389fd528685ed28d93e082e21",
                        "size": 211520
                    },
                    {
                        "created": 1698751390.40126,
                        "modified": 1698751390.40126,
                        "name": "/dev/shm/incoming/2023-10-31T11:13:09.ais",
                        "sha256": "58bb31686cd43061100178ea8cd2c5c7b4fed153f1dd366bf6334c43f1efee5a",
                        "size": 273772
                    },
                    {
                        "created": 1698751379.61126,
                        "modified": 1698751379.61126,
                        "name": "/dev/shm/incoming/2023_10_31_112217_00_00_K_143.txt",
                        "sha256": "20b195425db8914bbbb0a0e42351d1f81c685792df2ddc5ad97f82e62b851cba",
                        "size": 2045
                    },
                    {
                        "created": 1698751359.08126,
                        "modified": 1698751359.08126,
                        "name": "/dev/shm/incoming/2023_10_31_112217_00_00_K_143.kml",
                        "sha256": "c4f390940ba3157e471feab0b3093b26a114496d16912cb4e53f1642bc8e4b4c",
                        "size": 975
                    },
                    {
                        "created": 1698751358.86126,
                        "modified": 1698751358.86126,
                        "name": "/dev/shm/incoming/2023_10_31_112217_00_00_K_143.jpg",
                        "sha256": "731185f117d7c608a7138681601667000ba04bbc38480af8a9bf0c2123360eb9",
                        "size": 909593
                    },
                    {
                        "created": 1698751340.55126,
                        "modified": 1698751340.55126,
                        "name": "/dev/shm/incoming/2023_10_31_112153_00_00_K_142.txt",
                        "sha256": "041f9c8d28ede2756fbb7b6782a37a2e485ff0bff153cf41386fda629853304f",
                        "size": 2045
                    },
                    {
                        "created": 1698751338.90126,
                        "modified": 1698751338.90126,
                        "name": "/dev/shm/incoming/2023_10_31_112153_00_00_K_142.kml",
                        "sha256": "50a61d5c2a23c762b4a47bca0c74aca42ecd0a091afaf8eda69a6b77cf105e14",
                        "size": 977
                    },
                    {
                        "created": 1698751341.00126,
                        "modified": 1698751341.00126,
                        "name": "/dev/shm/incoming/2023_10_31_112153_00_00_K_142.jpg",
                        "sha256": "4bc9a78203b44de3d4269917bec7577f2de1c14fb8b1a8c7e910618328bfdc67",
                        "size": 1024096
                    },
                    {
                        "created": 1698751325.61126,
                        "modified": 1698751325.61126,
                        "name": "/dev/shm/incoming/2023_10_31_112138_00_00_K_141.txt",
                        "sha256": "4ebdb11933dbf83a5a783bbdd8f1e82e802d000d8bb84041e86bec8123f477b4",
                        "size": 2039
                    },
                    {
                        "created": 1698751323.98126,
                        "modified": 1698751323.98126,
                        "name": "/dev/shm/incoming/2023_10_31_112138_00_00_K_141.kml",
                        "sha256": "ddd472c51953c7eb337162ceeafcf894f815d2225eb87d954eb4d84594542e63",
                        "size": 980
                    },
                    {
                        "created": 1698751334.65126,
                        "modified": 1698751334.65126,
                        "name": "/dev/shm/incoming/2023_10_31_112138_00_00_K_141.jpg",
                        "sha256": "74f3d5afe0a3b4be360942a597f86f89323d2f84b82a3810ff9aba92d27bfecb",
                        "size": 1174160
                    },
                    {
                        "created": 1698751321.87126,
                        "modified": 1698751321.87126,
                        "name": "/dev/shm/incoming/2023_10_31_112052_00_00_K_140.txt",
                        "sha256": "0ffb9d595a1faa583c4b48e3ece52b43ee57907821fc318c4a441c50b4ccdf17",
                        "size": 2048
                    },
                    {
                        "created": 1698751265.17126,
                        "modified": 1698751265.17126,
                        "name": "/dev/shm/incoming/2023_10_31_112052_00_00_K_140.kml",
                        "sha256": "c41c0b06275add151ad8cba289069f4e56dd861c994aef47be458bea18076634",
                        "size": 979
                    },
                    {
                        "created": 1698751264.80126,
                        "modified": 1698751264.80126,
                        "name": "/dev/shm/incoming/2023_10_31_112052_00_00_K_140.jpg",
                        "sha256": "4f93b06e65ea8c7fe7b0fd3d8b7f99a3b64ed53aaccf514f46c69a73b259b6e7",
                        "size": 945073
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "4c3c3bb9abd6c02145e6b5f239461413271b48a2b64269dc40604f3260b6480d",
                "size": 7755016
            },
            "errors": "",
            "filename": "2023-10-31T11-27-05Z",
            "size": 7755016,
            "verified_at": 1698768232
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698761780,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "Kg8TtkXEuzxWt0GrQnuwP8acA63VIXb3MQjZBSTDAg+kKj6RQeAly+BlJd7Csef6vhnIAGTLPmTwYXIjIB3UBGieK+T8R/qx7UYXIFJCrJi81oV1RgO+rILhutoGlZtNCDKYV6EzZWE9sSSMnU1VHYBYzqzADdqaPMntcsnqJEoGBUJ6ev+x9/P6WMwNl/l4rct/bo9aRy2Xr3MZQaMhpv9aHSDwYVGU+42VN6QizpYASEN0KsPVnAiC3wNliqKZCR/Wm2Oy1vnrhk+pX0uJkfcf0YMU2jU60wYg5qAob6mVWgKN+hy3zVxjHLJcDW+Ns+7niW2x+GoBIa+nTv6EHN57wTZZVyAGOQfsFiTQjx2HfTBLbKOAVRrGkA+x7wbb7lsGT9l7ab36yWDEx445bRoWrPOuIv+qnQRf/Nmxsq4ceXzkKonHOIx8w5DKjd/Ae52VX7SCmsZGtpTiiLsR/AswH2RYGC5DFyHrpVln1GMI5rVp/ivyJZ74R6LrA9sC6Z9JfURdyB2IKKnAHD+1vDrrfLHlOXCrJt62nkoxmrDkTd5fRy6CCPEmiK8ftwHu9yk0f/s8rFS5t0fAptY7SDPPIGK2B6DR/ApUUMJGfgwxTSkDQMo5/A3kmJXHdJyxSY8Ul3fxbWg414VQybLXTqIK66jYEmI4f34vb/EGi+g=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T14-16-16Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698761650.854362,
                        "modified": 1698761650.854362,
                        "name": "/dev/shm/incoming/2023-10-31T14:04:08.ais",
                        "sha256": "cbfd9240f78f21a4424582eae7771eaee3bc11a77b9828ba9b1bd20576481d1c",
                        "size": 45243
                    }
                ],
                "mission": "EFCA1",
                "sha256": "a8d3a86771a0bd1758f3a0e019140727284ca7b2edd9198d04635b5bf65c6b6c",
                "size": 12040
            },
            "errors": "",
            "filename": "2023-10-31T14-16-16Z",
            "size": 12040,
            "verified_at": 1698781640
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698751550,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "pq+ebLZmlQoTKRfgJsA0KPFbsuycV1TTBOYASExe5jy/2u1rBTE67hM3Zh81SAj+DP5Lo3Lt4Yuia7QKX47Y2Z1WZqrpcwaHZxx2oI/+4kD0biKMbW62+KVbL89NlByPgT6vUbHz8qqCCDLGqz5SxwdCw3PheU43/n0EpTXyk0/CeOo6LZCZECulC0tp4qdlI6/pTygCK7QyVzNFDFeCpjjt/ZeCPTcFEVsdSI/quSDTteD9ewPQC9juD6iiHCSqDQA8ZSOK7NgYRltUDQMPfZ5KAyYTGlcQsBmwwNNIcMaqpZVk4sgrFDuslLrIBDzdUFdqcRv5MrKjqmUGEHynCptxZHxI5nWwQ7QQsyFKTZYzV/gWn7YnrZ3gOISh8A7jUPEBHXI882jBhiPmytqsULX4NTzJSlJzfKCVFXLHhDV49bgYGU6XdTtNpIB+jXN08ExNQdNKbNQmAbskBC+vUABIhJ3nbVzKm09kQN2IVHhvJYwK+A60HbzZgmF/hlk3227Jb24Y44iUpPjipNmYBNuBXuW4I71/XsJc/XD7aEyd57i3D99KZzcj55XEIpek2DTFDPxjvdW/G8I6YZEbmUQR4lu4P9Y8R1HC9P8Xr5t3KpytwqCoTkfNmJF0OYnjRq/oh0RlYYBDgJ6hZEz3JhU+kM9651j8MqRHRKaAUOc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-25-43Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698751418.134362,
                        "modified": 1698751418.134362,
                        "name": "/dev/shm/incoming/2023-10-31T11:13:34.ais",
                        "sha256": "589163be35574a0a32d1cedcc626c7c7dd1404bc7d7fa00e6e77322e38e7e4a8",
                        "size": 81840
                    }
                ],
                "mission": "EFCA1",
                "sha256": "3d24c63c84f71e6692dcb8567665d8c5fdec03990e115ab750b2ae979fba9bf1",
                "size": 20049
            },
            "errors": "",
            "filename": "2023-10-31T11-25-43Z",
            "size": 20049,
            "verified_at": 1698781875
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698674105,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "eltEoFW0+jgPc1Jw5iC9i9uz5MW7XoKh5MvOYMd0UaAwzgglmOKPtx0NLj9J+U+IrOPi7XBSkV+l+QuRsapVI6fqHBJztvbfVB3NsLst4LVeZUGFZTxFHjqZTBLAPRldduHZ63KG3vBjYfciTiE+4yJPwHwjk+ZSxrUUOmN/Q5WVJ64dJP3sjTxlQxdhzfqsYUzFY06rsAlvWxIPIaSHMXLMd8YvQyrkge69PNmPyVjs1TDiiJY5qkpmCEXqXVZpE+sa0xTsnM40ONLLtKNFb00B2l7cL1Qs8Tk8HcB2D8EWoeyIO9XIlb+0zPyoYEFDq8+O0Yb8r/0TW6pnAY9ulYP0qdRIyza1V8Z/xnPbIKIi0ozu91kzme30Ug3W2kHQefEvIi4cDZusmku3OQuG111QqOGlA1ido/fhNnuWqrQs1ShIC6K63yv8K+tPVUoXALMuLOfPiUD/6SaA3FH9MwcXRpFWGgDNwNu9sFkkCM8dacvqnMwH0MWzvrGAZHtQgRp112eA1ovO+2++8RsumDt+ntUgFpb1Xv0czl20HNskhGwPYsgDSSYHfFrHI7EOd+hoJAqkffJb/ebUw73oKdRngsndJFV1irfpSWRC2E+tOWw8hnSYNc0xxo250Nh19yL+hrg6409gEVSVudPUFwF9aerUh7FZfFWtjzwRldg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T13-54-04Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698673916.5486982,
                        "modified": 1698673916.5486982,
                        "name": "/dev/shm/incoming/2023-10-30T13:50:43.ts",
                        "sha256": "b3c1c4d980ba2d2bc38972b0b666222a2881556715512472ffb8634c39265f04",
                        "size": 16673344
                    }
                ],
                "mission": "OSP2",
                "sha256": "b26035e5a2b8c42e0b217cc7baa50453b43465a4f5002642f546e94e6811a20b",
                "size": 16006161
            },
            "errors": "",
            "filename": "2023-10-30T13-54-04Z",
            "size": 16006161,
            "verified_at": 1698754578
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698735392,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "HMe7Yp/zoKQxxCvossqiSzdWoepbPFzvqJKrOQ4cCL0XeKiX567fUu4gt4AVEqc39AwCeTizrJXfChN+0CiCHShmirZQOcmbVrOmjs14vQcnJcH/GgMlASYz5xq+hnsLsSSEwOxDmgS0qUuM67RE/IPsAwbc+JCM0OTUYWTY1wJX8RgcDpeJn8FWqxvp9UpWowt9iCanUqSOKB/pFD3bm72ZRE996ZlSH2fnXf9G0XX958nTbo9fbTQ367H3ypshOVvfumnP/PiBQAGG16TCkb88nYKHZfxPpw7oganih3w8MjV8Z7hCMQqW+ZiiWAXGrco/7jCtDzjXqT/R9K2ESdjFD6STmlCOwRMbZSNxuwDpBmFpd4WOIrf0/no/DvwprcWWKMMdsGtRAdbC2t+jY7jcMnAHiEjwusJnJ+lCS4rOY9e2xmUD8A2Yb7g5yut/zRMZXcFv6xV9nYdMvJOsxhqORDdwT3UOUpOC/irfds6DByqu5K+dljm+6lHqgeBNd1a1yDpr20cBsvDISRoT+bTClCjGZY4pPIpX6RVUeThoroEtKo1Ev7HFqYHdo1wnke9BfVQAoMknW/WVpurm7O6dxszgNEM/w9SMkedliZAOEL312H43rQM282xaTJz+Lm7h6MXFpPTUG+xWJFuJGab6g9IfXMlNQv/byF7W1A0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T06-54-21Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698735136.60126,
                        "modified": 1698735136.60126,
                        "name": "/dev/shm/incoming/2023-10-31T06:42:12.ais",
                        "sha256": "27eb4d9a7c9e63d549d893d8cfdc9fbbcc575587456c64579c4a76cdf5f4bc35",
                        "size": 11632
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "ce459ae12f1a0f593ba3aa120bc1773373ed14b915b84f37080ee8288dc166f7",
                "size": 2205
            },
            "errors": "",
            "filename": "2023-10-31T06-54-21Z",
            "size": 2205,
            "verified_at": 1698766608
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698735940,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "XuGNNbDj6yA/LaYfjLOYcpha0NGaHoEiz7d2E/7FMzlOCnF0bEe7xjnmpOqgK3pBhJNdihcXzng6KafxF9luIpux7Auwm68qzGjUuicxjH3236ALlRtw6X1HwYevAgiHy+tcaDsFPfZYyP1dCBPSGtXt27MACzlVEXhVmsWZDvoQ9F48ozZLXGxghd5rAbeMGv3qDVILphdujhl72kPsSia/mwKiVUIqqK1uBJ8jiYLgCcWUxDcBhglPWdLwr3ZIxGRjOscNixtAC1GJOe+tfuvnLU4oFXPFt+0M7I6cj6n+kCw6mYUpiqWz4mdQ0yUcQJvwkT646+VUDnrJ67wp/VUvoCId9rCN+Vyc7KcTUmT+xQ0P50g7LjbHv9duvWFjcJ3tNfjKIUFiVXJRAdYJOelZtEYkdscAt+IpprR6OaOe3iH607jdy4KMqbzk9zO8fOmrdAtmTqn/0/ovkrPkut3v4JRZEHuEh31wx+BboeAZX6gAHVfP4A+4EAY2YtEF1gToAWINCnL2qNCAnwfaBQA8Qw45gRsBMk3K1JWvH49cjD16Eiz53/dn+AX+ki+whFNoHqsC7oJzS6fXFqVqPbzj2F53smDWAEGoLt645jawikDaAFSBqesiN4hDx/t1u11g0PNVVM6ESEa/Cb42QQIP5Vza1bT5cw8PegdLzHI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T07-03-25Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698735737.81126,
                        "modified": 1698735737.81126,
                        "name": "/dev/shm/incoming/2023-10-31T06:52:16.ais",
                        "sha256": "8a41e07d97433ef85da40e431bd3ea15b57451f8ef832bcb64b9ba1e3088162f",
                        "size": 486345
                    },
                    {
                        "created": 1698735794.40126,
                        "modified": 1698735794.40126,
                        "name": "/dev/shm/incoming/2023-10-31T06:33:14.ts",
                        "sha256": "332d8e4794454e92c120db1ee8e876030eaa757c54348834b046403034110594",
                        "size": 441398432
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "f993e19b84b12c4f21954dc8f12c05483de46ec05cc59a8e526c205d401dc783",
                "size": 397366581
            },
            "errors": "",
            "filename": "2023-10-31T07-03-25Z",
            "size": 397366581,
            "verified_at": 1698767093
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698763609,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "b13rYRa2YkeoNJzvyXy6NyqvB6tMrQgTKza1Cew/tfaeSLwkmXs57JyNsWNrT8njcab4iW0rpEzPnVNgZaqBHcppwM4K10Q+03cnVnfQnEHxPGsM8BwMW2tMdZzDetCrphhTV3IJkC6gKx2g0cEijI56zwxyB3p4wFYrIP9aVBa25wBeauc/6asbwqR5Tk4ErZTmb5dxj9Wyhf2REsJqmt64njTJYvm1d11cmc1D1OE5VGtCqaH8M4Z9egX6iovuU+KaGJniuMyrnBkGzXmGimZwl9ItG2j7q1E9NAu+L6FYD1l2u/fnKpZVQl2GZTM77zFIPehd6wGhiETbasdCm4brUJ8nFZlxJ5CyR/pxczfn80v16pC2p+s5PRE97hQLqmVtGrLadgM2bLeGBqyXw+8xFaGaExutf88QCAVuvJITxJNsOIgp/6/qshK088lFLSGzFzwVt+9z1wSlQbDVTWWUwdVyCUrarG2lC/YuRQJAjaZ1k5ZAI9Fqc3MbOpRSNDxqmQCZ351t+VX+ZU3+XrHIfKBAARyH1BAYVpSpceW+PrsnyDSThJVjSfPaoF9bvPgggHoVLqUBfme8BX+X/VSs8Rpd1wIk2n53L9jkuagV8DZ+QU/jbvwWi5T5KONzQ62ZECPjJNukAU30o73kPtxUx1TVEyGZT4hpF0DmXXI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T14-46-44Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698763477.634362,
                        "modified": 1698763477.634362,
                        "name": "/dev/shm/incoming/2023-10-31T14:34:30.ais",
                        "sha256": "ffd3c81c00ae3349c9ce1f7c36775b6f7010a7212cd057ea2b7e575979e893ca",
                        "size": 5909
                    }
                ],
                "mission": "EFCA1",
                "sha256": "4cd7754051f7f1c029fc520d13ac4dc6a487a8fbeb00b3aae196c7d0bdfc8655",
                "size": 1703
            },
            "errors": "",
            "filename": "2023-10-31T14-46-44Z",
            "size": 1703,
            "verified_at": 1698781655
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698755762,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "PGWkQpHb5bbzApKHshB5gOwKOzbGcZsw2sfGtTwO5CF63K5DRsaceZm8AqSaQmQHGMR/3QBduXPf+FnFdVJOkxvhlFMHTJWZ0YW6xXNNgNVLqqIykVKfXIigzgASo/U3UiiskgH1gABOSANB5Bd0tjOYfro/2UyFB+ktziVE+U7EshPmqX8bGvYJHa5L9ELymjf6PCOK4/ugf/4HYhyTz8zyyJjpooXSPcYRazNReIK3Y3Oxnq497sRYfkeueSmIO6TytwPL0a20lxjN+/koEEqBr8O/VufsXWA8tVfHLwITfyaT2AarPHrilAQO+4OKq+WTYiZKYSabirb9CQlA+89b+Rprw9C1i32fumMfquBbG+Z5b0/aOebHNM/mMyeeJDaqsbeQ9Wp6iVVBjI4GIYB9w00lw7GZEKlP8rCZBZ4wE6SD6FSmgna9zMrNgXNup40f3KPwjc9UbsZGFE77C3jCr0f6ZLWinTGn0qlGCR3nWkescnHHwCrhnutOsaI9b5Fmlw6FNoWJjDKlDeQyT5A3geNtrztOFpowTzlPyZPs5O1cdD6jIY/CYluPl4lN4oYGAFY6XVNS5jGfHzwKdUQjK82jensztJPXbIDwmLAqS4khGIG95aQV40hpm38ihPA2AovWdpoRuQd61sJCNy6tZubpBDrAwRU+HxDnZR4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-35-56Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698755631.7143621,
                        "modified": 1698755631.7143621,
                        "name": "/dev/shm/incoming/2023-10-31T12:23:48.ais",
                        "sha256": "61c4217099f4538dbd098c341dcaa7728088716391ee3da76ad391a3324d3c82",
                        "size": 39683
                    }
                ],
                "mission": "EFCA1",
                "sha256": "133b2dcb20fbfab42300bf45862dcac2df624c1f7b18cffd43fa7c1a95883bca",
                "size": 11945
            },
            "errors": "",
            "filename": "2023-10-31T12-35-56Z",
            "size": 11945,
            "verified_at": 1698781660
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698653554,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "v0iO5okKaKThRr3v25uRqdSUEHWhnU4AroT7aQIEiQ+s1WgFCf1tMn6gzO0lu62mZUZRiFRP2VK9SYRsmzxH5hYi52yXMtJDWVh0J/eqIjI/PYlXsE5UfcgwUXf23tjN1pAVVQZh6913jlfVkUeznFVowno6gT3WbMZ3Cbj+EGwZM1TuzNDKnwR6oBcvmZJI0ikzvpLVb4+Y36dpny9MljFgNMRkjsAUvcZAdJRnTCfE+v/0MQ+F94zaH90kUMghUkDqTiDdyurqzr3yQtZBndvCWCQdDSuLOlSVbvip9UI/wzjQH0/M556alMMHGQrM1OuVDClc3VdHKCgykbwK+18kfBzAPuErx01n2DNDOQIHnzZfgTfhHVtABdqf4D0XtySv6pTa5R83X51n1wIIoOH7KyFUUNpNVJF5JI3jCFhS4tP/63GIQEmNbWaOBjv5IYpHLpyVzRPzsbVRpq0L9fXL6XSH5Ys1JrDvQNzYjSGsL8woOtxhf5UfWxlcNncRN+XBotntxbUVQp/2KmBWJSeks3JNK8er98+2096ACoW/0vFOD1MOwtfMhigaT3MQ5KElrwpY0gPpdcpinW8Y3+/qfue5+wZCtmmoXqo2nAagGW6+sz2ErKgqsQLukB1TSvXExJmw6utDCr0YbrQ/gY6tz54H7rB9s7C5ZGxCRxY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-12-04Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698653395.136036,
                        "modified": 1698653395.136036,
                        "name": "/dev/shm/incoming/2023-10-30T08:09:44.ts",
                        "sha256": "7d3b9527f016d36c30b7c41008b0b53b683a7c5640784c06cdae27aa024e3b8c",
                        "size": 126148
                    },
                    {
                        "created": 1698653289.336036,
                        "modified": 1698653289.336036,
                        "name": "/dev/shm/incoming/2023-10-30T07:58:07.ais",
                        "sha256": "debb9cab7431aac639e037e28516e5f7101ddaf55f2e8a5f52336fd293fcb869",
                        "size": 100965
                    },
                    {
                        "created": 1698653378.626036,
                        "modified": 1698653378.626036,
                        "name": "/dev/shm/incoming/2023-10-30T08:04:55.ts",
                        "sha256": "f7342ffe14e2400cc1b21a18e27eb5f60e976126d6b92ad84c3fd1a5f54857e2",
                        "size": 62595916
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "ce7f0b2820de3fabbf04570b1c7e81ff93204a03240bf799d67bbef00b1a70c1",
                "size": 60565823
            },
            "errors": "",
            "filename": "2023-10-30T08-12-04Z",
            "size": 60565823,
            "verified_at": 1698666538
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698665341,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "YephRO0kaHfI0h5CDJCKsq9qfxiirXHG3Z833ozopxTNcteHWb4onZ2+bRS0gzthTINGZ5/a25TLZfxBB/XDuppi5dX+J6BkAgHwme8Ji+aKYrvrDcEbBSQDsrhj5cOgdqvbULEdaD3bn6WAEOF0euUdzgpaDYoXjXTQQNVFcWgQsaAYt0UbljUtyeGLsSHiS/vs7uqQ6Sh//x9HG3RfZQKiw1l96Wi8CuwynaJqeS76IZQesOgoF4MXt67/OsYK7yAbDCD0TNyV6uzlfMi45FKfj58LRIKzJwLeSuOjAIgrq1W94edKRsoSAsEnnNPQ1+irF1xFmW7NT2JncWAVUljZCTfpdF4mSoceZhEVZOhcg1InmgAY9TJ2eyyekGeeviJO3QXZWfXt3oLwVqJYPBxeBDieq7Vsf/sFDnoCqYz8qK5Do9bO7++TCh+OKV6VOZZggQc/Bih1jDDUXW5sJx6PP4QjUk03GWsVyB3W4p9b+pZj75HS412FH1yAXarsQ+qOvzEw4FJ0snIfLASw8QF0GB2xEq1p/SD+y9tHQmB/plJ4NYul2/xTEl3fYaPGPdoEa/uqG6NGisnjN7E5GMA0tnMLXBsx8zUtnGM4h3alCrHmsB+uKsQnnvG8YBTMu3xc6rsAIBmIK6UVSqxS1nJcfudDxq7gopTMZV8g7tI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T11-27-57Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698665153.8079405,
                        "modified": 1698665153.8079405,
                        "name": "/dev/shm/incoming/2023-10-30T11:15:51.ais",
                        "sha256": "5afcb1526d87d084e637ddc7a0c48757d0827921a5aa31a470f209cc22719c97",
                        "size": 295626
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "7a2baf5b77710f445b404a88447e46102d50b640fafe7102d9ea5e279baf2c35",
                "size": 89325
            },
            "errors": "",
            "filename": "2023-10-30T11-27-57Z",
            "size": 89325,
            "verified_at": 1698683904
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698666503,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "mN2PFH08oidrGTdb7Wi/XXj3a0ZOUoYQZeo6z1Mj+90Y40sHOyvDvXqYLZaSEIeTSllybgK9WSdzf+dRSG2gvHh9Yf5OO/zMi99YYqUv4FWOWvE3PtygCN9NZn4m7xUnU61CheIO94xkdNoBBBi45JLZ3081D6Cek4fsBRG6nR5sGlImjHkGW5R30L/fiu7ODUqBzNlpMuqpqqy4XGmGKKWT0eqTgt6VDK3mtAU7VsMDCo1ruWXCfTpgMsxjWiGq/v7rvS4WblGRDyDgQPfpSrA8v77DHwZ0yvVPsq1dNzqjxONrq3YltDoRNrL+vx7QdORsgauweyWt0Aj+j5q/j1qcun+mQbqVPq5fv7NfN16X9HiygG0/6s2FUboqk7+HReEC/ri2/1/urybaILVA+/1BAwQ+43Bf+QrPHoody5uT5jLK5r9leCaZuzjS4uGFzp07qcU52wnX2amQ6J3/FJ3SnRzLmp1wMhk0kjFNXH1K6pJBUmOKZNQxMuGhxCCAWuTwQWl4lc8qD5qzclykHzjDouL0nzYhtH7Tru1NHX1+wwTH/7DZrFqAfbPBCcW4ThqTejtlB5Fvoh1eWRiio9CtPjlScYP1vqJcR+jfjROw2ulBLRZocL80lYMO4wETOYoGmxck8exl6vlOEdpllKogp22lR63ClOQYgSdQ+28=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T11-48-04Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698666356.1879406,
                        "modified": 1698666356.1879406,
                        "name": "/dev/shm/incoming/2023-10-30T11:35:54.ais",
                        "sha256": "f81c2c4d8ba022e3ddd44cc15f6b4dae5608db7f32e0b62a2edb9e1fddab6557",
                        "size": 392331
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "3c47b91eaa6f2a92ffc39a8af4f8b0a6e4ccb98ba0ad964b308f275be0da05e2",
                "size": 116528
            },
            "errors": "",
            "filename": "2023-10-30T11-48-04Z",
            "size": 116528,
            "verified_at": 1698683987
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698661848,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "RCjOlCeUZxrKJRp6o4hXlkO1Dbhy9R95SyNTtpfRBll7SbvMtu6+BCvH//uAJBAkBYCzlswBFGxxvmTBuOr/HMosqTVkD1xT2BPkGso7L+EkwFe0g3+8+OOMmBukYo7PGRYm9g3orGVhONsYB7onXR/lo5WeGzKzMl+wml0zhM7FDEid78BVYz+DyUo/gySTbwIdcCBCnvuILV7INrYLKpV6y5QSD+VhMUJ7tLJQ5GJMNwwi4QzlSG49mj8q709LTRxAwP5GHMAu724ME2fS1OMi1RqNnS2Sl3ATDnEsTxYFW5tpxH3dz1Sg0vS6+1I/nS9qLGfcZd6n/mEqrXpQF6iNhvXnxEvB1MRgP0oZSxd3y9rTRjF9msUUVGlVvxZkA3lCoVb1zArAACef55YH61F3VshG/BNOsBxuh4LaCm39ZrPFBsuK2/E0ELotTuDanivl749cJPsvLShE52nPqWQLjaLoxmWQJDnXS+VLUVBJIBis6Di40X9lugkBUn8iy2KOuBUo60qwep1LjiMHO9x2TNZPefM8I5tp9os/bNrIG3gUux/JOafvb6gc16NoU2B14Bf4MIoIGul1LuJZRCaCQuWA9Qqf8nFON+K082ADSLrREm2ot+Jc4Rb2CQ8oh1CbS+ublYpJCH1WRJb45uUpl6frZppNEvPJoM9dsvc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-30-25Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698661700.7778287,
                        "modified": 1698661700.7778287,
                        "name": "/dev/shm/incoming/2023-10-30T10:18:19.ais",
                        "sha256": "0aff1bdab2c0ebdca86b292d7f088cfb80f7360cd223a0f6f1d6c068cd35f4ef",
                        "size": 675125
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "7c99460f44db7f07ac3ddc9dc6d031a25816f49336373ed483f2d464ea48f2f0",
                "size": 201460
            },
            "errors": "",
            "filename": "2023-10-30T10-30-25Z",
            "size": 201460,
            "verified_at": 1698693383
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698654643,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "PEKgoniWMAN40PA0J9gWdsF7ifsruEz/OrUQLeox3kDYdedUy/KEe0OYg+6EFVu63/1IitTBfH0V2Zet2ff1/UKZI0CMhOiAUoG6pJ8H/o2gxfpwZ/kQ0/QDwGCcL+TmlOGIww182kuZP0ytMjeoeiABJ/tVBmNkzetalIUq63ZLqlpXrwZc3K7SPynoCG7l+UIf9U++cCQ3bNniVTIS8aOUG2QKCvP15Z20ZL48lk5Peph33QTfwVyZ1Znj/KX+ScPXSH5K73D1z0tE9ZpriH9muSl5Nl0EWSYBYwDfpzmy20f8B0q9jLDd4OTWCti9T+i9mhCnQXZiQvMzhDtWIESCJhNknKqs8pj3G1DIH3X4BPvBIh4yR16jsZW9cLovMLOAjO9lKQtv75zcAIBQsYPFysVhGZQhL5Mx/Hqo6jGcmC0CWEhfJD2NnWf2ziW9/NJRML5oDk2S0h9usga6CCV4DyfMia+y4MxU2OTzvkWeS8966BvADfGPERouUPUmyAoHaqb1zc8pTfMiY+zvajiydjWZntskjo/bSuvNgL5Ck+5yLxms01UefDme6NKUqtbhO2SV0Q+cX3aCEnWtIdVdovlLZC+NtfNGbx2xwswWtV9hJ+6L/6lvS2jG6QyzL+fT5oQ3tFW56MDNeiMLl+TquEVDtnyIFtq8WRv54YE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-30-18Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698654491.996036,
                        "modified": 1698654491.996036,
                        "name": "/dev/shm/incoming/2023-10-30T08:18:10.ais",
                        "sha256": "73116c2cc598643ddbbccd0e4c899cbbf6d23d61babd289e7138f85d8c0ffe32",
                        "size": 112964
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "d2ce279f2582127fc8e3188661335a6f06440e49d4d372999ab25cd1dafe0ad5",
                "size": 30459
            },
            "errors": "",
            "filename": "2023-10-30T08-30-18Z",
            "size": 30459,
            "verified_at": 1698666554
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698736484,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "TfGjp9FmdFD07WY/9DzQ6cKQZG+S5IgmDBYdlJivZKKiY0c36cxm0vUKDybzUvrAS3G79mHkMm4o59UxEMqng+6GLZce2lmhLAkYJ7HEpbZdldCynnw62Teikitg1Xj5UhMxHG0Wi/1c9Pw8eAmXZjf8d4TuqUqGMCdz1555odxqf0T8sSGX51MH2XWGbZTjtSGDBin5M4V9OOhfpvGXNfCIK8FNFtJAjmUuLOqXsHZ6QdbbH/Eo3Fuh/imd3aleW2zJ9FhmY/3+chqdNa8+K0bpqsLrKsyjx8yXBY+z4gF/oHHcjEkossHB6/SMRPIA6xp8+as1kmUVro13IU37px9fS9zV5MSpMO/nQcZ4ooNc/T9EDOpuiM53Lvr9FR/lojmuwcnPSlWIehqxB1wfXm/2FZtqILxz0il7JTX6kpYhMCL+4VnO+ddCatJYwg37GSE/ZGT3g+HZMeBsOHLoZuSwEHqiPnIrB5EMXT9nE952nqMYBhi/rIDl3F45gTacMrjkBylF18suay6xtgiMi84yKEpW8hjSyUKhSZkJQ6EXxd7ftRswOv7FQVpEJOEFvzvwRXyeKDggSJMgjc744Wctr7aUMJyQ1ANIBjUF/vNiMJX80SxuTQbHTTFgeH9JOcz5BopVBEutPKx7qsj8JkSsjW0pf2o99qRdDAoxeNU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T07-14-24Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698736338.45126,
                        "modified": 1698736338.45126,
                        "name": "/dev/shm/incoming/2023-10-31T07:02:17.ais",
                        "sha256": "4f4c4d3b44dd0a7ef99d62e449fcec30cccabda4cadee09dac07a9baff0df74d",
                        "size": 873580
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "c88a1921dd6093381b9c7c36da7563d5dd87a63bf2eb8f69ed56f242cce2bddd",
                "size": 272140
            },
            "errors": "",
            "filename": "2023-10-31T07-14-24Z",
            "size": 272140,
            "verified_at": 1698766610
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698737139,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "CmtlbytF02Zm3SApk+6xS3bHe3eZ1PiEg7ck7Oycdk0On08XL6yyz3t68w9oUo2MEBvrwhEG3VbpapUIk7v10oMPNPLR3TUyr5CZ5FdMCXZdtwKDkaUznFLhIAT5uNSVk9Buf/Y8IEwhHBaoPRGIjmrYGBvfEfoCvlEXWwSGVv14m+/vEVHAGjSVOZsfJGxM11XKBHOQcvobXS38aQKNlogSK9y6SPjfbvup+MDb1mUDMn1gTyAsGRVLmt/JtTPFlM23ON4DfOvn+DjV3JJsUuPGJuQbsGDzboAjiHTaqgqNDznEx5Jcw/8g4bHHgRQn871S7KZKB3Z5m6rA4A2fd7E6zqXR77R369u2ZYBpWSip8C0ShGW6XErYaaQ3ZDxj2+PUPkVaLcx+/UcZ1x3tKWAL9PaSLCubIWue0Aj9M8/TYTHvXImE4LWXp5P7gEGxOCul4gwyYM/Y6UAi+rlUlDFpI7LWw/GwgHAzLb/GFbrgif/35w1P7T7fqduTYG/D8CVzKaMfoiLqTTrZ2B3B+EgZiSgvw7eVdybZWTz6dshlIBy1Zmu+tn3EKlf8rpaKNKRiaQqyA7rd8QIixDA89+2exu8CPjEcgC+6lTCWukCrfmBI/+/7qznalRTB0bVqI06pnndQwU+ImZO0ShdJSk6H6h9J5JD8997FMPxryag=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T07-25-19Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698737003.12126,
                        "modified": 1698737003.12126,
                        "name": "/dev/shm/incoming/2023_10_31_072257_00_00_K_002.txt",
                        "sha256": "ac7e7dfb5c078a8ef10442afb8569b4a25d631ff0d1c5a91ce723a90d8a4c7a2",
                        "size": 2047
                    },
                    {
                        "created": 1698737005.97126,
                        "modified": 1698737005.97126,
                        "name": "/dev/shm/incoming/2023_10_31_072257_00_00_K_002.kml",
                        "sha256": "cdcf3d8f74f5622fe22a9fafe20c3579d155bb16916d5320bf65d7de8fb664fb",
                        "size": 975
                    },
                    {
                        "created": 1698737001.58126,
                        "modified": 1698737001.58126,
                        "name": "/dev/shm/incoming/2023_10_31_072257_00_00_K_002.jpg",
                        "sha256": "cc4d3165f099044a8475470d0df32150d776584d9fd516dfb05b3226079c1eb1",
                        "size": 688544
                    },
                    {
                        "created": 1698736939.44126,
                        "modified": 1698736939.44126,
                        "name": "/dev/shm/incoming/2023-10-31T07:12:18.ais",
                        "sha256": "04d5ccc7d0ea4c04b3a2b9149c098857020a5cdaad44bc3a11b14a4bd85ee615",
                        "size": 893869
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "b891e514cb3c7aa6969b2ff4dac8cbe9000a43e332c90c23495cf1ca9be9cc44",
                "size": 963967
            },
            "errors": "",
            "filename": "2023-10-31T07-25-19Z",
            "size": 963967,
            "verified_at": 1698766636
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698762387,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "efzXcDQJqHoKkm9gL38DVaPgT7Rd4cwmmNvquIt3GDX6DpKhExPlfcgKEX64WMWFTyMqwAuHpBsbC8J1iNGpUV6KWD7R+OpHNajbvOKQ9Wuz8GFO092/t6hzGGSJLi/9UVRDRhZbJQ2AIo2eRKdjLYUbUsOVmR+27hpAKR/LvQ0xmPaqzv7pJS3WVHWKxpRIFgt0ljI0ss21rth59R11dL0Z07gRS1VmyYHD9mpQzrQ1zYO3eKUxzoq3ShS4Pio/qd7iXekXSJjtLnkHbSZfGXdd88L1QKUY3MirPYYNZXlcVQMpNtHxNOSIZeCSgSjdnjAwewnO0RELPSeLgNSJhpl91ejzQh7GiIX4FPdAXTvKhKoFUnpIFSszpnkuodhhj5Coq+X4nHrH7xh0HKWCpY8vTz5l8p0+I2zJq6GQw7vLVIKmsO4msZDE3s5UPU0HWvtpUVGbBojy2B+2AnOrp+DydLX/r2SY5HME0JS6A27gDAAwow29YQ/bLCfn66C7CUoREZA0gHROYsxOT6UQ231PlhaODZFrfo2gTXZ9RtQZCJOqSloqvurkMM7qI2Uvd7xsJ9HOBDYrRrok9q0zEKXBOBLWPztKvD3JPEOPoHV7aBb3qfkmlgmWBWJbxJueN68B9HLuxYwVxkDTfS0ahBQObgrvCwSkxxcrOV961Y4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T14-26-24Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698762258.134362,
                        "modified": 1698762258.134362,
                        "name": "/dev/shm/incoming/2023-10-31T14:14:10.ais",
                        "sha256": "74561898a18ac914a452a37c4bfe43ce46e9c7608954fc92943c6a13fe4cff2d",
                        "size": 16530
                    }
                ],
                "mission": "EFCA1",
                "sha256": "803bdcac8fe4182669a44deb3f143252dca7009b5d474eb7dceab3111836c9c6",
                "size": 5099
            },
            "errors": "",
            "filename": "2023-10-31T14-26-24Z",
            "size": 5099,
            "verified_at": 1698781662
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698755472,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "CQjFFKLTwNB52sHD/BTtpOAPpoinAeN0QEU8kDIQFF1gZRcF+afzM5K92kGbK9Mik8YCKxCaajl7bMjnPXg9auRRJ9f9jP7V0efnDMGkXc+9DQbPJx/tFpnaZCGJKYQIfkcrkHq1elVZnUvEPpDznIN6AtxxQNM0rF773TXU0E402OLF2IndJlvo7sSzmyZCdCuy9wxLbBGUZcCkpUkNAp7ttFCmWSfgOJ3ijBVayJM7INZzqjHKLp9JAxkKtiYEGl3EZ8tcckQmh5iN17GzKvRO090ap4Hd6OamG+wJ6sTxog+X8qV/FvsNNSTxMvjr/3TlfNfIXcsj16QhzwTXeFJ733GFaW1lvznnkDOHpw07FAPhqXAbGsel0EedEt5NOX1mbNGLKXUGfYOCtzOcpppBu7pMCUUxJWwMmKYJJSvIHCY3gg+rdvx6b8fwt/fI/emKiFBicHJQHsMTWYB+XdzjsssblFqfpF/nwTZCbio3jOrW6sl43wOtjha1+tmDM3n7OWJKT7r1oxRUCWthH1iNiLSRdtqGCGwMySfrCrzmpv6dlZRlfcxFXupi7q6JJcX9yNfN2z+cC8TAY+a7sB01CkrmAyWvG6dy4YYRLtpUkWHWfwLaevWUDs3zgsuZyfkCryIXk6/YVrHuG5QIY7ODoHm2NzLWs566V+aXUpg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-31-00Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698755333.4643621,
                        "modified": 1698755333.4643621,
                        "name": "/dev/shm/incoming/2023_10_31_122822_00_00_V_150.txt",
                        "sha256": "4e9087910327c87e9ab8fd005625492feaa7ffcbd75a9ac0fca7a1fd03de49ee",
                        "size": 2056
                    },
                    {
                        "created": 1698755335.514362,
                        "modified": 1698755335.514362,
                        "name": "/dev/shm/incoming/2023_10_31_122822_00_00_V_150.kml",
                        "sha256": "1a18519806c505320e46d578746f9539465505bc305dd3898a2bbf94c7313de8",
                        "size": 982
                    },
                    {
                        "created": 1698755336.1843622,
                        "modified": 1698755336.1843622,
                        "name": "/dev/shm/incoming/2023_10_31_122822_00_00_V_150.jpg",
                        "sha256": "e50fea7b8488f1fba154bc993b52ed06321311ed415f9030239d597c6d1a62e6",
                        "size": 778557
                    },
                    {
                        "created": 1698755330.7343621,
                        "modified": 1698755330.7343621,
                        "name": "/dev/shm/incoming/2023_10_31_122822_00_00_V_149.txt",
                        "sha256": "21f38293db0c2b707c32ddfcf26c9b5ce03097bb8e711e1cd681ffd6c0c5f0b8",
                        "size": 2051
                    },
                    {
                        "created": 1698755330.084362,
                        "modified": 1698755330.084362,
                        "name": "/dev/shm/incoming/2023_10_31_122822_00_00_V_149.kml",
                        "sha256": "d1dcba9d35cc74f99d8e967579ca61ab6aa82cc84ffcacc85b6f8cd4ab7fc7cd",
                        "size": 982
                    },
                    {
                        "created": 1698755329.7043622,
                        "modified": 1698755329.7043622,
                        "name": "/dev/shm/incoming/2023_10_31_122822_00_00_V_149.jpg",
                        "sha256": "b214b1f3d6519f3ac92aaae665a4aef59037a8925a08541cf30d73347a5a3e46",
                        "size": 775587
                    },
                    {
                        "created": 1698755317.6943622,
                        "modified": 1698755317.6943622,
                        "name": "/dev/shm/incoming/2023_10_31_122808_00_00_V_148.txt",
                        "sha256": "a3a2b407781dd0afaa2e8f9e6bda5a0f7d61e748131551dde3be6196e79c9611",
                        "size": 2050
                    },
                    {
                        "created": 1698755327.024362,
                        "modified": 1698755327.024362,
                        "name": "/dev/shm/incoming/2023_10_31_122808_00_00_V_148.kml",
                        "sha256": "204694806f4e4ac592894078ee545186565504fffda2b5cee7a7cc97674f7295",
                        "size": 987
                    },
                    {
                        "created": 1698755326.844362,
                        "modified": 1698755326.844362,
                        "name": "/dev/shm/incoming/2023_10_31_122808_00_00_V_148.jpg",
                        "sha256": "241a36ef66c0058c97f00703527dcf5d4c6cd9cf3da39f6c5cd95897ee2e2b08",
                        "size": 961860
                    },
                    {
                        "created": 1698755327.4843621,
                        "modified": 1698755327.4843621,
                        "name": "/dev/shm/incoming/2023_10_31_122802_00_00_V_147.txt",
                        "sha256": "d07ecf0bcc3987a7e1b38565ab67a4ba534b8a7eeb09e0c743618024ba509721",
                        "size": 2050
                    },
                    {
                        "created": 1698755316.304362,
                        "modified": 1698755316.304362,
                        "name": "/dev/shm/incoming/2023_10_31_122802_00_00_V_147.kml",
                        "sha256": "cebce08c41030a4caa4374dc3532ca0e51a72926e9276a51599658185064421d",
                        "size": 984
                    },
                    {
                        "created": 1698755315.064362,
                        "modified": 1698755315.064362,
                        "name": "/dev/shm/incoming/2023_10_31_122802_00_00_V_147.jpg",
                        "sha256": "e6e30066b0a419d4099ee8f751c431c5ac42fc96bf92d274bdb96df9dc73cce1",
                        "size": 907956
                    },
                    {
                        "created": 1698755314.014362,
                        "modified": 1698755314.014362,
                        "name": "/dev/shm/incoming/2023_10_31_122759_00_00_V_146.txt",
                        "sha256": "1cf3c0a163940c2f3e68eda16782b26833c930048fb4f8a6d907a9fbd73024b1",
                        "size": 2049
                    },
                    {
                        "created": 1698755314.1843622,
                        "modified": 1698755314.1843622,
                        "name": "/dev/shm/incoming/2023_10_31_122759_00_00_V_146.kml",
                        "sha256": "004bf0d140ea7e4b3ece51964b47069e17471c881600005f9d2a1a8f0e2aa4bb",
                        "size": 983
                    },
                    {
                        "created": 1698755312.9643621,
                        "modified": 1698755312.9643621,
                        "name": "/dev/shm/incoming/2023_10_31_122759_00_00_V_146.jpg",
                        "sha256": "d134f9d2ac0ed9fa0ced33737993474f2e7d7fe95e9e229f8e650c1f7e04b182",
                        "size": 913850
                    },
                    {
                        "created": 1698755301.084362,
                        "modified": 1698755301.084362,
                        "name": "/dev/shm/incoming/2023_10_31_122755_00_00_V_145.txt",
                        "sha256": "f8ce61fa19893b602c8ad2834844dde4a98e8fdada5bc44c4ad7184cd125ed9b",
                        "size": 2055
                    },
                    {
                        "created": 1698755303.014362,
                        "modified": 1698755303.014362,
                        "name": "/dev/shm/incoming/2023_10_31_122755_00_00_V_145.kml",
                        "sha256": "81fc28cde2a3eaadf0f36320764a375349df068e76761a24b373e96fd4fb33b3",
                        "size": 984
                    },
                    {
                        "created": 1698755298.9643621,
                        "modified": 1698755298.9643621,
                        "name": "/dev/shm/incoming/2023_10_31_122755_00_00_V_145.jpg",
                        "sha256": "ba9523dc7ab57224b71b5a0926b634cff2cd5082da28da002c34bcfbb52d80bb",
                        "size": 917748
                    },
                    {
                        "created": 1698755307.7143621,
                        "modified": 1698755307.7143621,
                        "name": "/dev/shm/incoming/2023_10_31_122755_00_00_V_144.txt",
                        "sha256": "fa7b6552b668b7bfd2bef2344516c47d3e7a9c5740540ebe746fefbc72477b84",
                        "size": 2050
                    },
                    {
                        "created": 1698755297.8943622,
                        "modified": 1698755297.8943622,
                        "name": "/dev/shm/incoming/2023_10_31_122755_00_00_V_144.kml",
                        "sha256": "9a40a0b67f9d4308efdb2f16201d37fd035004f4d786abc262f454e91f640113",
                        "size": 983
                    },
                    {
                        "created": 1698755298.824362,
                        "modified": 1698755298.824362,
                        "name": "/dev/shm/incoming/2023_10_31_122755_00_00_V_144.jpg",
                        "sha256": "953114c5f5a3f93a4ed55cce70564d42bdbc410bd639c161531c483cfb46788f",
                        "size": 935742
                    },
                    {
                        "created": 1698755296.854362,
                        "modified": 1698755296.854362,
                        "name": "/dev/shm/incoming/2023_10_31_122751_00_00_V_143.txt",
                        "sha256": "bf8a3a5b3d8537ea5bffa631562f60f01c0ec4cf3e81c5da19db044238465707",
                        "size": 2050
                    },
                    {
                        "created": 1698755296.6743622,
                        "modified": 1698755296.6743622,
                        "name": "/dev/shm/incoming/2023_10_31_122751_00_00_V_143.kml",
                        "sha256": "4a2ba08cdcd13763acfb45fa508f540b50fa5e9160ab40e6b53aeea11757e7e8",
                        "size": 981
                    },
                    {
                        "created": 1698755296.014362,
                        "modified": 1698755296.014362,
                        "name": "/dev/shm/incoming/2023_10_31_122751_00_00_V_143.jpg",
                        "sha256": "b235a0dd53fdf9b740042607f7bae2e2bc95e771a538bbd65ff4f6fd8d0da8d0",
                        "size": 989747
                    },
                    {
                        "created": 1698755294.9543622,
                        "modified": 1698755294.9543622,
                        "name": "/dev/shm/incoming/2023_10_31_122749_00_00_V_142.txt",
                        "sha256": "28c7d4031d8795e47d2b7c9ad0b7bb362637576a36b84fc5364e25ae1c717b00",
                        "size": 2049
                    },
                    {
                        "created": 1698755296.9543622,
                        "modified": 1698755296.9543622,
                        "name": "/dev/shm/incoming/2023_10_31_122749_00_00_V_142.kml",
                        "sha256": "fe7c11fd6357a2276dfd68a05e644396ea1e899ea720c4575634e6b0eba258bf",
                        "size": 984
                    },
                    {
                        "created": 1698755294.564362,
                        "modified": 1698755294.564362,
                        "name": "/dev/shm/incoming/2023_10_31_122749_00_00_V_142.jpg",
                        "sha256": "5bba4ae91212732422b77e1c6b299efe457e7420fb7e80b4aa05c7b6f8908c90",
                        "size": 1020811
                    },
                    {
                        "created": 1698755289.034362,
                        "modified": 1698755289.034362,
                        "name": "/dev/shm/incoming/2023_10_31_122741_00_00_V_141.txt",
                        "sha256": "9de562abfa86ed0b7bcabd926db358e8286c4e03200e93bc7ff44d6aa872a6a0",
                        "size": 2050
                    },
                    {
                        "created": 1698755291.634362,
                        "modified": 1698755291.634362,
                        "name": "/dev/shm/incoming/2023_10_31_122741_00_00_V_141.kml",
                        "sha256": "8808fab2c7679c256f2f7b6d75d0fead649b311f05ae278158c00131865bf5d4",
                        "size": 982
                    },
                    {
                        "created": 1698755293.754362,
                        "modified": 1698755293.754362,
                        "name": "/dev/shm/incoming/2023_10_31_122741_00_00_V_141.jpg",
                        "sha256": "03e3c7765ff8215917d2db93f69f3febf98d282b2ee488100c6880334a551ea9",
                        "size": 673323
                    },
                    {
                        "created": 1698755280.844362,
                        "modified": 1698755280.844362,
                        "name": "/dev/shm/incoming/2023_10_31_122736_00_00_V_140.txt",
                        "sha256": "b9caa02b462055e36315a2ef1547cd601ef56e0ecd1ba89149c064eaf49a7881",
                        "size": 2050
                    },
                    {
                        "created": 1698755280.1843622,
                        "modified": 1698755280.1843622,
                        "name": "/dev/shm/incoming/2023_10_31_122736_00_00_V_140.kml",
                        "sha256": "ddd86f3bfb67b5018675f24fda5a8c1cc5fd110ac7b4cc83da1efd0d187f2785",
                        "size": 982
                    },
                    {
                        "created": 1698755279.6443622,
                        "modified": 1698755279.6443622,
                        "name": "/dev/shm/incoming/2023_10_31_122736_00_00_V_140.jpg",
                        "sha256": "b0b76af02358ce89e263b058735b4c2fe30ac95df67806bc0500b05d0ac2b452",
                        "size": 672844
                    },
                    {
                        "created": 1698755269.614362,
                        "modified": 1698755269.614362,
                        "name": "/dev/shm/incoming/2023_10_31_122724_00_00_V_139.txt",
                        "sha256": "f9a0ae9ee47b926e8d6db12a82eafb14e23336b2775743ad42fcb4f85805de82",
                        "size": 2050
                    },
                    {
                        "created": 1698755268.1543622,
                        "modified": 1698755268.1543622,
                        "name": "/dev/shm/incoming/2023_10_31_122724_00_00_V_139.kml",
                        "sha256": "212f811c1d083e500d8a583f6da7bac87daf2006e9e848168d832b6f74ed9411",
                        "size": 981
                    },
                    {
                        "created": 1698755268.074362,
                        "modified": 1698755268.074362,
                        "name": "/dev/shm/incoming/2023_10_31_122724_00_00_V_139.jpg",
                        "sha256": "a3c79b539eac06e68848729d49845abb3d1d6d4db71f725aca44d0943bed52a5",
                        "size": 829861
                    },
                    {
                        "created": 1698755266.054362,
                        "modified": 1698755266.054362,
                        "name": "/dev/shm/incoming/2023_10_31_122720_00_00_V_138.txt",
                        "sha256": "412c82bae8c897e0d3fc6e238913bf08a7e8a9de876850139c225c82b7b14828",
                        "size": 2049
                    },
                    {
                        "created": 1698755264.314362,
                        "modified": 1698755264.314362,
                        "name": "/dev/shm/incoming/2023_10_31_122720_00_00_V_138.kml",
                        "sha256": "95ed3d0e5a6f5ad19c14f316d5186c09a5aacbd985690598c643a225cb1328ce",
                        "size": 981
                    },
                    {
                        "created": 1698755265.274362,
                        "modified": 1698755265.274362,
                        "name": "/dev/shm/incoming/2023_10_31_122720_00_00_V_138.jpg",
                        "sha256": "b222bc1668e62e44d43f89c732b564d40b51778ab4579e6c5f0ff896930786cd",
                        "size": 863597
                    },
                    {
                        "created": 1698755155.864362,
                        "modified": 1698755155.864362,
                        "name": "/dev/shm/incoming/2023_10_31_122541_00_00_V_137.txt",
                        "sha256": "26cc90be30602797a6caf62ed6cf9e53f570b6e905a59ceaa1088901a50355d3",
                        "size": 2051
                    },
                    {
                        "created": 1698755157.494362,
                        "modified": 1698755157.494362,
                        "name": "/dev/shm/incoming/2023_10_31_122541_00_00_V_137.kml",
                        "sha256": "4cd772a0477332c456caca97440583c5d928c37e9ab8a1c32bbcc902cb272d81",
                        "size": 979
                    },
                    {
                        "created": 1698755165.064362,
                        "modified": 1698755165.064362,
                        "name": "/dev/shm/incoming/2023_10_31_122541_00_00_V_137.jpg",
                        "sha256": "60e28c43905ba66b9e23c3ac662c95009ff76919debae43c78f14ebc0deacb74",
                        "size": 1050227
                    },
                    {
                        "created": 1698755145.504362,
                        "modified": 1698755145.504362,
                        "name": "/dev/shm/incoming/2023_10_31_122504_00_00_V_136.txt",
                        "sha256": "49f9a73a198ad49464b75ba75b3bd6fa95d89704bb2a1bea59f3e084264c5ddd",
                        "size": 2053
                    },
                    {
                        "created": 1698755141.814362,
                        "modified": 1698755141.814362,
                        "name": "/dev/shm/incoming/2023_10_31_122504_00_00_V_136.kml",
                        "sha256": "4e23b54cad389c2b1ac15c77ff316d5fe100518503e76fd7bd77e1cf4a4aac46",
                        "size": 982
                    },
                    {
                        "created": 1698755131.7043622,
                        "modified": 1698755131.7043622,
                        "name": "/dev/shm/incoming/2023_10_31_122504_00_00_V_136.jpg",
                        "sha256": "90edf5d3eca772898cf319ac94cb395facbf22f896a339cd9e592e8a2abb3c51",
                        "size": 637694
                    },
                    {
                        "created": 1698755130.4243622,
                        "modified": 1698755130.4243622,
                        "name": "/dev/shm/incoming/2023_10_31_122501_00_00_V_135.txt",
                        "sha256": "624c6924ffdbfddadfee20024d8df60dae0b33179aab720f77d7657f64fd5474",
                        "size": 2053
                    },
                    {
                        "created": 1698755129.8943622,
                        "modified": 1698755129.8943622,
                        "name": "/dev/shm/incoming/2023_10_31_122501_00_00_V_135.kml",
                        "sha256": "cc9860482e81ddeb83bdab7d7f435958c65f5b8145107bea01178b0b519e00ef",
                        "size": 982
                    },
                    {
                        "created": 1698755129.784362,
                        "modified": 1698755129.784362,
                        "name": "/dev/shm/incoming/2023_10_31_122501_00_00_V_135.jpg",
                        "sha256": "c0647674e3f9b5091adb5e24507ae70c8f63e6dfd8394e403ddee9f09c5428c0",
                        "size": 634158
                    },
                    {
                        "created": 1698755128.7343621,
                        "modified": 1698755128.7343621,
                        "name": "/dev/shm/incoming/2023_10_31_122459_00_00_V_134.txt",
                        "sha256": "c0ae81bbdc89ba02b6a09baa91cb8cfe49e0a3e3c742d72f1ad70ce1cf185d0a",
                        "size": 2053
                    },
                    {
                        "created": 1698755128.364362,
                        "modified": 1698755128.364362,
                        "name": "/dev/shm/incoming/2023_10_31_122459_00_00_V_134.kml",
                        "sha256": "2b7fd1714a19b792a33d647b15b8b534a2970f1b474e01b3af9b63a6a500670e",
                        "size": 982
                    },
                    {
                        "created": 1698755127.794362,
                        "modified": 1698755127.794362,
                        "name": "/dev/shm/incoming/2023_10_31_122459_00_00_V_134.jpg",
                        "sha256": "c9a83629d3a771bccd5313cde5b48f2d6064188bf775eb483379b39451b4c47a",
                        "size": 639088
                    },
                    {
                        "created": 1698755126.7343621,
                        "modified": 1698755126.7343621,
                        "name": "/dev/shm/incoming/2023_10_31_122456_00_00_V_133.txt",
                        "sha256": "5de356c251e026bb690798027333a451d7c2cd74fe0f5c7ba2ab09fb468d3ff2",
                        "size": 2051
                    },
                    {
                        "created": 1698755126.1443622,
                        "modified": 1698755126.1443622,
                        "name": "/dev/shm/incoming/2023_10_31_122456_00_00_V_133.kml",
                        "sha256": "c23117026196e83d6ad0265e92297601db3c45824e781b498770f1b47f536651",
                        "size": 980
                    },
                    {
                        "created": 1698755125.6643622,
                        "modified": 1698755125.6643622,
                        "name": "/dev/shm/incoming/2023_10_31_122456_00_00_V_133.jpg",
                        "sha256": "8b033f2b363c2df4adeb831b9815b1e389db416464edfa8e3ecebb910430c53f",
                        "size": 622067
                    },
                    {
                        "created": 1698755127.884362,
                        "modified": 1698755127.884362,
                        "name": "/dev/shm/incoming/2023_10_31_122454_00_00_V_132.txt",
                        "sha256": "710641e16128558572970208571524d4ce3f5b9179c3cbfcf4e0ed5cfa767790",
                        "size": 2052
                    },
                    {
                        "created": 1698755121.1443622,
                        "modified": 1698755121.1443622,
                        "name": "/dev/shm/incoming/2023_10_31_122454_00_00_V_132.kml",
                        "sha256": "9d58a94dbcc498ec5c1ceb239d28966e6b30ae5fdacd5b5da3c6794de2f985fa",
                        "size": 981
                    },
                    {
                        "created": 1698755118.354362,
                        "modified": 1698755118.354362,
                        "name": "/dev/shm/incoming/2023_10_31_122454_00_00_V_132.jpg",
                        "sha256": "470fbd106628ded96b594302c17f83f7fc642de1198fb86cd0e7de343f2a79d8",
                        "size": 632328
                    },
                    {
                        "created": 1698755111.854362,
                        "modified": 1698755111.854362,
                        "name": "/dev/shm/incoming/2023_10_31_122444_00_00_V_131.txt",
                        "sha256": "4949ac5760fb9147d6028a82d044550bb03b5572669978dcf008ed37583103ee",
                        "size": 2052
                    },
                    {
                        "created": 1698755112.764362,
                        "modified": 1698755112.764362,
                        "name": "/dev/shm/incoming/2023_10_31_122444_00_00_V_131.kml",
                        "sha256": "24f312ea6d8c8a37020376f7909a7cefba04560d5e3219005c3ab7a01067b8c8",
                        "size": 984
                    },
                    {
                        "created": 1698755111.244362,
                        "modified": 1698755111.244362,
                        "name": "/dev/shm/incoming/2023_10_31_122444_00_00_V_131.jpg",
                        "sha256": "65b527805b88b7e647e71da540762ff6893f572437f212582bbfabcc8c307fca",
                        "size": 899054
                    },
                    {
                        "created": 1698755099.494362,
                        "modified": 1698755099.494362,
                        "name": "/dev/shm/incoming/2023_10_31_122443_00_00_V_130.txt",
                        "sha256": "0b1f4f7cc2ff334c232d4bbd132fb9360ac9e1575f4467de684d2c5b255a1d39",
                        "size": 2052
                    },
                    {
                        "created": 1698755100.034362,
                        "modified": 1698755100.034362,
                        "name": "/dev/shm/incoming/2023_10_31_122443_00_00_V_130.kml",
                        "sha256": "c45d36de51fc47b102214dca35283efc7eef35696872f7ccc9693e6c94949442",
                        "size": 985
                    },
                    {
                        "created": 1698755098.4643621,
                        "modified": 1698755098.4643621,
                        "name": "/dev/shm/incoming/2023_10_31_122443_00_00_V_130.jpg",
                        "sha256": "19cac1e43e9ffdd1abc2d0ee9224d019f32cec67f4fe88dceb41f712ab3ccecb",
                        "size": 963365
                    },
                    {
                        "created": 1698755097.4043622,
                        "modified": 1698755097.4043622,
                        "name": "/dev/shm/incoming/2023_10_31_122420_00_00_V_129.txt",
                        "sha256": "01ba3d6371e08a582b46aaca3d91c49448fdf7c3af1b5e8758d7103786104c17",
                        "size": 2053
                    },
                    {
                        "created": 1698755090.374362,
                        "modified": 1698755090.374362,
                        "name": "/dev/shm/incoming/2023_10_31_122420_00_00_V_129.kml",
                        "sha256": "ecf6e9f3a1040f4ce3fecd9f21e56aa4a91b0ee4e151bf0fae08c280666d487b",
                        "size": 981
                    },
                    {
                        "created": 1698755085.524362,
                        "modified": 1698755085.524362,
                        "name": "/dev/shm/incoming/2023_10_31_122420_00_00_V_129.jpg",
                        "sha256": "d4f0271fa412e42df7630a71a0e163f3ff1d0ac48f5bfe699aa96d95598764f8",
                        "size": 1006794
                    },
                    {
                        "created": 1698755083.304362,
                        "modified": 1698755083.304362,
                        "name": "/dev/shm/incoming/2023_10_31_122411_00_00_V_128.txt",
                        "sha256": "1eadb79b4f2b8eb27f136b3f04b80b8fdd75231b777ebf4373d1d739cdfa468b",
                        "size": 2054
                    },
                    {
                        "created": 1698755072.524362,
                        "modified": 1698755072.524362,
                        "name": "/dev/shm/incoming/2023_10_31_122411_00_00_V_128.kml",
                        "sha256": "d70375536f85d3ab9300fccb8af0fea543b2772e09ce081282ea61605fde26b9",
                        "size": 982
                    },
                    {
                        "created": 1698755072.104362,
                        "modified": 1698755072.104362,
                        "name": "/dev/shm/incoming/2023_10_31_122411_00_00_V_128.jpg",
                        "sha256": "85f65997668d01b0f3ee6c528130c19682317e4dfccd17e295f051d0db5a343a",
                        "size": 840852
                    },
                    {
                        "created": 1698755070.4443622,
                        "modified": 1698755070.4443622,
                        "name": "/dev/shm/incoming/2023_10_31_122408_00_00_V_127.txt",
                        "sha256": "15f7b87bc4a3330cdff3d8dadca4edcd44b4580a976a7f805907e2fd2f4ac49e",
                        "size": 2053
                    },
                    {
                        "created": 1698755070.084362,
                        "modified": 1698755070.084362,
                        "name": "/dev/shm/incoming/2023_10_31_122408_00_00_V_127.kml",
                        "sha256": "d88fd08a4514d5f03531885f343d22b567ebd381f843ffb8fc6ff766f833b17d",
                        "size": 981
                    },
                    {
                        "created": 1698755069.744362,
                        "modified": 1698755069.744362,
                        "name": "/dev/shm/incoming/2023_10_31_122408_00_00_V_127.jpg",
                        "sha256": "ffd96ae795dd619edbc5588173bb2066ae5c1179eee115af1d746de410a20c13",
                        "size": 905787
                    },
                    {
                        "created": 1698755061.544362,
                        "modified": 1698755061.544362,
                        "name": "/dev/shm/incoming/2023_10_31_122359_00_00_V_126.txt",
                        "sha256": "bbaac5a55b26b9ebe9602e3af0ec3ae970578332e865d83548860cee658e54c2",
                        "size": 2053
                    },
                    {
                        "created": 1698755060.284362,
                        "modified": 1698755060.284362,
                        "name": "/dev/shm/incoming/2023_10_31_122359_00_00_V_126.kml",
                        "sha256": "a7dba80da2fa4306129a66f648527c324c4e64d733288270850579b44be9611b",
                        "size": 982
                    },
                    {
                        "created": 1698755062.034362,
                        "modified": 1698755062.034362,
                        "name": "/dev/shm/incoming/2023_10_31_122359_00_00_V_126.jpg",
                        "sha256": "b294d1d829eeaea60907053763c5476c6da8777cd1da2cbcfcbc6eb7e05cc0b0",
                        "size": 840168
                    },
                    {
                        "created": 1698755028.1743622,
                        "modified": 1698755028.1743622,
                        "name": "/dev/shm/incoming/2023-10-31T12:13:47.ais",
                        "sha256": "51d6506e8cba92e0883567e6939f98d85a6be099e04c7e6fcf69c5f0ceb98816",
                        "size": 35409
                    }
                ],
                "mission": "EFCA1",
                "sha256": "441103a0be87b6dd92b15d0f1f7fa4abe444b609d9d19c54909034f01ad4e123",
                "size": 20785349
            },
            "errors": "",
            "filename": "2023-10-31T12-31-00Z",
            "size": 20785349,
            "verified_at": 1698781700
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698757333,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "oJJnk+yrM0cLqK2Jg39bUlg9NevlwtcMhJ5gAiAw8DVmYP5KNkHkF7TVcNjyVVKdvdahEGAfhBs6ekFdvBPskkDVe1YCmFalqFs5GSpgyW7tAr8sBHzm79q/focG6jet+aEGFKK2gJUGPBoW4E8WQLyHq8BX9XCTHrsL7MveXoBxKhxWR4hXRUfw9CSbiEt2UCa6YQ67ajlwpdiGB6c7QQgdcD0QX+Gmz0kx8XERIeCAh/Oq3daYPSsI5Z9V9Dt/Mwz6q+L3QL2V+cEi1ndbLR3G17kGzgyYPmKCNigFMObBPHxVS8XFMkq1ukiwvxIyHQDNNUd9vnU4VVJsvDxte8WAtBDsFFtvXTafTh1HOThzO9Zf9ow6J7pfGy4gTjssFGAS05YWLyqOz16fsj16tNSvxUVdaRiri693AnZ9nlGI+9kNsKUy4VOa0sYGtqvCTq1uqULHBTnQy8yZ4cEbLzX+aWAzsXlZN1fW+sZOQcN6gyFwdZjZJB7/M3e7rzLUn4AtUNEoZmSkSvfWiUQUkLaKbsVihaUovYWiOAGh47F94tEQmkOK5ycADfvQkvcf2qH9HNy1pRyooabrP2W69ogEdiXs+wMxIem54yg6FVEA69UcbkRCv3rGyha0WHnDt7fiGv5OOVEtTJRlqu30+lzGwmW80mr2GOIcKWvoY04=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T13-01-19Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698757271.6843622,
                        "modified": 1698757271.6843622,
                        "name": "/dev/shm/incoming/2023-10-31T12:31:11.ts",
                        "sha256": "9ab2c5d73bcc383753fff2d6726165ce13c861a223a2103cae023d401e1436f0",
                        "size": 410434080
                    }
                ],
                "mission": "EFCA1",
                "sha256": "d397de2e388727ba8fd8094de7db027f0df104fbaad86ec96c897198b01e3f22",
                "size": 395204816
            },
            "errors": "",
            "filename": "2023-10-31T13-01-19Z",
            "size": 395204816,
            "verified_at": 1698781761
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698753959,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "pbakr9n0Ku6nRLlGEdOtvH03gjvHuuAnSMJR3byYcZeYeIRaMVNd9ns2USIiDNOhIw6pJJvi4CqYpMBe2cNomAbMN0oI4SZbiePDcnvJ+3ZN1NIHj/Lo/ieFxskD1qOK2QaMaIGu2zl3qp/yhd+o/vEuRl4rK2a3S+cIloz6MnH/57g9idf43ivyNaDdQfaJqAdkx+dhhS2wztW1sqxSn92Z/i4Kr85I39vH42QG/1rj5k5+D6LPxfwz7i+Q/R3crBHnBYEfWpNiXA8pc0e5FZuFKLfYyWptwkSm1xQvKmNcLmBb5jHjDl4xgrilSTOJ6hT2Hix4aWllL5k8fAZq56ctGJ2S1b4dbjB7ZNW67IO659oRzrgZiNlgQrRi6vb0/rcCJAo7WODfr76v9D65DYKr+5KCmG7V0hHISC4b6w3+YU0/VyFlslfB1shWaTYVWpf9l+CkhZf4R34HuxX5wemaoXQi4YfnAB/zfFSaOV/i8BbF7k1AWhEHGbDh49TmdaXfdAjizdsqL7Di1kX3GZv/k4eNZKgotUHJRHZNLFSy7nMsLzQ+b9HK0g2gpo9ezVTHFWDSwQjUnLg7scXoUIxGZvYPTsZsvWK+wNsif56WrDXuOuEY8hvIaFu/Te6aNnhMxnH0DMG/yRssVmUB79muEosd6zfI9CnFfiu7IO8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-05-50Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698753824.504362,
                        "modified": 1698753824.504362,
                        "name": "/dev/shm/incoming/2023-10-31T11:53:42.ais",
                        "sha256": "8745114a1c317d9caa5e2fa39abde243616982a19c498b3330b9b30b9b537a22",
                        "size": 38255
                    },
                    {
                        "created": 1698753798.9143622,
                        "modified": 1698753798.9143622,
                        "name": "/dev/shm/incoming/2023_10_31_120254_00_00_V_075.txt",
                        "sha256": "d653cdb1887c25b91df41e39aa4deb75b69b8e3c23fe6b6559eaa0b17adb48ef",
                        "size": 2050
                    },
                    {
                        "created": 1698753798.7143621,
                        "modified": 1698753798.7143621,
                        "name": "/dev/shm/incoming/2023_10_31_120254_00_00_V_075.kml",
                        "sha256": "fb1efdd58ef0aca43f0ccf9d8ed1acbd8e986784bf3b81dc3328d77696824219",
                        "size": 983
                    },
                    {
                        "created": 1698753799.074362,
                        "modified": 1698753799.074362,
                        "name": "/dev/shm/incoming/2023_10_31_120254_00_00_V_075.jpg",
                        "sha256": "3e8f77ad8be252aa8c740da5333f22a1c571b0e1d9a76f55b0d2783acfb2b356",
                        "size": 1053442
                    },
                    {
                        "created": 1698753796.804362,
                        "modified": 1698753796.804362,
                        "name": "/dev/shm/incoming/2023_10_31_120251_00_00_V_074.txt",
                        "sha256": "6894450f9e0e228c666c00a7d14b749b15783122e1ce15f55cd9e25545e22c62",
                        "size": 2050
                    },
                    {
                        "created": 1698753796.294362,
                        "modified": 1698753796.294362,
                        "name": "/dev/shm/incoming/2023_10_31_120251_00_00_V_074.kml",
                        "sha256": "cd37b7efba21f5179aa1d9187c500e14c2ea373824b956c090b44321639e8f0c",
                        "size": 984
                    },
                    {
                        "created": 1698753795.604362,
                        "modified": 1698753795.604362,
                        "name": "/dev/shm/incoming/2023_10_31_120251_00_00_V_074.jpg",
                        "sha256": "01bed0f38256a1b788a4419d41fe883992250c95648f2364f150f2247bba2b37",
                        "size": 966509
                    }
                ],
                "mission": "EFCA1",
                "sha256": "a9d89f3350deea82b7ada39451912f1960d322a1f5cad7359f2506fde5edd106",
                "size": 2032869
            },
            "errors": "",
            "filename": "2023-10-31T12-05-50Z",
            "size": 2032869,
            "verified_at": 1698781769
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698750336,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "hK9rYvB53yGxhlOBR95XAQ0rjWekB63GhLXZ5vkRv/po98e5etT2A10yjkZ5samz+K/xfQd+KHdUb19mYvSmtJmMgQsooBHZkDZsmY/LUeoQHPC/Ieru5J7tLtEb4Qn0aUS4vytGOxgizPDmFqchaNEkFRQEjJOOmJHzd9624fAfwOnVA6jcLAlYgAfoxBYG/KzVEgS80ZCDpe02rpM4VeO7wVTniqW9t2PoYQJV9WE+NnS5bV1KMSN/IzihYEaUNciTA0/NauinLBZUL/lnW2l54eHNSWW0XmkEbw4lma40hJelOnv+IRGXGAgxccyZNQZ7PjXJZHpcNKoovbk0/I9gM+sGkiQtjj8b5HPI48cB0Xrw1y8nPDD815uXYIBMSDntbFzH7yo8NpmtcJw4po/h758hQsJlKyCDTU+a6ARKqLIZsyLGvXZ9gLpc3kN3+3ANaJpGUkVuAlSXKC0Xc+gw1OInuXEzm+RKHVAt7vwAE62YhtD0GjWUYiJODMXZTOyaS8wQiXL8z5VnfWubY+YldtCmfvFBvGWSLuTxx1j4Ns8sgWTqnXUMPhKHzCIF1D5e9knFqZHpcQ1Z7JFc3tA+HLqn6UrgMPF+DEsPDy77ugzgj+mhoBXrD4RssRRXbzqiZtpeMhlHsiAFGvyowj8Y4sIKAvVAiR3yFg3jgcM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-05-29Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698750201.114362,
                        "modified": 1698750201.114362,
                        "name": "/dev/shm/incoming/2023-10-31T10:53:19.ais",
                        "sha256": "6f9833a15d545d6a47d0a622a7b8a45b0431847d40a6d0c36c28bbe986ef149e",
                        "size": 80241
                    }
                ],
                "mission": "EFCA1",
                "sha256": "21bf1e60b591058da772c313e94c2a940b8235675c2abc2e8646d41bbf827399",
                "size": 18640
            },
            "errors": "",
            "filename": "2023-10-31T11-05-29Z",
            "size": 18640,
            "verified_at": 1698781838
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698670713,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "JZIHJ+UGp7vbe7Sa1mzZIbsNmJhLu+49Sc3qd4rwZFLLnA+9W+4YkOKt+SGEFfqwFeDxZ9ETSXeD40bn2SOs6gEQuuRvs2qavV7M8DVcFNpK4C8iWt4c8omPOjoSONIhKsxUYkKEal50KI0eZ2lrFKfiz9a5Jf2N629qhHKNFFUbub2cb+9Slj8c6MUqaXYlw0JjZ018Pi0oEAbvR6d4NyU8LKoUPPJ7oqJIpWheR4QhxRCBqNN1Y+zq1jcJ0dbZdxNTrmQovHmuXFJnN3ERvyGpP+VaZghe3ZQYFvSj1TI3ntLndpF34R4QIrFnrMcmKDlgqJReLJCqMX49wg/Q6KYe8CZHbKdzcnlMGpTWpusX0xfU7Z0wz38RppzMJla8SRQsYwPRWnZ3i8Tn2AcS8lBGrvevdK+B0uTsFgiVqlZ5B91DLvluhR0D0Cw3Bb7/wcNshMkFQ4ynQeY8bPdXAt3Mdb/EYVbTutJInsZwBymD1EpRV8mxNjJdR0l6yrdPZLmSSJIRl67ljid5T/qu3Na6bbZZ+qAsNnwtrW+2+Y/TX8sAfE/S9tFUUdhNXj8ynVxWzB0Ypt6StuNvhNbtkQNPOAlUhBfNgupkxuVtovP+inxp6fYE3PDbmNLyQE+quHUfF2s0VUaWaxU8FN1B7NmTVfgBWHjkfIcBq6//fVc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T12-58-11Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698670564.0679407,
                        "modified": 1698670564.0679407,
                        "name": "/dev/shm/incoming/2023-10-30T12:46:03.ais",
                        "sha256": "e3f0578e8e4a360259e55cb77a4c95b8a111630636007800d6cf2310e433e428",
                        "size": 894945
                    },
                    {
                        "created": 1698670523.6179407,
                        "modified": 1698670523.6179407,
                        "name": "/dev/shm/incoming/2023_10_30_125453_00_00_K_145.txt",
                        "sha256": "b984083a99129f13de91761924b07069a983f133b4152659e08e44a5af6e8926",
                        "size": 789
                    },
                    {
                        "created": 1698670516.1479406,
                        "modified": 1698670516.1479406,
                        "name": "/dev/shm/incoming/2023_10_30_125453_00_00_K_145.kml",
                        "sha256": "c9ebab5b417319cfba0746551911ded3d149a1151fdf93c2da40982282c8a4a4",
                        "size": 933
                    },
                    {
                        "created": 1698670514.7279406,
                        "modified": 1698670514.7279406,
                        "name": "/dev/shm/incoming/2023_10_30_125453_00_00_K_145.jpg",
                        "sha256": "b74318fea40aa5fd0293bfceeed7d55acac7dbccdd4dbcb6cef15dafc4595869",
                        "size": 587773
                    },
                    {
                        "created": 1698670512.9979405,
                        "modified": 1698670512.9979405,
                        "name": "/dev/shm/incoming/2023_10_30_125451_00_00_K_144.txt",
                        "sha256": "b474cfcf34cdd63d10d0321aee28a5c186c8c7f191a8bbfa035e7c9f8d1aed94",
                        "size": 789
                    },
                    {
                        "created": 1698670512.5979407,
                        "modified": 1698670512.5979407,
                        "name": "/dev/shm/incoming/2023_10_30_125451_00_00_K_144.kml",
                        "sha256": "b21ae2a126af3dc459a4db4c75e7994d57835f2b974458c678d324a27a26012c",
                        "size": 933
                    },
                    {
                        "created": 1698670511.9679406,
                        "modified": 1698670511.9679406,
                        "name": "/dev/shm/incoming/2023_10_30_125451_00_00_K_144.jpg",
                        "sha256": "646184b33b6be733f8c5f4d700f60f4b35dc77ca743f838ee68b69f422881ebd",
                        "size": 545070
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "34e0d16c0d81bde1a165d66f789da35c3b6c385dd6989a8a2ad32471e81e3976",
                "size": 1384056
            },
            "errors": "",
            "filename": "2023-10-30T12-58-11Z",
            "size": 1384056,
            "verified_at": 1698684456
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698737511,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "Z+8LH1IRGn9WJ9MfUJsIItVIvKMkYY7KRDTM0KU2iiO8oJP5Iq6zK0qVhKQdt+T2C3b+VCPmpsap175XzMN9lRmXjkHRzoh8UPbGC9a3C278bGgdtZ1z+SRQ4eTNLCLyw62HFYzDw3+cuhhXMFOkeRgCu6NwzMN+ClGSwRbtyCI/kX6wn8l6lObMSEnXsXkwTlO94NH1FgDVeMLmcAUBt3ugxAkkxGD+X5tOc5qIfQ6jx7xnAki5MfaDNlVMV7ycxnXAJeWN7CMgfM4gI3PK5M2s2xnNePGmRzsCltbiqM91W2CJ0hlsKYdg7XOBWR3NwIodiIuyZ8KzWkflk90xkft9H6nAsGXr9T+3PdOfOhPfOs5NYj8pcPslPqG7IG1/kS+GLK7Dv+CUsB7za/xrZ+aLnV66OwfmwDODKc5lmqeymt2Cm8hkFOz80fs3sX4KdkK/GlWQgyznn1wZtUlZdAxUWlAQP3yySviHpPT6gAw5wgDZUWU6FsZ1gI8by7nW85uIJus8Vj7N40Aarpf1MCBjDatV6ZrrxwYNXfIsDrEtmdvrCyt3fKbKVNMS6Yc+ssyLmDToVPCnTzFh694PLsl4TC3GQ27EcFpmqNc0EzgIscqIvlvLHPXNMtBkThhZJdpjHQUzqYdCbJTpbfC6SZap5xM+FQUqO2HW37Ek9oQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T07-30-59Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698737355.54126,
                        "modified": 1698737355.54126,
                        "name": "/dev/shm/incoming/2023_10_31_072836_00_00_K_003.txt",
                        "sha256": "982963478f67398d32b7cfe82aadbcf2607a58a9ce66ed19b7e3cd7341a9ed4a",
                        "size": 2047
                    },
                    {
                        "created": 1698737337.19126,
                        "modified": 1698737337.19126,
                        "name": "/dev/shm/incoming/2023_10_31_072836_00_00_K_003.kml",
                        "sha256": "3f6c539ec5b69667de6c777aedc12c7455377d42309db0abb042ee1c6b5a8ca1",
                        "size": 978
                    },
                    {
                        "created": 1698737334.20126,
                        "modified": 1698737334.20126,
                        "name": "/dev/shm/incoming/2023_10_31_072836_00_00_K_003.jpg",
                        "sha256": "048cbbf55b735108f31b157367138c3248f4b89947be44d000c2811782e0a931",
                        "size": 1002890
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "daa196357afd982176edaa866eae7ce00147565f1c95f9d59a5f7a03d3a718b1",
                "size": 995357
            },
            "errors": "",
            "filename": "2023-10-31T07-30-59Z",
            "size": 995357,
            "verified_at": 1698766639
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698739135,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "nRcL51oIFoy/z+wm/nKXY8lvHc7MXbiKSpbkCwDwJMvako8UzT3Ud2mAAMQFIst9LsMNY3Ep/a6PgwvYXcDrYWvjvhLLLUhpdYtXNV9yrz93FSid1zIkpm4LR+vzAKxIk/Ct5pIsY4tbTuivV6OetWZTgjBV1IAc8sWowEvezRKcp1jWDxPAWLF9hpgc0XzNC1Mz6QgEqwD1fk4X+6g7CGsEVlaNG+2l7JxFkCz9aPbyM28HBTncmgRUcS0GfPmblhwSVLf+I3+AV0T/BKuglHUBpAb94JDS25cLFQkm86k7zPs89LqwL29tj83kxWFi+eVpvWuA6sJJswVI0KfTcjPdkaYGNjXpMRbfBRUZeIR+85UA4fQd/BOLBRkDuNMZ0HYoH0xIFfyhBIWn074xoe7bNbfvsWuZ4QwwcTAUGBw/C+0tow/c7GC2wNd9MRzoUvqbPVwYF3O3LWPVx7dvedGKIGEzwZ6WFr65O9/J6Cdngz3+OEg6P6PbNGVsuIlbC7n+wAxYf/kWteeZIzANPKJibNA0tuG3upw6GTKqiIPXDXP+uck2a58VHWtd1Jq/EbAgcwEf69lWIg12Xf19WLlh1OCWP+J/Moyx5KVUBOt3BUIQY8wM0/M7dA/uEfBnKlUC4AHiAqeEsr0V8nQhn05aXycHS4lQYmL5xvdojAI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T07-58-30Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698738995.09126,
                        "modified": 1698738995.09126,
                        "name": "/dev/shm/incoming/2023_10_31_075602_00_00_K_029.txt",
                        "sha256": "164ae99bba9694338736289694917202f584a564b4f94c154fb4a480a00821e6",
                        "size": 2042
                    },
                    {
                        "created": 1698738993.78126,
                        "modified": 1698738993.78126,
                        "name": "/dev/shm/incoming/2023_10_31_075602_00_00_K_029.kml",
                        "sha256": "2d5335eb9f1d4eee62823a2d4fa469897e6f82ca0289c18f40396a125fe75b47",
                        "size": 979
                    },
                    {
                        "created": 1698738987.05126,
                        "modified": 1698738987.05126,
                        "name": "/dev/shm/incoming/2023_10_31_075602_00_00_K_029.jpg",
                        "sha256": "865a25b932f6117a898de78fd8476425ccd90231a2af9c778650623ef9741049",
                        "size": 857388
                    },
                    {
                        "created": 1698738894.39126,
                        "modified": 1698738894.39126,
                        "name": "/dev/shm/incoming/2023_10_31_075428_00_00_K_028.txt",
                        "sha256": "12496ac0b71f59b36ebeacef67be7c28f86d2b1a2e8c430aa78caff3ef41f677",
                        "size": 2045
                    },
                    {
                        "created": 1698738884.69126,
                        "modified": 1698738884.69126,
                        "name": "/dev/shm/incoming/2023_10_31_075428_00_00_K_028.kml",
                        "sha256": "48a92a7b82ae80f3b39ba54d654ae953209193704c61ae3d5a96587e0e4a2a75",
                        "size": 978
                    },
                    {
                        "created": 1698738892.90126,
                        "modified": 1698738892.90126,
                        "name": "/dev/shm/incoming/2023_10_31_075428_00_00_K_028.jpg",
                        "sha256": "00a395093d71255f63b4cf53ce41a99c888952a389fc342219785b731fdbfd90",
                        "size": 950257
                    },
                    {
                        "created": 1698738866.39126,
                        "modified": 1698738866.39126,
                        "name": "/dev/shm/incoming/2023_10_31_075404_00_00_K_027.txt",
                        "sha256": "617ed30da70af522ddd86f73b46ff042acce4813c78d00a4be142ed9e458608b",
                        "size": 2045
                    },
                    {
                        "created": 1698738863.12126,
                        "modified": 1698738863.12126,
                        "name": "/dev/shm/incoming/2023_10_31_075404_00_00_K_027.kml",
                        "sha256": "d4d563df50c9618521c6c30d135f947f6d0c614a9efe40bfab7f738d0df3beb5",
                        "size": 975
                    },
                    {
                        "created": 1698738871.39126,
                        "modified": 1698738871.39126,
                        "name": "/dev/shm/incoming/2023_10_31_075404_00_00_K_027.jpg",
                        "sha256": "ada6313351142898c498cb3d2131cbbd3c47f5c7f6802d4040d29b092662e8bb",
                        "size": 931677
                    },
                    {
                        "created": 1698738838.07126,
                        "modified": 1698738838.07126,
                        "name": "/dev/shm/incoming/2023_10_31_075332_00_00_K_026.txt",
                        "sha256": "eda922e70e0d674db6671b1673169a4eb6429d9368cfff2f42e1c0bb4f5816fc",
                        "size": 2045
                    },
                    {
                        "created": 1698738838.48126,
                        "modified": 1698738838.48126,
                        "name": "/dev/shm/incoming/2023_10_31_075332_00_00_K_026.kml",
                        "sha256": "3c2da0a595df36b84eba73784e96d8cd8fecbe3b7eb49aef3663742fe7073489",
                        "size": 974
                    },
                    {
                        "created": 1698738827.46126,
                        "modified": 1698738827.46126,
                        "name": "/dev/shm/incoming/2023_10_31_075332_00_00_K_026.jpg",
                        "sha256": "72b0717c3ed10fafc94192215e8a18cc4c06ea763190a101c78b120c5adb2d45",
                        "size": 1145791
                    },
                    {
                        "created": 1698738791.60126,
                        "modified": 1698738791.60126,
                        "name": "/dev/shm/incoming/2023_10_31_075238_00_00_K_025.txt",
                        "sha256": "1f32138ffa0329b39d7d56f5fdd671482fca2fc70d1959b067ca448639ffdf39",
                        "size": 2046
                    },
                    {
                        "created": 1698738792.17126,
                        "modified": 1698738792.17126,
                        "name": "/dev/shm/incoming/2023_10_31_075238_00_00_K_025.kml",
                        "sha256": "1aa5a5dfe3a670cafd63e06d3c7e47611a541b1de535ff83287bdfdc6d546799",
                        "size": 973
                    },
                    {
                        "created": 1698738782.02126,
                        "modified": 1698738782.02126,
                        "name": "/dev/shm/incoming/2023_10_31_075238_00_00_K_025.jpg",
                        "sha256": "a6bdef12053286ac2f680ea86bdee813e2423d5717aa91c8b8a780f0b6920ec3",
                        "size": 900717
                    },
                    {
                        "created": 1698738780.96126,
                        "modified": 1698738780.96126,
                        "name": "/dev/shm/incoming/2023_10_31_075230_00_00_K_024.txt",
                        "sha256": "84b8ccd04d68f53f21d0eb8a73f85b9f194a96351511692a2bf2f336d73d5154",
                        "size": 2045
                    },
                    {
                        "created": 1698738773.04126,
                        "modified": 1698738773.04126,
                        "name": "/dev/shm/incoming/2023_10_31_075230_00_00_K_024.kml",
                        "sha256": "199a99b9b683da01c7dc53e958b8213f8314994e919c2ffdbd606e162089b341",
                        "size": 977
                    },
                    {
                        "created": 1698738780.20126,
                        "modified": 1698738780.20126,
                        "name": "/dev/shm/incoming/2023_10_31_075230_00_00_K_024.jpg",
                        "sha256": "79907118a339be773ad55414abd0c31171e2515eca35edeb59959e1e9889fc65",
                        "size": 975837
                    },
                    {
                        "created": 1698738762.58126,
                        "modified": 1698738762.58126,
                        "name": "/dev/shm/incoming/2023_10_31_075218_00_00_K_023.txt",
                        "sha256": "347e8e7bce8db9a749d85aa791e7ecfe118ecb216feee130b76cd161e4d1c214",
                        "size": 2045
                    },
                    {
                        "created": 1698738767.53126,
                        "modified": 1698738767.53126,
                        "name": "/dev/shm/incoming/2023_10_31_075218_00_00_K_023.kml",
                        "sha256": "8b2fa96ad5c1eebea4c37d54e076554390d1be0a0db68ba345b2f27177af7ed5",
                        "size": 977
                    },
                    {
                        "created": 1698738760.76126,
                        "modified": 1698738760.76126,
                        "name": "/dev/shm/incoming/2023_10_31_075218_00_00_K_023.jpg",
                        "sha256": "0408ce05e9a7b31bf15df9a7fc61679c6fa9d062116406190c1d31cf742b3bb4",
                        "size": 463082
                    },
                    {
                        "created": 1698738751.29126,
                        "modified": 1698738751.29126,
                        "name": "/dev/shm/incoming/2023_10_31_075208_00_00_K_022.txt",
                        "sha256": "d08e4d0fead2dd16021e0cdeee530d29e5c075a7d6f4d9cdd6d4de375dab1243",
                        "size": 2045
                    },
                    {
                        "created": 1698738756.02126,
                        "modified": 1698738756.02126,
                        "name": "/dev/shm/incoming/2023_10_31_075208_00_00_K_022.kml",
                        "sha256": "7afd9ddf0b0155e6c3a9e4318137fca8383d5c7dd556f4b52a4e6af0225e487a",
                        "size": 977
                    },
                    {
                        "created": 1698738750.63126,
                        "modified": 1698738750.63126,
                        "name": "/dev/shm/incoming/2023_10_31_075208_00_00_K_022.jpg",
                        "sha256": "6a3796b274fb2a72bca9d851bbb7040e8d2ba83367836be7ac2d90697759b57a",
                        "size": 862113
                    },
                    {
                        "created": 1698738742.22126,
                        "modified": 1698738742.22126,
                        "name": "/dev/shm/incoming/2023-10-31T07:42:21.ais",
                        "sha256": "f4a5515347643c05f3b9164e8bcd0857b9c961ef7bc5a8ba356f18d7cc628089",
                        "size": 822431
                    },
                    {
                        "created": 1698738732.21126,
                        "modified": 1698738732.21126,
                        "name": "/dev/shm/incoming/2023_10_31_075133_00_00_K_021.txt",
                        "sha256": "568da79b34882ba15af036be8449f64eba33ce581840dd4d54883e63ff9939e2",
                        "size": 2045
                    },
                    {
                        "created": 1698738713.35126,
                        "modified": 1698738713.35126,
                        "name": "/dev/shm/incoming/2023_10_31_075133_00_00_K_021.kml",
                        "sha256": "64c8d09b534d84e739516ef90c9ee4dfcb1bfef09271ee1febb1fa7d3807161e",
                        "size": 979
                    },
                    {
                        "created": 1698738710.83126,
                        "modified": 1698738710.83126,
                        "name": "/dev/shm/incoming/2023_10_31_075133_00_00_K_021.jpg",
                        "sha256": "6f43a59f9896c46a2c5527ac3382a74e12a980f7f869564e8136b6b596863b97",
                        "size": 925873
                    },
                    {
                        "created": 1698738690.08126,
                        "modified": 1698738690.08126,
                        "name": "/dev/shm/incoming/2023_10_31_075103_00_00_K_020.txt",
                        "sha256": "aeabe4135ebfd951cafdec904ebba921089cb4e61f7f046501cc78b8a961c4f9",
                        "size": 2045
                    },
                    {
                        "created": 1698738697.35126,
                        "modified": 1698738697.35126,
                        "name": "/dev/shm/incoming/2023_10_31_075103_00_00_K_020.kml",
                        "sha256": "f88235d5b9bc7218955f70da40f35b3dfaa35b2677868bbfb1c4e59ddeac55b8",
                        "size": 979
                    },
                    {
                        "created": 1698738689.38126,
                        "modified": 1698738689.38126,
                        "name": "/dev/shm/incoming/2023_10_31_075103_00_00_K_020.jpg",
                        "sha256": "babf798a621227e9bf838e469732c1b51a5152ded049b691865f2dc63a531e34",
                        "size": 913653
                    },
                    {
                        "created": 1698738675.04126,
                        "modified": 1698738675.04126,
                        "name": "/dev/shm/incoming/2023_10_31_075054_00_00_K_019.txt",
                        "sha256": "2f8a820eee3d2c1ef0c0078cf159b1e8b4928c1c6bc0be64f88512571c5ab55c",
                        "size": 2045
                    },
                    {
                        "created": 1698738678.54126,
                        "modified": 1698738678.54126,
                        "name": "/dev/shm/incoming/2023_10_31_075054_00_00_K_019.kml",
                        "sha256": "78f5882b50dc9efa459f81677d515b846b1993f9b4aec59a6717734358cdddf3",
                        "size": 977
                    },
                    {
                        "created": 1698738674.00126,
                        "modified": 1698738674.00126,
                        "name": "/dev/shm/incoming/2023_10_31_075054_00_00_K_019.jpg",
                        "sha256": "8a5158e380a84866b1819b634a5f8d6366809126fdaf8b88697d67c3c2375750",
                        "size": 1050805
                    },
                    {
                        "created": 1698738653.55126,
                        "modified": 1698738653.55126,
                        "name": "/dev/shm/incoming/2023_10_31_075036_00_00_K_018.txt",
                        "sha256": "3e6a190e63cf692e03d9749c80a7d93e22bfc108957539a589a4cc94ef0fad01",
                        "size": 2044
                    },
                    {
                        "created": 1698738660.66126,
                        "modified": 1698738660.66126,
                        "name": "/dev/shm/incoming/2023_10_31_075036_00_00_K_018.kml",
                        "sha256": "8b464953bedaf9ee64c307e758d8f868dbd5f43aad0b1a575ee2bb12c46d92f4",
                        "size": 975
                    },
                    {
                        "created": 1698738650.36126,
                        "modified": 1698738650.36126,
                        "name": "/dev/shm/incoming/2023_10_31_075036_00_00_K_018.jpg",
                        "sha256": "ce4f9d7da9b8f7f0067d8edd114f432fc2bbf53e908e5478578c9676931fc551",
                        "size": 988087
                    },
                    {
                        "created": 1698738629.21126,
                        "modified": 1698738629.21126,
                        "name": "/dev/shm/incoming/2023_10_31_075008_00_00_K_017.txt",
                        "sha256": "11c4d5c7ba5250bbb0c69d39fb39cf3aa8183b01ed6c5570f79eb53c880715b4",
                        "size": 2045
                    },
                    {
                        "created": 1698738629.78126,
                        "modified": 1698738629.78126,
                        "name": "/dev/shm/incoming/2023_10_31_075008_00_00_K_017.kml",
                        "sha256": "043331b869af6542c1e6f29fb10514d837457250926a0e352eaba888bf355f5d",
                        "size": 975
                    },
                    {
                        "created": 1698738629.43126,
                        "modified": 1698738629.43126,
                        "name": "/dev/shm/incoming/2023_10_31_075008_00_00_K_017.jpg",
                        "sha256": "c753c49195ca9c980c1a15e25d9ddd4e0092d7f809132e01705cd406e9867586",
                        "size": 1037562
                    },
                    {
                        "created": 1698738637.32126,
                        "modified": 1698738637.32126,
                        "name": "/dev/shm/incoming/2023_10_31_074959_00_00_K_016.txt",
                        "sha256": "586783bbe6caab304373238194208aee2dcac09f0ce6bd97d9157ca0ca667e27",
                        "size": 2045
                    },
                    {
                        "created": 1698738615.49126,
                        "modified": 1698738615.49126,
                        "name": "/dev/shm/incoming/2023_10_31_074959_00_00_K_016.kml",
                        "sha256": "f430e797b35591721e06636fbd09baad3578fc601b02bf7421d309b27c8f5fe3",
                        "size": 975
                    },
                    {
                        "created": 1698738616.26126,
                        "modified": 1698738616.26126,
                        "name": "/dev/shm/incoming/2023_10_31_074959_00_00_K_016.jpg",
                        "sha256": "7479853edc9a1d8659d78db3ba6b8508a115347ed1d3187e5b3f7385ed6ffadc",
                        "size": 1045923
                    },
                    {
                        "created": 1698738590.68126,
                        "modified": 1698738590.68126,
                        "name": "/dev/shm/incoming/2023_10_31_074913_00_00_K_015.txt",
                        "sha256": "07b4a2d232cca07a4a3a8d0f6d2b1e2708fcfa4dfcb03db88a3c1d29db394067",
                        "size": 2045
                    },
                    {
                        "created": 1698738581.83126,
                        "modified": 1698738581.83126,
                        "name": "/dev/shm/incoming/2023_10_31_074913_00_00_K_015.kml",
                        "sha256": "129951e72eaf8511a6050072f5f0d1b09ae2325c0ab9e3f1f5a3e3954be20059",
                        "size": 978
                    },
                    {
                        "created": 1698738578.56126,
                        "modified": 1698738578.56126,
                        "name": "/dev/shm/incoming/2023_10_31_074913_00_00_K_015.jpg",
                        "sha256": "ccd4953dddad96fee5b6f534f662280d9ced391031b41067ef060dd943024913",
                        "size": 1030273
                    },
                    {
                        "created": 1698738559.88126,
                        "modified": 1698738559.88126,
                        "name": "/dev/shm/incoming/2023_10_31_074854_00_00_K_014.txt",
                        "sha256": "b4dcccafd1cb9579d0c2cedd5266f469ff5af800e07d231acc85a05f0c151b5c",
                        "size": 2045
                    },
                    {
                        "created": 1698738559.67126,
                        "modified": 1698738559.67126,
                        "name": "/dev/shm/incoming/2023_10_31_074854_00_00_K_014.kml",
                        "sha256": "4fab9f03d4312071ed4260aefba9d197081617b1237f65d5f724412fb4b255c5",
                        "size": 975
                    },
                    {
                        "created": 1698738568.96126,
                        "modified": 1698738568.96126,
                        "name": "/dev/shm/incoming/2023_10_31_074854_00_00_K_014.jpg",
                        "sha256": "fc7c3467dc31ae362186f304ae46a1a82a8e3cb87eb03a0664770f5546ea6218",
                        "size": 1059461
                    },
                    {
                        "created": 1698738540.68126,
                        "modified": 1698738540.68126,
                        "name": "/dev/shm/incoming/2023_10_31_074833_00_00_K_013.txt",
                        "sha256": "f91a51a2361bb07a6c4047f4025c830a148cc2336fef9eb5b35f28d826b20190",
                        "size": 2045
                    },
                    {
                        "created": 1698738545.52126,
                        "modified": 1698738545.52126,
                        "name": "/dev/shm/incoming/2023_10_31_074833_00_00_K_013.kml",
                        "sha256": "0cc578968791c752810ddc1c008d4360bd1eeb79b58a65c838d82c52141785c2",
                        "size": 978
                    },
                    {
                        "created": 1698738535.36126,
                        "modified": 1698738535.36126,
                        "name": "/dev/shm/incoming/2023_10_31_074833_00_00_K_013.jpg",
                        "sha256": "c03ec7366aa93491ba1f77e905569291b6e413a591fb97de20b8eece9c3d4350",
                        "size": 1076372
                    },
                    {
                        "created": 1698738534.29126,
                        "modified": 1698738534.29126,
                        "name": "/dev/shm/incoming/2023_10_31_074819_00_00_K_012.txt",
                        "sha256": "4e929d813ec598b27b1ee192b127086bf7d348aa23841ec562d948b7f4c726fd",
                        "size": 2045
                    },
                    {
                        "created": 1698738528.68126,
                        "modified": 1698738528.68126,
                        "name": "/dev/shm/incoming/2023_10_31_074819_00_00_K_012.kml",
                        "sha256": "dd2ecf5a9fc2da7d583653f24d659b0478e6b1234dcf4a5583c4d8fe3e85e7b7",
                        "size": 978
                    },
                    {
                        "created": 1698738522.62126,
                        "modified": 1698738522.62126,
                        "name": "/dev/shm/incoming/2023_10_31_074819_00_00_K_012.jpg",
                        "sha256": "5cdd6448ff13aa807717261d0cf496f96bbc84bbc3dc491c4f7a4e6ca74e42f5",
                        "size": 1434435
                    },
                    {
                        "created": 1698738502.48126,
                        "modified": 1698738502.48126,
                        "name": "/dev/shm/incoming/2023_10_31_074759_00_00_K_011.txt",
                        "sha256": "d861863e884a25d0d41816248c8400eb84e68fc6e0c4f35eaeaa688b05562adf",
                        "size": 2045
                    },
                    {
                        "created": 1698738507.30126,
                        "modified": 1698738507.30126,
                        "name": "/dev/shm/incoming/2023_10_31_074759_00_00_K_011.kml",
                        "sha256": "50a5f565903ecd661f2a22429ac5a7938c7de435bbe1e286e3f8132773702045",
                        "size": 978
                    },
                    {
                        "created": 1698738499.38126,
                        "modified": 1698738499.38126,
                        "name": "/dev/shm/incoming/2023_10_31_074759_00_00_K_011.jpg",
                        "sha256": "df72ef561f0f3137966aa4c773dcd39e808f2f23d90998a37240513912d1454c",
                        "size": 1087014
                    },
                    {
                        "created": 1698738473.25126,
                        "modified": 1698738473.25126,
                        "name": "/dev/shm/incoming/2023_10_31_074710_00_00_K_010.txt",
                        "sha256": "bbd2c687e22b028156cc2a794cb655c8bda521f95b4e8de56995b91f8fd57b8c",
                        "size": 2045
                    },
                    {
                        "created": 1698738464.41126,
                        "modified": 1698738464.41126,
                        "name": "/dev/shm/incoming/2023_10_31_074710_00_00_K_010.kml",
                        "sha256": "35817cd737b35817f2815c543874bf6b85fc86a35061c754f9a5fe3fa08ce72d",
                        "size": 976
                    },
                    {
                        "created": 1698738455.63126,
                        "modified": 1698738455.63126,
                        "name": "/dev/shm/incoming/2023_10_31_074710_00_00_K_010.jpg",
                        "sha256": "9983d796f7382287fced68aaf3e8f48c57dddf1223f580631536beaad02b4a9e",
                        "size": 1330803
                    },
                    {
                        "created": 1698738437.14126,
                        "modified": 1698738437.14126,
                        "name": "/dev/shm/incoming/2023_10_31_074652_00_00_K_009.txt",
                        "sha256": "9281db22f9e0bf5c18b7255ba4a76da3efa94b09fe9c1958348516485d3a747a",
                        "size": 2045
                    },
                    {
                        "created": 1698738438.72126,
                        "modified": 1698738438.72126,
                        "name": "/dev/shm/incoming/2023_10_31_074652_00_00_K_009.kml",
                        "sha256": "2f7549c1504a3b36fd8d6af784283f2b7ba14afe001c66bb1fcb01857b551b1b",
                        "size": 979
                    },
                    {
                        "created": 1698738440.95126,
                        "modified": 1698738440.95126,
                        "name": "/dev/shm/incoming/2023_10_31_074652_00_00_K_009.jpg",
                        "sha256": "eac3ef3920e9c935c0ff1266e71d45a20868e56544b14b67a31a93a3d4ad7146",
                        "size": 1282664
                    },
                    {
                        "created": 1698738409.49126,
                        "modified": 1698738409.49126,
                        "name": "/dev/shm/incoming/2023_10_31_074613_00_00_K_008.txt",
                        "sha256": "48dfca0b8f5bd332db6ad242e8e02269a9c054d9413bbed88ec3772524baeb96",
                        "size": 2045
                    },
                    {
                        "created": 1698738399.80126,
                        "modified": 1698738399.80126,
                        "name": "/dev/shm/incoming/2023_10_31_074613_00_00_K_008.kml",
                        "sha256": "8c71f380d4a89d98cb4a09b86daf7f035f886365466bbe7ce13706d1eceac363",
                        "size": 977
                    },
                    {
                        "created": 1698738406.99126,
                        "modified": 1698738406.99126,
                        "name": "/dev/shm/incoming/2023_10_31_074613_00_00_K_008.jpg",
                        "sha256": "e06684284b2ca7b514d7009606f0d8ad1ee13091d67bcd585e99c101004a0abb",
                        "size": 1276486
                    },
                    {
                        "created": 1698738381.04126,
                        "modified": 1698738381.04126,
                        "name": "/dev/shm/incoming/2023_10_31_074559_00_00_K_007.txt",
                        "sha256": "992abb77be55b221d1dd5d20906dc46b96960c13b2c764157480eba48497d715",
                        "size": 2042
                    },
                    {
                        "created": 1698738380.35126,
                        "modified": 1698738380.35126,
                        "name": "/dev/shm/incoming/2023_10_31_074559_00_00_K_007.kml",
                        "sha256": "68e43e51eb5f5ca870abda68a66dc9ab6a5ac494961977ad1dd5b0402ec87fdc",
                        "size": 978
                    },
                    {
                        "created": 1698738373.51126,
                        "modified": 1698738373.51126,
                        "name": "/dev/shm/incoming/2023_10_31_074559_00_00_K_007.jpg",
                        "sha256": "67fd4b295f46311f446d64292efbd51cdc87d03747263793be88fdb57b743f4c",
                        "size": 852461
                    },
                    {
                        "created": 1698738335.15126,
                        "modified": 1698738335.15126,
                        "name": "/dev/shm/incoming/2023_10_31_074506_00_00_K_006.txt",
                        "sha256": "49b872663a2170dbeebc64b952bc53d922dc7885a42911a27a480c9a68153896",
                        "size": 2039
                    },
                    {
                        "created": 1698738349.55126,
                        "modified": 1698738349.55126,
                        "name": "/dev/shm/incoming/2023_10_31_074506_00_00_K_006.kml",
                        "sha256": "e896aea34dc5d9ac31ffe506420518a2b79d150199b9d0ecb079680bd1bbeab1",
                        "size": 978
                    },
                    {
                        "created": 1698738344.39126,
                        "modified": 1698738344.39126,
                        "name": "/dev/shm/incoming/2023_10_31_074506_00_00_K_006.jpg",
                        "sha256": "bd53c7c69f631f27bc70ae3cbb91030e4f52009a31afc5f4aa196b313664e1ea",
                        "size": 1172879
                    },
                    {
                        "created": 1698738259.74126,
                        "modified": 1698738259.74126,
                        "name": "/dev/shm/incoming/2023_10_31_074354_00_00_K_005.txt",
                        "sha256": "283425f807c6601a77714977123abcfde41af29b74ce77052bd156ee2d692542",
                        "size": 2040
                    },
                    {
                        "created": 1698738260.28126,
                        "modified": 1698738260.28126,
                        "name": "/dev/shm/incoming/2023_10_31_074354_00_00_K_005.kml",
                        "sha256": "46c7f8825ab1b0e1a4b9019d7773a46cf01f44a95ec237db6f12a22c9595b1be",
                        "size": 977
                    },
                    {
                        "created": 1698738262.44126,
                        "modified": 1698738262.44126,
                        "name": "/dev/shm/incoming/2023_10_31_074354_00_00_K_005.jpg",
                        "sha256": "d182abbbcfb2ef40d24c73e80563fc916fcfd851f2317ac095f7201c70ba7438",
                        "size": 1249888
                    },
                    {
                        "created": 1698738249.62126,
                        "modified": 1698738249.62126,
                        "name": "/dev/shm/incoming/2023_10_31_074325_00_00_K_004.txt",
                        "sha256": "dbe86dddf4263b4ac3e7207027ce4382fc7419b5bbe7e19af793a6b01fcfacbb",
                        "size": 2048
                    },
                    {
                        "created": 1698738234.25126,
                        "modified": 1698738234.25126,
                        "name": "/dev/shm/incoming/2023_10_31_074325_00_00_K_004.kml",
                        "sha256": "f300c46c67903a172b33edb509102b77e4de5f0f727d07fdd30d089727cd72c9",
                        "size": 975
                    },
                    {
                        "created": 1698738237.57126,
                        "modified": 1698738237.57126,
                        "name": "/dev/shm/incoming/2023_10_31_074325_00_00_K_004.jpg",
                        "sha256": "0bc65bf4b2abb7fb8fe4e5715415be13838ab3971ca7d1805a15b8484ce27b2d",
                        "size": 1246567
                    },
                    {
                        "created": 1698738141.30126,
                        "modified": 1698738141.30126,
                        "name": "/dev/shm/incoming/2023-10-31T07:32:20.ais",
                        "sha256": "4521d36375ba79681b94c1bf77e96c37c39e064af08b1a15cdfdc9ee36db3a55",
                        "size": 885229
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "6d42e83f4117189b4c4d86cb6659b2d26a8e8fb5f312e0951eff353d0b2d3a5f",
                "size": 27674076
            },
            "errors": "",
            "filename": "2023-10-31T07-58-30Z",
            "size": 27674076,
            "verified_at": 1698766763
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698737775,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "UDdXvGgB+CDvzQDRIfoAkck8VaIqHJI9e2hoxSBGuQ8ss82TB79NIosl0ZudId4t3e1uVfFJoOI69AJmaKjM1OF9f3jQOUR6TdFop6EnhPg5a3d8dgoYfvRAFwGbWdnnVY/jYcvkOJnvB1Esd1knvhz/cwGzXKkweAhv/zYjnohtQhwWOG2fjo7PmWP9onT/fp5njcAlBqrocqd+sl9M1s4txJ1vjIRgZKmrM8JdZT3cNF2NkCHVN7UcJSElpQ3CyeGjAzecLw8VwJgJWuN3YeuovywSndYUVfAz5Wriob6g5t1sx9p7v5PN39CoiHIZwHga+jdEjzKJEZ331Td3QpSL025w+lwiSkNip9EU2BlHHvXHOjVv+1OpZX1BYN78EhM8kZVp+lgtD9Ry9vrzvSf0gvxAGryagkSP6EPcKvWTihQHf1g5CsBlkvWvIS9nyl9bMwlu9IXP//9G/gdYc+FHz9491gfvjAzKDELG1OGWFIm+COi/HyiDsZea7zqAYoy8KLVwnoqxjcacERekdWbY7LEmdQWLb2yiF856yofW8Boy9Y9Aic29m2KTT3bqnJPa712bDpB0ivLFemCB/EFTIwIn2wR+PZfylp7Iy01QR1d9PofC8bGuoxrj3oNXe+G9tonc7VxxfgXkZjKM5r18hBbuXfHbKiuevBSgQ3w=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T07-33-27Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698737540.06126,
                        "modified": 1698737540.06126,
                        "name": "/dev/shm/incoming/2023-10-31T07:22:19.ais",
                        "sha256": "a00303d8a6e5edcceb29aaba6c92f3f5c1e16cedbb71ab3dc21acc23056e68cd",
                        "size": 912348
                    },
                    {
                        "created": 1698737600.88126,
                        "modified": 1698737600.88126,
                        "name": "/dev/shm/incoming/2023-10-31T07:03:20.ts",
                        "sha256": "29b267c95623cb5fb87cc4e02ce9fd397d10b358c2c9809e00b331d95c6b6851",
                        "size": 440734792
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "ef55cc46c96abc8d2830b927746bec344629ee26f04fef49be2d65a9e2ceff18",
                "size": 398758480
            },
            "errors": "",
            "filename": "2023-10-31T07-33-27Z",
            "size": 398758480,
            "verified_at": 1698767113
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698739498,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "WfilBW4gcQjn2lCOfgFyRYI+8JnhNvb0GE86qaED7CsQO1Eg36a57pFQGk95/NtNhQLLbBA2S38RGDBX/JJTiBxSXSJdgfA5Gohwi25HH9A8dCpuhwGPqsv/nOSTBjg8O3AGXTG7t7Ka0lu71HFowELxD/4uaoOTIPLbE9OzK26AesGWDDuaO0XVw18acf22S1zvCEVhGBoaiilCrP6zxROM63vJ3xL/l1RiAjTZdfva0plDc21n6fnx6MZGiTtXR7hjJmlBaSWLibdmaWde9RfcBXkc5FjPKsE8BpWPYD7H1xEJ5qtC3/sMa7HvZHxAFYYCUuyrFN0gIVJ1w7PM5PdWxkmaHt2pBSkRAtDFQWgPNgkzErWUuk7fa/E/6qWmLUyEDLHZ0mT/a0ia/GtwBRGXsX0AWvRxtVXFDLqID9Ybq6Ex03b4Gja8mbiXMVFAtGxWumE9o9drvY3nJ33t/9EP8d2ImuZ+hiY5y2GWOMUKGxV2KYb21xScpyJltz1auutfhGvP8/SogAy98tGyJYaCdAR/GL8lHBetbSv8/2vyEcKxrdW4ijuj424QBjMAHdJtCj/pgPqlp8Jzspu7SKL4c3ph4ZIg5qsqrQqTNcXQTLHmYArqTMdzlUc+y/LnMCSWcJkBRNCWSaINlWAuzqhqyIQEhNQDRaOeoylyA7E=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T08-03-37Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698739343.15126,
                        "modified": 1698739343.15126,
                        "name": "/dev/shm/incoming/2023-10-31T07:52:22.ais",
                        "sha256": "6ad5fed2b5468baa257b0b845837804f0d2fc513df13f6afb566ecacefa11dcc",
                        "size": 705083
                    },
                    {
                        "created": 1698739407.40126,
                        "modified": 1698739407.40126,
                        "name": "/dev/shm/incoming/2023-10-31T07:33:27.ts",
                        "sha256": "c4af0f8c3dd3559aaa5be7f561f12a56c51dbed945efedcedcef7d4c34088621",
                        "size": 440531940
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "ceee6cae7186953e2297a8e7a14c6f365b96dc628366cd8cabf372a6f0328ead",
                "size": 397998446
            },
            "errors": "",
            "filename": "2023-10-31T08-03-37Z",
            "size": 397998446,
            "verified_at": 1698767312
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698740089,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "i+e+gGoESdQC3HA37QeJwk7PUsiVd9AOhkxpEQ4ywkIz8WtfDGD8BOYMAONw+ewOXo8uXY9bnmPGvqLNo5oSFhtkyL/zJbQmP9LYMf3RVL3ll+FrEAf+9xdZs6CxpbzTKcHMo/YiYEgh2+58XYBJ29hKsVYc8j3QKsi7iMigtgznyUUGRB8zeGYtmOCteWYOsFmoPxCeytYXWT9bhHIWwFeinygoQPCgBWHqT0en8z8ihT1ddoWJtWBzgTxDOQZG48JV8FqrBEu1xRZxPMqyNQgichvGpZGpaBV2FnLx9+JncIqjtS9HupqHlZ7duuh+BgwK/HeigxIp+LkYWxFd5kuVIknnKw6Q6Baa+gvpxmsYnAxQ9oipwH1bkfoFstNwEngX0pyhpaC92DQOOTvKolBnIfD9xyTtCWJkVp6DwKfmGymxj58KgU4MKSXaUjBzz5WzsLLsM6IOn/Sk9v0KvIggRSjN438xkhl3dqj2RinPxEQtyt3e9axlnsDtDuv0FxDASjVTGcgH7EFQl5HJUiU53I/Kq9z/AEHsdHTyjcMnV/0mYJ3f5KFuYNYHG9ZTdk/ZZ8S09+QALjPR0WSMYObRxbBom931t9P7IYRkumOsLEej/s8tcpHT9I4DzFFxntqkcKJOn6hmLQ/RSLQ2ix9dL0kSF7E/a9V/sJyrrJM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T08-14-29Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698739944.68126,
                        "modified": 1698739944.68126,
                        "name": "/dev/shm/incoming/2023-10-31T08:02:23.ais",
                        "sha256": "098f77fafa4b38a7b68481afff2f0bb18ae8ecf089a20076f027e0d9a0b0ea86",
                        "size": 721779
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "e498232cdaa138aef6ee27b1faaed7712623e690f22eb57f9a2bf2620ade2513",
                "size": 222992
            },
            "errors": "",
            "filename": "2023-10-31T08-14-29Z",
            "size": 222992,
            "verified_at": 1698766891
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698741165,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "EAfsnX1+sswwfueGezAfY4JzDlhIFYjEV8Vcb4qEPOdGbPTe9vz+dmO2XvfgGO4KnjAoYkgyNJZ3hqZYDWOcjpG2kdsJoS/UamhBRAcKjy1pSfBZ7cIcb6XGHdPn7iCiveXA2PMAAg9dzyxXVa3aSkuIWdoaMEMgGY4aWeUAd9bilzmOJRY6o77gPpJmxSn4t9Ggjpg5vctc7+Vjn9SbZvszMgyC5O51QpMJzBZ2SUcMACZaEq/EcoIoc1FupoGDxbPZnbnmrFapce1fufPty7DamWE1CG8YPc9oqYpe03tqmVvXAzw6HauoXC85Qln6LGM14Z4t3oR4WG5fOrXEuMM0kn5ZZi7Pm5VwTPnR4h0uGRQDrctDrN0Z3Vp5Cc+0FSGryfH49Y5fDwb5Xy251/fZo1yAy5dqwCmJm0wAp9/H5H0v7QZAVqHwMKTxCpX0L/STVulZ4AztDSXsg2M9udJNOv5Ld08TDSUS9tFRCBTzwXmBtUk6MDJv3m0ufrpmIFqXSG/nRrw7oUclzVwBAGSPZoI3eDXm9zt2K3FXWP3tqQHT9Gn7T8gIaaIPWk/pm4Xdw+Jb9yCPb4GtO+9CjVVXLGZiJMoz/X+9fnEU3HT6HoBbUHt5+pOkilCmlYUbihqTA1pACyqQOie0/xFbKWHFuRLisNGhquDijr4/sI8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T08-31-01Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698740939.69126,
                        "modified": 1698740939.69126,
                        "name": "/dev/shm/incoming/2023_10_31_082832_00_00_K_047.txt",
                        "sha256": "90e4f14e0ed203488c370b7d6bffd9b0dc52a5706cc04f47ed21f6be3d413741",
                        "size": 2044
                    },
                    {
                        "created": 1698740945.58126,
                        "modified": 1698740945.58126,
                        "name": "/dev/shm/incoming/2023_10_31_082832_00_00_K_047.kml",
                        "sha256": "395adcef7e3c8386f63109a75bb51fd4d5304ad0af749fe4df26f37d6456a38f",
                        "size": 977
                    },
                    {
                        "created": 1698740934.84126,
                        "modified": 1698740934.84126,
                        "name": "/dev/shm/incoming/2023_10_31_082832_00_00_K_047.jpg",
                        "sha256": "57e8f81a265fdac035f66c3f44094b9f5fe9b8a88f3ff0f1d46535e3406dbf97",
                        "size": 814215
                    },
                    {
                        "created": 1698740911.75126,
                        "modified": 1698740911.75126,
                        "name": "/dev/shm/incoming/2023_10_31_082809_00_00_K_046.txt",
                        "sha256": "297221fcbc9e8076bfd9963e089608ae211b1e5994a51f8fd34f5cdb432056b2",
                        "size": 2044
                    },
                    {
                        "created": 1698740919.91126,
                        "modified": 1698740919.91126,
                        "name": "/dev/shm/incoming/2023_10_31_082809_00_00_K_046.kml",
                        "sha256": "268f9aa28c4cf742d118623656ae35bdbc5fa83aac4ade732515427dc06e1ef7",
                        "size": 976
                    },
                    {
                        "created": 1698740909.65126,
                        "modified": 1698740909.65126,
                        "name": "/dev/shm/incoming/2023_10_31_082809_00_00_K_046.jpg",
                        "sha256": "5bc39735de8de1800e0cabeabfc3bead1c4d05f41c46c53794d8d0718f69a0f5",
                        "size": 1093637
                    },
                    {
                        "created": 1698740908.58126,
                        "modified": 1698740908.58126,
                        "name": "/dev/shm/incoming/2023_10_31_082805_00_00_K_045.txt",
                        "sha256": "1b6fdec10a4b6f797eb0a211c85ef2a8464a9f033a577f7f23c0b9e43b7c8bae",
                        "size": 2044
                    },
                    {
                        "created": 1698740909.22126,
                        "modified": 1698740909.22126,
                        "name": "/dev/shm/incoming/2023_10_31_082805_00_00_K_045.kml",
                        "sha256": "858ec5bc9c3656b81f82d63cd61426af62705a3504d7773e7274fdd52d313af0",
                        "size": 977
                    },
                    {
                        "created": 1698740907.98126,
                        "modified": 1698740907.98126,
                        "name": "/dev/shm/incoming/2023_10_31_082805_00_00_K_045.jpg",
                        "sha256": "98682b86c1ca460ce9d13c06398b590a0324232ee2acbdc133e9e1802af5c7c4",
                        "size": 978041
                    },
                    {
                        "created": 1698740812.19126,
                        "modified": 1698740812.19126,
                        "name": "/dev/shm/incoming/2023_10_31_082619_00_00_K_044.txt",
                        "sha256": "bc4486e88d1a73f0852c364dd5e06939ae6f975c7e01fa7cd370cc152fa557da",
                        "size": 2042
                    },
                    {
                        "created": 1698740806.38126,
                        "modified": 1698740806.38126,
                        "name": "/dev/shm/incoming/2023_10_31_082619_00_00_K_044.kml",
                        "sha256": "a2a4e54c1e4b3a1e39e1b7ec8b8c5c127170eae0a693b3f64bad656fbc8bd670",
                        "size": 977
                    },
                    {
                        "created": 1698740814.78126,
                        "modified": 1698740814.78126,
                        "name": "/dev/shm/incoming/2023_10_31_082619_00_00_K_044.jpg",
                        "sha256": "5abb6ca417d582ee3149f255e7d46669c55f2cf8972177e9dd55b2a6e3dfbe4c",
                        "size": 954899
                    },
                    {
                        "created": 1698740718.22126,
                        "modified": 1698740718.22126,
                        "name": "/dev/shm/incoming/2023_10_31_082451_00_00_K_043.txt",
                        "sha256": "8834bc736127da76394c7e95342e996aa618de64e2264b4bd14be13b23389ad8",
                        "size": 2043
                    },
                    {
                        "created": 1698740714.87126,
                        "modified": 1698740714.87126,
                        "name": "/dev/shm/incoming/2023_10_31_082451_00_00_K_043.kml",
                        "sha256": "73ea3fa4a0d3c7783b6d1cad346b263d7564e8c7841f979581d8e111a57a5865",
                        "size": 979
                    },
                    {
                        "created": 1698740722.02126,
                        "modified": 1698740722.02126,
                        "name": "/dev/shm/incoming/2023_10_31_082451_00_00_K_043.jpg",
                        "sha256": "2ac3d04fecfa5f92c13224ecf7c2a3e9085e13cc6cfb5661c30260a83f392681",
                        "size": 923410
                    },
                    {
                        "created": 1698740642.17126,
                        "modified": 1698740642.17126,
                        "name": "/dev/shm/incoming/2023_10_31_082338_00_00_K_042.txt",
                        "sha256": "7b3866a7d96e2c6344d99e8916ad00387e1f8cbea839e7138e4e4eb342b47136",
                        "size": 2045
                    },
                    {
                        "created": 1698740641.78126,
                        "modified": 1698740641.78126,
                        "name": "/dev/shm/incoming/2023_10_31_082338_00_00_K_042.kml",
                        "sha256": "8d3859a7524684ed83b2f3051a44ea867a7d84c76bf7c02d01a374b5a6280609",
                        "size": 974
                    },
                    {
                        "created": 1698740641.14126,
                        "modified": 1698740641.14126,
                        "name": "/dev/shm/incoming/2023_10_31_082338_00_00_K_042.jpg",
                        "sha256": "c1b1c90c26daa266658f8f41f0da6eb17e5cac546677b6cdf95a70ee37dbe9be",
                        "size": 998009
                    },
                    {
                        "created": 1698740571.88126,
                        "modified": 1698740571.88126,
                        "name": "/dev/shm/incoming/2023_10_31_082222_00_00_K_041.txt",
                        "sha256": "9a8a0ad9086da58cc00d57f5680644fdaa9d3b6532c150d124bda53ed487d7e4",
                        "size": 2045
                    },
                    {
                        "created": 1698740572.58126,
                        "modified": 1698740572.58126,
                        "name": "/dev/shm/incoming/2023_10_31_082222_00_00_K_041.kml",
                        "sha256": "c9a2a973c0e48075e75ada03f42cfc3966e70f392139cb59f9f8ccfdf5467219",
                        "size": 977
                    },
                    {
                        "created": 1698740563.68126,
                        "modified": 1698740563.68126,
                        "name": "/dev/shm/incoming/2023_10_31_082222_00_00_K_041.jpg",
                        "sha256": "b7eb76f5a1c762fc25df699da440ac27155ec0bfba7620d3b95b2543f2e2bb9b",
                        "size": 1270112
                    },
                    {
                        "created": 1698740546.05126,
                        "modified": 1698740546.05126,
                        "name": "/dev/shm/incoming/2023-10-31T08:12:24.ais",
                        "sha256": "8b81c81ab0010f65b10a22e4b4a6993666f5e021119df1d4ac9fd01ca213ce1a",
                        "size": 734248
                    },
                    {
                        "created": 1698740545.02126,
                        "modified": 1698740545.02126,
                        "name": "/dev/shm/incoming/2023_10_31_082206_00_00_K_040.txt",
                        "sha256": "2b08d79e56f59238734bf78df4182e5ec89f37eb8fbac0db0572a4d09088d245",
                        "size": 2046
                    },
                    {
                        "created": 1698740549.73126,
                        "modified": 1698740549.73126,
                        "name": "/dev/shm/incoming/2023_10_31_082206_00_00_K_040.kml",
                        "sha256": "c5e5a1ec47ce0b7d03a187bdb1a9b8c717bbdd9accbfde46a8cc38810f02b245",
                        "size": 977
                    },
                    {
                        "created": 1698740538.61126,
                        "modified": 1698740538.61126,
                        "name": "/dev/shm/incoming/2023_10_31_082206_00_00_K_040.jpg",
                        "sha256": "c1cab76511114b21811f85dfe84058a43f35fedca60d190abbbf31ca6c4c2f48",
                        "size": 1119923
                    },
                    {
                        "created": 1698740516.81126,
                        "modified": 1698740516.81126,
                        "name": "/dev/shm/incoming/2023_10_31_082131_00_00_K_039.txt",
                        "sha256": "98d31e06c4bd4ea09f8d7ecfb1d30e75985d06d5acebe41c6d1819c234da3c85",
                        "size": 2045
                    },
                    {
                        "created": 1698740514.55126,
                        "modified": 1698740514.55126,
                        "name": "/dev/shm/incoming/2023_10_31_082131_00_00_K_039.kml",
                        "sha256": "8f5ce9763123be66db4f7c8fd8968ee9337c0e5d21e03b375cf15f241b117584",
                        "size": 975
                    },
                    {
                        "created": 1698740507.68126,
                        "modified": 1698740507.68126,
                        "name": "/dev/shm/incoming/2023_10_31_082131_00_00_K_039.jpg",
                        "sha256": "7aca25de560bbebcf088d414542565e1576bd35eb9d792c9fe72f52848f6244c",
                        "size": 991396
                    },
                    {
                        "created": 1698740480.22126,
                        "modified": 1698740480.22126,
                        "name": "/dev/shm/incoming/2023_10_31_082057_00_00_K_038.txt",
                        "sha256": "393cd9ab50ac53b23caefb25d145335f770fc883e1f3d964a75622a8c6149f7a",
                        "size": 2046
                    },
                    {
                        "created": 1698740470.49126,
                        "modified": 1698740470.49126,
                        "name": "/dev/shm/incoming/2023_10_31_082057_00_00_K_038.kml",
                        "sha256": "2fb0e7290065d3e3fddd29131134234863b95c2acbf5205c82af3685a460126f",
                        "size": 978
                    },
                    {
                        "created": 1698740479.69126,
                        "modified": 1698740479.69126,
                        "name": "/dev/shm/incoming/2023_10_31_082057_00_00_K_038.jpg",
                        "sha256": "b9abc8b357dc5f245d495087cc0e33b5debe68988cd4cfd1d99a0af0909653c9",
                        "size": 985134
                    },
                    {
                        "created": 1698740452.05126,
                        "modified": 1698740452.05126,
                        "name": "/dev/shm/incoming/2023_10_31_082020_00_00_K_037.txt",
                        "sha256": "d831a24cc816576d53d4d580be85db3c838cf922c09529ca9e8790a244af4a15",
                        "size": 2045
                    },
                    {
                        "created": 1698740459.17126,
                        "modified": 1698740459.17126,
                        "name": "/dev/shm/incoming/2023_10_31_082020_00_00_K_037.kml",
                        "sha256": "eaf905498ff3051e7043e745925732cfc246bf7a341e5e2d8530e26a677ceec7",
                        "size": 979
                    },
                    {
                        "created": 1698740451.60126,
                        "modified": 1698740451.60126,
                        "name": "/dev/shm/incoming/2023_10_31_082020_00_00_K_037.jpg",
                        "sha256": "98b7ba374a84f017ea0b532d97c5bab6af8c4e56ca29a3d4e0354d99d3b7b6bd",
                        "size": 988860
                    },
                    {
                        "created": 1698740445.16126,
                        "modified": 1698740445.16126,
                        "name": "/dev/shm/incoming/2023_10_31_082016_00_00_K_036.txt",
                        "sha256": "330b2ffb0bc5ba720b0ee8fa016267b8c0602fd01b77ece8781ab2c024583034",
                        "size": 2046
                    },
                    {
                        "created": 1698740443.98126,
                        "modified": 1698740443.98126,
                        "name": "/dev/shm/incoming/2023_10_31_082016_00_00_K_036.kml",
                        "sha256": "836fd55cdddd2c835b9403ace347b67fa9b686ced055d5ef52a8fcc948211b77",
                        "size": 977
                    },
                    {
                        "created": 1698740436.25126,
                        "modified": 1698740436.25126,
                        "name": "/dev/shm/incoming/2023_10_31_082016_00_00_K_036.jpg",
                        "sha256": "4d7658b02d22d039fb17ed58e0b0b399bb441d8c9172ad6486715bed556ecd57",
                        "size": 980200
                    },
                    {
                        "created": 1698740431.29126,
                        "modified": 1698740431.29126,
                        "name": "/dev/shm/incoming/2023_10_31_082005_00_00_K_035.txt",
                        "sha256": "14a781519df68051c719a895852a60f9d35c4af660a276d3c1b63094c142068f",
                        "size": 2046
                    },
                    {
                        "created": 1698740423.33126,
                        "modified": 1698740423.33126,
                        "name": "/dev/shm/incoming/2023_10_31_082005_00_00_K_035.kml",
                        "sha256": "947818fa6f80a212ccc38332ca2a53b040a421ad8c9d04cc285db2fb2539d025",
                        "size": 978
                    },
                    {
                        "created": 1698740421.96126,
                        "modified": 1698740421.96126,
                        "name": "/dev/shm/incoming/2023_10_31_082005_00_00_K_035.jpg",
                        "sha256": "bc298fc36758c070b59c7507f1d589034f8c61fce54f0f441fc35e19386f13b6",
                        "size": 1006383
                    },
                    {
                        "created": 1698740376.26126,
                        "modified": 1698740376.26126,
                        "name": "/dev/shm/incoming/2023_10_31_081918_00_00_K_034.txt",
                        "sha256": "f082a57f102a047536a2126d537970914b767508fcbe91bc4ba38285b2a66ec2",
                        "size": 2045
                    },
                    {
                        "created": 1698740386.06126,
                        "modified": 1698740386.06126,
                        "name": "/dev/shm/incoming/2023_10_31_081918_00_00_K_034.kml",
                        "sha256": "52a1d5a34e7a8fa7d93dcb81ae258d20b0a530d4edd287b51110f3c35352982b",
                        "size": 980
                    },
                    {
                        "created": 1698740375.33126,
                        "modified": 1698740375.33126,
                        "name": "/dev/shm/incoming/2023_10_31_081918_00_00_K_034.jpg",
                        "sha256": "61dc826793ca7e08d258782b6769889c4d18760347a8915f3352b7d06c2ab113",
                        "size": 1063776
                    },
                    {
                        "created": 1698740343.10126,
                        "modified": 1698740343.10126,
                        "name": "/dev/shm/incoming/2023_10_31_081843_00_00_K_033.txt",
                        "sha256": "04b17ba3422fc49a45dbe140b40e42b16769d8b252874e49d6a71cf0cee94552",
                        "size": 2045
                    },
                    {
                        "created": 1698740348.00126,
                        "modified": 1698740348.00126,
                        "name": "/dev/shm/incoming/2023_10_31_081843_00_00_K_033.kml",
                        "sha256": "bb975a1498e00c57df39b755564ce74037ea63428cc9fd7b7cbf15e09edf46cd",
                        "size": 977
                    },
                    {
                        "created": 1698740347.71126,
                        "modified": 1698740347.71126,
                        "name": "/dev/shm/incoming/2023_10_31_081843_00_00_K_033.jpg",
                        "sha256": "b4cf34d0806cb0a29c83b05329c57dd7bd82aea0745c29e2226afb05adcced6a",
                        "size": 808934
                    },
                    {
                        "created": 1698740310.75126,
                        "modified": 1698740310.75126,
                        "name": "/dev/shm/incoming/2023_10_31_081809_00_00_K_032.txt",
                        "sha256": "86a54a79670d80bc1233aabf864c7c82dde09d14f4ba1d25ff9d976d3a62d4ea",
                        "size": 2040
                    },
                    {
                        "created": 1698740318.60126,
                        "modified": 1698740318.60126,
                        "name": "/dev/shm/incoming/2023_10_31_081809_00_00_K_032.kml",
                        "sha256": "5c8b3860b6e31289154112504ef26149faf7b47d3b2724d1ff189fe18de18313",
                        "size": 976
                    },
                    {
                        "created": 1698740306.48126,
                        "modified": 1698740306.48126,
                        "name": "/dev/shm/incoming/2023_10_31_081809_00_00_K_032.jpg",
                        "sha256": "8f4ce182da981eeee991b4c00fdc97f82e74e198413eb50912627d0a03894860",
                        "size": 1108976
                    },
                    {
                        "created": 1698740272.79126,
                        "modified": 1698740272.79126,
                        "name": "/dev/shm/incoming/2023_10_31_081734_00_00_K_031.txt",
                        "sha256": "341510fee9c00da812e1fb9d4505b900dd3d2d252801d1418dd3f4e13694421a",
                        "size": 2042
                    },
                    {
                        "created": 1698740275.30126,
                        "modified": 1698740275.30126,
                        "name": "/dev/shm/incoming/2023_10_31_081734_00_00_K_031.kml",
                        "sha256": "09f06451f14fb78ad7a6b21b768bebbe770b160ac40fcfe99308d2e58dc2bc83",
                        "size": 977
                    },
                    {
                        "created": 1698740271.94126,
                        "modified": 1698740271.94126,
                        "name": "/dev/shm/incoming/2023_10_31_081734_00_00_K_031.jpg",
                        "sha256": "4ae77a48c19f404cded03ae33021d8b867322c6b9d1539d60077ad651743fc8f",
                        "size": 1112508
                    },
                    {
                        "created": 1698740271.04126,
                        "modified": 1698740271.04126,
                        "name": "/dev/shm/incoming/2023_10_31_081515_00_00_K_030.txt",
                        "sha256": "30e1e50c780d1b1de94e4f414e9555051a1c4db51036d9f04dcab960a2114b27",
                        "size": 2047
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "42538821cd3de2eebfefbe615b743f81f83c1b28ea92c820a08b2b1b1df4487d",
                "size": 17417012
            },
            "errors": "",
            "filename": "2023-10-31T08-31-01Z",
            "size": 17417012,
            "verified_at": 1698767085
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698741363,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "k4+RdWHMX0IJAfWITxB4UnTvUq+3V1lodUyvUpoCusf+ifxld/DRd7ZLAUgU9SOBnfxdTSSjFBzZsEGERo9h1YQyo/q1l7PVIK1BllE1gE3scE6RtTeLRcVrWALEN7LY00Ti4Dku4x644IRTRFWc2z1J/aDVxCYDhx+szY7YJ/NlGRJsGYuVS21gewha5fqvYFpC9n7nVMI0n+vY9P7rV5pJW7RmplmmelpNt+0wZhUrB4ZHyImrYkE3HfgtxFRRw7xRxTw1BmvfmIc9MOEf1JmQTHIDp0bN3xjqKdZJzNOtgsE9hR3nEtgRKQ/MPhJOIGa2kqRBXJ28LsRqwKJnMO0X99x4GBDZw7fYeusDm5w6AOsCfDxFSoRzmlvpjg4Vsslwhv39/s6iYGM7Id+yjXrQFbZN8uuC69DUTiBZ6N+0WsjpsOmW4EAvV7mRr829TvXtEH+iM8X4ITg0XbsvoVV+8C8sSc4U/VG4evoidThV4gHJ9nxSGxkeKuXytBOSq/efbXYS8o0ciiAOcCn+wE0iEpjfOHYo+15y3WRXpJIDH6T8gFO+KCVruAAyEsD3hSL0is8Em6oUDfsQWsApdMg5CIbBQMNO+pUH/KKz+YBa9BBCzkRUUje8i43xYRYq6ovH6W4O5AtY5TCxITf+sYKz+r+ubJbApXgQv285G7c=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T08-33-42Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698741147.48126,
                        "modified": 1698741147.48126,
                        "name": "/dev/shm/incoming/2023-10-31T08:22:26.ais",
                        "sha256": "397024c2adfabcbfe5bd899be97420b50817dc204acc6fe3715a7905ee124d62",
                        "size": 671457
                    },
                    {
                        "created": 1698741214.08126,
                        "modified": 1698741214.08126,
                        "name": "/dev/shm/incoming/2023-10-31T08:03:33.ts",
                        "sha256": "7910576579b257113312835804b55b06a331cf661b5072fadc2e75a04b34d89d",
                        "size": 440775776
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "8bbf7e45113399025526f25f77bcaee6f7c37a7f9febadf45dcf1e14ef98972c",
                "size": 397800437
            },
            "errors": "",
            "filename": "2023-10-31T08-33-42Z",
            "size": 397800437,
            "verified_at": 1698767583
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698752157,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "YT3Z12p5g+0/q2IWRGBsZFa0P1rF/8dyEE3eXzY7ZTmsQ8+s+wnOoc4aIeVLGcMxuwsS2nsvYoQGVSdeDvuwGgxwM1b98F4g9Fu8PIccNk8dOBpg0u/N6K/Twn+xzj1x0rmfnWwvA4Rmv7+1OLxpL/qkw8zjnR93ijMRXcd9KotRI758AKUC/Q52tqgLUdJNF4TVCzZbukf2YwGqZcXhjhxLX4GVC831tr5k1oT15Woc3WOaPeWq5nihWZq01incSvZtkZukhgBEUpAInHI7puhIrUr0XrVuuRqOsAIooS4aCmyLWsm7JtuO1Uyt8GyJUr2q1L7HHh54bml2Vc0I2mU4/YgH0rpKeSHy3gToXZNFb5qUd5inxA9uNUkiTnG4Jx6QHFwiB0p/BU+mQ78W8dPbPAWIE+fZrbXZyqrY7o9YxpcjpDWwzY/4wkmptJciZT+NHEaioDWien1bn6e4W1+e9dJ1Y0W+Sq1p1UaNidXI31+y9h3FES/WAIDkD6BFVQBqOCcamFfcMe1Fr9hlqWrKr8Hkv/FyQYjmoVrC7b58PnXFpM6A1BNQI3OCiDn08kwUgk2Dv0Yx6Wij+9Sm07zDPglY+YDXGXBQh8mf0l1mXdFag4vo2gdGS860SrR+CssrQManx/4WOkdIsZ/qCE8B7XlB8+tiEufR4yKicnE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-35-48Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698752020.4243622,
                        "modified": 1698752020.4243622,
                        "name": "/dev/shm/incoming/2023-10-31T11:23:38.ais",
                        "sha256": "3549f45a0b4890f1b79120225fceb2af476a9ba7b85d233ed4f7e46391d834f4",
                        "size": 46487
                    }
                ],
                "mission": "EFCA1",
                "sha256": "1196eaf7f7eee647c8754c3da31121103957d111e47728b66cde55ac6d7decca",
                "size": 13037
            },
            "errors": "",
            "filename": "2023-10-31T11-35-48Z",
            "size": 13037,
            "verified_at": 1698781848
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698755524,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "pbNirGco1hw7m88D3zqMqG35KcSmuih7I0PJtMdKlrCO4M6lCCmiFiHMTsZVT78Sbt3MZBgF1vwrDzjdB2QE925pAuJVcEmL44RIsDZch6li5Z6iJjD91hceT9sQ9un1KEV3WoFiN9uxACVR0zAfZWgY2NtOU4kS06/Mkzkt7moy9zcUZ6Tu7B+iObaG5DylRRyt9zTM4fXvimrSDd2vPOq8K4x3bA4ybeqHrRb771tDNj8rrsHWDLzwEEQ2Ff0ASFSx9luemHYWompvRyYsIt+s1dZJOsVu0bPTJa23QrS55i+yiCzy9KF1YbiVLLsPI5hWBRYN1mcmjylOELQV4R8UTsNn5GXAjXwtsXh3VKAUEuAci2+5yikNVXIcIC+L0s9DjvwDFXkX+AuxQrtycqIlrhqzB80QBzIj8hZmlWtOIegshHefAWy/S8OzL6bNoMEuT1TfjA30Yh5OK4dBNP4FojT2ZR2bM0Rl+505zxvWnBkjm4pmRFdSsFeokhpvRjhRscwdmJyamwNWajiQkh/Za7TawkP8PBk8MOTWW5+C4oKRvZqwNFo7KxiC7PgGYFnzFc/5/kTkhN2pkdb8H72yHbH2Filv21LFB69Gh034t2N6by/bgw7Ls62ZXluyPA9ddxhlYbzJdbD2d/gJUNFLG5sq2ZEnB2ZrfVheX90=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T12-31-12Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698755465.9343622,
                        "modified": 1698755465.9343622,
                        "name": "/dev/shm/incoming/2023-10-31T12:01:05.ts",
                        "sha256": "70409e37285897100cfc1207f9d02f956af10190078bcf2df038997d9d66024e",
                        "size": 410342148
                    }
                ],
                "mission": "EFCA1",
                "sha256": "f5d35acd74c002d91214f5ce61b76e1cb641acd8f272294566ee815322e6371a",
                "size": 395796313
            },
            "errors": "",
            "filename": "2023-10-31T12-31-12Z",
            "size": 395796313,
            "verified_at": 1698781869
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698748303,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "dh/K68q/CXZxmeQNxdCBzMh79FtxSogddPmDN7SqUiLxQk1vCxyRHIwbm7BkZAcTR/lX/60w82l2LKHb424iy6RpXYdsf8R3Br8ZQcn692XEQ63YsDKDiSqnGYy7X9Ctkb/cTir8G5raw00if7fTDX3iTcUIRnqZXhHtpOS1aSM7QHemXjDZXjeiKix0oVVBOccpUbg+kuqeMf5oTZJbQzrFDKbaxqf0ci2HYgYlvLRTWpt2VgT2pOCO6iGeiOCpMHttLLs23EqaiTnnxGMOcVx9ZqDdQy7VQspsqljBbcibUDMa7l5N0fp6xZ2esOIFRxNxDODFGiKznZxvCMuNAmB7Lvr14jy5mwOxb+pZXHTSAC+8tZJ7cJz5kyNvIYWOrszh0htk9B+tAaWEdDyf4pZ6ZruwbyYokv7oYDGudA2pVq4XsaKeTRofgF90krfsHZlBETf/76EWySNdfuvvBfB1xgInCM4NpW4t608oMbwGn+Zrcg1x36iXshmOPJgMZxBinmKu/I0dM9MW96oHZgr9tAsxH5os0UEZwhWF7P5euTDVi70/2CbeY7vF4k29ryC81Llz8/R26tyKHQxbsQIfSMpjVwTfznv1JYVLB2E++vuExtnz9/W15fYt5kzPChxVOYGSp/wsGxcQYmMEtvSFZb+c4eQhZHfu0phFMjo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-30-52Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698748241.364362,
                        "modified": 1698748241.364362,
                        "name": "/dev/shm/incoming/2023-10-31T10:00:40.ts",
                        "sha256": "59beab082ee1cab67173767bad9e62e25808586c0380ba03cd40b3c449ca9d48",
                        "size": 399707552
                    }
                ],
                "mission": "EFCA1",
                "sha256": "c133a4a6711d1c572520be4e72ffda3d10754cc946befb173e7f9757d5e37d9c",
                "size": 385018506
            },
            "errors": "",
            "filename": "2023-10-31T10-30-52Z",
            "size": 385018506,
            "verified_at": 1698782036
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698664252,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "sK0N3JHcJy6FqiMMp3PZg8E5peE0EaunHieL+1B6lyOrNmoNnwilX50VGH/mKz2rgCixPRZpY+QbQHmYZMpvr7Ih3Bu/spJmJo5iExcWcZ1q90kELDJYpMAzOXfqKbpDTyH3Jbrs38CuFpKFF0VywhYQlJNKiJpIT/508LU6sTSjVuCl7ue56UrjcZY9emy5y/9nF6v6+pDY22KrCcWU4M1uyD+Zo4Ovd/XZz7VM3znSPwuPw0j/gdCmjINYb9SrOVlw4T0oqIi50UpcM/AJyrJpa9g4STEMkCWDPRzasdb27pK3xAbzYfAT8gbKxLNcn3RyYl9f8Lp34TpISN8beor+/EOCK6Yk+jSU4ysycxGiX4H/5eQjDNn+lHxQvi8wjojOJQna+IfhVBTMX+h77pWi0SLeHAY8N6d0XioVaueSmqkZZ8baoPrl2/8QlluFKLIwUxtUnPjYKUk9w9sJeB1XGcoa9W+QZQIbtz+LZkR4Npb0GljAtBBpstbXogcFCnkuCyNdVvZweJfwIzXPdb0aMZciV+Uzu+UaK3x6mHytYw8w/qpMlxX2OZnhYcyZwU/pZmVnhnHs50bOa/K5REH7jJ6OlSw04CuvsYpy93VypMgZnYCIUH8yQq6LlN36dICaRKJ+RqU4SUkk/73lVN9BC6/nqhIsqWtqFFRNazY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T11-10-32Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698664106.0878289,
                        "modified": 1698664106.0878289,
                        "name": "/dev/shm/incoming/2023-10-30T10:58:24.ais",
                        "sha256": "d01c722c6e91a9ddbbeb053782f67517bc979e50713d3a8bbf7bb0b36a4caad6",
                        "size": 614025
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "c911ee2dcefaafe2dbeb177bb5cb12c8ba7f787abed8f872ecd7921fc4e8a9ce",
                "size": 176387
            },
            "errors": "",
            "filename": "2023-10-30T11-10-32Z",
            "size": 176387,
            "verified_at": 1698693155
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698749131,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "i+tFThFOZYk+vJXKaqBzvY4PIBPETqsJiRmjqwYZ2gpD+qT5QTFVvDJbeF49K/afm/zuSj5DxGyU8sKiB9d2wFJ9RX8Alz2fkK+DtsQHue+2o3OK3PvegJ2jHdXd/9oEwkK5u3M0tWXHQ2tCfeGzDOdn8+y13oftS+00Rlg4GciqytCdDEYpyxZApjxyeWmyeh8RDY85D8ocrSjJzFSRSj/BTLQNuTqfuUiw7gNf6j9GaZ2efY1OOM3SdadbEF4sdezE4axysLPZGJKOfprfxGgrTvqYLrLNnz9w14p83XgwUMhmfzNNkiH8AKhIqCzVvJv1252C30/XZFZOk+yHNrIR3d/0KMJcICr2DkzocZVA0XwGbazkfKU/bMZ66bJ69UasQ5y/0fZUjnWmSWgaBg0PCuuEeMZAxXc1pbL5ByM75OqdsiTxj8pSPqZpfjadQtFjINfW4o07s9oBB5/67ehaRVcrQr4K3PCxb+3ZSxF5TPgjBu4bSQVrAB6PMkuP4i9KjFIgVnh3FUgfSrfcSLsiGDkp4yl9FxH/dXQ/SsmiyjdwDzc2P8jZLhLCidYSVlfRXiF7rZjC2grfZb0b3DDyV82d65ypRY9GvhRQBNSBDzf8Q1/KdoZmL1GUzfPY5v7PJCqwb6URHoMKlabBUMJjCHX95nfo8diRdrwO1qM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-45-25Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698748998.854362,
                        "modified": 1698748998.854362,
                        "name": "/dev/shm/incoming/2023-10-31T10:33:17.ais",
                        "sha256": "a227044f8e983e5d171a07695899adb1b0a20d50630fe7220da7adad8d0c61c9",
                        "size": 113835
                    }
                ],
                "mission": "EFCA1",
                "sha256": "fc43863f2d5a287cb55a01bd9ae0d58674fc005f5b72d830b23799d0c5a3a35a",
                "size": 23382
            },
            "errors": "",
            "filename": "2023-10-31T10-45-25Z",
            "size": 23382,
            "verified_at": 1698782143
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698655853,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "nQXAEor29Tfm387EZu0dzI6XlctZKV6tKyLVbY21tuMGSeGaFBT/4R36w0neechxY3Nq3fYzM35IatQ3MIOccGFZfCI+FkUX3YQxXjXu/H/L0+sUlEW9dy/5nB6viBozlSQyyJcHK+Pg9E9Xyym2UtwRQdIPv3WMhvLDbEkbZobt1g7AtzHJVvb6Qq1pQ8ja2KwktivSSDC+CiVV9PuSKi+wE5JUV36NZl5AHadDmKwJIJkpiA/Zb9cHgiwQNy2Tx+d3J/3QS92WK7KxGd2MaJtoCaRQGvJDOqHBNzRWW7Wa3yNC68DlKPFylp9ZIvRhLSJTbcxWGK0QKwpanJdRgLH5F1BYPMeK/9WXFMr3Evav0/0SSWWAzcxVAdYNDlds+OT1s/AGAHqBWMi0Xb49Apro/TpcvSFTBtCZU/kDTEwpvd1FoSPD2+a3twbQ5Wcm4sXYllIGA2/YHb9toqVdMBjxn+DGDS/bzYJdPbyRbzn5/ajhMVsgOh3UocbN/Ta5UO4abfRlABxaDJ2QqonyWaEX5wpu/E74RKIpoNCjnhbfHrqHf4qdYy1ladLwTRMXxFEyjbePdLZwMIEGzg6Cq6Oby4X8XSSUbM2fNginUilgSAA+7vgg7lZ+YfDeO6h/AuECATf00v0ugyl5emRdcv4Ns3fgwiERxpinX9wqziM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T08-50-26Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698655701.756036,
                        "modified": 1698655701.756036,
                        "name": "/dev/shm/incoming/2023-10-30T08:38:17.ais",
                        "sha256": "7594eb1dc7d9b9a5fb15648b93d23b899048f7134c411da5d1ee08ab17724632",
                        "size": 92284
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "0e635fe2cf51777bc722ec1d1c0ca81925314b291b3868e4b856960e000e518a",
                "size": 25398
            },
            "errors": "",
            "filename": "2023-10-30T08-50-26Z",
            "size": 25398,
            "verified_at": 1698666846
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698657058,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "eRpZaekF/6TC6ohayOG48UJ2EXNlyDJC4XJYXsEaG9IJkJh04vN1gYgzz0S1HbyMLs/2f36XUs1d/8xTHaaoTPCBxo+ARlQpsdEimacTXcvVM51idmzjnozZbTBJI/XPcXhYetD4NleVcN2ajZkMRvh4jC6Lp3EZnEfIJ5V3cfsbqhq+PbU74RlhIJrAov0PbVAq+yU+xAG+il3SuhpKoTlUP8GNLLa39hI1TuJVkK0eiiKHQerVngrae7RzGC4V98hDhqB2gXgvGI6D9o5SJ7xFYP4AKU7FRUBUIMPNCntkheGnWFq8rrvf78Mp87JYdIrviZuPtoY9YMjb4WTcNkbVSAQr2xtrnNs/ApBblrj2bdnA+Szaq8Ek4qFq5gfArigxd+30Fno4nlyhHVf6zOxRBooSsN4c0nPAMSIE26Seu6r8t4hMVIyiju6DHy5JNdneAdSbNkWM0XBJTThfbpEfORI412PRdek1eIX+xYBFA6zOOvVCBz8uWWasugSM+JKVib6vZOhA9VLRng5xqkTrrpWzhahzYKeDY9wrKj3O7zsrKlNBtsvzxYmCAN2Py6RDtSpS9/6CjfAfMPCoNDrpApMkyxQgPMWDqX6wVke+FdzznsKSKAUxwQp0o7WDVXkMdDvzMDXPuLfV/NDMZ44uwXIlnsFH2GYRQOs3SD0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-10-34Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698656908.896036,
                        "modified": 1698656908.896036,
                        "name": "/dev/shm/incoming/2023-10-30T08:58:25.ais",
                        "sha256": "d81aa13c3c324c0018b1f60a6b5cc597c8e6a0411f1378c8047e4c6d85cb1ec3",
                        "size": 82359
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "a6ade34bdc090acd050cdb0dc090c1f8d5596bd4165c0e792fafbc5c0ec388cf",
                "size": 22702
            },
            "errors": "",
            "filename": "2023-10-30T09-10-34Z",
            "size": 22702,
            "verified_at": 1698666857
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698752756,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "aUy050RlZktSMip6KI2z9gl89vohdV0RP5GSwIr2/YxhkIgZjaW/r1ToRzPozuC6Nqr9/NAkTdrN5YjGf6DnuYB+8Cwc4yOEJhRWH2HVn+2Mchhgq36+22M5p1h3CP+3yyC2jYd+enBaFGBJOn2/viHV+0LFh+tKlFQad4NLnfMSsJ1+3IYAz7iANL6DnPGFhibQtyTBorVKoJcdYiwtD5+bmfSJKXWQl6AbXzrTmE6U4DV9Lj1amUVaH0Dc8ex/JD7tZ7j2ir4XXuxJyazSQTEWE85fyeaf9cHMiW483VeWyodPHd0S1Z20tCyIJtkdDS8mKez/u1ipL1gtdDlznTc9VVuouGhY35yV+RJejDegCj6cNs9Uh8X9phs75LTtix4UxLaFIqSB+7NUPwUO5LLkw1hZjF3yfLZpOg18i9KDqwGbclBCMmOkIfSJgPne5YpH/2L/NNu67xD0GMKIDx46PYu9A7qsSW4cHHCK7TNVuNURvqlmzx+VxlBvDjzuMffJqGTqXyngpMD71Kaw+/4sI/5ZhuB5AyREewayiwCZ6uMA7kvaT53MvH8y3I+cyCeFgyC12hdkCoOQDl6pVYgsAFVPHMCIAYNe9xVCaxhQWdYVTfdmKSdQaofZzHidLPHU6PRim4aim+I1TCN+vM4OwOAj4LyLCloxXfMZZUQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-45-46Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698752621.9043622,
                        "modified": 1698752621.9043622,
                        "name": "/dev/shm/incoming/2023-10-31T11:33:40.ais",
                        "sha256": "f9e3d0f963f60068640464535807557b348ac417ce753f3b8caa4898dfcef259",
                        "size": 58517
                    }
                ],
                "mission": "EFCA1",
                "sha256": "aad0f422318e46fd7215ae85bdeac1b80b1391fcf5ac24c99b4327100edd6f12",
                "size": 15723
            },
            "errors": "",
            "filename": "2023-10-31T11-45-46Z",
            "size": 15723,
            "verified_at": 1698782145
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698667234,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "XsDhAgf3rMnntu5Jjl/SD0lfH4mEx19mgFQtADMVo3nkBdjc0raqTiQ6XsDmAbrUDfR31tzSY4JPGSyb79989fBIwUGXC+rCM6b0tfU/jlqa7CqPlRAvYP+g8F/kQXa+d7W4H06i1cfG4uGSQikSdZXP0fpGqoQdF26N78OO3CUUVSR3+K9nFO8i1qkiONdpvloEX0dQ+c7Fgm5+FQPh06Bh5kzMw/+HJR1l5su015Zp66kloTO/By2ROjUS8rrmyKJA44Hy8ui3JFPs7yyJyFvrqk0NAnE89QwEYOl9KAuL2jQxQpyBJMZJgyei5gE1iX1lMzCUcT6vJQjSn32E1RAtEIHNYXiLPi5ZGFfl7RV8DSLBV89aEdw0RkSldwtmkEL9rLBFwyc7LQBHTo7ly7PQlDi13mcSdHr7m/QxrSwstQvzhf/pPTRwT5hMxX4XtOURQnT6U3fG4VttJ9n64Glow+MT99AzYUaO4iS2TZTYSc/iGVcmIxK5ykJOacgG98ucA4DwbrH06sE6tT1eZZHVoFa4Qp7JOty7/rUsLTW/Rnd0bn/nu0J6XQfNuQhsxN2xosLzgTmNAEWk52why5I/TqQWUFoHTuvjyV7de+vfcUCgwLJx8Ya9+tlJe7oRx6VzqnFheyf7mHRWVAnxU7z/gzn2L2NdWeIX1pkLu1c=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T11-58-01Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698666957.8279407,
                        "modified": 1698666957.8279407,
                        "name": "/dev/shm/incoming/2023-10-30T11:45:56.ais",
                        "sha256": "37bb9c4025107aa9631c11acf48774b1e7e57fd024e5e69386d2023579701b7f",
                        "size": 502660
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "e9c1156f6cd68415d4e165cd904ecf19a6145ae3e1c183146474454e4f916f2e",
                "size": 147295
            },
            "errors": "",
            "filename": "2023-10-30T11-58-01Z",
            "size": 147295,
            "verified_at": 1698684052
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698658102,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "tWNSeqJU+pYcPdGsgNFBGd6NGVNofKEa1ii4G98fKqPazOJziiSEcq0Vv45NAXrZj5QXZZuZEw0+wQux8VGeSEwSbq0cKdTaoRpiru4nF9ZEihA4JkvIJTjFhQlFyLVfrYFkvqxhK1f1/yIISyfPkzPdMvVZK+LvSq9B3jv4C0CWWKoi5D4JDoMcyP0f2gJMyemlNdMEQo+8Ks41WyqrNWHYWaHyKdB1bw4a4yMVpf5qjmwDs0nZOFLf1aWSwdBG0fNUx/jXI7SuRgfMxxdi1NippZ7gLDzLwdpKUVi1fljDT2Bp3Ct+JNFCBADO9hWoTdmCJXgi4Ri/tDed7Ar+gp4+ZY1iKZRFJmzNDqBlFRgm65hT/0RMAS9KWo0jhIbsXSMpNtmcZLJtDcCdjbQSUl7aqQSYHlSs/W4cnaBQhtOWKwMo5Sval5BYF/dsyt3WM8DWPccsWypyJO9mM+YfyuuMwYYnPOZcKKaTZJT2ZrYJO1ffxZlOaIS0g+fEmTniF/ow+w3qmOfaqEYSHWRTAQld9sKfiXrL6sg2pkFw0jLXTWTBtzGycR5ikCJzrj0Ui/TisSipVzLMP6jmqIL27HIHgYGSrzJtDJeq6MWDsKSaHKNZrdoxQ3WVXHynaYXXTSz2XMyXydgDlAULIzgCkBym7dsU1A9al5zqHpB2Zw4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-27-36Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698658048.576036,
                        "modified": 1698658048.576036,
                        "name": "/dev/shm/incoming/2023-10-30T09:11:14.ts",
                        "sha256": "db13945cc821c71c2e3c013adc51b13be8c937f8d79a97904575c905c0681463",
                        "size": 209089276
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "fb5b4c8ee0b0c0dd9499c01df954b643ff29270a4a32174a259b0fdab76f16dd",
                "size": 201527732
            },
            "errors": "",
            "filename": "2023-10-30T09-27-36Z",
            "size": 201527732,
            "verified_at": 1698667272
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698742058,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "eD28Yy2kWwjCdCIkGPoO+JMokWErIY6RRCWFy883tuspPqiPimjCmHdsI/qnXnLOgv9tGfVpnQOt0izSsZyJ0ByCE4ThRDPOzVRDagy3MHY6Pgtv6g0vqz/9bDN4e4a+S4O3qttuDCfqgxHhWaLLjJ2lkikP6mO2p0WjgCUclMd7fqsxUPheyF2d06WvYHZa2MTND3TcoHHNJM/Gsu8jAR+0h8LG3q2QruloFxUNbNZb0xjiG4xRj6V/wAag9KVES+KJLGN/AKSsCOyAZCLNrt2xlnil9uVnluPS0LdINheuM3jiDYv3aL0p7eIdXoVezAzOk5WUvO5HLWsVgiLZBdQnJxeeKpU74pbgVFvX/U9BoHMhwpQzTHRmwOFPGNE4l/Yy4eSFV6jgmtkUP6zD3o729Jhr+DfK7VWE5ZnEWrM0tCrKkghfvywYoMRTKkiH3PwtG7DXnmOCcJc7jcnnxx0/Kv4gjEVDVhrdeBdoPaXCOU0ruNla44Wdz8G++xKZn/KnwauXh0Z0kQJ0zAoynjFA90ykr1z/XdbSFxqgiYQHJh1lLMYMLYCAGa5wMcblovVEeanciljEMTR+25pvz7szHi4w1OdjVuEh95GVwdZmxIKRbNR/z++wLScklywNco60Vvz43yrOnl1clXWYKawbnE9Z15MWTmpZz+r+lqw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T08-47-28Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698741920.9314184,
                        "modified": 1698741920.9314184,
                        "name": "/dev/shm/incoming/2023_10_31_084500_00_01_V_014.txt",
                        "sha256": "68fe52c16d423f6770605c05d8e5c9f7ca92b4f187563c26be9e4140435a8fe2",
                        "size": 2041
                    },
                    {
                        "created": 1698741920.5714185,
                        "modified": 1698741920.5714185,
                        "name": "/dev/shm/incoming/2023_10_31_084500_00_01_V_014.kml",
                        "sha256": "4d8f73766cc689d3028070ce0a22c0b8ffcfea822a27778ee890254463fec8f8",
                        "size": 952
                    },
                    {
                        "created": 1698741919.9414184,
                        "modified": 1698741919.9414184,
                        "name": "/dev/shm/incoming/2023_10_31_084500_00_01_V_014.jpg",
                        "sha256": "bfc63417779c9a0be6dc0d5e80eb30678e55035775bb3b680139e1d587c8b512",
                        "size": 1465081
                    },
                    {
                        "created": 1698741918.8714185,
                        "modified": 1698741918.8714185,
                        "name": "/dev/shm/incoming/2023_10_31_084500_00_01_V_013.txt",
                        "sha256": "b9d78c90d11eacd93cca4233faef7bdca5c009b032f27051430d32c00c8626c9",
                        "size": 2041
                    },
                    {
                        "created": 1698741918.3814185,
                        "modified": 1698741918.3814185,
                        "name": "/dev/shm/incoming/2023_10_31_084500_00_01_V_013.kml",
                        "sha256": "66c92c5722b80e68550c1b8a625dd8664f9772725d9d62a53d582c380bd9ca55",
                        "size": 951
                    },
                    {
                        "created": 1698741917.8314185,
                        "modified": 1698741917.8314185,
                        "name": "/dev/shm/incoming/2023_10_31_084500_00_01_V_013.jpg",
                        "sha256": "f057aa1a4d52e0a71f910957cfbb101bdd40a33cbe182348a1168195664fc37d",
                        "size": 1414740
                    },
                    {
                        "created": 1698741812.5314186,
                        "modified": 1698741812.5314186,
                        "name": "/dev/shm/incoming/2023_10_31_084313_00_01_V_012.txt",
                        "sha256": "9fa1eb9d69bca9e686e5e4650c0ca630bb6109ed457eb9188bce12343eac1e15",
                        "size": 2040
                    },
                    {
                        "created": 1698741812.3414185,
                        "modified": 1698741812.3414185,
                        "name": "/dev/shm/incoming/2023_10_31_084313_00_01_V_012.kml",
                        "sha256": "eb4aeae838a279aaff3b66b7cb150a3632407e73d899ff6be80d7a9b3763f707",
                        "size": 952
                    },
                    {
                        "created": 1698741811.8114185,
                        "modified": 1698741811.8114185,
                        "name": "/dev/shm/incoming/2023_10_31_084313_00_01_V_012.jpg",
                        "sha256": "6b8a081d339983073c5747378cba27ae1291bdfc8476de18f697873a4e93b8af",
                        "size": 1251566
                    }
                ],
                "mission": "OSP1",
                "sha256": "a224609bca0467a9c9f8473659c8e9609f2df17148a6a071e9e389316480d0ce",
                "size": 4136695
            },
            "errors": "",
            "filename": "2023-10-31T08-47-28Z",
            "size": 4136695,
            "verified_at": 1698766909
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698741773,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "myaz5ns67IzNrQp1l/nAeOYeOZ+PiRCA8nfGnppyC6ems/fh6uVNA9qimw4ijaXI14gJm4zemJtixXCHw6ct/1yBnEVH0mn9N7SH5KZnky3qhnHxYKDFO69IaQc5c8OMcF7q3BG2RD/W+onJhKTuUenvFRLCh+q9pEr2WiCQu5Nbpty4edA7rMLbQRrssjgZOA/Tmv3TpNwkOoPzCrEGMd78tpg2hQb1k7rc7wmGkkXWNpUHaHBlPbHEDIkhNaJTwBGKJDO9LlPxcb3Pf3P81xuyQxCtY/AYagSJomhCuG+K4Um5XHOw6zNlsZpbqih+OdDfoV6+Ua91tRwzxtOSFouY2Da5C489HANM6x4viFzRUfkVaFjquEvHxgp9mdAfBsQ0zF/eu+X9EkXhD+oomujVLkLfMZkKS+/it9r+WvX+YvrcIcKb/15cb3krliT1qmaZI52UDylW3tb9g17gXVqcw9tkD2ZuP0arWmQjym+oWj2ONiYsQZiLHb1mKqqPybJNiUBlTOhFHhI98kYjoIKOagGTM80l3wVUg3ufvohbPPDio1J0q6+544QcDHoCTs17676rk2lsws3atlGtset/tzVkIvoX2S5GR3Homo/RXwM1/iCKPGoWqxKlVQwD5VOWa1Twd7G2zKDjfMRScPtlBI4H714BJocB3lERdwQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T08-41-54Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698741586.3514185,
                        "modified": 1698741586.3514185,
                        "name": "/dev/shm/incoming/2023-10-31T08:29:00.ais",
                        "sha256": "747627b2e7b8bb3e64a631c3e4cf605f9bc723aaad49730ada1f18a123436afb",
                        "size": 130031
                    }
                ],
                "mission": "OSP1",
                "sha256": "c8c6a05acae2df6d1e7c40b05dd5bd0ebf809488a9388409c539da8534fafa28",
                "size": 29656
            },
            "errors": "",
            "filename": "2023-10-31T08-41-54Z",
            "size": 29656,
            "verified_at": 1698766971
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698741941,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "Gpg8udz2PNiIP85Q6wNe32Qfc/+vmcf/OSJSteldd2ckH4Rbp3MyhqFvPjBbbjLJVHg1FH6PYvPJwT0cPIfsygvpsmjnzTsiNdm86h0HLQDm49g6Ohg1rNvbS3oxAL2ru1BbIs9uAjkM+A7v/kR6JcTjePprbikvi2J/Kf3FEUj2z2ZlXy1qXNVi3Xa232D2/V/UPT/0NyVJDKLH+JN/0esJx1vD0tlJCZ4yMTbkqLJa/CYceeXWWEy2cRQKXjp+JE9Fi6U/N08OosMzVbAes08bc+LniDSrHa6haYM2Yqipr5Y5SQ90/0Pd/b5KKY/+gf3IxmWck4RjtJ0xo5jUtdS/GArj8QqYjqcgStDv8cqKcYMwkA1KZuOymXN//8O55qsYjndeYY3hW0UNmAELestxa+hB7gzbnkT9SbuCSvAdDaLR9z5QcZuyjwlQaJWQZt7LW/rP1wfPsx2GDS9J3ILpGcVl730OzMl8bWcJLa73PBfiUC5lAxiDJm0F1QkQ6PIV4gts0dIc/sCgFR8uVb26759tdcR0vCKhhPTTSacMzX+JXhVF4iCz0r3a1kZy6hsKd30XGy/Wi+Y8iutYhScY0R0ZNK3y7/4WyWfslcHQwOiRs2Oc3A3OmPgpwdk6/SDp22t4Y5CbiYbwkeoIa1P01unZkuRtBEk7AluCHy8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T08-45-20Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698741802.48126,
                        "modified": 1698741802.48126,
                        "name": "/dev/shm/incoming/2023_10_31_084302_00_00_K_049.txt",
                        "sha256": "e1be2e7a1241672ea43f267a65568bc8cea1bf9ec549b5e0480310cde6140203",
                        "size": 2047
                    },
                    {
                        "created": 1698741813.61126,
                        "modified": 1698741813.61126,
                        "name": "/dev/shm/incoming/2023_10_31_084302_00_00_K_049.kml",
                        "sha256": "d2bbbff994dcaa842c2e6e3490d76892c506913ea7d7e742fdff48685b841086",
                        "size": 977
                    },
                    {
                        "created": 1698741795.28126,
                        "modified": 1698741795.28126,
                        "name": "/dev/shm/incoming/2023_10_31_084302_00_00_K_049.jpg",
                        "sha256": "d91be382244f1ce55fdfbb404b3d7315238ac730f9c6dd84fd2bdcc996b8ed25",
                        "size": 640136
                    },
                    {
                        "created": 1698741748.48126,
                        "modified": 1698741748.48126,
                        "name": "/dev/shm/incoming/2023-10-31T08:32:27.ais",
                        "sha256": "24bdec246c527f50f87d28e694c27ad3710b3286c7e6147df8e2c942a6133012",
                        "size": 571716
                    },
                    {
                        "created": 1698741719.55126,
                        "modified": 1698741719.55126,
                        "name": "/dev/shm/incoming/2023_10_31_084138_00_00_K_048.txt",
                        "sha256": "1a338392c17f259dc7b66de3ba74d79850361497342dbcdc489b196e0786026f",
                        "size": 791
                    },
                    {
                        "created": 1698741726.35126,
                        "modified": 1698741726.35126,
                        "name": "/dev/shm/incoming/2023_10_31_084138_00_00_K_048.kml",
                        "sha256": "0c1cba4303b4f9d0520b7b5b12ac9e4f307da3f01944d8759181643569836cd1",
                        "size": 893
                    },
                    {
                        "created": 1698741715.32126,
                        "modified": 1698741715.32126,
                        "name": "/dev/shm/incoming/2023_10_31_084138_00_00_K_048.jpg",
                        "sha256": "e6325521d76295628d80d4e395250086b5c6f7277e851a445f36c0626720ac1c",
                        "size": 602356
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "dbc9f1a03e4d33d0b361e520f0c1111f0c4682f6d6ee607cff7418630fb55b9f",
                "size": 1398104
            },
            "errors": "",
            "filename": "2023-10-31T08-45-20Z",
            "size": 1398104,
            "verified_at": 1698767125
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698777855,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "lpsPkgx4ta+/UyrfFM025pSIjSu0BDX+xcM4/uqys1aoHaJx1GR6Q2AcJtIck7vsvLrucQlethbpWsieI0y+eDMgo2TgF8idSe3/0VEbS5Kokx8oyF5E+GGBeI6hfFoTpNg2CRoQMapAzW5HbxtFlkGQcgGYoSHo9TXt90qcyrmKiY7yRs5LwrDuziBx5F746Stxrv7xJB0HTF6cA0XH0XB9eqRJkDAk7fPTSF3ecW+bE5EUUQshp2GMLoK+zF9QcyV0dqfTQJn9mLz5TEesJAN3o26OZgLJnsaYAOfSqaKOWd/7sm9ZRguJhXlk9JaOUsYiVnAr2hUavkqbFntwtX1xC83uvWZ95ytayE7KTlbKl4YAKmVziK2x2LOq5sZ9O3egmlTwTS3rkxJxJBlBD+SQ0rkcye7/tJ+DxDA8ddfXAC91sqq2Ist/yADSagnfUSZfMhvwI6GBz579pqzP3ntVos+F839Htd2ERS7nOYAgB/e1hCLjPxCKeixwOIeJ0pic7XYdmZRtRq4JacyPq+KCaWMMj6bUMJdVo2xAOxoe3D8Ht8/5dTox0hEAu3eb3bYyi7vdVxKogzOnmMPTgcG+zQwdA19tX3kHsqn4pKXpWWMnS+TNruQsxx2XMf9aP52THj8LYq2EaXyy2VqG57KeNjKFGAFbKNZcsZX+dEo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T18-44-01Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698777717.673036,
                        "modified": 1698777717.673036,
                        "name": "/dev/shm/incoming/2023-10-31T18:31:55.ais",
                        "sha256": "776750b8cf00a49be376664ba544d4973d13090bc5925008f0bb5be50ac6757e",
                        "size": 62833
                    }
                ],
                "mission": "OSP2",
                "sha256": "74a80fd2b6a7785dc1cab942ff4293e7e9e74a744bc07f10854447951fed0db2",
                "size": 14759
            },
            "errors": "",
            "filename": "2023-10-31T18-44-01Z",
            "size": 14759,
            "verified_at": 1698784265
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698774252,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "gXLtPVS/rHMDpaySAs8qeh+wQ7VYRYH3qqXwwDe/MYDbrPa7Bs4SiIEEewpI1bsRrHI7ISg55kNa0g3rSpNRw60VFhTErz8Rh91Zg/qcgvtKfm5WceGn7jJLyPD/uqxHlZct8lpjVdgT2GScaPoYsCKeqaD1d4L907pPiGwDsZZFOOSA4vERkw+/WkR7oXYb//B9rM9w5B+QT0uPn2gxNBpgJNZKiPWPvoqPi7HrTzBo1vsuDWt3FvNFnZoI2XA9wQ5fQZ4w4MKKR/z01X2zjnyM6SCBfVa5jGYQ2BomEqfs7yumfvwTAK+v/Far3r1VHBtCwh8Wo8w2Jpx9m/XfA+afYCKsnnX8auyioCPWG+TR+68KxayUrBR883TQdx1jjjg50wE0vUdhbTQ0opwnG9dh1EFXaHZSKUDvXYDcvLJSxkXX8NgEB5W4RIxbNWpkei8PNuQyabs3PY1AeTcRtJGK9hGjXuqr3dcalCLfSW3nSiu+QtC2934zp7v3Z8WAwfvUU7nUYkor5tnbXXul160hMenEpPHSdT2/IGhiClsPiHNHyX+WjjM1jLwm2kxTdeyl6F/m6NWvy1h06J8ZWO5ZzIvK/+EhvsxoLYef2lCHu0KOr31EldMu/lC9SfvGg0lYyCDGPcBfFIx1E55iff2eT0l5zXBq4Wn2UGxsdfg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T17-43-53Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698774105.3230362,
                        "modified": 1698774105.3230362,
                        "name": "/dev/shm/incoming/2023-10-31T17:31:44.ais",
                        "sha256": "a6f0da86df0c4a7e1bdc630dee011ef2b390d80ab4337b431d941f4b12198ad0",
                        "size": 61665
                    }
                ],
                "mission": "OSP2",
                "sha256": "e7ae67a8836af26c1eeeb02bed5b308e159fa90b471528a8721ed21d2c172330",
                "size": 16837
            },
            "errors": "",
            "filename": "2023-10-31T17-43-53Z",
            "size": 16837,
            "verified_at": 1698784299
        },
        {
            "aircraft": "G-WKTL",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698659254,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "paIO3DbIv+yMI4YG8jb4TvNiUN+A6Lq4H7EvFwRy2xKBdPaO9ybuJCsVz8jlatrwdqNFzQg6GeBL63OiZwXQvDiLqpegsBVq2l8fmg4sDB6fUSQd6f+nPtmE66u+l52HJqQN3VJRs4CnTKwi9eCMscix2mC2geaW0+pTOIcTWJicON0U0WvXiv8alzMcgmUnbveYCX4X1Jp+o5KGDNZSW0bkduA55lgNUJhPiLsTcHaErxBqaoLQxxFmitsS8XZmPsXQ13zupWvlimWcyrJVp9zPauGACgp4XcCjyt1xy+Iw51ibZXQnNnNlRq9K3TS3pH3/NMkxFJULmgesuXtHW9eCVq/khdGPXdllpxEZ2iTGVYAT9c/6gv/kr02vORc43u/oAMLtCOK1mRVPxHXFYGB3DY7qw/iFJG5A8Vdi1S4H1ajpqwhVk7mMLF6mDtVvJZ50so+Zt5m3SZiXKFM4MIDKKGYlppRegHQ45TdaQjMmx/bByR/4sDuFDmjucC9uQtByD77f+mBjvbC6KimlFkVXCjLIfIXdpx2y626kTAU8m2flcTnWzIne/NpYs9Rk90djJ9eZ6cQ9CWqpfLze8MLlzPw1BskgOugOoH8StLWqfWvJ5Y2haZul/cIWjk84BfWuc4TFN685R/+u/go/FPzQOwnJd3x4rqCnUSQLVNA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-47-07Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698659110.546036,
                        "modified": 1698659110.546036,
                        "name": "/dev/shm/incoming/2023_10_30_094444_00_00_V_048.txt",
                        "sha256": "9365f889cc2b70c3194ad802ceaee8f7d92057bfa6476db62f8557c7cdc5e7b1",
                        "size": 2029
                    },
                    {
                        "created": 1698659110.426036,
                        "modified": 1698659110.426036,
                        "name": "/dev/shm/incoming/2023_10_30_094444_00_00_V_048.kml",
                        "sha256": "0bc49e6879aaf852842ec33f63fcebd67fe51c0486a502426fd72b945a073b65",
                        "size": 976
                    },
                    {
                        "created": 1698659108.246036,
                        "modified": 1698659108.246036,
                        "name": "/dev/shm/incoming/2023_10_30_094444_00_00_V_048.jpg",
                        "sha256": "05dd9396d49f7394b51257de11623d5ef01b940a43b3f34b3cc0560f33da6633",
                        "size": 933004
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "139504d904e39d2d9182a1104708be09ca987d21ce738d25b29fa89d2576a12f",
                "size": 934406
            },
            "errors": "",
            "filename": "2023-10-30T09-47-07Z",
            "size": 934406,
            "verified_at": 1698667307
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698742309,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "UCVOIteeANao26v9Ji5x4Z8tQM2zDH32yWnu6eIIg3GHnENpwDPoZy9sMW3ZoNwGjZIBtqLs9DOQrTPcDYlVzzngHPl9uJnA9CWa0G8G8U403F4KdBE182eABsBCJ217T8/FIkjM7P2Zk/3xHiWv/TKbnujcNzwUk8Gdda+KKwo1cuopnLmiqFRcX7W1GB6hmaREEFLEv6H/5koR1jZeCP/zxooZ2/CnR4CgHj86cGoD2e+51mRCJ1+iqJF6JzlYSX4tagCyfTwmlp+crObmxXtsrSlEwxkp0j3koQ4tFXtX0hrSdTczF8AgH/3I9N9laWZxZ5d8+zNIbzXUqJyAj2G1JuYfsjaeR+wFpGs/wmiIpllJVSoQvvYnv06AbCqY2anHetAgP2kMMa5Hi06MrNQfMbd5NmICPULioQgSgQZr4835vCtmWVqFLB1bKIJTpLUtVgdliIsg4HMax679EbJGoGJ6KipfiusLwxWa0cQCmIY1FMcOyztSkh7REsbNui6YF9FL1zrdYAJTey/hDt9cC4/KIcNbkuPAW4LcaZjxkVCRyVGQCsxVxXZN4uQ4if5J/NzNjTBnLrz3vamvTh10e4YnpIWNZt5FGJ9w7S4/CSrb8dTPkqBA1cWeVzBeZZZVQvt7hQfQD1TGWoOHMnLufat27oX7Oa2wodhLaNc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T08-51-37Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698742173.8914185,
                        "modified": 1698742173.8914185,
                        "name": "/dev/shm/incoming/2023_10_31_084920_00_01_V_016.txt",
                        "sha256": "9db8f8a150e7f389912a9e4bba5ae990a117726f414b9f8d7a11844b55fcfb4a",
                        "size": 2037
                    },
                    {
                        "created": 1698742173.8114185,
                        "modified": 1698742173.8114185,
                        "name": "/dev/shm/incoming/2023_10_31_084920_00_01_V_016.kml",
                        "sha256": "cac37b3b224caaa4e9690a3bfd31e391878ce281e6e2b7080ae47fd21182dbe1",
                        "size": 953
                    },
                    {
                        "created": 1698742173.7314186,
                        "modified": 1698742173.7314186,
                        "name": "/dev/shm/incoming/2023_10_31_084920_00_01_V_016.jpg",
                        "sha256": "50818386f81ad70b0ba95218c39863c55f7f42efb88af069df5751bcab22f084",
                        "size": 1694387
                    },
                    {
                        "created": 1698742172.8414185,
                        "modified": 1698742172.8414185,
                        "name": "/dev/shm/incoming/2023_10_31_084919_00_01_V_015.txt",
                        "sha256": "0678d8bf7a8d51499f2826eb4948202191b843da0dbea994aa6b48010588a384",
                        "size": 2042
                    },
                    {
                        "created": 1698742172.7714186,
                        "modified": 1698742172.7714186,
                        "name": "/dev/shm/incoming/2023_10_31_084919_00_01_V_015.kml",
                        "sha256": "acab7ba73c9d5809c8d98e65ffce88c592d463a3a3255ddb686aaa97e84a7bc9",
                        "size": 953
                    },
                    {
                        "created": 1698742172.6714184,
                        "modified": 1698742172.6714184,
                        "name": "/dev/shm/incoming/2023_10_31_084919_00_01_V_015.jpg",
                        "sha256": "e70d1787ffd7c36fa23e29a19871ef03c5954c901ba650958ccc4fddb32acc2d",
                        "size": 1385208
                    },
                    {
                        "created": 1698742142.0914185,
                        "modified": 1698742142.0914185,
                        "name": "/dev/shm/incoming/2023-10-31T08:39:01.ais",
                        "sha256": "57ba6c9fc2c94a324e8610401251488264d761a2feae459855bc917a3ec61417",
                        "size": 194312
                    }
                ],
                "mission": "OSP1",
                "sha256": "1e6fb64789286e41b85daed11523e714d997df6815e39ec347bfd8c636a000bb",
                "size": 3130420
            },
            "errors": "",
            "filename": "2023-10-31T08-51-37Z",
            "size": 3130420,
            "verified_at": 1698766832
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698743090,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ah7JDAKEVTiFd7H39cyKqLjLYkd/vmq1SQVWX6vrCPOzJ9hsUwNKLorwr3Zmcsjqf+8kbrpxp28N30yvWHVFG7ovGE5dOzl1kDN8YqGDIfXFlO9jQdK/OKxiEpDf5NTgvSgLm0MXIcAdsp1TFxWsnj6Sb+YHQ0x6J4i2iaE5xZa0egdOKWvwxlEWIpAaFlas25sQt9ZFSpEnniS0G9/85HHOFKpiM20WidiVr/lNhzxghRnfDj3Fhv0tz7i8KS3gE3Kvy7VfzNa75eXfB8a9LbQEKUvQuQWklf1WLmIVwVcuw+20PWOmAj3BZx3/x/SOMDYumlQ4Fw+Ue8lw4sjhy97f476m7+JKeNy5Mq3TLDp2yMoyhkde5SplUIADFKZoE3DY1p1BfX1l9N5Kx6AbMOxmrLuiYMCfTeoSBjegb2IOtDXwV9FJo5NrwPCp37J2pJrlHTOJs+EYsIoD29eyfjzbSgsUg07GvgVCHFBNj80W6MkytX/8z1yzGHB901V1Y/MhUe3w7XzP9TFOTU2sxMFgWkHUJbbIDqW2Y9j53cPLLN3Zz4+KHtee7OiX4cA9USO6m4Du8/ZvRZi84xaWippxUVpuxB2kTIJ4v6Biz54tmYeEszAbecMxCmjbDSRYMMJa00218m06YnEWgHQOldGmLRV3QmpiF+DLrOOOPlk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-04-33Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698742945.5814185,
                        "modified": 1698742945.5814185,
                        "name": "/dev/shm/incoming/2023_10_31_090211_00_01_V_017.txt",
                        "sha256": "25da79df48feb66d381c1aa45ed886916e34ce9f3b810893f1e67878b8c028ed",
                        "size": 2039
                    },
                    {
                        "created": 1698742945.4914186,
                        "modified": 1698742945.4914186,
                        "name": "/dev/shm/incoming/2023_10_31_090211_00_01_V_017.kml",
                        "sha256": "3c2c333144d2eb66459cbdc36d94d573b65989ab3983e99ed8761b74c9176254",
                        "size": 950
                    },
                    {
                        "created": 1698742945.0814185,
                        "modified": 1698742945.0814185,
                        "name": "/dev/shm/incoming/2023_10_31_090211_00_01_V_017.jpg",
                        "sha256": "6589a3803f5c277459b59467e3a8a53a18cdb8b690f6d42412239202eae24cb0",
                        "size": 774678
                    }
                ],
                "mission": "OSP1",
                "sha256": "cc83bf1cae128ce801fe6701367b033d342fdb71a8fbace32943771c79e64210",
                "size": 769041
            },
            "errors": "",
            "filename": "2023-10-31T09-04-33Z",
            "size": 769041,
            "verified_at": 1698766921
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698742499,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "Ih4Td5HotiCHBgwgtRbYKPUelzx6yYaYdv8L2yYsgWPSBum/HxlMOP2DfAX5BTDZLQjuZucgt6aQukoGqb7WF5AW3vGD3Ic8diaDTNvhVG1acn6kKnjL5bkLlTYV+CPsfpqyGVCealVpXfEJJt7itroDGTF/3OG0VdN97V6tMVS38KakL4fzSTMGcTmhajE0vutwW476FmB6TlIEsMXo1J3tdFwypzITv23pD7Bmp7YgRtClduWwjW8wpYT9s5O21PPeWiIKCMSzQ0fal+tVaCv6O45zsu3JoC+OjSPvfTup40UA8NTcpL3L8Kbl3oQC4Odf9S9b10JmeFMZLVP7+LNohdfvSN5nI5DnCdka0tB/ZHmWgaSLYDvRzQ3WLnbqdvcJdV1VblyOaXk4n1pwa3dOM/KKjOCSax1VWWDg1J/sc6CrfluE1IJKMlQbVlJjrd3+m53jwdWToFsodhM+382OLSnXk8SZY8XrigITdItu5gQWTa9/2GnwVHW3xMoZRhE705IjIO2tftHBJo0zg4TMwPjM8u4XlCNuTJVNCfmZTMebAvzWZfoXcJb8HiCM1EdW8/T43z+qDXhrYU016GOHFuIjENpwoefEyYIKXwUPQwCLtcyONeWeSyQ/XKbOzcvsHoNUhyrWpn0wGcQdvcN3FyHCf9/OBeIFmlVIPTI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T08-54-37Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698742350.10126,
                        "modified": 1698742350.10126,
                        "name": "/dev/shm/incoming/2023-10-31T08:42:28.ais",
                        "sha256": "e1dd3db0a2fa1079c6533aa3aa0183429086cf713d92ee9e2f3ad02f44ba6083",
                        "size": 572280
                    },
                    {
                        "created": 1698742352.86126,
                        "modified": 1698742352.86126,
                        "name": "/dev/shm/incoming/2023_10_31_085213_00_00_K_060.txt",
                        "sha256": "bd3127e73644e6e36e197d9629c51b6ed654fcaf596e369178a3b037a9ead048",
                        "size": 2044
                    },
                    {
                        "created": 1698742350.54126,
                        "modified": 1698742350.54126,
                        "name": "/dev/shm/incoming/2023_10_31_085213_00_00_K_060.kml",
                        "sha256": "926c26c7e61200b9564d30a3f944286b13f2f40c9ec2854b2fd67309742e2911",
                        "size": 978
                    },
                    {
                        "created": 1698742353.52126,
                        "modified": 1698742353.52126,
                        "name": "/dev/shm/incoming/2023_10_31_085213_00_00_K_060.jpg",
                        "sha256": "34540c2c18fae40bd81ad28146bb49995ee80c7a3b47aa9df6f25f92a87f41e1",
                        "size": 956396
                    },
                    {
                        "created": 1698742291.83126,
                        "modified": 1698742291.83126,
                        "name": "/dev/shm/incoming/2023_10_31_085111_00_00_K_059.txt",
                        "sha256": "4c2ff88ab09542fb29b03a576b13011d1ff82c2b793e7af5cb51de7d771ab797",
                        "size": 2044
                    },
                    {
                        "created": 1698742292.29126,
                        "modified": 1698742292.29126,
                        "name": "/dev/shm/incoming/2023_10_31_085111_00_00_K_059.kml",
                        "sha256": "d97b45ab67ee2050ed242395a82909aaabe74abb774055cc8cfc441e4685b3a0",
                        "size": 975
                    },
                    {
                        "created": 1698742288.62126,
                        "modified": 1698742288.62126,
                        "name": "/dev/shm/incoming/2023_10_31_085111_00_00_K_059.jpg",
                        "sha256": "33bb7f5d1eca5eeb947f36df51621da54019ae5a32cd73b5bcea9e324ace2917",
                        "size": 809043
                    },
                    {
                        "created": 1698742283.72126,
                        "modified": 1698742283.72126,
                        "name": "/dev/shm/incoming/2023_10_31_085044_00_00_K_058.txt",
                        "sha256": "208d7d8e391bc020921417e791aa2f273c0e2aa13e847df92da5bb007ebf56c4",
                        "size": 2033
                    },
                    {
                        "created": 1698742274.95126,
                        "modified": 1698742274.95126,
                        "name": "/dev/shm/incoming/2023_10_31_085044_00_00_K_058.kml",
                        "sha256": "f474c6dc0648c6644cc70332299cc0c697bd784580e64fb18c46974c92fb15a6",
                        "size": 976
                    },
                    {
                        "created": 1698742265.17126,
                        "modified": 1698742265.17126,
                        "name": "/dev/shm/incoming/2023_10_31_085044_00_00_K_058.jpg",
                        "sha256": "855036d40896ec20050fa558b59dd920155c08f55280e6970a4959e42177a747",
                        "size": 713707
                    },
                    {
                        "created": 1698742252.90126,
                        "modified": 1698742252.90126,
                        "name": "/dev/shm/incoming/2023_10_31_085028_00_00_K_057.txt",
                        "sha256": "1a894dc9045167fa14ebdc97f56f3111af0c25827704d54877aa4ed859040040",
                        "size": 2037
                    },
                    {
                        "created": 1698742251.72126,
                        "modified": 1698742251.72126,
                        "name": "/dev/shm/incoming/2023_10_31_085028_00_00_K_057.kml",
                        "sha256": "b056fd08180923a13fc87849791988beb0d6554eefb14de42822b5c91da5b0c0",
                        "size": 975
                    },
                    {
                        "created": 1698742251.55126,
                        "modified": 1698742251.55126,
                        "name": "/dev/shm/incoming/2023_10_31_085028_00_00_K_057.jpg",
                        "sha256": "3503c9bffb30bcfaa860f463f9e6134c33f47e4c481c0238fcc4388d025ec1f6",
                        "size": 1266679
                    },
                    {
                        "created": 1698742222.10126,
                        "modified": 1698742222.10126,
                        "name": "/dev/shm/incoming/2023_10_31_085002_00_00_K_056.txt",
                        "sha256": "e25740ee3b4b02121112f187386d932c413af36ac982b742c86bc7200c4398f6",
                        "size": 2046
                    },
                    {
                        "created": 1698742224.84126,
                        "modified": 1698742224.84126,
                        "name": "/dev/shm/incoming/2023_10_31_085002_00_00_K_056.kml",
                        "sha256": "439dbe5455a36937dfa6f6ed5098f87b80b57960cd257eafcf453ba9a521d0a4",
                        "size": 974
                    },
                    {
                        "created": 1698742218.26126,
                        "modified": 1698742218.26126,
                        "name": "/dev/shm/incoming/2023_10_31_085002_00_00_K_056.jpg",
                        "sha256": "10157a9f2a892cb5164d79b29c9f95343868c0be7db1ad48acd359b344d72148",
                        "size": 976559
                    },
                    {
                        "created": 1698742208.46126,
                        "modified": 1698742208.46126,
                        "name": "/dev/shm/incoming/2023_10_31_084934_00_00_K_055.txt",
                        "sha256": "0e5172dbc4412cccc83452b552b0fcae0eca8292c48e37d17f10f1a1100bd52a",
                        "size": 2045
                    },
                    {
                        "created": 1698742194.55126,
                        "modified": 1698742194.55126,
                        "name": "/dev/shm/incoming/2023_10_31_084934_00_00_K_055.kml",
                        "sha256": "054f0b10f58bbf59f9fc7029f69542fdce213538e34284e1e9dffa31f862fcdc",
                        "size": 977
                    },
                    {
                        "created": 1698742194.07126,
                        "modified": 1698742194.07126,
                        "name": "/dev/shm/incoming/2023_10_31_084934_00_00_K_055.jpg",
                        "sha256": "e117771d5ea51fe1f0e51e8f07a234bad2d9defb1e39e4e6d3a2ce663c8d5761",
                        "size": 1012811
                    },
                    {
                        "created": 1698742193.49126,
                        "modified": 1698742193.49126,
                        "name": "/dev/shm/incoming/2023_10_31_084923_00_00_K_054.txt",
                        "sha256": "e58015b8724375eda1bc237336379c3b0ae7f166dac146d99875dab05cfb0e37",
                        "size": 2045
                    },
                    {
                        "created": 1698742190.07126,
                        "modified": 1698742190.07126,
                        "name": "/dev/shm/incoming/2023_10_31_084923_00_00_K_054.kml",
                        "sha256": "5dba78ea1093fa5202c6629695963e431c7dea34289e414a3de79a9190538ab9",
                        "size": 978
                    },
                    {
                        "created": 1698742180.70126,
                        "modified": 1698742180.70126,
                        "name": "/dev/shm/incoming/2023_10_31_084923_00_00_K_054.jpg",
                        "sha256": "bb02540112a20d72720c687b936892e597d6a4a23c5f00757500f6fb9e0b36a4",
                        "size": 1001408
                    },
                    {
                        "created": 1698742137.43126,
                        "modified": 1698742137.43126,
                        "name": "/dev/shm/incoming/2023_10_31_084827_00_00_K_053.txt",
                        "sha256": "1a063710104ed36f111f7a6267835a1aab1ec0f6cec87ff159579e58a2dd94a5",
                        "size": 2031
                    },
                    {
                        "created": 1698742136.16126,
                        "modified": 1698742136.16126,
                        "name": "/dev/shm/incoming/2023_10_31_084827_00_00_K_053.kml",
                        "sha256": "f90201ba3266e1dd0531e7521d395b0c50577ba0258864565cf22935482020a5",
                        "size": 977
                    },
                    {
                        "created": 1698742134.26126,
                        "modified": 1698742134.26126,
                        "name": "/dev/shm/incoming/2023_10_31_084827_00_00_K_053.jpg",
                        "sha256": "1cbe3e2831c5ef3fffd0e56ac0e31199ee1da72bfe416f4259ba4d122a4ab77d",
                        "size": 880408
                    },
                    {
                        "created": 1698742114.16126,
                        "modified": 1698742114.16126,
                        "name": "/dev/shm/incoming/2023_10_31_084813_00_00_K_052.txt",
                        "sha256": "ef79cbd43462fec04329b68f88339b806e0ceae9c7ccf1db533c10307205beb4",
                        "size": 2039
                    },
                    {
                        "created": 1698742132.98126,
                        "modified": 1698742132.98126,
                        "name": "/dev/shm/incoming/2023_10_31_084813_00_00_K_052.kml",
                        "sha256": "fac56883218a5b8a17b87afd0751241db33b815f30692547e8c34d7702109728",
                        "size": 975
                    },
                    {
                        "created": 1698742109.91126,
                        "modified": 1698742109.91126,
                        "name": "/dev/shm/incoming/2023_10_31_084813_00_00_K_052.jpg",
                        "sha256": "3337df1143b0457b6530ede303f53da19aaefdfe9ea0bc91ec57d85251089126",
                        "size": 1044000
                    },
                    {
                        "created": 1698742073.69126,
                        "modified": 1698742073.69126,
                        "name": "/dev/shm/incoming/2023_10_31_084729_00_00_K_051.txt",
                        "sha256": "19a60697eb68bb68f774376e6f9b71d3060a8e35fec3003a2a4e1366635965b3",
                        "size": 2040
                    },
                    {
                        "created": 1698742072.57126,
                        "modified": 1698742072.57126,
                        "name": "/dev/shm/incoming/2023_10_31_084729_00_00_K_051.kml",
                        "sha256": "6824c5e63be5b491b0a5e046c783f56cb6e14bea7614fd84cd734dd63e308433",
                        "size": 978
                    },
                    {
                        "created": 1698742067.01126,
                        "modified": 1698742067.01126,
                        "name": "/dev/shm/incoming/2023_10_31_084729_00_00_K_051.jpg",
                        "sha256": "400dc8e86770c8f4446b07fa8deed7e311d48f6f187d9e2d23fa56e0e0e25ea5",
                        "size": 1008498
                    },
                    {
                        "created": 1698742010.08126,
                        "modified": 1698742010.08126,
                        "name": "/dev/shm/incoming/2023_10_31_084612_00_00_K_050.txt",
                        "sha256": "1ff0e61a7008342c7a31387e7d66b15745ef795cf8f020661130099469106cea",
                        "size": 2037
                    },
                    {
                        "created": 1698742005.32126,
                        "modified": 1698742005.32126,
                        "name": "/dev/shm/incoming/2023_10_31_084612_00_00_K_050.kml",
                        "sha256": "89f00d1c1e9b44f20173469537bdede1a8c11aa16c2f4f2e5d3e8d09c9648987",
                        "size": 975
                    },
                    {
                        "created": 1698742000.81126,
                        "modified": 1698742000.81126,
                        "name": "/dev/shm/incoming/2023_10_31_084612_00_00_K_050.jpg",
                        "sha256": "1bda5aacc744f420e243443d21c895b7820cd2ea8c90022d1426bc14839c6c19",
                        "size": 986012
                    },
                    {
                        "created": 1698741994.64126,
                        "modified": 1698741994.64126,
                        "name": "/dev/shm/incoming/2023_10_31_084302_00_00_K_049.txt",
                        "sha256": "e1be2e7a1241672ea43f267a65568bc8cea1bf9ec549b5e0480310cde6140203",
                        "size": 2047
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "43ee06d38596d06dbfe3ca6fc4690514f77c7b802ce87742ff2ed1963fa5467e",
                "size": 10827858
            },
            "errors": "",
            "filename": "2023-10-31T08-54-37Z",
            "size": 10827858,
            "verified_at": 1698767209
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698743106,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "TOkoWwLabPCd8e1kgXJJEuTm+CeBuKBK/IHblzQpn6OCD69FctPwWT5hgXfqDATdxdaVHX0Tw0i0ln6K8E8WjOlKsQhxcUTMCVyV2TRbw0SDLWwQaDn8HvZbgyecj1l+3PSjVi33/JywGWq28KOPUWIMZLf7tHel+cUheYwLR8b8YAHjvk/X9/VH7VYGnaBqrXiGkN6XDcnpMR+TiaZCTsXWOembFl/evWNC/2SBiCAwRgowIN8gdJy9bKlIg4wT4aO+CqzvRysfva2Jqeh94n6ya4Sq8NqiQbXP3Sxl6a5rkd25SoSXkvhuqDaI1r2LhXcP3QvxbZK53EKn5Dw/LPghz9V4YqJ6zjv55Imkxonc1QQ25qK/JflQnuhUmn2faVI78Y0RFFoh27Wv7ODKDTQUx6Lq5is1iHtSPu0Wr2qPaxrZWS5FcGG/+1uRgKZhPV46HFDHOC9FUIsn1Q3I6TUO0DnLCcvdIQPv7KPcM8NYrDgkEXUpz57ynGPoWGE16HDyW2U4aar+EHWZFCodxyadUgG0qwtcxERusACF6Wfv2hkoLjt3WYHFXx5ezt+GONv4NbkIySfZ6XzGBYLMGxTD/mHr9/GqpBbKoEnTeyaTgYZTIlWjnfMYrtB0Y9IfuLaOQmtlKVDFkKH7SEsEHc8IB6jK7KAjSITx2YsTal4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-03-48Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698742998.54126,
                        "modified": 1698742998.54126,
                        "name": "/dev/shm/incoming/2023_10_31_090244_00_00_K_075.txt",
                        "sha256": "44536397dd9cc8b542e2539b9dd2c72b48446a66f38412583cd89a9a2b371409",
                        "size": 2045
                    },
                    {
                        "created": 1698743011.72126,
                        "modified": 1698743011.72126,
                        "name": "/dev/shm/incoming/2023_10_31_090244_00_00_K_075.kml",
                        "sha256": "a6631eed5bec80956b76f3abf7c409a8e353136887499e61c67a0d0a42ca2097",
                        "size": 975
                    },
                    {
                        "created": 1698742990.01126,
                        "modified": 1698742990.01126,
                        "name": "/dev/shm/incoming/2023_10_31_090244_00_00_K_075.jpg",
                        "sha256": "4eb258bbb0a5b5f8e8babf2aae929defe321090a6290880eeb5bb32b577bdcbb",
                        "size": 1054627
                    },
                    {
                        "created": 1698742986.54126,
                        "modified": 1698742986.54126,
                        "name": "/dev/shm/incoming/2023_10_31_090238_00_00_K_074.txt",
                        "sha256": "cbca79a3d6fe8fda328bbd3f034b3b025ae3b22ccbdd04bb4e8a421178564a31",
                        "size": 2045
                    },
                    {
                        "created": 1698742979.45126,
                        "modified": 1698742979.45126,
                        "name": "/dev/shm/incoming/2023_10_31_090238_00_00_K_074.kml",
                        "sha256": "b2776ea7424f894c02fc171e0c956b12253805719c543a81d0ce9a158225b325",
                        "size": 976
                    },
                    {
                        "created": 1698742986.74126,
                        "modified": 1698742986.74126,
                        "name": "/dev/shm/incoming/2023_10_31_090238_00_00_K_074.jpg",
                        "sha256": "7f04f5f5977f035da821a5eccca83f313614a68f08f9aee8370c0417423f4a33",
                        "size": 1083350
                    },
                    {
                        "created": 1698742952.08126,
                        "modified": 1698742952.08126,
                        "name": "/dev/shm/incoming/2023-10-31T08:52:30.ais",
                        "sha256": "1b3c442604af5508248b724890e9182f1f36c4f5e3168d4e5c891b834bd8c293",
                        "size": 550067
                    },
                    {
                        "created": 1698742869.29126,
                        "modified": 1698742869.29126,
                        "name": "/dev/shm/incoming/2023_10_31_090036_00_00_K_073.txt",
                        "sha256": "17a2525e981866f81642d16f304d0967902e514cd7f40e5d9abe14c2304fc0cc",
                        "size": 2046
                    },
                    {
                        "created": 1698742861.70126,
                        "modified": 1698742861.70126,
                        "name": "/dev/shm/incoming/2023_10_31_090036_00_00_K_073.kml",
                        "sha256": "c0d0263219f0c5c672d02d7c5b04fd6bb42d7b664aace95e8eee1b9eb1dc3724",
                        "size": 975
                    },
                    {
                        "created": 1698742853.83126,
                        "modified": 1698742853.83126,
                        "name": "/dev/shm/incoming/2023_10_31_090036_00_00_K_073.jpg",
                        "sha256": "7d6b19b548d8b2f352b65318d9830487b86580f07a7e5aadcad46179c9a58d03",
                        "size": 722404
                    },
                    {
                        "created": 1698742850.98126,
                        "modified": 1698742850.98126,
                        "name": "/dev/shm/incoming/2023_10_31_090033_00_00_K_072.txt",
                        "sha256": "d7322694a0f1df684b11f8d7c6b4cef5fb5dc8cb278ea3a628acc475f300d065",
                        "size": 2046
                    },
                    {
                        "created": 1698742850.27126,
                        "modified": 1698742850.27126,
                        "name": "/dev/shm/incoming/2023_10_31_090033_00_00_K_072.kml",
                        "sha256": "5393e24353230ed037a7fda9a8569708c39feb12f36517915352491527b56c00",
                        "size": 976
                    },
                    {
                        "created": 1698742849.95126,
                        "modified": 1698742849.95126,
                        "name": "/dev/shm/incoming/2023_10_31_090033_00_00_K_072.jpg",
                        "sha256": "114abb1d7bf049e03508e1cfe06670cc6505202667771acc3c3f7c8fb3a6ad6d",
                        "size": 714700
                    },
                    {
                        "created": 1698742823.85126,
                        "modified": 1698742823.85126,
                        "name": "/dev/shm/incoming/2023_10_31_085958_00_00_K_071.txt",
                        "sha256": "7bba874642f5c2e7c95da3c6b64715cef57c2117a17a5717868b86499380db38",
                        "size": 2046
                    },
                    {
                        "created": 1698742825.61126,
                        "modified": 1698742825.61126,
                        "name": "/dev/shm/incoming/2023_10_31_085958_00_00_K_071.kml",
                        "sha256": "e64a13d0fa2c1a0094c7d4b9fb0adbbf49b335ccfbc854d6e91a8248fa9ed492",
                        "size": 978
                    },
                    {
                        "created": 1698742820.22126,
                        "modified": 1698742820.22126,
                        "name": "/dev/shm/incoming/2023_10_31_085958_00_00_K_071.jpg",
                        "sha256": "3e9170f5ccbe6e602c1d118015f152f1432f94b9c40bd3038236efc0145c6a6f",
                        "size": 761530
                    },
                    {
                        "created": 1698742815.32126,
                        "modified": 1698742815.32126,
                        "name": "/dev/shm/incoming/2023_10_31_085950_00_00_K_070.txt",
                        "sha256": "90b953a2af088e06d222f962b640566abfc75333f83dad6806df5081727db2e5",
                        "size": 2046
                    },
                    {
                        "created": 1698742814.87126,
                        "modified": 1698742814.87126,
                        "name": "/dev/shm/incoming/2023_10_31_085950_00_00_K_070.kml",
                        "sha256": "f3728da85279ab1707be637fac93db8c4eac7a28501cfce9e81fa7b028d9863d",
                        "size": 973
                    },
                    {
                        "created": 1698742814.77126,
                        "modified": 1698742814.77126,
                        "name": "/dev/shm/incoming/2023_10_31_085950_00_00_K_070.jpg",
                        "sha256": "62475ae31c581cbdd87128ecff5abf33bd96ac941d056609ee66acc707cd0940",
                        "size": 795131
                    },
                    {
                        "created": 1698742791.66126,
                        "modified": 1698742791.66126,
                        "name": "/dev/shm/incoming/2023_10_31_085934_00_00_K_069.txt",
                        "sha256": "2126e2eb03df37e01d221f36e8f2271aebbe28af7299455ce263386c3eeae57b",
                        "size": 2046
                    },
                    {
                        "created": 1698742810.36126,
                        "modified": 1698742810.36126,
                        "name": "/dev/shm/incoming/2023_10_31_085934_00_00_K_069.kml",
                        "sha256": "91c2283edecc761f6def55ec85109002f8dc4e167230933df84e04b32321c945",
                        "size": 979
                    },
                    {
                        "created": 1698742792.92126,
                        "modified": 1698742792.92126,
                        "name": "/dev/shm/incoming/2023_10_31_085934_00_00_K_069.jpg",
                        "sha256": "a133796a53ff1a1d9ed8569b22b7ae0f5d478e8283bc1acea6ae90f0096b8b32",
                        "size": 761172
                    },
                    {
                        "created": 1698742779.84126,
                        "modified": 1698742779.84126,
                        "name": "/dev/shm/incoming/2023_10_31_085911_00_00_K_068.txt",
                        "sha256": "ab99271b749bcaba689d5c3c44c2466f6dcc4dd6af6c551083f9d05a3bf3d95f",
                        "size": 2045
                    },
                    {
                        "created": 1698742780.28126,
                        "modified": 1698742780.28126,
                        "name": "/dev/shm/incoming/2023_10_31_085911_00_00_K_068.kml",
                        "sha256": "a9c3888bb108eaa131073471c81a40a1f1df259f97cf40a6994d4061b5ab0250",
                        "size": 976
                    },
                    {
                        "created": 1698742780.20126,
                        "modified": 1698742780.20126,
                        "name": "/dev/shm/incoming/2023_10_31_085911_00_00_K_068.jpg",
                        "sha256": "792882af741065cfc54e119b489d350435b2ae2c7ede3a74b9dc95c2ddfe6905",
                        "size": 1329695
                    },
                    {
                        "created": 1698742777.07126,
                        "modified": 1698742777.07126,
                        "name": "/dev/shm/incoming/2023_10_31_085902_00_00_K_067.txt",
                        "sha256": "8f4d7aa8b81c6998a2000a5ac817c7b38b425be6ec6664753cf1916bf4b4cabd",
                        "size": 2045
                    },
                    {
                        "created": 1698742775.54126,
                        "modified": 1698742775.54126,
                        "name": "/dev/shm/incoming/2023_10_31_085902_00_00_K_067.kml",
                        "sha256": "5df17149dbc2c05cc3f92ce50d904a1cab4f0901a47199c0b919e0194cf0c2f4",
                        "size": 977
                    },
                    {
                        "created": 1698742765.20126,
                        "modified": 1698742765.20126,
                        "name": "/dev/shm/incoming/2023_10_31_085902_00_00_K_067.jpg",
                        "sha256": "544aed219bc74d44e9a33dac32b65faf8d62bb2c9005a5edeef0d0db9ea5e602",
                        "size": 1372105
                    },
                    {
                        "created": 1698742763.06126,
                        "modified": 1698742763.06126,
                        "name": "/dev/shm/incoming/2023_10_31_085853_00_00_K_066.txt",
                        "sha256": "d056325d8236aac60b293dd472b6bac253a69fee0f7a0a634c8f8062b7fd0be9",
                        "size": 2042
                    },
                    {
                        "created": 1698742752.80126,
                        "modified": 1698742752.80126,
                        "name": "/dev/shm/incoming/2023_10_31_085853_00_00_K_066.kml",
                        "sha256": "3ad46bfbf74d83651e9d98a64e1bdcea32ae3dbba82686d63031ea0498357aa9",
                        "size": 980
                    },
                    {
                        "created": 1698742752.37126,
                        "modified": 1698742752.37126,
                        "name": "/dev/shm/incoming/2023_10_31_085853_00_00_K_066.jpg",
                        "sha256": "54e142d12a6780073aa58ccd691d73df3a2cc1be8f752fac36bcf6ff4ee25866",
                        "size": 1176048
                    },
                    {
                        "created": 1698742697.73126,
                        "modified": 1698742697.73126,
                        "name": "/dev/shm/incoming/2023_10_31_085800_00_00_K_065.txt",
                        "sha256": "c05058d84d27af23b6cefcef9fba197e8f631960cc3b54ae6e6efbcd3b4adb46",
                        "size": 2043
                    },
                    {
                        "created": 1698742704.95126,
                        "modified": 1698742704.95126,
                        "name": "/dev/shm/incoming/2023_10_31_085800_00_00_K_065.kml",
                        "sha256": "9d91a4b944dd30da25ca065c2b7e44512c9767dd076544de1839e6366f0a2490",
                        "size": 977
                    },
                    {
                        "created": 1698742704.90126,
                        "modified": 1698742704.90126,
                        "name": "/dev/shm/incoming/2023_10_31_085800_00_00_K_065.jpg",
                        "sha256": "82189c039619b1cada2bce31ed9bb4b1e7eff36b33c1083cddfdeb44621e2f57",
                        "size": 682132
                    },
                    {
                        "created": 1698742672.95126,
                        "modified": 1698742672.95126,
                        "name": "/dev/shm/incoming/2023_10_31_085726_00_00_K_064.txt",
                        "sha256": "e8b2aeda6fe59af600c0c0d479d17b6083d7b4c368477a03f6966baa71e167a6",
                        "size": 2046
                    },
                    {
                        "created": 1698742681.06126,
                        "modified": 1698742681.06126,
                        "name": "/dev/shm/incoming/2023_10_31_085726_00_00_K_064.kml",
                        "sha256": "311ecd68fa65380047c9c8cef2b7c4fd3c5a42ab55bf54a1489ecda0e5f27ffd",
                        "size": 977
                    },
                    {
                        "created": 1698742673.34126,
                        "modified": 1698742673.34126,
                        "name": "/dev/shm/incoming/2023_10_31_085726_00_00_K_064.jpg",
                        "sha256": "ff9f93c8e5ffbb42fe29ad2ec035b52b6a0b922a306338bad9b3127f3cbb1a0c",
                        "size": 1068285
                    },
                    {
                        "created": 1698742657.79126,
                        "modified": 1698742657.79126,
                        "name": "/dev/shm/incoming/2023_10_31_085713_00_00_K_063.txt",
                        "sha256": "fc38ae6f3e42efc161b4dac18761869e129027efdd1ba710037258dbeb09bd46",
                        "size": 2041
                    },
                    {
                        "created": 1698742657.62126,
                        "modified": 1698742657.62126,
                        "name": "/dev/shm/incoming/2023_10_31_085713_00_00_K_063.kml",
                        "sha256": "12c2931f3cf298e629b97e72879ec8f47f64bb62e8f174176a884a850f7ae080",
                        "size": 976
                    },
                    {
                        "created": 1698742650.76126,
                        "modified": 1698742650.76126,
                        "name": "/dev/shm/incoming/2023_10_31_085713_00_00_K_063.jpg",
                        "sha256": "cad7167578cb95993a956c8cacb306b1dccf257deb907d2f8fafec9865f9321d",
                        "size": 1081519
                    },
                    {
                        "created": 1698742574.91126,
                        "modified": 1698742574.91126,
                        "name": "/dev/shm/incoming/2023_10_31_085542_00_00_K_062.txt",
                        "sha256": "9422dee58298b2583f483edff658d033c0cb0cf13f34fed14bd503c4a453aa7f",
                        "size": 2045
                    },
                    {
                        "created": 1698742568.19126,
                        "modified": 1698742568.19126,
                        "name": "/dev/shm/incoming/2023_10_31_085542_00_00_K_062.kml",
                        "sha256": "e364992b4aa7c938b7f03ca7fad03061bce1837b41de0a8a55f0989425be15db",
                        "size": 980
                    },
                    {
                        "created": 1698742566.76126,
                        "modified": 1698742566.76126,
                        "name": "/dev/shm/incoming/2023_10_31_085542_00_00_K_062.jpg",
                        "sha256": "74d098b0aa93ee2a82f8019e63a03f3bd78d5730d02465fc8db8203a83905d67",
                        "size": 1123100
                    },
                    {
                        "created": 1698742492.29126,
                        "modified": 1698742492.29126,
                        "name": "/dev/shm/incoming/2023_10_31_085425_00_00_K_061.txt",
                        "sha256": "620b95c17708b28e8ff818604c244ee679927165be696976f3c513989b8f780c",
                        "size": 2045
                    },
                    {
                        "created": 1698742486.74126,
                        "modified": 1698742486.74126,
                        "name": "/dev/shm/incoming/2023_10_31_085425_00_00_K_061.kml",
                        "sha256": "08b880b7b045f62a2935330044a73cc0b9232e8a34ba0e9a303f463981024b47",
                        "size": 977
                    },
                    {
                        "created": 1698742481.09126,
                        "modified": 1698742481.09126,
                        "name": "/dev/shm/incoming/2023_10_31_085425_00_00_K_061.jpg",
                        "sha256": "ce7f2b4cd71a1d14621f69dea5a21c19006bf2d2668822329848b6dcfb60bb62",
                        "size": 832889
                    },
                    {
                        "created": 1698743020.48126,
                        "modified": 1698743020.48126,
                        "name": "/dev/shm/incoming/2023-10-31T08:33:40.ts",
                        "sha256": "eddbf68efacd1cd2454be5d4f89c407eb2581d06b97e6d01a03382ed6672e398",
                        "size": 440375900
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "c0c9a2b80f69d78e22a53d793eb996edbe798e43454837400ae33349efcc721a",
                "size": 412258188
            },
            "errors": "",
            "filename": "2023-10-31T09-03-48Z",
            "size": 412258188,
            "verified_at": 1698767733
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698742988,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "kmHY6wQw4Qoq/k5v5Rh1iIP5pmPbulXn0UcxDjMSEmgxAkJ/jJ6mPvcIhMIXpqKGQdQUZqC40z4tL7i9ie6Kiq6CEvjiSzHuUAYCRAYKB3OmvsuITlK5oMZLrPzJeRtyahTFkAYkOl1ZsZH324TWMGQ649Go7RinbzrWTo3Fjuw7fsuDOkYb/FJz8OK+KFFtx9ct6DmiusNnvK/SFkl4pXiLIYtq6sHsKuie2Pmo6mErlUqdjCxXEvXpUIStLDeYTIw+z5Y0Srveq/dCZoU9RA7GoB1GdVQn53ukji+F/bvsBXvvWytG8HiBhrpjwMgWKUJ1+FrTRwT5SybG7P7+iEvvlWndwhkcvUYzf4sbMl8XTOCxnJs+fbYnVD5y7bRQWkfHHzC5ig79/ob4Q9m1wHKFM44ZWO9L3ukYSdiPeNTsyyUCd6678rp9iRbIizON9MuDiftzbH1LNRxYyMcGwe+qmgtKtyb+HHIPBpgDM0dpa7XNpppuGtaH0gdMKJdIMnfs2GBW9qgzJeyIc57W8hYgdGCPzUPMoS9lW3l5Jzd8C0dyqHz+h0lu3Cgq+rzmspEte2r04G6Fzo4g/IIjYanCC+ot6COPVzF94jUsj5IxVcaWZ7qvH4fb8MddSv30Dx9XXBcPZEK7t62eN6tRaEYyrl/ckn76IdptsGBtrxg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-01-19Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698742757.9243622,
                        "modified": 1698742757.9243622,
                        "name": "/dev/shm/incoming/2023_10_31_085856_00_00_V_003.txt",
                        "sha256": "0b41b6244589d2ad61b6107e5cc629ba7ce112914c175a962ea2f0782f71aaae",
                        "size": 777
                    },
                    {
                        "created": 1698742764.9443622,
                        "modified": 1698742764.9443622,
                        "name": "/dev/shm/incoming/2023_10_31_085856_00_00_V_003.kml",
                        "sha256": "b35864950fdb353ba2743fcee125bed8c65e84b495a4959a9b6bfe05f037bfb6",
                        "size": 897
                    },
                    {
                        "created": 1698742777.6543622,
                        "modified": 1698742777.6543622,
                        "name": "/dev/shm/incoming/2023_10_31_085856_00_00_V_003.jpg",
                        "sha256": "3ee4981dda47e726196d7e1dc4a5b226532114b4338c9f80002e87908c693f94",
                        "size": 1031713
                    },
                    {
                        "created": 1698742776.284362,
                        "modified": 1698742776.284362,
                        "name": "/dev/shm/incoming/2023_10_31_085854_00_00_V_002.txt",
                        "sha256": "54bacaf72a5f1e1b1755b4aee5c6f2603c6a847531fa29df3f6a17d555c6dd55",
                        "size": 782
                    },
                    {
                        "created": 1698742753.764362,
                        "modified": 1698742753.764362,
                        "name": "/dev/shm/incoming/2023_10_31_085854_00_00_V_002.kml",
                        "sha256": "6a20e7bd71ae48606ddc859fb135d6d5e69e36badaabc2928aa351f2dad9e652",
                        "size": 897
                    },
                    {
                        "created": 1698742753.114362,
                        "modified": 1698742753.114362,
                        "name": "/dev/shm/incoming/2023_10_31_085854_00_00_V_002.jpg",
                        "sha256": "5d5538dcefe042ca00ed3308b64323617e04c6fbe1fc80dbb67a12531d88971c",
                        "size": 1062633
                    },
                    {
                        "created": 1698742752.054362,
                        "modified": 1698742752.054362,
                        "name": "/dev/shm/incoming/2023_10_31_085853_00_00_V_001.txt",
                        "sha256": "fdcf7b380b1fdcd7249e0dff9941a91819c2ee47a414f981f40718b96e4a2b67",
                        "size": 782
                    },
                    {
                        "created": 1698742752.7243621,
                        "modified": 1698742752.7243621,
                        "name": "/dev/shm/incoming/2023_10_31_085853_00_00_V_001.kml",
                        "sha256": "836165566841848d7578aef69fea7854af202454976a3e100d0bf93b844cedc7",
                        "size": 895
                    },
                    {
                        "created": 1698742751.524362,
                        "modified": 1698742751.524362,
                        "name": "/dev/shm/incoming/2023_10_31_085853_00_00_V_001.jpg",
                        "sha256": "b1283b6b516a5003db646a72fe9340976ce5a5691db759d9f647294b93193b96",
                        "size": 1062887
                    }
                ],
                "mission": "EFCA1",
                "sha256": "74cec6d79eeafc772606c78d02874a5472d6d7751e3a777e7ca62e9f374bc9bd",
                "size": 3152188
            },
            "errors": "",
            "filename": "2023-10-31T09-01-19Z",
            "size": 3152188,
            "verified_at": 1698781983
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698743102,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "VpBpxpulg4h0z/0bHtrpXO9eu8YS/MwHEjxZd1b7taKKPWqopQrBD4WJyeGFkJANuMTPDWh8uqiFsIJYPEhMBJD4JMX0PQUpGMT7wzawVVEkmeecF3QQHvSdGJqXAYrSpr8fenfQ/P+VLLGjEPss8OVDN+ygm/d5TvQs/uacT8VzgGos+JGaCa3gvRkN5b28UHgRpG6wbPAOodnuv2N+oqYQsuDb76PV5xu2rEIr1+1KeYR9iMA/DU9EOaA89nrZ+d/VGARPHIJoNrnBoJd0pBuACbJN9B9zAq/hyMTlzMCger5b8rswnGk2YOX5ZvZGaVB/hKtuiOXHVH+1WtBEz+aM+Ke5KsG9f2umJtIkWgVFeqDhJEAN7KgYmqBUu1kYbgYA4MaBOTgeLn9EbhshMpikvCedmtx6gk5cGeIJ/jc+QvqiezhHhT5qePzljq8C4VbDj+iZMUuDqq9b23cPY1hPQZGk1ib4R6aUXJlTRfUEdW2HAtLxB6/dPYukFR7tAWiszaQSt+xBSq6B/O26bPA/y09FYlfgI7Zy1XaOsTcj6p514cHhbLQ5VXqBr83v5/P85oPgZKFECcetE38hkeApyPk3RKl+6SLK+4+afv1pnPXlL47SjCWeeUA1B/jY/VA/ZZDBIG3NIV1K8pIWhAtmvXWC2NX5OWEe0hmnZdE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-04-53Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698742967.564362,
                        "modified": 1698742967.564362,
                        "name": "/dev/shm/incoming/2023-10-31T08:52:41.ais",
                        "sha256": "d03ea95b01a8140d07e7fa47d01703874c0a40a6c5c22b714f4d4e48055e478e",
                        "size": 6414
                    }
                ],
                "mission": "EFCA1",
                "sha256": "0ac5f07d9795c99f4416d08275985048924d7161f93131062627bb33327b49d1",
                "size": 1911
            },
            "errors": "",
            "filename": "2023-10-31T09-04-53Z",
            "size": 1911,
            "verified_at": 1698781998
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698743521,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "qBkvRxtRhxURm7YtGAUnRjoXTdtfb7d3sKKQmML0QIKJXRhO4vQkqkg34z+KncqN/nt3Mm9GzAW18gg9rI+IfHObnDQPocJtpdIAWqf8thB4h1/MvSOXA3PROB6nsMZGz20Xc2DeIVL6dtNsw9qhj8xAmayt0YeZ4DWShpT8dF/iYE91YBqqGPAMU3lHYxXyCMfHj/GXJ4clb4Fh9Ek/LpiIUEYFLNhVLFslSXayggXlxHAAoBWGRcEJi4csWHaR4ukvJZJSu3BkluL5AeM9PSBu/67DqPYPcFkppL1kF9PBmfORtnIDqDZy9399Q8mvsypyWfLB9GkHQjYimYjudWXzjF5mQq4wNs1CPptb1hIADermv+O/DzlTixYW35gEuQmJdieCWz9oV0w+v7TApUkCPY9BTyNszYGV+NHnWaf/cBf/NitAvbuXnC+UXw5XprVuLl+oMiUj6eVfc3WueYZm4nQhfox0JcJzAYzfDYQL7osjDJAQ3Bn1D+XCe+CRYULGQhQKqJsAHcnybiTH+7Hv0tgkirQvbRhE/UGyRKUkDZGNg5ywRttJGLR3bSETtG5W6PJmdwvVorZ1oBOsJsZ3c+wEgLdemVQ4wnOIP9uZqx0CiYwhIxwDtrO5+yf0Dqu3h2aRjad9ync7u5Rm+ykL682xpouYdas0hu6op0A=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-11-04Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698743344.3114185,
                        "modified": 1698743344.3114185,
                        "name": "/dev/shm/incoming/2023-10-31T08:59:03.ais",
                        "sha256": "b0a79eea2c0b76a0d1d6f801eb5f95f5031c6bf79d18b7c42659c7dea9d0779f",
                        "size": 208402
                    },
                    {
                        "created": 1698743455.2414186,
                        "modified": 1698743455.2414186,
                        "name": "/dev/shm/incoming/2023-10-31T08:40:27.ts",
                        "sha256": "13492bc89b7fb82952943cbe6a888d6ab7068cf639ffeda02e97f524e90c27c5",
                        "size": 408232600
                    }
                ],
                "mission": "OSP1",
                "sha256": "8158357832eb204d606ebb179a6dc2a7ab2678fbddb10046cf72f99b937035c2",
                "size": 393781843
            },
            "errors": "",
            "filename": "2023-10-31T09-11-04Z",
            "size": 393781843,
            "verified_at": 1698766550
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698751899,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "aE1h4LZLhE6DLdononCSfPf4eePxMN8/OooZs3nve7UIFbhQFgpVCcf8/H5ajDNRtU0Wn7FRaQYFzEaCNlQW+5OQPp5ZqgenkrXmgiD+2z7y8nyuWMDYa0wdIgyBWr5WcyxLx5bbTyXRHxsXETZ00HQosi/z8DAwvaaeFCXb+0f25olf3TpsNtVHVk2luyWZIuMH8aq0VxDj9jWGx2FDsfNureAStjZXFqeJZgn6ia0bu3jBge3Ue4q6j5L5G0zd+qbyASLoLK8qjhvi9Viz2cWvOHpovtYSqdzN+4UpK9SIKXxli3q1VHcGhRjaGqSTikiwjkd3zLX6dKJXL9zaMEs3jD9Cb8iFliqIt+RNHaSQwUCpdFiei2h3QZXMJ7iFNBWlKv23YDEqgMXFNdKrQCcOLcUeyjgKLoCZjBDo+0ys1vimTz8tW6h+V5unXK8jDyowHG/q1CQcjUu65b0sD8VplawKBmIMlZLqT893i7On6P1C+essgZzOAS86E4o/7RUIVvdM5D6rcO4Re4KfRXTgLfphA4mjjYX8j3WbEpbnIs/bnqVIVyX0ZtC+xrf/DAxDWHyNLn2Ux9vSPtB/gUXcitObdutatb2kTb+XaoEI9V+IW5JYoHMUGf2NYKO528Co8SYRgx9NSFN3RQqmq8Vjcv2I1GrPLro59FMHWPw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-31-25Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698751758.0314186,
                        "modified": 1698751758.0314186,
                        "name": "/dev/shm/incoming/2023-10-31T11:19:17.ais",
                        "sha256": "f2bdbee224a2e9a07f20238d1f262e05bb7ea20c15c786717926390e8df86f4c",
                        "size": 275878
                    }
                ],
                "mission": "OSP1",
                "sha256": "ff587def3e6fb9ef756c95c0361d78653e8f47d0fed8cdb4a9151ba61baeeaab",
                "size": 66244
            },
            "errors": "",
            "filename": "2023-10-31T11-31-25Z",
            "size": 66244,
            "verified_at": 1698767543
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698743552,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "FDlHcj4fxjc018KODe747VOH/3KcY+Y/8kc9Fk/fDnbidTBS7292aMQ0fzgYH5n2+uNFnPExW1zJI1pcIE+Rdo8hCE/bpPsDeYwqaS2DrlQqa9T+RahEWQQxZ/ijRG/6wKnYGIjvnf8HxDHtmcXsCrqpZnwynzcxsmJ3ntd7NXqb9+4RVU28ulmFZh2TR9tQGh9YX8/bJGllKTTJQXorCIYLjFGOgO38nuw82MfVXndJRyL19zOWiZZjAdjIs1ocrKEoG38pqDoapq83Wu2b7lHEa/dnsbvRJitRKARRkucEJhGMMIrSZb8xdBgXhTCb1pCDx57El2EWhngE7LhFm7e8SYbpyzHTy5cGMSaxTOU0imJTaMeZpi3C/03bEkh0b6IIgLyiTo8SINN0ECxJBuiNAyz7PcGX4ATiidqF+p9d0C1aFzq1S14hv9atVoB4TSbJxOlKmbj2UWzbd3MuYpmltSfjteOrxyJBJ3jM3ZyLANa7uBmydXbq766cvwuzqnh9rm77YFyBRU0idNR0/gxn6HtFBq5XDxNi42PtElKTF7lel/eJs3KhRYbRLOMycAwiPwHvOhWkSk3jPHF/JL+0R7VetLlIoKE46kSblct7I3NeQ5Yuz1arXPyv+jGFlNNYeh7nfHKk7X5+2ht3chAIJfNnzM7jKXVM4TYw0Qo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-12-14Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698743524.2243621,
                        "modified": 1698743524.2243621,
                        "name": "/dev/shm/incoming/2023-10-31T08:59:24.ts",
                        "sha256": "a43c0b8faf7c63bb95763ac415c128a282d1c1eaa85e0482fced9167a3b309e0",
                        "size": 172021692
                    }
                ],
                "mission": "EFCA1",
                "sha256": "eb1acdda7a072b7106b176652d27eaf56e91c7c5d407d78a78f2cec084009ef2",
                "size": 165391611
            },
            "errors": "",
            "filename": "2023-10-31T09-12-14Z",
            "size": 165391611,
            "verified_at": 1698782051
        },
        {
            "aircraft": "2-WKTJ",
            "client": "EUNAV",
            "contract": "NOSEAGULL",
            "created_at": 1698743698,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "Yfh5wdwO7PQ2xdBzwxM1dRT5mKN+uzRYzjYtkDiMZ0sitBZgFQQt991RB/W1DiagDeyOtS9PxLFkZMafHw5v/85huQRbvN8sg3RjZk/69FLAqox0FY+LLKE0Rk70FRCmYmBaDa3x/NNUl0cLXH3HBh8N04thIioJaIxXcZvWHHynox6FAHknGeBMe5JpuduCKQ0ObaMbZXWim3NmRmHLB3V+0f/2gvkH/VB4YpBGYWHG0hiNcoA0XXz+/98qU2Ue8CdqTnKFtiq1MtQnW2e/UhqtnZh7mxDrmKxAF9iUQJHDda4jZZ3KHJM72IKxmnDyBF3U+e0uVnSoFBvsFRrL0iymt7zExPfS1lELz/EaO0yayMUwmZiusUk0spvfD7HcHLhQFTziaN5/jGAmUx8BA0c16K9iVXwr1ObCVlk20iZCDC6cMh4s4PiPWVczz9NjardAEsP2SUkRin4rEQys4WO+u7vK5dYHDFaKEEF6+2bZFHwZeuuZGcQ5U0cy7wPEqHS+bWCFSkDc99NO2/+kBO2D/x+fJHblL7ckLWDG0Sf5gB7RPYASM9JyCBZOA68qUiZZ8u+yzPd9f9pMH9MovZ8bjeigzWCdQ6ZUSuJvLHraLN9bGppkwXmAGsY170JSFlr0+vZvphh8+OGCATZHlwZSOBGAJAy3juysBn+CP90=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T08-35-58Z_EUNAV_NOSEAGULL",
                "inventory": [
                    {
                        "created": 1698741230.189791,
                        "modified": 1698741230.189791,
                        "name": "/dev/shm/incoming/2023-10-31T08:23:09.ais",
                        "sha256": "b93339ceb783c3b40e03858c55d9ff21183ac19296728f3ec255e503749d7cd4",
                        "size": 361
                    }
                ],
                "mission": "NOSEAGULL",
                "sha256": "efb9e7b39df2b822cf2c891843f0a8a94428e36ecc284932f5a64ac58687c1d6",
                "size": 404
            },
            "errors": "",
            "filename": "2023-10-31T08-35-58Z",
            "size": 404,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698743568,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "j/s9hlAW0ZoEs0aVoJqYjCuFQn2cihhclV0F+PFQ3BSOAcZSsp6uWE4GEdojX8W6+95f+UYhjg/Qle+kSGN0JGweIfEe9nDSEj2Kxnj0AqGMMrt0iyfXOKTE7+F8hJV5tbhm2LvbgIoKKv6x76gg3bMkYH6T+lTTmSSXLEUnvVc79sZ8fmcY3T4crUkLj9AcdRBvCkjhg51IoAq2ut4PwYS4apI1mqddg+YYyNmw37RiJQsrk6y9sRSa+ZQrcRZ/O/ntm8+UmOZQTbaphFHdWjW9tZn8m8/1y0eW6QeYQ1kbvlFRZ5pMS+wjr6k1oe5gIQ5sU+z1HD/MueqoCmv63pDEH8/C8tRhosuSIPbqJbIaznkjWhUT3ZiPT/RoYRWRb9XQaJ+epFsrMnY5h4eXr7MqI/PX/4+0wakPMz+jER1MfywvW8ffTXR9giu9XO4OVsiBj9r+3Jso5W4CVEYde9/9SVFiRmenqP6iLdXunY073NZMvi8ghTOb2517AXfuhezzIaKM/QAsQjnwn1dJcVM5DHDWH5docMWuCR59omPWjWmdwi21nZIb90/tB63FyQvomFK4j4RMgMO7oZIVtzhNhVUBCmJ3B53O2nOQVJA+4kf0aZ6WSxFJNLPDkimvqaVugxxAOZDtwstfSydURDE/V6/Vmd+zDPWYPJ6YFdI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-12-30Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698743429.09126,
                        "modified": 1698743429.09126,
                        "name": "/dev/shm/incoming/2023_10_31_091010_00_00_K_076.txt",
                        "sha256": "d6955c6e73b879a6c19df8dc6361fd9628d7d8da1c1a70f11e212829478bc473",
                        "size": 789
                    },
                    {
                        "created": 1698743429.57126,
                        "modified": 1698743429.57126,
                        "name": "/dev/shm/incoming/2023_10_31_091010_00_00_K_076.kml",
                        "sha256": "f467558639fbd8e516b4544886935892c7ae880192a1df9a581e997dd36b80f4",
                        "size": 931
                    },
                    {
                        "created": 1698743423.74126,
                        "modified": 1698743423.74126,
                        "name": "/dev/shm/incoming/2023_10_31_091010_00_00_K_076.jpg",
                        "sha256": "57c0f73fd28d60d9079aaab64e57d7470b7cb0245e4b02a599d256101730853f",
                        "size": 497070
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "59fd13f12f0e5e752e4d15ac00d04dafab8044c423368ffb6c9591d36cd447c3",
                "size": 484152
            },
            "errors": "",
            "filename": "2023-10-31T09-12-30Z",
            "size": 484152,
            "verified_at": 1698767297
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698743699,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "jilFLvgTYiCEEaP3iz+TcfLTQfGplaKUbsMJe7xcz19X1tSEwKpCyso1CuILCWdunOKJnFqQy+QI3khqoD2zZQMcFY6oMBnWFsZ5+PZQtkL/9cFB+g894GTuZN3Sn5H4RYJBauvlxQRlOL3S5K3HHaRRNqLuqlwoj3QzTINwctGq/bDVDTq+10lbkJ2cqgTCOSFK9mIhQa+uU2ojXlaoKMSWup+78CG3+8v6aL8r2MSrAn4ek0Yu9Jj6SQyKM/oAoNgSjWqnrIeKOsNEKonW1HxsbSoaB5p27xFJKwkkATmpgmnAcbsxkQs8231Cfr8pkxCyFC4JKHXFWnC9hN6U7PMqYEF53QzAciG/lYTXwJnoMjM/oAinvh1uzPRA0PkveN9my0jHmI93GwESaiXmgvl4r7e9Xsuj2ySco3/t60Ec94V1VrNEgxfYS3YIo33HjlKUI3Fs5mrzN79YgslPUMR1TwQP/H7H4ZWpDoABRTAvVKebSEjJHZU4S+LXMYiBdQqIlMlrhKy1+YatRdyrcPR0DwFW8BcsyJGmF14hlw4/OeGuBEKjlRqF8rigcII0XpCe6blr71h/1scYrd/gkFE/ULLzTSLJjnTvmkXmCLAqm4ZHR6RfuzFmjpSKRCMJMNGDKlY8AK9m3DGCU9Sn3iKXcqGlglxwc4xESFY0Bco=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-14-40Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698743553.01126,
                        "modified": 1698743553.01126,
                        "name": "/dev/shm/incoming/2023-10-31T09:02:32.ais",
                        "sha256": "25edcb315f1f25596301059cbcda6a2e16ac4fc7a3ce4190444ee24e0f648e50",
                        "size": 512749
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "4a541ed844978f1a110e216e0d6d4ef705c9540fbcf8d8b503d6cbeb8ec64492",
                "size": 150894
            },
            "errors": "",
            "filename": "2023-10-31T09-14-40Z",
            "size": 150894,
            "verified_at": 1698767385
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698743699,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "dihEsQ2Vdu7/MgMwdGlvTa2tTcdltpIzOaEAfkQrNlaywFoIxvUAQU4IR4rQ+2/0jQonghyvwcgccj7g5LVyZZnCD0UpMx87+BhiHmPA6bNaD161/oq1jiT/Ck5t4Ho2Pfc4lHWBdUNxfBgkeOGJXRCxTW7R7FQLIk4OGpHABUrLix867xV8gENaR7tjzcXsHUUyB59+w/USnctzFZqeEmIixLJpyy9glx/B83ywE8tW4dgVJmAnI67Xgw4Q0Dk+nqwPpdlnIRBKlNGkQckPxdcSUd3+NqK7AmgPeTmTbXrfLOjJpY0AuPseSTeJfRkmeEHXB26j1X9kBEVFoTiy646CluTyi5uFZ2xnOhZczhCfHHiQ0MYbVpQZ7Hx3YxItLIpM8rnhyJ18w9BIDTSzof7daXfPyHyl/s95C1rhqqe5Ln6b4G6BJtQTUEV0OhYS6YFJvQ9R5/V68ICs1uZ0yqpPTDz77aYjug+rmonmur+HaiwiFMQ7LOLi8ObSW8wCgXTs/8EepAkjjztvepOq5vDdJ8zz5LkkG7a5vuVbXrqOiFbicUWAn4dg/eYUj1Sg6D4ZzOu1w2UCPFdGDLC537CaZ7oL/kvcXWngX57PTVLZj3G8NbPScmXGNf8h90dSAbV77yqsgr5r2R4q9+qwyqTHbwftHraDOqC0uAIgGU0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-14-56Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698743569.7143621,
                        "modified": 1698743569.7143621,
                        "name": "/dev/shm/incoming/2023-10-31T09:02:47.ais",
                        "sha256": "8cda3f329832dc2b128c07a140298c3c3d95c86320acf0ae0aaa1caa20480e00",
                        "size": 11126
                    }
                ],
                "mission": "EFCA1",
                "sha256": "a98b928ad2ef91783188568d337b20c9e7893c9cf98b840eedd1b541be8a0252",
                "size": 3469
            },
            "errors": "",
            "filename": "2023-10-31T09-14-56Z",
            "size": 3469,
            "verified_at": 1698781976
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698662310,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "tbnuxUqIjrdM7aUsPWKcmsagHm53tn2y4fHshmASq05Ky7lI3C0iA009dQm7LOh2weMPDkK7nKA5q1itAB9OCQlTbdWzlciZRvCfAhCx261at+NoJPLla7OIJ01RUX6KCMHst9z/qjgDsftD8QZFQVVLMUwFweKTKSaQal/HJ9L0G3ZFtK1vRTVA4tcwxg5hv8ETjX4q58vPZ+Ll2FTHQG3T3DgcpEfan2STUBS4vev2cFy0P0tkTCwvyRDo7t6csXah/8s3nwlu5jQkfGRjmh2xcqKwtLeWoOb5dkarcqsrfO7D4Jo8wUBNHhIEQHrqH6e+EcIDqCYrpajf8qfJ/UGwN2RhyfNRoZcQIas79PU4s2t1sy1eQG69lDylHgRLNdkW7Mt15pn8YxUwlrdJcq8JdEjIKkGu9F7hZz6KDSRhwm05oC1Lv12Q5D1lJZ95PIP2GXSqrw23Bqu57N66+ddv5tUv6y4Zvv4398PBhsu07BHJLRaTRuHIizlk2PoSPVpW0hubOKkZPYUphDJaf5U3j9Bx/Vx5rI57BJr7mIAvUf5+UZc9aKEcKgXu/RaoNDuCOhPUCHhsirrTppDjTjQMWpaAUfR09zHjK/+f1Vz/TSo6OVZjGzL23VXQmR6wIPEVjvo2zc1HNs8yBeIOX04Oz+SsyFhBWY4saWJriLc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-36-50Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698662144.3179407,
                        "modified": 1698662144.3179407,
                        "name": "/dev/shm/incoming/2023-10-30T10:25:41.ais",
                        "sha256": "69029ded4b316e7f599ff4b6d360316e6b987a6408135ac3d68d39af8172d2cb",
                        "size": 183911
                    },
                    {
                        "created": 1698662200.6779406,
                        "modified": 1698662200.6779406,
                        "name": "/dev/shm/incoming/2023-10-30T10:06:40.ts",
                        "sha256": "9015d0a973afb4a80c525869aec962d5d3e3642f79ff3369c7f12247281fbc35",
                        "size": 441169824
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "f6c0d1d695de98af17621e37bd2d0d714290704a5f4516125e0f64e885e79bb7",
                "size": 397318375
            },
            "errors": "",
            "filename": "2023-10-30T10-36-50Z",
            "size": 397318375,
            "verified_at": 1698684041
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698663067,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "uO6QXxWKNvZGjC9M8AZ+UO6GpNev7vCY8vMN1PekinRkgeEqHySgY9ir5GmSq5uSHjnfyCUPXm+GQBQv+41z31L77vhGn/PNNXq4DKlVntAmb6MEdSf6wUZZpody6qLtGgxb+ZJC3QgG/5jVu79pKDzohiio6/CTFxv1Z50BHA5vO1Rn+eeAAeAdNlRUinIa/kpj0+pTdV5IjTEiNOr2E7JUIjOrA5lKhco8iDLK54xlMkO1xBHeEV16gTtIqwbN7PRqIZ3CuTDU4Lg8yfQ6k2TTTzYlFK5ieRvRmbpUF+MHWREjA0dC7NnA0g6xnYxGg22mo55n6i8AqlOYQoB5w6/Mp4U69iEP7cTGeHqo1s3SLuedyTN4Cwb8N4Xbf+W67X7cvEKzEurY8LZbGU9DXnLmNRGUwlTynton6N29RilaCX6IWlAUDDGlMuX0GrW3wQcnKb+ZaIqE7NJZoU5tT+85ZubRWHv+5DhzG46eGWQTSdaaXzg5QvrlIyLq92f2v2Pv4n7FWZQBGhwpzNg4t/KMcqEhQy1icZAbOEPpnz+y2qeIADWjRiOJJstHfVbOUzJO2z2jmVAg4QqnY+DHjDK2EJ1BvljWw+PV/GTVQlJyd7bgEsUlN+xcA39rQfSNe/sOoT7QqlGDS/pA12D7KkAkYGD8xp47FeUOrVi7Uis=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T10-50-45Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698662921.2278287,
                        "modified": 1698662921.2278287,
                        "name": "/dev/shm/incoming/2023_10_30_104824_00_00_V_036.txt",
                        "sha256": "76a61bbd136c90be0f8fdec249aa157e542176bb0acbfb6d8374bec2fd9cc761",
                        "size": 2028
                    },
                    {
                        "created": 1698662920.8678288,
                        "modified": 1698662920.8678288,
                        "name": "/dev/shm/incoming/2023_10_30_104824_00_00_V_036.kml",
                        "sha256": "a530b87c93c0251c8085d652cdb800f248df7e86fa17367623811339da3cc71c",
                        "size": 969
                    },
                    {
                        "created": 1698662920.7178288,
                        "modified": 1698662920.7178288,
                        "name": "/dev/shm/incoming/2023_10_30_104824_00_00_V_036.jpg",
                        "sha256": "aac7d280bd919719ca08313d54d3bb6fc1535dd8c07a6e643ca72dd02fddab69",
                        "size": 963375
                    },
                    {
                        "created": 1698662903.2178288,
                        "modified": 1698662903.2178288,
                        "name": "/dev/shm/incoming/2023-10-30T10:38:21.ais",
                        "sha256": "3c4513f400d89d9557aaef1ab29b9697cdd5bbaa9b2018f615f2716012668912",
                        "size": 638560
                    },
                    {
                        "created": 1698662853.9878287,
                        "modified": 1698662853.9878287,
                        "name": "/dev/shm/incoming/2023_10_30_104715_00_00_V_035.txt",
                        "sha256": "5a81cc353ead5873268114e5e8a22cd09eac8d82614d48b348175f8b1b19aef7",
                        "size": 2026
                    },
                    {
                        "created": 1698662853.797829,
                        "modified": 1698662853.797829,
                        "name": "/dev/shm/incoming/2023_10_30_104715_00_00_V_035.kml",
                        "sha256": "183a7de13a8c298d57eac96fd67b4764c7f571f298b24349892014a7cb28f766",
                        "size": 976
                    },
                    {
                        "created": 1698662853.5978289,
                        "modified": 1698662853.5978289,
                        "name": "/dev/shm/incoming/2023_10_30_104715_00_00_V_035.jpg",
                        "sha256": "c3218df5087587b7675c12704c538d71a4571cb471f495dc26f0822dbb3707a4",
                        "size": 1028819
                    },
                    {
                        "created": 1698662852.5378287,
                        "modified": 1698662852.5378287,
                        "name": "/dev/shm/incoming/2023_10_30_104550_00_00_V_034.txt",
                        "sha256": "e8640003275b47fc5e80230c935a2816735e8f0088dcae781b4dc44bd2970c1c",
                        "size": 1532
                    },
                    {
                        "created": 1698662765.4278288,
                        "modified": 1698662765.4278288,
                        "name": "/dev/shm/incoming/2023_10_30_104550_00_00_V_034.kml",
                        "sha256": "c11edcdda799f2f36588bce750923ca5d2bc0d723e32318f197145bad8e3a5aa",
                        "size": 935
                    },
                    {
                        "created": 1698662765.317829,
                        "modified": 1698662765.317829,
                        "name": "/dev/shm/incoming/2023_10_30_104550_00_00_V_034.jpg",
                        "sha256": "ce940f24780f3c7d79a906544b08e94a5f7bbab5020dab6149af4b68f2a04de6",
                        "size": 776722
                    },
                    {
                        "created": 1698662753.077829,
                        "modified": 1698662753.077829,
                        "name": "/dev/shm/incoming/2023_10_30_104534_00_00_V_033.txt",
                        "sha256": "7f3583875f308eef2862e41b892c3df896b38d5aab3e49bfb6f982125ebf02cd",
                        "size": 779
                    },
                    {
                        "created": 1698662752.9778287,
                        "modified": 1698662752.9778287,
                        "name": "/dev/shm/incoming/2023_10_30_104534_00_00_V_033.kml",
                        "sha256": "fe49f2df0089503fb261ec5dab7763755ff4b260eccd68591f148b8cbb0ebf5a",
                        "size": 891
                    },
                    {
                        "created": 1698662752.067829,
                        "modified": 1698662752.067829,
                        "name": "/dev/shm/incoming/2023_10_30_104534_00_00_V_033.jpg",
                        "sha256": "c8c3678437eb5f7f6734fc9a8e531c6de81c72dcf820b3e75e38f65776362cc7",
                        "size": 1140444
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "30466b81d3c26e9ae94869ebbb766235db07639a8f991ff23b644fe30cd8c08e",
                "size": 4097787
            },
            "errors": "",
            "filename": "2023-10-30T10-50-45Z",
            "size": 4097787,
            "verified_at": 1698693498
        },
        {
            "aircraft": "2-WKTJ",
            "client": "EUNAV",
            "contract": "NOSEAGULL",
            "created_at": 1698743802,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "Os/9BKd8R4g6Uf0FDInBufblADXoFd7QpZ6splolXmNi1KcoWQCarF5RGRX2dUNvIaEKSmc4qXVFg7Vc7leLTnuOy9tLsf6Jn/E9mLAx1QNeALQqyL8DG9V/ltEnuJIH9crH3/e3Q1hQkeSkR85/BIWSjMnX4qukJ8eJ0CDpgrLJPA9k/HxZ96o6QaLLUDC2atDGWOr4Kt1AanCQgr7QptNP5xV1q23G3cHQLf6Dv0UAG7G4dwT80CPHPOk9kuG6u+IHsBJWGNf/mDswE93iAGmw+7iOsDNkhg+ZakvAH+dDqjfcpWpERYK8IXq0l1fIxdHU66YOtH0Yewn+q9oF1qZOidah+KRALD4yg9hI5JKJWkoyOu12V6r65eTxQPlSawy/qNigZPqb4yBuOrJoLt7NfLQacWEKLd9si0gQywwWPwSsQZpSUkor9bIFB5iIffDprhrdJyCSacxKH9jQ2jfEjo7zv2B810INQMG7g0JOhTNEQkf44y7iaUBdXsUvCzpG8jDFqhVRhQf0GGbONEjf0mePhgP1u7OrIOJ2lB/wLyOZE2NxlNq1Knu7MzYcCL6iLPpf3QQtekEKOghqboOL6AxgpKivvzxv0OjB/4FTJOJBt+fJjKqep6fnj/uzwLpILsiNS4IsVTsXrblcDfyoeiWldRjmN4gdTyOUr34=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T08-46-02Z_EUNAV_NOSEAGULL",
                "inventory": [
                    {
                        "created": 1698741833.699791,
                        "modified": 1698741833.699791,
                        "name": "/dev/shm/incoming/2023-10-31T08:33:50.ais",
                        "sha256": "5fdb6355a314f24a443a08b357f527b90736ec8f988f03e5099db7440057f363",
                        "size": 368
                    }
                ],
                "mission": "NOSEAGULL",
                "sha256": "8ca5bed90af1841405739a71155a476045899215f08fcce0862f26232ffd023a",
                "size": 415
            },
            "errors": "",
            "filename": "2023-10-31T08-46-02Z",
            "size": 415,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "EUNAV",
            "contract": "NOSEAGULL",
            "created_at": 1698743810,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "ftd0icC4hfjBQTDdAeFBmr1vx+DUouVdWHlZ9Ft13yiscbiZHxMiGOewGTJwkl4zuWMDAk6J/TKkBl1n1XzzkXm/EK8sNyNc+WwsWLIwylqEiLvJcIdk4XBraD03Plrz1/mrTT4UIxHyErqlpum2roZZm1XKUU3wgprCE1CGJgE5HuyebbCTLHb4zPucSDkYfd/7DPGfcgiEiqrOWJ8ttftsaqB133oWarKvzFAbhs4K4b8R0lc5YvdKkSnU15jntntxQdcg9/7eTVzfpb4+GBkLax2YFF6iibhdj54KmgNcPFfSGhBQGbAtU9FRX4wOIhau+yLpqms8Sq7dop8PzjMfquXrPA2Sqy6pSh9WKY1pBs95MJ8Gj6zwR02axILcnJs0VSnj03MnHvBp6ESazpCyS67GUHdfSNDEPe9kqwEcj8ar44pwrjY1/Zk+jr117XjEozUeDz9QpmE2W7NzR7y3+4FDdLu5BpYcwlKHvbgMfZ1jJx0DFQVNkFlshrE7aenYKQGzqNh9CLHkkU26v0pBr9t/BG4gbFof3Qyu0EkI/KUnFj8sYGhITObNegR1Kgf51cq8ztUl50V4b9KWOaLajphcxMABjLIY421bto4c2DVUpJECbas+/lhm0TLDQvG0Qm0PeQ2A5eGBl6/qgsQZpoSClL+zuwqAL8O2PZs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T08-53-48Z_EUNAV_NOSEAGULL",
                "inventory": [
                    {
                        "created": 1698742417.0397909,
                        "modified": 1698742417.0397909,
                        "name": "/dev/shm/incoming/2023-10-31T08:23:36.ts",
                        "sha256": "11472b485decee7e014f608cc4f0821de42a3a6170f3a2dab8d337c91b871848",
                        "size": 435651460
                    }
                ],
                "mission": "NOSEAGULL",
                "sha256": "39f3f1466a2b908717fb12c257a85644634d6ac07ffc1364401c066c23aeadb9",
                "size": 420310357
            },
            "errors": "",
            "filename": "2023-10-31T08-53-48Z",
            "size": 420310357,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "EUNAV",
            "contract": "NOSEAGULL",
            "created_at": 1698743822,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "ZRpnPIRU5cVsjS84zWJfd1q6NAyPLg10OYFfEzLL0rxQaJV4LwVcp+99OalJatr1SQPG8yOW4DFyl+jcEQY9515vosh7Kbq2QGGimaRRPcfLI2c9pbqYbvoNHjPDLTxScAql8dVUOHxKa8ZsH11bgNtEvpizTeDsyhMyRGumfvMvDCWc6NIJNuhNUTFpkid9J5wpBupLOZYVKgPdkXvniiiIa5Gmx8/yWmDK963X1St/pkxlqKZvvveA3uddo9fixNSei6IkUBqj6I+lhEhQ39Tadz3+4pWVytFaLtQC/XRUxSEwmVhTECC5LFX6zEsIhdPeCtf/1tRSeiQVUhJTbIa/ETKkXsLxJ8XuFn0Xwpna2I3WuiAN13prT15b8hjcJFgbGW/jQEOb9W5PPm0uUrY0Rr4v9+P+1OiVW58OtMIDVO5/LB1XuWvDZfNAO23oS4xgOEjuE+NJhOQ/hgerKLHZBwy4u0owhM+37lJevWplOFph68zYSjNDOZ9G1o+DFVF4eTy9meqCOtsJUyDhWKOLHoq1adKA1QvF3yOQ6fRAk4Fy0cgZujE+BCRZdaqkod8g3tVNcYn6ACqyWW0QwiISNZMVThwrUCcuFwFd1vieXdCugYOifBnwyC37+eLoCQe15g1k8dGD/JGpX/SIvaN8Q39GT5W9+TzeDnC47J8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T08-57-57Z_EUNAV_NOSEAGULL",
                "inventory": [
                    {
                        "created": 1698742554.2897909,
                        "modified": 1698742554.2897909,
                        "name": "/dev/shm/incoming/2023-10-31T08:43:53.ais",
                        "sha256": "881d612df8422a6f8a5684e90f73e8c8cfde902b817ba28d9afb54e8ce1d4d81",
                        "size": 304581
                    },
                    {
                        "created": 1698742554.2897909,
                        "modified": 1698742554.2897909,
                        "name": "/dev/shm/incoming/2023-10-31T08:53:43.ts",
                        "sha256": "5fee14d566e209687a90a3afc10b492ca592f46413ced194aa33e7811a76c374",
                        "size": 19985528
                    }
                ],
                "mission": "NOSEAGULL",
                "sha256": "acd0e19747f5960c3dd50c021d4765a46488b3d937e9a44514de9a046398980f",
                "size": 19556284
            },
            "errors": "",
            "filename": "2023-10-31T08-57-57Z",
            "size": 19556284,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "EUNAV",
            "contract": "NOSEAGULL",
            "created_at": 1698743832,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "SVw5V5XHC9rw0nMuozz2Mv5DPe+CoUwJr6EVdmCjLn8f5S4SM4KayGEl4DfNGf0ACD+xkYeEzFcJG+Lufg5R2WHt9Z3USHft58uiokDbM18YMuB4naCo6iywOgHl8aduSxpAAXeJwVlx14+inB14NUnm+P3T2uTykSlX4fV07SJTJlnUZVJFoub/CR9JSDJth5g68y7729N9MFnGMnxnLIZXXdR+k16Doe2wf50ftiipX2W1JBT3nzRCwkQWxLs+fmq1Q/ya4gCRhkkQZAcQE1NjpfX/RYjjgpv3WcTXmz1Sswn23Jf9ajuR4DynaPseHXrMxY+MVtxLx1qUrCibwS5Hu7Amk+2aaq5gzXqWzDrM0KzjRQ4eYa9S+Q7IdcuJxB6r/gF396jo+9CA1SEnBtRe4Dx/3toB1FjFz7ErY4BeTYzEEDjwm6UWBdKZgROWKoZ9rucQ1HQFNWzqPB7Mivo82SvuQp6FcYbmVq1WEiMN6M0P7PpV0uHtGfm+D+TkkExOzaax7c/D7xTCQzKiOONwo/3+L5O+FxbJhsZPZyvzIyqae/QTR6w2f2e1zvUFbw+hEWNP2cvcKk1WcUcaVZM/SQsT34v+A3MFlER/euu/zLVSx1FZ0JmEZwEXZr6orOFg1q14qEpptblKzLqDeQI3PZwdt/4pWheLTUvNgZM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-06-03Z_EUNAV_NOSEAGULL",
                "inventory": [
                    {
                        "created": 1698743035.0897908,
                        "modified": 1698743035.0897908,
                        "name": "/dev/shm/incoming/2023-10-31T08:53:54.ais",
                        "sha256": "8d9de9d4e01291e5c10d748cf6004bf27165ff84cb18eced4b27fe99fd5c44d4",
                        "size": 507130
                    }
                ],
                "mission": "NOSEAGULL",
                "sha256": "b7a06b3377af57ff8eab2e425a5f4edd9859823a091572296ebcd704fbaf5af4",
                "size": 153977
            },
            "errors": "",
            "filename": "2023-10-31T09-06-03Z",
            "size": 153977,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698743841,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "hS9s90B7ZPbyAVt4xn4NW+Mo1s3wuNIaiXjDnCgUq98r45zJVlni6SH3MEeMhU+A3FnJeHk1Whtd8D2tO5/d9J3em/Be6mQAHHm5X2YOLq8y+WWRMnqfDZY7hpIc3kY+leCyCpNSgtR+hcd3nwUFyYad4oJI2KT8+SN2Ylk7lm/FNjYVKxLWgqYsA3Cl7lTrFxE+bQl2Qx63PeTzie6vuH8p9lk7cv20AYOWzt6zvNQZSCZX3tlCM7mGFhRDYhKOTlh2oYPeg8y+fzO2DWZhTf0KNjpACctGsaS4Ibv2+oZxWnFteV8SvUkPMea5SFkNGXSWN2jmyUcnz66PDthMpGd5MAIjronyTVd5VJ6U9WjLJxmTZWQqXundAf6cSILMVkIs6Hg7A9cujAHb0GS3oZHsdzon5q4rEPlBQUSd0tiXqccjxL4N2ipwlEG+FgPVZwrFL3Iw/r58XMxG7gqGC7CwFozvBfMO85xGQwyfRk0oSwSm0vCoWh7rDAS7YhwLSnsK/WMlhDvYuPmpA0tNZCLNGWOKI4Qmw4HUKXLv3tL6J9+joa/dx1wLWMcHrRhPNdLqFIHeKBX3WpzEkCKvCoONyi7ry9jnyXwu9bBdNVyH9haUomYRKgHIps5ceVf/Apv53KdhZwON073jl3D0/8g3Of8Ld+CnlnTLoSuPkpg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-16-01Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698743636.019791,
                        "modified": 1698743636.019791,
                        "name": "/dev/shm/incoming/2023-10-31T09:03:55.ais",
                        "sha256": "be577a0430c2d2ba1de78fe3c7f144f8767b796465b69339642d470fb15e7ab5",
                        "size": 518099
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "d6c3e11c5522928398f25e7dc331c34d4ac923cf6840873461313dfb4f79ae0d",
                "size": 153465
            },
            "errors": "",
            "filename": "2023-10-31T09-16-01Z",
            "size": 153465,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698744110,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "TmPbNmCXvthaxqSC7fclVAf+mFEPY78dbMfNeyr+X8f3XfW6UEddSbVK2LbhBAaMaxQjQOmaar9fpiL6rRZlYxQoSbZuAPG0MLlwYQRcVjh9Xj7IIT1QlIFdQ08gwqpLG9AsoZ39QJIJFfZj+FeuAdi2wGfdgBf7dxhegrrhdUZwxX3u4u7enGaa2QG2YlPfkXjp63dOhJ8OQA1amhtAAUI884g15u81HK88A73GhZV9u+ZEest3SoGAsg+f/gvFGIe0uFJcYfk8U91FSWrS9uC2tSmOc/VZqjZ3aKlkr/OP1Bt82cwKStiuSjNhBWdINxWEAeL/5DhObm7zz2WGRbIPhfTKn9UNr+LX+TVcSy2AwyFi7s0ARm4mrgbGTJCXSiSnwZwweVuCEjHYWPZEnQXOKVB1nnG25XJC+07HHXIHc15m19rgRQ7b0ojz14b37wANJHdxtTuLVXVQdr6/5iRf3tzGYV68XvDC3ARZH/fjEsP+kaOLG3FMK59lno5qMW/2wFhPO7FY16NqwCJMHsVfqa77SqxUB4YV103IkLlHuLhhRhmCCdEGcV03FA140Sc2tcXy0PgGACBdPYuqpw5EWGjyiV7pYnQ3JaiOwv9Iyz5xzcfdPKuIJijnekvP1Es/eXXAeFrQjLqkRr9pn29v86vGvEhnhqn3SS5nWsY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-21-10Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698744064.8097908,
                        "modified": 1698744064.8097908,
                        "name": "/dev/shm/incoming/2023-10-31T08:56:35.ts",
                        "sha256": "aa2d90975cf425be55476334187e1de8b63dec4b3abf561a054392813fdcdad4",
                        "size": 303592364
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "16e09d5c118d84e74d91ec5b382789db9081d138925e13e3178483eae01ffcee",
                "size": 290374598
            },
            "errors": "",
            "filename": "2023-10-31T09-21-10Z",
            "size": 290374598,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698744240,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "KoYfRATVEGMMdI//T6sEEOfAxuVSWIJqtn9GzSxoehj5dwJ06K9eU8e6VhQwKLIfBbo1EbO8peI7RtFECp1GTCzYkQY4dajKvIvHTDmUxyuwHqkYzlVCaAiXe7Tl/ypgA/1Ezdx2WxVy2wJdJ2glkU9BUItETmgWpsUszXoAxXgSuNWzMKyIv5UVRLDrXeUSvlARpoUPIVT0mXrT7j0Z/vAoLpKylvlFTs7aMqwX4x8jvnE8v7JJ3Y+kLDmRJ10kIN3/HatTa6Ovrg8XHVkkinrj2GIJRRAhEU5CJ2zQ4qXQcWTmv1uC6vsWuW8wtfgaI71QU95WN84ypmsggEQZHz6FFNJfvwvkD9d8uarv86e8H+fKQH7RrjCVMAMEZPozrABDV9dCnZwvsl4BpnGJ/9DV9fKHYbwQGys7/KYzJd/tb2C7/Qqc6OGYo8FsmgWmb1cHB1TrC9h8rzxnZbl3gWMJh9wNHDOcAO+cncDxhKYVgIGAAoJUElwdGfYrRQJVS0mq3fTfRdXEhz3KriTU8EHxZMjy4sj+ivEi15EqWExbCxBSSmJ10I0aBx+QArh+ByZ9Lgf9fZ1KS+/nTEt2bzU/RRpbrw3NrdA5W+KWU5nxoFlqjcg2zv8VVpVCw7e391AsxnJ12rUhzlL/bdrefm4wcR2zrs5yPYuhiV5tiwU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-23-56Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698744112.0997908,
                        "modified": 1698744112.0997908,
                        "name": "/dev/shm/incoming/2023_10_31_092128_00_00_V_001.txt",
                        "sha256": "6b2a1dc77cb30e934384dada885403591c54b0aebf1f3884b67cc280250f72a4",
                        "size": 2053
                    },
                    {
                        "created": 1698744109.979791,
                        "modified": 1698744109.979791,
                        "name": "/dev/shm/incoming/2023_10_31_092128_00_00_V_001.kml",
                        "sha256": "c65adbd0e66700db819ea055bbccb5980ee796810a37aaff558f15cf0eb0697b",
                        "size": 961
                    },
                    {
                        "created": 1698744109.189791,
                        "modified": 1698744109.189791,
                        "name": "/dev/shm/incoming/2023_10_31_092128_00_00_V_001.jpg",
                        "sha256": "2fdd7cb9cc777d5d200346852c31995f0c8b1994a1626a924daec457e6be23c5",
                        "size": 887780
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "d0cfd88ad10c8a8a608c0fa41c191c25aa8b2bb757b94c34489276d018b6817b",
                "size": 882375
            },
            "errors": "",
            "filename": "2023-10-31T09-23-56Z",
            "size": 882375,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698744369,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "PIeRo8ZfuS6ULP/CVIZMJz7+iUjWCezen5xqk0zOPosGypsv+IyWKH/zkxUqGzz+8NVWc7drTEp66fXp6X5EkwxxLGlyytYUITmzijmF6NrYxyHwalMvUv+7C1+QJWtytqepBtFL87wIHUFrr2SkVMbS3i9D7xOrTEgs4xetbW4Lip6VQmt6m0H+wskfyNMLD2XlaKfgtM2+/pZ5GrpIKTXszzD/FW5tcPajNQw2VpUB2FdmxQPl+ZkhTb90FWY7i69CsqFm0xj2opux7AiUF0ceoJpgolvulPEP0VO9LIh/h2wGx5fj1B6jbMJ+MnPlSmz8XS1W6gwTCzj4stnEgEITicAGgWfhO4oqO/AF3enXTqhN6dKhh8JO3QsBLzkYiEiILrMLevKYANknI9lOu7EF/fud0Rz2TAneM3/bzSYTDaaTcYu4f/e4hoNAo05h3JOxm9bHsyhip9QalcIGLMSLli0zqkfvOUgztLlGVJbvsCFT90OOrFA/ZYEWDu5cazHxgF5KWH70oGsWZ59pysn0ybXOF7PGTuW/gB/oSBo0ImIefYJZM3dWHN4kLH+1MkrDTYByn/j/Erm+XSVdTL3N2+jzbydcvBobCFh46qOfzyrExbUo+2Ic4tI/b91TRtBNeRwN7yFQYhF23KTJziKTGNYFaqrYUjiBICODaNY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-26-05Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698744237.0497909,
                        "modified": 1698744237.0497909,
                        "name": "/dev/shm/incoming/2023-10-31T09:13:56.ais",
                        "sha256": "63b0b03c8741447c1ebd2d55f7261de58d98ac09b02caca6a5e7960d4b756da5",
                        "size": 733624
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "5cdaeb56dde6635055adbdb5908b83bf266a4b73320686e27d045764ee81ddfd",
                "size": 210801
            },
            "errors": "",
            "filename": "2023-10-31T09-26-05Z",
            "size": 210801,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698744345,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "kvoU9bl7u8vJvinpm0VMOq4luPpoF5YHSH6Yvqoa2vL9/pROjKnkBcAKux8g9lbxdLkNJauBwjYnmAbZAxzX2WaKh0s/jMAqCEGn7K3K5SQrQZ6oJ3YlP8SVjHt9o4PbImbwmSxXviY7bHnneQ+oxmmq/CbAE4vnlIQrUuuNhc9PhEMwMx4MniFXp3xW3ZEMo5LEt64I+GGvvGUkxVoPdGOzhkNKLYcS8o9fHOrsdX+a/Lp3c3ibW7K7AS6nHHo17juR48gxLOKp5Maijlyshvrnp28aW2JL1f4yUPXPMSd0ZIAXIvllByuZIxGv5KG2grPF5P9iK4G9OqA1jf9/ZjRIXZacEsZMW3PUDEDk4h6XwavhWKjrH0uznGEBbSdLfIFbqn4DxJEVthno2bEbiiZFpRPscofuRiwkPi5TOQHSqqDLXtMI1XTQlEhF7dDV5KdPqnQQI7gAN0fCJTbqcpunEXnVMeHYUfgEj/WWW6CaSxXcALFglavKLR8ftGBPyiPka249REi2VxKcv51Qk8NpfU028llSv7OVm3f2EcF3L2sEEl7LnLEKIC27mB9/BS45ttxBcm5vOZrd/A67yTS81TUF6W1nMSKLwfqYToaZC2MVZJ14QztFxrzA5CcxsoeIf9fpjgxDdSWkycqaTfnxGiotZIs9FzqetLCHocA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-25-34Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698744206.5514185,
                        "modified": 1698744206.5514185,
                        "name": "/dev/shm/incoming/2023_10_31_092303_00_01_V_021.txt",
                        "sha256": "01d12960917e1bb7fde2527f8639ef122422c432753d265ae6f82a24d444800f",
                        "size": 2039
                    },
                    {
                        "created": 1698744206.4414184,
                        "modified": 1698744206.4414184,
                        "name": "/dev/shm/incoming/2023_10_31_092303_00_01_V_021.kml",
                        "sha256": "25bd88a45fb29905c5b3b5adaaded6569800cd3eb820fd841f7f4213b6cf56c7",
                        "size": 952
                    },
                    {
                        "created": 1698744205.5114186,
                        "modified": 1698744205.5114186,
                        "name": "/dev/shm/incoming/2023_10_31_092303_00_01_V_021.jpg",
                        "sha256": "e39e4f034a2cd72038a317cf586df7cca3956641b1b67244d4773bde1eac033c",
                        "size": 1374191
                    }
                ],
                "mission": "OSP1",
                "sha256": "9fe342f43a7c45506eb748d028f4e372cb033f2869ae975630486cd0cab98683",
                "size": 1375983
            },
            "errors": "",
            "filename": "2023-10-31T09-25-34Z",
            "size": 1375983,
            "verified_at": 1698766888
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698744343,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "LOPkP0Rd+vhKqmiLxH3BlKvsQo7/MNjoF8u0mlktaW2yVxAfwdTEzgckik+jTkhujamFhZtzrH9uootowl4sGx5y/1IKialRLeo4+k7sNcdbcSrJ7lnRiCSG2lCgzz/bnZ9W9pI++fkfPLwZMkalo/T5Fy8urxxGrx2sY+fEJidl/V91fbOUi4HtkOWlsCXdHKicNDrK+FQT9grNWpw7QTAMHIywI3NA0L+8SqYl9KL6rF2feqVa91EWRSixJVFQRUpPJC134WsSsDF5EZjjwszphUx7fJo9enkHHbZ/YqzGdWQ1jFvT+mnm4jKzOurA7at0/YTsZspGhJH8O5jKCi/Idgz6TgjryaT0uktLQH09Ld0ywsLjnrKdCZ17Dov7T7HY9AgTDQ8ORgOyxkMW4Ah3Ns1kPl0MiOiv+CRCqRBqa2OZxPMSZsW2Oqpqb1eExk0omYlUBBs4CVNcQh38y076Qo1GFrd9cpBFJwdXmqlX8ND0sXZKVAwPNSCeJp5/3EjhiwEz6EnSLdi01IQOGCc6UNNXPADUolBRRLtzzn7cANfnlQb1g+1a5u43yd9fcuocUaicbWZNyrPj60oLP3Sh+TQjX5powm/GTAQJ6bxe5WSmggri2rH251mnaTdMO6gHemkrM3AQxWKyh5zHeN3SXqcf/Z6Jita7HcFX9HE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-24-38Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698744154.37126,
                        "modified": 1698744154.37126,
                        "name": "/dev/shm/incoming/2023-10-31T09:12:33.ais",
                        "sha256": "45d46a80884ac8fb24bbb0393487c232de63f3b5f45f38c6b95ee138d3ca8631",
                        "size": 280762
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "221d7a1ece0b80cd0fe5b5a528804369e375c123a858a2e40e394051cebea57f",
                "size": 81063
            },
            "errors": "",
            "filename": "2023-10-31T09-24-38Z",
            "size": 81063,
            "verified_at": 1698767437
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698744309,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "C28nxZGwDR5rN8JfrJKEgJ8bVJkhckG0LxunXs5GgRPHWqNK8Lffe7lDxaADGzRaJlpjV6QpyV2y//yldL2+0hHY1U1iEnlantX+IZVLq8DLb9Tp13jaIf2Tb6aTJKmhcZA7kD7rTC22rLf10tx1RmXJSw9x+fb2FOnxDfT2TIVbSoz5NjF1aQSUM7kYZOgQz951e0sUSoJjYLYTiQErqROG8pF0s36QHo0LzgN6XQeiHT8YGnUWmY0iDblGSU/g9y+8+45mbsmwky9TpnG7pDzjCgyArzq1m41qe1DJ5pZo40Yqmtvb6Jn/U8WrBw86kRzFci9I901nez8yOX+EUuZEXR8geuIL6RS+aW6UjREkJNpel4YD4asXIpIUr5KxcW4Zt4taeO4YHilLAPshJUr4aGrVmeJdjqNKsKpebAnaiYA4Z58QrmHaWT9HntB3GOkJM37WjWVRdT+aRIZpPywKIKmhyCmAWbupHUdPLgAxqOu64K4grmiQOktC069ZV9ONZtV7CdA5cfiwVsctpWMPXUDIne18ztAdw9U7lQ9eF19EuyidamroIao1eDjjkKiAfW5DJ42nkYG0XFMR1Pe+WbZaURUEFC0EdDrV/6VblMmZd5dA/rhq2L+T0F1zT5Oz2GOfW7k8d5hpaU0Np7JjdeVH644I1AmujNhgTgg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-25-06Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698744178.374362,
                        "modified": 1698744178.374362,
                        "name": "/dev/shm/incoming/2023-10-31T09:12:49.ais",
                        "sha256": "5460e6736c775fce0bee6cf293b5c5d1ace278fadd6a9d29f84b873ae9e489df",
                        "size": 51262
                    }
                ],
                "mission": "EFCA1",
                "sha256": "a396c129d695e8df43b4273e27d8d4e3947d9f9df802ba16c20ed9242aa4f9f0",
                "size": 11506
            },
            "errors": "",
            "filename": "2023-10-31T09-25-06Z",
            "size": 11506,
            "verified_at": 1698781990
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698665453,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "KDigwilGD1418FRBW9BHsmPZpTy8bm43XALpYEcE5kco0GjUII3jK4sLn5gx6PUU58cTUNkfY44Zm339/J7lVv6eo2aV2dqT9vXRYpxFz/K2OaWBC4YjZ+BYcNRBYMkQBqnsCKzjgghqMp4oIjdHSufgoms0C5wgx+fT3sSTHr6oDm0rTjYkq6J5zudQFfkcGHQXDgqQAMU59CrFaSPAo2AKtkrZIeNWpPsZd8KHK+YYxmizXv25bPAIeFL7uJBANpIJK5TaBE8EZlTANkMHiAlqC9J5irwruuXP8OK7QSvH3RPJfpjskHOESvf0aY8ZKg3DP9Zn5/3qJb0LdJUoRW1WKHwZB2GxPsuRmbDOcBKxTgTopep65B66vuv57lKT8CWcvjzlLvf/EIA6ytheMDvwXNnpzp6MpSyYyntx6Q6uXsxf/63I0t/aULBN00w5kc1wTLJ3qWABk7xu2BrtqI9kF+w1NMtJ3dnzAbcddPNGGK5xgYL/TA7wmW/O5vD56o4UF/l20dt/fRVzDFdqb4QBhFetZiztKaAsyiOSu8q0R2VpmeV4+5heLeySlmo4TX/+iLD8bC/gkChZPRTJeBsFjwkuHr0ODxrz/fivWFJ9mbK4IsDLHWP49G97zJ5ntSzoI6WJqAW20caHPE/gWITURUmifhQDO2Q/iY7M+n0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T11-30-35Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698665310.7778287,
                        "modified": 1698665310.7778287,
                        "name": "/dev/shm/incoming/2023-10-30T11:18:28.ais",
                        "sha256": "11aa894785aec7e31f5552c3108f89aebac832f041ff4829b614acdcbea0d559",
                        "size": 470511
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "bb6fc40898d453c3a06fbdbfe587adc461eefbd57782e15e935c6ccda4b9f7ad",
                "size": 132714
            },
            "errors": "",
            "filename": "2023-10-30T11-30-35Z",
            "size": 132714,
            "verified_at": 1698693252
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698744517,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "RAVyze4KeU7aVxGG9jL1+xWKhiOT3n2AOsTrI3Q8UwUy1PRlnGVS7okI9XXVj9YAl0s6S+EvyrdQXglvBlFQyas7Ecxxm3R2KvC1w1E8mDBUyJCADOohywTEPN+tkusFqOxsvlECnLnxbdaQZ3vYTJ0LZn85AOCHMzm3A9FedmbhpZIDSnaQHHD6UplQYhBUyvIO8OSMkdK5tWqjgp38LPaAjURjID30lnpYtvLsPKPdigFUVRxrrBEYZ49nK/+C8Sm+PwGgTcLK3FKuA0pQrk74rTA0Y7NgFAV9zTOEgCV7oFCgNc3lbr16Kp+YIbEOJ3/uYqgn782MtFKfeF16PaZlLcMFWAezwAzrrXkEqAUuj+nsC3Alh0v/Z17DNccTnOS0wKJDnDbS6ZHMWyJaVEPQcigARJwO6us+mUz7sTYA4UvlQRm8MAVjo28d6S/yYRpPF78tev/NL/8J3mtSeHvSmrc9koE1FWbJJ2Yw+Pp8DJ6zBOxLKeDCTwwqYOUanomHZ0g3Vh27DIBZh7Mg9q/YznZEpTAqf5IZoqHCw7FhfOpxHAXHWhZK9gR48eEkvZRgLops7vKPbcRwYDpGDqGLI+OmHJ/kY4s4xLk7M962TB0F4autawq/uvIDVrPOuHkY1p6AuLbrO8/9Nfg6//TvhfPygo/OzJVOEzW/5xs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-28-27Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698744379.0597908,
                        "modified": 1698744379.0597908,
                        "name": "/dev/shm/incoming/2023-10-31T09:21:10.ts",
                        "sha256": "fed521f032ed96fd0adb84e448c3107c6e9797976788341cccdfb35dec1e8fac",
                        "size": 70051996
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "7802b6ac50b98dfc9e8c730e4fbdc77e627332216f8dcf4d2a7f20da29770bb1",
                "size": 67515749
            },
            "errors": "",
            "filename": "2023-10-31T09-28-27Z",
            "size": 67515749,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698744947,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "IXhmeQxoLkqqbH1bsmszkyU7vFon/QghtbSvzIKJQ/vwbv9ethYjiUwfGQeHbhQfDHdwZZWHQf1+jFbuwtqk8OL3+wUOkQvfRqnyvaAxl9H+iAcZmPQM+uauic/CmUpUyhRbJv8C58YbgX8uxWApNkUuS1EGuHMfW4CGq4HErXU9inaERlg2MT8tkL7QtnFL/tGfHpBaYPDFGTAcH4ckMgWk7xBWr+Gwuek2m2vR/1I0+IQeNfLshegYzPfI51h8i1C1NokJQRjdp52A8Fl8ymP52omOtrypph5h2inKYDAgIaFxyThUipszLQZzEqrTKJ+H98+/kVgNbGtRHcIV4r8Oh+5suc373lU3Xskp6a5Mg+blXCK+dvdhV5IoGRs3uzmtX4kP/YJsK2EktPHxR1Y0Dtpg4UZsoaPVaivQXpj3eETQrIUDAp8Bagq4HojM7ylDxqoTqgPncQNxgWnv508hwxdYdHh9gb5rl5tkzCVptS7zs0msYSlB15nQ865S9LUy7o3ETuXQxw5RDYKopgRFcc/3regVH8asfb1ecZf01nigvxoHvThMTVIZD9t1ehkMfLXKKX1lmvHTPKdxjQDdsNDi+bOhk9BE33fVD+iS2SkH8/9/JXg1lyfs/fpVE4Byp0n3k2jPJvmFZ0bc+4hsNaus4hfxwYwcVca2TH8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-35-36Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698744810.9114184,
                        "modified": 1698744810.9114184,
                        "name": "/dev/shm/incoming/2023_10_31_093317_00_01_V_030.txt",
                        "sha256": "efeeffaff3666b399d8302745f6515ea6354b499d6fa21aad6fb7eae22c4ab74",
                        "size": 2039
                    },
                    {
                        "created": 1698744810.1814184,
                        "modified": 1698744810.1814184,
                        "name": "/dev/shm/incoming/2023_10_31_093317_00_01_V_030.kml",
                        "sha256": "4d610922761e43cf76799662e99ce8cba25ad03e90a8766db5fac4a803609044",
                        "size": 952
                    },
                    {
                        "created": 1698744810.0514185,
                        "modified": 1698744810.0514185,
                        "name": "/dev/shm/incoming/2023_10_31_093317_00_01_V_030.jpg",
                        "sha256": "9fb852f20dcbd59f5590d1a7ab29e810ae0c8eaab09fbed2f974e5342fb89232",
                        "size": 1496707
                    },
                    {
                        "created": 1698744724.8214185,
                        "modified": 1698744724.8214185,
                        "name": "/dev/shm/incoming/2023_10_31_093142_00_01_V_029.txt",
                        "sha256": "e867118009e9b8301db22dbfc22c7d935cb37a79521d25c545e8487d91e4c538",
                        "size": 2034
                    },
                    {
                        "created": 1698744724.0214186,
                        "modified": 1698744724.0214186,
                        "name": "/dev/shm/incoming/2023_10_31_093142_00_01_V_029.kml",
                        "sha256": "86b05843239b0f2f5de29bbb1e212924f044cdfdaf0e847b1ac166216b303840",
                        "size": 951
                    },
                    {
                        "created": 1698744723.9214184,
                        "modified": 1698744723.9214184,
                        "name": "/dev/shm/incoming/2023_10_31_093142_00_01_V_029.jpg",
                        "sha256": "1fd01515efbde85e9ba078cefb43488ed17f96bc6026322048dfd53647445230",
                        "size": 1637003
                    },
                    {
                        "created": 1698744722.9714186,
                        "modified": 1698744722.9714186,
                        "name": "/dev/shm/incoming/2023_10_31_093142_00_01_V_028.txt",
                        "sha256": "771e0dd11a7764ab8f680afb9291c29cc4623fb6d8119e17dd1f3494c110f8ba",
                        "size": 2039
                    },
                    {
                        "created": 1698744722.8414185,
                        "modified": 1698744722.8414185,
                        "name": "/dev/shm/incoming/2023_10_31_093142_00_01_V_028.kml",
                        "sha256": "39bcee32234df2f66efdb490cd82bc225dc78f905c40e8b721ff6198c551518d",
                        "size": 952
                    },
                    {
                        "created": 1698744722.6014185,
                        "modified": 1698744722.6014185,
                        "name": "/dev/shm/incoming/2023_10_31_093142_00_01_V_028.jpg",
                        "sha256": "be46e40cda36d77b5f885f71b062a1a18ad4a41e474d37610ed2440df34429c9",
                        "size": 1586169
                    },
                    {
                        "created": 1698744658.9314184,
                        "modified": 1698744658.9314184,
                        "name": "/dev/shm/incoming/2023_10_31_093035_00_01_V_027.txt",
                        "sha256": "8dd3e40ee9957e83b85dcd6c06a8545eca4ee8cb24adb1b3f103d1157e7e4892",
                        "size": 2039
                    },
                    {
                        "created": 1698744658.6814184,
                        "modified": 1698744658.6814184,
                        "name": "/dev/shm/incoming/2023_10_31_093035_00_01_V_027.kml",
                        "sha256": "2def9038eed4bda5333dd24f5a284df3296afc17fc61db18e2857970ba0669d1",
                        "size": 953
                    },
                    {
                        "created": 1698744658.3714185,
                        "modified": 1698744658.3714185,
                        "name": "/dev/shm/incoming/2023_10_31_093035_00_01_V_027.jpg",
                        "sha256": "5b8870f955fef1883f2dd2203be6eca70a8b86f26598eb35f19d365a65dd0424",
                        "size": 1563916
                    },
                    {
                        "created": 1698744657.6414185,
                        "modified": 1698744657.6414185,
                        "name": "/dev/shm/incoming/2023_10_31_093035_00_01_V_026.txt",
                        "sha256": "988a769b4aafec741beaac536eb231b80d682be86e1a229f751225c3628b4caa",
                        "size": 2039
                    },
                    {
                        "created": 1698744657.3014185,
                        "modified": 1698744657.3014185,
                        "name": "/dev/shm/incoming/2023_10_31_093035_00_01_V_026.kml",
                        "sha256": "a5a3a65004292b0e6a443348276f8045b22fec31c0e9efd470dbea7eb6781fc1",
                        "size": 951
                    },
                    {
                        "created": 1698744656.8714185,
                        "modified": 1698744656.8714185,
                        "name": "/dev/shm/incoming/2023_10_31_093035_00_01_V_026.jpg",
                        "sha256": "d6ff65ff7fbbcba813adfe210f0ef0f42294a41930c2f355bbc0a11c31faa9f8",
                        "size": 1569009
                    },
                    {
                        "created": 1698744614.2614186,
                        "modified": 1698744614.2614186,
                        "name": "/dev/shm/incoming/2023_10_31_092954_00_01_V_025.txt",
                        "sha256": "21a5271d69fe4d923c3e5731693940e6061fb6ae5ec33bddbcfe64128e51c987",
                        "size": 2034
                    },
                    {
                        "created": 1698744613.8914185,
                        "modified": 1698744613.8914185,
                        "name": "/dev/shm/incoming/2023_10_31_092954_00_01_V_025.kml",
                        "sha256": "53e1d7be2d4761f37c5bfef46ddf1434950de3fd8c7694fd93097b9c9d235d52",
                        "size": 952
                    },
                    {
                        "created": 1698744613.7114184,
                        "modified": 1698744613.7114184,
                        "name": "/dev/shm/incoming/2023_10_31_092954_00_01_V_025.jpg",
                        "sha256": "8c9e9b22163d726e4bbd54bd0952750afd69e71a34c66d86429cbd0d42db71d7",
                        "size": 1332019
                    },
                    {
                        "created": 1698744612.8514185,
                        "modified": 1698744612.8514185,
                        "name": "/dev/shm/incoming/2023_10_31_092951_00_01_V_024.txt",
                        "sha256": "37ca433dc4cd84178d31dfaaf23b21692075f274bd4796a13dc42479dd26e2be",
                        "size": 2039
                    },
                    {
                        "created": 1698744612.6414185,
                        "modified": 1698744612.6414185,
                        "name": "/dev/shm/incoming/2023_10_31_092951_00_01_V_024.kml",
                        "sha256": "20f20a55ee9c37eab2aeff1655d29b2c284693e94a7b782bf1d6cb2cd315e7cd",
                        "size": 953
                    },
                    {
                        "created": 1698744612.2114184,
                        "modified": 1698744612.2114184,
                        "name": "/dev/shm/incoming/2023_10_31_092951_00_01_V_024.jpg",
                        "sha256": "25d49bc2d1366b07d59a33b15cfe1d4cdadb8d983147861a9a16a869cb1e7781",
                        "size": 1318400
                    },
                    {
                        "created": 1698744546.0114186,
                        "modified": 1698744546.0114186,
                        "name": "/dev/shm/incoming/2023-10-31T09:19:05.ais",
                        "sha256": "ca3fd7afbc5a76e885deac14b601322c19910718b4d73d95df0828d982440da6",
                        "size": 241955
                    }
                ],
                "mission": "OSP1",
                "sha256": "169d45ba8f74d5ec8ea926af977070c94fe8bc28f74fa14b3aadefe47d6df49e",
                "size": 10574323
            },
            "errors": "",
            "filename": "2023-10-31T09-35-36Z",
            "size": 10574323,
            "verified_at": 1698766780
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698744953,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "KJcO2YhSu18j+0iPuPgc/icUjmzwtjfe0IKsl+cMq0SdBz6AL4ndlSQzUHRDRxumwuOS8FpiSekLeKzhCI+lT05pmGVE8ho0nEuqBi3p0TqW5Bu3siwaoWsg5fveD/IeXg6UEXrJNDmAvkpb7WD6u+rVlvb2jcDI4YNQFD2yRFV12Pq0y3cbIU8D+cB8xRe4k8W41icFTgmt84v/n5qKqoKFgUj3/oC3Gfm9W4vo2HVODxTIQrAsyqrMTM0PrgFnl2s/NGXW+BoZsqVBEv/w8XOgeaOlE4HKJiZ4SF0WlAkqpsQML/ItEtkeuMSBQbp79DuIbNCwMHgDRahntT4hbdUtllkJerFspzVkbvi3VqRDqa1FDGNor+mgS3GK2DBQ4dYbqSpdUfwq8uIG+yj57kqsnajb1HovOsjU5KbRisrBITgGbpTEb1K1KKxt8pTB/8LLfQsJzVkFQ97uJbKnrNCQnTH3roO/NEHEcGP99MyuYzgcTRBH3ixoCDeu2/Bqi3N0jdZPkw1nSe/B52B1/2olcJXXTHFxpi0b0s1Gm6HB9nELLa6QeN5w/MF98b1b691JYQ+4+mamlPuNaQy5RW2bBF8Hk1ccGeYfU9SXHzp6LGG2xyhdwcB8ZqvraxwqMN1GXfNuFYNVuVQ3VoARZkZGV/l53lOAYn4E+96dMw0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-33-54Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698744756.16126,
                        "modified": 1698744756.16126,
                        "name": "/dev/shm/incoming/2023-10-31T09:22:34.ais",
                        "sha256": "47e7d10c720089f6480e413f866ea5d06b10d9fe8af20795a4db66d70cec7086",
                        "size": 176095
                    },
                    {
                        "created": 1698744826.17126,
                        "modified": 1698744826.17126,
                        "name": "/dev/shm/incoming/2023-10-31T09:03:45.ts",
                        "sha256": "ceebd8867435db324a592e7e4a980734243d650d294762901a7855ffd7e94558",
                        "size": 440456552
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "1d189b640cc7811f28d23a6e1997255da57600334921fe17d55377c4116f8deb",
                "size": 397254395
            },
            "errors": "",
            "filename": "2023-10-31T09-33-54Z",
            "size": 397254395,
            "verified_at": 1698767951
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698744910,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "gjsvZC1B2kM4d/ZumT34g8AxsjQYsRnRzQkRYQi8ZyTxvDmP+OcOnPUER0gyCWdMPQ6cfFhsdB0ae+D/5iMmPmbUv24hQeq1CDR4cghQJ2om6PcLwuj9jOM86yTFaUJMt0V0NgENHn4GUPyqGNhmYRWn+GsSIYb4nBQHK7d8UUIND8UmCpr7kkNZMwVXYbZbBoHKa6q78NvS+K4OliB+weIPv98rwNDFRU/2IEHJVVdSzZpAVZvJzpDu8Y5kuWxH9Gy5G7ylMH0E/FbHlnXKQZvLUhuy4U438MNlpklkx6IF/Az89VX1Pp9yPERA/zuoc90Piv7lvNAILRpng3EfJCTU5TbfyvPK51XieAvhu4HDwIn8bsS88BDjhu88ghQ6tmON5j5JhhGA12P/ekl8UCtmZNuTIA3Loczq+F5a0ErfPCvic2YfY3uwsqNfPwWFqOd6URxzbFsfxso3vBluO3ogq95jsRqkqcVLvAlSvUXpoexHcHwf7m2SYDGXglAiMm76nqHgsN2TArHNhKL529JEmVHoqoIMhQJICCnMkPoZYKdcKxF4V9PYrKHnrzF98xrD8Bs8+/rSoHaBJbJkafXpsesv0xDmhUm/j7sEQQQlWegTqe0KGh6c7Ke5vl62MeXt6m8irJ9Y4OxQY81PzRf79KUVNlhoPJ9p7u3nKds=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-35-04Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698744779.084362,
                        "modified": 1698744779.084362,
                        "name": "/dev/shm/incoming/2023-10-31T09:22:58.ais",
                        "sha256": "ea4e2c4825d220499435d1243910ca49fc2d7a301a3a988134cefab89e348bac",
                        "size": 138883
                    },
                    {
                        "created": 1698744710.094362,
                        "modified": 1698744710.094362,
                        "name": "/dev/shm/incoming/2023-10-31T09:31:36.ts",
                        "sha256": "1794ad504ee0ad7fa457ff423299d520dcd02101e5b44e2ec99cbba5773eaaab",
                        "size": 2398128
                    }
                ],
                "mission": "EFCA1",
                "sha256": "d27c90e8685959c1e10a2f633f0c0eda5f37ab2afaeeed87f67c559c7cebcf85",
                "size": 2320212
            },
            "errors": "",
            "filename": "2023-10-31T09-35-04Z",
            "size": 2320212,
            "verified_at": 1698781995
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698778459,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "i2QVAfAQNhrlBG7sPWDbdY6qa4PJQf7luF53S11LqYuuj/idgbFFeRQubEaIqvPH7SPwOqiN5ZCxci56KK8Hfla5ja7fB2sWwWrZpwW8BWy6gllHKNv0C15OWmMNeV688mNpzwkRQjqSn3WTwT4wwu4PjmY4a2b5Ayia3qMFVxVU6b2YXyZEY4ml54GuzI1xy1rawbxwvGEsube84bGCULXfQjhYWjIAd4JUJsOpXNicgYOoG/uQO8BY5eJlCyKwWwn0x8BElQuHAEHRjwHLNUjylJxCn8c5px8LfGhV1AtU1jt7O3y80lHLVkmVc8d6rzKJjHkD7toLgtDcSTzdEtolBJF41tb4ZclfRPnbIE76PmCdK88n/RheDX7k3q/OazG+AK4KHHgBSoQf+XrQX4WPftDt2HscY78oktHzfhSbtMHj5HHSNiLiaXv94+XWSMnXy99ei4Zex2G9v4L0+IJlvTP7b2URKH88XevyqP9ZzIsVHjYpmqYiXw+qD6u+M1eHTag68ycKW3SgkFYns2gWyZB9a34/XPeV3mOmST+0lCgLRm1IB57VvhAUqCMk+eDeWVhGmZK2Cu46dq64P+ju9sR9VSHwE0hr3bj4sYomkSsaVmYv6Qd8qT6jdZk0/RatjZ3yo7pT+jBkHvhUS8KEtfiWFg7ERDm26Cd9K2s=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T18-54-05Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698778319.8030362,
                        "modified": 1698778319.8030362,
                        "name": "/dev/shm/incoming/2023-10-31T18:41:57.ais",
                        "sha256": "7a75a30ba636b399966b83c9e675d7024c2fb4bebc139d3b5a330328dbaa00a7",
                        "size": 175875
                    }
                ],
                "mission": "OSP2",
                "sha256": "e4a75af3ff0a73f41c7564343691acb91cab0e9026bb9de95d7230c6d822c79a",
                "size": 38264
            },
            "errors": "",
            "filename": "2023-10-31T18-54-05Z",
            "size": 38264,
            "verified_at": 1698784351
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698744965,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Sj6Z4CAa6l74vj+4y5+Y553iMP2ePo04JZlrTynKC5po2df4tTq4cQvuNrt57ph5azjwNjlw5JIkQM/uiQhMm8hMSRrB2YW6m/XX1ES2DTvEWfXryrFC/tFCJFFUFkdbTvOW1MvdVQshfZeZkgQd1jE7Y+bD3b6MhewUjNmvJ5nA6DW9VDpmp8KYjnPLvI+LMvDYGCB3W3Au2dfZ2MaMVHLzs65SzYeQnpqCdC/BlaLvwJ8KH0YFjA8qNlep7GK35LTfAqb9B0EcKj2w009E0qBSFa9NsXTtql+ujg+St5uQS6ZnDnkG12fJPPk4yvZn+k6pQsH3VahT20ASvj8dVWLDZqha/QAHn5y8iDx2FmALiVdhoHKOEehA6aRWFCxdxhcusW3A5QKCDafTbzf6LwPZRtrGqxPigGT383RSFP/Hmh8O8UoEY2KpR0UcF7VR06EEyXQs7CggiSC3Ij1XzeW+q93Kq/VZrp/n2nmypnQzMszvO7GMW1rdj6f2dh3fmyhQwDJTqqOxA1j7U4qwUkpng6Tt2sJNrbwkAwxTyAMJD/EV7fvpc/9v42uwU1w0Xj9Pr9LQk/RUnlDVzYd/kHuJEGRFntWAc2XzhqK3/Y5SnJPKzPjlEsujEVYbiklgyeNgh49SqoDS2KaPI9gre+FLCSwwiAJyM586mjNfQ5Y=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-36-02Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698744838.0597908,
                        "modified": 1698744838.0597908,
                        "name": "/dev/shm/incoming/2023-10-31T09:23:57.ais",
                        "sha256": "641c18cb79efbda004827c828284ea456a2ed41a7a6006e98575f03e00957a90",
                        "size": 814967
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "5f5fb7259f647c438852c4bc2314c58c492b8eb1187c436ff02a582e4a1317f9",
                "size": 246251
            },
            "errors": "",
            "filename": "2023-10-31T09-36-02Z",
            "size": 246251,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698745218,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "GwIl8AzquZixvdMyJeh55QzEjcSY1h+yspw6LlNIKborTfNag7rwNG0Tc3owCIn8q5AvS/guQFn6gn0SNkZEjc5ZKq5c0+1hj8q/tkZ0HV8C+5hGmFB2I8Fk06naUqVr3IbXtI1zKyiofi1hZBX7D+HU/fSUMv7S9n3vOh1nwSB3JxbO/1TfETHg0uenviZDvqOFCMQWtVnU2A8c2gKFmtZmECtOrYcRRFEGkBiOofnhG6nZhI9Q8DTNdX6HwFJVXfY/P80LggQqSx7SfqJkGrUy8w4wcod0j4aCRn8o0t3eXCt8PyLdJDH5/qqpDIg7RIuA4C3v3PhFSQd45Y3LFXhCXmpD8+GCqNUeoQAI2gYQz5hd0KUKvL9mB9POnetMhWn7mEmYVk4XdIMRFhj3MqvX8Uvn+0krajLz4MJPQudU6pOpok6EmX8cd6ncLcOcV3JW8/Q4+tDiV/zi60KR9NsX2DJlLlCLGaCPESVQq7mc6kBg9cbKtgEEJmBpnwX5IqzJJsHDh7SN1SFF/pRtjUpnfBeeCEv6q69mN9iFH5qdPsXgrSHVi3X7xohWVs861US4FW/Mr1HJYXnu0tAXzUtfN4EQXeOsSq/BKc49hJhmgOj1JrA+S753Jk8zoB9MJWs8qLuDdxVGLj9UeMltSHFvZW7Db0mkWDcYmmqItxM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-39-22Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698745147.2214186,
                        "modified": 1698745147.2214186,
                        "name": "/dev/shm/incoming/2023-10-31T09:29:06.ais",
                        "sha256": "4e94d72c863d10c301800acfbe852abe288c1a2b51214789c2e3cb3654351295",
                        "size": 270896
                    },
                    {
                        "created": 1698745125.6814184,
                        "modified": 1698745125.6814184,
                        "name": "/dev/shm/incoming/2023_10_31_093824_00_01_V_036.txt",
                        "sha256": "d643ae369c39913c1883d35970ead0501c55159866bae9a50a578f232ec6e7fb",
                        "size": 2035
                    },
                    {
                        "created": 1698745124.7314186,
                        "modified": 1698745124.7314186,
                        "name": "/dev/shm/incoming/2023_10_31_093824_00_01_V_036.kml",
                        "sha256": "8da9187672f6f4443439e0c19a3324c16a0a07c127a46997857c9bda6d6e14e0",
                        "size": 951
                    },
                    {
                        "created": 1698745124.6314185,
                        "modified": 1698745124.6314185,
                        "name": "/dev/shm/incoming/2023_10_31_093824_00_01_V_036.jpg",
                        "sha256": "7b54eba7d52eb2f3d571cf49be5f0fd9759e75c7e226012dede8b079ddaeff75",
                        "size": 1413620
                    },
                    {
                        "created": 1698745112.4714186,
                        "modified": 1698745112.4714186,
                        "name": "/dev/shm/incoming/2023_10_31_093807_00_01_V_035.txt",
                        "sha256": "646c97935750e70d99da0ef0064b7ee40e1921bc0b5d60783c64bd667c7a50f4",
                        "size": 2035
                    },
                    {
                        "created": 1698745112.3714185,
                        "modified": 1698745112.3714185,
                        "name": "/dev/shm/incoming/2023_10_31_093807_00_01_V_035.kml",
                        "sha256": "9d83ed28b5ae213d92339b2bf0afd1867cf08811bde14901ac582f7f210a34be",
                        "size": 952
                    },
                    {
                        "created": 1698745111.5214186,
                        "modified": 1698745111.5214186,
                        "name": "/dev/shm/incoming/2023_10_31_093807_00_01_V_035.jpg",
                        "sha256": "2338f134a4b16a6c36241479a38289e47d7707572874c270ed4e5aaf226fc422",
                        "size": 1340965
                    },
                    {
                        "created": 1698745100.2014184,
                        "modified": 1698745100.2014184,
                        "name": "/dev/shm/incoming/2023_10_31_093803_00_01_V_034.txt",
                        "sha256": "6790d6eabd17b8b85e76fd9c3da0055eddaddc43cb1062c1f90032e1bad1212a",
                        "size": 2036
                    },
                    {
                        "created": 1698745100.0614185,
                        "modified": 1698745100.0614185,
                        "name": "/dev/shm/incoming/2023_10_31_093803_00_01_V_034.kml",
                        "sha256": "8326e419479fe3d190b7a241d0388a187796310d8374f788a44420d3df5d8df3",
                        "size": 949
                    },
                    {
                        "created": 1698745099.3114185,
                        "modified": 1698745099.3114185,
                        "name": "/dev/shm/incoming/2023_10_31_093803_00_01_V_034.jpg",
                        "sha256": "d09b78569c2dbbe61d4df3fa8d289129a80c969c2e1f55a2dfbbd9cebe6e1833",
                        "size": 1353231
                    },
                    {
                        "created": 1698745067.5814185,
                        "modified": 1698745067.5814185,
                        "name": "/dev/shm/incoming/2023_10_31_093724_00_01_V_033.txt",
                        "sha256": "3f9159a1228536f7229dcd0a38e21495535eeff0e1ae06ce965f87966c82fbcd",
                        "size": 2037
                    },
                    {
                        "created": 1698745067.4814186,
                        "modified": 1698745067.4814186,
                        "name": "/dev/shm/incoming/2023_10_31_093724_00_01_V_033.kml",
                        "sha256": "ef2deebf5499a79dc10afbd690986022f3e229ea1c396d0273e1daba8e761c85",
                        "size": 953
                    },
                    {
                        "created": 1698745066.8014185,
                        "modified": 1698745066.8014185,
                        "name": "/dev/shm/incoming/2023_10_31_093724_00_01_V_033.jpg",
                        "sha256": "9a60be2b016fb5d571c77c23f6d7ee79045abf6dc5bb77f784a6c79470d13894",
                        "size": 1344469
                    },
                    {
                        "created": 1698745013.3414185,
                        "modified": 1698745013.3414185,
                        "name": "/dev/shm/incoming/2023_10_31_093633_00_01_V_032.txt",
                        "sha256": "37079c829d976c5e77e23a40f3419f2e01f3af8985344c4a58bda1af589ef53d",
                        "size": 2035
                    },
                    {
                        "created": 1698745012.7414186,
                        "modified": 1698745012.7414186,
                        "name": "/dev/shm/incoming/2023_10_31_093633_00_01_V_032.kml",
                        "sha256": "70b047ea36bf97fac29560135961662b837f8bf14e9884be58c1d6ed0dce43bc",
                        "size": 952
                    },
                    {
                        "created": 1698745012.6514184,
                        "modified": 1698745012.6514184,
                        "name": "/dev/shm/incoming/2023_10_31_093633_00_01_V_032.jpg",
                        "sha256": "499db267766a8721c011c46e7f3d95c715034da0f74dcb4269af05039c9bda02",
                        "size": 1281014
                    },
                    {
                        "created": 1698745011.7014184,
                        "modified": 1698745011.7014184,
                        "name": "/dev/shm/incoming/2023_10_31_093630_00_01_V_031.txt",
                        "sha256": "323f06e3daa2caa3929f0ae3ff6f10517099a6eab346f87bfdbd9598910d78c4",
                        "size": 2035
                    },
                    {
                        "created": 1698745011.5714185,
                        "modified": 1698745011.5714185,
                        "name": "/dev/shm/incoming/2023_10_31_093630_00_01_V_031.kml",
                        "sha256": "c6ab63c755cac76c35b7400adc77da08ce8af5981264ad1a140cb46dcdc73789",
                        "size": 952
                    },
                    {
                        "created": 1698745011.0914185,
                        "modified": 1698745011.0914185,
                        "name": "/dev/shm/incoming/2023_10_31_093630_00_01_V_031.jpg",
                        "sha256": "4d9dafbd9ce4f30f3ca838b57d7830a009fc67a47be76279b404455d4387e652",
                        "size": 1344322
                    },
                    {
                        "created": 1698745151.7714186,
                        "modified": 1698745151.7714186,
                        "name": "/dev/shm/incoming/2023-10-31T09:11:00.ts",
                        "sha256": "99d5f81b4a25039c941982f650cf9d8e5f7d923ec62bb6cc3dfa31a26bb18f7c",
                        "size": 385588188
                    }
                ],
                "mission": "OSP1",
                "sha256": "fbf898ce046c181c759776c773a35f563c5be764ac9a847903a7d4c238e7f013",
                "size": 380063388
            },
            "errors": "",
            "filename": "2023-10-31T09-39-22Z",
            "size": 380063388,
            "verified_at": 1698766648
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698745533,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "OxyrOL9CjbugCzDTeGfy32tQOt8uLpxiuUliHZmL31y2OWVyorvZuj80JyjUeT6QdYEAwGDVJikvArdUlZQGbsrschZJ++T9cquDT+yfvpR/Y+DUh8gGiN89RAeRGhmvueF1sYHbe21+9xRrREZML670qb7FslaZ5po5MNsYqgRb21hATj5KASagvMVBctQ9Ha+t1t9SPHhqZmrqU9lN9J60Ft/whQK2iQV56WSvgq2SJDEOXIRsQEHBJOGwBtSju7mvsowt70sHyqxMDIGk3l8CGWAnMbYODMrkIwomVG2WGNSGycj2N0A/b1TMg0PV2JSGk7IOehomxpSpblgLl/YNeBsIAwIKdvv8dq90dlTeEu2ylzW6W4no0w7diNM+46KdByICFSQPT01KwCRfMjKJeg4LYzQ/VNiqeJn4wtgSu2cchgxhwqNMdziNNE9KbpowJv8DktGEhBQOHMY0dfWYjlKgK8OEqKuSb6peUAuaikzLAlDkSHPLCTW6jzeu3NKsvGWwdSXBlVyPD0FBUeWQaOzoXVLrmW302wlQ4ADHxRIO1q0ADoJrKQkofgUHPIKpYfwNjsd4hs2Xh+S4Bee4d3EhyzQpItpuL8gYyWgkF1k0usL3o4+eNZyqCv+OIIroyKpypLKr/PAwHBMrZ3DH9vQZMGGK500rMEqkFyI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-44-45Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698745359.66126,
                        "modified": 1698745359.66126,
                        "name": "/dev/shm/incoming/2023-10-31T09:32:36.ais",
                        "sha256": "ad3e50066b6dedabcda7972795a4179801d34ab0d45fb3fd722f527d0fb7a268",
                        "size": 188107
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "1c946eeca968ae37964926e9bd557723efc8b636239ff0d1c0c37722d6d09817",
                "size": 53066
            },
            "errors": "",
            "filename": "2023-10-31T09-44-45Z",
            "size": 53066,
            "verified_at": 1698767543
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698745511,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "PbFCVYmKzSkOamJ4vO1Wz6jnUQ7haMbw3fmD5vvSh2M9xvpeRKnN9fltpvL4zBHFVjD5lr3fWRM46ik60ZTkFMWhwE8o/bCJ734NcXdKxApt+KgF63R4yjpUJuHwFSXEz6sb+r6XYL2+Bf+o3zvsZiDtFHkpa/MKGkTWpOn6/nFR58EE8WjWE63uglV2NWfPfD8dtSsUK0ybed1bWwQoawiSmu1IJrbgpD7v3fLCOTfdmvLCI6OdAc2DS2vOPsurVpagsd+znTQ/4G5FvH6buGBpbNsF56PPtY77kra78tFWKwG6TA85+NnQ0+n+Qj4qnZFVWgWvuwc3z4TXn0ZOOK1nTxPuIMycOB5tASEYeuoamHGyORs6KpttnpaQQ3taALM1NH+nf/v4Yut2syl6RZrOofVwJVLa8EZGEFWigz+lXzN3ZefP6fxS2jICsZxQb0yXOuoCDzhTjFGoDVMUB0gq2QUOO3UaJkZJkxtG4OwBDj42p9UeyX9KeGEVI83DFr3VADn0bMSvJ1XsI2muUR3hjy92ioIuScW7KiRPQZ4/ieM6oNksI1BtFqgDfBiidtxQ6ZdD2k4flJltHi/Ket/KEYVQ00e2Kwzn9JIqbUEbOVM8S+POjNmyP14lEln5+9EV/S1KHSDPU5l5di988tqdJOoOjMoEbi9X2vEofh0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-45-08Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698745385.504362,
                        "modified": 1698745385.504362,
                        "name": "/dev/shm/incoming/2023-10-31T09:32:59.ais",
                        "sha256": "55103d4be39541bc2e5c26c774568f49b04103852aadc10a6bb320f88e1f6ae1",
                        "size": 128422
                    }
                ],
                "mission": "EFCA1",
                "sha256": "5960f31298b653ccdedf70a7bc31a2cec7d8d198a08a367318e2ab721b19e2e6",
                "size": 24173
            },
            "errors": "",
            "filename": "2023-10-31T09-45-08Z",
            "size": 24173,
            "verified_at": 1698781979
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698745569,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "qGgnUIvHzNFnPmdOzeVQBc8mRt0ypxIul9UzoR4cqHQYegTFNhEMNyqtS7VwD/frX9Kop++AK2OpqyfUbrzrXFtB+NIWBbHT3oYiZEvFagNU5QWaqY6f3NrBrwyMSlzv2UxNPtWOsgRaJNRjNlo4J28uTqNNt4D30BDtILbTuUiCD36PXStpgxEVrnEx037FSMyOJiPYpiJynlXaaBZ3TRIZtYs+H2UHgOL8tqZsGsWcq69lNCxZsGG4vWUYVsKNBu7ALGuRAAw7vuriM13y2dfbtPQV4XRihVw3bm9ux3TKhGH/7+frx6bu0YaEOoNEaV64UugLbXclo4lMx9eTvKW/KNv5r262Oi7u7j/E6ARmTe0R7/CD/hXRWIlVqyql3U5xx9M4cJLMp32bNnHIw3rrEaXNN70xrC495Ks4paAxFc6Ehyn785kzPJldbCyLfpI/k92+5+QgSYqIbBnSF4E+FR3e7Dd4sTm5CgCRZLNkgHKqsuyV5wehvkrWsdSXbMqBHsdDdi28ntNuGPMvjCHGKRA54Xw49geQ99OpjylNWZD35Gprivwd4EPRKit5LjHLU6vK5PbS5294xcxeFE1mQbQWJ3wi6ZZhiM/VQFk/CH9XcUdy/MMJH6CJeeen4ZADsZO+MZ+m5QGtp3BoyEekL8u76gPVaL0UWQp6vU8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-46-07Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698745439.139791,
                        "modified": 1698745439.139791,
                        "name": "/dev/shm/incoming/2023-10-31T09:33:58.ais",
                        "sha256": "aae08734c4fed108c284c678b61e4572ae5107a86b6ee6bd1f9ebab96f0c3f88",
                        "size": 749908
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "73b62e0ede8b0412478f1de476ca015dbe217a1861fe336a745665b39438a06b",
                "size": 235225
            },
            "errors": "",
            "filename": "2023-10-31T09-46-07Z",
            "size": 235225,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698746168,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "izWe/B7jkTgSMGvja7L82GdQ7E46LdhKgeBlIAT+xdjwci45TEB70jCjHJdh4Jzjt94b1Jjtb42EAm/kZGlvKFGaWcXMw72BFHPoQep5Msr+dNlXMfF0zOKid79gTMsj5dycAMpoZa4Yw39b8Ki+LnszLDa8sOGdXzlFMSpcnaDO0VF0dVRXYzzyPlmZ42xe8LjDQf3nmLo6ROqpvMfxNAexN/vgdbGO8mH0/eBcbzFxmgnXUDlIlLiyMmCTR6PBItPPgsb/PqwfqlRENKh4+sS4m8ZlLl7dNTSdNgFy2/Ph6Da4Cn9ZUBlNU314TVz1Kj2NOYVZfGFzIpSWkvVIj5R7K4SY+FocEWDuzMtOvk58SDq23kTSyMhowAexBu/hiB4bFSkb9BwiUkHPRxHpDVTix2R5XmkZy08+8fJOy/3GethxN0Q02GiEfazAAqiMtwLg+8UkiRe1s2YeAehLUzhtlWQDrmmgKMoH3sJaplRPBReZdP6tX8whny0Huuu4pPqGGUesA6CcMTGpUZ3n4R/tg1OiFIAHlO4I9t1kGRqydPPFcOKy1SN27zjJPF2VySpULUS5r6sIw+EOcO9h4pMYG61hSmLizBrQPmyWLdB1kpRGuguzqBXFlLGwshxYX3WC/XHROD1ZMF+AY9mY6VQeSZxOfX4DDW8K2uvws04=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-56-05Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698746040.009791,
                        "modified": 1698746040.009791,
                        "name": "/dev/shm/incoming/2023-10-31T09:43:59.ais",
                        "sha256": "fa448009bfbba70d07ad16a2f5c3d8ff3ba97e5164cc4d4a1d8c0d0128f9d0f7",
                        "size": 718832
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "37b86304b20b64f6b033d4ac2049cd7dfc5a4101cedf522febda5a308b9e072d",
                "size": 217806
            },
            "errors": "",
            "filename": "2023-10-31T09-56-05Z",
            "size": 217806,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698745886,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "MYWRsdGYnVoDaCnFS6TbZJlc59jdbkjzwJ1TLViNH1iIq8dhnwqpRDZxdKtlSuBLq66+FCTGkPzPgkVQE2yOUPY6YUMA/+/b0eywmWx+mMoJaOQVXjXuyhOcDYpTvHiitaMkKz6pprtDv8fRuoursBto2p9+fPTwHT3q8CBq9PC730dO0J/Xq/UirvSRkkHfFrGq1s0SQucnfW57e4is+JcybX9eWrng57mZYmMnzV92deyLjUEeM3Wk9ANXN1ER2mICWxoT2947LOEv3KFWTAo2XnC14qAAoOndsvC76ixyRQg1y6r90YEKdK4D/sY6Kn+UIx4vwkneNUaT44ibGHXrCF158d6x3CcHy/w3hJgcIBd1twaICL/4qL84fxXEOuzz7r9XniiMjAf8jy1jp+J/JDsvHrbeFAZKVDqidXEeDy5zx39PQtr4elOzsdnjWdvYzvlj7Ruq6oEvxAf+ROtQOtCNWKas2Si8/OPkkurxku9usGV6erKozpp/ODIrEWH2nnZXd3M7uRQzCs0rK92vneH24HxbjfLcWs7RJZBJmsyHcKCihU5HSe6SLdVNni2LBxOdZpIhakPHVjbggV6f3R6a7hvdZ8oT9rtZt2y9nk+W6CQ8wlTIdHJWNV0BnWUVcm/l2k3RkD6G/TA9yeOW+i56CNMOMSy0VRDE5mo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-51-15Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698745748.0314186,
                        "modified": 1698745748.0314186,
                        "name": "/dev/shm/incoming/2023-10-31T09:39:07.ais",
                        "sha256": "e9c1fa4af1e2d5e1932bcd3793406ec5ef495abf07cc55de20206fa2ae15d900",
                        "size": 273910
                    },
                    {
                        "created": 1698745664.3614185,
                        "modified": 1698745664.3614185,
                        "name": "/dev/shm/incoming/2023_10_31_094729_00_01_V_045.txt",
                        "sha256": "8953dd203ce1f4e09e0b07cfc0b73f7eadef09477bd7e134ca24ae2b939ba040",
                        "size": 2034
                    },
                    {
                        "created": 1698745664.1714184,
                        "modified": 1698745664.1714184,
                        "name": "/dev/shm/incoming/2023_10_31_094729_00_01_V_045.kml",
                        "sha256": "42140a6e8fecfd26ef7887a21ddb4eb84628fbe5817d71025989405dd1342845",
                        "size": 952
                    },
                    {
                        "created": 1698745664.0214186,
                        "modified": 1698745664.0214186,
                        "name": "/dev/shm/incoming/2023_10_31_094729_00_01_V_045.jpg",
                        "sha256": "7accc7d97a5f939b99218f323c9ab8d794a53fef12c6b5cb8bb4688308a43d95",
                        "size": 1436485
                    },
                    {
                        "created": 1698745663.3114185,
                        "modified": 1698745663.3114185,
                        "name": "/dev/shm/incoming/2023_10_31_094722_00_01_V_044.txt",
                        "sha256": "4440602cd837bc00aa2ad14ebf92f2e3030c285aad8a897a5956431b78f76dae",
                        "size": 2039
                    },
                    {
                        "created": 1698745663.1114185,
                        "modified": 1698745663.1114185,
                        "name": "/dev/shm/incoming/2023_10_31_094722_00_01_V_044.kml",
                        "sha256": "d38446d35f8868afa79207ab728a634a4dfe3c5a8dd16876a5d82f1e0696d7f1",
                        "size": 952
                    },
                    {
                        "created": 1698745662.9614184,
                        "modified": 1698745662.9614184,
                        "name": "/dev/shm/incoming/2023_10_31_094722_00_01_V_044.jpg",
                        "sha256": "84b1b7f5497b5cd32bd776b46494e13b2ed992f0a085cac23bb7a89e4ec84068",
                        "size": 1408848
                    }
                ],
                "mission": "OSP1",
                "sha256": "ef8e11050a820051921c5e3f73bbfc703ec6d13b5ed3ace359acce30105c4d57",
                "size": 2914393
            },
            "errors": "",
            "filename": "2023-10-31T09-51-15Z",
            "size": 2914393,
            "verified_at": 1698766717
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698746397,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "vfdPPmQSNkimxBhZLP8TdLMuCE5jtTuXdg/qY0FvyRl5M9SHmATbdViX/dGQfX4xpWqN5A7N3oJQs9aKOkjTH9C/qrdHnZoWfEyxTttypwDmnoRCE16QdnEVvipQuASujIs6GI6QyDmFpoXBKfTtEUHbHtqIfh084ftr2CMIS2wmjEqE3auudI3cfHtXyIsEsPtpSJpHK03uuo+M+w0DkpwKmstiBqFc4I/UtxA9sETttnTykIfn2xMR1E8ixr97+QaZ792WBJEus8bZROUgAQDBIEb9OxpGRgzGoX7d5yB9/Ua2y3j+yeZl3//P9gsiOutWy4A7LDfs6ODXIduTIa3k3dlpcQtT50eGEfkDEi8XIWIjT5Gby/iJOJ4bWpc+1bJghTnrwBhkzWd0bSld7eVZjtW1rOiQ+gKYDcU5/JPIKgLbxHiu0Rtcf/3zU+ScWy2EMa0g1RbSVUwsh4gsBxQFCJH1nSZYVrqxHfEm9jL3u5b4W5pchoFXsIc+Hkk2LxNMApZ1DQZSxygsOuK/hLAR3zE/LYz9ByjunKqnWZl+4JpXw8wm4A0TzxH9w2DZ3GGc9LobvSQWd8MZj4gbt+WlBYC1oQYYhTspmfuQlSRfuxyb/Ca7PR81KyYj74NBlLKONpiqiECgPJvJnKhYAh7kZzeNnuVVKsHsKPYvKLg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-59-37Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698746262.18126,
                        "modified": 1698746262.18126,
                        "name": "/dev/shm/incoming/2023_10_31_095715_00_00_K_100.txt",
                        "sha256": "7c9218f2de493a66039e75e8a53eefc5c5b997532a757ff7645774397448f3b9",
                        "size": 2044
                    },
                    {
                        "created": 1698746256.63126,
                        "modified": 1698746256.63126,
                        "name": "/dev/shm/incoming/2023_10_31_095715_00_00_K_100.kml",
                        "sha256": "5a79135ecfd3c796dd3ea820708f6b3c458048be14d115c0ac3bec709a5273c7",
                        "size": 976
                    },
                    {
                        "created": 1698746265.75126,
                        "modified": 1698746265.75126,
                        "name": "/dev/shm/incoming/2023_10_31_095715_00_00_K_100.jpg",
                        "sha256": "c386459c31f33047059f7fb51c50579729209fb39f20ce4677c8da22da683521",
                        "size": 1423911
                    },
                    {
                        "created": 1698746244.93126,
                        "modified": 1698746244.93126,
                        "name": "/dev/shm/incoming/2023_10_31_095653_00_00_K_099.txt",
                        "sha256": "e95cbe4bcb2a24b6c8c9e713f43c22fae8ed5b15aa6f173143d5868d9f636398",
                        "size": 2044
                    },
                    {
                        "created": 1698746247.77126,
                        "modified": 1698746247.77126,
                        "name": "/dev/shm/incoming/2023_10_31_095653_00_00_K_099.kml",
                        "sha256": "6b08ce3802b141700d0b9086c38e2476111c7600fb8c8759cf24aaa793c9be97",
                        "size": 975
                    },
                    {
                        "created": 1698746242.10126,
                        "modified": 1698746242.10126,
                        "name": "/dev/shm/incoming/2023_10_31_095653_00_00_K_099.jpg",
                        "sha256": "115827d3d273b7303380f7d85c6667bca744576514e4b93445fc4a03db7d395f",
                        "size": 1090340
                    },
                    {
                        "created": 1698746215.76126,
                        "modified": 1698746215.76126,
                        "name": "/dev/shm/incoming/2023_10_31_095636_00_00_K_098.txt",
                        "sha256": "f3041b258ad9178818e62614ce791243a6be1a6fc25bcaa62239b1c202eb0efb",
                        "size": 2044
                    },
                    {
                        "created": 1698746224.89126,
                        "modified": 1698746224.89126,
                        "name": "/dev/shm/incoming/2023_10_31_095636_00_00_K_098.kml",
                        "sha256": "11cada72856d2513899ed49f91a35f04c90e3b37c5a4933551ee23c1f64c961d",
                        "size": 971
                    },
                    {
                        "created": 1698746214.78126,
                        "modified": 1698746214.78126,
                        "name": "/dev/shm/incoming/2023_10_31_095636_00_00_K_098.jpg",
                        "sha256": "9ce2b7e4efa8203b338cdc786786fc26abbfa89393d44c4c60974592ba689cfa",
                        "size": 1208748
                    },
                    {
                        "created": 1698746214.16126,
                        "modified": 1698746214.16126,
                        "name": "/dev/shm/incoming/2023_10_31_095628_00_00_K_097.txt",
                        "sha256": "37d49a20142262b4f67c73362caee95c2120448fe2dcd36c02dcde2c0d30b159",
                        "size": 2045
                    },
                    {
                        "created": 1698746214.71126,
                        "modified": 1698746214.71126,
                        "name": "/dev/shm/incoming/2023_10_31_095628_00_00_K_097.kml",
                        "sha256": "de6da9a98d0db915723ab4070230ca6ab27e9ef4ee76ca9e893fefd47bf09d61",
                        "size": 977
                    },
                    {
                        "created": 1698746213.27126,
                        "modified": 1698746213.27126,
                        "name": "/dev/shm/incoming/2023_10_31_095628_00_00_K_097.jpg",
                        "sha256": "5395b309ca0fe013988201dc8d7fc0f096892b78ac64e44252c2ebe2fc982082",
                        "size": 977323
                    },
                    {
                        "created": 1698746212.58126,
                        "modified": 1698746212.58126,
                        "name": "/dev/shm/incoming/2023_10_31_095622_00_00_K_096.txt",
                        "sha256": "3f087f5ba52e3b20f3f5a96639d23b4faafcb13b1e0db9ef1d59a3c40f0791be",
                        "size": 2044
                    },
                    {
                        "created": 1698746201.42126,
                        "modified": 1698746201.42126,
                        "name": "/dev/shm/incoming/2023_10_31_095622_00_00_K_096.kml",
                        "sha256": "cd1801ecb5680f8af632879c0b16792916cc37e5bfbb628c23e34e92a4e5a0fa",
                        "size": 974
                    },
                    {
                        "created": 1698746200.82126,
                        "modified": 1698746200.82126,
                        "name": "/dev/shm/incoming/2023_10_31_095622_00_00_K_096.jpg",
                        "sha256": "316398f1bd62e28fa6122338b4e1253fce69850907f56508006611cbc4491ff2",
                        "size": 937689
                    },
                    {
                        "created": 1698746135.04126,
                        "modified": 1698746135.04126,
                        "name": "/dev/shm/incoming/2023_10_31_095514_00_00_K_095.txt",
                        "sha256": "b9f41bab82e125488958e3f1f81bfb1b33ff13aa57efec584ff04699f29fbb85",
                        "size": 2042
                    },
                    {
                        "created": 1698746145.57126,
                        "modified": 1698746145.57126,
                        "name": "/dev/shm/incoming/2023_10_31_095514_00_00_K_095.kml",
                        "sha256": "fefd828c8737fc8fd34af6043a41a09811cc8a35c69f857b545d2f10e04ea13a",
                        "size": 974
                    },
                    {
                        "created": 1698746140.02126,
                        "modified": 1698746140.02126,
                        "name": "/dev/shm/incoming/2023_10_31_095514_00_00_K_095.jpg",
                        "sha256": "91726685d38dd924ca551fe363b3cd22410af9b34a047543e25c3d4e9afab084",
                        "size": 1050371
                    },
                    {
                        "created": 1698746124.85126,
                        "modified": 1698746124.85126,
                        "name": "/dev/shm/incoming/2023_10_31_095457_00_00_K_094.txt",
                        "sha256": "442db6996739d137895e227576d63129e675f00f7fecf37acd4cc8fc48f9cb84",
                        "size": 2042
                    },
                    {
                        "created": 1698746129.54126,
                        "modified": 1698746129.54126,
                        "name": "/dev/shm/incoming/2023_10_31_095457_00_00_K_094.kml",
                        "sha256": "21dd32b41119a522bcd36aecec0937ea3e192b38b46c5d58999011d15db3444a",
                        "size": 979
                    },
                    {
                        "created": 1698746119.53126,
                        "modified": 1698746119.53126,
                        "name": "/dev/shm/incoming/2023_10_31_095457_00_00_K_094.jpg",
                        "sha256": "a6a599f7907f4f0a25642dd6bdb90148424320ae2edb38a375465c878767dca8",
                        "size": 970191
                    },
                    {
                        "created": 1698746118.46126,
                        "modified": 1698746118.46126,
                        "name": "/dev/shm/incoming/2023_10_31_095457_00_00_K_093.txt",
                        "sha256": "3562d98e59189f2127d815d22c9dc6a188636d85c77b7cc3731e166534fa9f94",
                        "size": 2044
                    },
                    {
                        "created": 1698746124.32126,
                        "modified": 1698746124.32126,
                        "name": "/dev/shm/incoming/2023_10_31_095457_00_00_K_093.kml",
                        "sha256": "b2159df41d5d9c54de5b6ef2ad4f62e8fc48c4e95e767ca7c7392b78881b6502",
                        "size": 977
                    },
                    {
                        "created": 1698746118.89126,
                        "modified": 1698746118.89126,
                        "name": "/dev/shm/incoming/2023_10_31_095457_00_00_K_093.jpg",
                        "sha256": "af4071bb9764bd09e3d7e93760ad4c35e7176f7cb7984e01285609e61980a602",
                        "size": 992247
                    },
                    {
                        "created": 1698746115.16126,
                        "modified": 1698746115.16126,
                        "name": "/dev/shm/incoming/2023_10_31_095435_00_00_K_092.txt",
                        "sha256": "40c7d86294e30f4549103a9952babc3852748eb9a48ae65287795b3afbef7675",
                        "size": 2044
                    },
                    {
                        "created": 1698746094.46126,
                        "modified": 1698746094.46126,
                        "name": "/dev/shm/incoming/2023_10_31_095435_00_00_K_092.kml",
                        "sha256": "5ae5999c089fe21389cd50bb4a81b0d68e0e05fb4daf7a8075bad08b71b219ec",
                        "size": 975
                    },
                    {
                        "created": 1698746093.69126,
                        "modified": 1698746093.69126,
                        "name": "/dev/shm/incoming/2023_10_31_095435_00_00_K_092.jpg",
                        "sha256": "7679b8eec4a22317ac0b2a881ed09a9ba75021e11731bc496851870fd9ae9451",
                        "size": 579473
                    },
                    {
                        "created": 1698746038.56126,
                        "modified": 1698746038.56126,
                        "name": "/dev/shm/incoming/2023_10_31_095335_00_00_K_091.txt",
                        "sha256": "cc2c1086e01aa9af49d2f99d9141992e6de56bd4a4bde77617a54c02a5954ffd",
                        "size": 2043
                    },
                    {
                        "created": 1698746040.62126,
                        "modified": 1698746040.62126,
                        "name": "/dev/shm/incoming/2023_10_31_095335_00_00_K_091.kml",
                        "sha256": "5c5e0404816c545bdda791a959a67125bf132180e5c77d0dea28427a3d1d8b21",
                        "size": 975
                    },
                    {
                        "created": 1698746041.04126,
                        "modified": 1698746041.04126,
                        "name": "/dev/shm/incoming/2023_10_31_095335_00_00_K_091.jpg",
                        "sha256": "d959c7da0ada84f96c75424745972512c3266ba2e17698a85beb9b956359ebb4",
                        "size": 984801
                    },
                    {
                        "created": 1698746014.89126,
                        "modified": 1698746014.89126,
                        "name": "/dev/shm/incoming/2023_10_31_095315_00_00_K_090.txt",
                        "sha256": "6de0054c793972bff5433fb01bb6403e57d9e25e81d16a717119528b443865e6",
                        "size": 2044
                    },
                    {
                        "created": 1698746017.85126,
                        "modified": 1698746017.85126,
                        "name": "/dev/shm/incoming/2023_10_31_095315_00_00_K_090.kml",
                        "sha256": "52f5bf9a73e27960653095a10368da252fde76e4d4ee7a4240f9cb18358a16ca",
                        "size": 977
                    },
                    {
                        "created": 1698746012.77126,
                        "modified": 1698746012.77126,
                        "name": "/dev/shm/incoming/2023_10_31_095315_00_00_K_090.jpg",
                        "sha256": "ac144ecd01abb15583490f65b7013ef2a7ce0e85487b8f639e7c3c38583ef974",
                        "size": 1388296
                    },
                    {
                        "created": 1698746004.85126,
                        "modified": 1698746004.85126,
                        "name": "/dev/shm/incoming/2023_10_31_095256_00_00_K_089.txt",
                        "sha256": "459805276cb349a9fa3755167b34bacc44ef94288c471f2d4f3ede90117971bb",
                        "size": 2044
                    },
                    {
                        "created": 1698746005.73126,
                        "modified": 1698746005.73126,
                        "name": "/dev/shm/incoming/2023_10_31_095256_00_00_K_089.kml",
                        "sha256": "f6e191912767d473f088937ef92f62454953aece9a85a59b1bf66f96aa8b1f51",
                        "size": 976
                    },
                    {
                        "created": 1698746009.45126,
                        "modified": 1698746009.45126,
                        "name": "/dev/shm/incoming/2023_10_31_095256_00_00_K_089.jpg",
                        "sha256": "54d5d831fbe925f614afa894a048502e129ce6284f96b64aff00ac0db8258d06",
                        "size": 564437
                    },
                    {
                        "created": 1698745998.62126,
                        "modified": 1698745998.62126,
                        "name": "/dev/shm/incoming/2023_10_31_095243_00_00_K_088.txt",
                        "sha256": "babcd30cbe310bbdf9614b4acd71533baaa0b77e73a948e39c19b104c5cc7cc6",
                        "size": 2044
                    },
                    {
                        "created": 1698745997.27126,
                        "modified": 1698745997.27126,
                        "name": "/dev/shm/incoming/2023_10_31_095243_00_00_K_088.kml",
                        "sha256": "d62b1f4783093164194e17ee82cfda676fb776ba43a5093cf3a49dcedcc30270",
                        "size": 977
                    },
                    {
                        "created": 1698745996.08126,
                        "modified": 1698745996.08126,
                        "name": "/dev/shm/incoming/2023_10_31_095243_00_00_K_088.jpg",
                        "sha256": "652e1647cd78d442fbb0bb03027a7170b3c1969a9c3e5820f246cca832f6aa12",
                        "size": 676333
                    },
                    {
                        "created": 1698745975.06126,
                        "modified": 1698745975.06126,
                        "name": "/dev/shm/incoming/2023_10_31_095226_00_00_K_087.txt",
                        "sha256": "26768da9e5c6b3eb129f7775bc99c1f52e32435893dd12544e8890a90119c728",
                        "size": 2044
                    },
                    {
                        "created": 1698745972.40126,
                        "modified": 1698745972.40126,
                        "name": "/dev/shm/incoming/2023_10_31_095226_00_00_K_087.kml",
                        "sha256": "843f223177ae50a5eaf635a4e741b80ca5b99d5f9f2677832fb15d9caa0fe6b3",
                        "size": 976
                    },
                    {
                        "created": 1698745967.80126,
                        "modified": 1698745967.80126,
                        "name": "/dev/shm/incoming/2023_10_31_095226_00_00_K_087.jpg",
                        "sha256": "7820467cd3b2ba1176bce27287255c46eb41dc82d3bc1f911ff49b93204ecc1a",
                        "size": 1143545
                    },
                    {
                        "created": 1698745961.74126,
                        "modified": 1698745961.74126,
                        "name": "/dev/shm/incoming/2023_10_31_095219_00_00_K_086.txt",
                        "sha256": "d5fc1768e9322c50aad621e51704c791f1d7c7a6787dc1de2cb1ace2aba05ff4",
                        "size": 2044
                    },
                    {
                        "created": 1698745961.39126,
                        "modified": 1698745961.39126,
                        "name": "/dev/shm/incoming/2023-10-31T09:42:39.ais",
                        "sha256": "d6fe7542d1c774e454a827e7915b8e6355b97b57ec4d37cd0b7939f5eae7a24b",
                        "size": 278747
                    },
                    {
                        "created": 1698745961.26126,
                        "modified": 1698745961.26126,
                        "name": "/dev/shm/incoming/2023_10_31_095219_00_00_K_086.kml",
                        "sha256": "107b6b78a81150a26927526c7eb0b75c4377499fecfac5bd350bd83181ae25be",
                        "size": 977
                    },
                    {
                        "created": 1698745960.70126,
                        "modified": 1698745960.70126,
                        "name": "/dev/shm/incoming/2023_10_31_095219_00_00_K_086.jpg",
                        "sha256": "97dc974b61d49a7f5d47982ead1ba21d8fa8d44e41c6d308d2b467137b32633c",
                        "size": 1080907
                    },
                    {
                        "created": 1698745947.35126,
                        "modified": 1698745947.35126,
                        "name": "/dev/shm/incoming/2023_10_31_095201_00_00_K_085.txt",
                        "sha256": "ce9ed7acc7ea31f0ee2ed30300dadd484f4fb7daf71a0866a49260fd5b11669d",
                        "size": 2043
                    },
                    {
                        "created": 1698745939.61126,
                        "modified": 1698745939.61126,
                        "name": "/dev/shm/incoming/2023_10_31_095201_00_00_K_085.kml",
                        "sha256": "311227e41e41775c58b6ce20353959fdc0d131cba48f7b4fe0c210819dfc2f97",
                        "size": 978
                    },
                    {
                        "created": 1698745944.73126,
                        "modified": 1698745944.73126,
                        "name": "/dev/shm/incoming/2023_10_31_095201_00_00_K_085.jpg",
                        "sha256": "34ed5d1e4245ec87747a454349e6953303c360509ec73d2bd3f5f1d4499bbef5",
                        "size": 703606
                    },
                    {
                        "created": 1698745936.67126,
                        "modified": 1698745936.67126,
                        "name": "/dev/shm/incoming/2023_10_31_095155_00_00_K_084.txt",
                        "sha256": "8faddbbb899f3e1f60dfc67d19ceb7e2c3286d3811eb067ea4c214aca3ad2a84",
                        "size": 2044
                    },
                    {
                        "created": 1698745936.43126,
                        "modified": 1698745936.43126,
                        "name": "/dev/shm/incoming/2023_10_31_095155_00_00_K_084.kml",
                        "sha256": "283e8ea839c46c7f036e7e92739328d37ada11f13913ea7195ebb323ea4c49e3",
                        "size": 977
                    },
                    {
                        "created": 1698745935.78126,
                        "modified": 1698745935.78126,
                        "name": "/dev/shm/incoming/2023_10_31_095155_00_00_K_084.jpg",
                        "sha256": "2040b52f9d20927289f40cc7cdcaee5c9f27d2fd720323068e34da707bfdfe75",
                        "size": 741185
                    },
                    {
                        "created": 1698745919.43126,
                        "modified": 1698745919.43126,
                        "name": "/dev/shm/incoming/2023_10_31_095128_00_00_K_083.txt",
                        "sha256": "4b4a7865a346449b716ad107085c56d6a941eeb013052435218302dd23fbe183",
                        "size": 2045
                    },
                    {
                        "created": 1698745914.60126,
                        "modified": 1698745914.60126,
                        "name": "/dev/shm/incoming/2023_10_31_095128_00_00_K_083.kml",
                        "sha256": "a7fd7f0cd0e744486589de03bd735dac43ce65d64cb9cc40783bfc5d95b9d2c2",
                        "size": 978
                    },
                    {
                        "created": 1698745911.94126,
                        "modified": 1698745911.94126,
                        "name": "/dev/shm/incoming/2023_10_31_095128_00_00_K_083.jpg",
                        "sha256": "6783ac453a932b91d7ba1bb993563df0931b27203059e617d97943f2c9cd581e",
                        "size": 1222102
                    },
                    {
                        "created": 1698745909.53126,
                        "modified": 1698745909.53126,
                        "name": "/dev/shm/incoming/2023_10_31_095118_00_00_K_082.txt",
                        "sha256": "c9b6913293710eff4812c5ac4e701bde657eb008254e1951ddea4c1621e5677e",
                        "size": 2044
                    },
                    {
                        "created": 1698745901.85126,
                        "modified": 1698745901.85126,
                        "name": "/dev/shm/incoming/2023_10_31_095118_00_00_K_082.kml",
                        "sha256": "4c9842390bdd70121d5264461e1b9fad6b6a023d57ac26d856b565d13c8fa4c1",
                        "size": 976
                    },
                    {
                        "created": 1698745910.01126,
                        "modified": 1698745910.01126,
                        "name": "/dev/shm/incoming/2023_10_31_095118_00_00_K_082.jpg",
                        "sha256": "688877391e7adb2be17ec59cae20a3bcd94a2fa5bb4e3339acc886703601cf1d",
                        "size": 1219543
                    },
                    {
                        "created": 1698745845.41126,
                        "modified": 1698745845.41126,
                        "name": "/dev/shm/incoming/2023_10_31_095021_00_00_K_081.txt",
                        "sha256": "be530bf913b9de9a2b2699263352dfdaabc89764b77d85f8d48dc64f0d54dfb3",
                        "size": 2040
                    },
                    {
                        "created": 1698745854.52126,
                        "modified": 1698745854.52126,
                        "name": "/dev/shm/incoming/2023_10_31_095021_00_00_K_081.kml",
                        "sha256": "fd1bfde85bfbe34426226704d104934bbd8eb4edf39c8c6388b56223da72d044",
                        "size": 976
                    },
                    {
                        "created": 1698745854.43126,
                        "modified": 1698745854.43126,
                        "name": "/dev/shm/incoming/2023_10_31_095021_00_00_K_081.jpg",
                        "sha256": "438f3051816cc26e975e41feaafe6d3edc57b3cb0a536047665275b97de37bc6",
                        "size": 1182839
                    },
                    {
                        "created": 1698745778.93126,
                        "modified": 1698745778.93126,
                        "name": "/dev/shm/incoming/2023_10_31_094918_00_00_K_080.txt",
                        "sha256": "12e0c72be3b21b3c22e616d75fc542c951e1417245ccaa98c72aaf12210b9e1d",
                        "size": 2041
                    },
                    {
                        "created": 1698745773.24126,
                        "modified": 1698745773.24126,
                        "name": "/dev/shm/incoming/2023_10_31_094918_00_00_K_080.kml",
                        "sha256": "468205461eaf180d8eef221e950c101686a16127ab567c4e9725b5ddfd2878c9",
                        "size": 976
                    },
                    {
                        "created": 1698745773.94126,
                        "modified": 1698745773.94126,
                        "name": "/dev/shm/incoming/2023_10_31_094918_00_00_K_080.jpg",
                        "sha256": "eebc13cf05d732ddfcbc411cc7fa94e20e3890a4f1516427a8e0e9e800019a32",
                        "size": 1303932
                    },
                    {
                        "created": 1698745717.29126,
                        "modified": 1698745717.29126,
                        "name": "/dev/shm/incoming/2023_10_31_094818_00_00_K_079.txt",
                        "sha256": "4910c85d3ed31aa8b6e567734f4d551d2683e7408034e194bbe0d52d37055db9",
                        "size": 2038
                    },
                    {
                        "created": 1698745726.35126,
                        "modified": 1698745726.35126,
                        "name": "/dev/shm/incoming/2023_10_31_094818_00_00_K_079.kml",
                        "sha256": "ff7de33ac9c38a655cc475279aa359a95d5e55885c2356de425feb20c9f65d58",
                        "size": 973
                    },
                    {
                        "created": 1698745716.62126,
                        "modified": 1698745716.62126,
                        "name": "/dev/shm/incoming/2023_10_31_094818_00_00_K_079.jpg",
                        "sha256": "c29d273e2652c8e341a40fc60f6b1b5ee35ce367006f34f89c57887a3c3fc72f",
                        "size": 576229
                    },
                    {
                        "created": 1698745694.65126,
                        "modified": 1698745694.65126,
                        "name": "/dev/shm/incoming/2023_10_31_094754_00_00_K_078.txt",
                        "sha256": "63175bdbdb48244b1cf470bf35b9f71fe9975aa99034da3f9f606d51cef9d65b",
                        "size": 2038
                    },
                    {
                        "created": 1698745713.35126,
                        "modified": 1698745713.35126,
                        "name": "/dev/shm/incoming/2023_10_31_094754_00_00_K_078.kml",
                        "sha256": "31d7baf42ca43ee0318667697f8ae96af0012bf542b40b7fa360e1b7fbad7ef1",
                        "size": 979
                    },
                    {
                        "created": 1698745704.74126,
                        "modified": 1698745704.74126,
                        "name": "/dev/shm/incoming/2023_10_31_094754_00_00_K_078.jpg",
                        "sha256": "99eac298c7cd2006c9039eab9b9b223d032a8fc507d94d3a1c2db6828e6c4242",
                        "size": 592247
                    },
                    {
                        "created": 1698745691.90126,
                        "modified": 1698745691.90126,
                        "name": "/dev/shm/incoming/2023_10_31_094600_00_00_K_077.txt",
                        "sha256": "a8b95f5e7f38e06bba60341f58ebdb47f25fcdb93760faec75a85e00185a448a",
                        "size": 2042
                    },
                    {
                        "created": 1698745592.24126,
                        "modified": 1698745592.24126,
                        "name": "/dev/shm/incoming/2023_10_31_094600_00_00_K_077.kml",
                        "sha256": "eb74411e66c2c5bde20bb1c5b41280bb8d27089c1e5760cbdd937069086f17fa",
                        "size": 979
                    },
                    {
                        "created": 1698745592.01126,
                        "modified": 1698745592.01126,
                        "name": "/dev/shm/incoming/2023_10_31_094600_00_00_K_077.jpg",
                        "sha256": "099d2982ccb12890d2106dd2d2beb11eca5d5676613e01097c02b0a421b771bf",
                        "size": 578645
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "172749811fec8de3ed0ed0d6784210cb1c2f2f953724ad0030746a5ad01c37a9",
                "size": 23221310
            },
            "errors": "",
            "filename": "2023-10-31T09-59-37Z",
            "size": 23221310,
            "verified_at": 1698767675
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698746718,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "JqIYv/OCFniHwqoiSUinlyxBg3nzoLD4uuCZL+acblq6ZIXVoTTKe9IiTPyOoS0iG11lpMGk9odSDgYLIO9WA4d66P8YhRX5sG3v3xLnP0WJzzXEbwdzWhqi0qxMMOBZ8sZMOlNKu+nHZIlL/0Fuu8CpB+IjXAlqouUKX+ICVcRcIlpaiepGWBZlcnML3/R+3gYwwnuwchIQeF1ZlcQWB2UmPRJ32XBdoqChKH6R5GEEPgqA90wmy5zeZPaobROMcGa1G6uoByFgVoqK9Qz/qPztRC7s5n9riDzvCiO7Q47bpEkmADjlP4uc9rR7bTDqKXyizv79tXXwa2WKiNlwHgi7BVimahBsCcU+oRgnHL3ybobZHa4M8JolZU8aASzqgXAZ0fquEJQjsc5WUXMNM0Dq/TFXsLtDZqjxq6j0x/03UYDI3UkCRh274JbV8YTLcbYGaKm3nN6TOvRF1QfoI5NioWs+jJWyND35/Pq4D6rC8ue01D5rvKR90jbAnQi0AmuyPxrZNFQoaVkdT74HmN/xdGrxGWX/O55o9a1U9etbwB2ZbXNb6C+7ixS+6F3QmohUbso1jTTVg0FkSd7pXlGsjhrixi7p6CDTbI+c1mG34A6BhQVvvM79+ao3eUzvX5n+vt/47H3AT4KmhZtEWFiY8j7lP3C2+hOV2ZaFW+w=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-05-10Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698746587.244362,
                        "modified": 1698746587.244362,
                        "name": "/dev/shm/incoming/2023-10-31T09:53:06.ais",
                        "sha256": "dca3c772c51f13784557f8ad6fc4d0541542485be1b9945ccf3058ff0d730f6a",
                        "size": 121124
                    }
                ],
                "mission": "EFCA1",
                "sha256": "8ecdde1c141b9aedabdacc1aa83657fe64fb0caf9071cdcf8ec99dcbbbc9e8a1",
                "size": 22101
            },
            "errors": "",
            "filename": "2023-10-31T10-05-10Z",
            "size": 22101,
            "verified_at": 1698781938
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698746115,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "QKWsyxSHNZ92fHLhG8LNUem9MyOPtJMMc6EFeRGaUGR0AMkn45KWXbFkmqzD9Vyr0qGlFvwmvcaRdYFMeSJaipFDbJM2JD8P0L65Fu6ZMvGEbzXVAN0qJ4ygp3+UvQbb3/9xLIwe8lMu8kNxzArZV4cQVN9JQ68w+qoapAK5TcBg1lD2J23sRZUHNVmIKxM7Nn/BeZj2osuTwIxTLNacpOmltwLkEfW3egW8kHxLa6nIth+H+uw4WvqG9myNWgGgw9DUkrjGUTquSJNmuX3hDrc20xaZKyR0c/bPaWZOyerPTxYOpXbM6jIqg5kSZ48Uxzt7BMJnaE4r9/kd+ZIbKv8jOcUXH3l3tGXlokGIoYuLg6waGbbyl9+oxhr6SCi7ThFCHcrSyrvW4tBtEpAdQvrcZQQ66WJSARe1cXX6oClZ44jQAmZZESMnrE0P1m9y/tuPsNGIWF8eEsOX/zLJ0vXWrPjOwqVzHRw2AH/C7rTrE55KIcGbl6YhXycfmXKK66UhIu2sREhBLbzKfai2mirrJpw+fTTPsV72CSjWQ8UstqYr/tlNw3I3jRWXp48mQd1Wx62yKtrhkQ4AJhicOtq0ofNn4tVeyE4PJEnhCDGmtC4mRobDV0yhDuUf4IMU/TCvY5ThbvKpZ13dmJBwvODU5nDq2TKKCNZ1DbZJaqE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T09-55-12Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698745986.584362,
                        "modified": 1698745986.584362,
                        "name": "/dev/shm/incoming/2023-10-31T09:43:05.ais",
                        "sha256": "1b703f23e5cecb43a4fb80d9ec87d417fc27ef1c7a4970bf2be8b3b07ebaaeec",
                        "size": 105189
                    }
                ],
                "mission": "EFCA1",
                "sha256": "f05680de91e33cbfb98dac612daff0f3da81104b913803d39e848ac09ee45a88",
                "size": 19438
            },
            "errors": "",
            "filename": "2023-10-31T09-55-12Z",
            "size": 19438,
            "verified_at": 1698781940
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698746772,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "JCFNG7ADxVGEgbvQQePvw/Ci/7WRMxxQj2n8cN5XZEPBAt1slOfDyNSvOuIatD9nVM8TH6RxZBDd5wY/Yq2UD04KULWd3W+0iiaWdETKJYh9UlqoqcEkq2ldJJWuj7SepJUnRCVgYMgpvyChp2KWDd2/bi1HCqPiM6cp1vTG2myh6EJmY5pQL6XdJAmMLXi1ZiYom2yqT7p/bCJiGbuZZnkFyZ//U1Q+HglUtK+zKqtcUqpXzWUxaUCPSWChmn+HtRcoNwRn5w/rnibcWnU2Vhs8h2y2RmsshpgXd17o2mGx8rWjL3GBkhIm0lMKzKn/ERfAZsBTUyZLVufwDYbm7I5rUL+0HsKr8MX8JkGnNzC86fes9C5w6NaOBDM865nQExx6auPOQ+wroZ+oaMAgB/rA2cs4v5y48CoYkjHzjSAql7nLZmeKlIc2lxZ96BcBdYc0Nb/BqnCcPIwNj+q4cEB7dcK/t77ETDhlFrTr26pi89sAiF5sXliOlfJjhsN2NYXNh2eRwf075qNSTW9rljA/1eY+g5+Pqwppu3tUnyIV2i/C8LDOnGYiHTY9b+ucnDwqSb9QaLjw5wrbV/s5X+fxpfyF7MhWQILl6WwcZ7XpR0wmKlqEXaC6MqK/vmEn5tbJF45hLjGXxl6+e5uOOO4g0Ucm+jh35YDja906rr4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-06-09Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698746641.0497909,
                        "modified": 1698746641.0497909,
                        "name": "/dev/shm/incoming/2023-10-31T09:54:00.ais",
                        "sha256": "554e18cd44153341b8024b383191900e98042566257a2c5b3dccedf09c526a3b",
                        "size": 519799
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "9f48f327fb206e922e869a8112a5e3c0cf0bccf3bffc14670ec7e411b774f877",
                "size": 146368
            },
            "errors": "",
            "filename": "2023-10-31T10-06-09Z",
            "size": 146368,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698746775,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "CCYB7T/fIi5hUx+nDkvidZa9VNgCNOM9n4jFs0+AYCl3/J2MbQlAwNLjmtuJGgLbrBqlBpWFEf4a1gEqCRDuxHTUtVEakF23jE3iG3rtJkzplfTTo7XBwX7lZOVzN/aYfJlvG1REPK4ZMEJr8bJ2R6TyhiwOM2WqwumxUDKEOECXS6zq9IIih/ujjuLft5G9YLbM9I/ZTrd+n5d/t6uiOOotgmaUcH2fw111+UX8dnPT5hVrjHxmLFHntwitJlm/VbT3vjieICEvUsArCdVpXbozxLoCWyY9v+NE99dV+psuld6FvcEjqmYhH7hkDxv4F96Hg+3QTXn6DHaTJrAfuXaiF/M3oRn3yqZXwWNFOl9zlR/DV/yubEd5sTY88O1Wo0pHhmJNK7W51He0u1rP5PiwwyHHZqjs0wvtg8FCH2XNHLxtQ4PlGeSbU523/11yCDNBQGnR9LzO0lpmjxmdr58uHglVy5BU29+VUVzOqL4LLDvLf2vv6WDXP+DHNsZjg+kmlPT4O+DiQs7jLDL36RmT6T1Z88L1XxtKhDzgQubSc1ojTxNHTLsWdtM/4qo2qhzf/cSKMyFghgoy65xbFXfAof0vEtnCm3Elm9uXfrIijlEZyFEzrLhm8SzsCUknsFl+5NFt8tEghNNFH+vhX/PuypRnBJ8eATgOk7sXEeg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-06-05Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698746638.0514185,
                        "modified": 1698746638.0514185,
                        "name": "/dev/shm/incoming/2023_10_31_100338_00_01_V_052.txt",
                        "sha256": "256c66446e7bef98f0a00fa7dac33ccd74b1e01ab6cdce2bdf4f230f3b48ac1d",
                        "size": 2039
                    },
                    {
                        "created": 1698746637.9314184,
                        "modified": 1698746637.9314184,
                        "name": "/dev/shm/incoming/2023_10_31_100338_00_01_V_052.kml",
                        "sha256": "1654bb5397cb8afc0288e33d98330b083c621cbf73946ae86acd3c88b791f634",
                        "size": 953
                    },
                    {
                        "created": 1698746637.3814185,
                        "modified": 1698746637.3814185,
                        "name": "/dev/shm/incoming/2023_10_31_100338_00_01_V_052.jpg",
                        "sha256": "c93cc983eb95615788f5bf2d94a17595c0babbe58d76e48e53d4ee934a077a56",
                        "size": 1466518
                    },
                    {
                        "created": 1698746604.9914186,
                        "modified": 1698746604.9914186,
                        "name": "/dev/shm/incoming/2023_10_31_100308_00_01_V_051.txt",
                        "sha256": "3b98b2f4a609f29f2564a5524a0a694e7255b57071af649c357f8cfab31c89e4",
                        "size": 2039
                    },
                    {
                        "created": 1698746604.8214185,
                        "modified": 1698746604.8214185,
                        "name": "/dev/shm/incoming/2023_10_31_100308_00_01_V_051.kml",
                        "sha256": "e8eb2fab92f962efa95c62c84c747b3e72cf5f0dad47a0c3ca0126a0c236c516",
                        "size": 950
                    },
                    {
                        "created": 1698746604.7214186,
                        "modified": 1698746604.7214186,
                        "name": "/dev/shm/incoming/2023_10_31_100308_00_01_V_051.jpg",
                        "sha256": "cbb47e5d7fd42e92dddd978eddacacc27fe5b8a071891fb9a4ec6b0e92942bfd",
                        "size": 1292966
                    },
                    {
                        "created": 1698746510.7114184,
                        "modified": 1698746510.7114184,
                        "name": "/dev/shm/incoming/2023_10_31_100133_00_01_V_050.txt",
                        "sha256": "f896b52a43542bfa747753bacea0ca0ed83530dce224ccaddcfd90bb345e92f4",
                        "size": 2033
                    },
                    {
                        "created": 1698746510.3714185,
                        "modified": 1698746510.3714185,
                        "name": "/dev/shm/incoming/2023_10_31_100133_00_01_V_050.kml",
                        "sha256": "335cb962cd1c251ec55d53954e0ab8c8256c189b52ee9659b57c41133839df71",
                        "size": 952
                    },
                    {
                        "created": 1698746509.9614184,
                        "modified": 1698746509.9614184,
                        "name": "/dev/shm/incoming/2023_10_31_100133_00_01_V_050.jpg",
                        "sha256": "5dac373da93258342700594bf95770f1bc75a4b4cc90daa2881d65d7ac00bff9",
                        "size": 1145540
                    },
                    {
                        "created": 1698746509.3014185,
                        "modified": 1698746509.3014185,
                        "name": "/dev/shm/incoming/2023_10_31_100128_00_01_V_049.txt",
                        "sha256": "916c9eb0d9400e77275516a3f221233a874bda4c48102b7048adfba610eacc76",
                        "size": 2039
                    },
                    {
                        "created": 1698746508.9114184,
                        "modified": 1698746508.9114184,
                        "name": "/dev/shm/incoming/2023_10_31_100128_00_01_V_049.kml",
                        "sha256": "c993db2da7544ef1168f07c6ab8659685933055be4d42c0ae5e4fd24211b5022",
                        "size": 953
                    },
                    {
                        "created": 1698746508.5014186,
                        "modified": 1698746508.5014186,
                        "name": "/dev/shm/incoming/2023_10_31_100128_00_01_V_049.jpg",
                        "sha256": "41f3213ab5e8f859bf0f1c1e3a053c684fbd349d364dc088ab21847724414b77",
                        "size": 1120399
                    },
                    {
                        "created": 1698746392.2114184,
                        "modified": 1698746392.2114184,
                        "name": "/dev/shm/incoming/2023_10_31_095935_00_01_V_048.txt",
                        "sha256": "e200266ceb7b54d0d5a11e1fe1b6adf233906701cad5ebe2c7550214d020f224",
                        "size": 2038
                    },
                    {
                        "created": 1698746391.9114184,
                        "modified": 1698746391.9114184,
                        "name": "/dev/shm/incoming/2023_10_31_095935_00_01_V_048.kml",
                        "sha256": "873765f32df13f4839555d59e3d37a1234d71c1afabbc9c78e6fddda5bcb0ecc",
                        "size": 950
                    },
                    {
                        "created": 1698746391.4014184,
                        "modified": 1698746391.4014184,
                        "name": "/dev/shm/incoming/2023_10_31_095935_00_01_V_048.jpg",
                        "sha256": "d2e2af342672f00fc7ff579d946e9ab80ea8b2412d7a46e9b2ecf79afb21e179",
                        "size": 1514767
                    },
                    {
                        "created": 1698746349.0914185,
                        "modified": 1698746349.0914185,
                        "name": "/dev/shm/incoming/2023-10-31T09:49:08.ais",
                        "sha256": "6f88752f68611ebeb37a4353eaa729866ab679e6b1220bd7651dd3e5f5023194",
                        "size": 300690
                    }
                ],
                "mission": "OSP1",
                "sha256": "2d5aec0b114e4b189d35ce5c3c74eceb03ef6b392813dea599312e3d7f82b21c",
                "size": 6620338
            },
            "errors": "",
            "filename": "2023-10-31T10-06-05Z",
            "size": 6620338,
            "verified_at": 1698766687
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698746910,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "IkcUC6bn3tWgmm80C80znLcfVcXSZ1tmqyof7tdrNQcsyihG6JZ1USU9fUDMg/H+omFv/voJ4mo8lbXWK8uFDHIMyzppjuP5u6qgrDhSvkjl+H0bFsspkRt7fh6s7RPM04nqcvJtDHEgRZh5dwAKOECR1AKfq721fwpo8xrxrKP5GnROaqO4k6VaEBr/T+aLNmPA76kN13KDctpe2jtXCKcUlLXjpIMZH5DYq/EhwBr6v1YzJ59JKJpLQmvJ+qZsdSHjdyFn4h4n5/GuXLHofnHlyq0z6SWHUPhG0Z0fbiQsmL+AY/ZEovgtPmTIbTgAh2GJ+NH36h9Zpslcb6V0v1XDZBxXf1DKxvWLprSu3d2s/oGWYHakzz3ej01xz3kRaUrZrCs4i1l84WRAM1tP/MSLo9fdXSF82tNUzMqBAKWtL3u2aAPOqoGG9Ky3mYxwePmxhxUqqgU/1zHWvzRBbLN66akW6X8RhdbogCTCiEG0q9SMO97YQTlP6zXyDTovMQBlh35lKv7O/Lj7rDggbBpmY3DZ8VeTs6Hyr+Fm3B7yz93ebqbgWEKWezl37zldwglfl/HDbFdJOhbsQxLmFQEHCOtV3RZ1zEVI5MvI7Sxcx/NbfqUbn0TceEf7vzQziSWrE+RQ4iPR+jkK/A0hVMwuDGJ06sI2I2nmaIuf2HA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-08-13Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698746770.11126,
                        "modified": 1698746770.11126,
                        "name": "/dev/shm/incoming/2023_10_31_100548_00_00_K_101.txt",
                        "sha256": "5829b8bd76095a67ba33ed81e55359eae5120efe38ddf5ba304ad4dc57b8103b",
                        "size": 2042
                    },
                    {
                        "created": 1698746764.13126,
                        "modified": 1698746764.13126,
                        "name": "/dev/shm/incoming/2023_10_31_100548_00_00_K_101.kml",
                        "sha256": "78f694fbe137918a474c70595018bc166961047a61f367f8b33ffcf796c900a8",
                        "size": 953
                    },
                    {
                        "created": 1698746773.80126,
                        "modified": 1698746773.80126,
                        "name": "/dev/shm/incoming/2023_10_31_100548_00_00_K_101.jpg",
                        "sha256": "8596764a2647e9cf64e162cb1293b82642fa1495a639a106ac71938471b4a702",
                        "size": 745960
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "620a795aa26d8c147e5e6e120118dd92a93f1c8dd5737b7b34514d35768c7077",
                "size": 740611
            },
            "errors": "",
            "filename": "2023-10-31T10-08-13Z",
            "size": 740611,
            "verified_at": 1698767748
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698764435,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "KFc999ZuD9cCxd0CylsfcA624uccNPQk2//ZOAMJYoKfoHheTBnP2pKGMMQ6jJO2nmeknjnD/xEfbe2CjnvtmueYg4Sxe2mtS+bj7DrSbpA8hwzAJ9w/Z5G2/kqXIJMMQtZBCCVknQmKrRStR4xkbWXtR315T4YB55YvkdrN6LkMkwbBPpZfhjaiM2IxXQrMW/EsMtQvmEK2ehHDjCEuSvXRaCPX9vEmIpoXdCTPly1Ssd1AEw1CitXdSlY36n4gY76Vvk27V0OM+3+oEYeYykAYlI8+Sob888kboxb4PxnRqkJmYhWFXmVZZp8qxGO5c05lQg3waGUjC5Nc1aHesqiYyRdJcSnjgVp4l8/Mx+9YZHfpVC6libtOAzU7kmMhDK4vWEQT8+7N0bKwZ28R7nfvCUQ/GTMVkhVaNqXWIJKCz/F+pN0wS782aRHil/OeDs6co457zWY0cV6QnMdE5ESsT00oh0k6BImnA94LQiZpqxu2sOcAoAZ2QBJUROEAbvZt0uoS7EsUKJRLssSCQQ5FSAJP+uUhBpCeHpEoASZrItZ7lqbh/Uw8CvNh5xrhaHyc9TVPnjkBEuDYISl52lq0yMX1+YQtcMKtj7IKePFPWb9tE1kp8OWcWz/DONdMhvwqfeeO6MqccKNqRgrG4MsckqjeeW9gwpbQHE+85pw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T15-00-18Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698764293.003036,
                        "modified": 1698764293.003036,
                        "name": "/dev/shm/incoming/2023_10_31_145751_00_03_V_005.txt",
                        "sha256": "67d6e924be6350cd1dd9780d8351c408ae9506795b6691b00442234c2d732d2d",
                        "size": 2037
                    },
                    {
                        "created": 1698764292.403036,
                        "modified": 1698764292.403036,
                        "name": "/dev/shm/incoming/2023_10_31_145751_00_03_V_005.kml",
                        "sha256": "4547bfa47f42e0b44b446932283b4d5a59eb099074827d3e8d02dbad8f754c00",
                        "size": 976
                    },
                    {
                        "created": 1698764292.3130362,
                        "modified": 1698764292.3130362,
                        "name": "/dev/shm/incoming/2023_10_31_145751_00_03_V_005.jpg",
                        "sha256": "bf0c567d8f57f338581e8f341e0d0e50840ae55b0b3ff32c2ba431473d32f619",
                        "size": 947199
                    }
                ],
                "mission": "OSP2",
                "sha256": "e31736128b290dfa996d3b96ad6fa58d97d4d43efe274c8fb3a7911e33257cce",
                "size": 947702
            },
            "errors": "",
            "filename": "2023-10-31T15-00-18Z",
            "size": 947702,
            "verified_at": 1698783361
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698763397,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "WSc2g3PFZG4DJuLJ2c0Z2ZixjgV7gbnK7hUlGsYGXQykZMgwngMTTkni+oofYXb76G4VKwSKUfG8rtxmnnii8BIuA1oxAAx/HQIsOn6B5+MCeaeBysMsUdViKfxnXmNO17t9jbLCq7Dj80Xwi082UPpGwU7a8TSplw7phSU0GZl9GaR4GdmDmhZRlWrEbBxriD9bapQRVUN/F7e9W5BNk3TCMn2S31DiPgdc+TCLlBYUjVAX2ZISlKkXz7nIEghewB6TQ+TLg8N/DuH5te7RFSj7EiMQ2bvbORdgyJgB7ubZuEHE4myYpxnDaPPdeaAW3MVTzbHBWVS0RdaBYZ5gfjk0PzStLn4IQOvj9fwjFiqHpvBRzlP6haeNyiWJKvJBQuZAENmFR/RFa161MFZKekS7awJrutgXVXH1jasVNa+MFVIFDBsHf+j+jkCljEwhtXu1Z4RBp79NHxWdc3zcxvs6PavGHoM0kFvsXCE2g+NAXOyLJq4iZ6Qq+HosX3Kgg714kFIO4JzazuIxAVIAOLwaxa1V4XOm819jwwpvRfqW6RQi4KU16O4iNVqreeCGUL5qaBT6fEkLUVZ0/I2qrOjj+SFe69vJeWV3YYaCSIZQzJtThikuTRYl/J8TImyOJvACZzGVte9dAF2jrMvGW9gXt3WW2d9hP1uSOzwLnLY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T14-42-57Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698763249.243036,
                        "modified": 1698763249.243036,
                        "name": "/dev/shm/incoming/2023-10-31T14:30:45.ais",
                        "sha256": "7fc98c588243b7034928d87b96234302fe377b729a61674eabd166a981062318",
                        "size": 51389
                    }
                ],
                "mission": "OSP2",
                "sha256": "fe5717d11bf2f2ade629592c52c852938ad4522a05b285473c57da795ee3d29d",
                "size": 10215
            },
            "errors": "",
            "filename": "2023-10-31T14-42-57Z",
            "size": 10215,
            "verified_at": 1698783368
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698747370,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "i9uwYmQKGOBxox200MZwrLYCaKxcD86L30FVMq0zXORmxoBXGJBcSjNVVCVbY1qunc6bRMl1566mv+ZelI83sgX9PONiPm43Uf1f8qjuy07d5Pm1Dzpr0XJ4lJvk096FAQEt+nSkKLcSRDhLns7Cwm2uxFkRwH702OnI8G98ukkuHN/IDuCO+dseG2b51vICod+h58XauF6HTbXXZ9KErgsIAP14o+bhKYwNB1BLiW2i6v274E2l+MeYyE/SwiiC4yevOKCtdRjRTSZ3TCMePjSt+Qkvj4/FmyOLJej4jLBwhYlNoi3m3q6F+N6hfhEdMM+HFzd+aL0gpROr2RSnXdiAvcDM5+1APw2vmuVmDp58VdaDesJgZeJaVss2Qc4a8txyLmxRQa8GjCB3J4L/lrz/uWnfPWtBj9B6OOzy6QU+MtgAqxbksCfEZLnyYCDyvHjPU2OLunaATNMtd689ONlK8vattTgJaLv5eUALOn2xKxMhpUc/rVnt0XZQd0ENjwFLmb1EfTsMiU0Wd9qfFeAn+g4+fRuSzPok64gZIntuA3bXA/JZ9ukvm4B9ghzEjfrpKvZoX6FB/NmpQGo9gM3/aUewrJKTC9vkloR7ayPUFxJbw+wWV1RAKgoeqH9iK45xFtSZqwN4qqlynwtFqpfvD+IDTe6tcmdEUIjINw0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-16-07Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698747242.5297909,
                        "modified": 1698747242.5297909,
                        "name": "/dev/shm/incoming/2023-10-31T10:04:01.ais",
                        "sha256": "6a1369b3fa549df625c0a4ade820788e44b07a00fbfea91b4644e8151568acf7",
                        "size": 114234
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "e9feb27de1647467a8ae528b11d250963b2977d8ccf00f05640e8842fc7162b9",
                "size": 27924
            },
            "errors": "",
            "filename": "2023-10-31T10-16-07Z",
            "size": 27924,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698747684,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "qy36nBQGNqKD0rBz1QVBsBMCMAxc/xoyav1LO3AkWK6zJtFmyySg0n8cskr3sPucPMcH40gqR6t3myKiSulamWSjqB0b3zPWIU6xEP+I/B4YeA0X2ZIU5F+GGELmT4kj3Vm4BL21ET032lXXjAw1Yi28EkpBWVwW31ir48MRwmzf95l65XX+a6PIiKyOosXQfFOyi4eDtZLIvPrxdnNVZ+b2f1lysK75IEUk9hT0n5t5nAcbAcb/9k7a4D/7U0YEiCf+Zc3dw3d0m1KaxxmX6WoSLkMj5UBLKABrSmYd27bu040ET1/+t8kgLpH6MF74wYQj65LG6jPxHUX1Uy0KKBavFwaqmyyiVP9LkVSumBjEubXiV8hnsyeBHBiIzPfFIn7D+QVQyX9o9HS7kbAWOP8dzXQkLB668RHIeOQEQw+cTKgtvJBKahmAsh979iaoMS+KxRY6f3hN3YUAFI0s9N27/ne4shOYKjRWC6qtONrXRg8yilWctz+2NreJ28x2iaGAhmfdXdU38Wo4g0CXVkxeJzj0QvcDVEl+C2C3IhDT+1OrQT9r6ao9ItrT2/JGhXnMIiVrKIzfiC5ZGgvLrILpKAO01j88x8jcH3ov32Upca8fZPu2OIj8ObfZhyDFEcZ7YIYUmy7G3iKV51i2h4/ZFd7ACgRXo4tNFTI1Nlk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-21-14Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698747551.3414185,
                        "modified": 1698747551.3414185,
                        "name": "/dev/shm/incoming/2023-10-31T10:09:10.ais",
                        "sha256": "dcbd01ac773947bf6cb1901d65d1bf75e2d477b0f871858e787df16f0b6c8785",
                        "size": 249056
                    }
                ],
                "mission": "OSP1",
                "sha256": "cb9543ada5d1eed5d2972f17f2cec222f3fb00ea1dde038de991644254a81f30",
                "size": 59094
            },
            "errors": "",
            "filename": "2023-10-31T10-21-14Z",
            "size": 59094,
            "verified_at": 1698766339
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698747027,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "FezzooRkbV7keUSWHXl24Fi2ebMC0SJwmqIdCnJQrwldM56kco1bJ4pDHHZNaUMRqw6suefeqQqQ937xDYlSGJN53VGDh5GZKa6dSti8UGqJMbWNBGv7hPgw6y6eMlxcOMGEwRr1LjHJHcQ29yRJ89nhSE3bIIJGd9PIQYETdokICTyj8VS5bzjwM+KhUwV44QwXVut4xcCulqAy/QrbKggqcG24vRySeXxXVpwYnAwU9/cx3AisFW56leEfagdNRJHS+rlBpo0xoP/VFe213KDJN8PqlL0QzlCPVTcw4hlxtccq1vYlKYDGOJD4zfaJGYJXYFyE6jolUNKPWkiEKwLmsu9J/HVdwK0XCN8KNrRmUKY9GcgmPsm1UgWaWj1nJj0TY33eysGgEfJB46a1n01Gxm66wF7viuzv9zAftmyrvq8jMVZ3i2kiZeTWySzIWcj+wuRtXELPsPSAkOwxo56sMTMlc871N4KnofsHk57Qv9Ibx/+r74bAFJqtw38NWeVBx5mTIw5nj8R15ELOaf8gv7vjfK7xdGa8ZwwywTKyN2lOMuggH4BZMvtmqpBoVui1tkopOZIP9i+XrM5hD5fdJDpDaYDV++PBrcJLGLgvFX2SeqbmBVV0wwLrER445DCWjVR7r5R31Sn8bgwJo5S8+22gQFpgp19YNxAzoOY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-09-27Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698746950.1114185,
                        "modified": 1698746950.1114185,
                        "name": "/dev/shm/incoming/2023-10-31T09:59:09.ais",
                        "sha256": "e61f91fa2850a215ca38e7ce862bac00a2ae1f46a7f56d29603e105833909ca9",
                        "size": 296027
                    },
                    {
                        "created": 1698746958.3014185,
                        "modified": 1698746958.3014185,
                        "name": "/dev/shm/incoming/2023-10-31T09:39:17.ts",
                        "sha256": "684dd7409a60f683e4e1125e05d5dbe77c314bbabcb22a16c9be7d55219c0238",
                        "size": 411752900
                    }
                ],
                "mission": "OSP1",
                "sha256": "b50852f490974959a806f730ba020fae352296e757c6e98d367ca96022a8c057",
                "size": 397267200
            },
            "errors": "",
            "filename": "2023-10-31T10-09-27Z",
            "size": 397267200,
            "verified_at": 1698766501
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698747051,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "BecMG+CZSB4ZAqdGmPdLcaRrXXV8JZ92MCgw609Bv0yXWHw37Y7jYlzJxbLFphwuftp+xGhTJrupFTiAwmHXaNnYBr2U4IsZmFH/lWCYiia9HLXQFX8TH4+LD+3RGxSl2F8frjpZMt89LJDrzl3H/h/zpBEewHb/E1Ix2JNLsZ6n2ueH82gj3Tnw+5UslXt6q0J181JHpuFmx7zytgJDcKROJkXwVoMh7NLZ29sxSWw6yiNrh9L3u6cIRp+xnbiiCkmcHxXmSVEJPgusKgA1SbAkNZGpWv6mumuBzXpGJ+qUWt0NPh5fZ6ih5JmV+cd9qFeNgRNCSlqx5vDn00nK29YUTIyFuJTYkRBToGdVAfiOCy9nnHL06Qn6RfQuSlG/y3+b4xeUlXGq9eswfdCKhG1dvlEGq1tQ8KPWHDgoCVXsZxUWqUzDCPF2liAmGsCoJk8t7qxkDmogngTvDgr2EYNw5JnYIIR+OenNbarlUAZoptaWjMzt9M8ShNVj55NnPvvt5GD6g/JowQnNTezo/je8FxghjYkj74Sa7u8qEeK5Td3eCLsef4pXicn7nSX2rqIUr7dgt/3tQQzF/OmsWjv5chKLS+ZdolwOuRIzpfYmqaeOnoR4mP3XWjpfossUAmsA+02QUpeBBNr7hWZ5Iv9YdV37paOTfWsfOfV9KWY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-10-34Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698746920.27126,
                        "modified": 1698746920.27126,
                        "name": "/dev/shm/incoming/2023_10_31_100806_00_00_K_102.txt",
                        "sha256": "45e5a92b3c2d61a3b083f3ddfe55404fa56f43c91fdc6a5a9d3f8ac0bea05e29",
                        "size": 2044
                    },
                    {
                        "created": 1698746912.56126,
                        "modified": 1698746912.56126,
                        "name": "/dev/shm/incoming/2023_10_31_100806_00_00_K_102.kml",
                        "sha256": "bc8d053d5bb0c221a127e2c0765bbbb8f7240f7a9302c6ebea797505c8434076",
                        "size": 976
                    },
                    {
                        "created": 1698746909.61126,
                        "modified": 1698746909.61126,
                        "name": "/dev/shm/incoming/2023_10_31_100806_00_00_K_102.jpg",
                        "sha256": "fa83bc6cc8a68618006d9dc02f40d92ecdb3c103757cf06c1aab3df2b9205d15",
                        "size": 671511
                    },
                    {
                        "created": 1698746908.56126,
                        "modified": 1698746908.56126,
                        "name": "/dev/shm/incoming/2023_10_31_100548_00_00_K_101.txt",
                        "sha256": "5829b8bd76095a67ba33ed81e55359eae5120efe38ddf5ba304ad4dc57b8103b",
                        "size": 2042
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "3d09055fc651f3b8bd452dd0cdee40fffded4937f6891437c706b6403cbdb45d",
                "size": 663083
            },
            "errors": "",
            "filename": "2023-10-31T10-10-34Z",
            "size": 663083,
            "verified_at": 1698767804
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698747666,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "UBjN7QB5JATXGEGG4ybZmbtA4OD5zXRwQtLQqaxTHxDnjnnu6404ITLNtXNFGoUUvcLP9UVagBozak9CwuuPXmcHdruVw9ojbLglqipteSqfhzJdg7zKecjMZ9pKR7Z2SkBXO+asj0j8xlNA9Ha3Ez7tvkzgBrkCUnkf/m+mIbbYooBs5fVC8QnHrmWvOSHjHfm97kuJDODFER+QtjA1AALjAd8g60C8/KHQ9GBYDIAvbF4IiP4ae0j9Anv4bx3ldYdewL56Bljl28fYYphdjZpMmIWI7LjVTVXCUIhcdJxBkijLqY5aj9tV68U0+gdpwuEtgey6Fc6Eh26VK2xmYRc2gcIl9AxkLhhv3DA6e7Gir/SET6tJOjUbRIxok7lCkdL/j5UcTun3OQ2r95gRe3ySgc8+udIYxSRAzmRI8xwubyG5Imvt2wXdjrZynR+4+qC4KYvvpJZWEVa8R8+vwW7lkBlrpnvHhCxIi1wA/oRKKZWihoCi2dOPeum59j8yzh6NMgyRhVh8TsDjR1rhJcmFINx81e3cgR6T3JiTeK2CbZvNyq+WkXxPsHSspIV/mIyUfnkVbmdroBMtVRgYXB78z+ezq+KutAFznCdF+2kxak83rRoWRq3DdIXYezLD0xQQc0xynLVujUHGRqh8za8k12tWuvH+Zp9hei1c3qs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-20-44Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698747536.63126,
                        "modified": 1698747536.63126,
                        "name": "/dev/shm/incoming/2023_10_31_101825_00_00_K_122.txt",
                        "sha256": "bc1a87dafc1bc24436cf9e1be521f0e98dec97bcee2cbe2d12391d79e89d0811",
                        "size": 785
                    },
                    {
                        "created": 1698747521.29126,
                        "modified": 1698747521.29126,
                        "name": "/dev/shm/incoming/2023_10_31_101825_00_00_K_122.kml",
                        "sha256": "5c984e4bbd366ffc3a4b9c2948e01f241294843551a04438d8886dc4f7f16cf4",
                        "size": 913
                    },
                    {
                        "created": 1698747535.06126,
                        "modified": 1698747535.06126,
                        "name": "/dev/shm/incoming/2023_10_31_101825_00_00_K_122.jpg",
                        "sha256": "317d6ec24e9dbef57cb73a0714762c68ce815cd9ace339d79ddc12a40bbf02bf",
                        "size": 588853
                    },
                    {
                        "created": 1698747517.04126,
                        "modified": 1698747517.04126,
                        "name": "/dev/shm/incoming/2023_10_31_101802_00_00_K_121.txt",
                        "sha256": "c061ed05c67e5eb72cf481047f4241cf20133c03f15de7df511d4e43255a2143",
                        "size": 789
                    },
                    {
                        "created": 1698747503.00126,
                        "modified": 1698747503.00126,
                        "name": "/dev/shm/incoming/2023_10_31_101802_00_00_K_121.kml",
                        "sha256": "a16baa00a88db4be4374a0444a2ed18a7310bc9f69d23ee9fd557b15354aa7c6",
                        "size": 913
                    },
                    {
                        "created": 1698747505.95126,
                        "modified": 1698747505.95126,
                        "name": "/dev/shm/incoming/2023_10_31_101802_00_00_K_121.jpg",
                        "sha256": "2877e3c951fe93ecd8a63deb182a910bffad811cb4b24b9c066175146e322832",
                        "size": 631511
                    },
                    {
                        "created": 1698747372.27126,
                        "modified": 1698747372.27126,
                        "name": "/dev/shm/incoming/2023_10_31_101551_00_00_K_120.txt",
                        "sha256": "97d71fbcd544adcaf9f376debd2a49d5582b869ffd2e0de1bdd044f9ce79bbf1",
                        "size": 2045
                    },
                    {
                        "created": 1698747380.40126,
                        "modified": 1698747380.40126,
                        "name": "/dev/shm/incoming/2023_10_31_101551_00_00_K_120.kml",
                        "sha256": "0d406eb1bcc16387573823bad4b2c25620061073583ad22d50987ada7a9e2dc5",
                        "size": 976
                    },
                    {
                        "created": 1698747387.96126,
                        "modified": 1698747387.96126,
                        "name": "/dev/shm/incoming/2023_10_31_101551_00_00_K_120.jpg",
                        "sha256": "48fb26dba70725dd01dba9ae43364866a8c42b177a8d7e1ecc88ef56a9da40ec",
                        "size": 912295
                    },
                    {
                        "created": 1698747369.70126,
                        "modified": 1698747369.70126,
                        "name": "/dev/shm/incoming/2023_10_31_101550_00_00_K_119.txt",
                        "sha256": "5a342e94efcdadfdcfc5038a774846a277792d978a8a7d7bee051bcc6136e2cd",
                        "size": 2046
                    },
                    {
                        "created": 1698747369.35126,
                        "modified": 1698747369.35126,
                        "name": "/dev/shm/incoming/2023_10_31_101550_00_00_K_119.kml",
                        "sha256": "4d6ed5a6a47e4e3a9e6ec450ceafdbccfc4a68ba6d14ead988d92b87e324126b",
                        "size": 977
                    },
                    {
                        "created": 1698747386.54126,
                        "modified": 1698747386.54126,
                        "name": "/dev/shm/incoming/2023_10_31_101550_00_00_K_119.jpg",
                        "sha256": "b77c2b83f5940ef0764cd1125f0a14a7ee4ecfb4017c483450694c79dd5b1d33",
                        "size": 930993
                    },
                    {
                        "created": 1698747368.31126,
                        "modified": 1698747368.31126,
                        "name": "/dev/shm/incoming/2023_10_31_101544_00_00_K_118.txt",
                        "sha256": "eca4e8f68af1a2fee9cbf8f0e3e944fc75c2809fa916847d65c3b2d011708068",
                        "size": 2045
                    },
                    {
                        "created": 1698747368.08126,
                        "modified": 1698747368.08126,
                        "name": "/dev/shm/incoming/2023_10_31_101544_00_00_K_118.kml",
                        "sha256": "3bd0747709b6d73b1c0fbf9e9910eaf2d12afe49b977cda92067e755f8a18d21",
                        "size": 978
                    },
                    {
                        "created": 1698747368.66126,
                        "modified": 1698747368.66126,
                        "name": "/dev/shm/incoming/2023_10_31_101544_00_00_K_118.jpg",
                        "sha256": "9f11134e3d7ecd2c444fe36b80cc216c6ef815317230e35945582a225547e380",
                        "size": 933966
                    },
                    {
                        "created": 1698747353.95126,
                        "modified": 1698747353.95126,
                        "name": "/dev/shm/incoming/2023_10_31_101512_00_00_K_117.txt",
                        "sha256": "037543a3f963d1522c688eb203ba66be133c594f1a0441d02f4a60e98b222ce0",
                        "size": 2045
                    },
                    {
                        "created": 1698747334.00126,
                        "modified": 1698747334.00126,
                        "name": "/dev/shm/incoming/2023_10_31_101512_00_00_K_117.kml",
                        "sha256": "ba615f44498f48c87e2867ad1a4bbbad9d176663ee9175465548829c3d9d81fc",
                        "size": 977
                    },
                    {
                        "created": 1698747334.89126,
                        "modified": 1698747334.89126,
                        "name": "/dev/shm/incoming/2023_10_31_101512_00_00_K_117.jpg",
                        "sha256": "e740623ca22eeda72caffe6a6f50477b1a8194265e5ec52d20c54c87250f1e59",
                        "size": 1253842
                    },
                    {
                        "created": 1698747325.67126,
                        "modified": 1698747325.67126,
                        "name": "/dev/shm/incoming/2023_10_31_101458_00_00_K_116.txt",
                        "sha256": "05e77c9e6b9faaccc60d72a89752f6dd2d1d2a49b72d84afe2c8373714822728",
                        "size": 2045
                    },
                    {
                        "created": 1698747329.40126,
                        "modified": 1698747329.40126,
                        "name": "/dev/shm/incoming/2023_10_31_101458_00_00_K_116.kml",
                        "sha256": "1350a5cba5e4adb8fd5e5b9c2874fc0853a69187ee9e5c6deb1988de22fc2964",
                        "size": 979
                    },
                    {
                        "created": 1698747326.08126,
                        "modified": 1698747326.08126,
                        "name": "/dev/shm/incoming/2023_10_31_101458_00_00_K_116.jpg",
                        "sha256": "c9fd4484857507e7801a5c5eeae82a8c291a075c884175a62b7a047ad61737a3",
                        "size": 1294077
                    },
                    {
                        "created": 1698747307.53126,
                        "modified": 1698747307.53126,
                        "name": "/dev/shm/incoming/2023_10_31_101444_00_00_K_115.txt",
                        "sha256": "69d14e00de064e592ad127c3aebcb762237bc83e776c0cec65da814cb13af1af",
                        "size": 2046
                    },
                    {
                        "created": 1698747307.13126,
                        "modified": 1698747307.13126,
                        "name": "/dev/shm/incoming/2023_10_31_101444_00_00_K_115.kml",
                        "sha256": "3a7c7c7258cd3b0282b4e470bad06c18cfcc8765ecec075d703665690a19e18a",
                        "size": 978
                    },
                    {
                        "created": 1698747316.35126,
                        "modified": 1698747316.35126,
                        "name": "/dev/shm/incoming/2023_10_31_101444_00_00_K_115.jpg",
                        "sha256": "4455944a09a121517b3cc13d8ed83e0586ace2621f2a3a31a6d79b759ad62feb",
                        "size": 1151528
                    },
                    {
                        "created": 1698747292.02126,
                        "modified": 1698747292.02126,
                        "name": "/dev/shm/incoming/2023_10_31_101416_00_00_K_114.txt",
                        "sha256": "56b5c09113ecb195e7656655e09e7cf314cc8390645d85f98f9d293cc00bbb61",
                        "size": 2045
                    },
                    {
                        "created": 1698747300.21126,
                        "modified": 1698747300.21126,
                        "name": "/dev/shm/incoming/2023_10_31_101416_00_00_K_114.kml",
                        "sha256": "30f7462a4f4e1442f1748be10e680a044900ddb0ff129c9f5ddb3ba4dea289e0",
                        "size": 976
                    },
                    {
                        "created": 1698747288.31126,
                        "modified": 1698747288.31126,
                        "name": "/dev/shm/incoming/2023_10_31_101416_00_00_K_114.jpg",
                        "sha256": "05156fd8ca64cdd1ad50794a4bd1c825862f0de059fe3021386333de50d99ff5",
                        "size": 1346427
                    },
                    {
                        "created": 1698747270.06126,
                        "modified": 1698747270.06126,
                        "name": "/dev/shm/incoming/2023_10_31_101406_00_00_K_113.txt",
                        "sha256": "624ac5899ef4ab7a927fefb7da1bc75697b6ddcd8e2c905440d15b43bc897b57",
                        "size": 2043
                    },
                    {
                        "created": 1698747269.70126,
                        "modified": 1698747269.70126,
                        "name": "/dev/shm/incoming/2023_10_31_101406_00_00_K_113.kml",
                        "sha256": "e4fe9211488571e4715adc442855bb5b3eeafa2f06d8b435167b6001396f10eb",
                        "size": 978
                    },
                    {
                        "created": 1698747279.16126,
                        "modified": 1698747279.16126,
                        "name": "/dev/shm/incoming/2023_10_31_101406_00_00_K_113.jpg",
                        "sha256": "29bc41a423ec042a6f0c7ae3bba0d55db9d53ad94788460435f0f99671949124",
                        "size": 1221881
                    },
                    {
                        "created": 1698747268.38126,
                        "modified": 1698747268.38126,
                        "name": "/dev/shm/incoming/2023_10_31_101357_00_00_K_112.txt",
                        "sha256": "ffa563d9b19c89ae8b682b5429c8b4c13fd75468fe286ad784820390751f784d",
                        "size": 2045
                    },
                    {
                        "created": 1698747260.48126,
                        "modified": 1698747260.48126,
                        "name": "/dev/shm/incoming/2023_10_31_101357_00_00_K_112.kml",
                        "sha256": "c51d1bd4f5245a90b9546e6483fc36754bdce3dc7d4d5080af47ba8471dc684a",
                        "size": 978
                    },
                    {
                        "created": 1698747265.60126,
                        "modified": 1698747265.60126,
                        "name": "/dev/shm/incoming/2023_10_31_101357_00_00_K_112.jpg",
                        "sha256": "1cf152742fa6a2fddae085abfbbcb49d3b0743568ffd20c817c5dd31016cae46",
                        "size": 469314
                    },
                    {
                        "created": 1698747267.79126,
                        "modified": 1698747267.79126,
                        "name": "/dev/shm/incoming/2023_10_31_101347_00_00_K_111.txt",
                        "sha256": "bb56474b1a9932402670794103f9f0ef764e4eeb103898e72407e846544dcde5",
                        "size": 2046
                    },
                    {
                        "created": 1698747255.93126,
                        "modified": 1698747255.93126,
                        "name": "/dev/shm/incoming/2023_10_31_101347_00_00_K_111.kml",
                        "sha256": "80d1417b283b40061939535d76f6d24285cae05a757097193017392276150074",
                        "size": 977
                    },
                    {
                        "created": 1698747254.82126,
                        "modified": 1698747254.82126,
                        "name": "/dev/shm/incoming/2023_10_31_101347_00_00_K_111.jpg",
                        "sha256": "4e95235b62c5a24fc23b2fc7d3441e5ac550535d12076fdcaf947eead3c97a15",
                        "size": 1181791
                    },
                    {
                        "created": 1698747240.77126,
                        "modified": 1698747240.77126,
                        "name": "/dev/shm/incoming/2023_10_31_101336_00_00_K_110.txt",
                        "sha256": "82c0da7f48f7e12c182e01870b9d12d6ab8dce98f9b8798631f455655d3cd814",
                        "size": 2046
                    },
                    {
                        "created": 1698747240.33126,
                        "modified": 1698747240.33126,
                        "name": "/dev/shm/incoming/2023_10_31_101336_00_00_K_110.kml",
                        "sha256": "f3ea5e6dfea59fd8dae2e439af84bcfc76c7b9cb2944c66982c5761ba41eaaf5",
                        "size": 975
                    },
                    {
                        "created": 1698747235.88126,
                        "modified": 1698747235.88126,
                        "name": "/dev/shm/incoming/2023_10_31_101336_00_00_K_110.jpg",
                        "sha256": "4d006c171bcde156a55b529b106115fac6bb9ef1d6c56c7ceb2f4f897244fe61",
                        "size": 583317
                    },
                    {
                        "created": 1698747231.76126,
                        "modified": 1698747231.76126,
                        "name": "/dev/shm/incoming/2023_10_31_101328_00_00_K_109.txt",
                        "sha256": "e99c2226e968116424187bce9b758da89e99487b245390ce9848dbf44695b603",
                        "size": 2046
                    },
                    {
                        "created": 1698747231.61126,
                        "modified": 1698747231.61126,
                        "name": "/dev/shm/incoming/2023_10_31_101328_00_00_K_109.kml",
                        "sha256": "4682fc069b64ac66d24c6ff21a3bee4ba0bbc04963a08a9f95694a356449841d",
                        "size": 974
                    },
                    {
                        "created": 1698747230.99126,
                        "modified": 1698747230.99126,
                        "name": "/dev/shm/incoming/2023_10_31_101328_00_00_K_109.jpg",
                        "sha256": "a28bccc382cc4badaa7ff3ee769bda933a03f25bd384e4bca8f4892f27da216f",
                        "size": 692421
                    },
                    {
                        "created": 1698747217.18126,
                        "modified": 1698747217.18126,
                        "name": "/dev/shm/incoming/2023_10_31_101304_00_00_K_108.txt",
                        "sha256": "a98618cd148c4688b6895201f4193fa480b3ae6cf2449f25823538863f573b03",
                        "size": 2045
                    },
                    {
                        "created": 1698747210.61126,
                        "modified": 1698747210.61126,
                        "name": "/dev/shm/incoming/2023_10_31_101304_00_00_K_108.kml",
                        "sha256": "9b5e0d30d59a35b8779a63867bcc328fcc6493823d2e46258beba1e76ef92310",
                        "size": 975
                    },
                    {
                        "created": 1698747211.39126,
                        "modified": 1698747211.39126,
                        "name": "/dev/shm/incoming/2023_10_31_101304_00_00_K_108.jpg",
                        "sha256": "fc2c71e954eaac63f4711dcaf81939c5f7d1ae85fa92be5e41bcb2041535274b",
                        "size": 1030739
                    },
                    {
                        "created": 1698747194.81126,
                        "modified": 1698747194.81126,
                        "name": "/dev/shm/incoming/2023_10_31_101249_00_00_K_107.txt",
                        "sha256": "b8a7f1d909e90392a235391c3d8733265b9357b6e872c41217e999e691959ff7",
                        "size": 2046
                    },
                    {
                        "created": 1698747204.00126,
                        "modified": 1698747204.00126,
                        "name": "/dev/shm/incoming/2023_10_31_101249_00_00_K_107.kml",
                        "sha256": "6289e2b8b97cd1fc0bf90f750c9353d5911b46db546c735333014c87ad9cc0c1",
                        "size": 977
                    },
                    {
                        "created": 1698747203.93126,
                        "modified": 1698747203.93126,
                        "name": "/dev/shm/incoming/2023_10_31_101249_00_00_K_107.jpg",
                        "sha256": "f9247fc84c591576cc836f481642791315e8d458c7a878e4c466df075ec145b3",
                        "size": 1121500
                    },
                    {
                        "created": 1698747176.85126,
                        "modified": 1698747176.85126,
                        "name": "/dev/shm/incoming/2023-10-31T10:02:48.ais",
                        "sha256": "42b2472bf68ecee49fad12a489377e0fd2d498970da807034ef4c85e57a600ce",
                        "size": 77065
                    },
                    {
                        "created": 1698747175.29126,
                        "modified": 1698747175.29126,
                        "name": "/dev/shm/incoming/2023_10_31_101237_00_00_K_106.txt",
                        "sha256": "547707fc1fb5ae181d29dfafa4a81d2eb3f60bc153bd01082b01858fe54a11db",
                        "size": 2041
                    },
                    {
                        "created": 1698747176.21126,
                        "modified": 1698747176.21126,
                        "name": "/dev/shm/incoming/2023_10_31_101237_00_00_K_106.kml",
                        "sha256": "c5141e8bf16289b9f6670e756fcf7f64cdcd74d7fca83291bb1836abe9228166",
                        "size": 975
                    },
                    {
                        "created": 1698747179.95126,
                        "modified": 1698747179.95126,
                        "name": "/dev/shm/incoming/2023_10_31_101237_00_00_K_106.jpg",
                        "sha256": "757d5b3d5138174e89a9e3e06974de37e06a60dd229c530747260f000efb7447",
                        "size": 921810
                    },
                    {
                        "created": 1698747118.82126,
                        "modified": 1698747118.82126,
                        "name": "/dev/shm/incoming/2023_10_31_101139_00_00_K_105.txt",
                        "sha256": "08e95a84691e0fd6d751f345cc559e093e94b0fbb2dde6743a474b528e75b3bc",
                        "size": 2045
                    },
                    {
                        "created": 1698747115.50126,
                        "modified": 1698747115.50126,
                        "name": "/dev/shm/incoming/2023_10_31_101139_00_00_K_105.kml",
                        "sha256": "393de3a2d2c0df5f5db3a5154e0781e23e66cbefacfa081ffbc5909e31e37213",
                        "size": 978
                    },
                    {
                        "created": 1698747118.65126,
                        "modified": 1698747118.65126,
                        "name": "/dev/shm/incoming/2023_10_31_101139_00_00_K_105.jpg",
                        "sha256": "b4f74e2e9d6bc27ea7fd493335b376436ceb05287ea7ce9f6e916018c863749b",
                        "size": 1049524
                    },
                    {
                        "created": 1698747113.41126,
                        "modified": 1698747113.41126,
                        "name": "/dev/shm/incoming/2023_10_31_101133_00_00_K_104.txt",
                        "sha256": "c2c71f3d0bad5fbee9ee3d16b6acaf0afbb21b543aa4dfc17aba130485529425",
                        "size": 2046
                    },
                    {
                        "created": 1698747113.17126,
                        "modified": 1698747113.17126,
                        "name": "/dev/shm/incoming/2023_10_31_101133_00_00_K_104.kml",
                        "sha256": "30ead945bcd87c529f747f869467c75508bafde6a867b6705ba69c2a6558b980",
                        "size": 977
                    },
                    {
                        "created": 1698747113.49126,
                        "modified": 1698747113.49126,
                        "name": "/dev/shm/incoming/2023_10_31_101133_00_00_K_104.jpg",
                        "sha256": "14ec6ff7eac3f5ea09272e029d790c72c6a33a3b62df4bd237d4e45fe5a53e7f",
                        "size": 1063277
                    },
                    {
                        "created": 1698747088.04126,
                        "modified": 1698747088.04126,
                        "name": "/dev/shm/incoming/2023_10_31_101056_00_00_K_103.txt",
                        "sha256": "bede72fd70b071a37d8d7624596400ae7abcb5e4fc89e52a68a89229794825ef",
                        "size": 2039
                    },
                    {
                        "created": 1698747087.76126,
                        "modified": 1698747087.76126,
                        "name": "/dev/shm/incoming/2023_10_31_101056_00_00_K_103.kml",
                        "sha256": "d5caa93a83d488c2cf9f31eb1b971c734503df90fda387479cbeb5b2edb94412",
                        "size": 979
                    },
                    {
                        "created": 1698747083.46126,
                        "modified": 1698747083.46126,
                        "name": "/dev/shm/incoming/2023_10_31_101056_00_00_K_103.jpg",
                        "sha256": "81f29cee2c0aba7b44978f064b64463867d5d81879cc409e61a6489f27aa3ba6",
                        "size": 1326495
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "d22b00373c1d02a09ebb99134f9f4755e4240d78a715aa22b55a99fcecc0a63b",
                "size": 19680146
            },
            "errors": "",
            "filename": "2023-10-31T10-20-44Z",
            "size": 19680146,
            "verified_at": 1698767929
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698747326,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "UF8pupDAnuYG6WX7fuKvUkM8L05YPM0D9ls9Adq2P+iQ6Bp7wEm81kdqgbM/novDo3czdOki8gdub8tvKpBttG0LeIOm6+bLzpq/l+gozJURmWPevVvpRxYbRgKIPPLAZ8UK+R1EQnZHLlFG+2xd7yORF/NK/ebmylUHkM3FLdQ4GiBdnC/69uOQXpY6Mn8OjAtE9ccyVukanlYxT2WG5Kdn2E8Xa5c5jNewAbGAJdjZ+Lvmar0Yfe2XFdVWazp28EiDKytTyBLG1Q86I3/C0Doh/dOxp/AbAKuE4hHXc4M73m3UaJzM+CorHkHG36B4PDMR5S8gNPDFLQ6TcrjLNPygiVDMTa23PkHqm6G/1LrLa47aHnIcW0RMSWLfsJerWFyvPdGMQOkVdDRqz2fQQ5uC2MT7F2LGVKJtahB7XUok+Db0ZNBdO4aPlkKYwwyTPisXvdrYpMYxQiOODU+Y8zeLGbTqMnvhokO9XuqPjTcxzSxu/r7jbb1ZC0pVpkizJ+RS32ZIwgy9COIA6qhh3ifaf+yAe4Z8wLPi1Ll0PcM0vxpNBGil6hD+RkQI//yUOvJH8dOmE8hvZBDFBnqJueTQApCcC3x0VHVQQY2k/UUnpRrDZ11HZ0LkqiuSvIrSYWIuDcrVJlKY8wmtMkBPFgdseffTQ+iPI+xIVO4MoNc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-15-20Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698747194.364362,
                        "modified": 1698747194.364362,
                        "name": "/dev/shm/incoming/2023-10-31T10:03:07.ais",
                        "sha256": "8e777f8c7a9bb02c1e89a2ca54d91dc65ec805e5667555ee444c8fbae088064c",
                        "size": 121223
                    }
                ],
                "mission": "EFCA1",
                "sha256": "f1ad07723212351156ea8a520f1d195496419384c2c3f1d3dec5b2ac91b8bfb0",
                "size": 21643
            },
            "errors": "",
            "filename": "2023-10-31T10-15-20Z",
            "size": 21643,
            "verified_at": 1698782092
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698747974,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "EEJegX70rc70/rcrM8SNcj5xKFdTqXWz3xrHeqVqj+2TlJb7KaMUH+QnFeM1kUhsz8f+F3wWTbjGQotBPv3o3ZiQ5Xl8mEoItT6XezKnzC5boKvlw+E391bWKpCrL9VbOWEWG07RNfXPcJN6GBSnRHbRozFUhStwq5oswLGRmMXGCRd58pAXKdfyNg8iIHPjtDwnw+hWIq65CFLF+pHD1wzjgoSWv4dCXerblGAyxNzsVct2zfVxn/MRys49tQcnK4+OTwAjrCh9Z5tEQdT2H9MvlpkhZD6CWd8IzbzSMZkzIX1kAokVnRCCLP9J1oZgdDQMbiC7QF5/fOcYKEYBh41f/ljj9FtibtJBfwwl+uQkX/zfmlD0/Fph7fTP+1t1OeeAN1883+bKG2ankAhhHDBJQCuxGOBrjKeJOZL5T10NphbJdO3zZBCABnXvYtXMatmN9dwS9rcJN2/mXzPL4EU12YXFomLH9P6I6UOJXhpIJ02dEPMOEf1LBHUn+vOPPXgojAeSveUwnJomYmWLgxleADdW4j8G+9BEuHBYaIu2UOfC+mRqhcIJh/mdAZaxWfqeGhOKVYRyAZlQjK6JQV6u3q5zmPKIqUyBSVOyKKHHZtVHe6h4RosYn0mEDJXmIX6+FfEQ4o+/JsxoxQmzqhnvVx/F2J5JI8GvWeDnx+E=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-26-11Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698747843.3297908,
                        "modified": 1698747843.3297908,
                        "name": "/dev/shm/incoming/2023-10-31T10:14:02.ais",
                        "sha256": "3f466616a8b1d8c752f3a0f985972e782b9f6a6d8348a75352e7a699475f7d92",
                        "size": 71968
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "d3e0e342fd5addcc7753ac9711f28f7c378135505e6a068488dac22aa2ab20ca",
                "size": 14201
            },
            "errors": "",
            "filename": "2023-10-31T10-26-11Z",
            "size": 14201,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698748290,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "wMPgb+Cw2JrObCgvnBQgB3788ITRT4QeHJ8wWndCTCw8Mq4IqE0bQON8gMoR0iGBkgKHv3MUaabahc4IH3ElDzA3UCqHQa03UIQtVA/VLz8dMBbJQgnaPtnmG7jtqskBx3ud+oEPpOX5EAAmSiLxp4VJhJVw1LPetnCu1tekjxiYTpukLZbiRgmzQg9OxooNtyWtos4JT77XMktJl6hsxHjq58KXmTkJEhGeJ+MGbBbincC/BDYnUxuzdG2CU1gmDdkkJFW6mofqMi5/yrmqLItG+dBVkfxc8ji90auoImPwLOPhsd99vtjVEzuhIC16aUfAsB24bHKucFyF/GXsKjC8tNCECx0xhN5hTKZvwYekrCDsk9GOxAgqqM3ZcWu0Jqxc5/5ovk89sBaL8JFgDnYEWdE/1yEzXYyrjrT9Crz+79Abjm1olxgEkygsvjDf86GGjHTrVZEuMUP1NO6Af6yldFh2m3gZIRqPNyqF1Hs0fHd/hLVgXgYwnmy1bYn7KEX0PvQejG4Ev8Eg3LuLTlfHZ9uxoC0oyRw0J+f1cxVKx1menmu890kORDoJAOGPz9m82+d1uS/fTJtEiczp+TjtbowuXP2H9XamPwTGWCA4Mu0/4Ku119UgEWwBLkhG15OdieztA/UrV5N7v7UdJ87wBZIG4OlA9HxcJS3ihxs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-31-15Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698748152.5014186,
                        "modified": 1698748152.5014186,
                        "name": "/dev/shm/incoming/2023-10-31T10:19:11.ais",
                        "sha256": "13514da82108d1cafb206f9c47cf23c83bc02ee981976512a945eaf507355377",
                        "size": 240647
                    },
                    {
                        "created": 1698748043.1014185,
                        "modified": 1698748043.1014185,
                        "name": "/dev/shm/incoming/2023_10_31_102704_00_01_V_055.txt",
                        "sha256": "39c225689ba55889bb3168ad210bc594ef686ae5410962f7f0083b2192ea6c03",
                        "size": 2039
                    },
                    {
                        "created": 1698748042.5814185,
                        "modified": 1698748042.5814185,
                        "name": "/dev/shm/incoming/2023_10_31_102704_00_01_V_055.kml",
                        "sha256": "1d54de2530ab7bbf9c6fe92e36306f3aa66027fae77c54b9c4778a4801deaa7c",
                        "size": 950
                    },
                    {
                        "created": 1698748042.2214186,
                        "modified": 1698748042.2214186,
                        "name": "/dev/shm/incoming/2023_10_31_102704_00_01_V_055.jpg",
                        "sha256": "5ac9c06bfe862e1a4aa00962c2b7ff76d91f9e4f503235079541e32ceec553ca",
                        "size": 1411126
                    }
                ],
                "mission": "OSP1",
                "sha256": "3ac13c067a70b27b3e6dc1560890474ae362c0731384c17a6d4469a0c6c73d8a",
                "size": 1469262
            },
            "errors": "",
            "filename": "2023-10-31T10-31-15Z",
            "size": 1469262,
            "verified_at": 1698766609
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698747968,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "JQ5qAEPc4nY0+Y5PkyVcajPbGvJtgs1GBx25CheIwWneX2b650oqABWjy/R4ZiFy6jHeG1uPErcuOuL4nUBNNwiAFvnWOq0qZTNLek/sh7Vrd0IzQTzMIXHld1gF9TfJ5bXN9+9saq9ODzPdnFAARYCYRXwQfbjT4Hciezeuv/dD5U4ry1bFHB+DSOdELPPfRT6qEMnH6mGFFrOy2gAkd2efxD07d7SBog1oVg7uiYfAXjLot5yEH45NSK28NNpABX3+qi5G14h3ivHcwGj2LjMsWPJEf9JHXubL6tufX863+ogh/gk+CEYhPCNRRzhP/f0QLRQV6D2O/d73rJRc/rmxZkKiEcESVxOXcnDyC0gbTSDsvgjjOcVCaz7+MKBd3h/SLvbtZhdxXxeml+h0azNLdCEgWDz0YalItRlmJwAvxVhhLHg8QAa9IT62hKAjV12uXDcDYbRyNhu+SENQP8dazgaLK3NNgKIIYbgdF8Sf64DxU+iJes1M0jxTGyuD8KoZponbYC4HGO1U7grQzZGPN91PeNCBaIyA+ryRvYYw4zK4d962+oYKQ1Iq02aGHKld8/I7o7IVPB3pYkR8zo6GsLrnme0Rb88atKXAq9sZ9b+z/GgUpwIaMCkdMtzTMOgc3oI26z1OHUkVvW2q3GbZsYsAMUXXguNWvY6GQh8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-25-02Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698747779.06126,
                        "modified": 1698747779.06126,
                        "name": "/dev/shm/incoming/2023-10-31T10:12:56.ais",
                        "sha256": "d4d705547438fcf8d2aa0004b4911bdbd1d7e3810b2d391eceeafa0311f227da",
                        "size": 110724
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "680c53998de289e6cf704aa5d8a671a09239d71a5609d7ec4f492a312cc142ee",
                "size": 33378
            },
            "errors": "",
            "filename": "2023-10-31T10-25-02Z",
            "size": 33378,
            "verified_at": 1698767886
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698747936,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "uny+KlwJ7+aMct0mDU0Sw3F5mMY9JG7WuP8CHwtgMXg1WAdGSvPjlp9Z6qKnT/cRD7m2WeokeMcUsyARNlJjz2xwTuwLxFDeqaqsGEBakG9uIXMrhMbklU9wmBTm0J78wWpAB6gfbm7HQ/A0JkHWOCdBJAKoGd0G0GQ0RlWZhJs7Azp1SWbDCS2nzAQZrC/9Q6h3XhoS/dxTR/FdrT32p1WyTGVWoo6cKqtVDl/gxEUo4NSfS3MtSM4ZGzpRGBuW2As4CoH1X/mHOT0AUAeVrQhiki1WRte+RxRB/dJMD9+2eaDk1Cd8ISgS090B+leFbKVyVo0M8VkNp2x7WfrHfYlBCrZGGknbob2l6R3+Ml1nUJBYbYJjJolp+IhKKDRvMy31UXAHtB8pKLL++l/gt6ceBc9WyZa/RPQvu4oYZVXA6Sgsk7lsR+tWri+FrKqZlXX/P5C40GcpDms5Qb8iQ/ojd4N5ffCL+cc5H4lvQzphjq5I9Iih7mqUapiFk4eTfttO2Sxkq+FaSTzKBFA5V1GetiLj1flfqReHNN0V93qf705lEBpZ7g8H42IbF2ceeZrYkcQOw2RZxClioWCu+RRmdFgN+aHj4hJUZR78TTOkvz69FyQTgHkYE/zpGoxPVjHwGti8EROiuy6mMVBTxH1F/PCA/X8HmCkSYqr13Ak=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-25-24Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698747796.594362,
                        "modified": 1698747796.594362,
                        "name": "/dev/shm/incoming/2023-10-31T10:13:14.ais",
                        "sha256": "d8af056575d963b7f3795cc829ada4f57b81b52c45ff376e5e63b7c4088875dc",
                        "size": 133668
                    }
                ],
                "mission": "EFCA1",
                "sha256": "14f5ac7a24355189e306ebbcbf5eef2c2cdae2ff50495f2a3aeb57302f3c1693",
                "size": 23319
            },
            "errors": "",
            "filename": "2023-10-31T10-25-24Z",
            "size": 23319,
            "verified_at": 1698781913
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698748572,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "GWgy8SjGFVvYA3vY8Ou9383qVxosoRRc1toIs3QR9qZktuLERVQuuuqZhiAK8LpbJV7TpM3E+LMUfSPb4rVkh7cp34HOxOuClmsoyqTNgYqKC+ffQKyF5jNbIl9rJMOxTdBw7UZfHTBqk8Y09OSM+wZGo7X6UjjDF6gB473zVrGtE8wxD70/9sqPWxhTkcZ9zFlkRxGdDVkKz5QszF/XkHfu/N52DuInK3+h6rvI815G2U+Tbrq/XNAkNp+mH8iOlQkV0ikt7yCkjFlhtoNuLzTWdKjNQYoYYCZ1Z5aej2v3UpFPoOvNDNF4hwwyYE4AQq633BsmUY9AbXTtQkW1wksr7Ajv+FCnd0pal6/0G+I6FYtrY/RjE/uL5lfC9wsfuduwSafjI5W8SozZr/c4TXSphbWph3anOkOAifllqbJ0QaY5DJMywnbDm1Mi8N4pSsXH2LavU1MENnvt3s06vlGX6uXjS+LP5t29VC88dO+1iHt7M2atU0NzLwumcnQ1NlrWHGT4WIN1vTVuy0ijMbF4OqP0xMllrQ7V4X5MWDc6ftBfxv94C4ESCppM1Fim/EwKlEAkb1B1Kv44+nGcaeG2Id2SWACHy7BQE9phktI8dWWZjn/zXA5XikcIOvW4dhi46TsuwJWQf9QKF35AjFJQscK1nA9ewLtpwlpPvyk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-36-09Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698748444.249791,
                        "modified": 1698748444.249791,
                        "name": "/dev/shm/incoming/2023-10-31T10:24:03.ais",
                        "sha256": "67d3e96421daace005af9073398c58e4e823031309945f7635c9415dc3eb83f8",
                        "size": 113597
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "594dedaab62a088d3244d07648d9d645627ffad4a18fa275d0dbf2002d9806d1",
                "size": 30472
            },
            "errors": "",
            "filename": "2023-10-31T10-36-09Z",
            "size": 30472,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698750649,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "YPfWcjvXRsRhf43Xpfmg+XT9U/7rIzfnno72TskTaWUPFox8+qGtpZOr/9G8TvJi4vR51H4FPI6+6sHJn6AcPrI2rGI+BpFSS1na/UG/0rAE1btsFUu0V331ldqlWT5ol6JQHX2nTggCmR8kjU7GVjT8QfWgThjWfC5Uerrgz+bKM/MPMArExbVW70jaSVB6He83G6nXZ0zDl3Sp6R5WmpRHlYNqJVAGTbaXMigDWAlXYJbnWwQVFVrAdxArr++RbMIdxAyYZIJs1fkpaZNYr1xu0o/ppLZvJOtNOQ/08v+na5BLhJoNxUMRJReUQbDsFJ4l09D4pVDNx4ylkZCsozf5wE7BnkK431fXC/bzDqBsekacwXKHENI88jua9vgTkEtoy4NkmgEwyDkHbTLyY9LFYifPB9NMq06fhyKkdLRuhKTjxEKHA579tdLIZirF7CMsnJ2hYMZRc47vL+i8Fba+bvojfKLDizQgmqO2X9Of9B43Ny+Dd3hxpHJn1XZkcVa2JkVZnMSeL2M013p7cWvvhchjO+9c77v9/FgzRbfygqDDBRAajQXCdc66hVCShL8yZ8wENmwhtikVaIY6uhiKS4d+YS9xUs3odAEOLQ+RUnvi0qTctifB5TUjPJcQW1wkGiKHmoi5vnZ7je5+dmZp8x2zMJ8bAsVZAdVkvbA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-09-39Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698750556.5414186,
                        "modified": 1698750556.5414186,
                        "name": "/dev/shm/incoming/2023-10-31T10:59:15.ais",
                        "sha256": "aa5bd2533ac6549430906f33bd72d03fe10a2e54b02208e446f3eff0b685b2dd",
                        "size": 230647
                    },
                    {
                        "created": 1698750570.9614184,
                        "modified": 1698750570.9614184,
                        "name": "/dev/shm/incoming/2023-10-31T10:39:30.ts",
                        "sha256": "8690756e1c15f8d88fb9190e2221903ef46b6d5fa46763ab0bf3fb30996b1f8f",
                        "size": 410694272
                    }
                ],
                "mission": "OSP1",
                "sha256": "278c6568053987167dad49dde31f031f3e228aacec9dd88002fc9267d139037d",
                "size": 396235142
            },
            "errors": "",
            "filename": "2023-10-31T11-09-39Z",
            "size": 396235142,
            "verified_at": 1698766356
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698748518,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "qamBClDRWVyUFvpySjy2GCVxjLAWdhHhJ4DnWb6Wu/HjrvRG3hti+bxodOf08vF6Szn+tjjBT0RpIeSGx3+TC0EWl3EUS0qA3bY/wKkGW19rbyBf113MV7ATNhrqNyfsLfNDWGI8qV+7qI7k/JJKyegQSoS11NMFb9WEREKpYlR/YPOl5lvRoljxHVgoYMiFS/ol1sUjYTQBGSksCgGHzD0LA9jagWDrcqRA6sW5HkZuVtVGqtrHN/upEFJM5WrKw69JoyV5P1JemvCGcgVTxXLwfa8O5ixFsDL+94RTJ5BYd2jxvebFPYdzYo/NkCFhJh1YjLPTi5scS9L1LnPKBYzKaPnq0JynNLU2XGRZQVIvzb5ElPQfEXNgNCkQtuAJoj/8ydfhQB/p1P6RnP7MCjgc93hfkODLRdgvadXlnxa1YOCRl89yzrisjGh84fU7bLUUGLUgheRXzoGfWe9yji8A+j5vTrWeYUwtCwuPKAk21HVZC8bjA9zkOuKHYWstDXdpKB+RLFOe/5T7JEIyxNUg8JXUlcukd6qtZ1PsUGyHkcijZ2omcaQ0uiJ5FiI62RUgknzMcPphdpOWx5WwdBtuWdsLLvZ7OFa2ns6Ulryg8pSHmezZkbNGUobvIhPJy+topzagWVJXLHOfAc6dpge8nh7bpP6biqa7XeMvGro=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-34-06Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698748382.32126,
                        "modified": 1698748382.32126,
                        "name": "/dev/shm/incoming/2023-10-31T10:22:59.ais",
                        "sha256": "90ca43a2eb07256e976fe6a4326d034eb99867a33e0627e61ea40292be85133b",
                        "size": 174611
                    },
                    {
                        "created": 1698748437.49126,
                        "modified": 1698748437.49126,
                        "name": "/dev/shm/incoming/2023-10-31T10:03:57.ts",
                        "sha256": "ad504e1abb7005c9e119197a08fa75630d592a2c8d2c5d3da618c038441b4301",
                        "size": 440754344
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "072fa9a7cb281c7b57ae6a0c9c0fee762cea280a89e4a8830ad4d9437c53e5cc",
                "size": 397673805
            },
            "errors": "",
            "filename": "2023-10-31T10-34-06Z",
            "size": 397673805,
            "verified_at": 1698768435
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698748529,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "NMnXVZX4chvMbin/jZuNJUqmc46NzTHqnn8gjwTNb0FgZY6eUpSW4KG3wkM4We+aQpCmM19FNcQi3+2Kl1EIxjT3oKDkq0BeTt/yms0rkqeRR13HxuSWH0h/3c/LOr9oOzaOBxAP1dO/3CR40/j6SSC6F/RVOANw34ryqUjyv904kp02DDMlgb1kfJ/ADSq/RvGGUI/eR1VQhQVsrs2mgCwSwX2kc0t9OSBOFsv+4P2KJ/hNSFT+v12khSbOBMUIDMGawQOm00/ivjWaFPhpx3b6RofOrIPuEWVVPJAdHGF2vWKc6yAkmnkvhqrI1gj7qawdLz/GEoVDr8maQ2l2YmhoT37X8oMtjrYQQnlSk7lZqEJ8+n2PnT7jOsPsB/+BKVwXiaFVW6Ep8Uoc9wJICYEMCrl0Fp6PufSjy7o17CRgToezgtUhsZ1SAz/5ktZcUmlMN354APwOA+SrnTj3727oeprka3Chr3VZheH3g8ePYo4vEFmtIqSv9INY0uo5oSuJaZnh3M/JZ303MApvbKu/B8ELFy7Mxw1vZYgtRO2mdMIBzrKGJWAKxnE1utpvsXICSkpRHn/Dr/7cuxiMiwsOwK6IbyltVeekaIqcZ0CHg72kIktoU2lmpHw+89w64tjf8IdybIA2LiVhKNIVEKSwHBP/99hnIsve/lsCUIE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-35-21Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698748397.7243621,
                        "modified": 1698748397.7243621,
                        "name": "/dev/shm/incoming/2023-10-31T10:23:16.ais",
                        "sha256": "b3a94cd59bc30ce84ac4f3aeab19ee742a8fbda47fc263347b215bba59f0ee9e",
                        "size": 146305
                    }
                ],
                "mission": "EFCA1",
                "sha256": "daa75a4372857dac2b04b64fd64f3a8a4dde111f95345dc59cd7148ab78484a5",
                "size": 27874
            },
            "errors": "",
            "filename": "2023-10-31T10-35-21Z",
            "size": 27874,
            "verified_at": 1698781888
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698765804,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "kxkPd+9XF1DYD4qjeTSaNrbAPoA+CBZuS9cPQlzb4ULsNTp2GiHW11q77A8fOExvOrIjuN+G02T4NLVgMxUSzFmOCGjKY6XusW5tMJ22/jDuYM1UQvsodSjlAwqf1RjaSBWaZzM+PZDznGW3mJYj38ua1EQZe4mUsh2Mh4/F1cu3EQoLK8t1USeNBImLZYV5s+c1O8mHwm9NupPQT2pi5hcKqq+Q/I88PhYFqE5T477mu0rIY+csmHTlGoKNMBj8aOGT5PNPXODZxekVuTNR1pXwbchmlERSrjlnBVm+yvrzebNSzEQOZ191GiVJVQsSnunRh2OI6VXaRKW2UZWkYsbKNMQVYYUvQvGtd8f44LZUC6pqYvTTgKOEgkPKks1ul8Wsz9AdwbPb6yuqNHEZ4T8HqNxNWRyclnkz5namDZPYmxA9M7ahCE6LinaFMvVEoWWZmG46paZ8qreB12ckFYVzcnxnupO0LbEp02Zz7M4+IvAXgxLAYZIdtk+/3gEzhn4M5tNr5vFPSEg4MJ0EQlq/vW5z348fwApiGRdBOrPVONdXIJXynylf6f38+6APtVHI86b8R5GJcdZ8giZmOrMK+2bW0R3swCDfCuWBG1DswmjlY+qhPN2j2IR7SwaYHYapHLIHLm4n4egYUR+yjeDSNl3lq/GieIg98c7IMF4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T15-23-05Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698765660.183036,
                        "modified": 1698765660.183036,
                        "name": "/dev/shm/incoming/2023-10-31T15:10:55.ais",
                        "sha256": "ee95858a8474ce41e87808a6cda83425e44abc74b2bf04e1cef4f5ace4958aa4",
                        "size": 71373
                    }
                ],
                "mission": "OSP2",
                "sha256": "48366c7f12c93fa6c71789ee0b02b35e090d7305e99b18fe64cf62e3dab56ce6",
                "size": 16136
            },
            "errors": "",
            "filename": "2023-10-31T15-23-05Z",
            "size": 16136,
            "verified_at": 1698783412
        },
        {
            "aircraft": "9H-DGM",
            "client": "FRONTEX",
            "contract": "OSP1",
            "created_at": 1698748836,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "URxL8Oc1NuSQT5i4tk/53XxGJ/816zpjV+qs4q5Pcs0SiXwjlwI1IKeDAizWuStP8HV8gdlLLboqNdJKIhRzQOxV5zvP6dBjXxqF2grlcH85u+BzTx4hshzr5cD+mLy7k8NxIv5WIPfLzZPLTKhB4inakRp4Kufi6eFSO/Z2XzO/ykXMju3beBoBdlQOL7YLozLg/+MCqEP/tHfRaY8ielzDRmZboilSGCdj6wICIVI6WBeBo6D2N+B3befreeM+hVB9+uUBut96aO+fviiforaM4zEOjeeDDO/PK+plHtpZX9ovmvvPGDgy0OqoQUuwRXJBZmi0FE0Fg0YFr6zz35/idRk9yz38pJ754QovEKMDRntvMnvawmSdTq+JFK/TrCnfvgUjaArpY/beZSHo25t0dTiWX7pILXZPSz6jqHLNyLFnhE/nmM7GRr75k+/uG4nSWnT/blwGzgySbXmEiIT+xsiBnS1F1hXK0rDpfum47aWZbUVJmO2OdIEusSDC1CVI4BambfKqK4JCl+hJnHy+ybbUOmt6Bz08Jw/t/O5nMgrUQoFcM0cJ1FqYinxXKI07ZPrLUPZYuF9LTFl5Qn6m+IdQtajIQpACcZ+AuccAjd9IJqtC7xmIQT5tzaoMGVUJNyqftBY09ehrsl3yaKA03L/JanoVRuk3Omg8hu0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-39-34Z_FRONTEX_OSP1",
                "inventory": [
                    {
                        "created": 1698748753.0414186,
                        "modified": 1698748753.0414186,
                        "name": "/dev/shm/incoming/2023-10-31T10:29:12.ais",
                        "sha256": "fa8bff2aef2dcfc7676d36ee86bd6c6378c5df05dfe80ccdfff9378474ceb2f8",
                        "size": 235742
                    },
                    {
                        "created": 1698748764.7614186,
                        "modified": 1698748764.7614186,
                        "name": "/dev/shm/incoming/2023-10-31T10:09:24.ts",
                        "sha256": "7ecd4a4a7cfe866c1a0ccbd8f3c51a91937c021acbf68aaf2f65a4d7490a1bde",
                        "size": 410010140
                    }
                ],
                "mission": "OSP1",
                "sha256": "ad3c48f143b547fdfdbca563d77068bd3d4113ea0f90f539371a5c5d68ef2e48",
                "size": 395597563
            },
            "errors": "",
            "filename": "2023-10-31T10-39-34Z",
            "size": 395597563,
            "verified_at": 1698766121
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698749177,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "CQevV31NBg7B37dopAO/5pfgW5WaFwMN7RJvic80qUjg0pVDnLSM19IYcMLOOPHM7sPIglrbJp9ptxPFJpTur31MW9atKcfQKnSXm9cLYtvdZ2gvKPhPfLH5/DAk/1QhwTQp2dXQcuNb0USDP9f1F4314e04J6btU4k9wInMxgj7/LzNMo2sdfHIW2wzy2b7ILfNEngul6qmlWpAwQ9eUmUBJcip6I6oWvLawTKreHHn4dL1dqqvMKSXyY3H4TSQLYP3AxyV7TiIS8dvvP8/evHquMBFuiBFhLM+xD8tVApp434z8Iarr6FVo1oKE9vDMdnI9SBAaJDRo4/NT4r9MhIToWp84qOfIUk6aZHvivxOtMPb+DXOWylLF08mfTsFZp4MCSkSOkMuddXUFXOmpp90yWkLQZDxag2pIN7/pp99bk6jJ5Njscdj+md+8Nwo/2N8KCJmH/rP9uvbzIwVnvRjE3Ug6d3so+uexL88sRl7K/rTsY77ML80Z2zwUiFNPsJD+EAoWuBcsF+XErs4Z5A+O8RFBp5d5Iw5Q7GJ1dIerIQmbjj/XXc+gTKUgVVGxtF1ZgPqW7vLNI3DXpjBRT+jk9vnCXbRd2TxQYyDiUKClvsJXYlcLJyBlY0wWp5D1/vP8ob8jBOEUi+HjWVzGn1Rpl9wLH52iQB0OfXI1mo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-46-13Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698749045.0497909,
                        "modified": 1698749045.0497909,
                        "name": "/dev/shm/incoming/2023-10-31T10:34:04.ais",
                        "sha256": "7aacf92003ed41aa577019ddde82e9396f153928f81dc3fae55616f63d094d35",
                        "size": 178061
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "0987ed0932d7e9451dccfcf49624a249590db78370df7e98e12b3854968f3fc1",
                "size": 44205
            },
            "errors": "",
            "filename": "2023-10-31T10-46-13Z",
            "size": 44205,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698749145,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "f2n9hnfbyHhQ1bmXkznozygsNJmR0Y4sJdYBlbCzCVDDbj8bldJGBf2QzafgcN+i9QULw3pzGpq6aSNoMZelnKRNpIO0bmSfmnqZEh5MjOwhp92kZVrVgSpEP8N24kB/oOM7NCKUlhAHAtkb6+PaoY/sS11lEBTtZYfs5x3DUP8kEUyCMI2rRG36QyOYB0en+k/wklJhNgNcB8RH7h48IdZGBWYkz5k18bLoq7VJz3o2//JBb+8DqSTvQiEy56YelhnBqBdC9tRM1wmD/dG+JqBwrwO7vY+KuwBhxaHg25m0zeW66Xr9bkq02QVMQpNESingtXZdhEQPhzmO3gYohsL2D5xgJFhaMIa2phACOgwwxC4cXAn5Ts1028pWWyXm1ctphqe17frkyTPxGZ4LhKyTH0pFS/GkVXAk9DLX2TwQCLLivrPUccjHOdGwK3c56TV5O5nh/Br7vtXlVzmiVtwjxF4Vwxw3tQxBn8XynmkTah0POBkXiVyU7E6ZBOPu0uHNiq3rzLT7kNjedyeTYeSjYBJpopQXe6/DPx8PDIjZz76Nk9TlsIpv9pXAwuVf+9pZHuBr23GDXzdesiDOYeeJPfwlmls4UesbSmv/5jZslQUbM1tTFxmeHx9j8OBrridw2powlN90kbtAl0mpYmFBptpSfH8xkW9/+MEI0Vk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T10-45-10Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698748983.35126,
                        "modified": 1698748983.35126,
                        "name": "/dev/shm/incoming/2023-10-31T10:33:02.ais",
                        "sha256": "6e9ad476b3afa3f978d555bae8db4490cabc61d3cf89835f04de8ccf1ffe19d9",
                        "size": 199372
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "56613e70e3d20212dd64ce9ac569e220dd391928b70d63ae8cfef37ecb0ce43a",
                "size": 58615
            },
            "errors": "",
            "filename": "2023-10-31T10-45-10Z",
            "size": 58615,
            "verified_at": 1698768006
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698766417,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "lzw3pjbhvQ0L4WQZiYcQUjYpkXYj+BroyxRhhTSUVeJrLs46f2lTTHtyrEpuYJyAZorqV0Jswtb/Zu2VVn+Mpg5qMp5cGziTuZnnwaDy+svFf+pDWLGT0H5R5++kID4vnWVd0tQYyGGUnA1U6VHYZyLE2vSgOntZ76fabE4hpv3zD7QMMj3u/u7+ynM0HSHjCFoa22bdiKVWiHrFAhXJxDJ+hchOBxscJDmq3b4oQO++opp2+mfpVDCwH3qluxW+XyCGwo3OrHlUG75TkUuEgDQataGShGtqRZcDKYRyCHLB5cn3jn4y3twNqx/JanEvT+ppUTqeIpfk5qqonwpizqe9J0FucK2nNDBRWIVePNfljf038HzW48uQe8h933/Rs86u2SiWccp5SEN9bQVARcHiWJgXdGOPfRLMQu4NcJyMX3rvG+mL1Jih+svAKXLrUB7AGJSFaaxtkrVUHkHmdmuoWOB8QR9t0ym9lzNQ0drV2r5oxe1bA5w/1tQUPfD4zPgjumA+HqnS8gVsMGCxFidyd9ieNJvgwiV6HjXOhPgmSHaF/vgOVvp7aWR4Cy+0vuZ09I1+GelKCO5GXvKtpQXItiBtGx6y8UGXiT16+0vskDibbe92dY8VBhZMWeHY04dNINSEgSheUN0wEyD2VZFvi3VFvegfkNeyWBj8V1I=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T15-33-18Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698766269.3430362,
                        "modified": 1698766269.3430362,
                        "name": "/dev/shm/incoming/2023-10-31T15:21:00.ais",
                        "sha256": "9366dd60989e77582680e0cf17c61e6ac1cef08f8d0aa9b55a1caef9d552ca33",
                        "size": 75540
                    }
                ],
                "mission": "OSP2",
                "sha256": "bdd393830a33c3f0974eae16ec99fdd7caf5e6ba732250b3301642aef0c4bf56",
                "size": 16691
            },
            "errors": "",
            "filename": "2023-10-31T15-33-18Z",
            "size": 16691,
            "verified_at": 1698783417
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698764594,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "g0LzTDQvoR8FAkqtALdA3U7ot3tTA1fBfpWct7K92HLoTfruazmz+FXfbNgMKYlc3sp9uO9MWv3hLwADVeSvQHI8gn/iM5aIfx8t59zU8o14AHZC05mxfLejSpTpmYN8yGKw9MNJXnaB5t8x/x//6creMi4PfyUO1PDYSb5erqYcr9RssjFo1MblwblCdvwtf0S/fk/+o6o9UEsXM6bp2H5WwrOtX6+JdnCIJRZPvpZFuMiQCNOqaYPKiobbUds6TZRPMlqVE6VR2zi/f8Zu7nXEy7QM/8eAwT6xt0BZQaSB6/GSdbuCQcY1f83074SXEfy5n5AuwtoQKMkCuz4oHgZv6HxcJaq07UyZr4G9ZSAzTALdzpXqJh4G1l2dnzCGTr8CPbw790XGF3xjQo/MsqMyd6pZuqtHgI7f7FSm7Q51Unl+lbt46TCD31ScdB7+UXpH1HrmOTUkyJ/u8ZIY/IPdpyMyTV3yaceLBv/tiAokBZ9pUbWW5BX/IzVimDLqKqzUqZTKSyAT61pXV+0xkn8dqbAD9w4DbD/qzKPnqxf/yC9X4JI7C+O+g78gvQqauPaaHVgP5vMbBZCXf/gkpJMwePkUbrQ9SS7Csugg+CeXhYcz41FfnM1JJdfOn9y99KkRnPTvqrcwSRU0NjE1E1mw5G7CBPC9FGYot/P/Pnk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T15-02-57Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698764453.8830361,
                        "modified": 1698764453.8830361,
                        "name": "/dev/shm/incoming/2023-10-31T14:50:50.ais",
                        "sha256": "5c33e910ad888577e674b962cfe2bf7123be6dad58fdec8343fcd2f740d6be98",
                        "size": 85081
                    }
                ],
                "mission": "OSP2",
                "sha256": "ca6121185b4c030b9ffbb99488cbebfe13fa46eb70514f8204005348992782d3",
                "size": 19523
            },
            "errors": "",
            "filename": "2023-10-31T15-02-57Z",
            "size": 19523,
            "verified_at": 1698783444
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698751919,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "v3ToizVva8QggXKFhxgesAIGisxNtK36bIT0t6nLmy7KjNz1eAD7p/kk295SrcByk4ia+nvQCNI+6VYiPuRXFg8fl320R3maUCRQmFjJPCiB7b3tu9gQrDsuqTRWWPB4iiOhz9pLYtPu1UWT4znkYGGnivbiSrK0BGmXUCmVTSJe6+rnaSLyE6dIFg86Qpa96jxt8K+BMjrl0F+f0V+2aAf4diA85MkYcTsu+wd1VvM9pKOfdxJUfe0YoJqbQ/P7qG8yCRYEpUNTrhPuLbgmtLjI7xQ+rIfoCSIbOHxZ7uJWFsta521Nh+/exTa1ep+f3NqDY9x6ecRvcsubNk8iO+dSmLGScByPpzAAqYaoynSd+e9SPeED41BU/SQr/UriMGNPGLue9P3I2jdPgvrJ13MvSA8tKk4Anw4++5CCw06A4/WrAidGvgE47wWdFaMcRn0haJFfZ94k+PaAWPyhGlUx3S7KdXGmBF+33FznXt3oNLxFUzeR+bOqabTrsKfPR0XjrftUKy4EY8A0SHj5YF1NfO431wF/8Z3YnLfu5J/PxJLbZc/KvLmMsFg4qMZVy/s8K9tXXt/3SHiKVzK0BcpU0B1hhzrfQjC/shRFWwzpEirTzFh06/7vOp9HDoaSwQ9LJ6lHG4ao/61pCkkd6Zy8IzckI74KrCO4SSvlpVc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T11-31-04Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698751853.604362,
                        "modified": 1698751853.604362,
                        "name": "/dev/shm/incoming/2023-10-31T11:00:53.ts",
                        "sha256": "45baede21cc94e85978f17c98cb02d9ca38f5f61e0541f1e25c1642f71dd85a8",
                        "size": 409268292
                    }
                ],
                "mission": "EFCA1",
                "sha256": "491f24e27f650ed736f608642a7fa14a16dcd8398fd088fb934082dc0b1cb8da",
                "size": 394570443
            },
            "errors": "",
            "filename": "2023-10-31T11-31-04Z",
            "size": 394570443,
            "verified_at": 1698781934
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698767621,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "LrSv84BDNWRzcg7o3cNrjf/0H2fwJUDxP7Lit9nq4+vQD6x0SysD3ACU4ndsdl9ofcfdmc/gHi0J1J+DdzYOAea9nHeg665PWionoUI6S7EhKTA/vUGe4ncfgpJsCYb/U8aDhBQW/lprkuAFLVWE9FA+6W5p31ZgDxQt0T3NOcV0aW+WB5QzNgwIJpQU1dKPCVvHGeiC/EdxBf8ZgI5aO4NYpadTJR9v+2m96ITxDawsLtRpurSflpwmJjMcm1GyDdmA0jC2R2hWzOcnC4+rETv0frhS1IfsGi6rExbBdDl3+ikX77NEsuO9zfI49+Y8funr1rP7iV9PLd/rmuyuo2OP6Q/WEWJa0/l+WlYVxBH+88sdng/o4QAe9hcHYjmDS8k7C+3yDPkzMsK1UGtlV23VQxNLxJRrP606RyTANAhmz49/PKXr9BX3a6C4ukE8ruv1TWPTGxQC+rkJ8nJhGNOfPO3jcqUZcOAXsZEIqZYtwZtwBINKfDgy6no8hogQM/xUGmyQ5xRudt4KN/yiqdbzJimXcnjl4RGbmHjJjUfE341A2P16I5cfTgM3gIl5rHKt5NOp6AwWYhQxP/phaVC5KJPywdFBNcGnjK9oJxfQ524Fli9loB7Jf6shX0ApYtdJnUEmVM7I/ESMRzlGc8WnEjgctxcrTskI9iJz5bE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T15-53-22Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698767477.6230361,
                        "modified": 1698767477.6230361,
                        "name": "/dev/shm/incoming/2023-10-31T15:41:14.ais",
                        "sha256": "40db5159d5697ad6a153d6e329db722cd601ceb1f5e8ec2acef3f537ccc9ba0f",
                        "size": 65566
                    }
                ],
                "mission": "OSP2",
                "sha256": "57ca2dfe5f11a21ff86ba92b73500fbe2fd6bdbf603600e6d7bb760827b39034",
                "size": 15971
            },
            "errors": "",
            "filename": "2023-10-31T15-53-22Z",
            "size": 15971,
            "verified_at": 1698783482
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698769436,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "q2gelvGLmWygeTqdi8/FQ/YgIA+2R+TMT7t/QwsrqwQPjJuunnztVQDmwKdhxZ9Bix1KrV+mXmUb2orEnebAi8ji23a5WFDC8IDF0jZz1/mvjTFnoqp9bzYMyGFv4YYNNZOlAIg4MXq56oLCKSAU7zqRVRZbalXLiK6RQUO3r99CNrGGtB4EH7BOBM9T/qf2+NVaOBQrD6j7x/9U8+TUcN3X6FKbu1m7n4DU8Y+bYYIpyinlPEv9a3KzepSFf5yMJMpt//aEarmT8bxa5hR8kK6sHy2JEXWDeqcguZMmHKLwXPn3aNU1JADCazK6kKPSqwZnxj5H/rFw6FQVH5yl2+7po218HRyt5p1lShwBuelDKDT67KwQvJp5a8gy63QHERkeIg1C0ABLSsQSFCteQSgeGZiljXoRA3SULDViMNDmn1OiPt89cgut/wXV//+PEjukJqyvoOyk64Aggn/lPx9N+m/stxlWATUoXo3pqZozw0CSWhIdkzyQOUFfZMq/2dk/mVPMnqm8zdiKoIZVgM3iLf8qRNlCeHOwtjPGSZGTvym3xXYPY9RHjPQNH0TmOU6Fe5em2rVHlM3EnphqfkbIrcnDJ7HkM6Hvg8t5BGsGusUdr8u3/RWufaynR+wDpigygF6Hh9ilVtm4e9ZTpJw4RUJi54hBG0k5rxYqI54=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T16-23-36Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698769288.3630362,
                        "modified": 1698769288.3630362,
                        "name": "/dev/shm/incoming/2023-10-31T16:11:24.ais",
                        "sha256": "2b923ae713d90d35cb9f67d5971780e385f9fdaaf847fc0b168d8f270dc6f904",
                        "size": 60626
                    }
                ],
                "mission": "OSP2",
                "sha256": "b150b6384cd54e7690bb50cf25306f82c4a83f0f159f654171a558fef1a110d0",
                "size": 13431
            },
            "errors": "",
            "filename": "2023-10-31T16-23-36Z",
            "size": 13431,
            "verified_at": 1698783490
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698770036,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "HeAw/St2ufeIRzA8aQg0WOrvpN/Bg2S6KADBvtu54PbHUJhXvoBm+0L5VFeP/MjM7bceUGRqZEmbQdC4VVJq/GVBerq/l29H4gJHe8kQu7I+8CWpDAkfyunEtAFy+v9IRTM6BKYD5shh47J4gcjfZPsFgdb4mQI9U2pLnp/x8xklQahNHu+kXa+B2gGi377YAzirF8TR47NUg7hcIcPS//SeNA05MYa3hHBeFAPkRxbRh+PrDIL1CI22mCGAjiZ8fnXZ5YoXbZBIMu7tdZR7RRlhPMq/aU9vDUSUOHH60uMRjMwqw39UjxhxLe60oC/ihO5S1MmJkncTveVW9OnkTuUVzRxiUTbET/D0ox+aXou4mgvxy6r4SEK8w6YEjtm9gQJjgtOC57EGCWU8KJajf1HFbJY+f1Nhr7FO+LEoBg6IRjzIvAw3ndKRpw52r1iKAcPA3rAw+j+AALRzSKln0CtVx+coBCK0DwitdCQlm+uhsnVZgodZVKewVrJyJ+S7H0YlE2/8AHThunU6oiAzvWZ4wGTvcHlJeGBy38KWjR8BqePyL+T/rPQl9oxNxdjzrLJbTQiLOHcIBmenA8vKoD5ZDrVWaR34Yv+owQbVQBgO11Az2aA3jx3VkVA5qksvuqrdv8XUmcXeAxGSHyKg5C1vypi9iIH6gwI3s0cCpGo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T16-33-36Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698769889.443036,
                        "modified": 1698769889.443036,
                        "name": "/dev/shm/incoming/2023-10-31T16:21:28.ais",
                        "sha256": "9268ca96565c01f87f387ffa69c988b107446f3b546953bf74370a69ed8edf21",
                        "size": 60091
                    }
                ],
                "mission": "OSP2",
                "sha256": "ef33731befd5fcea7796d22b6f729961f4c9c797208d7f89942f0aa8570870d5",
                "size": 13771
            },
            "errors": "",
            "filename": "2023-10-31T16-33-36Z",
            "size": 13771,
            "verified_at": 1698783492
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698776345,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "WT0fbNxRCN0I/0mGKPiMB0u+zRPDR3jOjQv1evQpP2A/5cVO26SbGBXFq65qQlBPK64s1ZJZxSJJKjMKNpsUKJ+r4CFeDUYs9gQ/YYyNPjFUQ1SKxYAyeQymFgys0CLEbuOn164x0j6lfJpVz0AU2AAXINqx+dTn1gO+LglTbFOMw9FADf2MiQXteiQeF2/V5MQpcafYF8xLWQP6nJu9ZajpxTgNyzaNjR0vVMYKyfCNPpK7usW6Qe+zIv1an14F3a7zvMwPZ1SB954QBPKMnQdbvAt2hUoZW4Hq5lA80uwOUcrp7X2hCRMQ5y6NKZH9+HmpizQ3TLv4+xkSEJ2YB9hMsH/6jnDd/+CgN13PRbWzQJhthzX0banc6AQART1SakXTWuWQVaTLMiX1+ApEbLFw/e55gvpGiw1493pY6CFaJD144DKQIkDKK0T6tlvRrvUXbbHnAYRvS/0l0fZP78udZYCgmZOnZPO07k4vyCu7P9hms3ct0kLP2uk+gv/Xb4BSMkwa6OJpAKjFK7fGzW9nTOlb//CyZES27sq9M8rtLNoAt5866y2Ua7q/gdK24HJEgA2qDlDFdJUn6dcyodIB9GH+v04GiQi877/mKPKwV5Jw34fqyYIq2mupk1sgdOqZc77/O+px+P6LvyHm4TadJAvjEHsqJq7/lbA46vQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-31T18-18-13Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698776286.443036,
                        "modified": 1698776286.443036,
                        "name": "/dev/shm/incoming/2023-10-31T17:55:56.ts",
                        "sha256": "6dc0c8477e76adbb8fb861c5b87ff053a8772eb6597fe6b797fbebaf705983cf",
                        "size": 302396872
                    }
                ],
                "mission": "OSP2",
                "sha256": "fba9a59f5869dea78febc1c1feb4111bbb2cc2a0ebdc7c2a2b21862607498768",
                "size": 292080758
            },
            "errors": "",
            "filename": "2023-10-31T18-18-13Z",
            "size": 292080758,
            "verified_at": 1698784581
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698817432,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "E76s5QyxugVmSZpA93tioLf80YuvYEbPgmGCx5fRxMTfcdND5Cuf9YlLDVXdlvyGIjPhcN01nVY/DMrGVNKVW9UGl/glJKaXIO9RqYCvfdkkpd2wzDLp3skc964vxLwqOnso7HzVvRAnXYzUUmmPsBLS50y6PKX+WCUCT1DCagTtkyEWlga5gBQvmaCyRFO2hkBTPgNGHOy2XAhUV6SHSA/NySGyIKGS2DhB7a5Ebmp9lZy6JqMok+DJTqlbFI8OSYlhvRV83SIPPeesLtjQH5bLOFTnhjRGm1dmpjKTc4p09VDv+Q/0agGKS/cPsJpkI6Hhhodjvp4ERy6BGQrG4VCikDybz7bHDCSVmZg7E2ZvozZ7dZz0xFDP8VlZVfSrXVbFLmfiyqqaPCVre8t79gBpeCOLL1p+SSnLt2E5aIhdGDXt2ESQ9ornkeftY11ceH3huDthSPtnoen692VUt65oCN35mDvEUSENRejqyCH8jRGHVvQBH8RUcniAHPgggpjbda4JZRToBOvessahVS0gjemb+0j+uuOeAeSIvqQO1oC0KHuEepBn37KgE2J/WLR31KUPtQdUa/0s7Y0N0GAck4iufQbbmtOFKuh8xvtkwQtEFLFp03IUCMQtk8odLYHvxDxJxOBK8RiA4S8F1GGtQMmrXRLPCHcDeVvZac8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T05-43-44Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698817300.1982224,
                        "modified": 1698817300.1982224,
                        "name": "/dev/shm/incoming/2023-11-01T05:31:39.ais",
                        "sha256": "02de6f02b6a04c99bcb24acea1a281271631f1c154491f53367622e8a75aa46a",
                        "size": 81009
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "888d7950ac322e900d1c95ce4e7f6119406deccb0b5d4a8378b32fc21d7921ab",
                "size": 22737
            },
            "errors": "",
            "filename": "2023-11-01T05-43-44Z",
            "size": 22737,
            "verified_at": 1698844561
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698818036,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "EqwZjZLU9hOOXnNEMhW6d+39ciTr6ArLkuIIJJhwyEejzQTILo5NfCKLOyhwRCeyho9UT4+fw3fuPFatpvpEzIOG2bxuoPRT9rM+4G1SmKG5ge1KlX4en5wKePnG9uWE4g7QnkfOlL6/NRSb6iz1BJU2t/KFpGtgGZnE6pcuTFRzHg5sq5mmV6b4nCmStVQZXBQvQ81d4zRBVBt4CPAqOrE9oeSMkykQquxsemWvTKBIZyAcB5pGQoFmoOscMTLna2UAedCxNoT328ngljjHoj6Db+Dx7c/XJzBVJ+aswSNu87DEAYQ4f7Sf/ZFKCFbXfLYWs+11msurUBYtbyi4VyhGZV9Y5UvMPt9cyC8aq5zm3rX9rHbqRQK6VvlDGUg+1xxwQB0LH3mVG7bFQM+MnS4djB+6yn7rQFSxkPvSLl46ShNr86HLww2xmjtoaRPqYUfe9voekntS6fqr0O8tgeRzxL2+Tpwgb3+0gxKCmy5ExMV6qwfX0sgmwlOml4VZX2pqDj6cR19kMIT83rArMyH933gd3h2Tg8t3zyYV2RjgoWZIsNs4y95nVXMzefbfZdwgUqPl3C882pE8UhYPnkIub/zexC+rMSN+97gKhPX9e4oKQ7tFU/BewZcSN0RXWWZZEnsnEOPvAyz8qqduUAa2AMXMkfRjf0G2h/yqVD4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T05-53-48Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698817902.2182224,
                        "modified": 1698817902.2182224,
                        "name": "/dev/shm/incoming/2023-11-01T05:41:40.ais",
                        "sha256": "a5b5e13ed3fc4afb314ffb82cdb429ebaa90bb768af22e95f7efd6fbbe55594c",
                        "size": 59220
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "fa57406c78ffdaa5a9235c74c9d57d55261da90702da5864327f54c24bcbe679",
                "size": 15904
            },
            "errors": "",
            "filename": "2023-11-01T05-53-48Z",
            "size": 15904,
            "verified_at": 1698844665
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698815500,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Q0sfqtqj8y3+sNn8DHeuTjS6JV2yX3pWzh+4FNTWMlrxG5F5kjzM23hsUKTYVKF0+e6bPd1el7IsFnxHOpxLoYRIcFh2BVWbOA72Z2fG49xlAlQAn2ctMZI3goUjjncbK9opinVAZMvl78t/llpGJnmMr03XEVs3moHGo1r90+UtvG2MORSjpszyPsIW2GsCn4Iu1bgpzq6be8iFaxQYZIgjC0hmSy51UgbgT4BZIauaf6oYTUcyvECzyP2eR6Wc+A7IVZAHOxPQSHk4NgPMV/jehtEPB0azpNXOJAd0FhlTLND+mdP85PBvK7D0/4a2EuxrLKfLN5rcJ2GDi8ZtZPgAtVKZTcVSPtwE2NHHzzxUuey//yec6zd8Mvgb+WDRVFRwlkF2BSBFys58DzyQglWL8qCJb8MXq0wz2eYuOTRyPkDug4iOVNwsUdMqM6XBkBz+zSezT9UWgHBCQCgPYppe37luj9TUafllYcCmWRmCo2iS6EcCVrLa/EGD5nHPqDDVJeoqmS83YlbmQFJyFhPIwREWR+pMwefuItpVRsQdFeGjRSV0BcAb/sVbBMtAZ4o7XEfnST22l0p33TFZdBEj9VqRgZCUdF2BcLq3078GdrLZwVvrYZ0Kp4YNLAjGGu43yrg5HEwo8rCY4hBxcSCDENVflMImef8wzw66oH4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T05-03-24Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698814879.1182222,
                        "modified": 1698814879.1182222,
                        "name": "/dev/shm/incoming/2023-11-01T04:51:18.ais",
                        "sha256": "77ed0ea3ee2e6fd37cada76a41a6cfc5f87bb1183c0a969c7d5bdd83f3fdb700",
                        "size": 15591
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "49193570d5efa257da5056829e5f32084dcfe3d5a321c70e2460fec8da98e7bb",
                "size": 3744
            },
            "errors": "",
            "filename": "2023-11-01T05-03-24Z",
            "size": 3744,
            "verified_at": 1698844555
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698816228,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "q6j6FJwZsIjn1V8ZzEZexuB/JwxmdK4RN8PqUDhepsstWgzURnhLVDK3J0OcoV6uzA1SMX6qsa1sQ6+AmrqSbikliUV6DEOtqmhCJ53r97eS/6zDFuetU2cSVlVktse2iv5zimTHhHHNyt/6oOC5Y62UTDFneH1ZYbVYDMIM7/sHDC9QPJPi/7QsuUAknY2zPhXZaJQRvaJIWsKqOeLOaoLsoj9kiSdAVbn8ujwTnIG2P79uh63UJW2ZLptLlJbJBJfUgBvylHLuDCxVnDrzb1hVrXz2J5xOkx2hF4YJrpeUXSWO1tiUVwbXMU4Z3O7ybqjR5ek9URdu9qpL7XeZjH97Ik3sS8JKDmxal7us52wu0fguZLkAF/jr4dJQ/PV4yzl48Rn8bECgsyJVYb3I4QYdxIgUwC0jE0kkgci51pcpE6QquAKFuayT61Z4QIZpdH2JIAUppHzwhVOIi8dvKviO9w2gSstk6SS+s6eSG+rf1cxFUTDYUJ8NLChKOS2ZQGVQ1ABDDoib4OUxj4Un0iMLbT0oNeK42sjyGS7FQt8arLggz/aF0ryVh4vAePVYcWg/WA37Dx5GLQ5gJoIaC1rOLqC74ryeDSVAKMXdZJicPnHE4jy7q+PSUs7ZfUWeTXUB0ZcOIUnjMjAHBONXY0AL90uzlnjTRBdCn5o0V30=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T05-23-44Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698816098.4582224,
                        "modified": 1698816098.4582224,
                        "name": "/dev/shm/incoming/2023-11-01T05:11:31.ais",
                        "sha256": "70e34ff5ce0df6d3085798c3b21e0ab55c70de40c9900cabe6a51e52d86ff5ea",
                        "size": 10314
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "c9979d7e93490fc2dafebc3cbff389d3203e5f88c52e18c8ed65f0ae940ace1d",
                "size": 2946
            },
            "errors": "",
            "filename": "2023-11-01T05-23-44Z",
            "size": 2946,
            "verified_at": 1698844669
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698816687,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "SnRYQFOVmJzLOvuvJjLfiZN+OfI9/yxCVrR23bnyYvsn2ilLx0PoIruj99bJ1o1PQ5L8Bc6P2Cl6iFJ+9IV5cBELukPheOm5MMPhYqJiabZ3k5bocx6VafD/wVfm/J/8gtgFqZbDLFX8PANGXVNY9LpCftbW/j8RWEGpvzBCIKbOr4iEYM6g4blnuOssSqFx2anp8QV6shlFx3pm6gQgDyR+jd43ewLqKJFGx0FCFrOjKBAnFTQEyejv1Ams47qQoQ+2ZzMalxBbRa0i2KYxFax9Bd75FSSvjmFNowVwSURoNaHCFvAPppLcR2Mi/TybqggZ0gfVgqfA8GQ7P3cN6c6xKHghiUGg0Lwi7hdkDsJQBlp+Ly72dk8MEz1jGnfp3t/48i4I0KUnKhlXlo621ftPyk9oL9eND304SupuvV2FYGRAj2hIFDuy3/ftUpv2kyOvKNXQenbkK7svsPwJDPlUz4166hYJ3kdPFMEIJkQg8+31UnmIGbi0NWbZ0PAnG35XPXFEFfdoRyNvJqeP3pHuLJOIEh1tJgdl/8YstEDqJuY0vZUYUwGrtMe+xpdfgZV5jp8zslGJZPmQMPfJSBAHXvST2oWB2x/0aa/Nj36ti+Ly0OOzNInZRO8Fr3rR2OcNNyY5au8dQKGXBJfDoPuHCNFNiRfMbAk47WNFuas=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T05-30-35Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698816628.6582222,
                        "modified": 1698816628.6582222,
                        "name": "/dev/shm/incoming/2023-11-01T05:00:27.ts",
                        "sha256": "95e6e3c9a8b50b94e47565bf5a28a0a300bc9b9b6fca4655c4cc3ea11c3f2f51",
                        "size": 411831484
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "4ae58dd21ab496044eb2bcb0fd0f515bd3fbf7bb069bb9500b78137adad19ef0",
                "size": 395880167
            },
            "errors": "",
            "filename": "2023-11-01T05-30-35Z",
            "size": 395880167,
            "verified_at": 1698845523
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698821647,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "iy0Cq189kxL0Sjr/YQurcrzOQuXrApzE5CDqRjptsUlQzy7+vkdQ0P22bSQN7Vi++ttb66Gyp2SVG2BgvYIap/v+fEmjZhNeTpVbMW9EnRtvmvsY+noWFiEQIicbuwyJu/otsfzlhLke2/TbErgV5U5Ch9QBoHD7vJ1nNqty5AIQ8+pG0TQkeB4P92ATOXDiR4ystIOgT6yYYRWVnnM9uTsrRotQudMyZWOBcm+8QjXZCP6qLnvrCFulGwIam85r1fpIoH1wRMGBYXmubbYS6un7TrQLfXjUc1ocoN1QIdKg/MRibFB58g5zdbPoHE+brnodeJSiRhP9l+XgEtA02R+fSRBOWgHvmjBTXh9+UGXhPVYdQ1OlQTtvlJSoRVryO6cE5wUqVukP0FRL0mRZWLTyVVWL5VajO09knYFrk1DyF3nXCKzmp/nvcJTHmxkQ2kC9mA7Q/HLf1NPxBRRIqMFddHglQjXGd6ErIPE3tKdPjNJnAQPQ6VXg5xOGIH5Aq8fT+OfZFM4ILlIxt2p8AP01OCIOjO0I69uAXUtYuYqNDDQ18zgbx7dMcvfcjn0LuTp1s6uloz+iSTmwrm8vavvkLX/95SVdj05WPq6WEPC2I7uNK7GM2e+FkkXlPJqiVZXcXAK33uPrZTCLAnrsixxtUHqoF1+ng6TKmKHURqs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T06-54-01Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698821512.4282224,
                        "modified": 1698821512.4282224,
                        "name": "/dev/shm/incoming/2023-11-01T06:41:51.ais",
                        "sha256": "796219b85946cc28233ed3416617d3c4bfeeb411de873691a714fc198898d4c7",
                        "size": 47726
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "633fd5688cf54772296b3dfe0c182c5964af057e0af0f4066c5e07b4d7eb5846",
                "size": 13966
            },
            "errors": "",
            "filename": "2023-11-01T06-54-01Z",
            "size": 13966,
            "verified_at": 1698844577
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698821737,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "hayphyib3323WiC3HOwVZsbD6GZsjLdoda01ANl6EGtnV0BNeQvdD9KMKH49PrS2WB7ThSJHIPWxttvh93T6ev317CD6+IE5O93mdoowOUiSx0yE7oiyOMI1xRCJhaEKEQSkQA4a97nLaFUJJ8nKn8zHB79fE8BJEOLe6EhtA3sax47JEfX/bJe8YVBrvXB5WhSy/tUSTAN/3ChDtgkCcWlb83j7QBJMA5W1zu35c1UVRnJ6+CBDOBdEg5a2xHD0XejAwn9tcOoY4LFhsukE+DxptsY/cl/TSI2pCQDNkZP4mxS3PmDk5etZNE/VowmFHb6idHBBEpZM8/5GTEdlw59Mh+uh8QNlPOIeGah9+dO8+hHyphAnmoExfxPSBKNQWSOYf6r/gSY8yCsVTQt6jDs/7Fdn4xJpgEIzZppqSEUFlfWe2iIGdRgWVEMvGPYq5zFTafD5gi+7wyx+ngyDBHo/DjIwL+TbnWos6FBqPV7+7DPmGhwVzUDmvURuZ9hmeiq7GRLT+6nSGmvZ38FERF1C0i33Smh0TPrz7/EodQ3j7L6UXBUv7qzKOWQNvv4XC3XtdgA1KXxJwZ5HMjRncvMA6gjUqsSmLG/zas8pHZFBgEYcztPtT3g3FTUvygzagqF5UA4IWDvOn3jEldSrdT2JSxREAAh7k7spWyjFW+s=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T06-52-47Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698821443.6496625,
                        "modified": 1698821443.6496625,
                        "name": "/dev/shm/incoming/2023-11-01T06:40:39.ais",
                        "sha256": "5e6bfe7ff060ac2a6d0b7b3b3874dfd15f12a7c3106cb28467a5f5bca6f270c6",
                        "size": 14682
                    },
                    {
                        "created": 1698821382.5296626,
                        "modified": 1698821382.5296626,
                        "name": "/dev/shm/incoming/2023_11_01_064915_00_00_K_001.txt",
                        "sha256": "8c17f97f16bdb66c87a8ee862a4802f04b98d51b1352b01c87320944ebc01e2b",
                        "size": 1522
                    },
                    {
                        "created": 1698821377.1896627,
                        "modified": 1698821377.1896627,
                        "name": "/dev/shm/incoming/2023_11_01_064915_00_00_K_001.kml",
                        "sha256": "1bee71e0882bf06493032cf325784ae50cd564eb4c6009bb1e12a61b9951d487",
                        "size": 974
                    },
                    {
                        "created": 1698821382.1096625,
                        "modified": 1698821382.1096625,
                        "name": "/dev/shm/incoming/2023_11_01_064915_00_00_K_001.jpg",
                        "sha256": "b884b4158881ed57ea9845ec02053d67a5f079fe65089e44eff06c00db8af49d",
                        "size": 678306
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "cdc4ecfc8b63048deee9e86fe1d18a7db6deb45f86ae26425ec60954666f206a",
                "size": 672893
            },
            "errors": "",
            "filename": "2023-11-01T06-52-47Z",
            "size": 672893,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698819242,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "EysNUnxaeTpRllyE0ixZIOUpdwTJpy1Rcz7yz0H3ZXTIaeJ/7Jpr5mrujmeHrw8gMb40zGnR6RXl9JboP9R3InZGVtYiti5xCGDPc8eZB8SWT54T+8GvIu8VzWr+RKVVhOijGKlMPTLxrV9uYpPbTJgb5dgx+MuiXEjQfVOkB89EuQTJPaAUavTwANOSreQqH/ZsVd0eTTpdRj9wi/Rcmh1UYX7YuVCLd5BHHlE5NrArfLnMNN8PXHu7FLWyrI6IKEU6qZZ/TnWXX9NL6a/q98VoGE3+SP6QIyKTg81YMq9i2p4GCQCDIYc0SUka9tVLuaIhtVprZPjODXsccyCx8rW7M+SdWVfu92T9PKd8RWocCZnt6hLxVmEHZGBixEqAX+1bTyz9ZzoQ3m8H9M4fusp5pQF+AASBU1TN3O60CiGNOfsbXZlIePnwX9Uz2jMsMlDIG5MiTBf7dRgWx7os8IPT784s7+ZxFD5f3rOx3xBWYtI/ROX+cMcWQq05HnpBZp3B8Xx+tm4WL2AkyXz8ctKZ+U+pAwJEVImUR4rxMZE9Q9cKBjq8XfQpML89xKMbkQgk/PfgSuoBGhjiP+X41/LvwDj6qj3uWrIuALi2oEFtmdixGucC9b7+lOdsetyNfC/teXF/Ux772JkhFOVUcyUEAPUBeZVyF+b/wPQfM2M=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T06-13-53Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698819105.1882224,
                        "modified": 1698819105.1882224,
                        "name": "/dev/shm/incoming/2023-11-01T06:01:44.ais",
                        "sha256": "2efbacf4d1d3bebec5c2b7adafdd0fdd03aa184dd98eaf9bdc70f7c719d459e3",
                        "size": 42788
                    },
                    {
                        "created": 1698819041.0482223,
                        "modified": 1698819041.0482223,
                        "name": "/dev/shm/incoming/2023_11_01_061016_00_00_V_002.txt",
                        "sha256": "aa814d201b0bd5e41958d06f4ee55ba3691f53c996ac0ee0e71b37d7af08150e",
                        "size": 2034
                    },
                    {
                        "created": 1698819053.3082223,
                        "modified": 1698819053.3082223,
                        "name": "/dev/shm/incoming/2023_11_01_061016_00_00_V_002.kml",
                        "sha256": "aa050eceb3a6e935c973714829658151fe03d02bddf441c5d498eaf5606edeb7",
                        "size": 978
                    },
                    {
                        "created": 1698819049.2382224,
                        "modified": 1698819049.2382224,
                        "name": "/dev/shm/incoming/2023_11_01_061016_00_00_V_002.jpg",
                        "sha256": "9ef9d48d52c423e9e96be7aa5a0816c14464597f3713518a17fa201b71f0495c",
                        "size": 1167187
                    },
                    {
                        "created": 1698819021.6182222,
                        "modified": 1698819021.6182222,
                        "name": "/dev/shm/incoming/2023_11_01_061000_00_00_V_001.txt",
                        "sha256": "3592e85aba9f41d8205b6256d0e5b68be13d1cfb64ac9aec4baff8768247d802",
                        "size": 2030
                    },
                    {
                        "created": 1698819030.8482223,
                        "modified": 1698819030.8482223,
                        "name": "/dev/shm/incoming/2023_11_01_061000_00_00_V_001.kml",
                        "sha256": "e77f569202dde77992642a55d879f1baa73c6cfb39829a82fe1ae77df5809d4b",
                        "size": 976
                    },
                    {
                        "created": 1698819021.0882223,
                        "modified": 1698819021.0882223,
                        "name": "/dev/shm/incoming/2023_11_01_061000_00_00_V_001.jpg",
                        "sha256": "8155411a9546d0898e42cf3a7b8e1fec33a16403aecf0767a452f0fbe1528aff",
                        "size": 990026
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "6a2ca998c176be82e7340098da4a3f4a99cc46139f01ad3584d2355589d7b6ed",
                "size": 2173056
            },
            "errors": "",
            "filename": "2023-11-01T06-13-53Z",
            "size": 2173056,
            "verified_at": 1698844606
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698819839,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ODvaFlLZBOwOQJwNfHkpkeDwbq40YeAvZWukVJ1HuQtgMmaTlcTxTOvdo+ZuHbARdM2YPubOyn/0P7Q6T8PZFrbnZPw2ONZ4A3NEiQXVqY4IGUvjAa+9WNIx30HMa9/lFcOwYnMqGREkROuIb/6yQMljlo7nqNYY4Cx53lY6ta97rZZc6rBPG4SvoF0mJjGQKzPXmiBLj9VXmhCDGxr8X4qn+e1rcV0cbhB1QEIy/CIVu+VBtX1PCSwpCtvZuDs4eUOvKUM+JeAyPU6GvplhGI6hZQqXZysDyMVBXE1D3xs3LodbhsdRxIG9XmANSOasB52v0VDUqkYOR+DdlkNztAEF68A8Y1RWvF1uz9Rlee0pbuTJZlmo5kLy3dfNVstT8FWeOVrSzo3T92pBPRqW7fQFXOu2bbh69TsvowhQ2XScAfOqweDw9/29JWtYet1Wu2WRjnanPJatVuaxuO6xO3bu0LbR4jweQ/kslgaiMYYfW9njLIm5pluH/V62MW2iWW9sLpHvNYHdf8SrHz8XF3qZ35fVqxWRgKJ0hrC99+2rIx2uWAK1zl/GPxZlUTI19ZT4pVwA4T2u5T/krrIHlV5sBMWeTupGrVHxdakwWCgZ+cTlSCkiwY/awcA1Ikp8n54qj4Ugz81jCH0BAYJPIzpHTRoC1twX5P6POwL0dA4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T06-23-51Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698819706.1582222,
                        "modified": 1698819706.1582222,
                        "name": "/dev/shm/incoming/2023-11-01T06:11:45.ais",
                        "sha256": "a2dd870cb9179a42f868bc44c0795598b5e0274e7f2bb5c6ad3c05d9d516c03c",
                        "size": 30396
                    },
                    {
                        "created": 1698819610.5082223,
                        "modified": 1698819610.5082223,
                        "name": "/dev/shm/incoming/2023_11_01_061948_00_00_V_011.txt",
                        "sha256": "45fbdfa6bac1808143a400f1b7314d3fc4c8f9875543eede40779040df63a644",
                        "size": 2035
                    },
                    {
                        "created": 1698819605.9282224,
                        "modified": 1698819605.9282224,
                        "name": "/dev/shm/incoming/2023_11_01_061948_00_00_V_011.kml",
                        "sha256": "6bc899b692186e82ba591993db292d99b46b18b4510152937c0af707dbb41dcc",
                        "size": 980
                    },
                    {
                        "created": 1698819605.1982224,
                        "modified": 1698819605.1982224,
                        "name": "/dev/shm/incoming/2023_11_01_061948_00_00_V_011.jpg",
                        "sha256": "c0384194792aca8210c8ba75514d6704d12ab2652c20d5bc790993f5c173b2e4",
                        "size": 911725
                    },
                    {
                        "created": 1698819565.9682224,
                        "modified": 1698819565.9682224,
                        "name": "/dev/shm/incoming/2023_11_01_061905_00_00_V_010.txt",
                        "sha256": "64dbfeaff69f70a2b9435d3b00d5a7b482a192c31616113a0d767710c40152ea",
                        "size": 2030
                    },
                    {
                        "created": 1698819571.3082223,
                        "modified": 1698819571.3082223,
                        "name": "/dev/shm/incoming/2023_11_01_061905_00_00_V_010.kml",
                        "sha256": "855788b3dd98fd0d220655e8d5bc49bc1e0c02e9029b55af48f8eb2bae4ba497",
                        "size": 978
                    },
                    {
                        "created": 1698819571.5482223,
                        "modified": 1698819571.5482223,
                        "name": "/dev/shm/incoming/2023_11_01_061905_00_00_V_010.jpg",
                        "sha256": "9a18c090de845774089ee5905f0d05634164546fbffcbf90a94d34a5e994d34f",
                        "size": 946431
                    },
                    {
                        "created": 1698819551.3382223,
                        "modified": 1698819551.3382223,
                        "name": "/dev/shm/incoming/2023_11_01_061851_00_00_V_009.txt",
                        "sha256": "031e7dec93be5dde661429b955e0b28d1fb9504297d74f86e8a5d6f08c015432",
                        "size": 2033
                    },
                    {
                        "created": 1698819548.9482224,
                        "modified": 1698819548.9482224,
                        "name": "/dev/shm/incoming/2023_11_01_061851_00_00_V_009.kml",
                        "sha256": "183eae0620f60f63d9fee99bcc2eb08254c0dd7def541e2ccd69d4f6e2a7b7c1",
                        "size": 977
                    },
                    {
                        "created": 1698819558.1282222,
                        "modified": 1698819558.1282222,
                        "name": "/dev/shm/incoming/2023_11_01_061851_00_00_V_009.jpg",
                        "sha256": "9d37f6f1cfa5c707bdcd152d675fffde9a748539907457f805ae77e8ca3d7349",
                        "size": 888699
                    },
                    {
                        "created": 1698819525.1982224,
                        "modified": 1698819525.1982224,
                        "name": "/dev/shm/incoming/2023_11_01_061823_00_00_V_008.txt",
                        "sha256": "5a80acfe4bbc4eba88db0db17c14ccb81ba0a84cdc8bca3a413438ac66a703da",
                        "size": 2037
                    },
                    {
                        "created": 1698819525.0182223,
                        "modified": 1698819525.0182223,
                        "name": "/dev/shm/incoming/2023_11_01_061823_00_00_V_008.kml",
                        "sha256": "074f2f5c7e66232742635161f307a495db39e42603fc1b843a84841b88b684f9",
                        "size": 978
                    },
                    {
                        "created": 1698819519.3982222,
                        "modified": 1698819519.3982222,
                        "name": "/dev/shm/incoming/2023_11_01_061823_00_00_V_008.jpg",
                        "sha256": "e2f15adfabd293464e9a14bfbdf83741839894ced9a9d0bb7e966794d3b03232",
                        "size": 994030
                    },
                    {
                        "created": 1698819470.6882224,
                        "modified": 1698819470.6882224,
                        "name": "/dev/shm/incoming/2023_11_01_061706_00_00_V_007.txt",
                        "sha256": "5b6694ee36d6c7138f6f6e4586f9cd639b4f0d5b74f3ebcf007c4dcc05f982dc",
                        "size": 2033
                    },
                    {
                        "created": 1698819459.9482224,
                        "modified": 1698819459.9482224,
                        "name": "/dev/shm/incoming/2023_11_01_061706_00_00_V_007.kml",
                        "sha256": "3a4c6f2a2f057978f335f1f54c71383e537ce766ff36ecd4e6a60aa109a03c99",
                        "size": 979
                    },
                    {
                        "created": 1698819459.5482223,
                        "modified": 1698819459.5482223,
                        "name": "/dev/shm/incoming/2023_11_01_061706_00_00_V_007.jpg",
                        "sha256": "048fa2c7ac001bc94e210d5ec6f6d2afe79f87c271c4211a2417f0bbf73701bd",
                        "size": 927748
                    },
                    {
                        "created": 1698819431.1482222,
                        "modified": 1698819431.1482222,
                        "name": "/dev/shm/incoming/2023_11_01_061646_00_00_V_006.txt",
                        "sha256": "1ff23976178da947708ece580efab8f7aa98b03a4927b5f051763fc0143b6323",
                        "size": 2031
                    },
                    {
                        "created": 1698819435.9082222,
                        "modified": 1698819435.9082222,
                        "name": "/dev/shm/incoming/2023_11_01_061646_00_00_V_006.kml",
                        "sha256": "ed3ff0f8d11354222bfe6cc98ff6e863ab25bd3750bf90eb5ca2288c67c845b3",
                        "size": 979
                    },
                    {
                        "created": 1698819430.4582224,
                        "modified": 1698819430.4582224,
                        "name": "/dev/shm/incoming/2023_11_01_061646_00_00_V_006.jpg",
                        "sha256": "d2a06dc310cf89727a0a644789042c23283e1b8319dd30d262013d738d09339d",
                        "size": 863688
                    },
                    {
                        "created": 1698819409.9382224,
                        "modified": 1698819409.9382224,
                        "name": "/dev/shm/incoming/2023_11_01_061624_00_00_V_005.txt",
                        "sha256": "7dba27f91d34fe24d663f01d39ed08cc40a557e882339e546187211aac92d750",
                        "size": 1523
                    },
                    {
                        "created": 1698819404.2282224,
                        "modified": 1698819404.2282224,
                        "name": "/dev/shm/incoming/2023_11_01_061624_00_00_V_005.kml",
                        "sha256": "e9325b60dd6256024e7266b5b916e7586a2ba7b08b89ce4cb0c4dc0193584eb0",
                        "size": 934
                    },
                    {
                        "created": 1698819405.1682222,
                        "modified": 1698819405.1682222,
                        "name": "/dev/shm/incoming/2023_11_01_061624_00_00_V_005.jpg",
                        "sha256": "91bf5b44ef900c9b4399dbbf32fb32affa3042339bedc5885670259ae3d9beab",
                        "size": 1044003
                    },
                    {
                        "created": 1698819321.8482223,
                        "modified": 1698819321.8482223,
                        "name": "/dev/shm/incoming/2023_11_01_061502_00_00_V_004.txt",
                        "sha256": "6bf45bac60cf4268264146585b9560fe8cdc08827d6294fec780c2c8f9219c55",
                        "size": 2025
                    },
                    {
                        "created": 1698819320.4682224,
                        "modified": 1698819320.4682224,
                        "name": "/dev/shm/incoming/2023_11_01_061502_00_00_V_004.kml",
                        "sha256": "ab1eb41252d08cfa91f009c7f666c07c971a344673cf49d64b0f3d36b3c50052",
                        "size": 974
                    },
                    {
                        "created": 1698819318.7282224,
                        "modified": 1698819318.7282224,
                        "name": "/dev/shm/incoming/2023_11_01_061502_00_00_V_004.jpg",
                        "sha256": "51f137369f034d2e519478e9cd0a06d8e1a988e756eb5bd68e9a2795d9d92299",
                        "size": 871311
                    },
                    {
                        "created": 1698819312.9482224,
                        "modified": 1698819312.9482224,
                        "name": "/dev/shm/incoming/2023_11_01_061450_00_00_V_003.txt",
                        "sha256": "f9e70d4d9a0d487bca52ff618470f86667cc9ea001b2c65f86e49df3389759b8",
                        "size": 2034
                    },
                    {
                        "created": 1698819314.6382222,
                        "modified": 1698819314.6382222,
                        "name": "/dev/shm/incoming/2023_11_01_061450_00_00_V_003.kml",
                        "sha256": "ce7b404a56794f30aeebb1abed11402b89159599bd82e80db5b0d94d98bac063",
                        "size": 977
                    },
                    {
                        "created": 1698819311.4282224,
                        "modified": 1698819311.4282224,
                        "name": "/dev/shm/incoming/2023_11_01_061450_00_00_V_003.jpg",
                        "sha256": "de79328d6f9d01c9cbc12dbdf7912f81c88315517a7980eeaa69be12f87bafd2",
                        "size": 867556
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "9fdc8177ef0f4aeb05f61cae39ff2636bd9d54a191107288bc91e52f5bed3303",
                "size": 8320320
            },
            "errors": "",
            "filename": "2023-11-01T06-23-51Z",
            "size": 8320320,
            "verified_at": 1698844612
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698818638,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "hE1a2kjCbbGQcrOtLM9id1FYVgc3qodEHogY9Gje1VPQnw2UOqw09EnLa15DhTQio0NC7uRnze2rZokH68CCx9QgulewmuM5Htrz1ZpWcM5mcDB58949hkcVp+7OxAMyYdiMBHLSt/3M5jopZBvKokHh5cHxLORTTOM4qpdVU81hFfyiLAD0nQ6dV6oRQJkwxvdJE5pWFFtT600YyB1jmWv9A+a/yMokOOdKCJu/oihpNngf5QfkeRFfXHtv4A9LSuAZ/qnJJ2Z1AdUHWl0r6C8k44iyPMoxrLX9C3ODTwbnTP5319dqkbGfCh3SktIx1MrJsmT3KVliCaXifSXKcqjrzXfRotYfgQFbulnPMjJpw1zuJwyL7sHgwbeKBxpE/hI/FsbkJua8URd1k1R8ZB+V1CHT8+TYqe6hEYNNY0YaknRggIbh1leM4m3WOHmsqY2o9RbgcTPFjT70VO/r9gvKJShL97dd58UVC1wXz3L+TM8AWtljg1147NG7xl6WuFV+TPV5zjzWKFX37TV/wzCukOrl6ZxiBEaLl+k7JJTfuBlqprIqNAmBOqlvSwEX/oMLFIGPVho0G7VPwDKwj0hHYR7B3DSotknqc7JHMUTstizYuZ3gDacar0x+gKFU+X/Lid1wbh98S/WrSxcxrjH3aQXyxDyHPUi3Vlo4L38=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T06-03-49Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698818504.3382223,
                        "modified": 1698818504.3382223,
                        "name": "/dev/shm/incoming/2023-11-01T05:51:42.ais",
                        "sha256": "41ad2219d22943600a98c1c8b9012a66fb80adbc373a34250c3f7acffb84adcb",
                        "size": 53987
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "06c11ceed21df558384fa6f0f8d0da03e229a9d0e291bd88361250995b348597",
                "size": 15301
            },
            "errors": "",
            "filename": "2023-11-01T06-03-49Z",
            "size": 15301,
            "verified_at": 1698844649
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698818496,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Lc7rLgTylag8JnPWg8s+o2Cywlp2oYYNnPpX0uoQDQiDNjp+q+DvzXjAvc4iEt/GnCQYIjbss5Ibp1h/PFk7jmp3YYvKuZfX8SRNV+e4QzWrBmTqf0C0v1Rrv3IYnrLyItF7OBgphSKLzEnUU2YVuDmzX6Sig1xCkWhwEbydcWxBdBpH7GEN1svWdDO4+LNQLJ7VMmNCqSwT2kakvF1IkdJyIAZFHnmNns0sUYaI8o/hPG2XK87qE8AR5zfXFIk28fduOTLP9uYa6OJHx+fs3dFyjrDpiy7tlF3b3Pwf95FaTDamgGKXnw5uS/l2fHIVuRQrvU0TPo/VCq6HlKjBwmhqXS2IfJDB5nK6M75ih/idgxrsVk8VHgASi2AzSXqzh4kuMAAbV9rkldAbbicppGTzFlFCowpeA5PLl/tUacReqtXBCfwO5DwQwBhn41SOo2g7UhsxQFXnq1KHdhsPt/hXjyh4RJFhLXrJKG08xc2wsgefApFT/ehhImC1CVU2uL3ADrDmHwTQ54NRnBb7CtX7NVx8XrZ31lLXg37mSeLR62H8GzQmOv3STqDdwQbvwNNsPhJ0jDviNInRutsI+FGLFLzvfOfWi8nyPBGHuUQn3zHVuGyUzjG9A2O6Rm6mBMKlrwiPXWS42GQlzlIQj5Rc0xwIqpegnjvnlcujS3o=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T06-00-46Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698818434.7482224,
                        "modified": 1698818434.7482224,
                        "name": "/dev/shm/incoming/2023-11-01T05:30:34.ts",
                        "sha256": "6580ca58d841f08a79a060e3dc3a18ff6c4791971189081695c567380e7211ec",
                        "size": 410764020
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "5562b07e34a94762291b6a6798ac6b06b5bd37c76ad88ee42a84940063457dd5",
                "size": 396353425
            },
            "errors": "",
            "filename": "2023-11-01T06-00-46Z",
            "size": 396353425,
            "verified_at": 1698845443
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698819983,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "TnwJhHffe/q/TtC6bD67ijHrm0w9WHNrOZLXyt6jEJC/IJ2kd1KMUWiuWhn2706LYM3aIm3lmZYaXO6BEmbeqsqVKoMFJirbM9rv/lge2zWFeYvf2RrBRD25fqeoXmZ8NljOJANiNbjt5HYUvvQ0I4bW0jUw128gei+o6FvDJfAfcedrNf0/4u/gmMfgRIbQ3+KeKBM1LsmBIRpYBzjGhvWwZhP37PsROBT2yhj9rSoNG72jeBMNgI497W+qVI6fU9DYxzSSjYsEbwXmzen+jLJoKNajkPiQAK5qs9VBSQV9VLAt8Xp5hDc8OePRQvq50Xvk/gGz8aCC1r73nyC5YLr5hYVp2SOfDy2B1MKvB1PP2bGlJiLo7CDaVCN7cWa3ylhzlrjiYPDmTX0d819MJsIAxjKXgOi6qQqzi7uMokbW7WTSE5iXHpY4ZX1G7fcKO4o/E7qAQGPyQ9AHBgyn+5RdPJMuTSKVC6v/Ak/ERAmlm7JnUahdxSn4t9VwflWH1FJ/LIw1n1iKunwFutaRthMWxGGANuANiUoyEBEnzpZAw6/xX186gG9dLhpmiuX30FCiYg5lMpy16rnp5Z3Rq6CXaTaYJP6MlqT6GaDO5sJ8emG9wRhB0uvdMm0K7wENRNmt5vQzXvPBPHHYnZEh/GHpvNiOU1RFqjEi4WXtEto=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T06-25-33Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698819809.1085453,
                        "modified": 1698819809.1085453,
                        "name": "/dev/shm/incoming/2023-11-01T06:13:25.ais",
                        "sha256": "1a5547e7ee0b13dd728c4cd7b4c5e7d04a73d2ac7ac98a3b5f1d9aea4b93516b",
                        "size": 23009
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "881cb545a17f8b9056ab734f41d541199e6269a2553c50f80052427fbe22cca7",
                "size": 4319
            },
            "errors": "",
            "filename": "2023-11-01T06-25-33Z",
            "size": 4319,
            "verified_at": 1698847389
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698821043,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "KUI+mIKSXBS8ykehXu52K65A4Y15co/k00G0Qan9gjmt7BtgqU/LItkpeUs1hZMTT5arPpYEiY4IyKCpgJU9IlEqap8NKGPGgZsZ+UpxRESHmj9Ux9sAgQigLAiiFaiqapeZDVPtU9fPu5S1pnTZ7U0u36kw2vlEeDlK3OL3QQoZDfVh9XRH8hYSeKahg5WjNZ0m6gd5jNwqG8XqwXh3jTXkOsI0latjyAeaiXsxhr5dCrx+3BQvwEZ4LcNUsg1XgTXRUdXbPwyqTFn9sraWKxX5iu90N2ShLnEEFGIYeh1K0vV7YCdUoOxoYJfdZASorhnSNP2CFHmLkpnYOhgnmsRnPKHxbQHCh7x1cZ4hWBucnwbC7y/18EmG5VCMLajhVHIUuhS8czWd4arGdu2YcQH+rSZRrGllBBfDjHwamfav4BcEMubhzHLwY/Ec7+AzYL31JODMjvbrs1AwpuMq1s3KWbZhGfseD4rO+gOSwKBFcatOpDrvcMzeZqSCbrRrBcEKEs8+ozRTiMPPxZ8ajIN4zmiWEaUmU/ykGq3uNO+ldFiA1kB38tLBwq4HEJVnD3sd4FxVDSaIQrS9boPf9KB8EAoGNwdCu5EfW3b1Wd/kCgnMho034hzJhfTBQZWXoT5QTg+GiyAxLhmCYXC4PAVBrXBsG/0um0frBI/FHbE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T06-43-57Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698820911.0582223,
                        "modified": 1698820911.0582223,
                        "name": "/dev/shm/incoming/2023-11-01T06:31:50.ais",
                        "sha256": "5795de960bdb695eca48b37ea685cafd9d67ea0c1c1f5e9f0304dac5e3950b67",
                        "size": 35941
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "55ae5443a6d9c37ca3580c0d7628f4e37e22fa0f7506842d80c31c87e40ba4a7",
                "size": 10350
            },
            "errors": "",
            "filename": "2023-11-01T06-43-57Z",
            "size": 10350,
            "verified_at": 1698844571
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698820298,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "g3cCREESCUwPTY8I6X135cFXnQgg9bIRtK1NxiMkUijRC9mSpIfSMNAaIL2UxDdPGbzm/ecA6OxXs8DfxhWNHq5FeXDV3trba7D3HruZXjjxObdRcrYgrIafHGFdofPDmshBvMMNS+gkPStKm9/F+uR37YtloM3eaL0SOZvkMwg4Xhl4JGpTG1up3mMvI4eEwi1OQY0JzBabsEykNr3gNn8zP3bm2xb85w+rrUuVbDBXNcfYRaTQm3UySQ+6CIwBFRcVcHqJSf2tLV69UD9r8nFvjGlGTMIEK35xBOsFlJsE1lvrZ8LM24bvKnPXAvm/SaiG4nlbMusdNvQTUf82m52ktOIH+C0h+g7maDB+d4BwozRkVc8yW8BixnIRwWvSWYhzlWt5kQwra30uU8vcxM9ZZ1ZnXZKnwzre9ptgTyILfQ5xTQ4q0LkzXfC0IgQTUfCGz2eKGaWkoYgh4ZslhnUYj8Ue9ONXO25FYrbexGmJGQ2Ib+SkT59HRO6NXWhjinJuKqiKdSrTc3D4Fe0/64wFyqN/ngmo4C00flDYBiExZyV/Aebo42luKT9ojqpIXV9VRUxUdhxdPJ3Wnh4ayoryJZHYcMWjJ2HumgAgvzUvp9nW3n+WMfu5oJXZ5eVB/jtzrBEFPeU/UY3zFnxaFPCB+OGIkJZ+/PeIib7RsN4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T06-30-47Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698820136.8782222,
                        "modified": 1698820136.8782222,
                        "name": "/dev/shm/incoming/2023_11_01_062816_00_00_V_013.txt",
                        "sha256": "b79b3121b67586f78f5f6db546d19fe839a1752a5c3c4ff01537ff79b2137354",
                        "size": 2036
                    },
                    {
                        "created": 1698820125.7282224,
                        "modified": 1698820125.7282224,
                        "name": "/dev/shm/incoming/2023_11_01_062816_00_00_V_013.kml",
                        "sha256": "6f9c7e857631d5c6f11e794d7500ebbed993e65f6cf9e813c77c764df3175a65",
                        "size": 979
                    },
                    {
                        "created": 1698820117.7482224,
                        "modified": 1698820117.7482224,
                        "name": "/dev/shm/incoming/2023_11_01_062816_00_00_V_013.jpg",
                        "sha256": "a450beda58b355a16d45153a92cd3995f76e031ad06fc4316f238256ba637e14",
                        "size": 1349193
                    },
                    {
                        "created": 1698820241.2082224,
                        "modified": 1698820241.2082224,
                        "name": "/dev/shm/incoming/2023-11-01T06:00:40.ts",
                        "sha256": "46c27ea50b5c8f8b02d755e1d03c330ac75e0c446b9e617ef72b0913c6b69f2f",
                        "size": 410603656
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "2d7f6954249b873216d0f602f179360d2f041fe221a96435ea07d28d6e16fa15",
                "size": 397453019
            },
            "errors": "",
            "filename": "2023-11-01T06-30-47Z",
            "size": 397453019,
            "verified_at": 1698845599
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698820554,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "CroxOEEOe9OAhIpFWeQV+WlxmLdVS1WQ64yDstG2DzpGMD48L47U4wfjpvx5errCFdW7k9VhJdCG2HG0waF57l8c8YSlj5Od71a1/t9FAcYahOmof48fBgUGD1OqLubotb+5cETAHwvB4YtBCRak2yK1TFTXOdBvfM0CbUehOzY75feAzij6uli90Gqn9WTdcChBiqZs4/Lnpd1YZDWzfH1NO2ESO3NOFbYoq7edpLGc88Eb48TWXBw9rBtul3lysmOfdaY59lFNhnpOTdmtCSaN0Sz24a7NHO5kYCUFwDMup0COM+ROzdGxbbFENHUvUK5fo0YzMaDOBHXscplnwEBIlxQ1LR4Itv2XK4VRI+GItWA9P2sh6XKxPXIj3hQw3v6IQufRa5NDOuguEE2qsn1F3Zz7ka1pGQadLhEPvUl45Vj21QlD8WXHSSImBVdWKuAHHZO1G8t4MeBY/Om/IdioaUYPuDTsp66bpQ4ktUZXzDX9EOyolIia5/PtKeJ2WGMApcCmafszVs/nlc+WytbbTDFLJHr8DHVO4+YG/EaAxWo1u0EWlQOJ670x/rTdPxM4Sc0QH7pYfpcyKXjyEJOhDyxL34c/H9dYoustJUz+1DfOBPAULX4kQnkDYhTVhuJ2rcEPKO7bPEL0m6rOC05Uwo9a7lBR2Slw/UJxccM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T06-35-35Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698820412.0385454,
                        "modified": 1698820412.0385454,
                        "name": "/dev/shm/incoming/2023-11-01T06:23:29.ais",
                        "sha256": "6283386df066f9b9d48c3b4413cdcd17d0f70f3d978a620c8283f59675f8c491",
                        "size": 29886
                    },
                    {
                        "created": 1698820369.5485454,
                        "modified": 1698820369.5485454,
                        "name": "/dev/shm/incoming/2023_11_01_063228_00_00_V_001.txt",
                        "sha256": "e3629d6a3ce4f8a61c7065adefd222d4da8150455c6969d6ae66d7c018abe95f",
                        "size": 778
                    },
                    {
                        "created": 1698820370.3485453,
                        "modified": 1698820370.3485453,
                        "name": "/dev/shm/incoming/2023_11_01_063228_00_00_V_001.kml",
                        "sha256": "2f2701869f700892b11671d780e34e28784210cf7288a293d8773da6ba5e5d42",
                        "size": 921
                    },
                    {
                        "created": 1698820360.4585454,
                        "modified": 1698820360.4585454,
                        "name": "/dev/shm/incoming/2023_11_01_063228_00_00_V_001.jpg",
                        "sha256": "b7d8201d861cf1c7d08177d02698544ea7256df8f74a49e8e7cf48a376154715",
                        "size": 1221305
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "a31e7072400571693a326488667605ea88796642e3c3c418659b0c3f049dd360",
                "size": 1228096
            },
            "errors": "",
            "filename": "2023-11-01T06-35-35Z",
            "size": 1228096,
            "verified_at": 1698847428
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698821158,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "bLfuihnYvDb0KfxqxL5DcUL8kSNmPEFLZlJm9gfUpmLkQiVtphGQUfA9S4FoE/bmO8tzhc1009Y7E60KABrcvxKnn9VJpsxhJah+m+7m4FOnohcPF2btroT7xBGyEsVPnRBFa4ZuVKlBAZHnp4nNHSmx+ZdIyKLQm1/8YwRkBwLTE3M3cHqj+aW0+f/ozixZwbeaGTXjmsNaZSjsJNN7SIjOV7XsqJz9cV90zyybqPj8VZxiBnqcouzACYnRYZ0iX5a8QXI2RxVrPCVrb8/cnpAXXCIUHKa2JjXKM+UBnELzpTRG2HRL+5bUxQ11c5MQWdmA9x0ABKutxfOgzjJkcC54da2/mOrDjPm3TgmjktxbJyegaCD3phk8IDeMjZw8zBUVGNgaNpJ+USzvPDOilf91Va9dae5z2fpJjfm+cMtYp9vV4qa2jULKWhhrKn/bFupbpsLi/ZpmIluZx0R65VtSawDWvKoKp6V9qsYLKg9C4wPE0dtXFYJfx3eLKJ5EQZI/uEL2PCCNQHrHgzBy/ljd1/bIM1m2mRphDMul9s2LzUpDTGA2FW0JcZhEVgWhApW/ZdJ4z+jb11siGaKgH2e1TQgEbJeN09z3aJix0Fe4fsUU9ekKq1GObffVNdDtP0tU+Sx0YG6azngzUC90oNyXzk58m+YI3Rsht+7CnBY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T06-45-40Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698821013.2585454,
                        "modified": 1698821013.2585454,
                        "name": "/dev/shm/incoming/2023-11-01T06:33:32.ais",
                        "sha256": "dd63d9e3180b934d2ed5f059d96db8e2bc93922cddfafce0477be13ad958c127",
                        "size": 422150
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "796480f3146ae567f8eec3ef8a1ca93b10e9ada1b6cfb7ded40dd676fdeb5233",
                "size": 129467
            },
            "errors": "",
            "filename": "2023-11-01T06-45-40Z",
            "size": 129467,
            "verified_at": 1698847435
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698820179,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "KsbQ1XC5J8BVWERoigW9iVugUEXxI9fwiG4xpNrLrKuqWhiV6KerGNh7Rq+etJ2F8NgXvqplb3vIn3douZTaYLYTZfzhTqR/Wqj7gZzE2O7aOaszIkbWuk/pisdGVWWkBIZW1fEbJSkXpThhJcjkXulOHsWOrjhh28//hFSEomMrGRjHqUMphXXet9Sf+KeSTjfrbOW+z8yb0qZUSV5WQSV2X0jz+EdAZNj9q0xnIeqMvugqvF7hIcS4jW9BAMbF3OPgfWDB7DR790bQuodmJ5JQfzEyO0kruIL+ExMf1jHBRc/yxI/5zlyQUm47pdyhMXvfphQhlB0WnerD/1E6enrJXqXD9/BRqUi4tzB3xP69Pi2Bnku7osYwnUkGWEOZ8WKFFIfzpZRRTlrsWh2Vsr4+TW7hwH+GWhAh1tQPqZAbnUQAs6/Dy9QUZqf0tVNqkcHk4V9sV45hTt7RTVuCB2OV1UET75uTgsZluPqEK1UTIFpEgJRY+1PvJNJa2FVI8bz6UNHKVg9bgyEZYmem7cFAvHWFYvkdiaBE3TFYmm9MzQYOHyaevHaoMIDrBGv8ND7bLzZS1HR33HWfnAihT3kRK2zLiQJ+GLSXC6Zcb104tNt8ZF2nytf9j/h9jQZIDBg0tEqSUYOiJgHNGipE3U/1AhM2QVV1upeVGYuMda4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T06-29-19Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698820031.1885455,
                        "modified": 1698820031.1885455,
                        "name": "/dev/shm/incoming/2023-11-01T06:26:10.ts",
                        "sha256": "c59b90f8d01c52dc8edeb8696b58b8d9736647fdcff51dd64b36e4257ed3390f",
                        "size": 12976512
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "27e890c241c9a00efd2c806c333103461244cb7ff7644c681672c48ecc42a9c9",
                "size": 11952480
            },
            "errors": "",
            "filename": "2023-11-01T06-29-19Z",
            "size": 11952480,
            "verified_at": 1698847454
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698822192,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "BiJ7UUOYWmZ1pz5+WUiDv6XH3JqV6LgXXi6L+XoQdQIHT48QryUnUMiG6vrcBVCVjYlY5I9WhRYxTglLRf0PB5W05ASLrNTOnchIOdGpeKJra9EepG3BqV0plbILKGR7ubB3Rwb1sDJkEuG/+KuxIwSTcErA5WSceYfn5WO8Dzt4o7wxF63A9dFTBBaEsw+j1bJiX2y5WYUFXDCBUHarKwYNnkq6IGrvv3iFqUfQlx8ScjaDCVGqsOS/S5BfOzNrCVQ2BKZ7Sbj3rxYZ4U/Xn9ne/WSiNM5ArBszg9ptxOKEdTFAnW877E4qpl3x3rzbeTxsQevjmnLogCifJdkkdJyJIGkZgMalV8LU5Bnieg1gTb6kENh3IIPj6rYwfKNDvyMvfROFFNYLl85s2GYLCvIAyELXLBSLe98vlHRz8yZiGK1Nq3J/G741LEP90nNid3tUAnqFn8OFheXPi93hzMI2TJ5DS3uHGPXO+WdacuZ4rqcb95/pn+KNkWb4WRasyRuFvyXGsKBc1iNvWIV+nDN+TKde4qksE/VZk5+hofokvOwE1pk6wnxD7dSr4cvlDQeLQoNfE24R5WtWPsKyBDyL1kVAHsDq2nO0G6CZcsHINgbEGrnIrGMz2OoJbJ9RxgToZPYtrR5h3U6uEBEcpYxrlqkSTYHcS7T5iQbrGCY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-02-51Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698822046.0796626,
                        "modified": 1698822046.0796626,
                        "name": "/dev/shm/incoming/2023-11-01T06:50:43.ais",
                        "sha256": "7b2f684bf2c7d7b37d4cd33d5c38d7b5e0dc99cedf1978335b1ecddb20000c20",
                        "size": 13250
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "8055bc3fcdf4032208342b79fdafddfa88d33694057397069350173a57ef9693",
                "size": 3104
            },
            "errors": "",
            "filename": "2023-11-01T07-02-51Z",
            "size": 3104,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698822109,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "lvFfhWz43GkKkoMOXm63lt0J3nhJtSwliBMnWnMm/23cVmn5J8+RqrbxX0Z9ifIf6RWWa2E6b5s0HnNAzczVEBLVK7L4DaTcd0IzBY4Ok3kC/0BL6le/kZiJ0vL4oFGfUKfjYj+/9u4BF8WN9GKXfqmTo7LxrHQT8mA0LrGJGLHYUdZWEgt2kIsVzBYGEJcvoisrutFV+vQzssg5AK2LQmMABEOQIoZfwMsTxyCgFWTZ2+ZxePTB/+F+I1mJijWpweGRxlr6kv4d27igpGStC2EN8M9ENv1hP7SXgSn3h9yI3Q4gxOIX+EkFbVLGM/NpjIhdUT9oW9h2LcZnCD6gybQCNuBj+KERif8sHjkAqnkoibzaBgK/QgO8uhOsbILT2grceS0U9W4iRLNY+VaHcn4alkZShwmeOeNUjdA7AEggzp3nJ2F3Pl2MvnOjYoE/BTY41vyqruVWgCVRv/KpwxaZndSFt+1V/8eLxxP6HS40ulDPFxQCb3byelKcMuLaB/AjNN18iKce4m+TorODo1JrG5icqfkdjb20pxEgDvlvt6Dk1NpzhmBYkoupUf1U1SUNou6voKdueQBilhlSbT/XS8pNu1h6Os0Mi0mPUEOBkosEL4iglQNPU5fsZxFGXL2Dd5G1zJoXkDfurZm0eUhyeCDhQaFDPeIjtAIdW28=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-00-59Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698822047.8982222,
                        "modified": 1698822047.8982222,
                        "name": "/dev/shm/incoming/2023-11-01T06:30:47.ts",
                        "sha256": "8ceb3d6705fedcfba79848dcbc0d69336704a745ab33c8a17a2ea52526cd7618",
                        "size": 410879076
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "aa33c95c3a12aeeca1d403cfdf65b02122fb9d1247405295d129d1d53488b20d",
                "size": 396319915
            },
            "errors": "",
            "filename": "2023-11-01T07-00-59Z",
            "size": 396319915,
            "verified_at": 1698845634
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698821761,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "lAS95pgSVEjeWoJKFtCSvlxmEVdrboRCqKm6GilZd/4GNCRBPxMSP0qxGVQr/Hkb63UAaXtQ0AHlft4GrftR7got7eKC51B6loxalQqTBcSaNDW5khTA0bjAvC0kt2+uYDJ04tTctQ4ZczEndBqWDg7fCLTl52XdDww6jGlFBeQbUR1otEFkGfVh/r6o3T7Pvr2FPs+68RSrhQuPgvy+qTfzIBLa8gOjmee/mAzq5W0rjjL7rn2c5vFVMhEPCXCoS+8CqRqpvGtUloP8p0EpLNpARPShh40iuv3dcxxirRr8f7JpNBr8p1a8sUpckCHw/U7x0R1lTi6wj5CHzseIyNCVCdh0B0XAc3jg8o6RxJPzdllDvrbZ3CJvGAquxDNXPb+UL0/3Ex9bOtr4od8cAbY161mX9VbrhbJ8Cel6Uclu3fXhx4y0eBclyVmeecIJMG3LLHJXa4cPuYfXdam7skvXAbczSrNFlfPfgc7xN6bwpHVdyMiZ9I1aPmZ/cphrMcEQzcvZXceZDL/+McDbMISp+rsWAepgo8QTNr41zt0MdMvUFiTLcPAzYfAS4X+lwcQCSHKzpfxv9ZtMcBPmhbijq/kdLtPeB9tr9qgGsa7d5gCkaRprQq4tFaavyM63+1FutqNbhJKd55+B+TjvZSgVcRlo2AicEs9YokTjqRw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T06-55-38Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698821614.2985454,
                        "modified": 1698821614.2985454,
                        "name": "/dev/shm/incoming/2023-11-01T06:43:33.ais",
                        "sha256": "aeddcaa71d2897ccb31437a75f80688b8e5095add11a8b33b15a60377d26a78c",
                        "size": 911527
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "f4bf7d2f54f922c34500579603545fbc512d35df49d7fce5a3dc04fd4c3d7ced",
                "size": 278237
            },
            "errors": "",
            "filename": "2023-11-01T06-55-38Z",
            "size": 278237,
            "verified_at": 1698847432
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698822874,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "WBRsCMxk3wB4Pk5ZiUt7h1wrjkPiGvt9xaWUDZmJrrA4O0BQIPxh0BiSngwRgKSBHWfh2skgJZtAdGPt0tlrQ789p/Y+Z5+SNXtok4gNJwlCKpZinEjDWAIzyjGZcvb+IJ88qFbkFbK6egsf6DOf8GUW9nebTX8zz2mKcYsjcEHz9oS2aGaMAb90IH6BOQxVGcbCl1TEkBUa6UajReqfha1fIHu4X08ehvP5eXHuy7HoJTSZbyhIQ3oqC3cTBP/02zlyLdu8No/PRFFC2EOiZaQLXa2f/cGbXBxv+CsVnhWXxhucucG1nvE9fKuAr6Gw5rdkBotUszwzWdmzt16oD74PfHhUrBtH07hvLHpFSPhQRHluVnZ7IIE0AxPjeWrBoJo3koHYh3l7K4FCaldolJ6id4jIfx4+zRJw+BIrWdJ5ZGDaiwycnbbPMfkT2ZOIOdUDwySCdOZ6YDI809VAt3lsKEdwdx+Le+qaQhE67ZrL+y6EaQEUspLInlb+JQhvtOz18QNpINT5a+VbfnhDMEcXtl3S7LcgjF17O/Z7ecuH2lsEKLjFThhQ/1/LzU+LdcE1+BqeIB0C+7T08MJgOOZVnxXModPQi4iavdM4TNqYTp7YrCRXVvqOzoanS2uAfC+yWyvhZRJSgwxmyYvqyByVg8MdeY0/QOcBMZNlwoQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-12-43Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698822647.5596626,
                        "modified": 1698822647.5596626,
                        "name": "/dev/shm/incoming/2023-11-01T07:00:46.ais",
                        "sha256": "4afce478a54db725cb4e6b656cca5f7e28c96dbf0953bdf4997e104e3e9d4ff5",
                        "size": 701343
                    },
                    {
                        "created": 1698822754.9496627,
                        "modified": 1698822754.9496627,
                        "name": "/dev/shm/incoming/2023-11-01T06:42:00.ts",
                        "sha256": "7d1b59eb53a9c695131597706aac4ff5628acad1beb429fb9944447445f33562",
                        "size": 436736220
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "33aa8b6cfeb5b95744758450d17218bfa20b738a01db939ab132508da4bd8863",
                "size": 395434746
            },
            "errors": "",
            "filename": "2023-11-01T07-12-43Z",
            "size": 395434746,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698822850,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Re2PubPV0Sm60+NvNUlBC4EbP5ipWVgrIhp49+b3sJ7V8urYxhi1Dd0KtTrWRDrfDOmTXfkL0ZFyiNXGfL7C6iK+lbEmBH6CrP7HoK/qJJQ0JZDE7ER3fyOPPgA4vAEWDlke4vFKLiy7SSMYLLow8tMUY7g3CAm9On/KcHs2K6FVRPHKXUF2ObBkLUCAK/dig0DmJd+6xNlSbDxjLuKu2roP0Tw/FwAb4bl2xHYcUFwiRgMzCU3seNz2D3lDpPYG7rWlCjawry2G4N5BJWmpRrKRqXZVcUsbAmXYCgoW1PLqmpIUYcm2hdLNFwY07ed3IreIlVI1nEN/E+0YV4cVoXuMzG6g3FE4UVZhOSknbd6yK9pPI9MHysep9B48PUzxWwizbH4BmVdNXjZcb9M89K0W0Gt1xqamIm5p1iI9PIspbu4bKV8TQxmUviGili2wKuvov+v5rqEYfUlhIr06idIMGrRXf+nLCArNqwufpgweTlHKA/YpEoQv35MxTqv4mlrXpX9GRQU3jZmLkcPDlcDcbulTV17Az9b+805Ur42qIfwI2tScPDwEbma+qPTp3x72Uk1JjuanbbZBJPaO47l+AZcUWE/8OsoxHU6U+GzgZzfaCNeiVjHBWNnxD/ck95VG2iRGDaxpQxhHfWhLUZP+ihMuQHtoE3+6lX9o60k=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-14-01Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698822714.7482224,
                        "modified": 1698822714.7482224,
                        "name": "/dev/shm/incoming/2023-11-01T07:01:53.ais",
                        "sha256": "e80535a2bb16dab36ff408f0e8b3de3ab6fb485f4591550f3671fd4f6f6743b4",
                        "size": 40582
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "d5a2d2acd8d101a0efe541dc3884678a7c679bb33e9dfa1fa5689442ded8b2c5",
                "size": 12207
            },
            "errors": "",
            "filename": "2023-11-01T07-14-01Z",
            "size": 12207,
            "verified_at": 1698845422
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698822967,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "tphnDf2AMf5U0TkI/dvmDfNLwLvH1XFMMGeCopIU1JMF8x6bN4jtspwJUrJhRj7arp3v4op3H9YASvYzV4VGI1v6dW90F+SdoeKfp+6koHgQ3v/3bSOl7ei8ICjEnEDZEZFlGy2CpgJ63fZvWmijA2CI/kOd0Ln1i3y2zCsBrdYuGIUHg27OUA9NrOb/fyLdZnwajjRll+Hx+PdE+EBoJfqPaFwEMS3VeKxwcikQ9qSqq2ch8rpKeBh/A+m22ZqR64zB6MPuz6Dx2/75VO1xljURGb1PJVf/zuFXsoOitq/W/hmaJou5IPOExKViS7dsp4Gr7TosT0GkBhLXk5pr8H3uh8bvu4u6FnVIswQBtgqyCpnrFmeH329GPUolDMUbm59MfGrjSuQgIp1sVObc3sugLP+8nJnGZfk+IaWxMhgf/OHjapxO0TQc+SjPPUdHecAqvKscB5RgOU6GcWNBwEFxTtrM8l/olIjVLub9ScXcJTLI3A3/IFpSyje7phcBP94XrGo45arFblKGTd3WpycKX735iGnmmVNkvMOUL5O7dYw/feYgS49xCsYXPWcV3jZmAH3MHC9iQ3juSjoiCbb+H46i0gBKZ6BxfzSsQbMpyo0dLLtjVZ3CutkXGCCfedxbh9cUOJVV9ZWi//PBte3/VYLea7SN2JGlO8rEPJo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-15-43Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698822816.2585454,
                        "modified": 1698822816.2585454,
                        "name": "/dev/shm/incoming/2023-11-01T07:03:35.ais",
                        "sha256": "b56e5d4064f82a20f41457851e4bb87b3961c1087651d13f628c8c3a081d7b41",
                        "size": 660529
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "4e8243a10aa2beddcaa18d39bb9c5f3bec913e61632fbcd0bfb7ed4b0c410572",
                "size": 199729
            },
            "errors": "",
            "filename": "2023-11-01T07-15-43Z",
            "size": 199729,
            "verified_at": 1698847396
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698823398,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "iKQ5d07ZqN+Id27D+XkR2gH87GJh8WRXvqdoGAd85Yyi43Fb6jG4swSz4ClZVJwfQH+E8XcWp5vr7VdajUH9DCxPu1tfTAHL+0yrNWODrj6CVxw9EjST6XhItQ2VCshFDVW3cyWcCu31jEXXrBLhelxKVUPaHnaF9Rxt2w+68kDVsZtlqIqILkcPaXbDF2ifc2BAc754vpGa3xW1DGS2AjiGmf9vCftp7znEgtonGj5mztMxjh41AJXqUSHtborKvsQENsv4Ty52h9E4Q6gqGsZkvkmV9N+oir942+jOJ8nBCVtBUrzopvSMjNUtyje/iH7F8KZAJsDCF5CQ8KGx+yLRVLAf3MXdlpmpNA2yb46Vm2gpA5eD3Q0MbA9MTtMZQhIsFgY0vbG94V3eH12Yv0Qw0HaB8gJEkanGF9Gi6NNiAbSp72wJCogH6ncro8JsZymW1I71WMXplYNi7vyUO4vnL7/4/rJtuntW8mm70f/4QydKF3p3l//XLbKFuAOjnH6Ea7idqtB8YaooALgJKfSlDIP7KyGhyknmcQd2f/QiNxL0YBGSdBDMmcT5iEFQCXI2LSQ5GScujFMyG4O0hU4fwnx7gYw92tgp5ldvQt/QcEaojWRutk40hWv+MA61T5GmwAhcXJRj7Mqo3z0fFTaqxQJeixpxv4E8Pow7Tmw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-22-57Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698823248.9196627,
                        "modified": 1698823248.9196627,
                        "name": "/dev/shm/incoming/2023-11-01T07:10:47.ais",
                        "sha256": "82157cb9c3bf47cd944800a4e7ebe3cc753eefe666cc4e5e2fc2ce1356f3ac42",
                        "size": 992247
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "b622c8c1c5e7653153c8f85e313bf3fa260b0d7b5cd35645d8cc3c167a3d5ece",
                "size": 314327
            },
            "errors": "",
            "filename": "2023-11-01T07-22-57Z",
            "size": 314327,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698823524,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "esuMNqMURiDFEMDvFIAK9YdrdVnWkL9otw9NDZb3TDYfbOpXIXmEEIFLfdV+nrTy8p6vXxcrM2uIyxdBegx6W1qhVEO+NK8PEAbUx4m6hWNSJkX0fOOVhaAfNNuiQa8H7402MgkfbUViXXre1Wlo6sA4FLalbFWrqNmKYQewKnI5xU4dKL5odibct9BOU1EP+7uBfzqq/kJKIKNAaJg2su+bL66TcsZK8B8LJkVUOT6lFqeyg27LUquan59Ag0PdoHjGdxlwalL2GEHR++8Ta4W8StNfQzIzxKFv/ioOYIbvbCkWhh2EI44fq6OH4T1BY56AXc+22vGI6AsQ/cCLKARpPYFvNz7LyKaMeUWEPRmk5UTpUhdxCdWPVK8fUYFdWrhaQdeFeoIlW6ZIJ+k2Hzr1q+GjSxXIh1JJheQ8+bq33BXg4r5M5W4mt+pU4hm8x984ATvFAETyWjQ3ikY8EyJ777M7VBY9j5lx8f4Ub+SMp0tteho4VXOfALyJERVyzDd3zmxlgR6jh5PG7stCUirD1qXzGZ4jib8jjFfeIo0ruTg3YYIUBwlHBDXbnAR6ObgKtS/iRWe+JkMQuxWs1EQhWuKc4bz/D6kED7QHP5O+SLeVjoYWP7/USIyUQVp7x1If2m3fku8y1S4xr2SRtmktpABVwH0U3gUm13aJAyI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-25-15Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698823412.1182222,
                        "modified": 1698823412.1182222,
                        "name": "/dev/shm/incoming/2023_11_01_072256_00_00_V_016.txt",
                        "sha256": "3df9326838995e15e147ca32b0f5ff449f991b48ac1c291558113045d35c38ac",
                        "size": 1951
                    },
                    {
                        "created": 1698823403.5082223,
                        "modified": 1698823403.5082223,
                        "name": "/dev/shm/incoming/2023_11_01_072256_00_00_V_016.kml",
                        "sha256": "219890423dc0d93d60d6e5b126baa1ed5d35916965ee3615eb5b9fa3624c0d50",
                        "size": 956
                    },
                    {
                        "created": 1698823391.5982223,
                        "modified": 1698823391.5982223,
                        "name": "/dev/shm/incoming/2023_11_01_072256_00_00_V_016.jpg",
                        "sha256": "0e75aafdbad8a053f8725d33706875625259cc4c28be5f00a4031c29d4b4cb47",
                        "size": 1118854
                    },
                    {
                        "created": 1698823315.9082222,
                        "modified": 1698823315.9082222,
                        "name": "/dev/shm/incoming/2023-11-01T07:11:54.ais",
                        "sha256": "297d9f45e2126d4c7b36c9216043d60354aa2e808dc5a29506c4d265e436dff5",
                        "size": 22979
                    },
                    {
                        "created": 1698823304.5182223,
                        "modified": 1698823304.5182223,
                        "name": "/dev/shm/incoming/2023_11_01_072125_00_00_V_015.txt",
                        "sha256": "30f9106632c41d7ffaeb3b61f0add2160901c48dbdef2859a3808c80ced2e792",
                        "size": 1532
                    },
                    {
                        "created": 1698823302.5982223,
                        "modified": 1698823302.5982223,
                        "name": "/dev/shm/incoming/2023_11_01_072125_00_00_V_015.kml",
                        "sha256": "c198d8d055557f226eb8927f02a9ed7935a984a77d90aec03632c339679a702e",
                        "size": 938
                    },
                    {
                        "created": 1698823302.3982222,
                        "modified": 1698823302.3982222,
                        "name": "/dev/shm/incoming/2023_11_01_072125_00_00_V_015.jpg",
                        "sha256": "ddb161f74ab5c25e2eb485bec827993adb46672cf76123fe97d2676c97d691f2",
                        "size": 765508
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "0b0e5804db2d4dbc23cd03d6e8b0969479a6667d3866ee5f71702a6cd703e327",
                "size": 1886143
            },
            "errors": "",
            "filename": "2023-11-01T07-25-15Z",
            "size": 1886143,
            "verified_at": 1698845450
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698823912,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "wjqdEKIvQY0YA2+o0TGlfC0s9ex7t6bffbiZOovgWAPOHs0KmSZa0o3VMKPbnbZoBFpciB3IlTlkKWcPtTHBPbPxzSvkkTWcPOW/iE1a575sgdxWCnIDl9o6pXuGi/pqTfk3SFnM3AG1xyitgNz1KF12ozgCSJKikCabRNo8C6xFkMm54b6Qo6T8r5ZhYY1iif8jfj0rnMpJ8wfWy6FGW60Z1w1h5PmlbQMDygV/tyLKp1KigFZKzuvFsSXaTtEdtDdXHx14JNBs6/5cPUJc/CsprRqjsWsxMCAamsxYCT8PcMKG3yQQU+WoZzeZ/FtyptKLf2IdmGtPTtH4BQxjHDesisjOutw3x9CaXQYVHvjDTTDTGLCVt4TQ+RnOzamjB/uASM110y06DnIhKKun7VZV/xkyIhypZzTQxj4f5jgZt64yAGTFf73+Q4gd+7fa+Vo9nkPjwYTMFfrJTLg/ZahIEG6Bln8QENa1hRCUrYN1HyCl2UecWP4lOYrGBbxPGnj5q5XrpRxhh/iuGb8pOvcdwmRJzjzner93VH4OC/wwvFFU0oQu1h58ZyMWs5B5TXsrsVXYMwI/juq9bpm9K46HgoDNgUHrAkMhmOsz4Ua3FWy9mKT3zOplzONufFwMjzhtYoIfam42GL0OtlvXTneQTwGrUDCfqwDYfYRDaEc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-31-01Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698823854.3582222,
                        "modified": 1698823854.3582222,
                        "name": "/dev/shm/incoming/2023-11-01T07:00:53.ts",
                        "sha256": "ec5d8bccaf91b6d13775a54730da356a5e73d079a95ab75c1e9b0bcf491190a0",
                        "size": 410763080
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "f417376dc8da0949b4942a0391d217c4347300ef35b89056d4c1b669e66933db",
                "size": 396301770
            },
            "errors": "",
            "filename": "2023-11-01T07-31-01Z",
            "size": 396301770,
            "verified_at": 1698845960
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698823569,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "lV69JYEtnD3TEatCgvnMAgDrisxeRczpF4uz8Ka6RBknY50IxuZZ/af6PX3uuv8ACfeA+a1xZM4aUt0vzTmTBMStB0GaTmmFIoeo1UeICh9WlegF/dmJV07+QPs6pLQNgLSfJrFpfYKUrvEfKil6WfoqOsshqEVoElF5euAGeUB7jAfI7MXY9/mzdkmg7UN5rJtGUAcGrlfSh/eKtfVdkmyyiMyljqagcx4I/hKxSEyUg72xEgqHVvit+jcWbrOtBaMI+urPy0FNExOFofpSmL1wEAQe0UYZUdeFrAQdWVK7yLAJX7M98x2AoZO3eqEywIq11uI2cl81FTxs9RTaQoH6QRMTExJki6Y0A54fQcXLKQBlKakOKgIwnJCJ8EiWUcwSEIv9KLIuv6pTMNC7dMg9wkype2nIuJ09M+Hn7BBhCFHCaV9cl5tW8iy1gzNr0U9gjxop3q5k6N2JwyvHBO2UAKbFf512I7wmUYw5Xxp85JGxZ28ZIusU1ZLBydKOKryL/946hpSZL3ky9FRwns0FUJpHH0fkX7Kh8MrQg3uMg/MhCmMgUoEaSe1OvbWTFvhT9CQUza3k5r3bUbStTmsggYhvANgF08FWHom+HwPuU3RHYuXcGsf9ytOjHS8l4PKC1T1bX0ub5uYjDAEd/NrT2lXQ/eKZ/RyMLNL4TpU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-25-45Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698823417.1685455,
                        "modified": 1698823417.1685455,
                        "name": "/dev/shm/incoming/2023-11-01T07:13:36.ais",
                        "sha256": "035e24a89cc229d9b9dc576b3fcb09cb15495d2aae934450dbe412cd6cb01b15",
                        "size": 635159
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "a809118b6e9e8d7c9405839b0f5a7f298bef008a9285d9bc6fc262939a981e07",
                "size": 193542
            },
            "errors": "",
            "filename": "2023-11-01T07-25-45Z",
            "size": 193542,
            "verified_at": 1698847401
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698823997,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "KSueT1xIl/FRtfSfEW4tKn4xFgyDKwFE5h679sJtorNpDux1IKkgNxwQJxAFLoiguGj3olqipCBRw8XG6YoxE604jqC2Zxdqhhxh9XgMOKa8ppWXqElclAPKGZC9+eamMaghBdXcvvBM+TWSLH8L+KdazPaI6ubF+Sm/kY3v7Ft8L64WzPyUtWBZ51WNFKh+CPTeAWo/R3HiaTEQKJUV3U1QOBngAMeGHAcugbIWha29HO/w/BZK2hb2xHU4jKhMwKJtBtWDz9bg/AWP+F8079Qy3HWagqU1qvKbfSZep18gf3GeMwckFntWnb2ZMpJ7VTVGmFFFo+e/1sOXjF34iRqbdIZZi984J4fzLFs0RXR7Yzz0mF94CC6MLsFXCgDdQahiwwcLKVcSfpmBgFZ6+Kem5I7Rx80WPjPaVJ1vF1hPNv2MmHxhaLe/nUjMpYmiN4MUZQVFiX1NOMpEBuoHktj0LeSZN4r2vRY4SSgo26iGA01ERZSFEPcZftzEYVkaSnqrqwbtWqBPP5K3SbIsSRA6ThvsPTaZPEwCdAWBHwV6dq7fhoEcexg5FweslSLbfa67WDQvXGi/KvOXLLVV3dHFinZdf5wLJKhm7E61dcG5kfShANIBFuFtHDnRliVX1eviw+9hc7p4ExJ9WHd6Hzt5ECNmBuVPw47NQ8pzgI8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-32-55Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698823849.3796625,
                        "modified": 1698823849.3796625,
                        "name": "/dev/shm/incoming/2023-11-01T07:20:48.ais",
                        "sha256": "d75c3c9f64359cc3455fd82ee4a479c4b9b64f33337f4fa1f0021afddd092b3d",
                        "size": 1152024
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "a943a1b988d27204797c81b92f1930b9eb8ac3f9ec6226db7dfdec71568ab8a2",
                "size": 370819
            },
            "errors": "",
            "filename": "2023-11-01T07-32-55Z",
            "size": 370819,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698824641,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "KKjtREJpp6uOUkw7vIBkibpS6VaMZKPcu0TxwQeaI3run8ka43fP9ttHfcN4KaKCTqkdbpOhY7Oe06s1T+eOqT7inoxAs8rJfUh6TGuH/+uzHgJ9ApXepEiwxLgykATK0/Zm2Ny5a/4cbtewhXy2Lyl1T+LdAGG1oIFGaXvQKOYC3ChfdBcOaTjbz2WAhREVs38ca8SkCk5FnShcMP+VpXNrhRxVqHO/ajjuIRm+LWytdxWvcBlZ4cuudxejjaLuHpr82aeZdhmkeAF58XuyG7y5i7v12V+bg+Wjis8oMCwhkiR0B4ELYGj2x9AWQQBdAAPxEBjb3OxfGOOGF1420amZC0xD0bPZTr7EtT32wW51+b/xajWDlhs5Fj/+aJOTgbMLuXq+wOc9g5bFUfqfJRvFn8I+/K2SS3+Dv+UWff1C7we+SaHwZNrdv1bUpKOzuCnvBmS2WTm8JJupFjxnGmBw50R09bSjGWrn2UvFQzysuK210rW7RPYB2I4hPhwIkXjJJqT9BRQAyrsFsCx/+VbPXQV4y/0SkWBVSFVaw7bAFdaZGMRzPzMj2blIHpPaoKbGBW4fSIah5DmmGjBYQt/JYiR/zPwv4St6DocPOBB6v2W0F3M1oqd4cv7QO3tuB8VTE7k5wlS/u68JoyBXfJbuZBfsXfPEe2wnRo1fdUc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-42-48Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698824450.7996626,
                        "modified": 1698824450.7996626,
                        "name": "/dev/shm/incoming/2023-11-01T07:30:49.ais",
                        "sha256": "91c197607ff048b3341e31b9ae77cd852343092dd5f4a3820ee60bdbf10122f1",
                        "size": 1105174
                    },
                    {
                        "created": 1698824561.2596626,
                        "modified": 1698824561.2596626,
                        "name": "/dev/shm/incoming/2023-11-01T07:12:40.ts",
                        "sha256": "cfa0c619b5365081b0eb54aee10f545bba9dae7b6842398796ecf3a88f842eb6",
                        "size": 441231676
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "d91ce15650756f975e13fac423f45725ef81a239490cc79eb867807f747a0bf5",
                "size": 397774894
            },
            "errors": "",
            "filename": "2023-11-01T07-42-48Z",
            "size": 397774894,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698824050,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "RzEDMb61IUaPjHdjARwYepP9yPfLSsFltlNoO9pgnLt8UJwZk0UvI+TELD4cRVTvnZtGoQsMwz1uDe9TeNvxusDx9fu5rCUmJFV4vHE3yHqQQ0WPWv93RjhhO9CSc0xkQEvyTHDR81sACGwbTe/3Xe4UYLXKp2jJtqI1sDXNTJWrcjyHCNy8393NJGkRMEjcKQSyIeTENIgDyfMw7UnxITKm+PFwDsIt71fjxYwIxvX005Z19MnKTQGZai83T0XQGowiOtP3kgNpkdUTBZYJJvvUwqPvgqzi/HbfRT0qC5XDvAGkK+oV0aRR8O0OirPRMJubb0fpppHQo8UsnoUifXlaRXilJvStgGJ4lTOT3+VhHFAzku4H7fmaV0g2NmXRu0/pGeIt5MBGp99I4gPSD57HUS9DbgpVPaG/6FKdAyk7X+t0gew0NTOjqCbSRRwGhFgMdXFMcVvqhhYHkWVUyYIY7SD1WBPt/SUWNQd94xbQv8xbzOwvJmioSh9f1GQDBUa6L+KHjtmXe5sQabzD1p0DzL391ji1afBVWqExSesce9ARpDMYGv3dBULHWljRngXWZ/jCxU9ydrUV+PdYD6soLclmUaZALqDjKirtUTTKRLI9LsNneXrJvtst12lLWjeQPHKDR0ojvtAG4pVrMwfdkJ+uo9iFvEvQsrpDHoE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-34-01Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698823916.6982224,
                        "modified": 1698823916.6982224,
                        "name": "/dev/shm/incoming/2023-11-01T07:21:55.ais",
                        "sha256": "c87dfab27fe7ecfa6af927224bd761fdce6b2066a5527a87daa93ac659817111",
                        "size": 23284
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "984e58fd056f427a9496f9af98c8e808769d7eaef9abe5ef7a475316ceaba104",
                "size": 6558
            },
            "errors": "",
            "filename": "2023-11-01T07-34-01Z",
            "size": 6558,
            "verified_at": 1698845497
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698825227,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "hjFZqxb7AC7cjy5qfMDuAOTbEOftnl6vT71E0/5oes3D+TWQlKb69iLBQlQZbagSCPtaPtlJyjPOxhxOHOompr2i2/Qlb70iC4SsnciMDe3qrHfG72Cl2Ik6MPfW9EPtxHWgWj42fgj6NI2xBwaJUzkMdBzCU2/sy84JsQj/SCaSF8TAUTZbBdcxP0+pmUsU/I0VDkHzbb+xx3wSScbymg/blGU4tN5FLy+XTubFj3EkbAdQIuOastNzIRVSsKluv5baxMhZsEL9bp8F/otopX3rfwwlsac9vk72oRy+4kCa+YTNXRA2wdUc6L+aZ9PemP1J5xu+AOGafbUccWL8ony15gG97ec0SKYOa5nJVtuwemVSmGWnaJHhmpr/deS/zv8H+jnldSb2uJUs5H8lyy3gLtMg/VnQrgUqA9KAW+XawaM9XUnCQA9aUzSz0eu6U0p5pUuQ4YqFw8gN6yVfrVvgp/AVnaQm2ZKfgHgvy7Ek4qVuHeYNz+iFkUW3ogKKCA9ox5LFmvxUYxMIrgL56vQv1YOVqQNilITAoY5EX0b8q6NofIRK5XDTGDHFGtnxy+uqLWSRKrhGu4EOzwqNVmm8UIT40xD3djJKC7lp878BWYOD+KHhujagGNV0NmjJR0aqK3S4e8z1+IQX9FPsD+MNvPGpAdEq7YL3XNYexTY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-52-54Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698825051.0296626,
                        "modified": 1698825051.0296626,
                        "name": "/dev/shm/incoming/2023-11-01T07:40:50.ais",
                        "sha256": "062eb956bd85dc733cd0cd46a37ff389dc5d28308f6cdc471d41e46e235e0b8c",
                        "size": 1014895
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "f71bd894ff10d4400469f84bec506f1798e8d810e085d5c59b03667e3820900e",
                "size": 324285
            },
            "errors": "",
            "filename": "2023-11-01T07-52-54Z",
            "size": 324285,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698825257,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "oHcXQmwMB/mH8HaHTOlnCPecJLXTr1C3CQsYnD/ddx45UoLWf6tRF0LedgIUBCBeSXXyx4+hSjslN7nB3IsOu3Yl+NUaKV42Oporr1qzWM9cirGi1vVGoXWHDhU05lJYL87oA0oc0PzJniAWtjxBUuhl7cBVite8pQ1EdigxO5XI5QQbdz90F06H+8TQWMLy7ufAVbrj+8pY8lD7VNiYysoCRVUhAcBzqYEiySuDEBQPGxEWtaEjtopZ1c6+3917MwOK0lnqgEilaUAlJfpaNQn+wQhmDPlqfX5GpZ1OF1n3neWyH6Srmhw/px+W91n1w6TIi5ZliEbGleX0CQ02LlbBcm9QHGzzOsP5M7nWVOJmpm+NgIhDkEKSOD6vSIxUzXmYvxrKLAJm3724OxLTrRJuiAwuhI/qYENAJH86Uo+upENJbaYH8PKt6XySn+lLNk8PWBPOs+Rm36z4S21JSKe/YMf1X7Y7eQ1M627yupEA2MOX0zDPnzC2UP2IpYouXgSH2x1x6E/mMVsJgH5e+8LQA91kGcN/NMyDPCMFLXT7OVmcPV04FNk2TBQUI4xcw3BYA5f/5wISHfjEVxm/lTLBUM8T1Tq20URg5BaNKdRKSTFx6i5wWTt1iNR0UOYkEPVDTL+DBK/0AidoCxT/hgwy7xXcRlcPyGwKB2LERic=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-54-08Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698825120.2782223,
                        "modified": 1698825120.2782223,
                        "name": "/dev/shm/incoming/2023-11-01T07:41:59.ais",
                        "sha256": "86320caf29160fb3c8674261f98019fe0cdebed6847615fff74b5ad3255bc498",
                        "size": 48145
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "52b7e055480b3e13905e3deb81d4f058172b5920667ec371685ec9e0c37c7c10",
                "size": 14411
            },
            "errors": "",
            "filename": "2023-11-01T07-54-08Z",
            "size": 14411,
            "verified_at": 1698845502
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698824770,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "cgxnxbIx+c41pUoe/W/iVLyHny+/BP/r4kUktgvs1mm4kklcau9ioqonGNPw+HRlkEDr1ALV/9VDY6UVI6DWWuBeBAerfbyGewVrl2C57Yt5PqbyPlTRHeDHHZ5N304ms6Cu8OqGrIcI6nZHRm6qrkJqet2Il2QuzHrvG/DLjuDZM91RlJw2stDD+0E/OsBkorbOP0+wulfWwazrgx8RwQLfvWC9Jg3G7M00zuG89xe3Ac0+I2816X88M/1nk1JkrVhmFhztFuBkPqNKwfecCr1gdQ4kYPaKpnQyp9H0H6mTIOLSbzLxPJ0fgC2ttzm82erOjwEiqMiVro7t9asqcu2HGn32ejPyFGq9C9pwz34ab+o48r4ZBTACYxWtk5J1JgMmwu2ebj73VbFazpSYrgW4ZCtSv894/MMe8NedWHGf2gUdeQNLUqTzS0xEwM2BzdTF+ttGShA1UKoroSBWsu2R6B0lDjfcZvGw6dxDARapqV1H2Q05gfho43yE0Ssp+v/sWgr1N9dPvqXHL2hvvAJ0ad1WZRAaJNSqgGByUeihLzZ3YniDUjD0/0rNh5KY3itRLvYP2PqVNlxr6PTyBVI2xuE9SMNlnuSM50gEEfikGCflFFl3fRlLclCeG21TkGkYOkIxdhcqu8g/yEOW71X4BI6FD0l69lUz5ILA+QE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-45-47Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698824618.9985454,
                        "modified": 1698824618.9985454,
                        "name": "/dev/shm/incoming/2023-11-01T07:33:38.ais",
                        "sha256": "4a5f54a545fe020d59bb69544d026b80ab33cfadef83ddb21d72c7529e0cd185",
                        "size": 530230
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "be1d9fb81869f06e1e660a4ecfeca97f86149aa65b9773c025f37ab1d0dd4e8c",
                "size": 161555
            },
            "errors": "",
            "filename": "2023-11-01T07-45-47Z",
            "size": 161555,
            "verified_at": 1698847479
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698825698,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "TPeuncG6pdiVplELO/nlfattUDaj9iu+fu0lkpJQgrg5zErKt9LpCfrkxV1NAOg2R0FiFmwfPmFHiukIqLDiateFf6VeQiNH2+ovqpbN8D5yAJXE9985ZbgbYb3LEhBOsJKijh8wTyzyLnGsV5x4wbndnw+5qeEASipuIIOjZXKMfasPnYeBgggOVaRIEUkIcgDWhu1V9DZ2ZJZOHo3wwqB4OL/RswJmoqqnQMmTB++RIP/CSfFWu1R6bLRDnO1z6P7NDGHufRWW5kmqjaCr7iuRATsb3UXS76E6XPm8AwmtOxeL85qEwGWEqx23oTjc4iT+O5Ujpqew8m9SVFReZfbSRWDVCIlpLU6Tysr5fBt8idSbf/MqVqx2aKAJ0E+HfeiX1viSyENXGxDEPzQv6RyeN3LxSOxkbGrMW2IdZswjtyt/Qgk0DtA7FTh14MKahmRKmHAG/TyLZTf35wBauHUrMLlDDYmAIspo1uewkEQZQF9xmNVSxEEZJMBuwU8A4SOrZjwL7TrwSUQPgJhzRoNBoHejs1laAECJsl4HoLaxeREd7kX1P8wcY1sJfm8nQTVCOI3CE7WFtJSYxItfbM/UUuy3wr9C3ZtSSQZGYyPSAPu401pZvvw+RNl7lrsgZWpp/CCmUXzk+lnAvq9WtjURBRO5u3NuymiS575h3PI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-01-11Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698825551.6485455,
                        "modified": 1698825551.6485455,
                        "name": "/dev/shm/incoming/2023_11_01_075845_00_00_V_026.txt",
                        "sha256": "3889d6a7cc99ff9dc7016ecda41af09336d98df91e607b2367ba03cbcda2f3d8",
                        "size": 2033
                    },
                    {
                        "created": 1698825551.3785453,
                        "modified": 1698825551.3785453,
                        "name": "/dev/shm/incoming/2023_11_01_075845_00_00_V_026.kml",
                        "sha256": "003be809aae8fcdd3291fbf0cd3a9101071d2de682d9393be4ffbb0601496fa9",
                        "size": 978
                    },
                    {
                        "created": 1698825551.2385454,
                        "modified": 1698825551.2385454,
                        "name": "/dev/shm/incoming/2023_11_01_075845_00_00_V_026.jpg",
                        "sha256": "669e6813083c545bce0d5c007183ceee977cf081237358f660a3eae269f6bfe7",
                        "size": 814412
                    },
                    {
                        "created": 1698825546.2385454,
                        "modified": 1698825546.2385454,
                        "name": "/dev/shm/incoming/2023_11_01_075839_00_00_V_025.txt",
                        "sha256": "a9d6bea0dfcc9e814d45df9c0932dbf3b6d412461b65a336674719c4cb7a7ac9",
                        "size": 2033
                    },
                    {
                        "created": 1698825545.9985454,
                        "modified": 1698825545.9985454,
                        "name": "/dev/shm/incoming/2023_11_01_075839_00_00_V_025.kml",
                        "sha256": "f9b099617fab06ada0325f159895642dbb9d272baed65b364d8ba8f15ae9134c",
                        "size": 976
                    },
                    {
                        "created": 1698825545.7485454,
                        "modified": 1698825545.7485454,
                        "name": "/dev/shm/incoming/2023_11_01_075839_00_00_V_025.jpg",
                        "sha256": "b87f9f5419c3cddc2fee8033fa55557aaa4f5d841979e3d95ec1584b6235dc9c",
                        "size": 726146
                    },
                    {
                        "created": 1698825540.9885454,
                        "modified": 1698825540.9885454,
                        "name": "/dev/shm/incoming/2023_11_01_075833_00_00_V_024.txt",
                        "sha256": "2952fdb1075dfe7f1be8f71b7cf9e8387d37a215f24849d7174001a5dafaf9f8",
                        "size": 2033
                    },
                    {
                        "created": 1698825540.8085454,
                        "modified": 1698825540.8085454,
                        "name": "/dev/shm/incoming/2023_11_01_075833_00_00_V_024.kml",
                        "sha256": "f7281fec8d401200a1a9eac26100faa7fdd5d6efde8b8085b698685eab56634d",
                        "size": 978
                    },
                    {
                        "created": 1698825540.8085454,
                        "modified": 1698825540.8085454,
                        "name": "/dev/shm/incoming/2023_11_01_075833_00_00_V_024.jpg",
                        "sha256": "e644b517296aef6f3e70bb12f17034931fce29acda70e5942d51be84ff372bbc",
                        "size": 741889
                    },
                    {
                        "created": 1698825532.1385455,
                        "modified": 1698825532.1385455,
                        "name": "/dev/shm/incoming/2023_11_01_075828_00_00_V_023.txt",
                        "sha256": "6e8359cad955ae4f4ea44ba720afe6ed7d30824b3acad20f3a7f650e7fec034d",
                        "size": 2033
                    },
                    {
                        "created": 1698825531.9585454,
                        "modified": 1698825531.9585454,
                        "name": "/dev/shm/incoming/2023_11_01_075828_00_00_V_023.kml",
                        "sha256": "2a009e0f00db3e41c741d093f7aae60359cb18ae1b1ef43f219c5de6d0b7a580",
                        "size": 977
                    },
                    {
                        "created": 1698825530.7685454,
                        "modified": 1698825530.7685454,
                        "name": "/dev/shm/incoming/2023_11_01_075828_00_00_V_023.jpg",
                        "sha256": "e899a5b18b4c2c08c4c4ab8c4add227ca8efda6b2802da909e76388f19442f2f",
                        "size": 759158
                    },
                    {
                        "created": 1698825528.8085454,
                        "modified": 1698825528.8085454,
                        "name": "/dev/shm/incoming/2023_11_01_075824_00_00_V_022.txt",
                        "sha256": "6e0855a40b0c99ff6454b931eb44d1c51b36566ec4bca931d27f53f21a10d15c",
                        "size": 2030
                    },
                    {
                        "created": 1698825528.6785455,
                        "modified": 1698825528.6785455,
                        "name": "/dev/shm/incoming/2023_11_01_075824_00_00_V_022.kml",
                        "sha256": "20b8cfee8de2b376ba82ad5ed838250708331314194729a6d4ca5c1953b605e6",
                        "size": 975
                    },
                    {
                        "created": 1698825528.5785453,
                        "modified": 1698825528.5785453,
                        "name": "/dev/shm/incoming/2023_11_01_075824_00_00_V_022.jpg",
                        "sha256": "a17c16df95e2fcabfb231930f927ce4826cbdc924daf51efb0385bb08a640c75",
                        "size": 759661
                    },
                    {
                        "created": 1698825511.4485455,
                        "modified": 1698825511.4485455,
                        "name": "/dev/shm/incoming/2023_11_01_075806_00_00_V_021.txt",
                        "sha256": "1eec7c7d5529fece2ae1690258500b16e6d88366dc1c6affb7bdd6354e431cd7",
                        "size": 2034
                    },
                    {
                        "created": 1698825504.8185453,
                        "modified": 1698825504.8185453,
                        "name": "/dev/shm/incoming/2023_11_01_075806_00_00_V_021.kml",
                        "sha256": "5022d94ff27a29b90e31fa03ccaeba1c199fdbfddbd02a15ef076b5a64430682",
                        "size": 976
                    },
                    {
                        "created": 1698825511.7985454,
                        "modified": 1698825511.7985454,
                        "name": "/dev/shm/incoming/2023_11_01_075806_00_00_V_021.jpg",
                        "sha256": "3e056b997379f290b575a72acf10666bd2dcff14063982aea14d485c71e3e361",
                        "size": 755013
                    },
                    {
                        "created": 1698825438.3785453,
                        "modified": 1698825438.3785453,
                        "name": "/dev/shm/incoming/2023_11_01_075655_00_00_V_020.txt",
                        "sha256": "c144f8c09594c8da40d32b402c40ce2e21d1394115fb930469bb295a6d3ab173",
                        "size": 2035
                    },
                    {
                        "created": 1698825447.8185453,
                        "modified": 1698825447.8185453,
                        "name": "/dev/shm/incoming/2023_11_01_075655_00_00_V_020.kml",
                        "sha256": "724441e053908f6a18913b1cb829ee538ae052c419ecfdb2699d144061d8e08e",
                        "size": 977
                    },
                    {
                        "created": 1698825437.3385453,
                        "modified": 1698825437.3385453,
                        "name": "/dev/shm/incoming/2023_11_01_075655_00_00_V_020.jpg",
                        "sha256": "b790c915e5092e3f610063721fa18362ad5af210bb5be110f521b555a2170ca7",
                        "size": 1368430
                    },
                    {
                        "created": 1698825426.5085454,
                        "modified": 1698825426.5085454,
                        "name": "/dev/shm/incoming/2023_11_01_075646_00_00_V_019.txt",
                        "sha256": "2462db1b23bb230c4d37ffb6f1e55911f3e707fce447572cbb8804b38009dae9",
                        "size": 2033
                    },
                    {
                        "created": 1698825425.0085454,
                        "modified": 1698825425.0085454,
                        "name": "/dev/shm/incoming/2023_11_01_075646_00_00_V_019.kml",
                        "sha256": "4338ae2b34ae0d258b92d54981d1b7fe82af84e900a68b2882dd35210d695ad8",
                        "size": 977
                    },
                    {
                        "created": 1698825430.9085455,
                        "modified": 1698825430.9085455,
                        "name": "/dev/shm/incoming/2023_11_01_075646_00_00_V_019.jpg",
                        "sha256": "8af37c745ca41c598a59f3aca36744754e52d8fd17876245e1d6dff6061f7ea7",
                        "size": 1266192
                    },
                    {
                        "created": 1698825422.6085453,
                        "modified": 1698825422.6085453,
                        "name": "/dev/shm/incoming/2023_11_01_075639_00_00_V_018.txt",
                        "sha256": "d0a2ab2b125ad4294eb4e4ec4d18e06f6c468a7441f29ebefc03dd32228eeb3b",
                        "size": 2034
                    },
                    {
                        "created": 1698825422.2485454,
                        "modified": 1698825422.2485454,
                        "name": "/dev/shm/incoming/2023_11_01_075639_00_00_V_018.kml",
                        "sha256": "cc33709df4e580c910e56aa8e0cca081606650685f3457b2ab0cc9d40d3d35e9",
                        "size": 978
                    },
                    {
                        "created": 1698825411.8185453,
                        "modified": 1698825411.8185453,
                        "name": "/dev/shm/incoming/2023_11_01_075639_00_00_V_018.jpg",
                        "sha256": "6124f932c1c92e9148e0c5a78e34f54fd9b16a28efaef602d99a761223ff4f6d",
                        "size": 1304527
                    },
                    {
                        "created": 1698825367.9085455,
                        "modified": 1698825367.9085455,
                        "name": "/dev/shm/incoming/2023_11_01_075536_00_00_V_017.txt",
                        "sha256": "f17a9b7c8579c44de76bfdf85429f965efd6af8f92f16ba05c82514f376e751b",
                        "size": 2032
                    },
                    {
                        "created": 1698825366.5985453,
                        "modified": 1698825366.5985453,
                        "name": "/dev/shm/incoming/2023_11_01_075536_00_00_V_017.kml",
                        "sha256": "a41e322b9d262dff9a4f189227d8384e7120a409e3b294f603a294e54a4d2d97",
                        "size": 977
                    },
                    {
                        "created": 1698825363.3585453,
                        "modified": 1698825363.3585453,
                        "name": "/dev/shm/incoming/2023_11_01_075536_00_00_V_017.jpg",
                        "sha256": "824da16b1cb80b579d106040c6cdafac3e77c9919e7b35e0741dec845921b534",
                        "size": 1488247
                    },
                    {
                        "created": 1698825278.3585453,
                        "modified": 1698825278.3585453,
                        "name": "/dev/shm/incoming/2023_11_01_075403_00_00_V_016.txt",
                        "sha256": "ef5162967719c0cccffa81a1af6f0e91cdea209289ffb179fb51f8b7501770b7",
                        "size": 2031
                    },
                    {
                        "created": 1698825269.2385454,
                        "modified": 1698825269.2385454,
                        "name": "/dev/shm/incoming/2023_11_01_075403_00_00_V_016.kml",
                        "sha256": "bc4e418999946b2700c3197cea732e451a9c9025474503efafadc2a2ef9c155c",
                        "size": 978
                    },
                    {
                        "created": 1698825279.9285455,
                        "modified": 1698825279.9285455,
                        "name": "/dev/shm/incoming/2023_11_01_075403_00_00_V_016.jpg",
                        "sha256": "36aed1c1b686c178b12dd44fd759b4d529387af0828e8eb45f87b3a3525c96a9",
                        "size": 949140
                    },
                    {
                        "created": 1698825248.8985455,
                        "modified": 1698825248.8985455,
                        "name": "/dev/shm/incoming/2023_11_01_075334_00_00_V_015.txt",
                        "sha256": "fa12b45df5366e8edcc3e86092e01f819326609dd844e1bcf9409a438cf14b25",
                        "size": 2029
                    },
                    {
                        "created": 1698825246.4985454,
                        "modified": 1698825246.4985454,
                        "name": "/dev/shm/incoming/2023_11_01_075334_00_00_V_015.kml",
                        "sha256": "4d088c901e1fd4f27c7f13b85048b9c78c1af348f7400ee6203b6f5ab1730ba6",
                        "size": 977
                    },
                    {
                        "created": 1698825248.2185454,
                        "modified": 1698825248.2185454,
                        "name": "/dev/shm/incoming/2023_11_01_075334_00_00_V_015.jpg",
                        "sha256": "2ca451369110f16e0b5a6c051cfbe2e62ad3135a41f6d2484140315c78d4d3dc",
                        "size": 898154
                    },
                    {
                        "created": 1698825220.3285453,
                        "modified": 1698825220.3285453,
                        "name": "/dev/shm/incoming/2023-11-01T07:43:39.ais",
                        "sha256": "b19a6154874b9f91a81c3443d92d95a238aa5a6d57cede7350c2db9f6e80e35c",
                        "size": 559452
                    },
                    {
                        "created": 1698825219.6685455,
                        "modified": 1698825219.6685455,
                        "name": "/dev/shm/incoming/2023_11_01_075319_00_00_V_014.txt",
                        "sha256": "79e95696254e97ee65a53171c4eed3dbbcc1c7993c6d2a3a15c285a6743de9f0",
                        "size": 2031
                    },
                    {
                        "created": 1698825217.2885454,
                        "modified": 1698825217.2885454,
                        "name": "/dev/shm/incoming/2023_11_01_075319_00_00_V_014.kml",
                        "sha256": "70872917a3a7a3724e5d93fe6400a2d65899a38e8e6d0279334665620b1d3af2",
                        "size": 976
                    },
                    {
                        "created": 1698825215.3785453,
                        "modified": 1698825215.3785453,
                        "name": "/dev/shm/incoming/2023_11_01_075319_00_00_V_014.jpg",
                        "sha256": "06f173a8b45d46bc13e5934cbd31049408e938e89e878607129860d8e7ecdcf7",
                        "size": 940808
                    },
                    {
                        "created": 1698825193.4885454,
                        "modified": 1698825193.4885454,
                        "name": "/dev/shm/incoming/2023_11_01_075251_00_00_V_013.txt",
                        "sha256": "ad8d83db2d6c7d5a7c649c532b9da371f0c6a940b3ed6bbc6ab0f415a144328c",
                        "size": 2032
                    },
                    {
                        "created": 1698825191.7385454,
                        "modified": 1698825191.7385454,
                        "name": "/dev/shm/incoming/2023_11_01_075251_00_00_V_013.kml",
                        "sha256": "c154536debc469419176d176349b6f8fb3e0af2a96bbbc5840c03607a1f2690c",
                        "size": 976
                    },
                    {
                        "created": 1698825190.7785454,
                        "modified": 1698825190.7785454,
                        "name": "/dev/shm/incoming/2023_11_01_075251_00_00_V_013.jpg",
                        "sha256": "23c70d459938039a34597f95a80a834744578f761598c6e334885c7e0f24ad43",
                        "size": 990502
                    },
                    {
                        "created": 1698825183.8385453,
                        "modified": 1698825183.8385453,
                        "name": "/dev/shm/incoming/2023_11_01_075224_00_00_V_012.txt",
                        "sha256": "f3c9a2d5f4ba481058f4027650e6b1d9d4b7029811d013bd8fa118217a329325",
                        "size": 2032
                    },
                    {
                        "created": 1698825177.9685454,
                        "modified": 1698825177.9685454,
                        "name": "/dev/shm/incoming/2023_11_01_075224_00_00_V_012.kml",
                        "sha256": "8ce01f68b32c3f33a79d67d1a786b923b1f10e37c5f1ac6141c510b121e63cc8",
                        "size": 978
                    },
                    {
                        "created": 1698825167.6185453,
                        "modified": 1698825167.6185453,
                        "name": "/dev/shm/incoming/2023_11_01_075224_00_00_V_012.jpg",
                        "sha256": "4a243269ac6db2ade818cd035727980f2a66711b5ee71b5dfcc8c9bdc65a91ab",
                        "size": 1042793
                    },
                    {
                        "created": 1698825155.8185453,
                        "modified": 1698825155.8185453,
                        "name": "/dev/shm/incoming/2023_11_01_075217_00_00_V_011.txt",
                        "sha256": "08c4dadb2118e5d5d7ef31e6985a0cc7d193b2e1ad30f6aa21982295fe03d735",
                        "size": 2033
                    },
                    {
                        "created": 1698825160.8085454,
                        "modified": 1698825160.8085454,
                        "name": "/dev/shm/incoming/2023_11_01_075217_00_00_V_011.kml",
                        "sha256": "81a51162a38fef6162acbe1df005e8adadec96317471eb4e2bcc6d5a78c9fbc8",
                        "size": 977
                    },
                    {
                        "created": 1698825158.5485454,
                        "modified": 1698825158.5485454,
                        "name": "/dev/shm/incoming/2023_11_01_075217_00_00_V_011.jpg",
                        "sha256": "bc882d0e6c10bdb7bb6a80d928ffdc466ee74376eae3f675e2df58fddde414f0",
                        "size": 1098267
                    },
                    {
                        "created": 1698825071.3985455,
                        "modified": 1698825071.3985455,
                        "name": "/dev/shm/incoming/2023_11_01_075046_00_00_V_010.txt",
                        "sha256": "a948bdf5eb7f3f0d9222ebfc6d0574ab1a2e42cbc9eae061462d6723ca3f9804",
                        "size": 2027
                    },
                    {
                        "created": 1698825070.1885455,
                        "modified": 1698825070.1885455,
                        "name": "/dev/shm/incoming/2023_11_01_075046_00_00_V_010.kml",
                        "sha256": "962d2f16b63235fd22fe6fecafe2ebc4522e0d7ec22c065034675d105d19c047",
                        "size": 979
                    },
                    {
                        "created": 1698825071.7685454,
                        "modified": 1698825071.7685454,
                        "name": "/dev/shm/incoming/2023_11_01_075046_00_00_V_010.jpg",
                        "sha256": "2892ec02b1f47aa267c25f7c2ea880745e6d078473667001835dd8a39251ae31",
                        "size": 1002183
                    },
                    {
                        "created": 1698825056.8785453,
                        "modified": 1698825056.8785453,
                        "name": "/dev/shm/incoming/2023_11_01_075025_00_00_V_009.txt",
                        "sha256": "3d51728b0ab00555762cc250d17a2248d0a7afaa94eb49ad5bad7307a988fbe1",
                        "size": 2026
                    },
                    {
                        "created": 1698825056.2785454,
                        "modified": 1698825056.2785454,
                        "name": "/dev/shm/incoming/2023_11_01_075025_00_00_V_009.kml",
                        "sha256": "8477b4da3a5c9ce7526f24234cff478a1799e0dc9774123c449e2dd4ac76a286",
                        "size": 973
                    },
                    {
                        "created": 1698825053.0185454,
                        "modified": 1698825053.0185454,
                        "name": "/dev/shm/incoming/2023_11_01_075025_00_00_V_009.jpg",
                        "sha256": "1c973f4a5840f02472a96a7445446f507bc7fb498e69334e88ebf52a431f55f4",
                        "size": 954874
                    },
                    {
                        "created": 1698824947.5985453,
                        "modified": 1698824947.5985453,
                        "name": "/dev/shm/incoming/2023_11_01_074828_00_00_V_008.txt",
                        "sha256": "de071eab6717a1d696028a1190b98180400ce5fc9b48db7b07073fdc1234f9b7",
                        "size": 2025
                    },
                    {
                        "created": 1698824936.5185454,
                        "modified": 1698824936.5185454,
                        "name": "/dev/shm/incoming/2023_11_01_074828_00_00_V_008.kml",
                        "sha256": "9fa06380a46e1af5e60549db84d0fa9629cb83d887baa014d4ec6a431c87d9dd",
                        "size": 978
                    },
                    {
                        "created": 1698824925.9585454,
                        "modified": 1698824925.9585454,
                        "name": "/dev/shm/incoming/2023_11_01_074828_00_00_V_008.jpg",
                        "sha256": "cd2143321542c58f20fbd38620c1d37dccad4f30ba43212feb426b6ac10dd521",
                        "size": 978610
                    },
                    {
                        "created": 1698824924.2685454,
                        "modified": 1698824924.2685454,
                        "name": "/dev/shm/incoming/2023_11_01_074823_00_00_V_007.txt",
                        "sha256": "147e51fb63690b28965144577b734bcb67a5d1008ce019358169e04728639935",
                        "size": 2028
                    },
                    {
                        "created": 1698824924.0085454,
                        "modified": 1698824924.0085454,
                        "name": "/dev/shm/incoming/2023_11_01_074823_00_00_V_007.kml",
                        "sha256": "b941707f25e50921fc7e281cf3a448fb2844a0d02bd0b8e0220065f45bc7eb04",
                        "size": 976
                    },
                    {
                        "created": 1698824923.7285454,
                        "modified": 1698824923.7285454,
                        "name": "/dev/shm/incoming/2023_11_01_074823_00_00_V_007.jpg",
                        "sha256": "551101181ba9e3055f69444ced2adbe2748f5d1554ff202c0e6841d97d069d62",
                        "size": 1069046
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "943da0616e88f7a41f8698c4e738673f5567f3e53b0d18d4dceae1b92223914c",
                "size": 20059860
            },
            "errors": "",
            "filename": "2023-11-01T08-01-11Z",
            "size": 20059860,
            "verified_at": 1698847675
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698825004,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "obpjc4jENMYt6/Jr7a+gcg0wO393nxDDd6zAekEmmGAwQiTp83U0l6xxfIBLjW+PPTNS6JwjpQa6bAKfX3fyREDmSpoAuGUYJV+brrAg1hT/0BVu0t4xPBca/T5W8H6DhMw25quINPF8InCCCde0KuvMhM5zC/O4rH7qMjpkQB0NP9iQ0YVA8MGKkHbyW+TWDPr6adS11cLU0iSP6Zisbs/DTBhgOb8iQMu3XiI+ALnumOFkHLh1sKrNL/IOyqsvC+gsDyHqlqe+bwC/6fkvUG1MBbr95rnc1NjSgeGhMBMoYCGlnR0IUzkEL4HXUR8IvolgFyOzvtEG5Z0Az/r3oy8PgpJ0Ums+cVMHM7UBUP/dqPILPY469sy/mqy8eg3OgOMrpw2JnN73nSCTtMeVBeBTfEo51WRcRXA1auRhcaHWa9vrgUhcF2qcA/iUxcZXkOKf9uP9Y6Wzl1pYAOunrX5dOZxBiVcXKTJfeiu9/z51K2fGacsaLhQrZTR3AVaU0pBU2DqPCy2Aunn9b4uUYptxzvF555EmGTYg9m/yRaMp+gdwikaz+NNeJxE+wlLdL5fOamj17VAC6gA1wsW6O94ykj1yXwtTmj4VFQZcmpJE5vtpBAITVcO/II9yKnp87I8G1mq8ASxoEC6Rx27a/HJgwIwxgCS7Wo/cFHDWkPA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-48-45Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698824911.5385454,
                        "modified": 1698824911.5385454,
                        "name": "/dev/shm/incoming/2023_11_01_074803_00_00_V_006.txt",
                        "sha256": "dbed935895c8eceb4cc8fcd8469448289026f21e8c301f3030dcf1646b9ba06c",
                        "size": 2022
                    },
                    {
                        "created": 1698824910.2085454,
                        "modified": 1698824910.2085454,
                        "name": "/dev/shm/incoming/2023_11_01_074803_00_00_V_006.kml",
                        "sha256": "de06413d784566834da6abe252facd8fdb4a4eb07ffa77356186e69e6ce17330",
                        "size": 977
                    },
                    {
                        "created": 1698824902.3985455,
                        "modified": 1698824902.3985455,
                        "name": "/dev/shm/incoming/2023_11_01_074803_00_00_V_006.jpg",
                        "sha256": "6e1a224fde5771baf8ea9fa878fe10132da4f6a4ee15a97a8065149a7ea3b293",
                        "size": 905350
                    },
                    {
                        "created": 1698824887.4885454,
                        "modified": 1698824887.4885454,
                        "name": "/dev/shm/incoming/2023_11_01_074736_00_00_V_005.txt",
                        "sha256": "4a9df0971a8cbffd0a08866950a21004493c0636979829e4d5f313ad0d7293f5",
                        "size": 2026
                    },
                    {
                        "created": 1698824894.5985453,
                        "modified": 1698824894.5985453,
                        "name": "/dev/shm/incoming/2023_11_01_074736_00_00_V_005.kml",
                        "sha256": "414aeebd86884ef292045c3a3eddfdc0ee184f2a3c2a210b866f15d65685c833",
                        "size": 978
                    },
                    {
                        "created": 1698824883.6985455,
                        "modified": 1698824883.6985455,
                        "name": "/dev/shm/incoming/2023_11_01_074736_00_00_V_005.jpg",
                        "sha256": "f9598b81231c323f12dac74f09d5ebf9657b67a4b90c808d1f9247bf6016356e",
                        "size": 909302
                    },
                    {
                        "created": 1698824876.3085454,
                        "modified": 1698824876.3085454,
                        "name": "/dev/shm/incoming/2023_11_01_074640_00_00_V_004.txt",
                        "sha256": "8b73cafc67fd251499f76f528cf706c79b2e84b8d43c30ce0339fd7628d59eb1",
                        "size": 2032
                    },
                    {
                        "created": 1698824826.2185454,
                        "modified": 1698824826.2185454,
                        "name": "/dev/shm/incoming/2023_11_01_074640_00_00_V_004.kml",
                        "sha256": "b5f71aa955011e38f9b3c89c96234f52c31757632a530dc8e4aad2d91e3db6aa",
                        "size": 978
                    },
                    {
                        "created": 1698824827.8085454,
                        "modified": 1698824827.8085454,
                        "name": "/dev/shm/incoming/2023_11_01_074640_00_00_V_004.jpg",
                        "sha256": "c98f37ae19b54ccd1d0dc15dd59029871645fb6a17a37ca06e73bd85994da966",
                        "size": 851204
                    },
                    {
                        "created": 1698824775.6685455,
                        "modified": 1698824775.6685455,
                        "name": "/dev/shm/incoming/2023_11_01_074551_00_00_V_003.txt",
                        "sha256": "69ba6302b89ec834a5781206f466bc2074ec8eaf453cb47aa8ee9eee5e84d988",
                        "size": 2035
                    },
                    {
                        "created": 1698824765.6885455,
                        "modified": 1698824765.6885455,
                        "name": "/dev/shm/incoming/2023_11_01_074551_00_00_V_003.kml",
                        "sha256": "08cdacdd2cd1f9269964599a03007d6598dfb1100582df4c38bf38493124ab8f",
                        "size": 976
                    },
                    {
                        "created": 1698824765.3085454,
                        "modified": 1698824765.3085454,
                        "name": "/dev/shm/incoming/2023_11_01_074551_00_00_V_003.jpg",
                        "sha256": "80f3fd9964f177ca833b3095c2e7f1c6b7bbb4d7a8bf16f4a18046219c7ef21a",
                        "size": 1480286
                    },
                    {
                        "created": 1698824916.0185454,
                        "modified": 1698824916.0185454,
                        "name": "/dev/shm/incoming/2023-11-01T07:18:35.ts",
                        "sha256": "9dd244687934d63ef6f095a5221a7b2b3409b92c6348789148937253ec47970a",
                        "size": 426632160
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "5b70460db4dfc515450b625dbd6b6697236956e364a9b6f568a03dfb5af652f7",
                "size": 404346045
            },
            "errors": "",
            "filename": "2023-11-01T07-48-45Z",
            "size": 404346045,
            "verified_at": 1698848571
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698825829,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "iEajeW3xqlQi6+PaLsHxbBoVkXugw4TjY+MVFb1iPLWo3Ew0wS5+OkPv9S9L3Fja7jWissKCbxE3sS70c74kyCoL1weJmXd7K76i/E0uxKHbGzDixg9bzFwrtzN2CYS2FHf4VpqxyEDuk6H9a7cQdBL7lTETJNYNVPfVb1O8b1YGd3kHZHnhr9meq1ZAoVa5WaJWTD6fIFOHZY8JvtltXCXuVY6Guf13fnyWoozRaW8sTljHkEGe8/508tiaxzj84cMJ6ZF/oR7BPx82AfCg0OIx6++oUvNN0wG//RfvXX3Kdi5LgGwLbdf+M99GxeBu7mX1QfpjVyNQ4pwnya7SAVy8UwLwy0bxNsv2/j5tyBmKUgAYvrDNflLXq75M4yPZS7BmS69qFHZNPgl9mczpSp+hoy+iWrdxqeKlgIn2qMTtSY8CM6JPNrQvRESGgzXz1cyYUYolHLT555UlDh8o0kZAZTGmzhIhu4YsM8a7RyKuoc/8IR2rTWpvDqv1QmSxpZGDk5cs+6ZDlUBgHpx8PErmRf0cVJZCPmS5KYuwqpHGJzuGTY8FN5OCrq65pPuLAftMOCN1ZavNgatyBKHqrakkz+g9GQBGz6wiz7Tngycs6E7uK1bTUnxSuDfw8VbuCeYcDyKJMZkbOF8gviPLU/g8ltxNkgOaz84wIf5FgL4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-02-59Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698825652.3896625,
                        "modified": 1698825652.3896625,
                        "name": "/dev/shm/incoming/2023-11-01T07:50:51.ais",
                        "sha256": "0d10cb60e68c33fd184e418bed2fff8abd741449dbcfd6e3b7cc9ef87aa1dffe",
                        "size": 677756
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "c8532c282a94e6511d6201d4964c666ad2787cd217c6b1f54bbe8c040faa6e2a",
                "size": 216097
            },
            "errors": "",
            "filename": "2023-11-01T08-02-59Z",
            "size": 216097,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698825871,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "bwn0iKe8gj8kqjoXdHq8IaQRjLhZ+n4YpgZWGuBaA7h6SJhstfYederrRfXsQiGDpMzDgqzyfB4zyxDFpCRRji+fikMN7H5oYrwBnWdKijas3L+ccYQvjtdk/ug9Z/AKfSKFffUrbSVViAHX2ikd5UYrIgUgihzswMlg0d1oTFyxwXRp/fS/LP/lQ/U2UPwWu9BTWbyruQz2CGS/Pgvs/Uq+TVcWBCfQJyCLA6X+lPGr3qMvq8gseT7oa3yyUw+HZs+Queen7oOT50S92eFtHVL76ic8uaGlrqJegoK1AxwDPGfyXDaquN9hQPY1fLZ8/NEgxo1TvjlwzMzFWBJhqKRINxGIFmzczOy28+zix1YnVjEfWpUvVEgr0gE4gJk7nWrCvIG9mrQWcjj3jgtQAc/21EBhvo1xOLZrJzwtVVz1kpmzGfxIN4WPd7oC+Kgwh8FoWVAE2d7po05XvOdFwR4oJJhkWlTf9L0l7YaPbt0Au5fyw5jra2gHK1/XUgQ8CvN03NYNr4dQYBFf8uqP/1F3VwDRg7Tv3d7Cv9n98vaI5/7O8P9R6mYRzgcGZDEncNk4omH5EAal0VOfLVsdkJoFREGSJMVF+sMruWRVCD8Wvkri80DQBxNsiRzeV4QrMfKZS8oTZ4fnLpJTcECx10Jc+UoKMnO9BsZErZDDyE4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-04-22Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698825740.2082224,
                        "modified": 1698825740.2082224,
                        "name": "/dev/shm/incoming/2023_11_01_080202_00_00_V_018.txt",
                        "sha256": "5e3897b85927e5fdc5f00813a6f38949749633defa2e566029da6a8343ee0a76",
                        "size": 2034
                    },
                    {
                        "created": 1698825746.1782224,
                        "modified": 1698825746.1782224,
                        "name": "/dev/shm/incoming/2023_11_01_080202_00_00_V_018.kml",
                        "sha256": "4c1129976deea0947911aca8220464ebda03133f5e47dd6692b7f6f32b73d75a",
                        "size": 978
                    },
                    {
                        "created": 1698825735.8982222,
                        "modified": 1698825735.8982222,
                        "name": "/dev/shm/incoming/2023_11_01_080202_00_00_V_018.jpg",
                        "sha256": "e4dadb885c3560fa00a0b30b848108c7fb9bce5087143c7f5f547dcaf6635b25",
                        "size": 917244
                    },
                    {
                        "created": 1698825722.3282223,
                        "modified": 1698825722.3282223,
                        "name": "/dev/shm/incoming/2023-11-01T07:52:00.ais",
                        "sha256": "11a29dff12d72672b6ec64c2f1353873150cfa63b4ce9d70168ed59df93278b4",
                        "size": 51958
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "2810d1a8c670eb0268bc5c474ba96bb2803da14576d6d76106aaa0c157a4d48c",
                "size": 930516
            },
            "errors": "",
            "filename": "2023-11-01T08-04-22Z",
            "size": 930516,
            "verified_at": 1698845545
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698826455,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "peqZvg44irgi2APs1iNxWJIu01kOi8ub/cxYQcamw8xzMgX4ZCMDLyBxrO6LR1Ocs5TDupYd67G1ubMN0wXFLCxDtWDVqClzaHyYDXCJtkPJ9grDZHPHh+9Fd4hYtTxjX2yAen0lMWTq6yc7374tTj9bzTZFM4iuUmK9ssPPodQkQ1s641/O59gUAbcdvY9IHV/9TG+reE9Ws8+ajhTPwX4TRtYHVCX0oVpm90f6pz4Sgc0iAwcn9QGvNycyHaTKUcGWHX6Rgl3RcK7gXk9HvhpSOlRxlLIUSASowbN07HkRkwkeWHtJ2qF3i6ZEMLpWB1Kxn16QEdAsAsBW+xlNVRvZTP5lABKjehWLexcRjanviXL6u6UNZ4xLekbRRh4Pb5EivtlTur982LQLqNwJg3hALc45rzdUoctyzGPkIuii+iEk4lqdAc6tXUxNGmJXPbYeTNoE7cDDm+47qFUbRy+l2gkZypkq5grDGl5f4jKzMWTPMwxQKxH57PE5r/rX/lx8Ru/w0ybJFOqKDC6glTyluKqVDilMs9mn2olMpmXto7snp1ZIfdpvnRVgE1f/+TEQhXMXuw49Y41W9UK+qbvfpjxyL5zL6wuuR8cktPih+rItjcuYVOlv9L9Y8n01H84okrP2R9ZOvWGvJ8+iv9Au8yT/Tt9vHq88OupDVU8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-14-08Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698826323.1682222,
                        "modified": 1698826323.1682222,
                        "name": "/dev/shm/incoming/2023-11-01T08:02:02.ais",
                        "sha256": "d0ab35ee86bc825d56b5800a6f3a801d17d40026469bc6a1cec5788e897e7317",
                        "size": 46960
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "f099d7a181a850751bc46c322a3930ea7380f016f25b34cc1f55f8b9cb37e3af",
                "size": 13731
            },
            "errors": "",
            "filename": "2023-11-01T08-14-08Z",
            "size": 13731,
            "verified_at": 1698845615
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698825723,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "h7duvP44CrcFgy0EuCzyZlVhKBhAANQnJPpU8lTefjKGqPD4gb7xRwbVpD8D5buoX5ClSUi5FaR0MUnaWHsytqmaUjOpgNFUA55PvmYnS6chQ3AoPj2MRVPguBmheiFoLvGixZOFJnBrZjNlCYczB/WQCi/kUflnkSR6ctxJDlcTkzB949fupMRAMaXaX9rQ0HJjMmRfYPKAOKiBhb9xgBYkLuRT6/+Tx92u2j89LCuq+t2S1Ghwc2Yd8imQwazZ5Bc0bG4LP4IOI3z9V0Q7WSVWAimi0LKx2yr9U9ssO7Hv/KwLYkZcoFAUSYu1ljG9vwJ6rYgzSLr5TxP/dsPRHb5qhy/Wra0WB3LlOnlZoHIV1HJfXAVyqp6L7NCdnYlP2NOF+QdoTSunNUQ4J8mYi/vb6gD6/Ko6NCPa5tmairGiKBubPlyz9ymvda6yNktI5RM5h9XYjxeQ5GPYHgNxzGTLi3QqxxijYnaRsv7oEJmpkljnu7/GhefRRYZdDodxhIh0W7uMMnNA82CzF7vE24+SFDrhBxHGqqU08KWv+PY7lxGsqDoS8a6Iu2sPS5SYxl3Vqa35/yfYvZSp3/ZJYW/AUd4rZIsyaDaO1ltloPb6o/jO5LIIE0SG+PwD9uVwtyUV3P+7JYmCyXqN1VtuV6R99y3UFC5Aj4smLyWB1EU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-01-10Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698825660.8582222,
                        "modified": 1698825660.8582222,
                        "name": "/dev/shm/incoming/2023-11-01T07:31:00.ts",
                        "sha256": "a6a1764eec9656d5c97665ad7359f072b60116405dc53a08d089e27fdb9c681e",
                        "size": 410801808
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "a37e5147e809f44a60b527f7020e967d41526edc25c1de7825006945e81ee4d8",
                "size": 396257820
            },
            "errors": "",
            "filename": "2023-11-01T08-01-10Z",
            "size": 396257820,
            "verified_at": 1698846126
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698826537,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "XIm/w3KKEp5sJPt8m92pR9+ulDyUzsPNc1Rs49+AeTTUCbvRXJ4ZmrC+tW86MAcUY4thN7u9zV4gSOXFB7dpy7wrl1e5qv1i8KfFtr+vwBdOQURfwnyZ7qFZItVPz7nRku9JfA/P4/5tEvcLj21YzNpHakCii5A+TsTm1LmFhDVqTH/oq5RfvIhk78Kk/j3P9TLUCdC5aEjAVWEEFVBGJnF54gSRdD1DH2M0JmVVkPkzyJbbRFQZUt1Ouos5pLgQyto1yLXl0u7x/fCcCjTRY/19x+qqNB8bwosQpo9fs1xTpxl7Hqi5rUdJvoA/UAf3mzz21cEMxdrGVeZeozhMww1o9dIcmpKWybK7MIm9LIqQmPkfcoePxaLgvWYbPQ4tPYAqORKshS7ewBEpJYG6ArUjxhD4ygdYzpjzKY34jmH3DPCDni1d9V6ALGQyhiMFhJKBoQjf8wdmJdpEsivQMxlpmfmFziS0oGOEcFtKlJp60ayNurdlt1a4/2FZNRVRxAobF+vFP3M89gGtXxoaPH+0XQnPHpuIeiurhqRlpKN+RzbL8igFwDjVTRMuFZIMaKTegPYKp3XSybmxOArGWA8eRvkMkHCCP1dmRh0wOjcbCWNfG8bJAmsc+6lsIUyAuEF4iLMBazRccuf9UVTP8ocgubcSlzYKGbyAqm7aW7A=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-12-57Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698826253.7096627,
                        "modified": 1698826253.7096627,
                        "name": "/dev/shm/incoming/2023-11-01T08:00:52.ais",
                        "sha256": "69a70583747ca52d53f1ab52a450d81bb00fb7bb58504f2eb381aa786ec2230a",
                        "size": 700483
                    },
                    {
                        "created": 1698826367.7696626,
                        "modified": 1698826367.7696626,
                        "name": "/dev/shm/incoming/2023-11-01T07:42:47.ts",
                        "sha256": "00d0ffd2548f8010519552ec734f06b13bc28049f7df0382076636cad4ffc457",
                        "size": 442309480
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "f657b5be02edb4e2eb046ea36db4ab6a3d0bf0e13378f8400594f1fe58b43b73",
                "size": 397328852
            },
            "errors": "",
            "filename": "2023-11-01T08-12-57Z",
            "size": 397328852,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698827013,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "X/LhMWk9cLKXZ4A3iqyzCcaihYbbvNL2ZOX1j7zi97lZbJdM1C37ftOSG8SRiNa0hPydrCExN3szMccFw0g3uO57n1+vm5V3xqscd2aYSI/PEwX5y1xMWDE+qO0CVh7JOGw5vrECs7MBMgpg5aYLSoHW05E289omcj9Kuw+fi4F3tPmfP92OcLUg79zdq2T9vH9tIifgmr1+jwjPKadnDlHWe/gwT8tuW5ZJR2pnIwL1nPVWRwnAA0ZGt5FsBCvZf7WzVedfB+Bq4YGLW7hJX4imV2mCx+2rplz3MpY3+g8wbxyOAKsNmJ1mILGLaKcuB5knS5gecSMdkLXFi5DRCGAubEIvKZWsuzzs6DKjzd77+wcEb4YCMaOBmaS/MUWjkn8wgvFo5ZpkUrwPWwsLODPcCqJWdXeV5CzdmR5IeiV9ABShtYShUOIAcTOmvCaiPD/ZGiQVNVPmB32xZ4xnx5P8u7d5SYbYcNNpBXJYfLujDRfqTW2P1cfUzMs7i3m/8Suv6qx7SmvpeW9HojHIsArFxI/1E+Xci0UeM3Jiyl6qL6Wu0weyKBE7JA05xSurb+vy+8w0OSOKohKSyh0wb9SK/7YpewrmxkK9C9+wNvHPziVO7oqoPNyAsq/hsSs8snN8d5D63AqnTuvgsXUaGrNjx3cz3mjftoopyCMxzMo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-22-58Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698826854.4696627,
                        "modified": 1698826854.4696627,
                        "name": "/dev/shm/incoming/2023-11-01T08:10:53.ais",
                        "sha256": "35e8eaf14449d7c0b028a690165cc0a95ff239eab2016a1b0d126bff733d63ba",
                        "size": 802639
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "6a3af52d278d8e4d2ab33d7ec2f6cf33789048d1f3d0f3db4b101bca78ae321b",
                "size": 250040
            },
            "errors": "",
            "filename": "2023-11-01T08-22-58Z",
            "size": 250040,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698826569,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "lwxy0zxKx6hLPPijrqaJxvlq52An0MjIN6zwcZ4/bX7gAtXQs9MFEC6A+U0Tf0oi6XSHazUe7BNAW5Rayu10rMiPBcLoOaU0VqsRX+LdqzU0AUTP9MZQttVPW+zdjFsPaqIu3LJkTXpKRyuXFlNNs9JEqRubhoIymLLu2YyK3Sq501DQ1tkN57jJhiYMQDzehmewMiKFXSZCtVLeNZNIW7f7jzyZJs7CiQZGuV5yntvKp8QjysmLAnORQBbBL0rzA/Dc3wmAmfH3MljcMMLst5AXepgxkd+nnUMoFXfaQuVHLl2PurLuvY9ciE1YszRd0+fDA1fCA96gKJR/jyMOt3zDyI/L2/m6rAvzeZorgyiT48IaszgpD1ZM3pg6oIJEwzVuYVlA8+zTHtAV+7VpT1ODhKWsfHNQwx0Nz9ebpzwKR3O+tgRcLyMZzstP4ekFS/ls4JM3zJ1YSALdRL5OxPFY9r+KBTNfQlmhVKfmniJBWA8wEkvET5LMNEGDae0QlRkLJfh8D1yO6pHWISzwAc4QYVG1pwM5E59FAv3RPa8VAlcKNxQeIZQw+R+gKd+W7KxgzszGcBNNP0lwBjOnGK2iiWU/TPF03dmBwaoTAO/QVrTj0WoPY6su/J86594tdBGEm6J40sFgnaUdCYpqP3EDaK3NDngJXXo6qqdh8H8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-15-45Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698826422.0985453,
                        "modified": 1698826422.0985453,
                        "name": "/dev/shm/incoming/2023-11-01T08:03:41.ais",
                        "sha256": "8f924b63d0bb746ab823c028c0d808b30146fff64735f53537ad0bfb9e1cc36d",
                        "size": 674718
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "8fe40e6d376bf2577e55c3529c8637b228b4c7c8afbd75dabfedf22a197beb32",
                "size": 210289
            },
            "errors": "",
            "filename": "2023-11-01T08-15-45Z",
            "size": 210289,
            "verified_at": 1698847769
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698827600,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "EatRNno1h9++khllFA55I4xQgA+oK9SaIFFaBWzQf4NUZ3C5GKabYY/6Nr3sHX7X1+9JZb4C+4bxckYK/55/HTkPrjEHVFYHBsmuotIx093oHZlEC2ccsziAb+V6kuBD3EGNLkkJR2wgtGbroGX1rsP2zu5wt1YprDvude6SroOorOuJDfO9cfRArHGkkr5kXvhkdhWPCBOVjVR0t6if2i/P5osH5xpkJu5/DfI2eCIwcqpLjlC08UIYgKnUSFRX+2sMtdtPM5Co4fzmXuzJ5qgJ36tFwAUZs5EP78So9oVaZrvqeGDaAWkJ175qd8xOyltL1hmsZ0NFzcy+oRHgvRkKuxzHqxiaUh0+SgB9Wbs6ndmZLXCW2pS/27QC9I4am9wqBA7FP9pAIReTE3vuBoHhU8CcZ1IJgfrVOUkJyyXy/WXL99ShR5amCfHhyYwyBj7XiNqRS1I0DK4Uq2zE/9eejbI0ra30dzVrr/LWcX4pb4hT9nJB3wJyTo4FyiNBGv9tStOoqR807pMtrGegwN4KaXwWzNwkWXxJE+bejrDw+umgh9Qf8/ps6at2s5q/vFY/q16/iOMf07Oz/8L1XBNWALdsD3Z2KkV26VFFzfE+vdpWybnB8k+n+0SfGWAdAUF+sz8b6BSkuvHquBl2mWIT7mcSZoh4Gn8Qg+PbK0o=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-33-02Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698827455.9296627,
                        "modified": 1698827455.9296627,
                        "name": "/dev/shm/incoming/2023-11-01T08:20:54.ais",
                        "sha256": "74109b2552c0098649f722e59739595b6481677d5bd357386e00fb94128afed2",
                        "size": 659489
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "1393997d4397a948817b8247ce16fe312b7f1076253133a43b8549f1b0e404d6",
                "size": 206742
            },
            "errors": "",
            "filename": "2023-11-01T08-33-02Z",
            "size": 206742,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698827531,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "mXNgt6vImFlO9dhElen07RgKMdnx0dSUgTZBUjcRGEiS2GKrMdzy7eGdqfSk1GHlqdERh1qR6u5H5tLgKeXr4C5Fsn0H2PS6poMznfzdcJtHvXWCpFM/DIu08ck0KliPUGdT4lZqlhaJ7hejIkTOYsVl7wR9nLh6idI3FuHKc7vkEg9zUHCa1SiwHO2FUfmQ5kK2mIixf9pwMwdozZeG/emqZ1wSl1tGHFzU011U3NO8xGhJC9ZZpxEsEMRl5Gr0EgVu+0FrjN7M10Itj+jtRvX2T0npAgILo5zJwLnRgTWCLPRbpwepyTJVCbktBlxJkkq+Iiyx3HW8z51YQUIvnIC2YZcNphavhheVcIXvVxZV+BfigZzMUZ8tQkgHfHjM7OjKKZy0ZsnyvUOliEit3O4NvSm8ZP0gKlc4SvzwYg4/izK3vHWvtzI43NXqNu4yjaYSiFBMaFksxkD3XOIEkqLaY2lkfXVSlyTgc4ALZI0GJG8H5+juhBHKULmG2ARvGoGPSKf7FBccVCFE1XUvRm1PkHpei8IP4oiZQa8BRdkV8RSCYLv2EhQF+oduZSCjBrjATJohPDaq5cuN6zODE2B6R2Nd4RRtJQ2FW5pfFOuRgrII6ILaWTmeaLCUE/dGKB8ApsaeWC3ZQOzUMTDACGqn0B7ksyCe7zZCr0RTfj0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-31-16Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698827467.4382224,
                        "modified": 1698827467.4382224,
                        "name": "/dev/shm/incoming/2023-11-01T08:01:07.ts",
                        "sha256": "2835450a40cf631bcf4eb7e84c450b078b49214fbc368bede18ba938b5de5a73",
                        "size": 410879828
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "886bf2b02fb668810a55fcfc44bdc11a99ad0afbb8f75b9b2a679b443e4bd015",
                "size": 396353174
            },
            "errors": "",
            "filename": "2023-11-01T08-31-16Z",
            "size": 396353174,
            "verified_at": 1698846214
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698827770,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "OO/jvTLscjbWFfkBPcydviIilqx6USDA1m90ZFQm//UiSNtskmC6V1HkpE0I4LVnG+XYiVXHIZONJTa6UiKnrgdU/iAHyiGSnYvx8EY6akg58rbx8oIaaq1YW2XtIUYg3n570u4bItTPSMvYcb/N1OVZyVMosFsklHie0O+oy4QduuoEJQjpQXXnwmlxe+iiodlgtQgB+uLBBCOb/C8bLDuLKnl7A4VnAfYpLwRHBw+7SO7X7IivAh4yyVMh8Cl3YbJQ0ZunTwIzVbWu4SkZWIcq9hi031SRqIJRN3OzsXqILy7QEDDwU0hsr3Uul3CaVts2bwTm5nszOtG0gErNO7Teg4hfMwZYc0V1MzfYoWLX8NZ2oQAeuuf5r+uwbdv0bdE+V9Sp+/Knp/swyA1aM2ER8vNJU+ATG4g2pqSG1PMrr1Y+6UkNydYa1V6Z4n8YD9KII+Vo5+aeZXcedyCk7P80F7zwaqkfU00pjhhdWssk/g6e1vZHFPBTwCmmos/VnmVJFMxkoDxjRtmgt3Y7h8YDPUCXdSn/N/90BTudH1IC9R7x5qV53DNEtr2/niobDzfp7PlFBnvP4DMkMTcHMOO8NDpDME4AhwOnR3dRJijYx6SuhiCigbt/IzeCIBRExMC+vfhiO8JCQ1snn4keB9nRbE8anCjwK7/KOTJeJp4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-35-48Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698827624.9185455,
                        "modified": 1698827624.9185455,
                        "name": "/dev/shm/incoming/2023-11-01T08:23:43.ais",
                        "sha256": "a2575cbf3693a552818026bc956de06480e52011b791657c3ef922afad957038",
                        "size": 712623
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "98043e246a61df05e0d15ad9369f810f90bd39aa99b68caaaaa38b3c0af24f1f",
                "size": 223203
            },
            "errors": "",
            "filename": "2023-11-01T08-35-48Z",
            "size": 223203,
            "verified_at": 1698848270
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698828246,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "LLlfVqJZO4ENNbosuwyKBv/cMn4W+M1e/Y72SN0ywQ0PDOtkGJfQLJVvlyzqZ2gmM8lOgRvgzuZw/W/QO+wXL8gUjeaX9UfkiZQYw5ETLN3ntxEMdry5/Anr4XxsAJQqm+wEi9cjVLTwThHEQkD0IbtSlnY504dcH4A06oHP1hel+mtoUhYQHutgv9AvkCr9+Gx3vuMv5REPICTshWHJGVrDEPhPrkE4L7GpPGshPW+R3oGs/6GvJxyCNjBqsvyWvI/JYAUSGvp7sjeZufzOMa5+6r6GRCNsDBYoJbCFn2VFxcW8wAmsx6QarIsNJbLLbr0MC2fXgeqAlewELwydvEKDEbulttXFZNucRilCq/F10wNmhRAF8RiKyBaMtb6i/oVbZY+Rvl6JnLHeUknaX9wjjYQXiCbf9rbiniF+GppA6nvkZmdvswjrH9UFfBLu/OSW0N9eIPL7V6sy+UBNT3Yiih51t9tpulFuDCxM3c4LSNbuVqBNIlVye36JihEzhx/V4fYBI3prwo06ILIUYpPMWb0Rny6bRsn3O/jMFr96n04TJUbeoOgkNGjuSV6WtIhPz7GCpmtwT25kZf+5g/iK/VoKqUG5UQkUnmfS7BC559LosCtj1VfV9pcbvW48R9BNQTW5eUDkpP51Wy2r4vRVEAVwyw8PWhjglmGMjeU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-43-00Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698828056.2396626,
                        "modified": 1698828056.2396626,
                        "name": "/dev/shm/incoming/2023-11-01T08:30:55.ais",
                        "sha256": "87eb2cb051742f30faa2de65bc590c65fbe23b8004be44ea5bd0a595756fc367",
                        "size": 639809
                    },
                    {
                        "created": 1698828174.3196626,
                        "modified": 1698828174.3196626,
                        "name": "/dev/shm/incoming/2023-11-01T08:12:53.ts",
                        "sha256": "6fe444b2df7cc8d5e517a58c20d791ba93081caebcc24e381a51bb254930365a",
                        "size": 436130484
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "307ed02f11d7a003f5948224ad8cab3ce776e86945534ce6ec902f866340e795",
                "size": 396152187
            },
            "errors": "",
            "filename": "2023-11-01T08-43-00Z",
            "size": 396152187,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698828096,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Pj0g+ilyNhF/gyfHHpBimSW+2AV6xW6gtC9A1u3g5ozPo581Pslj8PQZYLpa7zqgnf7ahXFTfXMXEm3yDDlt01tXZ4D1sbHpaNocGGUyIfIczdixBNoL5LGFK23MEOI6E9RI5jZwXPgrYthabCoG6FCcxHa44mnh7FYkMCLHK3EKzFvzDmKs3Sv8zOZQI1B4/nILo5K3QtkyfIBNQTEWyklMRjJZ4/C4+enyQSdxXkPY41kZX2Zv7z6vbKoPCUcbgRdrwKMkzLpsWZoNORe1ElM3nNBmiyZtUqGCV5FaqAN/rHQI9w70mhwbmmO7UXY168uuzOCiL8f1NQJ72cQTgUL0j6tgwVWVBkat3zM2BuU3kNywAhxMcJ3ez5iWMN7ffrgr15M4lv+jn+GpgV+1zhfJegabisCDUA5inW1pTGO6fUNajNzhOd6Gt1HlGJj4jYkj+f7h0Pxec+WPczu5kZlKnkaqyL1wAt7QZ4p12F0b1qWCesu4IQLzCzCBEYoD5M8vuSbC1y06qGKgCjlWS9UbuaGY0kvfES8GkD4QhtvHFMpe0lm3/jkSqbRm9VgYPPjW68NOhCL56Rb+MUX1cJqeHCbC/z6dLhqZQpRS5LDNyBLN8JMfmoGw/2G8bTR586ipIo9a8qPOeeYOa6ouGn58qW4cGQZsM99zf2IVsd8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-41-30Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698827968.2882223,
                        "modified": 1698827968.2882223,
                        "name": "/dev/shm/incoming/2023_11_01_083906_00_00_V_020.txt",
                        "sha256": "be6d2fd986779a0f399d8839f372eafb17f256ce3811a97ac2e1da96e256d549",
                        "size": 2036
                    },
                    {
                        "created": 1698827974.7482224,
                        "modified": 1698827974.7482224,
                        "name": "/dev/shm/incoming/2023_11_01_083906_00_00_V_020.kml",
                        "sha256": "0c8ea1b5c38f437846489c50ac00b4fac8f29a8a2871705a24b35062a9bb3a70",
                        "size": 976
                    },
                    {
                        "created": 1698827975.0282223,
                        "modified": 1698827975.0282223,
                        "name": "/dev/shm/incoming/2023_11_01_083906_00_00_V_020.jpg",
                        "sha256": "71c8f01914e5d83f1ee94771dcb1739a85bf76eb5fa7cca268949e9a6b2fe3e6",
                        "size": 1124525
                    },
                    {
                        "created": 1698827939.8282223,
                        "modified": 1698827939.8282223,
                        "name": "/dev/shm/incoming/2023_11_01_083835_00_00_V_019.txt",
                        "sha256": "34fa612aaa924bde45ca79b9adfba1801d809a2db9b80a6bea8cf83b22690670",
                        "size": 2029
                    },
                    {
                        "created": 1698827958.3282223,
                        "modified": 1698827958.3282223,
                        "name": "/dev/shm/incoming/2023_11_01_083835_00_00_V_019.kml",
                        "sha256": "ddc2122b35685d77ff7c39765b5e0915158e22525ed4c1b7bf9464498239af7c",
                        "size": 975
                    },
                    {
                        "created": 1698827931.2982223,
                        "modified": 1698827931.2982223,
                        "name": "/dev/shm/incoming/2023_11_01_083835_00_00_V_019.jpg",
                        "sha256": "751a5f494bab7e709791148e3397ff60ba4350f944719b18386a617038dea11f",
                        "size": 1270493
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "7c9491212a8ad5550954661d127261ad7b41f18b3380d4073bdf4065b1c58ecd",
                "size": 2398490
            },
            "errors": "",
            "filename": "2023-11-01T08-41-30Z",
            "size": 2398490,
            "verified_at": 1698845838
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698828630,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "DkjD9flqHec0IfmH9O5V8joNpQdC3Rhvk43t6HQisbmlsGO9aoDq0qDh0PEFs7THf/kpjcaR5YFKJ7bWziTYVKlLXH3uLoZpKNEfmtmbym0BNS3jOMSgQIwJk9w+gJn5iPaf1TJAi2UhfyLVQiOk0AKryMr2Ttyfh6S+mPvkeqMCs9EUgzC2BHtQgcot9uGCvcaYwCSq1IpPGBqbcZ2D2djR51U97IfdXSsngDVMq6zNWjeH6SpBw8X7VJUTMqFKQIeJWzYHZCUbO8ST0b0KcTv3ecwdbnKSm2iFw98pBNltvv6RF+z8ETj84eDp1AwuldKLNSj2ekhKxUZmitj+x2fSI6Eb06X4dnxGrPP+NK4QVCNCopHg/cYY1NwoqBC5w6eVwuui6RV1tSFyizLbvz5C3s9fLtHXo1Ry/hR6fK4YbHxRMgUcOoLwkurwyvj02rlscxnuvh+KSI2nbO//MzEqP3ddwCT3qU2n4x9m6Bxk5+md/oHW4JfCrDHQLByv3l930B6vgHrm+c1PUFmNUUBbMinJRqRktwqEPR5mqzCr3le7V13XGl7ttQ6aaTqqjRT9ORqXwUXKOtRZoTraHCUtN2wAOCFE6oBV4kgR+4nFqIBfRrQW0eemGBNM+U/c2VgFXIC2BThUc9w27SBRWfa37ZmZmpXFQZwucF1Pqbk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-48-53Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698828532.0985453,
                        "modified": 1698828532.0985453,
                        "name": "/dev/shm/incoming/2023_11_01_084835_00_00_V_029.txt",
                        "sha256": "df571925d4839bc03b0992596289cfb92e969e44a57a2cbcaa1097fdc5e401e1",
                        "size": 2033
                    },
                    {
                        "created": 1698828532.2985454,
                        "modified": 1698828532.2985454,
                        "name": "/dev/shm/incoming/2023_11_01_084835_00_00_V_029.kml",
                        "sha256": "75cb496b69b37a47b67f9682c5e5aacfa69bc93ea82d0c4acc839c9b8841748c",
                        "size": 976
                    },
                    {
                        "created": 1698828527.7985454,
                        "modified": 1698828527.7985454,
                        "name": "/dev/shm/incoming/2023_11_01_084835_00_00_V_029.jpg",
                        "sha256": "f89507f8be7ed54f7ad9af04accfcf5ad45abd8b1fe9405280da31a9b66fe05e",
                        "size": 891557
                    },
                    {
                        "created": 1698828414.5785453,
                        "modified": 1698828414.5785453,
                        "name": "/dev/shm/incoming/2023_11_01_084627_00_00_V_028.txt",
                        "sha256": "fd94381a912d6d96624ae46915e0ab5830fff3b89e3a5853c50a5e6e5f801df6",
                        "size": 2027
                    },
                    {
                        "created": 1698828414.3785453,
                        "modified": 1698828414.3785453,
                        "name": "/dev/shm/incoming/2023_11_01_084627_00_00_V_028.kml",
                        "sha256": "c34344ac21bbfa2887c5956e6663572a0dc871df0653beba444b4f309916dcf1",
                        "size": 977
                    },
                    {
                        "created": 1698828416.2385454,
                        "modified": 1698828416.2385454,
                        "name": "/dev/shm/incoming/2023_11_01_084627_00_00_V_028.jpg",
                        "sha256": "b37a55e3d7e3afcc1f1dfcbf1223fe61efe26652338f267aa01fcd699a170059",
                        "size": 1745225
                    },
                    {
                        "created": 1698828417.9585454,
                        "modified": 1698828417.9585454,
                        "name": "/dev/shm/incoming/2023_11_01_084512_00_00_V_027.txt",
                        "sha256": "618f95313f14dee4eb8d9380a65884bae7f704f0f19317334c5cf9c25d14bb7c",
                        "size": 2034
                    },
                    {
                        "created": 1698828329.8685453,
                        "modified": 1698828329.8685453,
                        "name": "/dev/shm/incoming/2023_11_01_084512_00_00_V_027.kml",
                        "sha256": "bac775e97bff6f6bcf388cdda9e769661fba41a49ae4ca8f8a710c82e4182a63",
                        "size": 977
                    },
                    {
                        "created": 1698828340.0085454,
                        "modified": 1698828340.0085454,
                        "name": "/dev/shm/incoming/2023_11_01_084512_00_00_V_027.jpg",
                        "sha256": "4ae99f6b7f3d1adc66bfe1ee37f098ca4c248d4010266e86fb9f581daed0bb10",
                        "size": 1835249
                    },
                    {
                        "created": 1698828225.2785454,
                        "modified": 1698828225.2785454,
                        "name": "/dev/shm/incoming/2023-11-01T08:33:44.ais",
                        "sha256": "4db7fe7cabf30bf36354748c435fc709292f8128505b6308aecdf67a1ddf2e2d",
                        "size": 627395
                    },
                    {
                        "created": 1698828527.5285454,
                        "modified": 1698828527.5285454,
                        "name": "/dev/shm/incoming/2023-11-01T08:18:47.ts",
                        "sha256": "78731f630360c11f3a3abf0b02bc4a0927236b9447ba701679828abd49f7fb48",
                        "size": 426303160
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "f08cbb73d3a5e3367342e2e022ea08461be40a24621e1c64f877101fdb217cff",
                "size": 404700625
            },
            "errors": "",
            "filename": "2023-11-01T08-48-53Z",
            "size": 404700625,
            "verified_at": 1698849200
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698828803,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "BB+gELnwtw+hdZSV3T0/E84K5iJYU/HFunyOk/njmztUpOi/MLcG2afQA4gQsFzzbydXn3ToLisshr9oC3V+Wm9F7B+hf9V72XLsVgAGB5SCRqndcnQuynVGaDQA2EOmcGcZxmlIJP1J3Zt2CRvwPjkTjD3Mw5Dpl3rY35lOzuBPFoj+7csjq/PtUPTSnq4YLYNhz+Jg1orb7ZcUoS/VrEgQ4YfWwYP8Cvo5uG9nDY0eCk/vtGisqWkkKUk5Rl9W+5QEiXGcPdti6WPYLPDTHYLSljGRQfvdqlwDbdXGwe1yEM0+BT64aorwfUOmqGH3rrNZ9MtkQ8c/JrHKsaQOqwU/cRLD27EfNTvWti6XEecWkBnJSVUzF+YvB9+bWld/LvB+PIEIx2CUvYLCfxvP/RP/1EUeGQJA0a9p7hTI9FdIz//fVimd7s+LEZvZow8GaCV0TrMADIhzFylHHkSnubTCK87cnzjgXTnp3+2QynJDAYOn9KnGKLDiluJ9ZdcsnKHiF/9DLT7dUdAdtoiwo5CY1qHjZvJbzz80VbDUFk7R4P6gi8wwF0BGrdoa875IAUXrqYrzdk8RWJiWWOtnCjyUFNLSuVoLnrQSiEowvREpVn9EpzvOJv9iYY9fdOnzkyl+XKSwu/Qh8JL2+82lnnI/6xmvAygO3rOHdnaJVpY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-53-03Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698828658.2196627,
                        "modified": 1698828658.2196627,
                        "name": "/dev/shm/incoming/2023-11-01T08:40:56.ais",
                        "sha256": "d397ea08c1471658c656c7c47c4a240f9fe6a773f6b7229a27b3784cc6c9df4f",
                        "size": 541382
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "82a645f9c5f38998a57382be0a8cf485080c3579b9d619d5cfbc188fa4b7309d",
                "size": 167652
            },
            "errors": "",
            "filename": "2023-11-01T08-53-03Z",
            "size": 167652,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698828939,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "lNwfyqUnWLMqc5XRnVvM4zutBAEr+5JCIV+eS3c6f7hjJcWI5uTU9fbIbRnQvUmlaCZLJKGEvOlJ/3WnY9KD2mNxzdZy1OBo9+ZJxi8BgkEgiIIiD41yCLPbUcIzDUShZ/Q7uBMxyO42JPTRBVF4yV5GGiUFuIgCXR0jpGE/xf51OKG/dvVREVw0TjcvavBVc2IaNJDsQo+jx++HFPF/5dXFaPTHo+7kjrjl19bKm9j7BqlSHcE2EfTG2N2yq3K+zN6eY0qXodjpQPmvnXX7dryBv025HFAmKFO8nuTIJ0gq+R12KoPnmNKiaCWE/TTZUrp44hNxpz7e+jlDyKniS5173J+qPTkNcX+hEmbumi9KjAYB7wHSDaxxqRq0frVD3O58ST10X4bMl0s2CXDpY0hzYx3CPJ7QpO3I1pjyHgkwNrSdONu+y3EdeDt8ey/YapioBIF33qvIjE7kpjUzfi7xZvNnALlrLVzK+RzhL25FIBQoDO0inrbckdfmYq9Oa1izRYyCBq4+Bj9e7SAh4tHapHWa3rM+y5+qtCmLFO1O4YRS0jIJq6w72rvAI42mpAfihPoz8ngw2R/ZDsFx0ZztNFgsOJ75JGmZkBQWfYUzqC9rPqtZDfz5mW9rgetIjDcA1UKlUjR/AVIUYW5IXf5YQbIG6yT6hEk3v2n9fhE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-54-43Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698828758.1725543,
                        "modified": 1698828758.1725543,
                        "name": "/dev/shm/incoming/2023_11_01_085217_00_00_V_003.txt",
                        "sha256": "4d0b2b9da57ae8f26b5bdd7af1cf31d6977d9c8f60c8de7260d59802ae575c43",
                        "size": 2063
                    },
                    {
                        "created": 1698828756.7025542,
                        "modified": 1698828756.7025542,
                        "name": "/dev/shm/incoming/2023_11_01_085217_00_00_V_003.kml",
                        "sha256": "794654b9af42923c907328eefdcab139eb354ab0bda34c356611f6e4f147d835",
                        "size": 980
                    },
                    {
                        "created": 1698828756.0825543,
                        "modified": 1698828756.0825543,
                        "name": "/dev/shm/incoming/2023_11_01_085217_00_00_V_003.jpg",
                        "sha256": "604e7f0a148abcb5560dcf052b2b16ad699e1914b720c16fbf6e4ef6a588ccd2",
                        "size": 907459
                    },
                    {
                        "created": 1698828755.2525544,
                        "modified": 1698828755.2525544,
                        "name": "/dev/shm/incoming/2023_11_01_085216_00_00_V_002.txt",
                        "sha256": "ca84237c007f8615eeb10fffa4a6c0e76bbef422eda7a3924119b149678d7c50",
                        "size": 2062
                    },
                    {
                        "created": 1698828755.0325544,
                        "modified": 1698828755.0325544,
                        "name": "/dev/shm/incoming/2023_11_01_085216_00_00_V_002.kml",
                        "sha256": "b926480002ee3e687898b60189626f210dc1c49a3bd1a7cea100f48d77d99007",
                        "size": 978
                    },
                    {
                        "created": 1698828756.7925544,
                        "modified": 1698828756.7925544,
                        "name": "/dev/shm/incoming/2023_11_01_085216_00_00_V_002.jpg",
                        "sha256": "d4b3cf3039eb8faafd9c54caa4f6df07114439f0a9037c95c0f21024db68ee92",
                        "size": 1023500
                    },
                    {
                        "created": 1698828754.2125542,
                        "modified": 1698828754.2125542,
                        "name": "/dev/shm/incoming/2023_11_01_085215_00_00_V_001.txt",
                        "sha256": "2e43060b55c8b52a3565a749f31b0615afd55a2562c00082257580189a54f707",
                        "size": 2068
                    },
                    {
                        "created": 1698828753.9925542,
                        "modified": 1698828753.9925542,
                        "name": "/dev/shm/incoming/2023_11_01_085215_00_00_V_001.kml",
                        "sha256": "7c8592e9b465c409328bcaaa1e24aff4696c632fc2cea941c1cae761eecb2526",
                        "size": 980
                    },
                    {
                        "created": 1698828753.1825542,
                        "modified": 1698828753.1825542,
                        "name": "/dev/shm/incoming/2023_11_01_085215_00_00_V_001.jpg",
                        "sha256": "a2446fb9bfd50ec5d26786975efa398471935c7e0b462760b7de4fce8fd9320c",
                        "size": 696936
                    }
                ],
                "mission": "EFCA1",
                "sha256": "b6c1b34d8b0b1dcf004eb86c67eb8807767a9e43d0f919e11c96e09371bd34de",
                "size": 2610487
            },
            "errors": "",
            "filename": "2023-11-01T08-54-43Z",
            "size": 2610487,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698828865,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "sVxcZQkw0oJ7lGaJIhsPUYjuwxlTmWJ9NYqFbts/BWV1BYHXz2hXCF2Yk39M3O5WFHSP9lwMue8CipC93BX634B+YadFvucoKz9kVjEQuK73O6i+fsifr9jm21nbgsmBZwYWwP1gZ22MSxM4TfhgGwQZ1wOkFa2QNQzNgqGnGWoWET5oS2Y/x3gpHBCNiPWr2Ub9bJ/6/ItbDcFZKwv5WcwayQxIvh8CV/ugMJe+qYLBQKBjrr3bn+oVi99ut6vcLzua9Gsk7lKsZTM9Xz+XtBdaMKm4fN5B4wK7VBbEhmAaW7vhCl6GSLpUxUsslNkZOKOjRG65nVtE3JyoYBxHCuIh+aTSzVpYMYnv6omVOm5MYtl5vjvZtou4lx8cEt7Gs6S6twvkXAj5H5myodhBJnsKKIg/hvt7ZdjSiKkF89mbYlSFs0/vuPBIwtgaFnFC7KVx8PevJ3m4tRDbZxqNmfUVEVT+YdW33Xna9h0qqXxYK2oVbmp3DTe2aQJiOrOCwemw1lVuObXsk1Yu5NdLF0JmAiVU5kGFVJ5FRhK8uNWBBW71PZF3Lbqk14SoQ/nzsdlqnG7vOSROIxnPrekedTfj80MRuJ4rmWdbAJtZky3TkhUtRHZnWQlFsn/AfDXY09VYcs+xoZSl7OGbxJVtAaU7mFiwol8AZAOZiitjNzw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-54-17Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698828731.3482223,
                        "modified": 1698828731.3482223,
                        "name": "/dev/shm/incoming/2023-11-01T08:42:10.ais",
                        "sha256": "7acbe83d18ae5c932624dbcee41ae5d42ef74c3ae2fcf64a99dcc2115350aa58",
                        "size": 32485
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "cd1ad2da530f789e3af28650a7f2108c720889b5a7eccc72568b3afedbcd9590",
                "size": 8777
            },
            "errors": "",
            "filename": "2023-11-01T08-54-17Z",
            "size": 8777,
            "verified_at": 1698846000
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698829337,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "RpgO+uvnSQ1jq21rmMRplpFWZcssRhfD2tj8yCBzLt9eDZjLXZ3xE67KGs20oROT98fEozWhHuz/hvX11mopyEZ7v2oQ7oanUAOuzoiyhex8aenCXq0Lw9VC/zOsorpoMxSxUraOQxcEhcJ02ICEnprw7QNMIdVIbEY5RfKauPg8ez5Oz1nSy9i3PSH0PxdPUUZFqApEvHyDfBZf7BidxRSyUj2sr1vFjEr8TBkXE9xnHWftLB4nYABPwIEvsUX/L8jnPN26wNVOcIT2XWWgRCYUaTLUzHH4eeHmbd9U+48CBNStqhsI3EPw5hLA12QFmOoB4sTARKhy9q+WvNBCWSZf2x2Ak8l8bfWlKHA2XqlA3pw7/klA7qCLJNuHV4dNTOPfp5Nh2/GMDQ4NtNKOCPOJw2ElXYFFC0hOLUM5iffq3Zq3e3PG1mezzq3kgBeYFfQEXOifnCXxpLl7TrfFfX2pXWiSHghmJeF05uIf+BFHkfzi8zitq6S1VNaNO66vWiMviOTz1RHGYVnune0d85lqu0033d84BxLUHMZyrLsOiLY3bI7MlnJ/pC5oJxs1r7jFpu4ZOZjZPEEyxZ0SgAXxqCQBDQGrm+KhioD6OrXD3tTSfVqIEpnf65ouxh/pwEwFdRgWZIJdgtshCep1Twn4ES6H8SwSuQjKxhLpFtQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-01-21Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698829274.0982223,
                        "modified": 1698829274.0982223,
                        "name": "/dev/shm/incoming/2023-11-01T08:31:13.ts",
                        "sha256": "01764fa575afd74f1979298a169b7f536748a018c308e1c49077a4c065ec17e7",
                        "size": 410582224
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "5ba1522f42e1836e079bccac68a8b373be38f8b09d10779b266e400a355e0e79",
                "size": 395940499
            },
            "errors": "",
            "filename": "2023-11-01T09-01-21Z",
            "size": 395940499,
            "verified_at": 1698846560
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698829287,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "Hpov76v7m8LaR4buL5FKvpuGbXDCrLcT4N3uswdaQMG0E+rWM23opJq7m9iFz1kL/i43EfpBPJGCbHOImBhoDjKFy11lrzhsJdL97Qm5ywp4FmYecbgKurzL8b9c12QhhnBy3jKkDZq3SpNW/Ckyg9Y3KaeVML7W6w50qa+psUkq9tUxs1mBgM7fCxqwhXiKrA+uHg6uoqLSHuiplikBkkw711/ae3EV4eBHYMEhPPAF3czaGnuTUEdoq5PgS8ZlmdANyk13llOLiyKpk2TXR3mzn1FtrWxZDAbrHjFmsxDKX676WOCPm7DtdTinhjqRK6/q4uBOHYuZDMY0tPpiGneXDljjmPtLbORBNcqsB3ZF7F8Q62isvh1Hk+WI7NdbtUl7Hz11WAusWuPWLme3U1wPZpRa1sSbH0B4qqkKb4j6WtcjeABjyMq7pZBZO4lL6P+euoXiPKctzA+02wlcXw0pJexUeWUbj6qE8f0Ila/Wi1WIDKOm7Rz0qdLMsP4PnAEb3rJ7f1YkipQ0UYWyjWnZWWDwtL1MNns8yeY+JyDYJjWlFGRRM/6QfytcQYVTYWeThl4cuAd+OqdgxB/dTqNDEO8/iT0bM9qtc9dDNTG8Q+/jyFsTcVW26yq8nOgENcaop9V4vvzLawpRHrl/ryQ2Rb3QIKZxslg102apWVU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-01-17Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698829086.0725543,
                        "modified": 1698829086.0725543,
                        "name": "/dev/shm/incoming/2023-11-01T08:47:12.ais",
                        "sha256": "6b3ae1d6bf678a85bd0a5f27229e35026fa7c5bb7c3dfce616734c1f7cb45e2d",
                        "size": 5532
                    },
                    {
                        "created": 1698829147.9325542,
                        "modified": 1698829147.9325542,
                        "name": "/dev/shm/incoming/2023-11-01T08:54:42.ts",
                        "sha256": "6c7d652eb9264fab561a886931c9d10a028dde154c6a1f584bcc299c9ce7df60",
                        "size": 60350820
                    }
                ],
                "mission": "EFCA1",
                "sha256": "9435f3767de0cdf9be5c037ff9cc3a3c9e8c16f612f35fd1ed6d3c22399d5d21",
                "size": 57984216
            },
            "errors": "",
            "filename": "2023-11-01T09-01-17Z",
            "size": 57984216,
            "verified_at": 1698848160
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698829344,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "no6fIDqpTFSdYkJdP3ikKYFBUcC9J6kjY2cwyVHBBmX7ukxbWSqma1ODh5N4IazeEyjI3MvBIjVQNhBI/JdMkvsjE5YtfIxAkjiOzSeWwBAzVu5HDw/hQwU3vA/b2HZ1vZ/uWcNj2XhCq3lCcR78AHWm7Zj9/PURPmhW/LzuXkn9D2NMYSRJU/h9fGn4PKxMEDswPHpL1KN4lCJpAgnjnwJotQrSqttnusDmZPH32EHqF2omr13LY+BjNS9bRAMJC9AX4upr264GSS0DLK7SQxQQ6U53kYRnJZYVdC0xAwl2OMB/RR373FnWyYPuo9P4vNAJO7+dQiH/PuDI6cHzfhw+tm7huNbKaO+xwNcY7BlP+oTk5t5vVxQrQMcdah5GI++b/2Sl3JGNR4yikgmuk1lW/O6woQNTyhJWhX1Cb+NOltaZhKp/jWnsf2CYhMx1gDL0n1hDTj5OQN8AyAQrtKgxg1gAh4O+vwWO0+PerbRt3DOtznb4GOMaXLCNAnf22GKCqXkdhq6L9gdl8932Yp+FL6NfZeDfOizNGG6bB45WpcQGljqr8DmkHrGMxPOtqHSxZftFyqanZOgrxj2Y0Bzsg6iXICg28ZVLaTN/v4vlbG1jipIhTNMKxj0ZtyY6CqPrQ0VnRxW0Woge9GW53/cnb+1DTl6++rXlPULsy/g=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-01-59Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698829198.7985454,
                        "modified": 1698829198.7985454,
                        "name": "/dev/shm/incoming/2023_11_01_085936_00_00_V_048.txt",
                        "sha256": "48dcaaa2e4cb06772aa68bc8bbb08b06db8a2726f9dd4a9029f8bfbfe288e16c",
                        "size": 2030
                    },
                    {
                        "created": 1698829196.5185454,
                        "modified": 1698829196.5185454,
                        "name": "/dev/shm/incoming/2023_11_01_085936_00_00_V_048.kml",
                        "sha256": "8a376e2ac5192c75e1922ee80f47b360474a254692b0224e37724f18200c7717",
                        "size": 975
                    },
                    {
                        "created": 1698829198.5385454,
                        "modified": 1698829198.5385454,
                        "name": "/dev/shm/incoming/2023_11_01_085936_00_00_V_048.jpg",
                        "sha256": "4c4578d3c6fce9df4243b280f825bebf358ace1916a6eb29e9a961fbb83a43b2",
                        "size": 735732
                    },
                    {
                        "created": 1698829176.2085454,
                        "modified": 1698829176.2085454,
                        "name": "/dev/shm/incoming/2023_11_01_085908_00_00_V_047.txt",
                        "sha256": "4d93d592910c57163a8595121f706ea935867a827b4477cb59616c06ade63869",
                        "size": 2034
                    },
                    {
                        "created": 1698829178.1585455,
                        "modified": 1698829178.1585455,
                        "name": "/dev/shm/incoming/2023_11_01_085908_00_00_V_047.kml",
                        "sha256": "c6d22ced131f2945b32d7fd5ee4cb24eb5d2722e34ef8511d12a5dbf67b85bce",
                        "size": 977
                    },
                    {
                        "created": 1698829168.7885454,
                        "modified": 1698829168.7885454,
                        "name": "/dev/shm/incoming/2023_11_01_085908_00_00_V_047.jpg",
                        "sha256": "78761f3c0762cdb6d7cdf5786e2210d2d623c798b7644a4a6ae98e5b81006d5a",
                        "size": 644113
                    },
                    {
                        "created": 1698829166.5385454,
                        "modified": 1698829166.5385454,
                        "name": "/dev/shm/incoming/2023_11_01_085853_00_00_V_046.txt",
                        "sha256": "3b271b8e0431ee431ce1a448b25e1d1bdb4d4cefdedad5de23b4ad31f594edb4",
                        "size": 2035
                    },
                    {
                        "created": 1698829168.4185455,
                        "modified": 1698829168.4185455,
                        "name": "/dev/shm/incoming/2023_11_01_085853_00_00_V_046.kml",
                        "sha256": "3e648f56b4776f5290b8303699772f06b194208d431da6fe0a66fb0f3e638818",
                        "size": 977
                    },
                    {
                        "created": 1698829159.5485454,
                        "modified": 1698829159.5485454,
                        "name": "/dev/shm/incoming/2023_11_01_085853_00_00_V_046.jpg",
                        "sha256": "68b3ba5143bf401ec0c7153985a693281ca382d7a65b44916277dfff83dc7eae",
                        "size": 811261
                    },
                    {
                        "created": 1698829143.9285455,
                        "modified": 1698829143.9285455,
                        "name": "/dev/shm/incoming/2023_11_01_085849_00_00_V_045.txt",
                        "sha256": "1db1814cb3c5dd507709b2fb12802f93c03fa7bb201c1e823b69094a78594ead",
                        "size": 2035
                    },
                    {
                        "created": 1698829143.4785454,
                        "modified": 1698829143.4785454,
                        "name": "/dev/shm/incoming/2023_11_01_085849_00_00_V_045.kml",
                        "sha256": "ef98b6a16ec95bb015420a6cd4521dbbe050af6c97876be3fff3b93a839c7e50",
                        "size": 977
                    },
                    {
                        "created": 1698829149.9385455,
                        "modified": 1698829149.9385455,
                        "name": "/dev/shm/incoming/2023_11_01_085849_00_00_V_045.jpg",
                        "sha256": "020d3d93db5f1e02b9c015cc9eb1ed462b9b1b38b57d4a4f27ac6ec99969eeaa",
                        "size": 939377
                    },
                    {
                        "created": 1698829094.0585454,
                        "modified": 1698829094.0585454,
                        "name": "/dev/shm/incoming/2023_11_01_085727_00_00_V_044.txt",
                        "sha256": "d6fcc3ecdc84a144a30407a74ac67c1b6099e7e98d6f0d7e7cbc817adee7ff0e",
                        "size": 2032
                    },
                    {
                        "created": 1698829077.6085453,
                        "modified": 1698829077.6085453,
                        "name": "/dev/shm/incoming/2023_11_01_085727_00_00_V_044.kml",
                        "sha256": "621edec59c1073a4a835c2d8682cc1aa9778742cb7fb41e5d1797d182b1f23ee",
                        "size": 977
                    },
                    {
                        "created": 1698829074.1185453,
                        "modified": 1698829074.1185453,
                        "name": "/dev/shm/incoming/2023_11_01_085727_00_00_V_044.jpg",
                        "sha256": "0396bacd7f200372f17aac964b57ce2ff8a73c68e5133141539fa2a2a564a06a",
                        "size": 743161
                    },
                    {
                        "created": 1698829062.2285454,
                        "modified": 1698829062.2285454,
                        "name": "/dev/shm/incoming/2023_11_01_085707_00_00_V_043.txt",
                        "sha256": "9c717c5e1310d2c78209e04eadfe6d9b51ef47b178f263598fc06a61eb20a18e",
                        "size": 2032
                    },
                    {
                        "created": 1698829045.3685453,
                        "modified": 1698829045.3685453,
                        "name": "/dev/shm/incoming/2023_11_01_085707_00_00_V_043.kml",
                        "sha256": "82ebbe1b69b927a70b788abdef3f1fc1381a3f94f72a92c657cf63bf11b72b91",
                        "size": 976
                    },
                    {
                        "created": 1698829052.9685454,
                        "modified": 1698829052.9685454,
                        "name": "/dev/shm/incoming/2023_11_01_085707_00_00_V_043.jpg",
                        "sha256": "dc9290d4319b6c430a8e47305dbaa45fe7005993f6194dd7811d0eaf9de6c2d8",
                        "size": 729142
                    },
                    {
                        "created": 1698828997.8085454,
                        "modified": 1698828997.8085454,
                        "name": "/dev/shm/incoming/2023_11_01_085608_00_00_V_042.txt",
                        "sha256": "24a5423651e2dd1cb071a3296e498e1ad91c8d6d13e19f04d699509d4fec9f09",
                        "size": 2032
                    },
                    {
                        "created": 1698828997.5085454,
                        "modified": 1698828997.5085454,
                        "name": "/dev/shm/incoming/2023_11_01_085608_00_00_V_042.kml",
                        "sha256": "9c764f07fb1557b13bb2ad8c108e1141611a916f2a1d54d5a6b688a58692074d",
                        "size": 977
                    },
                    {
                        "created": 1698828994.8385453,
                        "modified": 1698828994.8385453,
                        "name": "/dev/shm/incoming/2023_11_01_085608_00_00_V_042.jpg",
                        "sha256": "eba042b93f0c27f4c5699f19d8da192cc090790b5b606732586d0c57c365bd2e",
                        "size": 1040083
                    },
                    {
                        "created": 1698828985.9985454,
                        "modified": 1698828985.9985454,
                        "name": "/dev/shm/incoming/2023_11_01_085558_00_00_V_041.txt",
                        "sha256": "31f22d9428a255c6d6aa75f325bfeff0d701b3ee48f9233d72a04e82f9dcad92",
                        "size": 2032
                    },
                    {
                        "created": 1698828986.6685455,
                        "modified": 1698828986.6685455,
                        "name": "/dev/shm/incoming/2023_11_01_085558_00_00_V_041.kml",
                        "sha256": "93b7f2182a2541103b08fa40ef23dc6de73452fd274f0e0dee571f71fa7ccac0",
                        "size": 975
                    },
                    {
                        "created": 1698828985.3385453,
                        "modified": 1698828985.3385453,
                        "name": "/dev/shm/incoming/2023_11_01_085558_00_00_V_041.jpg",
                        "sha256": "89918d66e79974be0b2fb244768b3ed0a6f8c139659cda28e63b3e15ac4e3128",
                        "size": 855246
                    },
                    {
                        "created": 1698828974.4985454,
                        "modified": 1698828974.4985454,
                        "name": "/dev/shm/incoming/2023_11_01_085550_00_00_V_040.txt",
                        "sha256": "d0def5462170255ac0bd647a45908cb779db963d1fd5b6b5a562f250f78b5d71",
                        "size": 2030
                    },
                    {
                        "created": 1698828972.8885455,
                        "modified": 1698828972.8885455,
                        "name": "/dev/shm/incoming/2023_11_01_085550_00_00_V_040.kml",
                        "sha256": "34dd92725fa5af527fdaf58fe6d8e53aae7a14cb0c50ff3584141e5ea78113c6",
                        "size": 974
                    },
                    {
                        "created": 1698828972.7585454,
                        "modified": 1698828972.7585454,
                        "name": "/dev/shm/incoming/2023_11_01_085550_00_00_V_040.jpg",
                        "sha256": "ec60d7a7b465acf2acb91698e78cc21316b39643093cd314c48133dbef1b0d4e",
                        "size": 1015399
                    },
                    {
                        "created": 1698828971.8285453,
                        "modified": 1698828971.8285453,
                        "name": "/dev/shm/incoming/2023_11_01_085544_00_00_V_039.txt",
                        "sha256": "4b49494db7a796cbe7bedfbfa0539074d7460edf79685e61a383fb6561f32dee",
                        "size": 2031
                    },
                    {
                        "created": 1698828971.6385455,
                        "modified": 1698828971.6385455,
                        "name": "/dev/shm/incoming/2023_11_01_085544_00_00_V_039.kml",
                        "sha256": "f85696a62c8218b1cd88f430235667bd06d7e87b78057a6b0bd66e79859fee96",
                        "size": 975
                    },
                    {
                        "created": 1698828971.0185454,
                        "modified": 1698828971.0185454,
                        "name": "/dev/shm/incoming/2023_11_01_085544_00_00_V_039.jpg",
                        "sha256": "9e65dbcbf195cc1c6c2ef0f60a2354717fe9d336ce1d48d08f20999cde8eca66",
                        "size": 895750
                    },
                    {
                        "created": 1698828961.3785453,
                        "modified": 1698828961.3785453,
                        "name": "/dev/shm/incoming/2023_11_01_085539_00_00_V_038.txt",
                        "sha256": "38ad9146ac9cf32c9abe650c70bea85e02134dd85937a3d567bbfad1c3a255eb",
                        "size": 2036
                    },
                    {
                        "created": 1698828970.7285454,
                        "modified": 1698828970.7285454,
                        "name": "/dev/shm/incoming/2023_11_01_085539_00_00_V_038.kml",
                        "sha256": "d3725c7f5246ee6ae6d4c9ba0e287d356d265535fa537b0425ec8e142bac0b9a",
                        "size": 978
                    },
                    {
                        "created": 1698828968.5185454,
                        "modified": 1698828968.5185454,
                        "name": "/dev/shm/incoming/2023_11_01_085539_00_00_V_038.jpg",
                        "sha256": "47938e29db82d6f11b8deea0adb24921855827ee3083fb8c88979292924b625f",
                        "size": 818889
                    },
                    {
                        "created": 1698828947.1885455,
                        "modified": 1698828947.1885455,
                        "name": "/dev/shm/incoming/2023_11_01_085521_00_00_V_037.txt",
                        "sha256": "83a9b7d996c241ebdbb84c11b98d6ca90454ddc187543c95920a378eb281b50a",
                        "size": 2032
                    },
                    {
                        "created": 1698828954.4085455,
                        "modified": 1698828954.4085455,
                        "name": "/dev/shm/incoming/2023_11_01_085521_00_00_V_037.kml",
                        "sha256": "876d270abff01dbf58c6d590f4293a20c79624b7e4716cc0460c594502f36599",
                        "size": 978
                    },
                    {
                        "created": 1698828952.0485454,
                        "modified": 1698828952.0485454,
                        "name": "/dev/shm/incoming/2023_11_01_085521_00_00_V_037.jpg",
                        "sha256": "91041cafaaf5f194efbce70fe4d9c7cde502cc9075e1e9465bfa878f0d467262",
                        "size": 649857
                    },
                    {
                        "created": 1698828943.3685453,
                        "modified": 1698828943.3685453,
                        "name": "/dev/shm/incoming/2023_11_01_085516_00_00_V_036.txt",
                        "sha256": "92ffb29cb5e27e10694da2cf13e066334106487e79bf7d83a8fe5825f4090d05",
                        "size": 2032
                    },
                    {
                        "created": 1698828934.4385455,
                        "modified": 1698828934.4385455,
                        "name": "/dev/shm/incoming/2023_11_01_085516_00_00_V_036.kml",
                        "sha256": "3795df4f7dc0a0f88b9e5d21f273ad98e1cc8682ba57cd6cf84ee5a6ea232108",
                        "size": 977
                    },
                    {
                        "created": 1698828943.0885453,
                        "modified": 1698828943.0885453,
                        "name": "/dev/shm/incoming/2023_11_01_085516_00_00_V_036.jpg",
                        "sha256": "ed7c7c83f719661548b39d5bb84b2796dab38bbbeadd7a868dc8755247fd8041",
                        "size": 895139
                    },
                    {
                        "created": 1698828827.3185453,
                        "modified": 1698828827.3185453,
                        "name": "/dev/shm/incoming/2023-11-01T08:43:45.ais",
                        "sha256": "997c82b0f4b54ccd344141365dc7a1f2cb3d248d6297f298d41b056e7b19e549",
                        "size": 563216
                    },
                    {
                        "created": 1698828737.1885455,
                        "modified": 1698828737.1885455,
                        "name": "/dev/shm/incoming/2023_11_01_085154_00_00_V_035.txt",
                        "sha256": "f99f33e57c88d271c74ca5c1cc9fbbd7b286bd47652ac35b96f3a8c69ac6bf50",
                        "size": 2034
                    },
                    {
                        "created": 1698828728.3885455,
                        "modified": 1698828728.3885455,
                        "name": "/dev/shm/incoming/2023_11_01_085154_00_00_V_035.kml",
                        "sha256": "2c0cfecac34c77eb42de2912c399a9e6b05ffafe8b354347a6a873fe5da56dfa",
                        "size": 975
                    },
                    {
                        "created": 1698828738.5285454,
                        "modified": 1698828738.5285454,
                        "name": "/dev/shm/incoming/2023_11_01_085154_00_00_V_035.jpg",
                        "sha256": "b930086867e06aea45f5bda0ce8707a8a2dc9fb1c0355d5874f03c3b9982228f",
                        "size": 908358
                    },
                    {
                        "created": 1698828632.5685453,
                        "modified": 1698828632.5685453,
                        "name": "/dev/shm/incoming/2023_11_01_085007_00_00_V_034.txt",
                        "sha256": "8ac36a663d0a63c2114f0f6ac3fd295717df3e4e0601759fb64f4110e43f60d0",
                        "size": 2030
                    },
                    {
                        "created": 1698828630.2785454,
                        "modified": 1698828630.2785454,
                        "name": "/dev/shm/incoming/2023_11_01_085007_00_00_V_034.kml",
                        "sha256": "28b778f409c46ed9036744e2786a7068e8fc2c48c1e90a622626a597091fa734",
                        "size": 977
                    },
                    {
                        "created": 1698828631.9985454,
                        "modified": 1698828631.9985454,
                        "name": "/dev/shm/incoming/2023_11_01_085007_00_00_V_034.jpg",
                        "sha256": "0889a88aa2cf0de01093f2055c6960ffb441a07a8669ba50bb010a909b587628",
                        "size": 777361
                    },
                    {
                        "created": 1698828619.2685454,
                        "modified": 1698828619.2685454,
                        "name": "/dev/shm/incoming/2023_11_01_084950_00_00_V_033.txt",
                        "sha256": "94c9fa4c40ca1257749d636fdae5af418953cbfdd1c51ac92ae13918c0d43cdc",
                        "size": 2032
                    },
                    {
                        "created": 1698828621.8085454,
                        "modified": 1698828621.8085454,
                        "name": "/dev/shm/incoming/2023_11_01_084950_00_00_V_033.kml",
                        "sha256": "d0abd6f1fe130a1a08e0dfd9a16c1175976a15e79defa6d4de3d939bd1317c99",
                        "size": 974
                    },
                    {
                        "created": 1698828612.7385454,
                        "modified": 1698828612.7385454,
                        "name": "/dev/shm/incoming/2023_11_01_084950_00_00_V_033.jpg",
                        "sha256": "f76a4dd239a1e2b75e72aea5f87175a1eff0f114c6b4a2f5454858f266bf6db3",
                        "size": 783962
                    },
                    {
                        "created": 1698828597.9185455,
                        "modified": 1698828597.9185455,
                        "name": "/dev/shm/incoming/2023_11_01_084929_00_00_V_032.txt",
                        "sha256": "f0d0c04957f9df953bd082c0c44cca06685d9d1f1d3f7c65266003878a58919d",
                        "size": 2034
                    },
                    {
                        "created": 1698828592.3285453,
                        "modified": 1698828592.3285453,
                        "name": "/dev/shm/incoming/2023_11_01_084929_00_00_V_032.kml",
                        "sha256": "cd1b0b7554fffe4e0bcdf28d32dc04328f485432bfce3bb70f85ba9650a39a04",
                        "size": 977
                    },
                    {
                        "created": 1698828588.0785453,
                        "modified": 1698828588.0785453,
                        "name": "/dev/shm/incoming/2023_11_01_084929_00_00_V_032.jpg",
                        "sha256": "fb2c31db3827e4eb217dd0c79175ff36875bad654d9cc72156204d6051b78633",
                        "size": 785027
                    },
                    {
                        "created": 1698828586.0685453,
                        "modified": 1698828586.0685453,
                        "name": "/dev/shm/incoming/2023_11_01_084921_00_00_V_031.txt",
                        "sha256": "7d3dd5f7f21fd7d709f36a7b325a914e3c88f7da9c7e3ad8840cb7458d5e2aef",
                        "size": 2034
                    },
                    {
                        "created": 1698828584.7285454,
                        "modified": 1698828584.7285454,
                        "name": "/dev/shm/incoming/2023_11_01_084921_00_00_V_031.kml",
                        "sha256": "ff817d425599725b7f2bda982174a40341ec0fdc56b6c944d94d22a152166259",
                        "size": 974
                    },
                    {
                        "created": 1698828575.3685453,
                        "modified": 1698828575.3685453,
                        "name": "/dev/shm/incoming/2023_11_01_084921_00_00_V_031.jpg",
                        "sha256": "d425d0311d47af248b638742dc8a8ed05c50c736d301277dac31728c46a08f4e",
                        "size": 750176
                    },
                    {
                        "created": 1698828546.3085454,
                        "modified": 1698828546.3085454,
                        "name": "/dev/shm/incoming/2023_11_01_084838_00_00_V_030.txt",
                        "sha256": "2ab7faa25e98005dd7427df84ce3802d748a99bf9ba8d3beece220866cbeecf1",
                        "size": 2033
                    },
                    {
                        "created": 1698828545.0085454,
                        "modified": 1698828545.0085454,
                        "name": "/dev/shm/incoming/2023_11_01_084838_00_00_V_030.kml",
                        "sha256": "50dfbfd7fbe0f9cb18aeaeff90f373cd4ce48d79117088088b23e08cd9b43836",
                        "size": 975
                    },
                    {
                        "created": 1698828540.8585453,
                        "modified": 1698828540.8585453,
                        "name": "/dev/shm/incoming/2023_11_01_084838_00_00_V_030.jpg",
                        "sha256": "94c009a752d691adeec879c9e6ea4284e9a0c1ebe29912b8d00ab273e2b9595f",
                        "size": 661816
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "56966e279558e5fdc441c766e791b72fde47f3f488685fe03a7864bae2c84e6f",
                "size": 15540595
            },
            "errors": "",
            "filename": "2023-11-01T09-01-59Z",
            "size": 15540595,
            "verified_at": 1698848634
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698829471,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "R7hGtE7YhMYHGFo1wjoEUbdkszYonFhf+JbW1b2WV0cQCpU2SKDmDNWjkudPTl0/WRzXQjWXaoWtwb01ynOP+I+hwpkOFKmQn9WPLNx2jJXjdDLfGKz82cl0vHRfGwIulGCNVL90Z3Sr2q0/frgXpwmBaRF9VChrR4Z+g7J1Y1Z5U5OqG39b4Ol1Ca0oibb4dJXtGpXqmzbM/NdBhj7b/jYjjeoYTsLzIfxEeZZjl5cu1x4Suf4+G/JQV4AJjUE1zkidWICJ0PqfpBmbwxk38VhOYe27Af6dXWvAROpOJewpf9ZfQSrTgKkHrylATrppFltWe/qOXF0Ya82DNDl2qnGVncuJGHIBK0avkJ+t50OCcSI7DmGPTVD1AsXCc4SiXoI2NXIgqhR8Xm3eGNsHs6zZc+S4hNO+6JlQg2k72bETt1BhG8i451fqA1BtfOdUIbGG6rAsUv/q8xtAFV2BhxMDoRKIu/vZcQBAv4sKGSS2IeTUyzpc1dNrH5h2Lu3zBdf43UaucRTS2xDXUhCnIv/SYVCwAUEjE9oivbHY+4JZyhuY+b7XrqTBj5htTm/kOfaxykvoheKkGEZTBubh7FX/550IR8u9YAM5XAzZKu203pDYgM0GCRAP5oPlHXq1k/o+lDOuWc0eZLU5KbxHS54JS/ZGrU7x42CwmQLodPU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-03-07Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698829259.4596627,
                        "modified": 1698829259.4596627,
                        "name": "/dev/shm/incoming/2023-11-01T08:50:58.ais",
                        "sha256": "027e81d4da0bfaec2ba9ccdfe11dd367dc11c8caf64934fcb671306cc8c1cb85",
                        "size": 474927
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "bd5e9512fd1f2b477cd697918bfdc4ab4190e5a4cbbc86588c2494f5ae57ce4d",
                "size": 141560
            },
            "errors": "",
            "filename": "2023-11-01T09-03-07Z",
            "size": 141560,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698829571,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "hkZk9iFNKnK/wIEfI+hciS7pDnvKKH6+KDTX2TyuUQqw7D1ClGCMC8xMzjRRUI3fx9mao6nHUScoSiAfq+j2w0GJnLiIcTIHhdEHJBpUEnVXwDZiPVukIozId9kWSxsYxizFbmeXJC3Mni1P+c+KjLK+D9bXBpBKGe8KoZJ1zYfMVhHXC/+eQYG6G69s5OlFR+WMxEQ5f39uj02v82CNZT2gSjyKRr48iP1OXBe0RzpazPKX2wnTrhaBYfa9UyvHPeKlVxHsXxOEeGbe6l0W5Sp/4ZjSdJo4nFTQjwVDvBporEs/rW9vT91RVll6Wd0YYq2GPQMFVEfJMePcsrOl8YU9qm7yHYE6/Ia6aoE+a3a/PCeQQoj2cQA1KerBEmKakBXn7B4nA323pOvlScV8uGgZn+rtQcbRczrRdfvt8U0EJi5wUFIlWYxwmgFA8hEFQPJCJ7BfmUN7G1kRc5MkI9GN+bY8OUPcuz6icy98JrvgTC1Ku3Wpdxuacjgokazj6/JLh1nPeFe4vdSUhTneN3ZOVm9hjwSiJFo3VJKq17t+le3PbOlUzfiKzqaLjg+9LWcqiRY13GcwFTefVMark46UgXTwX4Iz3lnmltmvowdodztPb3dRjlsZMRC15HGdhSCT1XOccqvavXm+6rJmzeBPBUrZA3p/tlA7oe1bSXw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-06-02Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698829439.6082222,
                        "modified": 1698829439.6082222,
                        "name": "/dev/shm/incoming/2023_11_01_090333_00_00_V_022.txt",
                        "sha256": "babb4848fb7eafdea510d4f704891d049a99f0a1f670a69395c8042e1b375281",
                        "size": 2032
                    },
                    {
                        "created": 1698829439.2082224,
                        "modified": 1698829439.2082224,
                        "name": "/dev/shm/incoming/2023_11_01_090333_00_00_V_022.kml",
                        "sha256": "a41f5bb69a28f2e1e29e8ee3da2e02f00a9538ce637f18c23b1b3350d4ee6103",
                        "size": 977
                    },
                    {
                        "created": 1698829438.7282224,
                        "modified": 1698829438.7282224,
                        "name": "/dev/shm/incoming/2023_11_01_090333_00_00_V_022.jpg",
                        "sha256": "072b3078a23ec4345e9ca55229c8ac4732895021e65b0e467075d0f0426199f9",
                        "size": 968317
                    },
                    {
                        "created": 1698829332.0382223,
                        "modified": 1698829332.0382223,
                        "name": "/dev/shm/incoming/2023-11-01T08:52:11.ais",
                        "sha256": "9777b42c8f4dadb82e6c2fad4bf86ace35d14dde3b7df30976274100510d0894",
                        "size": 36030
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "56cc9dea7d16a534d6c2b61b906fd26d90b62c9a79f79d7dfd86a977b3751878",
                "size": 979530
            },
            "errors": "",
            "filename": "2023-11-01T09-06-02Z",
            "size": 979530,
            "verified_at": 1698846109
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698829933,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "tlX1WmjZFQ0xxO83Va4HOzHVvWhtisN0Mdl5D/cCrIBzc/fb+nZbzhC+jWdJO8VpKNUJTw7YFnj37GeloWtJ0NG55O09UNCPKZ8fIn5+ILbA3xbmhtHo1eli6QM3V+Oe95QsH6eFm+Otx4QYQllnkz5Q+KOVQdENNZeaknrOSN1RdJvUtL/o1ibaq4j51o+FdNyI8BYUICzHbZk/kUnvwcVaLsPYQMhanMU91uGdv7SNH59LA2pzbzykiMbln6dd9tsmhgfn6GZjJw1so810v0LQmN28VF8SHB3n+ebB67mTIc6fSqUfq7qDmaL2EvfsthkoknuGPajm5JcyL7eHMHIRkyXznMQD75e0O8Jy5s4NVAo05Xn1og0jFBgn3p/l+RA+f+av1J+z/NYfxVNuQx3bWj7F1RBm7IFa/1jfNWBnJd1sRNi9wA+O8VyG9NxeYhjpyeNC5jTvtq0yT17hsa25JzeHbS/WcIIbWgVaZ9HNvb2Q31jRTn/5K8dCrrBSiOd/O81OQayYMs6kD8DtR95GjqVuz4D+aFNDhCwvTkiN2+rxwAZnisVm/qh5lZPMhJ6wQbGbH4/tOW85BevWh4s5ow0+uobLgegFwXXdCkVpqJjW/RULPmT+xCrmDsu8A+CrUj8+2cz+GKB97VyJ0xfLTA9GmYV1R1sBb/2+6oo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-12-06Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698829802.2582223,
                        "modified": 1698829802.2582223,
                        "name": "/dev/shm/incoming/2023_11_01_090943_00_00_V_024.txt",
                        "sha256": "baaa58187be3a95e1748afc2628b0c47a3ab0f3241f761f3191e5b358d38ebf5",
                        "size": 2030
                    },
                    {
                        "created": 1698829804.9182222,
                        "modified": 1698829804.9182222,
                        "name": "/dev/shm/incoming/2023_11_01_090943_00_00_V_024.kml",
                        "sha256": "0562221a9d615b12f3a2ffa2989043b79dce1477c8d681690289e18b24e192dd",
                        "size": 976
                    },
                    {
                        "created": 1698829804.7682223,
                        "modified": 1698829804.7682223,
                        "name": "/dev/shm/incoming/2023_11_01_090943_00_00_V_024.jpg",
                        "sha256": "5bfcfca852bba1365f2bf5c6cd9e33ca37fbd31e12b44a4530fa94baf8276890",
                        "size": 1042385
                    },
                    {
                        "created": 1698829777.4182222,
                        "modified": 1698829777.4182222,
                        "name": "/dev/shm/incoming/2023_11_01_090922_00_00_V_023.txt",
                        "sha256": "c50ac6649bb531cb702c285a31dd4fba732ebcc180a3f806c44473caebcee38a",
                        "size": 2029
                    },
                    {
                        "created": 1698829777.0582223,
                        "modified": 1698829777.0582223,
                        "name": "/dev/shm/incoming/2023_11_01_090922_00_00_V_023.kml",
                        "sha256": "67789bac0f87c907c20ececd53c90126c0cd9f97dd23dbb5a6cea8e291c98905",
                        "size": 977
                    },
                    {
                        "created": 1698829776.3682222,
                        "modified": 1698829776.3682222,
                        "name": "/dev/shm/incoming/2023_11_01_090922_00_00_V_023.jpg",
                        "sha256": "d9a1a75e7dd29fcfeeae5e1a57454637c042070902b3e216e6b4b9069d2bbb63",
                        "size": 943603
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "45c294228ccce5d374cdf377173ab5f6ab91fb6bd1804cc4d8f2a7f02f8f12f3",
                "size": 1989376
            },
            "errors": "",
            "filename": "2023-11-01T09-12-06Z",
            "size": 1989376,
            "verified_at": 1698846161
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698829819,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "YPNae8v28DGD1TlzQuB4Ww6bXR0CYcGqEyMxKNXPhrjWH3mw11NE0FFneD7rOb8NkG2Phpx/Bv1rIyPFkslgfrtli/3EozUDZqOm/5ymy+6tpDQwTJGHKptrgxn2wFtbJ/45lJngVBK/GAsYjXviqggB8GAyicdOboOpFgsMe3ZhgMoqIaeo6eo7+zbidLvSmlCUjc5UrOXuFEk76Kkh7kvEIUmgSQew2SEAroNQzaIJnOW89RmaKpHClcBPxCf8+FcjrHluoJBiA1RjVNANyWyPksvhhX//q3AC+f5dY6hykN/hY6k0bMPbCy06UJ4Zeu2zKUlWyGQDJGDP2ZEovdpvJfW9B7yCi2vL2w2eAS1reU+qQ94iib7PpOedAWknQgVXF3Rd1WYeN2p7OH8P8txqSM39VUcatPgoO44QYAtG0b2ERzZrmoDkQiYTiGF93BLPgqXMUrC8NhuWWd5th4QIk1E+SqOPs5hGYy1mQoDyuIWyr2QpF4QCw8pMce0zJB2chS4GucYdvpSct3QGnKNDYI0jlCSG1GpOT+atrtEwII33gegOl9L2zQOsrK9LXK636rHWJZtE/zOm/o3+OwU0Pm9/1HRbezhJ1WxuUX9ajEW5haCjMFcXp9pC2ns0PAlJBRecCgg5tfFZfASIsIU5FJ46HeRLjzYUi5wdnsQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-10-15Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698829688.3625543,
                        "modified": 1698829688.3625543,
                        "name": "/dev/shm/incoming/2023-11-01T08:58:06.ais",
                        "sha256": "a2aeb80353f69e3b771a620da88cc947fc76e6ccbe10140912c004c038ff5749",
                        "size": 70295
                    }
                ],
                "mission": "EFCA1",
                "sha256": "b418219cfecf4c7b00de231a575f9fdabd0deaa7ed0cb892c3afe2d3cf1410ce",
                "size": 18085
            },
            "errors": "",
            "filename": "2023-11-01T09-10-15Z",
            "size": 18085,
            "verified_at": 1698848303
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698829575,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "PQRd8akOyVCIyUzxflpmogNuBB3PspCCnWQoIwN7X7b9U+RKx7D7D56f2BpSdYK+5i6eZ2gLBP1Mmw+9qpT/Ih9exHREFoQ3udSXhkG/55zVeBiOUaq+puniUibOvOdVGKkUa2h/swWibiZ5s42EtGIoTpjox2qiJH90HKSSP+rrS6VkMPViSqoS9tFuj+gM/bo6dFfLG4TeIqrdz0z3UOQSCdPF3iYeljWf6UaVMIg0Jg5zcZBctOxAHfdBgxlHgMJbBTkQ8OlQJhaL98gxKR0ETeZiV0p2wMcbdp3JE/IZLsFOQ/irvQFpfXNgmJ8QcaM13FXki/CqpP4gUIFJKggaHLFuaDo5CMxejWnjdFZeaXXt6mxzbCNwDgIunIOufNr6LV7xfF8c2cpgxRQAvsPIV6o0DouiIkUvVgzFD10w8e3yZyxun2ID40S/Q8Ux46EjsiUJbQamPGxpn8Ugl4rur8hCHEscwcUK8KeBPrn3zF62RP1+Zh0Vq9dbcMG18kBeS4l28ZpEaKUtBnTQ0CuQWh9sl0wR6mHpEZfIyYvY7HnlbkBlSxEadhQKdfsU7WSRbcISWHFoT/RwhT7P8+Lpox2yPiYjPI6xW1nMXJ7lSxNIIrHO67BXJ1qxQA4skuiHkcYkLzTjva8BeQHU/bH4HgJkT30MadC02WkBSiQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-05-53Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698829428.9385455,
                        "modified": 1698829428.9385455,
                        "name": "/dev/shm/incoming/2023-11-01T08:53:47.ais",
                        "sha256": "fe553931ff351f9df8baf01c4224a3defa0119c13da970f597dca6f7c9774208",
                        "size": 584855
                    },
                    {
                        "created": 1698829355.4985454,
                        "modified": 1698829355.4985454,
                        "name": "/dev/shm/incoming/2023_11_01_090206_00_00_V_049.txt",
                        "sha256": "ec0f0b79687103b053c5d6f5be694efc42e975b718c761b7ccc415d82c31119d",
                        "size": 2039
                    },
                    {
                        "created": 1698829355.3485453,
                        "modified": 1698829355.3485453,
                        "name": "/dev/shm/incoming/2023_11_01_090206_00_00_V_049.kml",
                        "sha256": "0581feddd779e7514de1a7459028b53dbcd32b9fe937bc63841997f7937bfe6d",
                        "size": 975
                    },
                    {
                        "created": 1698829346.5485454,
                        "modified": 1698829346.5485454,
                        "name": "/dev/shm/incoming/2023_11_01_090206_00_00_V_049.jpg",
                        "sha256": "364c26aa03cdadab9560fdf48f03e0ba16c20cf8c169d682ff70e9d046ea46d1",
                        "size": 948722
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "0bcf3dc621a33e09aab7deb82147cd513c5fcbca6a2bee010ec01a319fa9ac37",
                "size": 1124509
            },
            "errors": "",
            "filename": "2023-11-01T09-05-53Z",
            "size": 1124509,
            "verified_at": 1698848689
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698829798,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "DzEuIsr6HQ5oXkgzAFo8lsA+Wt8y0Pksr2STJFRBv8ewKecsIhKzFxdXLjvs65iz9r52QSCISfq3FjSBAugORYZbInOtSX9fZ9ktMs7yrt5TnfUMOBbVp+0edsmUCtOKaPpaP4CSDAp2jtKa/nNn0VtbFkDtIXPqQCBYQUgj3TY4ZWpDS3X/quIy5IWBnytBkxRexSjTvkZQJG4t1ACLi/GXlDhhlIEX/DQsBSitO4R7qV4IW0r1gvAhtoyg5bYlp30IwXcIZRzztauc+4ED0+z4FHEkgB4gzMorZtiEJ24dS7ANPFexZuH2246PKVvrexJTtUp67VBUUrS1yxrhAdOiMBRBALBQP4O0alHMji1H7oUgrJmegcbjDU+Yzz7xomOFvxRAeRRVw7c7wyjQRYrZwhBZbhHG4PfCk5V1sf8h9Ik/oSTgRyeI/CUXzxnyFpvjSwI7mmYiresSTRPS5jUzKpMxYr2gUyf/7AYfZ/gmlLi/a1Nlq97/DnkG05ujUZAir9Y/i28WFkP0vChUCKZH65lJFYGnHIg+wDW4h8wKvTG7DySmgDEifjj3vkICsb+oabS9VtWgUU6VGch8P0GLE8b3jZRdO6MEgfyhutUcUHzMCoITd4/Lq1X4iWDj5pE1mXJwQ/YP/KWgPpFq/Lqa6BsxKQUhVct9m6BY0c0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-09-33Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698829650.6585455,
                        "modified": 1698829650.6585455,
                        "name": "/dev/shm/incoming/2023_11_01_090709_00_00_V_051.txt",
                        "sha256": "210c937c43590c488321ca5111106a310ecddbfac473127b8972e1674933d8e1",
                        "size": 2032
                    },
                    {
                        "created": 1698829654.5285454,
                        "modified": 1698829654.5285454,
                        "name": "/dev/shm/incoming/2023_11_01_090709_00_00_V_051.kml",
                        "sha256": "866a75a6d97ff48556a3bea28e792aa128a8b44b9a9cc830896d3c1e18a3426c",
                        "size": 980
                    },
                    {
                        "created": 1698829650.0585454,
                        "modified": 1698829650.0585454,
                        "name": "/dev/shm/incoming/2023_11_01_090709_00_00_V_051.jpg",
                        "sha256": "16e755fce8e515724dfb38f03bbeaa7ef4a2fcaf622d24af654596192df95650",
                        "size": 931005
                    },
                    {
                        "created": 1698829631.9585454,
                        "modified": 1698829631.9585454,
                        "name": "/dev/shm/incoming/2023_11_01_090643_00_00_V_050.txt",
                        "sha256": "c9b1497b77e2352921cfe71b46ac92dce71b8ba742e57453eaed05999e5b22a0",
                        "size": 2033
                    },
                    {
                        "created": 1698829621.2585454,
                        "modified": 1698829621.2585454,
                        "name": "/dev/shm/incoming/2023_11_01_090643_00_00_V_050.kml",
                        "sha256": "ace597f8f79bcb1969f98cd2ecba6c7fa8806d6e927e36406a60ed55491e2142",
                        "size": 975
                    },
                    {
                        "created": 1698829621.0985453,
                        "modified": 1698829621.0985453,
                        "name": "/dev/shm/incoming/2023_11_01_090643_00_00_V_050.jpg",
                        "sha256": "ad1bc47a66c0d1fef702bdd91e5ab0a508d41a1d3ff32df997c8974d8019f9dd",
                        "size": 889752
                    },
                    {
                        "created": 1698829620.1985455,
                        "modified": 1698829620.1985455,
                        "name": "/dev/shm/incoming/2023_11_01_090206_00_00_V_049.txt",
                        "sha256": "ec0f0b79687103b053c5d6f5be694efc42e975b718c761b7ccc415d82c31119d",
                        "size": 2039
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "ae783876d4673395c7b815826bf120331db58bdd3d1fe0849f7368b1346b5bd1",
                "size": 1818916
            },
            "errors": "",
            "filename": "2023-11-01T09-09-33Z",
            "size": 1818916,
            "verified_at": 1698848719
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698830095,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "JHtxyDbJHoeHq6Cg5V4VAMcrq+F7a7Xi9iJ0x38wRrzZzuavPdzEPhBqUS4csVQB4bhp/mT75tXybN0qd02z40KYWhRJZp7EkE5OlZ5PW8BK/RJsMWcZF5jXcFOLdj6BpaLwdiJme7pR90DVjbO/RhTRbcx+DFPLaDO5tVmWPrPdEQiC2hIP5Yy1KYd04E2U5y4kl4lOLGcfJ8US/hV3frO4/jH+8UeNZpOV4C5UF6jqKFgh3YrP4/NghLdZsr42qmLn4V3RBTrg8SqknfgXNO34KhVW5YPTlYUxjhGo9CX8pDWgeprYfCfl7O8U372a80uNRs5yDD/wqVM5+/Q3R+gRUB5SZ9boqFLlhJ1Pan2+GY33/TSzhNQRjdPwjY1Vi6mvlO5lbTgLiYSFSEH/RMO7+Ljrt2dL/Ac4ljHhpkqGwFDAfh2Za3p0gsMm9bL/4MlqldqbS7XP5mVZ19ACbunUnFcTG580XHnXkV4zxcpyDd3J/nl0+/2GCAfRrI1qmz5ngZBdUKoW4wZt6tXYure9ra44ms/+IGtGlyRJM0QoR36qs2LaAyBhGW9sWtGUo/PcEXg5n9+7SHdSW45w8FPBWkNqvPTD11Vmn1L3bdN+/Rwh8qwNUlAQKTy6Qh2R81Aal0XKq7TiKMfn7BoRdAk6dQWjGztURSFrpBF140Q=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-13-23Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698829980.8496625,
                        "modified": 1698829980.8496625,
                        "name": "/dev/shm/incoming/2023_11_01_091219_00_00_K_018.txt",
                        "sha256": "e1b3deba94b493c31eab7755a204c16a4451f2b57fd782b2837b3b500cf0a65a",
                        "size": 2046
                    },
                    {
                        "created": 1698829967.6296625,
                        "modified": 1698829967.6296625,
                        "name": "/dev/shm/incoming/2023_11_01_091219_00_00_K_018.kml",
                        "sha256": "1f558940ae6618d2c11f7f98622e87c74e6a59bda22121263b38e4d60935cf4b",
                        "size": 978
                    },
                    {
                        "created": 1698829970.7496626,
                        "modified": 1698829970.7496626,
                        "name": "/dev/shm/incoming/2023_11_01_091219_00_00_K_018.jpg",
                        "sha256": "1dd88ab71c940dbf140b1bc6231824fc1c8b16dcb7d961a35843763899482e1e",
                        "size": 851696
                    },
                    {
                        "created": 1698829949.0296626,
                        "modified": 1698829949.0296626,
                        "name": "/dev/shm/incoming/2023_11_01_091209_00_00_K_017.txt",
                        "sha256": "a72af37205aa53e5f240da1fb07e0cc74c5a431ac877015c237a4f13fe5526a4",
                        "size": 2050
                    },
                    {
                        "created": 1698829950.4896626,
                        "modified": 1698829950.4896626,
                        "name": "/dev/shm/incoming/2023_11_01_091209_00_00_K_017.kml",
                        "sha256": "69c192b2e9475364c8eb3f73b0854c1346e0e03e698b422b1f82f58fe2465983",
                        "size": 977
                    },
                    {
                        "created": 1698829959.0496626,
                        "modified": 1698829959.0496626,
                        "name": "/dev/shm/incoming/2023_11_01_091209_00_00_K_017.jpg",
                        "sha256": "f917c96e9b82fa319729f9c16f156ce0d3ddf3cf5d1285961c0f32eb44a3a305",
                        "size": 1193889
                    },
                    {
                        "created": 1698829947.2996626,
                        "modified": 1698829947.2996626,
                        "name": "/dev/shm/incoming/2023_11_01_091206_00_00_K_016.txt",
                        "sha256": "19c9df9708946eafa6ee1fb0b6bfc8cd052f72b438a9ce480be39bcbebf211da",
                        "size": 2050
                    },
                    {
                        "created": 1698829958.9196627,
                        "modified": 1698829958.9196627,
                        "name": "/dev/shm/incoming/2023_11_01_091206_00_00_K_016.kml",
                        "sha256": "d9682bde27bafb7c25e9d3b4976ef3edb61b48e1b50acda3dcb05f56a77b32bf",
                        "size": 976
                    },
                    {
                        "created": 1698829947.1096625,
                        "modified": 1698829947.1096625,
                        "name": "/dev/shm/incoming/2023_11_01_091206_00_00_K_016.jpg",
                        "sha256": "d4140a0885a0a53e4d08388fcdc552af2b1385146e4b915ab59a9ad3c9e7eff4",
                        "size": 1046507
                    },
                    {
                        "created": 1698829946.2596626,
                        "modified": 1698829946.2596626,
                        "name": "/dev/shm/incoming/2023_11_01_091202_00_00_K_015.txt",
                        "sha256": "3bad154f004642291a372f28d86d42a9c5629cf02bd4b20b6b819f9f3e124b6d",
                        "size": 2050
                    },
                    {
                        "created": 1698829946.1696627,
                        "modified": 1698829946.1696627,
                        "name": "/dev/shm/incoming/2023_11_01_091202_00_00_K_015.kml",
                        "sha256": "516626ccd126165ef7549a89a693fb780661fa547d8e859a79e2ce3d3986a37c",
                        "size": 977
                    },
                    {
                        "created": 1698829946.0496626,
                        "modified": 1698829946.0496626,
                        "name": "/dev/shm/incoming/2023_11_01_091202_00_00_K_015.jpg",
                        "sha256": "abb1236c6665c8d23432ea1fc18d3381032b5c1e8b84727123e2bf04f8215987",
                        "size": 1051968
                    },
                    {
                        "created": 1698829935.5996625,
                        "modified": 1698829935.5996625,
                        "name": "/dev/shm/incoming/2023_11_01_091157_00_00_K_014.txt",
                        "sha256": "ca73634ca9adcb52a44f99e1b143cba1d422e30f6c540c59f4540274263cb9d5",
                        "size": 2050
                    },
                    {
                        "created": 1698829939.7796626,
                        "modified": 1698829939.7796626,
                        "name": "/dev/shm/incoming/2023_11_01_091157_00_00_K_014.kml",
                        "sha256": "c297816af84bed5da8314a9e9f623a4f99c042ee4f09131cfb73ba59add5363b",
                        "size": 979
                    },
                    {
                        "created": 1698829933.4896626,
                        "modified": 1698829933.4896626,
                        "name": "/dev/shm/incoming/2023_11_01_091157_00_00_K_014.jpg",
                        "sha256": "4d15fc7149a718eae5c1f5c100529ab0a59095ab36c43d1e8b1ad7f82ebf2ac1",
                        "size": 1098789
                    },
                    {
                        "created": 1698829866.8096626,
                        "modified": 1698829866.8096626,
                        "name": "/dev/shm/incoming/2023_11_01_091046_00_00_K_013.txt",
                        "sha256": "cd94d6cbd6d4868fffc4a6282427ad6a0999554e15b68b7b468d428bcea7ad1b",
                        "size": 2050
                    },
                    {
                        "created": 1698829870.4696627,
                        "modified": 1698829870.4696627,
                        "name": "/dev/shm/incoming/2023_11_01_091046_00_00_K_013.kml",
                        "sha256": "f0304f2451ffe78dbc4728ab5e4c346560aa1f2d33e8ef9122e3aa47417f66a6",
                        "size": 980
                    },
                    {
                        "created": 1698829865.7696626,
                        "modified": 1698829865.7696626,
                        "name": "/dev/shm/incoming/2023_11_01_091046_00_00_K_013.jpg",
                        "sha256": "cd46374185d2d13ecccedbd930aff31f159ee8054a25cd329318afe994ab46ec",
                        "size": 1136872
                    },
                    {
                        "created": 1698829864.8096626,
                        "modified": 1698829864.8096626,
                        "name": "/dev/shm/incoming/2023_11_01_091042_00_00_K_012.txt",
                        "sha256": "d7a2ae61fc838f372fcf10c19dc7c93c164b66852d3a101536bda65432346e2f",
                        "size": 2048
                    },
                    {
                        "created": 1698829864.7196627,
                        "modified": 1698829864.7196627,
                        "name": "/dev/shm/incoming/2023_11_01_091042_00_00_K_012.kml",
                        "sha256": "81ffcb5c5ebd104fe5fb994f5b16e2215e33105fb2774f53c5846eba2c6e8bbf",
                        "size": 977
                    },
                    {
                        "created": 1698829864.1396625,
                        "modified": 1698829864.1396625,
                        "name": "/dev/shm/incoming/2023_11_01_091042_00_00_K_012.jpg",
                        "sha256": "1b2ae8352ecffd5726efde2624e431c03bb91f976419d677737beba474534976",
                        "size": 1224172
                    },
                    {
                        "created": 1698829861.8496625,
                        "modified": 1698829861.8496625,
                        "name": "/dev/shm/incoming/2023-11-01T09:00:59.ais",
                        "sha256": "8ca77073b0d42c6e5f70a00dafc4e41433cf83d962acfad4ab54f596ae9d495a",
                        "size": 302218
                    },
                    {
                        "created": 1698829855.2196627,
                        "modified": 1698829855.2196627,
                        "name": "/dev/shm/incoming/2023_11_01_091038_00_00_K_011.txt",
                        "sha256": "e52023a834bf8492317d0e40abd7eaf8dea34baee91df7480e01d5936765e6b0",
                        "size": 2050
                    },
                    {
                        "created": 1698829861.9396627,
                        "modified": 1698829861.9396627,
                        "name": "/dev/shm/incoming/2023_11_01_091038_00_00_K_011.kml",
                        "sha256": "5b09df5a701e72fb77f7a49149b89d5761e7888a28f38b51435a8392a5595f76",
                        "size": 978
                    },
                    {
                        "created": 1698829850.9696627,
                        "modified": 1698829850.9696627,
                        "name": "/dev/shm/incoming/2023_11_01_091038_00_00_K_011.jpg",
                        "sha256": "3a4d5c63727ba272bb14701473e229c49764f52fb1114cbd835371469006cb3d",
                        "size": 1118745
                    },
                    {
                        "created": 1698829820.8696625,
                        "modified": 1698829820.8696625,
                        "name": "/dev/shm/incoming/2023_11_01_090959_00_00_K_010.txt",
                        "sha256": "06c873ff890ab07a32cd92f9bedf1c63e30131461a739354ca5f9e1b02b00e1a",
                        "size": 2050
                    },
                    {
                        "created": 1698829818.3796625,
                        "modified": 1698829818.3796625,
                        "name": "/dev/shm/incoming/2023_11_01_090959_00_00_K_010.kml",
                        "sha256": "5d1ad465824c48cd35d4b730ab2f78794c50f1a3235d759056fc9094d6b7dc3e",
                        "size": 978
                    },
                    {
                        "created": 1698829816.6296625,
                        "modified": 1698829816.6296625,
                        "name": "/dev/shm/incoming/2023_11_01_090959_00_00_K_010.jpg",
                        "sha256": "71057d82700bd6b50e37d1598067082c03f7cd0f69e62bfdbce661f265726b3b",
                        "size": 1072121
                    },
                    {
                        "created": 1698829806.5896626,
                        "modified": 1698829806.5896626,
                        "name": "/dev/shm/incoming/2023_11_01_090930_00_00_K_009.txt",
                        "sha256": "0814bde7656b69a4e800a2e2d01bb80e57a03fd3eab9e73aa6b4bdda050c2df1",
                        "size": 2050
                    },
                    {
                        "created": 1698829801.0996625,
                        "modified": 1698829801.0996625,
                        "name": "/dev/shm/incoming/2023_11_01_090930_00_00_K_009.kml",
                        "sha256": "4d482b036a67e1a92cdbe843d633c5a05a9ab8b876c5f59107b5f0efc8c30b0f",
                        "size": 976
                    },
                    {
                        "created": 1698829804.2196627,
                        "modified": 1698829804.2196627,
                        "name": "/dev/shm/incoming/2023_11_01_090930_00_00_K_009.jpg",
                        "sha256": "19f2e8f106bd25b10a51339f0d0c209e2783b5821c89aadd686ae0d6cfe4efbb",
                        "size": 1077917
                    },
                    {
                        "created": 1698829799.8296626,
                        "modified": 1698829799.8296626,
                        "name": "/dev/shm/incoming/2023_11_01_090925_00_00_K_008.txt",
                        "sha256": "26024a92202261f79b6b677c68baabed94097aac982446674c8ac8ac8fd091bb",
                        "size": 2050
                    },
                    {
                        "created": 1698829793.3496625,
                        "modified": 1698829793.3496625,
                        "name": "/dev/shm/incoming/2023_11_01_090925_00_00_K_008.kml",
                        "sha256": "68294a53c835d87473fd5e88bc7425840976461f6a36f5ef2845f93466003940",
                        "size": 978
                    },
                    {
                        "created": 1698829792.5296626,
                        "modified": 1698829792.5296626,
                        "name": "/dev/shm/incoming/2023_11_01_090925_00_00_K_008.jpg",
                        "sha256": "d1f2e47bfcfc247c667db25e8a512bf594e1129818dedeee4427592fa897f03d",
                        "size": 1151757
                    },
                    {
                        "created": 1698829793.6396625,
                        "modified": 1698829793.6396625,
                        "name": "/dev/shm/incoming/2023_11_01_090922_00_00_K_007.txt",
                        "sha256": "a522b70c4726f5b70ae12c4ff13d5148ca2144cec78f3b5654034436996c7ffa",
                        "size": 2050
                    },
                    {
                        "created": 1698829780.4896626,
                        "modified": 1698829780.4896626,
                        "name": "/dev/shm/incoming/2023_11_01_090922_00_00_K_007.kml",
                        "sha256": "76040e8816afa360182719908c8bca99766fcab989470b266c62b622e5a1122f",
                        "size": 978
                    },
                    {
                        "created": 1698829779.7896626,
                        "modified": 1698829779.7896626,
                        "name": "/dev/shm/incoming/2023_11_01_090922_00_00_K_007.jpg",
                        "sha256": "eeba3f235be9688ca22face5a3487ce5be2d2123b72b6db348ad49fc9903a727",
                        "size": 1143903
                    },
                    {
                        "created": 1698829778.8696625,
                        "modified": 1698829778.8696625,
                        "name": "/dev/shm/incoming/2023_11_01_090917_00_00_K_006.txt",
                        "sha256": "e1a5bb06be00a8946d28ca12d29e9cb30cdf0f0324f82f5399668178c32afc7e",
                        "size": 2050
                    },
                    {
                        "created": 1698829778.7396626,
                        "modified": 1698829778.7396626,
                        "name": "/dev/shm/incoming/2023_11_01_090917_00_00_K_006.kml",
                        "sha256": "b8bc6b45f0292a0bff077f040648ff48ef02b996d9850b493e74afb448e5a9a8",
                        "size": 975
                    },
                    {
                        "created": 1698829778.2496626,
                        "modified": 1698829778.2496626,
                        "name": "/dev/shm/incoming/2023_11_01_090917_00_00_K_006.jpg",
                        "sha256": "115f71d28abdb5ca13130b51b892dfdfb06bc6a9bea9060147bde62d89c130b8",
                        "size": 1124685
                    },
                    {
                        "created": 1698829777.3896625,
                        "modified": 1698829777.3896625,
                        "name": "/dev/shm/incoming/2023_11_01_090914_00_00_K_005.txt",
                        "sha256": "7576fc748e4dd725ffa952faf4dc093356726da38d8aaa4c7c10abf6ccf612ca",
                        "size": 2050
                    },
                    {
                        "created": 1698829777.8296626,
                        "modified": 1698829777.8296626,
                        "name": "/dev/shm/incoming/2023_11_01_090914_00_00_K_005.kml",
                        "sha256": "0d7089d0dec3ec201a8e256472cf51d506e7e27138d3f76f40efe3f002b11a5e",
                        "size": 975
                    },
                    {
                        "created": 1698829776.1796627,
                        "modified": 1698829776.1796627,
                        "name": "/dev/shm/incoming/2023_11_01_090914_00_00_K_005.jpg",
                        "sha256": "0a51bd61b2c25853a39add0a26729412ea5532261d7cd8a1b2e752ede030b793",
                        "size": 1154676
                    },
                    {
                        "created": 1698829710.2596626,
                        "modified": 1698829710.2596626,
                        "name": "/dev/shm/incoming/2023_11_01_090803_00_00_K_004.txt",
                        "sha256": "92635f103107308f7d0965616146683b85727bc48e46463248b88c67294275f9",
                        "size": 2050
                    },
                    {
                        "created": 1698829720.6296625,
                        "modified": 1698829720.6296625,
                        "name": "/dev/shm/incoming/2023_11_01_090803_00_00_K_004.kml",
                        "sha256": "f2fe93293a129bfa389d0d2534c9c139a1cd97a029ce20e5bf320bc2a67c4731",
                        "size": 975
                    },
                    {
                        "created": 1698829699.5896626,
                        "modified": 1698829699.5896626,
                        "name": "/dev/shm/incoming/2023_11_01_090803_00_00_K_004.jpg",
                        "sha256": "8a7f25274a4e1764303645f250243c0b6e1ad87dab57476273bbbe4958f0a6c0",
                        "size": 875852
                    },
                    {
                        "created": 1698829665.0596626,
                        "modified": 1698829665.0596626,
                        "name": "/dev/shm/incoming/2023_11_01_090704_00_00_K_003.txt",
                        "sha256": "2e673fe0c087608f78c9e4e18acbe5e7bb4f91abfc25d4b32f454501f5e7367e",
                        "size": 2049
                    },
                    {
                        "created": 1698829654.2696626,
                        "modified": 1698829654.2696626,
                        "name": "/dev/shm/incoming/2023_11_01_090704_00_00_K_003.kml",
                        "sha256": "7e32dcde5ba0b57e4d2fa067ee88b175c1dc9e4d3172a50fca8cde3e63e0129d",
                        "size": 977
                    },
                    {
                        "created": 1698829649.7096627,
                        "modified": 1698829649.7096627,
                        "name": "/dev/shm/incoming/2023_11_01_090704_00_00_K_003.jpg",
                        "sha256": "b434a90aebd228155c8a13f66fb005cc90ac415f6541a17da1de75e64dcba2c4",
                        "size": 695878
                    },
                    {
                        "created": 1698829601.3296626,
                        "modified": 1698829601.3296626,
                        "name": "/dev/shm/incoming/2023_11_01_090601_00_00_K_002.txt",
                        "sha256": "b92d4a37fe16cdb52a9e15224b10a619ac4a2a721deecfc2c66d806d5e54e669",
                        "size": 2050
                    },
                    {
                        "created": 1698829588.3596625,
                        "modified": 1698829588.3596625,
                        "name": "/dev/shm/incoming/2023_11_01_090601_00_00_K_002.kml",
                        "sha256": "9ffd48e53d8ad57ea81abba764d4fcbadcc635a68e9f1de95f10a99a267ca155",
                        "size": 975
                    },
                    {
                        "created": 1698829583.8196626,
                        "modified": 1698829583.8196626,
                        "name": "/dev/shm/incoming/2023_11_01_090601_00_00_K_002.jpg",
                        "sha256": "c25ed5e12bcce5305b2ceb03650e305fd37e19f5321a497f616523f5c9533d48",
                        "size": 1035945
                    },
                    {
                        "created": 1698829997.5696626,
                        "modified": 1698829997.5696626,
                        "name": "/dev/shm/incoming/2023-11-01T08:43:00.ts",
                        "sha256": "0723cd60e1cf562c968c548a2dcef548e678a9dd841de15e0b94176285da6813",
                        "size": 437561540
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "59397ac4b609ceb72bc7d33745f6ba81477926c4106ad935d7da64bdfdcb36f1",
                "size": 414670709
            },
            "errors": "",
            "filename": "2023-11-01T09-13-23Z",
            "size": 414670709,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698830301,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "QvRt3X7fAjP0f8yEoCJGjpzNGhzB+g6awUTZkvrjhyWY6IKyiwQEx82Lqj2buriSZBFXxgo5BWGgwxWplsAE9bmI7m6XLwWHv+/+60CDDVL7JbIleSzDrB5d8GNrmOmp9xGLpxV2lFOVCavP2qkkuRg8AKW0zL0dqt3xH/D5h3PrYREkKwXSrbsBwxS3/Q7NnW36de2ktiJjolHNor3iCnLtv/Efho7oEu15P9gXhlaEKU6MV1VpFHDD3KIS1aRfnG9/y3nC1xMSZ4Umjj/7dUTveJqrKGrsHQo4S2KI3/J7kzrUH1/LGOVU74MGWsaVcbR1QK6runuOs+s+dAfUTsQXeF0oPH5pmFRWfkCvaSPQYQrtGWXpD+fVIn6m0JbuEQDPqszLCV0IU5zjmBkpzEJWUEZfcr3I8lhY4U48ivYp00bA0b7vF7yQlGy3iYjhB/RgG35qVaQJVVZJcCHzJxV/NdgzgKBiHxnTZOTAAni0funaqxlg1REt75U+3d2r1WEklyuLtLCs01h+OdS5rtf5okpiUwEQZ7lKwlfPIZ18J5BuEuuYDmeMyNBNj57lxw0m2MAk8v3qtvW8+DnUA/9CYdFZyI6HzYv/Ukwnog65+dtogYWvGhimhen2Sph+XaOF42gS/nivWfM7nbJCIdFqwiZFJi5KNfEXfVCdYps=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-17-50Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698830161.1396625,
                        "modified": 1698830161.1396625,
                        "name": "/dev/shm/incoming/2023_11_01_091524_00_00_K_023.txt",
                        "sha256": "3a39d165cf029f8ad9813976b4c8f0d3f461147155cc4d0c7886611bb4e33aff",
                        "size": 2050
                    },
                    {
                        "created": 1698830156.7796626,
                        "modified": 1698830156.7796626,
                        "name": "/dev/shm/incoming/2023_11_01_091524_00_00_K_023.kml",
                        "sha256": "5ab3de609dbf128bc4688477d4598e8161e24b9759dd8236be017382bf4c55e5",
                        "size": 977
                    },
                    {
                        "created": 1698830144.5496626,
                        "modified": 1698830144.5496626,
                        "name": "/dev/shm/incoming/2023_11_01_091524_00_00_K_023.jpg",
                        "sha256": "d28131f7c0bdd52cd2c76ba2857be487b39c32469a096016e4f9d89c7a04592f",
                        "size": 1012695
                    },
                    {
                        "created": 1698830085.7696626,
                        "modified": 1698830085.7696626,
                        "name": "/dev/shm/incoming/2023_11_01_091412_00_00_K_022.txt",
                        "sha256": "9a042af8ae7c4cfdd2e2566925992e9f663093063f0757c3153e217ee6ebb583",
                        "size": 2049
                    },
                    {
                        "created": 1698830075.8396626,
                        "modified": 1698830075.8396626,
                        "name": "/dev/shm/incoming/2023_11_01_091412_00_00_K_022.kml",
                        "sha256": "1c4462b5231970f7fa797444f4f91f37503d2278d913e5a4b2e0254c085c469f",
                        "size": 977
                    },
                    {
                        "created": 1698830075.1196625,
                        "modified": 1698830075.1196625,
                        "name": "/dev/shm/incoming/2023_11_01_091412_00_00_K_022.jpg",
                        "sha256": "bda7c12eae14b1d4adcdb370138b66fef0678859668e25e8c71b610b8518cc34",
                        "size": 696864
                    },
                    {
                        "created": 1698830051.2196627,
                        "modified": 1698830051.2196627,
                        "name": "/dev/shm/incoming/2023_11_01_091324_00_00_K_021.txt",
                        "sha256": "dc935bc6c5093fda9dfe6245a13bb6edbd58bead745a620684fa03814accf35c",
                        "size": 2045
                    },
                    {
                        "created": 1698830040.0396626,
                        "modified": 1698830040.0396626,
                        "name": "/dev/shm/incoming/2023_11_01_091324_00_00_K_021.kml",
                        "sha256": "b0fa12c812835e250c5d5130477c17dd7cfa2e0b95028f346eaf150983ed8889",
                        "size": 979
                    },
                    {
                        "created": 1698830029.6596625,
                        "modified": 1698830029.6596625,
                        "name": "/dev/shm/incoming/2023_11_01_091324_00_00_K_021.jpg",
                        "sha256": "5ff48908519aa23e526028e1f6abb564e760d5dfe6c6643725d321ec0019b9af",
                        "size": 1013333
                    },
                    {
                        "created": 1698830028.5896626,
                        "modified": 1698830028.5896626,
                        "name": "/dev/shm/incoming/2023_11_01_091320_00_00_K_020.txt",
                        "sha256": "297cd4cfb96115568996212fb62686ef4caf971d89de7aa99c45af1b5fcb8ae8",
                        "size": 2050
                    },
                    {
                        "created": 1698830023.1296625,
                        "modified": 1698830023.1296625,
                        "name": "/dev/shm/incoming/2023_11_01_091320_00_00_K_020.kml",
                        "sha256": "640baf7d7823387c6af0a8f855bf717c31793b19563541d01584e865c4d18c3d",
                        "size": 977
                    },
                    {
                        "created": 1698830017.2096627,
                        "modified": 1698830017.2096627,
                        "name": "/dev/shm/incoming/2023_11_01_091320_00_00_K_020.jpg",
                        "sha256": "30c4be603b06d3949e5f10461db45d1f3c58cb309052c491209ed04b05a5acca",
                        "size": 1021757
                    },
                    {
                        "created": 1698830016.1396625,
                        "modified": 1698830016.1396625,
                        "name": "/dev/shm/incoming/2023_11_01_091315_00_00_K_019.txt",
                        "sha256": "eac9c250e8e0c78f91298e9f993ddc924ce17e7bb58281b714c18ce96c61880f",
                        "size": 2050
                    },
                    {
                        "created": 1698830039.4696627,
                        "modified": 1698830039.4696627,
                        "name": "/dev/shm/incoming/2023_11_01_091315_00_00_K_019.kml",
                        "sha256": "942d0b4a7beb9ee1a19bd6747360cbe70d5ea9a3b6e00096abed217e53e68e8c",
                        "size": 981
                    },
                    {
                        "created": 1698830015.3696625,
                        "modified": 1698830015.3696625,
                        "name": "/dev/shm/incoming/2023_11_01_091315_00_00_K_019.jpg",
                        "sha256": "d60bfa2909c1c6f270084bca0fa1b582ad67dd3b8fb4df2c678e76dcc4f001f9",
                        "size": 1030027
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "e2758ca0f571dea39e7eeeae4386e844acec076c70e9a608b4611d1e93513b5c",
                "size": 4772411
            },
            "errors": "",
            "filename": "2023-11-01T09-17-50Z",
            "size": 4772411,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698830628,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "k3cTlsOp4S2e4QjLHlcSJLW9a0x8Z1ovy9iC5wkgHZK4TBD8qmUm5mZ96Smsr4mON0qU25M3LU6mPo4bBvUwRLG1xj3ghks3jSE4vp7S5l8HNXvk5ueZggj2rwheE8r4rbmUg/a4oMiHlOdBoJ5z6vXKZLeDYjZsy38jJEmqar6AEw7efl+ZTEj9WVbLFMWwDKshT3t+iwDgWOFNxcWu2x8AC+KD8fszhWczyDlmqkZ96eoGNcLDZHuxN9gUcC+b6wR7aXWD2EU5fOudk8vMpTlrSDAPLuZ7HzSDbefMGeMmlX4AXbMUqntIzNd+ernIDso+CRIFmiQARoXviLJDs+/LrSoIoimILqrEmsby81Mu6Zv8L03uNiLPC7MDuMHQiyH2+kBSQmCs14kGN6BMyin+HvGYMZnlv13zZdHSc9WNQgVNGfWDpcC2ps4jWE2AL/5V7juhR8dKRSJQYhDQ6uvZLEnNdBkXtr0c+/a7+zJp+Jz3H/kzqQBdrb6EmuRKgr/UWMEbzr/QcoHplDc2fqn4TtTk0fku2W2kp+n81fA3R58nZ9E5RRTiVs5L8uRSC6QbiqB74MaaWBBQyI30y4LcpMRd8Vytomcfe+/+1h4kBYC7j2kufBd6Vc7Y1oyOXTnXdB4im1Yyq5qe4etPTKMKBvEQIKk5+YScuCh3DRM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-23-12Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698830469.5796626,
                        "modified": 1698830469.5796626,
                        "name": "/dev/shm/incoming/2023-11-01T09:11:01.ais",
                        "sha256": "034c023ca855e2215cf68b334a3b3803a7831171799b7a5419ce230ca62f107c",
                        "size": 234141
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "12e6ffe0d3c98da47f8f9f2873686e7a2d5aa73ab194069e368c125943323184",
                "size": 67141
            },
            "errors": "",
            "filename": "2023-11-01T09-23-12Z",
            "size": 67141,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698830666,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "UVXmK98h4GUshhgUJWgfYuSTBiycFBfTew6p2iWJqijrZTxlYdlGweh5A1gWQS4x3Hfml9iiPf+krqPt26MfrFYfBkkzPLuDZVuqTZs7GR1k5jgehiuvd19NhziJohZzsTqXm2aTPNC6Lxq6O0Jc4fP1jfybLEzDXAzMRzJBOTbj95P1s1ZBnTWIn3e09EpgWeIgZygxJa6ZtWVEfv4D5jSFK5sYMoDqrdaI+7eOnts65zpVSFPnORXVB5Wz/c4IfJiuYvE/niaf0+BeVZSvLNuYAs3EzMMnbbV/4ulpEBWq21xZjof9JBfEHZw4e0aqRhfU+Erac348HeMyC3fcTWT7f6P5pVKeQ6lnmx3NhhwzAyI3HHPeAxK8MvtzXzgcHHk34nroc4KzFIAuBMlGaeMlB8HruLzAutzD3t6D+T2gHRti3B/4oo6sQqoxLn1HcBueejE+6DPwPNtIOt+I1FWRHfulo885lIg0zNKFrjKF1Z5R5X2/qnVhUYYKy1qblt4n6Ku6QPc1G6JCsJ8YmO+xOfKKiO9J+FvcubzjD30thPjrqSpoj14RCj7rDLwmkGbDiPqHHaXz2RM0py/9Sck4YBTbboZ5JELhZhWOONKMlQpQYvmbW+W6B49XeKUqNC+2o0ymDODaHWdQ4K1DRNs8FFHuKg6uKZdzVcH95N8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-24-20Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698830534.0982223,
                        "modified": 1698830534.0982223,
                        "name": "/dev/shm/incoming/2023-11-01T09:12:13.ais",
                        "sha256": "a7fdfd4446014ca9409e16133edfc14b07e79d2c68110edaa665bd3ccb8e64d1",
                        "size": 123923
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "b571c40a9337c3e722944fe5b752a93d8ee971cc82c48b4a0965640bf446343a",
                "size": 31392
            },
            "errors": "",
            "filename": "2023-11-01T09-24-20Z",
            "size": 31392,
            "verified_at": 1698846196
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698830428,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "J11q8UR4st3NLFMc2jJibTOf1KA2DMYE+RecCan+wQ35MoYfxPL2PallPcdjBSPLu/evkscwqfZ1HBSLkJPEXZQmAVUyJOe/WLz2Le7jM4T4Gn+iiSV0NiSW3mlIxC1kbScq6uZYQkVPubt5qX/iaoe+OijcGa71+ETtPqI2Ztfm/HE8GTLId8d4COelPYlOrtTKS0wGuv/2FYDJVBunYCmPPuWTyrEXr1HswBWkPGFsKy0ov1JCNSpzwTxELuFFcTXp/9xddV+q81HCtGDf8GWtRtgKxGIaGxM/0VPvAgKGJ74Aw6HSF4/RBoGiEkbDbTabuLmuc5RGClfrVedsrDxV5QfM4fRyw7pVBBM372FS/UOcRSc/Z6Uprc311X3ZJkPaGjXpApQCbSW4KZgnY3awK5tE4z7F2MmJA6i9OBVE+fSM8QuVVfaCRlQlvYBu3Gvfcxh+B/r7RcrtwQmwi2FL3WmhV45CRBsrKQu/z3i6iKL5vYIse8KcSrAkCbFAEiOnB2X/FtUtxQD5lshmupl4RcBkUYU9Zu+Lny2t9RUWZ8Y6EcxNAUbJ5tyeqPPCAxffV3mPrPSKE7XWjzjgjkUe9x46NM4V6E2WJhZiZaNArbSi2gtU4Dxd9Z3rFrT45lYYYdutaaNnauXvBi3Gtg9brZ4wZciSMaoA7zpRttI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-20-19Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698830293.9225543,
                        "modified": 1698830293.9225543,
                        "name": "/dev/shm/incoming/2023-11-01T09:08:08.ais",
                        "sha256": "2ce9d9a582dbba2ab27ffa4f41930ec2d09f2d4f529fa35c89d7267498fd1f9e",
                        "size": 124942
                    }
                ],
                "mission": "EFCA1",
                "sha256": "50331bcdd917774985a5658cfa8c0d35bbe3f25af27f52c96c4ee3bdd7b9e9bf",
                "size": 28820
            },
            "errors": "",
            "filename": "2023-11-01T09-20-19Z",
            "size": 28820,
            "verified_at": 1698848098
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698830275,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "d4k/AzICPXbtSptPDoIWaW2b+9oB9JMU2izkuFPxeToLjpSqr6pR4suULOfERdJ21J1k/2ORmRu069gmTskYG6FmvDivGZDN1c/eGTXS0dgORIIV0NEGfuDTS0o8MpyK7/HdOEDIA5PsrgKP3NUX/tGSvK8/Xz8LaGrpb7ApmzF9Pr8Ah21gIuSn4N5or30K88BGTYdW2u8lrsagTarLABTieD9Qig2AZN+BZPCB8Am+TLWkQyVbdA/UJf1mhaxR6gLyvxq/gC0wcj35tAaafAyz1O2Swednld5N4b80DEcVEg48LkVJo4o+JKHCDEPHyk+Wqx6JPWlqG1MXaazFMGFptP8eYyiOF0egOxL4R9ZLUIxFcYqZhTW/QoR0Av2ImrwR21sERGbunMonVBVYFr2Ll5wa9oTeUuLVXICWl24CRdYjBbum6cEyNUrEAzDZRWFkhmqcy61ovAkkCaGyBN5DS6kRNqOq1mcRBbp+JT2NKKyrJjUBbv2mgcMnMIQxoMXodQP65jCEudCkTA2V15t9t+OxdV0/xq1yeeZrYdfhgM+/ojpXsNVgtF5/w0458PTeDfsT7TmCgneccTgPz3cWzjLm6yV8ps2fT/OD8SgY15lFbXduL/akmdKdOe0vIzvV9wFL9z45/K7eAg7d5zx+SZFs2b1m22dI1NxMKFs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-17-31Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698830122.4085455,
                        "modified": 1698830122.4085455,
                        "name": "/dev/shm/incoming/2023_11_01_091503_00_00_V_052.txt",
                        "sha256": "ded39457e97b4a0bcd1c62024d68d778aaaac5f56cdc185566036d0f21de7dde",
                        "size": 2034
                    },
                    {
                        "created": 1698830121.9485455,
                        "modified": 1698830121.9485455,
                        "name": "/dev/shm/incoming/2023_11_01_091503_00_00_V_052.kml",
                        "sha256": "1e456be7f69c61ec458a86c06c7f48112450c5acce10344a5aa001e07da328b9",
                        "size": 975
                    },
                    {
                        "created": 1698830121.3585453,
                        "modified": 1698830121.3585453,
                        "name": "/dev/shm/incoming/2023_11_01_091503_00_00_V_052.jpg",
                        "sha256": "a575c947240c1672b7eba7e0e47ea8c93cfe9c4e3c3cda2a89210f0db5046026",
                        "size": 755072
                    },
                    {
                        "created": 1698830030.2685454,
                        "modified": 1698830030.2685454,
                        "name": "/dev/shm/incoming/2023-11-01T09:03:48.ais",
                        "sha256": "652d0916cec2a496df77632978ec5f064f7b8a019c29c99c8d30588c57fbfbff",
                        "size": 588369
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "c78f501c2ea7d9024f847aa78b29f568f4ff501feb7a1dabf97478c8bf67a442",
                "size": 929312
            },
            "errors": "",
            "filename": "2023-11-01T09-17-31Z",
            "size": 929312,
            "verified_at": 1698848756
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698830427,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "AjOTiGNRLKqAV6Ni6srtNFfsjTqHh02+Zmbc1jJ32nkK8yq/M1yRXCUkM7l7pHBP/PwFdqizxw7qhOWAjSlBvWmNGqrNZZwBNqghUHCCIyOKrYb3bKomcai9ngQZBe5mIvH6Mzm1x7H9cc9bmPxNMR2hxX+opCGoNeK4GkuF5BLncjWRoUFLWjeP9Y8Q8oFad/TqpIAOybP46+SGrAHPlaj3nd3960aF/fBOxAmFbdaGNwbDJnDV1SzvsaiJg1+DN3XxUWXWve/hyz7U7/t/sjXQKiYyyj2kEDArFjVCwGMXaYn8lS00kkSGVVsYV/AFGylF2CrXm4IZ5cae0sacs3HwdR09wmg2DEylOGObogEyrdQDpRsiKLeGvfwLH99VfyQlHyei6LAEEiN5itHh8BulH9sVl469xav72KwVrI3L0MazxtMi/KXg+oDYVxfCRuDqeu+mWayWCETf8Z2Ke+ym1Cl0XjvDgoek1FF6qXXSDloLYu4pN+xMqNOO9pPTrvVRA1WrTwH8rVwSm9ioa2Ad9QtTqHE4rrflAYKOCqg1zoTaXlPjpp2MtVC9WE6lirJ4H7NAUuavPcUDx/735MQ3tFDmHI9viSlCRkBNwWOJ/ANEVhZYavhTjC3FFDAHL6NmMnryk2Rh1EH8VDWxn9pMroFjXh2UjaihpAMQon8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-18-59Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698830333.2285454,
                        "modified": 1698830333.2285454,
                        "name": "/dev/shm/incoming/2023-11-01T08:48:52.ts",
                        "sha256": "044256a64d4c16c8e00ce1e6ffc0f260301fcb5e4ddb2e4c6564a1f7e4ebc105",
                        "size": 426792712
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "4991c551c3b493bd55da163478b857a176c3d5738a9857f887014420ac801452",
                "size": 400045819
            },
            "errors": "",
            "filename": "2023-11-01T09-18-59Z",
            "size": 400045819,
            "verified_at": 1698849635
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698831218,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "GsIOsmeeFoy4zbLVan6qQYBBrv7ZWFKkYb0QOxtiO5WNjUW1iC69xMVFbdmomU9fSa7pnR5/etZ8t45GwFmMabsxRMmrVWgzhVLOT9pzSdLTBLs+WaZ42j9bV0jNVP7EbyBK2HDADJvrEVlQvD328n1h8E39SL2we2bR8i+DtpB2to/ObGR6vtUtThlByNbtLY6bqdrr0qAzdKNv+g/H80qbfloIXfgBk5Q3fWV6tS4Hvg+Aog/6VEbBBA+pJ2zI43DsqpKz5oprGQgyZY+Q6be9+WK0I4ea81w8fHLD6Yt+RW7CyzTVvzftJtvlbMpEPh1nc3OUuaFWmlukbGHzY2dgXWn5qSIjcSfDcw56Q38Pp2ug8OLHBBJOzUPy/oEb11LPi5nIYyeI9GIU1U6C0Yurf8B1Fw2uWYosVrje0dyLL5+kY9BoaHd32TUDCOMYi1NILFXSDj6Rp4nnEriDKnmCUJCSIlbRfdxz92YQ4YSALsBvBcbYl9HXGUMDOrICiZOm2mIz4K+lFOi3BRcSnkBjromyKPBHDSSvCIakUu8i0tJ5U4eWBgpd7H1hvJKEj4A6/205shGdc7GAYiq5BnJw9aivx8yYcQW+ZhX3fDvFM2gVfT8+n0vc6dgV6HxJcQ5Zp3lC0qg+foPTknIl8xSU7IbaFLlY8o4SQytupog=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-33-16Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698831070.7996626,
                        "modified": 1698831070.7996626,
                        "name": "/dev/shm/incoming/2023-11-01T09:21:09.ais",
                        "sha256": "e9ed0720a5a7eab9c3cfa102a205228b19c03c5888940d246efbcb0966e51144",
                        "size": 331352
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "7be4581c753029979de3a5deca5fff53f85594ca865b16afc4a2aa2a73edd394",
                "size": 95660
            },
            "errors": "",
            "filename": "2023-11-01T09-33-16Z",
            "size": 95660,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698831148,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "UBQsom28mORqTkjJCZ4KTEu0VLgWcDbS8/WX4WIsJ0ZfxWwNFDJ+/WT/+0CpPK6hpshYsYjBdpKohVC+t+nW0Y3gW09dN0hBuokgulzuyjOsc9ovEe/ws9ZG6wxyHsG8utv7DyXlysexi+uaT8ck9U4qtTLDGh3uzWbB4LaFrXXTSa16M8010hGWAarBZHk0qmr6h+EzqvnFPKhZvGaY7RCIA+R7CKyItxbCQos8XxwLJskRhyv1oC19u7RtgrhSAnvueV/7f65+QqSnPlogDj9XbjI0jMAdn1Vz6dIpKLEVJz+R17bkMxyor7Hutcx6Ij9856zDKLQsMtBSQtLincSAuk7LFsdZmCQBASlcTQH1oaJcSvJ8PxtgMQ81kq1xEcWuevr5Jbd1B4chMZ9z8zzf+SLy+WN7SXnavmuPc1Oac2lChXrsuRdqrHDSp7qMe1DpoxaFH+EBi5RyT9gzqhtka90H06cuoVDVw4sG+oGNKCVINXTY/ReMWjMspuqwPFK9aZZXIAVdWiB8fwgCwM6Suo9tOrDqkQUFokfZZpiaqxaJATpXM71nA/fZeoj0KGGs2ZYr4cJPmHGX4HVup0+BnXZW6dqNtD4DdTnBnKqJXDlOgQK0x0TUwDiCHx1AOeHk24B7RRL8dyJ3RPtk46bMUZC4H0zFb8nhQbelUTw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-31-29Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698831023.7382224,
                        "modified": 1698831023.7382224,
                        "name": "/dev/shm/incoming/2023_11_01_092954_00_00_V_026.txt",
                        "sha256": "ccdd4bc93f1ac412159ec20e2f6611876a154d9c7a9903dc9e696abf71891250",
                        "size": 2037
                    },
                    {
                        "created": 1698831023.4582224,
                        "modified": 1698831023.4582224,
                        "name": "/dev/shm/incoming/2023_11_01_092954_00_00_V_026.kml",
                        "sha256": "3d69be7cf58e67d95140fd6e752d0ebf4f073a23d649af61fe38514dff10bd81",
                        "size": 976
                    },
                    {
                        "created": 1698831021.1882224,
                        "modified": 1698831021.1882224,
                        "name": "/dev/shm/incoming/2023_11_01_092954_00_00_V_026.jpg",
                        "sha256": "16f703d319aac1d0cfa6431ad8935fdfb7692a912b83c56caabfd3cb63040ece",
                        "size": 1159807
                    },
                    {
                        "created": 1698830927.9582224,
                        "modified": 1698830927.9582224,
                        "name": "/dev/shm/incoming/2023_11_01_092826_00_00_V_025.txt",
                        "sha256": "7668117112705c792e83bb7dc57908cb91bc7c2f7320adc6eb0c28d79006104b",
                        "size": 2034
                    },
                    {
                        "created": 1698830935.9982224,
                        "modified": 1698830935.9982224,
                        "name": "/dev/shm/incoming/2023_11_01_092826_00_00_V_025.kml",
                        "sha256": "cf44ab2fed9aecf4b6ddc83bfc210a3be5b0ebe4b874bf76ac7d4e5d2cce90cd",
                        "size": 978
                    },
                    {
                        "created": 1698830935.9282224,
                        "modified": 1698830935.9282224,
                        "name": "/dev/shm/incoming/2023_11_01_092826_00_00_V_025.jpg",
                        "sha256": "1f6dd856f9b70200c2a29a6010891081a04470fc964aa2e3eeb6ca6ffac4fc63",
                        "size": 1121691
                    },
                    {
                        "created": 1698831080.5982223,
                        "modified": 1698831080.5982223,
                        "name": "/dev/shm/incoming/2023-11-01T09:01:20.ts",
                        "sha256": "8c4b11e39b76579325402bc615f3b6f1c111fc90698266c19dca05f7e19ee10a",
                        "size": 411065948
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "1a3af5f6565cac42bfb708d5d8c7310ec36757489b467e131a7a769c48c56c55",
                "size": 398879595
            },
            "errors": "",
            "filename": "2023-11-01T09-31-29Z",
            "size": 398879595,
            "verified_at": 1698846718
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698831034,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "Z0yKW0yrspNY/KwcnM+Duf0cGU3mduEZz9usUrusFy+cLBBHA8+4BhEWCoHKDvqHB0bQWSU53ngb44GuygI466F46OHd7KbyZKaVltVkrleRgXLvglqOlUVAQX9o2i190t65m6iHY0tzKuX0KQRoxdsFN8m3o4ADakHiS02waD41iGZO/pIKfGEiLr9zh1uu42TUbI7QtUi6U6BvbZEXz8SFmF4Kk9uDhyqrFiMYz6Co+2Ngw7LLqTMs8eB7PXLWkRQDI8bGfkcnReEXP0+99th2rRQ6BzmW7xnlKOZFl5Hk018Ark7mWJHtN7J/qRXWVhLGP1hTX+iT5Pa0Q/WzmiHFFT348V4h3TN2T2Jd4i2FpaE+vIWq9wqoHHwUp/Ty2TNR9wM+ankoh2Yyk+WooujVjpSQLzf1cdhVz8YTGjWLzrHdqe/fA+o7JIUrrStUyQx5sKD8lTmOP5Nus8xesRa/sNPxOcHQUsmcZscSVCpGohs0SPiGKP34L26rwSvC9dVIvV9ESVciW6Pv82xLVcvlBgSm1NpNx8PhcffFI0ben8BRiPingn35AmYzXf4q+R79PpTZBXsOPADktdsIKuHn0HtGths7Rac0EycWHGzwC5nYxLyIk4FttGWvhpjE6IPDjCIdFyetls7a0f0q6LqlVW7egOdUGlCvGv+xIQA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-30-23Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698830897.0525544,
                        "modified": 1698830897.0525544,
                        "name": "/dev/shm/incoming/2023-11-01T09:18:13.ais",
                        "sha256": "6fbaab7f617030a89a5dbe9ad65890257573250662ce081b92a68ff8a6b8e0dd",
                        "size": 176281
                    }
                ],
                "mission": "EFCA1",
                "sha256": "2d8ec84c73b0ca619e071753c0c54ab84012bf7c601255608db902bd691b47de",
                "size": 35973
            },
            "errors": "",
            "filename": "2023-11-01T09-30-23Z",
            "size": 35973,
            "verified_at": 1698848100
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698830779,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "PlblyEzK5L+Vduj62eQaWT1zg/dW00QCWm/ytqmlu49Xag6MPk0PNjVOtizrJqi8UCkEO5IaS8HD+LXy2I3FtJUH6dgosgXjQQip67XAmAbTBT6Y3tFB+mBcuBiAAImqpp7wuDR4j1ytSM+jMhnKbn0Im5P8++gBUwyPJsTJyRZPFrGTLkmw8+KjzVdUcXxMKkWrgiq427H9oKqWN8mUTKU/lFuH1rNKDy9MpxZBc1IclVit7+sFNU7wWozuIGkOjmIvES+priLv95CsQGXX2mrmiQXkQXttSSB4/CizKnrcYSMvJr2/WXfEj5DAvFfej6UBNNXG0SYUpOI1sQM/qM4IO6X0GwQ/AFUX5xErDygCCgoI8+ztOHl1v2S3YhkmHSGcdBhYiqTY5qowdQb2Q83kIOWUvGRbcMr0/BGIvH+kqRf5vlICI2tRid0D9q1546TX73zEpixATC7+NX9gQfXuzPe7HovPVPvdgerr7kks3h0mZd1czgATxJ3F3HWJS0448KgT/X9yuuG7Dz+dwD3i9FcDVz6R3tLVOWwGYpEA3FDAZQuK0bx18gHY8IhOGbvW4CEFCJD9vY0fqOROmdJPvwrFhsNldd00k4zlVQS15Z43yBmVt7TVYyJtst9uOcPy8DhNsPI6FUFOYjEJMPGx5Qy5JfoQkl6VdIDS0R4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-25-56Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698830631.5185454,
                        "modified": 1698830631.5185454,
                        "name": "/dev/shm/incoming/2023-11-01T09:13:50.ais",
                        "sha256": "d32f9723a105cacde7b6f309f593829ec74dce1bafff7e2d7b32587cf1f6ab5b",
                        "size": 614733
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "71d62858586e1bc0878172c62e2ea822c256ee1b19820271558929ab0d39c5cb",
                "size": 189048
            },
            "errors": "",
            "filename": "2023-11-01T09-25-56Z",
            "size": 189048,
            "verified_at": 1698848776
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698831598,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "rwMVv/xoZM4p14ZveYR7FWgrxhcWRdAnGR3ek0ZO6DpDhO6KAmVJjhCxcG25EdVexmKH6zU2jf3b6JLLxQSJc1ofO6siOi6XPoW7652D4XvQ2kBOS6ZIknfDqv900dXVyF0rcjgwS1E41hdy3n2AH5Nx6tnV6S6GF+Rk2FDRMNSIQJzmX5ybBYaPnGVHj03RlRkkRjHAQ0ARoysuE6uc5ALVMZVLUkywgJSPcYecpfKhkkY2UIpeoeNM9VgOhibzKBjCDNPfG4t3jwy+kuE2VBibbL8LNJA/59Sts+I61B/b0xfcr/9GVi81L9Ed6gYI06//yHIbcfYo/FhxrMk3siUOARQIH4esstW6bHtvnaa6cOlBKgLlQ+psC+3VRsGSZ9LYtyzwjHcR1phxQJAWqpbsl4dZbyi32laNOwaP0NB04mCSgcQjB61kvVb/2smbdxST52fE/EP1+gCWTzRRG1bJnSCKWBxiK/f1GZYwz8SWqASU/JQEz1XwQhSttGHjUy86pSeDJb64I43Q8tz7cKBpGirV+i0qe5TJLozStGYLBMeV1+F63byCUUTX9+Urvw8gSObzaHLNVhycMJ58XkNIcSR8RUMsrppV3VJg6Sn6sL9ohlgirk06tDDVA/N1p7IPWVZ706Rh2CtBdqTt7Qc+Rk11way5BWPiUDsj2FQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-39-38Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698831452.0696626,
                        "modified": 1698831452.0696626,
                        "name": "/dev/shm/incoming/2023_11_01_093709_00_00_K_025.txt",
                        "sha256": "721be74f3d5c43fdee1b5ed7f1826a74f880cce24f8dc1832fabd64121e71276",
                        "size": 2043
                    },
                    {
                        "created": 1698831451.9596627,
                        "modified": 1698831451.9596627,
                        "name": "/dev/shm/incoming/2023_11_01_093709_00_00_K_025.kml",
                        "sha256": "52c2a862facbd2439f2af8d18d54529c228a80544724ff0e1aef180caa60340d",
                        "size": 974
                    },
                    {
                        "created": 1698831451.0196626,
                        "modified": 1698831451.0196626,
                        "name": "/dev/shm/incoming/2023_11_01_093709_00_00_K_025.jpg",
                        "sha256": "965eed04aea809bdd6041bc9eab3a935a65861160538e3cad87e231ec84ccb0c",
                        "size": 310175
                    },
                    {
                        "created": 1698831449.9696627,
                        "modified": 1698831449.9696627,
                        "name": "/dev/shm/incoming/2023_11_01_093551_00_00_K_024.txt",
                        "sha256": "8d50f1e65b9a41ffe6107401107b936022a0fec882e9e8e051d93cd0455812dd",
                        "size": 2044
                    },
                    {
                        "created": 1698831379.0196626,
                        "modified": 1698831379.0196626,
                        "name": "/dev/shm/incoming/2023_11_01_093551_00_00_K_024.kml",
                        "sha256": "d341eaa38a593fe425247422e4c6ad651617824fb0a5165eda40b5ebc2f4d3b4",
                        "size": 978
                    },
                    {
                        "created": 1698831373.0396626,
                        "modified": 1698831373.0396626,
                        "name": "/dev/shm/incoming/2023_11_01_093551_00_00_K_024.jpg",
                        "sha256": "91e7974feda4f84005eae0882a6186441c49ce4c4fe37ead29e5ddc124f1beee",
                        "size": 167620
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "ef06be0f526d34d8e092d6e16d7440ee826e54f9f4767f8499b661557339966c",
                "size": 433149
            },
            "errors": "",
            "filename": "2023-11-01T09-39-38Z",
            "size": 433149,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698831817,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "sM491y1wmDaGeSbbmeDlTnIJiz788ZmLiDyYMPuiSYd6zmcueTDstofqU3WbYtYhk6DJ73Q0VJLuQCYJFdYLwTgf9sSLf0O1kqgIP+YFm9WjGeKFQotpIb+ChfmJyyST3+6w4s/ABkDY2ViBVga3movjKhnaZNNgzCmBptMBp7q9rB1g7liOSMWvSABWHaNtucjYYHgGMBPIFdDnCXwH5WeLgc2omupwZ1gUde+BVKOV/x192EZ3CnrZI6a8F59efJ3HdjtH59t49GGfpAohbHbw7PrPfGlBVxvpwedqFp/JhxVbEIDNRg2gmoi/SBZLisrT061/VsPGaRTcaMzYCq66qTGx7WSNQTAMpxW3hgNODELpJDn/xmR1NG+GPRS7pednn5S7IlJXZUmVjTTRbdqIc8RyNi/QH9Ln1VHrze4jN6nSqEXZMuM+1Z5GccwQ39/o+0cGXAIJGHIWG/SCWPQU/PIoksm55RZCvF7hCEWP3C2tUYFPPra3edSO58Nfg81Jd4GA518rQKUiRvFgw1u/35Jcy2b2TIAGLS66a6ClGTxAwsBl8WugTz+QW0lmCM2RCnefXIdNM3vTMsGkna8hbVgA0MGAP/NY/YlHwM/nrCzU6or0NhV120yeMuyHOC0GEBQUw+w6ttpLabzpg81HmhRxQJxVE0IyaVnM9bE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-43-18Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698831671.0696626,
                        "modified": 1698831671.0696626,
                        "name": "/dev/shm/incoming/2023-11-01T09:31:10.ais",
                        "sha256": "2daad92d21a773ad54aaf8b236ed84112c2d91648adc50672eb0c3485af61290",
                        "size": 398084
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "ba4333db837cacde8565631feb3ef3200a3cae5800ceeda096022901d7be3205",
                "size": 116999
            },
            "errors": "",
            "filename": "2023-11-01T09-43-18Z",
            "size": 116999,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698831631,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "OR9GjRsl+kljrGVuvkKTsp5iVk6XRzQLFFeHlOIAa8EPShLInHjgOA6tNM+PLlBdBZK8Hv3BIk+57CK5d7nFFyqn9KeEmAXd4D5k8g7Tqzpr44Rn0YEIy8mmPx1Uu8lS/Ttpc7YsCtprHNaN80WMlZ3dmHX/31+Qpu01oCc8yLSi0bx5hzmtYBSIykT9WppzAmnpMezSavI5Ey7qI5rTptukkydm6/QJDaEfTtjkpjjqEc+gCDcxdaHrYZYhA8DKE4m6CdVHaEAIgwZczNRfM2dz2DuHP7CHsDCkKfcyrZefnRLHda2zqlL3ufDUmnAjQGkjqfp6KEog4zUJozkeQYo+VBT0avVkTOcR2DA5Sv7+kCmJLKofB7/vYtMKadVSkbcbWUvbRa2UqI3OFNjgw/Ku+IKlZDuNZkF+nggzwzRgRqAgJmuVZUYOLygkrzqSNMqEk0KqM4le/ddfbvEi00yixFPdOmvZdVMhiWmYKW5Rb6c7PtbS0ufEeMQ+TVdDmBDhxOj5jr8aP5O0uYIAkUkxOAR9O5qH8n9zV2WNA9BjPRrMiLxq5O/RWca7tiW/mbhBxlw25kZ3wvWL4tbG+8dwz0v/wW06+en6Fk2AHjBT+E4UAEVzGp0UokRvloRvoUBP07dbsNq5Vo8t2hg7WjcbRQid/AJV+01D5sl5XKE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-40-27Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698831499.0725543,
                        "modified": 1698831499.0725543,
                        "name": "/dev/shm/incoming/2023-11-01T09:28:17.ais",
                        "sha256": "6f7a19e86339ca771a7a52a7878639edb0db38241ec8b8779ee976504e0958a6",
                        "size": 175435
                    }
                ],
                "mission": "EFCA1",
                "sha256": "c8cc1d204ac64018c200123f58931b6232d2841117231d66617d67888fce024f",
                "size": 35109
            },
            "errors": "",
            "filename": "2023-11-01T09-40-27Z",
            "size": 35109,
            "verified_at": 1698848107
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698831676,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "jjegZV7uFCA6MQ23QOFYXqw/FQlYWcmH1Zray9UavsOGemfcrsnp1dbxNam8dveEnFnn+v5HNenHBaMwlJx+xvNDrvNNCTPc0Br+oNtpb0parFJMIqmS5w01IXwVthXsvWwy8lMHN+ut6/yYiS1aoL8mq0zfHK0v0gRobClUO6+6CZKCZ9hkWBeIptrxI7LQUrCSbbm2aseEO/kLBU8qPJWfuAHqFkckUCaqJgc+9N7h1zzEaYO83hLZMoyvK7vnOSiuB7piMKbOSl/6cFp3M7x+XX2Fbbf+RVLGB3Ebed09UREPnaFKKpZYnOjdjfHgTD8V72iji7sb5VSrHmNPnJ6hujUeYEzbJlI/3hO4gsLKzAZS3N+My09aKLF1n//0p42dPyCPvxmyw+Sz+FFZOaq52uoZZpWTBFuhpnIAqjN5my8vnWeoO8lfre3sEqloLzk7Ohp5m6JD8C+UO4l4wTFaUc5SLGKapXg/MahZlLeshmaL9O18xkQ1xKyTfkewyZPDmffz7YTAyG5Z5OOGLA1FP08yqcZWJbx8m/s+fEGAqSmPHsaOi76vSdXEi+wkRl9HehA9N2sTjZDXOQ3nFW8Pfuxd37mIVZS97ZS2lo71qEhRGJYlboolbaemJUplov8aYvp7zJN2RqjcRN9outfRysYKPriBGY39saBLbEM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-40-48Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698831603.4425542,
                        "modified": 1698831603.4425542,
                        "name": "/dev/shm/incoming/2023-11-01T09:17:18.ts",
                        "sha256": "8b880a72c12781c18495414ade493035ca4d4630ee8247022bd607f6c61033de",
                        "size": 308543488
                    }
                ],
                "mission": "EFCA1",
                "sha256": "1c5563cb1102ccebad7ebe34ce8371c8293911dbbca4778f40a14acd395f7ee0",
                "size": 297025062
            },
            "errors": "",
            "filename": "2023-11-01T09-40-48Z",
            "size": 297025062,
            "verified_at": 1698848243
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698831881,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "fDtd377MgsiCuxv3a4ROj5QSB3F6UKiAJ676bOBX81J7tvoFAo0rITevvFgC3/0uWGNMTNY4mxQqSn6P+twExTvDjugy3ChxR4EEEU3e+N82IuVldeO0G4gOEYn76wsOHmDLWejoItxY0ATg20VucyccIJ95+QT3bCK46PRRAaRMZQErx7KX8P7q+Eka0QLX9yww0GdalmJF39g2Bj10gSq4Layp01ZAdx2L5wzwmI5QX6AaCXTreT1y5tRmeR0iAppSwbuj1WowFRAUFV9dP+pkGyXwfsEFIvXMNHQd5+sMcMVQV2BH79Nf3LYq//Pmt3nEZ7zimnhPlz1UHtpmLb1QVx7G2cyyrLv/te97bhJaqUv+oCpDsYKoHdIXFjaGgtLoT/b30ek7LkwjdOS8wx8uivvna3aJluIsVNzuVtQudSF50HCF2ZvIC2fmE9UgStG/RSSh33Wmy/Juvoyt9bflTjInt5/lSACVKF9v245ujcEYrEmR/9CI+MraadBPKqR6aIzLCLq3S3Z0XA0um/s2ACOJqWDGDIYdkWRQadoYeOBDg3L28l7kxECdnRJ2oXFmg3FP4poPLhKvMyq70Du8bKt+CyuDsFhYMslO0yUbbLxYTSwCutX5dJLc7+gEn38TUHnvu50fjxC20RFCBEj0hiUELpEcgwi2QIqhswg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-43-33Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698831803.2896626,
                        "modified": 1698831803.2896626,
                        "name": "/dev/shm/incoming/2023-11-01T09:13:22.ts",
                        "sha256": "fcb6700699c901b8aa725c611b4daf999c2795d704c35dd9c2d7115e32b103a4",
                        "size": 441747736
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "57386c2f08854d9be4a9783486c45bdf49d8cee8de07f7c1514195fd55ed6051",
                "size": 397699695
            },
            "errors": "",
            "filename": "2023-11-01T09-43-33Z",
            "size": 397699695,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698832233,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "fgdnc1uVaxr89sa5oYh/JD1rEMML/Ck0H6iJoYAGKMOnrcN8nQHVs23MeIDdTrieHFS6F9YEdzh5kbAx82JWF+IJQ1TBbIA1RIXZ8EBFhJF9JbRoWtfK//mUocmZEVU826HzRTPUE8KuGHyAfvjnD0zvOLjkC8X0mY3ywTj1SxUSBSoZcglzbAhmZ92rExQABjlyBpRHHKG1olldSunqdbtp8hG/ZwRC9QxcVZrN2HRcPDkQcJyXfl1rJ+RWS3V0sT4tEVSFTuHIGMuIh+i2PdSm76gBpsjkMQyK2k6SutG1cU0MOSVzo479REavcO/3s5hKl4XMn+vNNLaJJhV8MBYyHxNqN4444vnOqpHnN2dl10wd9OlxPnm0AHSmVpcfhqOwjKept0c+EYMiJdgQnDGlC60oCqsbDZ7Hq0RvbZbGKSgTCsy/Y16FV239vpM7dEACug1EXZrVUi0NAD/RaTdZNBuuFUW4rXOw1XUuzhkIFYt1Wi7A5/YoEo81/Hg1T4DE2JZNo7jdV1PqS+ADqXxnyMb49gRwk1GFgTKSncMD6Xp5nJu3fRvqBRkwW/9s83k/W1o+yCXogyuX6U7olq3lQDhkMbDxGb1FGMi7f42PlRFW1B4zxFcl8qiobVttSB9pC/ubDQh7JorQopQ0FqvxQAxhPwwoZwwVcdkn7RA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-49-08Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698832139.1685455,
                        "modified": 1698832139.1685455,
                        "name": "/dev/shm/incoming/2023-11-01T09:18:58.ts",
                        "sha256": "288423cc6ae5f280601d75dfe8e52f053840814594508cb034a48e0d99f586f0",
                        "size": 426506012
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "741122ca646a14bd020c1f28d73518af94e166f88f718f48d6ecb093e7725978",
                "size": 399777469
            },
            "errors": "",
            "filename": "2023-11-01T09-49-08Z",
            "size": 399777469,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698831983,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "b30WBAsmXrXs7c1nugoM0bf9bSlaazzJzA+22E2tNwDADRQhi/c34Mvah1wXkgXuIuQhfF4uwY/neKXOFo6hJa1uP9tKOo2GQJJkwHAGgsj4aP2skOD4zk4QiaWjJk5oSbcmZbJt//nO8tYQ0vqzp2APTB92C2QIMaUzFOgckpGYxJenls+xhnx8WZ6YbhhHxmmF9CUiIyrOnKNbaMXKQwKPaJLF1+uDTikRCwZ7QuSlCjCRjLKNMpRJnNwhd/bcUROFDNZB2Z8PNCmR9MgFVzmtEWRlK6uWZBcI82gB6CRI8LpcauoXVCY/klY96B1bhxqXEhTLyR7ikA25TKTkWnx1a3jeAgC/6aMLkJVW/wNfRQseXRWzDN196nlRS8WHBO1fuUePjSniMwhx4A/26mTOJWRHOFCQOwPFIuV0J5wlCFwigNmqXbu9UJQ5A4sq34w2I9WqFPzyuZRNvBetgDnE7JFLXdXew/CKQ8wCl05Y3sF27tI1tK6zVzCjgoBbjIkMXfXTZCQT/fnosGWVfPvcl4VkulI1k5KOXc1o7pIUOY16m53ZVAg0q1fakm7qEduTEgqeEubrpJgNqjSZa6QwXPBaEDgTTdgqKn/KKWsdynSsCWOVoEWIA6vHHMvXTgVnxSQaXzIWodkPcsBSsQbNGPB+CcrCbKyc5LHVRO0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-46-17Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698831861.2182224,
                        "modified": 1698831861.2182224,
                        "name": "/dev/shm/incoming/2023_11_01_094346_00_00_V_028.txt",
                        "sha256": "79c9781ebd11f7c5aa5dd32886a8c7f8e6258c11aa59141691ab8094d94cbe06",
                        "size": 2032
                    },
                    {
                        "created": 1698831854.6682222,
                        "modified": 1698831854.6682222,
                        "name": "/dev/shm/incoming/2023_11_01_094346_00_00_V_028.kml",
                        "sha256": "8f6f4145ded55895b0895703d0ecc3aaf65aa7cbaf01e1c6ab9e3956fa5ac620",
                        "size": 978
                    },
                    {
                        "created": 1698831853.0382223,
                        "modified": 1698831853.0382223,
                        "name": "/dev/shm/incoming/2023_11_01_094346_00_00_V_028.jpg",
                        "sha256": "647091eaf371c5301ae3eddbc6e36e9c346bb38bc31fd244ec427a371dbdbdac",
                        "size": 875852
                    },
                    {
                        "created": 1698831849.8182223,
                        "modified": 1698831849.8182223,
                        "name": "/dev/shm/incoming/2023_11_01_094332_00_00_V_027.txt",
                        "sha256": "a9873379660ec319dc8bdf6bc8888d2eb422c4adc17edadd52a9263552896773",
                        "size": 2038
                    },
                    {
                        "created": 1698831827.0182223,
                        "modified": 1698831827.0182223,
                        "name": "/dev/shm/incoming/2023_11_01_094332_00_00_V_027.kml",
                        "sha256": "5dd32bdef23580f82fdbc45dc81595a569d892c4a280a668c08d168b7458503a",
                        "size": 976
                    },
                    {
                        "created": 1698831829.8482223,
                        "modified": 1698831829.8482223,
                        "name": "/dev/shm/incoming/2023_11_01_094332_00_00_V_027.jpg",
                        "sha256": "74c4ab81a65c367e9a5f0014a6ece701c5fa71f7578cd9d27673a39264089193",
                        "size": 890705
                    },
                    {
                        "created": 1698831736.1382222,
                        "modified": 1698831736.1382222,
                        "name": "/dev/shm/incoming/2023-11-01T09:32:15.ais",
                        "sha256": "a867d0770410433424dfb38a91409d0fdafca5113f1cabec8d725e5cf1b3accc",
                        "size": 111448
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "7b371de290ff05b6651018f0daf20af5d4ccb8d749cfc38e680ac182129c07b7",
                "size": 1792114
            },
            "errors": "",
            "filename": "2023-11-01T09-46-17Z",
            "size": 1792114,
            "verified_at": 1698846297
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698832456,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "nskwlD+j9rP4FcDao9ioPH3SNIDDHKHKcWod+HdQeHuOF3wYLuD/1GTOt0v4xIVN0uznHHQoco/rgMCH7/NYeQVthtcDLd19rcIL3tmPJHV8L45OsY7qZR8Nbh+2gEQWxJYZIIPsIHhGx98UOlEOq0wbcDbRkXN4U1wyPQD+L3KeYmoqrGpqd/ZFPckE/ZVtHvihbsbeQvpXopWex/d8Kea5B2h20LF1t3YjWtT9ArLFvoUzQnWz76DAWDUZv0r2Jzfz6IMd1hT0j0xK6DeJto7EPCSL/rA8zDLG2QQ/oaTvZFD0I81Dsxp85I7FfEO58VVsh7YkXrEwfZkVQ6xsy5U+9AbmBbZUBrDuDnItKamhmj1Ub7VMtU6sk/3PTJ+rs1IG35vyJ8R2MpEE1SIb6tOU3ccPk70PoWm4h5nT5AY0Pgei6Qzfj9IHiYO/8mP2xKS3OMhvaevSr4lJ7oyGF+GEgWfRj/KG0wA55K/HWa+DXWPOPTVZglBLTpHUGuyIyodPR2aWt6HXCL/yqHjtgN0xoOatNLi626Lr8R/QytwgekDEb3Nk5MhsU2s8SVCsy27SQFxpyj0Zr5vz9sxoRijX40o7hbmDyMGyRGDPPNjcwL/KjDtiZ04tjDL5XDCcxHx/kXZgOEOMCVhf+Drgjsnl7oc5+ABBzXzs3GzUHE0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-53-59Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698832310.9027698,
                        "modified": 1698832310.9027698,
                        "name": "/dev/shm/incoming/2023-11-01T09:45:28.ts",
                        "sha256": "a2ab0df82d3d1b08d1313caf46b2350341b7b6f57baa8b32b3b566866bbc7821",
                        "size": 86967860
                    }
                ],
                "mission": "EFCA1",
                "sha256": "b3c2e60420aa7d3ab38d574299c4e2efccaacc9302809981da9e9e9ca46a15d8",
                "size": 83986351
            },
            "errors": "",
            "filename": "2023-11-01T09-53-59Z",
            "size": 83986351,
            "verified_at": 1698848250
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698832494,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "WvryQLuBLhZsAG13gqPGMuNzeEMuw3LBaHO/GahOGYKTdtzRbaON3b5Gvm5180mGFmtjh7uSCI8rsce1DemhEH06Gmm05PNHsEV7UTHMkZ8bNcHVWFBR6IJn/bSDgkFsnTaMO3dcDjhy0JxkebyxNdFkbZ3RyBmuH9F3z3RTEdCDduPgr78NHUxO8o5v9fhZ28pqvnDXneBDDg28e6a8JKq2JFkQGBnGPj/sV2I6Hd9lXykpmabBwdvY2XXi/RfyiYe0VXSbSfOg3y7kMVsUMTmaenUa7ggq23dcNMa9x6br8qB4+mW5/wWH4bZqBVwgxnpbzGecjnicx+darg+eCiKRqvv8wlQ15LH7wNAAE5a0lEePFKuF+l8N0op83p/IM2yY/XPW0wefYsqQMMwMFWCyHhtdr6LJ5G8OKKv8BwfPFZgfSLnOvxCqXrRj6P0dcAHBT3KNIldrhiS+FKjE+5qmQUCXSsOSEhShUTMJ5+7qojLm84FhkUhHXv1iNWlkH/2l48xYEcHc5mmPTaWBGvfDqWlG4RkSTQSIpDpqv3PRcxfEcr2gsDbrbsNC2hKMq/a4EIyoaDG9nvpvUpK+ezeS4ID5qouNmde6JoFp/njJ1MP0aVX0pwrSM+DqeDpvujQfGZfV3+T5WeV0y2F3fCL9HLLhX9zWAb27FJLPZB4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-54-21Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698832354.0996625,
                        "modified": 1698832354.0996625,
                        "name": "/dev/shm/incoming/2023_11_01_095155_00_00_K_026.txt",
                        "sha256": "b13d76d6678381253ba50ace7365322e8f00eba2d61743312e3d9652337ff9d6",
                        "size": 2048
                    },
                    {
                        "created": 1698832345.1896627,
                        "modified": 1698832345.1896627,
                        "name": "/dev/shm/incoming/2023_11_01_095155_00_00_K_026.kml",
                        "sha256": "214e4e2e6c4b74a49d25e5b1edd9d2ae1718c5e7ed1a19760f37862479d50cca",
                        "size": 971
                    },
                    {
                        "created": 1698832341.8196626,
                        "modified": 1698832341.8196626,
                        "name": "/dev/shm/incoming/2023_11_01_095155_00_00_K_026.jpg",
                        "sha256": "c250ffc1de001415ab3eaabf4a6ad8b9acffdc0ccdc42e87a5fab2bf7185e0b6",
                        "size": 908658
                    },
                    {
                        "created": 1698832272.1396625,
                        "modified": 1698832272.1396625,
                        "name": "/dev/shm/incoming/2023-11-01T09:41:11.ais",
                        "sha256": "0f4c15088cacf59f075df7727588d4a9be691fda22b2ca0a420cbfb8f0526999",
                        "size": 460278
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "542ca41ef36145f40603496ddcaab4e1907eae0cc86d90d9404f27d85c99ffd5",
                "size": 1044604
            },
            "errors": "",
            "filename": "2023-11-01T09-54-21Z",
            "size": 1044604,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698832904,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "D78gCCuQV0prUOBur5VlSdTgXqZgFkzkkqTBzJnvvCa46gbHUMVP1Itggkq+QpoJp9vA0CxYGViYGeNj1aLoZ8ephPcfaPNdiOPUJ+1soVWcfDBzXLdog/9F+9t6Mv5t4QvxNPk9QwGi83Zu3Do3Upp1EN0nTsvaGI5YA13Y9NfGFZ5dVUVnD8GbgQTwKKXxSKuBOr/RJhPx4vBsajGL4kbgWop7Pj+uwtFmLpqlytznU6kGa0UJreAB+YsdNS7ECujxm5JkZGNZP15FLVPSv6n8ozysm8btRHvJX2ei5T+lreU2aiohMHpd4xWn8aBBoXHmm5XNVX39re4jZOpWLwZruRcj3UHys1QDEArQnH/SIUry2O8bEMdOVyacBp30oCHnod0LgCaGJn2huQRyE34yIwWwH5/twDcOPZbYYXcl8wHf8IR1QYkopG5nrBR1+J3J0xUiZ5UWkMH39Qi5+f6pDAvwywAA2fesNUe0rPdRMuHQIkKHm8vrm1sYzptizx1FTtDoZWq4k1leuXajpsXDgPu4sfEuutTgsxACmGMfYd5KwJB6Byuxa9i4Jn5MJpRFs6OLIEDcg5J/vDaUeOGqUutxOi8cbn79TZQ1dEntYu/I55s1P9mzO6oh4hP06r1QKfFDsxx2yILOYRn5qDp9jb68KNkPnO/EmFra/rU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-01-06Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698832812.9682224,
                        "modified": 1698832812.9682224,
                        "name": "/dev/shm/incoming/2023-11-01T09:31:26.ts",
                        "sha256": "36e85395dca16c693c2f44232b6bbee36fcbf79f0943c6f0976aa940ef73b92d",
                        "size": 394002432
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "03da61505dfbd3627b2645aa67e822ace7675c8edce6b2bbaefc2bf1fd6bfc1a",
                "size": 379747305
            },
            "errors": "",
            "filename": "2023-11-01T10-01-06Z",
            "size": 379747305,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698833033,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "DbfwCZalChsZaSHwcEQ36tg5BvO0WciZFXLAoKoLzoa5tAD5Gfp6uoiRx4p4FuQ9Mx6EZ5ZkrOU+FgjJ+xiJyo77VReEmg/QvqPYuLD7mLcfxFYQwAns83K5kAlRGYJdEMh+goG7Qok+jest3zZhykO51DHa2jUztBAWNOT9IFc7I7GisRTge8POf/5NsMhdcDEbRDGGFGcPr1CB759LPerjbqs8sM5OPGLeaZLZlqskoIvLl0iQrRg0SJKwPrV6afZqP1pRUlzNsxmX/C9GAJeB1nFWJtbWB4Jb/u75rcL5myY9XpWJ2VZjiJC6eGuIGd2OmJ6tne3mn3wr8eh+YCjHuO9N/rqq2KKaCuFhbezlDFssG6wiXeyuntoATNseBvMq/se92DIRlDSMq5Gqj2j3Xc2N4QgfFfu+YYgMDptbwW86Crb3RtCpiVGvuMWK5iOZuu4/7nwhc+YAHDZU9ECCOx9Lly71gMllZieXNalDTW/aFGtWTm8AdU0HkAqC2rTN4C61NYIxHjbD08qVlbmmHzRXkRB7jKWN+HbXwg1PwnxQpU0DbB/OznNYhP8g27T1q8rrpHPK9CFoa0Rb+fy1YMjPy7bEDmgVQFZIeZq0EOw1wA6p7eF3LsMmn6BHz7r9WEyMeSqJFVljEH+LdG11Xpp9FDK+F5J54x8vw9s=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-03-19Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698832873.6696627,
                        "modified": 1698832873.6696627,
                        "name": "/dev/shm/incoming/2023-11-01T09:51:12.ais",
                        "sha256": "9cbb04e5fbd8690e15a04e43b749c1b36dbea2b411d1cbe9e2ee40497019b0f5",
                        "size": 499745
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "75dcf7e32e698b56077d3ec3e08ad3a78c9c092ed5a1f325eb808f595f170dc8",
                "size": 151878
            },
            "errors": "",
            "filename": "2023-11-01T10-03-19Z",
            "size": 151878,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698832601,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "l7DXWmaftE+fhe1XHEN33mXeqX1dJVaE1z73wHE581VlPyYw1A1UICgIuT0H964Zt8phtz0uZfSJ5oGisC7PIUOQORIAFprYvhlNr6QQAfqvz3hjLkBxeefgmgropE5nW6dVUmfiSRo7P6sAkJ3roUE/1Y7Xw1Pjqx3ngjC1FVTue1F3/PpfCj6h/cM7+WgUnGUjds+JS3lFD6K21uFXSEsae/FDtEjmpQRpRQ9NrkZoTQgZwyUPET8dAofCiw+5oqOrDSW7gPZDohrxVp92svriFd2J6MQ/+X2AC8TERBBqtM4MrXce+DMlC1UK8LD6tYDA8i9JKNt+qQtnT99ScdWFgndES7GXYZVBHI2bAsMih1q34YupnLwt99Ga/IwA9dM6VeKyGpKpd1yM1JLl3ZjhUbxw0A5J11prjY9WGgHnV9Tl/1PCW8n7d1K2uB6nxeK06rSyN/7SYlrny9lFDNrwGK8UgYpZESyIUp+o0FaxbIzQTy2ZtbpxbO0f53HXvfuEgeKLpvYm8HCGO+4qITnFLyTVBT23ymZKA94dmMH0sOBlqHmLwSw6B/D9WsdBMcMK8wctZXjuytt7sff4oyjTW3UQVjlhRvcNLbMRz2HpTxL5nloZz5izhaunxazb9scMGaZCe/joYeBsK22CIYiWA0Mvppncb/J0U/7VCHM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-56-33Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698832467.7527697,
                        "modified": 1698832467.7527697,
                        "name": "/dev/shm/incoming/2023-11-01T09:44:24.ais",
                        "sha256": "48546d4fc47aac31d2750abd413157af0d6a7a3e953fac1c071c75e16af62086",
                        "size": 136299
                    }
                ],
                "mission": "EFCA1",
                "sha256": "e1a50d2ced49681f3a379a5641c9c53b6f42e318643c02f2b499813dec0492f6",
                "size": 26739
            },
            "errors": "",
            "filename": "2023-11-01T09-56-33Z",
            "size": 26739,
            "verified_at": 1698848133
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698833686,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "lySzNXNzRilA6afqXNX/Pxk1aN0JqeLVmRImX9QShhg1fd8Jzxg8XyiIf3fog0Zfq0ZZZjl2N+GVQ373c6fKFMPlHRtcys7tRzx7FRtEQIAtuz0+SerpQ1s3bpQCthaGFyN6yD0xUiF1N5Pew3WccwZXeda03+sniYgKUevdlTOmnGhncJsu0pTMdOai8ws+a6MI32vuA8phompOPIsIki567QwzHKgnYzhrGEFu+nJYXZXvh3/7qXHCVPZMO5eXctIiENWjWWPfdvG0eXkbOWP6nWncg5fqXe/c+9z5jHhQRwIFVfAr1Xz1XHblIf3728ufB8ZBNTcZKNtHVYlds/DdV18Lyg8+HOMO8iRPhqGSQszQ8mg8dKDRticwfDdIWFUndX5Np1bihE+xkowHaSljZbvXUbs6CcAKOcqUPnU6K94x0yrgam8NT6R3BcIIwb2blIxK8zCY0eNI8Zpb9NEklJdrUNDHf25Rz7HkO5efXWtETYe2IxwwQWlqhi6r59lX9DoWFvmQzPxWASEuK2R3+ETE/p+o4gmMPnb+KrRTBcSksIkkACHOL2ey7b5zhUzNUC36oqNn8hYySyyZT0fCVjIfxYFsnuATSpqfj/BlKA35xpWjAnJjiLoURld08UPSQ7EDOQPZ5d07hK120AGglv5qoc37K1TvP0s8PE4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-13-35Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698833526.7196627,
                        "modified": 1698833526.7196627,
                        "name": "/dev/shm/incoming/2023_11_01_101144_00_00_K_027.txt",
                        "sha256": "d9824c5639345547b2ae23c8cd6fed5c5a88efbf235da2e9eb3dfd70cc5a113a",
                        "size": 1545
                    },
                    {
                        "created": 1698833526.4196627,
                        "modified": 1698833526.4196627,
                        "name": "/dev/shm/incoming/2023_11_01_101144_00_00_K_027.kml",
                        "sha256": "d599edc1c591c10ea56625f52917a3689b33bcb24922fb98dc88611111e28995",
                        "size": 940
                    },
                    {
                        "created": 1698833524.6096625,
                        "modified": 1698833524.6096625,
                        "name": "/dev/shm/incoming/2023_11_01_101144_00_00_K_027.jpg",
                        "sha256": "ab46949c246b687fe2649eddf410a2581410e671ed3689a92c0e3e580d9a7dbc",
                        "size": 679216
                    },
                    {
                        "created": 1698833475.0796626,
                        "modified": 1698833475.0796626,
                        "name": "/dev/shm/incoming/2023-11-01T10:01:13.ais",
                        "sha256": "a4fc20446b4c8cc4328d805a353d2e837882dc1273f6c62e7bce9f209317ef83",
                        "size": 517285
                    },
                    {
                        "created": 1698833608.9696627,
                        "modified": 1698833608.9696627,
                        "name": "/dev/shm/incoming/2023-11-01T09:43:28.ts",
                        "sha256": "7030a49b8cdbb79348778719da2b167a95fd701efabdc2832cfc9cdddb445ca3",
                        "size": 442914652
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "f52c0334e9507859a58375ea92f1a1ca9e968efa615fd3e4532976d181cb7bd4",
                "size": 399625851
            },
            "errors": "",
            "filename": "2023-11-01T10-13-35Z",
            "size": 399625851,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698833207,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "m2itHBFMCR3J0lsup4z4Vo2dqv66Lmsp3HVoaKQb5JCmPK/onjnGY0tb7+vItFF/eZbQNJAOlM69j77NuYMfE4+9m0tq7jLFcA90Rqw0fqTGaFI68oOUCPT1cMJH14yYIVf+GhzlZiC3ZBQ4LCZhngWyHrC8s6kI+YzGVtBaUbnTHvO4jx4ZrHg5XqPEVEfCM5arKV6mvLj5ul3JRqPiGX6spVmaAeFP65q3QzECCGHWacr7GNdYC0fH7ouA5YVz2X9vd6KJn8xCQ7bOrqzyMsVCr4efqYaIl7nRtwrqIR/5iq8QtD4Vr96ac09YTPzsTBF6q3XaG1roPM4POJSvRyotzYmMhrCgpODvSePxgtLkJJayKSEtj/CbejUKSkSxl1Dbq+76Pk5sNlYRz7NDtSPl5JBjMWovCMO35qssdWrCXH5pvMXp/yvGOfiGxb5vInJmI5KHjs+7HP8hb1M9vnNYzj2AFbFqc1v/1VR19ZlqfHtNvQiqBn9t2MO0P2Gg1rTNdX4qtTdlhnIzrLssCkpoXego0Rugc8H9Y5kgjw+iEVJMWYDwsWGFo2J+OL53IigYRamMyxcJC9OUpys0WhX96HMmApViyY9upnCpzf5rF7DX16xWQyb/PC8UMXX2UJnIFXa+TQC6jDDx57gx4qw14RCguPdMPQwgKWcJbVc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-06-37Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698833070.7227697,
                        "modified": 1698833070.7227697,
                        "name": "/dev/shm/incoming/2023-11-01T09:54:27.ais",
                        "sha256": "860f0cf2021dd878220bef3d4ba9023595fe327b7313ba4f96febb670dd788d7",
                        "size": 167975
                    }
                ],
                "mission": "EFCA1",
                "sha256": "c93d7782a3ee4c69f5d1ba230b4a1c2379e556d6e787b004a749039ca0b1f1f0",
                "size": 30688
            },
            "errors": "",
            "filename": "2023-11-01T10-06-37Z",
            "size": 30688,
            "verified_at": 1698848136
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698833809,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "srM5f4IMwAXrDuuhUjzlaBbvhlibLrYAy4TTtKhOgovZXBlaSEo7jFQLzmi0CO4Wil6888/8lTvhRmTXY+Z2vmqDKc1fL4BNI4+LLuYSd6PZ7+0f533KVNLG7x9RFS2ZLX9lSKHokM440MdrNuQMWJEpDK6NcA79/IlAt9VP8mEaOy8AlFZvoFXecBXvCugA5m1VYkDaiIZuaP7xKund99xPwWo/AA9LycWPhYZhLuQVidjfju3OrYFFAal8JfZHUgE7p+basBU+yN9+XWvZpGUe+1u4mIr+EmUdvHk3n6z0bonuNPVV9FaT5bwlL/jSoT/vAHrwhl8uWAgm4+VNBskBz1ZcDqpGc2DEQjxpYgCiGjwDQBqCxfV5x9+YodeMbN4TRpcLWvWEpnDRrRL7CR/VBhY3SuF6L5zY0eSEM7vMdssbbegGGT6xY5IsG47VslKcRh0PYIQQxQJ7iWG24h9FFrWwwzygGNq0UQ6GMXZVfrM84yOQBI9bPWvvZjb4NPw6jLRk4//tv3lV70wNyCD5ZNAWSZwHrSDQhL+xS5obhZGHdxHEKBCaHHrn8irGZCH4d+W3DofRMHGTyFA/TAF6j2qL25kSCsmbANw7fR8KnK9VF5Zpg5216w79bLnAm5oZF8GJ03CMZHkKgR5b2ySRQIOMD9nEfJMOiS1oh6U=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-16-42Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698833675.5027697,
                        "modified": 1698833675.5027697,
                        "name": "/dev/shm/incoming/2023-11-01T10:04:30.ais",
                        "sha256": "deead7b81d83ed0c69c59e1b3bde6a9305873cbe9628e0c5ffa0d904066cf634",
                        "size": 148846
                    }
                ],
                "mission": "EFCA1",
                "sha256": "5eeb1b389273084884340a5eb01013a5e924e39755391d079c406a7c993b0f66",
                "size": 26914
            },
            "errors": "",
            "filename": "2023-11-01T10-16-42Z",
            "size": 26914,
            "verified_at": 1698848142
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698833188,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "WQMsQe/E2KOLf4WGbLndtxhDSVvr/UZVWHwy2xUkovOH+bR73tJG7VhxBsoOpdhLm4QheCRh/xEsUWw9LMmyecl7gwwM2+uFEKSZ8PGk/19VXvQf9q5Ok+J7wDMzjZ7+6Xl0wyzJ4mkbhXlP2qSH+cbDxlNn5R0A7gy27vd1Z0AgkUVzZfle/yZgOJs/FW0LIYH8MN98769MuRshsVfC23iwbTgBldLw2Ni1Mh7F1JnLf5vITGPLsoyi8JfgeAlrxZbQDIeFt9VUjEn5p0Z1FXTF5NHbo7YNIEkC7w7980TRghweZ8VqANnCM80Qf4ldYUoCL2f5y1M4n/1Dncd46m5/L0fYzgcT23KdRVGVoyUr3ZsGtO5StmfQ8xh2gXwXhmv1VaSURSPojfUnFKnqS30ctpa0O2oLT+v2mk/amkWsMcpB7dotVphQNGehC+WKYaWw5y8oiRedRAoK1EPtCv/+uaPKu+LoBYQScFwJfzH2OgGiYipEyTZ/q3RaFEGOlQ21O/cLQD84pwQmfRzs4elsnZqU2ea7s8U/R6x4hnA+t/QjeTnc7XS9Y1N5QWl6QQL8NeHXWY4KclG2pAMhljGrzliBcUuLlZenhqTbdRkGTmXqORb5K/8deUayPK2FacY1a8nc04lofmpoeTQtFkRnUjDChbNSmYRWXbzUXPk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-06-05Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698833036.3185453,
                        "modified": 1698833036.3185453,
                        "name": "/dev/shm/incoming/2023-11-01T09:53:55.ais",
                        "sha256": "6ea6a7b9bf891a7ee724ea044b03bc1c0e0fc9efdede77ab78f6b69a582a232d",
                        "size": 622970
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "547fa6036b23be6cf80a4dac53f3b6246370a70a328c3197f6850bc5f7f515ec",
                "size": 193285
            },
            "errors": "",
            "filename": "2023-11-01T10-06-05Z",
            "size": 193285,
            "verified_at": 1698849277
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698833791,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "s06aDGJRTnRUdhCu3OQbXALLYZgZa/vg3rNnxAZ5cSPb/ukPZUnAGccg59l13iFsd6Ng5CCq0ly7XFtUAAQbs2p5CRcjKFDZpbDfw4c9OeZklNtMR0DQeVX2WrJq/TCmNy2/JxQd2fcSxuM3D6A3Vyb6s1ETuOIh7APOBG9kUtStvzJjFEv05qEo+c9pVIX4o93a68PSXXIYc94a8A8o8fk8t1PJZIiH2EYKsQ3IpVfjkk3zEgU4PygyqJozZo0oZDaQGbqJfhVIVIppD2olyq/O9Qmy39HHSBXd4O242MARBxiD/vXmlCNgkJ3xWkO7UYuvtddwE6hu/2egCvEb+nm+wq9RD8R+N46yPsuj8FRGWF2N6AgRPU4liGYCSYiBKB3gWXfo8/jZpVa+9ke2PrEb/VxGF+eFE/Gz/Z6513aDczhkwFO06SuIbwWVGE3PHGYT0U18i4OSA5w5VvmAu/BfDMQxauFQ9UDVAHrNGvhvruYzP1/wEgWPB9m5M3I0ww7V0c+aKkV9JczAL3VB/rrGPqNiKsZthccXCU8DcolqswFN+TD31t7bkeygpRrVypMe83Q4BhTMxjhaQd+wrERGny9YdzmKsqRwR7a/YIBkMKR19Hu8cLtBB5oo4k9C8pNEMRd9B8zQPXd01bzR7BZo0m7WEgaGESmqBy11R+0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-16-06Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698833638.0085454,
                        "modified": 1698833638.0085454,
                        "name": "/dev/shm/incoming/2023-11-01T10:03:56.ais",
                        "sha256": "29fa92028c91a8b49e8ca99d3f625d7b92b7075f8cad3e82d628fe391ede8dd9",
                        "size": 608477
                    },
                    {
                        "created": 1698833576.8785453,
                        "modified": 1698833576.8785453,
                        "name": "/dev/shm/incoming/2023_11_01_101236_00_00_V_061.txt",
                        "sha256": "ef38c6831ddd1f6da128bacb7140819fc6b180aa98a17c64a6eed22bab9c3cbe",
                        "size": 2032
                    },
                    {
                        "created": 1698833576.6185453,
                        "modified": 1698833576.6185453,
                        "name": "/dev/shm/incoming/2023_11_01_101236_00_00_V_061.kml",
                        "sha256": "f8f75b3014c7222a33a52313734dacb9b1ddb50f2f8f5e673af31bce5d4f9157",
                        "size": 976
                    },
                    {
                        "created": 1698833572.8885455,
                        "modified": 1698833572.8885455,
                        "name": "/dev/shm/incoming/2023_11_01_101236_00_00_V_061.jpg",
                        "sha256": "fd0578523375c59ab561a589dbe74e1324b060fa68192a08a468314339033684",
                        "size": 794296
                    },
                    {
                        "created": 1698833551.4285455,
                        "modified": 1698833551.4285455,
                        "name": "/dev/shm/incoming/2023_11_01_101206_00_00_V_060.txt",
                        "sha256": "b1011253c8848f5900ee58efa8a89e30a646b335becfe91b163ea32359fab383",
                        "size": 2032
                    },
                    {
                        "created": 1698833550.0885453,
                        "modified": 1698833550.0885453,
                        "name": "/dev/shm/incoming/2023_11_01_101206_00_00_V_060.kml",
                        "sha256": "2ca55ab7e08e4997cf570e48fa909f2868c2df3e1b36b3339a802a537a746e58",
                        "size": 972
                    },
                    {
                        "created": 1698833548.2285454,
                        "modified": 1698833548.2285454,
                        "name": "/dev/shm/incoming/2023_11_01_101206_00_00_V_060.jpg",
                        "sha256": "6575af22427d97f2b27906a3fa4a003194c81d13d4dd27289239520f95e9fb77",
                        "size": 724888
                    },
                    {
                        "created": 1698833552.6985455,
                        "modified": 1698833552.6985455,
                        "name": "/dev/shm/incoming/2023_11_01_101159_00_00_V_059.txt",
                        "sha256": "f21c5426e3d9d6ac727849f3591d485f702ccf1b76c6ce9914904704e6e6c963",
                        "size": 2031
                    },
                    {
                        "created": 1698833551.5585454,
                        "modified": 1698833551.5585454,
                        "name": "/dev/shm/incoming/2023_11_01_101159_00_00_V_059.kml",
                        "sha256": "e79f083d18c14435e550d460790bc334f1410559991a4aa4e209fcddbb91b30f",
                        "size": 977
                    },
                    {
                        "created": 1698833546.5585454,
                        "modified": 1698833546.5585454,
                        "name": "/dev/shm/incoming/2023_11_01_101159_00_00_V_059.jpg",
                        "sha256": "a87448f0c6ed1411af333410f125ab9c46ed35448af97197573cb648175e7ea3",
                        "size": 619429
                    },
                    {
                        "created": 1698833535.8885455,
                        "modified": 1698833535.8885455,
                        "name": "/dev/shm/incoming/2023_11_01_101154_00_00_V_058.txt",
                        "sha256": "90a1a8c9c88a5c54549e9779a76132d82e868c32d514d059580b986c2bf1bbb1",
                        "size": 2032
                    },
                    {
                        "created": 1698833543.2185454,
                        "modified": 1698833543.2185454,
                        "name": "/dev/shm/incoming/2023_11_01_101154_00_00_V_058.kml",
                        "sha256": "a40de9cb0baa32cb06e5fb62465309581a3c2ec9ddec44f923ef78036d4300bb",
                        "size": 977
                    },
                    {
                        "created": 1698833533.0585454,
                        "modified": 1698833533.0585454,
                        "name": "/dev/shm/incoming/2023_11_01_101154_00_00_V_058.jpg",
                        "sha256": "df83b99fa0c34f2f695f519b7e69bcc864b0ffb198bc048b6a7c9522c5c3ae16",
                        "size": 678646
                    },
                    {
                        "created": 1698833526.8985455,
                        "modified": 1698833526.8985455,
                        "name": "/dev/shm/incoming/2023_11_01_101146_00_00_V_057.txt",
                        "sha256": "b07279294b4b6828b63847e920d7134aa494c1264e638b3491a2e1ae5c8d79f3",
                        "size": 2032
                    },
                    {
                        "created": 1698833523.5285454,
                        "modified": 1698833523.5285454,
                        "name": "/dev/shm/incoming/2023_11_01_101146_00_00_V_057.kml",
                        "sha256": "f3a87f2dae33e4f9637606520c8898bccd7b229f6cf354d5f744edb9db98f175",
                        "size": 978
                    },
                    {
                        "created": 1698833520.7685454,
                        "modified": 1698833520.7685454,
                        "name": "/dev/shm/incoming/2023_11_01_101146_00_00_V_057.jpg",
                        "sha256": "acb8e462f5eff661900f449f96e5adcbcbb64a35ad0b2d1714b8e172548d655b",
                        "size": 641149
                    },
                    {
                        "created": 1698833519.6985455,
                        "modified": 1698833519.6985455,
                        "name": "/dev/shm/incoming/2023_11_01_101136_00_00_V_056.txt",
                        "sha256": "9d84dbfd99e4c89bbaf38ec5b8eda5571b671b536b934b4c4b8200c312d34bce",
                        "size": 2032
                    },
                    {
                        "created": 1698833520.4285455,
                        "modified": 1698833520.4285455,
                        "name": "/dev/shm/incoming/2023_11_01_101136_00_00_V_056.kml",
                        "sha256": "98f4b7d773da658246c18ca22620eaca2d7d8d22fcbc586fae23735372bebca4",
                        "size": 977
                    },
                    {
                        "created": 1698833518.9385455,
                        "modified": 1698833518.9385455,
                        "name": "/dev/shm/incoming/2023_11_01_101136_00_00_V_056.jpg",
                        "sha256": "f5f37c6647385ab80b84912939c7fe6b6bd84d69e314ace3eb718ba8a611849a",
                        "size": 600993
                    },
                    {
                        "created": 1698833498.5485454,
                        "modified": 1698833498.5485454,
                        "name": "/dev/shm/incoming/2023_11_01_101121_00_00_V_055.txt",
                        "sha256": "2dc210ed07adf51825b4a15be739d915adc563efccdaa2b0dd7fe80399deca65",
                        "size": 2032
                    },
                    {
                        "created": 1698833505.6085453,
                        "modified": 1698833505.6085453,
                        "name": "/dev/shm/incoming/2023_11_01_101121_00_00_V_055.kml",
                        "sha256": "047410904cc388ec7e61839160a01e715efc42daf64405e259fe57465c1364fb",
                        "size": 977
                    },
                    {
                        "created": 1698833495.3385453,
                        "modified": 1698833495.3385453,
                        "name": "/dev/shm/incoming/2023_11_01_101121_00_00_V_055.jpg",
                        "sha256": "a00387da9c0924a54e1df7a8ae3fbcbd03c5caffc2c52218adfb40fbc1ffb4e1",
                        "size": 646683
                    },
                    {
                        "created": 1698833479.4585454,
                        "modified": 1698833479.4585454,
                        "name": "/dev/shm/incoming/2023_11_01_101051_00_00_V_054.txt",
                        "sha256": "8cd65708bba02b51c6fed040db4fa7079245738fd1161b42374d9143206ef5c2",
                        "size": 2032
                    },
                    {
                        "created": 1698833472.5485454,
                        "modified": 1698833472.5485454,
                        "name": "/dev/shm/incoming/2023_11_01_101051_00_00_V_054.kml",
                        "sha256": "2132b7d6f16f8299bbd6523816c3c9caa0e46d30dc836831a3ab7986b57c8a9a",
                        "size": 977
                    },
                    {
                        "created": 1698833471.8885455,
                        "modified": 1698833471.8885455,
                        "name": "/dev/shm/incoming/2023_11_01_101051_00_00_V_054.jpg",
                        "sha256": "f50fb343e5d7e5d8a856b75c9b4f67602fdd5720cb100c2cb0c38125311e0a02",
                        "size": 834781
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "39b18d212c8a8caa944fa00cc481a2c226c63dc8f53cdfc149180199c25e00b1",
                "size": 5666153
            },
            "errors": "",
            "filename": "2023-11-01T10-16-06Z",
            "size": 5666153,
            "verified_at": 1698849446
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698834039,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "AEaE2pT6J80ChRRNcSUIfQbfuLib4jhiEgazKSg9z2ds9gAA0GItOC2SS56NXXrZZaRCgiJlOHsyVm72x0HrGP5a/TGyOyhNEIfLazfqbZyhpGdqWs6t0hxDt+6naKLoJJ/CMtCa4Jd0LPfowTJpEUXX1yAoVHkIHVg7pmr9rEURYb02iBEaymTowQeQGFmytRZ4uN/DceHVTQCraHgwHSzblFbYzmGMYRipLnk1AILCoSWPiTBUhni/uOjvqSzNVqSAa9VthUkbBueUX7Uq7Ty4qdwHfed84Ly/8va7s/BMBrynaDwUOPzSdwsfGdtAkc1wLn077lby5o1SeywslxOkcntPyrM7pqYMVyvh8eYz4xG6wHPbgxeu0XULITmD8JcWwFL3OMC9X1bzCnYETXzBelbux+nsNtFquIUxsmea2/7k6H8USJrM+6fknfiSLlpBxdIRHxvfbrCHP6nD/N6+xdJjE/8rzaBEicRyD8rsgJQNskAi9X/RK2gRT8vCGLJ5wxYyQ+iHnppEweIJvOZUUgfM65rxaRZ4lfMl5NMfQ9G2qqQkN/F26f2vy7X5GTAcWi2YnXGZ82zhbaj6m6j2lOA+vDBuVE1nZ85mSlcPjMethv4PxqOV0Y/pWwKydAMoxruaCmXXNpCNG+2diGlNuGcwP9jy9HGkwyVFwLE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-19-11Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698833945.1485455,
                        "modified": 1698833945.1485455,
                        "name": "/dev/shm/incoming/2023-11-01T09:49:04.ts",
                        "sha256": "f356858a93428b23808ef2a456796f580bdda6114c09ec38f85aaec51c71ac21",
                        "size": 433355416
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "07a462129229013f7a1f02cf810db35636188730aaedd537119c3d4668197449",
                "size": 406457937
            },
            "errors": "",
            "filename": "2023-11-01T10-19-11Z",
            "size": 406457937,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698834221,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "Cxmixv91cVBFSwt/pE5Pkgv9JoaDMCsL/RntGefFaplVvcfExK2bB92vbqssp63M01d2rz5FerrRbCiAa+Wi0QLkDiJqVQfMeGcwArBS2bsgzdELdfQ2GUVKBNspk8lv7Gv3x+KA1agXG0Tn+ObQ2TIvpXIqHjGUZAYar9RLmnBtOh5mexSzue9v0/7J4WabYECGu+wSOH2Lbvs0/J1zx2ZdTVpBSh4XzkJ5eWFQRhBoqlCMmGFRs+Mv+tfBZDEUqlGP9ZXAxKYuKjDS+b5RmyUaFPhKnJ/JMw/u+4PygJQ/+AbrPmVG4p3i9H6FR33i6m+3aiwqw4y7ny7T/6Wqds/gKG65qkirB0f3lYz1D0LZuYFsx9CQ4pLtz4ruj/SOwhSXQqcmNPG1uJCVghUOLWPyWi9FYczhPq6jc0CkKOqW45xWoeL5jv1FidNSrak7fZCsjkmrW7IaVZmi+a87iYC45qP9Xpi/d/SWpjdpVkoNz2Ey+68Wp8cUOy3xkjfmNXjDoYrsufhAkb+DycuYXU+l34c3Xm1K0FUUTSr9hFIvYkCDhYvMVLH/9/EuaOHWP13g5YapgcwTXLSKanY3mK1nkXlTqf2JXM+CgddQy4T2ZovYg+SRdaiz6VuUWq7n3XfMYnm8c32kbgLf72J8YwW82iB9cutQmrfqqNoxOsQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-23-19Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698834076.3096626,
                        "modified": 1698834076.3096626,
                        "name": "/dev/shm/incoming/2023-11-01T10:11:15.ais",
                        "sha256": "a29292ae21b6ad54b05e520d6e04c34ca58689805a84abfa9d806fcaecad5370",
                        "size": 501814
                    },
                    {
                        "created": 1698834077.4196627,
                        "modified": 1698834077.4196627,
                        "name": "/dev/shm/incoming/2023_11_01_102052_00_00_K_028.txt",
                        "sha256": "0a86fd603fa3eae6b1f06651c4ffb75d9a418b0e16915533e4d76c71e9c560d3",
                        "size": 2047
                    },
                    {
                        "created": 1698834069.2896626,
                        "modified": 1698834069.2896626,
                        "name": "/dev/shm/incoming/2023_11_01_102052_00_00_K_028.kml",
                        "sha256": "d81ea4b1af835393612f5a1a9f169801c4b6eaeb5566e58e0500c350fbd811c9",
                        "size": 978
                    },
                    {
                        "created": 1698834084.3096626,
                        "modified": 1698834084.3096626,
                        "name": "/dev/shm/incoming/2023_11_01_102052_00_00_K_028.jpg",
                        "sha256": "5ba8da60b95e5c86983e4ebb61678d21eb1b8922f0f6ebd96d64213097d40e6a",
                        "size": 837043
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "50b3c65b7665c8d16d7e097e8b04146d638d36cda63a3ad74fee36dd38888cc0",
                "size": 986152
            },
            "errors": "",
            "filename": "2023-11-01T10-23-19Z",
            "size": 986152,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698834987,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "sVGT/rSHkw7DpPMDe9vNsGpvjDLKHagP/nv+LRzAnnborklJxafS9DZqHbKFNXVxsRSw/irYdaRVTJXPfrqWOoCOsqww5K2UNzeoGVp2WeKCANwLFPFADyYtIUg94hEfG4PJvlFshgS9xOEf4KVFxVkTVnjeukJX3yKI96eYHTPNhQASbTGhHVY27u+j2NQ7gyK5yNLP54SG6KhKiMoZdDVpedbREvKLPJvIbCWu7GqmSJR9CFlBMMudGLD54FR0hSE8+sXwcp9i2imgf3WZP9TVaXvEwKkIaOSGPvZ+/svRy2Gnt3bA49FzUVOFKpitUo7T0t+j3oEnHIj0SkW+5hr4xcGlWzmbOA+bkl82lVxKCz8HZ02yY122Ei2Ve1DVVpHAbVJ8Sr/0V6Dj6OffZFZkvMG5Wmsgh1NYwvzFjwhBtc2BfYgoGvzZLTRQifPjtfY6Dx0iPnoJ+Dd5qJOZcFXjyWyX33EfdrejUwKJkP+PQBW2gGOOJmjMXvj6wPXm/Tp86ORUvmgCeO005G8+JQuWavdMspPjPVdPGY/v5m7EPeAUYaiAPeFHuQbU71zdaadrLqu5IfnRups8+4SS/IELTH/cdDfS8yok+EPz8GH2K6Gq1uFsryPGlqHdWQEMI8N/TC4RO9QNRiOxjCoIQ3U3zCuJEcA6txQ+YdYQHoM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-36-05Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698834840.5285454,
                        "modified": 1698834840.5285454,
                        "name": "/dev/shm/incoming/2023-11-01T10:23:59.ais",
                        "sha256": "4d1c61f3e071aed09b9f48c6f86cf0d352f6e580c399705bf03dc43829f5fc4d",
                        "size": 684158
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "ce3f3db58f1d557cc7c13a2adb90eea8cff164e89d0d18adb55bfcd4bea2f739",
                "size": 212122
            },
            "errors": "",
            "filename": "2023-11-01T10-36-05Z",
            "size": 212122,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698835086,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "sjfBRvjodJAvLMfXprM28hg8mzmJFjXSs1pt1HPIEpIlEGdW5DDssZBNxT7HvUq0cdFT0723G7xfu/9bD89TwDVK9Q7apATcBi02w7LVxjKOQ+bPQwm9/az9kgQC6t5daLpwUmqGlxAqBjhqQ+rurFrPjWSJFNMHxmhIUhHJcNfRQ28ORrBITynEvNSFrI81xEBUFYAwSoa86eSgwWOTiI/fBsWfQTl87rcSmBsgdmhALLdwjzT8kcezVE0f/VRi7ADRLVD0wFZgbpZnupbr7S393Fs1qav2e/15HjI0EZm0Zc175tc8mCI6GV9NUFWad+BHk9I+EKAvLzGsxwNwheRKWE1hnYurtud/Uov7ZILYfCGI5sZYQbuNI6UPytQ1KpzAHL0cipI+icPBm7jCvaE5nKhv6uBqB4C+rhb7hIuQLFMOuvk7vbOlLjsdIsks9hy7mZTw6sYM/aKugsncZyVBlKBVeC64j2RydMHfSawiOAiRyfiLXcO0EakYv1VoJndfpP4Dc9oLqeOr4tYVQXTy8LvX0Tv9s13JXVStIcr8oRCPBykNR32WJd5in/gkcpjf6gmBpBgZqNdmrOixc+BekyJdr9k7UKO36jrrGUmwS4kHJfw/vM+7QOAfDFYQPGbJwQo4QYKsebAUqVFcZy0gfSy/iS9EkF2k9TiZzw4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-37-30Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698834922.8596625,
                        "modified": 1698834922.8596625,
                        "name": "/dev/shm/incoming/2023_11_01_103500_00_00_K_053.txt",
                        "sha256": "c4637a4b3f897ad0d9f935f1de508a5cd2fdda0bcb140f960e69b2932356f188",
                        "size": 2050
                    },
                    {
                        "created": 1698834930.9396627,
                        "modified": 1698834930.9396627,
                        "name": "/dev/shm/incoming/2023_11_01_103500_00_00_K_053.kml",
                        "sha256": "8a0955e2d203b636d08d0003f75f26236abb91364d537ad6487864689e8689a7",
                        "size": 979
                    },
                    {
                        "created": 1698834932.0696626,
                        "modified": 1698834932.0696626,
                        "name": "/dev/shm/incoming/2023_11_01_103500_00_00_K_053.jpg",
                        "sha256": "807f06847d04f35ceebc2ffa34b474233c57dfac6c56d8ab4ed5d566893bf72c",
                        "size": 1196282
                    },
                    {
                        "created": 1698834920.2396626,
                        "modified": 1698834920.2396626,
                        "name": "/dev/shm/incoming/2023_11_01_103457_00_00_K_052.txt",
                        "sha256": "e8aef8b983e50878c11d3adfbb38e1cf66ca1cb5140069c25d838c8313d3ae45",
                        "size": 2050
                    },
                    {
                        "created": 1698834920.0696626,
                        "modified": 1698834920.0696626,
                        "name": "/dev/shm/incoming/2023_11_01_103457_00_00_K_052.kml",
                        "sha256": "142d522ee0adb0790540e6bcf2f4c022e7a879d448fb17ab3bd9c674d228e07a",
                        "size": 976
                    },
                    {
                        "created": 1698834919.5896626,
                        "modified": 1698834919.5896626,
                        "name": "/dev/shm/incoming/2023_11_01_103457_00_00_K_052.jpg",
                        "sha256": "7f3634ea77a6e27efb030ac99c4612ee62447aab7cc3b3ce94d9d1c7629be521",
                        "size": 1018553
                    },
                    {
                        "created": 1698834902.6596625,
                        "modified": 1698834902.6596625,
                        "name": "/dev/shm/incoming/2023_11_01_103441_00_00_K_051.txt",
                        "sha256": "8d2b83400dd3406f3dedb5d87517a379608fc375c79b16bca0cf140c93f6f192",
                        "size": 2050
                    },
                    {
                        "created": 1698834917.3396626,
                        "modified": 1698834917.3396626,
                        "name": "/dev/shm/incoming/2023_11_01_103441_00_00_K_051.kml",
                        "sha256": "900492da278051d869565ab623bc4a33b8dd866259c11b138f4f6bf39f30dcfd",
                        "size": 979
                    },
                    {
                        "created": 1698834906.6296625,
                        "modified": 1698834906.6296625,
                        "name": "/dev/shm/incoming/2023_11_01_103441_00_00_K_051.jpg",
                        "sha256": "c382f82e83244494bc3e1a099d0eb029bc3f05f9acf0ca15e8faaf935af68159",
                        "size": 807592
                    },
                    {
                        "created": 1698834896.2696626,
                        "modified": 1698834896.2696626,
                        "name": "/dev/shm/incoming/2023_11_01_103437_00_00_K_050.txt",
                        "sha256": "b3b8b2238f915290983d77cbc6ab9c192c6f7adc7f5fc02e105686e8db09cea8",
                        "size": 2050
                    },
                    {
                        "created": 1698834896.0296626,
                        "modified": 1698834896.0296626,
                        "name": "/dev/shm/incoming/2023_11_01_103437_00_00_K_050.kml",
                        "sha256": "026d73a90e9c6322ff2a6fe2f69355acc6b0d8f92b8c0297137df4c02a24c0c5",
                        "size": 976
                    },
                    {
                        "created": 1698834895.4596627,
                        "modified": 1698834895.4596627,
                        "name": "/dev/shm/incoming/2023_11_01_103437_00_00_K_050.jpg",
                        "sha256": "34f2aeb1d6c38d51ec46b00aede8d9f57d2f0f173e438f8f5e969e54316b752b",
                        "size": 821016
                    },
                    {
                        "created": 1698834874.0796626,
                        "modified": 1698834874.0796626,
                        "name": "/dev/shm/incoming/2023_11_01_103359_00_00_K_049.txt",
                        "sha256": "3640d264d850b9fd8df02782e24a7e28ca045260abf5b09398fc142b04edd526",
                        "size": 2050
                    },
                    {
                        "created": 1698834871.7396626,
                        "modified": 1698834871.7396626,
                        "name": "/dev/shm/incoming/2023_11_01_103359_00_00_K_049.kml",
                        "sha256": "a453c4e6398bcf9e9ff9c5b10d0be3cd5d1e299bf67d2bfe63210c7373cf598d",
                        "size": 975
                    },
                    {
                        "created": 1698834871.5696626,
                        "modified": 1698834871.5696626,
                        "name": "/dev/shm/incoming/2023_11_01_103359_00_00_K_049.jpg",
                        "sha256": "59e0a928035133af30c8e14cc0b6876bdb0b004fae524a2aba2908ed87e9d2e9",
                        "size": 956296
                    },
                    {
                        "created": 1698834849.6496625,
                        "modified": 1698834849.6496625,
                        "name": "/dev/shm/incoming/2023_11_01_103348_00_00_K_048.txt",
                        "sha256": "ec73cd6d45d215295ffeb05e0417f8736b03731873b3e1ad5042a80c09b55f99",
                        "size": 2047
                    },
                    {
                        "created": 1698834857.6596625,
                        "modified": 1698834857.6596625,
                        "name": "/dev/shm/incoming/2023_11_01_103348_00_00_K_048.kml",
                        "sha256": "b35f2d9eded46a77480273139d190a12d16c18d05b03117bcadfa99fa67bfe12",
                        "size": 975
                    },
                    {
                        "created": 1698834848.6096625,
                        "modified": 1698834848.6096625,
                        "name": "/dev/shm/incoming/2023_11_01_103348_00_00_K_048.jpg",
                        "sha256": "9aebc7976c45cd7aa737b347fa466d8056ac221d1142f2095d08dd5499da8c65",
                        "size": 1021104
                    },
                    {
                        "created": 1698834847.7096627,
                        "modified": 1698834847.7096627,
                        "name": "/dev/shm/incoming/2023_11_01_103345_00_00_K_047.txt",
                        "sha256": "30310f6b42006ce0bc304cde874f28891b2249c3b6ed34866ce6c5456a2467c8",
                        "size": 2045
                    },
                    {
                        "created": 1698834847.5596626,
                        "modified": 1698834847.5596626,
                        "name": "/dev/shm/incoming/2023_11_01_103345_00_00_K_047.kml",
                        "sha256": "6cf624d8222b5c63a1082c68c14d94d47c2a4c9c44dd78310711354170cc4ee5",
                        "size": 978
                    },
                    {
                        "created": 1698834846.6696627,
                        "modified": 1698834846.6696627,
                        "name": "/dev/shm/incoming/2023_11_01_103345_00_00_K_047.jpg",
                        "sha256": "61580dfa2b256c58e2a3b0003eb6981255f340eae8553e46db40875ea79afdac",
                        "size": 1077066
                    },
                    {
                        "created": 1698834845.4996626,
                        "modified": 1698834845.4996626,
                        "name": "/dev/shm/incoming/2023_11_01_103342_00_00_K_046.txt",
                        "sha256": "2e51e5188fe7ed2ab62d7e2f8872e2d246b679c7e71c91fdb6898c8bc93e65c7",
                        "size": 2045
                    },
                    {
                        "created": 1698834844.7396626,
                        "modified": 1698834844.7396626,
                        "name": "/dev/shm/incoming/2023_11_01_103342_00_00_K_046.kml",
                        "sha256": "f145f62d492382699719601822de6ad17db9913d802e09b4d38bc06cafaa7bc8",
                        "size": 975
                    },
                    {
                        "created": 1698834845.6096625,
                        "modified": 1698834845.6096625,
                        "name": "/dev/shm/incoming/2023_11_01_103342_00_00_K_046.jpg",
                        "sha256": "1ce642d1ee99d22c494babe45de0d9ee7e9113613879ee7e6201790b8178361d",
                        "size": 1096791
                    },
                    {
                        "created": 1698834842.8296626,
                        "modified": 1698834842.8296626,
                        "name": "/dev/shm/incoming/2023_11_01_103338_00_00_K_045.txt",
                        "sha256": "dcb458082a8f9f8b38791798d3b3590e9a52d1ab4b17412d7df9415c452666e1",
                        "size": 2045
                    },
                    {
                        "created": 1698834843.4196627,
                        "modified": 1698834843.4196627,
                        "name": "/dev/shm/incoming/2023_11_01_103338_00_00_K_045.kml",
                        "sha256": "46c1e833346d6a8a5c7e68f0a8a3efb1fe369d74450121a44258eadfe6fc724e",
                        "size": 973
                    },
                    {
                        "created": 1698834842.1596625,
                        "modified": 1698834842.1596625,
                        "name": "/dev/shm/incoming/2023_11_01_103338_00_00_K_045.jpg",
                        "sha256": "f02eb0dac38ec3e129d8d823566086e2a1787d34a5cdbaeee431cc32a6963574",
                        "size": 1043639
                    },
                    {
                        "created": 1698834786.3096626,
                        "modified": 1698834786.3096626,
                        "name": "/dev/shm/incoming/2023_11_01_103240_00_00_K_044.txt",
                        "sha256": "cf2b963bbb320c0aa9d3e0ca207e37877204c8c1591459872c7d689b469f0142",
                        "size": 2048
                    },
                    {
                        "created": 1698834782.9796627,
                        "modified": 1698834782.9796627,
                        "name": "/dev/shm/incoming/2023_11_01_103240_00_00_K_044.kml",
                        "sha256": "e136d7486a5da8358cf2b2bdac618387684bad129eeeb7a4eff4a34d24936952",
                        "size": 974
                    },
                    {
                        "created": 1698834785.8396626,
                        "modified": 1698834785.8396626,
                        "name": "/dev/shm/incoming/2023_11_01_103240_00_00_K_044.jpg",
                        "sha256": "2936d3532cb8058d90b40c6336e53f75e63b5b314ab0a1fc9c35a80f6c1f6b82",
                        "size": 874063
                    },
                    {
                        "created": 1698834774.4396627,
                        "modified": 1698834774.4396627,
                        "name": "/dev/shm/incoming/2023_11_01_103223_00_00_K_043.txt",
                        "sha256": "daa1b5818cc35b5ec8cbcb7024460f7a846ab609e73a51436d85285f2b4e83f2",
                        "size": 2050
                    },
                    {
                        "created": 1698834767.5496626,
                        "modified": 1698834767.5496626,
                        "name": "/dev/shm/incoming/2023_11_01_103223_00_00_K_043.kml",
                        "sha256": "422ce4ac546759e5306b3c5be5a652d9811b9701d45c8748028c7e5dff917749",
                        "size": 975
                    },
                    {
                        "created": 1698834765.2796626,
                        "modified": 1698834765.2796626,
                        "name": "/dev/shm/incoming/2023_11_01_103223_00_00_K_043.jpg",
                        "sha256": "3f2f57993b3a72f65dd9f14a83c44e090f5ea530925df9cc5db837ae02adba0f",
                        "size": 1088088
                    },
                    {
                        "created": 1698834726.6696627,
                        "modified": 1698834726.6696627,
                        "name": "/dev/shm/incoming/2023_11_01_103127_00_00_K_042.txt",
                        "sha256": "3062e1c9383be5986191ab8764924157eb405a74741b5093d7ab3d405f2213e3",
                        "size": 2050
                    },
                    {
                        "created": 1698834708.2796626,
                        "modified": 1698834708.2796626,
                        "name": "/dev/shm/incoming/2023_11_01_103127_00_00_K_042.kml",
                        "sha256": "c75172f91492a4fbf94376a61e0a9b11ce71fa0559271868c705dc5653014b56",
                        "size": 977
                    },
                    {
                        "created": 1698834707.4496627,
                        "modified": 1698834707.4496627,
                        "name": "/dev/shm/incoming/2023_11_01_103127_00_00_K_042.jpg",
                        "sha256": "e0884088e0866618dbd3199b264d272c93ee7efdee4626831bc0e492a73dc3e1",
                        "size": 877128
                    },
                    {
                        "created": 1698834706.3796625,
                        "modified": 1698834706.3796625,
                        "name": "/dev/shm/incoming/2023_11_01_103124_00_00_K_041.txt",
                        "sha256": "2fd9033b2638f4b72d3cfbaa4e17494f3a3e0e6425c3cb5cdb176d3ba4335060",
                        "size": 2050
                    },
                    {
                        "created": 1698834706.2496626,
                        "modified": 1698834706.2496626,
                        "name": "/dev/shm/incoming/2023_11_01_103124_00_00_K_041.kml",
                        "sha256": "91db8e1aa50f934f0d57d57c324df8efcbc2b2c06c517aa3913a7d77e0a7df23",
                        "size": 977
                    },
                    {
                        "created": 1698834705.6896627,
                        "modified": 1698834705.6896627,
                        "name": "/dev/shm/incoming/2023_11_01_103124_00_00_K_041.jpg",
                        "sha256": "22452fd37db3a547e9cdc9517e214d32d523afb94689769cc382edf66abda330",
                        "size": 842003
                    },
                    {
                        "created": 1698834705.7896626,
                        "modified": 1698834705.7896626,
                        "name": "/dev/shm/incoming/2023_11_01_103121_00_00_K_040.txt",
                        "sha256": "8597bfdc16193b1568d7781a7ecb8ff5ab38ed415d7d04c94af067ff7b5d9811",
                        "size": 2050
                    },
                    {
                        "created": 1698834704.2996626,
                        "modified": 1698834704.2996626,
                        "name": "/dev/shm/incoming/2023_11_01_103121_00_00_K_040.kml",
                        "sha256": "2051f811459492580e1e94d69615a29807de0283f8bdfb1feb1c5a2731d7b349",
                        "size": 976
                    },
                    {
                        "created": 1698834704.0496626,
                        "modified": 1698834704.0496626,
                        "name": "/dev/shm/incoming/2023_11_01_103121_00_00_K_040.jpg",
                        "sha256": "782fa7d56e89a3b5b8199e777cfdc476dd08279e8f59b25d34ead8e96e061cd0",
                        "size": 803854
                    },
                    {
                        "created": 1698834689.8096626,
                        "modified": 1698834689.8096626,
                        "name": "/dev/shm/incoming/2023_11_01_103055_00_00_K_039.txt",
                        "sha256": "f3ff1fa9371b2997a076cf225caf70286588457a23462bd27661f43cde66e22b",
                        "size": 2050
                    },
                    {
                        "created": 1698834691.5696626,
                        "modified": 1698834691.5696626,
                        "name": "/dev/shm/incoming/2023_11_01_103055_00_00_K_039.kml",
                        "sha256": "70310490de09a5bd3d6522b874d4215f24232f5971546d1c86c296e8af4a2560",
                        "size": 976
                    },
                    {
                        "created": 1698834681.2996626,
                        "modified": 1698834681.2996626,
                        "name": "/dev/shm/incoming/2023_11_01_103055_00_00_K_039.jpg",
                        "sha256": "8a10e296b8b8aa8abf9830fe043d29b824b96c9e42734d879ae5828e51575fa1",
                        "size": 882963
                    },
                    {
                        "created": 1698834678.9896626,
                        "modified": 1698834678.9896626,
                        "name": "/dev/shm/incoming/2023-11-01T10:21:16.ais",
                        "sha256": "bf17aad140a146bad4304a3df8216acd22485515d307f320e496dce0949d5a6b",
                        "size": 501697
                    },
                    {
                        "created": 1698834669.9296627,
                        "modified": 1698834669.9296627,
                        "name": "/dev/shm/incoming/2023_11_01_103044_00_00_K_038.txt",
                        "sha256": "12b433a245d8cd94b9532e3569009f3e9d5f8034e9d3f5b0d582e4560fc2eb97",
                        "size": 2050
                    },
                    {
                        "created": 1698834671.9396627,
                        "modified": 1698834671.9396627,
                        "name": "/dev/shm/incoming/2023_11_01_103044_00_00_K_038.kml",
                        "sha256": "8904f365f7b74a961e2475d7222b5ae9664e8f1f5ff2e16db690e3fd6dfcbe3f",
                        "size": 977
                    },
                    {
                        "created": 1698834669.0196626,
                        "modified": 1698834669.0196626,
                        "name": "/dev/shm/incoming/2023_11_01_103044_00_00_K_038.jpg",
                        "sha256": "a8bfecf7a81d29b6191c05f8f8d6b4baa0de124ce6a0764c625bcf1dad0c7ce4",
                        "size": 1307928
                    },
                    {
                        "created": 1698834666.8696625,
                        "modified": 1698834666.8696625,
                        "name": "/dev/shm/incoming/2023_11_01_103041_00_00_K_037.txt",
                        "sha256": "c0116d6e2a77d92af092546bcede2e366e2978dcab6000c6720c0aa98b399d73",
                        "size": 2050
                    },
                    {
                        "created": 1698834668.8896625,
                        "modified": 1698834668.8896625,
                        "name": "/dev/shm/incoming/2023_11_01_103041_00_00_K_037.kml",
                        "sha256": "11a3bbb68e36db80264e7e0c2effae9969b695f5a09f788e6104063cfc6074a7",
                        "size": 980
                    },
                    {
                        "created": 1698834666.4096625,
                        "modified": 1698834666.4096625,
                        "name": "/dev/shm/incoming/2023_11_01_103041_00_00_K_037.jpg",
                        "sha256": "468b9b0a3a9a8263a15c66ca72bcb9ad02f36ee0bd558c745e7b062aaffeb8dc",
                        "size": 1295564
                    },
                    {
                        "created": 1698834664.1696627,
                        "modified": 1698834664.1696627,
                        "name": "/dev/shm/incoming/2023_11_01_103037_00_00_K_036.txt",
                        "sha256": "fd6815a4c844ea0a316d1237806c1e3d695dfad5f93b0eaf995dfc5f546d28ab",
                        "size": 2048
                    },
                    {
                        "created": 1698834661.8796625,
                        "modified": 1698834661.8796625,
                        "name": "/dev/shm/incoming/2023_11_01_103037_00_00_K_036.kml",
                        "sha256": "5c3c866052e1f15b9eeaeb6881f85a3aa4565e858baec03c557274acd316234c",
                        "size": 979
                    },
                    {
                        "created": 1698834663.6496625,
                        "modified": 1698834663.6496625,
                        "name": "/dev/shm/incoming/2023_11_01_103037_00_00_K_036.jpg",
                        "sha256": "81d3d825187975d2bb3b1506b7d2bcb04c9822570e75ffe03d39866863dce3cd",
                        "size": 1243842
                    },
                    {
                        "created": 1698834652.8196626,
                        "modified": 1698834652.8196626,
                        "name": "/dev/shm/incoming/2023_11_01_103033_00_00_K_035.txt",
                        "sha256": "f8708fddb381565d432f250c4e15af2aef30c669341ea9e60c0b07ef33b6ec25",
                        "size": 2050
                    },
                    {
                        "created": 1698834652.4596627,
                        "modified": 1698834652.4596627,
                        "name": "/dev/shm/incoming/2023_11_01_103033_00_00_K_035.kml",
                        "sha256": "1059a49ac8dcd493d0ff34c3d5c978cc65731b8e3ce50a1468055de60263c65f",
                        "size": 980
                    },
                    {
                        "created": 1698834652.1596625,
                        "modified": 1698834652.1596625,
                        "name": "/dev/shm/incoming/2023_11_01_103033_00_00_K_035.jpg",
                        "sha256": "0009702f202433f71a1d245ac608f18821b6b1e2beb1108284b67934df1add0a",
                        "size": 1130570
                    },
                    {
                        "created": 1698834597.0496626,
                        "modified": 1698834597.0496626,
                        "name": "/dev/shm/incoming/2023_11_01_102927_00_00_K_034.txt",
                        "sha256": "846a8dea22999875c9990806e4649997fbc51a46174a2feb58b8e3ac4f4f285b",
                        "size": 2050
                    },
                    {
                        "created": 1698834586.4796627,
                        "modified": 1698834586.4796627,
                        "name": "/dev/shm/incoming/2023_11_01_102927_00_00_K_034.kml",
                        "sha256": "a33a30a58f9893ec244f99e138e3ed46bc8ddfdeb4f388470ac9d33f750dbf6d",
                        "size": 978
                    },
                    {
                        "created": 1698834586.3996625,
                        "modified": 1698834586.3996625,
                        "name": "/dev/shm/incoming/2023_11_01_102927_00_00_K_034.jpg",
                        "sha256": "139e52551f2d1dc9a9f5bb72a2511c0114eb4a0761e4ffc64dde4f5b3f4b3b32",
                        "size": 1115596
                    },
                    {
                        "created": 1698834572.9496627,
                        "modified": 1698834572.9496627,
                        "name": "/dev/shm/incoming/2023_11_01_102915_00_00_K_033.txt",
                        "sha256": "ea135de844afb5cf4af19d130794f4da296fd1706b5a2618589e62a090f833ed",
                        "size": 2048
                    },
                    {
                        "created": 1698834582.3796625,
                        "modified": 1698834582.3796625,
                        "name": "/dev/shm/incoming/2023_11_01_102915_00_00_K_033.kml",
                        "sha256": "c6ace975a59399536292c8682dc9904903974b3679070200b0f75a56cbd468ff",
                        "size": 978
                    },
                    {
                        "created": 1698834576.8696625,
                        "modified": 1698834576.8696625,
                        "name": "/dev/shm/incoming/2023_11_01_102915_00_00_K_033.jpg",
                        "sha256": "920ab3c78d3f28503a7f9ee9ae5e1c104954efbbfab6dcb6a1e11809ded60f8b",
                        "size": 1080776
                    },
                    {
                        "created": 1698834520.8396626,
                        "modified": 1698834520.8396626,
                        "name": "/dev/shm/incoming/2023_11_01_102822_00_00_K_032.txt",
                        "sha256": "824e2b03e6128de1f7a89781af1e3d7fd287669e77c9691c0f4c5cc00cac3508",
                        "size": 2050
                    },
                    {
                        "created": 1698834521.5996625,
                        "modified": 1698834521.5996625,
                        "name": "/dev/shm/incoming/2023_11_01_102822_00_00_K_032.kml",
                        "sha256": "163425de71a6bd183cc7c5493f069abbe60aa81fbe8723729ebc1310baba9dd7",
                        "size": 978
                    },
                    {
                        "created": 1698834522.5096626,
                        "modified": 1698834522.5096626,
                        "name": "/dev/shm/incoming/2023_11_01_102822_00_00_K_032.jpg",
                        "sha256": "0c9d94fe47fa2f9b3dd161bdea12319d11f253ba51f3e2ccc1aa0cb62c2f2686",
                        "size": 1169296
                    },
                    {
                        "created": 1698834522.5296626,
                        "modified": 1698834522.5296626,
                        "name": "/dev/shm/incoming/2023_11_01_102818_00_00_K_031.txt",
                        "sha256": "963742e9ea5917a6e81a5378cf1ce884e4065b257c1e450d67ef91d0ecec95eb",
                        "size": 2050
                    },
                    {
                        "created": 1698834516.0396626,
                        "modified": 1698834516.0396626,
                        "name": "/dev/shm/incoming/2023_11_01_102818_00_00_K_031.kml",
                        "sha256": "a9bd609c039e48d10e88d0c0ee85fbd5f64b8fc568d2961311823c903bb86046",
                        "size": 978
                    },
                    {
                        "created": 1698834515.0896626,
                        "modified": 1698834515.0896626,
                        "name": "/dev/shm/incoming/2023_11_01_102818_00_00_K_031.jpg",
                        "sha256": "21cdf60abdbfe4ed7cd0a959b16b6946f4868fb9300f2b4ee76a4f30f6241990",
                        "size": 1006596
                    },
                    {
                        "created": 1698834460.6596625,
                        "modified": 1698834460.6596625,
                        "name": "/dev/shm/incoming/2023_11_01_102727_00_00_K_030.txt",
                        "sha256": "f47e2317f2135815ff77f76e0d04bf83a3112e9df6f2187341240f33a67307d0",
                        "size": 2046
                    },
                    {
                        "created": 1698834461.3196626,
                        "modified": 1698834461.3196626,
                        "name": "/dev/shm/incoming/2023_11_01_102727_00_00_K_030.kml",
                        "sha256": "0a939b65e70c36bec4ab68120750d708fe988eb0aaf89b190b7450434dd8a26d",
                        "size": 972
                    },
                    {
                        "created": 1698834460.9696627,
                        "modified": 1698834460.9696627,
                        "name": "/dev/shm/incoming/2023_11_01_102727_00_00_K_030.jpg",
                        "sha256": "b9173ec63c4f1061c5ab743b8c65f0c8ed8cc6472987d013e9184c4ec10c0363",
                        "size": 819326
                    },
                    {
                        "created": 1698834455.2196627,
                        "modified": 1698834455.2196627,
                        "name": "/dev/shm/incoming/2023_11_01_102656_00_00_K_029.txt",
                        "sha256": "758127cbaef70c21a9d995c099bd49c5c73cd2f6330f4c9b0e59e062be7c3243",
                        "size": 2048
                    },
                    {
                        "created": 1698834439.8296626,
                        "modified": 1698834439.8296626,
                        "name": "/dev/shm/incoming/2023_11_01_102656_00_00_K_029.kml",
                        "sha256": "230b6a21a13489db4c07a7da3da4095d351cdefd24c9a8d6ee71606c744e9b0d",
                        "size": 974
                    },
                    {
                        "created": 1698834436.7896626,
                        "modified": 1698834436.7896626,
                        "name": "/dev/shm/incoming/2023_11_01_102656_00_00_K_029.jpg",
                        "sha256": "9c3af12b5a64e7193cc3a26167403350660a9594985fcab8eac59df80f82a2c8",
                        "size": 969617
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "67013642f3f9d909ac1a0852bf4017fe5d9d8fdb07dc1c261cc373cac57a0f7c",
                "size": 25683593
            },
            "errors": "",
            "filename": "2023-11-01T10-37-30Z",
            "size": 25683593,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698835850,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "ULbjUjHrDD99hUwesAqfqS2SV3/jJqkeAssPcSBUTAx3N2mubfoHPwcOvJB4Z0afhLAKQ7OTOrnW3+Av8K9hMIazvObZ9Eo1sUkk9Gxzy3+1mYYxRCl13UEnKARbs9iDnqtEkCth0n4hostIyr7I1ZOgtLuFv+EugG/T20D913QUJstyf39tOEgNX4RQ+8dmHAyUTXhOiwA+i9fYBLLpW49HI7LN5PouJ0HayHjzxh7tialU9vc7Bkqg2HBm4dMC0NVkHY8LSQ792iqOXueBlFGIeZCsWzmk/joQv3aRhAfmUMO8Skrb/0ZnhK/qtwXp3yUYaHkVxsvEsB/c0xjsylSBmXIhpTh6PlzU4NkWHo9qqofQ5FMXRzNpe+07ny90T6wjNqCMEntjGSqU3eM18OzsFYqJ1fZcWvsDRqKC1IWYq3Pl9//6070VIgUeST7NdwPcPGR2Ii7y/DV89ipByQ9f7J2PZlbCnZ/xsYWmVoZ7dZJbqMvOpyLPSre1DecX1j/fRwlGp+HxprrbGW5nl2KibpRAZo/JXEc8oI/jdsR/MbsC194eHH0K7HwmC1oAdHjF6ZkZ9bcQEBrj7LI33+BgpYJeTGAWqR0uTrnmj+mimImVCyVZxvw+UY1IrH+ylELpgHjSy3Ur0oGR0RpUDEIdK+8eoTIPM3aSV8Ng1rI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-49-19Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698835751.0485454,
                        "modified": 1698835751.0485454,
                        "name": "/dev/shm/incoming/2023-11-01T10:19:10.ts",
                        "sha256": "ae4614a02b751b531e09ee68c0f03e339c5e8510b85f7c4b15b671d3b23d2cb3",
                        "size": 421979348
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "c7751e10cc56aafb2979f3593214d635e5499f70b5f2137497a2e319fbbbc986",
                "size": 395370933
            },
            "errors": "",
            "filename": "2023-11-01T10-49-19Z",
            "size": 395370933,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698834410,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "lpj2HLL4Bs9MgxE1IXLgfZpKiKTP3uMMDyJTLFQTZ8KwFwdZ+kia6Rw3WFeeAoASY42JCLKD4CMNa9MXPQnujo3rcXOnvdizUdhjSceBhnB1VTc7TstGShfOZqA36Mu4EteME4N5vCCKnuhzmGt0C3kVZNUqCDDZwCrb6a2YkXQyOFSWC6MRdPbZX0OXr0Yc/9gMp4PEy8sTEAJF9T8XgGIu6/k8gg/otPzQFSKRht/J3vZ333OsOGK9HXv4Q6C72P7xVAj5P6/2teMB7KReGyrz0QQI5v0s69A+VqwusnDLeDAeAfucS0WJg/wyGq0Dja4U2D7cwP/49GVG5bLtrXdNBn0YrmZyulDBns5cUiOT+7XObTCfvIueRL5+G4gCQJzC7vUW0M4KqwD29ri7q4bgmVj3BkKUAJPAEGnETr/fgRR4PvfwDiGi8whHSQJaw5qrCdEhrHmWBcpEkeHEsZ5buR1Zx7tsEB88XMvc6+OW60AonPyF7//ENAXfvCQ0j7aHEMmcYwAoBY8+rYFEjWFN5DNnLBwhGeRUptlVwhOOnfpShNN7DZGmq9yAuQNtdRpruTIttE+kfCLCLKOSbZ5i8IYRXLm1eqgNzTKsKvi7FmuFf1R1GApfLEvgfBOusJ/vH9dalM/4qFt2qik+lxBG/K+KdE/YdpDAAiSjjVE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-26-40Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698834276.4627697,
                        "modified": 1698834276.4627697,
                        "name": "/dev/shm/incoming/2023-11-01T10:14:35.ais",
                        "sha256": "0aa78875583a878b42dd114d6c2c749966724c9f7706ef888eb8f446e7e13a69",
                        "size": 119508
                    },
                    {
                        "created": 1698834167.1327698,
                        "modified": 1698834167.1327698,
                        "name": "/dev/shm/incoming/2023_11_01_102222_00_00_V_013.txt",
                        "sha256": "cb31506d66320442cf5cd3d521df8b5a9e91ca5bff6d55d5316d924d5412ca78",
                        "size": 2052
                    },
                    {
                        "created": 1698834167.7527697,
                        "modified": 1698834167.7527697,
                        "name": "/dev/shm/incoming/2023_11_01_102222_00_00_V_013.kml",
                        "sha256": "5f9b799806d5640055876b3bd3958b03ae8472e0fea5afb41d1abd7bc4ce5d6a",
                        "size": 984
                    },
                    {
                        "created": 1698834165.2027698,
                        "modified": 1698834165.2027698,
                        "name": "/dev/shm/incoming/2023_11_01_102222_00_00_V_013.jpg",
                        "sha256": "1c69cee814a2b10a4788bcc2e54a6c3c58d58517bc6eb7a127ffed66847b6344",
                        "size": 1260415
                    },
                    {
                        "created": 1698834153.9027698,
                        "modified": 1698834153.9027698,
                        "name": "/dev/shm/incoming/2023_11_01_102218_00_00_V_012.txt",
                        "sha256": "145b19f49e9c01cbc9aed90ab49da6e0be011450095689fddabe6e755ed33efd",
                        "size": 2051
                    },
                    {
                        "created": 1698834156.7527697,
                        "modified": 1698834156.7527697,
                        "name": "/dev/shm/incoming/2023_11_01_102218_00_00_V_012.kml",
                        "sha256": "b76a004027a9fa62244cc165dbd366a1d4c9d26f3d72221ba972852e19adc7c1",
                        "size": 984
                    },
                    {
                        "created": 1698834153.1527698,
                        "modified": 1698834153.1527698,
                        "name": "/dev/shm/incoming/2023_11_01_102218_00_00_V_012.jpg",
                        "sha256": "5c03eebe80ae5506f889b0fff31eaa173b6476ee53aa2a141da4d6fcbbb36a6e",
                        "size": 1289210
                    }
                ],
                "mission": "EFCA1",
                "sha256": "127437a35484e7aabcc5359b4c3f5e37971bcdcb9081f02a196df026c16f16b6",
                "size": 2575490
            },
            "errors": "",
            "filename": "2023-11-01T10-26-40Z",
            "size": 2575490,
            "verified_at": 1698848173
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698834817,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "r4cYmLlSQrClygyk7rtevUg3n8NVc/Bf2zDykVokRnFPZtk8wRl1Uf+JRv7TkEuAH/qNv/zkFEnOINnWFFGe7YhieRDLLCILzemzktkPheRLwXco+d1w4wW5YLvObG1LoW1CBkQerF1bEziOdtbEY9/Vl+YHw/XUdrDLaje1hbvxZHAqHUHIiqvpNFnogX6ADMsq+avkkW4VZt87BPQXySBXsEeHjn0kxjsHoVKmS54kgRoxIGeSd51xN3wxyx8GyG0boggabkgI4H0SlINy5nXUYfmXJW2WQaQtBP4qe661NCUEP3Tukn9ogtRtT94mwIPVq4wRjXWWrbDT/e3W6SEeQFWKISOedFAnsmhD8Vm+rs4R0dTFf21wFwTIwVHUZTZFPJROo75Qyg1ZOcn7oqSf7kDIB5juHiuHiuzkkqqCYh35A3diqFHcn2gtfm68dukFaSxjl7kAhj4WjLbPJg77m/FM1MRYMTWgKaMLvHDnNeFNqzVNPY4dDa8vlU+yRE/K7Z6hc9IyuMzIUxM0jJwykkuwJ/2V9sUzIsNJWwDvA/JhyFcZLl9aDTfzUKQDEHzopdY7u7T6lD4b7xWrIi15dW/7LAVfXPO5gFsakErDzjW0oc8q99BUR/tKPqnhB6/CqRa2ZI0Ozxyu/lrv7UsihAXdW2owsrEe8awETic=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-33-26Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698834683.2427697,
                        "modified": 1698834683.2427697,
                        "name": "/dev/shm/incoming/2023_11_01_103055_00_00_V_019.txt",
                        "sha256": "d420064218619c01ac4bfb7d13e02c91cbbdf4ec6f6af75bbd606fda72602afb",
                        "size": 2052
                    },
                    {
                        "created": 1698834684.8827698,
                        "modified": 1698834684.8827698,
                        "name": "/dev/shm/incoming/2023_11_01_103055_00_00_V_019.kml",
                        "sha256": "ce49cfae3af08ef3fd9f8971e31e973ff1197a67a4a6e12c5496387cba81095e",
                        "size": 982
                    },
                    {
                        "created": 1698834679.2527697,
                        "modified": 1698834679.2527697,
                        "name": "/dev/shm/incoming/2023_11_01_103055_00_00_V_019.jpg",
                        "sha256": "edf50073dbb0bf422e639f6eca43b29db2e647b634a373d61397500bdd06ee8a",
                        "size": 948428
                    },
                    {
                        "created": 1698834676.2627697,
                        "modified": 1698834676.2627697,
                        "name": "/dev/shm/incoming/2023_11_01_103053_00_00_V_018.txt",
                        "sha256": "947cd03d099ca03ae4878262c6909433072bd80459374109c554707a8cbfce12",
                        "size": 2052
                    },
                    {
                        "created": 1698834676.0127697,
                        "modified": 1698834676.0127697,
                        "name": "/dev/shm/incoming/2023_11_01_103053_00_00_V_018.kml",
                        "sha256": "9e49264353770eb70aed42b3d2024e647e775e089f8c8b522c693fff433ece19",
                        "size": 983
                    },
                    {
                        "created": 1698834675.2227697,
                        "modified": 1698834675.2227697,
                        "name": "/dev/shm/incoming/2023_11_01_103053_00_00_V_018.jpg",
                        "sha256": "2dff9180928d20c7c93594c0db1b7431fbc63a702de812808fdc2319439049a7",
                        "size": 1015755
                    },
                    {
                        "created": 1698834665.8527699,
                        "modified": 1698834665.8527699,
                        "name": "/dev/shm/incoming/2023_11_01_103049_00_00_V_017.txt",
                        "sha256": "22ca018e8353df02a20f297ff0285a9c0a328d45fcf59a6c9bc51219b7387c2d",
                        "size": 2051
                    },
                    {
                        "created": 1698834668.8327699,
                        "modified": 1698834668.8327699,
                        "name": "/dev/shm/incoming/2023_11_01_103049_00_00_V_017.kml",
                        "sha256": "02f330e6a2eb8dbe3880afd0f00fdc30f86e45094693dce56f983138ccd33fc2",
                        "size": 983
                    },
                    {
                        "created": 1698834662.5927699,
                        "modified": 1698834662.5927699,
                        "name": "/dev/shm/incoming/2023_11_01_103049_00_00_V_017.jpg",
                        "sha256": "4a94871ba8caf01ba3722f25ddffe38aa8f6136dcf828f3f02fc6d887885eeb9",
                        "size": 1039116
                    },
                    {
                        "created": 1698834608.5827699,
                        "modified": 1698834608.5827699,
                        "name": "/dev/shm/incoming/2023_11_01_102950_00_00_V_016.txt",
                        "sha256": "ee9b5518ef77a0767a16992551b1d29c385071da153b947df5350b7f28263373",
                        "size": 2051
                    },
                    {
                        "created": 1698834608.3627698,
                        "modified": 1698834608.3627698,
                        "name": "/dev/shm/incoming/2023_11_01_102950_00_00_V_016.kml",
                        "sha256": "8ae28d37036732312653c48c70f66b3064984f1952833d0b635b154333d7d8c7",
                        "size": 982
                    },
                    {
                        "created": 1698834614.55277,
                        "modified": 1698834614.55277,
                        "name": "/dev/shm/incoming/2023_11_01_102950_00_00_V_016.jpg",
                        "sha256": "cc49880f9e57d92866aa482079c2608070e59dedaa5bbcd50f1a6618f281d4c0",
                        "size": 1035788
                    },
                    {
                        "created": 1698834607.3227699,
                        "modified": 1698834607.3227699,
                        "name": "/dev/shm/incoming/2023_11_01_102749_00_00_V_015.txt",
                        "sha256": "159e3dd621181860e437b49019ee85f6cbccd78df751a4eb20b258ad4b163839",
                        "size": 2054
                    },
                    {
                        "created": 1698834486.80277,
                        "modified": 1698834486.80277,
                        "name": "/dev/shm/incoming/2023_11_01_102749_00_00_V_015.kml",
                        "sha256": "46682ca0a63b5edbb781df9360738efcd5d0e85a5d5f9d5c56371d323e2a11f9",
                        "size": 981
                    },
                    {
                        "created": 1698834486.4327698,
                        "modified": 1698834486.4327698,
                        "name": "/dev/shm/incoming/2023_11_01_102749_00_00_V_015.jpg",
                        "sha256": "bfbb14a6fa7046ebd68a034223eb721aa1da5ef7792158b2d380a3362595d499",
                        "size": 1097520
                    },
                    {
                        "created": 1698834485.7627697,
                        "modified": 1698834485.7627697,
                        "name": "/dev/shm/incoming/2023_11_01_102745_00_00_V_014.txt",
                        "sha256": "88023af91b0ac3e53156acc5c353d07991ec007d6614fc1ce97d4d4a364b32ca",
                        "size": 2054
                    },
                    {
                        "created": 1698834484.3527699,
                        "modified": 1698834484.3527699,
                        "name": "/dev/shm/incoming/2023_11_01_102745_00_00_V_014.kml",
                        "sha256": "320acc9e42da004d98411ec929b7709e9866e0065f18da91d9f411a7705e8919",
                        "size": 980
                    },
                    {
                        "created": 1698834483.6527698,
                        "modified": 1698834483.6527698,
                        "name": "/dev/shm/incoming/2023_11_01_102745_00_00_V_014.jpg",
                        "sha256": "1e666abf09c977daaa7061fb68081082172cb48152aaae31492f3565a5cab281",
                        "size": 1141527
                    }
                ],
                "mission": "EFCA1",
                "sha256": "20c2597e5205c4a7df7df76b28de3c98bfbeccb5d17fdf5be7f8cc71d1ac84f4",
                "size": 6284459
            },
            "errors": "",
            "filename": "2023-11-01T10-33-26Z",
            "size": 6284459,
            "verified_at": 1698848217
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698834176,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "sE0cs9ncpN4iB88DhXbgOk8oh/c/8DpkjCz+0G7gPl+MnSBq4LM+lsy9w11wmjDVdSZHVDvUofj9196WqVKzsCdqtIhK7BpmMHmZ/pWDh6gUQx0rZBf4ETePqfp1xbRKgQSekGjOI7e86bqxoh6WYei77/AMUe1E6fEel8dQPRC+hRcZbVEwTyE1zV7vGKWMNTGdg/cZ4usLAfy4PViBID5ALCtjbRnBZXtJKcjwHldLAl3vZyclabjL07HIiuubwYxyPZu+NifjeL7fma/SjlqwkUM3wRqUeJcuAUHSQtLQ2EKMJq+RuzVYPybUgReI3Sk4nwG9jb0iL35ctXt3+W3W9FYR0PEgiqFuLWQjlLTxlvMtFRtU2hvDCfJeaHEVWu9ZMZLRds4ERKSlpeuudpfl+O+jcOzmNYPKGMcRkXhXFvBJz+TRPTP0tkSxNJcF4alFkcaQGcNgrIL0NvHhvKw1aMNX3o5MWx/1nAa4Hpg2G10HQEM05JpzurHGgQanxV7ejYxZ8D92AnX4dH7afZ/S8TQxt9cgVzc0KxjUcpIfYSsjr8mqtxUhQzkDuRM9QTeag58q1IFVV0FEPtNWyk3+k6ObIA2/o99f1h00ILl+NIzWmpSxIqs5UdMDYRBIwepF0AD91T0DVgJdN7YcGAaNTlBDrFzQDobfwwPD8ro=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-22-03Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698834098.54277,
                        "modified": 1698834098.54277,
                        "name": "/dev/shm/incoming/2023_11_01_102120_00_00_V_011.txt",
                        "sha256": "4267d972b2b6342ecea8ec01a4a606e7257751984b06333e7e4940d970de2523",
                        "size": 2051
                    },
                    {
                        "created": 1698834097.9427698,
                        "modified": 1698834097.9427698,
                        "name": "/dev/shm/incoming/2023_11_01_102120_00_00_V_011.kml",
                        "sha256": "3dd70260ceed16f24bc21d1c2c7058a90b380026e654418893342df5516f149e",
                        "size": 983
                    },
                    {
                        "created": 1698834097.3327699,
                        "modified": 1698834097.3327699,
                        "name": "/dev/shm/incoming/2023_11_01_102120_00_00_V_011.jpg",
                        "sha256": "08fb2dfcc70062d9cb6a14a02054aa784eec4c2284c50f3fe14a60711c56d64d",
                        "size": 994186
                    },
                    {
                        "created": 1698834085.7027698,
                        "modified": 1698834085.7027698,
                        "name": "/dev/shm/incoming/2023_11_01_102108_00_00_V_010.txt",
                        "sha256": "e218bfc2e0c335695058f975455e534576c5a29e420be1d1256604746e0388de",
                        "size": 2046
                    },
                    {
                        "created": 1698834085.4727697,
                        "modified": 1698834085.4727697,
                        "name": "/dev/shm/incoming/2023_11_01_102108_00_00_V_010.kml",
                        "sha256": "64148c1ebbc3082e2690eda80eccd4b855a5b46ed63f3c3055a0d4fe4517c881",
                        "size": 982
                    },
                    {
                        "created": 1698834086.0927699,
                        "modified": 1698834086.0927699,
                        "name": "/dev/shm/incoming/2023_11_01_102108_00_00_V_010.jpg",
                        "sha256": "1697665719ab14d68a038bb6ea3faef633d76714f869dd1dada1edc8e3baa211",
                        "size": 1037887
                    },
                    {
                        "created": 1698834021.0827699,
                        "modified": 1698834021.0827699,
                        "name": "/dev/shm/incoming/2023_11_01_101957_00_00_V_009.txt",
                        "sha256": "01ad382995d78d9f490a5a3cb0fc0518fbae0e564aa4a6de73a682001216bc69",
                        "size": 2047
                    },
                    {
                        "created": 1698834016.55277,
                        "modified": 1698834016.55277,
                        "name": "/dev/shm/incoming/2023_11_01_101957_00_00_V_009.kml",
                        "sha256": "e776c38a0a18dec92094bcc4a57414c2e996b9c85729956263380ce62adf58d8",
                        "size": 985
                    },
                    {
                        "created": 1698834015.7027698,
                        "modified": 1698834015.7027698,
                        "name": "/dev/shm/incoming/2023_11_01_101957_00_00_V_009.jpg",
                        "sha256": "e55e812e3328b10ba1dab4caa48523cc11648a05359d389df6ae4bb638505c32",
                        "size": 910216
                    },
                    {
                        "created": 1698833994.9927697,
                        "modified": 1698833994.9927697,
                        "name": "/dev/shm/incoming/2023_11_01_101937_00_00_V_008.txt",
                        "sha256": "1323d79e3b787523168c2cd2d8df7d6fea8abccb2d76d990309ee7516222af5d",
                        "size": 2044
                    },
                    {
                        "created": 1698833992.54277,
                        "modified": 1698833992.54277,
                        "name": "/dev/shm/incoming/2023_11_01_101937_00_00_V_008.kml",
                        "sha256": "b14f60350156e6d4129a08a2a8f7b2912fbecfb8eeccc381939f5a3abb7efc17",
                        "size": 986
                    },
                    {
                        "created": 1698833992.1227698,
                        "modified": 1698833992.1227698,
                        "name": "/dev/shm/incoming/2023_11_01_101937_00_00_V_008.jpg",
                        "sha256": "cf42096e3fd9a1ec6b133566900d48b9781e7f6ffe8da179405fa0ae67b11d3b",
                        "size": 892929
                    },
                    {
                        "created": 1698833971.03277,
                        "modified": 1698833971.03277,
                        "name": "/dev/shm/incoming/2023_11_01_101910_00_00_V_007.txt",
                        "sha256": "d9cac7817978e2ee98795a407d2b0acb7d3c4c4fd18b82ef9a65370f71a07682",
                        "size": 2048
                    },
                    {
                        "created": 1698833972.80277,
                        "modified": 1698833972.80277,
                        "name": "/dev/shm/incoming/2023_11_01_101910_00_00_V_007.kml",
                        "sha256": "24c3eda9c3df5b91866d227e90efb04a7c3d9c08fcb68d6e6f798f2d7a458e38",
                        "size": 983
                    },
                    {
                        "created": 1698833969.1127698,
                        "modified": 1698833969.1127698,
                        "name": "/dev/shm/incoming/2023_11_01_101910_00_00_V_007.jpg",
                        "sha256": "6984e51ef6f4b19be77094fce4fded95a847d43c58ecd7cfbbaef3509dd474d0",
                        "size": 881802
                    },
                    {
                        "created": 1698833949.5127697,
                        "modified": 1698833949.5127697,
                        "name": "/dev/shm/incoming/2023_11_01_101848_00_00_V_006.txt",
                        "sha256": "e131bdcde1f76735d7f1ddf657b8c087f121d783f054e7f04b418341c32b5c86",
                        "size": 2051
                    },
                    {
                        "created": 1698833948.2027698,
                        "modified": 1698833948.2027698,
                        "name": "/dev/shm/incoming/2023_11_01_101848_00_00_V_006.kml",
                        "sha256": "265e4c574c359934ef69a99aa873b047240bc63181b501b47d254bd5cf620cd5",
                        "size": 980
                    },
                    {
                        "created": 1698833948.1127698,
                        "modified": 1698833948.1127698,
                        "name": "/dev/shm/incoming/2023_11_01_101848_00_00_V_006.jpg",
                        "sha256": "e05c95809a0c64e0d6f100a928f8a0af8d2a1bc8589cff0f4942f6767ed65989",
                        "size": 1140335
                    },
                    {
                        "created": 1698833916.1027699,
                        "modified": 1698833916.1027699,
                        "name": "/dev/shm/incoming/2023_11_01_101816_00_00_V_005.txt",
                        "sha256": "a2e3a3f506bffa34a59e29f15d1d906b01f614f44f5539f00839ddeacbfb5fe7",
                        "size": 1547
                    },
                    {
                        "created": 1698833913.1227698,
                        "modified": 1698833913.1227698,
                        "name": "/dev/shm/incoming/2023_11_01_101816_00_00_V_005.kml",
                        "sha256": "16257931e9bcc22c5bec00aad98c7f38b3f682d5c1b8c11f969a7a4fca14bb7a",
                        "size": 943
                    },
                    {
                        "created": 1698833912.5827699,
                        "modified": 1698833912.5827699,
                        "name": "/dev/shm/incoming/2023_11_01_101816_00_00_V_005.jpg",
                        "sha256": "a16d4d63c45ea2775db08eeb0207b72abe949cd88b6def6a4b5585d2cc0cc504",
                        "size": 1139351
                    },
                    {
                        "created": 1698833910.7627697,
                        "modified": 1698833910.7627697,
                        "name": "/dev/shm/incoming/2023_11_01_101810_00_00_V_004.txt",
                        "sha256": "f790241d374ba07ae6234adb866dbd42fc762a909fa6e37032ae97e569ea6f82",
                        "size": 1549
                    },
                    {
                        "created": 1698833910.4027698,
                        "modified": 1698833910.4027698,
                        "name": "/dev/shm/incoming/2023_11_01_101810_00_00_V_004.kml",
                        "sha256": "f66d0c2e10fbf7490a4c0111ad110b1bca863eb2f583aeab9c64a7a7de08d1c3",
                        "size": 941
                    },
                    {
                        "created": 1698833910.05277,
                        "modified": 1698833910.05277,
                        "name": "/dev/shm/incoming/2023_11_01_101810_00_00_V_004.jpg",
                        "sha256": "2ff19d5bf6024195354291ef1a2eee63656c2339dbf3a9005769fa118effbb93",
                        "size": 955208
                    },
                    {
                        "created": 1698834117.4727697,
                        "modified": 1698834117.4727697,
                        "name": "/dev/shm/incoming/2023-11-01T09:51:57.ts",
                        "sha256": "1e14038e2f2a17e50322f263869b168f91120970c2d4743837e1023459d22972",
                        "size": 409821388
                    }
                ],
                "mission": "EFCA1",
                "sha256": "7bf4be2f8718176d092a38c6fc7481409a1ffa34120cb84e401a6fc20d9ebbfe",
                "size": 403372869
            },
            "errors": "",
            "filename": "2023-11-01T10-22-03Z",
            "size": 403372869,
            "verified_at": 1698848298
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698835462,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "Ea1I5vfEOjmPZllVqbh6QTTST6MEpEhUxY7Gv8IMJczTMHNhtFwWIj2DCo6sWknEJxoaavCl/SofadpkMftA22d9tszc+a6BvNFG0QwzaNAMk61vYR9wxx0v6/2aeLAwEIuC303mkKkLvTL3/wAKJmCNf2MXOj6vNbWtcPOnALULcpgrJJQH2/li5DWaRsF8sXoRVzFUexWuFCms/UmpM6rah9IE4zpPJUMGPU76GFuzutAf1Xsl7cA9qT3+QWDpV1Ev+eJlfnks+0UQ493UfEWOg7XmzuRM8MvmiBktZYt+WjPHazP9DdvIJ1/x25TyYkbikn3hBjLuJUhoPYUokDkeEJaXlnDFMsAG9uCFJPQPrfEnVZi6ch3lmzL15B3pirU9R2fCIMZieaAL5pay5v0tkLlSt5eTS5qWRf7VYV9BW9PvX7WOBz4SNmwPsz0K2GwLWNz1Olt5ojLIPIh3PqrO+KxEI57lSPKYWHqnVn2Q7G3U13uxVN+4pfcsuGlf+UKhPzRDMLJyZTxHehVNJNfba6PMOkRhxzPZlNio5p8k3aOAaNL7/97Fpj7sk4QlU8m6/KrQJ3nDO9Jv9Ymq/PGwiB3Qrvd37ftOHapJ/B8Fwn9/p2zCLrAzS6zaPSgFu5o69xkUWHfRhkWvJbBUie9sYOdJpJq1rRJFAxBfjik=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-43-24Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698835279.5996625,
                        "modified": 1698835279.5996625,
                        "name": "/dev/shm/incoming/2023-11-01T10:31:18.ais",
                        "sha256": "62fe876581a00c37b6b7763c240e0420147a32dc55c6c7c92f77130387d18c04",
                        "size": 508516
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "82b9a4faed3379d8ae6880f189bb97d3453437c3c39b51ffe80a1ebe6dfa656e",
                "size": 156850
            },
            "errors": "",
            "filename": "2023-11-01T10-43-24Z",
            "size": 156850,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698835512,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "uVwxwefiZjFn9BppHP5n+XUtyhLqB/Pzy1chUfMG/6ao4Y2FzrFuKj3mw96hRjOpgDvMvTiGthuBbViOZFmElgOeEi+yJjTpaS3Mqytq8+uRCZitluJOMpHTwWDRvSst31/bsq/dK011Nb977CCcFHsTvFfGaRvZlpcjLPvlxaGLx0PWVKUBQc+T1jRbumDlOzCIpVXd0L3bwLF7r294qN62mgeQrxSKP5vxAa42Ie9pwxM7I1S9YrxM/+/1XwigBfMxpNh/AvjBM9XOmjPBVnJxtpHZnV+6S798bBkRn1l+kDC21ryTLaOm84Hq5QyhnQZxB9kZ3VSrDOj+lWwk5nU5sZ9/uianPVPNbe/xwILAhxUGzmzK7hP9aQ2mQIcaNc+ZZmy0xpyXblv4BDkTARMsLyJAumDT7Xz2Vg0Bxe5SaGC1W9fmem2TVxs4j4UCI9qF6LYUcVF0SeF11czRYxnL9IM54/YXcBzPOrBEstqcLJ9ckhJgISOg8nj0G36Pp6o1riLC/pkKzqChjAP+Clp6nFll1XRAbwZlwviRk5tUYsPw40znz6aSr9z0j6eE4DMU84SqkzFuecnZFzBykT9B/lesTQ90ca7M5mM8JdeNXwXX6X7m88yPOQpYlyYXtD2MkIxUi8vjuDDV0dL/bmg1QVjG5P1PmUZfbUmsq7E=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-43-46Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698835415.4896626,
                        "modified": 1698835415.4896626,
                        "name": "/dev/shm/incoming/2023-11-01T10:13:35.ts",
                        "sha256": "05d1ed352721b4676d403823ea713fa8470f500e1fce96b45df85a2a803edc3e",
                        "size": 442813132
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "cee07b85e5ef4a027375c0da9e021b6c3aefd54bd0c4f57f270d832460e63758",
                "size": 398655915
            },
            "errors": "",
            "filename": "2023-11-01T10-43-46Z",
            "size": 398655915,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698835594,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "D0MKTRaTLxANfFsZqFFlHUtjifBqAoeGsqeMpmpLCtQv4SwB5qTxpBqjAMy/S1qeoZAxP72YHVbfoMihDhmK8JEG+itEF/CqbUhRzmAQ05+jLJsQ9X1JStaUzvkGy7xNg6hzTuCXG8NdmVlR5QhzVVrDFG8PUn+PbUamvWYY4UBac+qdiBDDXbR1bFNnM7aX45XXOeiAjMy3yoJDMTdgcyjTmIO8+qyd0Q9ahrFXEX/txcl0+0IDhYi2/yJ3tse/labW5WU2bRPU99qiAsfEeWbv4AMbVKj05iBzpTbJDRONWMrmAVmcjZD0RjAbg28A8LDBnTBtTDsCXJbFrclIIp3QmSjz/R3dMyvd6Sviq4p/p6FMYxL/jVakE/+zar1jTpNxjAGr4ndGcO7C0XTMoxJJgOb4dtw1ewKwf0MpG0JVeULO8F/aOunk5noI7EDTgZsyj9zy5gB6HcC36f3bXkas6JvLlvh49iMOTUnCPfmNdoR7UYmImmpBvYg66mPJuzIJnB7gu1hInHk+oSit9WsCZAYFdIfTFuQgO4GZAMGYgpU52kgCFrClYQpRp0QbH2hxhxcnT8GP4n16c7iX3ipLvE0T9E1mOXCVkfpgNQJsPaBQdp+5zZhNqrQSHXISO4dNhlQUZOiZi3Kr3dbz1xWIyBWK84nTO5PT7YJO+BA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-46-09Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698835441.7185454,
                        "modified": 1698835441.7185454,
                        "name": "/dev/shm/incoming/2023-11-01T10:34:00.ais",
                        "sha256": "4665dd52f2f234cda2f47366b28e3aaa62a303dbcb21cafc6264c4f023f0764e",
                        "size": 736561
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "0607c1070125f2852cfcd2f841a8b2cd8537bbc55d7709bccbb25826dba7f4f7",
                "size": 228749
            },
            "errors": "",
            "filename": "2023-11-01T10-46-09Z",
            "size": 228749,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698835616,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "OnrpYa7a5g8TYQpuo/nwmbirEYv+37uBEKjT4VbC1LPo9XCZAOZdfLU5AxqqUp93C+CpgCJPYk1i5xshI8ATZ2nYEZMoeAtiq8FSWPXQQCVHeLmWPDReWmbQqLfd7NHLWhruMLOgSpIfqCNETKpFS6FSA4YH6erfTcJLv4uDsWDHmtbVzal71oCFf8gNeC/lp5dkVXJdv6OajVislM40Q5Pqe99u+6dE8BbSnVdGE3IuHFAWYfLztDZLcz7J/fCjAFaLZonSbO2ctTFHz+mVDk2047Lp1mwXDycWuwUJP94HKhcjiiZ5pZSThRRmBLFNSrcGvRXTdUoHKaTOEAKcvr5uSGP+7h+7XTekqXKtgwP73cJfFNzZeQBU9oFibPmmtcj8nulatjrH/fxdKEhewZGWO7IFd4n+Ay5e+K1co8P0MPBWBMsfbCUXke9dbP9uaAwSlNh9yD9nGzQ/gf4UPXNvurF2eZxJ1dg+ceRbEeRyhng2Ly+1JJrApICD4p0bpEzkai/fqkRSGc34IzLHuHEfz01VzplcURRNwju/jJbui2NZzcwD5iOEfOttDjjDKCa8skKA175VCwILuHAQN/QxcQOPWtsDRqTzHc3uCivHkW9+xHhQJATXWxV2Adju4uEuGHyIlJNUkvGYbS4N79mh9ReFs9p85s1JgsPx/q8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-46-46Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698835482.9627697,
                        "modified": 1698835482.9627697,
                        "name": "/dev/shm/incoming/2023-11-01T10:34:37.ais",
                        "sha256": "7317910c2078bbd2e2e1ae55fd073ef19d63ce9c91d0b10db89b8cf4292c6145",
                        "size": 88015
                    }
                ],
                "mission": "EFCA1",
                "sha256": "77758bbf8570e6c238dbcac7fc9c88a738618bfa1f3b29808eddbe741ffe5732",
                "size": 17244
            },
            "errors": "",
            "filename": "2023-11-01T10-46-46Z",
            "size": 17244,
            "verified_at": 1698848191
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698836094,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "jkUX9jSwPU8s4dPr+AK4z8t0IEuEszrS9iJdNiaJ418HXcSXPEnOMgX74S/6CTXVjHB8KwZp96AiwmFdFvFw6k6CuYKo3VVipXwEfh3+DoS8/qSwVpQrcCeju2aIn755jtpriYgZIkFRE1k2LJN9NuZgsRIePqTTMn5P7C0VGvJiSubBnGhvq9p00mfXqXKHoc5Y2NDyInoPHgNHGqhe5ltoRDs0n0E2WTwLHwkU2SW8cdDwJzR0L+Epk3vEZjA56s+PMAu+7HilShfyepuehcv+vJf39lSedn9rxYOENw5vbGBtS4HqMvTo6z0M4PAaIAQoHMWy1gIITqSFjcDCQGN3uNUbTfcnSK4WulThY2Ld7/2vXAj/L9rs30s7lq0TTHA05ULYH3ZbFmqmlP8FpvzabvHcM2u5ZG6jlfepfayp2WEvarNoPoZofDSSWLHUGMwLySdiNrMvDayle+QWeOmeZvXkjYvBjtuMIz1N6x0tmLMmfwbnhMLtDlDlPpiTOhVp20y/7Zi/iyMwg/67ttyImjNHiQjPGLEmA9oPMBio2CPOdWSNZftn8r6B/nu+O4ByK+RW8evUQC+pR7OwTETvmpfxvlASWhjUmaze5SBlzs1vvNCmMf/W71oKlmisaD58SggEjmU3weOej4BDbDMf28dGsL+SWHluxC1s6Jk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-53-28Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698835881.2996626,
                        "modified": 1698835881.2996626,
                        "name": "/dev/shm/incoming/2023-11-01T10:41:19.ais",
                        "sha256": "8277e448d252d2ef9d7a07dace329d4522f48a716c054c3c0bc9d17d4f68975b",
                        "size": 480494
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "819b2862a04c2dd8b9f2ab0945be164d837524d578112c76a966ee8d1b34d1d2",
                "size": 147153
            },
            "errors": "",
            "filename": "2023-11-01T10-53-28Z",
            "size": 147153,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698836190,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "p9voJLSJApk3N0EvvagKSwhyMPv8kYWN/pxShQ0+FN3aNUrRYKSj9aVpHpxKfd7OI+9oSyqeO9873Gfk2SXKYNtQx9nezmsLFglwFXGKeBU66aA4a/v3UFAp1SUJ7qI2C3l8l9cIJlSHH17TqqIEgrxDs5cHP+EXUbbwM9kzyIK9dhn8NKzjJG1HIAOPi7n85iJy/20BPwQnRGAj5wPWcbW2wz4eJZsiVKSJ5kC/mNN5bIi2cbsrYxhYmZDHiu9zXqEBJb45idmY69jFjtFpqXVjKZUqR70CMgi4x/5RvQZ83XTYSzsalfUDJjaJOgFOxOYVnlBvLuzTzlBkWqWPe9W+owPIx7khjvbIJiPlCBX+wwiGFujBVx2kumnhLRyW57PduMqHWRJHP4AZsb/XRSNkAxVzeUoP3r5Y7INwtl6Gir/TorPNrl7UO8lTuAF5h2tYDJ2cV0J0OZOd6/pZ3UeWJSVmP0UF8HMRCcPxffhxXPm2wBPfxlI+bDfGkDocOVv1UqPkpk2xwx7zXpJAduL93y6e0ot1tG5sdqwjUU+36+zVA6f6MxkMGF9Sy0RqWzHxF13t/+QnP18A1jHDIZp6cdh0rI7M7Nc/QL7bQVQ4gzZ4wknzcqVObKU9WFDZunNruQX6/akJXxO6doY1lAbke1uAIh0AOJtWll4r8Ac=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-56-06Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698836042.3885455,
                        "modified": 1698836042.3885455,
                        "name": "/dev/shm/incoming/2023-11-01T10:44:01.ais",
                        "sha256": "87e83dc16f1cc509452a054f0a28c758e7845b3f94da0f19d0ec7cc40c3ac4a3",
                        "size": 710838
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "0cf4b9d62fcff0916c0aa96734ec1f333978c1ac6dc2588c6f9e31242050622a",
                "size": 215987
            },
            "errors": "",
            "filename": "2023-11-01T10-56-06Z",
            "size": 215987,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698836417,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "EPSpnfXSuwzjfYFJbs886bstJGkE7oGJRCsQa4xqGVWcbn1/OwJaMoh1shJdyGjcnpDt6iAQDnrL/VZ978Raxh3xzDOyKBNkmq/LFremUVhL96s5jVRlGGW3UEJzk79Uu/oXmvFg4KCOqSuR/105IlDoIVDq+2/yC7exbBAnOG14xvLVdFgqfdIhlaqhC3VrkCDnRyRWyemsMeV/BPbzX3cGXsbJkJ79JnkEXBiZSUAvtwqUSSaS0sbv+JJvt+n0DC6BAG/ZDWDqEEgn/y5MLuq6a7EqIT+urpgopvDaFQmA90BXq/h5wsYcKgrGwta+l6Liav9JTKFQy1Wmg2tg/Jt59M+RFTn2BNeqaEVYfltVowd9ci0wYN/deIcWtQeU5kD7L9qagYk4e1j/o5W7Cg8hez0xQ9aNUrILFAK7BDF+k+mUpcZ0EibA0kwyJIuuqix82e9yEe/MHcXmwd941djRRgbgxdN/Ppt5bxHUyMCSjaUVSK86TcID8w3eL8FWhtdsG5vJKfm8E+RnESaQ6CQHh+s3ZYbeEGLKw2ZKy6A2qwugTr3ZJFh4c+sTxGL9+yCA3hVjEPCQwLSbAnscjckbWoRO59hDO1YWsdoTjEKqL8Q5lNIn/mwGqXHTPwlxpM0T7TVr7MfcV+fAA3M9EoW7P6N9XdZr7jjoGSyxYV8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-59-55Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698836278.0696626,
                        "modified": 1698836278.0696626,
                        "name": "/dev/shm/incoming/2023_11_01_105728_00_00_K_055.txt",
                        "sha256": "37b8b4af400105042f953dea5d421ac8501a274c3b0adf8632b2476dca1bf7f0",
                        "size": 2046
                    },
                    {
                        "created": 1698836274.2296627,
                        "modified": 1698836274.2296627,
                        "name": "/dev/shm/incoming/2023_11_01_105728_00_00_K_055.kml",
                        "sha256": "f0820d7d3bb88239ccea27bf1a15f0012b5c44f87411cb8211cac04c1da6ab19",
                        "size": 975
                    },
                    {
                        "created": 1698836283.7296627,
                        "modified": 1698836283.7296627,
                        "name": "/dev/shm/incoming/2023_11_01_105728_00_00_K_055.jpg",
                        "sha256": "68134277c3332dd7302f2f70331f1ecffe71a8d603993326254ac5901fb4fca8",
                        "size": 648719
                    },
                    {
                        "created": 1698836256.1496625,
                        "modified": 1698836256.1496625,
                        "name": "/dev/shm/incoming/2023_11_01_105716_00_00_K_054.txt",
                        "sha256": "ea4ebff6c746fda9ccbb4c647c8a2705c00f93a9afdd388482332e4e0f88c0d1",
                        "size": 2047
                    },
                    {
                        "created": 1698836265.6596625,
                        "modified": 1698836265.6596625,
                        "name": "/dev/shm/incoming/2023_11_01_105716_00_00_K_054.kml",
                        "sha256": "39605b1a4d5b51fbae916da2d815ee1f8f2ba56c525e7d7e68dc34aaf85bd91c",
                        "size": 978
                    },
                    {
                        "created": 1698836255.0996625,
                        "modified": 1698836255.0996625,
                        "name": "/dev/shm/incoming/2023_11_01_105716_00_00_K_054.jpg",
                        "sha256": "9e854170ac4d7e8bbf1815d2f4e69daa0123da289b883125432b80078bdcb20f",
                        "size": 936266
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "a6da0e2338668ccc8a536c78e914a7c9900affe733526f1339933ce2065d789b",
                "size": 1576314
            },
            "errors": "",
            "filename": "2023-11-01T10-59-55Z",
            "size": 1576314,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698835989,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "uIc0c2CPbd35HLwqB+qAYrnXTflstPu0RXL6C/D9gdJh+u9S/J3e+syGBOPfvqJXIdOov5vdXmI1+czhLKwomZvH9Z8z63lBZROEpmgCZhmJ9yXojVDoDKcV011oHc81nISOqzazKv7jEzGZNp6VBn/zBPC4807Gmv9nG9BR8KT7TGkK7n5e0Qw5zRUvTBo/1Z3Tx8YbYVYSvUspmhPf/liH4pXdXmzwTu1loL64Qgf/RZkL8u5KqVTYfpS2WUeOfy2C4E8VJFFXMVB3xrIj45jQ6g587LT+Nfzt4zt/DiOUUkGM4uNNhjtY06UOBv9/HuJ4xYX6q4ZrHtx+Y+YdGOnWPD+K62EcpBi5VtMzHyiEbbyji5Ez8Iw7qoNtbul7C7X3Envd2qqbSpNS8oCWEOlHgqh6jAPOu3mubJChTCo8Hmt7o2NKA9V/NeDUNqlgbvye83gBP4C73bjpbLLFujHiA0VmZHGGRrdduvhoLCXGumNiiY7hE9U52cXhOVQRy0/UU3NdbJXKalymZPoaHQAvXIJ/Pl1CH4SpT2duGvaa1jMYOBTwIZ7qDfNBZ0EX5woTpKpmeuj0Tc1dDUG/msbKeXRPOoug4USppcYw2loblauf7WU7xFrZeNhjKAM+o6QvJHqbTmEOR3NZ5SgluyTpBxTI8d9BlHWjwqRXYMg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-52-14Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698835924.06277,
                        "modified": 1698835924.06277,
                        "name": "/dev/shm/incoming/2023-11-01T10:22:03.ts",
                        "sha256": "37880a284b5ac14b0a75bb3af83ac200e33a60e5d733d09e9b1dc492c6df9021",
                        "size": 410847868
                    }
                ],
                "mission": "EFCA1",
                "sha256": "566da42491f566a7abe707cc3c5a310524d918818c40ff9bc8ee87d8411d8d7a",
                "size": 395990955
            },
            "errors": "",
            "filename": "2023-11-01T10-52-14Z",
            "size": 395990955,
            "verified_at": 1698848331
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698836794,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "PXm0bQHQDOLcTcuL8Y7+v0/eoZTHEKYD9V2ly4OG4OpTMfPU+kpWXByJEfkZVClD3Briul6y+R2uL3D7q7EPU/HA2eNaHuEOpPeyDihEFFK4GHbh0YwawYT2MmU6g607xBJmZ+cE/o1/r1NvWD9rbv59x807+g25PijMdC6UBg+veY57PDspLLzc0T8py738RAQRgQNmrLb3W46cx9RyZW8CpMfAlB/t4ekBlTq0XkrihRDlR6N2PPrzNLlg5Qw1NctC4+d+BmrIaxs9kTxdWE8fEtwTOllrNDJL2Ulht8CBH0ItM8Wfp7A2GyE6ngKlYea/yCxZ5//R1WepaRqksubfuyuY7nwceO2ntxqSfhgn5YcxOckhxXPoP9WCgUsGk9PK/4Nk/9z5+M2rsAvqPjlrDXF2TD/mQBGHprVjgysCv7lDRfiotKnCdiCVpHnritViTwNNxfcfFYc1qaJrgdkCtw/Tgp2Aw6BV9UYqRM5YbieBdAi02PkrewoYCr7WBT2XTC0Br30uhx6Hfly1WNiV12YPLx2ZrOL5wUNn0MHSCs3b7EqOfB8exWvStNdw1MYFnXnZZDPDVupKGGeNQrD5K07ElcmDDZL9yiqOR0tlyPKdePi2fGrXEVFga/DH14pwGhYSS+JOc7G6/EIfJex42VPpnDbJXoyFRvdLQKc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-06-10Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698836644.1585455,
                        "modified": 1698836644.1585455,
                        "name": "/dev/shm/incoming/2023-11-01T10:54:02.ais",
                        "sha256": "c3a643e449e4bfea037f4fbb0f45f9c31940d8d51ef5f7cb95c43dd0539b1ac5",
                        "size": 641933
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "4691145ada8e6273196222a8fec2a3ce16ed89808bf2e0e4337786e92498ce99",
                "size": 192784
            },
            "errors": "",
            "filename": "2023-11-01T11-06-10Z",
            "size": 192784,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698836934,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "me0zBJnXWeGLBrVPYnvbwEyNGJRzHESqzw3Ft7ZQsMVikHyhtd9iX5t/mzLZBT+kxCocKMsLBWJXeCGHAm7sYp7EYFGIw6MBZQrhHv8g6jXFgXwzXa4GcjvpPmtrJjPQF6Z27jy86+/2kjEuoXeY3MS/pjSTZvws2+tD4gMscGoNzPgLc5qnCEzhn/X8wcxZq0xWTy+8ZYUg+6k16MfVKY2hIHIzx+gKmo6Ghy9YkdkSjhqHQ5c8qJrHUzZp/GHEBboaVur6LRLd3nlVjPRZmSGQGmoLv2HbQkD15nLP5NYXPdqfQbNdKNQXD6X558K+9NCkk45T8lFaaj52EDjlqr6W08o8jor7s+9VAZT8sH9hkP1Pi8UmNaKH1CwYtuWdlSlZ9qARZ+1xpcQKZjzR8aAaUoAuAh3TNCt3najTJ2A/oOvoZMiDKeix1Svqs5wf51I/ohgimDAi3Irfmuyyt0Ce6nWgbUF6sXMTPdOThT3kId8Kc3FJZoQPPFH6OuTt/NbWqNg6JM6en8MLrxDFXD7UFWvJ57EQ2Gef76ojirLXSbD/dGimtQ3ivmmP1qi3q3a7nw9vqLeCVKQ9FFRWxoCS41iKRCyPwG3Xbca3/WLBbsKea3xpycd4iVeL4//8T3vO/J+Z1lvXgRwfxKBt8ZlOGGc9YnkIVBPq/Mp2TIE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-08-05Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698836764.8396626,
                        "modified": 1698836764.8396626,
                        "name": "/dev/shm/incoming/2023_11_01_110536_00_00_K_075.txt",
                        "sha256": "a6bfbd3a7d56967d68bc3317f5fd2dfd1d3e0f137fc63f235b5e31f9d3e81e18",
                        "size": 2050
                    },
                    {
                        "created": 1698836764.3996625,
                        "modified": 1698836764.3996625,
                        "name": "/dev/shm/incoming/2023_11_01_110536_00_00_K_075.kml",
                        "sha256": "850729e831587cba3951df092258195c0ee3c7aa4c6bf101f21ee6d24f80d338",
                        "size": 974
                    },
                    {
                        "created": 1698836760.0196626,
                        "modified": 1698836760.0196626,
                        "name": "/dev/shm/incoming/2023_11_01_110536_00_00_K_075.jpg",
                        "sha256": "4d48d043e5c582505c7a59ac2277b66b72f6852ee025a1f6fa5974e3e224f25b",
                        "size": 1118096
                    },
                    {
                        "created": 1698836703.9996626,
                        "modified": 1698836703.9996626,
                        "name": "/dev/shm/incoming/2023_11_01_110437_00_00_K_074.txt",
                        "sha256": "08415b6c2f29c97941fa1d1e8e818f3de065e7c0525550607cebdf80b638c1a7",
                        "size": 2050
                    },
                    {
                        "created": 1698836701.5196626,
                        "modified": 1698836701.5196626,
                        "name": "/dev/shm/incoming/2023_11_01_110437_00_00_K_074.kml",
                        "sha256": "7e5804c8de8a521ccf6ed8e1152d7e95b7cc494b53fcf1f7be56ec5c581808f9",
                        "size": 978
                    },
                    {
                        "created": 1698836704.2796626,
                        "modified": 1698836704.2796626,
                        "name": "/dev/shm/incoming/2023_11_01_110437_00_00_K_074.jpg",
                        "sha256": "6ba9302b21a04efd7e6ab5673221aa3c569773d1ca8267fa7d8ad61aae081a1e",
                        "size": 986316
                    },
                    {
                        "created": 1698836693.0396626,
                        "modified": 1698836693.0396626,
                        "name": "/dev/shm/incoming/2023_11_01_110429_00_00_K_073.txt",
                        "sha256": "7170ce9b4b28a01bae224e8c564e096bddeed198d473a28655b2a6b99a273369",
                        "size": 2050
                    },
                    {
                        "created": 1698836698.9396627,
                        "modified": 1698836698.9396627,
                        "name": "/dev/shm/incoming/2023_11_01_110429_00_00_K_073.kml",
                        "sha256": "ca8512d6bb6bdd7682b1f047897e0abb5df794d66eb750244f4bc12caae5e046",
                        "size": 975
                    },
                    {
                        "created": 1698836693.3496625,
                        "modified": 1698836693.3496625,
                        "name": "/dev/shm/incoming/2023_11_01_110429_00_00_K_073.jpg",
                        "sha256": "541725df60cc040fde056d44a282ebe47f15074917d575e3ce99a9631632ff44",
                        "size": 1108756
                    },
                    {
                        "created": 1698836688.2496626,
                        "modified": 1698836688.2496626,
                        "name": "/dev/shm/incoming/2023_11_01_110426_00_00_K_072.txt",
                        "sha256": "38c3b671f10f5346ad5ddae305fda1b4794b3a1609f7ee77dca07bce612e3cf2",
                        "size": 2050
                    },
                    {
                        "created": 1698836687.9096625,
                        "modified": 1698836687.9096625,
                        "name": "/dev/shm/incoming/2023_11_01_110426_00_00_K_072.kml",
                        "sha256": "8164af4047a5cbbca504797c35fa2a3ff0a7b4ad433157c1aca0d58c408e8812",
                        "size": 977
                    },
                    {
                        "created": 1698836700.8196626,
                        "modified": 1698836700.8196626,
                        "name": "/dev/shm/incoming/2023_11_01_110426_00_00_K_072.jpg",
                        "sha256": "b53c5fe90e56c94f584bee4af04b69fc6cd3a30ad28ca44c5f93a29f35e98635",
                        "size": 1161626
                    },
                    {
                        "created": 1698836676.4496627,
                        "modified": 1698836676.4496627,
                        "name": "/dev/shm/incoming/2023_11_01_110406_00_00_K_071.txt",
                        "sha256": "db186e4da3891ebd48fbd76e9e0e6ccb324c3edeeacfbf97dcf3b3e2c259e13c",
                        "size": 2050
                    },
                    {
                        "created": 1698836664.2496626,
                        "modified": 1698836664.2496626,
                        "name": "/dev/shm/incoming/2023_11_01_110406_00_00_K_071.kml",
                        "sha256": "a8e26dd957934e1a462ef9b2a3d7b40685c4880e93263c906bc62f27399c5ace",
                        "size": 979
                    },
                    {
                        "created": 1698836663.5796626,
                        "modified": 1698836663.5796626,
                        "name": "/dev/shm/incoming/2023_11_01_110406_00_00_K_071.jpg",
                        "sha256": "2bd5421df614f659f74fb5b12aca118cb512af5ae243ec037662cfe82413577f",
                        "size": 628087
                    },
                    {
                        "created": 1698836658.3096626,
                        "modified": 1698836658.3096626,
                        "name": "/dev/shm/incoming/2023_11_01_110350_00_00_K_070.txt",
                        "sha256": "838ee518ddbf9e40046fbd8acee3a670ce86f8e5d8311fbb6c5262910e63e11d",
                        "size": 2050
                    },
                    {
                        "created": 1698836659.1496625,
                        "modified": 1698836659.1496625,
                        "name": "/dev/shm/incoming/2023_11_01_110350_00_00_K_070.kml",
                        "sha256": "99dab045ceeff510ae443027c11b71e5f3f1c0f23ee66afa8a65d51a72edeee2",
                        "size": 977
                    },
                    {
                        "created": 1698836657.9096625,
                        "modified": 1698836657.9096625,
                        "name": "/dev/shm/incoming/2023_11_01_110350_00_00_K_070.jpg",
                        "sha256": "1fc6db35e06511a5d796a60216748ff27e1c806772f795f16e94f3d19a95d903",
                        "size": 934994
                    },
                    {
                        "created": 1698836645.1796627,
                        "modified": 1698836645.1796627,
                        "name": "/dev/shm/incoming/2023_11_01_110338_00_00_K_069.txt",
                        "sha256": "89548cf468bc1a73d06a8435e0d286a0c67dacddb324b71301a21de4466015db",
                        "size": 2049
                    },
                    {
                        "created": 1698836642.5996625,
                        "modified": 1698836642.5996625,
                        "name": "/dev/shm/incoming/2023_11_01_110338_00_00_K_069.kml",
                        "sha256": "362612d14e935101b7b60d655202c96dab864a0977a5d372146e54c50606c72c",
                        "size": 979
                    },
                    {
                        "created": 1698836643.5296626,
                        "modified": 1698836643.5296626,
                        "name": "/dev/shm/incoming/2023_11_01_110338_00_00_K_069.jpg",
                        "sha256": "c98f85ed0e5a413076d3efbea9bb21aaeaa4639f2a3fd7323b79e5da8ec9217b",
                        "size": 1185394
                    },
                    {
                        "created": 1698836620.4396627,
                        "modified": 1698836620.4396627,
                        "name": "/dev/shm/incoming/2023_11_01_110312_00_00_K_068.txt",
                        "sha256": "2d5b87b2d31316603e9574f8323e434297e318c55df63b38f8565f5572aa30eb",
                        "size": 2049
                    },
                    {
                        "created": 1698836626.2496626,
                        "modified": 1698836626.2496626,
                        "name": "/dev/shm/incoming/2023_11_01_110312_00_00_K_068.kml",
                        "sha256": "334b035dc959d92da617c6b93e9e6918a3ef40e47a3be1ce5fcef1a20cd4658a",
                        "size": 979
                    },
                    {
                        "created": 1698836629.5596626,
                        "modified": 1698836629.5596626,
                        "name": "/dev/shm/incoming/2023_11_01_110312_00_00_K_068.jpg",
                        "sha256": "6c15ef962fe460b7da6a5316ca68dea7df046718913e0e0fa7f7a50e535a49c0",
                        "size": 1076145
                    },
                    {
                        "created": 1698836578.2896626,
                        "modified": 1698836578.2896626,
                        "name": "/dev/shm/incoming/2023_11_01_110224_00_00_K_067.txt",
                        "sha256": "596d0034f80208a4e5a772040ce79e2e7f42545a0c620a0f5eaea5255ac3e0be",
                        "size": 2048
                    },
                    {
                        "created": 1698836576.8396626,
                        "modified": 1698836576.8396626,
                        "name": "/dev/shm/incoming/2023_11_01_110224_00_00_K_067.kml",
                        "sha256": "87627083fa20fe10bc03117cf9d09b2056a728ba5b7b1685aa9fc1d517a87075",
                        "size": 975
                    },
                    {
                        "created": 1698836573.3896625,
                        "modified": 1698836573.3896625,
                        "name": "/dev/shm/incoming/2023_11_01_110224_00_00_K_067.jpg",
                        "sha256": "ae68226465a09edf3f7a13f9fd602b979c553ae92243fb56620f145ca9cfde08",
                        "size": 981389
                    },
                    {
                        "created": 1698836564.0996625,
                        "modified": 1698836564.0996625,
                        "name": "/dev/shm/incoming/2023_11_01_110213_00_00_K_066.txt",
                        "sha256": "28fb38aa070fef3596e64d2b378822a1f28fb8b525ed0657c67155057536a038",
                        "size": 2050
                    },
                    {
                        "created": 1698836568.0996625,
                        "modified": 1698836568.0996625,
                        "name": "/dev/shm/incoming/2023_11_01_110213_00_00_K_066.kml",
                        "sha256": "e6c9c71059f4d7da0004f4dd368983dfdc325583bfdcfaa592c27a83f57f6728",
                        "size": 979
                    },
                    {
                        "created": 1698836568.1596625,
                        "modified": 1698836568.1596625,
                        "name": "/dev/shm/incoming/2023_11_01_110213_00_00_K_066.jpg",
                        "sha256": "d1e2c99bb9bf7fdb451729582c0db37a4dbd27fe0e67e0af1704e928eaf28d38",
                        "size": 1262786
                    },
                    {
                        "created": 1698836546.2796626,
                        "modified": 1698836546.2796626,
                        "name": "/dev/shm/incoming/2023_11_01_110205_00_00_K_065.txt",
                        "sha256": "607e5ec451efa5c09cbe767ac9bf1a52403ca35d97391d72f790ff30c38a50d6",
                        "size": 2050
                    },
                    {
                        "created": 1698836549.1196625,
                        "modified": 1698836549.1196625,
                        "name": "/dev/shm/incoming/2023_11_01_110205_00_00_K_065.kml",
                        "sha256": "36083f76561739298191af783560f1dc6555744e44fc74face7c320802396f16",
                        "size": 979
                    },
                    {
                        "created": 1698836547.7296627,
                        "modified": 1698836547.7296627,
                        "name": "/dev/shm/incoming/2023_11_01_110205_00_00_K_065.jpg",
                        "sha256": "dce7b1d17521228d4a6b80b7c6950a7296343cd922d67278a09cd7deafabb0cc",
                        "size": 1151921
                    },
                    {
                        "created": 1698836536.8596625,
                        "modified": 1698836536.8596625,
                        "name": "/dev/shm/incoming/2023_11_01_110154_00_00_K_064.txt",
                        "sha256": "76647a018f37e1f3e7b721f68924b6b4dfb494e5c30acf9c28da61b77f06e7e0",
                        "size": 2050
                    },
                    {
                        "created": 1698836543.7796626,
                        "modified": 1698836543.7796626,
                        "name": "/dev/shm/incoming/2023_11_01_110154_00_00_K_064.kml",
                        "sha256": "cc4ece587c11c0e1105855d42a3a4a11f53c6035b6d9076ea7cf3c595535c39b",
                        "size": 978
                    },
                    {
                        "created": 1698836536.1596625,
                        "modified": 1698836536.1596625,
                        "name": "/dev/shm/incoming/2023_11_01_110154_00_00_K_064.jpg",
                        "sha256": "9a98e212122e0f8802f78336feb3308973a945df96db4f2fa7f0de4e07ac626e",
                        "size": 659868
                    },
                    {
                        "created": 1698836531.9996626,
                        "modified": 1698836531.9996626,
                        "name": "/dev/shm/incoming/2023_11_01_110151_00_00_K_063.txt",
                        "sha256": "f0acf03bebf360c319e38f730d3c47bd69bf252636891affdcaea60c67e9201e",
                        "size": 2050
                    },
                    {
                        "created": 1698836531.8396626,
                        "modified": 1698836531.8396626,
                        "name": "/dev/shm/incoming/2023_11_01_110151_00_00_K_063.kml",
                        "sha256": "549b957e3e08d2bf69b7d8ae449befae8300419a832f42bf6b0336714b68a712",
                        "size": 975
                    },
                    {
                        "created": 1698836537.0096626,
                        "modified": 1698836537.0096626,
                        "name": "/dev/shm/incoming/2023_11_01_110151_00_00_K_063.jpg",
                        "sha256": "f325c5854c555fb54466556e7ce8c3183cd1691db8c94746e16bd00df0018462",
                        "size": 677827
                    },
                    {
                        "created": 1698836530.9496627,
                        "modified": 1698836530.9496627,
                        "name": "/dev/shm/incoming/2023_11_01_110147_00_00_K_062.txt",
                        "sha256": "d8cf7271ebc63620eee0a48f84c3d1db5f9d9819e8b26749f77e089bc12e816a",
                        "size": 2050
                    },
                    {
                        "created": 1698836530.5496626,
                        "modified": 1698836530.5496626,
                        "name": "/dev/shm/incoming/2023_11_01_110147_00_00_K_062.kml",
                        "sha256": "da5de874a70d6a56afd39b9512e3e7b227a1789c0efa75e5bd80926b91ff12c0",
                        "size": 975
                    },
                    {
                        "created": 1698836530.1496625,
                        "modified": 1698836530.1496625,
                        "name": "/dev/shm/incoming/2023_11_01_110147_00_00_K_062.jpg",
                        "sha256": "95397d793f5b12f59d57495c6b8165fc52000d724208045d3cb4cbcc588892d6",
                        "size": 643467
                    },
                    {
                        "created": 1698836524.0996625,
                        "modified": 1698836524.0996625,
                        "name": "/dev/shm/incoming/2023_11_01_110143_00_00_K_061.txt",
                        "sha256": "413a20bb66bd6abbaff3fbcaa05e0cdac851a74a0aed7bb41ce97d957e0b5c73",
                        "size": 2050
                    },
                    {
                        "created": 1698836518.3896625,
                        "modified": 1698836518.3896625,
                        "name": "/dev/shm/incoming/2023_11_01_110143_00_00_K_061.kml",
                        "sha256": "19c0c2f57966a444073954f4bb205330685cdae2c220f03500700126c391552d",
                        "size": 974
                    },
                    {
                        "created": 1698836518.1896627,
                        "modified": 1698836518.1896627,
                        "name": "/dev/shm/incoming/2023_11_01_110143_00_00_K_061.jpg",
                        "sha256": "b5e1eda7132888878a31b938964c2852fe049261e40aaa5b861f4c9fe7ffc912",
                        "size": 592115
                    },
                    {
                        "created": 1698836517.3396626,
                        "modified": 1698836517.3396626,
                        "name": "/dev/shm/incoming/2023_11_01_110131_00_00_K_060.txt",
                        "sha256": "1f61ed48fc5888b05a0e8a50fd6a3190000fd5fb965f6295fc2a6172debd9e6a",
                        "size": 2046
                    },
                    {
                        "created": 1698836517.1496625,
                        "modified": 1698836517.1496625,
                        "name": "/dev/shm/incoming/2023_11_01_110131_00_00_K_060.kml",
                        "sha256": "c2bdc646dfaf269167e657509e092e419af7f0f3d205f12ac0df65d4fff1a22d",
                        "size": 976
                    },
                    {
                        "created": 1698836516.8496625,
                        "modified": 1698836516.8496625,
                        "name": "/dev/shm/incoming/2023_11_01_110131_00_00_K_060.jpg",
                        "sha256": "ece6cff96e49db7d75b9840732259a32dea181ef8149fcebcc12df4e7a3f9f6c",
                        "size": 984726
                    },
                    {
                        "created": 1698836503.6596625,
                        "modified": 1698836503.6596625,
                        "name": "/dev/shm/incoming/2023_11_01_110111_00_00_K_059.txt",
                        "sha256": "448c5b53bc4b2c841ec78e9197964559cec8630ad634615df1cc4c51ca892e7d",
                        "size": 2050
                    },
                    {
                        "created": 1698836493.6196625,
                        "modified": 1698836493.6196625,
                        "name": "/dev/shm/incoming/2023_11_01_110111_00_00_K_059.kml",
                        "sha256": "e5a3e42119d57ed70a25312e3c02eb4ecd196e83a721ed1b35b1928fca04712a",
                        "size": 976
                    },
                    {
                        "created": 1698836492.9596627,
                        "modified": 1698836492.9596627,
                        "name": "/dev/shm/incoming/2023_11_01_110111_00_00_K_059.jpg",
                        "sha256": "155707210199d6fb6eaf13a20e7cec5b8f60a751a1d841089d085a1f19fd1134",
                        "size": 1128168
                    },
                    {
                        "created": 1698836514.5596626,
                        "modified": 1698836514.5596626,
                        "name": "/dev/shm/incoming/2023_11_01_110107_00_00_K_058.txt",
                        "sha256": "340d13d34a21ad3e124a9f2cc7243f28402d37fe1524e75b9a3aa06a7caf1cfa",
                        "size": 2050
                    },
                    {
                        "created": 1698836495.8396626,
                        "modified": 1698836495.8396626,
                        "name": "/dev/shm/incoming/2023_11_01_110107_00_00_K_058.kml",
                        "sha256": "8cc996107f8aa704ee5e8aa1104552a667cf6eede45b5e0139d0240aaf07ee68",
                        "size": 978
                    },
                    {
                        "created": 1698836491.2996626,
                        "modified": 1698836491.2996626,
                        "name": "/dev/shm/incoming/2023_11_01_110107_00_00_K_058.jpg",
                        "sha256": "3b0d2a65b9b88dad4ca7332a4190499194b51cc216edcf893de08fe7c996fdda",
                        "size": 1207111
                    },
                    {
                        "created": 1698836482.2896626,
                        "modified": 1698836482.2896626,
                        "name": "/dev/shm/incoming/2023-11-01T10:51:21.ais",
                        "sha256": "bbb871fad9718603a173dda67fa36c3318f3c316bf3e643aed6497499e3d031b",
                        "size": 510181
                    },
                    {
                        "created": 1698836480.1996627,
                        "modified": 1698836480.1996627,
                        "name": "/dev/shm/incoming/2023_11_01_110101_00_00_K_057.txt",
                        "sha256": "74dc559bb2de08ecbc61e38a28fc6e6896fa3f6bb22614341045f59e063484b3",
                        "size": 2047
                    },
                    {
                        "created": 1698836489.1196625,
                        "modified": 1698836489.1196625,
                        "name": "/dev/shm/incoming/2023_11_01_110101_00_00_K_057.kml",
                        "sha256": "47d9ebe3c097a792482914accef788f04ea58d3d936ced3403cfe6e062e02780",
                        "size": 978
                    },
                    {
                        "created": 1698836488.1996627,
                        "modified": 1698836488.1996627,
                        "name": "/dev/shm/incoming/2023_11_01_110101_00_00_K_057.jpg",
                        "sha256": "9bf65b2447d6b50ced70f7059204dd98b78e33afef6e283ad89a33b0219718e2",
                        "size": 1212802
                    },
                    {
                        "created": 1698836477.3996625,
                        "modified": 1698836477.3996625,
                        "name": "/dev/shm/incoming/2023_11_01_110057_00_00_K_056.txt",
                        "sha256": "a62fd25b7cdbc2f82dd0a20ed7177eaa185fef3aa753924707de508795f7a820",
                        "size": 2050
                    },
                    {
                        "created": 1698836478.0896626,
                        "modified": 1698836478.0896626,
                        "name": "/dev/shm/incoming/2023_11_01_110057_00_00_K_056.kml",
                        "sha256": "acf594062ba7cc7957a9f673dc4358e462f2d23661e18304614fc84bfa0eca14",
                        "size": 978
                    },
                    {
                        "created": 1698836476.3596625,
                        "modified": 1698836476.3596625,
                        "name": "/dev/shm/incoming/2023_11_01_110057_00_00_K_056.jpg",
                        "sha256": "e75eba554a2d3b4686968f8474d44d6de16922c820ba1dec130cbc26f347827a",
                        "size": 1124239
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "662930ee6c5ac53dbe63f110fa2ba926c510497a8361b53366514df5770c0ec4",
                "size": 19954381
            },
            "errors": "",
            "filename": "2023-11-01T11-08-05Z",
            "size": 19954381,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698837231,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "YSLpWL1CoQkfjxYikB4vMAmF4Ui7mqI9ZaTGpvq7RwxTa2EqCKh0DFEok4hQjy06Fic5lUfd4FUj7sd6u+y5r5MWsEgVRugskjSNMha7ve40ubjk91uhVv39JwYVesDWvp8x/RmdwuN0bksXC9UY4UlXL17DnVqPkPTU6q33+j9F6+n5nnpnLREE5ooCswEyMH8QCd172/WjxXUkEMCe6/LBIaXRtIlJ8F7Pipckp+eGIGpxJwh4ddoz+dVffdKtk/qJaKTqNZqvP4toj2kVZloycyGk+r04dRZolLpA4um5jmaRt946LvlVygJMQZXht9PASWoA0fFBY9AGhdmLf3KiFFEsk4/pYiBYGLPmyPxDNPAIuOqfyca02cx9BT6GUjdvN43NA1k0G2KnW9oWss4kh+O9D72B3F6w5QB8Yba3LvaQ5fsP+nLoYK2lHS0Vnex6HGrvFhToKFEFzww9RVrDtBEgCO3vWloS+3JCyv4+uh66IT3ESMvPuJAt3/Jy49WS8aC3uS3fbUVZKK1ArA2suEUecD5ajwrhleXw1CkqVvIWSQfL/BcoxMIk1YMxosUJ7nS7a6y6kq/lDYo8U5Kgrxh6z+XAJVfjWClJY9t8IWzMI3lAWaDMBySi8GpwEB/cOLzN6Hyesvh3pYELVLfM/w9a5aVkoGae1JsEzTE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-13-30Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698837083.0196626,
                        "modified": 1698837083.0196626,
                        "name": "/dev/shm/incoming/2023-11-01T11:01:22.ais",
                        "sha256": "c4073bcbbc4726feff63f5a9cc6f4ca4415cf812924a8ef1b8c15726dc556c68",
                        "size": 540643
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "aa4d61de998652de1b3e80711bf4e1f68ad604ad4543755b12a33b8b1a082e63",
                "size": 166313
            },
            "errors": "",
            "filename": "2023-11-01T11-13-30Z",
            "size": 166313,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698837385,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "CxR3fQnT98F8k5VWM9yt8bLnz7FRS4Zu4eo2T/p82YJLkKn1tkfIGUYCubNWxqPGzOl2NhjNZTGlFgVwfenwxJsJornWXmOFvH9FH8RAWlx7DFJjBbIROjRwS8PirYEWrIPDPXpChVD9B0uMEAGei4UapLLp5XEX+eBv04s0F11jxCCfNmhotFAlNfAVcKWbkAuB0iseS+p5oo0/mbqf1BJgnyibI/Sb4o6FVtrK5ah+4gHbDpVzdNBCxRmR6UyuQHcjCHzvnXOVlqdBgFQaP86oQKyC+zqtOHRpIErZHf14dsJsMFjunRfAO3v8c2PZutFlph7OJBgoPZLFal4jzODUFiQ5xdiJ8D8f7jKkT96/jGfoD7iCtRHn14G2sjZHnkeo2zoanBD8roRqaZaQ8UiXZ62AWH7geBR1vjQwv9ofAA+VW7OhgYDeDr3TW/KCNNQ5+1gSFGk/3t4JR6JC0l1OXwawul4EqRnMg0ZAJPXtiW4UucRXG8a6InGQbk/9Sn1sWTcpDd0YNksZYYjUe5fHByQJjsq9JPsIzNCIU+yw0s7QPDD6h/BfrdHjnZ7VOgGulxCeabbEY5n1fQAfOgFOdzF8y8ZPbdMLkovg2SuH59GlGLYdU2tQQt/1os45dlGyhJJY2H3dDgNI/yz7HqLLNqkJWTgSyEOSazjStik=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-13-51Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698837222.0996625,
                        "modified": 1698837222.0996625,
                        "name": "/dev/shm/incoming/2023-11-01T10:43:41.ts",
                        "sha256": "012b05152fd323c74097e8f3a156f2044ed8c3bb5e62b3d62d53701cf63fcba4",
                        "size": 442085572
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "3e6090029fd1d7e8daba2291cf7c17272200a142476e5ee4b26be4ee08654411",
                "size": 397822103
            },
            "errors": "",
            "filename": "2023-11-01T11-13-51Z",
            "size": 397822103,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698836824,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "CpTxKaKar6wUNUCf3CYL/BiYrJuNb6vcstTOF0+rFGbeersOFwpqcrMY3ZsM3UwbOlo0IxJxuxS/FARl2mAF7zBcQEREC9V8NhZqIp02VsAP2PDkCZG7i5qSYtodDpAlYUfNjyRuEhaVHS+9MSHrRIdhOQSvkIo12hbmZNtOKxKBTFYcEVspuTa7tWvrO3hB80uyDTkPNyPuY6JIDGBXt5qMWsRUkETNk9P0pub07cu0uaisMuQc8OE/2/IVe0ubCb2DTmnfpxI+Pp3SP7mqtAwUuEMNyyXuWwvy8KZ/+1atvHwXYSGgNRQ3i52pl07fZOgsaB5u82Qpfr45QYRT6kknOj3LuZkc7KEFXtr9QWbVpNEko7OB513Zhkj8TGRLXPYtJu6keo1tnfW7Qg9qFrOEhL/u8R/DupMLxWby7V31oh0d+orLbRperohmfNxQOsjXvZTRyqWM7+2433Q1dAt267M2YafByJ8+i2+zAcG7dX59hF4ALeec0U5zobV1FV8qHAwviFl2I83lz605JtQ5MM46KM94ajxzGVAz6HhL57ey9AmET836pXk5z9YB8rp0hdITF3+QuISzdYqlMRiugs+dhkxK9sNdu4vacVxUY594F8uVdUithviYeDwrPEkq0zsEh52Wn9TNiQBEVfCqVYMxo94tJMl0F9zKdMs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-06-54Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698836685.7627697,
                        "modified": 1698836685.7627697,
                        "name": "/dev/shm/incoming/2023-11-01T10:54:43.ais",
                        "sha256": "c4a8de5033fec1ff8513d8ebd62695820a0ee2ccf04ad52b66866457a0e07dc4",
                        "size": 44435
                    }
                ],
                "mission": "EFCA1",
                "sha256": "b57a02fd602332acbf179d83cf2337337f9c2985b167e160544aefd108dc14a7",
                "size": 10192
            },
            "errors": "",
            "filename": "2023-11-01T11-06-54Z",
            "size": 10192,
            "verified_at": 1698848245
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698837398,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "wcxUHBDFpiFCH0PezC4/pnken/mUTofqceBr7eKj8DkVZPLPz8b81SRc6CXQuqtXnd6VnQNS1xks2uwdpW8cCAlyKyGePNtLaqUxIOEeT1hIT52hBXnhczTYEWX4O4mtf7Ut5dM3n0tMsONy9ZAEdOknoj8t0G+wR4VjXfTP2kYM3H1MDjkax0vPI9Kkv4LFps9IPYkaWqAm8gvrx3rpYKiEezNYJDJm8rcuKcFjC3zPIwjmnsjENcidKE4cf9FtTt2wnJNTQx0w3aE/F2I9DdCtdDcBQEdUW6xjIogMA3EVYd15/qIWnSgccQwD9HJff//byUptK4YbhcgK9XPX/BPseKEQQLKRfuMRaHbEPb0RsyQmwDx/delukutBIlO6c4R2xemz4AJiR9t8fh+aFh5wiACwvVEzMOvBY0Ahm0zHue5n1c99Bqq3vAtUmoxTgi8uZsMmNSqcj3+H3ykCnX9xLwheLAvNUhEBc9Tz3DiehD71gzXIRU2Fgll0EAtr8YQNoY694e/34EiFoBl1q+1pvWL2ALLg5ml1mkGLVq9anSIg0u/OH+bKWw/c4DrOXLZDlEKcmtz2BNchoU3LyvKie2DMjfh2EuaAMvyltHotlCleEEDpCuzOGD3/IMDzDrJx4IJCKtvZ6+qC8UxXOIc5VZ/jowx1wM7/+efulMo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-16-15Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698837245.8885455,
                        "modified": 1698837245.8885455,
                        "name": "/dev/shm/incoming/2023-11-01T11:04:04.ais",
                        "sha256": "07a0d5f280e7d1e4cd03d1bfc6b98d24d98faa123f618ef6c0a87eb9af2ef38e",
                        "size": 618824
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "520a377443f28675e770bfb6e965874eab86ee666580940f7553cfb0649c1fca",
                "size": 183930
            },
            "errors": "",
            "filename": "2023-11-01T11-16-15Z",
            "size": 183930,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698837649,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "txChP/hLl3PzEZnkNF+IXLLvIsfpsl8zSgX/ojzDs7bsoCYI9Qwa7PFBNkMXYC7hTt87nKHQu3sDP4StnnkR2upjsLQz98NSv8Cg+kTmI+qlgSEly1TP4NKJ0fLEFOMhAKa7B18Z6kXlQoshchIh2pdjoir43NrfbXtDbNapVTOZVQLkJ3ckESb4/2KlIhXhuScyhNuINc5n2h15tBPpELIRsnn1Dt9xK5xPWI0bACyaU259ITEe5sdDu8uOkyYeIpyxtOFsWh13f6ewGgHbeCdz7bUf0y0j+QWyovbeqpQFxwOf7TSvLICsuo40WqRDENujiERe4Pv6E3P0gaIbP5otRFEEtcA8DZrTfqQoHg11XFdmBTCBJ21wc4jtVfddKN2micpiuCZtTeVsvhGubO6jpJyPxNOoGJbSOl7CmUloOciPLxxVDcoOET6xqV+vJj2tojsq9jbU6uFwvfbU+OiGhZantP1ciD5RwQIZIyk5zjVFdApgFEeQmi8QI5Az670IhbonrDBIFnh1V3+2UoT5ZJhwx6MvVf03SSVlwdk/WrkmQE3X7Jv6Xo7k4CQgFo1Whk7D9pTU6FRvuEULU7ZIdPblYSwYbw5TNRYZhr+qTrzU6JfU8gE/6G1hbwhAoGKXVZsxTKWG0fWLsSqicpnPPx67osboA2DDmMWkhH8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-19-24Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698837556.7485454,
                        "modified": 1698837556.7485454,
                        "name": "/dev/shm/incoming/2023-11-01T10:49:16.ts",
                        "sha256": "84eb287dcbe922ae128e6c2a7ffe38371e8c14cbe2d5d8b2da6f3fe31d0ac029",
                        "size": 427585320
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "751b8632e05d63bc57e98fa85688e7275f1f9fb38e508e7efaa64fe4123e4814",
                "size": 401369014
            },
            "errors": "",
            "filename": "2023-11-01T11-19-24Z",
            "size": 401369014,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698837860,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "jGpyPb8zgbdr98QXsW38PqggedWH9crBVl+9vQrWrpvGc1L/I1OxRTCy+WZi+1rWCpaleYqRxi/1wdhkCn1rpRomexBUBOVRCNgy4MY7akxEDCYe1BuiEmjTLMhsxni8NRgqTVEm+WFK9A9a0+Uy5JcLjnGmbC09uGAtQ+kzc/MSqWyAVZ6KOovfT02nLGkNueU2nms2OCyCDaPv16bitfZXJ7iT0tLFn9vCRO+jiNXyCXf589Jb0z4QVG8zY8tEA3tP/T9N0qDA1Go5WOaE/4WzcWmsnN1YhrkYAqc4TkcrMCgadW61nfQlK5c0egD5LoNuibJJ43TEffa5u7q8z1NMKbT3JwFcW03ykO0c+EzMhteOMTX7wwc1BK7m5wR6LEDiIKK4MuVTKVMxAIxDoi1SuYG6aNLB9o8WeQQEytYvDEoVm/wuQ/Ha/SMoJ/1Yg6UiWgdVSyxffs//8idbM31fPjw0Rt2UwDGSLSO1RAIuuYFT0gBiynMKmpy2hbMMnjVaxaSWbReaLZdsBaC9ttGmlNo7Gpfe9XSjvyPvuauDbF8LXaLMV6WdJvz6AgvI5kdGPPIBuGF46sj20PAGIJSMGkFM5I7vBhn49MmPDpjpgkfzcc+/Nt3juvBryyd3fsocgHEWhsfj7rqk5QA36tG7SYkhD2YwwglpXwyig/E=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-23-29Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698837685.0196626,
                        "modified": 1698837685.0196626,
                        "name": "/dev/shm/incoming/2023-11-01T11:11:23.ais",
                        "sha256": "f1ae2953cd7aad21631ab5d13fe7e89481b392da8e5256967fdca66851eb9ac6",
                        "size": 460412
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "641952b795eff5b87711da93d618a91d085d6e1cdf8eee268ad209e9e99c4f67",
                "size": 139018
            },
            "errors": "",
            "filename": "2023-11-01T11-23-29Z",
            "size": 139018,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698837796,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "QpaCRp0vbe6wHVRyQJIqHAc3qf1BcxSMyl507HLq9ThClmQJlVEFj13aWcpcX4qubXOp/7WBEKfx44hcekTlfUAaFnAUa/ZezmW+GOqPVpJFY+zsH4YCPtIgW4CahR4MKTws1ViilXmKSv9LQZy8G9cF9m6Aqgo/+7b6UZNJfez5q3zzYVmF6o00a/rE83gGElJXGzogYQ4A6bcusbntP5EqnByNfWVDyUxZwH2K8M/iWJ0/lUvCpzSvPHRcc/dQiaRSPVLTA43lQlgyfVb83z/mDhJN41z6XJYI4RPuV+zo5VS+t/bSFXNINthVHICYo6hAkYq/9t8i+F0PFvvnqTeqiUGDZDI6AdQbikeSR3jDCZdoXzIPRo1D9UWHvscF9ExacBIJN1p0mseCGcqlLJaqU3+sEfsnLM+EcHn3psqYZA5M0Zpp3uJx+pe34o/R5CnbelYfCr+/IG7ULdp0oClGlONP8SiiJZW/Ne+QJbmPW/pcWgDabzUcL7vbTmQKVJ/Xos3OCaX+LGSgIv3JpMRjxUw6nAU0vDx77/1i3T6Hsgm0RG/Thue3lBuricptl1X2sCA3RHOVg/661Ow9eIumYp6BwJQO59h3FTobFo1OKnvw0k46C6BI5XL+ruGzlVclmrW0l44IZ5SW2LcS8XfCItqje+DJg2kqCa6kcBQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-22-20Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698837729.8427699,
                        "modified": 1698837729.8427699,
                        "name": "/dev/shm/incoming/2023-11-01T10:52:09.ts",
                        "sha256": "ce90dac022d98d5278e291ee32328958244e94bc7c6c4aedd27978ff58cccd24",
                        "size": 410208856
                    }
                ],
                "mission": "EFCA1",
                "sha256": "084d9869995844edb3a4115ad783c8edb3f8fecc4c88fc4256ed43b6fd6dd8e6",
                "size": 395470576
            },
            "errors": "",
            "filename": "2023-11-01T11-22-20Z",
            "size": 395470576,
            "verified_at": 1698848362
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698838019,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "I5bgIC5F6MVTONppfh+Hhx/I8AhUn3NbCPh7SI3HH6QLJ8WeT8RPthguA64EaN7JzROdUmNzVptGtDZfGarTtnbFWsvSPcdPuE6a2A2DhiMJvyDC7QrWwT4pMR7s+cDq+z488owgrJGUldwnUZmV17UAIzaMSaRwmCbBGkYg3VpQ18rlHQ0S7xP9Kx9OvdTVmK28GB5p0s72VDlP9+K/tcKPDFChaIcwRqjNSPO6c9xEv/NmfajaobUBE5PC3vPgkWnAxWW8qlnE9+/WYoAd+X8x7QsOMm+jgAKu/yZmi+AWboYrUAGAXlUOd/cHjmSSsKs8aXpQZeyX7al+15u03hlhhc1DZ+xyLLgpLQ9GWNBn3RVxsw1yVc4UILnbNCCAcE1j7lncskOoxzBn7KYS4XsVwQ5n7NEEXCFzArVeIfArV079xiy4WQKA77GvMZs0s9MiYB50SYdu1iM8QXZAOlFoFcUzbnm1JUNx6MGlDsQ5oDH5GeDUSUsEKkq3vhYygWHZLFDqhfAqFMVBKBaXuhLsBq4f1cvSKCO5Mg2GkipyOAxCfhfu9JciTa18lynJV5npnnbnQ2vVBTZcVVGO4JbkRojOe8uklS4zHYtxnK4BGFKbtLIQ/63KGlSd4JkRPYTLjS9D93Vz6QQaWXJu7OpCJvmH4RsNgHHCptPECUU=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-26-31Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698837846.4685454,
                        "modified": 1698837846.4685454,
                        "name": "/dev/shm/incoming/2023-11-01T11:14:05.ais",
                        "sha256": "236b97e7db65933527b7dcf5bca9a99e16c2778f09041534acdc518cda679cf4",
                        "size": 659698
                    },
                    {
                        "created": 1698837864.2285454,
                        "modified": 1698837864.2285454,
                        "name": "/dev/shm/incoming/2023-11-01T11:19:22.ts",
                        "sha256": "ae3ae155830b2665ae59422503b6a7cedbe632ba28d48d1707b6ee5a5628c800",
                        "size": 76131540
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "d039c6e0fdc8f9278a36083a416814fcb08ce0e366f0cedda5598096b5828703",
                "size": 71871627
            },
            "errors": "",
            "filename": "2023-11-01T11-26-31Z",
            "size": 71871627,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698838593,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "L1+LqC52U2Pm4+/INP+dZhr5mDsNXEzB/TL6OPRmyu4IsepIndnCT7u1jJx0oak/UmGrZziIOWixtvRTbfplGrpcuUWWyhvHO8W19Y7Q8RoLN1SmortdhyJNdPr/s/tqdV4tJ9GBq9ASFEbGdUyyhjEhvk647fn/aX4gFkE1LMwd2x6469jwzTqauLoLSrhfCihxtNoFivVUIIEHB+2qamgpSwclrlzB2LdgoKdjP7Q+cScECmR89cfOkY9el1UQ5hs5ER/5PouyJOXsdY2kTFdOzvdQ7sP0gg/MxJsJ5z/8eGnOetqx/ugfftMEIu8n4GbYc8h+VOF9fptqz+eJ9UA3tlFLS//DkS7rWfVUCqhIjxJZHYm/79WAgyACMEAjQczuuct41uArzTiBWo9oA8HKaaVU3JHL04UEA1CyZIwNTMb23oU9Q4je97Tq1eEKINa2gRanPARBsuXOvYUtN4+XWC/qr3i8BMHeJVnu+R+MJnHHw58nl40jLyiDGbsSTlxI/n26Fcd003qTkMZNAVaHdDoLBKv+j07IUUw4F1OfB9kxPcrCpTYVptWM4KJ1CnJ02b9ZIxoma92KNax1bqAIcb1EteBSfPcXAPsgSkSSb7ijtyXMO1+tJAUZ6GYP3UKfa7agbZgnehCZGd+PbTKXhF95oIF9Nurc7gxyRXE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-36-14Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698838447.5385454,
                        "modified": 1698838447.5385454,
                        "name": "/dev/shm/incoming/2023-11-01T11:24:06.ais",
                        "sha256": "e3ddd007b869fac6cd08d2a07be7de9afe32c860663fd70c0e9e23b3c6ccf059",
                        "size": 622959
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "84b483646d80815a218973af2a6172324d3a2efb5f8ebb60805d3df8e8d55247",
                "size": 183832
            },
            "errors": "",
            "filename": "2023-11-01T11-36-14Z",
            "size": 183832,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698838743,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "FhvkuQRTvx1Iy/qwkSScqwP+nA0M0qFzbwVsBZTvE0okNKZFTRTVPdKd0uMraiB8ooILfjLtokt1Dwg0RiTYrhpSP9/zTsuQ6V+9t0VAoKusB7y0vGGLRG3cHSuKhb9n2HiBmIpEHNcyEM8eBBJ3G62+2YSo2HoTZdu5ymdbfTriflNK4d5+XYqug5F1zsLVFnPtA0iEwDhV48Ezrnjs/UPdUtdY16mKI3R8n3UGGRpb95AVr0TcDRMEsytyob9who3WyI34AJQZeWQXZIbAGZNOBzfZXSbQJpNHiAXrfQw0Rax9brC2kWD9H+XPyrBNK1cmpfn8es51aptVnmucJq9pII0foZtohhHynNIGRh/KoZtw9cjSztd/NwyW/nT7zZBdLjUfK1YAgf5YSS7w6dVcSlEezj2ZQEkAyH2ERkLbxxhObh1yLqqNIpx0CIXUpjawbJx0sN5x4VtFE8a56kFTVthn/FxMQtSbewtxLLUwEJFkJjEkRgfX0j/UBUuUc+WceTR3bYJzKPyqdMQqtxEBbwx851CDjmpehZF0I+il+XSg9KOJr2AZ8R5p63Q2B9TxvJlxUac1AkWbYGJ5BgHa9fvrXcZiHU01rqufd6VcEMSPBvrcykYf8lh1YDJUPh19m/avaOPjzJ2HvXoiOqc0vyNJhZH+TlomJgpHA24=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-38-15Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698838571.2096627,
                        "modified": 1698838571.2096627,
                        "name": "/dev/shm/incoming/2023_11_01_113549_00_00_K_093.txt",
                        "sha256": "4010bfd926e066482d59b49d6dcec02e7d642dfcaf653c4c25782aa1249e4362",
                        "size": 2050
                    },
                    {
                        "created": 1698838572.2096627,
                        "modified": 1698838572.2096627,
                        "name": "/dev/shm/incoming/2023_11_01_113549_00_00_K_093.kml",
                        "sha256": "2833995c07f332d690bd84177b6b6f5dff95d208ba5d65a79b57e65234a9e2aa",
                        "size": 979
                    },
                    {
                        "created": 1698838570.7996626,
                        "modified": 1698838570.7996626,
                        "name": "/dev/shm/incoming/2023_11_01_113549_00_00_K_093.jpg",
                        "sha256": "e38f6d8efa9eb4b074a059b6f5bed25331675dc61242080280b80a000db61976",
                        "size": 1203504
                    },
                    {
                        "created": 1698838568.6496625,
                        "modified": 1698838568.6496625,
                        "name": "/dev/shm/incoming/2023_11_01_113545_00_00_K_092.txt",
                        "sha256": "6e40b4e2bbaf8d693ba8f350b3b3f1e0ed3b0f1690a6544f2c0eb62cd6fd6bcb",
                        "size": 2050
                    },
                    {
                        "created": 1698838568.4496627,
                        "modified": 1698838568.4496627,
                        "name": "/dev/shm/incoming/2023_11_01_113545_00_00_K_092.kml",
                        "sha256": "3b5b15cdf8b517feb8277f230d0a8e68d46f11c5f62e0a617d50b1c32627364f",
                        "size": 976
                    },
                    {
                        "created": 1698838567.5796626,
                        "modified": 1698838567.5796626,
                        "name": "/dev/shm/incoming/2023_11_01_113545_00_00_K_092.jpg",
                        "sha256": "a968e65cd303b0835866b8b80f9f93739a15459099106179cef642acbb8d125d",
                        "size": 1342087
                    },
                    {
                        "created": 1698838558.6296625,
                        "modified": 1698838558.6296625,
                        "name": "/dev/shm/incoming/2023_11_01_113537_00_00_K_091.txt",
                        "sha256": "e03e63ff02dee7632d7bda68b890856de3fa2c9c4a6fc43383aa96420e519179",
                        "size": 2050
                    },
                    {
                        "created": 1698838558.1196625,
                        "modified": 1698838558.1196625,
                        "name": "/dev/shm/incoming/2023_11_01_113537_00_00_K_091.kml",
                        "sha256": "04ac09cbc9d10e4ff444935c6775b7d41a29748e513c0c706f6b4e6aa7572834",
                        "size": 976
                    },
                    {
                        "created": 1698838557.8996625,
                        "modified": 1698838557.8996625,
                        "name": "/dev/shm/incoming/2023_11_01_113537_00_00_K_091.jpg",
                        "sha256": "620132efda974417842438cf97ed17b0ba94acb9f9fd2bdbc2bbc4d75b0387ff",
                        "size": 989852
                    },
                    {
                        "created": 1698838553.8796625,
                        "modified": 1698838553.8796625,
                        "name": "/dev/shm/incoming/2023_11_01_113526_00_00_K_090.txt",
                        "sha256": "82baf001143819a09a87eb0cf53061f9f539394413e4e49ccd583cf681b258e9",
                        "size": 2050
                    },
                    {
                        "created": 1698838565.0896626,
                        "modified": 1698838565.0896626,
                        "name": "/dev/shm/incoming/2023_11_01_113526_00_00_K_090.kml",
                        "sha256": "3bc962efd5011882d049c487b2e5badf331dee8b1f4d8d328383e43681b7b1b9",
                        "size": 977
                    },
                    {
                        "created": 1698838552.8096626,
                        "modified": 1698838552.8096626,
                        "name": "/dev/shm/incoming/2023_11_01_113526_00_00_K_090.jpg",
                        "sha256": "dd3c58bc9f0f08973bc904c016cd8e871d98229068a7199a1cccd0dfbeb870c5",
                        "size": 1284280
                    },
                    {
                        "created": 1698838542.0996625,
                        "modified": 1698838542.0996625,
                        "name": "/dev/shm/incoming/2023_11_01_113520_00_00_K_089.txt",
                        "sha256": "0b85f1bebc0355ebf3d52eec681b08896a7a182ca226e9e6f19d873225769f07",
                        "size": 2050
                    },
                    {
                        "created": 1698838541.6096625,
                        "modified": 1698838541.6096625,
                        "name": "/dev/shm/incoming/2023_11_01_113520_00_00_K_089.kml",
                        "sha256": "04b885bcd2738edf202be867062ba06ddddc2da7dacd13ace94e228b6df20e86",
                        "size": 977
                    },
                    {
                        "created": 1698838539.6696627,
                        "modified": 1698838539.6696627,
                        "name": "/dev/shm/incoming/2023_11_01_113520_00_00_K_089.jpg",
                        "sha256": "dee9ac80674e5945e956f6cbbf19ae82c9db805fa07b9e29d6de7267a48eec5b",
                        "size": 1153559
                    },
                    {
                        "created": 1698838538.6096625,
                        "modified": 1698838538.6096625,
                        "name": "/dev/shm/incoming/2023_11_01_113513_00_00_K_088.txt",
                        "sha256": "5ef60675668b40ad517d0bda0c95ec5e0d696fcfe2f3e50b2f7ce5ccfaf58307",
                        "size": 2050
                    },
                    {
                        "created": 1698838537.3796625,
                        "modified": 1698838537.3796625,
                        "name": "/dev/shm/incoming/2023_11_01_113513_00_00_K_088.kml",
                        "sha256": "57a5401f76326bfaef332c066639df38482fe7da781b52500bdc6aea2cbc4559",
                        "size": 976
                    },
                    {
                        "created": 1698838530.2596626,
                        "modified": 1698838530.2596626,
                        "name": "/dev/shm/incoming/2023_11_01_113513_00_00_K_088.jpg",
                        "sha256": "68ba12bcf28d90dc03aac193f86e7f2ca1d77cc38e95147920217e8535d0ed8e",
                        "size": 942384
                    },
                    {
                        "created": 1698838502.8296626,
                        "modified": 1698838502.8296626,
                        "name": "/dev/shm/incoming/2023_11_01_113430_00_00_K_087.txt",
                        "sha256": "b993d40ccb1e49770060f8ade066a7c9136739c49552cd658fae7f9b07b5a1d6",
                        "size": 2049
                    },
                    {
                        "created": 1698838492.9096625,
                        "modified": 1698838492.9096625,
                        "name": "/dev/shm/incoming/2023_11_01_113430_00_00_K_087.kml",
                        "sha256": "8f993059618ee79e42dd7a75d20fc975a23d08889f9a107592b407bdca06f412",
                        "size": 978
                    },
                    {
                        "created": 1698838502.4496627,
                        "modified": 1698838502.4496627,
                        "name": "/dev/shm/incoming/2023_11_01_113430_00_00_K_087.jpg",
                        "sha256": "17bbf4d3e521f167418503abf06b488005ef943409cc96479ae0eff75804b04c",
                        "size": 1200862
                    },
                    {
                        "created": 1698838490.2896626,
                        "modified": 1698838490.2896626,
                        "name": "/dev/shm/incoming/2023_11_01_113417_00_00_K_086.txt",
                        "sha256": "fcbc26319506d0292fd53babff79f905c4ee7f83c6d8a4e6e034457c0d91c088",
                        "size": 2049
                    },
                    {
                        "created": 1698838481.6196625,
                        "modified": 1698838481.6196625,
                        "name": "/dev/shm/incoming/2023_11_01_113417_00_00_K_086.kml",
                        "sha256": "a800cfb924864d9a4c114bf355da1cb2fbd53d616759f7afb280fae664404c6b",
                        "size": 978
                    },
                    {
                        "created": 1698838479.6596625,
                        "modified": 1698838479.6596625,
                        "name": "/dev/shm/incoming/2023_11_01_113417_00_00_K_086.jpg",
                        "sha256": "9077b3d5fd80eb3af84453626b3ab4751e053919a0dd840bbfe6fcfb503b5d58",
                        "size": 1069743
                    },
                    {
                        "created": 1698838455.0796626,
                        "modified": 1698838455.0796626,
                        "name": "/dev/shm/incoming/2023_11_01_113347_00_00_K_085.txt",
                        "sha256": "1dee4701ea56cffa929cadf1193561f3731c8bc15a319129eaabc6e3f9c5b13a",
                        "size": 2050
                    },
                    {
                        "created": 1698838452.4496627,
                        "modified": 1698838452.4496627,
                        "name": "/dev/shm/incoming/2023_11_01_113347_00_00_K_085.kml",
                        "sha256": "f48872e15f6fe609138343658f8587a847107daaf1e549f781f4ea3c0a46202d",
                        "size": 977
                    },
                    {
                        "created": 1698838453.3596625,
                        "modified": 1698838453.3596625,
                        "name": "/dev/shm/incoming/2023_11_01_113347_00_00_K_085.jpg",
                        "sha256": "44bc715ce084e49a303bccc835a27378bad47225106eed15f3b7d3d8bb0affee",
                        "size": 869229
                    },
                    {
                        "created": 1698838429.0796626,
                        "modified": 1698838429.0796626,
                        "name": "/dev/shm/incoming/2023_11_01_113312_00_00_K_084.txt",
                        "sha256": "2fc9a7ae8b5f2b6eb6a52ad8987fe4fbeece177d6a903a660bafb920b460b518",
                        "size": 2050
                    },
                    {
                        "created": 1698838421.3996625,
                        "modified": 1698838421.3996625,
                        "name": "/dev/shm/incoming/2023_11_01_113312_00_00_K_084.kml",
                        "sha256": "90170cf4ecef393c130aa8ef40cdd27d21606f721c10564e6ac6e71f32004e2a",
                        "size": 978
                    },
                    {
                        "created": 1698838416.9196627,
                        "modified": 1698838416.9196627,
                        "name": "/dev/shm/incoming/2023_11_01_113312_00_00_K_084.jpg",
                        "sha256": "407622707f76db89162ae1e8b461dc07d85d62f8876461de55238a143b168487",
                        "size": 1145793
                    },
                    {
                        "created": 1698838395.2596626,
                        "modified": 1698838395.2596626,
                        "name": "/dev/shm/incoming/2023_11_01_113246_00_00_K_083.txt",
                        "sha256": "7705d091ce0894eeb1a83546ba45038d16a01677f186765904b7192c77b3cf53",
                        "size": 2047
                    },
                    {
                        "created": 1698838389.4696627,
                        "modified": 1698838389.4696627,
                        "name": "/dev/shm/incoming/2023_11_01_113246_00_00_K_083.kml",
                        "sha256": "7883759cb30fc96abb664001f2f4ea98e650b341d74d1d55fb22b12fa2b21f33",
                        "size": 978
                    },
                    {
                        "created": 1698838387.7596626,
                        "modified": 1698838387.7596626,
                        "name": "/dev/shm/incoming/2023_11_01_113246_00_00_K_083.jpg",
                        "sha256": "6b6d5317f150b424dd5bdd70d42763a2b45b11a64eb83bcd166c03781669bf2d",
                        "size": 1107797
                    },
                    {
                        "created": 1698838335.0896626,
                        "modified": 1698838335.0896626,
                        "name": "/dev/shm/incoming/2023_11_01_113154_00_00_K_082.txt",
                        "sha256": "04325e3b32dd95f210ba8b2702ecdaae7b0389b60dd4810d2f1052ac5c8bc893",
                        "size": 2050
                    },
                    {
                        "created": 1698838335.7996626,
                        "modified": 1698838335.7996626,
                        "name": "/dev/shm/incoming/2023_11_01_113154_00_00_K_082.kml",
                        "sha256": "dc50168a0447fdbcc116ebab1a14a01ab89a8bc2a1164030b6b99eaa96c5fde3",
                        "size": 977
                    },
                    {
                        "created": 1698838342.0796626,
                        "modified": 1698838342.0796626,
                        "name": "/dev/shm/incoming/2023_11_01_113154_00_00_K_082.jpg",
                        "sha256": "1dc7af23f2bce10ac04148c9574b905e869b3220de89f1295698826d4cc47394",
                        "size": 1025052
                    },
                    {
                        "created": 1698838331.5496626,
                        "modified": 1698838331.5496626,
                        "name": "/dev/shm/incoming/2023_11_01_113150_00_00_K_081.txt",
                        "sha256": "d5ec08feeee256e206ee917fad6c9b16cc6c4b54fff0698efbca64745e262f69",
                        "size": 2050
                    },
                    {
                        "created": 1698838331.2996626,
                        "modified": 1698838331.2996626,
                        "name": "/dev/shm/incoming/2023_11_01_113150_00_00_K_081.kml",
                        "sha256": "7fa09d2ae3e8ca850191b0ad78bd4b98f9852d053dddd455c0246c0772eca3ee",
                        "size": 978
                    },
                    {
                        "created": 1698838330.6196625,
                        "modified": 1698838330.6196625,
                        "name": "/dev/shm/incoming/2023_11_01_113150_00_00_K_081.jpg",
                        "sha256": "d1c62cb50b78af2b5195328b618bb0468cc6c6b2904fd626e8568b87e6b0e2f1",
                        "size": 1246835
                    },
                    {
                        "created": 1698838329.5496626,
                        "modified": 1698838329.5496626,
                        "name": "/dev/shm/incoming/2023_11_01_113147_00_00_K_080.txt",
                        "sha256": "2f2ea7a342bd32788cd12eb99c0948d8de9fad673ef1ac329f57d661c41d6879",
                        "size": 2050
                    },
                    {
                        "created": 1698838330.2596626,
                        "modified": 1698838330.2596626,
                        "name": "/dev/shm/incoming/2023_11_01_113147_00_00_K_080.kml",
                        "sha256": "169345633885a4d4a66cda74abe18933d7cd932d477d8f939d197279fb125e0b",
                        "size": 974
                    },
                    {
                        "created": 1698838328.9996626,
                        "modified": 1698838328.9996626,
                        "name": "/dev/shm/incoming/2023_11_01_113147_00_00_K_080.jpg",
                        "sha256": "cb70bb0cdd652b845e926bb7f679ce19488de7621e0973db72be97571fa5d9f8",
                        "size": 1132413
                    },
                    {
                        "created": 1698838295.9296627,
                        "modified": 1698838295.9296627,
                        "name": "/dev/shm/incoming/2023_11_01_113113_00_00_K_079.txt",
                        "sha256": "d6ffcde612b1c2c8ff7847ec86a148daafd3203b5a7fd46bd532054d904765d4",
                        "size": 2050
                    },
                    {
                        "created": 1698838304.0896626,
                        "modified": 1698838304.0896626,
                        "name": "/dev/shm/incoming/2023_11_01_113113_00_00_K_079.kml",
                        "sha256": "41e497aecb6d0788319680114179288b6487e32914409320483bce083c09d9a9",
                        "size": 978
                    },
                    {
                        "created": 1698838297.2296627,
                        "modified": 1698838297.2296627,
                        "name": "/dev/shm/incoming/2023_11_01_113113_00_00_K_079.jpg",
                        "sha256": "9613e2278c89afff84f13e110568d47521790511026688e3758d5af7656ad605",
                        "size": 619204
                    },
                    {
                        "created": 1698838286.6996627,
                        "modified": 1698838286.6996627,
                        "name": "/dev/shm/incoming/2023-11-01T11:21:25.ais",
                        "sha256": "af1732015363fb7c23331a9ef0bc7c2b8d388f53d4de4bf34a8723a09986ed2d",
                        "size": 422408
                    },
                    {
                        "created": 1698838304.1996627,
                        "modified": 1698838304.1996627,
                        "name": "/dev/shm/incoming/2023_11_01_113106_00_00_K_078.txt",
                        "sha256": "5e0bb28c7906a77d153db78a46a47ea443caf2eea164f9854fa3a9d1cb34e521",
                        "size": 2050
                    },
                    {
                        "created": 1698838287.8796625,
                        "modified": 1698838287.8796625,
                        "name": "/dev/shm/incoming/2023_11_01_113106_00_00_K_078.kml",
                        "sha256": "fb05664c44958ea45da05b9aaa91a3c9afb34acca1f663613119fc2ff4e6b73d",
                        "size": 975
                    },
                    {
                        "created": 1698838285.4696627,
                        "modified": 1698838285.4696627,
                        "name": "/dev/shm/incoming/2023_11_01_113106_00_00_K_078.jpg",
                        "sha256": "4fb1abd8b8599bd99647fd0cdc0ac5b6c7bb81bdad9cd6a4c15bb5f08bd4cf50",
                        "size": 641881
                    },
                    {
                        "created": 1698838268.8096626,
                        "modified": 1698838268.8096626,
                        "name": "/dev/shm/incoming/2023_11_01_113042_00_00_K_077.txt",
                        "sha256": "654d352f77592b3d120e5adbee9b729d409d964847df1be2cbd47891e6c48741",
                        "size": 2050
                    },
                    {
                        "created": 1698838268.3596625,
                        "modified": 1698838268.3596625,
                        "name": "/dev/shm/incoming/2023_11_01_113042_00_00_K_077.kml",
                        "sha256": "b92c92217bd8ccffc62c880182e9c0bcb0d57a81d71531b047424e2e29d95435",
                        "size": 978
                    },
                    {
                        "created": 1698838259.5096626,
                        "modified": 1698838259.5096626,
                        "name": "/dev/shm/incoming/2023_11_01_113042_00_00_K_077.jpg",
                        "sha256": "7c01ac6a6c821ec01e5b34b8092f60ab82fb8ea8acc3cbfc4f23852430f30a36",
                        "size": 1138063
                    },
                    {
                        "created": 1698838188.2896626,
                        "modified": 1698838188.2896626,
                        "name": "/dev/shm/incoming/2023_11_01_112921_00_00_K_076.txt",
                        "sha256": "af6a22e539af13a48d380b8fe123364109e0c4d8cf162afd1c3b17496105cbe5",
                        "size": 2050
                    },
                    {
                        "created": 1698838185.6796627,
                        "modified": 1698838185.6796627,
                        "name": "/dev/shm/incoming/2023_11_01_112921_00_00_K_076.kml",
                        "sha256": "bf37747c70732b8ed629fa4beca40a210e56bab4bc918f8d98823fd9c614bcd0",
                        "size": 975
                    },
                    {
                        "created": 1698838184.4396627,
                        "modified": 1698838184.4396627,
                        "name": "/dev/shm/incoming/2023_11_01_112921_00_00_K_076.jpg",
                        "sha256": "60aabaed835eeaa0c44bd0db3ade396aaf48a3bf121e7d1ff017ecc36fe70a4e",
                        "size": 1162811
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "b415d46333c47bc121edc1c05ac4646f630e6ef438e41f81f1fea05b351ed266",
                "size": 19408125
            },
            "errors": "",
            "filename": "2023-11-01T11-38-15Z",
            "size": 19408125,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698838639,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "NgZVHa1u77Wghyeekk5blRfDkb2zH/bLekjZlHXKvWa3gg49VNJ12XRJlCpoFMeeqGibwlWnipnbHbX3HB0lBfW2Z1U/bsJfhNVAgkCrDkfk1CZ78r6IKBbySdk09pisNK2vDWzTgc6A8reTLhM/YVQwePUEmtZGD1izBgx3+YHJsaLZfN8BVnIx+nYPA78g8k4KgysIky8HFk1Paw3+LTR5QOx2dmY6eYG0/Q21MP2ar7zoBAaYjMkMSWo5rQQoswxJ0eGfbmqKBcuoJ4l9TSvY0to6bG6lkyhEfaYSfOqyxuu+kXG3Dkrcu/PLv6uR4WAaNJFzMdW6IavLKJhU5BlHvU2FFT7gOCv4H3it56hKb0bbx84Joi9i45bp58l4xtmyAdedu0I337LLwcaPzG3G8H9095Ln/7BgXQ3yyJDqWjWfTOYYW0F3023mtgCdbHdLPs65gIIoHeF0/JGTNwWoi03sc7pz0Bslq+T0Ofv+Dz3jvvnMFFeYTkha9TJKH+zwP5nGNcqBEAOwyKbDxTaGcG5xxIKHBXlQRlKiU0RgQi/8hzjCMDuOU3Gz8M+tikDEitTvWIPGhnRlGp/u0w+d+/Q+zTfm3b5yXNUXegwyzdoBagBSTqD7zyhMfMUWPhN6ionrXBfSwDdKwI1/gXYAjtJYRALbjR7pWjv1kzg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-37-10Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698838503.56277,
                        "modified": 1698838503.56277,
                        "name": "/dev/shm/incoming/2023-11-01T11:25:02.ais",
                        "sha256": "9bc97f8369638a3353e82f379f2aed368be5d0fcd616945f1d59df5baeae8671",
                        "size": 43817
                    }
                ],
                "mission": "EFCA1",
                "sha256": "2f31eeaa7de074c4411bebb3fb0afacea36cec5f85898447865ff33d90f9d6d8",
                "size": 9502
            },
            "errors": "",
            "filename": "2023-11-01T11-37-10Z",
            "size": 9502,
            "verified_at": 1698848069
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698838804,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "PF1R+FM5apw4HQ9XqeMDFYsg0ZP8qNLl5ehDZbY6Hhx+ovZl7rs9g3v2zwMHI/Y7JiHNA1IC/Fcb2i50PA99/q8DK3qDp+iRHiTIbRA0Wx4mfQcoWZOp77fi9B2aVn++IMLutjuGMOpqMUg5PnOr8gmmgWjWk/siovnZuFhUQFIL2AuECEDS6qo7mfyBsvJMaGgmF4gnudw0aWRtkFrliyn6Fo0Rq5upvBHCa42J1GMSOzXGIfHCG/D8/mfPK1K+pZ3OuEzL2Ay67nutVC+nEWvs+gA1Ac+Pg3WWolF5Y8ZCh4NBJzpO9FRG+peC5hUiP7kej6joxyoqhJzih0+gFa8AMj2LpZ+FyTtCTrAAm2GmPQKttlfxF3I0Ze+9ylKsQhK0/ZL4QaDtpVvStwlxxMRc/h8ys1rSe/6vtKOK5Buw4vTy5PZbHd+xOSadWeZdYE31o3ITL3x9R9WiCb++rdNE6BQV+0l266J/btTmS69Q5QHnltBlOgq3aaaZbAtgYCff4R/Mt5ywDU9d1FxYBl1xMXDU06jIXCX+6cjxf5ZfyPFU1dtmzu4kanlYcmlb7d8Fc5beg6LsgftGPxu7R0RRC+9TwJnOwQZKrGy2m4XnglA8wIgXgFIOsyHTtpSTwn9JcgmfCs/AtN/o/5SdWjGpy/08XHsNW383b6piH+Y=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-39-31Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698838762.0027697,
                        "modified": 1698838762.0027697,
                        "name": "/dev/shm/incoming/2023-11-01T11:22:15.ts",
                        "sha256": "5dad9daf63e5c2f2e668595d9200bc84b1b1338835892781aada2037e246749d",
                        "size": 234188968
                    }
                ],
                "mission": "EFCA1",
                "sha256": "3ad6f0ae5c2290368788682fe6bd82929f812180df12b866b319e838156de048",
                "size": 225823876
            },
            "errors": "",
            "filename": "2023-11-01T11-39-31Z",
            "size": 225823876,
            "verified_at": 1698848153
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698658969,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "vFOIz5E243TgFBoTmOl9dCmND8ANiH4gu+qWP6ZvrXvXqc8IG3Aei7A2M+rUKf3KOfZ2E8xuhXY+OaxRi0epWNUSh2aDvVEG+I9CghLYOoG9Owl3ZVWC3tgBjFjGUUoSZM4uVBOv6DJp75y/edcgu0p9KlR8ZjCoAqUM4E3O8exqTyh7Wwqm8pNyD0uc8qaWHcar0I+196p2vLLxKg+qhup10jU3odziBwokpcdWIKhpblaGbZFYcbWLD2zAwWY4PMSQEfCpFUbNJoPXFYXpfOAxTxM+TEaWE4JJ+h1Nlyqbv7Fnzg/GjtKi3E2T3FvElNe92W5yPs0/IFaJG4vgZGam4lsXje3iCpiYgLIhw8Q7UM9sDxISGBWXoux15Xwt1a0po4HMrEa/o8ZvNCB+nI6/HsXNr1NUrNZi2lE5cD8P3xLRnthVzUVuMufOWJikKGtXMugdFKzVsoh2BIj5nL5q9FfDARUPqbOvMMg7wdggMBIkWaKkMGwNNQ+DXaG5//sRoXgnGbydl5RYpTWqauVH6DCEm6ipybOpdE1Taka+QvN1PiUCpi/n+edpfD42eCk9jgkXcvKRu8kNvVr4zafRoEid4ZuZ7/SlPlWXo424dbuinTKfF4eFm9pgDxKPrQiSP5NT7WyJEQF0EMvOXHDKYwUKbwaKS5W1WFxbYYM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-10-30T09-42-55Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698658840.1832688,
                        "modified": 1698658840.1832688,
                        "name": "/dev/shm/incoming/2023-10-30T09:30:07.ais",
                        "sha256": "195376de9d983db0c444aaddd1eb7f4b8ca7d1a23d6528e179c92a8bd6f333b0",
                        "size": 3654
                    },
                    {
                        "created": 1698658844.303269,
                        "modified": 1698658844.303269,
                        "name": "/dev/shm/incoming/2023-10-30T09:37:59.ts",
                        "sha256": "dffb6bdea68161f39eb9599aa9529531d05e0d2644c3617a50c06bdc331839cd",
                        "size": 37077924
                    }
                ],
                "mission": "EFCA1",
                "sha256": "d7c0c55ad2a1ae806e0f0b576c9581428d0f21739fed5baee44e7a12eef28e78",
                "size": 35661375
            },
            "errors": "sha256 checksum mismatch, please check upload (75bf4dd31a25f5a4dcef3a48f4fe00b3e5e2eaed3ac053d34b9a8e442d461bfc)",
            "filename": "2023-10-30T09-42-55Z",
            "size": 35661375,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698839065,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "oMxEmzhJtlyOwvG8Lw88IJc0hMLD61oB1xLcSc+E+Zwb+ymwmTcCp0+i+rkF2tkPvfNYq9Xo4bDQIwoor4boi4eVQrOH9ZwAFcg91sTU6kt5KqMJhehyZBm9xZd2fmlN0iTJF2HVa4io70bxnqziUFQ45PDfoATNghunSffyk1NewsEwbPsRC1PRT80HwrC4YIDu2Vr6uOI3y7M3SlSYiZakOSomaWFkcdNYQPi1/5e/QyPwsygoVQ3HjHCuOMXsObfU4dJuG/Yind8PF/D+dTE5isvOJdiOR2tlw/j88InZzQk1opdLwP7IX4VzvbUsb1wZF4lxfdz/gZ8nqoQaLZIqgVYZGY1slvA3ml3N543kfZlxXOp0eKBwNEOjnhEKxUxxB0ij+vYgOp1NUoe+Jou34JNOvT//sD5vNKFs0WUw9P47Rk1w7n5kWnhQvlBSSEdUzzZIXuqJU6xNnt1Lg+8ADQYhZGT0gXpwrzTI4w7VAUBQv7ZJyxkST6gcxziLrwEUdvJNDJaGFDX3fYhy4XvUdWYGGeeasx8qNDUTiyq1/njTUIJtvw3F99bE7ZK7FewpEs6L5v/CFKs4wM3BBhXs1MNwR1uJNcsyS0r6/n+AzP5BOVMCsavxthi6GFRJaHTo8dVj/TfX+E/hCS7LyG9Ur+GAn3cdrrQKac3MIXI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-43-33Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698838887.6596625,
                        "modified": 1698838887.6596625,
                        "name": "/dev/shm/incoming/2023-11-01T11:31:26.ais",
                        "sha256": "81f0d809545a59c3f961e2558cd97a5344a61bdf32aafdd3bb630a56e3135439",
                        "size": 426000
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "c01db369538e525067c8eeeeb6ef89f85699f9e645e1ac0712d92505a8338218",
                "size": 128880
            },
            "errors": "",
            "filename": "2023-11-01T11-43-33Z",
            "size": 128880,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698839110,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "pU4SzemgHygExMW9ppc87KcCpN5cQ3eD4t5TZeluXgR+BPXLo+oPLngYLolbTyZfKa9QiMdNqF/pIM3NI8R1CvklMcGu1Hu8U6dBhpdgRAbHVWFqTDfnwDTIRa+i5UBWFafMIDMKl0RDNGT08iYG1MEdKD1XLvjbZG+8+fYlloXubN9cmBaiJhXuD0655GZysHWc0tcDUw1MvTNCc+bI6+nHeEGx3mtohTuCPgDYWasuschwiQR506QA4z+j8jIAOwnci3iM0e6LQUG6WfI3bBGuBzBXLWk8E0VAYUg5dz4D6w3kRP3d3WtxRwwf6klVGw+finxwcMjaO9jPKtbDtSmtliLoTG+OvrTI8WIF8yG7iGiQIjfjLhm9IWsBaGmZx+7juV8IJqjNLaQ/Hj7esUQkYwCaLapb1FmGcN3YNEVNwRUXZdxdSLZKIzwcrcD+E1FFRWnfQ/2RFDJz/o5E/2Bi+OWrtq0uGUr/vQojir7k73FXRouTYDv/aG6pYAkTY0HqsQfoLsJ69xjB1mJ4PMRZZbYHuv8jmTieJOMIDdXPUQilJbpUURiP52jsJ8Z2Y+rPDOEDREuxMd9H9fPJui1As6pBNWsF9kEtwBsqBBJ0bxyZJvv48MoTL58yEpcpiZA7FF61y9oL5C+2yzZWonGNAN5Vjvli4fU/kySJAVs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-43-54Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698839027.7796626,
                        "modified": 1698839027.7796626,
                        "name": "/dev/shm/incoming/2023-11-01T11:13:47.ts",
                        "sha256": "0dc1a3cfb5d3aafab5bb3f87939bbfe77fd08eec2f5a15e861e7e4b32a3896da",
                        "size": 442022968
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "3c6de097ed44709d39a8c22b66f563ebbe3f7902d1365695cecf30311b898076",
                "size": 397868918
            },
            "errors": "",
            "filename": "2023-11-01T11-43-54Z",
            "size": 397868918,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698839665,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "gH+qYE38PmXD877qZpWAYloO3XPpElYU5pyw4E3HcM+1OJksmfpZzkEAkMwMOPTAWIE96DZgW+vE0jsDQuw2NhH14Iqsixt3qgxjofYI4rPaGVrw2w8eKc6gPUAe1cqAO6+vx29i4ZD+poivnZyqdtK4UX6PU7ji+8JMTpBG2b+NK5T1xYFCvtVMg1eHvoTbiwwX4oPpF6U3+NcsRTC3KRJJbsVHqkdyLSbo1gr7yoaGC8zz/4bLPylDQEmSyn2Nth64IRpVzwOyO3EwuqILC6NZfDVHsqr6WfFwTinyLBmYwB3euWEeWnA9oGiAtY23j2QyK8zZtmRUFRD0SPueDHawBn8S4oBzdA62UTt9Azab3uG2AknEzCeYkMIm6bm4XKbFbjFTE64Q9PligNcrUieezYn83rIvm5ovze4KoRprLlqyGidwH+3e11NiFRnLORyNe+gHvyYEa4E/u3D30U+AfnbBVQoSZYMnkcLuNgUMQwuWWHY2Qd/dvjmbF9o64TMXvhy234s9z3PY5+GjVWNFnajOTAc/JZ6A975Ut+rI9psa4BFAOfI/YUV6rIUgGbDSLtQc+1EtMoKJo3OrBYZeCAt8FQF6ev9ElPeEbX60nWnTlzJ7Z9AgonfTXi+Kg5xUgzJb9iwIl37pn0bnFppjw479JCLm8ejxHMRjZpo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-53-34Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698839489.1396625,
                        "modified": 1698839489.1396625,
                        "name": "/dev/shm/incoming/2023-11-01T11:41:27.ais",
                        "sha256": "a7bf2dbae15f6ab26197c6c5e31d2942abd6fa364521620873c376c674814dcb",
                        "size": 436517
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "537c9564f00e1cf3c78faf62efc077c55d23a96a5d78e48d378fbde12a054f1e",
                "size": 130378
            },
            "errors": "",
            "filename": "2023-11-01T11-53-34Z",
            "size": 130378,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698840329,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "T4B1AIinnlShfEdT+3ZZ5uXU2kotr3ZxRKpKE4x4xyA46lbRQYTvnG5jgnYwV0YS4u0Az4hplYTrpn6ELIqTEh4mOcIHRgf0B8mma3isPukPCw8xSPqQXiZM2h91dH61xvlzDZXjoTkKbeQD337guNE45uA6lkwc7A/nXg0PUB1Pq6rrCSUuo5lnprQw1sMsJlHSkM+umbg1f9tS18cHMl5jFiw8+zRWb9CBsYDTTQg5jVT3Ck9M5C53XuhkLhjMMa16DONgt5hsKCIqrPylW/A5K5KrrxJiNClfTa36XeGCEEP69qAqFZtnKERCf3dyGRov7OA9pNau8oBaD21SBnxzFGRkXdK4vhphZbcv00ez7b79uAoUC0F0mFdebfvafMUQx5lWV8oU6BWYb+d/B9JoEGj4IJfjXPSCEhhundcXwDYnRl/4uPCNKLu/78eWo9wLLG15ihB0pIFFVdl3KBKcIvOgGafgwGWgovUU2xo+YyprNTMzVGbYL3w6B/Qt4bkAUYVmSlno1TGmohrOfa+bc4dvQgz6X0w2woK98Mvmi0bU2BS3w4PXYCw6WZtzUc421O99ed5CTt/rmxaWfa8NTFXdmBP1UVLwlkRMeWIMGmDU4qCJI+BEzUnBhGfmQePzR/pcK9A1QKv8h5sH2GvE7fSSoVx/hheGfP2+Y1Q=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T12-05-08Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698840185.4396627,
                        "modified": 1698840185.4396627,
                        "name": "/dev/shm/incoming/2023_11_01_120242_00_00_K_095.txt",
                        "sha256": "3be07aa29be053c3492df5a60f5b98d95478c60408b97369be23c042528f1349",
                        "size": 2047
                    },
                    {
                        "created": 1698840188.3996625,
                        "modified": 1698840188.3996625,
                        "name": "/dev/shm/incoming/2023_11_01_120242_00_00_K_095.kml",
                        "sha256": "fe8215db7ed5e01170f930d544d93eb96fa39e116ba2cb303be43c740a84abe4",
                        "size": 978
                    },
                    {
                        "created": 1698840182.7496626,
                        "modified": 1698840182.7496626,
                        "name": "/dev/shm/incoming/2023_11_01_120242_00_00_K_095.jpg",
                        "sha256": "c5df4cd7763e3f9b4b901af21bca6ebff630eb939215b13907623f8504d353fd",
                        "size": 1074018
                    },
                    {
                        "created": 1698840116.2396626,
                        "modified": 1698840116.2396626,
                        "name": "/dev/shm/incoming/2023_11_01_120129_00_00_K_094.txt",
                        "sha256": "e2950ff6562dd0d32043b76c1e7c28875675e3d5509eaeb1bd423d3f98a0e8a6",
                        "size": 2049
                    },
                    {
                        "created": 1698840116.9196627,
                        "modified": 1698840116.9196627,
                        "name": "/dev/shm/incoming/2023_11_01_120129_00_00_K_094.kml",
                        "sha256": "a2b80d697d24f4397b1603d4180cf54c001a696aed0ff795304e5661de645ba9",
                        "size": 977
                    },
                    {
                        "created": 1698840114.5896626,
                        "modified": 1698840114.5896626,
                        "name": "/dev/shm/incoming/2023_11_01_120129_00_00_K_094.jpg",
                        "sha256": "95f7b46d710a5df678acef117be834891f4162f03d2f9732bcaf6c17f39d40a8",
                        "size": 1019498
                    },
                    {
                        "created": 1698840091.1796627,
                        "modified": 1698840091.1796627,
                        "name": "/dev/shm/incoming/2023-11-01T11:51:29.ais",
                        "sha256": "698fba69d54b887e8b2d8ffc7e2a217713b83b49ecfed17768462bbf1bcb91fe",
                        "size": 531879
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "43ac84a6d981733e23765efc4607af9c934e0dc3855d7467f282e76f9cef846e",
                "size": 2254574
            },
            "errors": "",
            "filename": "2023-11-01T12-05-08Z",
            "size": 2254574,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698840918,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "oZLu7OkXrVbPf7lieansPVXDS5wdZex+4Kb9VgfJo8YF4VTj0o8sKJ5TbRPmASlWq2qC8pcaNP5oZ3yTHl7S0X6tCVous/MMvQEyv+6JYiLINqkiVIrxUpQkNpzlKs5SSQODGm4UnP+qdigu8jUuv0pC6wq8HgLH4SNXK7xOgHo0CGTexQHzICdnglwsuBbbdEL/3l42WHJOntwNkSJFgv2UR42Q7uqD1k1qfI++oF5k9fR1HtY3bYvNVtzCeemgXgIHIBUxj4UpLUShp6BPm9Z++QRWGBsuvrVQqWpo7jO3n/CprgC6lhxRPlzYYRFf4QINhVDBFxjTJ58PcmUMepWZcqJuuMLEZrplHDlfobiXS/2PzTrrNOdFsdp7fGdEFeIzSii/+NTuh17CUVu8tTqXkKvapXEhumdn04RXs8Ami4wOevHVJyfbycLygcp3bSmP9MTZHKcNlhYuHmFhTu4xKTi2R/LnLPG13OmqHYgIyjrO2+zW2vrE87617lvp6AI+F58IjAc4/JlK9weaKgFb0P772UNzpv/tZ8Ak0G62FUp+uiNEfFDLGdsJrgFzj2FaJS9hLaAFwJFrYKG46PYROw+2MX1VupyeczvYgJV4zkveSe0ulWoHYdn9J2xuIyMrzzwQIX+pN1+ioU/eJOAJ8V2e/67hXLimCHPA3js=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T12-14-00Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698840811.6596625,
                        "modified": 1698840811.6596625,
                        "name": "/dev/shm/incoming/2023_11_01_121304_00_00_K_117.txt",
                        "sha256": "18e7dec3e16845ffad5eadc0d9789cc7bf6c72bc2f179005155348d89796744d",
                        "size": 2050
                    },
                    {
                        "created": 1698840814.5596626,
                        "modified": 1698840814.5596626,
                        "name": "/dev/shm/incoming/2023_11_01_121304_00_00_K_117.kml",
                        "sha256": "b1dfa182a6727cd554534db227127a53d7238ba31a20716acc7f00f621610473",
                        "size": 975
                    },
                    {
                        "created": 1698840811.2896626,
                        "modified": 1698840811.2896626,
                        "name": "/dev/shm/incoming/2023_11_01_121304_00_00_K_117.jpg",
                        "sha256": "23c7a8b0305d5f6ea4efa83a40916f4fa8287e0e820d27a2771f408d923f1380",
                        "size": 943572
                    },
                    {
                        "created": 1698840799.4396627,
                        "modified": 1698840799.4396627,
                        "name": "/dev/shm/incoming/2023_11_01_121254_00_00_K_116.txt",
                        "sha256": "ceb529aa1c7ec3d4fc2c74539a5844bc04ef85726f010abfc06b04901037be95",
                        "size": 2049
                    },
                    {
                        "created": 1698840802.1696627,
                        "modified": 1698840802.1696627,
                        "name": "/dev/shm/incoming/2023_11_01_121254_00_00_K_116.kml",
                        "sha256": "72af15d74a1c7c907336ab14f85824d2d676648d9fcb5e00f9b504ac94861106",
                        "size": 976
                    },
                    {
                        "created": 1698840792.3896625,
                        "modified": 1698840792.3896625,
                        "name": "/dev/shm/incoming/2023_11_01_121254_00_00_K_116.jpg",
                        "sha256": "86b0b722e9a98013bf4f91fa111d10e4ad2ba75689bd3cb68304a15b839967eb",
                        "size": 1155675
                    },
                    {
                        "created": 1698840791.4596627,
                        "modified": 1698840791.4596627,
                        "name": "/dev/shm/incoming/2023_11_01_121250_00_00_K_115.txt",
                        "sha256": "93f0e989c1f34451f628949e7cf4d97c3687248455aac90ed8b232f00ccc1ddd",
                        "size": 2050
                    },
                    {
                        "created": 1698840791.3296626,
                        "modified": 1698840791.3296626,
                        "name": "/dev/shm/incoming/2023_11_01_121250_00_00_K_115.kml",
                        "sha256": "31584c50e9d34d0d6e91b2230c13d97c30f55a1e5a44b9eecb6e63a704fc7f86",
                        "size": 978
                    },
                    {
                        "created": 1698840790.5796626,
                        "modified": 1698840790.5796626,
                        "name": "/dev/shm/incoming/2023_11_01_121250_00_00_K_115.jpg",
                        "sha256": "337d156735a1c4c6475355bb1290c668323191b1175bff4ca4675fc034507e2f",
                        "size": 1198364
                    },
                    {
                        "created": 1698840789.5196626,
                        "modified": 1698840789.5196626,
                        "name": "/dev/shm/incoming/2023_11_01_121246_00_00_K_114.txt",
                        "sha256": "3533ea4b4e5d8dc1942034d6437f9ce3e18846ebdb43e6ef077c3eaa343bf111",
                        "size": 2050
                    },
                    {
                        "created": 1698840790.3896625,
                        "modified": 1698840790.3896625,
                        "name": "/dev/shm/incoming/2023_11_01_121246_00_00_K_114.kml",
                        "sha256": "59792ba6ea043a5d497e626efced5f14dfab587b5bcaac1f326e244bf62c94c8",
                        "size": 978
                    },
                    {
                        "created": 1698840789.0496626,
                        "modified": 1698840789.0496626,
                        "name": "/dev/shm/incoming/2023_11_01_121246_00_00_K_114.jpg",
                        "sha256": "4cda64cfabdd3beac41111a99c642e480e04e8efd1f65c3fee1f2a74216ff475",
                        "size": 1218276
                    },
                    {
                        "created": 1698840782.3696625,
                        "modified": 1698840782.3696625,
                        "name": "/dev/shm/incoming/2023_11_01_121243_00_00_K_113.txt",
                        "sha256": "2dd3f979fac9449b76b5b6a9e7c05ceddc9b6e3e5147efb2afbdff1d621a929d",
                        "size": 2050
                    },
                    {
                        "created": 1698840782.9996626,
                        "modified": 1698840782.9996626,
                        "name": "/dev/shm/incoming/2023_11_01_121243_00_00_K_113.kml",
                        "sha256": "5d4954a65dc7caa80afbe665b53d31748e87153c1f35c6c2ef5e4427362037e8",
                        "size": 976
                    },
                    {
                        "created": 1698840787.1396625,
                        "modified": 1698840787.1396625,
                        "name": "/dev/shm/incoming/2023_11_01_121243_00_00_K_113.jpg",
                        "sha256": "0b911d070bedd637fc7a064bed8e6cb711ac20eb908a7a67eaff9d723408a0e4",
                        "size": 1190549
                    },
                    {
                        "created": 1698840692.0996625,
                        "modified": 1698840692.0996625,
                        "name": "/dev/shm/incoming/2023-11-01T12:01:31.ais",
                        "sha256": "be9e2eeec05573ea0fc1eb163377d733322f81058c16be85503bf85247912bc0",
                        "size": 622222
                    },
                    {
                        "created": 1698840679.2296627,
                        "modified": 1698840679.2296627,
                        "name": "/dev/shm/incoming/2023_11_01_121058_00_00_K_112.txt",
                        "sha256": "143c5ce6fb4107cf14d8521d3abbfec155da84fdd5e68b148680a33a4327e006",
                        "size": 2050
                    },
                    {
                        "created": 1698840683.2896626,
                        "modified": 1698840683.2896626,
                        "name": "/dev/shm/incoming/2023_11_01_121058_00_00_K_112.kml",
                        "sha256": "c0c544071076432d3b692d596f6ed8f55c9ea634d48b0c78c59dc5a434a3847c",
                        "size": 978
                    },
                    {
                        "created": 1698840682.0596626,
                        "modified": 1698840682.0596626,
                        "name": "/dev/shm/incoming/2023_11_01_121058_00_00_K_112.jpg",
                        "sha256": "aebca63849aca39ecb69665eafea822b137ce39d0fdd3a435e9c9a1bf82d9a99",
                        "size": 1336989
                    },
                    {
                        "created": 1698840669.9396627,
                        "modified": 1698840669.9396627,
                        "name": "/dev/shm/incoming/2023_11_01_121041_00_00_K_111.txt",
                        "sha256": "9319f981da362161228ca8a4d7a653a459e0f4f222c0a74972bed5d6dccf5c23",
                        "size": 2050
                    },
                    {
                        "created": 1698840660.7796626,
                        "modified": 1698840660.7796626,
                        "name": "/dev/shm/incoming/2023_11_01_121041_00_00_K_111.kml",
                        "sha256": "4740feb70bde1a0ae47a4f3bd256006e3c098df0660db362ecf41e4b5b6fc87f",
                        "size": 975
                    },
                    {
                        "created": 1698840653.9896626,
                        "modified": 1698840653.9896626,
                        "name": "/dev/shm/incoming/2023_11_01_121041_00_00_K_111.jpg",
                        "sha256": "c5c9e28f03f01e1378c028980cb289c08af03f7958c3d46e4c1f1098957c96fa",
                        "size": 1272863
                    },
                    {
                        "created": 1698840623.5896626,
                        "modified": 1698840623.5896626,
                        "name": "/dev/shm/incoming/2023_11_01_121003_00_00_K_110.txt",
                        "sha256": "38ad80605860a6e82871a8c72b1c28e80e9188352aa982ea1d828c5f40db1c9d",
                        "size": 2045
                    },
                    {
                        "created": 1698840623.2796626,
                        "modified": 1698840623.2796626,
                        "name": "/dev/shm/incoming/2023_11_01_121003_00_00_K_110.kml",
                        "sha256": "da19053628d7cdbea105a7a90d4f985a705833dfa6fca06e2282ab38bb81d563",
                        "size": 978
                    },
                    {
                        "created": 1698840620.4196627,
                        "modified": 1698840620.4196627,
                        "name": "/dev/shm/incoming/2023_11_01_121003_00_00_K_110.jpg",
                        "sha256": "8b65c44cbf51d22ecded635c43b8bacfc5ea6f6a01ff8ec53007138b6afb6deb",
                        "size": 1066336
                    },
                    {
                        "created": 1698840563.2896626,
                        "modified": 1698840563.2896626,
                        "name": "/dev/shm/incoming/2023_11_01_120852_00_00_K_109.txt",
                        "sha256": "cafb6f651864c6213a9381f6c494f848f57bd88d292471df731bd14b84d0f4c7",
                        "size": 2050
                    },
                    {
                        "created": 1698840557.7796626,
                        "modified": 1698840557.7796626,
                        "name": "/dev/shm/incoming/2023_11_01_120852_00_00_K_109.kml",
                        "sha256": "228d9430d1731249e5bff3d69fb6f4dd686a0671013b6e53523256d78c7a1745",
                        "size": 975
                    },
                    {
                        "created": 1698840566.9396627,
                        "modified": 1698840566.9396627,
                        "name": "/dev/shm/incoming/2023_11_01_120852_00_00_K_109.jpg",
                        "sha256": "333607e05e1a5e331be2130a6348adfd04e657d4eb51d883f50396cbdf99bee1",
                        "size": 986004
                    },
                    {
                        "created": 1698840548.6396625,
                        "modified": 1698840548.6396625,
                        "name": "/dev/shm/incoming/2023_11_01_120832_00_00_K_108.txt",
                        "sha256": "8a8adf0d4e35e4506af6fbc3ac53932c1bb616c2bece668a84eab03f8e3f9063",
                        "size": 2050
                    },
                    {
                        "created": 1698840552.4696627,
                        "modified": 1698840552.4696627,
                        "name": "/dev/shm/incoming/2023_11_01_120832_00_00_K_108.kml",
                        "sha256": "fd84e2a4baf7e7f56745834968445f4f44d41c1396bdf9f12ccb5f909ab8ba47",
                        "size": 979
                    },
                    {
                        "created": 1698840540.1296625,
                        "modified": 1698840540.1296625,
                        "name": "/dev/shm/incoming/2023_11_01_120832_00_00_K_108.jpg",
                        "sha256": "cca07741b69452094d9f50b63047ea170fd19714153deae3387c3e3aeb9cf90f",
                        "size": 704899
                    },
                    {
                        "created": 1698840536.8096626,
                        "modified": 1698840536.8096626,
                        "name": "/dev/shm/incoming/2023_11_01_120826_00_00_K_107.txt",
                        "sha256": "acdcfa7fdfbde7c3855442a0d58efdfeec01850cbe8d87df6ed6687f7f32ee2d",
                        "size": 2048
                    },
                    {
                        "created": 1698840537.6696627,
                        "modified": 1698840537.6696627,
                        "name": "/dev/shm/incoming/2023_11_01_120826_00_00_K_107.kml",
                        "sha256": "922cdf8b8855d5cea32fa22a0f4a30238e3a9173142828a6bd3cb0dbbef72d87",
                        "size": 980
                    },
                    {
                        "created": 1698840529.9996626,
                        "modified": 1698840529.9996626,
                        "name": "/dev/shm/incoming/2023_11_01_120826_00_00_K_107.jpg",
                        "sha256": "f73414deb983bd41e1b3be5e2966fa11fe041b72d7209b90aa4aaee1e24f46e3",
                        "size": 671711
                    },
                    {
                        "created": 1698840527.2396626,
                        "modified": 1698840527.2396626,
                        "name": "/dev/shm/incoming/2023_11_01_120821_00_00_K_106.txt",
                        "sha256": "67c1a73f459d2737de89fc4541a24433642477855b0a6ac15c19c4245bb40327",
                        "size": 2048
                    },
                    {
                        "created": 1698840526.9896626,
                        "modified": 1698840526.9896626,
                        "name": "/dev/shm/incoming/2023_11_01_120821_00_00_K_106.kml",
                        "sha256": "c94a66a5eb6c54be0106ba0e1142b4af87c826f960c4b056037e517ebcdeab23",
                        "size": 975
                    },
                    {
                        "created": 1698840526.7596626,
                        "modified": 1698840526.7596626,
                        "name": "/dev/shm/incoming/2023_11_01_120821_00_00_K_106.jpg",
                        "sha256": "7f8fe13ab2f2ebdd577c9e96d84d93676f2e2429784dbfbb69cc2da02899137e",
                        "size": 666301
                    },
                    {
                        "created": 1698840520.3396626,
                        "modified": 1698840520.3396626,
                        "name": "/dev/shm/incoming/2023_11_01_120818_00_00_K_105.txt",
                        "sha256": "8187aac7ac12587e0bfa1e1e219db11e00b6f50d4bc8bf114c6969fe9fba9d98",
                        "size": 2048
                    },
                    {
                        "created": 1698840524.2596626,
                        "modified": 1698840524.2596626,
                        "name": "/dev/shm/incoming/2023_11_01_120818_00_00_K_105.kml",
                        "sha256": "fefba2f5d12c663dfbec604427b2ccac2b7692bf0ac08ab30677e4b165be4970",
                        "size": 976
                    },
                    {
                        "created": 1698840517.7796626,
                        "modified": 1698840517.7796626,
                        "name": "/dev/shm/incoming/2023_11_01_120818_00_00_K_105.jpg",
                        "sha256": "dbaf327d0c798d130da984221db7576698ac41cf8ae9423878572023f896a4fa",
                        "size": 634930
                    },
                    {
                        "created": 1698840513.9396627,
                        "modified": 1698840513.9396627,
                        "name": "/dev/shm/incoming/2023_11_01_120813_00_00_K_104.txt",
                        "sha256": "95fe89c8896b2f01b3c99642082ec454259b35aba4e41f676649d41c079e6a68",
                        "size": 2049
                    },
                    {
                        "created": 1698840513.5796626,
                        "modified": 1698840513.5796626,
                        "name": "/dev/shm/incoming/2023_11_01_120813_00_00_K_104.kml",
                        "sha256": "84cd6b7c31d3948d326b020d94dc0fb748d20d3755888b288456e85f7c0b83aa",
                        "size": 979
                    },
                    {
                        "created": 1698840513.4696627,
                        "modified": 1698840513.4696627,
                        "name": "/dev/shm/incoming/2023_11_01_120813_00_00_K_104.jpg",
                        "sha256": "fc2a48c0c0d38f6d38a610c8cc66e5bb0463f7ca23e9b09ac53acd0bcfd2518b",
                        "size": 659801
                    },
                    {
                        "created": 1698840501.2796626,
                        "modified": 1698840501.2796626,
                        "name": "/dev/shm/incoming/2023_11_01_120757_00_00_K_103.txt",
                        "sha256": "398ef58506d66ec597391fa79e99bc0c0e46ae50da302491498c9699c6996fe7",
                        "size": 2050
                    },
                    {
                        "created": 1698840506.0896626,
                        "modified": 1698840506.0896626,
                        "name": "/dev/shm/incoming/2023_11_01_120757_00_00_K_103.kml",
                        "sha256": "b1296a20ac23f2e3af6988827816f9a21f8092675039db225a9c7392dfa4ebe2",
                        "size": 975
                    },
                    {
                        "created": 1698840500.2096627,
                        "modified": 1698840500.2096627,
                        "name": "/dev/shm/incoming/2023_11_01_120757_00_00_K_103.jpg",
                        "sha256": "f1d79506f75fd2da33e52498db478c7b0280cbfa9ac132c2aaaca8818c464e7a",
                        "size": 1068406
                    },
                    {
                        "created": 1698840477.6696627,
                        "modified": 1698840477.6696627,
                        "name": "/dev/shm/incoming/2023_11_01_120738_00_00_K_102.txt",
                        "sha256": "ec570c2d8c5518110d4f7c7756bd772817e2b7e39d68e23f3cfb420dd01c8ef3",
                        "size": 2050
                    },
                    {
                        "created": 1698840478.2396626,
                        "modified": 1698840478.2396626,
                        "name": "/dev/shm/incoming/2023_11_01_120738_00_00_K_102.kml",
                        "sha256": "609940d73656c168d86f9dd51360949a538c1553fd36b3d192195da46e2dd2bc",
                        "size": 978
                    },
                    {
                        "created": 1698840476.8596625,
                        "modified": 1698840476.8596625,
                        "name": "/dev/shm/incoming/2023_11_01_120738_00_00_K_102.jpg",
                        "sha256": "107e4077307264e925d758f1751120cef3036a3374411eac47088ba8b7f672a5",
                        "size": 877246
                    },
                    {
                        "created": 1698840458.6496625,
                        "modified": 1698840458.6496625,
                        "name": "/dev/shm/incoming/2023_11_01_120706_00_00_K_101.txt",
                        "sha256": "8f13ffc158f7624dd420576e2d2b3d46b1261802192d32f5f953faf48952269c",
                        "size": 2050
                    },
                    {
                        "created": 1698840454.2596626,
                        "modified": 1698840454.2596626,
                        "name": "/dev/shm/incoming/2023_11_01_120706_00_00_K_101.kml",
                        "sha256": "34e63c8abfd0fa04c85a698d16b0a86b3a83e8e56fbf4d40e047e1c169d9e6e5",
                        "size": 978
                    },
                    {
                        "created": 1698840463.3896625,
                        "modified": 1698840463.3896625,
                        "name": "/dev/shm/incoming/2023_11_01_120706_00_00_K_101.jpg",
                        "sha256": "c1c0988d448833231263537f715f9709ce816181915981e7fa5a7f6f0a39ea1e",
                        "size": 1183581
                    },
                    {
                        "created": 1698840451.3396626,
                        "modified": 1698840451.3396626,
                        "name": "/dev/shm/incoming/2023_11_01_120702_00_00_K_100.txt",
                        "sha256": "987dcf8b195aaa9527241c5591ae4d3a0c0ec46fe7692a7aba78762178ba2f23",
                        "size": 2050
                    },
                    {
                        "created": 1698840452.1496625,
                        "modified": 1698840452.1496625,
                        "name": "/dev/shm/incoming/2023_11_01_120702_00_00_K_100.kml",
                        "sha256": "50990c4f5d86e0f6ef71cfacb50e93bf6878623203be21a727511e7058119496",
                        "size": 976
                    },
                    {
                        "created": 1698840450.8396626,
                        "modified": 1698840450.8396626,
                        "name": "/dev/shm/incoming/2023_11_01_120702_00_00_K_100.jpg",
                        "sha256": "0b99b1c555e8afc5a06866f2dabcd223f196f0657c4c65534fbc505001d59e7b",
                        "size": 1236732
                    },
                    {
                        "created": 1698840445.3496625,
                        "modified": 1698840445.3496625,
                        "name": "/dev/shm/incoming/2023_11_01_120658_00_00_K_099.txt",
                        "sha256": "83d28faa4a26b6e5f2ee65f5493bea8e4d140eb3764e4a3fe1abd00ea6fbf3d4",
                        "size": 2050
                    },
                    {
                        "created": 1698840445.0396626,
                        "modified": 1698840445.0396626,
                        "name": "/dev/shm/incoming/2023_11_01_120658_00_00_K_099.kml",
                        "sha256": "73300e10ab6969e1c5aabafdd98b9846ed7f55ce673b85937d45e2090bd58961",
                        "size": 974
                    },
                    {
                        "created": 1698840444.7896626,
                        "modified": 1698840444.7896626,
                        "name": "/dev/shm/incoming/2023_11_01_120658_00_00_K_099.jpg",
                        "sha256": "e94f16f1c9470f7ab816b2c265da087a120aadacaefdcbb95771525769d753a3",
                        "size": 1211790
                    },
                    {
                        "created": 1698840437.5896626,
                        "modified": 1698840437.5896626,
                        "name": "/dev/shm/incoming/2023_11_01_120654_00_00_K_098.txt",
                        "sha256": "71edc34bc086264c2aeed58c0a98ab0c61a5a2d4605c69ed0896000691b87d18",
                        "size": 2049
                    },
                    {
                        "created": 1698840437.2296627,
                        "modified": 1698840437.2296627,
                        "name": "/dev/shm/incoming/2023_11_01_120654_00_00_K_098.kml",
                        "sha256": "78c03d49251c70a625352a98e2f14943d7e1ee1cce3bc60ddc0e89386022f3de",
                        "size": 973
                    },
                    {
                        "created": 1698840436.8896625,
                        "modified": 1698840436.8896625,
                        "name": "/dev/shm/incoming/2023_11_01_120654_00_00_K_098.jpg",
                        "sha256": "be86074383f5400b1828e47ea9f62c5a062a61e0cec3d31e85a2cb057b8af92c",
                        "size": 1158727
                    },
                    {
                        "created": 1698840434.9496627,
                        "modified": 1698840434.9496627,
                        "name": "/dev/shm/incoming/2023_11_01_120649_00_00_K_097.txt",
                        "sha256": "7928550b085732328a75e275c8992c56627619328c3b6ab1df2aadc31f4a5a05",
                        "size": 2050
                    },
                    {
                        "created": 1698840426.2096627,
                        "modified": 1698840426.2096627,
                        "name": "/dev/shm/incoming/2023_11_01_120649_00_00_K_097.kml",
                        "sha256": "3127f15ac77c512bdd2ab21c0a7c736af33153cdf05b200c442faab7f854c682",
                        "size": 978
                    },
                    {
                        "created": 1698840435.2396626,
                        "modified": 1698840435.2396626,
                        "name": "/dev/shm/incoming/2023_11_01_120649_00_00_K_097.jpg",
                        "sha256": "ff7f6812a5e3ce1b75d40bf1923f263b34f11be8e5726f12c21e66f4cc8368ff",
                        "size": 1068892
                    },
                    {
                        "created": 1698840397.7496626,
                        "modified": 1698840397.7496626,
                        "name": "/dev/shm/incoming/2023_11_01_120607_00_00_K_096.txt",
                        "sha256": "61260378aaa54bd055d8af9e719d799e50eb7aee06c8481d3f5db991fbae1347",
                        "size": 2050
                    },
                    {
                        "created": 1698840390.7596626,
                        "modified": 1698840390.7596626,
                        "name": "/dev/shm/incoming/2023_11_01_120607_00_00_K_096.kml",
                        "sha256": "94044ca36c9a2d3de9ccbd62ef47139e43615d8035d7923ef335aa047853d810",
                        "size": 977
                    },
                    {
                        "created": 1698840389.2196627,
                        "modified": 1698840389.2196627,
                        "name": "/dev/shm/incoming/2023_11_01_120607_00_00_K_096.jpg",
                        "sha256": "980749753353490548af2a5f81e7affe9f3902096cebfc84e1590b7592434f90",
                        "size": 1099782
                    },
                    {
                        "created": 1698840834.3396626,
                        "modified": 1698840834.3396626,
                        "name": "/dev/shm/incoming/2023-11-01T11:43:53.ts",
                        "sha256": "631cad50ef3f43e6d010a0c809bfc42f797ab40740833715dc24fbfe7f26e933",
                        "size": 441418548
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "b45aaaf6ae69dbe40d5c0a077076154c518c4bc371ef4ba2eabe1f89637e8a5c",
                "size": 419573697
            },
            "errors": "",
            "filename": "2023-11-01T12-14-00Z",
            "size": 419573697,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698841072,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "hni52W0grXltQDYvgEmtLCkJ0U4nreE6+E44w8bZJCtLXDPCMGJE+HDX5DxFgEKh4MWi3/2r8mSFC1Jq+wltfl6Tb/dYuBJ/claURLuLEknbhvkhh3I+MvQ7e8R9NsCegmzaEIZgni5VIRpoBH7zt4qBUJFX0ieY97l+w4ZxVPUAW5B/kX9WwAXoppujJ5ESPu2iWW4r6i3jkqf1kgDejhwZWvPsdVf6hNoXYEjmrqfIeb0/8oOUc1bXJcAETcb+bf/Jtm5ZBM5KEDSw7Bdg32TswTTjIRDY6VWZw00JmN0+T3sSo1jOOzzGJr3079a4415q6OxoIp0TmgTyhXpGp/u6RBZCL4r1WeeBvTcuml7GzeS/SP1bpCvKXNyI5gtMP5GIMrqt7wNrr3WE2sBhouxR7EnLCKBEBWTO9guCI/NOnxVfsVxGW9/kwOjiwLNRVVoZ43YeDqUsp0JYKuCT4eRVD6NJwEcwjF8v3H/3VufZRHDZLPNkwK47KzvKDRP33G0E7bLywVkif02u0UUALgwpJyz7xzGkKsL9KSgn0uxBWtVmNXZ555bMVEw/p4WotFu3m6TQIb2znWABvoGvQeh701acyRzm+vct05D1HXe2UUCgOHutb5NzIj/MCq8w8m6uThhffxyhaT2rp4LH6qg5Bo6553QaDiu0wEr80Oo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T12-17-00Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698840889.0796626,
                        "modified": 1698840889.0796626,
                        "name": "/dev/shm/incoming/2023_11_01_121423_00_00_K_121.txt",
                        "sha256": "6f810592b62a073061a9c6fbd07fb153130eef824e9815529aa1b3956d849d2d",
                        "size": 2048
                    },
                    {
                        "created": 1698840889.8196626,
                        "modified": 1698840889.8196626,
                        "name": "/dev/shm/incoming/2023_11_01_121423_00_00_K_121.kml",
                        "sha256": "28421cca81aa102baaf28b31dc27ebc93c029e50121f75fe6519f112193b91cf",
                        "size": 974
                    },
                    {
                        "created": 1698840888.5196626,
                        "modified": 1698840888.5196626,
                        "name": "/dev/shm/incoming/2023_11_01_121423_00_00_K_121.jpg",
                        "sha256": "9a10ae378bb3d1b082fb80f76c4d0c03f23f83f319a945c11aac65b669a6292c",
                        "size": 826197
                    },
                    {
                        "created": 1698840876.1296625,
                        "modified": 1698840876.1296625,
                        "name": "/dev/shm/incoming/2023_11_01_121416_00_00_K_120.txt",
                        "sha256": "1a859e4a2a9bcba1f3b7d8e355c2f38d6bace242e7a2c3c9f2abc45de24000f3",
                        "size": 2050
                    },
                    {
                        "created": 1698840885.6196625,
                        "modified": 1698840885.6196625,
                        "name": "/dev/shm/incoming/2023_11_01_121416_00_00_K_120.kml",
                        "sha256": "ae48d2a0ab06b93d9b1e281019ede82fdffff6f25a7bfdd3f6767db708801ed1",
                        "size": 976
                    },
                    {
                        "created": 1698840885.3496625,
                        "modified": 1698840885.3496625,
                        "name": "/dev/shm/incoming/2023_11_01_121416_00_00_K_120.jpg",
                        "sha256": "92f819754f9d1e006e280f13d0cc33ffa7b7ca578c64db43f210adc61eb871c4",
                        "size": 1044024
                    },
                    {
                        "created": 1698840873.0396626,
                        "modified": 1698840873.0396626,
                        "name": "/dev/shm/incoming/2023_11_01_121359_00_00_K_119.txt",
                        "sha256": "62ac7b37fb5032266164c37c8c0830cd59682265f912e28a9ed2b25a6026f95b",
                        "size": 2048
                    },
                    {
                        "created": 1698840872.4696627,
                        "modified": 1698840872.4696627,
                        "name": "/dev/shm/incoming/2023_11_01_121359_00_00_K_119.kml",
                        "sha256": "7ce70d875dd3e46122a6c8ad20c9995f39eb25ecd57ac3a69b28a359a8cd657c",
                        "size": 981
                    },
                    {
                        "created": 1698840866.8996625,
                        "modified": 1698840866.8996625,
                        "name": "/dev/shm/incoming/2023_11_01_121359_00_00_K_119.jpg",
                        "sha256": "8a84256ebf3f5c3c7954c40dfab9435357f93ceb639d770d9aecd9f231dcdcdd",
                        "size": 1207268
                    },
                    {
                        "created": 1698840859.2296627,
                        "modified": 1698840859.2296627,
                        "name": "/dev/shm/incoming/2023_11_01_121354_00_00_K_118.txt",
                        "sha256": "69b940dd448f868f3fd4dbb519e8194458deedf594bfed1e9e7c8865353bf876",
                        "size": 2048
                    },
                    {
                        "created": 1698840860.1296625,
                        "modified": 1698840860.1296625,
                        "name": "/dev/shm/incoming/2023_11_01_121354_00_00_K_118.kml",
                        "sha256": "6b908b50ba9c43cee2dc47a06307dc031477e742853318080d7c40d96c22bc19",
                        "size": 974
                    },
                    {
                        "created": 1698840849.5196626,
                        "modified": 1698840849.5196626,
                        "name": "/dev/shm/incoming/2023_11_01_121354_00_00_K_118.jpg",
                        "sha256": "2115f0bc417c0319dd4c5febbb1a588a1f73e826abba38676f169fd9c88f7d57",
                        "size": 1051634
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "cfaedc94ed4dd0be5d022eefecf0176ac35dabc671d53c43ccde9b3ce8404e8b",
                "size": 4132384
            },
            "errors": "",
            "filename": "2023-11-01T12-17-00Z",
            "size": 4132384,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698841196,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "AN8TXdeqNNuwNaltTfuBRYNdexL9rzswP2ME29NWh0HzrAKGplvvoub8fr68i39F+vVWfCDoDqvW6R2qBLJW4qlEC9BhokKhVDct5Zh2ljEfw2LI69OHfGNExwxwFJhNbk/pV0bF2vFBd35Wur49jjhixcpH7TjG9SLHAPkKxwG8i2gky50Zem6H8JZunOwR298l/WsNrfue1kMTDW1UQeyUOYpPkDWFMYTcWK8x33aaTvbHRwKyJ5aC9ZIyfJTK6YvHiEIovjGhNhdMpzjKCnOXwrlv/icjYAjrgu80xO/BSINEAUAN8xsAWMub3zy8FW0qOMonOa2YWBMFCZihAURPj5u16oqsOPrs54+cMXrXrtv/tqsu07/M4Bi1yTb8YBCt+Vpl14C2yVREsJu1fqJagAk+b+vYZ2rM6QaZFvzb7Lzfljv7d5uEe6kX9iHwtklbiRoVvv7U8OP6OjZDOrZhwtMeOHT/oE6eRF3g4In1eretRCshv3UISy+lBysuayVqghnwE1WdXocnXlDU4RVDylafRdH7ZGe4O4NWWxCwmyo9Z5sxZqa/JwpR9gl/lHQPDy5wckruKw4XTidC0Lc5HViWGRdv0ZxyhZoFyobrIvLTdVCfcfUIu9hnz5Ev5ikZ2ZLRm0OjFcE6/mwQhxkvW67N9m/BMO5XCckbWXE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T12-19-15Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698841035.7896626,
                        "modified": 1698841035.7896626,
                        "name": "/dev/shm/incoming/2023_11_01_121651_00_00_K_122.txt",
                        "sha256": "80d1dfd2691d713a11b681938e0b476ec8bbadde767c5dfce51be4b3d15f0b3c",
                        "size": 790
                    },
                    {
                        "created": 1698841034.5396626,
                        "modified": 1698841034.5396626,
                        "name": "/dev/shm/incoming/2023_11_01_121651_00_00_K_122.kml",
                        "sha256": "882de610b1140ea40801edc366e215fc872036f76519884bbcb02fb6a84fb525",
                        "size": 931
                    },
                    {
                        "created": 1698841039.1896627,
                        "modified": 1698841039.1896627,
                        "name": "/dev/shm/incoming/2023_11_01_121651_00_00_K_122.jpg",
                        "sha256": "7e25aea12bdb6bad25609f9d8aa475ee273e49932519f8c462d1b28de61ef2f8",
                        "size": 590326
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "9fabc6829271694c7c87f43f18cce92d92e81e563599c1261c77ff401e86ed4f",
                "size": 580451
            },
            "errors": "",
            "filename": "2023-11-01T12-19-15Z",
            "size": 580451,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698841447,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "a8/uJP57NZC5OtP9NAo/e5SmAgCByoAqDd0AuiyeuogTpEacfKJ8Il8hSjPAF8mCk8EvN2Uak5Pd5QsYXJ4cVeHWaYevEna/Atdk8fWKF+7FUp4x0LyqR/2E3Y5pzir5GnnCT9yfvTZiV2o2diKsMqgsuUcWMPs6WDsZvAUVI+7y8OqjIlkG0HvX9K7dNlgh7enDCmZ/Xh+kLZsmPL2vuUErP5hCp8FGk1biZIKamqc3wUCvHAYs3ZUKkob/XRjgdGaTfbQ1u1TTOxnCRGAvULtB4DWiUF6Ic2p3ZJ7JbdT5gq5ZRQwRIfCfILC/VKVH5QMYHXFAudn785WSFoxPVzW3uACyisb3CIzU+dyTJc7fF2U4nx/Q9+DFEvTNz1cBfSp4+AII0s8oPKHOe//07KBciJ0E0BTVttaxCXx68T96/nKQ36lpH+lG1NQkD8F3wZl7rtiHvTyDZjjZZTKC57zB7gQ21nDFo+2laZ0ppkqziQZPCNntbWW1L5/9EjEx7vu5Az+O4HvjZVsuMrSUe5iKu4f6+cxfzV+BQIwiQqvPIKih3oTSOT3SnIZhctO7wY3KdqTcLzfhaokEmoL6TL5Dj4ni1kZzNv8P+8hX7V+0ExHpX8E+gYXD/EGZ4SI6txTtIOJQsD2Xn9A262E7DI6iigcCu2rjr9CWwoH5Kco=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T12-23-37Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698841293.6996627,
                        "modified": 1698841293.6996627,
                        "name": "/dev/shm/incoming/2023-11-01T12:11:32.ais",
                        "sha256": "09e0890f17163bafeba6d3eae3256879182b21bce55261c625bc7d9cb7fb907b",
                        "size": 676143
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "5775312730dd86a2f87b171fcbbab612caf12a2707f44c5407203e8b2f2a4aef",
                "size": 200425
            },
            "errors": "",
            "filename": "2023-11-01T12-23-37Z",
            "size": 200425,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698841052,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "oyXgJlh/l4xgWWn0pqVFE5klTS3Tb69mBUQ+dfTZ1+OQ8aqSPuLlox9XNseMYOdEZFTsnmvxs8OwW9yHjsT6VNpWg4mTjFdnFj3nIZkDogZcXgOVLG/C1hc6gzuoBczVYTKJye9IBTYtTa0Vy28A0VL93+23UPdt5+FYFlMWviYuGJuwMmZ80hw1Dg4u25eEAIvvPCYwGUMYfhGZ5QgcHLIkDRZS9+lSps7y+BNK1uRgd/4M9w2/cF6Mw+czgZ93v7DolFcDC8vtZZwwhk3pBsP6eKQoz3TaBSNI4hSNsTepVHs1qDv+STcxY9tefbiFMEfqA8jpjQTtA+HYcc5i8EPz462SeUWDTJbjRRwX8ZS0dC0oAQAQ4mUgQZ8w2V8QTuNFcpOlm46sHqULkzLahnUp0Q9e2g6pcy7Jqv+uAqfPfwsLcKd+X/SKSKoCTKT4p9YK94CCvztB34iBDly5bja5TdtB5HJicyJNr785ucSXq3ugkX0odVsZp9EEOe0Lf4XRqMwEtDSK/7pK8c+AYSEvZYxQZwQLn8sXzpr+HV0ibFGdDPXYHNqaLr1XdC+2SyQX06buq/KLy3LmgE/Txzd2tsXRexUsWDlHm0YqYG/VqhorI3fZJGXH3MRgqUReAeNZXiZQOy5O77uWVgJ9EWSoESD/FwyLLH7/4AcnNw8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T12-17-27Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698840919.7127697,
                        "modified": 1698840919.7127697,
                        "name": "/dev/shm/incoming/2023-11-01T12:05:17.ais",
                        "sha256": "d6f78049192f8a00836458c02dadf20850798f839f73ea898e2547b650432493",
                        "size": 168675
                    }
                ],
                "mission": "EFCA1",
                "sha256": "5311deac9d8c2de05eb70bf9b76298167d01108a731af0a98c8ac0b0e36c233b",
                "size": 31793
            },
            "errors": "",
            "filename": "2023-11-01T12-17-27Z",
            "size": 31793,
            "verified_at": 1698848085
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698840532,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "vbqi6A7newDq5XsuvbRSruWvQ1IdPz45yDvX8AjpfE/RAOyTIu12DzRetMnpG7mg2GMI268CQK37jU9qrFtQD8gwHVCqxlg9z0PjDfbSWv/+tSIu1glKvq6SXkvMBtuCEZ2MFJPzmBf/bXf6Oj614tQl+yetmVA0ErFoGd4UPKUESpm8sHNQYmRkFx/SthngA3pQL59TJyx5wG3imEARphyY0jTmgAl2ZtsaMLcn0Cr8tgXJgk7U0tebWM1rxylkCFYVpXkFWe/dTlwqJB+D9W/D+2fL8/f7f0ImCJjolte41Bax/kyB229hJIOkKb0PGHlC+drtuRJfqixZivUrUAcq6Foi65sBDs5/1XAk0W0mHq9x4t3jyvWnl8Q4+MbFWqWsMAI7y3vK8lnrKcMfyHzl9D4hrHtP1Emk4nmkGKmp2yzl3jM45fG4nln+fdz9X9t2lMbxs+FeG7Lyf/tHrc8f63JKu5BgRq6B1mjUmZQsubsBhjOUelvCY2svQutZ1lkzS/rQzonr3kKhLroP/aiGElCa7t0q89BhLjM77v++dHAApp4G5kJemko7/XwfQ2vZxDTbNagJ8jOnwgLQmk/7aV2aJodhfZgnegR/D4NrvL+lZC70thtKHU2REv+e7vtLxd9ifJhM0AzdsmG4pWffedXOsFHtzTKxSSBDnc8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T12-08-47Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698840404.3227699,
                        "modified": 1698840404.3227699,
                        "name": "/dev/shm/incoming/2023_11_01_120627_00_00_V_020.txt",
                        "sha256": "511144de59a23b47893052de2346b5062f6dfde8b58ff376b83e46aa92e3b7e2",
                        "size": 778
                    },
                    {
                        "created": 1698840404.2027698,
                        "modified": 1698840404.2027698,
                        "name": "/dev/shm/incoming/2023_11_01_120627_00_00_V_020.kml",
                        "sha256": "b5872f7cd1ed733b320ae27ac3880fd90fb2bb90a0b9cb6b3780317d9b211892",
                        "size": 936
                    },
                    {
                        "created": 1698840403.7727697,
                        "modified": 1698840403.7727697,
                        "name": "/dev/shm/incoming/2023_11_01_120627_00_00_V_020.jpg",
                        "sha256": "eec5bc72e8d038e15b49f586a5f1283415bb47a825b98ba8c6a28303a1d61b77",
                        "size": 1159129
                    },
                    {
                        "created": 1698840317.3827698,
                        "modified": 1698840317.3827698,
                        "name": "/dev/shm/incoming/2023-11-01T11:55:14.ais",
                        "sha256": "2d766b91ba49f98ae8b4d534aec393340ae2c5921f1e176580c68f5a5ae44a92",
                        "size": 193443
                    }
                ],
                "mission": "EFCA1",
                "sha256": "c80a82ab85a3cf1cc38bd978f7ea748e90b80f7c6921a8a0e0b6417d28c86339",
                "size": 1193308
            },
            "errors": "",
            "filename": "2023-11-01T12-08-47Z",
            "size": 1193308,
            "verified_at": 1698848410
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698842043,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "mfQgvBDP09VAD1LEOjzXcNTEf00Wx4SkwaKOeWAlcPBWLvxSHO7BcZ0bzos1kDXLizqRUGw9BHCtqtDVXcdkzynBW8dozlKzWKKrfEMeA+LMAandBh5fQmomEx9H58n8BjAdxn7eBKqLREBPFC4/t9AKuadQHse1GE+XgixA3jqCVhdZ5tCYBGesF09XvGQ0uiawFF3/nwMyx4s1J+t88OSzoMy7xLV3ICn67fd0G0gtP0BBLnSA3X+rQshmHr05llNSC+Vu1Qn43ymc9mXnGqkE2n579PD309pzIzWe08j0LjzLy0UqLdVcawZvEzSLaM8nQz9NkaS62kxUqM/EU3MQbQQGeJIZ/r5KsUQqcQgg1nwRnyK0bnVpqMB4peq9q6AsR8y63ztlFQOFBvIvyOkyeNwz2s3rXdAZk4Tjc8d5r4MMMwGGlf2m8/N9YRc6hnc/pb3BOfh34KO0zvJzAtnI3ksTWPJNafIWVhWHZA/ZuwwIm3VWyxI/eDm+zZJmaDqnF2tbaWog6/BE4yASP4Fye4FeVvOsdMF2imQCu28ouEvqIXPLdeA+h8A8P3rsIuzPyzga0pC++lvgGnE4B9OVX/DQNthhpmZJXovuxJkT9MCRPgUYRC3T8xQhBMUMbZz/YYMF6mtDwtC9HUjujZRIPyBqqQk8oqjnJFWQzWQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T12-33-41Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698841894.9196627,
                        "modified": 1698841894.9196627,
                        "name": "/dev/shm/incoming/2023-11-01T12:21:33.ais",
                        "sha256": "bab56cc219f8845a5ad28c8a9a4aa6749f4fa7d10b66f8b3cc84baa1a7f5a20f",
                        "size": 698852
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "dd89be434f118f7822a36d211c43a2114fc8dd13ed1e0b407931e7c4bc4ad336",
                "size": 210466
            },
            "errors": "",
            "filename": "2023-11-01T12-33-41Z",
            "size": 210466,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698842744,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "LJVdZ/aTCGtc8SGiPvQm+qbKJhFqpd5qGENMHgUVaY4BqUQtEfOBMLPO8IDiNpXQXbaXupRDcENsvrFTDPn9UOF99sjG7j2BnIYI2HEXJ05wVgOSJnaXdC0FI4MKNHwHMOxVrGroBUXjmdDsSwzFBJzTn1AzkJtNWgK8PijQPEZHfhzDd+iV2ts+sTJwUK/HH++n42xAkK2Zsh/GDLNrK49Zybc615od1wiV4aNXJiIHYYuLJXFEk8aRbVtK6sRj9dTrY/Q/KzZeuGnLD5Ug/xejRxAQe4+2lICoKhaOTAsPykLXLjXWbjIhOSATuCbAOoGSGZ0ozyOoMGCa1QVc3xMvi26amHzsXwFxb5BnyEMurEkJ4WnqXXnT8Mx3UZkh1ZNdpkter7tks90Zmg6CFJEWLxxn574N8Cia79dHU4dsLWpcePf4zWVHF7Bl2b5WHzzPMh0HUVJtYbVYxORewCoWF5lBXbhyT+sst+uLwQmwzUinW4MnXwyg+zdrVkonIsu28xYHWMAiTC6U+ReVuILEm8EPC+m6fjcUdl4TIUjWKEgWbGyKGy6/CCj103nw1Lu0D+HbNUmU3rGnIiOLrCOV2y64VpExWr9boyfwj48QFKRyRISP9+WtUrlxWLTf+JM2ZlPhCX3zeJYznycqQfGaqOijs1M7CYUPAw3tyO8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T12-43-39Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698842495.7496626,
                        "modified": 1698842495.7496626,
                        "name": "/dev/shm/incoming/2023-11-01T12:31:34.ais",
                        "sha256": "d9a1aabef3a0371702c14b29275ea2933336be154a598f5a154c78e42ab42189",
                        "size": 826729
                    },
                    {
                        "created": 1698842471.2796626,
                        "modified": 1698842471.2796626,
                        "name": "/dev/shm/incoming/2023_11_01_124046_00_00_K_123.txt",
                        "sha256": "131e15ec8cdd5b1e032b4cd23d2129e8f5df5ee0a860131e0a1e1d00dd27f490",
                        "size": 789
                    },
                    {
                        "created": 1698842466.3396626,
                        "modified": 1698842466.3396626,
                        "name": "/dev/shm/incoming/2023_11_01_124046_00_00_K_123.kml",
                        "sha256": "28dec9bc794e59d382782c0df7e75fa426159af847e01aae981189f885aa8fdf",
                        "size": 893
                    },
                    {
                        "created": 1698842465.8196626,
                        "modified": 1698842465.8196626,
                        "name": "/dev/shm/incoming/2023_11_01_124046_00_00_K_123.jpg",
                        "sha256": "b7a62fc332a228ca72d30e4defb606ce3d685a5479e304e093307bc4efcff452",
                        "size": 905541
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "5d39e25f6160d4375e8d5bec04e1b58b5e974c300be09096d38c12f5be2bf987",
                "size": 1151945
            },
            "errors": "",
            "filename": "2023-11-01T12-43-39Z",
            "size": 1151945,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698842762,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "ZDXIaQ6b3CoJ+zIdHaLd74GIoWO3MP59ycfB+vLAOgwueU3AU+O3E9Ne1JJRBFb+FYpU5f50zRK5mhVa/iLZNuJGg9yXtLts3zklPzAakSKoNjhi9/eonUWNwiPrd+oELv+aMey/iZWvezpaRmYUPIaBbpJB2ElSjyz5ITok0OpVfywa4uA5BevQgGyMocQRatuxqSGMveDl3F5wrEFJmnpqrPE3LVCwrPCNVksBfAsvpCqmrYp9Lxkad0dhaX0f6N1gD19XdrD0mDRC6f0D+e0W2WzL38WI7wn73FYn7HeUoNvJPwN71fcwlhfjpQ59uT1XHSbIWAddRG4dRg8L7ikb7rl31N9UlZrG2nmdlsmiHhIi7VU7PQmGsPJ8M1ztVlao99D2v6Ski36qsiyiCtouXmYYK8NjqG8UP0QlZYg3wlGlOQOLVBfai6ESGiRnXmBZd77nJbcPJzASPDx7ih8FCDd1aHQgEub1AkAnR/lB4g0wZCiiqbtW0tfYtDAN8qamavrf4YwmXf6Z8qZa5NKfvE/NcwxwMREMW56zOmFMNwv8UbTxaBqVEqrf85i5lUTwD2dgAHvT2psA6H70fnrdk7RZKX+HvlJHJSu55C+1ZGOvxP6HnrLv17GJOQWCjj2h9CScptLPAy0zRCNwxznnNSmd4K8Qj+YeJhfsqpw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T12-44-12Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698842640.8596625,
                        "modified": 1698842640.8596625,
                        "name": "/dev/shm/incoming/2023-11-01T12:14:00.ts",
                        "sha256": "c98807fa27ed215eb10b60b996c160d395cec018b5f3a6dd564132bbb806c442",
                        "size": 437611736
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "8b58db5ccdf42208f87138309f05661a59e6667123306eeae8129b1d1fa1197c",
                "size": 393155925
            },
            "errors": "",
            "filename": "2023-11-01T12-44-12Z",
            "size": 393155925,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698842858,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "o8Cyc0WCCGDAMO/F19uPkhjVWPI83DDaZuRUvcFR/U9O8vMXndc6e8FX/aocrlj3sNw3xAR1IfAmk5/5bfR0Jp8I/ZZqe/TXJ5ZNx50oSlhze44KiT0uOyv89/EhX8VjyUY/twnYyNOmVEgthJqhDT7CZUxVgeQJzkhAdnIt3zMvzbXyUvatx5p9o7qArDMMl9KkNQP+/9kbvw7qaTvr/0nMvY0BpENLuokcqBoFc8vejQPE6keQCbpWO1o0Bda+nGuRpoCh6FOqztXbzseo7K1FWHwUK1u3BUuJiXWzTPFeNWI02nBAC5uKJnxv5tfX53VKMJvXkgaX5Xp8l5igNAHvbtdsjT5Sxh8fsVyK5/VENIqsc4ykks4F3FMCSZ1ZT0BIIfRz9OhJE7o99olBSxYIevGBua7urf7xfTWto3Sphicgd2k80HGn1+pp40Hf2RSLZUn9E3mPzWB1bHViuSeWN7MJTfoMNGQZGkCBOBzm5NwajOxm1LV8eaeaSMYTMOmbIwuyPgwQxpgUi6WfF3EaX0KPSK/aGF+DBvA5TWe1wg7nf18aox0UHiyBCs8OJLdB7AvMsFXckP5SCFQP1GeaAy+n4LKnfwtunZ8C+7m79gR0CqkpFIYuRtJFp96Ek5sEKE7ZEhJsHqlF26BRYm+My+PmbVMfZY/25Y0UkwA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T12-47-34Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698842730.1427698,
                        "modified": 1698842730.1427698,
                        "name": "/dev/shm/incoming/2023-11-01T12:35:24.ais",
                        "sha256": "6e1c08f680c3b84eb3cf000bcda43a8403763a0ecfefe3c56c7bfdf717490f12",
                        "size": 58801
                    }
                ],
                "mission": "EFCA1",
                "sha256": "e3e2de8f2f1ef3e0b4b85eaeee4154aaa509ffb416444763f5cfe0521219a223",
                "size": 15704
            },
            "errors": "",
            "filename": "2023-11-01T12-47-34Z",
            "size": 15704,
            "verified_at": null
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698841656,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "k/Q9oKwZqw2hx5T8MQ28HAvf8rYvUBDCC6TRSA0VzcW9QUI42ZOK43S81C0E7oV5vXnaGW7jnj3kpUg7mS0LdV05BvZrqt69yhBZfnn96ZGSqNw8jDmE8p7Pqfo8OtyR5N8hX07l1G0uZK881sxmBDUoBYXN0I6FTjXxn5DnC/y/XDTi3Zw9WjTUExiWgH8+5F05rICDNJhzPYnuYiW+jIRvuHEirV9RDKFHCue9xX4BKMsObRsAyVI1Qm6tEz4jor/7bWAu1GfcC2HaRcqWsLsxUCZukmnQduNK5puNAS24+PRszS3F66cLVSjN+aGGCOk8Goot5xyiA7woRAyKb3q/ePcDcKWIAgpOPnfwcX4Qn7TDhqDKhwPN31RKRPCmK5GNTMMfJlZqRbq4DVF8AUAQOcIy/Z0bSAjrsYG4MG/IWRIQrJAT7GqWySQ7EyrYXN4NZyfRLc8V71ZlY6bAeEej6/piJTLTPLmmAL9HMJGBVsAON66EtWwpVvg/d23+kih1b7fYs/iaXvZQ1CKZmCjtwE40SC/dKc+7Eg7OxwkKj+dKCxYWepS3dTuabz8DXm4Iv+yIlaDBQWEI6c7J9Kgg1lOn9FfS0FIBO+aWerJgdxDPYoBKDJpnEcqJ6l1f0Biu30y/4cTtt+R7uZYu6l3EsZMCv8vQLVz5HsDFvO4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T12-27-31Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698841523.6327698,
                        "modified": 1698841523.6327698,
                        "name": "/dev/shm/incoming/2023-11-01T12:15:19.ais",
                        "sha256": "f62dec05a40e941de3a45afe71073eb78d4c22956390d3e3468078bcdc1c4329",
                        "size": 194953
                    }
                ],
                "mission": "EFCA1",
                "sha256": "64cbc2234a61d4b2cd5614d46ee0a5abc058a25bdd200277bf694aafddf21a6f",
                "size": 38250
            },
            "errors": "",
            "filename": "2023-11-01T12-27-31Z",
            "size": 38250,
            "verified_at": 1698848127
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698842881,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "WeSjyZk0lZQwVs6ScQgtN0KEknKDQaci32qEd7gfPK1dC8qXQ+dbCEJFr7L+9sUuG7utUf61clkqU5vdyHgkT6U7NkUO2KOIFJGcEGaU64CAB5L/+U1mPTzS1HlVSkxgQ/r3qBvGeJxXwcdSPiY844ZY5LoOQYdN8CAYGHE0c1v4QswQZIHEQmaKtrY3S5SMI7OkfeCyk3g2anAow3Z9ylomPqhm7xg4aN4zAi5L72HxwJWJY2rj6pgnLlA0WAxB9oVwcW2WU2Npuj9OlYJlzlNYlTk+iGcJBbtg2q1S5X5f32Tl3fQNgkqMvfyynOZC6ZGp/84Vy0vZXCB7oPe29onTEIsvjIKqDGB902BPVeCWClE//SXJWPitY7DRjkq8gf9MFi37EVqs8CpTcd5q+1hoC6cliztsm1vORGOn+sZsuyuMkjFYhOzNmX5g7EmADkI72dBUjisMcuOkjyabwv+dc6vwYprWXpylIvgqxCeDiaB+BVLnMbIDKnv8qoRjufWDbHSTriy/0DSkvLmIGIxYAnPQ8m+YE/CUTxwwT+fy2QqOPMuXzShOxhhSdINujwQzfmaBxWYbfDeIq24uwqhgVgI+CnYy/l5vuNt4Dgp6a8FtLcDBPa8iCMocZFb/8mOwwHxTVS2+u8DKHwk53SAgcncOEukp4KRFVL+1bgI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T12-47-09Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698842677.0996625,
                        "modified": 1698842677.0996625,
                        "name": "/dev/shm/incoming/2023_11_01_124421_00_00_K_124.txt",
                        "sha256": "49e557b115cb96dd16dc16ffec497fb8b6b8ace6455bdd3ccb0d1280d2dd9c46",
                        "size": 2041
                    },
                    {
                        "created": 1698842678.0096626,
                        "modified": 1698842678.0096626,
                        "name": "/dev/shm/incoming/2023_11_01_124421_00_00_K_124.kml",
                        "sha256": "91b6a9ba076ea08d1cdb605da38129c3ce3fefb48f4eaf50527505c7a143b4d3",
                        "size": 974
                    },
                    {
                        "created": 1698842676.1896627,
                        "modified": 1698842676.1896627,
                        "name": "/dev/shm/incoming/2023_11_01_124421_00_00_K_124.jpg",
                        "sha256": "6d00a2790cec0946915b02ebf7866622f3ee47f11be5b0b954c731b9b96f4ccf",
                        "size": 554802
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "27a341ba98f2b7844b166c75e3826e036c91a2e7f5157b348be338c105a3813c",
                "size": 543669
            },
            "errors": "",
            "filename": "2023-11-01T12-47-09Z",
            "size": 543669,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698843524,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "CD5D18d+TE522M7ZGyxBtnIMioc35FjvkpMsHQrAq1rT2y8amHqMeLHt+9l0vCwLD2NrDmlRzntztcNwd8oheE4uPjDvCfrxEkPwUEX3KIfjxyemXw5cOVaPfDgeRFtxM3qd84EtqxDnNuiJlDedz+ReIH7bvYJBDPgaMLAJWhF7hcposrriJh8mVol+bnCJZc634OKseamUF/haiPIPH9OeZZdVBNvLjncCILOMrdPG7c6YfUHdTBiP7QEALA2g1kjGkxMM6WupMojQEUh01NpaSlIzzhPfPwuDkb96/02gpl8xzRr+E0twyFT63JIg5ZVO0rQD7Fo/qBWvuLWjfP4VDi2s47lIPFlJ+UW1HNQrTRTiLTcvm+4zVz3H6K6FjMeaUfQ7VjwNwPQyqEar3gzDPqJqcfmj75CQ1dgej3mmv4dac67dd8Px/PlYB+3I1Ti5OSEx2OKIcSAbBwJFJVl5PhXrfXh8Ph2Z+FLbSyEXVjYAYzicLyWTU0bp8gIzMjS1Zovb768xt7zpLcWchU6NiOb99Z5JgB5qaMmR8RUEVxK7FRzX0S9NYoS9EXfUh6HHOWfHFlvqyIG+oXnEFb2R9X7c2ZaWBtBTkiAzlwLh1e2Ygp6qk4RYS260m74TSaKhh3WQAgrPISLjBN4HbPmH1SAwYgXH0whr22AALbI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T12-58-07Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698843383.0496626,
                        "modified": 1698843383.0496626,
                        "name": "/dev/shm/incoming/2023_11_01_125538_00_00_K_156.txt",
                        "sha256": "85bc92a0201196d0f2de19ad2a2844d19d9fada6e23e767072ce821e05cca3a7",
                        "size": 2050
                    },
                    {
                        "created": 1698843364.5496626,
                        "modified": 1698843364.5496626,
                        "name": "/dev/shm/incoming/2023_11_01_125538_00_00_K_156.kml",
                        "sha256": "e62959c9b981c174180322e71f2bcf4bdce90eace6e50bf9734be2e76d4c1332",
                        "size": 977
                    },
                    {
                        "created": 1698843364.4096625,
                        "modified": 1698843364.4096625,
                        "name": "/dev/shm/incoming/2023_11_01_125538_00_00_K_156.jpg",
                        "sha256": "11272d532450759384e8d2f8757c2562d6cdc455327447d30f51f627e65f7bfe",
                        "size": 1069608
                    },
                    {
                        "created": 1698843352.9796627,
                        "modified": 1698843352.9796627,
                        "name": "/dev/shm/incoming/2023_11_01_125527_00_00_K_155.txt",
                        "sha256": "ba3d0e017ac921c48682336c299cbb6151d5227b09e06a6cd1cac7ddc0a3d782",
                        "size": 2050
                    },
                    {
                        "created": 1698843360.2196627,
                        "modified": 1698843360.2196627,
                        "name": "/dev/shm/incoming/2023_11_01_125527_00_00_K_155.kml",
                        "sha256": "a8b7889bc98f85713770a6b446bc8ea51ac4065791ced4332473e0ae4d5722db",
                        "size": 977
                    },
                    {
                        "created": 1698843352.6896627,
                        "modified": 1698843352.6896627,
                        "name": "/dev/shm/incoming/2023_11_01_125527_00_00_K_155.jpg",
                        "sha256": "80b91e06a82f3deeca6f6a67a4603802082f3eec7bf6fbb6f02a4d2c60f95d5c",
                        "size": 1067470
                    },
                    {
                        "created": 1698843342.7496626,
                        "modified": 1698843342.7496626,
                        "name": "/dev/shm/incoming/2023_11_01_125521_00_00_K_154.txt",
                        "sha256": "0329aaa2835e8e1d075d05c800960ae1c06353c3a7173ad740b11e4105275d18",
                        "size": 2050
                    },
                    {
                        "created": 1698843348.5096626,
                        "modified": 1698843348.5096626,
                        "name": "/dev/shm/incoming/2023_11_01_125521_00_00_K_154.kml",
                        "sha256": "717091b9c3ba10f1c4a2eae8bfa6dd4ea45ca707e1916bb70ed6e06bc6b3c5e0",
                        "size": 975
                    },
                    {
                        "created": 1698843348.5496626,
                        "modified": 1698843348.5496626,
                        "name": "/dev/shm/incoming/2023_11_01_125521_00_00_K_154.jpg",
                        "sha256": "fa19ade45268bf45e2100063dd17290475379e219395ad8e8cb70e70569e6106",
                        "size": 1024628
                    },
                    {
                        "created": 1698843336.7296627,
                        "modified": 1698843336.7296627,
                        "name": "/dev/shm/incoming/2023_11_01_125517_00_00_K_153.txt",
                        "sha256": "33c274c7087163176a6c6c5314000155615dcdb1895b475890cda41d30d398dd",
                        "size": 2050
                    },
                    {
                        "created": 1698843345.2296627,
                        "modified": 1698843345.2296627,
                        "name": "/dev/shm/incoming/2023_11_01_125517_00_00_K_153.kml",
                        "sha256": "cd39c0441bff4bf283d901ccd29e641bf152e64b53e0567fe4610baa1ce19770",
                        "size": 978
                    },
                    {
                        "created": 1698843336.0896626,
                        "modified": 1698843336.0896626,
                        "name": "/dev/shm/incoming/2023_11_01_125517_00_00_K_153.jpg",
                        "sha256": "29d4047ac5e9e50579d27ebb88c614525df05816c378ed8ec5b8283c2bed033b",
                        "size": 1030665
                    },
                    {
                        "created": 1698843335.0296626,
                        "modified": 1698843335.0296626,
                        "name": "/dev/shm/incoming/2023_11_01_125510_00_00_K_152.txt",
                        "sha256": "aa6af5d7c654c36dc11468d2739e255e5a4ccb697fe98b8b5e63fe75b52b0706",
                        "size": 2050
                    },
                    {
                        "created": 1698843335.6096625,
                        "modified": 1698843335.6096625,
                        "name": "/dev/shm/incoming/2023_11_01_125510_00_00_K_152.kml",
                        "sha256": "980cdf1f6dac80bd319320f604457fab3784982e7c75e10c50e913aac3986bb0",
                        "size": 978
                    },
                    {
                        "created": 1698843334.4096625,
                        "modified": 1698843334.4096625,
                        "name": "/dev/shm/incoming/2023_11_01_125510_00_00_K_152.jpg",
                        "sha256": "d1c3a8d74145f4769d3651e933f2cc28956386cb52e64af12293e844986feaa6",
                        "size": 1049355
                    },
                    {
                        "created": 1698843324.8096626,
                        "modified": 1698843324.8096626,
                        "name": "/dev/shm/incoming/2023_11_01_125452_00_00_K_151.txt",
                        "sha256": "7313bbd0ec700dce3ac41049a0e972171ffb94d61c76458dd6ee35aa4ebdade7",
                        "size": 2050
                    },
                    {
                        "created": 1698843323.7196627,
                        "modified": 1698843323.7196627,
                        "name": "/dev/shm/incoming/2023_11_01_125452_00_00_K_151.kml",
                        "sha256": "70d94516892c939aa9b818651533e6aaa2399257512603ec5f462b086c7f69e7",
                        "size": 979
                    },
                    {
                        "created": 1698843312.3496625,
                        "modified": 1698843312.3496625,
                        "name": "/dev/shm/incoming/2023_11_01_125452_00_00_K_151.jpg",
                        "sha256": "89012f7ccb7a336ffca20262b755acea04f8f0cf26f8b33eb122a8a36d5d7808",
                        "size": 1051775
                    },
                    {
                        "created": 1698843320.9896626,
                        "modified": 1698843320.9896626,
                        "name": "/dev/shm/incoming/2023_11_01_125439_00_00_K_150.txt",
                        "sha256": "7b4cf5c5749e82bfd7077be836c8e0f0bbd9fdf1845bf2da3a7aa9f4b21581b8",
                        "size": 2050
                    },
                    {
                        "created": 1698843306.6896627,
                        "modified": 1698843306.6896627,
                        "name": "/dev/shm/incoming/2023_11_01_125439_00_00_K_150.kml",
                        "sha256": "004d3a02e7b2db0ce42a4e944600688015db82df9c3c41e7922521318f84d53b",
                        "size": 977
                    },
                    {
                        "created": 1698843308.8796625,
                        "modified": 1698843308.8796625,
                        "name": "/dev/shm/incoming/2023_11_01_125439_00_00_K_150.jpg",
                        "sha256": "d113f82eb0f6a76e4f9b784ba53086a3c9709fc8d22c1f27222b15bf82986d30",
                        "size": 1103811
                    },
                    {
                        "created": 1698843275.9496627,
                        "modified": 1698843275.9496627,
                        "name": "/dev/shm/incoming/2023_11_01_125417_00_00_K_149.txt",
                        "sha256": "6aca76744733cb730184eee6844dfae28870ae5602af4a601195cea116e317dc",
                        "size": 2050
                    },
                    {
                        "created": 1698843296.8196626,
                        "modified": 1698843296.8196626,
                        "name": "/dev/shm/incoming/2023_11_01_125417_00_00_K_149.kml",
                        "sha256": "40bf7cf9d162ca55a80e39a339a9d9a7dcaa473f190372b9f336e6184a9c0aa0",
                        "size": 978
                    },
                    {
                        "created": 1698843288.1396625,
                        "modified": 1698843288.1396625,
                        "name": "/dev/shm/incoming/2023_11_01_125417_00_00_K_149.jpg",
                        "sha256": "cf9388c85cee9da75f0ff2d5ecea281e92b08f24c3b55c77e79d9c84b85cd0c7",
                        "size": 1253970
                    },
                    {
                        "created": 1698843274.3396626,
                        "modified": 1698843274.3396626,
                        "name": "/dev/shm/incoming/2023_11_01_125413_00_00_K_148.txt",
                        "sha256": "2227994bd0c504ae2d05057a6a7d496067ecc34acf4fe1f35c128b689d145e2c",
                        "size": 2050
                    },
                    {
                        "created": 1698843273.9796627,
                        "modified": 1698843273.9796627,
                        "name": "/dev/shm/incoming/2023_11_01_125413_00_00_K_148.kml",
                        "sha256": "987c82528f8d1ff52784382d22692fad4445a2ef226f014103859bcbe8b53a7c",
                        "size": 975
                    },
                    {
                        "created": 1698843273.6296625,
                        "modified": 1698843273.6296625,
                        "name": "/dev/shm/incoming/2023_11_01_125413_00_00_K_148.jpg",
                        "sha256": "d124c8be867ba9cf31d81319f4d7fadf9f988d34ef4d50e9cc9643b0a5c3312c",
                        "size": 1131413
                    },
                    {
                        "created": 1698843239.7896626,
                        "modified": 1698843239.7896626,
                        "name": "/dev/shm/incoming/2023_11_01_125339_00_00_K_147.txt",
                        "sha256": "4543d1cf88e5190255cce6b88eaa2d7f288f9ec3ad056e1f64f5bf230f9e2f01",
                        "size": 2050
                    },
                    {
                        "created": 1698843249.1996627,
                        "modified": 1698843249.1996627,
                        "name": "/dev/shm/incoming/2023_11_01_125339_00_00_K_147.kml",
                        "sha256": "8a296b8db2fb4a91838659cbefa26a48289e9871c9f60df5c4710771c7d463c5",
                        "size": 978
                    },
                    {
                        "created": 1698843246.5996625,
                        "modified": 1698843246.5996625,
                        "name": "/dev/shm/incoming/2023_11_01_125339_00_00_K_147.jpg",
                        "sha256": "4254c24cf07bf3f80125cb91ceb89e61d3d4441b6a09af5affe35c26ffae6c9f",
                        "size": 709200
                    },
                    {
                        "created": 1698843237.9396627,
                        "modified": 1698843237.9396627,
                        "name": "/dev/shm/incoming/2023_11_01_125336_00_00_K_146.txt",
                        "sha256": "093c8d7c5e53bfc55a1693a23aadcb08bc94c9e5fcf56e404b38926b11727d71",
                        "size": 2050
                    },
                    {
                        "created": 1698843237.3196626,
                        "modified": 1698843237.3196626,
                        "name": "/dev/shm/incoming/2023_11_01_125336_00_00_K_146.kml",
                        "sha256": "310e394ea68fa899f2ae7c8a2ae6e9bf34303ed1956830aa8cc0c103c0bc6ac4",
                        "size": 976
                    },
                    {
                        "created": 1698843236.7196627,
                        "modified": 1698843236.7196627,
                        "name": "/dev/shm/incoming/2023_11_01_125336_00_00_K_146.jpg",
                        "sha256": "fbe0fcaa63f911d34f2d6f24f000c9fe0a28c56dbb821532359f7faf167a3df6",
                        "size": 697252
                    },
                    {
                        "created": 1698843230.5696626,
                        "modified": 1698843230.5696626,
                        "name": "/dev/shm/incoming/2023_11_01_125322_00_00_K_145.txt",
                        "sha256": "5ec19229b47e7c8fb1453c45b49e2702933b36bbf396f966bd39b4f7062263b5",
                        "size": 2050
                    },
                    {
                        "created": 1698843228.3796625,
                        "modified": 1698843228.3796625,
                        "name": "/dev/shm/incoming/2023_11_01_125322_00_00_K_145.kml",
                        "sha256": "43f6ae15e218289dc673a17f6326ca730681ab151ad1c61ec67a6a29945d4490",
                        "size": 976
                    },
                    {
                        "created": 1698843227.9596627,
                        "modified": 1698843227.9596627,
                        "name": "/dev/shm/incoming/2023_11_01_125322_00_00_K_145.jpg",
                        "sha256": "b5bd5bad4f6f500ee28c9ee1e85c95732ce16e4444920223ce5bbf02188e2953",
                        "size": 1215536
                    },
                    {
                        "created": 1698843223.6496625,
                        "modified": 1698843223.6496625,
                        "name": "/dev/shm/incoming/2023_11_01_125318_00_00_K_144.txt",
                        "sha256": "043a1f9f012b478f81684d78890c3d8da04f8f01c7282c3754aa7928f447d0f5",
                        "size": 2050
                    },
                    {
                        "created": 1698843234.9696627,
                        "modified": 1698843234.9696627,
                        "name": "/dev/shm/incoming/2023_11_01_125318_00_00_K_144.kml",
                        "sha256": "25e11cf8bbf5e8868086679c35c87898320765fbfd0ec83bef61eb2345556653",
                        "size": 977
                    },
                    {
                        "created": 1698843222.9596627,
                        "modified": 1698843222.9596627,
                        "name": "/dev/shm/incoming/2023_11_01_125318_00_00_K_144.jpg",
                        "sha256": "f6a7c47958ee615189ad46138bc6ccefadc619db6f64a78d424ff2c94dd6976e",
                        "size": 1117736
                    },
                    {
                        "created": 1698843211.9896626,
                        "modified": 1698843211.9896626,
                        "name": "/dev/shm/incoming/2023_11_01_125310_00_00_K_143.txt",
                        "sha256": "442a840ab5ade0104ad2628cb14b856e34876442d206a6bb0bcf30f5ecd06d27",
                        "size": 2049
                    },
                    {
                        "created": 1698843220.2596626,
                        "modified": 1698843220.2596626,
                        "name": "/dev/shm/incoming/2023_11_01_125310_00_00_K_143.kml",
                        "sha256": "0af5f60a51756a7fdc24562a2297e5421a49fc943c8d947bfa76837e3ef668a9",
                        "size": 977
                    },
                    {
                        "created": 1698843221.3796625,
                        "modified": 1698843221.3796625,
                        "name": "/dev/shm/incoming/2023_11_01_125310_00_00_K_143.jpg",
                        "sha256": "b47607cd2fb5ed1185ca9def482a757cc962cdc97524368ee8150e244b8f625e",
                        "size": 1114664
                    },
                    {
                        "created": 1698843209.2996626,
                        "modified": 1698843209.2996626,
                        "name": "/dev/shm/incoming/2023_11_01_125305_00_00_K_142.txt",
                        "sha256": "f7de8a508aeaac9de40ebdacd1f02f344f5376a5ed7d7a2b2e05fefdca954a71",
                        "size": 2049
                    },
                    {
                        "created": 1698843209.8896625,
                        "modified": 1698843209.8896625,
                        "name": "/dev/shm/incoming/2023_11_01_125305_00_00_K_142.kml",
                        "sha256": "2342544162de23dd25290c904a7bbb024772c2ce1100c03c00b26314d26ae606",
                        "size": 979
                    },
                    {
                        "created": 1698843208.7096627,
                        "modified": 1698843208.7096627,
                        "name": "/dev/shm/incoming/2023_11_01_125305_00_00_K_142.jpg",
                        "sha256": "10b513e288f1468c489828b9e4433be5a96cd90b531e13e3111fb73e7046ab22",
                        "size": 966387
                    },
                    {
                        "created": 1698843201.7296627,
                        "modified": 1698843201.7296627,
                        "name": "/dev/shm/incoming/2023_11_01_125259_00_00_K_141.txt",
                        "sha256": "5e202f485fb89020558bde0dae0107f4d3310bc5982ec1a2f72fdfdeff4a1dea",
                        "size": 2049
                    },
                    {
                        "created": 1698843202.6996627,
                        "modified": 1698843202.6996627,
                        "name": "/dev/shm/incoming/2023_11_01_125259_00_00_K_141.kml",
                        "sha256": "245028ddafea8b2a9c3f6a515505109be1d96b23371eb107e29aacdd94f73356",
                        "size": 977
                    },
                    {
                        "created": 1698843202.6696627,
                        "modified": 1698843202.6696627,
                        "name": "/dev/shm/incoming/2023_11_01_125259_00_00_K_141.jpg",
                        "sha256": "bcbb2a7ddbd20d8a4bbce7276cfa55b8446579e8f354f72a621fadf50a71b8db",
                        "size": 1033001
                    },
                    {
                        "created": 1698843195.3396626,
                        "modified": 1698843195.3396626,
                        "name": "/dev/shm/incoming/2023_11_01_125255_00_00_K_140.txt",
                        "sha256": "9b029decd55854a00e98bd1ce1c6f7c506d877dc19ecc079e34036c3009e5554",
                        "size": 2049
                    },
                    {
                        "created": 1698843195.2196627,
                        "modified": 1698843195.2196627,
                        "name": "/dev/shm/incoming/2023_11_01_125255_00_00_K_140.kml",
                        "sha256": "8a745b09068529ff551319fdbd5cb0e3c8350df5df317850cdcd3148e8ff2d39",
                        "size": 975
                    },
                    {
                        "created": 1698843195.0596626,
                        "modified": 1698843195.0596626,
                        "name": "/dev/shm/incoming/2023_11_01_125255_00_00_K_140.jpg",
                        "sha256": "0c58fc60c8ed6785c13c11fa1caba62a40a5305a52a07fb9907afc29d11feb05",
                        "size": 1024210
                    },
                    {
                        "created": 1698843192.8296626,
                        "modified": 1698843192.8296626,
                        "name": "/dev/shm/incoming/2023_11_01_125237_00_00_K_139.txt",
                        "sha256": "565549be54e62af1512e46707fd2dbb974c4494fe64191bccfafed88194205bc",
                        "size": 2050
                    },
                    {
                        "created": 1698843193.3596625,
                        "modified": 1698843193.3596625,
                        "name": "/dev/shm/incoming/2023_11_01_125237_00_00_K_139.kml",
                        "sha256": "e330e46e614c291fcd52bb704b9c05a879feead8be4c07082bf5735091be0659",
                        "size": 978
                    },
                    {
                        "created": 1698843183.4996626,
                        "modified": 1698843183.4996626,
                        "name": "/dev/shm/incoming/2023_11_01_125237_00_00_K_139.jpg",
                        "sha256": "314fa45368a062c38a2096dc6ffd38cef8439903777d64e694434b07133ac50b",
                        "size": 768723
                    },
                    {
                        "created": 1698843178.8996625,
                        "modified": 1698843178.8996625,
                        "name": "/dev/shm/incoming/2023_11_01_125220_00_00_K_138.txt",
                        "sha256": "a26fc2e6243e796749659107f4a2ac6756de28b82e5111d36802d705306edd19",
                        "size": 2050
                    },
                    {
                        "created": 1698843164.6296625,
                        "modified": 1698843164.6296625,
                        "name": "/dev/shm/incoming/2023_11_01_125220_00_00_K_138.kml",
                        "sha256": "2a825d3e570e749b776cdb353c02a6b1ed12761739ab6f7103235dbb3ea6b66a",
                        "size": 976
                    },
                    {
                        "created": 1698843162.3296626,
                        "modified": 1698843162.3296626,
                        "name": "/dev/shm/incoming/2023_11_01_125220_00_00_K_138.jpg",
                        "sha256": "0a7c7c23c4246b5a5678d26cd5677a82e4b68056c6204adf80c0fbd7a7a4c04a",
                        "size": 993909
                    },
                    {
                        "created": 1698843158.0296626,
                        "modified": 1698843158.0296626,
                        "name": "/dev/shm/incoming/2023_11_01_125217_00_00_K_137.txt",
                        "sha256": "4e0ded8683a179373082612723f97fe61202a563384fd6dd55273c39d6df2f4b",
                        "size": 2050
                    },
                    {
                        "created": 1698843158.5496626,
                        "modified": 1698843158.5496626,
                        "name": "/dev/shm/incoming/2023_11_01_125217_00_00_K_137.kml",
                        "sha256": "36bbae3c905c258834b07030d45c0e0e0135c7abe2d48fba8d6ded29de86b0a6",
                        "size": 974
                    },
                    {
                        "created": 1698843157.1196625,
                        "modified": 1698843157.1196625,
                        "name": "/dev/shm/incoming/2023_11_01_125217_00_00_K_137.jpg",
                        "sha256": "8de4c1750e2affa95798319fd142ad5ae60eb66e54a034752ad9d733fc2eb4d6",
                        "size": 1049537
                    },
                    {
                        "created": 1698843156.4296627,
                        "modified": 1698843156.4296627,
                        "name": "/dev/shm/incoming/2023_11_01_125213_00_00_K_136.txt",
                        "sha256": "9fcbe2b5c770bc0bac3a0549f47512f970e09693497b70eb8f7154f35ec64ac8",
                        "size": 2050
                    },
                    {
                        "created": 1698843158.2596626,
                        "modified": 1698843158.2596626,
                        "name": "/dev/shm/incoming/2023_11_01_125213_00_00_K_136.kml",
                        "sha256": "778195edd18cc7eda872e895a4be3e59c2551590890d5538c0ab76c105bd569c",
                        "size": 974
                    },
                    {
                        "created": 1698843155.7296627,
                        "modified": 1698843155.7296627,
                        "name": "/dev/shm/incoming/2023_11_01_125213_00_00_K_136.jpg",
                        "sha256": "24a11b6527d41e8fac0131bd19b5664a57e47ed982ac1d4f3fd8ef0d5da57e99",
                        "size": 1147741
                    },
                    {
                        "created": 1698843153.7396626,
                        "modified": 1698843153.7396626,
                        "name": "/dev/shm/incoming/2023_11_01_125159_00_00_K_135.txt",
                        "sha256": "f14a84eda43e97056f0744750f1c6fbb093f8288605588c9cb7fb6bf4fec3fa2",
                        "size": 2050
                    },
                    {
                        "created": 1698843146.8696625,
                        "modified": 1698843146.8696625,
                        "name": "/dev/shm/incoming/2023_11_01_125159_00_00_K_135.kml",
                        "sha256": "f3aa00d8ae6ea4cb66cb17b9e39923042deefe03dca45736da2d984e48d291b4",
                        "size": 977
                    },
                    {
                        "created": 1698843145.6096625,
                        "modified": 1698843145.6096625,
                        "name": "/dev/shm/incoming/2023_11_01_125159_00_00_K_135.jpg",
                        "sha256": "68c1e2dd20d04343057566fb0812e0cec66bcbd8e61bf4b1f89574f0c7095888",
                        "size": 1048094
                    },
                    {
                        "created": 1698843141.0996625,
                        "modified": 1698843141.0996625,
                        "name": "/dev/shm/incoming/2023_11_01_125144_00_00_K_134.txt",
                        "sha256": "2b564bf1fda406d8497eaa036772c4df72b0d122b7a2971d8eac33790a84f9a1",
                        "size": 2050
                    },
                    {
                        "created": 1698843132.3596625,
                        "modified": 1698843132.3596625,
                        "name": "/dev/shm/incoming/2023_11_01_125144_00_00_K_134.kml",
                        "sha256": "2ba26cdac84c44978fb0a87ce41cb56df4c86796dd5e8287aac64ecf607e7dd0",
                        "size": 977
                    },
                    {
                        "created": 1698843136.1996627,
                        "modified": 1698843136.1996627,
                        "name": "/dev/shm/incoming/2023_11_01_125144_00_00_K_134.jpg",
                        "sha256": "5bdb17dfd49c4661983aeec9e0fcb7359d1e77444890ae3c0efd91d4248c0b5d",
                        "size": 1151759
                    },
                    {
                        "created": 1698843107.8296626,
                        "modified": 1698843107.8296626,
                        "name": "/dev/shm/incoming/2023_11_01_125130_00_00_K_133.txt",
                        "sha256": "599e3ca9827dfb0c27c7e78f1f349b704cf1e1c6c7910a01719f9e69bc3659f7",
                        "size": 2050
                    },
                    {
                        "created": 1698843108.1896627,
                        "modified": 1698843108.1896627,
                        "name": "/dev/shm/incoming/2023_11_01_125130_00_00_K_133.kml",
                        "sha256": "eda19ae1acaed9b349f9af7d9e4dbc4db52e647857bb9f6308e1b9bea0e7b749",
                        "size": 978
                    },
                    {
                        "created": 1698843106.7896626,
                        "modified": 1698843106.7896626,
                        "name": "/dev/shm/incoming/2023_11_01_125130_00_00_K_133.jpg",
                        "sha256": "b922d946b472b224102cc9d2e9388d5728da35e6178c8f92016ba704af318861",
                        "size": 1157461
                    },
                    {
                        "created": 1698843105.7296627,
                        "modified": 1698843105.7296627,
                        "name": "/dev/shm/incoming/2023_11_01_125119_00_00_K_132.txt",
                        "sha256": "c100ffc0b58c41934c975ed6d223450e6820cb17d03e3a548c4c228eb779554a",
                        "size": 2050
                    },
                    {
                        "created": 1698843108.2696626,
                        "modified": 1698843108.2696626,
                        "name": "/dev/shm/incoming/2023_11_01_125119_00_00_K_132.kml",
                        "sha256": "7b985696e6e45f338cc11b629a69e8f1827862cddcb91239e28d4c5c3af17d6f",
                        "size": 975
                    },
                    {
                        "created": 1698843104.8896625,
                        "modified": 1698843104.8896625,
                        "name": "/dev/shm/incoming/2023_11_01_125119_00_00_K_132.jpg",
                        "sha256": "6c31c259fa9f28f3e1d2af5f657be6fb6a084ced96af8bde047167de65e4ee12",
                        "size": 1103746
                    },
                    {
                        "created": 1698843096.7796626,
                        "modified": 1698843096.7796626,
                        "name": "/dev/shm/incoming/2023-11-01T12:41:35.ais",
                        "sha256": "efd4d6089958e9ccd1e7164ffbca930ff567c8c1858d279b74b074ecb1c364b1",
                        "size": 868602
                    },
                    {
                        "created": 1698843099.5596626,
                        "modified": 1698843099.5596626,
                        "name": "/dev/shm/incoming/2023_11_01_125109_00_00_K_131.txt",
                        "sha256": "e7017aeac8f2026089feb8c9634bc6afccda0ce1745feec51e1c0f7488acdea6",
                        "size": 2039
                    },
                    {
                        "created": 1698843098.3996625,
                        "modified": 1698843098.3996625,
                        "name": "/dev/shm/incoming/2023_11_01_125109_00_00_K_131.kml",
                        "sha256": "c31f252ca686462bf801d54bd59ec2ac2d85d244fde8fb9594b5ac435eebf07d",
                        "size": 975
                    },
                    {
                        "created": 1698843102.4696627,
                        "modified": 1698843102.4696627,
                        "name": "/dev/shm/incoming/2023_11_01_125109_00_00_K_131.jpg",
                        "sha256": "21d31a42cc3a35f66f80a64062f589b458e8e53656726c4790ee492cb4084b24",
                        "size": 1151865
                    },
                    {
                        "created": 1698843088.2596626,
                        "modified": 1698843088.2596626,
                        "name": "/dev/shm/incoming/2023_11_01_125057_00_00_K_130.txt",
                        "sha256": "5ade51c1431c07f4bb04fde0970a94ed408267981f70e8bc37f02e26bc12f0f2",
                        "size": 2042
                    },
                    {
                        "created": 1698843080.0896626,
                        "modified": 1698843080.0896626,
                        "name": "/dev/shm/incoming/2023_11_01_125057_00_00_K_130.kml",
                        "sha256": "ae74198efb3bf7fe2c32ae2ea3d2ffbbc4d1c9d96eb5fa9d8422c126db65e879",
                        "size": 981
                    },
                    {
                        "created": 1698843079.6196625,
                        "modified": 1698843079.6196625,
                        "name": "/dev/shm/incoming/2023_11_01_125057_00_00_K_130.jpg",
                        "sha256": "f02d9d204996724d0735245ee400f59b5e6b217a9c9b147ad326b2d3221c9277",
                        "size": 768751
                    },
                    {
                        "created": 1698843069.9696627,
                        "modified": 1698843069.9696627,
                        "name": "/dev/shm/incoming/2023_11_01_125045_00_00_K_129.txt",
                        "sha256": "5003e8c51d07e140d9a6f07f9d4c9cadb5445939c34bc542018e483ef35a0988",
                        "size": 2046
                    },
                    {
                        "created": 1698843076.8996625,
                        "modified": 1698843076.8996625,
                        "name": "/dev/shm/incoming/2023_11_01_125045_00_00_K_129.kml",
                        "sha256": "699316f11d5424610412690d2e0a0a4657f8d5002cc70fb1b64a64e1a4e299eb",
                        "size": 977
                    },
                    {
                        "created": 1698843070.3896625,
                        "modified": 1698843070.3896625,
                        "name": "/dev/shm/incoming/2023_11_01_125045_00_00_K_129.jpg",
                        "sha256": "390affbfee085d5d82af5ce029ef3910a8408181decb6365481f44d1963b961f",
                        "size": 579001
                    },
                    {
                        "created": 1698843066.2496626,
                        "modified": 1698843066.2496626,
                        "name": "/dev/shm/incoming/2023_11_01_125043_00_00_K_128.txt",
                        "sha256": "7da479a52081a2553ca050927ab80877792d07a94d1f422550c4031759360637",
                        "size": 2044
                    },
                    {
                        "created": 1698843078.6196625,
                        "modified": 1698843078.6196625,
                        "name": "/dev/shm/incoming/2023_11_01_125043_00_00_K_128.kml",
                        "sha256": "1d35a67e6ae0a6d773656ee587093718b6276cf59b4f278e1e12c15cbc50360d",
                        "size": 977
                    },
                    {
                        "created": 1698843065.4596627,
                        "modified": 1698843065.4596627,
                        "name": "/dev/shm/incoming/2023_11_01_125043_00_00_K_128.jpg",
                        "sha256": "1d692672e07ed42b8c72f4a0589223c4004647b99a550133a9f8b9e9213e17f2",
                        "size": 576567
                    },
                    {
                        "created": 1698843051.6396625,
                        "modified": 1698843051.6396625,
                        "name": "/dev/shm/incoming/2023_11_01_125020_00_00_K_127.txt",
                        "sha256": "35ebcb5d0eae3b49a32a8ccf7c86db202c99656266d21beadfc86a482aa44e52",
                        "size": 2050
                    },
                    {
                        "created": 1698843064.7396626,
                        "modified": 1698843064.7396626,
                        "name": "/dev/shm/incoming/2023_11_01_125020_00_00_K_127.kml",
                        "sha256": "75125ad247f9c6b06891d12cf61ba2b14a275b21e55f65cb30a59ca4c3979df4",
                        "size": 979
                    },
                    {
                        "created": 1698843042.0096626,
                        "modified": 1698843042.0096626,
                        "name": "/dev/shm/incoming/2023_11_01_125020_00_00_K_127.jpg",
                        "sha256": "3c453e23b173a77ade29f0f6d6e1bade3922363b819266a4aaf8004635604fe5",
                        "size": 1058552
                    },
                    {
                        "created": 1698842988.6096625,
                        "modified": 1698842988.6096625,
                        "name": "/dev/shm/incoming/2023_11_01_124930_00_00_K_126.txt",
                        "sha256": "27aa2567db71035a45f9945c5747eabc0fbfe3c2f5d88288b28cb9187c4876ec",
                        "size": 2048
                    },
                    {
                        "created": 1698843000.9096625,
                        "modified": 1698843000.9096625,
                        "name": "/dev/shm/incoming/2023_11_01_124930_00_00_K_126.kml",
                        "sha256": "c1a439f331e22aae593d250242b7138ea5bc1f315acecb9fca393dad5cd9e249",
                        "size": 973
                    },
                    {
                        "created": 1698842999.7996626,
                        "modified": 1698842999.7996626,
                        "name": "/dev/shm/incoming/2023_11_01_124930_00_00_K_126.jpg",
                        "sha256": "631735a82c1475dff13347a2f10a6fea87ad1fdb3b31f2ee50db6bcc23a8e7b2",
                        "size": 995383
                    },
                    {
                        "created": 1698842973.7596626,
                        "modified": 1698842973.7596626,
                        "name": "/dev/shm/incoming/2023_11_01_124906_00_00_K_125.txt",
                        "sha256": "27a62962b7105fc89297a5bc012775905d84b3c53993ed865428962a684531d7",
                        "size": 2044
                    },
                    {
                        "created": 1698842972.5896626,
                        "modified": 1698842972.5896626,
                        "name": "/dev/shm/incoming/2023_11_01_124906_00_00_K_125.kml",
                        "sha256": "9cb87b3c411b88f52eaa8f495943087341051afac8e07db5dd3136f151736f1f",
                        "size": 974
                    },
                    {
                        "created": 1698842967.0996625,
                        "modified": 1698842967.0996625,
                        "name": "/dev/shm/incoming/2023_11_01_124906_00_00_K_125.jpg",
                        "sha256": "38c396690a25aec53b2751f7eb731209a51bd94da5ee8a030acbaff528eb0c28",
                        "size": 981598
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "0d7e091ce08f75a041a8ae3bb21725b8e8c1d53a9881e743f694253cb817e590",
                "size": 32447914
            },
            "errors": "",
            "filename": "2023-11-01T12-58-07Z",
            "size": 32447914,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698843879,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "gu2QrU6N5wv9+EYcjJ4kzd2nrOGVPXLXQLBIsnHoESOikwBfR87a7A8leXN8DQeAq+00Zxel1Vwlp8yGBV2v7CcdzSf15WfMkWH+6ARmn5a9VTbN+3AE+6zQL9D+Z5+k79JmdWXs48BUOq0P0+O1rEAT9xhettDfnZ0o8NiXoAVllfPIwRfkdmGlpGdMaKon9uTlfOkWXRkD51UwlvCnulOy0Zm59VNMga5K2oinzTcFkgqVYTuK3JQX5N9ulG1MtjdKVSx4hXJm6sNJLnSWfRsxIvbQeLv1CvIg4HAYg4bLYYstY1DVQ5Haofwn9jOUc6a387mR/da3LP1/CRczBBuX3G0N0v5ren1CW7dxVDZYjbIN1vdg84rME2LNjozwRe0qTmHxEBDPOFewRhqtNI1HPxSbOWlFjy52EAXBnK5ZBfsLq9lwztkTONVJRVOdzUJZEpHkz901NgWJiAyIox8SpRrwsdM0t4+72pu3ZAr+qKmMm7GrYY8McBc1HwRqpL7WbWk6yaZTIyE050uj8HmBD0mta9EM81ezMpPMcmxeXA5EJtz41JpUthDrsQAeMBuNV7+BW4ShO65fiCDUwmBdPDbrjCT8ewKH6WSQMwWzqfA4rRfbV8VAVdD8Am77r4ticIOrhOmaK11r37q+iKzYc6NrZxvsfuCRdJIY6Xo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T13-03-45Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698843697.2996626,
                        "modified": 1698843697.2996626,
                        "name": "/dev/shm/incoming/2023-11-01T12:51:36.ais",
                        "sha256": "a059807bcb27476f9308905184210b244c246a0d06a7c228a0cfd84e2dfb3ae9",
                        "size": 806744
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "32de4c0a084197405644e1cef161e1b300a0d74736164a781baf12cccf7bd257",
                "size": 245714
            },
            "errors": "",
            "filename": "2023-11-01T13-03-45Z",
            "size": 245714,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698844441,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "Pi2RlBs7AaNof3krbW2QgVfnJSr62zRx/pEqKw3RIyqNKRUhto84NgvO/BeQhiLAb484qCtlWhtUGe4uwFEWJOvLWQmj2ZLmI+mk3CeMI1fCwO5LMvq8t5P559WqGJ6C+VRi2diCgE95ZRgyo+nX2YDIBA8By8YenOvwRUJV1nEEU0Suw990gWHB+pLS+RgNmVGWbyVoK1Imig03E25FFPefZiosb3J21Ct7Ckrf2ZUhEF+f0npRhfYGQkXbEMTnay72j9ADjUNZ5cV0cuV9EZBjq5tl1ntOR0jexcmMW7xMyHjiFyIhvx+6BVUWjhJ9W2coMoFg5ISV0FNhnl+16ky70t6IB/4yGow+Xe/30YEKEm+wOy/stf+yf249YGaU6tBYo4XMc7Ci2vLv4WaR7sNgpF2bB8rekM9xkGsZANtko9IEG5mEO7sdQ/YT32LT1Byj9QnZ7lGSLbIWj+RCiMLpX38RW1n923ZtmGvVl5IT4qqJDt6k1kh2Dj3IMZQWqHxcL5HXxmhqw3uS9U4x+h3bRHW+mjRSIER4WPTc7ZCtU6gJ/VSn4j+JhJW2lFEZOlTLqCwU69+/nCVk4CYDnYyIlC1r3lg2nmwkK7dCnPL+ueWBkbr0I79MwFvuW1rGaBPeRuqVUw+nQXAwrZBsk68j8V2+aBJP/SF29owOTuI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T13-13-43Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698844298.0096626,
                        "modified": 1698844298.0096626,
                        "name": "/dev/shm/incoming/2023-11-01T13:01:37.ais",
                        "sha256": "cffa45fbefd755b889917c806e78cfab3009b8a88d0fd3da8f5993b1675840bb",
                        "size": 540933
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "274dfee8d4367e423df1a944111c598ce90770ed9797adf6ac9a1f5dd3ec29fe",
                "size": 159758
            },
            "errors": "",
            "filename": "2023-11-01T13-13-43Z",
            "size": 159758,
            "verified_at": null
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698844560,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "ip/buUp1L9ttPqBwmSV+p4/GhtseDUturZHs2fzRkBnszD4nUkHWgMU2UhTJDFpRPtvBUohvZIWFffwxPnCbVbxDVxs+77BhQ2Tc0ZKDSCGzxTbr4JP4VOhvfzIeLbKzVEDOsytwSo5eJvFomx+R0qIE87ImEQszK1d+gnnzqKEhFOpZiQnu2FTgikfovQeBiMOCaGPDCcelSYuDc7IrvzP3dFbffGKN9QaC3FmuLyN5rgMvCdTaMrwYesNPTvHAc89Q4AX3vjGDvyA/EJrzn773fmCnIqJA0olOhV/SjH0EB6xeOFe1D/Ix7OVyGdgtt3R0cCeYLnnbbuE4wDw52Xb3PzQC058TL0a8wlB4wKBmHMVrMdWPDM2vk6b8hnkXvypoqpXInNry5QKGkklouKzsfbagJUbnoobBhqlDSaVMF+wfeBbSbJGqavA+MeUm6H+XCTfR67+9xXqYsUOEkGTpeE4vf2t7ucdGAY+wgHLSaRqdHyjlOxgeOU8yt7Xc7kE4+2JOrR8PuKnRDq5zWcVos/XYRwmLZGs7FC7DT3tPyHYJPWTpgAn39P6sq26xEMehDjvddBqJes2FxiUjUGhHVRvUexXsyZ6hLFCgrHBUrEj9eKhKKVE4QCiEHW+/qy1ZHM0xOXS3vbPsvVQK/hmb/cW9c2z7yqtYjt/FKfE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T13-14-17Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698844447.4596627,
                        "modified": 1698844447.4596627,
                        "name": "/dev/shm/incoming/2023-11-01T12:44:07.ts",
                        "sha256": "275259b1e7e5462822011f61aebde719ec8545ff2019bc9146f716e9d2826ef5",
                        "size": 426244128
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "f7e9b6b843585e428c2caae43d3917e8b2d68f6ca289ac18ff6973fb0bae586e",
                "size": 381866497
            },
            "errors": "",
            "filename": "2023-11-01T13-14-17Z",
            "size": 381866497,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698820457,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "QzI7vg93/eAmWbms8+NVgZZGCWWsCfvXfRfSgR6gyunj6MpWBYU8HAEatQhQ8VO3DyrA3uMVF3qPwaOjRKjoUpqKhvpBUqZYrrt/o7Dpjn2DyQhKx1G0M33IcrW6e1zENYiR90udrhPGh7qy2DoKXHtA7m0Ygp/MZ9CjmsLd2ooEyiFgHJ8bTlbIUXBNJQe1mqI+5ALzlZbtH9ID4HR7m7aFGIXiYldSzr55LOBRMB9DZqM3d8AwYbuFRZZ88fOb86dGShF9E30WiJAKDMVvwsykLe62paKAr5RH8+VmjK9GMmutGIkc8j2V5vquoorBAx3tOwMUqtq97vEjHtaZrK7e1pz5jJjwVUfBCpDXrouJKKW85lCh267LuE8d16NLzIldUqM6lnRoiycyO1bFAsn2B0zvNh12DNXefsnLYV8lSWf/1YtRLCfPBQrCqJ6FoARpQU498Pdbg9YR38/aWMCcNxm+QuwQoCoUFn90TfK9FYr6Eu8SKt4E8TM8QpF24EeDQWU+iqMqKfAi5RvjSCnRRMib/desSek5UORB81bHEkCYaGykBj5w1h1IYl8RBS8zEQu9UcBEDsTH6WlYHmBdeoIeL78RrF/d3TvygGSFZ/AYfR46mTd3bIJPFKK9577FyQLK9G8Wa6PNEe9Mn5Be73LoDjgxOykBbAHaC6o=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T06-33-59Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698820310.4782224,
                        "modified": 1698820310.4782224,
                        "name": "/dev/shm/incoming/2023-11-01T06:21:46.ais",
                        "sha256": "68662c402c15a031578578e0aaea7957192bedc9904be6c0d8166b10fe836b58",
                        "size": 46055
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "f35984900d572dfd4ce1a788cf76272c0cabb45c660f3654976c39d0c7adafe5",
                "size": 10763
            },
            "errors": "",
            "filename": "2023-11-01T06-33-59Z",
            "size": 10763,
            "verified_at": 1698844567
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698820090,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "e5LDZZi6dLgCNNqni90cXMuY8AGGYMusrPKnkmbNbnmUEF77+In5lIpJGz31CboM34vC8q+ZglB//cZD05vDN1Ym27Pazp0FJkTYDIZ3ODLDvAcOKwA2etuK0A8hWeWOTfooNn8ZWjT2jJDjdRQ679MJ3YRnJO/W3WPHwDWqcdGiRwu3QFsYWpFCnriHwffKvnh55Fb1t3AKUVag02wT7Mhui19DgWcIOBENzFEuJzeyRo4Gymq5VRPDyTyOXc5AdXRFx/flCfxJl3OO0E+LVGDBcTiJ1CXiBbvsiK4v1twXJYovM+R+DRFPf1GVyvLHYrVroYRjRyHs+fL62pysmnq5eNlaA0qp07gZCVm36gEAJFXR80DGjY4daJDT1eJqwBcjlQgu9ff+Ky8IvRXh1U4zE/DycFw5GCRRImOpfugYcOYhJgc87c/87qrXIn793WRZVwPMSY5KVXu6SUpyD8S9V2mxXXWg1gnAg4e9y7Yntbn6+3vZNeLige/KI/TNt/X5oUYO8vnJf8MYYoOv6ekQXXmbqvoRboXu0b9SpQ3nDt5nwdGzTuUusps55cF9QFOHLak1hwnX4huV/zq8gx5/KtvOv7mIK8DuqUrz7RonztqT8VtRRcsDp5qu7xS0YG5GUSLVTVG+m4IswiFCkIaChLkN8CUzfGbuSPKPOsg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T06-28-02Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698819958.4382224,
                        "modified": 1698819958.4382224,
                        "name": "/dev/shm/incoming/2023_11_01_062538_00_00_V_012.txt",
                        "sha256": "5de8fd548ab86c03a3c0c347ec8f0a4d5d24546df1f39d7c80200908990901d2",
                        "size": 2033
                    },
                    {
                        "created": 1698819962.1982224,
                        "modified": 1698819962.1982224,
                        "name": "/dev/shm/incoming/2023_11_01_062538_00_00_V_012.kml",
                        "sha256": "4669f0a306b73c993c6042c411a05934b0bf42e8a92636e3ba0925021ff88244",
                        "size": 978
                    },
                    {
                        "created": 1698819957.7182224,
                        "modified": 1698819957.7182224,
                        "name": "/dev/shm/incoming/2023_11_01_062538_00_00_V_012.jpg",
                        "sha256": "2d1152ccaeb7a088966c8c95d2df001362d478decd7c491635c1c0aba689fb56",
                        "size": 1053503
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "c1c2e4ecc47e736eae4d1025457196fc1277c6120c0b36ef227ff42edcbf940e",
                "size": 1055297
            },
            "errors": "",
            "filename": "2023-11-01T06-28-02Z",
            "size": 1055297,
            "verified_at": 1698844595
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698816834,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "mliSV8FzacHMIRQpRfdbX4UTPdpORAZ+JT6wKobPNEVOlhMlhZGGnL9iIUFAKc5KAN5K4FeywO4sEHM9j0jMICpyLv2V8S7R1O/mRAKGKhfBkuvORCu86kzJX6uTgjflB8qwBdgcp7sJS0rvubGPzEPMwruF+q0/xNK85KRxFHxq7zGMr4EHyg8oS6adPrv6ETqPyhq/mB0fmE34lLaENDR+fV3ICyZX6A6vk3DC2qPYalYOq2Gbufo9k3aoMoITeMaoOoZc9G0Ffp7zrH677rZWc5uzNoB9KLpR/f82nEca8Gc5sZEJ67HEfA1YPf3WmIFiVhueFJac7G4acMLwCJ0f395e6ss0HvxD32dsk8velNz8i7wIu8Q5lLodNpXuUGeQFgGnJaP6vM/wh3zpHl7VzB3qQJaxHkmqN4qjmNrj3/TVZqPttH3ZGbo7Z99hAPEjCASwEvOMN69iHX4hxfxNt5uCJZQYfay86W0PPMfamvw/0ZMopkY80Mg7NMucw/cARCNrZuiMW2QwDM32fYoor7hD5NqZxwrmCTpzBk91ts1pgsZr8WmeyZ2Vpnu3ztLr2ftgnqqQYgM1RCvHN+eSA207DoUsZ9izMd5T9KiRJCbvw+BH2GvrycnwPLp+lefcWfLLT3ba8HZLQced5oMNRa7oXHjbwW8dWKJPeZo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T05-33-46Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698816699.4882224,
                        "modified": 1698816699.4882224,
                        "name": "/dev/shm/incoming/2023-11-01T05:21:38.ais",
                        "sha256": "f6a6f3648775d91a8f918307191908f6bbca96a826f5ade87b01547b5f4f8093",
                        "size": 93315
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "66ac4d2221008890ec72d4236480da9d2df2b0164110a81e4822d7c496293d79",
                "size": 25543
            },
            "errors": "",
            "filename": "2023-11-01T05-33-46Z",
            "size": 25543,
            "verified_at": 1698844633
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698815624,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "QY1bpcNlHus/l6iXTlGLmLGQH0KnZArPPqoZnEF0Vactfz/Gg5LQOqDhi04sl4z/zJq8lzwve8kza5fksSLaCi2gdAdillpmCTmhddNlWPsrNWVi329NAu7p+/h7Q96g7YZly1a6YHVCoKAVIF/7sgFEX6eUWt0PiLun/CimI/zQxAcTerwz7vlQRG0hrBMbl69XcK6oHM4AhJrtm25dc9eu87t1n/q7g0Bgvt+/h1d8uyeckZHFNlpiP1qUqyVGC00lxlVVf8xPcLMCIReU9LsWVOlHzckHb9MC8+fH7g/X+KH+ETL1e5S5kY+L859z2CD4ht9p7ub/dXsMay/liWM0C2duCO5Sid3uqds/DnNYzMHhupWz34OwH68NnX3pW9jZYLGWsXxgwj68U5lQwjw/Os3mshpt8mEQ9uLL9Ar9Q6/MB6oGD3bdwZ1Ihcau8hr/NQGCNdzcoefC5gbcLOpuBda97aHYz4xCB6Qc2cXmDbHW4V4D8UJB3uHU1oykikN2TU84KidlsIvr1VW2KDrxknJht3bw/hiFBVPV0mMqzyTJFpCvWLoUN8dDyaupQSrsurcVYc2n+Zyo4MloI6/I+8pByHxtwI1JhOf7ZNojxhk1btrhM84RGKKtbV0D4DahWcPeNhrRQb2XKneKmV2rEq4JnCe3bgtPb6x+WJ4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T05-13-40Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698815491.8682222,
                        "modified": 1698815491.8682222,
                        "name": "/dev/shm/incoming/2023-11-01T05:01:19.ais",
                        "sha256": "ee9a09e7fc3cb6bb98cf2444e4d020443188778e5581db565ef99a39be9f151a",
                        "size": 6795
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "7b010c9a227a5427ad0770ef99c7d512246e9e61a18f72c0ef9018cfc2bd9a4e",
                "size": 2090
            },
            "errors": "",
            "filename": "2023-11-01T05-13-40Z",
            "size": 2090,
            "verified_at": 1698844640
        },
        {
            "aircraft": "G-RTNA",
            "client": "EUNAV",
            "contract": "SEAGULL",
            "created_at": 1698845065,
            "data": {
                "client": "EUNAV",
                "ekey": {
                    "key": "FYxLVUFsi2/J6TKnNga67xTvUYj4T3RuuaWQ/QBpPEuegmO+vCKSV1VXDrHzSz8gHpje1j+SLM9IRoUV1q1phDqtXJEL4tqIEyEE39K1FQ/pzw9IlWnK3yjvpvzf6OGo3zd30eYsf4DBv9d5GPpRA9jrVQynEDm5oFDMPrMGgxF2v8oLOJYAreu3r7bvcT39CwgstUyqPxISn0umjBmt8W05MIOnKyZYJCWrM5sIzqt69xWMfwBJjZPLsFcx+Z0uexiNCEFf8q+L2Y7S1n4pW32WgiRfhMnqQxJklwYjn8aDzAnC68D/m5dOxshrJZeQdg131SE/Ao3ETIuKpz/tYHWihhDgeA97hjad8kNVw1lYJUvx9sFh02wSt2IPp13FCGOmW6583VO8l/KfX85UqcyvLzFanbXx+fcjItgVI/+Oq/waavywuFXXbY+u2lk9FBkmh9q6MkJ1qDtUuPD062OZPMzNpZsOmp93M/pV9v2a6S5tY3IpWjn3Gc/O4ZeKaUWB/PmTGjBZNAIjaSkU4trG3EcfanFF3ccGPcr2ShNq1RpYVZLndCy/QB6c00DGbpuxd5tG9Z0/0GLgXLuxvmruSFhxk71CliF7dLLKtv1U91N7+h2d/Bgnn/u2zGfZfidzn8+S7NwAVTzaRRdFLCQGPTLGW+ZJB07CSpl+XmM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T13-23-49Z_EUNAV_SEAGULL",
                "inventory": [
                    {
                        "created": 1698844901.7996626,
                        "modified": 1698844901.7996626,
                        "name": "/dev/shm/incoming/2023-11-01T13:11:38.ais",
                        "sha256": "ecdf05006dc6d03d4b208ad72465d47aaf1893e3ff3c5117a7c17f07a7ff1d38",
                        "size": 233258
                    }
                ],
                "mission": "SEAGULL",
                "sha256": "68e47216208ca911da4d1b564141268c1c926daee5468828c0250926edd1fabf",
                "size": 64720
            },
            "errors": "",
            "filename": "2023-11-01T13-23-49Z",
            "size": 64720,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698822244,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "QDQfKRlZsa4F4Shix0vh+zchToZrKnfstSI46eDOBZNdtzk20aP/ey1b6sCYZO8HrDzQjQXYslirP8o18tSr94REtlS2Ua7mIFimmEtvoDdoTqEfckyx3LP4gEOfucagD9tUxlPziPM+fL1L3uSAzvYAFicgstNORPiwGVayrT9oDvmKA20aTASWvbmaePHle+dzoKvUj9v7tgB1AZcz3BQ+GhZHUAvsbJGaPInZIEPv6owIwtIti9Di5YwNJ6iS3cmSyot0og5704qdOoxuOrb3AjooIDbLCGY3KcV3iMyJWv8c2kD3Vcff1xiXIXXaB2ST455BR+eVuwqge6mlcwKOVqNKkyxmsZ7wD6Ey9GmjsL4gjhF+6hCNYCmVtLOgbcMCi8jbOcowFyEFBShXxFHKY+8qzN+1og7UpWIJrZso/eOi7LhiHIi1tMpEEejf0P9bfewpuSC3TSRHRhAkxUEL+wfEMtUL6brqB0kQeI9NJSQRCk5gqqJxjMh1vo67Aov9OyH4fLBBhcvluPR5XotfjiMvqI767NSkG3ZVsIOXi0huYoz9bCN5no0sdDECnwlZo79h0iLCBLWFbMmkTsDntHspRd0QuPnJTLijxh5SV/lF8d8dO4B3njnH2c//zVxNXrgc97hcUE3nZDU7grleFWYJWSUHiXgIr32zQIE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-03-57Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698822113.5482223,
                        "modified": 1698822113.5482223,
                        "name": "/dev/shm/incoming/2023-11-01T06:51:52.ais",
                        "sha256": "11341a4c2cb27179e07d062d9dfc8607b5bcca485ae349400ce9e888ffb5d211",
                        "size": 39799
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "0af98788119236e2c01dec80dd53e3b0eac806a70571f141dad0b8e0d52ef71e",
                "size": 11707
            },
            "errors": "",
            "filename": "2023-11-01T07-03-57Z",
            "size": 11707,
            "verified_at": 1698845180
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698823003,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "CZC3Luoq/fuj7joc8s8he9JUCY4Gn9zoia6/F83l7+3KoKl26Xvg0D3Skp485OXiqmkUK+csXiFJVLhPlhItKMXuLstNXOBZ3br+rxG4djefZau8dx7cPnXLGN8qIM0zeMpsS8HEeASnO5pMUz4BwcJffbhWjoEh2Mbi3PUgGrE0Kju25/YLQXDb2PBb0HCW67LODMfaOVG1bi40cfyOGb3M+MJq+oMsMpu2+UikXx+kiaq/1dimEXbS5y6ea3QLWHvmAKeH1/7By6KuDNaQveAfyMewxTH7cs8QnTFhtdidYcRU4GTVD4PVNCmk779gKSqOcX93FJMJQT4lvGiJPmr6ed473H/lCkZNw3X4pMevjCQB65DK1iKAmCpXZv4r0hhxLRM63YhtW+Y/eS6WA8TYHJQTynR02QFHwKiqCc2tY8cnAVHTdv7MO8VhJl60ZH4ZFl12KZLuVL5daVqZy/pnZp9Rra8jKdMFAthteVv/nKjsL/7BzrYphLRDzFCMP7v5lWHylgH68Oo/p6tUMebIA7e57GRvAKAPvlDQrUtUhY5f8jCXs5WSyCxnRMB0OP6j4gM3ffGQkLipdbPdn1vIZeAYUN1090ZXX+or4yBVb4nF28bzPWUacKGjsMdxfwoL81CrrEDcPoxLxae912Ph63KlhgLBK8GD11iKnos=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-16-35Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698822899.8282223,
                        "modified": 1698822899.8282223,
                        "name": "/dev/shm/incoming/2023_11_01_071411_00_00_V_014.txt",
                        "sha256": "557af8f4b28b27aeb21e3a03b4890733bffb6a9426916195c24efffe39fe73e8",
                        "size": 2032
                    },
                    {
                        "created": 1698822872.9682224,
                        "modified": 1698822872.9682224,
                        "name": "/dev/shm/incoming/2023_11_01_071411_00_00_V_014.kml",
                        "sha256": "892aaff74150775d9d4595ad99aca1e11e256e4fccadefc9895f141afac2262e",
                        "size": 978
                    },
                    {
                        "created": 1698822890.3882222,
                        "modified": 1698822890.3882222,
                        "name": "/dev/shm/incoming/2023_11_01_071411_00_00_V_014.jpg",
                        "sha256": "bdfdc771cb3a373d2786f97ad4ecadf9a3e3131b5e6c174ab180690f9b1a1a88",
                        "size": 834490
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "166cae3130438c788bf3d61de623ae84d018d0a0d1b693841ba44c23dbd69c7d",
                "size": 835901
            },
            "errors": "",
            "filename": "2023-11-01T07-16-35Z",
            "size": 835901,
            "verified_at": 1698845446
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698824651,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "P4QgGoU1l0uuuIU4R1EoiUCVk+ZJtu5y/gxqY0ULG745oxoGZuaqENMk508bCc5H057DgtDXyNCFAHfDwe4o8aoeZLN1gp36h6R/PomOClHABW+5JhIYdhBuotDZPXa+7V1Wm9i2jbKQb0lcxp8aUZtQaDwc0mBWwojBt65jzVG3nJ0zE23fiGu2M9I+ufOHq/Ra0fpH+xfeTU3Y3v72/u7qNFFH5vzxqFHggLanUS4HEzOwaI9F8aXdRw5rfeiewInH50+iMn0zL5DmBdgFiO8eVUQEZ0zO0/vpmsvXyrsgvpBtsvzdejP4L5tgKaAzyTqyLLaC5tF1qr9zCZNOP2o+3ySsGwYLrwZ8HnpZ1eYnF6pbAD2HJ26AaT1Jpkm1w75wqUL1rESezAxwsZVzSGRsVqgm88eYT3k3CoUbGgiavApcRTkBoe6FK02/NhB3TeVlSd5GXubWiWN8GAuVdbC7NKKEXZUR4JbUVHCa5VJDrwWQyVOCdVN7kj6BytV55p+x3ImmTGCsWLXpfg2ZfS2GZHZbNeQLTVFNWZsuwvlC+Q50j/8g1nbKNWNuOfWQM4i2OhQj/1KhRXX26ZBYDts/T/wZkyNghro4/G5jeYbgIQ/XeRxTd/OvfikFSrrjj7z9eio+f6QamH2uHakDn2BdtmOoy6wylLrWH80+tjs=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-44-04Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698824519.1982224,
                        "modified": 1698824519.1982224,
                        "name": "/dev/shm/incoming/2023-11-01T07:31:56.ais",
                        "sha256": "f9e094725f3f1eb6f5ae4edde6f1357e3b5967c4eb04ecf50d57b892361a5bb3",
                        "size": 31553
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "c96aea238a769cac43d1836dd7f8051763c9a3a474e0c457ea351ced484b5161",
                "size": 9821
            },
            "errors": "",
            "filename": "2023-11-01T07-44-04Z",
            "size": 9821,
            "verified_at": 1698845500
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698825645,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "mhGoIHNfObiW++CCp5uJmynkyD/OsHlQga2g7d1PZJZIxIQdLbeLYwvRuVVakBLp8vxEPxG16725koTH+PUPuYg88jmy79gdAiVIhT97KeZJbNaopLehwWMyhfnjyKcxaqkmciGsXmv3XabQY2hryj0ZY5ffvhGEcm/kzG4uWEsDE7UyFtudWkLwZ8FQSNSEZbDr6cRPkM+sGRpSz0qeN0XklOx2iE2oJ4mJ+r5Mh0DpoJIWFf8eNgqn0pt9SwpqgDTZRr3MdxKU7YATuXuOFp4PJhIGBOu249wtArc9LwI+L7ugJNTCtytlQshcUZ3p/CFLMrs7q3DXro2KvaV2pr72gAFcIAuSb+rgWLWeE3kTzswDzX/LE9S18lRwPjMdre9EHbBD+XLx5abJPd9JKCIrKJ8f7bvZFNKqhy6L8+w6B+JQcxXzBNOHgu46UNYHON/R9/99arWGSH/WqxUo4Em4CfOd9GnFGiGxpqXSQghHZdSegN0b19NcqQytDoYP/fPXk9ivFL9UC6RNyyrUOKBKol1NAYo9Cy9iyfuS1A3fSUj90g9Itc6MVec+nHgZvZexOKsDNrMBV1OdpZpL81uIXd+0SgWUJy9UihB0WeFfEXIMclCXCEia7O86LZqEku/wRPTqTlWjzOJ/q1AJ2smwmRD7JKuxocSiWainjDc=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-00-36Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698825527.0382223,
                        "modified": 1698825527.0382223,
                        "name": "/dev/shm/incoming/2023_11_01_075810_00_00_V_017.txt",
                        "sha256": "496bc362feaef80355ce9c71d4374ba64b76942cfaa15e5cbb8c4b94d90865f0",
                        "size": 2037
                    },
                    {
                        "created": 1698825519.2282224,
                        "modified": 1698825519.2282224,
                        "name": "/dev/shm/incoming/2023_11_01_075810_00_00_V_017.kml",
                        "sha256": "e1cf2378db732f64ef781aba8c98ebd5b494b8e2be17f4c23a418ec1e9e11711",
                        "size": 979
                    },
                    {
                        "created": 1698825514.8582222,
                        "modified": 1698825514.8582222,
                        "name": "/dev/shm/incoming/2023_11_01_075810_00_00_V_017.jpg",
                        "sha256": "715d226c2d0ee1ec5e8fc4aa0914c8358e008337a78bb895f8309647fdf7a8c9",
                        "size": 911382
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "cd48b2896b04b76a23d902621397d70f7ac3d80cbf380316a479cf58d116e189",
                "size": 912998
            },
            "errors": "",
            "filename": "2023-11-01T08-00-36Z",
            "size": 912998,
            "verified_at": 1698845539
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698827061,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "rs/DjAsy8tVAKafWz4n8CfaruCMQwQ5kL4LN3UZWtZgiQBUmR+mnrALJeo+zrD0bYPQ+75/YOaeBF8W5IasVeY5JwYP2LrW+S141/pSxJ9U5TRLQlFlkA9FtvjDXSwZQyPKAUswvFiAjwWtP0FsnR4GFoBot+arxXuXQA9PjLJZ9REQMrE11yQsvWU0qBAo63fXOoR9uoS+NGGebR+TZvJxMEi/CN7Tniu/GgdEg58oTkYGe2SlHWybBYmWgrsH8GBdu2woKJZRv110SK4zlZybL+y1oeNwuj0UFfbwjLgYz75bh5BBy00B6kxR1cym9WRAqZbWm92is1mBOxDGDPqkWF3q+xkExjSVKhcN5GIMo7QQxJZBabPIGzO7MviCZpMcKKATkfJovV/8HPf0U2K0hMxL9yc6xBVkAHBWM9wLvfEiWLMk3MyP6mJ38RhWIGR2ybJq99rt8poqjsb6/d7P3xv0fm4MY1LUG/QkiApkBCppC081d6swyr8euTiBvFEnCq6+5vr377IAbMLGLFGszSS5drQbVVMIEaRgmfccZQ8Vlixtk938sCGp7iUd9U6A4+b8XLIcZLkt1VPFhdVxzIAvq6GcnE8QJ2YiZyR+Yy+YI1KPHCwSUiLRHzJxTy/2FDWaiu7edL8diul/epA7un9NPhompEzFUuxD8EPk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-24-12Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698826924.4882224,
                        "modified": 1698826924.4882224,
                        "name": "/dev/shm/incoming/2023-11-01T08:12:03.ais",
                        "sha256": "bb6937e3709b00c33850015c21f4ddff26bcbcd299baeb80d28b0c9b7b72416e",
                        "size": 39010
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "e00294461940e641d0ca8aae99554ffb4f4890e4c9df8e0201ec4e6ebaf1b27f",
                "size": 11174
            },
            "errors": "",
            "filename": "2023-11-01T08-24-12Z",
            "size": 11174,
            "verified_at": 1698845618
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698827663,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "o+TeI/A41oouREfIRtdzWh2UmaLyL+8pYgrD9ExcZcSVvbmQxm5w2PgnRz3OactEeYzFxbsZEicsU65JfxpGfH6nMtNiqsYxhoeEMk4WhZtAN9fMz/DgM4Tqc6cP+DQGkXGiIoCajXFs3/DDvvIL+VdLecJ9/WYcxOr6T3Dc1L+/92Myznn1/Gp/nQQcfn2AZ0zlyxNLCwuz4v8WmBDaR2aWwLTxXYsBbnzkx6oy/r2n41aVddvYgsnJq9p0Y7YJkwhk08lFrNx9awkkUejw14Spu5ZXttJVUlrwvTWp/aD8H2wqVJelmyUS05OJsGWNIlfCPUjkvS4f3wGFoYD0elxAVNr4YJUR7Ms7WY9SuplXeGjAAiubSazFvLpLbMoxl5A1r5mHGAK7PsxcdiSytOeeN0qEjJ4+n+97zpzvWlIP7sQVfQknHrz9DKzDuLowXzZzUiXhzj5dxME6LnF0Gs7PWQegquRBmc66d0kkBcAnN6MioVDmMpusuh2UE5haUgHHC+UViDMWdAvlGyY7zys1H3or1pmAs1QQBA3+6NZiJd2TnKoGuQUc0HTsAcEveeM/Y+gkAkOUOES1sIzai0SAkldiB5T8dm3WpgdA+sRlmlVlJ0yrYZzqHTd6zawhktrWwYoNjs90ddxsIADs3H9cH0HvCwl4r75d95MDUcA=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-34-14Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698827528.1382222,
                        "modified": 1698827528.1382222,
                        "name": "/dev/shm/incoming/2023-11-01T08:22:04.ais",
                        "sha256": "11220dffa098f830ea401d750a4429a6ff772a6988c1be9113d588a368de6f4f",
                        "size": 32418
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "8e28ff6db1b841eecc0769c02ac27d6c7f1e23475f23ca232c318afe23ed2953",
                "size": 8957
            },
            "errors": "",
            "filename": "2023-11-01T08-34-14Z",
            "size": 8957,
            "verified_at": 1698845716
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698828265,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "THyiC2yIZ+v3dU+kO68P1gBotdFAIauPfFveh8W7mmIkYtFnA/085rP2iyHgXaIkA0S/6/OXJ4E/G8YssfJ3vR/wNY/VCpY6m4dZ7U0gZYIVYz2UweKIZGvDK2HOFR7s6H8U/GzL9gCaRCX/4nwARTkxQPOpIgNTW2V50NgqmYhCjFSuSSV1AaRiQi1E6wbQBTjxJTpQEhPO/cNnb2xGbpSCoXEQcPacNPeI2ZUycOAF00O/GotZMTqlDiPycC8jGp4SITQiSwWxbuYSCC2c6+3zc24cB2Fg3aIVLqUC++yFnsz5TM4SUGMPmDwUPalVwkjcc8ugrZ0rsLohs08lC83XF0YcKk3nK3Zuyeils1Yv9lUEhp3QTl+oeaiyXXF5Uox7cSnbtrQFrUvYMdYEQzmoXkAnlPk5paVKXBUPb8M41i4kayeRpJk+mYMZuP0x8OMTo4tKxNc8PYRgZa4UfFhBXjkXTdl+7g/ku82qensUjWjfV+31bboBA7hIJiiFInl/8IjzS1rHFsCWj6D236CK+naL8fhdSd2gjK7ieR+MbkVolPHnhfqv3zyMLyGEYKwUq8ce3wwNkkQ0253QZUqUf5PRdpZTMMxgv9SWZgc9XB89S4Z4mIX6CM3hIvVaLtttbDAYSD8gmKDlelKvS8uEnCVw43ddiTl/QHfokiQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-44-16Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698828129.9982224,
                        "modified": 1698828129.9982224,
                        "name": "/dev/shm/incoming/2023-11-01T08:32:08.ais",
                        "sha256": "159b8f563f063d41f8b83fdda419a02f4a59962fb24731f0232a0993d38902ed",
                        "size": 39541
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "a2463a5a896156539d330090984aeaa5c0a6fa62a4a6d4b2c24a56acc1929df5",
                "size": 10625
            },
            "errors": "",
            "filename": "2023-11-01T08-44-16Z",
            "size": 10625,
            "verified_at": 1698845932
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698828652,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "wYieMvmebg/IqAbJC4SIUgzx4OOEuNpMXSfp/S/s6hlCvg8anxNB7lRlGHR1IeMKmejbeznzpD5GErSXN9AlPu8+a8/6xayFYJBj5FptHkAsSOLbpVeLiSXTNKJUVLSiqc9lPml2bG3Xm8LgG5eKoLRp+8AcZ059XlyIhVXmiXXkirFH9w3wFSqT92e81/gnZE6ju5GYnkf1kBOWVXrnLJoE4lYVZnATA0CU1UbcbapAsWSGP9e9ZlCLRMuUvVunS4TcSSX2+3hrVYmHRVuiHkbGHgew97OaBM0DVHC37z/w0pGJU3qN/Wt0Sjvull2NFbvk/b5VqlIohn5HJP+jbC2D8/JEnqPQcaw/zYI1i7bFr34G7uzXzboKarEN6j2P6VXhLsQ0rQnS3xctJ+9yEhKTMMPGlRX85CGELE6J6Ka9QIK7UCOgSYjNTLN9j9z8YvDIafYtJAhlWhgC+Iqju4TWRZuSScwq60P3fRDlkByFI11fTHxgjPZTrEyLfnOH3oO5z0T3ndTtnNwfa7Z+dCkTTf/QoCmZxmqZ4rWKRweuVBcF3uCaks85pt6W7gzWf03fyqZzfBhI3/xon4ZTcqnbodER5CQwSLFh3XfjWd8cZayS2tWieJHwsCHsSSWLn3d/4zeNW9ZxDNdL2SLXIK9NP2CgVTezhU6WGzqgkYg=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-50-43Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698828523.5382223,
                        "modified": 1698828523.5382223,
                        "name": "/dev/shm/incoming/2023_11_01_084826_00_00_V_021.txt",
                        "sha256": "ddf91be8e459af5fd119fecdb717d20cc35683e20ecef0a8a8c16f9dce57a29f",
                        "size": 2031
                    },
                    {
                        "created": 1698828525.1582222,
                        "modified": 1698828525.1582222,
                        "name": "/dev/shm/incoming/2023_11_01_084826_00_00_V_021.kml",
                        "sha256": "8e970e33b51bce97c707920c41c3037a6229c2a5e27035e0aa9d1aecdf0485ae",
                        "size": 977
                    },
                    {
                        "created": 1698828525.0782223,
                        "modified": 1698828525.0782223,
                        "name": "/dev/shm/incoming/2023_11_01_084826_00_00_V_021.jpg",
                        "sha256": "337686d6038839612cdc2382d7e41a0108c45580d32552a1f26f209721075b52",
                        "size": 561554
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "8fb2c3f12edd8e4649b40acae82fbc30ddf65c36ccb426890c03e11a4045467f",
                "size": 546788
            },
            "errors": "",
            "filename": "2023-11-01T08-50-43Z",
            "size": 546788,
            "verified_at": 1698845996
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698830068,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Zi7+eHyAPROcUm0/J185ZU8s6WtgGWTnpNJsIOjtpbPpIemaZgrE+MGF02pCPaLtVnEQGwmIrrQiZd2Zak7P8LdQjKVLb/v2F0aay5/0vIl3Lc4CDx6LT9XBV8L7t3n4TgWGlqPBLqP+F6ejBIsl2xh4hEFA76LdG+OT/UYDKBptT0Y16rFvyvdj363FUsmMgu0ewq8YADso4I3yhqQZV4x6pAAxI3npwgcVyO2/I23bdFMcrBCLQ7hlNn1wg4v1GmhSiz/3wHTsrGef7oB2tG83Ufdap7HSRHL0kRoufGz9zzmVs7qNi/trOOCy5kOJBe+qAbtUJLHSHz5aBZxU0bIRX+u2sDLfdWPUl9mKxl+SdvFehyJ43ohzL76XZ8nedL7xZchIL6x/MIOOJsXSTj2DEKLsOwzE9dd0RobcaLqbUJTpoQHgGEfAIUTWPGtp+XrxsQPNa9TgP1QY+NpFYc1JSu1lWZY/IZFQhwUre3PyaljCVqlsXtm6E7FNmS/qqIvwotsPH75x5F+UAL5VsLfaDDbJwsuHjwjD5H3g9wxL691LjFRjswJhVuAOeh3/dxlF8dPjOrk9UNS+Cb6o8Gq/ptG6QNqVPmosDA7xx3ievEKogc8UZNSzu6z5wOgShTdCv4GWgPMMo+9DlhsP/G5K5Vh14Dtvc5HEm4b+OKo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-14-22Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698829933.4582224,
                        "modified": 1698829933.4582224,
                        "name": "/dev/shm/incoming/2023-11-01T09:02:12.ais",
                        "sha256": "2865eb75b86df9a34e2ee74c0a84ecffc58e708206022618ea51e31c457c493c",
                        "size": 112897
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "00de5f552730674dba1e7427015e9f949541fcdd26d384538597e4e02312ab10",
                "size": 27706
            },
            "errors": "",
            "filename": "2023-11-01T09-14-22Z",
            "size": 27706,
            "verified_at": 1698846171
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698831271,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "qUH7Vk+3plV7Nk+mQiC548Gn9/JjdrMmOXdEv/uQ8KapTviR3ZNW5DlGbPkWIezBDHvHTs7zFNsdRvCQIAFXBFv8sFV6ANvOG5aVRdclXTP0iVg0Pnl+tM42chfVBsjAZvBLq0uK+Ic4kixOlUyLIAXe2ho7mn2oF2IfVD3b1HuGub/JEDX2rEJehD02TdLbBsRaCneQ6R2KURQaPL4pUPv+ZyrlKTnchPfn13502OkhoCl4cf0RpRC26M/iOho3RlDg46+qWSIAKhl3FjTxAXSDv828oJMpVxP2pxGhG14/YOYrCIpn8o/mU0PDG//YskI9NWDLTqyaxGWiqciqxNuO5ZgsIK8ujjnM9DwY275GcWl2eEUxBYYSsIKB9JaBQZ+JS1G8coDvAIE2MZUeG9ljTQgVZy0V8RgireJmlcCaR1eBFIynwLmGBorTmsH92AayTDBRci+GjZ31Z3aR5iIkzShVQpAiFEEGwdPUhrfSDNpOC12r159VKuuETcP5iDFHVgQCP7HQqFulqv51ceL/lyyM1Ha4osa7SNUuJR77EvQKh1KmBRfbmobFXJC1CoILvdpoQ/ADXHt+OMz4RoAFyGBizqnL8Tt32rcEDqo5/63lSblwlCqpInpbcwhDbH831ONCEoJUW+tLMgqe6gQZQTj5yrQurOXAvc6aVMk=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-34-25Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698831135.2282224,
                        "modified": 1698831135.2282224,
                        "name": "/dev/shm/incoming/2023-11-01T09:22:14.ais",
                        "sha256": "33fe82cfbf00f144cc176a0792b9939d99e4058a3b7302ac4a21863b8cf3eec1",
                        "size": 131410
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "e3f7b2200ebf19763910849a6af651ec87d9112f541102c9f9518658bddbbdb6",
                "size": 33513
            },
            "errors": "",
            "filename": "2023-11-01T09-34-25Z",
            "size": 33513,
            "verified_at": 1698846230
        },
        {
            "aircraft": "2-WKTJ",
            "client": "FRONTEX",
            "contract": "EAGLE2",
            "created_at": 1698832471,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "bakn9KGZzVNvlsaTw1YpP7hz7AILGP0ue3uVAsGOXyRkpH1rjMccXlFebPgzrtgUGIu4av+SYeBhOuGPoes9DbJy/UH8fV2Y99con8U5CWhOsThXOOozUeJSpC0l79RQNnc74A0BvUiQyVe5nFgmUk87r98YRE+jeKeOyyF8NUkGEK/CD5OD0feXebAyTuIJcBAft0F0rNRaRrrARzhSDtCDC/gUsl2rDJUxAOlIef1mRf+zWZfDTNY9BzQllMUAzj1AHeC19bGu1mTqBpATgVD6tRsR7b74lBf0kEBbsK6fk/hLVamq1NyX1WMHoQ4EWMrnsMCg71acssLMN5Vjd0tqX89xzLm0fOqd/bzlUNExsjojeuBpPQVKDdBy5iUlOGbhXn7DoJhgpLp20uDV8e+xObfzx+6oWdith0qByxE9OrKjOsFDfvKU36o/zhW9MRgx38sSDZZ4bEK3OXpYxjWhU47rJFkk2gyVsvElGJ2yAMiJz47lN0PzqIWl2J6UEWlhUe67V/ztbc1MMSaTWwMDVoqQQoe6d4Q8P9bM0PPfMpU+VRD/aXNA+s+3WiizbHbbnUhOZXTaOYbGmM4t3TGB5nTqeAQclyJEoJqmxSbYz+IZLQL9czf8+mrlYGLq/F2Zp0bsT33gAPCqaDbHqL9nbqOaLo8gGo189fgw/MY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-54-27Z_FRONTEX_EAGLE2",
                "inventory": [
                    {
                        "created": 1698832337.9682224,
                        "modified": 1698832337.9682224,
                        "name": "/dev/shm/incoming/2023-11-01T09:42:16.ais",
                        "sha256": "6769ccfed3174b3410ba58c8045a4a613a4bb9d6969bba98b5199cd0968865d6",
                        "size": 150058
                    }
                ],
                "mission": "EAGLE2",
                "sha256": "a217cc909f6a2febf5837a57eb2496d9ee616886b9c1aba9e0d638804e55deed",
                "size": 42108
            },
            "errors": "",
            "filename": "2023-11-01T09-54-27Z",
            "size": 42108,
            "verified_at": 1698846351
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698822365,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "qPAeqXodxb/6jiNPOzV28QGcy+z5iRs0SkzWvILfPxsiJHRj/0APr49PWqNXzjI+TvlV4QiR/TzStzXeL4ogqoYJnMuGa/zvp96zdXZsr9ODLVqUe1Kv0UQdp9EMz7xw4YwEgba2iGkVB0GqMl8hGerxXX2B7woMaizPIOJcKsPP6AOkZvCb9XpriVtHgUW+wGb6nZkC1dGadhczNXePy3zyv5vl08VRC3TEPS9VsrS+2qs+BBL8gIat8cB1r5318ZvcVDGLfpr2M+64sIid85s7WEmIlRwRzoJG+lQ3zM4for6hf4yneoHod6QCVMn/MSHxHFVzNI/ryZPSdp8zIFSBODc1ROdH/qDVu6KE2Z3ljuB36wVcQ/gMgGb5R029qifbtwpWL+Qwxmlj5krKItIyXalotQtXtdkQveEOy46M0mPOsy46x1bC3yiT11oTRTWzhJxojYV4Cl93oyKe0wi7nodcgS4vlnTfYtaZ696wV363uLs8suaIYxb86EFOTBiltcXy7DJgWefm9I9TxEHfFZIGrpzN1QJOdSERM9JdVRrWtLW48iMxPOEM+wSnW5PO6rA3ZUXzl08sql5+Xr3Qq/B2aL9scBiv2cNWXliCh+3d9jFkg4ITg5Z4PcNcuVAu3OFXyF0jgtQfJWJjq+92TWSoN0fEVIgav+OM5hw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-05-42Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698822215.6785455,
                        "modified": 1698822215.6785455,
                        "name": "/dev/shm/incoming/2023-11-01T06:53:34.ais",
                        "sha256": "eb28103c6c8ba4db7e751b66ff2d898c7f2a63d329c13720d5c3f39bef3fa1e7",
                        "size": 755676
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "9fa13f1b3d067727f32c08369bec0d95a4ac015b1f64bd04e6f12e75ad460417",
                "size": 229878
            },
            "errors": "",
            "filename": "2023-11-01T07-05-42Z",
            "size": 229878,
            "verified_at": 1698847393
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698822615,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "DCoshjiStIdsfeZPLvLiJ1YQ2V+kxxifixBH58q9mXM+Xai+VfDUU4VwwbeDyGbd94ijVViTfxB+on8ayRcEcc82JOW77ttzu1LJcC93Nxs4iM+RKxTjdFtJstKOP2lJgX7v6QDzmWJFXfCL7A6R002RnnspcnU+odjgRxqMMvB02vNyxujcksRQjpIN4iRY8uRQvs3z0tVKf2hc/j3J46ncxE0XVnbz6aAgpt/9CqqvB6oXBv7utmqFunnZ133DKUF13iVriFli7blguS3iIxckObKwCZIBL/M3Pc8V3rzYI9SlQKlrgTBUygs9X9EkeP/tuBf3oq3MswDWjuPhOWJrDjKl7RCHvrAtDrFoS2kVLn4tHv7ABv7mwzf1ZgV3hZ4+NEHvdtCqmhDWaFu8qn2niCeA5aINSENRIxi6bFryfZvFp76bIRX8TSoLfEaHN5Lzxuk35Z4sWwyV6nwrQAzYHsUwvv5u4mTQhylTBIOQ85sb0dMaT/rSZNp/lp6TGi3gqEaciTbIiYH+rOid7yQgDuDgWlLX7hA3tbJQOKSVqAtAO0dVxuCxGqnVWXiQmlvSUMsH1+ohMY0pMw46dgnn07KoV5QOlTaL2KAm8L2I0inClvI8TzpbwEB+KD5ZZRbuhArynHzT66auPCcSVUSIQ3oo+A1FuUrLVFr3LZM=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-09-51Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698822469.2785454,
                        "modified": 1698822469.2785454,
                        "name": "/dev/shm/incoming/2023_11_01_070729_00_00_V_002.txt",
                        "sha256": "e302bd933df858ae0ecbf3311dabc51278dd360fef10408b3081709d0907cfae",
                        "size": 2029
                    },
                    {
                        "created": 1698822475.4485455,
                        "modified": 1698822475.4485455,
                        "name": "/dev/shm/incoming/2023_11_01_070729_00_00_V_002.kml",
                        "sha256": "0ffd319dde7cfb12c58725d194fec9448f9d37f9a38cc0ec5e54483d8ccc8cea",
                        "size": 974
                    },
                    {
                        "created": 1698822469.0285454,
                        "modified": 1698822469.0285454,
                        "name": "/dev/shm/incoming/2023_11_01_070729_00_00_V_002.jpg",
                        "sha256": "1395623f44a1041c618418f5a6c4a1732fd2789899ee4a7019a9a4e059c3ebb6",
                        "size": 1197016
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "7b4faff2328c990d12b9d02b8e416302a4e1e0e1a9002dd3e81a30fdc75b1947",
                "size": 1198828
            },
            "errors": "",
            "filename": "2023-11-01T07-09-51Z",
            "size": 1198828,
            "verified_at": 1698847406
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698824167,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "rkvGRtD/a+jfmEGKL6ti61Jv2vsFrf9+zVgXS771hvB+TJ6FDwxyJEOm1wri9KHrdEZbCZVTyVWJPG9VEgRWtaRM4uEEBC98eQMDlv0QXBPbdn+6DTA+htyblQuWVBAW96tn1ZJkajaPKW0e4NHetqPQz8RZK2FW/7PNEjvZNNQMLbypm6y3JgUuiAK21aHBxckKXtD6JhIscELVlRaei8vBoeVPfMLcsapItHpw50hpoHGNGhxElOlcBuqlB1OJpQuvBlRjbJGfvXas7+eVOYOLgDWdD36S7eBoJX57TkF9t0/SVJOdYaJOY9ETvUboEsRtTbxnjH3NOJtKgKQBZTTjgpEzx2aoy1wb0XYvBTTvJjgysY3J3on8qTJqWV0P+zyCb1vtvcJckp5qpe4duGHC0UVpmiTJBN1LY/9RY4fJdO9TYKNWqX1j6mvaD0LFIcrXzP/P0K/Iwl5A1jy2T9oyChvT9PLKaVD4JPX2VUfaXrq/B8++5FKn4LU+Wo1pMoxwE/Wzr57TZ/Xy4rjCcLssrvg+yfo+EUvTjoPltoLzi1icXNrUne9mqCAgvdq0GnhmG0R03ZRP9FUg7qiIjjWFXa6zh2NQrAq9tiVklGq40O93mJcf9VMgNj/CXtnWsJVD4CskTD9UDCi8KLfxhIrgwhB3/eZKDsykjtDtzXY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-35-43Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698824018.6885455,
                        "modified": 1698824018.6885455,
                        "name": "/dev/shm/incoming/2023-11-01T07:23:37.ais",
                        "sha256": "5f7cccfd7aa7d4863f7471ade91ba17d9f3a17a8a52f6a0ad26b003abbd48f52",
                        "size": 570351
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "c353851e4554ae70adfae6826c2eb9ddb5f5c12abaff5ef294a7205bcb184b8c",
                "size": 173675
            },
            "errors": "",
            "filename": "2023-11-01T07-35-43Z",
            "size": 173675,
            "verified_at": 1698847462
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698825971,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Smqd5ftzJWe5mygV2mFMo574979dem4/A2APd0Y7VWPEBx4dZC61HRuKNI0ZlVnBVu3WnyF2vUY9DA7Pmi2w2ZxW8XDLTPVqpLq9yWoxmtBLKNXsZ3X+rIfwCxC93Hk+GscflOH154eLSlf+9aeZcK0qYXJHRvUzid6JUtw26GPI1+VSCNcH9RjcOBD9E+Hec/gLzTH+2Tshr1q0K33YdBs3+/D/diq7ve308tkHvcnpoZ7pjD5r8433XKcfiTxDXTDbwpzQ5qJ+qwaLcGCkXgNtZFJCFkt4/MVtJytD10/KpE0ocXbxF4TIciqceWeQZImd1pBB7iEW8SSwN8Odp1XwUImgpJtML1PZuNosQKxtZR4m5iE6LwrS9Z8UnOIk6oXwjmllXsqE1KpvnJiBhYiYwVo+1mezgkM4pHRx556FHKhRWkl/ZUUt7vFR4urkORW5qit0IkdhFoG0MS8DZQmL4HXrZ1gE7136Nzdvr2qYNhsIXvAymUItNWZDmj8wgjKtSj91QktqxZlTai6NBkxgUO4bXGLn4g6/HiFLfw9G47+CDOMZ+2IuWNXZXCwbYpqHfXh+795GcF9b9lUGXo5xLz+jQum4o+npKaA/drIcMQhrU06UAWvocrnnsXeGf8ubm75o2E2B82CLqE4SfgAW2tWCRxhY4rF1jbPdV5o=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-05-47Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698825821.0985453,
                        "modified": 1698825821.0985453,
                        "name": "/dev/shm/incoming/2023-11-01T07:53:40.ais",
                        "sha256": "60d150149f016484acc32f41bab7fd59440975c8aa72efa47725b241f1d41e7f",
                        "size": 597532
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "683e905ef5f8dd2f749783586b7603e727ee0e66e5c2e7e5e2065b72c4ce730f",
                "size": 185040
            },
            "errors": "",
            "filename": "2023-11-01T08-05-47Z",
            "size": 185040,
            "verified_at": 1698847644
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698838033,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "I6sUPgcPQr3DPuVCx61751eYCpt7eu5eyCxFdj5wvbDq2yJKLRY0ZcjDbTqxZ/A/lN6BKyCRA3UcReMeecAAW4+FjxYJR2IhwclSwwbO/RUbOLMmn91Rx0jLG3Qo3thdBpL/XWmWq+UpkbSH4LmKUHrX2b20ErdXHeF/avSxLHWwPkUvUJbojkZNFMDyICjAHBAey5Jg1a0gtobz2GtENwjGLSoMDPnKsZWnWQwsD3RkL9KdT7vZbLOMdWtlbZvtQ3zMcB7cU0qfHbmlYQYpHgiSjOhTtQod24Rdp6Qkufd2tB+FzROmcV31Ct4nAlLxn4C0PUtUEUvRx7gBvWdi62u+VmRXrNPrSF8F2boVVnZRCh98xG03b7KCefp71zIJihRXk959dqdHiy0lWfZa14yqDTlhKpfi04kjhYsqlw6c6T7GbNRkf1YV9SUqX+JdydSpcegafVlxlE0s3V6vvcwlvrCYQIhLeRxnTpqHf4ckPfpG/Ph+UUE+fnq8bStLNNwnPFf5wpEDlEjlD+RDK7YiZtRekxjNL9fgPVh+1tQT1Ec8qTzRxecQJxTPu/U9ojXWJBKb5Mr0sPPZPvIe4KINwav3sMUldiGYVxFg+1pv0+gT+3z6SQZpT1vErQrwBAvD87KZOoVJw437S2B8CeWzfan5wSQrGTVbVqgv42s=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-27-06Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698837902.1327698,
                        "modified": 1698837902.1327698,
                        "name": "/dev/shm/incoming/2023-11-01T11:15:00.ais",
                        "sha256": "9f82361976496415412f0264e4367b8081e747e2bc07674e17b6dc5292fa9ad0",
                        "size": 3419
                    }
                ],
                "mission": "EFCA1",
                "sha256": "f8da1729f09ae5a596f17fe5a6354c4a443ef560f820d4f40393bbe26180c0df",
                "size": 1343
            },
            "errors": "",
            "filename": "2023-11-01T11-27-06Z",
            "size": 1343,
            "verified_at": 1698848067
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698839239,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "Nn6NWTbVh6f5phbK4uT7fzBSJy4y5wee5xwFXnQCy+75LaU1yNE/JCjXqxDcIm2a5szyUYzhjZQnMaARiAP9MtVjI2a0rTJYAGqxWHie8GFViT7BVlUvg/vrkpSmZJX4xwES+bhAfc+Ntpsr9QpHued5xDgPABSL8HygBjqeFqarmyX+JUpOgMJfooTz7cNcz2QKgDeW5p9KTluQ1agAM5Wus1qbAP/ru5io7UOu73kNH29kSWMxtX+/aM51ALA7jdgIXdvmXX9PN/Yssnta6w9WrdwavxNnh+srS3ouyn7id+ylHUCR9LRzApzyesPNwsHjHCwLmipXerfTamINtjMJvlaH+WKTaSrSGVzlJ37PhOiogsSJ5/Y0QZTfUPIGAFkmX5KzRMb97jjiTsCWdQ1H8CVvSopce/WEBrslvrgwOkI0TDnV3AwB9Gfbj7nTWABG/8YzFVEIUZQXKz8Azr/2AUnx0vWG3P+VqE8oE9wGJPuLKcKBQvkny0M66Iw/X/OBGyDw5BIBj2vHo/PYoQgxT9oUk38jktrA/WVGO0wvfKjTc/gjHWeUi/fpZ0a0v9Bj9qu9HN+mKUKoQeAYrHETDsVAIA+kB3YcMme7LL2biAs0Dq7seYTitlWGgZs3KG4okzxJIdEnvYRzwsR7z9gcd2lgykmbxZFMUuuz6Dw=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-47-15Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698839107.6827698,
                        "modified": 1698839107.6827698,
                        "name": "/dev/shm/incoming/2023-11-01T11:35:03.ais",
                        "sha256": "87f42208f803e4ea3b590e955270eb7a891100b3130bca9c40ea43cdfe8e9197",
                        "size": 111519
                    }
                ],
                "mission": "EFCA1",
                "sha256": "398956a39f7730c1aac838e5854ccabdbc1cf4db92c959753e177424ed1eb4f1",
                "size": 21796
            },
            "errors": "",
            "filename": "2023-11-01T11-47-15Z",
            "size": 21796,
            "verified_at": 1698848071
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698839844,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "HiKBExxUnAp9/d7g+9bw2IO1yuiqU5esVjUSu3tHJXeLPcIuZa2nAsva+ARHXb4eM5h6MaZiMDaNr6l0BA9CaGYUs0SqzRWbnGnItFsdVIWUXLj25s+PWY4CHe/C+V0pU8Q504g/ZvXy0at2xwpMIlEsluf/E9tTcq/raVNBgw0DVtBeGbgiZ0J2w4DufOyNiXXEKPPhzIrV/KypEHT7OX8GNGiSaGRJjwZ2jy/SWeKy/pRtK89stOPBuGJt9lYqWu7GydiZ8CqaOM7YUV++DBZxheAsZKpU3tFypkvoXDWqPxT1xvyi9rsPrXwTwW8dT03vKXZ9Nt8Fq0Hn9RgePrRP7XJ4rB1Dts2qbcWspEfqkkeT+55vmfWHzsO7w9pFwWXLMMRjzqQa0JP9ckdqfAUPk58+/ZNmgP3OR6EJ4hoSr1uBOghhB3lRkTKKsajBmr1toeapkWQ3gz1xQOmJtDFlbBChpV/dDH2pgrZi47VOVOTpWjr8FNeQWgUEWhtjUEQ17UJr39rm+tjM9M9lyZvAk//uyTFFaM3zjYHZEFE3FBzgD+DrmwDzfMrreJgsHNTxyUaOaiCFt8kSIg2buiipLBSl86BTUMNcUEeikBdgnZ2ikSkTDiJUMRti/yqJzJDtqY3rq8OyK8N+wk0VeLWE9aKVn/ZatWR2iQtqx+0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-57-19Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698839714.78277,
                        "modified": 1698839714.78277,
                        "name": "/dev/shm/incoming/2023-11-01T11:45:07.ais",
                        "sha256": "da05692c6ec56faaea7312217f19a7a696e7e6a3626e1e53b28e1fd9f0936aa6",
                        "size": 141345
                    }
                ],
                "mission": "EFCA1",
                "sha256": "0a1e8e22043d8348fa84e262e05b53ce972a8a52696e0cccaf879b25fc227d73",
                "size": 25831
            },
            "errors": "",
            "filename": "2023-11-01T11-57-19Z",
            "size": 25831,
            "verified_at": 1698848083
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698842254,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "n//xmYzoxYyvIABPc8IwTIRRPdvi9VCQjjxVSINQ/QGV1iZ83GvhGbitzgijEjRzyAneB7f50Rd9bBbu+8gD/7IfEWEjng16RQ1ioOEWbmTIjmgr/Nv0rWAqQ3I67YLMFdT6zgXp6qg0iM2eGdrhORPPLB2KvwsDP5pIv6vT7RYwG5HeeaGMNDCY6R7E7Avd8BeVDqnzxO39Fb/PBaUuMIjyyOp8Hb8YIOWMB825WNE9kpHx5ZkiFjDF/XbeVdnegwz1nC2AuBSbHOUWuzSpVttNzgy311nsvi2oGIpsL5lIx2vzm4HdZn+gRuNl1ksl4wAmWFraQw0C0WpkI/i72DHo0X58cWVixsJG6iEM4abkAuJVHP5azS3ELduGilXCn8UdC6lGTp/tw3P2uNB0q0xkAqaLmUg9XU2dCbsxBAa9Iox2dzONYo1WqW0JJs6CcQ3ug9F4bZxw1rmTG6MTljIXEdcJeuGuwNsp7ThVvTCPiEF64ycGezzm0wzT9hGs6zMcOVm3UOVKUU6arYAWwk/sT46oMOrzyHCtMSWapDczTjUqNtgnUthgyMGaKUcB1+5OwHe8bVDrVfWoaA6WEc405Tx1n95g2H44WCkloPN/plyGnNbdQHLkU9OeAZ2GAo8b29BHhG2udGBYbGfS6wn1eJLI5ARW9JE3LTov4bI=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T12-37-29Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698842124.31277,
                        "modified": 1698842124.31277,
                        "name": "/dev/shm/incoming/2023-11-01T12:25:23.ais",
                        "sha256": "3135a6e90a32f777bdd259ee1a18154dea550b24209c644c44960c0cecf27e28",
                        "size": 175782
                    }
                ],
                "mission": "EFCA1",
                "sha256": "08d4e10c7fe9687ceb386fadd9003b695f5e2cd2127f99df66ce8ca6fc574f27",
                "size": 40460
            },
            "errors": "",
            "filename": "2023-11-01T12-37-29Z",
            "size": 40460,
            "verified_at": 1698848102
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698827171,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "oHJJ9dktHO2ZFf8MwQ7dndXIXhcBl/ijdVHgZ+Ye9cOjTq7qUqvNqhVicUg5aKv82il0A+8mgUhDXdLA2XcqrRYbS7l1sOiU9r69w4hgQI/21/JwosEFG+CaMftgRb5B10x+xChB8fCHPRQemoEGiYg8MzPbJL92maDfa+P1M9p4N7bupMiBHb0kB/QsgZewNqubVoPY+Sjxf8bE0CMNFTYK0zHi8TMJp3M9LiZgUNYu/FSLod+uW+hZ8LOs95l54YC5iomGDPIXC6KMe1JL9BGVkBP4AmO3Md+q0jOcmbFp7PQXISdeL0Oc4VahrK8+6cL4y7PbY4ayrO/8vLWZSHt5O2kK6PVSEDJubwp3+F0ZxcAKT41meK4/gS0leNlsfaLoYyeAhJ4/D02m5dPMYgEOATpMxRdM//vxKG9L7PMgi9XTORslKsQODWR3ash3Z/blycnq7rezO5+NFYMHU2lf2b1dGTCx9k/InTQSoVkyw1/z0w6hciijKEIVeZ4SAKpBa8rj2R12W26W7pgfy2h/XsrwbSeJMURJgTG3myFbp2Sa6HtGNdAqwgZh1EXK8QlfpGV3FeavttGznredCHp5IsZmdUSJWJfdNPyWbDFDtv0hxgiSscYgKqdgVG5dzGwCBKSnBQjivXZ9O34DaPp2VfjJw4LiqqzXW32WJS8=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-25-50Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698827023.3485453,
                        "modified": 1698827023.3485453,
                        "name": "/dev/shm/incoming/2023-11-01T08:13:42.ais",
                        "sha256": "b8d9904ce97240d0dabf7f74e8d985684afbcd435cb8230094c5e65dd312c6cf",
                        "size": 709413
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "1503a18da9e2d4c628ffb09f26b47b125ce5ee361054f0a83d7f91847b086caa",
                "size": 219734
            },
            "errors": "",
            "filename": "2023-11-01T08-25-50Z",
            "size": 219734,
            "verified_at": 1698848121
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698832221,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "q+uJilnslfi6avKEF6DMwoCm8z0SvY6mhkT5VcmLVJrPD8f4xHnWTC7UIpF7eCO2j6KTYFPrAiiaZdts9Tltyj9rj+f5rq7yUetr5pK0BBw67+IzzmKWVWUH/V88OwMSx1HhVapQgC196azoNphH/eTt+12itTyFcTTM4nsHlmbBka/pPdCWNwl2EZRawtKpI7wbdVEQXwx9APdwBqXLAYuGbMYAF6F53v/uO+64NAhVd40tEbyC+ZG6/VmvRLCEQSu1nkD1RTKd83cmDxm5KQBwm+ndAAsZsfJsGmpHO/JX7vrWkNRxxMbtet4wh5r5JbE1n/CHNxfGKPYjjvnoL9iL8/V/wcyHbafawNEVXY2/KLGyL6gzgXreNrrAuG05np+eC1cPoL1mdrQj9AY/G8kFtremNtVrEO6ZkpJj/OP+bwHE724dDJjHS0QLastdXv5HwV4HFfI6A/ByFNuDMRejaASa4kcaDFaojuHBS3LByTsmnp3AcUt+xyRn7SX4wCOSJnM1dXL3nJdb7/X3olqCXeGIJ+JXIsxN3QsvtViRfHYrvG94SCrAujlrhanLWGXp/dQsPxvyzvVOryjdjvk6rbCL2Fo6l1qyQRowvXTzu2TCm/XrfX/JSvd7zzq1VRIjFZbFK4WbWfJZGEhmSGHRbXV7W4cjrM8hfmtRjR0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-47-31Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698831928.30277,
                        "modified": 1698831928.30277,
                        "name": "/dev/shm/incoming/2023-11-01T09:38:19.ais",
                        "sha256": "e68d3f18c3061ab426de1e63d29e403f16ac504319b8ed0bf006bf2229789424",
                        "size": 28597
                    }
                ],
                "mission": "EFCA1",
                "sha256": "0f12b1e497c042fe2dd43f44d8cd4055c50d6745d720eb402947c2b0cf26ee0e",
                "size": 7240
            },
            "errors": "",
            "filename": "2023-11-01T09-47-31Z",
            "size": 7240,
            "verified_at": 1698848122
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698835012,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "srqqNAYwQyin+whQAV1beS/eX5xVK10hognc/By8ddbpZU5ZCbd/rKExA5FJtcA6oI4G13FYRKo+FolVEgTnkeNehw3x2SxBA+Bbj7xLNbuv2q8Xv2aQrPKudiHSGJtk+5NGRqAZCc3SyRb69xMmk0RNNIn09MnC5BPx2+/PaGh8p56NkOIRdb8pODsJFKCthudOWUdlc0CZc6u3iE+fMXU6aiE/jVIPL9gKpWLxPvNhxZiAJWn0s4MUOesqe4fM+GfkgW4BzePIj7bV4wH1NzI7q2jpJOSbQUidOZsn9l0prETaBgxbFhq/9J/iMeNLAy9oS0yTwas5EVfPqxYH1WUzHIPDvtFRsaE+Cs1vZa2/BQg3D4Q3ugbEE0Pe5ez167NCb0GOO2cTJAKS0dMACfmdOf00SbYERAXiKySpT5XEP7qB68AbdgekdsF2dGWwENF15mcr2APFKgvX3TUssvWW3ohE5XjDsd8VqY2KGo4mW76OyBpup0KExPtDVaynm8fvjqnAtBYdb/aPL3vlRQmcZuwOVH22la/l70geiIonk3b04WYPKrc5uyO4B4QoOwLvoB4PQtzwI0GbLACF0mFtOhgts20QrfLxscDlu4hnNkDYXwEkVrPCXjo8aDYnJvuUDwfBsMmC6T/T5P81zn5xoHLyUp9lkmSV7DaOQu0=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-36-42Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698834877.1527698,
                        "modified": 1698834877.1527698,
                        "name": "/dev/shm/incoming/2023-11-01T10:24:36.ais",
                        "sha256": "62b2ece2bdcf1ff496c22a531d164f6880cfb3f3209f2d65e5fa3956be62a97c",
                        "size": 109285
                    }
                ],
                "mission": "EFCA1",
                "sha256": "9e96d5a5c9d88137f6688bbff00a5df8a3fb6b94e3a257ef51f876c6e39534fe",
                "size": 21204
            },
            "errors": "",
            "filename": "2023-11-01T10-36-42Z",
            "size": 21204,
            "verified_at": 1698848197
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698836218,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "iyXYE2elOwAjFWxTzX4U5IQIoDbYJPttGTkVoaZVmliCyM/meoliAqYtBOTEUD7KlPYmAzYizjkjSPfYOm2pQ3f5VokHzJg8d7auJL5yd/lAb6VQArtEcx7KWyKFNxYLsCJ9cdWIbxEcPwGfq0HP2obWRTmSNVlCxYGcn65YXviuD20/1YsMqy3eA2AMWvCQKU+TvVaKI04Y4n4eyUpRthk4SeFMg5IjGhMYpo/NojaaEagctPtNuaQ0SP2J+RTtWAmFGG+tztmXbMqboD+yRel1T9loCbs3KQu2Uce2+WDjVxi450XZGrhBg8QUcpceBuDELH0NjN72Ce+4umSOzH0vv6E0yAfyvSiWcWHrXsv9tbSSs+HV3XrIkqlYxZTWX9W4oIKVTUROGGpNKJY8E2Z49jHYOVXtl7AycijOfkTMYwY7krs4EQnIathGCgdh54RJ2pwCEJE4Q4R4XQU0LdJp7//ARB+ZIqgaPNBhFdTXnnjCtDy7V7wR92pwub3uTdMGzD5qXVU/QADcoXA6TXHeGBkUoCu2Tyj4V6Qffyty08zwCOPU9hlDO0QtQCSvvh6pElNB0PqbneyJKAOR1uN18mkVf5WJYyTM3xRZoSGzEJ5MpYGLkxf6kam3foghMw8b6ThnNaPlPP0NtwvzEh+L/eB5efYWwFRJyu80rWo=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-56-50Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698836083.03277,
                        "modified": 1698836083.03277,
                        "name": "/dev/shm/incoming/2023-11-01T10:44:42.ais",
                        "sha256": "18a4835b794593b8bab9b97763ee03969a2ac0756b4d6bce13554aa5e5732b73",
                        "size": 62790
                    }
                ],
                "mission": "EFCA1",
                "sha256": "020c7721ddb961c3d56a51b9135fcd5e8d3655253e7141176fc0a7c26aef6a43",
                "size": 13755
            },
            "errors": "",
            "filename": "2023-11-01T10-56-50Z",
            "size": 13755,
            "verified_at": 1698848212
        },
        {
            "aircraft": "9H-DGN",
            "client": "EFCA",
            "contract": "EFCA1",
            "created_at": 1698837434,
            "data": {
                "client": "EFCA",
                "ekey": {
                    "key": "QfgdwmM7bHJysN84W+XlSsF56AlMO/dwb+sFMgEpvXtRroSVGBDR5fKOvES+u6YN6VCN45tcFKI8ZVt28P/MFhUIa7fvDnqxvj4Ua0PaDI3PyXZtgmAvpZWbIW6tBH1fylz3idDsM6hD9oDi57FB9owaLpm1ngQ3iye8h869POQbUFBnoGb5wng/bUsnW4lHR4MijO6/TBkZFsFJdQfxPnv/s+cv9ZYFn5+sTlLLFLN1jSkNIO4VCuwFTsFtb3wqop7PerXKuds1ECu+v2vEjG7zf98QeDv+836nDHO5F3bAUUo2VO4cDMjYy4YKMtgtDgFP7RMp+ajI+uudx026Tcf0GFsEkqmdqPowY2wFBmmoDFukL0ZYton5SCWO4hwIrgJr4hgQDYDt9uyh77YlOscrOshN1YDz8e+E5bCJNNZ/oloI65Niq64Ff8Q49RYgxM4XoDUqEPc4tady4LR5ShbMLyEZOt8KodabjTN+AxhjDq2fKI9MuMCBJR3kDhK+MZdSivT32qcbCQWDS+WfVthkdIX9Sv+ZoKVvYAemxSIDgAn1u5IvaT6Q4cF8cPutGQ9YIilYGd/5PUJNbddlMu3KnT9O1p09dsSyE1RAoJEbWi0UWhbUgEnN9ELYuIjEGtuh3wxXXl5SByMCWJ5AQPpS1mlUjYPGrmJdGGNx1Ok=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T11-17-04Z_EFCA_EFCA1",
                "inventory": [
                    {
                        "created": 1698837300.7427697,
                        "modified": 1698837300.7427697,
                        "name": "/dev/shm/incoming/2023-11-01T11:04:45.ais",
                        "sha256": "41a2d9f8c9c95480bed5893f636c3aeb63ecc7c7ff84d3a08f4f7c1990d9777c",
                        "size": 10950
                    }
                ],
                "mission": "EFCA1",
                "sha256": "41c2e850be2db009fa310c3012bda971331473578a20544308d96ed70f222955",
                "size": 3561
            },
            "errors": "",
            "filename": "2023-11-01T11-17-04Z",
            "size": 3561,
            "verified_at": 1698848230
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698823203,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "YGOimPBfwMLJ3DYE6jX+BgcvCaBL2g0AhiyE5zDyK+bZ+vsw7KXFwZhQNteY+ipHY9fwvr267jQR8pgz65Z24/OUnHuB6+sdhtfGZAokPnji+t/EcfR8j/jCY+OM3GBUPCE0cbdp3DcQy6JEPmzmmGJQ+m1OJjhi0zaDu/nmF4lGFo/qnp7nv9RL/KtNeAUoHRj3LbXZvYN8dCjFjyyfNm2EzQUVKi3LasKeZHxAThIf35qrwdgi/6PyW1Dweo58ucpNnBcVf6vIoRZtrCTFrTdT4WSw3Ghgk55XpOU3jr6gAOMfJk/HZEp5HaJKUpzfXgbGNMwQ9Eo1D1x5XRVC+T+QFjlCz3sE5hIT1kBOW90vmsuv2EqLmNjwHkfIBpU3f12UEJCeVXSVjYMy+zEqBr4pppHrCfCAn/xtyCPzwjTvHZumlGbM6LqXM5HCCK8Cew91RWvOYyUdCaRB/OAGvw3oNf5vRn9uybaqiluRh3Dgk25VMd7yUjIw0UfE9OcVZ7PpYjQJQcgMpRHbVEfH0UWuvZlHfE0K7FPQdvmH+Jt3q/A8g/tlX/SkNuFZ/6NwZAzkpihxSb3TQCGwTENbJV19CPPbP9CleL7YqP+XnE4KtoQhdZ/YjECSzbV4R6Gr31wdQJzE/c/cHaTIe7f8F5v/Q+4Hdry8P+k6YIVDFsQ=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T07-18-41Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698823110.2785454,
                        "modified": 1698823110.2785454,
                        "name": "/dev/shm/incoming/2023-11-01T06:48:29.ts",
                        "sha256": "27aac2645ba4409d472685b7677c9823baa8e22f5fdb4c04d12a0ee268376779",
                        "size": 427449960
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "5815ced95a24e93ebbd360e05bf35605140b3022f065d29b12f38c039039dc00",
                "size": 401173938
            },
            "errors": "",
            "filename": "2023-11-01T07-18-41Z",
            "size": 401173938,
            "verified_at": 1698848330
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698826810,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "Tx35axxcBI4HrO2VQHgm5p9CI34HxK66vNnDesZ4rUB2FHO7SPM6k3PJ2vC+Gb4zMGcSGO32WfYnFElxBz/Ux0tbJQbt2wG6Fvvren7Hp5IcJFW2Tj5zsgLlvWc3QsdenJ/yMa6wKMPyeCRZqvG22w+cwnaFLxQd5pLC3ehPB9h8e/moSMhScvIwZnEZE/HpM5/oiaXa56hjdcq0Ap557Svo3fq8iFDQy+Il812IdrYGuXclulaYL6h4BRtYaW0kpJlM0srGyL1WMIOzAa29QRO0DT4/5ETVVMHbKFBEQH3CSKGqUK1M6jiXkFN9dtpUNBugC/TVRNRmRgRMzVb2kgdBVwJmXvVhXassYyHaByzs8wgQ2wVEfVi8hegZIn44VfGQRRVA4bzCyZm936PrkYLAsf61UTO6r2BTx+Lm/i8QiuoHOfycdPU73LnBplPHItOKlGSjBN2Z3LQtVSkV2z9IGb3QlEuQnhHjDpdGrU+nhaPfcJiHuMYpOnuN6tktSxMwW9BKP/5fDEASSYUlqBCWk2LApft/k7Huue2fdtOlKNd8GYk63JFhjyDNg+CPLNbKI4t7U2FEac/fVuwiRyheKp8TiFKVUDsz8f1KikTDBqZD9ITljE8MlTANdwlzMGArrapy8HyC2K1APZepyMjdMqycf+vL8+2XzvAVo24=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T08-18-49Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698826721.7185454,
                        "modified": 1698826721.7185454,
                        "name": "/dev/shm/incoming/2023-11-01T07:48:41.ts",
                        "sha256": "76eed61dbc0aab340e17314323dd9b6b4bb0851c460b8b5e996fc279b6fcccf3",
                        "size": 426695516
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "c55603498f5270df443baab96bf71360ae77cb176f7f55d36861cb37f0541113",
                "size": 400413046
            },
            "errors": "",
            "filename": "2023-11-01T08-18-49Z",
            "size": 400413046,
            "verified_at": 1698848753
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698831384,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "L11IF01n5AsI0ouOznBHTAHVB2zHzIZKSrHuYRnMDrR3gX6UAaimEz72w//oDBiOQse8V9LyOj7doM2C0SP52An7A0RW4HJkVExzRbCbUmzbKkJoMBMnIs2JJ44X+zgTZV9b4LhXUuzdqKLjpX2CsKS3V9bwV+R4M8zGouXJXwFxKg9gq/Xs32aW0xdfT5v/OjnCcbWP2MyiGA3uDaZ8PtnxQTM0FFah6nIJeYeLYtsAfsJBAwO6+yL8RMP1yNcILbTyWH0BqT3XklX+KFibJOnGYYh3uObHVlYjciO38JMBY6jq7wAhztOHdn2jNC5OfeDaKG+os3iDswFKccQ8FeM2nlcHZa0Nuew4YdkDGgVkZaypf1vQ3OamKA59/5bKhOHpItTDdrKlvvMYJUiYMUSvV2k63uFtjKhHWqZsJv6RT2jWlyzxvj6Ha30E1Xx0Cg1ytvEOKDzY67m8Wl2G0G/Dazq/CHEdL5tICYczk69Z2qjE9xEob9uwOtJ09ykfFytBWGn8rVP7uQxETTPfQPmwPYPyHFRcpB+MhED87/Cb9kzgxfWj12W51f5h+Zp/VgLhkQ3nILlN71FKXKJDEMwThikeXdTIZ1WZJLaNbeOtYg3AOGkDb/7enfvEVAq9SuFmss2PpsxoUbfx60lufPKXdwb05/vQTjEpz22/xXE=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-36-00Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698831232.5285454,
                        "modified": 1698831232.5285454,
                        "name": "/dev/shm/incoming/2023-11-01T09:23:51.ais",
                        "sha256": "32baa92e17f474c1251b81ef25b1904cd940f5cdb9d4c51faa3cc1f9719c6e5d",
                        "size": 624376
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "79508c5564e6521b5f9cc3e14913f9fd1cb8d9ff18bd2b25ecb608865e1be801",
                "size": 193339
            },
            "errors": "",
            "filename": "2023-11-01T09-36-00Z",
            "size": 193339,
            "verified_at": 1698848880
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698831982,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "F7yOu+g3xt9Q0y9twyInAQ5ueRUGDo81Pel2BrO3OVFN5Xk04Gv8e5PkfPJyM/8lxvsvqG1w/DQkQkSeY8jG/+iXQcKPUP/Ds5OiVDkajPkf6kcI4NW0V9DLvMWwQAhcvL8Ompf/TpcyECDt5IkCxBYD53GiLgfcMQx1K4o2nyTT3JQZJtXMVoVXHorqpap6xn9kRjHr0IO/I4yPIo8SDR/0zJsGNaNW9QbO6r2onjfs12OuldnqP7RYQiahCeVCiz7UZoLLSc5v61LlAGi+JwFb1sDokwcQf4rnyeLaw5bGP6gp6VTteKTnlw/1XK+W2jz0wRZtxk9+UVx3FS6jBI4uB2nFb8DGLcjRiMwX/Aw2ISuq4uKuCNdlfQeHeENOvLp/cgOI28X99YCmyUQyL0qZB+Ekn1C01AnOiMAMeHDgppl9wupaluLOVqj/FvH6lm39H5O/6I8R1LQ/qmCmAvu6eQldQUCtGOh5fc2mxzVThpk+SBOdiBN7m8dofgDQi2X/cA6qpMjgBsuKYsxbUjYth9V5DsV75gwH+9bWW8pdJ4UDUM4nwj/1Szv5vgQi42ujzgV2KeQEeTvzlCbu3YrTQWEBO6LKlAE+OQpURLkEWQtZmGntENYXubpttMk0KhhlWE3vBvMUDOv27iwDxslmNeZR3A8ofqupFRQ7zUY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-45-58Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698831833.7885454,
                        "modified": 1698831833.7885454,
                        "name": "/dev/shm/incoming/2023-11-01T09:33:52.ais",
                        "sha256": "8bd030cc5ef4e95260ee1bc6081e97bfe24f47c8b071a21fb50e8372260a6d5c",
                        "size": 679850
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "3bed2a560eb484b8b5b3720d7e2317e5f605aedbba9ea2e8275a6b86bb905c98",
                "size": 212299
            },
            "errors": "",
            "filename": "2023-11-01T09-45-58Z",
            "size": 212299,
            "verified_at": 1698848952
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698848993,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "CCGnty5gWhotTJn77qc47Ka5ZRqGtrBhU9jptYfRjf4Rrk5vxKDYvdx+PNYfbdL3jzRwDkq3wP0RP0Y8adVm3QBNqRbk1IOghvn7hTyPb3gJa6w9uWJ+z1d2nnsMkrOhk1lFP12VjGT3koLbb44o/rXLrD3FyhtuhWht6NyXtfw9igOp/+DPVxPzxL+/vtUiAnvH4poB47iY/o3zSr2NmqcxktxC3r8RcbSYCxYhIwd0MZRBQotS1h3/YqmSmVbMW0kGe2pULFbgpkx5n5JxBwlYsc5FF7Ez3pFCuwW/mk4dMEo6rPyo1PnjheAD9CRYYgr/VYYygrt47AGBiQtSZf2yc9Pjw68YnCFryZ7UKQChhMtK8BrFXFtki1iT7Cdwg2FKh/USdZdeEjVRGNzCWYbEeiyiYdZpETMus4+sD/FxNxbJlQVdC1dSiyHij1TxHtcBixbe6tzZJu51BuhzEm8Ccekf/uvuMqKuhGgLPKNVjrndCX8NP5LpcltgY6BZpPXzbAlTSrqdVj2S4jxhZhIFhKvC24iRWpE1OUy+vZrpA2tKEbWx9dQe7/9Pa0IzFf6Ay8uWjjiG3wPkBb0URqEF2vn4Nse5Cc6NPwZ3OEaGipjiz9qL7SrWgtuL2pBMQUpL9OQ2L5GvcZ7Veic3kzY5Ti0Rk1M3UP0zlhFSPRY=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T14-28-52Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698848805.1494894,
                        "modified": 1698848805.1494894,
                        "name": "/dev/shm/incoming/2023-11-01T14:26:00.ts",
                        "sha256": "eb5746c3d992b072e4b88e109d1db1f4e1cd2450424622c9cb7efa3df6c9b1ce",
                        "size": 10383616
                    }
                ],
                "mission": "OSP2",
                "sha256": "e9009dad1f5543a2d68ddf1e482600c04d00a9c204267cbdf15728740978ab3e",
                "size": 9976889
            },
            "errors": "",
            "filename": "2023-11-01T14-28-52Z",
            "size": 9976889,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698832582,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "s2BG4x7UJloav5JUaEkcKhuWVLebBnPU9h50DVsSl0Xg7PwjajB0VSZKZyH606rskXh7HOEbGmCdmPzpfzDV/Nd/wBq7Iekqh/vmnwjL+6GTEZKw2sIyA+1m9vbgClN1afHS1NLmjXF6CJJUVjFDrvGFDUtlAOG0bUDmEthP12QJNtr1+pRx3UP2Bfk3FO+C3rq++kWvSGPIzZCiMAJcNvUHgSZs1pPveqBUXJaWQZSbf+jjw+fPZT3Kyiq9dD/F+ZUS7qfYAG3v6URNWY1WbvQoMC+aI42QMcAbmWb+TZKoVtDLPhqHyI7NliRYOXReJcREkQvs/kFQuzqBUmgLSQz9rzkXsmUuwGcpPSH6/bATO/+BOv6LlWtRDevdr7YToiqiRrB/svbJd8j19WUWy2v0AXdeEsLl4cYu/Viyx4eekFLACMTy/ktx/pmIltcqfUQAmBQRQBAAzmfVthjcwhmg/jRlhzQnsrQiLrvzEAfqk7Z+JPxUy3PMdVDjQHhNIZGJIyYCQHZGeGWUrt+sTRi8I3jYyvMU7WPhvVuHxetgiTAdpZE0dmpWEp6pyJAm79k6eEskAFJBAHJbGr44RISOj9SyPUoq4AfXSjJxAKZrjm+VI8kMhZ0olT5fuGTrfGahnZ2sh3iEi7HzW1V+cfU19PSbj0eDqGLrXz9/+1U=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T09-56-01Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698832435.0585454,
                        "modified": 1698832435.0585454,
                        "name": "/dev/shm/incoming/2023-11-01T09:43:53.ais",
                        "sha256": "46b97f6b22898b03d0e2530682f0b1fef79f517b213e3b172bdf477440ddda01",
                        "size": 660092
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "25d5891b353e5f7daaff4a45cbe5cf326ed7d1a74e10149df4f5fc3ae603b378",
                "size": 205843
            },
            "errors": "",
            "filename": "2023-11-01T09-56-01Z",
            "size": 205843,
            "verified_at": 1698849161
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698833444,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "b56VfrVcC7WBJ+0Y+Tv2p8fLqEwIbC1ZUAV/aGKoc0fhmbV85rCeoBpBXfAZUTc7fiKgjeHd0VVgyIHRIBefdVAB3Dw/hIDjDBC5jclMRds9F4QlziTSc7bH41IKBFenOIQhYCZY2iD1eBrqmCi0XfWP0NQ+DDS4NuuoY7T6bkuE4kxBK1yWVTf285AkDY9zRtSfCB62PwcoDNWX8bKB7Hpw+Vac5TiP2QlKWWXytY7meJ7N813qehf6+dXz0saW2J6ZPo49hwn3n5kbGDFCQA1cMo+r8W1m9/VKQ5JJPSmU/nTUq3qigisbsbjrdKtWTOgf4/QOwzQPI4YnpWqijTIQD8CpZsykE6ZQpgR4Jc5/JbKfWhaaah5Mf61871ovJaC5hd2J0RUnZKoAGVC42ybS3Iw/7ZHHzaBToAel3S5beY1setVKQcljpLmnnaPEfMi9UyrfnlZO3LAdJ5tdrXo/QnOTBTg+BPXyOV4xLLrlYpTPTGrJ6scV31Gv4GfJhyFjaLFBj5f0K7WYZk5pCvLkQ6AViPt21X6aeCJEb7AV4LZAzGaj+T8mWT1ja3LebN/dFikE+PA2m3gkIA0pRNp7ZKZIOyMfTiyN2W1Ujql/ieM+7yMAuwFeAOTUHZu1coZI1hHjefnRQbMmI89kDXyrXfIrusReirp3qyaele4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-10-21Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698833307.2485454,
                        "modified": 1698833307.2485454,
                        "name": "/dev/shm/incoming/2023_11_01_100758_00_00_V_053.txt",
                        "sha256": "c85c34cde16f58bfe09ae1ff2b9465f685b119c784b7ddd4bce76879a9478f94",
                        "size": 2028
                    },
                    {
                        "created": 1698833306.8185453,
                        "modified": 1698833306.8185453,
                        "name": "/dev/shm/incoming/2023_11_01_100758_00_00_V_053.kml",
                        "sha256": "d3e10eb3c8cf5017d64c2fb0d112d32529f7fbbb79b9ed1357e9ea5987255d2d",
                        "size": 977
                    },
                    {
                        "created": 1698833306.6585455,
                        "modified": 1698833306.6585455,
                        "name": "/dev/shm/incoming/2023_11_01_100758_00_00_V_053.jpg",
                        "sha256": "1b6c27de9052e57ff7ec84be2bfe09630379ca99c4eb2e86e9a51d2800d29180",
                        "size": 1069575
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "ed60e443022f694bbf91467c42313e8940a269497082e28680642d3be3735644",
                "size": 1071363
            },
            "errors": "",
            "filename": "2023-11-01T10-10-21Z",
            "size": 1071363,
            "verified_at": 1698849326
        },
        {
            "aircraft": "G-WKTH",
            "client": "FRONTEX",
            "contract": "OSP2",
            "created_at": 1698849377,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "LuB6/PGlu7xZuU/K0u3SDO2rCRWu7jc+zBMaSecM4CIC47KvnXMP/IjKj0nOgw6z+W2aOWacckQp58FVtDv4NTJPCloNrqex2xS4I8r+W3GDyzNTgiGdHohOF9XvmIEJVesPQRq1vFUn2KCF8sq2mBXen5JFKfGa8BD8QOrJPd+m3Z34ssZY8m8vaYN1IG6Ya9x9obmuK+c8GKAYgl9ulKQGFEJcxixkKh/vboviSrzTPUTSgbCatVoR5CcrSycCxhjiPjw06dhhSUrYTtIQp8CHAKvgxvk+PHgMinYc6OnrgBkfVUmAmcDtQvlVXqHR9qGfNUtT1Q/Rxq+1agCy3t0/Oecdj0W/Q/la8XyOtQAM3KYnMpoaVczueEVo2ZGAdrtkEReLP3IjFW/XA01NuTorcocDpfplK9GHUajAjIagfF8v+GExXQq50aZyQ5ewjslCMVk6QFfLV+w5oDuLIul6IAVjqXHuIxVEubhNEL/IU5GbRCS84VZIFAitm0FduA3qBX4I1x+yzyQOTKvBFFfxfUWqkS2JI+vmfl1I9k0SK0AMnFhlY+V2M9rsk0VJMxiF8gS/W11JpZuusJCJW1KwmZYqORIMSOfq+zslk3rxOIwmIEtztlfqVT+LFnF10GPGHD3VDu7LTcfUSZJJWha850B6D/mVSre+2NQKaF4=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T14-36-02Z_FRONTEX_OSP2",
                "inventory": [
                    {
                        "created": 1698849237.0094893,
                        "modified": 1698849237.0094893,
                        "name": "/dev/shm/incoming/2023-11-01T14:23:55.ais",
                        "sha256": "7073b491c83f700d198d31511045d24e35351e23b40dcda4d8edc04e74a08f92",
                        "size": 9632
                    }
                ],
                "mission": "OSP2",
                "sha256": "9db971be90c54e155e6367c23a64d84f2478ec090060b2fbedbfabdc6d46ff10",
                "size": 2914
            },
            "errors": "",
            "filename": "2023-11-01T14-36-02Z",
            "size": 2914,
            "verified_at": null
        },
        {
            "aircraft": "2-WKTN",
            "client": "FRONTEX",
            "contract": "EAGLE1",
            "created_at": 1698834391,
            "data": {
                "client": "FRONTEX",
                "ekey": {
                    "key": "eTPC5sLhDonzCUOuGY5CKozxuOFVyHXYTakFOmDT6NcNe5MbCkO6i57vp0LBdk1tHrHuIGU8YkXIErECI9WMb9cice3cBtQxVyB5HPB4az/SISIApHT6x++A6+kqhh2ZTYL5VwSvf7OFsq9NlDHfnVjYhAC8quPLYU5sJMpVOSYPZPTMcbAypMGAxKCzfTk3GU4lT/ic/M9XhMCHOVlyt3w+Uvh6PokXzK5j6RVe0X164bnpCNNSTDxg9GWzTeqdY3iGUjgvdv6QixVv8ptim8tSp2ZP5f8ravRgiRWhcfvCI7LP7skeujR/iKw1Oh35/ysO1rXMB84W9apcmORd57wPTiAB0/bHQxh+H/sEH2elg+0uTARJaS09JxifM3v3WO0tbtJPtJ+wuhvYiCuZvpdoYQH94X6u/8IasWgvPr1Sj8cBWUKteaYBg8tr5NvLzbrG6qJQHayvqRTrtqA6qKZkLXkGnQoKa224iNy4X1dQabHMtT+QHCdXfpzBpLZmuC1+p/bikKHnJYp9K0tj6nSP5nMJLqjCnSJsw9E28FizhOm19zcsEnQiDQfhJJWBa7r2wZK7heBX1pkW1toq2sYwf4EOESDlvMhzyaJBiJe/9h5B8DFmi4iKGM9T/KItNkT2qIQUq5Ez9kThyPSiocjBFqS7tLmT9EKlMHfpj8U=",
                    "pubkey": "091f213aeaeffb4015e6303d19f9c724118d41f5"
                },
                "erase_after": null,
                "file": "2023-11-01T10-26-07Z_FRONTEX_EAGLE1",
                "inventory": [
                    {
                        "created": 1698834239.5085454,
                        "modified": 1698834239.5085454,
                        "name": "/dev/shm/incoming/2023-11-01T10:13:58.ais",
                        "sha256": "ce822aee90d0a7686f6b32b9fb736adf5f544a9b17a2d3f95aa521bb0985fcc8",
                        "size": 647338
                    }
                ],
                "mission": "EAGLE1",
                "sha256": "df54d636ab3d86cb2c55ddcb3f3afee640dbd286aa7b4fa73ef4a6d96b635a71",
                "size": 199798
            },
            "errors": "",
            "filename": "2023-11-01T10-26-07Z",
            "size": 199798,
            "verified_at": 1698849639
        }
    ]
}