import {useCallback, useEffect, useState} from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import {LogoutProvider, useLoginStore} from './Login'
import dea_logo from "./logo.svg"
import {Login} from './Login'
import MenuIcon from '@mui/icons-material/Menu'
import HelpIcon from '@mui/icons-material/Help'
import {useLocation, useNavigate} from 'react-router-dom';
import {useServiceWorker} from "./useServiceWorker";

export default function MyAppBar({links, settings}) {
    const [anchorElUser, setAnchorElUser] = useState(null)
    const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget)
    const handleCloseUserMenu = () => setAnchorElUser(null)
    const [anchorElNav, setAnchorElNav] = useState(null)
    const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget)
    const handleCloseNavMenu = () => setAnchorElNav(null)
    const loginstore = useLoginStore()
    const [userMenu, setUserMenu] = useState(<></>)
    const [siteLinks, setSiteLinks] = useState(<></>)
    const [burgerLinks, setBurgerLinks] = useState(<></>)
    const [onPage, setOnPage] = useState('')
	const location = useLocation()
    const navigate = useNavigate()
    const { reloadPage } = useServiceWorker()

    useEffect(() => {
        links && links.forEach((page) => {
            if (page.link === '/' + location.pathname.split('/')[1]) {
                setOnPage(page.name)
            }
        })
    }, [location.pathname, setOnPage, links])

    useEffect(() => {
        if (!loginstore.user) {
            setBurgerLinks(<></>)
            return
        }
        const menuItemClick = (page) => {
            navigate(page.link)
            handleCloseNavMenu()
        }
        setBurgerLinks(
            <Box>
                <Button
                  aria-label="navigation menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  variant="outlined"
                  onClick={handleOpenNavMenu}
                  sx={{border:0, color:'white'}}
                  startIcon={<MenuIcon/>}
                >
                    {onPage}
                </Button>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                >
                    {links && links.map((page) => (
                        <MenuItem key={page.name} onClick={() => menuItemClick(page)}>
                            <Typography textAlign="center" style={{textTransform: 'uppercase'}}>
                                {page.name}
                            </Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
        )
    }, [setBurgerLinks, loginstore.user, anchorElNav, links, onPage, navigate])

    useEffect(() => {
        if (!loginstore.user) {
            setSiteLinks(<></>)
            return
        }
        setSiteLinks(
            links.map((page) => {
                const deco = '/' + location.pathname.split('/')[1] === page.link
                    ? {borderBottom: '1px white solid', borderRadius: 0}
                    : {}
                return <Button
                    variant='text'
                    key={page.name}
                    onClick={() => navigate(page.link)}
                    sx={{my: 2, color: 'white', display: 'block', ...deco}}
                >
                    {page.name}
                </Button>
            })
        )
    }, [navigate, links, loginstore.user, location.pathname])

    const openHelpClick = () => {
        const page = document.location.hash.replace('#/', '')
        window.open(`https://sites.google.com/dea.aero/timesheets/${page}`, "_blank") // @TODO
    }

    useEffect(() => {
        if (!loginstore.user) {
            setUserMenu(<Login/>)
            return
        }
        console.log('reloading menu settings list', settings)
        setUserMenu(
            <>
                {/*<IconButton onClick={openHelpClick} sx={{p: 0, pl:2, pr:2, mr:1}}>*/}
                {/*    <HelpIcon fontSize="large" />*/}
                {/*</IconButton>*/}
                <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{p: 0, pl:2, pr:2}}>
                        <Avatar alt={loginstore.user} src={loginstore.image} sx={{borderRadius: '8px'}}/>
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{mt: '45px'}}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    {settings && settings.map((setting) => (
                        <MenuItem key={setting} onClick={handleCloseUserMenu}>
                            <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                    ))}
                    <MenuItem key='Reload' onClick={_ => { reloadPage() ; handleCloseUserMenu() }}>
                        <Typography textAlign="center">Reload</Typography>
                    </MenuItem>
                    <LogoutProvider>
                        <MenuItem key='Logout' onClick={handleCloseUserMenu}>
                            <Typography textAlign="center">Logout</Typography>
                        </MenuItem>
                    </LogoutProvider>
                </Menu>
            </>
        )
    }, [loginstore.user, loginstore.image, settings, anchorElUser])

    let logo = dea_logo
    if (window.location.host.match(/\.esao\.aero(:\d+)?$/)) {
        // logo = esao_logo
    }

    return <>
        <AppBar elevation={10} sx={{mb: 1, position:'absolute', top:0, left:0, right:0, zIndex:100}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img height={50} width={100} src={logo} alt="logo" onClick={() => navigate('/')}/>
                    <Box sx={{flexGrow: 1}}/>
                    <Box sx={{display: {xs: 'none', lg: 'flex'}}}>
                        {siteLinks}
                    </Box>
                    <Box sx={{display: {xs: 'flex', lg: 'none'}}}>
                        {burgerLinks}
                    </Box>
                    <Box sx={{flexGrow: 1}}/>
                    <Box>
                        {userMenu}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    </>
}
