import React, {useState, useEffect} from "react";
import {useApiCallback} from "../utils/Api";
import TextField from "@mui/material/TextField";
import {Button} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";


const buttonStyle = {
    textTransform: 'none',
    margin: '1px'
}


export default function Admins() {
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [username, setUsername] = useState('')
    const fetchUsers = useApiCallback('GET', `/api/admins`, {setLoading, onDone: setUsers})
    const addUser = useApiCallback('POST', `/api/admin`, {
        data: {user: username},
        setLoading,
        onDone: fetchUsers
    })
    const removeUser = useApiCallback('DELETE', `/api/admin`, {
        data: {user: username},
        setLoading,
        onDone: fetchUsers
    })
    const [config, setConfig] = useState(null)
    const fetchConfig = useApiCallback('POST', `/api/config/fetch`, {setLoading, onDone: setConfig})
    const getConfig = useApiCallback('GET', `/api/config/safe`, {setLoading, onDone: setConfig})
    useEffect(getConfig, [])

    useEffect(fetchUsers, [])

    return <Stack justifyContent="center" alignItems="start" >
        <Stack direction="row" alignItems="center">
            <TextField
                label="Name"
                InputLabelProps={{shrink: true}}
                value={username}
                onChange={(event) => {
                    setUsername(event.target.value)
                }}
                sx={{width: '300px', margin: 2}}
            />
            <Button disabled={!username} onClick={() => addUser()}>Add</Button>
            <Button disabled={!username} onClick={() => removeUser()}>Delete</Button>
        </Stack>
        <br/>
        {users.map(k => <Button key={k} sx={buttonStyle} onClick={() => setUsername(k)}>{k}</Button>)}
        <br/>
        <br/>
        <Typography>This runs every 10 minutes, but can be handy to run this after changing a parameter store variable
            for immediate effect.</Typography>
        <Button disabled={loading} onClick={() => fetchConfig()} color="warning">Re-fetch Config from Parameter Store</Button>
        <pre style={{fontSize:'0.9em', backgroundColor:'#222', borderRadius:'10px', border:'1px solid #666', padding:3}}>
            {config && JSON.stringify(config, null, 4)}
        </pre>
    </Stack>
}
