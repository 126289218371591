import {useState, useEffect, useCallback} from "react"
import {useApiCallback} from "../utils/Api"
import 'mapbox-gl/dist/mapbox-gl.css'
import {useGarminStore} from "../Garmin";
import {timeSince} from "../utils/time";
import {DataGridPro} from '@mui/x-data-grid-pro'
import useStatsStore from "../utils/useStatsStore"
import Link from "@mui/material/Link";
import {isValidLatLng} from "../utils/Mappery"
import NearestAirport from "./NearestAirport";


export default function GarminGrid({navClick=null}) {
    const [loading, setLoading] = useState(false)
    const {servertime} = useStatsStore()
    const garminStore = useGarminStore()
    const [garminEvents, latestEvents] = [garminStore.events, garminStore.latestEvents]
    const [tableData, setTableData] = useState([])
    const [devices, setDevices] = useState([])

    const fetchDevices = useApiCallback('GET', `/api/garmin/devices`, {setLoading, onDone: setDevices})
    useEffect(fetchDevices, [])

    const getLatestGarminPosition = (row) => {
        const {latitude, longitude, imei} = row
        let lat = latestEvents[imei]?.event?.point?.latitude || latitude || null
        let lng = latestEvents[imei]?.event?.point?.longitude || longitude || null
        if (lat === null || lng === null) return [null, null]
        lat = lat.toFixed(5)
        lng = lng.toFixed(5)
        if (isValidLatLng(lat, lng)) {
            return [lat, lng]
        }
        const found = garminEvents.findLast(ev => {
            if (ev.imei !== imei)
                return false
            let lat = ev?.event?.point?.latitude
            let lng = ev.event?.point?.longitude
            lat = lat.toFixed(5)
            lng = lng.toFixed(5)
            return isValidLatLng(lat, lng) ? [lat, lng] : false
        })
        return found || [null, null]
    }

    const nearestAirport = useCallback(params => {
        let [lat, lng] = getLatestGarminPosition(params.row)
        return <NearestAirport lat={lat} lng={lng}/>
    }, [])

    useEffect( () => {
        console.log('devices', devices)
        const _new = devices.map(device => {
            return {id: device.imei, ...device}
        })
        setTableData(_new)
    }, [devices, setTableData])

    const renderAt = (params) => timeSince(servertime, params.value)
    const linkStyle = {
        color: 'white',
        cursor: 'pointer',
        fontWeight: 'normal'
    }
    const renderPos = useCallback((params) => {
        let [lat, lng] = getLatestGarminPosition(params.row)
        return <Link key={params.row.imei} sx={{...linkStyle}} onClick={() => navClick(lat, lng)}>{lat} {lng}</Link>
    })

    const getColumnDefinition = useCallback(() => {
        return [
            {field: 'imei', headerName: 'IMEI', flex: 1},
            {field: 'position', headerName: 'Position', flex: 1, renderCell: renderPos},
            {field: 'lastseen', headerName: 'Last Seen', flex: 1, renderCell: renderAt},
            {field: 'firstseen', headerName: 'First Seen', flex: 1, renderCell: renderAt},
            {field: 'airport', headerName: 'Nearest Airport', flex: 1, renderCell: nearestAirport},
            {field: 'timesseen', headerName: 'Message Count', flex: 1},
        ]
    })

    return <DataGridPro
        hideFooter={true}
        loading={loading}
        rows={tableData}
        disableMultipleRowSelection={true}
        columns={getColumnDefinition()}
    />
}
