import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import create from 'zustand'

export const useToastStore = create((set) => ({
    text: '',
    severity: '',
    autohide: 6000,
    clear: () => set({ text:'', severity:'', autoHide:6000 }),
    showSuccess: (text, autoHide=6000) => set({ text, severity: 'success', autoHide }),
    showInfo: (text, autoHide=6000) => set({ text, severity: 'info', autoHide }),
    showWarning: (text, autoHide=6000) => set({ text, severity: 'warning', autoHide }),
    showError: (text, autoHide=6000) => set({ text, severity: 'error', autoHide }),
}))

export default function Toast() {
    const msg = useToastStore()

    return (
        <Stack spacing={2} sx={{width: '100%'}}>
            <Snackbar open={!!msg.text} autoHideDuration={msg.autoHide} onClose={msg.clear}>
                <Alert onClose={msg.clear} severity={msg.severity || 'error'} sx={{width: '100%'}}>
                    {msg.text}
                </Alert>
            </Snackbar>
        </Stack>
    )
}
