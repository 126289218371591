import useWebSocket, {ReadyState} from 'react-use-websocket';
import {getServer} from "./utils/server";
import {useEffect, useState} from "react";
import useStatsStore from './utils/useStatsStore'
import useConfigStore from './utils/useConfigStore'
import {useLoginStore} from "./Login";
import {useGarminStore} from "./Garmin";

const srv = getServer().replace('https://', '')
const socketUrl = `wss://${srv}/ws/status`

export default function WebSock() {
    const statsStore = useStatsStore()
    const configStore = useConfigStore()
    const loginstore = useLoginStore()
    const garminStore = useGarminStore()
    const {sendMessage, lastMessage, readyState} = useWebSocket(socketUrl, {
        shouldReconnect: (closeEvent) => {
            console.log('ws CLOSED', closeEvent)
            return true
        },
    });

    useEffect(() => {
      const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
      }[readyState]
        console.log('ws connection state changed', connectionStatus)
    }, [readyState])

    useEffect(() => {
        if (lastMessage === null) return

        const data = JSON.parse(lastMessage.data)
        if (typeof data.stats === 'object') {
            const updateStats = {...statsStore.stats}
            const updateLastSeen = {}
            Object.keys(data.stats).forEach(identity => {
                const modules = Object.keys(data.stats[identity])
                modules.forEach(module => {
                    const md = data.stats[identity][module]
                    if (typeof updateStats[identity] !== 'object') {
                        updateStats[identity] = {}
                    }
                    updateStats[identity][module] = md
                })
                updateLastSeen[identity] = statsStore.servertime
            })
            statsStore.setLastSeen({...statsStore.lastseen, ...updateLastSeen})
            statsStore.setStats(updateStats)
        }

        if (typeof data.assignments === 'object') {
            console.log('ws setting assignments')
            statsStore.setAssignments(data.assignments)
        }

        if (typeof data.identity === 'object') {
            console.log('ws setting identity')
            loginstore.setImage(data.identity.image)
        }

        if (typeof data.garmin === 'object') {
            console.log('ws garmin message received')
            garminStore.addEvent(data.garmin)
        }

        if (typeof data.streams === 'object') {
            console.log('ws setting streams')
            configStore.setStreams(data.streams)
        }

        if (typeof data.permissions === 'object') {
            console.log('ws setting user permissions')
            loginstore.setPermissions(data.permissions)
        }

        if (typeof data.servertime === 'number') {
            console.log('ws setting servertime')
            statsStore.setServertime(data.servertime)
        }

    }, [lastMessage]);

    return (
        <></>
    );
}