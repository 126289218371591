import create from 'zustand'

export const useGarminStore = create((set, get) => ({
    events: [],
    latestEvents: {},
    addEvent: (event) => {
        const {events, latestEvents} = get()

        const cur = latestEvents[event.imei] || null

        if (cur && cur.at >= event.at) {
            // the event is older than the latest event for this imei, so we ignore it.
            // it's not worth the processing effort to add it into the correct place in the array.
            return
        }

        let found = false
        events.forEach(e => {
            found = found || (e.imei === event.imei && e.at === event.at)
        })
        if (!found) {
            set({events: [...events, event]})
        }

        if (!cur || cur.at < event.at) {
            const newCurPos = {...latestEvents}
            newCurPos[event.imei] = event
            set({latestEvents: newCurPos})
        }
    },
    setEvents: (events) => {
        set({events})
        const latest = {}
        events.forEach(e => {
            const dd = latest[e.imei] || null
            if (dd && dd.at > e.at) return
            latest[e.imei] = e
        })
        set({latestEvents: latest})
        console.log('LATEST', latest)
    },
}))


export const SampleEvents = () => {
    return [
        {
            "at": 1695720900,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2790.2837,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 35.15374,
                    "longitude": 17.83935,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695720900000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695720990,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 497.66385,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 45.07564,
                    "longitude": 13.22451,
                    "speed": 220.854
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695720990000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695721020,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2922.873,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 35.17887,
                    "longitude": 17.72818,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721020000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695721110,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 497.66385,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 45.03148,
                    "longitude": 13.2016,
                    "speed": 200.356
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721110000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695721140,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2937.7695,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 35.20243,
                    "longitude": 17.61535,
                    "speed": 313.347
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721140000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695721155,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 18.578434,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.49978,
                    "longitude": 12.6164,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721155000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695721230,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 493.44843,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 44.96648,
                    "longitude": 13.24224,
                    "speed": 256.87
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721230000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695721260,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2934.7876,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.25361,
                    "longitude": 17.52339,
                    "speed": 313.347
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721260000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695721350,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 493.44843,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 44.89592,
                    "longitude": 13.2865,
                    "speed": 256.87
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721350000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695721380,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2937.7695,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 35.30372,
                    "longitude": 17.42841,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721380000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695721485,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 493.44843,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 44.82488,
                    "longitude": 13.33112,
                    "speed": 252.144
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721485000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695721500,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2943.74,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 35.29963,
                    "longitude": 17.3245,
                    "speed": 341.864
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695721500000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695721605,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 493.44843,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 44.75511,
                    "longitude": 13.37461,
                    "speed": 261.663
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721605000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695721620,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2943.74,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.20743,
                    "longitude": 17.32085,
                    "speed": 341.864
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695721620000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695721725,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 491.3414,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 44.68346,
                    "longitude": 13.41933,
                    "speed": 256.87
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721725000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695721755,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 18.578434,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.49978,
                    "longitude": 12.6164,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721755000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695721755,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2937.7695,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.22422,
                    "longitude": 17.40543,
                    "speed": 307.862
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721755000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695721845,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 489.23477,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 44.61325,
                    "longitude": 13.46297,
                    "speed": 266.523
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721845000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695721875,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2937.7695,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.31426,
                    "longitude": 17.3702,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721875000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695721920,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 11,
                "point": {
                    "altitude": 18.578434,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 35.49906,
                    "longitude": 12.61572,
                    "speed": 11.128
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 60,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721920000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695721965,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 482.91757,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 44.53952,
                    "longitude": 13.50851,
                    "speed": 261.663
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721965000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695721980,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 11,
                "point": {
                    "altitude": 20.608532,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.49866,
                    "longitude": 12.61589,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 600,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721980000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695721995,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2937.7695,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.40545,
                    "longitude": 17.33186,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695721995000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695722085,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 55.156635,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28014,
                    "longitude": -0.94914,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722085000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695722085,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 485.0229,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 44.46611,
                    "longitude": 13.55404,
                    "speed": 276.443
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722085000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695722115,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2937.7695,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.49742,
                    "longitude": 17.29267,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722115000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695722190,
            "event": {
                "addresses": [
                    {
                        "address": "operations@dea.aero"
                    }
                ],
                "freeText": "WKT05 TAKING OFF",
                "imei": "300434036091210",
                "messageCode": 3,
                "point": {
                    "altitude": -422.0,
                    "course": 0.0,
                    "gpsFix": 0,
                    "latitude": 0.0,
                    "longitude": 0.0,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722190000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695722205,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 51.08851,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28015,
                    "longitude": -0.94916,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722205000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695722205,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 476.6043,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 44.38853,
                    "longitude": 13.60189,
                    "speed": 261.663
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722205000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695722235,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2940.7527,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.58807,
                    "longitude": 17.25384,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722235000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695722325,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 51.09,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28016,
                    "longitude": -0.9492,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 1,
                    "resetDetected": 0
                },
                "timeStamp": 1695722325000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695722325,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 478.70828,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 44.31622,
                    "longitude": 13.64629,
                    "speed": 266.523
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722325000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695722355,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2937.7695,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.67894,
                    "longitude": 17.21489,
                    "speed": 313.347
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722355000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695722445,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 51.09,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28017,
                    "longitude": -0.9492,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 1,
                    "resetDetected": 0
                },
                "timeStamp": 1695722445000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695722445,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 480.8127,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 44.244,
                    "longitude": 13.69051,
                    "speed": 261.663
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722445000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695722475,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2931.807,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.76795,
                    "longitude": 17.17654,
                    "speed": 307.862
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722475000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695722565,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 44.171,
                    "longitude": 13.73518,
                    "speed": 266.523
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722565000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695722580,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 20.608532,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.49867,
                    "longitude": 12.61589,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722580000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695722595,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2931.807,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.85674,
                    "longitude": 17.14618,
                    "speed": 313.347
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722595000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695722640,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 11,
                "point": {
                    "altitude": 22.638865,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 35.4976,
                    "longitude": 12.61501,
                    "speed": 43.291
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 60,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722640000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695722685,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 480.8127,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 44.09517,
                    "longitude": 13.77126,
                    "speed": 271.449
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722685000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695722700,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 20.608532,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 35.49683,
                    "longitude": 12.60788,
                    "speed": 5.012
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722700000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695722700,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 44.99,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28009,
                    "longitude": -0.94899,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 1,
                    "resetDetected": 0
                },
                "timeStamp": 1695722700000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695722715,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2940.7527,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.94631,
                    "longitude": 17.13953,
                    "speed": 291.838
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722715000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695722760,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 40.922497,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 35.49981,
                    "longitude": 12.62921,
                    "speed": 242.885
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722760000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695722805,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 476.6043,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 44.0137,
                    "longitude": 13.78988,
                    "speed": 266.523
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722805000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695722820,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 584.4792,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 35.5112,
                    "longitude": 12.67327,
                    "speed": 247.482
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722820000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695722820,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 40.92,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 53.28022,
                    "longitude": -0.94924,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 1,
                    "resetDetected": 0
                },
                "timeStamp": 1695722820000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695722835,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2934.7876,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 36.03595,
                    "longitude": 17.12816,
                    "speed": 302.45
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722835000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695722880,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 592.991,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 35.53947,
                    "longitude": 12.65494,
                    "speed": 229.478
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722880000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695722940,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 40.92,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 53.2802,
                    "longitude": -0.94922,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 1,
                    "resetDetected": 0
                },
                "timeStamp": 1695722940000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695722940,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 480.8127,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 43.9406,
                    "longitude": 13.80501,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722940000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695722940,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 597.2498,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.56853,
                    "longitude": 12.62722,
                    "speed": 233.884
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722940000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695722955,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2934.7876,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 36.1288,
                    "longitude": 17.12026,
                    "speed": 313.347
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695722955000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695723000,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 449.2914,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.59968,
                    "longitude": 12.60806,
                    "speed": 233.884
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723000000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695723060,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 487.1286,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 43.87147,
                    "longitude": 13.82713,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723060000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695723060,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.63074,
                    "longitude": 12.58765,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723060000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695723075,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 38.89,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28018,
                    "longitude": -0.9492,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 1,
                    "resetDetected": 0
                },
                "timeStamp": 1695723075000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695723075,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2937.7695,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 36.22202,
                    "longitude": 17.12659,
                    "speed": 307.862
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723075000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695723120,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 482.91757,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.66248,
                    "longitude": 12.56767,
                    "speed": 233.884
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723120000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695723180,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 482.91757,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 43.82806,
                    "longitude": 13.87041,
                    "speed": 208.375
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723180000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695723180,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 476.6043,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.69736,
                    "longitude": 12.55482,
                    "speed": 247.482
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723180000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695723195,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 42.96,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 53.28016,
                    "longitude": -0.94905,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 1,
                    "resetDetected": 0
                },
                "timeStamp": 1695723195000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695723195,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2940.7527,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 36.30913,
                    "longitude": 17.15824,
                    "speed": 297.109
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723195000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695723240,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.73318,
                    "longitude": 12.54304,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723240000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695723300,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 478.70828,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.76789,
                    "longitude": 12.52999,
                    "speed": 229.478
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723300000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695723300,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 482.91757,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 43.85427,
                    "longitude": 13.82127,
                    "speed": 216.634
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723300000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695723315,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 32.79,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 53.2801,
                    "longitude": -0.94912,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 1,
                    "resetDetected": 0
                },
                "timeStamp": 1695723315000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695723315,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2937.7695,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 36.39056,
                    "longitude": 17.15732,
                    "speed": 291.838
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723315000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695723360,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 472.3976,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.79209,
                    "longitude": 12.55126,
                    "speed": 291.838
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723360000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695723420,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 482.91757,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 43.83735,
                    "longitude": 13.79325,
                    "speed": 220.854
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723420000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695723420,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 35.75778,
                    "longitude": 12.58377,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723420000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695723435,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2893.18,
                    "course": 225.0,
                    "gpsFix": 2,
                    "latitude": 36.40113,
                    "longitude": 17.04891,
                    "speed": 353.794
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695723435000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695723480,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.74672,
                    "longitude": 12.54707,
                    "speed": 225.135
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723480000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695723540,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 35.76218,
                    "longitude": 12.57619,
                    "speed": 302.45
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723540000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695723540,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 485.0229,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 43.84471,
                    "longitude": 13.85977,
                    "speed": 192.569
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723540000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695723555,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 55.16,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 53.28028,
                    "longitude": -0.94933,
                    "speed": 2.001
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 1,
                    "resetDetected": 0
                },
                "timeStamp": 1695723555000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695723555,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2180.67,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 36.31006,
                    "longitude": 17.06226,
                    "speed": 330.236
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695723555000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695723600,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 478.70828,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 35.72254,
                    "longitude": 12.59709,
                    "speed": 286.637
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723600000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695723660,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 482.91757,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 43.86367,
                    "longitude": 13.78052,
                    "speed": 229.478
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723660000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695723660,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 487.1286,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.73771,
                    "longitude": 12.62496,
                    "speed": 220.854
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723660000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695723675,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 73.48,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 53.28042,
                    "longitude": -0.9495,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 1,
                    "resetDetected": 0
                },
                "timeStamp": 1695723675000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695723675,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 1694.3945,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 36.32831,
                    "longitude": 17.16107,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723675000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695723720,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 510.3207,
                    "course": 225.0,
                    "gpsFix": 2,
                    "latitude": 35.7558,
                    "longitude": 12.60535,
                    "speed": 281.506
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723720000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695723780,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 482.91757,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 43.82693,
                    "longitude": 13.82829,
                    "speed": 225.135
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723780000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695723795,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 1495.1188,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 36.40753,
                    "longitude": 17.13028,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723795000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695723795,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 432.51895,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 35.71174,
                    "longitude": 12.58932,
                    "speed": 313.347
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723795000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695723840,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 28.73,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 53.28011,
                    "longitude": -0.94912,
                    "speed": 2.001
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 1,
                    "resetDetected": 0
                },
                "timeStamp": 1695723840000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695723855,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 288.90616,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 35.66808,
                    "longitude": 12.60252,
                    "speed": 281.506
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723855000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695723900,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 485.0229,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 43.85636,
                    "longitude": 13.84565,
                    "speed": 216.634
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723900000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695723915,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 190.00586,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.64611,
                    "longitude": 12.64269,
                    "speed": 276.443
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723915000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695723915,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2350.2717,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 36.41717,
                    "longitude": 17.0145,
                    "speed": 297.109
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723915000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695723960,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 51.09,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 53.28008,
                    "longitude": -0.94867,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 1,
                    "resetDetected": 0
                },
                "timeStamp": 1695723960000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695723975,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 167.4448,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.65068,
                    "longitude": 12.67691,
                    "speed": 204.336
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695723975000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695724020,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 485.0229,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 43.85495,
                    "longitude": 13.77033,
                    "speed": 247.482
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724020000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695724035,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 3046.01,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 36.42655,
                    "longitude": 16.89551,
                    "speed": 359.873
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695724035000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695724035,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 51.08851,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 35.66807,
                    "longitude": 12.64168,
                    "speed": 212.475
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724035000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695724095,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 26.70023,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 35.6881,
                    "longitude": 12.61057,
                    "speed": 220.854
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724095000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695724140,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 482.91757,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 43.79828,
                    "longitude": 13.83325,
                    "speed": 247.482
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724140000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695724155,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 136.7376,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 35.71677,
                    "longitude": 12.59615,
                    "speed": 266.523
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724155000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695724155,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2961.67,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 36.43673,
                    "longitude": 16.7676,
                    "speed": 341.864
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695724155000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695724215,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 338.65363,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 35.75596,
                    "longitude": 12.61552,
                    "speed": 302.45
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724215000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695724260,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 478.70828,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 43.74372,
                    "longitude": 13.89637,
                    "speed": 229.478
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724260000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695724275,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2946.72,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 36.44667,
                    "longitude": 16.641,
                    "speed": 336.013
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695724275000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695724275,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 369.8541,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 35.79301,
                    "longitude": 12.63183,
                    "speed": 233.884
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724275000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695724335,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 371.9372,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 35.79791,
                    "longitude": 12.60542,
                    "speed": 281.506
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724335000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695724350,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 51.09,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 53.2803,
                    "longitude": -0.94942,
                    "speed": 2.001
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 1,
                    "resetDetected": 0
                },
                "timeStamp": 1695724350000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695724380,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 478.70828,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 43.68823,
                    "longitude": 13.96036,
                    "speed": 242.885
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724380000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695724395,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2949.71,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 36.45635,
                    "longitude": 16.5161,
                    "speed": 336.013
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695724395000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695724395,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 386.52972,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 35.77495,
                    "longitude": 12.63247,
                    "speed": 229.478
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724395000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695724455,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 382.35846,
                    "course": 225.0,
                    "gpsFix": 2,
                    "latitude": 35.78991,
                    "longitude": 12.61941,
                    "speed": 242.885
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724455000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695724470,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 40.922497,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28026,
                    "longitude": -0.94929,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724470000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695724500,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 478.70828,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 43.63412,
                    "longitude": 14.02255,
                    "speed": 233.884
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724500000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695724515,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2946.72,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 36.46607,
                    "longitude": 16.38909,
                    "speed": 347.791
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695724515000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695724515,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 380.27344,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.76105,
                    "longitude": 12.63237,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724515000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695724575,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 390.70255,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 35.78665,
                    "longitude": 12.64784,
                    "speed": 204.336
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724575000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695724620,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 476.6043,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 43.58003,
                    "longitude": 14.0846,
                    "speed": 233.884
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724620000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695724635,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2946.72,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 36.4757,
                    "longitude": 16.26041,
                    "speed": 341.864
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695724635000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695724635,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 392.78955,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.79988,
                    "longitude": 12.62269,
                    "speed": 229.478
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724635000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695724695,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 384.4439,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 35.76922,
                    "longitude": 12.59799,
                    "speed": 261.663
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724695000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695724740,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 476.6043,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 43.52177,
                    "longitude": 14.14217,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724740000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695724755,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2943.74,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 36.48506,
                    "longitude": 16.13387,
                    "speed": 336.013
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695724755000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695724755,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 378.18878,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 35.73185,
                    "longitude": 12.58214,
                    "speed": 281.506
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724755000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695724815,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 365.6891,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 35.69153,
                    "longitude": 12.59381,
                    "speed": 286.637
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724815000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695724860,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 472.3976,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 43.46221,
                    "longitude": 14.1982,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724860000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695724875,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 227.00494,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.65644,
                    "longitude": 12.61889,
                    "speed": 271.449
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724875000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695724890,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2764.08,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 36.49441,
                    "longitude": 16.00534,
                    "speed": 359.873
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695724890000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695724935,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 165.39558,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 35.6459,
                    "longitude": 12.66307,
                    "speed": 220.854
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724935000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695724980,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 43.40446,
                    "longitude": 14.25234,
                    "speed": 229.478
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724980000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695724995,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 114.25987,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 35.66799,
                    "longitude": 12.64089,
                    "speed": 200.356
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695724995000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695725010,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2159.44,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 36.53449,
                    "longitude": 15.89449,
                    "speed": 341.864
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695725010000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695725055,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 57.19107,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 35.69,
                    "longitude": 12.61213,
                    "speed": 212.475
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725055000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695725100,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 472.3976,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 43.34557,
                    "longitude": 14.3075,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725100000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695725115,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 155.15404,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.71661,
                    "longitude": 12.59492,
                    "speed": 212.475
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725115000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695725130,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 1511.9019,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 36.61368,
                    "longitude": 15.81804,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725130000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695725190,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 305.46555,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 35.70109,
                    "longitude": 12.5901,
                    "speed": 291.838
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725190000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695725220,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 43.28652,
                    "longitude": 14.36256,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725220000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695725250,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 708.67914,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 36.6909,
                    "longitude": 15.74377,
                    "speed": 313.347
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725250000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695725250,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 190.00586,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.66751,
                    "longitude": 12.61756,
                    "speed": 261.663
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725250000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695725310,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 146.96606,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.66379,
                    "longitude": 12.66494,
                    "speed": 242.885
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725310000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695725340,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 43.22815,
                    "longitude": 14.41683,
                    "speed": 229.478
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725340000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695725370,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 791.00836,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 36.767,
                    "longitude": 15.67056,
                    "speed": 307.862
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725370000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695725370,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 97.93338,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 35.68011,
                    "longitude": 12.64917,
                    "speed": 216.634
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725370000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695725430,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 38.890022,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 35.69681,
                    "longitude": 12.61791,
                    "speed": 212.475
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725430000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695725460,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 43.17127,
                    "longitude": 14.46981,
                    "speed": 229.478
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725460000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695725490,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 804.081,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 36.84209,
                    "longitude": 15.59799,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725490000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695725490,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 138.78271,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 35.713,
                    "longitude": 12.5849,
                    "speed": 196.434
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725490000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695725550,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 35.69553,
                    "longitude": 12.55493,
                    "speed": 297.109
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725550000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695725580,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 43.11296,
                    "longitude": 14.52378,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725580000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695725610,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 806.2617,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 36.91967,
                    "longitude": 15.52295,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725610000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695725610,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 508.21,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 35.64797,
                    "longitude": 12.56518,
                    "speed": 330.236
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695725610000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695725685,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 449.2914,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 35.60116,
                    "longitude": 12.58374,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725685000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695725700,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 43.0535,
                    "longitude": 14.57875,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725700000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695725730,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 801.90076,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 36.99702,
                    "longitude": 15.44787,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725730000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695725745,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 463.99,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 35.55489,
                    "longitude": 12.60303,
                    "speed": 336.013
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695725745000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695725805,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 466.09,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 35.50801,
                    "longitude": 12.6172,
                    "speed": 341.864
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695725805000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695725820,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 468.19266,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 42.99506,
                    "longitude": 14.63261,
                    "speed": 225.135
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725820000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695725850,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 799.7211,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 37.07331,
                    "longitude": 15.37356,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725850000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695725865,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 297.183,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.47043,
                    "longitude": 12.60402,
                    "speed": 242.885
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725865000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695725940,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 468.19266,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 42.93865,
                    "longitude": 14.68458,
                    "speed": 233.884
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725940000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695725940,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 206.43726,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 35.49086,
                    "longitude": 12.58141,
                    "speed": 216.634
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725940000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695725970,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 801.90076,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 37.15127,
                    "longitude": 15.29758,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695725970000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695726000,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 83.661766,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.49786,
                    "longitude": 12.61745,
                    "speed": 80.83
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726000000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695726060,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 32.794052,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.49966,
                    "longitude": 12.61889,
                    "speed": 16.395
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726060000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695726075,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 468.19266,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 42.87844,
                    "longitude": 14.73983,
                    "speed": 242.885
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726075000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695726090,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 801.90076,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 37.21667,
                    "longitude": 15.22922,
                    "speed": 271.449
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726090000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695726090,
            "event": {
                "addresses": [
                    {
                        "address": "operations@dea.aero"
                    }
                ],
                "freeText": "WKT05 LANDED",
                "imei": "300434036091210",
                "messageCode": 3,
                "point": {
                    "altitude": -422.0,
                    "course": 0.0,
                    "gpsFix": 0,
                    "latitude": 0.0,
                    "longitude": 0.0,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726090000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695726120,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 22.638865,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 35.49977,
                    "longitude": 12.61638,
                    "speed": 6.021
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726120000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695726180,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 11,
                "point": {
                    "altitude": 22.638865,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.49977,
                    "longitude": 12.61642,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 600,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726180000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695726195,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 468.19266,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 42.80805,
                    "longitude": 14.76073,
                    "speed": 242.885
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726195000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695726210,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 799.72,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 37.24417,
                    "longitude": 15.10742,
                    "speed": 347.791
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695726210000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695726315,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 42.73626,
                    "longitude": 14.77809,
                    "speed": 247.482
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726315000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695726330,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 495.5559,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 37.30646,
                    "longitude": 15.01031,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726330000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695726435,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 42.66416,
                    "longitude": 14.77831,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726435000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695726450,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 235.24104,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 37.38892,
                    "longitude": 15.00157,
                    "speed": 233.884
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726450000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695726555,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 42.65659,
                    "longitude": 14.79056,
                    "speed": 174.096
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726555000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695726570,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 16.548569,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 37.40093,
                    "longitude": 14.92913,
                    "speed": 141.157
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726570000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695726600,
            "event": {
                "addresses": [
                    {
                        "address": "OPERATIONS@DEA.AERO"
                    }
                ],
                "freeText": "G-RTNA ARRIVAL",
                "imei": "300434037104160",
                "messageCode": 15,
                "point": {
                    "altitude": 19.0,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 37.40111,
                    "longitude": 14.92669,
                    "speed": 41.843
                },
                "status": {
                    "autonomous": 1,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695726600000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695726675,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 42.67663,
                    "longitude": 14.73054,
                    "speed": 225.135
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726675000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695726690,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 28.731268,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 37.40025,
                    "longitude": 14.9339,
                    "speed": 43.291
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726690000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695726780,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 22.638865,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.49977,
                    "longitude": 12.61642,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726780000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695726795,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 472.3976,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 42.62519,
                    "longitude": 14.78543,
                    "speed": 208.375
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726795000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695726915,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 472.3976,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 42.66635,
                    "longitude": 14.76977,
                    "speed": 170.567
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695726915000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695727035,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 42.70679,
                    "longitude": 14.72282,
                    "speed": 181.318
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695727035000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695727155,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 476.6043,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 42.74433,
                    "longitude": 14.75567,
                    "speed": 204.336
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695727155000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695727275,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 42.77483,
                    "longitude": 14.83815,
                    "speed": 233.884
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695727275000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695727380,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 26.70023,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.49978,
                    "longitude": 12.61642,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695727380000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695727395,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 472.3976,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 42.80648,
                    "longitude": 14.92411,
                    "speed": 233.884
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695727395000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695727515,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 42.83795,
                    "longitude": 15.00962,
                    "speed": 233.884
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695727515000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695727635,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 42.86907,
                    "longitude": 15.09468,
                    "speed": 233.884
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695727635000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695727755,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 463.98938,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 42.90837,
                    "longitude": 15.15813,
                    "speed": 247.482
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695727755000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695727875,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 466.09082,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 42.846,
                    "longitude": 15.21924,
                    "speed": 256.87
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695727875000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695727980,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 26.70023,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.49976,
                    "longitude": 12.61645,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695727980000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695727995,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 459.78784,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 42.84101,
                    "longitude": 15.24763,
                    "speed": 220.854
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695727995000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695728115,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 472.3976,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 42.8805,
                    "longitude": 15.1739,
                    "speed": 225.135
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695728115000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695728235,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 466.09082,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 42.8386,
                    "longitude": 15.188,
                    "speed": 256.87
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695728235000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695728355,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 459.78784,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 42.87824,
                    "longitude": 15.18774,
                    "speed": 220.854
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695728355000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695728475,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 463.98938,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 42.86945,
                    "longitude": 15.16255,
                    "speed": 256.87
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695728475000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695728580,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 26.70023,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.49976,
                    "longitude": 12.61645,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695728580000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695728595,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 459.78784,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 42.8884,
                    "longitude": 15.17834,
                    "speed": 220.854
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695728595000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695728715,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 459.78784,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 42.93446,
                    "longitude": 15.11212,
                    "speed": 220.854
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695728715000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695728835,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 468.19266,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 42.89185,
                    "longitude": 15.12051,
                    "speed": 256.87
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695728835000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695728880,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 11,
                "point": {
                    "altitude": 26.70023,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.49788,
                    "longitude": 12.61677,
                    "speed": 21.893
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 60,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695728880000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695728940,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 30.76254,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.49855,
                    "longitude": 12.62243,
                    "speed": 32.604
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695728940000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695728955,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 463.98938,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 42.82985,
                    "longitude": 15.18585,
                    "speed": 266.523
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695728955000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695728955,
            "event": {
                "addresses": [
                    {
                        "address": "operations@dea.aero"
                    }
                ],
                "freeText": "WKT05 TAKING OFF",
                "imei": "300434036091210",
                "messageCode": 3,
                "point": {
                    "altitude": -422.0,
                    "course": 0.0,
                    "gpsFix": 0,
                    "latitude": 0.0,
                    "longitude": 0.0,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695728955000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695729000,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 28.731268,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 35.49938,
                    "longitude": 12.62844,
                    "speed": 19.661
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695729000000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695729075,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 11,
                "point": {
                    "altitude": 20.608532,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.49942,
                    "longitude": 12.62842,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 600,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695729075000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695729090,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 459.78784,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 42.86731,
                    "longitude": 15.20819,
                    "speed": 220.854
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695729090000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695729135,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 11,
                "point": {
                    "altitude": 227.00494,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.49285,
                    "longitude": 12.59346,
                    "speed": 281.506
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 60,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695729135000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695729195,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 624.98,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.46044,
                    "longitude": 12.61295,
                    "speed": 336.013
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695729195000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695729210,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 459.78784,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 42.9125,
                    "longitude": 15.14223,
                    "speed": 225.135
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695729210000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695729255,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 648.51,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.44086,
                    "longitude": 12.67766,
                    "speed": 397.984
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695729255000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695729315,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 631.39,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.4169,
                    "longitude": 12.74354,
                    "speed": 391.434
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695729315000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695729330,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 463.98938,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 42.86758,
                    "longitude": 15.1703,
                    "speed": 242.885
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695729330000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695729375,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 644.23,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.39605,
                    "longitude": 12.81214,
                    "speed": 384.965
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695729375000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695729435,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 639.95,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.37302,
                    "longitude": 12.87868,
                    "speed": 397.984
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695729435000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695729450,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 457.6877,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 42.92113,
                    "longitude": 15.14023,
                    "speed": 229.478
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695729450000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695729495,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 635.67,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.35287,
                    "longitude": 12.94586,
                    "speed": 391.434
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695729495000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695729495,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 18.58,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 53.28003,
                    "longitude": -0.94903,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 1,
                    "resetDetected": 0
                },
                "timeStamp": 1695729495000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695729555,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 616.44,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.3348,
                    "longitude": 13.01438,
                    "speed": 372.263
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695729555000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695729570,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 463.98938,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 42.87709,
                    "longitude": 15.17066,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695729570000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695729615,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 6.4026947,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.27999,
                    "longitude": -0.94909,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695729615000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695729615,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 597.25,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 35.35087,
                    "longitude": 13.07905,
                    "speed": 378.574
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695729615000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695729675,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 474.5,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 35.36871,
                    "longitude": 13.14418,
                    "speed": 366.029
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695729675000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695729690,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 457.6877,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 42.92458,
                    "longitude": 15.13083,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695729690000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695729735,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 6.4,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.27999,
                    "longitude": -0.94909,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 1,
                    "resetDetected": 0
                },
                "timeStamp": 1695729735000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695729735,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 497.66,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 35.38652,
                    "longitude": 13.20675,
                    "speed": 353.794
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695729735000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695729795,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 510.32,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 35.40305,
                    "longitude": 13.26891,
                    "speed": 347.791
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695729795000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695729810,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 457.6877,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 42.8724,
                    "longitude": 15.17695,
                    "speed": 242.885
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695729810000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695729855,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 6.4026947,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.27999,
                    "longitude": -0.94907,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695729855000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695729855,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 527.22,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 35.42106,
                    "longitude": 13.3294,
                    "speed": 347.791
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695729855000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695729915,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 518.77,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 35.43963,
                    "longitude": 13.38969,
                    "speed": 341.864
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695729915000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695729930,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 455.588,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 42.92561,
                    "longitude": 15.14154,
                    "speed": 225.135
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695729930000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695729975,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 8.431412,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.27998,
                    "longitude": -0.94905,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695729975000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695729975,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 518.77,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.44817,
                    "longitude": 13.4528,
                    "speed": 372.263
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695729975000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695730035,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 489.23477,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 35.46361,
                    "longitude": 13.50711,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730035000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695730050,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 466.09082,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 42.90227,
                    "longitude": 15.12708,
                    "speed": 256.87
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730050000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695730095,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 8.431412,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.27999,
                    "longitude": -0.94903,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730095000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695730095,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 503.99,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 35.50222,
                    "longitude": 13.54363,
                    "speed": 330.236
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695730095000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695730155,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 495.56,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 35.54028,
                    "longitude": 13.5806,
                    "speed": 330.236
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695730155000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695730155,
            "event": {
                "addresses": [
                    {
                        "address": "+31628265214"
                    }
                ],
                "freeText": "Hallo poes, plop en ik zijn nog druk bezig met illegale visjes. Denk dat we Rond 1500 landen. Denk aan je Robin of lockesley xxx",
                "imei": "300434037315150",
                "messageCode": 3,
                "point": {
                    "altitude": 0.32,
                    "course": 0.0,
                    "gpsFix": 0,
                    "latitude": 0.0,
                    "longitude": 0.0,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695730155000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695730170,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 457.6877,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 42.88681,
                    "longitude": 15.20012,
                    "speed": 225.135
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730170000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695730215,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 14.52,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 53.27986,
                    "longitude": -0.94879,
                    "speed": 10.096
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 1,
                    "resetDetected": 0
                },
                "timeStamp": 1695730215000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695730215,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 499.77,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 35.58114,
                    "longitude": 13.61751,
                    "speed": 330.236
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695730215000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695730275,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 495.56,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 35.62106,
                    "longitude": 13.65399,
                    "speed": 330.236
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695730275000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695730290,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 459.78784,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 42.93325,
                    "longitude": 15.13498,
                    "speed": 225.135
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730290000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695730335,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 32.79,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 53.27971,
                    "longitude": -0.9486,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 1,
                    "resetDetected": 0
                },
                "timeStamp": 1695730335000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695730335,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 482.91757,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 35.65912,
                    "longitude": 13.69032,
                    "speed": 281.506
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730335000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695730395,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 478.70828,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 35.69359,
                    "longitude": 13.72016,
                    "speed": 261.663
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730395000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695730410,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 468.19266,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 42.94117,
                    "longitude": 15.07376,
                    "speed": 256.87
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730410000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695730455,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 489.23477,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.72684,
                    "longitude": 13.73441,
                    "speed": 229.478
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730455000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695730455,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 57.19107,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 53.28,
                    "longitude": -0.94899,
                    "speed": 2.001
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730455000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695730515,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 440.90186,
                    "course": 225.0,
                    "gpsFix": 2,
                    "latitude": 35.75003,
                    "longitude": 13.71776,
                    "speed": 271.449
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730515000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695730530,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 459.78784,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 42.96359,
                    "longitude": 15.11775,
                    "speed": 200.356
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730530000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695730575,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 51.08851,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28014,
                    "longitude": -0.94916,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730575000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695730590,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 405.31992,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 35.71038,
                    "longitude": 13.73343,
                    "speed": 313.347
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730590000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695730650,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 288.90616,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.68855,
                    "longitude": 13.78271,
                    "speed": 313.347
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730650000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695730650,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 461.8884,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 43.01959,
                    "longitude": 15.12723,
                    "speed": 177.68
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730650000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695730695,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 49.054813,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 53.28014,
                    "longitude": -0.94914,
                    "speed": 2.001
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730695000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695730710,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 136.7376,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 35.68767,
                    "longitude": 13.8349,
                    "speed": 247.482
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730710000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695730770,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 146.96606,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 35.70253,
                    "longitude": 13.80687,
                    "speed": 229.478
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730770000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695730770,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 461.8884,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 43.073,
                    "longitude": 15.13639,
                    "speed": 181.318
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730770000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695730815,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": -5.7648726,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 53.28002,
                    "longitude": -0.94905,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730815000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695730830,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 49.054813,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 35.70841,
                    "longitude": 13.76267,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730830000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695730890,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 198.21907,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 35.72192,
                    "longitude": 13.72654,
                    "speed": 225.135
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730890000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695730890,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 482.91757,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 43.12709,
                    "longitude": 15.14564,
                    "speed": 177.68
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730890000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695730935,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 4.3742046,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 53.28009,
                    "longitude": -0.94912,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730935000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695730950,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 451.38986,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 35.75594,
                    "longitude": 13.7436,
                    "speed": 247.482
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695730950000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695731010,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 409.4999,
                    "course": 225.0,
                    "gpsFix": 2,
                    "latitude": 35.74996,
                    "longitude": 13.70632,
                    "speed": 297.109
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731010000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695731010,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 480.8127,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 43.17925,
                    "longitude": 15.15465,
                    "speed": 174.096
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731010000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695731055,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 16.548569,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 53.28029,
                    "longitude": -0.94948,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731055000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695731070,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 384.4439,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 35.74906,
                    "longitude": 13.67152,
                    "speed": 271.449
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731070000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695731130,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 482.91757,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 43.23299,
                    "longitude": 15.16386,
                    "speed": 188.762
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731130000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695731145,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 413.68152,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 35.70776,
                    "longitude": 13.70969,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731145000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695731175,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 20.608532,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 53.27989,
                    "longitude": -0.94873,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731175000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695731205,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 367.7714,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 35.71994,
                    "longitude": 13.69233,
                    "speed": 281.506
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731205000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695731250,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 491.3414,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 43.2855,
                    "longitude": 15.18471,
                    "speed": 177.68
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731250000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695731265,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 351.1236,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 35.70769,
                    "longitude": 13.73568,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731265000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695731295,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 14.518934,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 53.27999,
                    "longitude": -0.94884,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731295000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695731325,
            "event": {
                "addresses": [
                    {
                        "address": "+31628265214"
                    }
                ],
                "freeText": "Hahaha komt goed, vissersboten zijn dat vangen visjes waar dat niet mag xx",
                "imei": "300434037315150",
                "messageCode": 3,
                "point": {
                    "altitude": -422.0,
                    "course": 0.0,
                    "gpsFix": 0,
                    "latitude": 0.0,
                    "longitude": 0.0,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731325000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695731325,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 264.1091,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.68202,
                    "longitude": 13.77846,
                    "speed": 291.838
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731325000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695731370,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 487.1286,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 43.33444,
                    "longitude": 15.20883,
                    "speed": 177.68
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731370000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695731385,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 173.59418,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 35.68221,
                    "longitude": 13.83142,
                    "speed": 261.663
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731385000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695731445,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 136.7376,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 35.69821,
                    "longitude": 13.80728,
                    "speed": 242.885
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731445000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695731490,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 487.1286,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 43.38363,
                    "longitude": 15.23353,
                    "speed": 177.68
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731490000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695731505,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 55.156635,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 35.70843,
                    "longitude": 13.76752,
                    "speed": 229.478
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731505000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695731520,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": -5.7648726,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 53.28018,
                    "longitude": -0.94927,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731520000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695731565,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 108.1354,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 35.70963,
                    "longitude": 13.7245,
                    "speed": 216.634
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731565000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695731610,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 489.23477,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 43.4347,
                    "longitude": 15.25907,
                    "speed": 174.096
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731610000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695731625,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 384.4439,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 35.74254,
                    "longitude": 13.71881,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731625000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695731685,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 2.3459404,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 53.28025,
                    "longitude": -0.94944,
                    "speed": 2.001
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731685000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695731700,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 342.81,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 35.70186,
                    "longitude": 13.74701,
                    "speed": 336.013
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695731700000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695731730,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 482.91757,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 43.48206,
                    "longitude": 15.28278,
                    "speed": 167.092
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731730000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695731760,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 319.97385,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.66722,
                    "longitude": 13.78119,
                    "speed": 307.862
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731760000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695731820,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 177.69528,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 35.6558,
                    "longitude": 13.83425,
                    "speed": 276.443
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731820000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695731850,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 478.70828,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 43.53429,
                    "longitude": 15.28818,
                    "speed": 177.68
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731850000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695731880,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 149.01262,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 35.67995,
                    "longitude": 13.82421,
                    "speed": 242.885
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731880000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695731910,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 0.31790158,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 53.2799,
                    "longitude": -0.94894,
                    "speed": 2.001
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731910000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695731940,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 124.472824,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 35.69094,
                    "longitude": 13.7834,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731940000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695731970,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 480.8127,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 43.58758,
                    "longitude": 15.2934,
                    "speed": 170.567
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695731970000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695732000,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 57.19107,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 35.70581,
                    "longitude": 13.74662,
                    "speed": 212.475
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732000000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695732030,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 6.4026947,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28005,
                    "longitude": -0.94909,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732030000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695732060,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 378.18878,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 35.73187,
                    "longitude": 13.73456,
                    "speed": 286.637
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732060000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695732090,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 480.8127,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 43.64233,
                    "longitude": 15.29752,
                    "speed": 188.762
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732090000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695732120,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 548.39,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.73119,
                    "longitude": 13.79482,
                    "speed": 330.236
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695732120000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695732150,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 12.489531,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.27997,
                    "longitude": -0.94886,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732150000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695732180,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 563.23,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.73229,
                    "longitude": 13.85539,
                    "speed": 330.236
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695732180000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695732210,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 482.91757,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 43.70001,
                    "longitude": 15.30136,
                    "speed": 196.434
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732210000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695732240,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 508.21,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.73397,
                    "longitude": 13.91745,
                    "speed": 341.864
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695732240000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695732285,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 12.489531,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.27997,
                    "longitude": -0.94888,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732285000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695732300,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 508.21,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.7358,
                    "longitude": 13.98184,
                    "speed": 341.864
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695732300000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695732330,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 491.3414,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 43.75761,
                    "longitude": 15.30014,
                    "speed": 192.569
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732330000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695732360,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 497.66,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.74144,
                    "longitude": 14.0486,
                    "speed": 372.263
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695732360000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695732405,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 16.548569,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 53.2799,
                    "longitude": -0.94854,
                    "speed": 2.001
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732405000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695732420,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 495.56,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.75011,
                    "longitude": 14.11758,
                    "speed": 372.263
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695732420000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695732450,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 489.23477,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 43.81462,
                    "longitude": 15.29754,
                    "speed": 174.096
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732450000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695732480,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.75767,
                    "longitude": 14.18601,
                    "speed": 372.263
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695732480000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695732540,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 501.88,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.76514,
                    "longitude": 14.25534,
                    "speed": 378.574
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695732540000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695732570,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 495.5559,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 43.868,
                    "longitude": 15.29949,
                    "speed": 174.096
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732570000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695732600,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 495.56,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 35.77648,
                    "longitude": 14.3248,
                    "speed": 384.965
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695732600000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695732660,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 474.5,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 35.79226,
                    "longitude": 14.39288,
                    "speed": 378.574
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695732660000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695732690,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 489.23477,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 43.92134,
                    "longitude": 15.31747,
                    "speed": 174.096
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732690000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695732720,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 478.71,
                    "course": 225.0,
                    "gpsFix": 2,
                    "latitude": 35.81118,
                    "longitude": 14.37941,
                    "speed": 336.013
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695732720000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695732780,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 449.29,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 35.7852,
                    "longitude": 14.4175,
                    "speed": 330.236
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695732780000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695732810,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 478.70828,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 43.9738,
                    "longitude": 15.33468,
                    "speed": 181.318
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732810000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695732840,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": -70.52857,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.2797,
                    "longitude": -0.948,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732840000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695732840,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 480.8127,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.78061,
                    "longitude": 14.4752,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732840000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695732900,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 503.99026,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 35.75007,
                    "longitude": 14.52197,
                    "speed": 313.347
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732900000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695732930,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 438.80548,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 44.02652,
                    "longitude": 15.32908,
                    "speed": 196.434
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732930000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695732960,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 468.19266,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 35.76453,
                    "longitude": 14.5648,
                    "speed": 281.506
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695732960000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695733020,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 407.40973,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 35.7959,
                    "longitude": 14.55425,
                    "speed": 233.884
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695733020000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695733050,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 161.29808,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 44.07044,
                    "longitude": 15.33005,
                    "speed": 144.222
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695733050000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695733095,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 251.72905,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 35.81808,
                    "longitude": 14.52637,
                    "speed": 188.762
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695733095000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695733140,
            "event": {
                "addresses": [
                    {
                        "address": "operations@dea.aero"
                    }
                ],
                "freeText": "9H-DGN ARRIVAL",
                "imei": "300434037315150",
                "messageCode": 15,
                "point": {
                    "altitude": 61.0,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 44.08826,
                    "longitude": 15.35221,
                    "speed": 53.108
                },
                "status": {
                    "autonomous": 1,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695733140000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695733155,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 85.69978,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 35.83719,
                    "longitude": 14.50283,
                    "speed": 167.092
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695733155000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695733170,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 69.402954,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 44.09111,
                    "longitude": 15.35567,
                    "speed": 44.72
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695733170000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695733215,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 73.47562,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 35.84805,
                    "longitude": 14.48931,
                    "speed": 91.483
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695733215000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695733275,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 79.58653,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 35.85567,
                    "longitude": 14.4806,
                    "speed": 47.646
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695733275000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695733305,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 77.5493,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 44.09394,
                    "longitude": 15.35402,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695733305000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695733335,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 79.58653,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.85826,
                    "longitude": 14.48172,
                    "speed": 24.173
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695733335000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695733395,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 77.5493,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.85981,
                    "longitude": 14.47987,
                    "speed": 26.507
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695733395000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695733455,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 79.58653,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.85913,
                    "longitude": 14.47781,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695733455000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695733515,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 77.5493,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.85947,
                    "longitude": 14.47629,
                    "speed": 13.212
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695733515000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695733575,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 11,
                "point": {
                    "altitude": 83.661766,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.85954,
                    "longitude": 14.47665,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 600,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695733575000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695733680,
            "event": {
                "addresses": [
                    {
                        "address": "operations@dea.aero"
                    }
                ],
                "freeText": "WKT05 LANDED",
                "imei": "300434036091210",
                "messageCode": 3,
                "point": {
                    "altitude": -422.0,
                    "course": 0.0,
                    "gpsFix": 0,
                    "latitude": 0.0,
                    "longitude": 0.0,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695733680000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695734175,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 77.5493,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.8595,
                    "longitude": 14.4767,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695734175000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695734775,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 77.5493,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.85949,
                    "longitude": 14.47672,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695734775000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695735375,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 77.5493,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.85948,
                    "longitude": 14.47674,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695735375000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695735975,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 157.20175,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.85897,
                    "longitude": 14.47646,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695735975000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695736575,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 79.58653,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.85949,
                    "longitude": 14.47674,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695736575000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695737175,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 85.69978,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.85949,
                    "longitude": 14.47674,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695737175000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695737775,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 102.01338,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.85955,
                    "longitude": 14.47683,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695737775000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695738375,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 114.25987,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.85953,
                    "longitude": 14.47685,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695738375000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695738975,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 83.661766,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.85948,
                    "longitude": 14.47672,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695738975000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695739575,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 83.661766,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.85948,
                    "longitude": 14.4767,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695739575000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695740175,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 87.73805,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.85901,
                    "longitude": 14.4773,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695740175000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695740235,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 11,
                "point": {
                    "altitude": 85.69978,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 35.85823,
                    "longitude": 14.47676,
                    "speed": 13.212
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 60,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695740235000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695740295,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 87.73805,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 35.85563,
                    "longitude": 14.47985,
                    "speed": 40.499
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695740295000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695740355,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 89.77659,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 35.85061,
                    "longitude": 14.48607,
                    "speed": 50.667
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695740355000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695740415,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 85.69978,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 35.84577,
                    "longitude": 14.4921,
                    "speed": 44.72
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695740415000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695740475,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 79.58653,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.84492,
                    "longitude": 14.49318,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695740475000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695740535,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 134.69276,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 35.85768,
                    "longitude": 14.47753,
                    "speed": 261.663
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695740535000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695740595,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 644.226,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 35.88475,
                    "longitude": 14.43548,
                    "speed": 297.109
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695740595000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695740655,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 1231.9999,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 35.90749,
                    "longitude": 14.38924,
                    "speed": 302.45
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695740655000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695740715,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 1776.3031,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 35.9065,
                    "longitude": 14.33267,
                    "speed": 297.109
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695740715000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695740775,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2293.2424,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 35.89994,
                    "longitude": 14.27907,
                    "speed": 291.838
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695740775000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695740835,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2499.26,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.89086,
                    "longitude": 14.22011,
                    "speed": 359.873
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695740835000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695740895,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2479.75,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.87494,
                    "longitude": 14.15633,
                    "speed": 347.791
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695740895000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695740955,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2479.75,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.86,
                    "longitude": 14.09477,
                    "speed": 347.791
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695740955000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695741015,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2476.97,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.84506,
                    "longitude": 14.03186,
                    "speed": 347.791
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695741015000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695741090,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2479.75,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.82999,
                    "longitude": 13.97012,
                    "speed": 353.794
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695741090000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695741150,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2485.32,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 35.8179,
                    "longitude": 13.90657,
                    "speed": 347.791
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695741150000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695741210,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2485.32,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.80381,
                    "longitude": 13.84417,
                    "speed": 359.873
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695741210000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695741270,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2485.32,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.78712,
                    "longitude": 13.7811,
                    "speed": 359.873
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695741270000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695741330,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2488.1,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.77054,
                    "longitude": 13.7168,
                    "speed": 359.873
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695741330000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695741345,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 26.70023,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28004,
                    "longitude": -0.94897,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695741345000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695741390,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2485.32,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 35.75621,
                    "longitude": 13.65356,
                    "speed": 347.791
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695741390000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695741450,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2488.1,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 35.7622,
                    "longitude": 13.59011,
                    "speed": 341.864
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695741450000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695741465,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 20.608532,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28008,
                    "longitude": -0.94905,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695741465000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695741510,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2490.89,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.74895,
                    "longitude": 13.52589,
                    "speed": 366.029
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695741510000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695741570,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2490.89,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.72941,
                    "longitude": 13.46295,
                    "speed": 366.029
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695741570000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695741585,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 20.608532,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28007,
                    "longitude": -0.94903,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695741585000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695741630,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2488.1,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.7095,
                    "longitude": 13.39916,
                    "speed": 366.029
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695741630000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695741690,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2493.68,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.68938,
                    "longitude": 13.33704,
                    "speed": 359.873
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695741690000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695741705,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 20.608532,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28005,
                    "longitude": -0.94903,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695741705000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695741750,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2496.47,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.66946,
                    "longitude": 13.27412,
                    "speed": 359.873
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695741750000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695741810,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2499.26,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.64991,
                    "longitude": 13.21282,
                    "speed": 353.794
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695741810000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695741825,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 22.638865,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 53.28007,
                    "longitude": -0.94903,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695741825000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695741870,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2504.84,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.63037,
                    "longitude": 13.1525,
                    "speed": 341.864
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695741870000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695741930,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2320.34,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.61121,
                    "longitude": 13.09214,
                    "speed": 341.864
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695741930000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695741945,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 22.638865,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28007,
                    "longitude": -0.94903,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695741945000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695741990,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 2178.009,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.59242,
                    "longitude": 13.03453,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695741990000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695742050,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 1925.34,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 35.57344,
                    "longitude": 12.97509,
                    "speed": 341.864
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695742050000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695742065,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 26.70023,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28003,
                    "longitude": -0.94897,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742065000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695742110,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 1694.39,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 35.53977,
                    "longitude": 12.92554,
                    "speed": 378.574
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695742110000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695742170,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 1442.64,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 35.48314,
                    "longitude": 12.90917,
                    "speed": 397.984
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695742170000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695742185,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 26.70023,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 53.28004,
                    "longitude": -0.94899,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742185000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695742230,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 1197.5,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 35.42346,
                    "longitude": 12.90162,
                    "speed": 391.434
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695742230000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695742290,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 996.04,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 35.3654,
                    "longitude": 12.89621,
                    "speed": 372.263
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695742290000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695742305,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 26.70023,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28004,
                    "longitude": -0.94901,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742305000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695742350,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 860.9688,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 35.33514,
                    "longitude": 12.85521,
                    "speed": 313.347
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742350000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695742410,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 676.3944,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 35.34572,
                    "longitude": 12.80514,
                    "speed": 247.482
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742410000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695742425,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 28.731268,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 53.28005,
                    "longitude": -0.94901,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742425000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695742470,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 654.9361,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 35.36595,
                    "longitude": 12.77161,
                    "speed": 242.885
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742470000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695742530,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 646.367,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 35.3741,
                    "longitude": 12.7226,
                    "speed": 286.637
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742530000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695742545,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 28.731268,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28005,
                    "longitude": -0.94901,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742545000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695742590,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 501.881,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 35.38425,
                    "longitude": 12.66653,
                    "speed": 291.838
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742590000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695742650,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 646.367,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 35.39829,
                    "longitude": 12.61621,
                    "speed": 286.637
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742650000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695742665,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 20.608532,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28004,
                    "longitude": -0.94897,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742665000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695742710,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 588.73413,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 35.41266,
                    "longitude": 12.56817,
                    "speed": 252.144
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742710000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695742770,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 468.19266,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.43941,
                    "longitude": 12.54763,
                    "speed": 220.854
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742770000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695742785,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 20.608532,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28005,
                    "longitude": -0.94899,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742785000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695742845,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 485.0229,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 35.46437,
                    "longitude": 12.57364,
                    "speed": 261.663
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742845000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695742905,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 140.82812,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 35.48995,
                    "longitude": 12.59593,
                    "speed": 177.68
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742905000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695742920,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 16.548569,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 53.28004,
                    "longitude": -0.94903,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742920000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695742965,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": -108.88673,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 35.49807,
                    "longitude": 12.61818,
                    "speed": 14.265
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695742965000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695743025,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 0,
                "point": {
                    "altitude": 18.578434,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 35.49943,
                    "longitude": 12.616,
                    "speed": 13.212
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695743025000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695743040,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 14.518934,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28008,
                    "longitude": -0.94912,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695743040000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695743085,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434036091210",
                "messageCode": 11,
                "point": {
                    "altitude": 22.638865,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.49979,
                    "longitude": 12.6164,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 600,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695743085000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695743160,
            "event": {
                "addresses": [
                    {
                        "address": "operations@dea.aero"
                    }
                ],
                "freeText": "G-WKTL ARRIVAL",
                "imei": "300434036091210",
                "messageCode": 15,
                "point": {
                    "altitude": 22.0,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 35.49979,
                    "longitude": 12.6164,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 1,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695743160000
            },
            "imei": "300434036091210"
        },
        {
            "at": 1695743160,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 14.518934,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28007,
                    "longitude": -0.94909,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695743160000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695743280,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 16.548569,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28005,
                    "longitude": -0.94907,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695743280000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695743400,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 16.548569,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 53.28005,
                    "longitude": -0.94907,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695743400000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695743520,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 16.548569,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28005,
                    "longitude": -0.94905,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695743520000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695743640,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 16.548569,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28007,
                    "longitude": -0.94905,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695743640000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695743760,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 16.548569,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28008,
                    "longitude": -0.94905,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695743760000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695743880,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 20.608532,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28007,
                    "longitude": -0.94905,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695743880000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695744000,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 22.638865,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28012,
                    "longitude": -0.94914,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695744000000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695744120,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 28.731268,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28003,
                    "longitude": -0.94894,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695744120000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695744240,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 28.731268,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28003,
                    "longitude": -0.94894,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695744240000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695744360,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 28.731268,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28003,
                    "longitude": -0.94888,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695744360000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695744480,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 28.731268,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28004,
                    "longitude": -0.94892,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695744480000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695744600,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 32.794052,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.2802,
                    "longitude": -0.94909,
                    "speed": 2.001
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695744600000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695744720,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 36.85779,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28004,
                    "longitude": -0.94888,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695744720000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695744840,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 36.85779,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.27995,
                    "longitude": -0.94882,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695744840000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695744960,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 36.85779,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.27991,
                    "longitude": -0.94882,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695744960000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695745080,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 36.85779,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.2799,
                    "longitude": -0.94884,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695745080000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695745200,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 36.85779,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.27989,
                    "longitude": -0.94886,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695745200000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695745320,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 36.85779,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.2799,
                    "longitude": -0.94886,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695745320000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695745440,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 36.85779,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.27991,
                    "longitude": -0.94886,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695745440000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695745575,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 36.85779,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.27991,
                    "longitude": -0.94886,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695745575000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695745695,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 36.85779,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.27993,
                    "longitude": -0.94886,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695745695000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695745815,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 40.922497,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.27998,
                    "longitude": -0.94894,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695745815000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695745935,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 40.922497,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.27999,
                    "longitude": -0.94897,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695745935000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695746055,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 38.890022,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28001,
                    "longitude": -0.94899,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695746055000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695746175,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 38.890022,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28,
                    "longitude": -0.94897,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695746175000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695746295,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 38.890022,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.27999,
                    "longitude": -0.94897,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695746295000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695746415,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 26.70023,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 53.28003,
                    "longitude": -0.94899,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695746415000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695746535,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 28.731268,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28003,
                    "longitude": -0.94899,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695746535000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695746655,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 28.731268,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28002,
                    "longitude": -0.94899,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695746655000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695746775,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 22.638865,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 53.28003,
                    "longitude": -0.94912,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695746775000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695746895,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 20.608532,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28003,
                    "longitude": -0.94914,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695746895000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695747015,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 20.608532,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 53.28003,
                    "longitude": -0.94912,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695747015000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695747135,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 20.608532,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28002,
                    "longitude": -0.94912,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695747135000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695747255,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 30.76254,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28,
                    "longitude": -0.94899,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695747255000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695747420,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 55.156635,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.27987,
                    "longitude": -0.94899,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695747420000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695747540,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 20.608532,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28013,
                    "longitude": -0.94909,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695747540000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695747660,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434039923470",
                "messageCode": 0,
                "point": {
                    "altitude": 20.608532,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 53.28016,
                    "longitude": -0.94903,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695747660000
            },
            "imei": "300434039923470"
        },
        {
            "at": 1695791580,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 10,
                "point": {
                    "altitude": 18.578434,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 37.40676,
                    "longitude": 14.93085,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 120,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695791580000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695791700,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 11,
                "point": {
                    "altitude": 24.66943,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 37.40671,
                    "longitude": 14.93087,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 300,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695791700000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695792060,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 22.638865,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 37.40675,
                    "longitude": 14.93085,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695792060000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695792420,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 20.608532,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 37.40676,
                    "longitude": 14.93083,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695792420000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695792780,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 16.548569,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 37.40671,
                    "longitude": 14.93085,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695792780000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695793140,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 30.76254,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 37.40677,
                    "longitude": 14.93089,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695793140000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695793485,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 14.518934,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 37.40669,
                    "longitude": 14.93089,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695793485000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695793890,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 108.1354,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 37.40697,
                    "longitude": 14.93109,
                    "speed": 2.001
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695793890000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695794055,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 11,
                "point": {
                    "altitude": 12.489531,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 37.40448,
                    "longitude": 14.93181,
                    "speed": 25.333
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 120,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695794055000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695794175,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 18.578434,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 37.40122,
                    "longitude": 14.93651,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695794175000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695794295,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 11,
                "point": {
                    "altitude": 28.731268,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 37.40128,
                    "longitude": 14.93649,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 300,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695794295000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695794505,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 11,
                "point": {
                    "altitude": 20.608532,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 37.40047,
                    "longitude": 14.93593,
                    "speed": 19.661
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 120,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695794505000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695794625,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 26.70023,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 37.40233,
                    "longitude": 14.91671,
                    "speed": 50.667
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695794625000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695794745,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 34.8258,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 37.40313,
                    "longitude": 14.90931,
                    "speed": 1.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695794745000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695794895,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 1020.64746,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 37.39,
                    "longitude": 15.00548,
                    "speed": 247.482
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695794895000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695795015,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 1910.02,
                    "course": 225.0,
                    "gpsFix": 2,
                    "latitude": 37.31973,
                    "longitude": 14.97422,
                    "speed": 353.794
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695795015000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695795135,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2443.68,
                    "course": 225.0,
                    "gpsFix": 2,
                    "latitude": 37.24329,
                    "longitude": 14.87555,
                    "speed": 347.791
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695795135000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695795255,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2952.7,
                    "course": 225.0,
                    "gpsFix": 2,
                    "latitude": 37.15968,
                    "longitude": 14.76683,
                    "speed": 404.614
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695795255000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695795375,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 4150.63,
                    "course": 225.0,
                    "gpsFix": 2,
                    "latitude": 37.07883,
                    "longitude": 14.66314,
                    "speed": 418.117
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695795375000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695795495,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 4090.66,
                    "course": 225.0,
                    "gpsFix": 2,
                    "latitude": 36.98491,
                    "longitude": 14.54562,
                    "speed": 446.114
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695795495000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695795615,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 4094.18,
                    "course": 225.0,
                    "gpsFix": 2,
                    "latitude": 36.88956,
                    "longitude": 14.42625,
                    "speed": 453.323
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695795615000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695795735,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 4094.18,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 36.77334,
                    "longitude": 14.42378,
                    "speed": 431.949
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695795735000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695795900,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 5134.41,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 36.63232,
                    "longitude": 14.54886,
                    "speed": 359.873
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695795900000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695796020,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 6076.97,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 36.5411,
                    "longitude": 14.63244,
                    "speed": 391.434
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695796020000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695796140,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 6996.3,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 36.46126,
                    "longitude": 14.7444,
                    "speed": 411.324
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695796140000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695796260,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 7839.37,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 36.41744,
                    "longitude": 14.88416,
                    "speed": 418.117
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695796260000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695796395,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8523.77,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 36.37646,
                    "longitude": 15.03273,
                    "speed": 424.992
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695796395000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695796515,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8749.39,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 36.33211,
                    "longitude": 15.19077,
                    "speed": 446.114
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695796515000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695796635,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8686.94,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 36.28897,
                    "longitude": 15.34505,
                    "speed": 438.99
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695796635000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695796680,
            "event": {
                "addresses": [
                    {
                        "address": "OPERATIONS@dEA.AERO"
                    }
                ],
                "freeText": "G-RTNA DEPARTURE",
                "imei": "300434037104160",
                "messageCode": 14,
                "point": {
                    "altitude": 8674.0,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 36.25495,
                    "longitude": 15.46515,
                    "speed": 434.523
                },
                "status": {
                    "autonomous": 1,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695796680000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695796755,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8669.96,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 36.24569,
                    "longitude": 15.49765,
                    "speed": 424.992
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695796755000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695796875,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8664.3,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 36.2036,
                    "longitude": 15.64584,
                    "speed": 418.117
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695796875000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695796995,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8658.65,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 36.16166,
                    "longitude": 15.79252,
                    "speed": 431.949
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695796995000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695797115,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8653.0,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 36.11668,
                    "longitude": 15.94852,
                    "speed": 446.114
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695797115000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695797145,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 10,
                "point": {
                    "altitude": 81.624016,
                    "course": 22.5,
                    "gpsFix": 2,
                    "latitude": 44.09394,
                    "longitude": 15.35404,
                    "speed": 2.001
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 120,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695797145000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695797153,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 20,
                "point": {
                    "altitude": -422.0,
                    "course": 0.0,
                    "gpsFix": 0,
                    "latitude": 0.0,
                    "longitude": 0.0,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695797153755
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695797235,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8647.35,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 36.07229,
                    "longitude": 16.10203,
                    "speed": 438.99
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695797235000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695797265,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 11,
                "point": {
                    "altitude": 83.661766,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 44.09398,
                    "longitude": 15.35402,
                    "speed": 0.0
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 1200,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695797265000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695797355,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8647.35,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 36.02784,
                    "longitude": 16.25489,
                    "speed": 446.114
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695797355000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695797475,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8647.35,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.98075,
                    "longitude": 16.41561,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695797475000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695797595,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8641.71,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.9324,
                    "longitude": 16.57987,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695797595000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695797715,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8636.07,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.88471,
                    "longitude": 16.74104,
                    "speed": 475.463
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695797715000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695797835,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8641.71,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.83379,
                    "longitude": 16.91219,
                    "speed": 483.016
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695797835000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695797880,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 11,
                "point": {
                    "altitude": 81.624016,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 44.09412,
                    "longitude": 15.35524,
                    "speed": 10.096
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 120,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695797880000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695797955,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8636.07,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.78338,
                    "longitude": 17.08026,
                    "speed": 483.016
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695797955000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695798000,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 83.661766,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 44.09959,
                    "longitude": 15.35442,
                    "speed": 25.333
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695798000000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695798075,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8624.79,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.73346,
                    "longitude": 17.2462,
                    "speed": 483.016
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695798075000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695798120,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 81.624016,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 44.10426,
                    "longitude": 15.34814,
                    "speed": 28.898
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695798120000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695798195,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8624.79,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.68342,
                    "longitude": 17.4114,
                    "speed": 475.463
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695798195000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695798240,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 79.58653,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 44.11058,
                    "longitude": 15.33957,
                    "speed": 33.873
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695798240000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695798315,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8619.16,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.63279,
                    "longitude": 17.57748,
                    "speed": 475.463
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695798315000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695798360,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 75.51233,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 44.11594,
                    "longitude": 15.33507,
                    "speed": 23.027
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695798360000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695798435,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8613.53,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.58279,
                    "longitude": 17.74071,
                    "speed": 475.463
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695798435000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695798480,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 241.42155,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 44.09307,
                    "longitude": 15.36588,
                    "speed": 188.762
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695798480000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695798555,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8613.53,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.53237,
                    "longitude": 17.90432,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695798555000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695798600,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 736.7557,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 44.04278,
                    "longitude": 15.31423,
                    "speed": 247.482
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695798600000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695798660,
            "event": {
                "addresses": [
                    {
                        "address": "operations@dea.aero"
                    }
                ],
                "freeText": "9H-DGN DEPARTURE",
                "imei": "300434037315150",
                "messageCode": 14,
                "point": {
                    "altitude": 976.0,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 44.04327,
                    "longitude": 15.22877,
                    "speed": 249.448
                },
                "status": {
                    "autonomous": 1,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695798660000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695798675,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8607.9,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.48191,
                    "longitude": 18.06702,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695798675000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695798720,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 949.2777,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 44.04357,
                    "longitude": 15.20935,
                    "speed": 252.144
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695798720000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695798795,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8607.9,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.43237,
                    "longitude": 18.22608,
                    "speed": 475.463
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695798795000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695798840,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 944.8388,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 44.04469,
                    "longitude": 15.10067,
                    "speed": 256.87
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695798840000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695798915,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8602.27,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.38053,
                    "longitude": 18.39148,
                    "speed": 475.463
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695798915000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695798960,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 947.0579,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 43.9839,
                    "longitude": 15.05127,
                    "speed": 247.482
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695798960000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695799035,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8602.27,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.32934,
                    "longitude": 18.55389,
                    "speed": 475.463
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695799035000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695799080,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 951.4981,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 43.91945,
                    "longitude": 15.00698,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695799080000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695799155,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8602.27,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.27756,
                    "longitude": 18.71731,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695799155000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695799200,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 944.8388,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 43.85504,
                    "longitude": 14.96293,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695799200000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695799275,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8602.27,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.22607,
                    "longitude": 18.87874,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695799275000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695799320,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 1227.3896,
                    "course": 225.0,
                    "gpsFix": 2,
                    "latitude": 43.80924,
                    "longitude": 14.89351,
                    "speed": 247.482
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695799320000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695799395,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8602.27,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.17444,
                    "longitude": 19.03993,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695799395000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695799440,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 1689.4629,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 43.74098,
                    "longitude": 14.86596,
                    "speed": 238.353
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695799440000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695799515,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8602.27,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.12268,
                    "longitude": 19.20073,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695799515000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695799560,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 1736.4631,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 43.66855,
                    "longitude": 14.82328,
                    "speed": 266.523
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695799560000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695799650,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8602.27,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.0715,
                    "longitude": 19.35873,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695799650000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695799680,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 1655.0428,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 43.59895,
                    "longitude": 14.78541,
                    "speed": 247.482
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695799680000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695799770,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8596.65,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 35.01897,
                    "longitude": 19.52013,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695799770000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695799800,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 1135.7988,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 43.52459,
                    "longitude": 14.74953,
                    "speed": 276.443
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695799800000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695799890,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8596.65,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.96631,
                    "longitude": 19.68096,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695799890000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695799920,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 495.5559,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 43.4639,
                    "longitude": 14.67776,
                    "speed": 252.144
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695799920000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695800010,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8596.65,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.91368,
                    "longitude": 19.84118,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695800010000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695800040,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 499.77222,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 43.4956,
                    "longitude": 14.61216,
                    "speed": 163.669
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695800040000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695800130,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8602.27,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.86072,
                    "longitude": 20.00121,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695800130000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695800160,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 501.881,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 43.48365,
                    "longitude": 14.65312,
                    "speed": 220.854
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695800160000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695800250,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8607.9,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.80787,
                    "longitude": 20.16006,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695800250000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695800280,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 506.09998,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 43.50411,
                    "longitude": 14.62651,
                    "speed": 192.569
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695800280000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695800370,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8602.27,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.75515,
                    "longitude": 20.3179,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695800370000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695800400,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 510.3207,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 43.48047,
                    "longitude": 14.6455,
                    "speed": 192.569
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695800400000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695800490,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8607.9,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.70206,
                    "longitude": 20.4759,
                    "speed": 475.463
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695800490000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695800520,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 506.09998,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 43.50517,
                    "longitude": 14.62428,
                    "speed": 185.012
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695800520000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695800610,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8602.27,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.64755,
                    "longitude": 20.63724,
                    "speed": 475.463
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695800610000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695800640,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 503.99026,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 43.49143,
                    "longitude": 14.6516,
                    "speed": 225.135
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695800640000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695800730,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8607.9,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.59372,
                    "longitude": 20.79605,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695800730000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695800760,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 503.99026,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 43.47251,
                    "longitude": 14.6266,
                    "speed": 181.318
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695800760000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695800850,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8607.9,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.54003,
                    "longitude": 20.95335,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695800850000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695800880,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 503.99026,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 43.52026,
                    "longitude": 14.64201,
                    "speed": 212.475
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695800880000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695800970,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8607.9,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.48688,
                    "longitude": 21.10849,
                    "speed": 460.617
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695800970000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695801000,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 506.09998,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 43.46021,
                    "longitude": 14.64098,
                    "speed": 216.634
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695801000000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695801090,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8607.9,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.43342,
                    "longitude": 21.26354,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695801090000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695801120,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 510.3207,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 43.39397,
                    "longitude": 14.63971,
                    "speed": 220.854
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695801120000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695801210,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8613.53,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.37887,
                    "longitude": 21.42089,
                    "speed": 467.997
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695801210000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695801240,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 476.6043,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 43.32719,
                    "longitude": 14.63954,
                    "speed": 220.854
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695801240000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695801330,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8619.16,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.32383,
                    "longitude": 21.57925,
                    "speed": 475.463
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695801330000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695801360,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 478.70828,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 43.26481,
                    "longitude": 14.62963,
                    "speed": 208.375
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695801360000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695801450,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8619.16,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.26876,
                    "longitude": 21.73643,
                    "speed": 475.463
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695801450000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695801480,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 43.22747,
                    "longitude": 14.68027,
                    "speed": 204.336
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695801480000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695801570,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 8619.16,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.21206,
                    "longitude": 21.89805,
                    "speed": 483.016
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695801570000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695801600,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 43.23569,
                    "longitude": 14.63051,
                    "speed": 170.567
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695801600000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695801690,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 7770.98,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.1502,
                    "longitude": 22.07321,
                    "speed": 546.632
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695801690000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695801735,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 476.6043,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 43.24949,
                    "longitude": 14.64578,
                    "speed": 216.634
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695801735000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695801810,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 6366.35,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.0858,
                    "longitude": 22.25412,
                    "speed": 522.102
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695801810000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695801855,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 472.3976,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 43.23006,
                    "longitude": 14.63566,
                    "speed": 170.567
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695801855000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695801930,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 5174.58,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 34.02734,
                    "longitude": 22.41812,
                    "speed": 460.617
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695801930000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695801975,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 468.19266,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 43.25175,
                    "longitude": 14.63735,
                    "speed": 208.375
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695801975000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695802050,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 4118.82,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 33.97474,
                    "longitude": 22.56448,
                    "speed": 424.992
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695802050000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695802095,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 476.6043,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 43.18913,
                    "longitude": 14.65108,
                    "speed": 212.475
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695802095000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695802170,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 3103.85,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 33.92434,
                    "longitude": 22.70441,
                    "speed": 424.992
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695802170000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695802215,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 466.09082,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 43.19003,
                    "longitude": 14.63046,
                    "speed": 167.092
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695802215000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695802290,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2940.75,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 33.87657,
                    "longitude": 22.83648,
                    "speed": 366.029
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695802290000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695802335,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 472.3976,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 43.24056,
                    "longitude": 14.62407,
                    "speed": 174.096
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695802335000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695802410,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2967.662,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 33.79187,
                    "longitude": 22.88712,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695802410000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695802455,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 478.70828,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 43.20878,
                    "longitude": 14.61879,
                    "speed": 212.475
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695802455000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695802530,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2967.662,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 33.69722,
                    "longitude": 22.90313,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695802530000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695802575,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 478.70828,
                    "course": 247.5,
                    "gpsFix": 2,
                    "latitude": 43.17201,
                    "longitude": 14.57808,
                    "speed": 192.569
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695802575000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695802650,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2970.66,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 33.67918,
                    "longitude": 23.00533,
                    "speed": 359.873
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695802650000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695802695,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 478.70828,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 43.13411,
                    "longitude": 14.58263,
                    "speed": 208.375
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695802695000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695802785,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2964.667,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 33.76201,
                    "longitude": 22.97381,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695802785000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695802815,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 480.8127,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 43.10136,
                    "longitude": 14.65544,
                    "speed": 212.475
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695802815000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695802905,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2964.667,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 33.71691,
                    "longitude": 22.90036,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695802905000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695802935,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 480.8127,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 43.06755,
                    "longitude": 14.73011,
                    "speed": 212.475
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695802935000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695803025,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2964.67,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 33.66632,
                    "longitude": 22.83141,
                    "speed": 330.236
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695803025000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695803055,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 480.8127,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 43.03328,
                    "longitude": 14.80532,
                    "speed": 216.634
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695803055000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695803145,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2967.66,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 33.688,
                    "longitude": 22.71569,
                    "speed": 336.013
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695803145000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695803175,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 478.70828,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 42.99786,
                    "longitude": 14.88321,
                    "speed": 225.135
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695803175000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695803265,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2967.662,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 33.7163,
                    "longitude": 22.60218,
                    "speed": 313.347
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695803265000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695803295,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 42.97437,
                    "longitude": 14.96636,
                    "speed": 208.375
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695803295000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695803385,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2967.662,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 33.74514,
                    "longitude": 22.49444,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695803385000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695803415,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 472.3976,
                    "course": 0.0,
                    "gpsFix": 2,
                    "latitude": 42.96942,
                    "longitude": 15.04344,
                    "speed": 170.567
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695803415000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695803505,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2964.667,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 33.77492,
                    "longitude": 22.38436,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695803505000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695803535,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 42.97397,
                    "longitude": 14.97585,
                    "speed": 185.012
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695803535000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695803625,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2967.662,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 33.80396,
                    "longitude": 22.27356,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695803625000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695803655,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 42.97021,
                    "longitude": 14.9028,
                    "speed": 177.68
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695803655000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695803745,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2964.667,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 33.82815,
                    "longitude": 22.16133,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695803745000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695803775,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 42.97174,
                    "longitude": 14.83045,
                    "speed": 174.096
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695803775000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695803865,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2961.67,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 33.85028,
                    "longitude": 22.04716,
                    "speed": 330.236
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695803865000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695803895,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 42.98194,
                    "longitude": 14.75927,
                    "speed": 181.318
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695803895000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695803985,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2961.6729,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 33.90973,
                    "longitude": 21.95294,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695803985000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695804015,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 42.99212,
                    "longitude": 14.68627,
                    "speed": 181.318
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695804015000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695804105,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2967.662,
                    "course": 202.5,
                    "gpsFix": 2,
                    "latitude": 33.85588,
                    "longitude": 21.86762,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695804105000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695804135,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 472.3976,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 43.00216,
                    "longitude": 14.61458,
                    "speed": 177.68
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695804135000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695804225,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2973.66,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 33.78775,
                    "longitude": 21.94811,
                    "speed": 353.794
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695804225000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695804255,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 472.3976,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 43.01205,
                    "longitude": 14.54358,
                    "speed": 177.68
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695804255000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695804345,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2961.6729,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 33.86296,
                    "longitude": 21.99555,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695804345000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695804375,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 478.70828,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 43.02236,
                    "longitude": 14.46904,
                    "speed": 185.012
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695804375000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695804465,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2970.66,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 33.92862,
                    "longitude": 21.90622,
                    "speed": 336.013
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695804465000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695804495,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 485.0229,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 43.03279,
                    "longitude": 14.39314,
                    "speed": 185.012
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695804495000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695804585,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2967.66,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 33.98719,
                    "longitude": 21.8028,
                    "speed": 347.791
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695804585000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695804615,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 487.1286,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 43.04297,
                    "longitude": 14.31881,
                    "speed": 181.318
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695804615000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695804705,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2958.68,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 34.04588,
                    "longitude": 21.69907,
                    "speed": 347.791
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695804705000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695804735,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 482.91757,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 43.06301,
                    "longitude": 14.2807,
                    "speed": 177.68
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695804735000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695804825,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2955.69,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 34.10444,
                    "longitude": 21.59524,
                    "speed": 347.791
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695804825000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695804855,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 468.19266,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 43.05861,
                    "longitude": 14.28411,
                    "speed": 188.762
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695804855000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695804945,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2743.77,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 34.16489,
                    "longitude": 21.48797,
                    "speed": 366.029
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695804945000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695804975,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 485.0229,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 43.00326,
                    "longitude": 14.31092,
                    "speed": 208.375
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695804975000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695805065,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 2172.7,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 34.22401,
                    "longitude": 21.38259,
                    "speed": 353.794
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695805065000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695805095,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 476.6043,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 42.94047,
                    "longitude": 14.32692,
                    "speed": 216.634
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695805095000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695805185,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 1334.19,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 34.28406,
                    "longitude": 21.27588,
                    "speed": 336.013
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695805185000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695805215,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 476.6043,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 42.8767,
                    "longitude": 14.34239,
                    "speed": 212.475
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695805215000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695805305,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 878.55273,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 34.33951,
                    "longitude": 21.18072,
                    "speed": 307.862
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695805305000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695805335,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 476.6043,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 42.8164,
                    "longitude": 14.36497,
                    "speed": 196.434
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695805335000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695805425,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 843.42285,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 34.38671,
                    "longitude": 21.08626,
                    "speed": 297.109
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695805425000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695805455,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 42.85821,
                    "longitude": 14.37091,
                    "speed": 185.012
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695805455000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695805545,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 860.9688,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 34.43033,
                    "longitude": 20.99616,
                    "speed": 281.506
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695805545000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695805575,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 470.29492,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 42.86533,
                    "longitude": 14.34544,
                    "speed": 204.336
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695805575000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695805665,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 865.3612,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 34.47454,
                    "longitude": 20.90756,
                    "speed": 291.838
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695805665000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695805710,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 478.70828,
                    "course": 45.0,
                    "gpsFix": 2,
                    "latitude": 42.82314,
                    "longitude": 14.38771,
                    "speed": 163.669
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695805710000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695805785,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 858.7735,
                    "course": 225.0,
                    "gpsFix": 2,
                    "latitude": 34.49414,
                    "longitude": 20.81813,
                    "speed": 297.109
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695805785000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695805830,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 292.5,
                    "gpsFix": 2,
                    "latitude": 42.84709,
                    "longitude": 14.33115,
                    "speed": 188.762
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695805830000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695805905,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 860.9688,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 34.42748,
                    "longitude": 20.77214,
                    "speed": 313.347
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695805905000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695805950,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 472.3976,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 42.83509,
                    "longitude": 14.35282,
                    "speed": 216.634
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695805950000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695806025,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 865.3612,
                    "course": 67.5,
                    "gpsFix": 2,
                    "latitude": 34.38921,
                    "longitude": 20.85744,
                    "speed": 313.347
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695806025000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695806070,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 476.6043,
                    "course": 112.5,
                    "gpsFix": 2,
                    "latitude": 42.79426,
                    "longitude": 14.41541,
                    "speed": 212.475
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695806070000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695806145,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 863.1647,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 34.45047,
                    "longitude": 20.93359,
                    "speed": 297.109
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695806145000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695806190,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 472.3976,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 42.77292,
                    "longitude": 14.49539,
                    "speed": 220.854
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695806190000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695806280,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 860.9688,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 34.46651,
                    "longitude": 20.84098,
                    "speed": 271.449
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695806280000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695806310,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 42.72047,
                    "longitude": 14.54787,
                    "speed": 216.634
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695806310000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695806400,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 860.9688,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 34.46457,
                    "longitude": 20.73245,
                    "speed": 307.862
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695806400000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695806430,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 472.3976,
                    "course": 135.0,
                    "gpsFix": 2,
                    "latitude": 42.66728,
                    "longitude": 14.60083,
                    "speed": 220.854
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695806430000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695806520,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 1151.7443,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 34.47621,
                    "longitude": 20.62509,
                    "speed": 266.523
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695806520000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695806550,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 90.0,
                    "gpsFix": 2,
                    "latitude": 42.62391,
                    "longitude": 14.66132,
                    "speed": 204.336
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695806550000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695806640,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 1836.5187,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 34.48905,
                    "longitude": 20.52793,
                    "speed": 276.443
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695806640000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695806670,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 315.0,
                    "gpsFix": 2,
                    "latitude": 42.64961,
                    "longitude": 14.70346,
                    "speed": 163.669
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695806670000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695806760,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 1861.7725,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 34.49017,
                    "longitude": 20.41704,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695806760000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695806790,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 476.6043,
                    "course": 157.5,
                    "gpsFix": 2,
                    "latitude": 42.61253,
                    "longitude": 14.65726,
                    "speed": 225.135
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695806790000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695806880,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 1859.2427,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 34.50022,
                    "longitude": 20.30222,
                    "speed": 318.903
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695806880000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695806910,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 474.50073,
                    "course": 337.5,
                    "gpsFix": 2,
                    "latitude": 42.63988,
                    "longitude": 14.69692,
                    "speed": 174.096
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695806910000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695807000,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 1859.2427,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 34.50717,
                    "longitude": 20.18512,
                    "speed": 324.533
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695807000000
            },
            "imei": "300434037104160"
        },
        {
            "at": 1695807030,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037315150",
                "messageCode": 0,
                "point": {
                    "altitude": 476.6043,
                    "course": 180.0,
                    "gpsFix": 2,
                    "latitude": 42.62248,
                    "longitude": 14.68606,
                    "speed": 229.478
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 2,
                    "resetDetected": 0
                },
                "timeStamp": 1695807030000
            },
            "imei": "300434037315150"
        },
        {
            "at": 1695807120,
            "event": {
                "addresses": [],
                "freeText": "",
                "imei": "300434037104160",
                "messageCode": 0,
                "point": {
                    "altitude": 1851.66,
                    "course": 270.0,
                    "gpsFix": 2,
                    "latitude": 34.51395,
                    "longitude": 20.06756,
                    "speed": 330.236
                },
                "status": {
                    "autonomous": 0,
                    "intervalChange": 0,
                    "lowBattery": 0,
                    "resetDetected": 0
                },
                "timeStamp": 1695807120000
            },
            "imei": "300434037104160"
        }
    ]
}
