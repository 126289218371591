import {useCallback} from "react";


const printAscii = (ab) => {
    return [...ab].map(n => (n < 20 || n > 126) ? '.' : String.fromCharCode(n)).join('')
};
const printHex = (ab) => {
    return [...ab].map(n => n.toString(16)).join('')
};

const parseTransportStream = (ab, start) => {

}

const parseProgramAssociationTable = (ab, start) => {
    console.log('PAT', printHex(ab.slice(start, start+188)))
}

export const ripBuffer = ab => {

    const v = new Uint8Array(ab);
    let start = v.indexOf(0x47)
    let lastpos = 0
    while (start > -1) {
        // if (v[start + 188] !== 0x47 || v[start + 188 + 188] !== 0x47) {
        //     start = v.indexOf(0x47, start + 1)
        //     console.log(v[start + 188], v[start + 188 + 188], v[start + 188 + 188 + 188])
        //     continue
        // }
        // const ascii = printAscii(v)
        // console.log('ascii', ascii)
        // sync_byte, tei, pusi, tp, pid, tsc, acf, cc = extract_bitfields(dd, 8, 1, 1, 1, 13, 2, 2, 4)
        const tei = (v[start + 1] & 0b10000000) >> 7
        const pusi = (v[start + 1] & 0b01000000) >> 6
        const tp = (v[start + 1] & 0b00100000) >> 5
        const pid = ((v[start + 1] & 0b00011111) << 8) | (v[start + 2] & 0b11111111)
        const tsc = (v[start + 3] & 0b11000000) >> 6
        const acf = (v[start + 3] & 0b00110000) >> 4
        const cc = v[start + 3] & 0b00001111
        if (pid !== 0 && pid !== 257 && pid !== 17 && pid !== 4096) {
            start = v.indexOf(0x47, start + 1)
            continue
        }
        if (pid === 256) {
            // video data
            start = v.indexOf(0x47, start + 1)
            continue
        }
        if (pid === 0) {
            parseProgramAssociationTable(v, start)
        } else {
            // console.log('LASTPOS', start - lastpos)
            // console.log('hex', pid, cc, printHex(v.slice(start, start + 188)))
            // console.log('ascii', printAscii(v.slice(start, start + 188)))
        }
        // console.log('ascii', printAscii(v.slice(start, start + 188)))

        start = v.indexOf(0x47, start + 188)
        lastpos = start
    }
}
