import * as React from 'react';
import {createTheme, ThemeProvider, responsiveFontSizes} from '@mui/material/styles'
import { darken, lighten } from '@mui/material/styles'

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const palette = {
    mode: 'dark',
    primary: {
        main: '#34373B',
        light: '#414449',
        dark: '#292c2f',
        contrastText: '#ccc',
    },
    secondary: {
        main: '#8493bf',
        contrastText: '#ccc',
    },
    text: {
        primary: '#ccc',
    },
    info: {
        main: '#8493bf',
    },
    background: {
        default: '#000',
        paper: '#424241',
    },
}
const basetheme = createTheme({})
const defaultTheme = createTheme({
    palette: palette,
    spacing: [0, 4, 8, 16, 32, 64],
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginTop: 0,
                    marginBottom: 0,
                    marginRight: 4,
                }
            },
            defaultProps: {
                margin: 'dense',
                color: 'secondary',
            },
        },
        MuiTextField: {
            defaultProps: {
                size: "small",
                margin: 'dense',
                variant: "outlined",
                color: 'primary',
            },
        },
        MuiLink: {
            defaultProps: {
                color: 'primary',
            },
            styleOverrides: {
                root: {
                    /* make those links easier to press on mobile devices */
                    lineHeight: '32px',
                    [basetheme.breakpoints.down('sm')]: {
                        lineHeight: '24px',
                    },
                }
            }
        },
        MuiRouterLink: {
            defaultProps: {
                color: 'primary',
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    backgroundColor: '#111e',
                }
            },
            defaultProps: {
                variant: 'outlined',
            }
        },
        MuiDataGrid: {
            defaultProps: {
                rowHeight: 30,
            },
            styleOverrides: {
                root: {
                    marginTop: 20,
                    marginBottom: 20,
                    borderColor: '#333',
                    backgroundColor: "#222",
                },
                row: {
                    "&.Mui-selected": {
                        backgroundColor: getSelectedBackgroundColor(
                            palette.info.main,
                            palette.mode,
                        ),
                        "&:hover": {
                            backgroundColor: getSelectedHoverBackgroundColor(
                                palette.info.main,
                                palette.mode,
                            ),
                        }
                    }
                },
                cell: {
                    borderColor: '#333'
                },
                columnHeaders: {
                    borderColor: '#333'
                },
                columnHeader: {
                    borderColor: '#333'
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    marginRight: 4,
                }
            },
            defaultProps: {
                variant: 'contained',
            },
        },
        MuiFilledInput: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormHelperText: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiIconButton: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiInputBase: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiInputLabel: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiListItem: {
            defaultProps: {
                dense: true,
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                size: 'small',
                margin: 'dense',
            },
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiAppBar: {
            styleOverrides: {}
        },
        MuiToolbar: {
            defaultProps: {
                variant: 'dense',
            },
        },
    },
});
const theme = responsiveFontSizes(defaultTheme);

export default function Main({children}) {
    return (
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
    );
}