import Stack from "@mui/material/Stack";
import useConfigStore from '../utils/useConfigStore'
import useStatsStore from '../utils/useStatsStore'
import {useCallback, useEffect, useState} from "react";
import {useApiCallback} from "../utils/Api";
import {Button} from "@mui/material";
import {canUser} from "../App";
import {useLoginStore} from "../Login";
import {timeSince} from "../utils/time";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Link as RLink} from "react-router-dom";


export default function Streams() {
    const configStore = useConfigStore()
    const {assigned, servertime} = useStatsStore()
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState('')
    const doSync = useApiCallback('POST', `/api/streams/sync`, {setLoading, onDone: setStatus})
    const doCheck = useApiCallback('GET', `/api/streams/check`, {setLoading, onDone: setStatus})
    const {permissions} = useLoginStore()

    const [streamStatus, setStreamStatus] = useState([])
    const doStatusCheck = useApiCallback('GET', `/api/streams/status`, {setLoading, onDone: setStreamStatus})
    useEffect(doStatusCheck, [])

    const [watchers, setWatchers] = useState([])
    const doGetWatchers = useApiCallback('GET', `/api/streams/watchers`, {setLoading, onDone: setWatchers})
    useEffect(doGetWatchers, [])

    const [canSync, setCanSync] = useState(false)
    const [canCheck, setCanCheck] = useState(false)
    useEffect(() => {
        setCanSync(canUser('POST', '/api/streams/sync', permissions))
        setCanCheck(canUser('GET', '/api/streams/check', permissions))
    }, [permissions])

    const [kwatchers, setKwatchers] = useState({})
    useEffect(() => {
        const kw = {}
        watchers.forEach(w => {
            const name = w.app+'/'+w.stream
            if (!(name in kw))
                kw[name] = 0
            kw[name] += 1
        })
        setKwatchers(kw)
    }, [watchers])

    const [tableData, setTableData] = useState([])
    useEffect(() => {
        const td = []
        const keys = {}

        Object.keys(configStore.streams).forEach(k => {
            const sss = configStore.streams[k]
            const name = sss.split('--')[0]
            const key = sss.split('--')[1].slice(0, 6) + '************'
            const stream = sss.split('/')[1]
            const id = name.replace(/^mportal-/, '') + '/' + stream
            keys[id] = key
            console.log('KKKKKKKK', id, key)
        })

        streamStatus.forEach(s => {
            const proto = s.protocol || 'none'
            const app = s.application.replace('--INPUT', '')
            const sname = app + '/' + s.stream
            let kname = sname
            let assignedto = assigned[app] || '-'
            if (s.stream === 'compiled') {
                kname = 'IA-' + (assigned[app] || 'none') + '/compiled'
                console.log(assigned[app], app, keys[kname])
            }
            console.log('KNAME', app, s.stream, kname, keys[kname])
            const dd = {
                'id': sname + proto,
                'app': app,
                'stream': s.stream,
                'name': sname,
                'protocol': proto,
                'lastseen': s.publish_time,
                'online': s.status === 'offline' ? '🔴' : '🟢',
                'bandwidth': Math.round(s.bandwidth / 1000),
                'watchers': kwatchers[sname] || '',
                'key': keys[kname] || '-',
                'assigned': assignedto,
            }
            td.push(dd)
        })

        setTableData(td)
    }, [configStore.streams, setTableData, streamStatus, assigned])

    const renderWatchBtn = useCallback((params) => {
        if (params.row.key === '-') return ''
        return <Button component={RLink} to={`/video/${params.row.app}`}>View</Button>
    })

    const renderSince = useCallback(params => timeSince(servertime, params.row.lastseen))

    const getColumnDefinition = useCallback(() => {
        return [
            {field: 'online', headerName: '', width: 1},
            {field: 'watch', headerName: 'Watch', renderCell: renderWatchBtn},
            {field: 'watchers', headerName: 'Watchers', width: 1},
            {field: 'app', headerName: 'App', flex: 1},
            {field: 'stream', headerName: 'Stream', flex: 1},
            {field: 'assigned', headerName: 'Assigned To', flex: 1},
            {field: 'protocol', headerName: 'Protocol', flex: 1},
            {field: 'lastseen', headerName: 'Last Seen', flex: 1, renderCell: renderSince},
            {field: 'bandwidth', headerName: 'Kbps', flex: 1},
            {field: 'key', headerName: 'Access Key', flex: 2},
        ]
    })

    return <Stack justifyContent="center" alignItems="start">
        <Stack direction="row" alignItems="center" sx={{}}>
            {canSync ?
            <Button disabled={loading} color="success" onClick={() => doCheck()}>Fetch Video Server Config</Button>
            : ''}
            {canCheck ?
            <Button disabled={loading} color="warning" onClick={() => doSync()}>Update Video Server Config</Button>
            : ''}
        </Stack>
        <pre>{status}</pre>
        <div style={{height: 'calc(100vh - 180px)', width: '100%'}}>
            <DataGridPro
                hideFooter={true}
                loading={loading}
                rows={tableData}
                disableMultipleRowSelection={true}
                columns={getColumnDefinition()}
                initialState={{
                    sorting: {
                        sortModel: [{field:'online', sort:'desc'}],
                    },
                    columns: {
                        columnVisibilityModel: {
                            key: false,
                        },
                    },
                }}
            />
        </div>
        <pre>{JSON.stringify(configStore.streams, null, 4)}</pre>
    </Stack>
}
