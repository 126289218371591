import {useToastStore} from "../Toast"
import {getServer} from './server'
import {useCallback} from "react";
const server = getServer()

export function useApiCallback(method, path, {data, setLoading, onDone}) {
    const errorToast = useToastStore((state) => state.showError)

    return useCallback((altdata=null) => {
        const mdata = altdata !== null ? altdata : data
        if (!path) return () => {}
        setLoading && setLoading(true)
        const controller = new AbortController()
        const opts = {
            method: method,
            signal: controller.signal,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            credentials: 'include',
        }
        if (mdata && opts.method !== 'GET') {
            opts.body = JSON.stringify(mdata)
        }

        let url = server + path
        if (altdata && opts.method === 'GET') {
            url += '?' + new URLSearchParams(altdata).toString()
        }
        fetch(url, opts)
            .then((response) => {
                setLoading && setLoading(false)
                response.json().then(data => {
                    if (!response.ok) {
                        data = JSON.stringify((data.hasOwnProperty('data')) ? data.data : data)
                        errorToast(data)
                        return
                    }
                    if (onDone) {
                        onDone(data.data)
                    }
                })
            }, (e) => {
                setLoading && setLoading(false)
                console.error(e)
            })

        return () => {
            console.log('aborting request', method, path, data)
            controller.abort()
        }
    }, [method, setLoading, onDone, path, errorToast, data])
}
