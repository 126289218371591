import {useState, useEffect, useRef} from "react"
import {useApiCallback} from "../utils/Api"
import Stack from "@mui/material/Stack"
import ErrorBlock from "./ErrorBlock"
import {Map as MapGL, Source, Layer, Marker, Popup} from "react-map-gl"
import 'mapbox-gl/dist/mapbox-gl.css'
import useStatsStore from "../utils/useStatsStore"
import {SampleEvents, useGarminStore} from "../Garmin"
import {timeSince} from "../utils/time"
import GarminGrid from "./GarminGrid"
import {isValidLatLng} from "../utils/Mappery"
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import {Button} from "@mui/material";
import {FullscreenExit} from "@mui/icons-material";

const mapboxAccessToken = 'pk.eyJ1IjoidGltc3RhbXAtZGVhIiwiYSI6ImNsbXJsMGltMDAzeTAydHA3ZnhnZDUyYjcifQ.Rje90za_7cTncw7EolnM7g'

function ChatMsg({event}) {
    const date = new Date(event.at * 1000);
    let [d, h, m, s] = [date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()]
    h = `0${h}`.slice(-2)
    m = `0${m}`.slice(-2)
    return <div key={`${d}|${h}${m}${s}`}>
        <code style={{backgroundColor:'#aaa', color:'#fff', borderRadius:5,padding:'1px 4px',marginRight:2}}>{d}|{h}{m}Z</code>
        <code style={{margin:2, fontWeight:'bold'}}>{event.event.freeText}</code>
    </div>
}

function PinMarker({name, text, colour, lat, lng, onClick}) {
    const size = 3200 / 100
    const pinstyle = {
        backgroundImage: "url('/map/pin.svg')",
        backgroundSize: 'cover',
        height: `${size}px`,
        width: `${size}px`,
    }
    return <div key={name}>
            <Marker
            id={name}
            color={colour}
            latitude={lat}
            longitude={lng}
            rotationAlignment='horizon'
            offset={[0, -size / 2]}
            onClick={onClick}
        ><div style={pinstyle}/></Marker>
        {text && <Marker
            id={name + '__text'}
            anchor="center"
            latitude={lat}
            longitude={lng}
            offset={[0, -90 / 2]}
            rotationAlignment='horizon'
            onClick={onClick}
            style={{fontWeight:'bold', color:'#000', backgroundColor:'#fffa', borderRadius:'6px'}}
        >{text}</Marker>}
    </div>
}


export default function Map() {
    const [loading, setLoading] = useState(false)
    const garminStore = useGarminStore()
    const {stats, lastseen, servertime} = useStatsStore()
    const [fetchedLatest, setFetchedLatest] = useState(false)
    const [garminEvents, setGarminEvents, latestEvents, addGarminEvent] = [
        garminStore.events, garminStore.setEvents, garminStore.latestEvents, garminStore.addEvent
    ]
    const [timer, setTimer] = useState(0)

    const [flightpaths, setFlightpaths] = useState({})
    const [influxMarkers, setInfluxMarkers] = useState({})
    const fetchFlightpaths = useApiCallback('GET', '/api/flightpaths', {setLoading, onDone: setFlightpaths})
    useEffect(fetchFlightpaths, [timer])

    const [flightpathmapobjs, setFlightpathmapobjs] = useState({})
    useEffect(() => {
        /** Draw 12 hour paths of all aircraft locations */
        if (!flightpaths) return
        const markers = {}
        const paths = Object.keys(flightpaths).map(aircraft => {
            const data = {
                type: 'FeatureCollection',
                features: [
                    {type: 'Feature', geometry: {type: 'LineString', coordinates: flightpaths[aircraft]}}
                ]
            };
            const ll = flightpaths[aircraft].length
            if (!ll)
                return null
            const [lng, lat] = flightpaths[aircraft][ll-1]
            markers[aircraft] = <PinMarker
                    name={aircraft + ' last position'}
                    text={aircraft}
                    colour={'orange'}
                    lat={lat}
                    lng={lng}
                    onClick={() => pointClick({identity: aircraft, lat, lng})}
                />
            return <Source key={aircraft+"_pathsource"} type="geojson" lineMetrics={true} data={data}>
                <Layer
                    id={aircraft+'_path'}
                    type="line"
                    layout={{
                        "line-join": "round",
                        "line-cap": "round"
                    }}
                    paint={{
                        "line-color": "rgba(90,15,154,0.8)",
                        "line-width": 5,
                        'line-gradient': [
                            'interpolate',
                            ['linear'],
                            ['line-progress'],
                            0,
                            "rgba(90,15,154,0.1)",
                            1,
                            "rgba(90,15,154,0.8)"
                        ]
                    }}
                    onClick={() => pointClick({identity: aircraft, lat, lng})}
                />
            </Source>
        })
        setInfluxMarkers(markers)
        setFlightpathmapobjs(paths)
    }, [flightpaths, setFlightpathmapobjs, setInfluxMarkers])

    const fetch24h = useApiCallback('GET', `/api/garmin/24h`, {setLoading, onDone: setGarminEvents})
    useEffect(fetch24h, [])
    const fetchLatest = useApiCallback('GET', `/api/garmin/latest`,
        {setLoading, onDone: ev => ev.forEach(e => addGarminEvent(e))}
    )
    useEffect(() => {
        if (garminEvents && !fetchedLatest) {
            fetchLatest()
            setFetchedLatest(true)
        }
    }, [garminEvents])

    useEffect(() => {
        if (window.location.hostname === 'localhost.dea.aero') {
            setGarminEvents(SampleEvents())
        }
    }, [])

    const map = useRef()
    const [lng, setLng] = useState(0.1)
    const [lat, setLat] = useState(44.1)
    const [pitch, setPitch] = useState(0)
    const [zoom, setZoom] = useState(3.6)
    const [mapstuff, setMapstuff] = useState([])
    const [paths, setPaths] = useState([])
    const [mx15Pos, setMx15Pos] = useState({})
    const [mx15Markers, setMx15Markers] = useState([])
    const [popup, setPopup] = useState(<></>)

    useEffect(() => {
        const it = setInterval(() => {
            setTimer((t) => t + 1)
        }, 60_000)
        return () => clearInterval(it)
    }, [])

    const getLatestGarminPosition = (imei) => {
        let lat = garminStore.latestEvents[imei]?.event?.point?.latitude
        let lng = garminStore.latestEvents[imei]?.event?.point?.longitude
        lat = lat.toFixed(5)
        lng = lng.toFixed(5)
        if (isValidLatLng(lat, lng)) {
            return [lat, lng]
        }
        const found = garminEvents.findLast(ev => {
            if (ev.imei !== imei)
                return false
            let lat = ev?.event?.point?.latitude
            let lng = ev.event?.point?.longitude
            lat = lat.toFixed(5)
            lng = lng.toFixed(5)
            return isValidLatLng(lat, lng) ? [lat, lng] : false
        })
        return found || [null, null]
    }

    const pointClick = ({imei, lat, lng, identity}) => {

        const msgs = []
        if (imei) {
            garminEvents.forEach(event => {
                if (event.imei !== imei) return
                if (event.event.freeText) {
                    msgs.push(<ChatMsg event={event}/>)
                }
            })
        }
        const name = identity ? `Aircraft: ${identity}` : `Garmin: ${imei}`
        const lastSeen = timeSince(servertime, identity ? lastseen[identity] : latestEvents[imei].at)
        msgs.reverse()  // show newest message first
        setPopup(<Popup
            longitude={lng}
            latitude={lat}
            offset={[20, 0]}
            key={`${identity} ${imei} popup`}
            id={`${identity} ${imei} popup`}
            anchor="left"
            closeOnClick={false}
            style={{color:'#000', fontWeight:'bold'}}
        >
            <strong>{name}</strong>
            <br/>
            latitude: {lat}
            <br/>
            longitude: {lng}
            <br/>
            <code style={{backgroundColor:'#777', color:'#fff', borderRadius:5,padding:'1px 4px',marginRight:2}}>{lastSeen} ago</code>
            <br/>
            {msgs}
        </Popup>
        )
    }

    useEffect(() => {
        const pos = {}
        Object.keys(stats).forEach(identity => {
            const mx15_position = stats[identity].mx15_position?.data || null
            if (!mx15_position) return
            const latlong = mx15_position[0].split(' | ')
            let [lat, lng] = latlong[0].split(' ')
            lat = parseFloat(lat)
            lng = parseFloat(lng)

            if (!isValidLatLng(lat, lng)) {
                // console.warn('CCC', mx15_position)
                return
            }
            pos[identity] = [lat, lng]
        })
        setMx15Pos(pos)
    }, [stats])

    useEffect(() => {
        /** Draw last known MX15 locations */
        if (!mx15Pos) return
        const markers = []
        const now = Math.floor(Date.now() / 1000)
        Object.keys(mx15Pos).forEach(identity => {
            const [lat, lng] = mx15Pos[identity]
            if (identity in influxMarkers) return

            if (!isValidLatLng(lat, lng)) {
                // console.warn('DDD', mx15Pos[identity])
                return
            }
            const at = lastseen[identity]
            const colour = now - at < 600 ? 'red' : 'grey'
            markers.push(
                <PinMarker
                    colour={colour}
                    name={identity + ' mx15 pos'}
                    text={identity}
                    lat={lat}
                    lng={lng}
                    onClick={() => pointClick({lat, lng, identity})}
                />
            )
        })
        setMx15Markers(markers)
    }, [mx15Pos, timer, influxMarkers])

    useEffect(() => {
        /** Draw last known garmin locations */
        if (!latestEvents) return
        const markers = []
        const now = Math.floor(Date.now() / 1000)
        Object.keys(latestEvents).forEach(imei => {
            const [lat, lng] = getLatestGarminPosition(imei)
            const dd = latestEvents[imei]
            if (!isValidLatLng(lat, lng)) {
                console.warn('AAA', dd)
                return
            }
            const colour = now - dd.at < 600 ? 'blue' : 'grey'
            markers.push(
                <PinMarker
                    name={imei + ' last position'}
                    text={`${imei}`.slice(-6)}
                    colour={colour}
                    lat={lat}
                    lng={lng}
                    onClick={() => pointClick({lat, lng, imei})}
                />
            )
        })
        setMapstuff(markers)
    }, [latestEvents, timer])

    useEffect(() => {
        /** Draw 24 hour paths of all garmin locations */
        if (!garminEvents) return
        const lines = {}
        const h24_hours_ago = Math.floor(Date.now() / 1000) - (24 * 60 * 60)
        garminEvents.forEach(dd => {
            let at = dd.at
            if (at < h24_hours_ago) return
            let lat = dd.event?.point?.latitude || null
            let lng = dd.event?.point?.longitude || null
            if (!isValidLatLng(lat, lng)) {
                return
            }
            lat = lat.toFixed(5)
            lng = lng.toFixed(5)
            if (typeof lines[dd.imei] === 'undefined')
                lines[dd.imei] = []
            lines[dd.imei].push([lng, lat])
        })
        const paths = Object.keys(lines).map(imei => {
            const data = {
                type: 'FeatureCollection',
                features: [
                    {type: 'Feature', geometry: {type: 'LineString', coordinates: lines[imei]}}
                ]
            };
            const [lng, lat] = lines[imei][0]
            return <Source key={imei+"_pathsource"} type="geojson" lineMetrics={true} data={data}>
                <Layer
                    id={imei+'_path'}
                    type="line"
                    layout={{
                        "line-join": "round",
                        "line-cap": "round"
                    }}
                    paint={{
                        "line-color": "rgba(255, 0, 0, 0.5)",
                        "line-width": 5,
                        'line-gradient': [
                            'interpolate',
                            ['linear'],
                            ['line-progress'],
                            0,
                            "rgba(255, 0, 0, 0.1)",
                            1,
                            "rgba(255, 0, 0, 0.8)"
                        ]
                    }}
                    onClick={() => pointClick({imei, lat, lng})}
                />
            </Source>
        })
        setPaths(paths)
    }, [garminEvents, setPaths, timer])

    const navClick = (lat, lng) => {
        let zoom = 8
        if (lat === null || lat === '') return
        if (lng === null || lng === '') return
        if (typeof lat === 'string')
            lat = parseFloat(lat)
        if (typeof lng === 'string')
            lng = parseFloat(lng)
        console.log(lng.toFixed(1), lat.toFixed(1))
        if (lng.toFixed(1) === '-0.9' && lat.toFixed(1) === '53.3' ) {
            zoom = 14
        }
        map.current.flyTo({
            center: [lng, lat],
            zoom,
            speed: 1,
            curve: 1,
        })
    }

    // useEffect(() => {
    //     if (map.current) {
    //         const _map = map.current.getMap()
    //         console.log('MapObj MAP', _map)
    //         _map.on('style.load', () => {
    //             console.log('MapObj Adding RadarObj', _map)
    //             _map.addLayer(radarObj(), 'waterway-label')
    //         })
    //     }
    // }, [map, map.current])

    const [fullscreen, setFullscreen] = useState(false)
    const [fullscreenStyle, setFullscreenstyle] = useState({position: 'relative', height:'80vh', width:'100%'})

    useEffect(() => {
        setFullscreenstyle(fullscreen ? {
            position: 'absolute', left: 0, top: 0, right: 0, bottom: 0
        } : {
            position: 'relative', height:'80vh', width:'100%',
        })
        if (map.current)
            setTimeout(map.current.resize, 1)
    }, [fullscreen, setFullscreenstyle, map])

    return <>
        <ErrorBlock name="Map">
            <Stack justifyContent="center"
               alignItems="start"
            >
                <MapGL
                    mapboxAccessToken={mapboxAccessToken}
                    initialViewState={{longitude: lng, latitude: lat, zoom, pitch}}
                    style={fullscreenStyle}
                    mapStyle="mapbox://styles/timstamp-dea/cln91w9w003jt01r70v4g7qbk"
                    ref={map}
                    projection="globe"
                    antialias={true}
                >
                    <Button sx={{position:'absolute', zIndex:1, bottom:25, right:10}}
                            variant="contained"
                            onClick={() => setFullscreen(!fullscreen)}
                    >{fullscreen ? <FullscreenExit /> : <FullscreenIcon />}</Button>
                    {/*<FlyingThing/>*/}
                    {mapstuff}
                    {mx15Markers}
                    {Object.values(influxMarkers)}
                    {paths}
                    {flightpathmapobjs}
                    {popup}
                </MapGL>
            </Stack>
        </ErrorBlock>
        {!fullscreen && <GarminGrid navClick={navClick}/>}
    </>
}
