import 'mapbox-gl/dist/mapbox-gl.css'
import Typography from "@mui/material/Typography";
import {Button, Modal} from "@mui/material";
import Box from '@mui/material/Box'
import {useEffect, useState} from "react";

const modalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: 5,
    boxShadow: 8,
    p: 4,
    component: "form",
    noValidate: 'on',
    autoComplete: 'off',
};

function Code({children}) {
    return <code style={{background: '#666', padding: '6px 12px', margin:6, borderRadius: 6}}>{children}</code>
}

function Size({size}) {
    if (size < 1024)
        return size + ' Bytes'
    size = size >> 10
    if (size < 1024)
        return size + ' KB'
    size = size >> 10
    return size + ' MB'
}

export default function UploadModal({detail, onClose}) {
    const [files, setFiles] = useState([])

    useEffect(() => {
        setFiles(detail.data.inventory.map(file => {
            const n = file.name.split('/').pop()
            return <Typography key={n}><Code>{n}</Code> <Size size={file.size}/></Typography>
        }))
    }, [detail, setFiles])

    return <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="Zip Inventory"
            aria-describedby="Inventory of encrypted zip file">
        <Box sx={modalstyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Zipfile <Code>{detail.filename}</Code>
                <Size size={detail.size}/>
            </Typography>
            <Typography id="modal-modal-description">
                {detail.errors && <Typography sx={{mt: 4, color: '#f66'}}>
                    {detail.errors.split("\n").map(txt => <>{txt}<br/></>)}
                </Typography>}
                <Typography sx={{mt: 4}}>
                    {files}
                </Typography>
            </Typography>
            {/*<pre style={{maxWidth:400}}>{JSON.stringify(detail, null, 2)}</pre>*/}
        </Box>
    </Modal>
}
