import create from "zustand";

const useConfigStore = create((set) => ({
    configs: {},
    ipmap: {},
    streams: {},
    setConfigs: (configs) => set({ configs }),
    setIpMap: (ipmap) => set({ ipmap }),
    setStreams: (streams) => set({ streams }),
}))
export default useConfigStore
