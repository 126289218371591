import {useEffect, useState} from 'react'
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import useStatsStore from "../utils/useStatsStore"

export default function ContractSelect({disabled=false, contract='', setContract}) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [val, setVal] = useState('')

    useEffect(() => {
        setVal(contract)
    }, [contract])

    const statsStore = useStatsStore()
    useEffect(() => {
        const contracts = ['']
        statsStore.assignments.map(row => {
            const [gnd, air, client, contract] = row
            const cl = (client ? client : '') + ' ' + (contract ? contract : '')
            if (cl && contracts.indexOf(cl) === -1) {
                contracts.push(cl)
            }
        })
        contracts.sort()
        setData(contracts)
    }, [statsStore.assignments, setData])

    return (
        <Autocomplete
            sx={{width: 250, margin: '8px'}}
            options={data}
            autoHighlight
            freeSolo={true}
            autoSelect={true}
            disabled={disabled || loading}
            renderOption={(props, option) => (
                <Stack direction="row" spacing={2} {...props} justifyContent="center" alignItems="center">
                    <strong>{option.split(' ')[0]}</strong>
                    <Box/>
                    {option.split(' ')[1]}
                </Stack>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Assign Contract"
                    InputLabelProps={{shrink: true}}
                    inputProps={{...params.inputProps, autoComplete: 'off'}}
                />
            )}
            inputValue={val}
            onInputChange={(e, newval) => {
                setVal(newval.toUpperCase())
                setContract(newval.toUpperCase())
            }}
        />
    )
}
