import React, {useEffect, useState} from "react"
import {useApiCallback} from "../utils/Api"
import Stack from "@mui/material/Stack"
import useConfigStore from '../utils/useConfigStore'
import useStatsStore from '../utils/useStatsStore'
import {Link as RLink, useNavigate, useParams} from "react-router-dom"
import TextField from '@mui/material/TextField'
import {Button} from "@mui/material"
import {useToastStore} from "../Toast"
import Typography from "@mui/material/Typography"
import {Stat} from "./Stats"
import ErrorBlock from "./ErrorBlock"
import useViewStore from "../utils/useViewStore";
import Grid from "@mui/material/Grid";
import StatusRow from "./StatusRow";

export default function Config() {
    const params = useParams()
    const [identity, setIdentity] = useState(params.identity)
    const {configs, setConfigs} = useConfigStore()
    const {assigned} = useStatsStore()
    const [data, setData] = useState({})
    const [filteredData, setFilteredData] = useState('')
    const {filter, setFilter} = useViewStore()
    const [loading, setLoading] = useState(false)
    const [valid, setValid] = useState(false)
    const fetchConfig = useApiCallback('GET', `/api/config?name=${identity}`, {setLoading, onDone: setData})
    const onDone = () => {
        setConfigs({...configs, identity:data})
        successToast('Configuration saved')
    }
    const saveConfig = useApiCallback('POST', `/api/config?name=${identity}`, {setLoading, onDone})
    const errorToast = useToastStore((state) => state.showError)
    const successToast = useToastStore((state) => state.showSuccess)

    useEffect(fetchConfig, [identity])

    useEffect(() => {
        setData(configs[identity])
    }, [configs[identity]], identity)

    useEffect(() => {
        if (typeof filteredData === 'undefined') {
            return
        }
        const t = setTimeout(() => {
            try {
                JSON.parse(filteredData)
                setValid(true)
            } catch (e) {
                console.error(e)
                errorToast(String(e))
                setValid(false)
            }
        }, 1000)
        return () => clearTimeout(t)
    }, [filteredData, setValid])

    const saveClick = () => {
        try {
            let dd = data
            if (filter) {
                data[filter] = JSON.parse(filteredData)
            } else {
                dd = JSON.parse(filteredData)
            }
            saveConfig(dd)
        } catch (e) {
            errorToast(String(e))
        }
    }

    useEffect(() => {
        let fd
        if (!data) return
        if (filter) {
            if (data.hasOwnProperty(filter)) {
                fd = data[filter]
            } else {
                fd = {}
            }
        } else {
            fd = data
        }
        setFilteredData(JSON.stringify(fd, null, 2))
    }, [data, filter])

    const navigate = useNavigate()
    const nextClick = ({reverse=false}) => {
        let ll = Object.keys(assigned).sort()
        if (reverse) ll = ll.reverse()
        let useNext = false
        let changeto = null
        ll.map(ident => {
            if (changeto) return
            if (useNext) {
                changeto = ident
                return
            }
            if (ident === identity) {
                useNext = true
            }
        })
        if (useNext && !changeto) {
            changeto = ll[0]
        }
        setIdentity(changeto)
        navigate('/config/'+changeto)
    }

    const prevClick = () => {
        nextClick({reverse:true})
    }

    return <Stack sx={{fontSize:{xs:'0.8em',sm:'0.9em',md:'1em'}}}>
        <Stack sx={{mt:2}} direction="row" alignItems="center" spacing={1} justifyContent="center">
            <Typography component="h2">{identity}</Typography>
            <Button onClick={saveClick}>Save</Button>
            <Button component={RLink} to={`/setup/${identity}`}>Setup</Button>
            <Button component={RLink} to={`/stats/${identity}`}>Stats</Button>
            <TextField
                label="Filter"
                InputLabelProps={{shrink: true}}
                value={filter}
                onChange={(event) => {
                    setFilter(event.target.value)
                }}
                sx={{width: '300px', margin: 2}}
            />
            <Button onClick={prevClick}>&lt;</Button>
            <Button onClick={nextClick}>&gt;</Button>
        </Stack>
        <Grid container spacing={0}  sx={{textAlign:'center', maxWidth: 800, margin: 'auto',
            fontSize: {xs:'0.7em', sm:'0.8em', md:'0.9em'}}}>
            <Grid item container xs={12} spacing={0}>
                <StatusRow setSelected={setIdentity} identity={identity} selected={identity} showClient={true}/>
            </Grid>
        </Grid>
        <TextField
            label="JSON Configuration"
            InputLabelProps={{shrink: true}}
            multiline
            disabled={loading}
            value={filteredData}
            onChange={(event) => {
                setFilteredData(event.target.value)
            }}
            InputProps={{
                style: {fontFamily: 'Monospace', fontSize: 12, fontWeight: 'bold'},
            }}
            sx={{width: '100%', marginTop:3, backgroundColor: '#333'}}
        />
        { filter ?
        <ErrorBlock name="StatusData">
            <Stack item xs={3} direction="column" spacing={0} sx={{width: '100%', m:2}}>
                <Stat identity={identity} module={filter} showTitle={identity + ' - ' + filter}/>
            </Stack>
        </ErrorBlock>
        : ''}
    </Stack>
}
