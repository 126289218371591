import React, {useState, useEffect} from "react";
import {useApiCallback} from "../utils/Api";
import TextField from "@mui/material/TextField";
import {Button} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useToastStore} from "../Toast";
import Typography from "@mui/material/Typography";


export default function Variables() {
    const [loading, setLoading] = useState(false)
    const [variables, setVariables] = useState({})
    const [varname, setVarname] = useState('')
    const [varval, setVarval] = useState('')
    const [jsonVarval, setJsonVarval] = useState({})
    const [valid, setValid] = useState(false)
    const fetchVariables = useApiCallback('GET', `/api/variables`, {setLoading, onDone: setVariables})
    const saveVariable = useApiCallback('POST', `/api/var/${varname}`, {
        data: jsonVarval,
        setLoading,
        onDone: fetchVariables
    })
    const errorToast = useToastStore((state) => state.showError)
    const clearToast = useToastStore((state) => state.clear)

    useEffect(fetchVariables, [])

    useEffect(() => {
        const instr = varval.replaceAll("\n", "\\n")
        try {
            setJsonVarval(instr ? JSON.parse(instr) : 'null')
            clearToast()
            setValid(true)
        } catch (e) {
            setJsonVarval(null)
            setValid(false)
            errorToast(`invalid json: ${e}`)
        }
    }, [varval, setJsonVarval, setValid, errorToast, clearToast])

    const varclick = (k) => {
        setVarname(k)
        setVarval(variables[k].replaceAll("\\n", "\n"))
    }

    return <Stack justifyContent="center" alignItems="start">
        <TextField
            label="Name"
            InputLabelProps={{shrink: true}}
            value={varname}
            onChange={(event) => {
                setVarname(event.target.value)
            }}
            InputProps={{
                style: {fontFamily: 'Monospace'},
            }}
            sx={{width: 220, margin: 2}}
        />
        <TextField
            label="Value"
            InputLabelProps={{shrink: true}}
            multiline
            value={varval}
            onChange={(event) => {
                setVarval(event.target.value)
            }}
            InputProps={{
                style: {fontFamily: 'Monospace'},
            }}
            sx={{width: '100%', margin: 2}}
        />
        <Button disabled={loading || !valid || !varname} onClick={() => saveVariable(jsonVarval)}>Save</Button>
        <Typography sx={{mb:4}}/>
        {Object.keys(variables).map(k => <Button key={k} sx={{textTransform:'none'}} onClick={() => varclick(k)}>{k}</Button>)}
    </Stack>
}
