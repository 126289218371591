import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

export default function GenericSelect({setValue, value, label, options, custom, disabled}) {
    options = options || []
    if (options.indexOf('') === -1)
        options.push('')
    return (
        <Autocomplete
            sx={{width: 200, margin: '8px'}}
            options={options}
            autoHighlight
            freeSolo={Boolean(custom)}
            autoSelect={Boolean(custom)}
            getOptionLabel={(val) => {
                if (val === null) return ''
                return val
            }}
            value={value}
            renderOption={(props, val) => (
                <Box component="li" {...props}>
                    {val}
                </Box>
            )}
            disabled={disabled}
            disableListWrap
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    InputLabelProps={{shrink: true}}
                    inputProps={{...params.inputProps, autoComplete: 'off',}}
                />
            )}
            onChange={(e, val) => {
                if (val === null) val = ''
                setValue(val)
            }}
        />
    )
}