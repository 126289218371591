export function isValidLatLng(lat, lng) {

    if (lat === null || lng === null) {
        return false
    }
    if (lat === 0 && lng === 0) {
        // although this is possible, the chances are so remote I'm not willing to acknowledge it.
        return false
    }
    if (!(lat > -90 && lat < 90)) {
        return false
    } // this catches NaN errors
    if (!(lng > -180 && lng < 180)) {
        return false
    } // this catches NaN errors
    return true
}
