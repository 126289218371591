import {useParams} from "react-router-dom"
import Stack from "@mui/material/Stack"
import ErrorBlock from "./ErrorBlock";
import React, {useState, useEffect, useRef, useCallback} from "react";
import { v4 as uuidv4 } from 'uuid';
import { getSDK } from '../utils/importlib'
import useConfigStore from '../utils/useConfigStore'
import useStatsStore from '../utils/useStatsStore'
import Typography from "@mui/material/Typography";
import {useApiCallback} from "../utils/Api";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {timeSince} from "../utils/time";
import {canUser} from "../App";
import {useLoginStore} from "../Login";
import {ripBuffer} from "../utils/RipMpeg";
// import useWebSocket, {ReadyState} from "react-use-websocket";

const SLDP_LIB_URL = '/sldp-v2.25.0.min.js';
const SLDP_GLOBAL = 'SLDP';
const DEFAULT_CLASSNAME = "sldp-react-player";

let SLDPlib = null
getSDK(SLDP_LIB_URL, SLDP_GLOBAL).then((SLDP) => {
    SLDPlib = SLDP
})

const SldpPlayer = ({
    altStreamUrl = '',
    streamUrl = '',
    width = 'inherit',
    height = '450px',
    splash_screen = null,
    controls = true,
    autoplay = false,
    muted = true,
    videoOnly = true,
    aspectRatio = '16:9',
    sldpOptions = {
        adaptive_bitrate: {
            initial_rendition: '720p'
        },
        buffering: 1000
    }
}) => {
    const playerInstance = useRef();
    const wrapperId = useRef(`sldp-react-player-${uuidv4()}`);
    const [useAltServer, setUseAltServer] = useState(false)

    const destroy = pl => {
        console.log('DESTROYING PLAYER', pl)
        if (!pl) return
        try {
            pl.destroy()
        } catch (e) {
            console.log('failed to destroy video player', e)
        }
    }

    useEffect(() => {
        // componentWillUnmount
        return () => destroy(playerInstance.current)
    }, [])

    // useEffect(() => {
    //     const x = setInterval(() => {
    //         const sldp = document.querySelector('.sldp_message')
    //         if (sldp && sldp.innerText === 'No playable sources found') {
    //             setUseAltServer(!useAltServer)
    //         }
    //     }, 500)
    //     return () => clearInterval(x)
    // }, [setUseAltServer, useAltServer])

    // const {sendMessage, lastMessage, readyState} = useWebSocket(streamUrl, {
    //     shouldReconnect: (closeEvent) => {
    //         console.log('video ws closed', streamUrl, closeEvent)
    //         return true
    //     },
    // });
    //
    // useEffect(() => {
    //   const connectionStatus = {
    //     [ReadyState.CONNECTING]: 'Connecting',
    //     [ReadyState.OPEN]: 'Open',
    //     [ReadyState.CLOSING]: 'Closing',
    //     [ReadyState.CLOSED]: 'Closed',
    //     [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    //   }[readyState]
    //     console.log('video ws connection state changed', streamUrl, connectionStatus)
    //     if (readyState === ReadyState.OPEN) {
    //         sendMessage(JSON.stringify({"command":"Play","streams":[{"type":"video","offset":"0","stream":"G-WKTIM--INPUT/live","sn":0}]}))
    //     }
    // }, [readyState])
    //
    // useEffect(() => {
    //     if (lastMessage === null) return
    //     console.log('video ws data', lastMessage)
    // }, [lastMessage])

    useEffect(() => {
        if (!SLDPlib || !wrapperId.current || !streamUrl) {
            return
        }
        if (playerInstance.current) {
            destroy(playerInstance.current)
        }
        playerInstance.current = SLDPlib.init(Object.assign({}, sldpOptions, {
            container: wrapperId.current,
            stream_url: useAltServer ? altStreamUrl : streamUrl,
            aspect_ratio: aspectRatio,
            splash_screen,
            autoplay: autoplay || useAltServer,
            muted,
            video_only: videoOnly,
            height,
            width,
            controls
        }))
    }, [SLDPlib, aspectRatio, sldpOptions, videoOnly, streamUrl, splash_screen, autoplay, muted, height, width, controls])

    useEffect(() => {
        // if (!playerInstance.current) return
        [...document.getElementsByTagName('video')].forEach(vid => {
            console.log('loadnig video', vid)
            const mediaStream = vid.captureStream()
            const mr = new MediaRecorder(mediaStream);
            mr.ondataavailable = async e => {
                if (e.data.size < 188) return
                const ab = await e.data.arrayBuffer()
                ripBuffer(ab)
            };
            console.log('video readystate', vid.readyState)
            vid.addEventListener('play', () => {
                mr.start(100)
                return true
            })
            vid.addEventListener('pause', () => mr.stop())
        })
        // return () => mr.stop()
    }, [playerInstance.current])

    const playerStyleMods = {
        '& .sldp_volume_btn': {display: 'none'},
        '& .sldp_volume_slider_wrp': {display: 'none'},
    }

    return <Stack justifyContent="center" alignItems="center" sx={{width: '100%', ...playerStyleMods}}>
        <div id={wrapperId.current}
             className={DEFAULT_CLASSNAME}
             style={{width: '100%', maxWidth:'852px'}}
        />
    </Stack>
}

export function VideoRoute() {
    const {identity} = useParams()
    return <Video identity={identity}/>
}

function InsufficientPermissions() {
    return <Typography component="h2" sx={{m:5, p:5, width:'80%',  border:'2px solid #fa0', borderRadius:5}}>
        Insufficient permissions to watch this video stream.<br/> (Or the stream is not configured.)
    </Typography>
}


export default function Video({identity='', nested=false}) {
    const {streams} = useConfigStore()
    const {assigned, servertime} = useStatsStore()
    const [loading, setLoading] = useState(false)
    const [air, setAir] = useState('')
    const [gnd, setGnd] = useState('')
    const [server, setServer] = useState('wss://videv.dea.aero:8443')
    const [server2, setServer2] = useState('wss://server.dea.aero:8443')
    const [url, setUrl] = useState('')
    const [feed, setFeed] = useState('live')
    const [error, setError] = useState(<></>)

    const [autoplay, setAutoplay] = useState(false)

    useEffect(() => {
        setAutoplay(identity === 'SES')
    }, [setAutoplay, identity])

    useEffect(() => {
        if (!identity) return
        if (identity === 'SES') {
            setGnd(identity)
            setAir(identity)
            setFeed('live')
        }
        else if (identity.startsWith('IA-')) {
            setAir(assigned[identity] || null)
            setGnd(identity || null)
            setFeed('compiled')
        } else {
            setAir(identity || null)
            setGnd(assigned[identity] || null)
            setFeed('live')
        }
    }, [identity, assigned[identity], setFeed])

    const [streamName, setStreamName] = useState('')
    useEffect(() => {
        let f = air
        if (feed === 'compiled' && !air)
            f = gnd
        setStreamName(f + '/' + feed)
    }, [air, feed, setStreamName])

    useEffect(() => {
        const url = streams[streamName] || null
        setUrl(url)
        setError(url ? null : <InsufficientPermissions/>)
    }, [setUrl, streams, streamName, setError])

    const [splash, setSplash] = useState('')
    useEffect(() => {
        setSplash((feed === 'compiled' ? "/video_compiled.png" : "/video_live.png"))
    }, [feed])

    const player = useRef()
    useEffect(() => {
        if (!url) {
            player.current = null
            return
        }
        console.log('GENERATING PLAYER')
        player.current = <SldpPlayer
            streamUrl={server + '/' + url}
            altStreamUrl={server2 + '/' + url}
            autoplay={autoplay}
            splash_screen={splash}
        />
    }, [server, url, autoplay, splash, player])

    const [timer, setTimer] = useState(0)
    useEffect(() => {
        const tt = setTimeout(() => setTimer((t) => t + 1), 5_000)
        return () => clearTimeout(tt)
    }, [timer, setTimer])

    const {permissions} = useLoginStore()
    const [watchers, setWatchers] = useState([])
    const doGetWatchers = useApiCallback('GET', `/api/streams/watchers`, {setLoading, onDone: setWatchers})
    useEffect(() => {
        if (nested) return
        if (canUser('GET', '/api/streams/watchers', permissions)) {
            doGetWatchers()
        } else {
            setWatchers([])
        }
    }, [timer, setWatchers, permissions])

    const renderTimeSince = params => {
        return timeSince(servertime, params.value)
    }

    const [tableData, setTableData] = useState([])
    useEffect(() => {
        const dd = []
        watchers.forEach(w => {
            if (w.app + '/' + w.stream !== streamName) return
            dd.push({
                'id': w.id,
                'bytes': w.bytes,
                'first_seen': w.first_seen,
                'geo_country': w.geo_country,
                'geo_isp': w.geo_isp,
                'ip': w.ip,
                'last_seen': w.last_seen,
                'platform': w.platform,
                'user_agent': w.user_agent,
            })
        })
        setTableData(dd)
    }, [watchers, streamName, setTableData])

    let names = air || gnd

    const getColumnDefinition = useCallback(() => {
        return [
            {field: 'ip', headerName: 'ip', flex: 1},
            {field: 'geo_country', headerName: 'Country', flex: 1},
            {field: 'geo_isp', headerName: 'ISP', flex: 1},
            {field: 'first_seen', headerName: 'First Seen', flex: 1, renderCell: renderTimeSince},
            {field: 'last_seen', headerName: 'Last Seen', flex: 1, renderCell: renderTimeSince},
            {field: 'user_agent', headerName: 'User Agent', flex: 1},
            {field: 'platform', headerName: 'Platform', flex: 1},
            {field: 'bytes', headerName: 'Bytes', flex: 1},
        ]
    })

    if (nested) {
        return (
            <ErrorBlock name="Video">
                {error}{player.current}
            </ErrorBlock>
        )
    }

    return <Stack sx={{width:'100%'}}>
        <Typography component="h2" sx={{textAlign:'center', fontSize:'1.4em'}}>
            {names} - {feed}
        </Typography>
        <Stack justifyContent="center" alignItems="center" sx={{textAlign: 'center'}}>
            <ErrorBlock name="Video">
                {error}{player.current}
            </ErrorBlock>
        </Stack>
        <Typography component="h2" sx={{textAlign:'center', fontSize:'1.4em'}}>
            Watchers
        </Typography>
        <div style={{height: 'calc(50vh - 180px)', width: '100%'}}>
            <DataGridPro
                hideFooter={true}
                loading={loading}
                rows={tableData}
                disableMultipleRowSelection={true}
                columns={getColumnDefinition()}
            />
        </div>
    </Stack>
}
