import Grid from '@mui/material/Grid'
import {Button} from '@mui/material'
import {ErrorBoundary} from "react-error-boundary";

export default function ErrorBlock(params) {
    const {name, children} = params

    function Fallback({error, resetErrorBoundary}) {
        console.log(error)
        return <Grid
            xs={12}
            item
            style={{
                border: '2px dotted red',
                padding: '20px',
                borderRadius: '20px',
                margin: '40px auto',
                minHeight: '100px',
                height: '100%',
                width: '80%'
            }}
            container
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12}>
                {name} is broken.
            </Grid>
            <Grid item xs={12}>
                <pre style={{color: "red", whiteSpace:"pre-wrap"}}>{error.message}</pre>
            </Grid>
            <Grid item xs={12}>
                <Button onClick={resetErrorBoundary}>Retry</Button>
            </Grid>
        </Grid>
    }

    return <ErrorBoundary FallbackComponent={Fallback}>
        {children}
    </ErrorBoundary>
}
