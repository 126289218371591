import React, {useEffect, useState} from "react"
import {useApiCallback} from "../utils/Api"
import Stack from "@mui/material/Stack"
import {Link as RLink, useNavigate, useParams} from "react-router-dom"
import TextField from '@mui/material/TextField'
import {Button} from "@mui/material"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid";
import StatusRow from "./StatusRow";
import LinearProgress from '@mui/material/LinearProgress';
import Box from "@mui/material/Box";

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate" color="success" {...props} />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant="body2">{props.value}s</Typography>
            </Box>
        </Box>
    );
}

export default function Config() {
    const params = useParams()
    const [identity, setIdentity] = useState(params.identity)
    const [loading, setLoading] = useState(false)
    const [code, setCode] = useState('')
    const onDone = d => {
        console.log('code', d)
        setCode(d)
        setTime(100)
    }
    const generateCode = useApiCallback('POST', `/api/setupcode/${identity}`, {setLoading, onDone})
    const [time, setTime] = useState(0)
    useEffect(() => {
        if (time <= 0 && code) {
            setCode('')
            setTime(0)
            return
        }
        const tm = setInterval(() => setTime(t => Math.max(0, t - 1)), 1000)
        return () => clearInterval(tm)
    }, [time, code, setTime, setCode])

    return <Stack sx={{fontSize:{xs:'0.8em',sm:'0.9em',md:'1em'}}}>
        <Stack sx={{mt:2}} direction="row" alignItems="center" spacing={1} justifyContent="center">
            <Typography component="h2">{identity}</Typography>
            <Button component={RLink} to={`/config/${identity}`}>Config</Button>
            <Button component={RLink} to={`/video/${identity}`}>Video</Button>
            <Button component={RLink} to={`/stats/${identity}`}>Stats</Button>
        </Stack>
        <Grid container spacing={0}  sx={{textAlign:'center', maxWidth: 800, margin: 'auto',
            fontSize: {xs:'0.7em', sm:'0.8em', md:'0.9em'}}}>
            <Grid item container xs={12} spacing={0}>
                <StatusRow setSelected={setIdentity} identity={identity} selected={identity} showClient={true}/>
            </Grid>
        </Grid>
        <Stack  sx={{mt:2}} direction="row" alignItems="center" spacing={1} justifyContent="center">
            <Button disabled={loading} onClick={() => generateCode()} color='success'>Generate Code</Button>
        </Stack>
        <TextField
            label="Code"
            InputLabelProps={{shrink: true}}
            multiline
            disabled={loading}
            value={code}
            inputProps={{
                style: {
                    fontFamily: 'Monospace',
                    fontSize: 36,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    lineHeight: '100%',
                },
            }}
            sx={{width: '100%', marginTop:3, backgroundColor: '#333'}}
        />
        <LinearProgressWithLabel value={time} />
    </Stack>
}
