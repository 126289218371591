import {useState, useEffect} from "react";
import {useApiCallback} from "../utils/Api";
import TextField from "@mui/material/TextField";
import {Button} from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import useConfigStore from '../utils/useConfigStore'


export default function IPMap() {
    const [loading, setLoading] = useState(false)
    const [ip, setIp] = useState('')
    const [name, setName] = useState('')
    const confStore = useConfigStore()
    const fetch = useApiCallback('GET', `/api/ipmap`, {setLoading, onDone: ips => confStore.setIpMap(ips)})
    const add = useApiCallback('POST', `/api/ipmap`, {
        data: {ip, name},
        setLoading,
        onDone: fetch
    })
    const remove = useApiCallback('DELETE', `/api/ipmap`, {
        data: {ip, name},
        setLoading,
        onDone: fetch
    })

    useEffect(fetch, [])

    const rowClick = (k) => {
        setIp(k)
        setName(confStore.ipmap[k])
    }

    return <Stack justifyContent="center" alignItems="start" >
        <Stack direction="row" alignItems="center">
            <TextField
                label="IP"
                InputLabelProps={{shrink: true}}
                value={ip}
                onChange={(event) => {
                    setIp(event.target.value.replace(/[^0-9/.]/, ''))
                }}
                sx={{width: '300px', margin: 2}}
            />
            <TextField
                label="Name"
                InputLabelProps={{shrink: true}}
                value={name}
                onChange={(event) => {
                    setName(event.target.value)
                }}
                sx={{width: '300px', margin: 2}}
            />
            <Button disabled={loading | !ip || !name} onClick={() => add()}>Add</Button>
            <Button disabled={loading | !ip || !name} onClick={() => remove()}>Delete</Button>
        </Stack>
        <br/>
        {Object.keys(confStore.ipmap).map(k =>
            <Grid item container xs={12} key={k} onClick={() => rowClick(k)}>
                <Grid item xs={3}><code>{k}</code></Grid>
                <Grid item xs={9}><code>{confStore.ipmap[k]}</code></Grid>
            </Grid>
        )}
    </Stack>
}
