import {Button} from '@mui/material'
import {useEffect, useState} from 'react'
import create from 'zustand'
import CircularProgress from '@mui/material/CircularProgress'
import {useApiCallback} from "./utils/Api";

export const useLoginStore = create((set) => ({
    user: '',
    image: '',
    loading: false,
    permissions: [],
    setUser: (user) => set({ user }),
    setImage: (image) => set({ image }),
    setLoading: (loading) => set({ loading }),
    setPermissions: (permissions) => set({ permissions }),
}))

export function Login() {
    const {user, image, loading, permissions, setUser, setImage, setLoading, setPermissions} = useLoginStore()
    const [loginUrl, setLoginUrl] = useState('')
    const [loginWindow, setLoginWindow] = useState(null)
    const [loginCode, setLoginCode] = useState('')
    const [userData, setUserData] = useState({})

    const code = (loginCode ? `?code=${loginCode}` : '')
    const loginCheck = useApiCallback('GET', `/api/login${code}`, {setLoading, onDone: setUserData})
    useEffect(loginCheck, [loginCode, loginCheck])

    useEffect(() => {
        if (user) return
        const interval = setInterval(() => {
            if (!loginWindow) return
            if (!loginWindow.location) return
            if (user) return
            let m = []
            try {
                console.log('loginwindow', loginWindow.location)
                m = loginWindow.location.search.match(/^\?code=(.+)$/)
            } catch (e) {
                console.log('error looking for popup location', e)
                return
            }
            if (m) {
                const _lcode = m[1]
                setLoginWindow(null)
                loginWindow.close()
                console.log('setting logincode', _lcode)
                setLoginCode(_lcode)
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [loginWindow, setLoginWindow, setLoginCode, user, loginCode])

    useEffect(() => {
        if (userData.hasOwnProperty('redirect') && loginUrl !== userData.redirect) {
            setLoginUrl(userData.redirect)
        }
    }, [userData, setLoginUrl, loginUrl])

    useEffect(() => {
        if (userData.hasOwnProperty('email')) {
            user !== userData.email && setUser(userData.email)
        }
        if (userData.hasOwnProperty('image')) {
            image !== userData.image && setImage(userData.image)
        }
        if (userData.hasOwnProperty('permissions')) {
            permissions !== userData.permissions && setPermissions(userData.permissions)
        }
    }, [user, image, permissions, setUser, setImage, setPermissions, userData])

    try {
        if (window.location.search.match(/^\?code=(.+)$/)) {
            // this is the popup login window
            return <div style={{position: 'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:'#666', padding:20}}>
                Processing authentication...
            </div>
        }
    } catch(e) {}

    const promptUserToSignIn = () => {
        const w = window.open(
            loginUrl,
            '__blank',
            'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500,height=600'
        )
        setLoginWindow(w)
    }

    return <Button
        disabled={loading || !loginUrl}
        startIcon={loading ? <CircularProgress size="1rem" /> : null}
        onClick={promptUserToSignIn}
    >
        Login
    </Button>

}

export function LogoutProvider({children}) {
    const {user, setUser, setPermissions} = useLoginStore()

    const onDone = () => {
        setUser(null)
        setPermissions([])
        window.location.reload();
    }

    const logoutClick = useApiCallback('GET', `/api/logout`, {onDone})

    return user ? <span onClick={() => logoutClick()}>{children}</span> : <></>
}
