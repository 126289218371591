export function timeSinceToStr(s) {
    if (!s) {
        return 'never'
    }
    const d = 24 * 60 * 60
    const h = 60 * 60
    const m = 60
    if (s >= d * 2) return Math.floor(s / d) + 'd'
    if (s >= h * 2) return Math.floor(s / h) + 'h'
    if (s >= m * 2) return Math.floor(s / m) + 'm'
    return s + 's'
}

export function timeSinceInt(servertime, at) {
    const now = Math.round(servertime);
    const dd = Math.round(at);
    return Math.max(0, now - dd);
}

export function timeSince(servertime, at) {
    if (at === null) return '-'
    const timediff = timeSinceInt(servertime, at)
    return timeSinceZero(timediff)
}

export function timeSinceZero(timediff) {
    if (timediff < 60)
        return `${timediff}s`;
    let time_s = timediff % 60;
    let time_m = Math.floor(timediff / 60) % 60;
    let time_h = Math.floor(timediff / (60 * 60)) % 24;
    let time_d = Math.floor(timediff / (60 * 60 * 24));
    time_s = time_s || time_m || time_h || time_d ? `${time_s}s` : '';
    time_m = time_m || time_h || time_d ? `${time_m}m` : '';
    time_h = time_h || time_d ? `${time_h}h` : '';
    time_d = time_d ? `${time_d}d` : '';

    let timestr = ''
    if (time_d) {
        timestr = `${time_d} ${time_h}`;
    } else if (time_h) {
        timestr = `${time_h} ${time_m}`;
    } else if (time_s || time_m) {
        timestr = `${time_m} ${time_s}`;
    } else {
        timestr = '-'
    }
    return timestr;
}
