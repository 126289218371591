import {useState, useEffect} from "react"
import {useApiCallback} from "../utils/Api"
import 'mapbox-gl/dist/mapbox-gl.css'
import {isValidLatLng} from "../utils/Mappery"


export default function NearestAirport({lat, lng}) {
    const [nearest, setNearest] = useState({})
    const [loading, setLoading] = useState(false)
    const fetchNearestAirport = useApiCallback('GET', `/api/airport/nearest?lat=${lat}&lng=${lng}`, {setLoading, onDone: setNearest})

    useEffect(() => {
        if (isValidLatLng(lat, lng))
            fetchNearestAirport()
    }, [lat, lng])

    const dist = nearest?.dist || null
    if (dist && dist < 999999) {
        return <div title={nearest.name}>{nearest.code} ({Math.round(nearest.dist)}m)</div>
    }

    return <div>{loading ? '...' : '-'}</div>
}
