import React, {useEffect, useState} from "react";
import {timeSinceZero} from "../utils/time";

export default function Ago({at, suffix=' ago'}) {
    const [xat, setAt] = useState(0)

    useEffect(() => {
        try {
            setAt(parseInt(at))
        } catch (e) {}
    }, [at])

    useEffect(() => {
        if (xat === '-') return
        const t = setInterval(() => {
            setAt(xat => xat + 1)
        }, 1000)
        return () => clearInterval(t)
    }, [setAt])

    return <span>{timeSinceZero(xat)}{suffix}</span>
}
