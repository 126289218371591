import {useState, useEffect} from "react";
import {useApiCallback} from "../utils/Api";
import {Button} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";


export default function Import() {
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(null)
    const [idtoken, setIdtoken] = useState('')
    const [count, setCount] = useState(20)
    const doImport = useApiCallback('POST', `/api/import`, {data: {idtoken}, setLoading, onDone: setStatus})
    const doImportStatus = useApiCallback('POST', `/api/import/status`, {data: {idtoken}, setLoading, onDone: setStatus})

    useEffect(() => {
        const it = setTimeout(() => {
            if (count > 0)
                setCount(count => count - 1)
        }, 1000)
        return () => clearTimeout(it)
    }, [setCount, count])

    return <Stack justifyContent="center" alignItems="center" >
        <Typography component="h2" sx={{m:4}}>Import configuration from legacy mPortal - Welcome to the Danger Zone</Typography>
        <Typography color="error">
        <strong>DO NOT RUN THIS unless you know what you are doing.
            This action will replace existing configuration on this portal.</strong>
        </Typography>
        <TextField
            label="idtoken"
            InputLabelProps={{shrink: true}}
            value={idtoken}
            onChange={(event) => {
                setIdtoken(event.target.value)
            }}
            sx={{width: '300px', margin: 2}}
        />
        <Stack direction="row" alignItems="center" sx={{marginTop:5}}>
            <Button disabled={status || count || loading} color="error" onClick={() => doImport()}>Run {count ? count : ''}</Button>
            <Button disabled={loading} color="error" onClick={() => doImportStatus()}>Import Status Only</Button>
        </Stack>
        <pre>{status ? JSON.stringify(status, null, 4) : ''}</pre>
    </Stack>
}
