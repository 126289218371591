import { Link as RLink } from 'react-router-dom'
import React, {useEffect, useState, useRef} from "react"
import {useApiCallback} from "../utils/Api"
import {useLoginStore} from "../Login"
import Grid from "@mui/material/Grid"
import {ExampleGreenFlag, ExampleRedFlag} from "./Flags"
import Stack from "@mui/material/Stack"
import {Button} from "@mui/material"
import ContractSelect from "./ContractSelect"
import {useToastStore} from "../Toast"
import GenericSelect from "./GenericSelect"
import Video from "./Video"
import Typography from "@mui/material/Typography"
import {canUser} from '../App'
import StatusRow from './StatusRow'
import useStatsStore from '../utils/useStatsStore'
import useConfigStore from '../utils/useConfigStore'
import Box from "@mui/material/Box";


export function StatusData() {
    const [configs, setConfigs] = useState({})
    const loginstore = useLoginStore()
    const loadConfigs = useApiCallback('GET', '/api/configs', {onDone: setConfigs})
    const statsStore = useStatsStore()
    const configStore = useConfigStore()
    const user = loginstore.user

    useEffect(() => {
        const tt = setTimeout(() => {
            statsStore.setServertime(statsStore.servertime + 1)
        }, 1000)
        return () => clearTimeout(tt)
    }, [statsStore.servertime])

    useEffect(() => {
        if (user) {
            console.log('loading configs')
            loadConfigs()
        }
    }, [setConfigs, loadConfigs, user])

    useEffect(() => {
        if (configs.stats)
            statsStore.setStats(configs.stats)
    }, [configs.stats, statsStore.setStats])

    useEffect(() => {
        if (configs.assigned)
            statsStore.setAssigned(configs.assigned)
        if (configs.assignments)
            statsStore.setAssignments(configs.assignments)
        // if (configs.configs)
        //     configStore.setConfigs(configs.configs || {})
        if (configs.ipmap)
            configStore.setIpMap(configs.ipmap)
        if (configs.servertime) {
            if (configs.servertime !== statsStore.servertime)
                statsStore.setServertime(configs.servertime)
        }
        if (typeof configs.lastseen === 'object') {
            {
                const nk = statsStore.lastseen
                let changed = false
                Object.keys(configs.lastseen).forEach(k => {
                    const newval = configs.lastseen[k].at
                    if (nk[k] === newval) return
                    nk[k] = newval
                    changed = true
                })
                if (changed)
                    statsStore.setLastSeen(nk)
            }
            {
                const nk = statsStore.lastseenip
                let changed = false
                Object.keys(configs.lastseen).forEach(k => {
                    const newval = configs.lastseen[k].ip
                    if (nk[k] === newval) return
                    nk[k] = newval
                    changed = true
                })
                if (changed)
                    statsStore.setLastSeenIp(nk)
            }
        }
    }, [configs])

    return <></>
}


export default function Status() {
    const statsStore = useStatsStore()
    const {permissions} = useLoginStore()
    const [clients, setClients] = useState([])
    const [contract, setContract] = useState('')
    const [identity, setIdentity] = useState('')
    const [loading, setLoading] = useState(false)
    const [assignToList, setAssignToList] = useState([])
    const [assignedTo, setAssignedTo] = useState('')
    const successToast = useToastStore((state) => state.showSuccess)
    const onDone = () => {
        successToast('Configuration saved')
    }
    const assign = useApiCallback('POST', `/api/assign`, {setLoading, onDone})

    const assignClick = () => {
        assign({identity, to: assignedTo, contract})
    }

    useEffect(() => {
        statsStore.assignments.forEach(row => {
            const [gnd, air, client, contract] = row
            if (gnd === identity || air === identity) {
                setContract((client ? client : '') + ' ' + (contract ? contract : ''))
                console.log('contract selected', client + ' ' + contract)
            }
        })
    }, [identity, setContract, statsStore.assignments])

    useEffect(() => {
        /** Build cached list of clients. **/
        const _clients = []
        statsStore.assignments.forEach(vars => {
            const [, , client, ] = vars
            if (_clients.indexOf(client) === -1) {
                _clients.push(client)
            }
        })
        _clients.sort()
        if (_clients !== clients) {
            setClients(_clients)
        }
    }, [statsStore.assignments, setClients])

    useEffect(() => {
        const opts = []
        const is_gnd = identity.startsWith('IA-')
        statsStore.assignments.map(vars => {
            const [gnd, air, client, contract] = vars
            const sel = is_gnd ? air : gnd
            if (sel)
                opts.push(sel)
            if (identity === gnd)
                setAssignedTo(air)
            if (identity === air)
                setAssignedTo(gnd)
        })
        opts.sort()
        setAssignToList(opts)
        console.log('ASSIGNED LIST', opts)
    }, [setAssignToList, identity, setAssignedTo, statsStore.assignments])

    const is_gnd = identity.startsWith('IA-')

    const [canAssign, setCanAssign] = useState(false)
    const [canSeeSESVideo, setCanSeeSESVideo] = useState(false)
    const [canEditConfigs, setCanEditConfigs] = useState(false)
    const [canSeeAllVideos, setCanSeeAllVideos] = useState(false)
    useEffect(() => {
        setCanAssign(canUser('POST', '/api/assign', permissions))
        setCanSeeSESVideo(canUser('GET', 'video/ses/live', permissions))
        setCanEditConfigs(canUser('POST', '/api/config/', permissions))
        setCanSeeAllVideos(canUser('GET', 'video/*', permissions))
    }, [permissions])

    const [gaccByClient, setGaccByClient] = useState({})
    useEffect(() => {
        const gacc = {}
        {clients.forEach(stepclient => {
            const named = stepclient || 'unassigned'
            gacc[named] = []
            {statsStore.assignments.map(vars => {
                const [gnd, air, client, contract] = vars
                if (client === stepclient) {
                    console.log('ASDASDASD', gacc[stepclient])
                    gacc[named].push(gnd || air)
                }
            })}
        })}
        setGaccByClient(gacc)
        console.log('GACC', gacc)
    }, [clients, statsStore.assignments, setGaccByClient])

    return <Stack>
        {canSeeSESVideo ? <>
            <Box justifyContent="center" alignItems="center" sx={{
                m: 2,
                overflow: 'hidden',
                position: 'relative',
            }}>
                <Video identity={'SES'} nested={true}/>
            </Box>
        </> : ''}
        <Stack direction="row" justifyContent="center" alignItems="center" sx={{padding:2}}>
            { canEditConfigs ?
                <Button component={RLink} to={`/new`}>New</Button>
            : ''}
            { canSeeAllVideos ?
            <Button disabled={loading || !identity} component={RLink} to={`/video/${identity}`}>Video</Button>
            : ''}
            <Button disabled={loading || !identity} component={RLink} to={`/stats/${identity}`}>Stats</Button>
            { canEditConfigs ?
            <Button disabled={loading || !identity} component={RLink} to={`/config/${identity}`}>Config</Button>
            : ''}
            <Button component={RLink} to={`/video/SES`}>SES</Button>
        </Stack>
        {canAssign ? <>
            <Stack direction="row" justifyContent="center" alignItems="center" sx={{padding:2}}>
                <ContractSelect disabled={loading || !identity} contract={contract} setContract={setContract}/>
                <GenericSelect
                    setValue={setAssignedTo}
                    value={assignedTo}
                    label={is_gnd ? 'Assign Aircraft' : 'Assign Terminal'}
                    options={assignToList}
                    disabled={loading || !identity}
                />
                <Button disabled={loading || !identity} onClick={assignClick}>Assign</Button>
            </Stack>
        </> : ''}
        <Grid container spacing={0}  sx={{textAlign:'center', maxWidth: 800, margin: 'auto',
            fontSize: {xs:'0.7em', sm:'0.8em', md:'0.9em'}}}>
        {Object.keys(gaccByClient).map(client =>
            <Grid item container xs={12} spacing={0} key={client}>
                <Grid item xs={12} sx={{p:2, mt:1, backgroundColor:'#222', borderRadius: '10px 10px 0 0', fontWeight:'bold', textSize:'1.2em', textAlign:'left'}}>
                    {client}
                </Grid>
                {gaccByClient[client].map(gndorair =>
                    <StatusRow setSelected={setIdentity} identity={gndorair} selected={identity}/>
                )}
            </Grid>
        )}
        </Grid>
        <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography variant="p" sx={{textAlign:'left', maxWidth:700}}>
                <ul style={{listStyleType:'none'}}>
                    <li>When an aircraft/terminal status is updated, the status area will blink briefly.</li>
                    <li><ExampleRedFlag title='INIT'/> = Missing status info, system probably in startup phase.</li>
                    <li><ExampleGreenFlag title='KLV'/>/<ExampleRedFlag title='KLV'/> = Aircraft positional data sent/received</li>
                    <li><ExampleGreenFlag title='TTM'/>/<ExampleRedFlag title='TTM'/> = Radar tracks sent/received</li>
                    <li><ExampleGreenFlag title='AIS'/>/<ExampleRedFlag title='AIS'/> = AIS data sent/received</li>
                    <li><ExampleGreenFlag title='REC'/>/<ExampleRedFlag title='REC'/> =
                        Video Recording active/inactive within the aircraft (Aircraft side only)</li>
                    <li><ExampleGreenFlag title='STR'/>/<ExampleRedFlag title='STR'/> =
                        Video Stream being received/not received on the IA terminal (IA side only)</li>
                </ul>
            </Typography>
        </Stack>
    </Stack>
}
