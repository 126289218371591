import './App.css'
import Status, {StatusData} from './panel/Status'
import useStatsStore from './utils/useStatsStore'
import Container from '@mui/material/Container'
import Toast from './Toast'
import CssBaseline from "@mui/material/CssBaseline"
import ThemeProvider from './ThemeProvider'
import {useToastStore} from "./Toast"
import {Routes, Route} from "react-router-dom"
import AppBar from './AppBar'
import {useServiceWorker} from './useServiceWorker'
import {useEffect, useState} from "react"
import {Button} from "@mui/material"
import {useLoginStore} from "./Login"
import {Helmet} from "react-helmet-async"
import ErrorBlock from './panel/ErrorBlock'
import WebSock from "./WebSock"
import Stats from "./panel/Stats"
import Config from "./panel/Config"
import Admins from "./panel/Admins"
import Variables from "./panel/Variables"
import IPMap from "./panel/IPMap"
import {VideoRoute} from "./panel/Video"
import Streams from "./panel/Streams"
import Acl from "./panel/Acl"
import Import from "./panel/Import"
import Compare from "./panel/Compare"
import Setup from "./panel/Setup"
import New from "./panel/New"
import Map from "./panel/Map"
// import Flights from "./panel/Flights"
import { LicenseInfo } from '@mui/x-license-pro'
import Uploads from "./panel/Uploads";
import Box from "@mui/material/Box";


LicenseInfo.setLicenseKey('473f7a35c4c96267b7e044104445562dTz04NjE4OSxFPTE3NDM3ODI0MDAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

export function canUser(method, path, permissions) {
    let can = false
    permissions.forEach(p => {
        if (p.method === method || p.method === '*') {
            if (p.path === path || p.path === '*') {
                can = true
            }
            else if (p.path.slice(-1) === '*' && p.path.slice(0, -1) === path.slice(0, p.path.length - 1)) {
                // match with wildcard suffix
                can = true
            }
        }
    })
    return can
}

export default function App() {
    const infoToast = useToastStore((state) => state.showInfo)
    const { waitingWorker, showReload, reloadPage } = useServiceWorker()
    const loginstore = useLoginStore()
    const {servertime} = useStatsStore()
    const [links, setLinks] = useState([])
    const [routes, setRoutes] = useState([])
    const [statusPanel, setStatusPanel] = useState(<></>)
    const [webSocket, setWebSocket] = useState(<></>)
    const [statusData, setStatusData] = useState(<></>)

    // decides when to show the toast
    useEffect(() => {
      if (showReload && waitingWorker) {
        infoToast(
            <div>
              New app version available!
              <Button style={{marginLeft:'5px'}} onClick={() => reloadPage()}>Reload</Button>
            </div>
          , 30_000)
      }
    }, [infoToast, waitingWorker, showReload, reloadPage, Math.floor(servertime / 30)])

    useEffect(() => {
        const links = []
        const routes = []
        if (loginstore.user) {
            const mlinks = [
                ['Status',     '/'],
                ['Streams',    '/streams'],
                ['Admins',     '/admins'],
                ['Variables',  '/variables'],
                ['ACL',        '/acl'],
                ['Import',     '/import'],
                ['IPMap',      '/ipmap'],
                ['Compare',    '/compare'],
                ['Map',        '/map'],
                // ['Flights',    '/flights'],
                ['Uploads',    '/uploads']
            ]
            Object.keys(mlinks).forEach(k => {
                const [name, link] = mlinks[k]
                if (canUser('GET', '#' + link, loginstore.permissions)) {
                    links.push({name, link})
                }
            })
            routes.push(<Route exact key="/map" path="/map" element={<Map/>}/>)
            routes.push(<Route exact key="/ipmap" path="/ipmap" element={<IPMap/>}/>)
            routes.push(<Route exact key="/new" path="/new" element={<New/>}/>)
            routes.push(<Route exact key="/compare" path="/compare" element={<Compare/>}/>)
            routes.push(<Route exact key="/setup/:identity" path="/setup/:identity" element={<Setup/>}/>)
            routes.push(<Route exact key="/acl" path="/acl" element={<Acl/>}/>)
            routes.push(<Route exact key="/import" path="/import" element={<Import/>}/>)
            routes.push(<Route exact key="/admins" path="/admins" element={<Admins/>}/>)
            routes.push(<Route exact key="/variables" path="/variables" element={<Variables/>}/>)
            routes.push(<Route exact key="/uploads" path="/uploads" element={<Uploads/>}/>)
            routes.push(<Route exact key="/streams" path="/streams" element={<Streams/>}/>)
            // routes.push(<Route exact key="/flights" path="/flights" element={<Flights/>}/>)
            routes.push(<Route exact key="/stats/:identity" path="/stats/:identity" element={<Stats />}/>)
            routes.push(<Route exact key="/config/:identity" path="/config/:identity" element={<Config />}/>)
            routes.push(<Route exact key="/video/:identity" path="/video/:identity" element={<VideoRoute />}/>)
            routes.push(<Route exact key="/" path="/" element={statusPanel}/>)
        } else {
            routes.push(<Route key="*"  path="*" element={<></>}/>)
        }
        setRoutes(routes)
        setLinks(links)
    }, [loginstore.permissions, setRoutes, setLinks])

    useEffect(() => {
        setWebSocket(<WebSock/>)
    }, [setWebSocket])

    useEffect(() => {
        setStatusData(<StatusData/>)
    }, [setStatusData])

    useEffect(() => {
        setStatusPanel(<Status/>)
    }, [setStatusPanel])

    return (
        <>
            <ErrorBlock name="StatusData">
                {statusData}
            </ErrorBlock>
            <Helmet>
            </Helmet>
            <ThemeProvider>
                <ErrorBlock name="Appbar">
                    <AppBar links={links}/>
                </ErrorBlock>
                <CssBaseline/>
                <ErrorBlock name="Main panel">
                    <Box sx={{
                        position: 'absolute',
                        padding: '10px',
                        paddingBottom: '50px',
                        margin: 0,
                        marginTop: '51px',
                        height: 'calc(100vh - 52px)',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        left: '0',
                        right: '0',
                    }}>
                        <Container sx={{position:'relative', height: 'auto', minHeight:'100%'}}>
                            <Routes>
                                {routes}
                            </Routes>
                            <Box sx={{
                                color:'#444', position:'absolute', height: 30, bottom: -50, textAlign:'center',
                                width: '100%'
                            }}>
                                © Copyright 2024 DEA Aviation - All Rights Reserved
                            </Box>
                        </Container>
                    </Box>
                    <Toast/>
                </ErrorBlock>
            </ThemeProvider>
            <ErrorBlock name="Websocket">
                {webSocket}
            </ErrorBlock>
        </>
    )
}
